import {
  CREATE_BRIEFINGBOOK_REQUEST,
  CREATE_BRIEFINGBOOK_SUCCESS,
  CREATE_BRIEFINGBOOK_FAILURE,
  statusType
} from '../../actions'

const initial = {
  data: {},
  books: [],
  initialLoading: true,
  status: statusType.IDLE
}

const edit = (state = initial, action) => {
  switch (action.type) {
    case CREATE_BRIEFINGBOOK_REQUEST:
      return {
        ...initial,
        status: statusType.IN_PROGRESS
      }

    case CREATE_BRIEFINGBOOK_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: statusType.SUCCESS,
        type: action.type
      }

    case CREATE_BRIEFINGBOOK_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    default:
      return state
  }
}

export default edit
