import {
  GET_ATTENDEE_LIST_REQUEST,
  GET_ATTENDEE_LIST_SUCCESS,
  GET_ATTENDEE_LIST_FAILURE,
  RESET_ATTENDEE_LIST,
  statusType
} from '../../actions'
import { get } from 'lodash'
import { ENTITY_INSTITUTION, ENTITY_CONTACT, ENTITY_FUND } from '../../utils/entity'

const initial = {
  data: [],
  counts: {},
  page: 1,
  total: 0,
  status: statusType.IDLE
}

const attendee = (state = initial, action) => {
  const { payload } = action

  switch (action.type) {
    case GET_ATTENDEE_LIST_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }
    case GET_ATTENDEE_LIST_SUCCESS:
      payload.data = filterAddress(payload.data)
      return {
        ...state,
        ...payload,
        data: (payload.data || []).map((attendee) => ({
          _id: attendee._id,
          entityId: getEntityId(attendee),
          type: attendee.entity_type,
          name: getEntityName(attendee),
          firstName: attendee.item.first_name,
          lastName: attendee.item.last_name,
          location: getEntityLocation(attendee),
          email: get(attendee, 'item.jobs[0].email', ''),
          phone: getEntityPhone(attendee),
          institutionName: attendee.institution_name,
          position: attendee.position
        })),
        status: statusType.SUCCESS
      }
    case GET_ATTENDEE_LIST_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }
    case RESET_ATTENDEE_LIST:
      return initial
    default:
      return state
  }
}

/**
 * Removes empty locations
 * @param attendees
 */
function filterAddress (attendees) {
  return (attendees || []).map((attendee) => {
    if (attendee && attendee.item && attendee.item.address) {
      attendee.item.address = attendee.item.address.filter((address) => address)
    }
    return attendee
  })
}

/**
 * Returns entity id
 * @param entity
 */
function getEntityId (entity) {
  if (ENTITY_CONTACT === entity.entity_type) {
    return get(entity, 'item._id', '')
  }

  return get(entity, 'item.entity_id', '')
}

/**
 * Returns entity name
 * @param entity
 */
function getEntityName (entity) {
  if (ENTITY_INSTITUTION === entity.entity_type) {
    return get(entity, 'item.institution_name', '')
  }

  if (ENTITY_FUND === entity.entity_type) {
    return get(entity, 'item.fund_name', '')
  }

  return get(entity, 'item.full_name', '')
}

/**
 * Returns entity location
 * @param entity
 */
function getEntityLocation (entity) {
  const locationTemplate = ({ city, state_province, country }) => {
    return [city, state_province, country].filter((field) => field).join(', ')
  }

  if (ENTITY_CONTACT === entity.entity_type) {
    return locationTemplate(get(entity, 'item.jobs[0]', {}))
  }

  return locationTemplate((entity.item.address || []).find((location) => location.hq) || {})
}

/**
 * Returns entity phone
 * @param entity
 */
function getEntityPhone (entity) {
  if (ENTITY_CONTACT === entity.entity_type) {
    return get(entity, 'item.jobs[0].phone', '') || get(entity, 'item.jobs[0].direct_telephone', '')
  }

  return ((entity.item.address || []).find((location) => location.hq) || {}).telephone || ''
}

export default attendee
