import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { getClassName, THEMES } from '../../utils'
import Dialog from '@material-ui/core/Dialog'
import { Spinner, Checkbox } from '../../components'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderDarkThumb, renderTrackVertical } from '../../resources/theme/q4.custom-scrollbar'
import './modal.component.css'

const styles = {}

/**
 * Modal Component
 */
class Modal extends Component {

  /**
   * Render Action Button
   * @param button
   * @param index
   */
  renderActionButton = (button, index) => {
    const { dataId, ref, ui, type = 'button', icon, label, hidden, invisible, loading, disabled, isChecked, onClick } = button

    if (type === 'checkbox') {
      return (
        <Checkbox
          dataId={dataId}
          theme={THEMES.DARK}
          key={`modal-checkbox--${index}`}
          id={`modal-checkbox--${index}`}
          label={label}
          isSelected={isChecked}
          onChange={onClick}
        />
      )
    }

    const buttonClass = getClassName('button button--tall', [
      { condition: ui, trueClassName: `button--${ui}` },
      { condition: (icon && !(label && label.length)), trueClassName: 'button--square' },
      { condition: invisible, trueClassName: 'button--invisible' },
      { condition: hidden, trueClassName: 'button--hidden' },
      { condition: loading, trueClassName: 'button--loading' },
      { condition: disabled, trueClassName: 'button--disabled' }
    ])

    return (
      <button data-id={dataId} key={`modal-button--${index}`} ref={ref} className={buttonClass} onClick={onClick}>
        {icon && <i className={`button_icon ${icon}`} />}
        {label}
      </button>
    )
  }

  /**
   * Render Modal Component
   * @return {XML}
   */
  render () {
    const {
      className, visible,
      headerSize, badge, title, subtitle, footerButtons, headerButtons,
      narrow, containerWidth, contentHeight, autoHeightMin, autoHeightMax, contentPadding, containerMaxWidth, contentBackground,
      includeChin, scrollable, closeIcon, fullscreen, loading, disableEscapeKeyDown, disableAutoFocus, disableRestoreFocus,
      children, spinnerProps, onClose, dataId
    } = this.props

    const styles = {
      containerStyle: {
        width: fullscreen ? '100%' : containerWidth ? containerWidth : 600,
        height: fullscreen ? '100%' : null
      },
      contentStyle: {
        minHeight: contentHeight ? contentHeight : 70
      },
      componentStyle: {
        padding: contentPadding ? contentPadding : (!fullscreen ? '0 24px 24px' : 0)
      }
    }

    const modalClass = getClassName('modal', [
      { condition: loading, trueClassName: 'modal--loading' },
      { condition: fullscreen, trueClassName: 'modal--fullscreen' },
      { condition: scrollable, trueClassName: 'modal--scrollable' },
      { condition: contentBackground, trueClassName: 'modal--content-background' },
      { condition: narrow, trueClassName: 'modal--narrow' }
    ])

    return (
      <Dialog
        className={`modal-root ${className || ''}`}
        classes={{ paper: modalClass }}
        data-id={dataId}
        open={!!visible}
        maxWidth={containerMaxWidth ? containerMaxWidth : 'sm'}
        disableEscapeKeyDown={disableEscapeKeyDown}
        disableAutoFocus={disableAutoFocus}
        disableRestoreFocus={disableRestoreFocus}
        onClose={onClose}
      >
        <div className='modal_wrap' style={styles.containerStyle}>
          {closeIcon && (!headerButtons || !headerButtons.length) && (
            <div className='modal_exit' onClick={onClose}>
              <i className='q4i-close-4pt' />
            </div>
          )}
          {(title || (headerButtons && headerButtons.length)) && (
            <div className={getClassName('modal_header', [
              { condition: headerSize, trueClassName: `modal_header--${headerSize}` }
            ])}>
              <div className='modal_title-container'>
                {badge && (
                  <div className='modal_badge'>
                    <i className={badge} />
                  </div>
                )}
                <h2 className='modal_title'>{title}</h2>
                {!!subtitle && (
                  <h3 className='modal_subtitle'>{subtitle}</h3>
                )}
              </div>
              {(headerButtons && headerButtons.length > 0) && (
                <div className='modal_header-actions'>
                  <div className='modal_actions modal_actions--left'>
                    {headerButtons.filter((button) => button.align === 'left').map(this.renderActionButton)}
                  </div>
                  <div className='modal_actions modal_actions--right'>
                    {headerButtons.filter((button) => button.align !== 'left').map(this.renderActionButton)}
                  </div>
                </div>
              )}
            </div>
          )}
          <div
            className={`modal_content ${(!footerButtons || !footerButtons.length) ? 'modal_content--footerless' : ''}`}
            style={styles.contentStyle}>
            {scrollable ? (
              <Scrollbars
                className='react-scrollbar'
                hideTracksWhenNotNeeded
                renderThumbVertical={renderDarkThumb}
                renderTrackVertical={renderTrackVertical}
                autoHeight
                autoHeightMin={autoHeightMin}
                autoHeightMax={autoHeightMax}>
                <div className='modal_component' style={styles.componentStyle}>{children}</div>
              </Scrollbars>
            ) : <div className='modal_component' style={styles.componentStyle}>{children}</div>}
            {loading ? (
              <Spinner
                theme='rain'
                mask={true}
                maskOpacity={0.5}
                maskColor='#FFF'
                {...spinnerProps}
              />
            ) : null}
          </div>
          {(footerButtons && footerButtons.length > 0) && (
            <footer className='modal_footer'>
              <div className='modal_actions modal_actions--left'>
                {footerButtons.filter((button) => button.align === 'left').map(this.renderActionButton)}
              </div>
              <div className='modal_actions modal_actions--right'>
                {footerButtons.filter((button) => button.align !== 'left').map(this.renderActionButton)}
              </div>
            </footer>
          )}
          {(((!footerButtons || !footerButtons.length) && scrollable) || includeChin) && (
            <div className='modal_chin' />
          )}
        </div>
      </Dialog>
    )
  }
}

Modal.propTypes = {
  className: PropTypes.string,
  dataId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  closeIcon: PropTypes.bool,
  narrow: PropTypes.bool,
  containerWidth: PropTypes.number,
  contentHeight: PropTypes.number,
  autoHeightMin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  autoHeightMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  containerMaxWidth: PropTypes.string,
  contentPadding: PropTypes.string,
  contentBackground: PropTypes.bool,
  scrollable: PropTypes.bool,
  loading: PropTypes.bool,
  spinnerProps: PropTypes.object,
  fullscreen: PropTypes.bool,
  includeChin: PropTypes.bool,
  headerSize: PropTypes.oneOf(['base', 'default']), // TODO: refactor modal as styles changed drastically
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  badge: PropTypes.string,
  footerButtons: PropTypes.array,
  headerButtons: PropTypes.array,
  disableEscapeKeyDown: PropTypes.bool,
  disableAutoFocus: PropTypes.bool,
  disableRestoreFocus: PropTypes.bool,
  onClose: PropTypes.func
}

Modal.defaultProps = {
  closeIcon: true,
  //Disable restoring focus on modal to fix IE11 issue with "focus" undefined
  disableRestoreFocus: true,
  autoHeightMax: '65vh'
}

export default withStyles(styles)(Modal)
