import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_TAGS = gql`
  query Tag(
    $entityId: String!
    $limit: Int
  ) {
    tag(
      entityId: $entityId
      limit: $limit
    ) {
      items {
        id
        name
      }
    }
  }`

/**
 * Tags Query
 * @param options
 */
export function useTagQuery (options) {
  return useQuery(GET_TAGS, options)
}
