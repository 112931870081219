import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { SectionSearch } from '../../../../components/shared'
import { Checkbox, Toolbar, ToolbarRow } from '../../../../components'
import { THEMES } from '../../../../utils'

const propTypes = {
  dataId: PropTypes.string,
  toolbarTheme: PropTypes.oneOf([THEMES.RAIN, THEMES.STEEL]),
  toolTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  search: PropTypes.string,
  filter: PropTypes.object,
  onQueryChange: PropTypes.func.isRequired
}

const defaultProps = {
  toolbarTheme: THEMES.RAIN,
  toolTheme: THEMES.Q4_BLUE,
  filter: {}
}

/**
 * Briefing Book Toolbar Component
 * @param props
 */
function BriefingBookToolbar (props) {
  const {
    dataId, toolbarTheme, toolTheme, search, filter, onQueryChange
  } = props
  const { activity, myBook } = filter

  const handleFilterChange = (value, type) => onQueryChange({ filter: { ...filter, [type]: value } })
  const handleSearchChange = (search) => onQueryChange({ search })

  return (
    <Toolbar theme={toolbarTheme}>
      <ToolbarRow justified>
        <div className='toolbar_group toolbar_group--spaced'>
          <Checkbox
            dataId={`${dataId}MyBook`}
            theme={toolTheme}
            id='myBook'
            label='Your Books'
            isSelected={myBook}
            onChange={(value) => handleFilterChange(value, 'myBook')}
          />
          <Checkbox
            dataId={`${dataId}FromActivity`}
            theme={toolTheme}
            id='fromActivity'
            label='From Activity'
            isSelected={activity}
            onChange={(value) => handleFilterChange(value, 'activity')}
          />
        </div>
        <div className='toolbar_group'>
          <SectionSearch
            dataId={`${dataId}Search`}
            theme={toolTheme}
            value={search}
            onQueryChange={handleSearchChange}
            onClear={handleSearchChange}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

BriefingBookToolbar.propTypes = propTypes
BriefingBookToolbar.defaultProps = defaultProps

export default memo(BriefingBookToolbar)
