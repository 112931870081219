import React, {PureComponent} from 'react';
import {Checkbox} from '../../../../shared';
import PropTypes from 'prop-types';

/**
 * Report Builder Choice Filter Component
 */
class ChoiceFilter extends PureComponent {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || []
        };
    }

    /**
     * Handle 'choice' type Filter select
     * @param remove
     * @param option
     */
    handleChoice = (remove, option) => {
        const {filterId, onChange} = this.props;
        const {value} = this.state;
        const newValue = [...value];

        if (remove) {
            newValue.splice(value.indexOf(option.value), 1);
        } else {
            newValue.push(option.value);
        }

        this.setState({
            value: newValue
        }, () => {
            onChange && onChange(filterId, {
                value: newValue
            });
        });
    };

    /**
     * Render Report Filter Component
     * @return {XML}
     */
    render() {
        const {filterId, options} = this.props;
        const {value} = this.state;

        return (
            <div className='choice-filter'>
                {options && options.map((option) => {
                    if (!option || !option.label || !option.value) {
                        return null;
                    }

                    const isChecked = value.indexOf(option.value) > -1;
                    const key = `choice-filter-${filterId}--${option._id}`;

                    return (
                        <Checkbox
                            key={key}
                            theme='rain'
                            label={option.label}
                            id={key}
                            isChecked={isChecked}
                            onChange={() => this.handleChoice(isChecked, option)}
                        />
                    );
                })}
            </div>
        );
    }
}

ChoiceFilter.propTypes = {
    filterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.array,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
};

export default ChoiceFilter;