import React from 'react'
import { format, formatDate, getClassName, inMillions } from '../../../../../../utils'

const EMPTY_PLACEHOLDER = '-'
const ZERO = '0.00'

/**
 * Quarter Data Component
 * @param value
 * @param metric
 */
const QuarterData = ({ value, metric }) => {
  const isChange = (metric || 'current').toLowerCase().includes('change')
  const className = getClassName('ag-cell-value', [
    { condition: (isChange && value), trueClassName: `ag-cell-value--${value > 0 ? 'increase' : 'decrease'}` }
  ])

  if (['current', 'qtrChange'].includes(metric)) {
    return (value || value === 0)
      ? <span className={className}>{format(value, 0)}</span>
      : EMPTY_PLACEHOLDER
  }

  return metric.includes('market')
    ? value ? <span className={className}>{inMillions(value, 2)}</span> : EMPTY_PLACEHOLDER
    : value
      ? <span className={className}>{Number(value).toLocaleString('en', {
        useGrouping: false,
        minimumFractionDigits: 2
      })}</span>
      : ZERO
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 * @param metric
 */
const CustomCell = ({ data = {}, column = {} }) => {
  const colId = column.colId
  const cellData = data[colId]

  switch (colId) {
    case 'securityName':
      return cellData || EMPTY_PLACEHOLDER
    case 'reportDate':
      return cellData ? formatDate(new Date(Number(cellData)), 'MM/DD/YY', true, true) : EMPTY_PLACEHOLDER
    case 'q0':
    case 'q1':
    case 'q2':
    case 'q3':
    case 'q4':
      return <QuarterData value={cellData} metric={(data.metric || 'current')} />
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
