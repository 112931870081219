import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// actions
import { useActivityQuery, useAttributeQuery, usePositionQuery } from '../../hook'

// components
import AttributeCard from './attribute.component'
import { ENTITY_TYPE, isTradingTicker } from '../../../../utils'
import { get } from 'lodash'

const { CONTACT, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  entity: PropTypes.object.isRequired,
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  tickerId: PropTypes.string.isRequired,
  securityId: PropTypes.string.isRequired,
  subscription: PropTypes.object.isRequired,
  institutionId: PropTypes.string,
  gdpr: PropTypes.bool
}

const defaultProps = {
  entity: {}
}

/**
 * Attribute Component
 * @param props
 */
function Attribute (props) {
  const { entity, entityId, entityType, tickerId, securityId, institutionId, subscription, gdpr } = props
  const id = [CONTACT].includes(entityType) ? institutionId : entityId
  const source = [CONTACT, INSTITUTION].includes(entityType) ? 'inst' : entityType
  const endDate = moment().tz('America/New_York').endOf('day').format()
  const { value = '13f', label = '13F' } = (subscription || {})

  const { data: position } = usePositionQuery(entityType, {
    skip: (!id || !tickerId) || !isTradingTicker(tickerId),
    variables: {
      tickerId,
      entityId: id,
      source: value
    }
  })

  const { data: attribute } = useAttributeQuery({
    skip: entityType === CONTACT,
    variables: {
      securityId,
      entityId,
      entityType
    }
  })

  const { data: activity } = useActivityQuery({
    variables: { entityId, endDate }
  })

  /**
   * Render Attributes Component
   */
  return (
    <AttributeCard
      data={{
        ...entity,
        position: get(position, `${source}HoldingCurrent.items[0].current`),
        purchasingPower: get(attribute, 'purchasingPower.items[0].purchasingPower'),
        aiScore: get(attribute, 'aiScore.items[0].score'),
        activity
      }}
      entityType={entityType}
      ownership={label}
      gdpr={gdpr}
    />
  )
}

Attribute.propTypes = propTypes
Attribute.defaultProps = defaultProps

export default Attribute
