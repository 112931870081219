import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// actions
import { getFund, statusType, GET_FUND_SUCCESS } from '../../actions'

// components
import { Spinner } from '../../components'
import FundPage from './fund.page'
import { THEMES } from '../../utils/ui'
import { get } from 'lodash'

/**
 * Fund Page Preload
 */
function FundPagePreload (props) {
  const { match, loading, getFund } = props
  const id = get(match, 'params.id')

  const [entity, setEntity] = useState(null)
  const [prevEntityId, setPrevEntityId] = useState(id)

  // Clear entityId from state if previous path id doesn't match current
  // Otherwise, if user is already viewing a fund and selects another from quick search,
  // page will display previous fund before fetching current fund
  useEffect(() => {
    if (!id) {
      return
    }

    if (prevEntityId !== id) {
      setEntity(null)
      setPrevEntityId(id)
    }
  }, [id, prevEntityId])

  useEffect(() => {
    if (!id) {
      return
    }

    getFund(id).then((data) => {
      const { type, payload = {} } = data

      if (type === GET_FUND_SUCCESS && payload.q4_entity_id) {
        setEntity(payload)
      }
    })
  }, [id, setEntity, getFund])

  return (
    <>
      {loading && <Spinner mask theme={THEMES.DARK} />}
      {(!loading && entity && entity._id) && (
        <FundPage
          entity={entity}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  loading: get(state, 'fund.detail.status') === statusType.IN_PROGRESS
})

const mapDispatchToProps = (dispatch) => ({
  getFund: bindActionCreators(getFund, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(FundPagePreload)
