import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { AgGrid } from '../../../../index'
import CustomCell from './cell/cell.component'
import { getQuartersEndDates, setColumnDefinition, formatLocalizedDate, DEFAULT_DATE_FORMAT } from '../../../../../utils'
import moment from 'moment-timezone'

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    securityId: PropTypes.string.isRequired,
    company_name: PropTypes.string.isRequired,
    latest: PropTypes.number,
    latest_date: PropTypes.string,
    qrt_date_1: PropTypes.number,
    qrt_date_2: PropTypes.number,
    qrt_date_3: PropTypes.number,
    qrt_date_4: PropTypes.number
  })),
  dataTotal: PropTypes.number,
  pageSizeId: PropTypes.string,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  data: [],
  dataTotal: 0,
  query: {}
}

class PeerAnalysisTable extends PureComponent {

  constructor (props) {
    super(props)
    const columns = this.getPeerHeader()
    this.state = {
      headerColumns: columns
    }
  }

  componentWillUnmount () {
    clearTimeout(this.interval)
  }

  getPeerHeader = () => {
    const today = moment.utc().endOf('quarter')
    const quaterDates = getQuartersEndDates(today.clone().subtract(1, 'years'), today, DEFAULT_DATE_FORMAT)
    const headerCol = [
      {
        field: 'company_name',
        headerName: 'Name',
        minWidth: 450,
        pinned: 'left',
        lockPinned: true,
        headerCheckboxSelection: false,
        checkboxSelection: false,
        sortable: false
      },
      {
        type: 'number',
        field: 'latest',
        headerName: 'LATEST',
        minWidth: 180,
        maxWidth: 220,
        sortable: false
      },
      {
        type: 'date',
        field: 'latest_date',
        headerName: 'LATEST DATE',
        minWidth: 120,
        maxWidth: 140,
        sortable: false
      },
      {
        type: 'number',
        field: 'qrt_date_1',
        headerName: formatLocalizedDate(quaterDates[1], DEFAULT_DATE_FORMAT),
        minWidth: 180,
        maxWidth: 220,
        sortable: false
      },
      {
        type: 'number',
        field: 'qrt_date_2',
        headerName: formatLocalizedDate(quaterDates[2], DEFAULT_DATE_FORMAT),
        minWidth: 180,
        maxWidth: 220,
        sortable: false
      },
      {
        type: 'number',
        field: 'qrt_date_3',
        headerName: formatLocalizedDate(quaterDates[3], DEFAULT_DATE_FORMAT),
        minWidth: 180,
        maxWidth: 220,
        sortable: false
      },
      {
        type: 'number',
        field: 'qrt_date_4',
        headerName: formatLocalizedDate(quaterDates[4], DEFAULT_DATE_FORMAT),
        minWidth: 120,
        maxWidth: 180,
        sortable: false
      }
    ]
    return setColumnDefinition({ columns: headerCol })
  }
  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  handleGridReady = (grid) => {
    this.interval = setTimeout(() => this.agGrid = grid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      this.interval = setTimeout(() => this.agGrid && this.agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click event
   * @param _id {String} - id
   */
  handleRowClicked = (colData) => {
    const securityId = colData.data.securityId
    securityId && this.props.history.push(`/security/${securityId}`)
  }

  /**
   * Renders CustomCell Component
   */
  renderCustomCell = (props) => {
    return (
      <CustomCell
        {...props}
      />
    )
  }

  render () {
    const { data, dataTotal, query, handleQueryChange, pageSizeId } = this.props
    const { headerColumns } = this.state

    return (
      <div className='peer-analysis_table'>
        <AgGrid
          className='peer-analysis-table'
          domLayout='autoHeight'
          sizeToFit={true}

          // suppress configs
          suppressMovableColumns
          suppressContextMenu
          enableSorting={false}

          // columns and data
          columnDefs={headerColumns}
          rowData={data}

          defaultColDef={{
            suppressMenu: true,
            cellRenderer: 'CustomCellRender'
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: this.renderCustomCell
          }}

          pagination
          paginationProps={{
            pageSizeId,
            forcePage: query.page,
            initialPageSize: query.limit,
            showPageSizeSelection: true,
            total: dataTotal,
            onPageChange: ({ selected: newPage }) => handleQueryChange({ page: newPage }),
            onPageSizeChange: ({ selected: newPageSize }) => handleQueryChange({ limit: newPageSize, page: 1 })
          }}

          onRowClicked={this.handleRowClicked}
          isPinned={data && data.length}
        />
      </div>)
  }
}

PeerAnalysisTable.propTypes = propTypes
PeerAnalysisTable.defaultProps = defaultProps

export default PeerAnalysisTable
