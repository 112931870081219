import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './formikTextArea.component.css'

/**
 * @deprecated - use TextArea instead
 */
class FormikTextArea extends Component {
  render () {
    const {
      id,
      className,
      placeholder,
      values,
      rows,
      errors,
      touched,
      handleChange,
      handleBlur
    } = this.props

    return (
      <div className='text-area'>
        {(errors[id] && touched[id]) ? <div className='tooltip tooltip--error tooltip--error--right tooltip--error_textarea--right'>{errors[id]}</div> : null}
        <textarea
          id={id}
          className={className}
          placeholder={placeholder}
          value={values[id]}
          rows={rows}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    )
  }
}

FormikTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number
}

FormikTextArea.defaultProps = {
  className: 'text-area_field',
  placeholder: '',
  rows: 6
}

export default FormikTextArea
