import pathToRegexp from 'path-to-regexp'
import senchaRoutes from '../data/routes/sencha.data'
import { crmEmailRoutes, manageEmailRoutes } from '../data/routes/crmEmail.data'

const PATH_TYPE = {
  sencha: 'senchaPath',
  react: 'reactPath',
  crmEmail: 'crmEmailPath',
  crmManageEmail: 'crmManageEmailPath'
}

/**
 * Lookup and convert react path to sencha path
 * @param path
 * @return {string}
 */
export const reactToSenchaPath = (path) => {
  return translatePath(path, PATH_TYPE.react, PATH_TYPE.sencha, senchaRoutes)
}

/**
 * Lookup and convert react path to crm email path
 * @param {string} path
 * @return {string}
 */
export const reactToCrmEmailPath = (path) => {
  return translatePath(path, PATH_TYPE.react, PATH_TYPE.crmEmail, crmEmailRoutes)
}

/**
 * Lookup and convert react path to crm manage email path
 * @param {string} path
 * @return {string}
 */
export const reactToCrmManageEmailPath = (path) => {
  return translatePath(path, PATH_TYPE.react, PATH_TYPE.crmManageEmail, manageEmailRoutes)
}
/**
 * Lookup and convert sencha path to react path
 * @param path
 * @return {string}
 */
export const senchaToReactPath = (path) => {
  return translatePath(path, PATH_TYPE.sencha, PATH_TYPE.react, senchaRoutes)
}

/**
 * Lookup and convert crm email path to react path
 * @param path
 * @return {string}
 */
export const crmEmailToReactPath = (path) => {
  return translatePath(path, PATH_TYPE.crmEmail, PATH_TYPE.react, crmEmailRoutes)
}

/**
 * Given requested path (sencha/react), convert to target path type with params
 * @param path - requested path (i.e. '/activity/5addedeaa03a8f0004dcdfe5')
 * @param sourcePathType - 'react' | 'sencha'
 * @param targetPathType - 'react' | 'sencha'
 * @return {string}
 * @private
 */
export const translatePath = (path, sourcePathType, targetPathType, routes) => {
  let regexMatchResult

  const matchedRoute = routes.find((route) => {
    const re = pathToRegexp(route[sourcePathType])
    regexMatchResult = re.exec(path)
    return regexMatchResult
  })

  if (!matchedRoute) {
    return ''
  }

  // Get route tokens, so we can extract any param names
  const pathTokens = pathToRegexp.parse(matchedRoute[sourcePathType])

  // Create key/value of prop names with values from requested path
  const pathParams = pathTokens.reduce((acc, token, index) => {
    const propName = token.name
    if (propName) {
      acc[propName] = regexMatchResult[index++]
    }
    return acc
  }, {})

  const toPath = pathToRegexp.compile(matchedRoute[targetPathType])
  return toPath(pathParams)
}
