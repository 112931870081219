import React, {PureComponent} from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import {getClassName, THEMES} from '../../utils/ui';

import './miniCalendar.component.css';

/**
 * MiniCalendar
 */
class MiniCalendar extends PureComponent {

    /**
     * Format Day of Week - defaults to M, T, W... etc.
     * @param date
     * @returns {string}
     */
    formatDayOfWeek = (date) => {
        const {formatDayOfWeek} = this.props;

        if (formatDayOfWeek) {
            return formatDayOfWeek(date);
        }

        return moment(date).format('dd').slice(0, 1);
    };

    /**
     * Render Date Picker
     * @returns {*}
     */
    render() {
        const {className, date, onDayClick, onNavigationChange, onTileRender, theme} = this.props;

        const classes = getClassName('mini-calendar', [
            {condition: className, trueClassName: className},
            {condition: theme, trueClassName: `mini-calendar--${theme}`},
        ]);

       return (
            <Calendar
               className={classes}
               onClickDay={onDayClick}
               onActiveDateChange={onNavigationChange}
               formatShortWeekday={this.formatDayOfWeek}
               value={date}
               calendarType='US'
               tileContent={onTileRender}
               nextLabel={''}
               prevLabel={''}
            />
       );
    }
}

MiniCalendar.propTypes = {
    className: PropTypes.string,
    theme: PropTypes.oneOf([THEMES.LIGHT_SLATE]),
    date: PropTypes.any,
    onDayClick: PropTypes.func,
    onNavigationChange: PropTypes.func,
    formatDayOfWeek: PropTypes.func,
    onTileRender: PropTypes.func
};

MiniCalendar.defaultProps = {
    theme: THEMES.LIGHT_SLATE,
    date: new Date()
};

export default MiniCalendar;