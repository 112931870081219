import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Sidebar, Button } from '../../../../components'
import { THEMES } from '../../../../utils'
import { DateFilter, EventTypeFilter, SecurityFilter } from './filters/components'

import './sidebar.component.scss'
import './filters/filter.component.scss'

/**
 * Sidebar Header
 * @return {*}
 * @constructor
 */
const Header = ({ onResetClick, modified }) => {
  return (
    <>
      <div className='sidebar_header-title'>Filters</div>
      <Button
        disabled={!modified}
        className='button create-new-list button--soft-grey'
        onClick={onResetClick}
        label='Reset'
      />
    </>
  )
}

/**
 * Events & Transcripts Page
 * @param props
 */
function EventsTranscriptsSidebar (props) {
  const [errors, setErrors] = useState({})
  const { collapsed, canReset, canApply, onResetFilter, onApplyFilter } = props

  const hasErrors = !!Object.keys(errors).find((inputKey) => {
    return errors[inputKey]
  })

  /**
   * On Error
   * @param id
   * @param error
   */
  const onError = (id, error) => {
    setErrors({
      ...(errors || {}),
      [id]: error
    })
  }

  /**
   * Reset filters and reset error state
   */
  const resetFilters = () => {
    onResetFilter()
    setErrors({})
  }

  return (
    <Sidebar
      className='eventsTranscripts-page_sidebar sidebar--tabs'
      collapsed={collapsed}
      headerTemplate={
        <Header onResetClick={resetFilters} modified={canReset} />
      }
      footerButtons={[
        {
          disabled: !canApply || hasErrors,
          label: 'Apply',
          onClick: onApplyFilter,
          theme: THEMES.CITRUS
        }
      ]}
    >
      <DateFilter {...props} errors={errors.date_picker} onError={onError} />
      <SecurityFilter {...props} />
      <EventTypeFilter {...props} />
    </Sidebar>
  )
}

EventsTranscriptsSidebar.propTypes = {
  collapsed: PropTypes.bool,
  canReset: PropTypes.bool,
  canApply: PropTypes.bool,
  onResetFilter: PropTypes.func.isRequired,
  onApplyFilter: PropTypes.func.isRequired,
  filters: PropTypes.object,
  securitySuggestions: PropTypes.array,
  handleFilterChange: PropTypes.func.isRequired,
  searchSecurities: PropTypes.func.isRequired,
  resetSecuritySuggestions: PropTypes.func.isRequired,
  isSearchSecuritiesLoading: PropTypes.bool
}

EventsTranscriptsSidebar.defaultProps = {
  collapsed: false,
  canReset: false,
  canApply: false,
  filters: {},
  securitySuggestions: [],
  isSearchSecuritiesLoading: false
}

export default EventsTranscriptsSidebar
