/**
 * UI Action Type Constants
 * @type {string}
 */
export const SET_APP_FULLSCREEN = 'SET_APP_FULLSCREEN'
export const SET_SENCHA_FULLSCREEN = 'SET_SENCHA_FULLSCREEN'

/**
 * Set Sencha fullscreen state
 * @param bool
 */
export const setSenchaFullscreen = (bool) => ({
  type: SET_SENCHA_FULLSCREEN,
  payload: bool
})

/**
 * Set App's fullscreen state
 * @param bool
 * @private
 */
export const _setAppFullscreen = (bool) => ({
  type: SET_APP_FULLSCREEN,
  payload: bool
})

/**
 * Dispatch SET_APP_FULLSCREEN
 * @param value
 */
export const setAppFullscreen = (value) => (dispatch) => {
  return dispatch(_setAppFullscreen(!!value))
}
