module.exports = {
  /**
     * Assign default classes names for widgets
     * @param name
     * @param layout
     * @param theme
     * @returns {{base: string, items: string, item: string, footer: string}}
     */
  setClassNames: (name, layout, theme) => {
    if (!layout) {
      return {}
    }

    const itemClasses = (layout.w > 1) ? [`${name}_item`] : [`${name}_item ${name}_item--narrow`]
    const baseClasses = (layout.w === 1) ? [
      `${name} ${name}--${theme} ${name}--narrow`
    ] : [`${name} ${name}--${theme}`]

    if (layout.h >= 3) {
      baseClasses.push(`${name}--large`)
    }

    itemClasses.push('q4-fade-in')

    return {
      name,
      base: baseClasses.join(' '),
      items: (layout.w === 1) ? `${name}_items ${name}_items--tall` : `${name}_items`,
      item: itemClasses.join(' '),
      header: (layout.w === 1) ? `${name}_header ${name}_header--small` : `${name}_header`,
      footer: (layout.w === 1) ? `${name}_footer ${name}_footer--small` : `${name}_footer`
    }
  },

  WEB_ANALYTICS_INSTITUTION_WIDGET:  {
    id: 15,
    title: "Web Analytics (Institution)"
  },
  WEB_ANALYTICS_DOWNLOAD_WIDGET:  {
    id: 16,
    title: "Web Analytics (Downloads)"
  }, 
}
