import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, PeerInput } from '../index'
import UtilityMenu from '../utilityMenu/utilityMenu.component'
import withOptions from '../utilityMenu/helper/withOptions'
import { getClassName } from '../../utils'

import './banner.component.css'

const UtilityMenuWithOptions = withOptions(UtilityMenu)

/**
 * Banner Component
 */
class Banner extends Component {
  /**
   * Render Banner Controls
   * @param control
   * @param index
   */
  renderControl (control, index) {
    switch (control.type) {
      case 'button':
        return this.renderBannerButton(control, index)
      case 'score':
        return <div className='banner_controls-score' key={`score-${index}`}>{control.ScoreComponent}</div>
      case 'utility':
        return <UtilityMenuWithOptions key={index} {...control} />
      case 'addPeer':
        return <PeerInput key={`addPeer-${index}`} onAddSuccess={control.handleAddSuccess} currentPeers={control.peers}/>
      default:
        return null
    }
  }

  /**
   * Render Banner Button
   * @param button
   * @param index
   */
  renderBannerButton (button, index) {
    const { size } = this.props
    const { dataId, theme, icon, label, tooltip, square, invisible, hidden, loading, disabled, reference, onClick, className } = button

    return (
      <Button
        className={className}
        key={`banner-button--${index}`}
        reference={reference}
        dataId={dataId}
        theme={theme}
        tall={size === 'thin'}
        square={square}
        loading={loading}
        invisible={invisible}
        hidden={hidden}
        disabled={disabled}
        icon={icon}
        label={label}
        onClick={onClick}
        tooltip={tooltip}
      />
    )
  }

  /**
   * Render Disclosure
   * @param disclosure
   * @returns {null}
   */
  renderDisclosure (disclosure) {
    const { type, theme, label, onClick } = disclosure
    switch (type) {
      case 'button':
        return (
          <div className={`banner_disclosure ${theme ? `banner_disclosure--${theme}` : ''}`} onClick={onClick}>
            <span className='banner_disclosure-label'>{label}</span>
            <i className='banner_disclosure-icon q4i-info-fill' />
          </div>
        )
      case 'info':
        return (<div className='button--info' onClick={onClick} />)
      default:
        return null
    }
  }

  /**
   * Render banner
   * @returns {*}
   */
  render () {
    const { dataId, theme, badgeType, size, icon, title, details, disclosure, controls, children, alignChildrenWithBadge } = this.props

    const baseClassName = getClassName('banner', [
      { condition: size, trueClassName: `banner--${size}` },
      { condition: details, trueClassName: 'banner--detailed' },
      { condition: theme, trueClassName: `banner--${theme}` }
    ])

    const badgeClassName = getClassName('banner_badge', [
      { condition: badgeType, trueClassName: `banner_badge--${badgeType}` }
    ])

    return (
      <header className={baseClassName}>
        <div className='banner_inner' data-id={dataId}>
          <div className='banner_elements'>
            <div className='banner_header'>
              {icon && <i className={`${badgeClassName} ${icon}`} />}
              <div className='banner_information'>
                <h1 className='banner_title'>
                  {title}
                </h1>
                {disclosure && this.renderDisclosure(disclosure)}
                {details && (
                  <div className='banner_details'>{details}</div>
                )}
              </div>
            </div>
            <div className='banner_controls'>
              {(controls || []).map((control, index) => this.renderControl(control, index))}
            </div>
          </div>
          {children && (
            <div className={`banner_component ${alignChildrenWithBadge ? 'banner_component--badge-aligned' : ''}`}>
              {children}
            </div>
          )}
        </div>
      </header>
    )
  }
}

Banner.propTypes = {
  /**
   * Used for tracking purposes
   */
  dataId: PropTypes.string,

  /**
   * Used to indicate the size of the banner
   */
  size: PropTypes.oneOf(['default', 'thin', 'small', 'medium']),

  /**
   * The icon class used to power the Banner component's badge
   */
  icon: PropTypes.string.isRequired,

  /**
   * The title to display in the Banner component
   */
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,

  /**
   * Used to provide additional details below the Banner component's title
   */
  details: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

  /**
   * An array of controls component to render within the Banner component
   */
  controls: PropTypes.array,

  /**
   * Used to determine whether or not the Banner component's children elements will be aligned with the badge
   */
  alignChildrenWithBadge: PropTypes.bool,

  /**
   * Additional information
   */
  disclosure: PropTypes.object
}

Banner.defaultProps = {
  size: 'default'
}

export default Banner
