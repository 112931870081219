import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

export const GET_INSTITUTION_CURRENT_HOLDINGS_REQUEST = 'GET_INSTITUTION_CURRENT_HOLDINGS_REQUEST'
export const GET_INSTITUTION_CURRENT_HOLDINGS_SUCCESS = 'GET_INSTITUTION_CURRENT_HOLDINGS_SUCCESS'
export const GET_INSTITUTION_CURRENT_HOLDINGS_FAILURE = 'GET_INSTITUTION_CURRENT_HOLDINGS_FAILURE'

/**
 * Get Institution Current Holdings
 * @param entityId
 * @param options
 * @returns {{}}
 * @private
 */
const _fetchCurrentHoldingsForInstitution = (entityId, params) => ({
  [CALL_API]: {
    types: [
      GET_INSTITUTION_CURRENT_HOLDINGS_REQUEST,
      GET_INSTITUTION_CURRENT_HOLDINGS_SUCCESS,
      GET_INSTITUTION_CURRENT_HOLDINGS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/ownership/v2/institution/${entityId}/current`,
    payload: params,
    queryOptions: { arrayFormat: 'index' }
  }
})

/**
 * Get Institution Current Holdings
 * @param entityId
 * @param options
 * @returns {function(*): *}
 */
export const fetchCurrentHoldingsForInstitution = (entityId, params) => (dispatch) => {
  return dispatch(_fetchCurrentHoldingsForInstitution(entityId, params))
}
