import React, { Component } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import qs from 'querystring'
import history from '../../utils/history'

// components
import ItineraryPage from '../../pages/activity/itinerary/itinerary.page'
import BriefingBookExportPage from '../../pages/briefingBook/export/export.page'

import { isEmpty, get } from 'lodash'

/**
 * Token Route
 * @param props
 */
const TokenRoute = ({ component: Component, headless, ...rest }) => {
  const queryParams = qs.parse(history.location.search.replace(/^\?*/, ''))
  const token = get(queryParams, 'token', null)

  const tokenValid = !isEmpty(token)

  return (
    <Route
      {...rest}
      render={
        (props) => {
          return (tokenValid) ? (<Component {...props} />) : (<Redirect to='/error/403' />)
        }
      }
    />
  )
}

class ExportRoot extends Component {
  /**
   * Render
   * @returns {XML}
   */
  render () {
    return (
      <Switch>
        <TokenRoute exact path='/activity/:id/itinerary' component={ItineraryPage} />
        <TokenRoute exact path='/tearsheet/export' component={BriefingBookExportPage} />
      </Switch>
    )
  }
}

export default ExportRoot
