import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { combine, formatDate, getLocalizedFormat } from '../../../../../utils'

import './cell.component.css'

const EMPTY_PLACEHOLDER = '-'
const DATE_FORMAT = getLocalizedFormat('MM/DD/YYYY')

/**
 * Title Cell Component
 * @param data
 * @param position
 * @returns {*}
 * @constructor
 */
const TitleCell = ({ data, position }) => {
  const isTarget = Array.isArray(data._target) && data._target.length
  const isDeal = Array.isArray(data._deal) && data._deal.length

  return <div className='cell cell--title'>
    <div className='cell_detail'>
      <div className='cell_detail-name'>{data.full_name}</div>
      {position ? <div className='cell_detail-position'>{position}</div> : null}
    </div>
    {isTarget ? <i className='cell_icon cell_icon--target q4i-targeting-4pt' /> : null}
    {isDeal ? <i className='cell_icon cell_icon--deal q4i-deal-4pt' /> : null}
  </div>
}

/**
 * Email Custom Cell Component
 * @param {object} props
 * @param {object} props.contact
 * @param {string} props.email
 * @param {function} props.handleEmailClick
 * @returns {JSX.Element}
 */
const EmailCell = (props) => {
  const { contact, handleEmailClick, email } = props

  if (email) {
    return (
      <div className='cell cell--email'>
        <div onClick={handleEmailClick.bind(null, contact)}>
          <i className='cell_icon cell_icon--email q4i-mail-2pt' />
        </div>
      </div>
    )
  } else {
    return <div className='cell cell--email'>{EMPTY_PLACEHOLDER}</div>
  }
}

EmailCell.propTypes = {
  contact: PropTypes.object,
  email: PropTypes.string,
  handleEmailClick: PropTypes.func
}

/**
 * Custom Cell Component
 * @param data
 * @param column
 * @param onExpandMenuClick
 * @returns {*}
 * @constructor
 */
const CustomCell = (props) => {
  const { data = {}, column = {} } = props
  const job = get(data, 'jobs[0]') || {}
  const institutionName = get(job, 'institution_name', '')
  switch (column.colId) {
    case 'full_name':
      return <TitleCell data={data} position={job.title} />
    case 'jobs.institution_name':
      return institutionName || EMPTY_PLACEHOLDER
    case 'type':
      return data.type
    case 'jobs.city':
      return combine([job.city, job.country]) || EMPTY_PLACEHOLDER
    case 'jobs.functions':
      return combine(job.functions) || EMPTY_PLACEHOLDER
    case 'phone':
      return job.direct_telephone || job.phone || EMPTY_PLACEHOLDER
    case 'email': {
      return (
        <EmailCell
          contact={data}
          email={job.email}
          handleEmailClick={props.handleEmailClick}
        />
      )
    }
    case 'activity_date': {
      return data.activity_date ? formatDate(data.activity_date, DATE_FORMAT) : EMPTY_PLACEHOLDER
    }
    default: {
      return data[column.colId] || EMPTY_PLACEHOLDER
    }
  }
}

export default CustomCell
