import gql from 'graphql-tag'
import { get } from 'lodash'
import client from '../../middleware/graphql.middleware'
import { fetchContact } from './detail/contact.actions'
import { fetchCorporateParticipant } from '../corporateParticipant/corporateParticipant.actions'
import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { NEW_CONTACT_INSTITUTION_SEARCH_OPTIONS, CONTACT_SUGGESTIONS_SEARCH_OPTIONS } from '../../utils'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../services/launchDarkly.service'

export const GET_CONTACT_EDIT_REQUEST = 'GET_CONTACT_EDIT_REQUEST'
export const GET_CONTACT_EDIT_SUCCESS = 'GET_CONTACT_EDIT_SUCCESS'
export const GET_CONTACT_EDIT_FAILURE = 'GET_CONTACT_EDIT_FAILURE'
export const GET_CORPORATE_PARTICIPANT_EDIT_REQUEST = 'GET_CORPORATE_PARTICIPANT_EDIT_REQUEST'
export const GET_CORPORATE_PARTICIPANT_EDIT_SUCCESS = 'GET_CORPORATE_PARTICIPANT_EDIT_SUCCESS'
export const GET_CORPORATE_PARTICIPANT_EDIT_FAILURE = 'GET_CORPORATE_PARTICIPANT_EDIT_FAILURE'
export const CREATE_CONTACT_REQUEST = 'CREATE_CONTACT_REQUEST'
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS'
export const CREATE_CONTACT_FAILURE = 'CREATE_CONTACT_FAILURE'
export const UPDATE_CONTACT_REQUEST = 'UPDATE_CONTACT_REQUEST'
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS'
export const UPDATE_CONTACT_FAILURE = 'UPDATE_CONTACT_FAILURE'
export const CREATE_CORPORATE_PARTICIPANT_REQUEST = 'CREATE_CORPORATE_PARTICIPANT_REQUEST'
export const CREATE_CORPORATE_PARTICIPANT_SUCCESS = 'CREATE_CORPORATE_PARTICIPANT_SUCCESS'
export const CREATE_CORPORATE_PARTICIPANT_FAILURE = 'CREATE_CORPORATE_PARTICIPANT_FAILURE'
export const UPDATE_CORPORATE_PARTICIPANT_REQUEST = 'UPDATE_CORPORATE_PARTICIPANT_REQUEST'
export const UPDATE_CORPORATE_PARTICIPANT_SUCCESS = 'UPDATE_CORPORATE_PARTICIPANT_SUCCESS'
export const UPDATE_CORPORATE_PARTICIPANT_FAILURE = 'UPDATE_CORPORATE_PARTICIPANT_FAILURE'
export const SEARCH_INSTITUTION_REQUEST = 'SEARCH_INSTITUTION_REQUEST'
export const SEARCH_INSTITUTION_SUCCESS = 'SEARCH_INSTITUTION_SUCCESS'
export const SEARCH_INSTITUTION_FAILURE = 'SEARCH_INSTITUTION_FAILURE'
export const SEARCH_INSTITUTION_REQUEST_GQL = 'SEARCH_INSTITUTION_REQUEST_GQL'
export const SEARCH_INSTITUTION_SUCCESS_GQL = 'SEARCH_INSTITUTION_SUCCESS_GQL'
export const SEARCH_INSTITUTION_FAILURE_GQL = 'SEARCH_INSTITUTION_FAILURE_GQL'
export const GET_CONTACT_SUGGESTIONS_REQUEST = 'GET_CONTACT_SUGGESTIONS_REQUEST'
export const GET_CONTACT_SUGGESTIONS_SUCCESS = 'GET_CONTACT_SUGGESTIONS_SUCCESS'
export const GET_CONTACT_SUGGESTIONS_FAILURE = 'GET_CONTACT_SUGGESTIONS_FAILURE'
export const GET_CONTACT_SUGGESTIONS_REQUEST_GQL = 'GET_CONTACT_SUGGESTIONS_REQUEST_GQL'
export const GET_CONTACT_SUGGESTIONS_FAILURE_GQL = 'GET_CONTACT_SUGGESTIONS_FAILURE_GQL'
export const RESET_CONTACT_SUGGESTIONS = 'RESET_CONTACT_SUGGESTIONS'
export const DISMISS_ERROR = 'DISMISS_ERROR'
export const RESET_FORM = 'RESET_FORM'
export const RESET_SUGGESTIONS = 'RESET_SUGGESTIONS'


/**
 * CreateContact
 * @param data
 * @param params
 * @private
 */
const _createContact = (data, params) => ({
  [CALL_API]: {
    types: [
      CREATE_CONTACT_REQUEST,
      CREATE_CONTACT_SUCCESS,
      CREATE_CONTACT_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: `/contact${params.request_profile ? '?request_profile=true' : ''}`,
    payload: data
  }
})

/**
 * UpdateContact
 * @param data
 * @private
 */
const _updateContact = (id, data, params) => ({
  [CALL_API]: {
    types: [
      UPDATE_CONTACT_REQUEST,
      UPDATE_CONTACT_SUCCESS,
      UPDATE_CONTACT_FAILURE
    ],
    method: METHOD_TYPE.PUT,
    endpoint: `/contact/${id}${params.request_profile ? '?request_profile=true' : ''}`,
    payload: data
  }
})

/**
 * Create Corporate Participant
 * @param data
 * @private
 */
const _createCorporateParticipant = (data) => ({
  [CALL_API]: {
    types: [
      CREATE_CORPORATE_PARTICIPANT_REQUEST,
      CREATE_CORPORATE_PARTICIPANT_SUCCESS,
      CREATE_CORPORATE_PARTICIPANT_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/corporateparticipant',
    payload: data
  }
})

/**
 * Update Corporate Participant
 * @param data
 * @private
 */
const _updateCorporateParticipant = (id, data) => ({
  [CALL_API]: {
    types: [
      UPDATE_CORPORATE_PARTICIPANT_REQUEST,
      UPDATE_CORPORATE_PARTICIPANT_SUCCESS,
      UPDATE_CORPORATE_PARTICIPANT_FAILURE
    ],
    method: METHOD_TYPE.PUT,
    endpoint: `/corporateparticipant/${id}`,
    payload: data
  }
})

/**
 * Institution Search
 * @param query
 * @private
 */
const _searchInstitutionGQL = (query) => (dispatch) => {
  dispatch({ type: SEARCH_INSTITUTION_REQUEST_GQL })
  return client
    .query({
      query: ADVANCED_SEARCH_INST_QUERY,
      variables: {
        query,
        securityId: '',
        entity: [NEW_CONTACT_INSTITUTION_SEARCH_OPTIONS.entityType],
        page: NEW_CONTACT_INSTITUTION_SEARCH_OPTIONS.page,
        limit: NEW_CONTACT_INSTITUTION_SEARCH_OPTIONS.limit,
        useElasticSearch: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)
      },
      fetchPolicy: 'no-cache'
    })
    .then((data) => {
      const searchResults = get(data, 'data.advancedSearch.items')
      dispatch({
        payload: searchResults,
        type: SEARCH_INSTITUTION_SUCCESS_GQL,
      })
    })
    .catch((error) => {
      dispatch({ error, type: SEARCH_INSTITUTION_FAILURE_GQL })
    })
}

/**
 * Contact Suggestions GQL
 * @param query
 * @private
 */
const _getContactSuggestionsGQL = (query) => (dispatch) => {
  dispatch({ type: GET_CONTACT_SUGGESTIONS_REQUEST_GQL })
  return client
    .query({
      query: QUICK_SEARCH_CONTACT_QUERY, 
      variables: {
        query,
        entity: [CONTACT_SUGGESTIONS_SEARCH_OPTIONS.entityType],
        page: CONTACT_SUGGESTIONS_SEARCH_OPTIONS.page,
        limit: CONTACT_SUGGESTIONS_SEARCH_OPTIONS.limit,
        useElasticSearch: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)
      },
      fetchPolicy: 'no-cache'
    })
    .then((data) => {
      const searchResults = get(data, 'data.quickSearch.items')
      dispatch({
        payload: searchResults,
        type: GET_CONTACT_SUGGESTIONS_SUCCESS,
      })
    })
    .catch((error) => {
      dispatch({ error, type: GET_CONTACT_SUGGESTIONS_FAILURE_GQL })
    })
}

/**
 * Check if job object has any values
 * @param job
 * @return {boolean}
 */
const hasJobValues = (job) => {
  return !Object.values(job).every((val) => !val)
}

/**
 * Convert contact form values to model expected from API
 * @param data
 * @return {}
 * @private
 */
const _createContactPayload = (data) => {
  const job = {
    address1: data.street,
    city: data.city,
    postal_code: data.zip,
    state_province: data.state,
    city_state_zip: [].concat(data.city, data.state, data.zip).filter((x) => x).join(', ').trim(),
    country_name: data.countryName,
    country: data.countryName && data.country,
    direct_telephone: data.phone,
    email: data.email,
    factset_entity_id: data.factsetEntityId,
    q4_entity_id: data.q4EntityId,
    institution_name: data.institutionName,
    institution_type: data.institutionType,
    phone: data.corporatePhone,
    title: data.jobTitle
  }

  if (data.institutionId) {
    job._id = data.institutionId
  }

  return {
    first_name: data.firstName,
    last_name: data.lastName,
    nickname: data.nickName,
    full_name: `${data.firstName} ${data.lastName}`,
    mobile: data.mobile,
    jobs: hasJobValues(job) ? [job] : [],
    bio: data.bio
  }
}

/**
 * Convert corp participant form values to model expected from API
 * @param data
 * @return {}
 * @private
 */
const _createParticipantPayload = (data) => {
  const job = {
    direct_telephone: data.phone,
    email: data.email,
    title: data.jobTitle
  }

  return {
    first_name: data.firstName,
    last_name: data.lastName,
    nickname: data.nickName,
    full_name: `${data.firstName} ${data.lastName}`,
    mobile: data.mobile,
    jobs: hasJobValues(job) ? [job] : []
  }
}

/**
 * Dispatch CREATE_CONTACT_REQUEST
 * @param data
 */
export const createContact = (data) => (dispatch) => {
  const payload = _createContactPayload(data)
  return dispatch(_createContact(payload, {
    request_profile: data.requestProfile
  }))
}

/**
 * Dispatch UPDATE_CONTACT_REQUEST
 * @param id
 * @param data
 * @returns {function(*)}
 */
export const updateContact = (id, data) => (dispatch) => {
  const payload = _createContactPayload(data)
  return dispatch(_updateContact(id, payload, {
    request_profile: data.requestProfile
  }))
}

/**
 * Dispatch CREATE_CORPORATE_PARTICIPANT_REQUEST
 * @param data
 */
export const createCorporateParticipant = (data) => (dispatch) => {
  const payload = _createParticipantPayload(data)
  return dispatch(_createCorporateParticipant(payload))
}

/**
 * Dispatch UPDATE_CORPORATE_PARTICIPANT_REQUEST
 * @param id
 * @param data
 * @returns {function(*)}
 */
export const updateCorporateParticipant = (id, data) => (dispatch) => {
  const payload = _createParticipantPayload(data)
  return dispatch(_updateCorporateParticipant(id, payload))
}

/**
 * Dismiss Error Message
 * @param bool
 */
export const dismissError = () => (dispatch) => {
  return dispatch({ type: DISMISS_ERROR })
}

/**
 * Reset Form - resets reducer back to initial state
 * @param bool
 */
export const resetForm = () => (dispatch) => {
  return dispatch({ type: RESET_FORM })
}

/**
 * Get Contact to Edit
 * Uses contact action with custom action types
 * @param id
 */
export const fetchEditContact = (id) => (dispatch) => {
  return dispatch(fetchContact(id, {
    types: [
      GET_CONTACT_EDIT_REQUEST,
      GET_CONTACT_EDIT_SUCCESS,
      GET_CONTACT_EDIT_FAILURE
    ]
  }))
}

/**
 * Get Corporate Participant to Edit
 * Uses contact action with custom action types
 * @param id
 */
export const fetchEditCorporateParticipant = (id) => (dispatch) => {
  return dispatch(fetchCorporateParticipant(id, {
    types: [
      GET_CONTACT_EDIT_REQUEST,
      GET_CONTACT_EDIT_SUCCESS,
      GET_CONTACT_EDIT_FAILURE
    ]
  }))
}

/**
 * Dispatch SEARCH_INSTITUTION_REQUEST
 * @param data
 */
export const searchInstitution = (query) => (dispatch) => {
  return dispatch(_searchInstitutionGQL(query))
}

/**
 * Dispatch GET_CONTACT_SUGGESTIONS_REQUEST
 * @param query
 */
export const getContactSuggestions = (query) => (dispatch) => {
    return dispatch(_getContactSuggestionsGQL(query))
}

/**
 * Clears contact suggestions
 */
export const clearContactSuggestions = () => (dispatch) => {
  return dispatch({ type: RESET_CONTACT_SUGGESTIONS })
}

/**
 * Clears institution suggestions
 */
export const clearSuggestions = () => (dispatch) => {
  return dispatch({ type: RESET_SUGGESTIONS })
}

/**
 * QUICK_SEARCH GraphQL query for contact search
 */
const QUICK_SEARCH_CONTACT_QUERY = gql`
  query QuickSearch(
    $query: String,
    $entity: [String],
    $page: Int,
    $limit: Int,
    $useElasticSearch: Boolean,
  ) {
    quickSearch(
      query: $query,
      entity: $entity,
      page: $page,
      limit: $limit,
      useElasticSearch: $useElasticSearch,
    ) {
      items {
        id
        _id
        score
        source_id
        type
        name
        institution
        q4_entity_id
        title
        phone
        email
        address
        source
        country
        country_name
        country_code
        location_city
        region_name
      }
      count
    }
  }
`

/**
 * ADVANCED_SEARCH GraphQL query for institution search
 */
const ADVANCED_SEARCH_INST_QUERY = gql`
  query AdvanceSearch(
    $entity: [String]!,
    $query: String,
    $securityId: [String]!,
    $filter: SearchFilter,
    $page: Int, 
    $limit: Int,
    $useElasticSearch: Boolean
  ) {
    advancedSearch(
      entity: $entity,
      query: $query,
      filter: $filter,
      page: $page,
      limit: $limit,
      useElasticSearch: $useElasticSearch
    ) {
      items {
        id
        score
        entityConnection(securityId: $securityId){
          ... on InstitutionSearchDTO {
            type
            sourceId
            institutionName
            country
            entity {
              id
              addressConnection{
                items{
                  addressLine1
                  addressLine2
                  addressLine3
                  city
                  stateProvinceCode
                  fax
                  countryCode
                  countryName
                  postalCode
                  region
                  phone
                  hq
                }
              }
              institutionType
              turnover
              style
              totalAUM
              equityAUM
              institutionType
            }
          }    
        }
      }
    }
  }
`
