import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'

// actions
import { openModal } from '../../../../../../actions'
import { useDispatchAction } from '../../../../../../hook'
import { useFundTargetQuery } from './hook/fundTargetQuery.hook'

// components
import { ActivityGrid, ContactGrid, CurrentHoldingGrid, HistoricalHoldingGrid, PeerAnalysisGrid, Spinner, Swapable } from '../../../../../../components'
import { SectionTab } from '../../../../../../components/shared'
import FundBanner from './banner/banner.component'
import FundOverview from './overview/overview.component'

import { ENTITY_TYPE, ROUTE_PATH, ROUTE_PATH_ID_LABEL, THEMES, mapRoutePath } from '../../../../../../utils'
import { DATA_IDS } from '../../../../../../tests-e2e/pages/fund/fund.definition'

const dataIdPrefix = 'FundFlyout'

const propTypes = {}
const defaultProps = {
  theme: THEMES.LIGHT
}

const { FUND } = ENTITY_TYPE
const TABS = {
  CONTACT: 'contact',
  ACTIVITY: 'activity',
  CURRENT: 'current',
  PEER: 'peer'
}

const {
  CONTACT_TAB,
  CONTACT_GRID,
  ACTIVITY_TAB,
  ACTIVITY_GRID,
  CURRENT_HOLDING_TAB,
  CURRENT_HOLDING_GRID,
  HISTORICAL_HOLDING_TAB,
  HISTORICAL_HOLDING_GRID,
  PEER_ANALYSIS_TAB,
  PEER_ANALYSIS_GRID
} = DATA_IDS

/**
 * Fund Profile Flyout
 */
function FundFlyout (props) {
  // #region MapDispatchToAction
  const dispatch = useDispatch()
  const handleModalOpen = useDispatchAction(openModal, dispatch)
  // #endregion

  const { theme, q4_entity_id: entityId, factset_fund_id, _id, onClose } = props

  const { fundQuery, targetQuery } = useFundTargetQuery({ entityId })
  const { fund, loading, error } = fundQuery
  const fundId = fund?.id

  const { target } = targetQuery
  const targetId = target?.id

  if (!loading && (error || (fund && !fundId))) {
    const errorRoute = mapRoutePath(ROUTE_PATH.ERROR_WITH_CODE, { label: ROUTE_PATH_ID_LABEL.CODE, value: '404' })
    return <Redirect to={errorRoute} />
  }

  return (
    <Swapable
      selected={+loading}
      layers={[
        <div key='fund-flyout--loaded'>
          <FundBanner
            dataIdPrefix={dataIdPrefix}
            // TODO remove factset_fund_id after search migrate
            // TODO remove _id after deal purchasing power migration
            fund={{ ...fund, factset_fund_id, _id }}
            targetId={targetId}
            theme={theme}
            openModal={handleModalOpen}
            onClose={onClose}
          />
          <FundOverview
            dataIdPrefix={dataIdPrefix}
            fund={fund}
            targetId={targetId}
            theme={theme}
          />
          <SectionTab
            theme={THEMES.RAIN}
            tabs={[
              {
                id: TABS.CONTACT,
                label: 'Contacts',
                dataId: {
                  label: CONTACT_TAB,
                  view: CONTACT_GRID
                },
                view: (
                  <ContactGrid
                    dataIdPrefix={dataIdPrefix}
                    entityId={fundId}
                    entityType={FUND}
                  />
                )
              },
              {
                id: TABS.ACTIVITY,
                label: 'Activity',
                dataId: {
                  label: ACTIVITY_TAB,
                  view: ACTIVITY_GRID
                },
                view: (
                  <ActivityGrid
                    dataIdPrefix={dataIdPrefix}
                    entityId={fundId}
                    entityType={FUND}
                    // TODO remove factset_fund_id after search migrate
                    entity={{ ...fund, factset_fund_id }}
                    showChildren
                  />
                )
              },
              {
                id: TABS.CURRENT,
                label: 'Current Holdings',
                dataId: {
                  label: CURRENT_HOLDING_TAB,
                  view: CURRENT_HOLDING_GRID
                },
                view: (
                  <CurrentHoldingGrid
                    dataIdPrefix={dataIdPrefix}
                    entityId={fundId}
                    entityType={FUND}
                  />
                )
              },
              {
                id: TABS.HISTORICAL,
                label: 'Historical Holdings',
                dataId: {
                  label: HISTORICAL_HOLDING_TAB,
                  view: HISTORICAL_HOLDING_GRID
                },
                view: (
                  <HistoricalHoldingGrid
                    dataIdPrefix={dataIdPrefix}
                    entityId={fundId}
                    entityType={FUND}
                  />
                )
              },
              {
                id: TABS.PEER,
                label: 'Peer Analysis',
                dataId: {
                  label: PEER_ANALYSIS_TAB,
                  view: PEER_ANALYSIS_GRID
                },
                view: (
                  <PeerAnalysisGrid
                    dataIdPrefix={dataIdPrefix}
                    entityId={fundId}
                    entityType={FUND}
                  />
                )
              }
            ]}
          />
        </div>,
        <Spinner key='fund-flyout--loaded' mask theme={theme} />,
      ]}
    />
  )
}

FundFlyout.propTypes = propTypes
FundFlyout.defaultProps = defaultProps

export default memo(FundFlyout)
