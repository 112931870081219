import {
  GET_CORPORATE_PARTICIPANTS_REQUEST,
  GET_CORPORATE_PARTICIPANTS_SUCCESS,
  GET_CORPORATE_PARTICIPANTS_FAILURE,
  statusType
} from '../../actions'

const initial = {
  participants: []
}

const corporateParticipantList = (state = initial, action) => {
  const { payload } = action

  switch (action.type) {
    case GET_CORPORATE_PARTICIPANTS_REQUEST:
      return {
        ...initial,
        status: statusType.IN_PROGRESS
      }

    case GET_CORPORATE_PARTICIPANTS_SUCCESS:
      return {
        ...state,
        status: statusType.SUCCESS,
        participants: payload
      }

    case GET_CORPORATE_PARTICIPANTS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    default:
      return state
  }
}

export default corporateParticipantList
