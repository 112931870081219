import React from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import { THEMES, getClassName } from '../../utils/ui'
import Button from '../button/button.component'
import { renderDarkThumb, renderTrackVertical } from '../../resources/theme/q4.custom-scrollbar'
import { Spinner } from '../index'

import './sidebar.component.css'

/**
 * Optional Sidebar Header
 * @param props
 * @return {*}
 * @constructor
 */
const SideBarHeader = (props) => {
  const { headerTemplate } = props

  if (!headerTemplate) {
    return null
  }

  return (
    <div className='sidebar_header'>
      {headerTemplate}
    </div>
  )
}

/**
 * Optional Sidebar Footer
 * @param props
 * @return {*}
 * @constructor
 */
const SideBarFooter = (props) => {
  const { footerTemplate, footerButtons } = props

  // custom footer template
  if (footerTemplate) {
    return (
      <div className='sidebar_footer'>
        {footerTemplate}
      </div>
    )
  }

  // footer buttons
  if (footerButtons) {
    return (
      <div className='sidebar_footer'>
        {
          [].concat(footerButtons).map((button, index) => (
            <Button
              disabled={button.disabled}
              key={`sidebar_button_${index}`}
              label={button.label}
              className={button.className}
              theme={button.theme}
              onClick={button.onClick}
            />
          ))
        }
      </div>
    )
  }

  return null
}

/**
 * Sidebar Component
 * @param props
 * @return {*}
 * @constructor
 */
const Sidebar = (props) => {
  const { className, theme, collapsed, children, footerTemplate, headerTemplate, footerButtons, scrollbars, loading = false } = props

  const classes = getClassName('sidebar', [
    { condition: className, trueClassName: className },
    { condition: theme, trueClassName: `sidebar--${theme}` },
    { condition: collapsed, trueClassName: 'sidebar--collapsed' }
  ])

  function renderChildren () {
    const hasScrollbars = scrollbars ?? true
    if (!hasScrollbars) return children
    return (
      <Scrollbars
        className='react-scrollbar'
        autoHide
        hideTracksWhenNotNeeded
        renderThumbVertical={renderDarkThumb}
        renderTrackVertical={renderTrackVertical}
        height={100}
      >
        {children}
      </Scrollbars>
    )
  }

  return (
    <aside className={classes}>
      <div className='sidebar_inner'>
        <SideBarHeader
          headerTemplate={headerTemplate}
        />
        <div className='sidebar_body'>
          {loading && <Spinner mask theme={THEMES.RAIN} />}
          {!loading && renderChildren()}
        </div>
        <SideBarFooter
          footerTemplate={footerTemplate}
          footerButtons={footerButtons}
        />
      </div>
    </aside>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.LIGHT_GREY, THEMES.RAIN]),
  collapsed: PropTypes.bool,
  headerTemplate: PropTypes.object,
  footerTemplate: PropTypes.object,
  loading: PropTypes.bool,
  footerButtons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    theme: PropTypes.oneOf(Object.values(THEMES)),
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  })
  )
}

Sidebar.defaultProps = {
  theme: THEMES.LIGHT_GREY
}

export default Sidebar
