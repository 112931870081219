import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { config } from '../../../../config'
import {
  getActiveTicker,
  getPageSizeFromStorage,
  cleanQueryParams,
  getClassName,
  THEMES,
  ENTITY_INSTITUTION,
  ENTITY_FUND
} from '../../../../utils'
import { fetchCurrentHoldingsForInstitution, fetchHoldingsForFund, statusType } from '../../../../actions'
import HoldingTable from './table/table.component'
import InstitutionHoldingsToolbar from './toolbar/toolbar.component'
import { Spinner } from '../../../../components'

import './grid.container.css'
import { getActiveToken } from '../../../../utils/auth/auth.util'

const PAGE_SIZE_ID = 'holding-grid'

/**
 * @deprecated
 */
class HoldingGrid extends PureComponent {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.initialFilters = {
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10,
      page: 1,
      search: '',
      regions: '',
      sectors: '',
      capGroups: '',
      sort: { property: 'current', direction: 'DESC' }
    }

    this.state = {
      filters: this.initialFilters
    }
  }

  componentDidMount () {
    this.getHoldings()
  }

  /**
   * ComponentDidUpdate
   * @param prevProps
   * @param prevState
   */
  componentDidUpdate (prevProps, prevState) {
    const { entityId } = this.props
    const { entityId: prevEntityId } = prevProps

    if (this.state.filters !== prevState.filters) {
      this.getHoldings()
    } else if (entityId !== prevEntityId) {
      this.resetQuery()
    }
  }

  /**
   * Reset filters and re-fetch holdings
   */
  resetQuery () {
    const filters = {
      ...this.initialFilters,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
    this.setState({
      filters
    }, () => {
      this.getHoldings()
    })
  }

  /**
   * Get query params for Holdings list
   * @param params
   * @returns {Promise<>}
   */
  getQueryParams = async (params = {}) => {
    const { filters } = this.state
    const { securityId, token } = this.props
    const queryParams = cleanQueryParams(filters)
    const exportToken = await getActiveToken(token)

    if (queryParams.sort) {
      queryParams.sort = JSON.stringify([queryParams.sort])
    }
    if (params.export && exportToken) {
      delete queryParams.page
      delete queryParams.limit
      queryParams.token = exportToken
    }

    return {
      ...queryParams,
      securityId
    }
  }

  /**
   * Handle filter change event
   * @param filters
   */
  handleFilterChange = (filters) => {
    this.setState({
      filters: {
        ...this.state.filters,
        ...filters
      }
    })
  }

  /**
   * reset filter
   * @param filters
   */
  resetFilter = (filters) => {
    this.setState({
      filters: this.initialFilters
    })
  }

  /**
   * Handle row click
   * Navigate to company page
   * @param rowData
   */
  handleRowClick = (rowData) => {
    if (!rowData || !rowData._security) {
      return
    }
    this.props.history.push(`/security/${rowData._security}`)
  }

  /**
   * Handle sort change
   * @param property
   * @param direction
   */
  handleSortChange = ({ property, direction }) => {
    this.handleFilterChange({
      sort: { property, direction: direction.toUpperCase() }
    })
  }

  /**
   * Fetch holdings for institution
   * @param filters
   */
  getHoldings = async () => {
    const filters = await this.getQueryParams()
    const { fetchHoldingsForInstitution, entityId, securityId, entityType, fetchHoldingsForFund } = this.props
    if (entityType && entityType === ENTITY_FUND && entityId) {
      fetchHoldingsForFund(entityId, filters)
    } else {
      entityId && securityId && fetchHoldingsForInstitution(entityId, filters)
    }

  }

  /**
   * Export institution holdings
   */
  handleExport = async () => {
    const { entityId, entityType } = this.props
    if (!entityId) {
      return
    }
    const exportURL = (entityType === ENTITY_FUND) ? 'holdings' : 'current'
    const filters = await this.getQueryParams({ export: true })
    const qs = queryString.stringify(filters, { arrayFormat: 'index' })
    window.open(`${config.apiUrl}/ownership/v2/${entityType.toLowerCase()}/${entityId}/${exportURL}/export?${qs}`, '_self')
  }

  render () {
    const { toolbarTheme, toolTheme, entityType } = this.props
    const { data, total, status } = this.props.ownership[entityType.toLowerCase()].holding
    const { filters } = this.state

    return (<div className={getClassName('holding-grid')}>
      {(status === statusType.IN_PROGRESS) && (
        <Spinner mask theme={THEMES.RAIN} />
      )}
      <InstitutionHoldingsToolbar
        toolbarTheme={toolbarTheme}
        toolTheme={toolTheme}
        filter={filters}
        noData={!data || !data.length}
        handleFilterChange={this.handleFilterChange}
        handleExport={this.handleExport}
        resetFilter={this.resetFilter}
      />
      <HoldingTable
        pageSizeId={PAGE_SIZE_ID}
        data={data}
        dataTotal={total}
        query={{
          page: filters.page,
          limit: filters.limit
        }}
        handleQueryChange={this.handleFilterChange}
        handleSortChange={this.handleSortChange}
        handleRowClick={this.handleRowClick}
      />
    </div>)
  }
}

HoldingGrid.propTypes = {
  entityId: PropTypes.string.isRequired,
  className: PropTypes.string,
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  entityType: PropTypes.string
}


HoldingGrid.defaultProps = {
  toolTheme: THEMES.INK,
  toolbarTheme: THEMES.Q4_BLUE,
  entityType: ENTITY_INSTITUTION
}

const mapStateToProps = (state) => {
  const { ownership } = state
  const profile = state.profile
  const ticker = getActiveTicker(profile.data)
  const securityId = ticker && ticker._security
  return {
    token: state.token,
    securityId,
    ownership
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchHoldingsForInstitution: bindActionCreators(fetchCurrentHoldingsForInstitution, dispatch),
  fetchHoldingsForFund: bindActionCreators(fetchHoldingsForFund, dispatch)
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HoldingGrid))
