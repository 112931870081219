import React, { memo } from 'react'
import PropTypes from 'prop-types'

// utils
import { combine, defaultIfEmpty, ENTITY_TYPE } from '../../../../utils'

import './header.component.scss'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  data: PropTypes.shape({
    entityType: PropTypes.string.isRequired,
    entityName: PropTypes.string.isRequired,
    institutionName: PropTypes.string,
    institutionType: PropTypes.string,
    address: PropTypes.object,
    job: PropTypes.object,
    gdpr: PropTypes.bool
  })
}

const defaultProps = {
  data: {}
}

/**
 * Contact Job Component
 * @param props
 */
function ContactJob ({ job }) {
  const { title, institutionName } = (job || {})

  return ((title || institutionName)
    ? <span className='tearsheet_header-detail'>
      {(title && title.length)
        ? <span className='tearsheet_header-detail tearsheet_header-detail--position'>{title} at </span>
        : null}
      {defaultIfEmpty(institutionName)}
    </span>
    : null)
}

/**
 * Header Component
 * @param props
 */
function Header (props) {
  const { data } = props
  const { entityType, entityName, institutionName, institutionType, address, gdpr, job } = data
  const { city, stateProvinceCode, countryName } = (address || {})

  return (
    <header className='tearsheet_header'>
      <div className='tearsheet_header-title'>
        <i className={`tearsheet_header-icon tearsheet_header-icon--${entityType} q4i-${entityType}-4pt`} />
        <span className='tearsheet_header-name'>{entityName}</span>
      </div>
      <div className='tearsheet_header-content'>
        {(entityType === INSTITUTION) && institutionType
          ? <span className='tearsheet_header-detail'>{institutionType}</span>
          : null}
        {(entityType === FUND) && institutionName
          ? <span className='tearsheet_header-detail'>{institutionName}</span>
          : null}
        {(entityType !== CONTACT)
          ? <span className='tearsheet_header-detail tearsheet_header-detail--address'>
            {combine([city, stateProvinceCode, countryName])}
          </span>
          : null}
        {(entityType === CONTACT)
          ? <ContactJob job={job} />
          : null}
        {(entityType === CONTACT && gdpr)
          ? <span className='tearsheet_header-detail--gdpr'>GDPR Forgotten</span>
          : null}
      </div>
    </header>
  )
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default memo(Header)
