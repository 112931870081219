import React from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import { Modal } from '../../../../../components';
import TextInput from '../../../../shared/form/textInput/textInput.component';
import './editorUrlSelector.component.css';

class EditorUrlSelector extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            unsaved: '',
            title: '',
            unsavedNewTarget: true,
            expanded: false
        };
        this.textRef = null;
    }

    /**
     * Sets the url to be added
     * @param url {}
     */
    changeUrl = (url) => {
        this.setState({
            unsaved: (url ? url.target.value : '')
        });
    };

    /**
     * Sets the friendly text for the Url in the editor
     * @param title {}
     */
    changeTitle = (title) => {
        this.setState({
            title: (title ? title.target.value : '')
        });
    };

    /**
     * Sets the Url target
     * @param option {}
     */
    setTarget = (option) => {
        this.setState({unsavedNewTarget: option.value});
    };

    /**
     * Checks to see if Url target has been changed before submitting
     */
    currentNewTarget = () => {
        return typeof (this.state.unsavedNewTarget) === 'boolean' ? this.state.unsavedNewTarget : this.props.newTarget;
    };

    /**
     * Sends Url back to editor, resets state, and closes modal
     */
    addUrl = () => {
        this.props.onExpand();

        const newTarget = this.currentNewTarget() ? '_blank' : '_self';
        this.props.onChange('link', this.currentTitle(), this.currentUrl(), newTarget);
        this.setState({
            ...this.state,
            unsaved: '',
            unsavedNewTarget: null,
            expanded: false
        });
    };

    currentTitle = () => {
        const {selectionText} = this.props.currentState;
        return this.state.title ? this.state.title : selectionText;
    };

    currentUrl = () => {
        return this.state.unsaved || '';
    };

    toggleModal = () => {
        const {onExpand, currentState} = this.props;
        const {expanded} = this.state;

        this.setState({
            expanded: !expanded,
            unsaved: !currentState.link ? '' : currentState.link.target,
            title: get(currentState.link, 'title') || currentState.selectionText || '',
            unsavedNewTarget: true
        });

        onExpand();
    };

    render = () => {
        const modalButtons = [
            {
                label: 'Cancel',
                onClick: this.toggleModal,
                ui: 'shaded'
            },
            {
                label: 'Done',
                onClick: this.addUrl,
                ui: 'citrus'
            }
        ];

        const isLinkActive = this.props.value.length ? 'rdw-option-wrapper rdw-option-active' : 'rdw-option-wrapper';

        return (
            <div className='rdw-link-wrapper' aria-label='rdw-link-control'>
                <div onClick={this.toggleModal} className={isLinkActive} title='Create Link'>
                    <img src={this.props.icon} alt=''/>
                </div>
                <Modal
                    visible={this.state.expanded}
                    onClose={this.toggleModal}
                    className='urlModal'
                    footerButtons={modalButtons}
                    title='Insert Link'>
                    <div className='urlModal--textArea field--text'>
                        <label htmlFor='activity-url-linkeditor' className='field_label'>Enter Url</label>
                        <TextInput
                            value={this.currentUrl()}
                            onChange={this.changeUrl}
                            placeholder='Enter URL'
                            id='activity-url-editor'/>
                    </div>
                    <div className='urlModal--textArea field--text'>
                        <label htmlFor='activity-url-name' className='field_label'>Text to Display</label>
                        <TextInput
                            value={this.currentTitle()}
                            onChange={this.changeTitle}
                            placeholder='Enter text'/>
                    </div>
                </Modal>
            </div>
        );
    }
}

EditorUrlSelector.propTypes = {
    value: PropTypes.string,
    newTarget: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onExpand: PropTypes.func.isRequired,
    expandedState: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    currentState: PropTypes.object
};

EditorUrlSelector.defaultProps = {
    newTarget: false,
    expandedState: false,
    disabled: false
};
export default EditorUrlSelector;
