import { peerConfig, statusType} from '../../actions'

const {
  PEER_CONFIG_DEFAULT_PEERS_REQUEST,
  PEER_CONFIG_DEFAULT_PEERS_SUCCESS,
  PEER_CONFIG_DEFAULT_PEERS_FAILURE,
  PEER_CONFIG_SEARCH_PEERS_REQUEST,
  PEER_CONFIG_SEARCH_PEERS_SUCCESS,
  PEER_CONFIG_SEARCH_PEERS_FAILURE,
  PEER_CONFIG_CLEAR_PEERS,
  PEER_CONFIG_REMOVE_PEER,
  PEER_CONFIG_ADD_PEER,
  PEER_CONFIG_FOUND_PEERS_RESET,
  PEER_CONFIG_SET_PEERS,
  PEER_CONFIG_RESET,
} = peerConfig

const initial = {
  peers: [],
  foundPeers: [],
  status: statusType.IDLE
}

const peerConfigReducer = (state = initial, action) => {
  switch (action.type) {
    case PEER_CONFIG_DEFAULT_PEERS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }
    case PEER_CONFIG_DEFAULT_PEERS_SUCCESS:
      return {
        ...state,
        peers: action.payload,
        status: statusType.SUCCESS
      }
    case PEER_CONFIG_DEFAULT_PEERS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case PEER_CONFIG_SEARCH_PEERS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }
    case PEER_CONFIG_SEARCH_PEERS_SUCCESS:
      return {
        ...state,
        foundPeers: (action.payload || []).map(({ _source, isInWatchlist }) => ({
          ..._source,
          ...((isInWatchlist === false || isInWatchlist === true) && { isSaved: isInWatchlist })
        })),
        status: statusType.SUCCESS
      }
    case PEER_CONFIG_SEARCH_PEERS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }
    case PEER_CONFIG_CLEAR_PEERS:
      return {
        ...state,
        peers: []
      }
    case PEER_CONFIG_REMOVE_PEER:
      return {
        ...state,
        peers: state.peers.filter((peer) => {
          return peer._security !== action.payload
        })
      }
    case PEER_CONFIG_FOUND_PEERS_RESET:
      return {
        ...state,
        foundPeers: []
      }
    case PEER_CONFIG_ADD_PEER:
      return {
        ...state,
        peers: state.peers.concat(action.payload)
      }
    case PEER_CONFIG_SET_PEERS:
      return {
        ...state,
        peers: action.payload
      }
    case PEER_CONFIG_RESET:
      return {
        ...initial
      }
    default:
      return state
  }
}

export default peerConfigReducer
