import { formatDate } from '../../utils/date.util'
import {
  GET_REPORT_CUSTOM_TEMPLATES_REQUEST,
  GET_REPORT_CUSTOM_TEMPLATES_SUCCESS,
  GET_REPORT_CUSTOM_TEMPLATES_ERROR,
  RESET_REPORT_CUSTOM_TEMPLATES,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../actions'

const initial = {
  data: [],
  meta: {
    page: 0,
    total: 0
  },
  status: IDLE
}

const reportCustomTemplates = (state = initial, action) => {
  switch (action.type) {
    case GET_REPORT_CUSTOM_TEMPLATES_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case RESET_REPORT_CUSTOM_TEMPLATES:
      return initial
    case GET_REPORT_CUSTOM_TEMPLATES_SUCCESS:
      const data = (action.payload || []).map((report) => {
        const {
          _id, title, create_date, updated_date, _profile, _entityType, fields, pivotFields, filters, sort, peers,
          indices, limit, isPivot, showTitle, widgetType, chartOptions, isLocked
        } = report
        const isUTC = true

        return {
          create_date: formatDate(create_date, 'MM/DD/YY', isUTC),
          updated_date: formatDate(updated_date, 'MM/DD/YY', isUTC),
          author: (_profile && _profile.firstName) ? `${_profile.firstName} ${_profile.lastName}` : '-',
          _id,
          title,
          _profile,
          _entityType,
          fields,
          pivotFields,
          filters,
          sort,
          limit,
          peers,
          indices,
          isPivot,
          isLocked,
          showTitle,
          widgetType,
          chartOptions
        }
      })

      return {
        ...state,
        data,
        meta: action.meta,
        status: FETCHED
      }
    case GET_REPORT_CUSTOM_TEMPLATES_ERROR:
      return {
        ...state,
        status: FAILED
      }
    default:
      return state
  }
}

export default reportCustomTemplates
