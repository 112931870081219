import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import { get } from 'lodash'
import { getDateTime, getDateFromMoment, getTimeFormat, guessTimeZone } from '../../../../utils/date.util'
import { DatePicker, TimePicker, TimezonePicker } from '../../../../components'
import { Checkbox } from '../../../shared'
import { THEMES } from '../../../../utils/ui'
import './activityDate.component.css'

class ActivityDate extends Component {
  constructor (props) {
    super(props)
    const { values } = props
    const defaultTZ = guessTimeZone()
    const startTz = get(values, 'start.timezone', defaultTZ)
    const endTz = get(values, 'end.timezone', defaultTZ)

    this.state = {
      showTz: (startTz !== endTz && endTz !== defaultTZ),
      defaultTZ: guessTimeZone()
    }
  }

  /**
   * Set Formik values for start date.
   * Set only if both date and time have been selected
   */
  updateStartDate = (startDate, startTime, timezone) => {
    const { setFieldValue } = this.props

    if (startDate) {
      setFieldValue('start', {
        date_time: (startDate && startTime ? getDateTime(startDate, startTime, getTimeFormat(), timezone) : startDate || null),
        timezone
      })
    }

    if (startTime) {
      setFieldValue('startTime', startTime)
    }
  }

  /**
   * Set Formik values for end date.
   * Set only if both date and time have been selected
   */
  updateEndDate = (endDate, endTime, timezone) => {
    const { setFieldValue } = this.props

    if (endDate) {
      setFieldValue('end', {
        date_time: (endDate && endTime ? getDateTime(endDate, endTime, getTimeFormat(), timezone) : endDate || null),
        timezone
      })
    }

    if (endTime) {
      setFieldValue('endTime', endTime)
    }

    // Flag modified EndDate so it can skip Normalization
    setFieldValue('modifiedEndDate', true)
  }

  /**
   * On date start change
   * @param value {Date|Moment}
   */
  onStartDateChange = (value) => {
    const { values } = this.props
    const { startTime, endTime } = values
    const endDate = get(values, 'end.date_time')
    const startTz = get(values, 'start.timezone')
    const endTz = get(values, 'end.timezone')
    const startDate = getDateFromMoment(value)

    if (endDate) {
      const endDateUpdated = getDateFromMoment(value)
      this.updateEndDate(endDateUpdated, endTime, endTz)
    }

    this.updateStartDate(startDate, startTime, startTz)
  }

  /**
   * On Start Time Zone Change
   * @param timezone
   */
  onStartTZChange = (timezone) => {
    const { values } = this.props
    const { startTime, endTime } = values
    const startDate = get(values, 'start.date_time')
    const endDate = get(values, 'end.date_time')

    // sync end time zone with start time zone when start timezone is changed
    this.updateStartDate(startDate, startTime, timezone)
    this.updateEndDate(endDate, endTime, timezone)
  }

  /**
   * on Uze Time Zone Change
   * @param value
   */
  onUzeTzChange = (value) => {
    this.setState({ 'showTz': value })
  }

  /**
   * On End Time Zone Change
   * @param timezone
   */
  onEndTZChange = (timezone) => {
    const { values } = this.props
    const { endTime } = values
    const endDate = get(values, 'end.date_time')
    this.updateEndDate(endDate, endTime, timezone)
  }

  /**
   * On time start change
   * @param startTime {String}
   */
  onStartTimeChange = (startTime) => {
    const { values } = this.props
    const startDate = get(values, 'start.date_time')
    const endDate = get(values, 'end.date_time')
    const startTz = get(values, 'start.timezone')
    const endTz = get(values, 'end.timezone')

    const timeFormat = 'h:mma'
    const endTime = moment(startTime, timeFormat)
      .add(1, 'hour')
      .format(timeFormat)

    this.updateStartDate(startDate, startTime, startTz)
    this.updateEndDate(endDate, endTime, endTz)
  }

  /**
   * On date end change
   * @param value {Date|Moment}
   */
  onEndDateChange = (value) => {
    const { values } = this.props
    const { startTime, endTime } = values
    const startDate = get(values, 'start.date_time')
    const endTz = get(values, 'end.timezone')
    const startTz = get(values, 'start.timezone')
    const endDate = getDateFromMoment(value)

    if (startDate && moment(startDate).isAfter(value)) {
      const startDateUpdated = getDateFromMoment(value)
      this.updateStartDate(startDateUpdated, startTime, startTz)
    }

    this.updateEndDate(endDate, endTime, endTz)
  }

  /**
   * On time end change
   * @param endTime {String}
   */
  onEndTimeChange = (endTime) => {
    const { values } = this.props
    const endDate = get(values, 'end.date_time')
    const endTz = get(values, 'end.timezone')
    this.updateEndDate(endDate, endTime, endTz)
  }

  /**
   * On 'All Day' checkbox change
   * @param value
   */
  onAllDayChange = (value) => {
    const { setFieldValue } = this.props
    if (value) {
      this.setAllDayDefaultTime()
    }

    setFieldValue('all_day', value)
  }

  /**
   * set default start time to 12:00 and end time to 11:59 when all day is selected
   */
  setAllDayDefaultTime = () => {
    const { values } = this.props
    const startDate = get(values, 'start.date_time')
    const endDate = get(values, 'end.date_time')
    const endTz = get(values, 'end.timezone')
    const startTz = get(values, 'start.timezone')
    this.updateStartDate(startDate, '12:00am', startTz)
    this.updateEndDate(endDate, '11:59pm', endTz)
  }

  render () {
    const { values } = this.props
    const { defaultTZ, showTz } = this.state
    const all_day = get(values, 'all_day')
    const start = get(values, 'start')
    const end = get(values, 'end')
    const startTz = get(start, 'timezone', defaultTZ)
    const endTz = get(end, 'timezone', defaultTZ)
    let startDateTime
    let endDateTime

    if (start) {
      startDateTime = (typeof start.date_time === 'string' ? moment.tz(start.date_time, startTz) : start.date_time)
    }

    if (end) {
      endDateTime = (typeof end.date_time === 'string' ? moment.tz(end.date_time, endTz) : end.date_time)
    }

    return (
      <div className='activity-form__activity-dates'>
        <div className='activity-date_container'>
          <div className='activity-date_fields'>
            <div className='activity-date_field field--date'>
              <label className='field_label'>Start</label>
              <div className='activity-date_pickers'>
                <DatePicker
                  value={startDateTime}
                  defaultValue={moment()}
                  onChange={this.onStartDateChange}
                />
                {!all_day && (
                  <TimePicker
                    date={startDateTime}
                    minutesIncrement={15}
                    timezone={get(values, 'start.timezone')}
                    onSelect={this.onStartTimeChange}
                    theme={THEMES.LIGHT_GREY}
                  />
                )}
              </div>
            </div>
            {showTz && !all_day && (
              <div className='activity-date_field activity-date_field_timezone-start field--date'>
                <label className='field_label'>Event Start Time Zone</label>
                <TimezonePicker
                  theme={THEMES.LIGHT_GREY}
                  date={startDateTime.toDate()}
                  timezone={startTz}
                  onSelect={this.onStartTZChange}
                />
              </div>
            )}
            <div className='activity-date_field field--date'>
              <label className='field_label'>End</label>
              <div className='activity-date_pickers'>
                <DatePicker
                  value={endDateTime}
                  defaultValue={moment()}
                  onChange={this.onEndDateChange}
                />
                {!all_day && (
                  <TimePicker
                    defaultHourOffset={1}
                    minutesIncrement={15}
                    date={endDateTime}
                    timezone={get(values, 'end.timezone')}
                    onSelect={this.onEndTimeChange}
                    theme={THEMES.LIGHT_GREY}
                  />
                )}
              </div>
            </div>
            {showTz && !all_day && (
              <div className='activity-date_field activity-date_field_timezone-end field--date'>
                <label className='field_label'>Event End Time Zone</label>
                <TimezonePicker
                  theme={THEMES.LIGHT_GREY}
                  date={endDateTime.toDate()}
                  timezone={endTz}
                  onSelect={this.onEndTZChange}
                />
              </div>
            )}
          </div>
          <div className='activity-date_field activity-date_field--timezone'>
            {!all_day && (
              <Checkbox
                theme='rain'
                id='activity-date_use-tz'
                isChecked={showTz}
                label='Use Time Zones'
                onChange={this.onUzeTzChange}
              />
            )}
          </div>
        </div>
        <div className='activity-date_all-day'>
          <Checkbox
            theme='rain'
            id='activity-date_all-day-checkbox'
            isChecked={!!all_day}
            label='All Day'
            onChange={this.onAllDayChange}
          />
        </div>
      </div>
    )
  }
}

export default withRouter(ActivityDate)