import React, { memo } from 'react'
import { getClassName } from '../../../../utils/ui/ui.util'
import './reportTemplateList.component.css'

/**
 * ReportTemplateList Component
 * @param props
 * @returns {*}
 */
function ReportTemplateList (props) {
  const { templates, templateType, selectedTemplate, selectedCategory, onSelect } = props

  return (
    <ul className='report-template-list'>
      {(templates || []).filter((template) => template && template.reportCategories.includes(selectedCategory)).map((template) => {
        if (template.reportCategories.includes('blank')) {
          return null
        }

        const { _id, title, description } = template
        const isSelected = selectedTemplate && (_id === selectedTemplate._id)
        const className = getClassName('report-template-list_item', [
          { condition: templateType, trueClassName: `report-template-list_item--${templateType}` },
          { condition: isSelected, trueClassName: 'report-template-list_item--selected' }
        ])

        return (
          <li className={className} key={`report-template--${_id}`} onClick={() => onSelect(template)}>
            <span className='report-template-list_title'>{title || '-'}</span>
            <span className='report-template-list_desc'>{description || '...'}</span>
          </li>
        )
      })}
    </ul>
  )
}

export default memo(ReportTemplateList)
