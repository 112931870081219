import React from 'react'

/**
 * Participant Component
 * @param participants
 * @returns {*}
 * @constructor
 */
const Participant = ({ participants }) => {
  return (
    <ul className='data data--list'>
      <li className='data_item data_item--header data_item--bordered'>Corporate Participants</li>
      {(participants && Array.isArray(participants) && participants.length)
        ? participants.map((participant) => {
          const { _id, full_name } = participant
          return (
            <li key={_id} className='data_item'>
              <span className='data_name data_name--initial'>{full_name}</span>
            </li>
          )
        })
        : null}
    </ul>
  )
}

export default Participant
