import { getFromXigniteToStandard } from '../stock/stock.util'

import {
  rain,
  darkTeal,
  mustard,
  surf,
  amber,
  apple,
  eggplant,
  lime,
  ice,
  walnut
} from '../../resources/materialui-overrides/colors'

export const AGGREGATION_ENUM = ['average', 'count', 'max', 'median', 'min', 'sum']
export const ownershipEntityTypes = ['institution', 'fund']
export const ownershipSurveillanceEntityTypes = ['institutionSurveillance', 'fundSurveillance']
export const ownershipShareholderIdEntityTypes = ['shareholderid', 'fundShareholderid']
export const surveillanceEntityTypes = ['institutionSurveillance', 'fundSurveillance']
export const pivotEntityTypes = ['institution', 'fund', 'shareholderid', 'fundShareholderid'].concat(surveillanceEntityTypes)

export const pivotFieldDefs = ['holdings.']
export const nonPivotHoldingsFields = ['holdings.current_qtr_date', 'holdings.report_date', 'holdings.prev_qtr_date']
export const stockEntityType = 'stock'
export const activityEntityType = 'activity'
export const defaultEntityTypeFilter = 'holdings.current_qtr_date'

export const emptyStateTitle = 'No results'
export const emptyStateMessages = {
  missingReportConfig: 'Field configuration is required',
  missingChartConfig: 'Series configuration is required',
  noDataAvailable: 'No data available for the current configuration',
  snapshotFailed: 'Oops. Looks like something went wrong'
}

// Entity Type > Field name > Current text vs text to replace
export const dataOverwriteMap = {
  activity: {
    category: {
      current: 'comment',
      replace: 'note'
    }
  },
  stock: {
    'Security.MarketIdentificationCode': {
      func: (val) => getFromXigniteToStandard(val)
    }
  }
}

export const chartTypeOptions = [{
  label: 'Table',
  icon: 'q4i-table-2pt',
  widgetType: 'table'
}, {
  label: 'Pie Chart',
  icon: 'q4i-piechart-2pt',
  widgetType: 'chart',
  chartType: 'pie',
  availableInCombo: false
}, {
  label: 'Bar Chart',
  icon: 'q4i-relative-performance-2pt',
  widgetType: 'chart',
  chartType: 'bar',
  availableInCombo: false
}, {
  label: 'Column Chart',
  icon: 'q4i-relative-performance-2pt',
  widgetType: 'chart',
  chartType: 'column',
  availableInCombo: true
}, {
  label: 'Line Chart',
  icon: 'q4i-chart-2pt',
  widgetType: 'chart',
  chartType: 'line',
  availableInCombo: true
}, {
  label: 'Combo Chart',
  icon: 'q4i-combo-chart-2pt',
  widgetType: 'chart',
  chartType: 'combo',
  availableInCombo: false
}]

export const yAxisOptions = [{
  label: 'Left',
  value: 'left'
}, {
  label: 'Right',
  value: 'right'
}]

export const colorOptions = [
  rain,
  darkTeal,
  mustard,
  surf,
  amber,
  apple,
  eggplant,
  lime,
  ice,
  walnut
]

export const reportPageLimit = 25
export const widgetTitleHeight = 32
export const widgetMinW = 8
export const widgetMinH = 5

/**
 * Config to determine if report supports auto-enable feature for RelativeComparison
 */
export const autoEnableRelativeComparisonConfig = {
  supportedYAxis: ['left'],
  supportedFields: ['Last Close'],
  supportedChartType: ['line']
}
