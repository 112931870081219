import './loadingState.doc'
import { isEmpty, isNil } from 'lodash'
import React, { memo, useMemo } from 'react'
import { THEMES } from '../../utils'
import { Ghostable, Spinner, Swapable } from '..'

const LOADING_STATE_LAYER = {
  LOADED: 0,
  EMPTY: 1,
  ERROR: 2,
}

/**
 * LoadingState
 * @param {LoadingStateProps} props 
 * @returns {JSX.Element}
 */
const LoadingState = (props) => {
  const { count, error, loading, errorState, emptyState, loadedState } = props

  const selected = useMemo(() => {
    if (!isNil(error) && !isEmpty(errorState)) return LOADING_STATE_LAYER.ERROR

    if (!count && !isEmpty(emptyState)) return LOADING_STATE_LAYER.EMPTY
    
    return LOADING_STATE_LAYER.LOADED
  }, [count, emptyState, error, errorState])

  return (
    <div className='loading-state'>
      <Ghostable ghosted={!loading}>
        <Spinner mask theme={THEMES.RAIN} />
      </Ghostable>
      <Swapable
        selected={selected}
        layers={[loadedState, emptyState, errorState].map((x, i) => ({ ...x, key: x.key || `loading-state--${i}` }))}
      />
    </div>  
  )
}

export default memo(LoadingState);
