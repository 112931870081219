import React, { Component } from 'react'
import { HtmlEditor } from '../../../shared'
import toolbarOptions from './toolBarOptions'
import './activityHtmlEditor.component.css'

class activityHtmlEditor extends Component {

  handleChange ({ html }) {
    const { setFieldValue } = this.props
    setFieldValue && setFieldValue('body', html)
  }

  render () {
    const { body } = this.props
    return (
      <div className='activity-form__activity-editor activity-form--section'>
        <div className='field field--text field--full'>
          <label className='field_label' htmlFor='activity-editor'>Notes</label>
          <HtmlEditor
            html={body}
            toolbar={toolbarOptions}
            onChange={this.handleChange.bind(this)}
          />
        </div>
      </div>
    )
  }
}

export default activityHtmlEditor
