import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { isEmpty } from 'lodash'
import { FETCHED, FETCHING } from '../../../../../actions/widget/ownership'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import {
  renderDarkThumb,
  renderLightThumb,
  renderTrackVertical
} from '../../../../../resources/theme/q4.custom-scrollbar'
import { loadTopShareholders } from '../../../../../actions/widget/ownership/index'
import { getOwnershipType, isServiceEnabled } from '../../../../../utils/user.util'
import { setClassNames } from '../../../../../utils/widget.util'
import { Spinner } from '../../../../../components'
import WidgetError from '../../../../../components/widget/error/widgetError.component'
import CurrentTopShareholdersTable
  from '../../../../../components/widget/ownership/topShareholders/current/currentTopShareholders.component'
import './currentTopShareholders.container.css'

class CurrentTopShareholders extends Component {

  /**
   * Triggered when component mounted
   * Enable auto-reloading if enabled
   */
  componentDidMount = () => {
    const { isEdit, status } = this.props
    if (isEdit && status === FETCHED) {
      return
    }

    const refreshInterval = this.props.options.refreshInterval
    if (refreshInterval) {
      this.timer = setInterval(this._fetchData.bind(this), refreshInterval)
    }

    this._fetchData()
  }

  /**
   * ComponentDidUpdate
   * Re-fetch data when security changed
   * @param prevProps
   */
  componentDidUpdate = (prevProps) => {
    if (prevProps.tickerId !== this.props.tickerId) {
      this._fetchData()
    }
  }

  /**
   * clear fetch interval on unmount
   */
  componentWillUnmount = () => {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  /**
   * Fetch Top Shareholder data
   * @private
   */
  _fetchData = () => {
    const { options, tickerId } = this.props
    this.props.loadTopShareholders({
      tickerId,
      holder_type: (options && options.type) || 'all',
      limit: (options && options.items) || 20,
      start: 0,
      page: 1
    })
  }

  /**
   * Check to see if profile data exists
   * @returns {*|Number}
   */
  hasProfile () {
    return (this.props.profile && Object.keys(this.props.profile).length)
  }

  /**
   * Render footer caption based on ownership type
   * @returns {XML}
   */
  renderFooterCaption () {
    let ownershipType = '13F'

    if (this.hasProfile()) {
      ownershipType = getOwnershipType(this.props.profile).label
    }

    return (<span className='top-shareholders_caption'>{`Based on ${ownershipType} data`}</span>)
  }

  /**
   * Generate ownership link
   * @returns {string}
   */
  getOwnershipLink () {
    const { tickerId } = this.props
    let link = `/security/${tickerId}`

    if (this.hasProfile() && isServiceEnabled('shareholder_id', this.props.profile.services)) {
      link = '/shareholderid'
    }

    return link
  }

  /**
   * Render Top Shareholders Widget Container
   * @returns {XML}
   */
  render () {
    const { status, theme, layout, options, currentTopShareholders, subscriptionServices } = this.props
    const is_loading = status === FETCHING
    const footerCaption = this.renderFooterCaption()
    const classes = setClassNames('top-shareholders', layout, theme)
    const renderThumb = theme === 'dark' ? renderLightThumb : renderDarkThumb

    if (!is_loading && isEmpty(currentTopShareholders)) {
      return (
        <WidgetError
          theme={theme}
          header={'Top Shareholders'}
          message={`No ${getOwnershipType(this.props.profile).label} data available.`}
        />
      )
    }

    return (isEmpty(currentTopShareholders) ?
        <Spinner/> :
        <div className={classes.base}>
          <header className={`${classes.name}_header`}>
            <h2 className={`${classes.name}_title`}>
              <Link to={this.getOwnershipLink()}>Top Shareholders</Link>
            </h2>
            <span className={`${classes.name}_details`}>Position and change</span>
            <span className={`${classes.name}_column`}>POS</span>
            <span className={`${classes.name}_column`}>CHG</span>
          </header>
          <div className={classes.items}>
            <Scrollbars
              className='react-scrollbar'
              autoHide
              hideTracksWhenNotNeeded
              renderThumbVertical={renderThumb}
              renderTrackVertical={renderTrackVertical}
            >
              <CurrentTopShareholdersTable
                options={options}
                subscriptionServices={subscriptionServices}
                shareholders={currentTopShareholders}
              />
            </Scrollbars>
          </div>
          <footer className={classes.footer}>
            {footerCaption}
            <Link className={`${classes.name}_link`} to={this.getOwnershipLink()}>View All</Link>
          </footer>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  const current = state.widget.ownership.current
  const profileData = state.profile && state.profile.data
  const services = (profileData.services || []).reduce((acc, each) => {
    acc[each.type] = each.enabled
    return acc
  }, {})
  return {
    currentTopShareholders: (current && current.holders) || [],
    profile: state.shared.profile,
    status: current.status,
    theme: state.dashboard.dashboardState.theme,
    securityId: state.dashboard.dashboardState.securityId,
    tickerId: state.dashboard.dashboardState.tickerId,
    subscriptionServices: services
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadTopShareholders: bindActionCreators(loadTopShareholders, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CurrentTopShareholders)
