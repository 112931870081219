import { combineReducers } from 'redux'
import position from './position.reducer'
import stock from './stock.reducer'
import news from './news.reducer'
import events from './events.reducer'
import activity from './activity.reducer'

export default combineReducers({
  position,
  stock,
  news,
  events,
  activity
})
