import {
  GET_FUND_REQUEST,
  GET_FUND_SUCCESS,
  GET_FUND_FAILURE,
  RESET_FUND,
  statusType
} from '../../actions'

const initial = {
  _id: null,
  factsetId: null,
  entityId: null,
  status: statusType.IDLE
}

const detail = (state = initial, action) => {
  const { payload = {}, type } = action

  switch (type) {
    case GET_FUND_REQUEST:
      return {
        ...initial,
        status: statusType.IN_PROGRESS
      }

    case GET_FUND_SUCCESS:
      return {
        ...state,
        _id: payload._id || null,
        factsetId: payload.factset_fund_id || null,
        entityId: payload.q4_entity_id || null,
        status: statusType.SUCCESS
      }

    case GET_FUND_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case RESET_FUND:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default detail
