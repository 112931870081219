/**
 * Retrieves the PostHog key based on the current environment hostname.
 * @returns {string} The PostHog key for the current environment.
 */
export const getPostHogKey = () => {
    const posthogProjects = {
      dev: "phc_P0tg0FcH667RxCXSsalqeY6nxbcmcXrdivCh1Zrf1XV",
      stage: "phc_Nj3qyN7bVyb1hfrbkjlcZnxbyWs3o10rN6dxUtMxNSn",
      prod: "phc_6t5lAkCR3xN68LHIllAOtqBdH4tmsMWLZCrDoiK3tdu"
    }
  
    let hostname = ''
    if (typeof window !== 'undefined') {
      hostname = (window && window.location && window.location.hostname) || '';
    }
    const hostnameSegments = hostname.toLowerCase().split('.') || '';
  
    if (hostnameSegments.includes('develop')) {
      return posthogProjects.dev;
    } else if (hostnameSegments.includes('staging')) {
      return posthogProjects.stage;
    } else if (hostnameSegments.includes('preprod')) {
      return posthogProjects.prod;
    } else if (hostnameSegments.join('.') === 'q4desktop.com' || hostnameSegments.join('.') === 'www.q4desktop.com') {
      return posthogProjects.prod;
    } else {
      return posthogProjects.dev;
    }
  }
