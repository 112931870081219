import { format, isReportingWindow, YET_TO_FILE } from '../../../../../../../utils'
import { get } from 'lodash'
import React from 'react'

const EMPTY_PLACEHOLDER = '-'

function formatted (value) {
  return (value || value === 0) ? format(value, 0) : EMPTY_PLACEHOLDER
}

/**
 * Title Cell Component
 * @param props
 */
const TitleCell = ({ data }) => {
  const { entityType, entityName } = (data || {})
  return (
  <div className='cell'>
      <i className={`cell_icon cell_icon--${entityType} q4i-${entityType}-2pt`} />
      <div className='cell_content'>
      <div className='cell_content-name'>{entityName}</div>
      </div>
  </div>
  )
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 */
const CustomCell = ({ data = {}, column = {}}) => {

  const colId = get(column, 'colId', '')
  const cellData = get(data, `${column.colId}`)

  if (colId.includes('entityName')) {
    return <TitleCell data={data} />
  } else if (colId.includes('qtrChange')) {
    const isYetToFile = isReportingWindow() && cellData === 0
    return isYetToFile ? YET_TO_FILE : formatted(cellData) || EMPTY_PLACEHOLDER
  }
  return formatted(cellData) || EMPTY_PLACEHOLDER
}

export default CustomCell
