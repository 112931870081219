import moment from 'moment-timezone'

import {
  GET_TARGETING_SAVED_SEARCH_REQUEST,
  GET_TARGETING_SAVED_SEARCH_SUCCESS,
  GET_TARGETING_SAVED_SEARCH_FAILURE,
  CREATE_TARGETING_SAVED_SEARCH_REQUEST,
  CREATE_TARGETING_SAVED_SEARCH_SUCCESS,
  CREATE_TARGETING_SAVED_SEARCH_FAILURE,
  UPDATE_TARGETING_SAVED_SEARCH_REQUEST,
  UPDATE_TARGETING_SAVED_SEARCH_SUCCESS,
  UPDATE_TARGETING_SAVED_SEARCH_FAILURE,
  REMOVE_TARGETING_SAVED_SEARCH_REQUEST,
  REMOVE_TARGETING_SAVED_SEARCH_SUCCESS,
  REMOVE_TARGETING_SAVED_SEARCH_FAILURE,
  RESET_TARGETING_SAVED_SEARCH,
  statusType
} from '../../actions'

const initial = {
  data: [],
  search: {},
  status: statusType.IDLE
}

const savedSearch = (state = initial, action) => {
  switch (action.type) {
    case GET_TARGETING_SAVED_SEARCH_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_TARGETING_SAVED_SEARCH_SUCCESS:
      const savedSearch = action.payload.data

      return {
        type: action.type,
        data: savedSearch.map((searchItem) => {
          const { filter, filter: { activity_start, activity_end } } = searchItem

          return {
            ...searchItem,
            filter: {
              ...filter,
              activity_start: activity_start ? moment(activity_start) : null,
              activity_end: activity_end ? moment(activity_end) : null
            }
          }
        }),
        status: statusType.SUCCESS
      }

    case GET_TARGETING_SAVED_SEARCH_FAILURE:
      return {
        error: action.error,
        status: statusType.ERROR
      }

    case CREATE_TARGETING_SAVED_SEARCH_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case CREATE_TARGETING_SAVED_SEARCH_SUCCESS:
      return {
        ...state,
        type: action.type,
        search: action.payload,
        status: statusType.SUCCESS
      }

    case CREATE_TARGETING_SAVED_SEARCH_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case UPDATE_TARGETING_SAVED_SEARCH_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case UPDATE_TARGETING_SAVED_SEARCH_SUCCESS:
      return {
        ...state,
        type: action.type,
        search: action.payload,
        status: statusType.SUCCESS
      }

    case UPDATE_TARGETING_SAVED_SEARCH_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case REMOVE_TARGETING_SAVED_SEARCH_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case REMOVE_TARGETING_SAVED_SEARCH_SUCCESS:
      const data = [].concat(state.data).filter((filter) => filter._id !== (action.payload && action.payload._id))

      return {
        ...state,
        type: action.type,
        data,
        status: statusType.SUCCESS
      }

    case REMOVE_TARGETING_SAVED_SEARCH_FAILURE:
      return {
        error: action.error,
        status: statusType.ERROR
      }

    case RESET_TARGETING_SAVED_SEARCH:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default savedSearch
