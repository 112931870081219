import React, { Component } from 'react'
import moment from 'moment-timezone'
import './upcomingActivitiesItem.component.css'
import { getLocalizedFormat } from '../../../../utils'

export default class UpcomingActivitiesItem extends Component {

  /**
   * Get icon based on activity category
   * @param category
   * @return {string}
   */
  getIconStyle = (category) => {
    switch (category) {
      case 'comment':
        return 'q4i-note-4pt'
      case 'phone':
        return 'q4i-phone-4pt'
      case 'email':
        return 'q4i-mail-4pt'
      case 'meeting':
        return 'q4i-meeting-4pt'
      case 'earnings':
        return 'q4i-earnings-4pt'
      case 'other':
        return 'q4i-report-blank-4pt'
      case 'roadshow':
        return 'q4i-roadshow-4pt'
      case 'conference':
        return 'q4i-conference-4pt'
      default:
        return ''
    }
  }

  /**
   * Render Moment Date
   * @param date
   * @param format
   * @returns {XML}
   */
  renderMomentDate = (date, format) => {
    const localFormat = getLocalizedFormat(format)
    return moment(date).format(localFormat)
  }

  /**
   * Render Activity item's date
   * @param item
   * @returns {*}
   */
  renderActivityDate = (item) => {
    switch (item.category) {
      case 'roadshow':
      case 'conference':
        if (moment(item.start.date_time).isSame(moment(item.end.date_time), 'day')) {
          return (
            <span className='upcoming-activities-item_date'>
                        {this.renderMomentDate(item.start.date_time, 'dddd, MMM D, YYYY')}
                      </span>
          )
        } else {
          return (<div className='upcoming-activities-item_multi-date'>
                        <span className='upcoming-activities-item_date'>
                            {this.renderMomentDate(item.start.date_time, 'dddd, MMM D, YYYY')}
                        </span>
            <span className='upcoming-activities-item_date'>
                            {this.renderMomentDate(item.end.date_time, 'dddd, MMM D, YYYY')}
                        </span>
          </div>)
        }
      case 'comment':
      case 'phone':
      case 'email':
      case 'meeting':
      case 'earnings':
        const startDate = item && item.start && moment.utc(item.start.date_time)
        const endDate = item && item.end && moment.utc(item.end.date_time)
        if (moment(startDate).isSame(moment(endDate), 'day')) {
          return <span className='upcoming-activities-item_date'>
                                {this.renderMomentDate(item.start.date_time, 'dddd, MMM D, YYYY h:mm a')}
                           </span>
        } else {
          return (<div className='upcoming-activities-item_multi-date'>
                            <span className='upcoming-activities-item_date'>
                                 {this.renderMomentDate(item.start.date_time, 'dddd, MMM D, YYYY h:mm a')}
                            </span>
            <span className='upcoming-activities-item_date'>
                                 {this.renderMomentDate(item.end.date_time, 'dddd, MMM D, YYYY h:mm a')}
                            </span>
          </div>)
        }
      default:
        return null
    }
  }

  /**
   * Render UpcomingActivitiesItem Component
   * @returns {XML}
   */
  render () {
    const { item, onItemClick } = this.props

    return (
      <li className='upcoming-activities-item q4-fade-in' onClick={() => {
        onItemClick(item._id)
      }}>
        <div className={`upcoming-activities-item_box upcoming-activities-item_box--${item.category}`}/>
        <i className={`icon upcoming-activities-item_icon ${this.getIconStyle(item.category)}`}/>
        <div className='upcoming-activities-item_content'>
          <h3 className='upcoming-activities-item_title'>{item.title}</h3>
          {this.renderActivityDate(item)}
        </div>
      </li>
    )
  }
}
