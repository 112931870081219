import {
  GET_ENTITY_OWNERSHIP_ACTIVITY_REQUEST,
  GET_ENTITY_OWNERSHIP_ACTIVITY_SUCCESS,
  GET_ENTITY_OWNERSHIP_ACTIVITY_FAILURE,
  RESET_ENTITY_OWNERSHIP_CHART_DATA,
  statusType
} from '../../../actions'

const moment = require('moment-timezone')

/**
 * Entity Ownership Chart - Activity Reducer
 * @param state
 * @param action
 * @returns
 * {
 *    [entityId]: {
 *      data: [],
 *      status: 'SUCCESS'
 *    },
 *    [entityId]: {
 *      data: [],
 *      status: 'SUCCESS'
 *    }
 * }
 */
const activity = (state = {}, action) => {
  const { payload, request } = action

  switch (action.type) {
    case GET_ENTITY_OWNERSHIP_ACTIVITY_REQUEST:
      return {
        ...state,
        [_getEntityId(payload)]: {
          status: statusType.IN_PROGRESS
        }
      }

    case GET_ENTITY_OWNERSHIP_ACTIVITY_SUCCESS:
      return {
        ...state,
        [_getEntityId(request)]: {
          data: (payload || [])
            .filter((record) => record.start && record.start.date_time)
            .map((record) => ({
              x: moment.utc(record.start.date_time).valueOf(),
              title: ' ',
              text: record.title,
              id: record._id,
              category: record.category
            }))
            .reverse(),
          status: statusType.SUCCESS
        }
      }

    case GET_ENTITY_OWNERSHIP_ACTIVITY_FAILURE:
      return {
        ...state,
        [_getEntityId(request)]: {
          status: statusType.ERROR
        }
      }

    case RESET_ENTITY_OWNERSHIP_CHART_DATA:
      return {
        ...state,
        [_getEntityId(payload)]: undefined
      }

    default:
      return state
  }
}

export default activity

/**
 * Get entity id from payload
 * If chart is for contact, contactId will be provided otherwise use entityId
 * @param payload
 */
const _getEntityId = (payload) => {
  return payload && (payload.contactId || payload.entityId)
}
