import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import { get } from 'lodash'

// actions
import { useInstitutionQuery, useTargetQuery, useDealQuery, usePipelineQuery } from './hook'
import { getInstitutionAnalytics, openModal, statusType } from '../../../../../../actions'

// components
import { SectionTab } from '../../../../../../components/shared'
import {
  ActivityGrid,
  ContactGrid,
  CurrentHoldingGrid,
  FundGrid,
  PeerAnalysisGrid,
  Spinner
} from '../../../../../../components'
import InstitutionBanner from './banner/banner.component'
import InstitutionOverview from './overview/overview.component'

import { ENTITY_TYPE, THEMES } from '../../../../../../utils'

const dataIdPrefix = 'InstitutionFlyout'

const propTypes = {}
const defaultProps = {
  theme: THEMES.LIGHT
}

const { INSTITUTION } = ENTITY_TYPE
const TABS = {
  CONTACT: 'contact',
  ACTIVITY: 'activity',
  FUND: 'fund',
  CURRENT: 'current',
  PEER: 'peer'
}

/**
 * Institution Profile Flyout
 */
function InstitutionFlyout (props) {
  const {
    theme, isAnalytics, getInstitutionAnalytics, isTargetSuccess, openModal, onClose,
    q4_entity_id: entityId, factset_entity_id, _id, handleRedirect
  } = props

  const { loading, error, data } = useInstitutionQuery({
    variables: { id: entityId }
  })

  const institution = get(data, 'institution.items[0]', {})
  const { id } = institution

  const [getTarget, { data: target }] = useTargetQuery({
    variables: { entityId: id }
  })

  const { loading: isPipeline, data: stages } = usePipelineQuery()
  const [getDeals, { loading: isDeals, data: deal }] = useDealQuery({
    variables: { entityId: id }
  })

  const targetId = get(target, 'target.items[0].id', null)
  const deals = get(deal, 'deal.items', [])
  const pipeline = get(stages, 'pipeline.items', [])
  const lost = pipeline.find((item) => item.lost)
  const activeDeal = deals.find((deal) => deal.stage !== (lost && lost.id))

  useEffect(() => {
    if (!id) {
      return
    }

    getTarget()
    getDeals()
    getInstitutionAnalytics(id)
  }, [id, getTarget, getDeals, getInstitutionAnalytics])

  useEffect(() => {
    isTargetSuccess && getTarget()
  }, [isTargetSuccess, getTarget])

  const handleDealUpdate = () => {
    getTarget()
    getDeals()
  }

  /**
   * Render Institution Flyout
   */
  return (
    <>
      {(error || (data && !id)) && <Redirect to='/error/404' />}
      {loading && <Spinner mask theme={theme} />}
      {!!id && (
        <div className='institution-flyout'>
          <InstitutionBanner
            theme={theme}
            dataIdPrefix={dataIdPrefix}
            // TODO remove factset_entity_id after search migrate
            // TODO remove _id after deal purchasing power migration
            institution={{ ...institution, factset_entity_id, _id }}
            targetId={targetId}
            hasActiveDeal={!!activeDeal}
            onDealSaveSuccess={handleDealUpdate}
            openModal={openModal}
            onClose={onClose}
          />
          <InstitutionOverview
            theme={theme}
            dataIdPrefix={dataIdPrefix}
            institution={institution}
            targetId={targetId}
            deals={{
              isLoading: [isDeals, isPipeline].some((item) => !!item),
              deals,
              pipeline
            }}
            onDealSaveSuccess={handleDealUpdate}
            isAnalytics={isAnalytics}
            openModal={openModal}
            handleRowClick={({ contactId }) => {
              handleRedirect({ contactId, entityType: 'contact' })
            }}
          />

          <SectionTab
            theme={THEMES.RAIN}
            tabs={[
              {
                id: TABS.CONTACT,
                label: 'Contacts',
                view: (
                  <ContactGrid
                  handleRowClick={({ contactId }) => {
                    handleRedirect({ contactId, entityType: 'contact' })
                  }}
                    dataIdPrefix={dataIdPrefix}
                    entityId={id}
                  />
                )
              },
              {
                id: TABS.ACTIVITY,
                label: 'Activity',
                view: (
                  <ActivityGrid
                    dataIdPrefix={dataIdPrefix}
                    entityId={id}
                    entityType={INSTITUTION}
                    // TODO remove factset_fund_id after search migrate
                    entity={{ ...institution, factset_entity_id }}
                    showChildren
                  />
                )
              },
              {
                id: TABS.FUND,
                label: 'Funds',
                view: (
                  <FundGrid
                    dataIdPrefix={dataIdPrefix}
                    entityId={id}
                  />
                )
              },
              {
                id: TABS.CURRENT,
                label: 'Current Holdings',
                view: (
                  <CurrentHoldingGrid
                    dataIdPrefix={dataIdPrefix}
                    entityId={id}
                  />
                )
              },
              {
                id: TABS.PEER,
                label: 'Peer Analysis',
                view: (
                  <PeerAnalysisGrid
                    dataIdPrefix={dataIdPrefix}
                    entityId={id}
                  />
                )
              }
            ]}
          />
        </div>
      )}
    </>
  )
}

InstitutionFlyout.propTypes = propTypes
InstitutionFlyout.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  isTargetSuccess: get(state, 'targeting.target.status') === statusType.SUCCESS,
  isAnalytics: get(state, 'institution.analytics.visited')
})

const mapDispatchToProps = (dispatch) => ({
  getInstitutionAnalytics: bindActionCreators(getInstitutionAnalytics, dispatch),
  openModal: bindActionCreators(openModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionFlyout)
