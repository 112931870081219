import './grid.container.scss'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LEVEL } from '../../../../../../src/hook'

// actions
import { useCurrentHolderQuery, GET_FUND_CURRENT_HOLDERS, GET_INST_CURRENT_HOLDERS_EXPORT } from '../../../hook'
import { openModal, modalType } from '../../../../../actions'
import { useExport } from '../../../../../services/csvExport/csvExport.service'

// components
import CurrentHolderSidebar from './sidebar/sidebar.component'
import CurrentHolderToolbar from './toolbar/toolbar.component'
import CurrentHolderTable from './table/table.component'

// utils
import { 
  getPageSizeFromStorage, 
  getTreeHierarchy, 
  formatDate, 
  EXPORT_DATE_FORMAT, 
  ENTITY_TYPE, 
  HOLDING_TYPE, 
  format, 
  getLocalizedCurrency,
  formatTitleCase,
  HOLDER_TYPE,
  getLabelByValue
} from '../../../../../utils'

import { get, isNil, omitBy, pick } from 'lodash'

const { FUND, INSTITUTION } = ENTITY_TYPE

const { STAKES, INSTITUTIONS } = HOLDING_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string,
  toolbarTheme: PropTypes.string,
  toolTheme: PropTypes.string,
  tickerId: PropTypes.string.isRequired,
  history: PropTypes.object,
  persistence: PropTypes.object,
}

const defaultProps = {}

const PAGE_SIZE_ID = 'current-holder-grid'
const ZERO = '0.00'

/**
 * Current Holder Grid Component
 * @param props
 */
function CurrentHolderGrid (props) {
  const { dataIdPrefix, toolbarTheme, toolTheme, tickerId, history, openModal, persistence } = props

  const [isSidebar, setIsSidebar] = useState(!!persistence.getGridFiltersDecoded() || false)
  const [holderType, setHolderType] = useState(persistence.getGridWithDefault(LEVEL.GRID_SUBTAB, 'all'))
  const entityType = (holderType === FUND) ? FUND : INSTITUTION

  const [state, setState] = useState(persistence.getGridFiltersDecoded({
    search: null,
    filter: {
      isActivist: false,
      position: null,
      style: null,
      turnover: null,
      type: null,
      activity: 'all',
      startDate: null,
      endDate: null
    },
    listOptions: {
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
  }));
  const { search, filter, listOptions } = state
  const { isActivist, position, activity, startDate, endDate } = filter

  const holdingType = []
  holderType === 'insider' && holdingType.push(STAKES)
  holderType === INSTITUTION && holdingType.push(INSTITUTIONS)

  const variables = omitBy({
    tickerId,
    search,
    position,
    currencyCode: getLocalizedCurrency(),
    excludeStalePositions: true,
    holdingType: holdingType.length ? holdingType : null,
    isActivist: ['all', INSTITUTION].includes(holderType) ? isActivist : null,
    activity: activity === 'yes' ? true : activity === 'no' ? false : undefined,
    startDate: startDate && formatDate(startDate, EXPORT_DATE_FORMAT),
    endDate: endDate && formatDate(endDate, EXPORT_DATE_FORMAT),
    ...pick(filter, ['style', 'turnover', 'type']),
    ...listOptions
  }, isNil)

  const { data, error, loading, client, refetch } = useCurrentHolderQuery(entityType, { variables })
  const { generateExport, exporting } = useExport({
    onError: () => {
      openModal({
        type: modalType.ERROR_MODAL
      })
    }
  })

  const source = (entityType === FUND) ? FUND : 'inst'
  const holders = getTreeHierarchy(
    get(data, `${source}HoldingCurrent.items`, []),
    'fundHoldingCurrentConnection.items'
  )
  const total = get(data, `${source}HoldingCurrent.count`, 0)

  /**
   * Handle query change
   * @param query
   */
  const handleQueryChange = (query = {}) => {
    const options = query.listOptions ? { ...query } : { ...query, listOptions: { ...listOptions, page: 1 } }
    setState({ ...state, ...options })
    persistence.setGridFilters({ ...state, ...options })
  }

  /**
   * Format mapper for CSV export
   * @param holder
   */
  const holdersCSVMapper = (holder, index) => {
    const {
      current, change, marketValue, marketValueChange,
      percentTSO, percentPortfolio, reportDate, filingSource, filingType
    } = holder
    const lastActivity = get(holder, 'activityConnection.items[0].start.date', null)
    const rank = index + 1
    const sourceData = holderType === FUND ? filingType : filingSource
    
    const dynamicKeys = {
      name: `${entityType}Name`,
      style: `${entityType}Style`,
      type: `${entityType}Type`,
      turnover: `${entityType}Turnover`,
      AUM: (entityType === FUND) ? `${entityType}PortfolioValue` : `${entityType}TotalAUM`,
      equityAUM: `${entityType}EquityAUM`,
      qualityRating: `${entityType}QualityRating`
    }

    return holderType === 'insider'
      ? {
          '#': rank,
          'Holder Name': get(holder, dynamicKeys.name),
          Position: current,
          Change: change,
          'Market Value': marketValue,
          'Market Value Change': marketValueChange || '',
          '%OS': percentTSO ? format(percentTSO, 2) : ZERO,
          'Type': get(holder, dynamicKeys.type),
          'AUM $(MM)': get(holder, dynamicKeys.AUM),
          'As Of': reportDate ? formatDate(reportDate, EXPORT_DATE_FORMAT, true, true) : null,
          Source: sourceData ? sourceData?.toUpperCase() : null
        }
      : {
          '#': rank,
          'Holder Name': get(holder, dynamicKeys.name),
          Position: current,
          Change: change,
          [`Market Value (${getLocalizedCurrency()})`]: marketValue,
          [`Market Value Change (${getLocalizedCurrency()})`]: marketValueChange || '',
          '%OS': percentTSO ? format(percentTSO, 2) : ZERO,
          '%PORT': percentPortfolio ? format(percentPortfolio, 2) : ZERO,
          Style: get(holder, dynamicKeys.style),
          Turnover: get(holder, dynamicKeys.turnover),
          Type: getLabelByValue(HOLDER_TYPE, get(holder, dynamicKeys.type)),
          [`AUM (${getLocalizedCurrency()})`]: get(holder, dynamicKeys.AUM),
          [`Equity AUM (${getLocalizedCurrency()})`]: get(holder, dynamicKeys.equityAUM),
          'As Of': reportDate ? formatDate(reportDate, EXPORT_DATE_FORMAT, true, true) : null,
          'Quality Rating': get(holder, dynamicKeys.qualityRating),
          'Last Activity': lastActivity ? formatDate(lastActivity, EXPORT_DATE_FORMAT, true, true) : null,
          Source: sourceData ? formatTitleCase(sourceData) : null
        }
  }

  /**
   * Handle export
   */
  const handleExport = () => {
    const params = {
      client,
      variables: { ...variables, limit: 0 },
      query: (entityType === FUND) ? GET_FUND_CURRENT_HOLDERS : GET_INST_CURRENT_HOLDERS_EXPORT,
      dataPath: `data.${source}HoldingCurrent.items`,
      fileName: `${source}_current_holders.csv`,
      formatter: holdersCSVMapper
    }

    generateExport(params)
  }

  /**
   * handle set holder type
   * @param {string} value
   */
  const handleSetHolderType = (value) => {
    setHolderType(value)
    persistence.setGridSubTab(value)
    handleQueryChange({ listOptions: { page: 1, limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10 } })
  }

  return (
    <div className='current-holder-grid grid--sidebar'>
      <CurrentHolderSidebar
        dataId={`${dataIdPrefix}CurrentHolders`}
        isSidebar={isSidebar}
        filter={{ ...filter }}
        holderType={holderType}
        onQueryChange={handleQueryChange}
      />
      <div className='grid_body'>
        <CurrentHolderToolbar
          dataId={`${dataIdPrefix}CurrentHolders`}
          toolbarTheme={toolbarTheme}
          toolTheme={toolTheme}
          holderType={holderType}
          search={search}
          noData={!(holders || []).length}
          onSidebarToggle={() => setIsSidebar(!isSidebar)}
          collapsed={isSidebar}
          onHolderChange={(holderType) => handleSetHolderType(holderType)}
          onQueryChange={handleQueryChange}
          onExport={handleExport}
          exporting={exporting}
        />
        <CurrentHolderTable
          pageSizeId={PAGE_SIZE_ID}
          loading={loading}
          data={holders}
          total={total}
          listOptions={listOptions}
          onQueryChange={handleQueryChange}
          holderType={holderType}
          history={history}
          error={error}
          onRefresh={refetch}
        />
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

CurrentHolderGrid.propTypes = propTypes
CurrentHolderGrid.defaultProps = defaultProps

export default withRouter(connect(mapDispatchToProps)(CurrentHolderGrid))
