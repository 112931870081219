import {
  GET_FUND_PURCHASING_POWER_REQUEST,
  GET_FUND_PURCHASING_POWER_SUCCESS,
  GET_FUND_PURCHASING_POWER_FAILURE,
  statusType
} from '../../../actions'

const initial = {
  data: null,
  status: statusType.IDLE
}

const fundPurchasingPowerReducer = (state = initial, action) => {
  switch (action.type) {
    case GET_FUND_PURCHASING_POWER_REQUEST:
      return {
        ...state,
        data: action.payload,
        status: statusType.IN_PROGRESS
      }
    case GET_FUND_PURCHASING_POWER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: statusType.SUCCESS
      }
    case GET_FUND_PURCHASING_POWER_FAILURE:
      return {
        ...state,
        data: action.payload,
        status: statusType.ERROR
      }
    default:
      return state
  }
}

export default fundPurchasingPowerReducer
