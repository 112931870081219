import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { AgGrid, PopoverMenu } from '../../../../../index'
import CustomCell from './cell/cell.component'

import { preventDefaultRowClick, setColumnDefinition } from '../../../../../../utils'

const columns = [
  {
    field: 'fund_name',
    headerName: 'Name',
    minWidth: 450,
    pinned: 'left',
    lockPinned: true,
    headerCheckboxSelection: false,
    checkboxSelection: false
  },
  {
    type: 'number',
    field: 'current',
    headerName: 'Pos',
    minWidth: 180,
    maxWidth: 220
  },
  {
    field: 'contact',
    headerName: 'Contacts',
    minWidth: 180,
    maxWidth: 220,
    sortable: false
  },
  {
    type: 'centered',
    field: 'style',
    headerName: 'Style',
    minWidth: 130,
    maxWidth: 220
  },
  {
    type: 'centered',
    field: 'turnover',
    headerName: 'Turnover',
    minWidth: 130,
    maxWidth: 220
  },
  {
    type: 'number',
    field: 'portfolio_value',
    headerName: 'AUM ($MM)',
    minWidth: 100,
    maxWidth: 120
  },
  {
    type: 'number',
    field: 'equity_aum',
    headerName: 'EAUM ($MM)',
    minWidth: 100,
    maxWidth: 120
  },
  {
    type: 'date',
    field: 'report_date',
    headerName: 'As Of',
    minWidth: 120,
    maxWidth: 140
  },
  {
    type: 'centered',
    field: 'aiScore',
    headerName: 'ai',
    minWidth: 60,
    maxWidth: 80
  },
  {
    type: 'number',
    field: 'purchasingPower',
    headerName: 'Purchasing Power',
    minWidth: 150,
    maxWidth: 170
  },
  {
    type: 'icon',
    field: 'quality_rating',
    headerName: 'QR',
    minWidth: 60,
    maxWidth: 80
  },
  {
    type: 'date',
    field: 'lastActivity',
    headerName: 'Last Activity',
    minWidth: 120,
    maxWidth: 140,
    sortable: false
  }
]

class FundTable extends PureComponent {

  constructor (props) {
    super(props)

    this.state = {
      popoverMenuProps: null
    }
  }

  componentWillUnmount () {
    clearTimeout(this.interval)
  }

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  handleGridReady = (grid) => {
    this.interval = setTimeout(() => this.agGrid = grid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      this.interval = setTimeout(() => this.agGrid && this.agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click
   * @param data
   */
  handleRowClick = (row) => {
    if (!row) {
      return
    }

    const { handleRowClick } = this.props
    handleRowClick(row)
  }

  /**
   * Renders CustomCell Component
   */
  renderCustomCell = (props) => {
    return (
      <CustomCell
        {...props}
        onExpandMenuClick={this.onExpandMenuClick}
        onExpandMenuItemClick={this.onExpandMenuItemClick}
      />
    )
  }

  /**
   * On Expand Menu Click
   * Displays expandable menu for contact/institutions/participants
   * @param event
   * @param popoverMenuProps
   */
  onExpandMenuClick = (event, popoverMenuProps) => {
    event.stopPropagation()
    this.setState({ popoverMenuProps })
  }

  /**
   * onExpandMenuItemClick
   * @param event
   * @param contact
   */
  onExpandMenuItemClick = (event, contact) => {
    const { handlePopoverItemClick } = this.props
    handlePopoverItemClick && handlePopoverItemClick({ contactId: contact._id })
  }

  /**
   * On Expand Menu Close Click
   * @param event
   */
  onExpandMenuCloseClick = (event) => {
    event.stopPropagation()
    this.setState({ popoverMenuProps: null })
  }

  /**
   * Handle onSortChanged event (multi-sort included)
   * @param grid
   */
  handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel || !sortModel[0]) {
      return
    }

    const sortParams = sortModel[0]
    this.props.handleSortChange({ property: sortParams.colId, direction: sortParams.sort })
  }

  render () {
    const { data, dataTotal, query, handleQueryChange, pageSizeId } = this.props

    return (
      <div className='fund-grid_table'>
        <AgGrid

          className='funds-table'
          domLayout='autoHeight'
          sizeToFit={true}

          // suppress configs
          suppressMovableColumns
          suppressContextMenu
          enableSorting={false}

          // columns and data
          columnDefs={setColumnDefinition({ columns })}
          rowData={data}

          defaultColDef={{
            sortable: true,
            suppressMenu: true,
            cellRenderer: 'CustomCellRender'
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: this.renderCustomCell
          }}

          pagination
          paginationProps={{
            pageSizeId,
            forcePage: query.page,
            initialPageSize: query.limit,
            showPageSizeSelection: true,
            total: dataTotal,
            onPageChange: ({ selected: newPage }) => handleQueryChange({ page: newPage }),
            onPageSizeChange: ({ selected: newPageSize }) => handleQueryChange({ limit: newPageSize, page: 1 })
          }}

          // event listeners
          onSortChanged={this.handleSortChange}
          onGridReady={this.handleGridReady}
          onGridSizeChanged={this.handleGridResize}
          onRowClicked={preventDefaultRowClick(
            this.handleRowClick,
            ['expandable-cell']
          )}

          isPinned={data && data.length}
        />

        {/* Popover Menu for Custom Cell */}
        {this.state.popoverMenuProps && (
          <PopoverMenu
            onClick={this.onExpandMenuItemClick}
            onClose={this.onExpandMenuCloseClick}
            scrollable={true}
            {...this.state.popoverMenuProps}
          />
        )}
      </div>)
  }
}

FundTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    fund_name: PropTypes.string,
    current: PropTypes.number,
    address: PropTypes.array,
    style: PropTypes.string,
    turnover: PropTypes.string,
    portfolio_value: PropTypes.number,
    equity_aum: PropTypes.number,
    report_date: PropTypes.string,
    quality_rating: PropTypes.number
  })),
  dataTotal: PropTypes.number,
  query: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  handleQueryChange: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
  pageSizeId: PropTypes.string
}

FundTable.defaultProps = {
  data: [],
  dataTotal: 0,
  query: {}
}

export default FundTable
