import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// components
import { Button, Checkbox } from '../../../index'
import TemplateCover from './cover/cover.component'
import TemplateItems from './item/item.component'

// utils
import { getClassName, EXPORT } from '../../../../utils'
import { get } from 'lodash'

import './option.component.scss'

const { PDF } = EXPORT

const propTypes = {
  dataId: PropTypes.string,
  entityType: PropTypes.string,
  isItinerary: PropTypes.bool,
  cover: PropTypes.object,
  settings: PropTypes.array.isRequired,
  templates: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onCoverChange: PropTypes.func.isRequired
}

const defaultProps = {
  settings: [],
  templates: []
}

/**
 * Template Options
 * @param props
 */
function TemplateOptions (props) {
  const { dataId, entityType, isItinerary, cover, templates, settings, onChange, onCoverChange } = props

  const selectedSettings = settings.find((item) => item.isSelected)
  const template = (templates || []).find((template) => template.id === get(selectedSettings, 'templateId'))

  if (!template) {
    return null
  }

  return (
    <Grid container spacing={3} className='tearsheet-export-modal_option'>
      <Grid item xs={12}>
        {(template.output || []).map((item) => <Button
          dataId={`${dataId}${item.toUpperCase()}`}
          key={`${item}`}
          className={getClassName(`button-output--${item}`, [
            { condition: (item === get(selectedSettings, 'output')), trueClassName: `button-output--${item}--selected` }
          ])}
          tall
          icon={`q4i-${item === PDF ? item : 'table'}-4pt`}
          label={item}
          onClick={() => onChange({ output: item })}
        />)}
      </Grid>
      <Grid item xs={12} className='tearsheet-export-modal_option-label'>Include:</Grid>
      {(get(selectedSettings, 'output') === PDF) && <>
        <Grid item xs={12}>
          <TemplateCover
            dataId={dataId}
            cover={cover}
            settings={selectedSettings}
            onChange={onChange}
            onCoverChange={onCoverChange}
          />
        </Grid>
        {isItinerary && <Grid item xs={12} className='tearsheet-export-modal_option-items'>
          <Checkbox
            dataId={`${dataId}Itinerary`}
            id='itinerary'
            label='Itinerary'
            isSelected={get(selectedSettings, 'itinerary')}
            onChange={(value) => onChange({ ...selectedSettings, itinerary: value })}
          />
          <hr className='field-separator field-separator--short' />
        </Grid>}
      </>}
      <Grid item xs={12} className='tearsheet-export-modal_option-items'>
        <TemplateItems
          dataId={dataId}
          entityType={entityType}
          template={template}
          settings={selectedSettings}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  )
}

TemplateOptions.propTypes = propTypes
TemplateOptions.defaultProps = defaultProps

export default memo(TemplateOptions)
