import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Location,
  QualityRating,
  TargetType,
  AUM,
  EquityAUM,
  PurchasingPower,
  Style,
  Turnover,
  Type,
  Ownership,
  PeerOwnership,
  PeerActivity,
  ExcludeActivist,
  AITarget,
  LoggedActivity
} from './components'

import './filter.component.css'

/**
 * Filter Component
 */
class Filter extends Component {

  /**
   * Check if the form valid any time the value has been changed
   * @param id
   * @param isValid
   */
  onValidate = (id, isValid) => {
    const { onValidate, filter } = this.props
    const validationErrors = {
      ...filter.errors,
      [id]: !isValid
    }

    onValidate && onValidate(Object.keys(validationErrors)
      .map((each) => {
        return !validationErrors[each]
      })
      .every((each) => each)
    )
  }

  /**
   * Update filter object and set current filters
   * @param item
   */
  onFilterChange = (item) => {
    const { handleFilterChange, filter } = this.props
    const isInvestmentStyle = Object.keys(item || {}).includes('investment_style')

    // Reducer sets default styleOptions automatically if investment style is selected
    const filters = isInvestmentStyle
      ? Object.keys(filter || {})
        .filter(item => item !== 'styleOptions')
        .reduce((newFilters, item) => {
          return {
            ...newFilters,
            [item]: filter[item]
          }
        }, {})
      : filter

    handleFilterChange({ ...filters, ...item })
  }

  /**
   * Render
   * @returns {*}
   */
  render () {
    const {
      filter,
      searchLocations, resetLocationSuggestions, isSearchLocationsLoading,
      searchSecurities, resetSecuritySuggestions, isSearchSecuritiesLoading, peers, getPeers, resetPeers,
      openModal, aiTargetingSubscription, region, onError
    } = this.props
    const { errors } = filter

    return (
      <React.Fragment>
        <section className='targeting-page_sidebar-filter'>

          <Location
            value={filter.location}
            suggestions={filter.locationSuggestions}
            searchLocations={searchLocations}
            resetLocationSuggestions={resetLocationSuggestions}
            loading={isSearchLocationsLoading}
            onChange={(value) => this.onFilterChange({ location: value })}/>

          <TargetType
            value={filter.target_type}
            onChange={(value) => this.onFilterChange({ target_type: value })}/>

          <QualityRating
            value={filter.quality_rating}
            error={errors.quality_rating}
            onChange={(value) => this.onFilterChange({ quality_rating: value })}
            onError={(error) => onError('quality_rating', error)}
            onValidate={(error) => this.onValidate('quality_rating', error)}
            openModal={openModal}/>

          <AUM
            value={filter.aum}
            error={errors.aum}
            onChange={(value) => this.onFilterChange({ aum: value })}
            onError={(error) => onError('aum', error)}
            onValidate={(error) => this.onValidate('aum', error)}
            openModal={openModal}/>

          <EquityAUM
            value={filter.equity_aum}
            error={errors.equity_aum}
            onChange={(value) => this.onFilterChange({ equity_aum: value })}
            onError={(error) => onError('equity_aum', error)}
            onValidate={(error) => this.onValidate('equity_aum', error)}
            openModal={openModal}/>

          {region === 'north_america' && <PurchasingPower
            value={filter.purchasing_power}
            error={errors.purchasing_power}
            onChange={(value) => this.onFilterChange({ purchasing_power: value })}
            onError={(error) => onError('purchasing_power', error)}
            onValidate={(error) => this.onValidate('purchasing_power', error)}
            openModal={openModal}/>}

        </section>

        <section className='targeting-page_sidebar-filter'>
          <Style
            value={filter.investment_style}
            onChange={(value) => this.onFilterChange({ investment_style: value })}
            options={filter.styleOptions}
            onStyleChange={this.onFilterChange}
            openModal={openModal}/>

          <Turnover
            options={filter.turnoverOptions}
            onTurnoverChange={this.onFilterChange}/>

          <Type
            options={filter.typeOptions}
            onTypeChange={this.onFilterChange}/>
        </section>

        <section className='targeting-page_sidebar-filter'>

          <Ownership
            value={filter.owns_security}
            suggestions={filter.securitySuggestions}
            peers={peers}
            loading={isSearchSecuritiesLoading}
            searchSecurities={searchSecurities}
            resetSecuritySuggestions={resetSecuritySuggestions}
            onChange={this.onFilterChange}/>

          <PeerOwnership
            value={filter.peers}
            getPeers={getPeers}
            resetPeers={resetPeers}
            onChange={(value) => this.onFilterChange({ peers: value })}/>

        </section>

        <section className='targeting-page_sidebar-filter'>

          <PeerActivity
            value={filter.peer_activity}
            onChange={(value) => this.onFilterChange({ peer_activity: value })}
            openModal={openModal}/>

        </section>

        <section className='targeting-page_sidebar-filter'>

          <ExcludeActivist
            value={filter.exclude_activists}
            onChange={(value) => this.onFilterChange({ exclude_activists: value })}/>

          {aiTargetingSubscription ? <AITarget
            value={filter.ai_only}
            onChange={(value) => this.onFilterChange({ ai_only: value })}/> : null}

        </section>

        <section className='targeting-page_sidebar-filter'>

          <LoggedActivity
            value={filter.logged_activity}
            startDate={filter.activity_start}
            endDate={filter.activity_end}
            onChange={(value) => this.onFilterChange({ logged_activity: value })}
            onDateRangeChange={(dates) => this.onFilterChange({
              activity_start: dates.startDate,
              activity_end: dates.endDate
            })}/>

        </section>
      </React.Fragment>
    )
  }
}

Filter.propTypes = {
  region: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  searchLocations: PropTypes.func.isRequired,
  resetLocationSuggestions: PropTypes.func.isRequired,
  isSearchLocationsLoading: PropTypes.bool,
  searchSecurities: PropTypes.func.isRequired,
  resetSecuritySuggestions: PropTypes.func.isRequired,
  isSearchSecuritiesLoading: PropTypes.bool,
  peers: PropTypes.array,
  getPeers: PropTypes.func.isRequired,
  resetPeers: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  aiTargetingSubscription: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  onError: PropTypes.func
}

Filter.defaultProps = {
  aiTargetingSubscription: false
}

export default Filter
