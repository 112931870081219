import '../placeholderMessage/placeholderMessage.doc'
import React, { memo } from 'react'
import PlaceholderMessage from '../placeholderMessage/placeholderMessage.component'

/**
 * No Content / Empty List CTA Message Component
 * @param {PlaceholderMessageProps} props
 * @returns {JSX.Element}
 */
function NoContentMessage (props) {

  return (
    <PlaceholderMessage {...props} />
  )
}

NoContentMessage.propTypes = PlaceholderMessage.prototype

NoContentMessage.defaultProps = {
  image: require('../../../resources/images/noContent/report_no_color.png').default,
  title: 'No Data Available'
}

export default memo(NoContentMessage)
