import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/react-hooks'

const GET_SEARCH_RESULTS = gql`
  query AdvancedSearch(
    $query: String,
    $entity: [String],
    $watchlist: Boolean,
    $page: Int,
    $limit: Int,
    $useElasticSearch: Boolean
  ) {
    advancedSearch(
      query: $query,
      entity: $entity,
      filter: { 
        includePeers: $watchlist 
      },
      page: $page,
      limit: $limit,
      useElasticSearch: $useElasticSearch
    ) {
      count
      items {
        id
        score
        entityConnection {
          ... on TranscriptSearchDTO {
            entity {
              companyName
              eventTitle
              eventDate
              transcriptUrl
            }
            eventId
            transcriptHighlight
          }
        }
      }
    }
  }
`
export function useAdvancedSearchQuery () {
  return useLazyQuery(GET_SEARCH_RESULTS, {
    fetchPolicy: 'no-cache'
  })
}
