import './grid.component.css'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

// components
import CustomCell from './cell/cell.component'
import { AgGrid, PopoverMenu, Spinner, UtilityMenu } from '../../../../../components'
import withOptions from '../../../../../components/utilityMenu/helper/withOptions'

// utils
import { getDefaultColumnDef, preventDefaultRowClick } from '../../../../../utils/agGrid.util'

import { THEMES } from '../../../../../utils/ui'
import { allGridBulkActions } from '../../../../../utils'

const MenuWithOptions = withOptions(UtilityMenu)

class TargetingGrid extends PureComponent {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      popoverMenuProps: null,
      utility: null,
      columns: [
        { field: 'name', headerName: 'Name', minWidth: 500, pinned: 'left', lockPinned: true, headerCheckboxSelection: true, checkboxSelection: true },
        { field: 'address', headerName: 'Location', minWidth: 180, maxWidth: 220 },
        { type: 'centered', field: 'style', headerName: 'Style', minWidth: 130, maxWidth: 220 },
        { type: 'centered', field: 'turnover', headerName: 'Turnover', minWidth: 130, maxWidth: 220 },
        { type: 'numericColumn', field: 'total_aum', headerName: 'AUM ($MM)', minWidth: 100, maxWidth: 120 },
        { type: 'numericColumn', field: 'equity_aum', headerName: 'EAUM ($MM)', minWidth: 100, maxWidth: 120 },
        {
          type: 'numericColumn',
          field: '_purchasing_power',
          headerName: 'Purchasing Power',
          minWidth: 150,
          maxWidth: 170
        },
        { type: 'centered', field: 'ai', headerName: 'AI', minWidth: 60, maxWidth: 80 },
        { type: 'icon', field: 'quality_rating', headerName: 'QR', minWidth: 60, maxWidth: 80 },
        { type: 'date', field: '_activity', headerName: 'Last Activity', minWidth: 120, maxWidth: 140 },
      ],
      focusedCell: {
        row: null,
        page: 1
      }
    }
  }

  /**
   * ComponentDidUpdate
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!this.agGrid || !this.agGrid.api) {
      return
    }

    if (prevProps.data !== this.props.data) {
      this.agGrid.api.redrawRows()
    }

    this.findAndUpdateFocusedCell(prevProps)
  }

  /**
   * Helper function to determine the state of a focused cell during pagination
   * @param prevProps
   */
  findAndUpdateFocusedCell = (prevProps) => {
    const { flyoutCollapsed, filter: { page } } = this.props
    const { focusedCell } = this.state
    const newPageLoaded = prevProps.data !== this.props.data
    const flyoutStateChanged = prevProps.flyoutCollapsed !== this.props.flyoutCollapsed

    if (!newPageLoaded && !flyoutStateChanged) {
      return
    }

    if (flyoutCollapsed || page !== focusedCell.page) {
      this.agGrid.api.clearFocusedCell()
    }

    if (!flyoutCollapsed && page === focusedCell.page) {
      this.agGrid.api.setFocusedCell(focusedCell.rowIndex, 0)
    }
  }

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  handleGridReady = (grid) => {
    setTimeout(() => {
      this.agGrid = grid
      if (this.agGrid) {
        this.agGrid.api.sizeColumnsToFit()
        this.props.handleGridResize(this.calculateAvailableSpace(this.agGrid.columnApi))
      }
    }, 0)
  }

  /**
   * Calculates the width of a left offset when flyout is open
   * @param columnApi
   */
  calculateAvailableSpace = (columnApi) => {
    const { layoutConfig: { flyoutMinWidth, flyoutShadowWidth, navigationWidth } } = this.props
    let availableWidth = get(columnApi, 'columnController.leftWidth', 0) + navigationWidth

    if (availableWidth <= 500) {
      availableWidth = window.innerWidth - flyoutMinWidth - flyoutShadowWidth - navigationWidth
    }

    return availableWidth
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   * @param columnApi - agGrid columnApi
   */
  handleGridResize = ({ type, columnApi }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => {
        this.agGrid && this.agGrid.api.sizeColumnsToFit()
        this.props.handleGridResize(this.calculateAvailableSpace(columnApi))
      }, 0)
    }
  }

  /**
   * Handle row click event
   */
  handleRowClicked = (data) => {
    const { handleRowClick } = this.props

    handleRowClick && handleRowClick(data)
  }

  /**
   * Handle cell focus event, saves selected cell
   * @param column
   * @param rowIndex
   */
  handleCellFocus = ({ column, rowIndex }) => {
    if (!column || column.colId !== 'name') {
      return
    }

    this.setState({
      focusedCell: {
        rowIndex,
        page: this.props.filter.page
      }
    })
  }

  /**
   * Handle bulk actions based on action id
   * @param actionId
   */
  handleBulkAction = async (actionId) => {
    if (!this.agGrid || !actionId) {
      return
    }
    const selectedRows = this.agGrid.api.getSelectedRows()

    if (!selectedRows || !selectedRows.length) {
      return
    }

    await this.props.handleBulkAction(actionId, selectedRows)

    this.agGrid.api.redrawRows()
  }

  /**
   * On Expand Menu Click
   * Displays expandable menu for contact/institutions and tags
   * @param event
   * @param popoverMenuProps
   */
  onExpandMenuClick = (event, popoverMenuProps) => {
    event.stopPropagation()
    this.setState({ popoverMenuProps })
  }

  /**
   * Handle expand menu item click
   */
  onExpandMenuItemClick = () => {
    // do nothing for now
    return
  }

  /**
   * On Expand Menu Close Click
   * @param event
   */
  onExpandMenuCloseClick = (event) => {
    event.stopPropagation()

    this.setState({ popoverMenuProps: null })
  }

  /**
   * Renders CustomCell Component
   */
  renderCustomCell = (props) => {
    return (
      <CustomCell
        {...props}
        onExpandMenuClick={this.onExpandMenuClick}
        onTargetNameClick={this.props.handleFlyoutOpen}
        onUtilityItemClick={this.onUtilityItemClick}
      />
    )
  }

  /**
   * Returns grid columns
   */
  getColumnsDefinition = () => {
    const { aiTargetingSubscription, region } = this.props

    const columns = this.state.columns.filter((col) => {
      switch (col.field) {
        case 'ai':
          return aiTargetingSubscription
        case '_purchasing_power':
          return region === 'north_america'
        default:
          return true
      }
    })

    return columns.map(({ type, ...column }, idx) => ({
      ...getDefaultColumnDef({ columnIndex: idx, type }),
      ...column
    }))
  }

  /**
   * On Utility Item Click
   */
  onUtilityItemClick = (event, entity) => {
    event.stopPropagation()

    const utility = {
      entity: {
        ...entity,
        id: entity.q4_entity_id,
        targetId: entity._target
      },
      anchorEl: event.currentTarget
    }
    this.setState({ utility })
  }

  /**
   * On Utility Close Click
   * @param event
   */
  onUtilityCloseClick = () => {
    this.setState({ utility: null })
  }

  /**
   * Get Utility Menu
   */
  getUtilityMenu = () => {

    const { utility } = this.state
    const { handleTargetUtilAction } = this.props

    const { entity, anchorEl } = utility
    const { q4_entity_id, institution_name, fund_name, full_name } = entity
    const utilityMenuOptions = [
      { action: 'CREATE_ACTIVITY' },
      {
        icon: 'q4i-savedtargets-2pt',
        label: entity._target ? 'Remove from Targets' : 'Save as Target',
        hide: entity._deal,
        onClick: () => handleTargetUtilAction(entity._target, { type: entity.entity, item: entity._id })
  },
      { action: 'CREATE_DEAL' },
      { action: 'BRIEFING_BOOK' },
      { action: 'DOWNLOAD_TEARSHEET' },
      { action: 'REQUEST_ENTITY_UPDATE' }
    ].filter((action) => !action.hide)

    return <MenuWithOptions
      theme={THEMES.LIGHT_GREY}
      items={utilityMenuOptions}
      open={Boolean(anchorEl)}
      onClose={(event) => this.onUtilityCloseClick(event)}
      entityType={entity.entity}
      anchorEl={anchorEl}
      anchor={entity.anchor}
      entity={{ ...entity, id: q4_entity_id, institutionName: institution_name, fundName: fund_name, fullName: full_name }}
      url={window.location.pathname}
      hasButton
    />
  }

  render () {
    const {
      data, total, filter: { page, limit }, isLoading, isGridReact,
      flyoutCollapsed, flyoutLeftOffset, handleFilterChange
    } = this.props

    return (
      <React.Fragment>
        {isLoading && <Spinner mask={true} theme='rain' />}
        {isGridReact && (
          <AgGrid
            className='targeting-all-grid'
            sizeToFit={true}

            // prevent row re-rendering unless nodeId changed
            immutableData={true}
            getRowNodeId={(data) => data._id}

            // suppress configs
            suppressMovableColumns={true}
            suppressContextMenu={true}
            enableSorting={false}

            // columns and data
            defaultColDef={{
              suppressMenu: true,
              cellRenderer: 'CustomCellRender',
              suppressKeyboardEvent: () => true
            }}
            columnDefs={this.getColumnsDefinition()}
            rowData={data}

            // custom components
            frameworkComponents={{
              CustomCellRender: this.renderCustomCell
            }}

            // pagination
            pagination={true}
            paginationProps={{
              style: flyoutCollapsed ? null : { width: flyoutLeftOffset },
              pageSizeId: 'targeting-all-grid',
              forcePage: page,
              initialPageSize: limit,
              total,
              onPageChange: ({ selected }) => handleFilterChange({ page: selected })
            }}

            // bulk actions
            bulkActions={[{
              id: allGridBulkActions.ADD_TO_SAVED_TARGETS,
              icon: 'q4i-targeting-4pt',
              onSelect: () => this.handleBulkAction(allGridBulkActions.ADD_TO_SAVED_TARGETS)
            }, {
              id: allGridBulkActions.ADD_TO_BRIEFING_BOOK,
              icon: 'q4i-book-4pt',
              onSelect: () => this.handleBulkAction(allGridBulkActions.ADD_TO_BRIEFING_BOOK)
            }]}

            // event listeners
            onGridReady={this.handleGridReady}
            onGridSizeChanged={this.handleGridResize}
            // firefox and safari only reads cell--title for preventing redirection path, chrome: ag-pinned-left-cols-container
            onRowClicked={preventDefaultRowClick(
              this.handleRowClicked,['expandable-cell', 'ag-pinned-left-cols-container', 'cell--title']
            )}
            onCellFocused={this.handleCellFocus}

            // css overwrites
            isPinned={data && data.length}
            isRowFocus={!flyoutCollapsed}
          />
        )}

        {/* Popover Menu for Custom Cell */}
        {this.state.popoverMenuProps && (
          <PopoverMenu
            arrowClass='bottom-right'
            onClose={this.onExpandMenuCloseClick}
            onClick={this.onExpandMenuItemClick}
            scrollable={true}
            {...this.state.popoverMenuProps}
          />
        )}
        {this.state.utility && this.getUtilityMenu()}
      </React.Fragment>
    )
  }
}

TargetingGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  filter: PropTypes.shape({
    page: PropTypes.number,
    limit: PropTypes.number
  }),
  region: PropTypes.string,
  handleFilterChange: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  handleBulkAction: PropTypes.func.isRequired
}

TargetingGrid.defaultProps = {
  data: [],
  total: 0,
  filter: {
    page: 1,
    limit: 10
  }
}

export default TargetingGrid
