import React, { memo } from 'react'
import PropTypes from 'prop-types'

// actions
import { modalType, REMOVE_CONTACT_FAILURE, REMOVE_CONTACT_SUCCESS } from '../../../../actions'

// components
import GdprMessage from './gdpr/gdpr.component'
import { Banner } from '../../../../components'
import { combine, ENTITY_TYPE, THEMES } from '../../../../utils'

const { CONTACT } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string.isRequired,
  contact: PropTypes.object.isRequired,
  targetId: PropTypes.string,
  hasActiveDeal: PropTypes.bool,
  onDealSaveSuccess: PropTypes.func.isRequired,
  removeCustomContact: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  dataIdPrefix: 'ContactPage',
  contact: {},
  hasActiveDeal: false
}

/**
 * Contact Detail Page Banner Component
 * @param props
 */
function ContactDetailBanner (props) {
  const {
    dataIdPrefix, contact, targetId, hasActiveDeal, onDealSaveSuccess, onContactUpdate, removeCustomContact,
    openModal, closeModal, history
  } = props
  const { id, salutation, fullName, nickname, suffixProf, source, status } = contact

  const custom = source === 'q4desktop'
  const gdpr = status === 'gdpr'
  const title = combine([salutation, fullName], ' ')

  function handleContactDeleteConfirm () {
    closeModal({
      type: modalType.CONFIRM_MODAL
    })

    id && removeCustomContact(id).then((data) => {
      const { type } = data
      const success = type === REMOVE_CONTACT_SUCCESS
      const failure = type === REMOVE_CONTACT_FAILURE

      success && history.push('/contact')
      failure && handleFailure()
    })
  }

  /**
   * On Action Completion Failure display an error
   */
  function handleFailure () {
    openModal({
      type: modalType.ERROR_MODAL
    })
  }

  const controls = [
    {
      type: 'button',
      dataId: `${dataIdPrefix}BannerEntityUpdate`,
      label: 'Request Update',
      hidden: custom || gdpr,
      onClick: () => openModal({
        type: modalType.ENTITY_UPDATE_REQUEST_MODAL,
        props: {
          values: {
            entityId: id,
            entityType: CONTACT,
            name: fullName
          }
        }
      })
    },
    {
      type: 'button',
      dataId: `${dataIdPrefix}BannerContactEdit`,
      icon: 'q4i-edit-4pt',
      hidden: !custom,
      onClick: () => openModal({
        type: modalType.CONTACT_MODAL,
        props: {
          id,
          onSaveSuccess: onContactUpdate
        }
      })
    },
    {
      dataId: `${dataIdPrefix}BannerUtility`,
      type: 'utility',
      entity: { ...contact, _target: targetId },
      entityType: CONTACT,
      items: [
        { action: 'CREATE_ACTIVITY' },
        {
          action: 'TARGET',
          hide: hasActiveDeal
        },
        {
          action: 'CREATE_DEAL',
          onSaveSuccess: onDealSaveSuccess
        },
        { action: 'DOWNLOAD_TEARSHEET' },
        { action: 'BRIEFING_BOOK' },
        { action: 'ADDRESS_BOOK' },
        {
          dataId: `${dataIdPrefix}BannerUtilityContactDelete`,
          icon: 'q4i-trashbin-2pt',
          label: 'Delete',
          hide: !custom,
          onClick: () => openModal({
            type: modalType.CONFIRM_MODAL,
            props: {
              content: {
                title: 'Delete Custom Contact?',
                message: 'Are you sure you want to delete this Contact?'
              },
              onConfirm: () => handleContactDeleteConfirm()
            }
          })
        }
      ]
    }
  ]

  return (
    <Banner
      size='medium'
      title={combine([title, suffixProf])}
      icon='q4i-contact-2pt'
      details={nickname && <span>Nickname - {nickname}</span>}
      disclosure={gdpr ? {
        type: 'button',
        theme: THEMES.CHERRY,
        label: 'GDPR Forgotten',
        onClick: () => openModal({
          component: GdprMessage
        })
      } : null}
      controls={controls}
    />
  )
}

ContactDetailBanner.propTypes = propTypes
ContactDetailBanner.defaultProps = defaultProps

export default memo(ContactDetailBanner)
