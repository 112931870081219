import {
  FETCH_CURRENT_SECURITY_REQUEST,
  FETCH_CURRENT_SECURITY_SUCCESS,
  FETCH_CURRENT_SECURITY_ERROR,
  FETCHING,
  FETCHED,
  FAILED,
  IDLE
} from '../../actions/estimates'

const initialState = {
  status: IDLE
}

const currentSecurity = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENT_SECURITY_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_CURRENT_SECURITY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: FETCHED
      }
    case FETCH_CURRENT_SECURITY_ERROR:
      return {
        ...state,
        status: FAILED,
        error: action.error
      }
    default:
      return state
  }
}

export default currentSecurity
