import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Card } from '../../../index'
import { CompactLayout, DefaultLayout } from './components'
import { combine, getPhones, CONTACT_INSTITUTION_CARD_LAYOUT } from '../../../../utils'

const propTypes = {
  /**
   * Used to show contact job details
   */
  details: PropTypes.shape({
    /**
     * Contact Job
     */
    job: PropTypes.shape({
      entityId: PropTypes.string.isRequired,
      institutionName: PropTypes.string.isRequired,
      institutionType: PropTypes.string,
      title: PropTypes.string.isRequired,
      directPhone: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      functions: PropTypes.arrayOf(PropTypes.string)
    }).isRequired,

    /**
     * Contact Strategy
     */
    strategy: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      country: PropTypes.arrayOf(PropTypes.string),
      region_group: PropTypes.arrayOf(PropTypes.string),
      custom_region: PropTypes.arrayOf(PropTypes.string),
      market_cap: PropTypes.arrayOf(PropTypes.string),
      quality: PropTypes.arrayOf(PropTypes.string),
      sectors: PropTypes.arrayOf(PropTypes.string),
      strategy: PropTypes.arrayOf(PropTypes.string),
      style: PropTypes.arrayOf(PropTypes.string),
      security_type: PropTypes.arrayOf(PropTypes.string)
    })
  }),

  /**
   * Used to show Contact and Contact's Institution Position
   */
  position: PropTypes.shape({
    data: PropTypes.number,
    status: PropTypes.string
  }).isRequired,

  /**
   * Used to show type of holdings (e.g. 13f, surveillance...)
   */
  ownershipLabel: PropTypes.string,

  /**
   * Used to apply card layout
   */
  layout: PropTypes.string.isRequired,

  /**
   * Used to apply card theme
   */
  theme: PropTypes.string.isRequired
}


/**
 * Contact Institution Detail Component
 */
function Institution ({ details, position, ownershipLabel, layout, theme, handleTitleClick }) {
  const {
    job: { entityId, institutionName, institutionType, title: jobTitle, directPhone, phone, email, functions },
    strategy
  } = details

  const address = getAddress(details.job)
  const phones = getPhones(phone, directPhone)

  /**
   * Get address
   * @param value
   * @returns {string}
   */
  function getAddress (value) {
    const address = combine([value.address1, value.address2, value.address3])
    const city = combine([value.city, value.state_province])
    const country = combine([value.country_name, value.postal_code], ' ')

    return combine([address, city, country], '<br>')
  }

  /**
   * Get strategy by institution id
   * @param value
   * @returns {string}
   */
  function getStrategy (value) {
    if (!value || !value._id) {
      return '-'
    }

    if (value.summary && value.summary.length) {
      return value.summary
    }

    const country = combine(value.country)
    const regionGroup = combine(value.region_group)
    const customRegion = combine(value.custom_region)
    const marketCap = combine(value.market_cap)
    const quality = combine(value.quality)
    const sectors = combine(value.sectors)
    const strategy = combine(value.strategy)
    const style = combine(value.style)
    const securityType = combine(value.security_type)

    return combine([country, regionGroup, customRegion, marketCap, quality, sectors, strategy, style, securityType])
  }

  /**
   * Renders widget based on the layout option
   */
  function renderLayout (layout) {
    const baseProps = {
      entityId,
      jobTitle,
      institutionName,
      institutionType,
      address,
      phones,
      email,
      functions,
      position,
      ownershipLabel,
      strategy: getStrategy(strategy),
      handleTitleClick
    }

    switch (layout) {
      case CONTACT_INSTITUTION_CARD_LAYOUT.COMPACT:
        return (
          <CompactLayout {...baseProps} />
        )
      default:
        return (
          <DefaultLayout
            {...baseProps}
            details={details}
          />
        )
    }
  }

  return (
    <Card
      title='Institution'
      theme={theme}
    >
      {renderLayout(layout)}
    </Card>
  )
}

Institution.propTypes = propTypes

export default memo(Institution)
