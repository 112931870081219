import _ from 'lodash';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment-timezone';
import {
    FETCHING,
    fetchConsensus,
    fetchBrokerDetails,
    setConsensusData,
    resetBrokerData
} from '../../../../actions/widget/estimates';
import {getUniquelySortedDataPoints, getPeriodLabel} from '../../../../utils/estimates.util';
import {setClassNames} from '../../../../utils/widget.util';
import { Spinner } from '../../../../components'
import { Dropdown} from '../../../../components/shared';
import BrokerDetailsChart from "../../../../components/widget/estimates/brokerDetails/brokerDetails.component";
import './brokerDetails.container.css';

const consensusMetricMap = {
    sales: 'income',
    ebitda: 'income',
    sga: 'income'
};

const metricMap = {
    eps: 'EPS',
    sales: 'Sales',
    ebitda: 'EBITDA',
    sga: 'SG&A Expense',
    ffo: 'Funds From Operations'
};

class Estimates extends Component {

    constructor(props) {
        super(props);

        this.handleMetricDropdownChange = this.handleMetricDropdownChange.bind(this);
        this.handleDateDropdownChange = this.handleDateDropdownChange.bind(this);

        this.state = {
            loading: true
        };
    }

    componentDidMount = () => {
        const {isEdit, isFetched, selectedData, options, securityId} = this.props;

        if ((isEdit && isFetched) || !securityId) {
            return;
        }

        this._fetchConsensus(selectedData.metric);

        if (options.refreshInterval) {
            this.timer = setInterval(this._fetchConsensus.bind(this), options.refreshInterval);
        }
    };

    /**
     * ComponentDidUpdate
     * Re-fetch data when security changed
     * @param prevProps
     */
    componentDidUpdate = (prevProps) => {
        const {securityId} = this.props;

        if (prevProps.securityId !== securityId) {
            this.props.resetBrokerData();
            this.props.setConsensusData({
                metric: 'eps',
                id: securityId,
                frequency: null,
                date: null,
                quarter: null,
                test: true
            });

            this._fetchConsensus('eps');
        }
    };

    componentWillUnmount = () => {
        if (this.timer) {
            clearInterval(this.timer);
        }
    };

    _fetchBrokerDetails = (selected) => {
        const {securityId} = this.props;
        const {frequency, quarter, date, metric} = selected;

        return this.props.fetchBrokerDetails({
            metric,
            frequency,
            securityId,
            quarter,
            date,
            limit: 25
        }).then(() => {
            this.setState({loading: false});
        });
    };

    _fetchConsensus = (metric) => {
        const {securityId} = this.props;

        return this.props.fetchConsensus({
            metric: consensusMetricMap[metric] || metric,
            securityId: securityId
        }).then((resp) => {
            const {selectedData} = this.props;
            const consensus = typeof resp.payload === 'object' ? resp.payload : [];
            const data = consensus.filter((item) => {
                if (item.title === metricMap[metric.toLowerCase()]) {
                    return item;
                }
                return null;
            });

            const estimates = ((data && data[0] && data[0].data) || []).filter((item) => {
                if (item.type === 'estimate') {
                    return item.date;
                }
                return null;
            });

            if(estimates.length) {
                const item = estimates && estimates[0];
                const _selectedData = {
                    id: securityId,
                    metric,
                    quarter: selectedData.quarter || item.quarter,
                    date: selectedData.date || moment.utc(item.date).format('YYYY-MM-DD'),
                    frequency: selectedData.frequency || item.frequency,
                    limit: 25
                };

                this.props.setConsensusData(_selectedData);
                this._fetchBrokerDetails(_selectedData);
            } else {
                this.setState({loading: false});
            }
        });
    };

    handleDateDropdownChange = (value) => {
        const {selectedData} = this.props;
        const {date, frequency, quarter} = value;
        const _data = {date, frequency, quarter};
        const metric = selectedData.metric || 'eps';

        this.setState({loading: true});
        this.props.resetBrokerData(metric);
        this.props.setConsensusData(_data);
        this._fetchBrokerDetails(Object.assign(_data, {metric}));
    };

    handleMetricDropdownChange = (value) => {
        this.setState({loading: true});
        this.props.setConsensusData({metric: value});
        this._fetchConsensus(value);
    };

    render() {
        let activeIndex = 0;

        const {selectedData, consensus, consensusStatus, broker, brokerStatus, layout, securityId, theme, history} = this.props;
        const {loading} = this.state;
        const {date, frequency} = selectedData;
        const currentMetric = selectedData.metric || 'eps';
        const path = `/estimates/${securityId}`;
        const classes = setClassNames('broker-details-widget', layout, theme);
        const isFetching = brokerStatus === FETCHING || consensusStatus === FETCHING;
        const consensusData = getUniquelySortedDataPoints(consensus);
        const noData = _.isEmpty(broker[currentMetric].estimates) || _.isEmpty(consensusData);
        const dates = consensusData.map((data, index) => {
            if (date) {
                if (!activeIndex && date === moment.utc(data.date).format('YYYY-MM-DD') && (data.frequency === frequency)){
                    activeIndex = index;
                }
            }
            else if (!activeIndex && (data.type && data.type === 'estimate')) {
                activeIndex = index;
            }

            return {
                date: moment.utc(data.date).format('YYYY-MM-DD'),
                frequency: data.frequency,
                quarter: data.quarter,
                label: getPeriodLabel(data.date, data.type, data.frequency, data.quarter, true)
            };
        });

        return (
            <div className={classes.base}>
                <header className={classes.header}>
                    <h2 className={`${classes.name}_title`} onClick={() => history.push(path)}>
                        Broker Details
                    </h2>
                    <div>
                        <Dropdown
                            className={`${classes.name}_dropdown`}
                            disabled={loading}
                            value={dates[activeIndex]}
                            options={dates}
                            optionKey={'label'}
                            theme={theme === 'dark' ? 'slate' : 'pale-grey'}
                            width={150}
                            height={30}
                            itemHeight={30}
                            dropdownHeight={95}
                            onSelect={this.handleDateDropdownChange}
                        />
                        <Dropdown
                            className={`${classes.name}_dropdown ${classes.name}_dropdown--metric`}
                            disabled={loading}
                            value={currentMetric}
                            options={['eps', 'sales', 'ebitda', 'sga', 'ffo']}
                            theme={theme === 'dark' ? 'slate' : 'pale-grey'}
                            width={100}
                            height={30}
                            itemHeight={30}
                            dropdownHeight={95}
                            onSelect={this.handleMetricDropdownChange}
                        />
                    </div>
                </header>
                <div className={`${classes.name}_body`}>
                    { !isFetching && noData ?
                        <p>There is no estimates data available.</p> : isFetching && noData ? <Spinner /> :
                        <BrokerDetailsChart {...this.props} />
                    }
                </div>
                <footer className={classes.footer}>
                    <div className={`${classes.footer}-label`}>
                        <span>In US dollars</span>
                        <Link to={path}>View Details</Link>
                    </div>
                </footer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const estimates = state.widget.estimates;
    const selectedData = estimates.consensus.selected;
    const consensusMetric = consensusMetricMap[selectedData.metric] || selectedData.metric || 'eps';

    return {
        securityId: state.dashboard.dashboardState.securityId,
        brokerStatus: estimates.brokerDetails.status,
        broker: estimates.brokerDetails || {},
        consensusStatus: estimates.consensus.status,
        consensus: estimates.consensus[consensusMetric] || [],
        theme: state.dashboard.dashboardState.theme || 'dark',
        selectedData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBrokerDetails: bindActionCreators(fetchBrokerDetails, dispatch),
        fetchConsensus: bindActionCreators(fetchConsensus, dispatch),
        setConsensusData: bindActionCreators(setConsensusData, dispatch),
        resetBrokerData: bindActionCreators(resetBrokerData, dispatch)
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Estimates));
