import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Scrollbars} from 'react-custom-scrollbars';

import './selectList.component.css';
import {renderDarkThumb, renderTrackVertical} from '../../../../resources/theme/q4.custom-scrollbar';

class SelectList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            highlightedItemIndex: -1,
            listItemsLength: this.getListItemLength(props.children),
            hasScrolledToItem: false,
            hasScrolledToItemHighlighted: false
        };

        this.componentReference = React.createRef();
        this.scrollbarsReference = React.createRef();
    }

    componentDidMount() {
        const {keyboardNavigation} = this.props;
        if (keyboardNavigation) {
            document.addEventListener('keydown', this.handleKeyDown);
        }
        this.scrollList();
    }

    componentWillUnmount() {
        const {keyboardNavigation} = this.props;
        if (keyboardNavigation) {
            document.removeEventListener('keydown', this.handleKeyDown);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            listItemsLength: this.getListItemLength(nextProps.children)
        });
    }

    componentDidUpdate() {
        this.scrollList();
    }

    /**
     * Scroll select list to provided scrollToIndex in props.
     */
    scrollList() {
        const {children, scrollToIndex} = this.props;
        const {hasScrolledToItem} = this.state;
        if (scrollToIndex >= 0 && children && !hasScrolledToItem) {
            this.scrollToIndex(scrollToIndex);
            this.setState({
                hasScrolledToItem: true
            });
        }
    }

    /**
     * Clear the highlighted list item
     */
    clearHighlightedListItem() {
        this.setState({
            highlightedItemIndex: -1
        });
    }

    /**
     * Get the number of items that the select list contains
     * @param children
     * @returns {Number}
     */
    getListItemLength(children) {

        if (!children) {
            return 0;
        }

        if (Array.isArray(children)) {
            return children.length;
        }

        return 1;
    }

    /**
     * On key down callback
     * @param event
     * @returns {*}
     */
    handleKeyDown = (event) => {
        event = event || window.event;

        switch (event.key) {
            case 'Up':
            case 'ArrowUp':
                event.preventDefault();
                return this.handleArrowNavigation('up');
            case 'Down':
            case 'ArrowDown':
                event.preventDefault();
                return this.handleArrowNavigation('down');
            case 'Enter':
                const highlightedItem = this.getHighlightedListItem();
                if (highlightedItem) {
                    return this.onSelect(highlightedItem.props.value);
                }
                break;
            default:
                return;
        }
    };

    /**
     * Get the highlighted list item
     * @returns {*}
     */
    getHighlightedListItem() {
        const {children} = this.props;
        const {highlightedItemIndex} = this.state;
        let childrenList;

        if (!children || highlightedItemIndex < 0) {
            return null;
        }

        childrenList = (Array.isArray(children) ? children : [children]);
        return childrenList[highlightedItemIndex];
    }

    /**
     * Handle the keyboard arrow navigation, provided the direction (up, down)
     * @param direction {String} 'up' or 'down'
     */
    handleArrowNavigation = (direction) => {
        const {scrollToIndex} = this.props;
        const {listItemsLength, hasScrolledToItem, hasScrolledToItemHighlighted} = this.state;
        let highlightedItemIndex = this.state.highlightedItemIndex;

        if (hasScrolledToItem && !hasScrolledToItemHighlighted) {
            highlightedItemIndex = (direction === 'down' ? scrollToIndex - 1 : scrollToIndex);
            this.setState({
                hasScrolledToItemHighlighted: true
            });
        }

        switch (direction) {
            case 'up':
                if (highlightedItemIndex > 0) {
                    const highlightedNextIndex = highlightedItemIndex - 1;
                    this.setState({
                        highlightedItemIndex: highlightedNextIndex
                    }, () => this.handleScroll(highlightedNextIndex, 'up'));
                } else {
                    this.clearHighlightedListItem();
                }
                break;
            case 'down':
                if (highlightedItemIndex < listItemsLength - 1) {
                    const highlightedNextIndex = highlightedItemIndex + 1;
                    this.setState({
                        highlightedItemIndex: highlightedNextIndex
                    }, () => this.handleScroll(highlightedNextIndex, 'down'));
                }
                break;
            default:
                return;
        }
    };

    /**
     * Scroll list to the given item index
     * @param index {Number}
     */
    scrollToIndex(index) {
        const {children, listItemHeight} = this.props;
        const scrollbarsComponent = this.scrollbarsReference && this.scrollbarsReference.current;
        if (scrollbarsComponent && children && index >= 0) {
            const scrollTop = index * listItemHeight;
            setTimeout(() => {
                scrollbarsComponent.scrollTop(scrollTop);
            });
        }
    }

    /**
     * Handle scroll of list item, as the user navigates with the keyboard arrows
     * @param highlightedItemIndex {Number} The index of the currently highlighted list item
     * @param direction {String} 'up' or 'down'
     * @returns {*}
     */
    handleScroll = (highlightedItemIndex, direction) => {
        const {height, listItemHeight} = this.props;
        const {listItemsLength} = this.state;
        const scrollbarsComponent = this.scrollbarsReference && this.scrollbarsReference.current;

        if (!scrollbarsComponent || listItemHeight * listItemsLength <= height) {
            return;
        }

        if (highlightedItemIndex < 0) {
            return scrollbarsComponent.scrollTop(0);
        }

        this.scrollToDirection(direction, scrollbarsComponent, highlightedItemIndex);
    };

    /**
     * Scroll the list item to the given direction
     * @param direction {String} 'up' or 'down'
     * @param scrollbarsComponent {Scrollbars} Reference to the Scrollbars component
     * @param highlightedItemIndex {Number} The index of the currently highlighted list item
     */
    scrollToDirection(direction, scrollbarsComponent, highlightedItemIndex) {
        const {height, listItemHeight} = this.props;
        const scrollTop = scrollbarsComponent.getScrollTop();
        const highlightedItemScrollTop = highlightedItemIndex * listItemHeight;
        const listItemsTotalHeight = (highlightedItemIndex * listItemHeight) + listItemHeight;
        let offset = listItemHeight;

        const withinBounds = (
            (highlightedItemScrollTop >= scrollTop) &&
            (listItemsTotalHeight <= scrollTop + height)
        );

        if (!withinBounds && highlightedItemScrollTop >= scrollTop) {
            offset = listItemsTotalHeight - (scrollTop + height);
        }

        switch (direction) {
            case 'up':
                !withinBounds && scrollbarsComponent.scrollTop(highlightedItemScrollTop);
                break;
            case 'down':
                const scroll = (highlightedItemScrollTop < scrollTop ? highlightedItemScrollTop : scrollTop + offset);
                !withinBounds && scrollbarsComponent.scrollTop(scroll);
                break;
            default:
                return;
        }
    }

    onSelect = (value) => {
        const {onSelect} = this.props;
        this.clearHighlightedListItem();
        onSelect && onSelect(value);
    };

    onMouseMove = () => {
        this.clearHighlightedListItem();
    };

    render() {

        const {height, children, listItemHeight, className, theme} = this.props;
        const {highlightedItemIndex} = this.state;

        const cls = [
            'select-list',
            className || '',
            (highlightedItemIndex >= 0 ? 'select-list--keyboard-navigated' : ''),
            (theme ? `select-list--${theme}` : ''),
        ];

        return (
            <div
                className={cls.join(' ').trim()}
                ref={this.componentReference}
                onMouseMove={this.onMouseMove}>
                <Scrollbars
                    ref={this.scrollbarsReference}
                    className='select-list_scrollbar'
                    hideTracksWhenNotNeeded
                    autoHeight
                    autoHeightMax={height}
                    renderThumbVertical={renderDarkThumb}
                    renderTrackVertical={renderTrackVertical}
                >
                    {
                        React.Children.map(children, ((child, index) => {
                            return React.cloneElement(child, {
                                isHighlighted: (index === highlightedItemIndex),
                                onClick: this.props.onSelect,
                                height: listItemHeight
                            });
                        }))
                    }
                </Scrollbars>
            </div>
        );
    }
}

SelectList.propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    listItemHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isLoading: PropTypes.bool,
    keyboardNavigation: PropTypes.bool,
    onSelect: PropTypes.func,
    scrollToIndex: PropTypes.number,
    theme: PropTypes.string
};

SelectList.defaultProps = {
    isLoading: false,
    height: 200,
    listItemHeight: 40,
    keyboardNavigation: true,
    scrollToIndex: -1,
    theme: 'rain'
};

export default SelectList;