import queryString from 'query-string'
import 'url-search-params-polyfill'
import { keys } from 'lodash'

export function addQueryParams (url, params) {
  if (params) {
    url = url + '?' + queryString.stringify(params)
  }
  return url
}

export function getUrlSearchParams (value = window.location.search) {
  return new URLSearchParams(value)
}

/**
 * Remove params with empty values
 * @param params
 * @returns {any | {}}
 */
export function cleanQueryParams (params) {
  return keys(params).reduce((acc, key) => {
    const val = params[key]
    if (params[key]) {
      acc[key] = val
    }
    return acc
  }, {})
}

/**
 * Check if running on localhost.
 * @return {boolean}
 */
export function isLocalhost () {
  return ['localhost', '127.0.0.1', ''].includes(window.location.hostname)
}

/**
 * Check if API is running on localhost
 * - In case UI is also localhost, 'develop API' is also accepted due to localhost testing capabilities
 * @return {boolean}
 */
export function isLocalhostApi () {
  const localhostDomains = ['localhost', '127.0.0.1']

  // Conditional domain accommodating localhost's ability to reach local and dev Api2.
  if (isLocalhost()) {
    localhostDomains.push('api2.dev')
  }

  return localhostDomains.some(item => (process.env.REACT_APP_DESKTOP_API_URL || '').includes(item))
}
