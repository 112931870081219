import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow, TableFooter } from '@material-ui/core'
import { Pagination } from '../../../components'

class DataTableFooter extends Component {
  /**
     * Render DataTableFooter Component
     * @returns {XML}
     */
  render () {
    const { showPagination, data, dataTotal, columns } = this.props

    const noData = !data || !data.length
    const colspan = (columns && columns.length) || 1

    if (!showPagination || noData) {
      return null
    }

    return (
      <TableFooter className='data-table_footer'>
        <TableRow>
          <TableCell
            classes={{ root: 'data-table_cell' }}
            colSpan={colspan}
          >
            <Pagination
              total={dataTotal}
              {...this.props}
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    )
  }
}

DataTableFooter.propTypes = {
  /**
     * Array of data to display in the table
     */
  data: PropTypes.array,

  /**
     * The total amount of data records
     * Used for pagination to determine page count
     */
  dataTotal: PropTypes.number,

  /**
     * Array of columns used to display the data
     * Columns require an id property
     */
  columns: PropTypes.array,

  /**
     * Used to determine whether or not to display pagination for this table
     */
  showPagination: PropTypes.bool,

  /**
     * Used to set an initial page on the component's first render
     */
  initialPage: PropTypes.number,

  /**
     * Used to set an overwrite page control with prop
     */
  forcePage: PropTypes.number,

  /**
     * Used to set an initial page size on the component's first render
     */
  initialPageSize: PropTypes.number,

  /**
     * Used to overwrite the default page size options
     * Default: 10, 25, 50, 100
     */
  pageSizeOptions: PropTypes.array,

  /**
     * A unique ID string used to store the user's last page size selection
     * Stored in localStorage under 'pagination' object
     */
  pageSizeId: PropTypes.string,

  /**
     * Override whether or not to show the page size options
     */
  showPageSizeSelection: PropTypes.bool,

  /**
     * A callback for when the user changes pages
     */
  onPageChange: PropTypes.func,

  /**
     * A callback for when the user changes page size
     * if pageSizeId is provided, this also backs up the user's choice to localStorage under that ID
     */
  onPageSizeChange: PropTypes.func
}

DataTableFooter.defaultProps = {
  data: []
}

export default DataTableFooter
