import { get, capitalize, isEmpty, isPlainObject } from 'lodash'
import moment from 'moment-timezone'
import {
  ENTITY_INSTITUTION,
  ENTITY_CONTACT,
  isDateBeforeDate,
  isSameDate,
  formatDate,
  getDateTime,
  guessTimeZone
} from '../index'
import {
  ALL,
  COMMENT,
  PHONE,
  EMAIL,
  MEETING,
  EARNINGS,
  ROADSHOW,
  CONFERENCE,
  OTHER,
  ACTIVITY_TYPE_OPTIONS
} from './activity.const'
import { ENTITY_TYPE } from '../entity/entity.const'
import { SAVE_ACTIVITY_SUCCESS_ADD_ANOTHER } from '../../actions/activity/form.actions'
import { statusType } from '../../actions'
import EntityLink from '../../components/activity/form/activityAttendees/entityLink.class'
import _ from 'lodash/fp'
import { formatLocalizedDate, getCompatibleTimeZoneOffSet, getTimezone } from '../date.util'
import { isEnvDevelop } from '../node.util'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

const _getActivityParent = (activity, isEdit) => {
  return isEdit ? get(activity, '_activity.item') : get(activity, '_activity')
}

/**
 * Returns true/false if a contact's job contains id, institution name, and factset entity id
 * @param job
 * @return {boolean}
 * @private
 */
export const isProperJob = (job = {}) => {
  return !!(
    (job._id || job.entityId || job.q4_entity_id) &&
    (job.institution_name || job.institutionName) &&
    (job.factset_entity_id || job.entityId || job.q4_entity_id)
  )
}

/**
 * When links are provided to this component externally (through props), linking between contacts and institutions are not existing.
 * This method restores this connection, by setting the institution id to the respective contact.
 * @param entityLinks
 */
const _setInstitutionId = (entityLinks) => {
  entityLinks.forEach((entityLink) => {
    if (entityLink.getEntityType() === ENTITY_CONTACT) {
      const contactJobInstitution = entityLinks.find((link) => {
        const entityLinkId =
          get(entityLink, 'item.jobs[0]._id') ||
          get(entityLink, 'item.jobs[0].entityId') ||
          get(entityLink, 'item.jobs[0].q4_entity_id')
        return link.getEntityType() === ENTITY_INSTITUTION && (link._id || get(link, 'item.q4_entity_id')) === entityLinkId
      })
      if (contactJobInstitution && isProperJob(contactJobInstitution.item)) {
        entityLink.setInstitutionId(get(contactJobInstitution, 'item.q4_entity_id') || contactJobInstitution._id)
      }
    }
  })
}

export const getEntityValues = (values = {}, isEdit = false) => {
  const { links } = values
  const entityLinks = (links || []).map((link) => new EntityLink(link))

  if (!isEdit) {
    _setInstitutionId(entityLinks)
  }

  const start = getTime(values.start)
  const end = getTime(values.end)
  return Object.assign({}, { ...values, ...start, ...end, links: entityLinks })
}

/**
 * Returns an array of options for activity types
 */
export const getActivityTypeOptions = (types = [ALL, COMMENT, PHONE, EMAIL, MEETING, EARNINGS, OTHER, ROADSHOW, CONFERENCE]) => {
  return ACTIVITY_TYPE_OPTIONS.filter((option) => types.includes(option.value))
}

/**
 * Returns an array with activity types and corresponding counts for Autocomplete dropdown filters
 * @param counts
 * @param includeParents
 * @return {{label: string, value: *}[]}
 */
export const getActivityFilters = (counts = {}, includeParents = true) => {
  return getActivityTypeOptions()
    .filter((category) => includeParents ? category : !category.isParent)
    .map((category) => {
      return {
        label: `${category.label} (${(counts && counts[category.value]) || 0})`,
        value: category.value
      }
    })
}

/**
 * Returns true if an activity is parent
 * @param category
 * @returns {boolean}
 */
export const isParent = (category) => {
  return [ROADSHOW, CONFERENCE].includes(category)
}

/**
 * Returns true if an activity is itinerary
 * @param activity - _activity from itinerary
 * @returns {boolean}
 */
export const isItinerary = (activity) => {
  const category = (activity && activity.type) || ''
  return [ROADSHOW, CONFERENCE].includes(category)
}

export const getActivityTitle = (activity) => {
  const category = get(activity, 'category')
  const categoryName = (category === 'comment') ? 'note' : category
  const defaultTitle = (typeof categoryName === 'string' ? `New ${capitalize(categoryName)}` : null)
  let title = get(activity, 'title')

  if (typeof title === 'string') {
    title = title.trim()
  }

  return title || defaultTitle || null
}

export const isVenueButNoAddress = (activity) => {
  const { address } = activity

  if (Array.isArray(address) && address[0]) {
    const isVenue = isEmpty(get(address[0], 'venue'))
    const isLocation = isEmpty(get(address[0], 'location'))

    return !isParent(activity) ? (!isVenue && isLocation) : false
  } else {
    return false
  }
}

export const isFormDataValid = (activity) => {
  const {
    start,
    end
  } = activity

  if (!isDateBeforeDate(get(start, 'date_time'), get(end, 'date_time'))) {
    return 'Start date cannot be after end date.'
  }
}

export const isConfirmationRequired = (activity, isEdit) => {
  const {
    start,
    end
  } = activity
  const parent = _getActivityParent(activity, isEdit)
  const all_day = get(activity, 'all_day') || false

  const dateTimeFormat = `MMMM D, YYYY${!all_day ? ' h:mm A' : ''}`
  const parentType = isEdit && parent ? get(parent, 'category') : get(parent, 'type')

  if (parent && isDateBeforeDate(get(start, 'date_time'), get(parent, 'start.date_time'))) {
    return {
      title: `Update ${parentType} date?`,
      text: `The start date of this activity is before your ${parentType}. Your ${parentType}'s start date will be updated to
                ${formatDate(start.date_time, dateTimeFormat)}.`,
      btnLabel: 'Update'
    }
  }

  if (parent && isDateBeforeDate(get(parent, 'end.date_time'), get(end, 'date_time'))) {
    return {
      title: `Update ${parentType} date?`,
      text: `The end date of this activity is after your ${parentType}. Your ${parentType}'s end date will be updated to
                ${formatDate(end.date_time, dateTimeFormat)}.`,
      btnLabel: 'Update'
    }
  }
}

/**
 * Returns Activity Category Icon
 * @param category
 * @param size
 * @return {string}
 */
export const getActivityCategoryIcon = (category, size = 2) => {
  const icon = {
    [COMMENT]: 'q4i-note',
    [PHONE]: 'q4i-phone',
    [EMAIL]: 'q4i-mail',
    [MEETING]: 'q4i-meeting',
    [EARNINGS]: 'q4i-earnings',
    [OTHER]: 'q4i-report-blank',
    [ROADSHOW]: 'q4i-roadshow',
    [CONFERENCE]: 'q4i-conference'
  }[category]

  return (icon && size) ? `${icon}-${size}pt` : ''
}

export const adjustAllDayTime = (activity) => {
  let {
    start,
    end,
    all_day
  } = activity

  if (all_day) {
    start = {
      date_time: getDateTime(start.date_time, '00:00:00'),
      timezone: start.timezone
    }
    end = {
      date_time: getDateTime(end.date_time, '23:30:00'),
      timezone: end.timezone
    }
  }

  return { start, end }
}

/**
 * Get all addresses from an array of itineraries.
 * @param itineraries
 * @returns {any[]}
 */
export const getItineraryAddresses = (itineraries) => {
  let addresses = [];

  (itineraries || []).forEach((itinerary) => {
    return addresses = addresses.concat(itinerary.address || [])
  })

  return addresses
}

export const hasChangedStateValueTo = (prevValue, nextValue, expectedValues) => {
  if (!Array.isArray(expectedValues)) {
    expectedValues = [expectedValues]
  }

  return prevValue !== nextValue && expectedValues.includes(nextValue)
}

export const hasSavedAndAddAnotherSuccess = (prevProps, activityFormState) => {
  const hasAddAnotherFirstTime = (activityFormState.status !== prevProps.activityFormState.status &&
    hasChangedStateValueTo(prevProps.activityFormState.type, activityFormState.type, SAVE_ACTIVITY_SUCCESS_ADD_ANOTHER))
  const hasAddAnother = activityFormState.type === SAVE_ACTIVITY_SUCCESS_ADD_ANOTHER &&
    hasChangedStateValueTo(prevProps.activityFormState.status, activityFormState.status, statusType.SUCCESS)
  return hasAddAnotherFirstTime || hasAddAnother
}

/**
 * Get parent itinerary values for create itinerary item
 * @param activity
 * @returns {{isParent: boolean, start: *, end: *, participants: *, _activity: {item: *, start: *, end: *, type: *, title: *}, address: *}}
 */
export const getParentItinerary = (activity) => {
  return {
    participants: activity.participants,
    address: (activity.address && activity.address.length === 1 ? activity.address : []),
    start: activity.start,
    end: activity.end,
    isParent: activity && !activity._activity,
    _activity: {
      item: activity._id,
      address: activity.address,
      type: activity.category,
      title: activity.title,
      start: activity.start,
      end: activity.end
    }
  }
}

/**
 * Get Addresses based on activity type
 * Earning and Roadshows will have multiple, while other types will use the first address assigned
 * @param activity
 * @returns {*[]|*}
 */
export const getActivityAddress = (activity) => {
  if (activity && !!activity.virtual) {
    return []
  }

  let address = activity.address || []

  if (!isParent(activity.category) && Array.isArray(address) && address.length) {
    address = [address[0]]
  }

  return address
}

/**
 * Flattens links array on activity root level
 * @param activity
 */
export const unwindActivityLinks = (activity) => {
  delete activity.contact
  delete activity.institution;

  ((activity && activity.links) || []).forEach((link) => {
    const entityType = (link.entity_type || link.entityType || '').toLowerCase()
    if ([CONTACT, INSTITUTION, FUND].includes(entityType)) {
      activity[entityType] = activity[entityType] ? activity[entityType].concat(link) : [link]
    }
  })

  return activity
}

/**
 * Flattens links array on activity root level
 * @param activities
 */
const unwindLinks = (activities) => {
  return (activities || []).map(unwindActivityLinks)
}

/**
 * Formats activity to display parent / child relationships.
 * @see {@link https://www.ag-grid.com/javascript-grid-tree-data/} for further information.
 * @param activities
 * @return {*}
 */
const buildTreeHierarchy = (activities) => {
  return activities.reduce((acc, activity) => {
    activity.hierarchy = [activity._id]
    acc.push(activity)

    if (activity.hasOwnProperty('_itinerary')) {
      activity._itinerary = activity._itinerary.map((itinerary) => {
        itinerary.hierarchy = [activity._id, itinerary._id]
        return itinerary
      })

      acc.push(...activity._itinerary)
    }
    return acc
  }, [])
}

/**
 * This function adds expanded flag to itinerary (child activities)
 * @param activities
 */
const markItineraries = (activities) => {
  return (activities || []).map((activity) => {
    if (activity.hasOwnProperty('_activity')) {
      activity.itinerary = true
    }
    return activity
  })
}

export const formatActivities = _.compose(markItineraries, unwindLinks, buildTreeHierarchy)

/**
 *
 * @param activity {Object} Activity payload object
 * @param fields {Array<String>} Fields to delete from activity.
 * @returns {Object} The activity payload without the fields.
 */
export const deleteActivityFields = (activity, fields) => {
  if (!isPlainObject(activity)) {
    return activity
  }

  (fields || []).forEach((field) => {
    if (activity.hasOwnProperty(field)) {
      delete activity[field]
    }
  })

  return activity
}

/**
 * Returns the payload of each entity link.
 * @param links {Array<Object|EntityLink>}
 * @returns {any[]}
 */
export const getEntityLinksPayload = (links) => {
  return (links || []).map((link) => {
    const { entity_type } = link
    const entityId = get(link, 'item.q4_entity_id') || get(link, 'item.entityId')

    // TODO Remove once contacts and contact jobs have q4_entity_id mapped and only return { entityId, entity_type }
    return (entityId && entity_type.toLowerCase() !== ENTITY_TYPE.CONTACT)
      ? { entityId, entity_type }
      : { ...link }
  })
}

/**
 * Get payload for on save activity
 * @param values {Object} Activity payload
 * @returns {Object} Formatted payload for save
 */
export const getFormSavePayload = (values) => {
  if (!isPlainObject(values)) {
    return values
  }

  const activityClone = Object.assign({}, values)
  const activityDates = adjustAllDayTime(activityClone)
  activityClone.title = getActivityTitle(activityClone)
  activityClone.links = getEntityLinksPayload(activityClone.links)
  activityClone.address = getActivityAddress(activityClone)
  activityClone._deal = (isPlainObject(activityClone._deal) ? activityClone._deal._id : activityClone._deal || null)
  activityClone.start = activityDates.start
  activityClone.end = activityDates.end
  activityClone.address = (activityClone.address || []).filter((item) => !isEmpty(item.location))

  if (isParent(activityClone.category)) {
    deleteActivityFields(activityClone, [
      '_deal',
      'links'
    ])
  } else {
    deleteActivityFields(activityClone, [
      '_itinerary'
    ])
  }

  return activityClone
}

/**
 * Get payload for on build itinerary save
 * @param values {Object} Activity payload
 * @returns {Object} Formatted payload for save
 */
export const getFormBuildItineraryPayload = (values) => {
  if (!isPlainObject(values)) {
    return values
  }

  const activityClone = Object.assign({}, values)
  const activityDates = adjustAllDayTime(activityClone)
  activityClone.title = getActivityTitle(activityClone)
  activityClone.start = activityDates.start
  activityClone.end = activityDates.end
  activityClone.address = (activityClone.address || []).filter((item) => !isEmpty(item.location))

  deleteActivityFields(activityClone, [
    '_deal',
    'links'
  ])

  return activityClone
}

/**
 * Get payload for on add another itinerary save
 * @param values {Object} Activity payload
 * @returns {Object} Formatted payload for save
 */
export const getFormItineraryAddAnotherPayload = (values) => {
  if (!isPlainObject(values)) {
    return values
  }

  const activityClone = Object.assign({}, values)
  const activityDates = adjustAllDayTime(activityClone)
  activityClone.title = getActivityTitle(activityClone)
  activityClone.start = activityDates.start
  activityClone.end = activityDates.end
  activityClone.address = (activityClone.address || []).filter((item) => !isEmpty(item.location))

  deleteActivityFields(activityClone, [
    '_itinerary'
  ])

  return activityClone
}

/**
 * Pre-populate activity's attendee
 * @param entityType
 * @param entity
 * @returns {{}}
 */
export const getActivityAttendee = (entityType, entity) => {
  const { entityId, name, fullName, fundName, institutionName, entityConnection } = entity || {}
  const isQ4Id = entity.id && entity.id.length === 32
  const _id = entity._id || entity.id || (entityConnection && entityId)
  const data = {}

  switch (entityType.toLowerCase()) {
    case CONTACT:
      const { jobs } = (entity || entityConnection)

      const job = [].concat(jobs || [])[0] || null
      const institutionId = (job && (job.factset_entity_id || job._id || job.id)) || null
      const institution = (job && (job.institution_name || job.institutionName)) || null

      const contact = [{
        entity_type: entityType,
        entity_id: entityId,
        full_name: name || fullName || (entityConnection && entityConnection.fullName),
        institution_name: institution,
        item: {
          _id,
          factset_person_id: entityId,
          factset_entity_id: institutionId,
          q4_entity_id: (job && job.entityId) || null,
          full_name: name || fullName || (entityConnection && entityConnection.fullName),
          jobs: jobs
        }
      }]

      data.links = institutionId || (job && job.entityId)
        ? [].concat(contact, {
            entity_type: 'Institution',
            entity_id: institutionId,
            institution_name: institution,
            q4_entity_id: job.entityId || null,
            item: {
              _id: (job._id || job.id),
              factset_entity_id: institutionId,
              institution_name: institution,
              q4_entity_id: job.entityId || null
            }
          })
        : contact
      break
    case INSTITUTION:
      data.links = [{
        entity_type: entityType,
        entity_id: entityId,
        institution_name: name || institutionName || (entityConnection && entityConnection.institutionName),
        item: {
          _id,
          factset_entity_id: entity.factset_entity_id,
          institution_name: name || institutionName || (entityConnection && entityConnection.institutionName),
          q4_entity_id: (isQ4Id && entity.id) || (entityConnection && entityId)
        }
      }]
      break
    case FUND:
      data.links = [{
        entity_type: entityType,
        entity_id: entityId,
        institution_id: entity.institutionId || (entityConnection && entityConnection.institutionId),
        factset_fund_id: entity.factset_fund_id,
        fund_name: name || fundName || (entityConnection && entityConnection.fundName),
        item: {
          _id,
          factset_fund_id: entity.factset_fund_id,
          factset_entity_id: institutionId,
          fund_name: name || fundName || (entityConnection && entityConnection.fundName),
          q4_entity_id: (isQ4Id && entity.id) || (entityConnection && entityId)
        }
      }]
      break
    default:
      return {}
  }

  return data
}

/**
 * Format activity date
 * @param start
 * @param end
 * @param allDay
 * @returns {string}
 */
export const getActivityDate = ({ start, end, allDay }) => {
  if (!start || !end) {
    return
  }

  const startDate = start && start.date_time
  const endDate = end && end.date_time

  if (!allDay) {
    return getActivityTimeZoneDate(start, end)
  }

  const formattedStartDate = formatDate(
    startDate,
    allDay ? 'dddd, MMMM D, YYYY' : 'LLLL',
    false,
    true
  )

  const formattedEndDate = formatDate(
    endDate,
    allDay ? 'dddd, MMMM D, YYYY' : 'LLLL',
    false,
    true
  )

  const formattedDateZone = formatDate(startDate, ' z', false, true)

  // start and end date are the same
  if (isSameDate(startDate, endDate)) {
    const formattedSameEndDate = formatLocalizedDate(formatDate(endDate, 'h:mm A'), 'h:mm A')
    return `${formattedStartDate}${!allDay ? `- ${formattedSameEndDate} ${formattedDateZone}` : ''}`
  }
  // start and end date is not the same
  return `${formattedStartDate} - ${formattedEndDate} ${!allDay ? formattedDateZone : ''}`
}
/**
 * Format activity timezone date
 * @param start
 * @param end
 * @param allDay
 * @returns {string}
 */
export const getActivityTimeZoneDate = (start, end) => {
  if (!start || !end) {
    return
  }

  const startDate = start && start.date_time
  const endDate = end && end.date_time
  const startTimezone = start.timezone ? start.timezone : guessTimeZone()
  const endTimezone = end.timezone ? end.timezone : guessTimeZone()
  const startTzOffset = `(GMT${getCompatibleTimeZoneOffSet(start)})`
  const endTzOffset = `(GMT${getCompatibleTimeZoneOffSet(end)})`
  const startTz = formatLocalizedDate(moment.utc(startDate).tz(startTimezone).format('dddd, MMMM D, YYYY LT'), 'dddd, MMMM D, YYYY LT')
  const endTz = formatLocalizedDate(moment.utc(endDate).tz(endTimezone).format('dddd, MMMM D, YYYY LT'), 'dddd, MMMM D, YYYY LT')

  if (isSameDate(startDate, endDate)) {
    return `${startTz}  ${startTzOffset} - ${moment.utc(endDate).tz(endTimezone).format('LT')} ${endTzOffset}`
  }

  return `${startTz} ${startTzOffset} - ${endTz} ${endTzOffset}`
}

/**
 * get default timezone when timezone is undefined, else return value contain compatible timezone
 * @param value
 * @returns {*}
 */
export const getTime = (date) => {
  if (!date) {
    return
  }

  return {
    ...date,
    timezone: getTimezone(date.timezone)
  }
}
/**
 * Converts response from GraphQL to similar one of REST so UI requires no changes
 * @param {object} attendee Graphql single item
 * @returns {object}
 */
export const mapActivityAttendeeGqlData = (attendee) => {
  switch (attendee.entityType) {
    case 'Institution':
      return {
        entity_type: get(attendee, 'entityType'),
        _id: get(attendee, 'entityId'),
        position: get(attendee, 'entityConnection.institutionHoldingCurrentConnection.items[0].current'),
        institution_name: get(attendee, 'entityConnection.institutionName'),
        item: {
          entity_id: get(attendee, 'entityId'),
          institution_name: get(attendee, 'entityConnection.institutionName'),
          address: [{
            city: get(attendee, 'entityConnection.addressConnection.items[0].city'),
            state_province: get(attendee, 'entityConnection.addressConnection.items[0].stateProvinceCode'),
            country: get(attendee, 'entityConnection.addressConnection.items[0].countryCode'),
            telephone: get(attendee, 'entityConnection.addressConnection.items[0].phone'),
            hq: get(attendee, 'entityConnection.addressConnection.items[0].hq')
          }]
        }
      }

    case 'Fund':
      return {
        entity_type: get(attendee, 'entityType'),
        _id: get(attendee, 'entityId'),
        position: get(attendee, 'entityConnection.fundHoldingCurrentConnection.items[0].current'),
        name: get(attendee, 'entityConnection.fundName'),
        item: {
          entity_id: get(attendee, 'entityId'),
          fund_name: get(attendee, 'entityConnection.fundName'),
          address: [{
            city: get(attendee, 'entityConnection.institutionConnection.items[0].addressConnection.items[0].city'),
            state_province: get(attendee, 'entityConnection.institutionConnection.items[0].addressConnection.items[0].stateProvinceCode'),
            country: get(attendee, 'entityConnection.institutionConnection.items[0].addressConnection.items[0].countryCode'),
            telephone: get(attendee, 'entityConnection.institutionConnection.items[0].addressConnection.items[0].phone'),
            hq: get(attendee, 'entityConnection.institutionConnection.items[0].addressConnection.items[0].hq')
          }]
        }
      }
    case 'Contact':
      return {
        entity_type: get(attendee, 'entityType'),
        _id: get(attendee, 'entityId'),
        position: get(attendee, 'entityConnection.contactHoldingCurrentConnection.items[0].current'),
        item: {
          _id: get(attendee, 'entityId'),
          full_name: get(attendee, 'entityConnection.fullName'),
          jobs: [{
            city: get(attendee, 'entityConnection.jobs[0].city'),
            state_province: get(attendee, 'entityConnection.jobs[0].stateProvince'),
            country: get(attendee, 'entityConnection.jobs[0].country'),
            phone: get(attendee, 'entityConnection.jobs[0].phone'),
            email: get(attendee, 'entityConnection.jobs[0].email')
          }]
        }
      }
    default:
      console.error('ActivityAttendee: bad data')
  }
}

/**
 * Maps Entity from GraphQL response to flat structure (for CSV export)
 * @param {object} entity Graphql single item
 * @returns {object}
 */
export const flattenEntity = (entity) => {
  switch (entity.entityType) {
    case 'Institution':
      return {
        _id: get(entity, 'entityConnection.id'),
        entity_type: get(entity, 'entityType'),
        entityPosition: get(entity, 'entityConnection.institutionHoldingCurrentConnection.items[0].current'),
        institutionName: get(entity, 'entityConnection.institutionName'),
        institutionType: get(entity, 'entityConnection.institutionType'),
        institutionStyle: get(entity, 'entityConnection.style'),
        institutionTurnover: get(entity, 'entityConnection.turnover'),
        institutionAUM: get(entity, 'entityConnection.totalAUM'),
        institutionLocation: `${get(entity, 'entityConnection.addressConnection.items[0].city')}, ${get(entity, 'entityConnection.addressConnection.items[0].stateProvinceCode')}`
      }
    case 'Fund':
      return {
        entity_type: get(entity, 'entityType'),
        _id: get(entity, 'entityConnection.id'),
        entityPosition: get(entity, 'entityConnection.fundHoldingCurrentConnection.items[0].current'),
        fundName: get(entity, 'entityConnection.fundName'),
        fundType: get(entity, 'entityConnection.fundTypeDesc'),
        fundTurnover: get(entity, 'entityConnection.turnover'),
        fundStyle: get(entity, 'entityConnection.style')
      }
    case 'Contact':
      return {
        entityType: get(entity, 'entityType'),
        _id: get(entity, 'entityConnection.id'),
        contactPosition: get(entity, 'entityConnection.contactHoldingCurrentConnection.items[0].current'),
        institutionName: get(entity, 'entityConnection.jobs[0].institutionName'),
        institutionType: get(entity, 'entityConnection.jobs[0].institutionType'),
        institutionStyle: get(entity, 'entityConnection.jobs[0].institutionConnection.items[0].style'),
        institutionTurnover: get(entity, 'entityConnection.jobs[0].institutionConnection.items[0].turnover'),
        institutionAUM: get(entity, 'entityConnection.jobs[0].institutionConnection.items[0].totalAUM'),
        fullName: get(entity, 'entityConnection.fullName'),
        institutionLocation: `${get(entity, 'entityConnection.jobs[0].city')}, ${get(entity, 'entityConnection.jobs[0].stateProvince')}`,
        phone: get(entity, 'entityConnection.jobs[0].phone'),
        email: get(entity, 'entityConnection.jobs[0].email'),
        calculatedInstitutionPosition: get(entity, 'institutionPosition')
      }
    case 'Activity': {
      return entity
    }
    default:
      console.error('ActivityEntity: bad data')
  }
}

/**
 * Normalize EndDate so it does not add +1 day
 * Step 1: Identify if normalization is applicable
 * Step 2: Verify if normalized EndDate is still after StartDate
 * Step 3: Update 'data' parameter reference with normalized date
 * @param {object} data activity record
 * @param { date } normalizeEndDateOptions.originalDate recieved endDate (unchanged)
 * @param { date } normalizeEndDateOptions.affectedDate currentEndDate
 */
export const normalizeEndDate = (data, normalizeEndDateOptions = {}) => {
  // EndDate Normalization is only allowed when activity is all_day & 
  // normalized EndDate is still higther than start.date_time
  if (
    data?.all_day &&
    data?.end?.date_time &&
    data?.start?.date_time
  ) {
    const { originalDate, affectedDate } = normalizeEndDateOptions || {}
    const endDateCandidate = moment(originalDate.date_time).tz(originalDate.timezone).format()

    // Check if normalized EndDate is still higther than start.date_time
    if (moment(endDateCandidate).valueOf() > moment(data.start.date_time).valueOf()) {
      data.end.date_time = endDateCandidate

      if (isEnvDevelop()) { 
        console.log('endDateCandidate:', endDateCandidate)
        console.log('EndDate normalized.') 
      }
    }
  }
}
