import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const GET_FUND_REQUEST = 'GET_FUND_REQUEST'
export const GET_FUND_SUCCESS = 'GET_FUND_SUCCESS'
export const GET_FUND_FAILURE = 'GET_FUND_FAILURE'

export const FETCH_FUND_REQUEST = 'FETCH_FUND_REQUEST'
export const FETCH_FUND_SUCCESS = 'FETCH_FUND_SUCCESS'
export const FETCH_FUND_FAILURE = 'FETCH_FUND_FAILURE'

export const RESET_FUND = 'RESET_FUND'

/**
 * Fetch fund
 * @param entityId - fund entityId
 * @param options
 * @param options.types - action types
 * @private
 */
const _fetchFund = (entityId, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      FETCH_FUND_REQUEST,
      FETCH_FUND_SUCCESS,
      FETCH_FUND_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/fund/${entityId}`,
    payload: {
      entityId
    }
  }
})

/**
 * Get FUND by entity id
 */
export const fetchFund = (entityId, options) => (dispatch) => {
  dispatch(_fetchFund(entityId, options))
}

/**
 * Get Fund
 * @param id
 * @param options.types - action types
 * @private
 */
const _getFund = (id, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_FUND_REQUEST,
      GET_FUND_SUCCESS,
      GET_FUND_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/fund/${id}`
  }
})

/**
 * Dispatch GET_FUND_REQUEST
 */
export const getFund = (id, options) => (dispatch) => {
  return dispatch(_getFund(id, options))
}

/**
 * Dispatch RESET_FUND
 */
export const resetFund = () => (dispatch) => {
  return dispatch({ type: RESET_FUND })
}
