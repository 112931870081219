import gql from 'graphql-tag'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { usePromiseQuery } from '../../../hook'

const GET_HISTORICAL_STOCK = gql`
  query Stock(
    $index: [String]
    $tickerId: [String]
    $startDate: String!
    $endDate: String!
  ) {
    stock(
      index: $index
      tickerId: $tickerId
      startDate: $startDate
      endDate: $endDate
    ) {
      items {
        date
        last
        currency
        tickerId
        index
      }
    }
  }`

const GET_PRESS_RELEASES = gql`
  query PressReleases(
    $tickerId: String!
    $startDate: String!
    $endDate: String!
    $type: String! = "press"
    $limit: Int! = ${100}
  ) {
    news(
      tickerId: $tickerId
      startDate: $startDate
      endDate: $endDate
      filter: {
        type: $type
      }
      limit: $limit
    ) {
      items {
        id
        type
        date
        headline
      }
    }
  }`

/**
 * Historical Stock
 * @param options
 */
export function useHistoricalStockQuery (options) {
  return useQuery(GET_HISTORICAL_STOCK, options)
}

/**
 * Historical Stock
 */
export function useHistoricalStockPromiseQuery () {
  return usePromiseQuery(GET_HISTORICAL_STOCK)
}

/**
 * Index Historical Stock
 * @param options
 */
export function useIndexHistoricalStockQuery (options) {
  return useLazyQuery(GET_HISTORICAL_STOCK, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Index Historical Stock
 */
export function useIndexHistoricalStockPromiseQuery () {
  return usePromiseQuery(GET_HISTORICAL_STOCK, { fetchPolicy: 'no-cache' })
}

/**
 * News
 * @param options
 */
export function usePressReleasesQuery (options) {
  return useQuery(GET_PRESS_RELEASES, options)
}

/**
 * News
 */
export function usePressReleasesPromiseQuery () {
  return usePromiseQuery(GET_PRESS_RELEASES)
}
