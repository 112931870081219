import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const SEND_MAIL_REQUEST = 'SEND_MAIL_REQUEST'
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS'
export const SEND_MAIL_ERROR = 'SEND_MAIL_ERROR'

export const MAIL_REQUEST = 'MAIL_REQUEST'
export const MAIL_SUCCESS = 'MAIL_SUCCESS'
export const MAIL_ERROR = 'MAIL_ERROR'

export const SENDING = 'SENDING'
export const IDLE = 'IDLE'
export const SENT = 'SENT'
export const FAILED = 'FAILED'

/**
 * @deprecated
 * Send email
 * @param data
 */
const _sendMailV1 = (data) => ({
  [CALL_API]: {
    endpoint: '/services/mail',
    types: [
      SEND_MAIL_REQUEST,
      SEND_MAIL_SUCCESS,
      SEND_MAIL_ERROR
    ],
    method: METHOD_TYPE.POST,
    payload: data
  }
})

/**
 * Send template email (v2)
 * @param data
 */
const _sendMail = (data) => ({
  [CALL_API]: {
    endpoint: '/services/mail/v2',
    types: [
      MAIL_REQUEST,
      MAIL_SUCCESS,
      MAIL_ERROR
    ],
    method: METHOD_TYPE.POST,
    payload: data
  }
})

/**
 * @deprecated
 * Dispatch SEND_MAIL_REQUEST
 * @param data
 */
export const sendMailV1 = (data) => (dispatch) => {
  return dispatch(_sendMailV1(data))
}

/**
 * Dispatch SEND_MAIL_REQUEST
 * @param data
 */
export const sendMail = (data) => (dispatch) => {
  return dispatch(_sendMail(data))
}
