import React, { memo } from 'react'
import PropTypes from 'prop-types'

// utils
import { 
  defaultIfEmpty, 
  getChangeClassName, 
  getLocalizedCurrency, 
  getMarketChange, 
  getPercentage, 
  inMillions 
} from '../../../../../utils'

const EMPTY_PLACEHOLDER = '-'

const propTypes = {
  data: PropTypes.array.isRequired,
  group: PropTypes.string
}

const defaultProps = {
  data: []
}

/**
 * Industry Analysis Grid
 * @param props
 */
function IndustryAnalysisGrid ({ data, group }) {
  return (
    <div className='tearsheet_grid'>
      <div className='tearsheet_grid-row tearsheet_grid-row--header'>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--text'>&nbsp;</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--narrow tearsheet_grid-cell--number'>%PORT</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--widest tearsheet_grid-cell--number'>VAL ({getLocalizedCurrency()})</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--wide tearsheet_grid-cell--number'>CHG ({getLocalizedCurrency()})</div>
      </div>
      {(data.length ? data : [{}]).map((item, index) => {
        const {
          securityId, securitySector, securityCapGroup, securityRegion,
          percentPortfolio, marketValue, marketValueChange
        } = (item || {})
        const name = securitySector || securityRegion
        const capGroupName = (data.length) ? securityCapGroup || 'Other' : EMPTY_PLACEHOLDER
        const marketChange = getMarketChange(marketValueChange)

        return (
          <div
            className='tearsheet_grid-row'
            key={securityId || `industryAnalysis-${index}`}
          >
            <div className='tearsheet_grid-cell tearsheet_grid-cell--text'>
              {(group === 'capGroup') ? capGroupName : defaultIfEmpty(name)}
            </div>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--narrow tearsheet_grid-cell--number'>
              {getPercentage(percentPortfolio)}
            </div>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--widest tearsheet_grid-cell--number'>
              {inMillions(marketValue, 2) || EMPTY_PLACEHOLDER}
            </div>
            <div className={`tearsheet_grid-cell tearsheet_grid-cell--wide tearsheet_grid-cell--number ${getChangeClassName(marketChange)}`}>
              {marketChange}
            </div>
          </div>
        )
      })}
    </div>
  )
}

IndustryAnalysisGrid.propTypes = propTypes
IndustryAnalysisGrid.defaultProps = defaultProps

export default memo(IndustryAnalysisGrid)
