import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { DatePicker, Select } from '../../../../../components'

/**
 * Report Builder Date Filter Component
 */
class DateFilter extends PureComponent {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : null,
            selectedOperator: this.getSelectedOperator(props.operator, props.operators)
        };
    }

    /**
     * ComponentDidUpdate
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {value, operator, operators} = this.props;
        const selectedOperator = this.state.selectedOperator;

        const newOperator = operator && (operator.value !== (selectedOperator && selectedOperator.value));
        const newValue = value !== prevProps.value;

        const newState = {};

        if (newOperator) {
            newState.selectedOperator = this.getSelectedOperator(operator, operators);
        }

        if (newValue) {
            newState.value = value;
        }

        this.setState(newState);
    }

    /**
     * Handle DatePicker change
     * @param moment
     */
    handleDateChange = (moment) => {
        const {isBasic, filterId, onChange} = this.props;
        const {selectedOperator} = this.state;

        const value = moment.format();
        const valueState = {
            operator: selectedOperator,
            value
        };

        this.setState({
            value
        }, () => {
            onChange && onChange(filterId, valueState, isBasic);
        });
    };

    /**
     * Handle operator change from Dropdown component
     * @param operator
     */
    handleOperatorChange = (operator) => {
        if (!operator) {
            return;
        }

        const {filterId, onChange} = this.props;
        const {value} = this.state;

        onChange && onChange(filterId, {
            operator,
            value
        });
    };

    /**
     * Get selected operator
     * @param operator
     * @param operators
     * @returns {*|null}
     */
    getSelectedOperator = (operator, operators) => {
        let selectedOperator = operator || null;

        if (!selectedOperator && operators && operators.length) {
            selectedOperator = operators[0] || null;
        }

        return selectedOperator;
    };

    /**
     * Render Report Filter Component
     * @return {XML}
     */
    render() {
        const {isBasic, operators} = this.props;
        const {selectedOperator, value} = this.state;

        return (
            <div className='date-filter'>
                {!isBasic && (
                    <div className='field field--full field--dropdown'>
                        <label className='field_label'>Operator</label>
                        {operators && (
                            <Select
                                value={selectedOperator}
                                options={operators}
                                onChange={this.handleOperatorChange}
                                searchable={false}
                                clearable={false}
                            />
                        )}
                    </div>
                )}
                <div className='field field--full field--date'>
                    <label className='field_label'>
                        {isBasic ? selectedOperator.label : 'Value'}
                    </label>
                    <DatePicker
                        value={value ? moment(value).utc() : null}
                        onChange={this.handleDateChange}
                    />
                </div>
            </div>
        );
    }
}

DateFilter.propTypes = {
    filterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    index: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    operators: PropTypes.array.isRequired,
    operator: PropTypes.object,
    onChange: PropTypes.func.isRequired
};

export default DateFilter;
