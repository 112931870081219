import {
  GET_STOCK_AVERAGE_VOLUME_REQUEST,
  GET_STOCK_AVERAGE_VOLUME_SUCCESS,
  GET_STOCK_AVERAGE_VOLUME_FAILURE,
  RESET_STOCK,
  statusType
} from '../../actions'

const initial = {
  value: null,
  status: statusType.IDLE
}

const averageVolume = (state = initial, action) => {
  const { type, payload, error } = (action || {})
  switch (type) {
    case GET_STOCK_AVERAGE_VOLUME_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_STOCK_AVERAGE_VOLUME_SUCCESS:
      return {
        value: payload && payload.volumeAverage,
        status: statusType.SUCCESS
      }

    case GET_STOCK_AVERAGE_VOLUME_FAILURE:
      return {
        error: error,
        status: statusType.ERROR
      }

    case RESET_STOCK:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default averageVolume
