import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Message from '../message/message.component'
import EmailPreferenceModalIcon from '../../resources/images/email/emailPreferenceModal.icon.svg'

import { config } from '../../config'
import { launchDarklyHelper, LD_FEATURE_FLAGS } from '../../services/launchDarkly.service'
import { closeModal, emailPreference, setEmailRecipients, updateUserEmailPreference } from '../../actions'
import {
  CRM_EMAIL_ROUTE,
  combine,
  getEmailsForDefaultEmailClient,
  getRecipientsForCrmEmailClient,
  THEMES,
  setCookie
} from '../../utils'

import './emailPreferenceModal.component.scss'

const className = 'email-preference-modal'

const propTypes = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.element,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  contacts: PropTypes.array,
  activityContact: PropTypes.object
}

export const EmailPreferenceModalText = {
  TITLE: 'Q4 email service',
  TEXT: [
    'Send emails directly from your Contact Address Book',
    'Personalization features to greet each recipient by name',
    'Emails are logged automatically as an Activity',
    'Records appear in your personal email inbox'
  ],
  CANCEL: 'Use default email service',
  CONFIRM: 'Use q4 email service',
  NEW: 'NEW'
}

const defaultProps = {
  title: EmailPreferenceModalText.TITLE,
  message: (
    <ul>
      <li>{EmailPreferenceModalText.TEXT[0]}</li>
      <li>{EmailPreferenceModalText.TEXT[1]}</li>
      <li>{EmailPreferenceModalText.TEXT[2]}</li>
      <li>{EmailPreferenceModalText.TEXT[3]}</li>
    </ul>
  ),
  cancelLabel: EmailPreferenceModalText.CANCEL,
  confirmLabel: EmailPreferenceModalText.CONFIRM
}

/**
 * Email Preference Modal
 * @param {object} props
 * @param {function} props.onClose
 * @param {string} props.title
 * @param {JSX.Element} props.message
 * @param {string} props.cancelLabel
 * @param {string} props.confirmLabel
 * @param {array} props.contacts
 * @param {object} props.activityContact
 * @returns {JSX.Element}
 */
const EmailPreferenceModal = (props) => {
  const { onClose, title, message, cancelLabel, confirmLabel, contacts, activityContact } = props

  const history = useHistory()
  const dispatch = useDispatch()

  const isCrmEmailFeatureEnabled = !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.CRM_EMAIL)

  const onUseDefaultEmailServiceClick = () => {
    dispatch(updateUserEmailPreference(emailPreference.DEFAULT_EMAIL))
    // Cookie set to expire in 1 day if user choose DEFAULT_EMAIL
    setCookie('user-email-preference', emailPreference.DEFAULT_EMAIL, 1)
    let emails = []

    if (contacts?.length) {
      emails = getEmailsForDefaultEmailClient(contacts)
    } else if (activityContact) {
      emails = [activityContact.email]
    }

    if (emails?.length) {
      const href = `mailto:${combine(emails, ';')}?Subject=Contact%20from%20Q4%20Desktop&bcc=${config.loggerEmail}`
      window.location.assign(href)
    }

    dispatch(closeModal())
  }

  const onConfirmClick = () => {
    dispatch(updateUserEmailPreference(emailPreference.CRM_EMAIL))
    // Cookie set to expire in 10 years if user choose CRM_EMAIL
    setCookie('user-email-preference', emailPreference.CRM_EMAIL, 400)
    dispatch(closeModal())

    let recipients = []

    if (contacts?.length) {
      recipients = getRecipientsForCrmEmailClient(contacts)
    } else if (activityContact) {
      const { firstName, lastName, name: fullName, institutionName: organizationName, email, _id: contactId } = activityContact || {}
      const recipient = {
        firstName,
        lastName,
        fullName,
        organizationName,
        email,
        contactId
      }
      recipients = [recipient]
    }

    if (isCrmEmailFeatureEnabled && recipients?.length) {
      dispatch(setEmailRecipients(recipients))
      history.push(CRM_EMAIL_ROUTE.EMAIL)
    }
  }

  const buttons = [
    {
      ui: THEMES.DARK_SLATE,
      label: cancelLabel,
      onClick: onUseDefaultEmailServiceClick
    },
    {
      ui: THEMES.CITRUS,
      label: confirmLabel,
      onClick: onConfirmClick
    }
  ]

  const renderTitle = () => (
    <div>
      <i className='icon q4i-mail-2pt' />
      <span>{title}</span>
      <span className={'new-badge'}>{EmailPreferenceModalText.NEW}</span>
    </div>
  )

  const renderMessage = () => (
    <>
      <img src={EmailPreferenceModalIcon} />
      <span>{message}</span>
    </>
  )

  return (
    <Message
      className={className}
      visible
      renderExitButton
      onClose={onClose}
      title={renderTitle()}
      message={renderMessage()}
      buttons={buttons}
    />
  )
}

EmailPreferenceModal.propTypes = propTypes
EmailPreferenceModal.defaultProps = defaultProps

export default memo(EmailPreferenceModal)
