import {
  GET_ENTITY_OWNERSHIP_CHART_STOCK_REQUEST,
  GET_ENTITY_OWNERSHIP_CHART_STOCK_SUCCESS,
  GET_ENTITY_OWNERSHIP_CHART_STOCK_FAILURE,
  RESET_ENTITY_OWNERSHIP_CHART,
  statusType
} from '../../../../actions'
import { chartStockLegacy } from '../../../../utils'

const moment = require('moment-timezone')
const initial = {
  data: [],
  status: statusType.IDLE
}

const stock = (state = initial, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_ENTITY_OWNERSHIP_CHART_STOCK_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_ENTITY_OWNERSHIP_CHART_STOCK_SUCCESS:
      const historicalData = (payload.historical || []).filter(record=> record.Date && record.Last)
      return {
        data: chartStockLegacy(historicalData),
        status: statusType.SUCCESS
      }

    case GET_ENTITY_OWNERSHIP_CHART_STOCK_FAILURE:
      return {
        status: statusType.ERROR
      }

    case RESET_ENTITY_OWNERSHIP_CHART:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default stock
