import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

// actions
import { getSecurity, statusType, GET_SECURITY_SUCCESS } from '../../actions'
import { useDispatchAction, useTypedSelector } from '../../hook'

// components
import { Button, Spinner, PageMessage } from '../../components'
import SecurityPage from './security.page'

// utils
import { THEMES, isQ4Id, PAGE_MESSAGE, DEFAULT_TICKER } from '../../utils'
import { get } from 'lodash'

import './security.preload.scss'

/**
 * Security Page Preload
 * @param props
 */
function SecurityPagePreload ({ match }) {
  // #region MapStateToProps
  const loading = useTypedSelector((state) => state.security.security.status === statusType.IN_PROGRESS)
  const legacySecurity = useTypedSelector((state) => state.security.security.data ?? {})
  // #endregion

  // #region MapDispatchToProps
  const dispatch = useDispatch()
  const handleGetSecurity = useDispatchAction(getSecurity, dispatch)
  // #endregion

  const { _id: legacySecurityId, security_type: securityType } = legacySecurity
  const id = get(match, 'params.id')
  const nonTrading = (securityType === DEFAULT_TICKER)

  const [tickerId, setTickerId] = useState(null)
  const [prevTickerId, setPrevTickerId] = useState(id)

  // Reset security
  useEffect(() => {
    if (!id) {
      return
    }

    if (prevTickerId !== id) {
      setTickerId(null)
      setPrevTickerId(id)
    }
  }, [id, prevTickerId])

  useEffect(() => {
    if (!id) {
      return
    }

    const request = isQ4Id(id) ? handleGetSecurity({ tickerId: id }) : handleGetSecurity({ entityId: id })
    request.then((data) => {
      const { type, payload = {} } = data
      if (type === GET_SECURITY_SUCCESS && payload.q4_ticker_id) {
        setTickerId(payload.q4_ticker_id)
      }
    })
  }, [id, setTickerId, handleGetSecurity])

  return (
    <>
      {loading && <Spinner mask theme={THEMES.DARK} />}
      {(!loading && nonTrading) && (
        <PageMessage
          message={PAGE_MESSAGE.SEC_PAGE_NONIPO}
          adjacentComponent={        
            <Button
              theme={THEMES.CITRUS}
              label='Peerlist'
              linkTo='/peerlist'
            />
          }
        />
      )}
      {(!loading && tickerId) && (
        <SecurityPage
          tickerId={tickerId}
          legacySecurityId={legacySecurityId}
        />
      )}
    </>
  )
}

export default SecurityPagePreload
