import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect, withRouter } from 'react-router-dom'
import { get } from 'lodash'

// actions
import { useContactQuery, useTargetQuery, useDealQuery, usePipelineQuery } from './hook'
import { openModal, statusType } from '../../../../../../actions'

// components
import ContactDetailBanner from './banner/banner.component'
import ContactDetailOverview from './overview/overview.component'
import { SectionTab } from '../../../../../../components/shared'
import { ActivityGrid, FundGrid, Spinner } from '../../../../../../components'

import { getActiveTicker, ENTITY_TYPE, THEMES } from '../../../../../../utils'

const dataIdPrefix = 'ContactFlyout'

const propTypes = {}
const defaultProps = {
  theme: THEMES.LIGHT
}

const { CONTACT } = ENTITY_TYPE
const TABS = {
  ACTIVITY: 'activity',
  FUND: 'fund'
}

/**
 * Contact Flyout Page
 * @param props
 */
function ContactFlyout (props) {
  const { theme, contactId, securityId, isTargetSuccess, openModal, onClose } = props

  const { loading, error, data } = useContactQuery({
    variables: { id: contactId, securityId }
  })

  const contact = get(data, 'contact.items[0]', {})
  const { id, status, managedFunds } = contact

  const [getTarget, { data: target }] = useTargetQuery({
    variables: { entityId: id }
  })

  const { loading: isPipeline, data: stages } = usePipelineQuery()
  const [getDeals, { loading: isDeals, data: deal }] = useDealQuery({
    variables: { entityId: id }
  })

  const targetId = get(target, 'target.items[0].id', null)
  const deals = get(deal, 'deal.items', [])
  const pipeline = get(stages, 'pipeline.items', [])
  const lost = pipeline.find((item) => item.lost)
  const activeDeal = deals.find((deal) => deal.stage !== (lost && lost.id))
  const gdpr = status === 'gdpr'

  useEffect(() => {
    if (!id) {
      return
    }

    getTarget()
    getDeals()
  }, [id, getTarget, getDeals])

  useEffect(() => {
    isTargetSuccess && getTarget()
  }, [isTargetSuccess, getTarget])

  const handleDealUpdate = () => {
    getTarget()
    getDeals()
  }

  return (
    <>
      {(error || (data && !id)) && <Redirect to='/error/404' />}
      {loading && <Spinner mask theme={theme} />}
      {!!id && (
        <div className='contact-flyout'>
          <ContactDetailBanner
            dataIdPrefix={dataIdPrefix}
            theme={theme}
            contact={contact}
            targetId={targetId}
            hasActiveDeal={!!activeDeal}
            onDealSaveSuccess={handleDealUpdate}
            openModal={openModal}
            onClose={onClose}
          />
          <ContactDetailOverview
            dataIdPrefix={dataIdPrefix}
            theme={theme}
            contact={contact}
            targetId={targetId}
            hasActiveDeal={!!activeDeal}
            deals={{
              isLoading: [isDeals, isPipeline].some((item) => !!item),
              deals,
              pipeline
            }}
            onDealSaveSuccess={handleDealUpdate}
            gdpr={gdpr}
          />
          <SectionTab
            theme={THEMES.RAIN}
            tabs={[
              {
                id: TABS.ACTIVITY,
                label: 'Activity',
                view: (
                  <ActivityGrid
                    dataIdPrefix={dataIdPrefix}
                    entityId={id}
                    entityType={CONTACT}
                    entity={contact}
                    showChildren
                  />
                )
              },
              {
                id: TABS.FUND,
                label: 'Managed Funds',
                view: (
                  <FundGrid
                    dataIdPrefix={dataIdPrefix}
                    id={(managedFunds || []).map((fund) => fund.fundId).filter((id) => id)}
                  />
                ),
                hidden: gdpr
              }
            ]}
          />
        </div>
      )}
    </>
  )
}

ContactFlyout.propTypes = propTypes
ContactFlyout.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)

  return {
    securityId: get(ticker, 'q4_entity_id', '1234567890'), // TODO: tmp default value for pre-IPO
    isTargetSuccess: get(state, 'targeting.target.status') === statusType.SUCCESS
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactFlyout))
