import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { addQueryParams } from '../../utils/http/http.util'

export const FETCH_ESTIMATES_BROKER_REQUEST = 'FETCH_ESTIMATES_BROKER_REQUEST'
export const FETCH_ESTIMATES_BROKER_SUCCESS = 'FETCH_ESTIMATES_BROKER_SUCCESS'
export const FETCH_ESTIMATES_BROKER_ERROR = 'FETCH_ESTIMATES_BROKER_ERROR'

export const SET_ESTIMATES_BROKER_METRIC = 'SET_ESTIMATES_BROKER_METRIC'

export const UPDATE_RESTRICTED_REQUEST = 'UPDATE_RESTRICTED_REQUEST'
export const UPDATE_RESTRICTED_SUCCESS = 'UPDATE_RESTRICTED_SUCCESS'
export const UPDATE_RESTRICTED_ERROR = 'UPDATE_RESTRICTED_ERROR'

export const SET_BROKER_DATES = 'SET_BROKER_DATES'
export const SET_BROKER_QUARTER_DATES = 'SET_BROKER_QUARTER_DATES'

const resources = {
  brokerTable: '/estimates/v2/broker',
  restricted: '/estimates/v2/restricted'
}

const _fetchBrokerTable = (url, params) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_ESTIMATES_BROKER_REQUEST,
      FETCH_ESTIMATES_BROKER_SUCCESS,
      FETCH_ESTIMATES_BROKER_ERROR
    ],
    method: METHOD_TYPE.GET,
    payload: params
  }
})

const _updateRestricted = (url, params, method) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      UPDATE_RESTRICTED_REQUEST,
      UPDATE_RESTRICTED_SUCCESS,
      UPDATE_RESTRICTED_ERROR
    ],
    method: method,
    payload: params
  }
})

export const fetchBrokerTable = (params) => (dispatch, getState) => {
  const url = addQueryParams(resources.brokerTable, params)
  const metric = params.metric
  const state = getState()

  if (state.estimates.broker.selected.metric !== metric) {
    dispatch({ type: SET_ESTIMATES_BROKER_METRIC, metric })
  }
  return dispatch(_fetchBrokerTable(url))
}

export const updateRestricted = (body, params) => (dispatch) => {
  const url = addQueryParams(resources.restricted, params)
  return dispatch(_updateRestricted(url, body, METHOD_TYPE.PUT))
}

export const createRestricted = (body, params) => (dispatch) => {
  const url = addQueryParams(resources.restricted, params)
  return dispatch(_updateRestricted(url, body, METHOD_TYPE.POST))
}

export const setBrokerQuarterDates = (dates) => (dispatch) => {
  return dispatch({ type: SET_BROKER_QUARTER_DATES, dates })
}

export const setBrokerDates = (dates) => (dispatch, getState) => {
  const state = getState()
  if (state.estimates.broker.selected.activeDate !== dates.activeDate ||
        state.estimates.broker.selected.frequency !== dates.frequency) {
    return dispatch({ type: SET_BROKER_DATES, dates })
  }
}
