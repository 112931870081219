import './placeholderMessage.component.css'
import './placeholderMessage.doc'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getClassName, THEMES } from '../../../utils/ui'

/**
 * Placeholder
 * @param {PlaceholderMessageProps} props
 * @returns {JSX.Element|null}
 */
const PlaceholderMessage = (props) => {
  const { className, theme, icon, image, title, message } = props

  if (!title) {
    return null
  }

  /** @type {string} */
  const baseClassName = getClassName('placeholder-message', [
    { condition: className, trueClassName: className },
    { condition: theme, trueClassName: `placeholder-message--${theme}` }
  ])

  return (
    <section className={baseClassName}>
      <div className='placeholder-message_inner'>
        {!!icon && <i className={`placeholder-message_icon ${icon}`} />}
        {!!image && <img className='placeholder-message_image' src={image} alt={title} />}
        <h2 className='placeholder-message_title'>{title}</h2>
        {message && (
          <p className='placeholder-message_message'>{message}</p>
        )}
      </div>
    </section>
  )
}

PlaceholderMessage.propTypes = {
  /**
   * A custom className to pass into the checkbox component
   */
  className: PropTypes.string,

  /**
   * Used to paint the component using a specific theme
   */
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT, THEMES.TRANSPARENT]),

  /**
   * The q4 icon class used to paint the icon above the title
   */
  icon: PropTypes.string,

  /**
   * A path to an image used for painting above the title
   */
  image: PropTypes.string,

  /**
   * Used to determine the title of the component
   */
  title: PropTypes.string,

  /**
   * Used to determine the message of the component
   */
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Used to determine the action buttons of the component
   * Note: Use standard Button props
   */
  actions: PropTypes.array
}

PlaceholderMessage.defaultProps = {
  theme: THEMES.LIGHT
}

export default memo(PlaceholderMessage)
