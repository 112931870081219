import {
  GET_ENTITY_OWNERSHIP_POSITION_REQUEST,
  GET_ENTITY_OWNERSHIP_POSITION_SUCCESS,
  GET_ENTITY_OWNERSHIP_POSITION_FAILURE,
  RESET_ENTITY_OWNERSHIP_CHART_DATA,
  statusType
} from '../../../actions'

const moment = require('moment-timezone')

/**
 * Entity Ownership Chart - Position Reducer
 * @param state
 * @param action
 * @returns
 * {
 *    [entityId]: {
 *      data: [],
 *      status: 'SUCCESS'
 *    },
 *    [entityId]: {
 *      data: [],
 *      status: 'SUCCESS'
 *    }
 * }
 */
const position = (state = {}, action) => {
  const { payload, request } = action

  switch (action.type) {
    case GET_ENTITY_OWNERSHIP_POSITION_REQUEST:
      return {
        ...state,
        [payload.entityId]: {
          status: statusType.IN_PROGRESS
        }
      }

    case GET_ENTITY_OWNERSHIP_POSITION_SUCCESS:
      return {
        ...state,
        [request.entityId]: {
          data: (payload || [])
            .filter((record) => record.current_qtr_date)
            .map((record) => {
              return {
                x: moment.utc(record.current_qtr_date).valueOf(),
                y: isNaN(record.current) ? null : record.current
              }
            })
            .reverse(),
          status: statusType.SUCCESS
        }
      }

    case GET_ENTITY_OWNERSHIP_POSITION_FAILURE:
      return {
        ...state,
        [request.entityId]: {
          status: statusType.ERROR
        }
      }

    case RESET_ENTITY_OWNERSHIP_CHART_DATA:
      return {
        ...state,
        [payload.entityId]: undefined
      }

    default:
      return state
  }
}

export default position
