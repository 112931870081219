import React, { Component } from 'react'
import { convertToChartArray } from '../../../../utils/chart.util'
import ReactHighstock from 'react-highcharts/ReactHighstock'
import {
  darkSlate,
  slate,
  paleGrey,
  softGrey,
  rain,
  sky,
  citrus,
  silver
} from '../../../../resources/materialui-overrides/colors'
import moment from 'moment-timezone'
import { find } from 'lodash'
import { getLocalizedFormat } from '../../../../utils'

export default class StockChart extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedRange: 3
    }
  }

  UNSAFE_componentWillReceiveProps () {
    if (this.refs && this.refs.stockChart && this.refs.stockChart.chart) {
      const selectedRange = this.refs.stockChart.chart.rangeSelector.selected || 0
      this.setState({
        selectedRange
      })
    }
  }

  shouldComponentUpdate (nextProps) {
    const { peers, stockHistorical, indices, peersExist } = this.props

    const peerItemsWithStock = peers.filter((peer) => peer.items && peer.items.length)
    const newPeerItemsWithStock = nextProps.peers.filter((peer) => peer.items && peer.items.length)

    const currentChartItemsLength = (peerItemsWithStock.length + (stockHistorical.length ? 1 : 0) + indices.length)
    const newChartItemsLength = (newPeerItemsWithStock.length + (stockHistorical.length ? 1 : 0) + nextProps.indices.length)

    let shouldUpdate;
    // if peer doesn't have the same color - redraw the chart
    (peerItemsWithStock || []).forEach((peerItem) => {
      const foundItem = find((newPeerItemsWithStock || []), (newItem) => {
        return peerItem._security === newItem._security
      })

      if (foundItem && foundItem.color !== peerItem.color) {
        shouldUpdate = true
      }
    })

    if (shouldUpdate) {
      return true
    }

    // If peers have changed, redraw the chart
    if (peers.length !== nextProps.peers.length) {
      return true
    }

    // Check if primary security has changed
    if ((stockHistorical && stockHistorical[0] && stockHistorical[0].Last) !== (nextProps.stockHistorical && nextProps.stockHistorical[0] && nextProps.stockHistorical[0].Last)) {
      return true
    }

    // only re-render chart if new data items were added/removed, theme changed or peers updated
    return (currentChartItemsLength && currentChartItemsLength !== newChartItemsLength) || (this.props.theme && this.props.theme !== nextProps.theme) || !peersExist
  }

  /**
     * Since we get today's stock data at various intervals after market close - delay showing today's items until midnight
     * @param stockItems
     * @return {T[]}
     */
  getFilteredStockItems (stockItems) {
    return ((stockItems && Array.isArray(stockItems)) ? stockItems : []).filter((item) => {
      return moment.utc(item.ISODate) < moment.utc().startOf('day')
    })
  }

  getSeriesPlotOptions () {
    if ((this.props.indices.length + this.props.peers.filter((peer) => peer.items && peer.items.length).length) > 0) {
      return {
        animation: false,
        compare: 'percent',
        showInNavigator: true,
        compareStart: true,
        allowPointSelect: true,
        enableMouseTracking: true,
        marker: {
          enabled: false
        }
      }
    } else {
      return {
        animation: false,
        allowPointSelect: true,
        enableMouseTracking: true,
        marker: {
          enabled: false
        }
      }
    }
  }

  getYAxisTitle () {
    if ((this.props.indices.length + this.props.peers.filter((peer) => peer.items && peer.items.length).length) > 0) {
      return '%'
    } else {
      return this.props?.stockHistorical?.[0]?.Currency || ''
    }
  }

  getYSeriesFormatter () {
    if ((this.props.indices.length + this.props.peers.filter((peer) => peer.items && peer.items.length).length) > 0) {
      return function () {
        return (this.value > 0 ? '+' : '') + this.value + '%'
      }
    } else {
      return null
    }
  }

  getTooltipFormat () {
    return function () {
      let html = '<div class="highcharts-tooltip_wrapper">'

      html += `<h2>${moment(this.x).utc().format(getLocalizedFormat('dddd, MMM DD, YYYY'))}</h2>`

      this.points && this.points.forEach((point) => {
        let pointFormat = '<h3>'

        pointFormat += `<span class='highcharts-tooltip_circle' style="color: ${point.color}">\u25CF</span>`
        pointFormat += `<span class='highcharts-tooltip_name'>${point.series.name}:</span>`
        pointFormat += `<span class='highcharts-tooltip_value'>${parseFloat(point.y).toFixed(2)}`

        if (point.point && (point.point.change === 0 || point.point.change)) {
          pointFormat += ` (${parseFloat(point.point.change).toFixed(2)}%)`
        }

        pointFormat += '</span></h3>'

        html += pointFormat
      })

      html += '</div>'

      return html
    }
  }

  getChartConfig () {
    const { theme } = this.props
    const isDarkTheme = theme === 'dark'
    const fontColor = isDarkTheme ? darkSlate : silver
    const borderColor = isDarkTheme ? slate : softGrey
    const buttonFillColor = isDarkTheme ? slate : paleGrey
    const buttonAccentColor = isDarkTheme ? citrus : rain
    const buttonTextAccentColor = isDarkTheme ? slate : '#fff'
    const localFormat = getLocalizedFormat('%m/%d')

    return {
      chart: {
        marginLeft: 0,
        marginRight: 45,
        backgroundColor: 'transparent',
        type: 'spline',
        pinchType: false,
        className: 'q4-fade-in',
        reflow: false,
        panning: false
      },
      enableMouseTracking: false,
      exporting: false,
      credits: false,
      navigator: false,
      scrollbar: false,
      legend: {
        enabled: false
      },
      rangeSelector: {
        labelStyle: {
          display: 'none'
        },
        inputEnabled: false,
        buttons: [
          { type: 'week', count: 1, text: '1W' },
          { type: 'month', count: 1, text: '1M' },
          { type: 'month', count: 3, text: '3M' },
          { type: 'month', count: 6, text: '6M' },
          {
            type: 'year',
            count: 1,
            text: '1Y',
            offsetMin: 1
          }
        ],
        selected: this.state.selectedRange,
        buttonSpacing: 5,
        buttonTheme: { // styles for the buttons
          width: 22,
          height: 15,
          padding: 4,
          fill: buttonFillColor,
          r: 4,
          style: {
            fontFamily: 'Open Sans, Sans-serif',
            color: buttonAccentColor,
            fontSize: '13px',
            fontWeight: '400'
          },
          states: {
            hover: {
              'stroke-width': 0,
              fill: buttonAccentColor,
              style: {
                color: buttonTextAccentColor,
                fontWeight: '400'
              }
            },
            select: {
              'stroke-width': 0,
              fill: buttonAccentColor,
              style: {
                color: buttonTextAccentColor,
                fontWeight: '400'
              }
            }
          }
        },
        x: -5
      },
      xAxis: {
        type: 'datetime',
        lineWidth: 0,
        tickLength: 0,
        crosshair: {
          color: borderColor
        },
        labels: {
          autoRotation: [0],
          rotation: 0,
          style: {
            color: silver,
            fontSize: '13px',
            fontFamily: 'Open Sans, Sans-serif'
          }
        },
        dateTimeLabelFormats: {
          day: localFormat,
          week: localFormat,
          month: localFormat,
          year: localFormat
        }
      },
      yAxis: this.getYaxis(fontColor, borderColor),
      tooltip: {
        useHTML: true,
        shared: true,
        split: false,
        hideDelay: 1100,
        formatter: this.getTooltipFormat()
      },
      plotOptions: {
        flags: {
          tooltip: {
            enabled: false
          }
        },
        spline: {
          states: {
            hover: {
              halo: {
                opacity: 0,
                size: 0
              }
            }
          },
          tooltip: {
            enabled: false
          }
        },
        series: this.getSeriesPlotOptions()
      },
      series: this.getSeries()
    }
  }

  getYaxis (fontColor, borderColor) {
    return [{
      id: 'stock-axis',
      // minRange: 1,
      allowDecimals: false,
      labels: {
        useHTML: true,
        align: 'left',
        style: {
          color: silver,
          fontWeight: 300,
          fontSize: '13px',
          zIndex: -1
        },
        formatter: this.getYSeriesFormatter(),
        y: 0,
        x: 10
      },
      plotLines: false,
      gridLineWidth: 1,
      gridLineColor: borderColor,
      lineColor: borderColor,
      lineWidth: 0,
      tickAmount: 4,
      tickWidth: 0,
      opposite: true,
      title: {
        text: this.getYAxisTitle(),
        align: 'high',
        rotation: 0,
        offset: 10,
        y: 3,
        x: -2,
        style: {
          color: silver
        }
      }
    }].concat(this.props.indices.map((item) => {
      return {
        id: item.key + '-axis',
        labels: { enabled: false },
        title: { enabled: false },
        gridLineWidth: 0,
        lineWidth: 0,
        tickWidth: 0
      }
    })).concat(this.props.peers.filter((peer) => peer.items).map((item) => {
      return {
        id: item._security + '-axis',
        labels: { enabled: false },
        title: { enabled: false },
        gridLineWidth: 0,
        lineWidth: 0,
        tickWidth: 0
      }
    }))
  }

  getSeries () {
    return [{
      name: 'Stock',
      id: 'stock',
      type: 'spline',
      color: sky,
      lineWidth: 2,
      marker: {
        symbol: 'circle',
        lineColor: null
      },
      zIndex: 3,
      data: convertToChartArray(this.getFilteredStockItems(this.props.stockHistorical))
    }].concat(this.props.indices.map((item) => {
      return {
        name: item.name,
        id: item.name,
        type: 'spline',
        color: item.color,
        zIndex: 2,
        lineWidth: 2,
        marker: {
          symbol: 'circle',
          lineColor: null
        },
        data: convertToChartArray(this.getFilteredStockItems(item.data))
      }
    })).concat(this.props.peers.map((item) => {
      return {
        name: item.symbol,
        id: item._security,
        type: 'spline',
        color: item.color,
        zIndex: 2,
        lineWidth: 2,
        marker: {
          symbol: 'circle',
          lineColor: null
        },
        data: convertToChartArray(this.getFilteredStockItems(item.items))
      }
    }))
  }

  render () {
    return (
      <ReactHighstock className='stock-chart_item' ref='stockChart' config={this.getChartConfig()} />
    )
  }
}
