import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {
    getPipeline,
    getPipelineLost,
    setActiveTab,
    updateDeal,
    filterAllSavedTargets,
    exportPipelineCsv,
    openModal,
    statusType
} from '../../actions'
import { Banner, NoSubscriptionMessage } from '../../components';
import { Mask } from '../../components/shared';
import {getActiveTicker, isNonTrading} from '../../utils';
import {isServiceEnabled} from '../../utils/user.util';
import PipelineComponent from '../../components/pipeline/pipeline.component';

function isPropReady(status) {
    return [statusType.SUCCESS, statusType.ERROR].includes(status);
}

/**
 * Pipeline Component
 * Refer to https://github.com/rcdexta/react-trello for documentation
 */
class PipelineContainer extends Component {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            initialLoad: true
        };
    }

    /**
     * ComponentWillMount
     */
    UNSAFE_componentWillMount() {
        const {pipeline} = this.props;

        if (pipeline.activeTab === 'lost') {
            this.loadLostTab();
        } else {
            this.loadOpenTab();
        }
    }

    /**
     * ComponentWillReceiveProps
     * @param nextProps
     */
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const {pipeline, stock, profile} = nextProps;
        const {initialLoad} = this.state;
        const { data } = profile;

        const isPipelineLoaded = isPropReady(pipeline.status.pipeline);
        const isTargetLoaded = isPropReady(pipeline.status.targets);
        const isStockLoaded = !isNonTrading(data) ? isPropReady(stock.status) : true;

        this.setState({
            initialLoad: initialLoad && (!isPipelineLoaded || !isTargetLoaded || !isStockLoaded),
            isPipelineLoaded: (isPipelineLoaded && isTargetLoaded && isStockLoaded)
        });
    };

    componentDidUpdate = (prevProps) => {
        const {pipeline, activityForm} = this.props;

        if (prevProps.activityForm.status !== activityForm.status && activityForm.status === statusType.SUCCESS) {
            (pipeline.activeTab === 'lost' ? this.loadLostTab() : this.loadOpenTab());
        }
    };

    onTargetFilterChange = (filter) => {
        this.props.filterTarget(filter || '');
    };

    loadLostTab() {
        const {pipeline, setActiveTab} = this.props;
        const lost = pipeline.lost && pipeline.lost.length && pipeline.lost[0];
        setActiveTab({value: 'lost', stage: (lost && lost.id)});
    }

    loadOpenTab() {
        const {getPipeline, setActiveTab} = this.props;
        setActiveTab({value: 'open'});
        getPipeline();
    }

    handleDealCreate(activeTab = 'open') {
        (activeTab === 'lost') ? this.loadLostTab() : this.loadOpenTab();
    }

    /**
     * Returns Subscription Page
     * @return {XML}
     */
    getSubscriptionScreen = () => {
        return (
            <div className='q4-fade-in'>
                <Banner
                    title='Pipeline'
                    icon='q4i-deal-2pt'
                />
                <NoSubscriptionMessage image={require('../../resources/images/subscription/pipeline.png').default}/>
            </div>
        );
    };

    /**
     * Render
     * @return {XML}
     */
    render() {
        const props = {...this.props, filterTarget: this.onTargetFilterChange};
        const {pipeline, profile} = this.props;
        const {initialLoad, isPipelineLoaded} = this.state;

        // show mask if data is not ready on first load
        if (initialLoad) {
            return (<Mask visible={true} spinner={true}/>);
        }
        // if pipeline don't have data redirect to error page
        if (pipeline.status.pipeline === statusType.ERROR) {
            return <Redirect to='/error/404'/>;
        }

        const services = profile && profile.data && profile.data.services;
        const isSubscribed = isServiceEnabled('crm', services || []);

        if (services && services.length && !isSubscribed) {
            return this.getSubscriptionScreen();
        }

        return (
            <PipelineComponent
                className={`${isPipelineLoaded ? 'q4-fade-in' : ''}`}
                onDealCreate={() => this.handleDealCreate(pipeline.activeTab)}
                onDealCancel={() => this.loadOpenTab()}
                {...props} />
        );
    }
}

/**
 * Maps App Store State to PipelineContainer Props
 * @param state - store state
 */
const mapStateToProps = (state) => {
    const ticker = getActiveTicker(state.profile && state.profile.data);

    return {
        pipeline: state.pipeline,
        stock: state.stock.quote,
        targets: state.targeting.allSavedTargets,
        profile: state.profile,
        securityId: ticker && ticker._security,
        activityForm: state.activity.activityForm
    };
};

const mapDispatchToProps = (dispatch) => ({
    getPipeline: bindActionCreators(getPipeline, dispatch),
    getPipelineLost: bindActionCreators(getPipelineLost, dispatch),
    setActiveTab: bindActionCreators(setActiveTab, dispatch),
    updateDeal: bindActionCreators(updateDeal, dispatch),
    filterTarget: bindActionCreators(filterAllSavedTargets, dispatch),
    exportPipelineCsv: bindActionCreators(exportPipelineCsv, dispatch),
    openModal: bindActionCreators(openModal, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PipelineContainer));
