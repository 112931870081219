import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { ENTITY_TYPE } from '../../../utils'

const GET_INSTITUTION_POSITION = gql`
  query InstitutionHoldingCurrent(
    $tickerId: [String]!
    $entityId: [String]!
    $source: String!
   ) {
    instHoldingCurrent(
      tickerId: $tickerId
      institutionId: $entityId
      source: $source
    ) {
      items {
        current
      }
    }
  }`

const GET_FUND_POSITION = gql`
  query FundHoldingCurrent(
    $tickerId: [String]!
    $entityId: [String]!
    $source: String!
  ) {
    fundHoldingCurrent(
      tickerId: $tickerId
      fundId: $entityId
      source: $source
    ) {
      items {
        current
      }
    }
  }`

const GET_ATTRIBUTE = gql`
  query Attribute(
    $securityId: [String]!
    $entityId: String!
    $entityType: String!
  ) {
    aiScore: aiScore(
      securityId: $securityId
      entityId: [$entityId]
      entityType: $entityType
    ) {
      items {
        score
      }
    }
    purchasingPower: purchasingPower(
      securityId: $securityId
      entityId: [$entityId]
      entityType: $entityType
    ) {
      items {
        purchasingPower
      }
    }
  }`

/**
 * Current Position
 * @param type
 * @param options
 */
export function usePositionQuery (type, options) {
  const query = (type === ENTITY_TYPE.FUND) ? GET_FUND_POSITION : GET_INSTITUTION_POSITION
  return useQuery(query, options)
}

/**
 * Attribute - ai score, purchasing power
 * @param options
 */
export function useAttributeQuery (options) {
  return useQuery(GET_ATTRIBUTE, options)
}
