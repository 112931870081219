import React from 'react'
import PropTypes from 'prop-types'
import { ComboBox } from '../../../../components'
import { isAllEntitySearch, THEMES, SEARCH_TYPE } from '../../../../utils'

/**
 * Search Location Filters
 * @param props
 * @returns {*}
 * @constructor
 */
const Location = (props) => {
  const {
    isLoading,
    regionOptions,
    regionSelectedOptions,
    countryOptions,
    countrySelectedOptions,
    metroOptions,
    metroSelectedOptions,
    onFilterChange,
    type
  } = props

  const allRegions = [].concat(regionOptions || []).map((option) => ({ ...option, label: option.value }))
  const allCounties = [].concat(countryOptions || []).map((option) => ({ ...option, label: option.value }))
  const allMetros = [].concat(metroOptions || []).map((option) => ({ ...option, label: option.value }))
  const isEntityType = type.some((item) => [SEARCH_TYPE.CONTACT, SEARCH_TYPE.INSTITUTION, SEARCH_TYPE.FUND].includes(item))

  const selectedRegions = [].concat(regionOptions || [])
    .filter((option) => [].concat(regionSelectedOptions || []).includes(option.value))
    .map((option) => ({ ...option, label: option.value }))

  const selectedCountries = [].concat(countryOptions || [])
    .filter((option) => [].concat(countrySelectedOptions || []).includes(option.value))
    .map((option) => ({ ...option, label: option.value }))

  const selectedMetros = [].concat(metroOptions || [])
    .filter((option) => [].concat(metroSelectedOptions || []).includes(option.value))
    .map((option) => ({ ...option, label: option.value }))

  /**
   * Handle filter change
   * @param {String} field - region, country_name, metro
   * @param {Array} options - array of selected values
   */
  const handleOnChange = (field, options) => {
    const values = [].concat(options || []).map((option) => option && option.value)
    onFilterChange(field, values)
  }

  /**
   * Handle removing selected option
   * @param {String} field - region, country_name, metro
   * @param {String} removedValue - value to remove
   */
  const handleOnRemove = (field, removedValue) => {
    const selected = {
      region: selectedRegions,
      country_name: selectedCountries,
      metro: selectedMetros
    }[field]

    const values = [].concat(selected || [])
      .filter((option) => option.value !== removedValue)
      .map((option) => option && option.value)

    onFilterChange(field, values)
  }

  return (
    <section className='search-page_filter-section q4-fade-in'>
      <div className='search-page_filter-header'>Filter By Location</div>
      <div className='search-page_filter-content'>
        <div className='search-page_filter-note'>
          {`Based on related ${isAllEntitySearch(type) ? 'Institutions or Securities' : !isEntityType ? 'Securities' : 'Institutions'}`}
        </div>
        <div className='field field--full field--text'>
          <label className='field_label'>Region</label>
          <ComboBox
            theme={THEMES.LIGHT_GREY}
            selectProps={{
              loading: isLoading,
              disabled: isLoading,
              placeholder: 'Select',
              showInFieldValues: false,
              value: selectedRegions,
              options: allRegions,
              isMulti: true,
              isDefaultList: true,
              closeMenuOnSelect: false,
              showDropdownIndicator: true,
              onChange: (options) => handleOnChange('region', options)
            }}
            removableListProps={{
              items: selectedRegions,
              onRemove: (value) => handleOnRemove('region', value)
            }}
          />
        </div>

        <div className='field field--full field--text'>
          <label className='field_label'>Country</label>
          <ComboBox
            theme={THEMES.LIGHT_GREY}
            selectProps={{
              loading: isLoading,
              disabled: isLoading,
              placeholder: 'Select',
              showInFieldValues: false,
              value: selectedCountries,
              options: allCounties,
              isMulti: true,
              isDefaultList: true,
              closeMenuOnSelect: false,
              showDropdownIndicator: true,
              onChange: (options) => handleOnChange('country_name', options)
            }}
            removableListProps={{
              items: selectedCountries,
              onRemove: (value) => handleOnRemove('country_name', value)
            }}
            />
        </div>

        {isEntityType && (
          <div className='field field--full field--text'>
            <label className='field_label'>Metro Area</label>
            <ComboBox
              theme={THEMES.LIGHT_GREY}
              selectProps={{
                loading: isLoading,
                disabled: isLoading,
                placeholder: 'Select',
                showInFieldValues: false,
                value: selectedMetros,
                options: allMetros,
                isMulti: true,
                isDefaultList: true,
                closeMenuOnSelect: false,
                showDropdownIndicator: true,
                onChange: (options) => handleOnChange('metro', options)
              }}
              removableListProps={{
                items: selectedMetros,
                onRemove: (value) => handleOnRemove('metro', value)
              }}
            />
          </div>
        )}
      </div>
    </section>
  )
}

Location.propTypes = {
  region: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired
}

Location.defaultProps = {
  region: []
}

export default Location
