import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// actions
import { useCommentQuery } from '../../../hook'

// components
import { RenderHTML } from '../../../../index'
import { get } from 'lodash'

const propTypes = {
  entityId: PropTypes.string.isRequired
}

const defaultProps = {}

/**
 * Comment Component
 * @param props
 */
function Comment ({ entityId }) {
  const { data } = useCommentQuery({ variables: { entityId } })
  const comment = get(data, 'comment.items[0].comment', '')

  if (!comment.length) {
    return null
  }

  return (
    <Grid container spacing={2} className='tearsheet_section'>
      <Grid item xs={12} className='tearsheet_section-title'>Comments</Grid>
      <Grid item xs={12} className='tearsheet_section-content tearsheet_section-content-editor'>
        <RenderHTML html={comment} />
      </Grid>
    </Grid>
  )
}

Comment.propTypes = propTypes
Comment.defaultProps = defaultProps

export default Comment
