import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Message } from '../../../components'
import { THEMES } from '../../../utils'

const propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

const defaultProps = {
  content: {}
}

/**
 * General Confirmation Modal
 * @param onClose
 * @param content: title, message, button label
 * @param onConfirm
 */
const ConfirmModal = ({ onClose, content, onConfirm }) => {
  const { title, message, label } = content
  return (
    <Message
      visible
      type='warning'
      title={title}
      message={message}
      onClose={onClose}
      buttons={[
        {
          ui: THEMES.DARK,
          label: 'cancel',
          onClick: onClose
        },
        {
          ui: THEMES.SPICE,
          label: label || 'delete',
          onClick: () => onConfirm()
        }
      ]}
    />
  )
}

ConfirmModal.propTypes = propTypes
ConfirmModal.defaultProps = defaultProps

export default memo(ConfirmModal)
