import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

// actions
import { useSecurityMoverQuery } from '../../hook'

// components
import TopMoverCard from './mover.component'

// utils
import { getActiveTicker, getOwnershipType, ENTITY_TYPE, MOVER_TYPE, THEMES } from '../../../../utils'
import { get } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  entityId: PropTypes.string.isRequired
}

const defaultProps = {
  theme: THEMES.DARK
}

const { SECURITY } = ENTITY_TYPE
const { BUY, SELL } = MOVER_TYPE

/**
 * Top Mover Card
 * @param props
 */
function TopMovers (props) {
  const { dataId, theme, entityId, ownershipType } = props
  const { value = '13f', label = '13F' } = ownershipType

  const [range, setRange] = useState(3)
  const [variables, setVariables] = useState({
    entityId,
    entityType: SECURITY,
    quarterDate: moment.utc().subtract(range, 'months').format(),
    source: value
  })

  const { loading: loadingBuyers, data: buy } = useSecurityMoverQuery({ variables: { ...variables, moverType: BUY } })
  const { loading: loadingSellers, data: sell } = useSecurityMoverQuery({ variables: { ...variables, moverType: SELL } })

  const buyers = get(buy, 'mover.items', [])
  const hasBuyers = !loadingBuyers && !!buyers.length
  const sellers = get(sell, 'mover.items', [])
  const hasSellers = !loadingSellers && !!sellers.length

  const handleRangeChange = (option) => {
    const { value } = (option || {})

    if (!value || (value === range)) {
      return
    }

    const quarterDate = (value === 'mostRecent') ? moment.utc().format() : moment.utc().subtract(value, 'months').format()

    setRange(value)
    setVariables({ ...variables, quarterDate })
  }

  /**
   * Render Top Buyer Component
   */
  return (
    <TopMoverCard
      dataId={dataId}
      theme={theme}
      isLoading={[loadingBuyers, loadingSellers].some((loading) => !!loading)}
      data={{ buyers, hasBuyers, sellers, hasSellers }}
      subscription={label}
      range={range}
      onRangeChange={handleRangeChange}
    />
  )
}

TopMovers.propTypes = propTypes
TopMovers.defaultProps = defaultProps

const mapStateToProps = (state, ownProps) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)

  return {
    securityId: get(ticker, 'q4_entity_id'),
    ownershipType: getOwnershipType(profile, ownProps.entityId)
  }
}

export default connect(mapStateToProps)(TopMovers)
