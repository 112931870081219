import {
  GET_EVENT_MODAL_FAILURE,
  GET_EVENT_MODAL_REQUEST,
  GET_EVENT_MODAL_SUCCESS,
  FETCHING,
  FETCHED,
  IDLE,
  FAILED
} from '../../../actions'

const initial = {
  data: {},
  status: IDLE
}

const modal = (state = initial, action) => {
  switch (action.type) {
    case GET_EVENT_MODAL_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_EVENT_MODAL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: FETCHED
      }
    case GET_EVENT_MODAL_FAILURE:
      return {
        ...state,
        status: FAILED
      }
    default:
      return state
  }
}

export default modal
