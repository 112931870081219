import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    fetchCalendar,
    fetchCurrentActivities,
    FETCHED,
    setCurrentActivityDate
} from '../../../../actions/widget/activity/currentActivity.actions';
import {statusType} from '../../../../actions';
import ActivityCalendarItem from '../../../../components/widget/activity/calendar/calendar.component';
import moment from 'moment-timezone';

class ActivityCalendar extends Component {

    constructor(props) {
        super(props);

        const today = moment.utc();
        this.state = {
            today,
            category: ['comment', 'phone', 'email', 'meeting', 'earnings', 'roadshow', 'conference'],
            calendarStart: moment(today, 'MM-DD-YYYY hh:mm:ss a').subtract(6, 'months'),
            calendarEnd: moment(today, 'MM-DD-YYYY hh:mm:ss a').add(6, 'months')
        };
    }

    loadCalendarActiveDates = () => {
        this.props.fetchCalendar({
            category: this.state.category,
            startDate: this.state.calendarStart.format('YYYY-MM-DD'),
            endDate: this.state.calendarEnd.format('YYYY-MM-DD'),
            currentAndUpcomingActivity: false
        });
    };

    loadCurrentActivities(startDate) {
        if (!startDate) {
            return;
        }

        const currentDate = moment(startDate, 'MM-DD-YYYY hh:mm:ss a').format('YYYY-MM-DD');
        const params = {
            category: this.state.category,
            startDate: currentDate,
            endDate: currentDate,
            currentAndUpcomingActivity: true,
            limit: 50,
            sort: JSON.stringify([
                {
                    property: 'start.date_time',
                    direction: 'ASC'
                }
            ])
        };

        this.props.fetchCurrentActivities(params);
    }

    _fetchData = () => {
        this.loadCalendarActiveDates();
        this.loadCurrentActivities(this.props.currentActivityDate);
    };

    componentDidMount = () => {
        const {isEdit, status} = this.props;
        if (isEdit && status === FETCHED) {
            return;
        }

        const refreshInterval = this.props.options.refreshInterval;
        if (refreshInterval) {
            this.timer = setInterval(this._fetchData.bind(this), refreshInterval);
        }

        this.props.setCurrentActivityDate(this.state.today);
        this.loadCalendarActiveDates();
        this.loadCurrentActivities(this.state.today);
    };

    componentWillUnmount = () => {
        if (this.timer) {
            clearInterval(this.timer);
        }
    };

    componentDidUpdate = (prevProps) => {
        const {activityForm} = this.props;

        if (activityForm !== prevProps.activityForm && activityForm.status === statusType.SUCCESS) {
            this._fetchData();
        }
    }

    render() {
        return <ActivityCalendarItem category={this.state.category} {...this.props}
                                     loadCurrentActivities={(startTime) => this.loadCurrentActivities(startTime)}/>;
    }
}

const mapStateToProps = (state) => {
    const activity = state.widget.activity;

    return {
        theme: state.dashboard.dashboardState.theme,
        status: activity.status || activity.currentActivities.status,
        currentActivities: ((activity && activity.currentActivities && activity.currentActivities.activities) || []),
        currentActivityDate: activity.currentActivities.currentActivityDate,
        calendar: (activity.calendar && activity.calendar.list) || [],
        activityForm: state.activity.activityForm
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchCurrentActivities: bindActionCreators(fetchCurrentActivities, dispatch),
    setCurrentActivityDate: bindActionCreators(setCurrentActivityDate, dispatch),
    fetchCalendar: bindActionCreators(fetchCalendar, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityCalendar);
