import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getClassName } from '../../../utils/ui'
import { Button, ReadMore } from '../../../components'
import './drawerLibraryCard.component.css'

function DrawerLibraryCard (props) {
  const { title, description, isLockedTemplate, isDisabled, onSelect } = props

  const baseClassName = getClassName('drawer-library-card', [
    { condition: isLockedTemplate, trueClassName: 'drawer-library-card--locked' },
    { condition: isDisabled, trueClassName: 'drawer-library-card--disabled' }
  ])

  return (
    <article className={baseClassName}>
      <h3 className='drawer-library-card_title'>{title}</h3>
      {description && (
        <div className='drawer-library-card_description'>
          <ReadMore
            text={description}
            lines={2}
          />
        </div>
      )}
      {!isLockedTemplate && onSelect && (
        <div className='drawer-library-card_action'>
          <Button theme='citrus' icon='q4i-plus-4pt' onClick={onSelect} />
        </div>
      )}
      {isLockedTemplate && (
        <div className='drawer-library-card_indicator'>
          <i className='q4i-lock-4pt' />
        </div>
      )}
    </article>
  )
}

DrawerLibraryCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isLockedTemplate: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSelect: PropTypes.func
}

export default memo(DrawerLibraryCard)
