import React from 'react'
import { formatDateByTimezone, getActivityCategoryIcon, VIRTUAL, formatLocalizedDate } from '../../../../utils'
import { RenderHTML } from '../../../../components'
import { get } from 'lodash'

/**
 * Get table header
 * @param location
 * @returns {*}
 */
const getHeader = (location) => {
  return (
    <thead>
      <tr className='itinerary_table-header'>
        <th colSpan='5'>{location || '-'}</th>
      </tr>
      <tr className='itinerary_table-subheader'>
        <th width='40'>Type</th>
        <th width='120'>Time</th>
        <th>Details</th>
        <th width='210'>Attendees</th>
        <th width='144'>Participants</th>
      </tr>
    </thead>
  )
}

/**
 * Get activity time
 * @param allDay
 * @param start
 * @param end
 * @returns {string}
 */
const getTime = ({ allDay, start, end }) => {
  const startDate = (start && start.date_time) || null
  const endDate = (end && end.date_time) || null
  const startTimezone = (start && start.timezone) || null
  const endTimezone = (end && end.timezone) || null
  const timeFormat = 'h:mma'

  return (
    allDay
      ? 'All day'
      : startDate
        ? endDate
          ? `${formatDateByTimezone(startDate, timeFormat, startTimezone)} ${endDate && `- ${formatDateByTimezone(endDate, timeFormat, endTimezone)}`}`
          : formatDateByTimezone(startDate, timeFormat, startTimezone)
        : endDate
          ? <>{formatDateByTimezone(endDate, timeFormat, endTimezone)} <span className='silver'>(end)</span></>
          : 'All day')
}

/**
 * Get activity venue
 * @param address
 * @returns {null[]}
 */
const getVenue = (address) => {
  return (
    (address || []).map((address) => {
      const { venue } = address || {}
      return (
        (venue && venue.length) ? <span key={venue} className='itinerary_label'>Venue: {venue}</span> : null
      )
    })
  )
}

/**
 * Get activity tags
 * @param tag
 */
const getTags = (tag) => {
  const tags = (tag || []).map((tag) => {
    const { name } = tag || {}
    return ((name && name.length) ? <span key={name}>{name}</span> : null)
  })

  return (
    (tag && Array.isArray(tag) && tag.length)
      ? <span className='itinerary_label'>Tags: {tags}</span>
      : null
  )
}

/**
 * Get activity notes
 * @param body
 * @returns {null}
 */
const getNotes = (body) => {
  return (
    (body && body.length)
      ? <span className='itinerary_label'>
          Note: <span className='itinerary_notes'>{<RenderHTML html={body} />}</span>
      </span>
      : null
  )
}

/**
 * Get activity attendees
 * @param links
 * @returns {*}
 */
const getAttendees = (links) => {
  if (!links || !Array.isArray(links) || !links.length) {
    return null
  }

  const exclude = []

  links
    .sort((a, b) => (a.entity_type < b.entity_type) ? -1 : (a.entity_type > b.entity_type) ? 1 : 0)
    .map((link) => {
      const type = (link && link.entity_type) || ''
      const id = (link && (link.q4_institution_id || link.institution_id)) || null

      if (type.toLowerCase() === 'contact') {
        link._institution = links.filter((link) => (link && link.item && link.item._id === id))[0]

        if (link._institution && !exclude.includes(id)) {
          exclude.push(id)
        }
      }

      return link
    })

  return links.filter((link) => link && !exclude.includes(link._id))
}

/**
 * Get activity participants
 * @param participants
 * @returns {null[]}
 */
const getParticipants = (participants) => {
  return (
    (participants || []).map((participant) => {
      const { _id, full_name } = participant || {}
      return (
        (full_name && full_name.length)
          ? <span key={_id} className='itinerary_label itinerary_label--small'>{full_name}</span>
          : null
      )
    })
  )
}

/**
 * Get activity row data
 * @param activities
 * @returns {*[]}
 */
const getRows = (activities) => {
  return (activities || []).map((activity) => {
    if (!activity || !activity._id) {
      return null
    }

    const { _id, category, all_day, start, end, title, address, tag, body, links, participants } = activity
    const attendees = getAttendees(links)

    return (
      <tr key={_id} className='itinerary_table-item'>
        <td className='itinerary_table-column itinerary_table-column--center'>
          <i className={`itinerary_icon ${getActivityCategoryIcon(category)}`} />
        </td>
        <td className='itinerary_table-column'>
          {getTime({ all_day, start, end })}
        </td>
        <td className='itinerary_table-column'>
          <span className='itinerary_label itinerary_label--black'>{title}</span>
          {getVenue(address)}
          {getTags(tag)}
          {getNotes(body)}
        </td>
        <td className='itinerary_table-column itinerary_table-column--truncate'>
          {(attendees || []).map((attendee) => {
            if (!attendee || !attendee._id) {
              return null
            }

            const { _id, full_name, fund_name, institution_name } = attendee
            const type = (attendee.entity_type || '').toLowerCase()
            const name = (type === 'contact') ? full_name : (type === 'fund') ? fund_name : institution_name

            return (
              <div key={_id} className='itinerary_attendee'>
                <div className='itinerary_attendee--attendee'>{name}</div>
                {(type === 'contact') && institution_name
                  ? <div className='itinerary_attendee--attached'>{institution_name}</div>
                  : null}
              </div>
            )
          })}
        </td>
        <td className='itinerary_table-column itinerary_table-column--truncate'>
          {getParticipants(participants)}
        </td>
      </tr>
    )
  })
}

/**
 * Activity Component
 * @param itinerary
 * @returns {null}
 * @constructor
 */
const Activity = ({ itinerary = {} }) => {
  return (
    <div className='data'>
      <span className='data_title'>Itinerary</span>
      <div className='itinerary'>
        <div className='itinerary_item'>
          {Object.entries(itinerary).map((group, index) => {
            if (!group || !Array.isArray(group) || !group.length) {
              return null
            }

            return (
              <div key={index}>
                <h2 className='itinerary_calendar-day'>
                  {formatLocalizedDate(formatDateByTimezone(group[0], 'dddd, MMMM D, YYYY'), 'dddd, MMMM D, YYYY')}
                </h2>

                {Object.entries(group[1]).map((location, index) => {
                  if (!location || !Array.isArray(location) || !location.length) {
                    return null
                  }
                  const isVirtual = get(location, '[1][0].virtual', null)

                  return (
                    <table key={index} className='itinerary_table'>
                      {isVirtual ? getHeader(VIRTUAL) : getHeader(location[0])}
                      <tbody>{getRows(location[1])}</tbody>
                    </table>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Activity
