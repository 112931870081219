import {
  GET_INSTITUTION_ANALYTICS_REQUEST,
  GET_INSTITUTION_ANALYTICS_SUCCESS,
  GET_INSTITUTION_ANALYTICS_FAILURE,
  statusType
} from '../../actions'

const initial = {
  institution: null,
  visited: false,
  status: statusType.IDLE
}

const analytics = (state = initial, action) => {
  const { payload = {}, type } = action

  switch (type) {
    case GET_INSTITUTION_ANALYTICS_REQUEST:
      return {
        ...initial,
        status: statusType.IN_PROGRESS
      }

    case GET_INSTITUTION_ANALYTICS_SUCCESS:
      return {
        ...state,
        institution: payload.institution || null,
        visited: payload.visited || false,
        status: statusType.SUCCESS
      }

    case GET_INSTITUTION_ANALYTICS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    default:
      return state
  }
}

export default analytics
