import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// actions
import {
  fetchInstitutionContacts,
  modalType,
  openModal,
  pushFlyoutDetailsItem,
  resetContactGrid,
  statusType
} from '../../../actions'
// components
import Toolbar from './toolbar/toolbar.component'
import Table from './table/table.component'
import { Spinner } from '../../../components';
// utils
import { THEMES } from '../../../utils/ui'

import { getActiveTicker, getPageSizeFromStorage } from '../../../utils'
import './grid.container.css'
import { config } from '../../../config'
import queryString from 'query-string'
import { fetchFundContacts, resetFundContactGrid } from '../../../actions/contact'
import { ENTITY_FUND } from '../../../utils/entity'
import { getActiveToken } from '../../../utils/auth/auth.util'

const PAGE_SIZE_ID = 'contact-grid'

class ContactGrid extends PureComponent {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.initialQuery = {
      query: '',
      filter: '',
      sort: { property: 'full_name', direction: 'ASC' },
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10,
      services: ['currentHolding', 'lastActivity']
    }

    this.state = {
      query: this.initialQuery
    }
  }

  /**
   * ComponentDidMount
   * Initially fetch contacts
   */
  componentDidMount () {
    this.getContacts()
  }

  /**
   * ComponentDidUpdate
   * @param prevProps
   * @param prevState
   */
  componentDidUpdate (prevProps, prevState) {
    const { entityId } = this.props
    const { entityId: prevEntityId } = prevProps

    if (entityId !== prevEntityId) {
      this.resetQuery()
    }
  }

  /**
   * ComponentWillUnmount
   * Reset the grid
   */
  componentWillUnmount () {
    const { entityType } = this.props
    if (entityType && entityType === ENTITY_FUND) {
      this.props.resetFundContactGrid()
    } else {
      this.props.resetContactGrid()
    }
  }

  /**
   * Fetch contacts
   * @param query
   */
  getContacts = (query = this.getQueryParams()) => {
    const { entityId, fetchInstitutionContacts, entityType, fetchFundContacts } = this.props
    if (entityType && entityType === ENTITY_FUND) {
      entityId && fetchFundContacts(query)
    } else {
      entityId && fetchInstitutionContacts(query)
    }
  }

  /**
   * Get query params
   * @returns {{}&{securityId, entity_id}}
   */
  getQueryParams = () => {
    const { securityId, entityId, includeNotes, entityType } = this.props
    const queryParams = (entityType && entityType === ENTITY_FUND) ? { fund_id: entityId, ...this.state.query } : { ...this.state.query }

    if (queryParams.sort) {
      queryParams.sort = JSON.stringify([queryParams.sort])
    }
    return {
      ...queryParams,
      securityId,
      entity_id: entityId,
      notes: includeNotes
    }
  }

  /**
   * Reset filters and re-fetch activities
   */
  resetQuery () {
    const query = {
      ...this.initialQuery,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
    this.setState({
      query
    }, () => {
      this.getContacts()
    })
  }

  handleQueryChange = (query) => {
    this.setState({
      query: {
        ...this.state.query,
        ...query
      }
    }, () => {
      this.getContacts()
    })
  }

  handleSortChange = ({ property, direction }) => {
    this.handleQueryChange({
      sort: { property, direction: direction.toUpperCase() }
    })
  }

  /**
   * Open AddToBB Modal and pass bulk selected contacts
   * @param contacts
   */
  addContactsToBB (contacts) {
    const { openModal } = this.props
    openModal({
      type: modalType.ADD_TO_BRIEFING_BOOK_MODAL,
      props: {
        entities: contacts.map((row) => {
          return {
            entityType: row.entityType,
            entityId: row.entityId,
            institutionId: row.institutionId
          }
        })
      }
    })
  }

  /**
   * Handle bulk actions based on action id
   * @param actionId
   * @param selectedRows
   */
  handleBulkAction = (actionId, selectedRows) => {
    if (!actionId || !selectedRows || !selectedRows.length) {
      return
    }

    switch (actionId) {
      case 'contacts-add-to-bb':
        this.addContactsToBB(selectedRows)
        break
      default:
        break
    }
  }

  /**
   * Handle export request
   */
  handleExport = async () => {
    const { token, entityType } = this.props
    const exportURLType = (entityType && entityType === ENTITY_FUND) ? 'fund' : 'institution'
    const exportToken = await  getActiveToken(token)

    const query = {
      ...this.getQueryParams(),
      token: exportToken
    }
    delete query.page
    delete query.limit

    window.open(`${config.apiUrl}/${exportURLType}/contact/v2/export?${queryString.stringify(query)}`, '_self')
  }

  /**
   * Handle row click
   * Navigate to contact entity page
   * @param rowData
   */
  handleRowClick = (rowData) => {
    if (!rowData) {
      return
    }

    const { history } = this.props

    this.props.handleRowClick
      ? this.props.handleRowClick({ contactId: rowData._id })
      : history.push(`/contact/${rowData._id}`)
  }

  render () {
    const { entityType } = this.props
    const { data, dataTotal, status } = (entityType && entityType === ENTITY_FUND) ? this.props.fundGrid : this.props.grid
    const { query } = this.state

    return (
      <div className='contact-grid'>
        {(status === statusType.IN_PROGRESS) && (
          <Spinner mask theme={THEMES.RAIN} />
        )}
        <Toolbar
          searchValue={query.query}
          filter={query.filter}
          noData={!data || !data.length}
          handleQueryChange={this.handleQueryChange}
          handleExport={this.handleExport}
        />
        <Table
          pageSizeId={PAGE_SIZE_ID}
          data={data}
          dataTotal={dataTotal}
          status={status}
          query={query}
          handleQueryChange={this.handleQueryChange}
          handleSortChange={this.handleSortChange}
          handleBulkAction={this.handleBulkAction}
          handleRowClick={this.handleRowClick}
        />
      </div>
    )
  }
}

ContactGrid.propTypes = {
  entityId: PropTypes.string.isRequired,
  includeNotes: PropTypes.bool,
  entityType: PropTypes.string
}

ContactGrid.defaultProps = {
  includeNotes: true
}

const mapStateToProps = (state) => {
  const ticker = getActiveTicker(state.profile.data)
  return {
    grid: state.contact.grid,
    fundGrid: state.contact.fundGrid,
    securityId: ticker && ticker._security,
    token: state.token
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchInstitutionContacts: bindActionCreators(fetchInstitutionContacts, dispatch),
  fetchFundContacts: bindActionCreators(fetchFundContacts, dispatch),
  pushFlyoutDetailsItem: bindActionCreators(pushFlyoutDetailsItem, dispatch),
  resetFundContactGrid: bindActionCreators(resetFundContactGrid, dispatch),
  resetContactGrid: bindActionCreators(resetContactGrid, dispatch),
  openModal: bindActionCreators(openModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactGrid))
