import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import './timePicker.component.css'
import { isValidTimeFormat, THEMES } from '../../utils'
import { Select } from '../../components'
import {
  getTimeFromDate,
  getMinutesTwoDigits
} from '../../utils/date.util'
const enterKey = 13
const tabKey = 9

class TimePickerComponent extends PureComponent {

  constructor (props) {
    super(props)
    this.state = {
      selected: this.getSelected(),
      options: this.getSelectOptions(),
      timeInput: ''
    }

    this.customValueCreation = false
  }

  componentDidMount () {
    const { selected, date } = this.props

    if (!selected && !date) {
      this.onSelect(this.getDefaultTime())
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { date, selected, timezone } = this.props

    if (selected !== prevProps.selected || date !== prevProps.date || timezone !== prevProps.timezone) {
      this.setState({
        selected: this.getSelected()
      })
    }
  }

  /**
   * Get the selected time from props, or a default one.
   * @returns {String}
   */
  getSelected () {
    const { selected, date, timezone } = this.props

    if (selected) {
      return selected
    }

    if (date) {
      return getTimeFromDate(date, { timezone })
    }

    return this.getDefaultTime()
  }

  /**
   * Get a list of the hours of a day (12-11).
   * @returns {Array<Number>}
   */
  getHoursInDay () {
    const hours = []

    for (let i = 1; i <= 12; i++) {
      hours.push(i)
    }

    hours.unshift(hours.pop())
    return hours
  }

  /**
   * Get a list of minutes within an hour, incremented by given increment in props.
   * @returns {Array<Number>} Minutes in hour
   */
  getMinutesInHourIncremented () {
    const { minutesIncrement } = this.props
    const minutes = []

    for (let i = 0; i < 60; i += minutesIncrement) {
      minutes.push(i)
    }

    return minutes
  }

  /**
   * Get the default time, which is the current time.
   * This adjusts the time by the offset provided in props.
   * @returns {String|Null}
   */
  getDefaultTime () {
    const { defaultHourOffset, timezone } = this.props
    const defaultDate = moment().add(defaultHourOffset, 'hour')
    return getTimeFromDate(defaultDate, { timezone })
  }

  /**
   * Returns the time select option items.
   * Loops through hours and minutes to generate the options.
   * @returns {Array}
   */
  getSelectOptions () {
    const timeSuffix = ['am', 'pm']
    const hours = this.getHoursInDay()
    const minutes = this.getMinutesInHourIncremented()
    const options = []

    timeSuffix.forEach((suffix) => {
      hours.forEach((hour) => {
        minutes.forEach((minute) => {
          options.push({
            label: `${hour}:${getMinutesTwoDigits(minute)}${suffix}`,
            value: `${hour}:${getMinutesTwoDigits(minute)}${suffix}`,
            minutes: minute,
            hour,
            suffix
          })
        })
      })
    })

    return options
  }

  /**
   * On select time callback
   * @param value
   */
  onSelect = (value) => {
    const { onSelect } = this.props
    onSelect && onSelect(value)
  }

  /**
   * Set date to option Date format
   * @param date
   * @returns {{hour: *, minutes: number, label: *, suffix: *, value: *}}
   */
  setValueInput (date) {
    const dateSplit = date.split(':')
    const hour = dateSplit[0]
    const minute = parseInt(dateSplit[1])
    const suffix = dateSplit[1].slice(-2)

    return {
      hour: hour,
      label: date,
      minutes: minute,
      suffix: suffix,
      value: date
    }
  }

  /**
   * handle input changes, set timeInput state and update time
   * @param query
   */
  handleInputChange = (query) => {
    if (isValidTimeFormat(query)) {
      this.setState({ timeInput: query }, this.onSelect(query))
    }
  }

  /**
   * on change function, handel change.
   * @param option
   */
  handleChange = (option) => {
    // if user entered a custom value and hit tab/enter, skip handleChange (onSelect is already called in handleKeyDown)
    if (this.customValueCreation) {
      this.customValueCreation = false
      return
    }

    option.value && this.onSelect(option.value)
  }

  /**
   *  Handle Key Down
   * @param event
   */
  handleKeyDown = (event) => {
    if (!event) {
      return
    }

    const { target, keyCode } = event
    const { value } = target

    if ([enterKey, tabKey].includes(keyCode) &&  (value && isValidTimeFormat(value))) {
      // enable customValueCreation to skip handleChange action
      this.customValueCreation = true
      this.onSelect(value)
    }
  }

  render () {
    const { theme } = this.props
    const { selected, options } = this.state

    return (
      <Select
        className='time-picker'
        theme={theme}
        value={this.setValueInput(selected)}
        options={options}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        filterOption={() => true}
        searchable
        clearable={false}
      />
    )
  }
}

TimePickerComponent.propTypes = {
  minutesIncrement: PropTypes.number,
  onSelect: PropTypes.func,
  selected: PropTypes.string,
  timezone: PropTypes.string,
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
    PropTypes.string
  ]),
  defaultHourOffset: PropTypes.number,
  theme: PropTypes.string
}

TimePickerComponent.defaultProps = {
  minutesIncrement: 30,
  defaultHourOffset: 0,
  theme: THEMES.RAIN
}

export default TimePickerComponent
