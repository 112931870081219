import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// actions
import { useManagedFundQuery } from '../../../hook'

// component
import ManagedFundGrid from './managedFund.component'

// utils
import { get, isNull, omitBy } from 'lodash'
import { getLocalizedCurrency } from '../../../../../utils'

const propTypes = {
  id: PropTypes.array,
  entityId: PropTypes.string,
  tickerId: PropTypes.string.isRequired,
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

const defaultProps = {
  limit: 5
}

/**
 * Managed Funds
 * @param props
 */
function ManagedFund (props) {
  const { id, entityId, tickerId, limit, securityId } = props
  const variables = omitBy({
    id,
    entityId,
    tickerId,
    securityId,
    currencyCode: getLocalizedCurrency(),
    sortBy: 'fundHoldingCurrentConnection.current',
    limit: Number(limit)
  }, isNull)

  const { data } = useManagedFundQuery({
    skip: (!id || !id.length) && !entityId,
    variables
  })

  return (
    <Grid container spacing={2} className='tearsheet_section'>
      <Grid item xs={12} className='tearsheet_section-title'>{`Top ${limit} Managed Funds`}</Grid>
      <Grid item xs={12} className='tearsheet_section-content'>
        <ManagedFundGrid funds={get(data, 'fund.items', [])} />
      </Grid>
    </Grid>
  )
}

ManagedFund.propTypes = propTypes
ManagedFund.defaultProps = defaultProps

export default ManagedFund
