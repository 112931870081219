import { formatDate } from '../../utils/date.util'
import {
  GET_REPORT_BUILDER_LIST_REQUEST,
  GET_REPORT_BUILDER_LIST_SUCCESS,
  GET_REPORT_BUILDER_LIST_ERROR,
  SAVE_REPORT_BUILDER_ITEM_SUCCESS,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../actions'
import { convertBase64ToBlob } from '../../utils/browser.util'

const initial = {
  data: [],
  meta: {
    page: 0,
    total: 0
  },
  status: IDLE
}

const reportBuilderList = (state = initial, action) => {
  switch (action.type) {
    case GET_REPORT_BUILDER_LIST_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_REPORT_BUILDER_LIST_SUCCESS:
      const data = (action.payload || []).map((report) => {
        const { _id, title, create_date, updated_date, _profile } = report
        const isUTC = true

        return {
          _id,
          title,
          _profile,
          create_date: formatDate(create_date, 'MM/DD/YY', isUTC),
          updated_date: formatDate(updated_date, 'MM/DD/YY', isUTC),
          author: (_profile && _profile.firstName) ? `${_profile.firstName} ${_profile.lastName}` : '-'
        }
      })

      return {
        ...state,
        data,
        meta: action.meta,
        status: FETCHED
      }
    case SAVE_REPORT_BUILDER_ITEM_SUCCESS:
      let reportBuilderList = [...state.data]

      if (action.payload.pages) {
        action.payload.pages = action.payload.pages.map((page) => {
          if (page.thumbnail) {
            page.thumbnail = page.thumbnail ? convertBase64ToBlob(page.thumbnail) : page.thumbnail
          }
          return page
        })
      }

      const found = reportBuilderList.find((each, idx) => {
        if (each._id === action.payload._id) {
          reportBuilderList[idx] = action.payload
          return true
        }
        return false
      })

      if (!found) {
        reportBuilderList = [action.payload, ...reportBuilderList]
      }

      return {
        ...state,
        data: reportBuilderList,
        meta: action.meta,
        status: FAILED
      }
    case GET_REPORT_BUILDER_LIST_ERROR:
      return {
        ...state,
        status: FAILED
      }
    default:
      return state
  }
}

export default reportBuilderList
