import React, {Component} from 'react';
import { isEqual } from 'lodash'
import * as Yup from 'yup';
import {withFormik} from 'formik';
import { Message, Modal } from '../../../components'
import {RadioButton} from '../../shared';
import CustomContactForm from './custom/custom.component';
import CorporateParticipantForm from './participant/participant.component';
import { CORPORATE_PARTICIPANT } from '../../../utils/contact'

import './contactForm.component.css';
const dataIdPrefix = 'ContactForm'

class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deleteConfirm: false
        }
    }

    /**
     * ComponentDidUpdate
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps, prevState) {
      const {values, onChange} = this.props
      const {values: prevValues} = prevProps

      if (!isEqual(values, prevValues)) {
        onChange && onChange(values, prevValues)
      }
    }

    /**
     * On Close Modal
     */
    onClose = () => {
        const {onClose} = this.props;
        onClose && onClose();
    };

    /**
     * Render
     * @return {XML}
     */
    render() {
        const {
            loading,
            visible,
            onSearch,
            openModal,
            searchSuggestions,
            clearSuggestions,
            contactSuggestions,
            onSelectContactSuggestion,
            onClose,
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            isTypeSelectable,
            errors
        } = this.props;

        const hideTypeSelection = (values && values.id) || !isTypeSelectable;
        const buttons = [
            {
                dataId: `${dataIdPrefix}Cancel`,
                label: 'Cancel',
                ui: 'shaded',
                onClick: this.onClose
            },
            {
                dataId: `${dataIdPrefix}Save`,
                label: 'Save',
                ui: 'citrus',
                onClick: handleSubmit,
            }
        ];

        return (
            <Modal
                dataId={`${dataIdPrefix}Modal`}
                disableRestoreFocus
                visible={visible}
                loading={loading}
                fullscreen={true}
                contentPadding={'30px 30px 140px 30px'}
                badge='q4i-contact-2pt'
                title={values.id ? 'Edit Contact' : 'Create Contact'}
                footerButtons={buttons}
                onClose={onClose}>
                <div className='full-screen-form contact-form'>

                    {!hideTypeSelection && <div className='field field--radio'>
                        <label className='field_label'>Contact Type</label>

                        <RadioButton
                            className='radio-button--inline'
                            id={'contactTypeCustom'}
                            value='contact'
                            label={'Contact'}
                            name='type'
                            isSelected={values.type === 'contact'}
                            onChange={handleChange}
                        />
                        <RadioButton
                            className='radio-button--inline'
                            id={'contactTypeParticipant'}
                            value={CORPORATE_PARTICIPANT}
                            label={'Corporate Participant'}
                            name='type'
                            isSelected={values.type === CORPORATE_PARTICIPANT}
                            onChange={handleChange}
                        />
                    </div>}

                    <CustomContactForm
                        visible={values.type === 'contact'}
                        errors={errors}
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        onSearch={onSearch}
                        searchSuggestions={searchSuggestions}
                        clearSuggestions={clearSuggestions}
                        contactSuggestions={contactSuggestions}
                        onSelectContactSuggestion={onSelectContactSuggestion}
                        openModal={openModal}
                    />

                    <CorporateParticipantForm
                        visible={values.type === CORPORATE_PARTICIPANT}
                        errors={errors}
                        values={values}
                        handleChange={handleChange}
                    />

                    <Message
                        visible={this.state.deleteConfirm}
                        type='warning'
                        title='Delete Corporate Participant?'
                        message='Are you sure you want to delete this Corporate Participant?'
                        onClose={this.onDeleteCancel}
                        buttons={[
                            {
                                ui: 'shaded',
                                label: 'cancel',
                                onClick: this.onDeleteCancel
                            },
                            {
                                ui: 'spice',
                                label: 'delete',
                                onClick: this.onDeleteConfirm
                            }
                        ]}
                    />
                </div>
            </Modal>
        );
    }
}

export default withFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    mapPropsToValues: (props) => {
        return {
            type: props.type || 'contact',
            firstName: '',
            lastName: '',
            nickName: '',
            jobTitle: '',
            phone: '',
            mobile: '',
            email: '',
            bio: '',
            street: '',
            city: '',
            state: '',
            zip: '',
            country: '',
            corporatePhone: '',
            institutionName: '',
            factsetEntityId: '',
            q4EntityId: '',
            requestProfile: false,
            ...props.values
        };
    },
    handleSubmit: (values, {props}) => {
        const {onSave} = props;
        onSave && onSave(values);
    },
    validationSchema: Yup.object().shape({
        email: Yup.string().email('Invalid email address'),
        firstName: Yup.string().trim().required(),
        lastName: Yup.string().trim().required(),
        institutionName: Yup.string().when('type', {
          is: (type) => type === 'contact',
          then: Yup.string().trim().required(),
          otherwise: Yup.string().trim().notRequired()
        })
    })
})(ContactForm);
