import React from 'react'
import moment from 'moment-timezone'
import { get } from 'lodash'

import { format, getUniqEntityLocations, inMillions, formatLocalizedDate } from '../../../../../../utils'

import { getEntityCategoryIcon, formatEntity } from '../../../../../../utils/entity'
import { ExpandableCell } from '../../../../../../components/agGrid/cell'

import './cell.component.css'

const EMPTY_PLACEHOLDER = '-'

/**
 * Title Custom Cell Component
 */
const TitleCell = ({ entityType, onTargetNameClick, onUtilityItemClick, ...data }) => {
  const isTarget = !!data._target
  const isDeal = !!data._deal

  data = formatEntity(data, entityType)
  return (
    <div className='cell cell--title' onClick={() => onTargetNameClick({ ...data, entityType })}>
      <i className={`cell_icon cell_icon--${entityType} ${getEntityCategoryIcon(entityType)}`} />
      <div className='cell_title'>
        {data.name}
      </div>
      {isTarget && <i className='cell_icon cell_icon--target q4i-targeting-4pt' />}
      {isDeal && <i className='cell_icon cell_icon--deal q4i-deal-4pt' />}
      <i
        className='cell_icon cell_icon--utility q4i-utility-4pt'
        onClick={(event) => {
          onUtilityItemClick(event, data)
        }}
      />
    </div>
  )
}

/**
 * Location Custom Cell Component
 */
const LocationCell = ({ address, onExpandMenuClick }) => {
  const locations = (getUniqEntityLocations(address) || []).map((addressItem) => {
    return {
      label: addressItem
    }
  })

  return (
    <ExpandableCell
      items={locations}
      onClick={onExpandMenuClick}
    />
  )
}

/**
 * AUM Custom Cell Component
 */
const AUMCell = ({ entityType, ...data }) => {
  const getAUM = ({ total_aum }) => total_aum && format(total_aum, 0)
  const getFundAUM = ({ portfolio_value }) => portfolio_value && inMillions(portfolio_value, 0)
  const aum = entityType === 'fund' ? getFundAUM(data) : getAUM(data)

  return aum || EMPTY_PLACEHOLDER
}

/**
 * EAUM Custom Cell Component
 */
const EAUMCell = ({ entityType, ...data }) => {
  const getAUM = ({ equity_aum }) => equity_aum && format(equity_aum, 0)
  const getFundAUM = ({ equity_aum }) => equity_aum && inMillions(equity_aum, 0)
  const aum = entityType === 'fund' ? getFundAUM(data) : getAUM(data)

  return aum ? aum + ' C' : EMPTY_PLACEHOLDER
}

/**
 * Purchasing Power Custom Cell Component
 */
const PurchasingPowerCell = ({ purchasing_power }) => {
  const value = parseInt(purchasing_power)
  return isNaN(value) ? EMPTY_PLACEHOLDER : format(value, 0)
}

/**
 * AI Score Custom Cell Component
 */
const AIScoreCell = ({ style, ai_score }) => {
  const isPassive = ['index', 'quant'].includes(style ? style.toLowerCase() : '')
  return (ai_score || ai_score === 0) ? ai_score : isPassive ? 'Passive' : EMPTY_PLACEHOLDER
}

/**
 * Quality Rating Custom Cell Component
 */
const QualityRatingCell = ({ rating }) => {
  if (!rating) {
    return EMPTY_PLACEHOLDER
  }

  return (
    <div className='cell_rating'>
      {rating}
    </div>
  )
}

/**
 * Last Activity Custom Cell Component
 */
const LastActivityCell = ({ dateTime }) => {
  const date = get(dateTime, 'start.date_time', '')
  return date ? formatLocalizedDate(moment(date).format('MM/DD/YYYY'),'MM/DD/YYYY')  : EMPTY_PLACEHOLDER
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 * @param onExpandMenuClick
 */
const CustomCell = ({ data = {}, column = {}, onExpandMenuClick, onTargetNameClick, onUtilityItemClick }) => {
  const cellData = data[column.colId]
  switch (column.colId) {
    case 'name':
      return <TitleCell
        {...data}
        onTargetNameClick={onTargetNameClick}
        onUtilityItemClick={onUtilityItemClick}
      />
    case 'address':
      return <LocationCell address={cellData} onExpandMenuClick={onExpandMenuClick} />
    case 'style':
      return cellData || EMPTY_PLACEHOLDER
    case 'turnover':
      return cellData || EMPTY_PLACEHOLDER
    case 'total_aum':
      return <AUMCell {...data} />
    case 'equity_aum':
      return <EAUMCell {...data} />
    case '_purchasing_power':
      return <PurchasingPowerCell {...cellData} />
    case 'ai':
      return <AIScoreCell {...data} />
    case 'quality_rating':
      return <QualityRatingCell rating={cellData} />
    case '_activity':
      return <LastActivityCell dateTime={cellData} />
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
