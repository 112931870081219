import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Collapse } from '@material-ui/core'
import pathToRegexp from 'path-to-regexp'
import { getMenuItems, sections } from './data'
import { isSubscribed, getEaIntegrationEnabledFlag, getEaPhase3EnabledFlag, getQ4LogoIcon } from '../../utils'
import NavTooltip from './tooltip/tooltip.component'
import { config } from '../../config'

import './navigation.component.css'

class Navigation extends Component {
  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      expandedSections: {
        root: true,
        intelligence: true
      }
    }
  };

    /**
     * Toggle navigation
     * Logic and state handled by parent component
     */
    toggleNav = (event, expand) => {
      const { onNavigationToggle } = this.props
      onNavigationToggle(event, expand)
    };

    /**
     * Expand/Collapse menu section
     */
    toggleSection = (sectionKey) => {
      const expanded = this.state.expandedSections[sectionKey]
      this.setState({
        expandedSections: {
          ...this.state.expandedSections,
          [sectionKey]: !expanded
        }
      })
    };

    /**
     * Check if menu item is active
     * @param menuItem
     * @return {Array.<T>|boolean}
     */
    isActivePage = (menuItem) => {
      const { history, securityId, tickerId } = this.props
      const currentPath = history.location.pathname
      const patterns = menuItem.pattern || menuItem.path
      let result = false;
      [].concat(patterns).forEach((pattern) => {
        const re = pathToRegexp(pattern)
        const match = re.exec(currentPath)
        if (match) {
          // Only allow Security Navigation Icon to be in 'active' state when viewing own security
          if (match[0].match('security') && (match[1] !== tickerId) && (match[1] !== securityId)) {
            return false
          }
          result = match
        }
      })

      return result
    };

    /**
     * Get menu items
     * @returns {*}
     */
    getMenuItems = () => {
      const { profile, securityId: legacySecurityId, tickerId } = this.props
      const data = profile && profile.data

      return getMenuItems({ legacySecurityId, tickerId }).filter((item) => {
        if (item.subscription === 'shareholder_id') {
          return isSubscribed(data, 'shareholder_id')
        } else if (item.key === 'report') {
          return isSubscribed(data, 'report_builder')
        } else if (item.key === 'engagement_analytics') {
          return getEaIntegrationEnabledFlag()
        } else if (item.key === 'webcast_analytics' || item.key === 'web_analytics') {
          return !getEaPhase3EnabledFlag()
        }
        return true
      }).map((item) => {
        if (item.subscription === 'ai_targeting' && isSubscribed(data, 'ai_targeting')) {
          item.title = 'AI Targeting'
        }
        if (isSubscribed(data, 'estimates_research') && ['estimates', 'research'].includes(item.key)) {
          item.openInNewTab = true
        }

        if (item.key === 'report') {
          item.title = 'Report Builder'
          item.path = '/builder'
          item.pattern = ['/builder', '/builder/:id']
        }

        if (item.key === 'engagement_analytics' && (isSubscribed(data, 'webcast') || isSubscribed(data, 'website'))) {
          item.openInNewTab = true
        }

        return item
      })
    };

    getExternalLink (item) {
      const { openInNewTab, key } = item
      if (openInNewTab) {
        switch(key) {
          case 'estimates':
            return {pathname: 'https://www.capitaliq.com/CIQDotNet/my/dashboard.aspx'}
          case 'research':
            return {pathname: 'https://www.capitaliq.com/CIQDotNet/Research/InvestmentResearch.aspx'}
          case 'engagement_analytics':
            return config.capitalConnectUrl ? {pathname: `${config.capitalConnectUrl}/app/engagement`} : item.path
          default: 
            return item.path
        }
      }
      else {
        return item.path
      }
    }
    

    /**
     * Render Profile Men
     * @returns {XML}
     */
    render () {
      const { expandedSections } = this.state
      const { navigationExpanded } = this.props
      const menuItems = this.getMenuItems()

      return (
        <div className={`app-nav ${navigationExpanded ? 'app-nav--expanded' : ''}`}>
          <div className='app-nav_mask' onClick={this.toggleNav} />
          <div className='app-nav_drawer'>
            <nav className='app-nav_menu'>
              <div className='app-nav_menu-header' onClick={this.toggleNav}>
                <i className='app-nav_menu-header_toggle q4i-hamburger-desktop-2pt' />
                <i className={`app-nav_menu-header_logo ${getQ4LogoIcon()}`} />
                <i className='app-nav_menu-header_chevron q4i-caret-sm-left-4pt' />
              </div>
              {
                // Render menu items by section
                Object.keys(sections || []).map((sectionKey) => {
                  const section = sections[sectionKey]
                  const items = menuItems.filter((item) => item.section === sectionKey)
                  const isExpanded = expandedSections && expandedSections[sectionKey]

                  return (
                    <Collapse
                      key={sectionKey}
                      component='section'
                      in={isExpanded}
                      collapsedSize='45px'
                      className='app-nav_menu-section'
                    >
                      {
                        section.title
                          ? (
                            <button
                              className={`app-nav_menu-section_header ${!isExpanded ? 'app-nav_menu-section_header--collapsed' : ''}`}
                              onClick={() => this.toggleSection(sectionKey)}
                            >
                              <i className='app-nav_menu-section_icon q4i-caret-sm-down-4pt' />
                              <div className='app-nav_menu-section_title'>{section.title}</div>
                            </button>
                            )
                          : null
                      }
                      {
                        (items || []).map((item) =>
                          (
                            <Link
                              id={item.key}
                              data-testid={item.key}
                              key={item.key}
                              to={this.getExternalLink(item)}
                              target={item.openInNewTab ? '_blank' : '_self'}
                              onClick={(e) => this.toggleNav(e, false)}
                              className={`app-nav_menu-item ${this.isActivePage(item) ? 'app-nav_menu-item--active' : ''}`}
                            >
                              {!navigationExpanded
                                ? (
                                  <NavTooltip title={item.title} isNew={item.new} openInNewTab={item.openInNewTab}>
                                    <i className={`app-nav_menu-item_icon ${item.new ? 'app-nav_menu-item_icon--new' : ''} ${item.icon}`} />
                                  </NavTooltip>
                                  )
                                : <i className={`app-nav_menu-item_icon ${item.new ? 'app-nav_menu-item_icon--new' : ''} ${item.icon}`} />}
                              <div className='app-nav_menu-item_label'>{item.title}</div>
                              {item.openInNewTab ? <i className='app-nav_menu-item_icon app-nav_menu-item_icon--new_tab' /> : null}
                            </Link>
                          )
                        )
                      }
                    </Collapse>
                  )
                })
              }
            </nav>
          </div>
        </div>
      )
    }
}

export default withRouter(Navigation)
