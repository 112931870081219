import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_ACTIVITY = gql`
  query Activity(
    $entityId: String!
    $startDate: String
    $endDate: String
    $limit: Int
  ) {
    activity(
      entityId: $entityId
      filter: { 
        startDate: $startDate
        endDate: $endDate
      }
      limit: $limit
    ) {
      items {
        id
        category
        title
        start {
          date
          timezone
        }
        end {
          date
          timezone
        }
        allDay
        body
        tag
        links {
          entityId
          entityType
          entityName
        }
        participants {
          id
          participantId
          corporateParticipantConnection {
            items {
              fullName                       
            }
          }        
        }
        _activity {
          activityConnection {
            items {
              id
              title
            }
          }
        }
      }
      count
    }
  }`

/**
 * Activity
 * @param options
 */
export function useActivityQuery (options) {
  return useQuery(GET_ACTIVITY, options)
}
