import {
  TARGETING_GET_CURRENT_POSITION_REQUEST,
  TARGETING_GET_CURRENT_POSITION_SUCCESS,
  TARGETING_GET_CURRENT_POSITION_FAILURE,
  statusType
} from '../../../actions'

const initial = {
  data: null,
  status: statusType.IDLE
}

const currentPositionReducer = (state = initial, action) => {
  switch (action.type) {
    case TARGETING_GET_CURRENT_POSITION_REQUEST:
      return {
        ...state,
        data: action.payload,
        status: statusType.IN_PROGRESS
      }
    case TARGETING_GET_CURRENT_POSITION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: statusType.SUCCESS
      }
    case TARGETING_GET_CURRENT_POSITION_FAILURE:
      return {
        ...state,
        data: action.payload,
        status: statusType.ERROR
      }
    default:
      return state
  }
}

export default currentPositionReducer
