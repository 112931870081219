import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Slider as MUISlider } from '@material-ui/core'
import { getClassName, THEMES } from '../../utils/ui'

import './slider.component.scss'

const propTypes = {
  theme: PropTypes.oneOf([THEMES.LIGHT, THEMES.DARK, THEMES.RAIN]),
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  track: PropTypes.oneOf(['normal', false, 'inverted']),
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  defaultValue: PropTypes.number,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

const defaultProps = {
  theme: THEMES.LIGHT,
  orientation: 'horizontal',
  track: 'normal',
  min: 0,
  max: 100,
  step: 10,
  defaultValue: 0,
  value: 0,
  disabled: false
}

/**
 * SomeComponent
 * @param props
 */
function Slider (props) {
  const { theme, orientation, track, min, max, step, defaultValue, value, disabled, onChange } = props

  const classes = {
    root: getClassName('slider', [
      { condition: theme, trueClassName: `slider--${theme}` },
      { condition: disabled, trueClassName: 'slider--disabled' }
    ]),
    rail: 'slider_rail',
    track: 'slider_track',
    thumb: 'slider_thumb',
    active: 'slider_thumb--active'
  }

  return (
    <MUISlider
      classes={classes}
      orientation={orientation}
      track={track}
      min={min}
      max={max}
      step={step}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      onChange={(event, value) => onChange(value)}
    />
  )
}

Slider.propTypes = propTypes
Slider.defaultProps = defaultProps

export default memo(Slider)
