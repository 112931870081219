import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import './widgetError.conponent.css';

class WidgetError extends Component {

    /**
     * Get Classes
     * @return {Array|*}
     */
    getClasses = () => {
        const {theme, type} = this.props;
        const classes = ['q4-fade-in widget-error'];

        if (theme) {
            classes.push(`widget-error--${theme}`);
        }

        if (type) {
            classes.push(`widget-error--${type}`);
        }

        return classes.join(' ');
    };

    render() {
        const {message, header, linkText, linkUrl} = this.props;

        return (
            <div className={this.getClasses()}>
                {header ? <div className='widget-error_header'><h2>{header}</h2></div> : ''}
                <div className='widget-error_inner'>
                    <div className="widget-error_message">
                        <span>{message} </span>
                        {linkText && linkUrl ?
                            <Link to={linkUrl} className='widget-error_link'>{linkText}</Link> : ''}
                    </div>
                </div>
            </div>
        );
    }
}

WidgetError.propTypes = {
    message: PropTypes.string.isRequired
};

export default WidgetError;
