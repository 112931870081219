import React, { PureComponent } from 'react';
import { AgGrid, Spinner } from '../../../../../../components';
import { getDefaultColumnDef, preventDefaultRowClick, THEMES } from '../../../../../../utils';

import CustomCellRender from './cell/cell.component';

const columns = [
    { ...getDefaultColumnDef({ columnIndex: 0 }), field: 'name', headerName: 'Name', minWidth: 260 },
    { ...getDefaultColumnDef({ columnIndex: 1 }), field: 'location', headerName: 'Location', minWidth: 180, maxWidth: 220 },
    { ...getDefaultColumnDef({ columnIndex: 2, type: 'icon' }), field: 'email', headerName: 'Email', minWidth: 80, maxWidth: 80 },
    { ...getDefaultColumnDef({ columnIndex: 3 }), field: 'phone', headerName: 'Phone', minWidth: 180, maxWidth: 200 },
    { ...getDefaultColumnDef({ columnIndex: 4, type: 'number' }), field: 'position', headerName: 'Current Position', minWidth: 150, maxWidth: 150 }
];


class AttendeeGrid extends PureComponent {

    /**
     * Handle AgGrid onGridReady event
     * @param grid
     * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
     */
    handleGridReady = (grid) => {
        setTimeout(() => this.agGrid = grid, 0);
    };

    /**
     * Handle AgGrid gridSizeChanged event
     * @param type - event type
     */
    handleGridResize = ({ type }) => {
        if (type === 'gridSizeChanged') {
            setTimeout(() => this.agGrid && this.agGrid.api.sizeColumnsToFit(), 0);
        }
    };

    /**
     * Handles redirect to entity page
     * @param data
     */
    handleOnRowClick = ({ type, entityId }) => {
        const { history } = this.props;
        const link = `/${type.toLowerCase()}/${entityId}`;

        type && entityId && history.push(link);
    };

    /**
     * Renders CustomCell Component
     * @param props
     * @returns {*}
     */
    renderCustomCell = (props) => {
        return (
          <CustomCellRender
            {...props}
            handleEmailClick={this.props.handleEmailClick}
          />
        )
    }

    render() {
        const {
            data,
            total,
            filters: { page, limit },
            handleFilterChange,
            loading
        } = this.props;

        return (  
          <>
            {loading && <Spinner mask theme={THEMES.RAIN} />}
            <AgGrid
                className='attendee-grid'
                domLayout='autoHeight'
                sizeToFit={true}

                // suppress configs
                suppressMovableColumns={true}
                suppressContextMenu={true}
                enableSorting={false}

                // columns and data
                defaultColDef={{
                    suppressMenu: true,
                    sortable: false,
                    cellRenderer: 'CustomCellRender'
                }}
                columnDefs={columns}
                rowData={data}

                // custom components
                frameworkComponents={{CustomCellRender: this.renderCustomCell}}

                // pagination
                pagination={true}
                paginationProps={{
                    pageSizeId: 'attendee-grid',
                    forcePage: page,
                    initialPageSize: limit,
                    showPageSizeSelection: true,
                    total,
                    onPageChange: ({selected}) => handleFilterChange({page: selected}),
                    onPageSizeChange: ({selected}) => handleFilterChange({page: 1, limit: selected}),
                }}

                // event listeners
                onGridReady={this.handleGridReady}
                onGridSizeChanged={this.handleGridResize}
                onRowClicked={preventDefaultRowClick(
                    this.handleOnRowClick,
                    ['cell_icon--email']
                )}
            />
          </>
        );
    }
}

export default AttendeeGrid;
