import React, { memo } from 'react'
import './reportCategoryList.component.css'

/**
 * ReportCategoryList Component
 * @param props
 * @returns {*}
 */
function ReportCategoryList (props) {
  const { categories, selectedCategory, onSelect } = props

  return (
    <ul className='report-category-list'>
      {(categories || []).map((category) => {
        const { icon, label, value } = category
        const isSelected = category.value === selectedCategory
        const className = isSelected
          ? 'report-category-list_item report-category-list_item--selected'
          : 'report-category-list_item'

        return (
          <li className={className} key={`report-category-item--${value}`} onClick={() => onSelect(category)}>
            <div className='report-category-list_icon'><i className={icon} /></div>
            <span className='report-category-list_title'>{label || '-'}</span>
          </li>
        )
      })}
    </ul>
  )
}

export default memo(ReportCategoryList)
