import { WEB_ANALYTICS_INSTITUTION_WIDGET, WEB_ANALYTICS_DOWNLOAD_WIDGET } from '../../utils/widget.util'

export const filterDashboardWidgets = (activeDashboardWidgets, activeDashboardLayoutTemplate) => {
  let filteredDashboardLayoutTemplate = activeDashboardLayoutTemplate || []

  const filteredWidgets = activeDashboardWidgets.filter((item) => {
    if (
      item?.id === WEB_ANALYTICS_INSTITUTION_WIDGET.id || 
      item?.id === WEB_ANALYTICS_DOWNLOAD_WIDGET.id
    ) {
      filteredDashboardLayoutTemplate = filteredDashboardLayoutTemplate.filter((layoutItem) => {
        return parseInt(layoutItem?.i, 10) === item?.layout_id 
          ? false
          : true
      })

      return false
    }

    return true
  })

  return {
    filteredWidgets, 
    filteredDashboardLayoutTemplate
  }
}
