/**
 * Token Action Type Constants
 * @type {string}
 */
export const SET_TOKEN = 'SET_TOKEN'

/**
 * Set Token
 * @param token
 * @private
 */
const _setToken = (token) => ({
  type: SET_TOKEN,
  payload: token
})

/**
 * Dispatch SET_TOKEN
 * @param token
 */
export const setToken = (token) => (dispatch) => {
  return dispatch(_setToken(token))
}
