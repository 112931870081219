import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { get } from 'lodash'

// actions
import { useIndustryAnalysisQuery } from '../../../hook'

// component
import IndustryAnalysisGrid from './industryAnalysis.component'
import { getLocalizedCurrency } from '../../../../../utils'

const GROUP = {
  SECTOR: 'sector',
  CAP_GROUP: 'capGroup',
  REGION: 'region'
}

const propTypes = {
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  security: PropTypes.shape({
    capGroup: PropTypes.string,
    sector: PropTypes.string,
    region: PropTypes.string
  }).isRequired
}

const defaultProps = {
  security: {}
}

/**
 * Industry Analysis Sector/Market Cap/Region
 * @param props
 */
function IndustryAnalysis (props) {
  const { entityId, entityType } = props

  const { data } = useIndustryAnalysisQuery(entityType, {
    variables: {
      entityId,
      sortBy: 'percentPortfolio',
      sortDir: 'desc',
      currencyCode: getLocalizedCurrency(),
      limit: 5
    }
  })

  return (
    <Grid container spacing={2} className='tearsheet_section'>
      <Grid item xs={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} className='tearsheet_section-title'>Sector (MM)</Grid>
          <Grid item xs={12} className='tearsheet_section-content'>
            <IndustryAnalysisGrid
              data={get(data, 'sector.items', [])}
              group={GROUP.SECTOR}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} className='tearsheet_section-title'>Market Cap (MM)</Grid>
          <Grid item xs={12} className='tearsheet_section-content'>
            <IndustryAnalysisGrid
              data={get(data, 'capGroup.items', [])}
              group={GROUP.CAP_GROUP}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} className='tearsheet_section-title'>Region (MM)</Grid>
          <Grid item xs={12} className='tearsheet_section-content'>
            <IndustryAnalysisGrid
              data={get(data, 'region.items', [])}
              group={GROUP.REGION}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

IndustryAnalysis.propTypes = propTypes
IndustryAnalysis.defaultProps = defaultProps

export default IndustryAnalysis
