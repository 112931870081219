import { format, formatDate, formatLocalizedDate, inMillions } from '../../../../../utils'

const EMPTY_PLACEHOLDER = '-'

const CustomCell = ({ data = {}, column = {} }) => {
  const cellData = data[column.colId]

  switch (column.colId) {
    case 'current_position':
      return format(cellData, 0, 'dash')
    case 'portfolio_value':
      return inMillions(cellData, 2) || EMPTY_PLACEHOLDER
    case 'report_date':
      return formatLocalizedDate(formatDate(cellData, 'MM/DD/YY', true), 'MM/DD/YY')
    default:
      return cellData || EMPTY_PLACEHOLDER
  }
}

export default CustomCell
