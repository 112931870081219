import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, capitalize } from 'lodash'
import PropTypes from 'prop-types'

// actions
import {
  createTag,
  deleteTag,
  resetSearchTags,
  resetTags,
  searchTags,
  openModal,
  statusType,
  CREATE_TAG_FAILURE,
  DELETE_TAG_FAILURE
} from '../../actions'

// components
import { AutoSuggest, Button, Message, PopoverMenu, Tag } from '../../components'
import { getClassName, MESSAGE, removeUnescapedCharacters, THEMES } from '../../utils'

import './tagInput.component.scss'
import './tagInputOld.container.scss'

/**
 * Error Message Modal
 * @param onClose
 * @returns {*}
 * @constructor
 */
const ErrorModal = ({ onClose }) => {
  return (
    <Message
      visible
      type='error'
      title='Error'
      message={MESSAGE.ERROR}
      onClose={onClose}
      buttons={[{
        ui: 'spice',
        label: 'close',
        onClick: onClose
      }]}
    />
  )
}

const propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  dataId: PropTypes.string,
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
}

const defaultProps = {
  theme: THEMES.DARK
}

/**
 * Tag Input Component
 * @deprecated used in activity details until activity not migrated
 */
class TagInputOld extends Component {
  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      showMore: {},
      addTag: false,
      value: '',
      loading: false
    }
  }

  componentWillUnmount () {
    const { resetTags } = this.props
    resetTags && resetTags()
  }

  /**
   * Store AutoSuggest input reference
   * @param autosuggest
   */
  storeInputReference = (autosuggest) => {
    if (autosuggest !== null) {
      this.input = autosuggest.input
    }
  }

  /**
   * On add new tag button click
   */
  onAddButtonClick = () => {
    this.setState({
      addTag: true
    })

    // handle input focus after animation (autofocus is defined to work reliably only on page load)
    this.input.focus()
  }

  /**
   * On value (query) change
   * @param event
   * @param newValue - the new value of the input
   */
  onAutocompleteChange = (event, { newValue }) => {
    this.setState({ value: newValue })
  }

  /**
   * Clear search results
   */
  onClearSuggestions = () => {
    const { resetSearch } = this.props
    resetSearch && resetSearch()
  }

  /**
   * Hide tag input field
   */
  onAutocompleteBlur = () => {
    this.setState({
      addTag: false,
      value: ''
    })
  }

  /**
   * On enter or space redirect to advanced search
   * @param event
   * @param params - {name, reference: {item, type}}
   */
  onAutocompleteKeyUp = (event, params) => {
    const keyCode = (event.which) ? event.which : event.keyCode

    if ((keyCode === 13 || keyCode === 32) && (params.name && params.name.length)) {
      this.createTag(params)
    }
  }

  /**
   * Handle search tag query change
   * @param query
   */
  getSuggestions = (query) => {
    const { searchTag } = this.props
    searchTag && searchTag(query)
  }

  /**
   * Render suggestion item
   * @param suggestion
   * @returns {*}
   */
  renderSuggestion = (suggestion) => {
    return (suggestion && suggestion.name)
  }

  /**
   * On AutoSuggest suggestion select
   * Called every time suggestion is selected via mouse or keyboard
   * @param event
   * @param method - e.g. click
   * @param params - {name, reference: {item, type}}
   */
  onSuggestionSelected = (event, method, params) => {
    if (method === 'click') {
      this.createTag(params)
    }
  }

  /**
   * Create new tag if doesn't exist
   * @param params
   */
  createTag = (params) => {
    const { tags, createTag } = this.props
    params.name = removeUnescapedCharacters(params.name)
    const isExist = (tags || [])
      .some((tag) => get(tag, 'name').toLowerCase() === get(params, 'name').toLowerCase())

    this.setState({ value: '' })
    !isExist && createTag(params).then((data) => {
      const { type } = data
      if (type === CREATE_TAG_FAILURE) {
        this._handleFailure()
      }
    })
  }

  /**
   * Get value (input value when suggestion is selected)
   * @param suggestion
   * @returns {*}
   */
  getSuggestionValue = (suggestion) => {
    return (suggestion && suggestion.name) || ''
  }

  /**
   * On tag delete
   * @param event
   * @param tag
   */
  onTagDelete = (event, tag) => {
    event.stopPropagation()

    const { deleteTag } = this.props
    const { _id } = tag

    if (deleteTag && _id) {
      deleteTag(_id).then((data) => {
        const { type } = data
        if (type === DELETE_TAG_FAILURE) {
          this._handleFailure()
        }
      })
    }
  }

  /**
   * On more item (tags) click
   * @param event
   * @param id
   */
  onMoreItemClick = (event, id) => {
    event.stopPropagation()

    this.setState({
      showMore: { [id]: event.currentTarget }
    })
  }

  /**
   * On more item (tags) close click
   * @param event
   */
  onMoreItemCloseClick = (event) => {
    event.stopPropagation()

    this.setState({
      showMore: {}
    })
  }

  /**
   * On tag click
   * @param event
   * @param tag
   */
  onTagClick = (event, tag) => {
    event.stopPropagation()

    const { history } = this.props
    history && history.push(`/search?query=${encodeURIComponent('#' + tag.name)}`)
  }

  /**
   * On Request Failure display error
   */
  _handleFailure () {
    const { openModal } = this.props
    openModal({
      component: ErrorModal
    })
  }

  /**
   * Render Tag Input Component
   * @returns
   */
  render () {
    const { theme, className, loading, dataId, tags, limit, entityId, entityType, results } = this.props
    const { showMore, addTag, value } = this.state

    const isQ4Id = entityId && entityId.length === 32

    const reference = isQ4Id
      ? { entityId: entityId, type: capitalize(entityType) }
      : { item: entityId, type: capitalize(entityType) }

    const baseClassName = getClassName('tag-input', [
      { condition: className, trueClassName: className },
      { condition: theme, trueClassName: `tag-input--${theme}` }
    ])

    return (
      <>
        <div className={baseClassName} data-id={`${dataId}Tags`}>
          <header className='tag-input_header'>Tags</header>
          <Tag id='tag-input-component'
               className={`tag-input-component tags--thin tags--wide tags--in-component`}
               theme={theme}
               items={tags}
               limit={limit}
               deletable={true}
               onClick={this.onTagClick}
               onDelete={this.onTagDelete}
               onMoreClick={this.onMoreItemClick}
          />
          <Button
            className={getClassName('tag-input_add', [
              { condition: addTag, trueClassName: 'tag-input_add--hidden' },
              { condition: theme, trueClassName: `tag-input_add--${theme}` }
            ])}
            icon='q4i-add-4pt'
            onClick={() => this.onAddButtonClick(entityId, entityType)}
          />

          <div className={`autosuggest-wrapper ${addTag ? 'autosuggest-wrapper--expanded' : ''}`}>
            <AutoSuggest
              inputReference={this.storeInputReference}
              className='autosuggest--thin'
              theme={theme}
              value={value}
              loading={loading}
              placeholder='Press ENTER or SPACE to add tags'
              onChange={(e, { newValue }) => this.onAutocompleteChange(e, { newValue })}
              onKeyUp={(e) => this.onAutocompleteKeyUp(e, { name: value, reference })}
              onBlur={this.onAutocompleteBlur}
              suggestions={results}
              getSuggestions={this.getSuggestions}
              renderSuggestion={this.renderSuggestion}
              getSuggestionValue={this.getSuggestionValue}
              onSuggestionSelected={(e, suggestion) => this.onSuggestionSelected(e, suggestion.method, {
                name: (suggestion && suggestion.suggestion && suggestion.suggestion.name) || '',
                reference
              })}
              clearSuggestions={this.onClearSuggestions}
              suggestionHeight={180}
              suggestionItemHeight={30}
              suggestionLimit={6}
            />
          </div>

        </div>

        <PopoverMenu
          theme={theme}
          items={tags.slice(limit)}
          anchorEl={showMore['tag-input-component']}
          open={Boolean(showMore['tag-input-component'])}
          arrowClass='bottom-right'
          deletable
          scrollable
          isMargin
          onClose={this.onMoreItemCloseClick}
          onClick={this.onTagClick}
          onDelete={this.onTagDelete}
          renderLabel={(item) => item.name}
        />
      </>
    )
  }
}

TagInputOld.propTypes = propTypes
TagInputOld.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  loading: state.tag.search.status === statusType.IN_PROGRESS,
  results: state.tag.search.data,
  tags: state.tag.tags.data
})

const mapDispatchToProps = (dispatch) => ({
  createTag: bindActionCreators(createTag, dispatch),
  deleteTag: bindActionCreators(deleteTag, dispatch),
  searchTag: bindActionCreators(searchTags, dispatch),
  resetSearch: bindActionCreators(resetSearchTags, dispatch),
  resetTags: bindActionCreators(resetTags, dispatch),
  openModal: bindActionCreators(openModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TagInputOld))
