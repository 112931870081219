import React from 'react'

export const renderLightThumb = () => {
  return <div className='react-scrollbar_thumb react-scrollbar_thumb--light' />
}

export const renderDarkThumb = () => {
  return <div className='react-scrollbar_thumb react-scrollbar_thumb--dark' />
}

export const renderTrackVertical = () => {
  return <div className='react-scrollbar_track react-scrollbar_track--vertical' />
}

export const renderTrackHorizontal = () => {
  return <div className='react-scrollbar_track react-scrollbar_track--horizontal' />
}
