import {
  FETCH_FUND_REQUEST,
  FETCH_FUND_SUCCESS,
  FETCH_FUND_FAILURE,
  statusType
} from '../../actions'

const initialState = {
  data: {}
}

const fund = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FUND_REQUEST: {
      const { entityId } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          [entityId]: {
            status: statusType.IN_PROGRESS
          }
        }
      }
    }
    case FETCH_FUND_SUCCESS: {
      const { factset_fund_id } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          [factset_fund_id]: {
            data: {
              ...action.payload
            },
            status: statusType.SUCCESS
          }
        }
      }
    }
    case FETCH_FUND_FAILURE: {
      const { entityId } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          [entityId]: {
            status: statusType.ERROR,
            error: action.error
          }
        }
      }
    }
    default:
      return state
  }
}

export default fund
