import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import moment from 'moment-timezone'

import { formatPhone, getEventIcon, formatDate, getTranscriptLink, getTimeWithZone, isMidnight } from '../../utils'

import './eventDetailModal.scss'

const getPhone = (phone) => {
  return (phone && phone.length) ? isNaN(phone) ? phone : formatPhone(phone) : '-'
}

const Date = ({ event }) => {
  const { eventDate, eventConferenceEndDate } = (event || {})
  const defaultDateFormat = 'dddd, MMMM D, YYYY'

  // Accepting unix and ISO time formats
  const fromFormat = [
    defaultDateFormat,
    'MM/DD/YY',
    'YYYY-MM-DD hh:mm:ss ZZ',
    'x'
  ]

  const isZeroTime = isMidnight(eventDate, true)
  const startDate = formatDate(eventDate, defaultDateFormat, isZeroTime, true)
  const endDate = formatDate(eventConferenceEndDate, defaultDateFormat, isMidnight(eventConferenceEndDate, true), true)
  const startTime = getTimeWithZone(eventDate)
  const rangeDate = `${startDate} - ${endDate}`

  const endOfEventDay = moment(startDate).endOf('day')
  const isEndDateInFutureDate = moment(endDate, fromFormat).diff(endOfEventDay) > 0
  const isRangeDate = eventConferenceEndDate && isEndDateInFutureDate

  return (
    <>
      <div className='event_detail-date-container'>
        <div className='event_detail-date'>
          <i className='event_detail-icon q4i-calendar-4pt' />
          {isRangeDate ? rangeDate : startDate}
        </div>
        {!isZeroTime && !isRangeDate &&
          <div className='event_detail-time'>
            <i className='event_detail-icon q4i-time-4pt' />
            {startTime}
          </div>}
      </div>
      <hr className='field-separator' />
    </>
  )
}

Date.propTypes = {
  eventConferenceEndDate: PropTypes.string,
  eventDate: PropTypes.string
}

const Audio = ({ phone, text, passcode }) => {
  return (
    <>
      <div className='event_detail-phone-container'>
        <div className='event_detail-phone'>
          <i className='event_detail-icon q4i-phone-4pt' />
          {text}
          <span className='event_detail-phone-number'>{getPhone(phone)}</span>
        </div>
        {passcode && (
          <div className='event_detail-passcode'>
            <i className='event_detail-icon q4i-lock-4pt' />
            {passcode}
          </div>
        )}
      </div>
      <hr className='field-separator' />
    </>
  )
}

Audio.propTypes = {
  phone: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  passcode: PropTypes.string
}

const Document = ({ url, text, type }) => {
  return (
    <div className={`event_detail-${type}`}>
      <i className={`event_detail-icon q4i-${type}-4pt`} />
      <Link to={{ pathname: url }} target='_blank'>{text}</Link>
      <hr className='field-separator' />
    </div>
  )
}

Document.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

/**
 * Event Detail Modal
 * @param {Object} props
 * @param {function} props.onClose
 * @param {Object<any>} props.event
 */
const EventDetailModal = ({ onClose, event }) => {
  const {
    companyName, eventDate, eventTitle, eventTypeName, exchange,
    phoneLiveUS, phoneReplayUS, phoneReplayInternational, phoneLiveInternational, passcodeLiveUS, passcodeLiveInternational,
    symbol, transcriptUrl, webcastLiveUrl, webcastReplayUrl
  } = event

  const shouldDisplayEventDocuments = () => {
    return !!(transcriptUrl || webcastLiveUrl || webcastReplayUrl)
  }

  const shouldDisplayEventAudio = () => {
    return !!(phoneLiveUS || phoneReplayUS || phoneLiveInternational || phoneReplayInternational)
  }

  return (
    <Dialog
      className='event-root'
      classes={{
        paper: 'event event-info'
      }}
      open
      onClose={onClose}
    >
      <div className='event_banner'>
        <i className={`event_icon ${getEventIcon(eventTypeName)}`} />
        <div className='event_heading'>
          <div className='event_title'>{eventTitle}</div>
          <div className='event_company'>{`${companyName}${(symbol || exchange) ? ' |' : ''} ${symbol} ${exchange}`}</div>
        </div>
      </div>

      <div className='event_detail'>
        {eventDate && (
          <>
            <div className='event_detail-heading'>Information</div>
            <hr className='field-separator' />
            <Date event={event} />
          </>
        )}

        {shouldDisplayEventAudio() && (
          <>
            <div className='event_detail-heading'>Audio</div>
            <hr className='field-separator' />
            {phoneLiveUS && <Audio text='Live United States' phone={phoneLiveUS} passcode={passcodeLiveUS} />}
            {phoneLiveInternational && <Audio text='Live International' phone={phoneLiveInternational} passcode={passcodeLiveInternational} />}
            {phoneReplayUS && <Audio text='Replay United States' phone={phoneReplayUS} />}
            {phoneReplayInternational && <Audio text='Replay International' phone={phoneReplayInternational} />}
          </>
        )}

        {shouldDisplayEventDocuments() && (
          <>
            <div className='event_detail-heading'>Documents</div>
            <hr className='field-separator' />
            {webcastLiveUrl && <Document url={webcastLiveUrl} type='webcast' text='Webcast' />}
            {webcastReplayUrl && <Document url={webcastReplayUrl} type='webcast' text='Webcast (Replay)' />}
            {transcriptUrl && <Document url={getTranscriptLink(transcriptUrl, true)} type='transcript' text='Transcript' />}
          </>
        )}

      </div>

      <div className='event_exit' onClick={onClose}>
        <i className='q4i-close-4pt' />
      </div>

    </Dialog>
  )
}

EventDetailModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  event: PropTypes.shape({
    companyName: PropTypes.string,
    eventConferenceEndDate: PropTypes.string,
    eventDate: PropTypes.string,
    eventTitle: PropTypes.string,
    eventTypeName: PropTypes.string,
    exchange: PropTypes.string,
    phoneLiveUS: PropTypes.string,
    phoneReplayUS: PropTypes.string,
    phoneReplayInternational: PropTypes.string,
    phoneLiveInternational: PropTypes.string,
    passcodeLiveUS: PropTypes.string,
    passcodeLiveInternational: PropTypes.string,
    symbol: PropTypes.string,
    transcriptUrl: PropTypes.string,
    webcastLiveUrl: PropTypes.string,
    webcastReplayUrl: PropTypes.string
  })
}

export default memo(EventDetailModal)
