import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getDefaultLimitValue } from '../../../../utils/report'
import { ReportLimit } from '../../config'
import './reportLimits.component.css'

/**
 * ReportLimits Component
 * @param props
 * @returns {*}
 */
function ReportLimits (props) {
  const { entityType, limit, onLimitChange, isLocked } = props

  return (
    <div className='report-limits'>
      {(entityType || []).map((type) => {
        const { _id, label } = type
        const defaultLimitValue = getDefaultLimitValue()
        const limitItem = (limit || []).find((each) => each._entityType === _id)
        const handleLimitChange = (value) => onLimitChange(_id, value)

        return (
          <ReportLimit
            key={`report-limit-item--${_id}`}
            sliderTheme='rain'
            fieldTheme='white'
            entityTypeLabel={label}
            entityTypeId={_id}
            initialValue={(limitItem && limitItem.value) || defaultLimitValue}
            onChange={handleLimitChange}
            isLocked={isLocked}
          />
        )
      })}
    </div>
  )
}

ReportLimits.propTypes = {
  entityType: PropTypes.array.isRequired,
  limit: PropTypes.array.isRequired,
  onLimitChange: PropTypes.func.isRequired
}

ReportLimits.defaultProps = {
  entityType: []
}

export default memo(ReportLimits)
