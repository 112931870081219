import React, { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

// actions
import { modalType, openModal, closeModal, createToast } from '../../../actions'
import { usePeerQuery, usePeerDeleteQuery, useHistoricalStockPromiseQuery, useStockPromiseQuery, useRelativePerformancePromiseQuery } from '../hook'
import { useDispatchAction } from '../../../hook'

// components
import { Banner } from '../../../components'
import PeerListToolbar from './toolbar/toolbar.component'
import PeerListTable from './table/table.component'

// utils
import { getFromXigniteToStandard, getPageSizeFromStorage } from '../../../utils'
import { get, isEmpty, isNull, omitBy } from 'lodash'

const dataIdPrefix = 'PeerListPage'
const PAGE_SIZE_ID = 'peer-list-grid'

/**
 * Peer List Page
 * @param props
 * @returns {JSX.Element}
 */
function PeerList (props) {
  // #region MapDispatchToProps
  const dispatch = useDispatch()
  const handleModalOpen = useDispatchAction(openModal, dispatch)
  const handleModalClose = useDispatchAction(closeModal, dispatch)
  const handleCreateToast = useDispatchAction(createToast, dispatch)
  // #endregion

  const history = useHistory()

  const [state, setState] = useState({
    search: null,
    searchField: ['name', 'symbol'],
    listOptions: {
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    },
    tableData: []
  })

  const { search, searchField, listOptions, tableData } = state

  const variables = omitBy({
    search,
    searchField,
    ...listOptions
  }, isNull)

  const [handlePeerDelete, { loading: deleteProgress }] = usePeerDeleteQuery()
  const { loading, data, refetch } = usePeerQuery({ variables })

  const peers = get(data, 'peer.items', [])
  const total = get(data, 'peer.count', 0)

  const tickerId = useMemo(() => peers.filter(peer => peer.tickerId).map(peer => peer.tickerId), [peers])

  const [getHistoricalStock] = useHistoricalStockPromiseQuery()
  const [getStock] = useStockPromiseQuery()
  const [getRelativePerformance] = useRelativePerformancePromiseQuery()
  const [loadingState, setLoadingState] = useState({ areTasksFinished: false, isError: false })
  const { areTasksFinished, isError } = loadingState

  const getPeerLoadingStatus = () => {
    if (isError) {
      return false
    }
    if (loading === false) {
      return tickerId.length ? !areTasksFinished : false
    }
    return true
  }

  useEffect(() => {
    setLoadingState((prevState) => ({ ...prevState, areTasksFinished: false, isError: false }))

    if (!tickerId.length) {
      setState((prevState) => ({ ...prevState, tableData: [] }))
      return
    }

    const getStockDateRange = () => {
      const timeZoneOffset = moment.tz.zone('America/New_York').parse(Date.now()) + 1
      const startDate = moment().subtract(1, 'months').startOf('day').toISOString()
      const endDate = moment().startOf('day').subtract(timeZoneOffset, 'minutes').toISOString()
      return { startDate, endDate }
    }

    const options = { tickerId, ...getStockDateRange() }

    const tasks = [getStock(options), getHistoricalStock(options), getRelativePerformance(options)]

    Promise.all(tasks)
      .catch((e) => {
        console.error(e)
        setLoadingState((prevState) => ({ ...prevState, areTasksFinished: true, isError: true }))
        return []
      }).then((results) => {
        if (isEmpty(results)) return
        const [stockResponse, historicalStockResponse, relativePerformanceResponse] = results

        const stocks = get(stockResponse, 'stock.items', [])
        const historicalStocks = get(historicalStockResponse, 'stock.items', [])
        const relativePerformances = get(relativePerformanceResponse, 'relativePerformance.items', [])

        const tableData = peers.map((peer) => {
          const { tickerId, id, exchange, name, symbol } = peer || {}
          const exchangeName = getFromXigniteToStandard(exchange)
          const trendData = historicalStocks
            ?.filter((stock) => stock?.tickerId === tickerId)
            .map((stock) => stock?.last)
            .reverse()

          const { volume, lastCloseChange, lastCloseChangePercent, last, currency } = stocks?.find((stock) => stock?.tickerId === tickerId) || {}
          const { expectedReturn, returnOnDate, stockSpecificReturn } = relativePerformances?.find((relativePerformance) => relativePerformance?.tickerId === tickerId) || {}
          return {
            id,
            trendData,
            currency,
            exchangeName,
            last,
            lastCloseChange,
            lastCloseChangePercent,
            name,
            symbol,
            tickerId,
            volume,
            expectedReturn,
            returnOnDate,
            stockSpecificReturn
          }
        })

        setState((prevState) => ({ ...prevState, tableData }))
        setLoadingState((prevState) => ({ ...prevState, areTasksFinished: true }))
      })
  }, [tickerId, getHistoricalStock, getStock, getRelativePerformance, peers])

  /**
   * Handle query change
   * @param query
   */
  const handleQueryChange = (query = {}) => {
    const options = query.listOptions ? { ...query } : { ...query, listOptions: { ...listOptions, page: 1 } }
    setState({ ...state, ...options })
  }

  /**
   * Handle bulk action
   * @param selectedIds - array of peer ticker id(s)
   */
  const handleBulkAction = (selectedIds) => {
    if (!selectedIds || !selectedIds.length) {
      return
    }

    handleModalOpen({
      type: modalType.CONFIRM_MODAL,
      props: {
        content: {
          title: 'Delete Peer?',
          message: `Do you want to delete the selected Peer List ${(selectedIds && selectedIds.length > 1) ? 'items' : 'item'}?`
        },
        onConfirm: () => handleBulkDeleteConfirm(selectedIds)
      }
    })
  }

  /**
   * Handle confirm message
   * @param selectedIds
   */
  function handleBulkDeleteConfirm (selectedIds) {
    handleModalClose({
      type: modalType.CONFIRM_MODAL
    })

    handlePeerDelete({
      variables: { id: selectedIds }
    })
      .then((response) => {
        get(response, 'errors')
          ? handleFailure()
          : handleDeleteSuccess(get(response, 'data.peer.delete.count', null))
      })
  }

  /**
   * On Action Completion Failure display an error
   */
  function handleFailure () {
    openModal({
      type: modalType.ERROR_MODAL
    })
  }

  /**
   * On Delete Success show toast message and refetch peers
   * @param deletedCount
   */
  function handleDeleteSuccess (deletedCount) {
    if (!deletedCount) {
      refetch()
      return
    }

    if (peers.length && listOptions.page > 1) {
      setState((currentState) => ({ ...currentState, listOptions: { ...currentState.listOptions, page: 1 } }))
    }

    refetch().then(() => {
      handleCreateToast({ text: `Peer${(deletedCount > 1) ? 's' : ''} deleted successfully.` })
    })
  }

  /**
   * On add new peer success show toast message and refetch peers
   */
  function handleAddSuccess () {
    refetch().then(() => {
      handleCreateToast({ text: 'Peer added successfully.' })
    })
  }

  return (
    <div className='peerlist-page'>
      <Banner
        title='Peer List'
        icon='q4i-watchlist-2pt'
        controls={[
          {
            type: 'addPeer',
            theme: 'square',
            icon: 'q4i-add-4pt',
            handleAddSuccess,
            peers
          }
        ]}
      />
      <PeerListToolbar
        dataId={dataIdPrefix}
        search={search}
        onQueryChange={handleQueryChange}
      />
      <PeerListTable
        dataId={dataIdPrefix}
        pageSizeId={PAGE_SIZE_ID}
        loading={[getPeerLoadingStatus(), deleteProgress].some((loadingItem) => !!loadingItem)}
        data={tableData}
        total={total}
        history={history}
        listOptions={listOptions}
        onQueryChange={handleQueryChange}
        onBulkAction={handleBulkAction}
      />
    </div>
  )
}

export default memo(PeerList)
