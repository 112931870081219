import { fetchProfile } from '../../shared/index'
import { getOwnershipType } from '../../../utils'
import client from '../../../middleware/graphql.middleware'
import gql from 'graphql-tag'

export const FETCH_TOP_SHAREHOLDERS_REQUEST = 'FETCH_TOP_SHAREHOLDERS_REQUEST'
export const FETCH_TOP_SHAREHOLDERS_SUCCESS = 'FETCH_TOP_SHAREHOLDERS_SUCCESS'
export const FETCH_TOP_SHAREHOLDERS_ERROR = 'FETCH_TOP_SHAREHOLDERS_ERROR'

export const FETCH_TOP_SHAREHOLDERS_SNOWFLAKE_SUCCESS = 'FETCH_TOP_SHAREHOLDERS_SNOWFLAKE_SUCCESS'

/**
 * GraphQL query for Top Shareholders (13F, SID, SV)
 */
const TOP_HOLDERS = gql`
  query InstitutionHoldingCurrent(
    $tickerId: [String]!
    $source: String!
    $limit: Int!
  ) {
    instHoldingCurrent(
      tickerId: $tickerId
      source: $source
      limit: $limit
    ) {
      items {
        current
        change
        holderType
        institutionName
        institutionId
      }
    }
  }`

/**
 * Fetch Top Shareholder Data from GraphQL (13F, SID)
 * @param tickerId
 * @param source
 * @param dispatch
 */
const fetchTopHoldersSnowflake = ({ tickerId, source }, dispatch) => {
  dispatch({ type: FETCH_TOP_SHAREHOLDERS_REQUEST })
  return client
    .query({
      query: TOP_HOLDERS,
      variables: {
        tickerId,
        source,
        limit: 25
      }
    })
    .then((data) => {
      dispatch({ payload: data, type: FETCH_TOP_SHAREHOLDERS_SNOWFLAKE_SUCCESS })
    })
    .catch((error) => {
      dispatch({ error, type: FETCH_TOP_SHAREHOLDERS_ERROR })
    })
}

export const loadTopShareholders = (params) => (dispatch, getState) => {
  return dispatch(fetchProfile()).then(() => {
    const state = getState()
    const source = getOwnershipType(state.shared.profile).value
    const tickerId = params.tickerId || state.dashboard.dashboardState.tickerId

    return fetchTopHoldersSnowflake({ tickerId, source }, dispatch)
  })
}
