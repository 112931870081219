import {
  ERROR,
  FETCH_INDEX_ERROR,
  FETCH_INDEX_REQUEST,
  FETCH_INDEX_SUCCESS,
  FETCHED,
  FETCHING,
  IDLE,
  REMOVE_STOCK_INDEX,
  SET_CURRENT_INDEX
} from '../../../actions/widget/stock'
import { citrus, spice, teal } from '../../../resources/materialui-overrides/colors'

import { without } from 'lodash'

const initialState = {
  status: IDLE,
  indices: []
}

const stockIndex = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INDEX_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_INDEX_SUCCESS:
      const usedColors = []
      let isNewIndex = true

      const indices = (state.indices || []).map((index) => {
        if (index.name === (state.currentIndex && state.currentIndex.name)) {
          isNewIndex = false
          index.data = action.payload.historical
        }

        usedColors.push(index.color)
        return index
      })

      if (isNewIndex) {
        indices.push({
          ...state.currentIndex,
          color: without([teal, spice, citrus], ...usedColors)[0],
          data: action.payload.historical
        })
      }

      return {
        ...state,
        indices,
        status: FETCHED
      }
    case FETCH_INDEX_ERROR:
      return {
        ...state,
        error: action.error,
        status: ERROR
      }
    case SET_CURRENT_INDEX:
      return {
        ...state,
        currentIndex: action.payload
      }
    case REMOVE_STOCK_INDEX:
      return {
        ...state,
        indices: (state.indices || []).filter((index) => {
          const removedIndex = action.payload
          return (index && index.name) !== (removedIndex && removedIndex.name)
        })
      }
    default:
      return state
  }
}

export default stockIndex
