import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isEqual, isNull, omitBy } from 'lodash'
import moment from 'moment-timezone'

// actions
import { searchEventSecurity, resetEventSecuritySuggestions, openModal, closeModal, modalType, statusType } from '../../../actions'
import { useEventTranscriptQuery } from '../hook'
import { useDispatchAction, useTypedSelector } from '../../../hook'

// components
import { Banner, NoSubscriptionMessage } from '../../../components'
import EventsTranscriptsSidebar from './sidebar/sidebar.component'
import EventTranscriptToolbar from './toolbar/toolbar.component'
import EventTranscriptTable from './table/table.component'

// utils
import { EVENT_SEARCH_FIELDS, EVENT_TYPE_OPTIONS, getTimeZoneOffsetMs, isServiceEnabled } from '../../../utils'

import './landing.page.scss'

const initialFilterState = {
  startDate: moment().utc().startOf('month'),
  endDate: moment().utc().endOf('month'),
  tickers: [],
  eventTypeOptions: EVENT_TYPE_OPTIONS,
  transcripts: false,
  peers: false
}

const initialListOptions = {
  page: 1,
  limit: 10
}

/**
 * Events & Transcripts Page
 * @param props
 */
function EventsTranscripts (props) {
  const [collapsed, setCollapsed] = useState(false)
  const [canResetFilters, setCanResetFilters] = useState(false)
  const [canApplyFilters, setCanApplyFilters] = useState(true)
  const [filters, setFilters] = useState(initialFilterState)
  const [listOptions, setListOptions] = useState(initialListOptions)
  const [prevFiltersState, setPrevFiltersState] = useState(initialFilterState)
  const [searchText, setSearchText] = useState('')
  const [total, setTotal] = useState(0)

  const profile = useTypedSelector((state) => state.profile.data)
  const services = profile && profile.services
  const isSubscribed = isServiceEnabled('events', services || [])

  const getQueryVariables = (currentFilters, currentListOptions = listOptions) => {
    const { startDate, endDate, peers, tickers, transcripts, eventTypeOptions } = currentFilters
    return {
      variables: omitBy({
        search: searchText || undefined,
        searchField: EVENT_SEARCH_FIELDS,
        startDate: startDate && startDate.utc().startOf('day'),
        endDate: endDate && endDate.utc().endOf('day'),
        timezoneOffset: getTimeZoneOffsetMs(),
        tickerId: tickers.length ? tickers.map((ticker) => ticker.entityId) : null,
        peers,
        transcript: transcripts,
        eventType: eventTypeOptions.filter(eventType => eventType.selected).map(eventType => eventType.value),
        ...currentListOptions
      }, isNull)
    }
  }

  const [getEvents, { loading, data }] = useEventTranscriptQuery()

  const dispatch = useDispatch()
  const securitySuggestions = useTypedSelector((x) => x.event.securitySearch.securitySuggestions)
  const securityLoading = useTypedSelector((x) => x.event.securitySearch.securitySuggestionStatus)
  const handleSecuritySearch = useDispatchAction(searchEventSecurity, dispatch)
  const handleEventSecuritySuggestionsReset = useDispatchAction(resetEventSecuritySuggestions, dispatch)
  const handleModalOpen = useDispatchAction(openModal, dispatch)
  const handleModalClose = useDispatchAction(closeModal, dispatch)

  const isSearchSecuritiesLoading = securityLoading === statusType.IN_PROGRESS

  // initial load onComponentMount
  useEffect(() => {
    getEvents(getQueryVariables(filters))
  }, [])

  useEffect(() => {
    setCanApplyFilters(!isEqual(filters, prevFiltersState))
    setCanResetFilters(!isEqual(filters, initialFilterState))
  }, [filters, prevFiltersState])

  useEffect(() => {
    if (data) {
      setTotal(data.event.count)
    }
  }, [data])

  useEffect(() => {
    getEvents(getQueryVariables(prevFiltersState))
  }, [listOptions])

  useEffect(() => {
    if (listOptions === initialListOptions) {
      getEvents(getQueryVariables(prevFiltersState))
    } else {
      setListOptions(initialListOptions)
    }
  }, [searchText])

  /**
   * Search Ownership Securities
   * @param query
   */
  function searchSecurities (query) {
    handleSecuritySearch(query)
  }

  /**
   * On toggle Click - toggle filter drawer
   */
  const toggleFilterSidebar = () => {
    setCollapsed(!collapsed)
  }

  /**
   * On filter change - update filter state
   */
  const handleFilterChange = (item) => {
    setFilters({
      ...filters,
      ...item
    })
  }

  /**
   * On pagination change - update listOptions
   */
  const handleListOptionsChange = (options) => {
    setListOptions(options)
  }

  /**
   * On Reset Click - clear filters
   */
  const onResetClick = () => {
    setFilters(initialFilterState)
  }

  /**
   * On Apply Click - Apply filters
   */
  const onApplyClick = () => {
    if (listOptions === initialListOptions) {
      getEvents(getQueryVariables(filters))
    } else {
      setListOptions(initialListOptions)
    }
    setPrevFiltersState(filters)
  }

  /**
   * On Row Click - Open Event Information Modal
   * @param {object} obj
   * @param {string} obj.id
   * @param {string} obj.tickerId
   */
  const handleRowClicked = ({ id, tickerId }) => {
    if (!id || !tickerId) return

    const events = (data?.event?.items || [])
    const event = events.find((event) => event.id === id && event.tickerId === tickerId)

    handleModalOpen({
      type: modalType.EVENT_DETAIL_MODAL,
      props: {
        event,
        onClose: handleModalClose
      }
    })
  }

  return (
    <>
      <Banner
        title='Events & Transcripts'
        icon='q4i-transcript-2pt'
      />
      {services && services.length && !isSubscribed
        ? <NoSubscriptionMessage />
        : (
          <div className='eventsTranscripts-page'>
            <EventsTranscriptsSidebar
              collapsed={collapsed}
              canApply={canApplyFilters}
              canReset={canResetFilters}
              filters={filters}
              onResetFilter={onResetClick}
              onApplyFilter={onApplyClick}
              handleFilterChange={handleFilterChange}
              searchSecurities={searchSecurities}
              resetSecuritySuggestions={handleEventSecuritySuggestionsReset}
              securitySuggestions={securitySuggestions}
              isSearchSecuritiesLoading={isSearchSecuritiesLoading}
            />
            <div className='event-transcript-page-tab_body'>
              <EventTranscriptToolbar
                collapsed={collapsed}
                toggleFilterSidebar={toggleFilterSidebar}
                searchText={searchText}
                setSearchText={setSearchText}
              />
              <EventTranscriptTable
                loading={loading}
                data={data?.event?.items || []}
                listOptions={listOptions}
                handleListOptionsChange={handleListOptionsChange}
                onRowClick={handleRowClicked}
                total={total}
              />
            </div>
          </div>
          )}
    </>
  )
}

export default EventsTranscripts
