import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import moment from 'moment'
import { get } from 'lodash'

// actions
import {
  getActivity as getActivityAction,
  getTags as getTagsAction,
  removeActivities as removeActivitiesAction,
  resetActivity as resetActivityAction,
  resetAttendee as resetAttendeeAction,
  resetItinerary as resetItineraryAction,
  createBriefingBookFromActivity as createBriefingBookFromActivityAction,
  generateItinerary as generateItineraryAction,
  openModal as openModalAction,
  statusType,
  openModal,
  modalType, setEmailRecipients, updateUserEmailPreference, closeModal, emailPreference
} from '../../../actions'

// shared
import { usePrevious } from '../../../hook'
import { Message, Spinner } from '../../../components'
import { SectionTab } from '../../../components/shared'
import {
  isParent as checkIfParent,
  getParentItinerary,
  unwindActivityLinks,
  formatDate,
  VIRTUAL,
  flattenEntity,
  getOwnershipType,
  getQ4SecurityId,
  getQ4TickerId,
  getCookie
} from '../../../utils'
import { useExport } from '../../../services/csvExport/csvExport.service'
import client from '../../../middleware/graphql.middleware'

// components
import ActivityDetailBanner from './banner/banner.component'
import ActivityDetailOverview from './overview/overview.component'
import AttendeeTab from './tab/attendee/attendee.container'
import ItineraryContainer from './tab/itinerary/itinerary.container'
import ActivityFormContainer from '../../../components/activity/form/activityForm.container'
import TagInputOld from '../../../components/tagInput/tagInputOld.container'
import { GET_ACTIVITY_DETAIL_EXPORT } from '../../../components/activity/hook'
import { launchDarklyHelper, LD_FEATURE_FLAGS } from '../../../services/launchDarkly.service'

/**
 * Activity Detail Page
 * @param props
 * @returns {JSX.Element}
 */
function ActivityDetailPage ({
  activity, tags, status, deleted, q4SecurityId, tickerId, match, activityForm, history, ownershipType,
  fetchActivity, resetActivity, removeActivities,
  createBriefingBookFromActivity, generateItinerary, resetAttendeeGrid, resetItineraryGrid, getTags, updateUserEmailPreference
}) {
  const EXPORT_DATE_FORMAT = 'YYYY/MM/DD'
  const isParent = checkIfParent(activity.category)
  const [pageState, setPageState] = useState(
    {
      isMessageOpen: false,
      modalProps: {},
      isActivityFormModalOpen: false,
      activityFormValues: null
    }
  )

  const previousMatch = usePrevious(match)
  const previousActivityForm = usePrevious(activityForm)
  const previousTags = usePrevious(tags)

  const { generateExport, _exporting } = useExport({
    onError: () => openModal({
      type: modalType.ERROR_MODAL
    })
  })
  /**
  * Map activity to csv
  * @param item
  */
  const activityCSVMapper = (item) => {
    const activities = unwindActivityLinks(item)
    const { contact: contacts = [], institution: institutions = [], fund: funds = [] } = activities
    const start = new Date(Number(get(activities, 'start.date')))
    const end = new Date(Number(get(activities, 'end.date')))
    const participants = (activities.participants || []).map((participant) => participant.name)

    // add empty record to activity if no links present to show it in CSV
    const emptyEvent = { entityType: 'Activity' }

    // merge institutions belonging to contacts (required business logic) and remove them from final list
    const uniqueInstitutions = []
    institutions.forEach(institution => {
      let uniqueFlag = true
      contacts.forEach(contact => {
        if (flattenEntity(contact).institutionName === flattenEntity(institution).institutionName) {
          contact.institutionPosition = flattenEntity(institution).entityPosition
          uniqueFlag = false
        }
      })
      if (uniqueFlag) uniqueInstitutions.push(institution)
    })

    return []
      .concat(!item.links?.length ? [emptyEvent] : [], contacts, uniqueInstitutions, funds)
      .map((entityObject) => {
        const entity = flattenEntity(entityObject)
        const isVirtual = activities && activities.virtual
        return {
          'Meeting ID': item.id,
          'Start Date': formatDate(start, EXPORT_DATE_FORMAT, undefined, true),
          'End Date': formatDate(end, EXPORT_DATE_FORMAT, undefined, true),
          Type: activities.category,
          Title: activities.title,
          Itinerary: isParent ? activity.title : '',
          Location: isVirtual ? VIRTUAL : get(activities, 'address[0].location'),
          Venue: isVirtual ? '' : get(activities, 'address[0].venue'),
          'Contact Name': entity.fullName,
          'Contact Phone': entity.phone,
          'Contact Email': entity.email,
          'Institution Name': entity.institutionName,
          'Institution Location': entity.institutionLocation,
          'Institution Style': entity.institutionStyle,
          'Institution Type': entity.institutionType,
          'Institution Turnover': entity.institutionTurnover,
          AUM: entity.institutionAUM,
          'Fund Name': entity.fundName,
          'Fund Style': entity.fundStyle,
          'Fund Type': entity.fundType,
          'Fund Turnover': entity.fundTurnover,
          'Corporate Participants': participants,
          Description: activities.text,
          Tags: activities.tag,
          [`Contact Current Position (${ownershipType.label === 'SID' ? 'Shareholder ID' : ownershipType.label})`]: entity.contactPosition,
          [`Entity Current Position (${ownershipType.label === 'SID' ? 'Shareholder ID' : ownershipType.label})`]: entity.calculatedInstitutionPosition || entity.entityPosition
        }
      })
  }

  const handleExport = () => {
    const extraData = isParent
      ? [{
        'Meeting ID': activity._id,
        'Start Date': formatDate(activity.start.date_time, EXPORT_DATE_FORMAT, undefined, true),
        'End Date': formatDate(activity.end.date_time, EXPORT_DATE_FORMAT, undefined, true),
        Type: activity.category,
        Title: activity.title,
        Itinerary: '',
        Location: activity.virtual ? VIRTUAL : get(activity, 'address[0].location'),
        Venue: activity.virtual ? '' : get(activity, 'address[0].venue'),
        'Contact Name': '',
        'Contact Phone': '',
        'Contact Email': '',
        'Institution Name': '',
        'Institution Location': '',
        'Institution Style': '',
        'Institution Type': '',
        'Institution Turnover': '',
        AUM: '',
        'Fund Name': '',
        'Fund Style': '',
        'Fund Type': '',
        'Fund Turnover': '',
        'Corporate Participants': '',
        Description: activity.text,
        Tags: activity.tag.map(t => t.name)
      }]
      : []

    const params = {
      client,
      variables: {
        parentActivity: isParent ? activity._id : undefined,
        id: isParent ? undefined : activity._id,
        securityId: q4SecurityId,
        tickerId,
        page: 1,
        limit: 0,
        sortBy: 'start.date',
        sortDir: 'asc'
      },
      query: GET_ACTIVITY_DETAIL_EXPORT,
      dataPath: 'data.activity.items',
      fileName: `activity_${activity._id}.csv`,
      formatter: activityCSVMapper,
      extraData: extraData
    }

    generateExport(params)
  }

  const { _itinerary, links } = activity
  const startDate = get(activity, 'start.date_time')
  const showPosition = moment(startDate).isBefore(moment().endOf('quarter'))
  const tabs = []
  const isReady = !!(activity._id)
  const loading = status === statusType.IN_PROGRESS
  const hasItinerary = Boolean(_itinerary && _itinerary.length)
  const hasAttendees = Boolean(
    (links && links.length) ||
    (hasItinerary && _itinerary.some((itinerary) => itinerary.links && itinerary.links.length))
  )

  // componentDidMount
  useEffect(() => {
    getActivityData()
    initUserEmailPreference()
  }, [])

  // componentDidUpdate
  useEffect(() => {
    const urlChanged = previousMatch?.params?.id && previousMatch?.params?.id !== match?.params?.id
    const formChanged = (
      previousActivityForm?.status !== activityForm?.status && activityForm?.status === statusType.SUCCESS
    )

    // fetch on tags changed, but only if initial activity fetch was successful
    const tagsChanged = (
      previousTags?.status && previousTags?.status !== tags?.status && tags?.status === statusType.SUCCESS && status === statusType.SUCCESS
    )

    if (tagsChanged) {
      fetchActivity(match?.params?.id)
    }

    if (urlChanged || formChanged) {
      getActivityData()
    }
  }, [
    match,
    activityForm,
    tags
  ])

  // componentWillUnmount
  useEffect(() => {
    return () => {
      resetActivity()
      resetAttendeeGrid()
      resetItineraryGrid()
    }
  }, [
    resetActivity,
    resetAttendeeGrid,
    resetItineraryGrid
  ])

  /**
   * Get activity data - activity and tags
   */
  const getActivityData = () => {
    const activityId = match.params.id
    fetchActivity(activityId)
    getTags({
      'reference.item': activityId,
      'reference.type': 'Note'
    })
  }

  /**
   * Init User Email Preference
   */
  const initUserEmailPreference = () => {
    const isCrmEmailFeatureEnabled = !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.CRM_EMAIL)
    if (isCrmEmailFeatureEnabled) {
      const userEmailPreference = getCookie('user-email-preference')
      updateUserEmailPreference(userEmailPreference ?? emailPreference.INITIAL)
    }
  }

  /**
   * Closes Message Modal
   */
  const closeModal = () => {
    setPageState({
      ...pageState,
      isMessageOpen: false,
      modalProps: {}
    })
  }

  /**
   * Closes Activity Form
   */
  const closeActivityForm = () => {
    setPageState({
      ...pageState,
      isActivityFormModalOpen: false,
      activityFormValues: null
    })
  }

  /**
   * Opens Activity Edit Form
   */
  const handleEdit = () => {
    setPageState({
      ...pageState,
      isActivityFormModalOpen: true,
      activityFormValues: activity
    })
  }

  /**
   * Opens Activity Create Item Form Modal
   */
  const handleItineraryItemCreate = () => {
    setPageState({
      ...pageState,
      isActivityFormModalOpen: true,
      activityFormValues: getParentItinerary(activity)
    })
  }

  /**
   * Opens Delete Confirmation Modal
   */
  const openDeleteModal = () => {
    setPageState({
      ...pageState,
      isMessageOpen: true,
      modalProps: {
        visible: true,
        type: 'warning',
        title: 'Delete Activity?',
        message: (activity._itinerary && activity._itinerary.length)
          ? `Are you sure you want to delete this Roadshow/Conference? 
                   Note: Deleting it will also delete all Itinerary Activities.`
          : 'Are you sure you want to delete this activity?',
        buttons: [
          {
            ui: 'shaded',
            label: 'cancel',
            onClick: closeModal
          },
          {
            ui: 'spice',
            label: 'confirm',
            onClick: handleDeleteConfirm
          }
        ]
      }
    })
  }

  /**
   * Removes Activity
   */
  const handleDeleteConfirm = () => {
    removeActivities([activity._id])
    closeModal()
  }

  /**
   * Generate itinerary PDF
   */
  const handlePrintItinerary = () => {
    const id = activity && activity._id

    const params = {
      file: {
        type: 'pdf',
        name: 'itinerary'
      }
    }

    id && generateItinerary(id, params)
  }

  /**
   * Creates Briefing Book
   */
  const handleCreateBriefingBook = () => {
    createBriefingBookFromActivity(activity)
      .then((data) => {
        const { meta, payload } = data
        if (meta.success && payload._id) {
          history.push(`/briefingbook/${payload._id}`)
        }
      })
  }

  if (deleted || status === statusType.ERROR) {
    return <Redirect to='/activity' />
  }

  if (isParent) {
    tabs.push({
      id: 'itinerary',
      label: 'Itinerary',
      view: <ItineraryContainer
        activity={activity}
        handleItineraryItemCreate={handleItineraryItemCreate}
        formModalOpen={pageState.isActivityFormModalOpen}
      />
    })
  }

  tabs.push({
    id: 'attendees',
    label: 'Attendees',
    view: <AttendeeTab
      activityId={activity._id}
      securityId={q4SecurityId}
      tickerId={tickerId}
      isParent={isParent}
      showPosition={showPosition}
      useGraphQL
    />

  })

  return (
    <>
      {loading && <Spinner mask theme='rain' />}
      {isReady && (
        <>
          <ActivityDetailBanner
            activity={activity}
            hasItinerary={hasItinerary}
            hasAttendees={hasAttendees}
            handleExport={handleExport}
            handleEdit={handleEdit}
            openDeleteModal={openDeleteModal}
            handlePrintItinerary={handlePrintItinerary}
            handleCreateBriefingBook={handleCreateBriefingBook}
          />
          <ActivityDetailOverview activity={activity}>
            <TagInputOld
              theme='dark'
              tags={(tags && tags.data) || []}
              entityType='Note'
              entityId={activity._id}
              limit={3}
            />
          </ActivityDetailOverview>
          <SectionTab
            tabs={tabs}
            rememberMounted={false}
          />
        </>
      )}

      <Message {...pageState.modalProps} />

      {pageState.isActivityFormModalOpen && (
        <ActivityFormContainer values={pageState.activityFormValues} onClose={closeActivityForm} />
      )}
    </>
  )
}

ActivityDetailPage.propTypes = {
  activity: PropTypes.object,
  tags: PropTypes.object,
  status: PropTypes.string,
  deleted: PropTypes.bool,
  tickerId: PropTypes.string,
  match: PropTypes.object,
  activityForm: PropTypes.object,
  history: PropTypes.object,
  ownershipType: PropTypes.object,
  fetchActivity: PropTypes.func,
  resetActivity: PropTypes.func,
  removeActivities: PropTypes.func,
  createBriefingBookFromActivity: PropTypes.func,
  generateItinerary: PropTypes.func,
  resetAttendeeGrid: PropTypes.func,
  resetItineraryGrid: PropTypes.func,
  getTags: PropTypes.func,
  updateUserEmailPreference: PropTypes.func
}

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ownershipType = getOwnershipType(get(state, 'profile.data'))
  return {
    activityForm: state.activity.activityForm,
    activity: state.activity.activity.data,
    tags: state.tag.tags,
    status: state.activity.activity.status,
    deleted: state.activity.activity.deleted,
    token: state.token,
    q4SecurityId: getQ4SecurityId(profile),
    tickerId: getQ4TickerId(profile),
    ownershipType: ownershipType
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchActivity: bindActionCreators(getActivityAction, dispatch),
  getTags: bindActionCreators(getTagsAction, dispatch),
  resetActivity: bindActionCreators(resetActivityAction, dispatch),
  resetAttendeeGrid: bindActionCreators(resetAttendeeAction, dispatch),
  resetItineraryGrid: bindActionCreators(resetItineraryAction, dispatch),
  removeActivities: bindActionCreators(removeActivitiesAction, dispatch),
  createBriefingBookFromActivity: bindActionCreators(createBriefingBookFromActivityAction, dispatch),
  generateItinerary: bindActionCreators(generateItineraryAction, dispatch),
  openModal: bindActionCreators(openModalAction, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
  updateUserEmailPreference: bindActionCreators(updateUserEmailPreference, dispatch),
  setEmailRecipients: bindActionCreators(setEmailRecipients, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityDetailPage))
