import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { capitalize } from 'lodash'

/**
 * Target Action Types
 * @type {string}
 */
export const GET_TARGET_REQUEST = 'GET_TARGET_REQUEST'
export const GET_TARGET_SUCCESS = 'GET_TARGET_SUCCESS'
export const GET_TARGET_FAILURE = 'GET_TARGET_FAILURE'

export const CREATE_TARGET_REQUEST = 'CREATE_TARGET_REQUEST'
export const CREATE_TARGET_SUCCESS = 'CREATE_TARGET_SUCCESS'
export const CREATE_TARGET_FAILURE = 'CREATE_TARGET_FAILURE'

export const CREATE_TARGETS_REQUEST = 'CREATE_TARGETS_REQUEST'
export const CREATE_TARGETS_SUCCESS = 'CREATE_TARGETS_SUCCESS'
export const CREATE_TARGETS_FAILURE = 'CREATE_TARGETS_FAILURE'

export const REMOVE_TARGET_REQUEST = 'REMOVE_TARGET_REQUEST'
export const REMOVE_TARGET_SUCCESS = 'REMOVE_TARGET_SUCCESS'
export const REMOVE_TARGET_FAILURE = 'REMOVE_TARGET_FAILURE'

export const CLEAR_TARGET = 'CLEAR_TARGET'

/**
 * Get target
 * @param id
 * @returns {{}}
 * @private
 */
const _getTarget = (id) => ({
  [CALL_API]: {
    types: [
      GET_TARGET_REQUEST,
      GET_TARGET_SUCCESS,
      GET_TARGET_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/targeting/target/entity/${id}`
  }
})

/**
 * Create target
 * @param data
 * @returns {{}}
 * @private
 */
const _createTarget = (data) => ({
  [CALL_API]: {
    types: [
      CREATE_TARGET_REQUEST,
      CREATE_TARGET_SUCCESS,
      CREATE_TARGET_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/targeting/target',
    payload: data
  }
})

/**
 * Create targets
 * @param data
 * @private
 */
const _createTargets = (data) => ({
  [CALL_API]: {
    types: [
      CREATE_TARGETS_REQUEST,
      CREATE_TARGETS_SUCCESS,
      CREATE_TARGETS_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/targeting/target/v2',
    payload: data
  }
})

/**
 * Delete target
 * @param id
 * @returns {{}}
 * @private
 */
const _deleteTarget = (id) => ({
  [CALL_API]: {
    types: [
      REMOVE_TARGET_REQUEST,
      REMOVE_TARGET_SUCCESS,
      REMOVE_TARGET_FAILURE
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: `/targeting/target/${id}`
  }
})

/**
 * Dispatch GET_TARGET_REQUEST
 */
export const getTarget = (id) => (dispatch) => {
  return dispatch(_getTarget(id))
}

/**
 * Dispatch CREATE_TARGET_REQUEST
 */
export const createTarget = (data) => (dispatch) => {
  const reference = {
    type: capitalize(data && data.reference && data.reference.type),
    item: data && data.reference && data.reference.item,
    q4_entity_id: data && data.reference && data.reference.entityId
  }
  return dispatch(_createTarget({ reference }))
}

/**
 * Dispatch CREATE_TARGETS_REQUEST
 * @param {Array}  params.targets
 * @param {String} params.targets.entityType
 * @param {String} params.targets._id
 * @param {Object} params.targets._target
 * @param {Object} params.targets._deal
 */
export const createTargets = (params = {}) => (dispatch) => {
  const targets = (params.targets || [])
    .filter((target) => target && !target._target && !target._deal)
    .map(({ entityType, _id }) => ({
      reference: {
        type: capitalize(entityType),
        item: _id
      }
    }))

  return targets.length && dispatch(_createTargets({ targets }))
}

/**
 * Dispatch REMOVE_TARGET_REQUEST
 * @param id
 */
export const deleteTarget = (id) => (dispatch) => {
  return dispatch(_deleteTarget(id))
}

export const clearTarget = () => (dispatch) => {
  return dispatch({ type: CLEAR_TARGET })
}
