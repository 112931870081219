import {
  GET_DEALS_REQUEST,
  GET_DEALS_SUCCESS,
  GET_DEALS_FAILURE,
  UPDATE_DEAL_FAILURE,
  statusType
} from '../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const deals = (state = initial, action) => {
  switch (action.type) {
    case GET_DEALS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_DEALS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: statusType.SUCCESS
      }

    case GET_DEALS_FAILURE:
      return {
        error: action.error,
        status: statusType.ERROR
      }

    case UPDATE_DEAL_FAILURE:
      return {
        error: action.error,
        status: statusType.ERROR
      }

    default:
      return state
  }
}

export default deals
