import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// actions
import { useActivityQuery } from '../../hook'
import { getEntityOwnershipChart, resetEntityOwnershipChart, statusType } from '../../../../actions'

// components
import Chart from './chart.component'
import {
  chartActivities,
  DEFAULT_TICKER,
  entityChartPosition,
  getActiveTicker,
  getOwnershipType,
  isServiceEnabled,
  THEMES
} from '../../../../utils'
import { get, isNull, omitBy } from 'lodash'

const propTypes = {
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  width: PropTypes.number,
  height: PropTypes.number,
  entityId: PropTypes.string.isRequired,
  quarters: PropTypes.number,
  subscription: PropTypes.array,
  data: PropTypes.shape({
    loading: PropTypes.bool,
    position: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  }),
  peers: PropTypes.array,
  stock: PropTypes.object,
  events: PropTypes.object,
  news: PropTypes.object,
  activity: PropTypes.array
}

const defaultProps = {
  theme: THEMES.DARK,
  width: null,
  height: null,
  quarters: 5,
  subscription: [],
  data: {
    loading: false
  },
  stock: {}
}

/**
 * Get peer
 * @param peer
 * @param quarters
 * @returns {{}}
 * @private
 */
function _formatPeer (peer, quarters) {
  const { tickerId, symbol, exchange } = peer
  return {
    security: { tickerId, symbol, exchange },
    position: entityChartPosition({ position: peer, quarters, symbol })
  }
}

/**
 * Entity Ownership Chart
 * @param props
 */
function EntityOwnershipChart (props) {
  const {
    quarters, security, ticker, entityId, data, peers, stock, events, news,
    getEntityOwnershipChart, resetEntityOwnershipChart, ownershipType, profile
  } = props
  const { loading, position } = data
  const { label = '13F' } = (ownershipType || {})

  const [range] = useState({
    startDate: moment.utc().endOf('quarter').startOf('day').subtract(quarters + .8, 'quarter').toDate(),
    endDate: moment.utc().startOf('day').toDate()
  })

  // Get activities
  const { loading: isActivity, data: activity } = useActivityQuery({
    variables: omitBy({ entityId, ...range, limit: 100 }, isNull)
  })

  // Get historical stock, news, events
  useEffect(() => {
    const services = profile && profile.services
    const eventSubscribed = isServiceEnabled('events', services || [])
    const tickerId = ticker.q4_ticker_id || DEFAULT_TICKER
    getEntityOwnershipChart({ securityId: security, tickerId, ...range }, { useGraphQL: true, eventSubscribed })

    return () => {
      resetEntityOwnershipChart()
    }
  }, [profile, security, ticker.q4_ticker_id, range, getEntityOwnershipChart, resetEntityOwnershipChart])

  // Check loading state
  const isStock = [stock, events, news]
    .some((state) => get(state, 'status') === statusType.IN_PROGRESS)
  const isLoading = [loading, isStock, isActivity].some((item) => !!item)

  const options = {
    ...props,
    ...range,
    position: entityChartPosition({ position, quarters, symbol: get(ticker, 'symbol') }),
    peers: (peers || []).map((peer) => peer && _formatPeer(peer, quarters)),
    stock: get(stock, 'data', []),
    events: get(events, 'data', []),
    news: get(news, 'data', []),
    activity: chartActivities(get(activity, 'activity.items')),
    ownership: label,
    isLoading
  }

  return (!isLoading && <Chart {...options} />)
}

EntityOwnershipChart.propTypes = propTypes
EntityOwnershipChart.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)

  return {
    ticker,
    profile,
    security: get(ticker, '_security'), // TODO: @deprecate and use securityId
    // securityId: get(ticker, 'q4_entity_id'),
    subscription: get(profile, 'services'),
    stock: get(state, 'ownership.entityChart.stock'),
    events: get(state, 'ownership.entityChart.events'),
    news: get(state, 'ownership.entityChart.news'),
    ownershipType: getOwnershipType(profile)
  }
}

const mapDispatchToProps = (dispatch) => ({
  getEntityOwnershipChart: bindActionCreators(getEntityOwnershipChart, dispatch),
  resetEntityOwnershipChart: bindActionCreators(resetEntityOwnershipChart, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(EntityOwnershipChart)
