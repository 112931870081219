import { get } from 'lodash'
import { isValidEmail } from '../regex.util'
import { combine } from '../ui'

/**
 * Get address
 * @param data
 * @returns {string}
 */
export const getAddress = (data) => {
  const { address1, address2, address3, city, stateProvince, countryName, postalCode } = data

  const address = combine([address1, address2, address3])
  const location = combine([city, stateProvince])
  const country = combine([countryName, postalCode], ' ')

  return combine([address, location, country], '<br>')
}

/**
 * Get phones
 * @param phone
 * @param direct
 * @returns {string}
 */
export const getPhones = (phone, direct) => {
  const corporatePhone = (phone && phone.length) ? phone : null
  const directPhone = (direct && direct.length) ? `${direct} (direct)` : null

  if (corporatePhone && directPhone && (corporatePhone !== directPhone)) {
    return combine([corporatePhone, directPhone], '<br>')
  }

  return corporatePhone || directPhone
}

/**
 * Get contact strategy
 * @param value
 * @returns {string}
 */
export const getStrategy = (value) => {
  if (!value || !value.id) {
    return '-'
  }

  if (value.summary && value.summary.length) {
    return value.summary
  }

  const country = combine(value.country)
  const regionGroup = combine(value.regionGroup)
  const customRegion = combine(value.customRegion)
  const marketCap = combine(value.marketCap)
  const quality = combine(value.quality)
  const sectors = combine(value.sectors)
  const strategy = combine(value.strategy)
  const style = combine(value.style)
  const securityType = combine(value.securityType)

  const summary = combine([country, regionGroup, customRegion, marketCap, quality, sectors, strategy, style, securityType])

  return (summary && summary.length) ? summary : '-'
}

/**
 * Get Institution Payload
 * @param  {string[]} results
 * @returns  {string[]} {string[]}
 */
export const mapInstitutionsResult = (results = []) => {
  return results.map(item => ({
    //root
    _id: item.id,
    _score: item.score,
    _source: {
      address: item.entityConnection?.entity?.addressConnection?.items.map(adr => ({
        address1: adr.addressLine1,
        address2: adr.addressLine2,
        address3: adr.addressLine3,
        city: adr.city,
        country: adr.countryCode,
        country_name: adr.countryName,
        fax: adr.fax,
        hq: adr.hq,
        postal_code: adr.postalCode,
        region: adr.region,
        state_province: adr.stateProvinceCode,
        telephone: adr.phone
      })),
      institution_name: item.entityConnection?.institutionName,
      country: item.entityConnection?.country,
      factset_entity_id: item.entityConnection?.sourceId,
      monogo_id: item.id,
      _id: item.id,
      q4_entity_id: item.entityConnection?.entity?.id,
      equity_aum: item.entityConnection?.entity?.equityAUM,
      total_aum: item.entityConnection?.entity?.totalAUM,
      turnover: item.entityConnection?.entity?.turnover,
      style: item.entityConnection?.entity?.style,
      type: item.entityConnection?.entity?.institutionType
    },
    _type: "institution"
  }))
}

/**
 * @description - Creates an array of email addresses from the contacts data
 * @param  {object[]} contacts
 * @returns  {string[]}
 */
export const getEmailsForDefaultEmailClient = (contacts = []) => {
  const emails = contacts.reduce((accumulator, value) => {
    ;[]
      .concat(value.jobs || [])
      .forEach(
        job =>
          job && job.email && job.email.length && accumulator.push(job.email)
      )
    return accumulator
  }, [])

  return emails
}

/**
 * @description - Creates an array of recipients from the contacts data
 * @param  {object[]} contacts
 * @returns  {object[]}
 */
export const getRecipientsForCrmEmailClient = (contacts = []) => {
  const contactsWithValidEmail = contacts.filter((contact) => {
    return ((contact?.jobs?.length) && (contact?.jobs?.some((job) => job.email)) && (isValidEmail(contact?.jobs?.[0]?.email)))
  })

  const recipients = (contactsWithValidEmail || []).reduce((accumulator, contact) => {
    accumulator.push({
      firstName: contact?.first_name,
      lastName: contact?.last_name,
      organizationName: get(contact, 'jobs[0].institution_name', ''),
      email: contact?.jobs?.filter((job) => job?.email?.length).map((job) => job?.email)[0],
      contactId: contact._id,
    })
    return accumulator
  }, [])

  return recipients
}
