import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import './htmlEditor.component.css'

class HtmlEditor extends PureComponent {

  constructor (props) {
    super(props)

    const initialState = this.convertToDraft(props.html)
    this.state = {
      initialState,
      editorState: (props.html ? this.convertToDraft(props.html) : initialState),
      designMode: true
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { html } = this.props
    if (this.shouldClearHtml(prevProps.html, html)) {
      this.setState({
        editorState: this.convertToDraft(html),
      })
    }
  }

  shouldClearHtml (prevHtml, nextHtml) {
    return !nextHtml && prevHtml !== nextHtml
  }

  convertToDraft = (html) => {
    const blocksFromHtml = htmlToDraft(html)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
    return EditorState.createWithContent(contentState)
  }

  convertToHtml = (editorState) => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()))
  }

  onEditorStateChange = (editorState) => {
    if (this.state.designMode) {
      if (typeof (this.props.setDirty) === 'function') {
        const { entityMap, blockMap } = editorState._immutable.currentContent
        const initialEntityMap = this.state.initialState._immutable.currentContent.entityMap
        const initialBloackMap = this.state.initialState._immutable.currentContent.blockMap
        if (initialEntityMap !== entityMap || initialBloackMap !== blockMap) {
          this.props.setDirty()
        }
      }
      this.setState({ ...this.state, editorState })
    }
  }

  onHtmlChange = (e) => {
    if (!this.state.designMode) {
      const html = e.target.value
      this.props.onChange(html)
    }
  }

  enterDesignMode = () => {
    const editorState = this.convertToDraft(this.props.html)
    this.setState({ ...this.state, designMode: true, editorState })
  }

  enterHtmlMode = () => {
    this.setState({ ...this.state, designMode: false })
  }

  onChange = (rawDraftContentState) => {
    const { editorState } = this.state
    this.props.onChange({
      text: editorState.getCurrentContent().getPlainText(),
      html: draftToHtml(rawDraftContentState)
    })
  }

  getBlockStyle = (block) => {
    switch (block.getData().get('text-align')) {
      case 'left':
        return 'align-left';
      case 'center':
        return 'align-center';
      case 'right':
        return 'align-right';
      default:
        return null;
    }
  }

  render () {
    const { dataId, toolbar, toolbarHidden, editorRef, label, placeholder, customStyleMap, toolbarCustomButtons } = this.props
    const { editorState } = this.state

    return (
      <div data-id={dataId} className='editor'>
        {label && <label className='editor_label'>{label}</label>}
        <Editor
          customStyleMap={customStyleMap}
          blockStyleFn={this.getBlockStyle}
          onChange={this.onChange}
          editorRef={editorRef}
          editorState={editorState}
          wrapperClassName='editor_container'
          toolbarClassName='editor_toolbar'
          editorClassName='editor_design'
          onEditorStateChange={this.onEditorStateChange}
          toolbar={toolbar}
          toolbarCustomButtons={toolbarCustomButtons}
          toolbarHidden={toolbarHidden}
          placeholder={placeholder}
          stripPastedStyles
        />
      </div>
    )
  }
}

HtmlEditor.propTypes = {
  dataId: PropTypes.string,
  editorRef: PropTypes.func,
  label: PropTypes.string,
  html: PropTypes.string.isRequired,
  toolbar: PropTypes.object,
  toolbarHidden: PropTypes.bool,
  customActions: PropTypes.object,
  placeholder: PropTypes.string,
  setDirty: PropTypes.func,
  customStyleMap: PropTypes.object,
  toolbarCustomButtons: PropTypes.array,
  onChange: PropTypes.func.isRequired
}

HtmlEditor.defaultProps = {
  placeholder: 'ex. travel information, meeting details'
}

export default HtmlEditor
