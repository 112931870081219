import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'

// utils
import { THEMES } from '../../../../utils/ui'
import {
  chartTypeOptions,
  yAxisOptions,
  colorOptions,
  getCompleteSeries,
  getSeriesLabel
} from '../../../../utils/report'
import { get, isEqual } from 'lodash'

// components
import { Select } from '../../../../components'

// css
import './chartSeries.component.css'

/**
 * ReportSeriesConfig
 * @param chartType
 * @param series
 * @param onChange
 * @constructor
 */
function ReportChartSeriesConfig ({ chartType, series, onChange }) {
  const [selectedSeriesId, setSelectedSeriesId] = useState(null)

  const availableSeries = getCompleteSeries(series)
  const selectSeriesOptions = [].concat(getCompleteSeries(series) || [])
    .map((option) => {
      return {
        label: getSeriesLabel(option),
        value: option
      }
    })


  const selectStyleOptions = [].concat(chartTypeOptions || [])
    .filter((option) => option.availableInCombo)
    .map((option) => {
      return {
        label: option.label,
        value: option
      }
    })

  const selectColorOptions = [].concat((colorOptions || []))
    .map((option) => {
      return {
        label: <span className='report-series-config_swatch' style={{ backgroundColor: option }} />,
        value: option
      }
    })

  if (!availableSeries || !availableSeries.length) {
    return (
      <p>Series configuration is required.</p>
    )
  }

  const selectedSeries = series.find((seriesItem) => seriesItem._id === selectedSeriesId)

  const updateSeriesItem = (key, value) => {
    if (!key || !value) {
      return
    }

    const newSeries = (series || []).map((seriesItem) => {
      if (seriesItem._id === selectedSeries._id) {
        return {
          ...seriesItem,
          [key]: value
        }
      }

      return seriesItem
    })

    onChange && onChange(newSeries)
  }

  return (
    <section className='report-series-config'>
      <div className='field field--dropdown field--full'>
        <label className='field_label'>Select Series</label>
        <Select
          placeholder='Select a series'
          theme={THEMES.WHITE}
          options={selectSeriesOptions}
          noOptionsMessage='No Data Available'
          value={selectSeriesOptions.find((option) => isEqual(get(option, 'value'), selectedSeries))}
          onChange={(option) => get(option, 'value._id') && setSelectedSeriesId(get(option, 'value._id'))}
          searchable={false}
          clearable={false}
        />
      </div>
      {selectedSeries && (
        <div className='report-series-config_details'>
          <div className='field field--dropdown field--full'>
            {chartType === 'combo' && (
              <>
                <label className='field_label'>Style</label>
                <Select
                  placeholder='Select a chart style'
                  theme={THEMES.WHITE}
                  options={selectStyleOptions}
                  noOptionsMessage='No Data Available'
                  value={selectStyleOptions.find((option) => get(option, 'value.chartType') === selectedSeries.chartType)}
                  onChange={(option) => updateSeriesItem('chartType', get(option, 'value.chartType'))}
                  clearable={false}
                  searchable={false}
                />
              </>
            )}
          </div>
          <div className='field field--dropdown field--full'>
            <label className='field_label'>Axis</label>
            <Select
              placeholder='Select an axis'
              theme={THEMES.WHITE}
              options={yAxisOptions}
              value={yAxisOptions.find((option) => (option && option.value) === selectedSeries.yAxis)}
              onChange={(option) => updateSeriesItem('yAxis', get(option, 'value'))}
              clearable={false}
              searchable={false}
            />
          </div>
          <div className='field field--dropdown'>
            <label className='field_label'>Color</label>
            <Select
              className='report-series-config_colorpicker'
              placeholder='Select a color'
              theme={THEMES.WHITE}
              maxHeight={220}
              options={selectColorOptions}
              value={selectColorOptions.find((option) => (option && option.value) === selectedSeries.color)}
              onChange={(option) => updateSeriesItem('color', get(option, 'value'))}
              searchable={false}
              clearable={false}
            />
          </div>
        </div>
      )}
    </section>
  )
}

ReportChartSeriesConfig.propTypes = {
  chartType: PropTypes.string,
  series: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

ReportChartSeriesConfig.defaultProps = {
  chartType: 'combo',
  series: []
}

export default memo(ReportChartSeriesConfig)
