import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getBackgroundImage } from '../../../../utils/splashScreen.util'
import { THEMES, getQ4LogoIcon } from '../../../../utils/ui'
import { TextInput } from '../../../../components/shared/form'

import './passwordReset.component.scss'
import { Button } from '../../../../components'

const PasswordResetComponent = (props) => {
  const { errorMessage, isSubmitting, onSubmit } = props
  const password = useTextInput('')
  const confirmPassword = useTextInput('')
  const backgroundImage = useBackgroundImage()
  const validationMessage = (
    <span>Password must be a minimum of 10 characters, including at <br /> least 1 uppercase letter, 1 number, and 1 special character.</span>)
  const backgroundImageStyle = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : null
  }

  /**
   * Perform validation and submit
   */
  const handleSubmit = () => {
    onSubmit(password.value, confirmPassword.value)
  }

  /**
   *  Update Password Reset to responsive page
   */
  useEffect(() => {
    const appBodyContainer = document.getElementsByTagName('body')[0]
    appBodyContainer.classList.add('q4-wrapper--responsive')
    return () => {
      appBodyContainer.classList.remove('q4-wrapper--responsive')
    }
  })

  return (
    <div className='reset-password-page'>
      {backgroundImage && (
        <div className='reset-password-page_background-image q4-fade-in' style={backgroundImageStyle} />
      )}
      <div className='reset-password-page_content'>
        <i className={`reset-password-page_logo ${getQ4LogoIcon()}`} />
        <h1 className='reset-password-page_title'>Reset Password</h1>
        <div className='reset-password-page_rules-title'>{validationMessage}</div>
        {(errorMessage) && <div className='reset-password-page_error'>{errorMessage}</div>}
        <div className='reset-password-page_form'>
          <form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
            <TextInput
              className='reset-password-page_input'
              type='password'
              placeholder='New Password'
              autoComplete='false'
              error={errorMessage}
              theme={THEMES.WHITE}
              {...password}
            />
            <TextInput
              className='reset-password-page_input'
              type='password'
              placeholder='Confirm Password'
              autoComplete='false'
              error={errorMessage}
              theme={THEMES.WHITE}
              {...confirmPassword}
            />
            <Button
              className={`button button--q4-blue ${isSubmitting ? 'button--disabled' : ''}`}
              disabled={isSubmitting}
              label='Submit'
              tall
              onClick={handleSubmit}
            />
          </form>
        </div>
      </div>
    </div>)
}

PasswordResetComponent.propTypes = {
  isSubmitting: PropTypes.bool,
  errorMessage: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
}

export default PasswordResetComponent

/**
 * Text input generic hook
 * @param initialValue
 * @return {{onChange: *, value: *}}
 */
const useTextInput = (initialValue) => {
  const [value, setValue] = useState(initialValue)
  const handleChange = (e) => {
    setValue(e.target.value)
  }

  return {
    value,
    onChange: handleChange
  }
}

/**
 * Use hook to fetch background image
 * @return {Object}
 */
const useBackgroundImage = () => {
  const [backgroundImage, setBackgroundImage] = useState()

  useEffect(() => {
    const fetchImage = (src) => {
      // eslint-disable-next-line no-undef
      const image = new Image()
      image.src = src
      image.onload = () => {
        setBackgroundImage(src)
      }
    }
    fetchImage(getBackgroundImage())
  }, [])
  return backgroundImage
}
