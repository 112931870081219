import {
  CREATE_TOAST,
  DELETE_TOAST,
  statusType
} from '../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const toast = (state = initial, action) => {
  switch (action.type) {
    case CREATE_TOAST:
      return {
        type: action.type,
        data: [].concat(action.payload, state.data),
        status: statusType.SUCCESS
      }

    case DELETE_TOAST:
      const data = [].concat(state.data).filter((message) => message.id === action.payload)

      return {
        ...state,
        data,
        status: statusType.SUCCESS
      }

    default:
      return state
  }
}

export default toast
