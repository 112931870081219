import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import * as Yup from 'yup'

// components
import { Modal, TextField, TextArea, ComboBox } from '../../../components'

// utils
import { ENTITY_TYPE, THEMES } from '../../../utils'
import { capitalize, get } from 'lodash'
import Grid from '@material-ui/core/Grid'

const { CONTACT } = ENTITY_TYPE
const OPTIONS = [
  { value: 'Contact Missing', label: 'Contact Missing' },
  { value: 'Contact Movement', label: 'Contact Movement' },
  { value: 'Contact Profiled at Incorrect Firm', label: 'Contact Profiled at Incorrect Firm' },
  { value: 'Contact Promotion', label: 'Contact Promotion' },
  { value: 'Contact Coverage Change', label: 'Contact Coverage Change' },
  { value: 'Executive Appointment', label: 'Executive Appointment' },
  { value: 'Missing Email', label: 'Missing Email' },
  { value: 'Incorrect Email', label: 'Incorrect Email' },
  { value: 'Missing DL', label: 'Missing DL' },
  { value: 'Incorrect DL', label: 'Incorrect DL' },
  { value: 'Fund Management Change', label: 'Fund Management Change' },
  { value: 'Fund Launch', label: 'Fund Launch' },
  { value: 'Fund Merger', label: 'Fund Merger' },
  { value: 'Fund Liquidation', label: 'Fund Liquidation' },
  { value: 'Incorrect Title', label: 'Incorrect Title' },
  { value: 'Incorrect Function', label: 'Incorrect Function' }
]

const propTypes = {
  dataId: PropTypes.string,
  loading: PropTypes.bool,
  values: PropTypes.shape({
    entityType: PropTypes.string.isRequired,
    entityId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

const defaultProps = {
  loading: false,
  values: {}
}

/**
 * Entity Update Request Modal Component
 * @param props
 */
function UpdateRequestModal (props) {
  const { dataId, loading, errors, values, setFieldValue, handleChange, handleSubmit, onClose } = props
  const { entityType, name } = values

  const [changes, setChanges] = useState([])

  const isContact = (entityType === CONTACT)
  const onOptionChange = (changes) => {
    setChanges(changes)
    setFieldValue('changes', (changes || []).map((change) => change.value).join(', '))
  }
  const onOptionDelete = (value) => setChanges((changes || []).filter((change) => change.value !== value))

  return (
    <Modal
      dataId={`${dataId}Modal`}
      visible
      disableRestoreFocus
      loading={loading}
      scrollable={isContact}
      onClose={onClose}
      title={`Request ${capitalize(entityType)} Update`}
      footerButtons={[{
        dataId: `${dataId}Cancel`,
        label: 'Cancel',
        ui: 'shaded',
        onClick: onClose
      },
      {
        dataId: `${dataId}Save`,
        label: 'Send',
        ui: THEMES.CITRUS,
        onClick: handleSubmit
      }]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            dataId={`${dataId}Name`}
            name='name'
            label={`${capitalize(entityType)} Name`}
            value={name}
            readOnly
          />
        </Grid>

        {isContact ? <Grid item xs={12}>
          <ComboBox
            theme={THEMES.LIGHT_GREY}
            selectProps={{
              dataId: {
                menuId: `${dataId}TypeMenu`,
                inputId: `${dataId}TypeInput`
              },
              error: { isError: !!(errors.changes && errors.changes.length) },
              label: 'Change Type',
              placeholder: 'Select',
              value: changes,
              options: OPTIONS,
              clearable: false,
              isMulti: true,
              isDefaultList: true,
              closeMenuOnSelect: false,
              portal: true,
              required: true,
              onChange: onOptionChange
            }}
            removableListProps={{
              items: changes,
              onRemove: onOptionDelete
            }}
          />
        </Grid> : null}

        <Grid item xs={12}>
          <TextArea
            dataId={`${dataId}Note`}
            name='message'
            label='Notes'
            placeholder='Add additional notes'
            error={{ isError: !!(errors.message && errors.message.length) }}
            onChange={handleChange}
            required={!isContact}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}

UpdateRequestModal.propTypes = propTypes
UpdateRequestModal.defaultProps = defaultProps

export default withFormik({
  enableReinitialize: true,
  validateOnChange: true,
  validateOnBlur: false,
  mapPropsToValues: (props) => {
    const isContact = get(props, 'values.entityType') === CONTACT
    return {
      isContact,
      entityId: '',
      name: '',
      changes: '',
      message: '',
      ...props.values
    }
  },
  handleSubmit: (values, { props }) => {
    const { onSubmit } = props
    onSubmit && onSubmit(values)
  },
  validationSchema: Yup.object().shape({
    isContact: Yup.boolean(),
    changes: Yup.string().when('isContact', {
      is: true,
      then: Yup.string().required()
    }),
    message: Yup.string().when('isContact', {
      is: false,
      then: Yup.string().required()
    })
  })
})(UpdateRequestModal)
