export const CRM_EMAIL_APP_MOUNTED = 'CRM_EMAIL_APP_MOUNTED'
export const SET_RECIPIENTS = 'SET_RECIPIENTS'

/**
 * @description Set mounted state of CRM Email App in the store
 * @param {boolean} flag
 * @returns {function(): {type: string, payload: boolean}}
 */
const _setCrmEmailMounted = (flag) => ({
  type: CRM_EMAIL_APP_MOUNTED,
  payload: flag
})

/**
 * @description Set a list of email recipients in the store
 * @param {object[]} recipients
 * @returns {function(): {type: string, payload: object[]}}
 */
const _setEmailRecipients = (recipients) => ({
  type: SET_RECIPIENTS,
  payload: recipients
})

/**
 * @typedef {import('redux').Dispatch} Dispatch
 * @description Dispatch Redux Action _setCrmEmailMounted
 * @param {boolean} params
 * @returns {(dispatch: Dispatch): *}
 */
export const setCrmEmailMounted = (params) => (dispatch) => {
  return dispatch(_setCrmEmailMounted(params))
}

/**
 * @typedef {import('redux').Dispatch} Dispatch
 * @description Dispatch Redux Action _setEmailRecipients
 * @param {object[]} params
 * @returns {(dispatch: Dispatch): *}
 */
export const setEmailRecipients = (params) => (dispatch) => {
  return dispatch(_setEmailRecipients(params))
}
