import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

export const GET_PEER_ANALYSIS = gql`
  query PeerAnalysis(
    $tickerId: String
    $peers: [String]
    $entityId: String!
    $entityType: String!
    $metric: String!
    $page: Int!
    $limit: Int!
    $currencyCode: String
  ) {
    peerAnalysis(
      tickerId: $tickerId
      peerTickerId: $peers
      entityId: $entityId
      entityType: $entityType
      metric: $metric
      page: $page
      limit: $limit
      currencyCode: $currencyCode
    ) {
      items {
        metric
        entityId
        entityType
        securityName
        tickerId
        reportDate
        q0
        q1
        q2
        q3
        q4
      }
      count
    }
  }`

/**
 * Peer Analysis
 * @param options
 */
export function usePeerAnalysisQuery (options) {
  return useQuery(GET_PEER_ANALYSIS, options)
}
