import React, { memo } from 'react'
import PropTypes from 'prop-types'

// utils
import { defaultIfEmpty, getChangeClassName, getMarketChange, inMillions, getPercentage, MOVER_TYPE, getLocalizedCurrency } from '../../../../../utils'

const { BUY, SELL } = MOVER_TYPE
const EMPTY_PLACEHOLDER = '-'

const propTypes = {
  data: PropTypes.array.isRequired,
  type: PropTypes.string
}

const defaultProps = {
  data: []
}

/**
 * Top Industry Buys/Sells/Holdings
 * @param data
 * @param type
 */
function TopIndustryGrid ({ data, type }) {
  const isMover = [BUY, SELL].includes(type)
  return (
    <div className='tearsheet_grid'>
      <div className='tearsheet_grid-row tearsheet_grid-row--header'>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--text'>Security</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--narrow tearsheet_grid-cell--number'>PORT%</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--widest tearsheet_grid-cell--number'>VAL ({getLocalizedCurrency()})</div>
        {isMover && <div className='tearsheet_grid-cell tearsheet_grid-cell--wide tearsheet_grid-cell--number'>CHG ({getLocalizedCurrency()})</div>}
      </div>
      {(data.length ? data : [{}]).map((item, index) => {
        const { securityId, securityName, percentPortfolio, marketValue, marketValueChange } = (item || {})
        const change = getMarketChange(marketValueChange)
        return (
          <div className='tearsheet_grid-row' key={securityId || index}>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--text'>
              {defaultIfEmpty(securityName)}
            </div>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--narrow tearsheet_grid-cell--number'>
              {getPercentage(percentPortfolio)}
            </div>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--widest tearsheet_grid-cell--number'>
              {inMillions(marketValue, 2) || EMPTY_PLACEHOLDER}
            </div>
            {isMover && <div className={`tearsheet_grid-cell tearsheet_grid-cell--wide tearsheet_grid-cell--number ${getChangeClassName(change)}`}>
              {change}
            </div>}
          </div>
        )
      })}
    </div>
  )
}

TopIndustryGrid.propTypes = propTypes
TopIndustryGrid.defaultProps = defaultProps

export default memo(TopIndustryGrid)
