import gql from 'graphql-tag'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'

const GET_CONTACT = gql`
  query Contact(
    $id: [String]!
    $securityId: [String]!
  ) {
    contact(
      id: $id
      securityId: $securityId
    ) {
      items {
        id
        salutation
        suffixProf
        fullName
        nickname
        directPhone
        phoneExtension
        mobile
        email
        bio
        source
        status
        coverage
        jobs {
          entityId
          institutionName
          institutionType
          title
          phone
          directPhone
          email
          address1
          address2
          address3
          city
          countryName
          stateProvince
          postalCode
          functions
        }
        managedFunds {
          fundId
        }
        contactHoldingCurrentConnection(securityId: $securityId) {
          items {
            current
          }
        }
        favoriteConnection {
          items {
            id
            lists
          }
        }
        strategyConnection {
          items {
            id
            contactId
            entityId
            source
            summary
            country
            customRegion
            marketCap
            quality
            regionGroup
            sectors
            securityType
            strategy
            style
          }
        }
      }
    }
  }`

const GET_TARGET = gql`
  query Target($entityId: [String]!) {
    target(entityId: $entityId) {
      items {
        id
      }
    }
  }`

const GET_PIPELINE = gql`
  query Pipeline {
    pipeline {
      items {
        id
        index
        label
        lost
      }
    }
  }`

export const GET_DEALS = gql`
  query Deal(
    $entityId: String!
    $limit: Int! = ${10}
  ) {
    deal(
      entityId: $entityId
      limit: $limit
    ) {
      items {
        id
        title
        stage
      }
    }
  }`

/**
 * Contact by Id
 * @param options
 */
export function useContactQuery (options) {
  return useQuery(GET_CONTACT, options)
}

/**
 * Target
 * @param options
 */
export function useTargetQuery (options) {
  return useLazyQuery(GET_TARGET, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Pipeline
 * @param options
 */
export function usePipelineQuery (options) {
  return useQuery(GET_PIPELINE, options)
}

/**
 * Deal
 * @param options
 */
export function useDealQuery (options) {
  return useLazyQuery(GET_DEALS, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}
