import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

// actions
import { getContactResearch, clearContact, statusType } from '../../../../actions'

// components
import ContactResearchToolbar from './toolbar/toolbar.component'
import ContactResearchTable from './table/table.component'
import { getActiveTicker, getPageSizeFromStorage } from '../../../../utils'
import { get } from 'lodash'

const propTypes = {
  toolbarTheme: PropTypes.string,
  toolTheme: PropTypes.string,
  id: PropTypes.string.isRequired
}

const defaultProps = {}

const PAGE_SIZE_ID = 'contact-research-grid'

/**
 * Contact Research Grid Component
 * @param props
 */
function ContactResearchGrid (props) {
  const { toolbarTheme, toolTheme, id, securityId, research, getContactResearch, clearContact } = props
  const [state, setState] = useState({
    listOptions: {
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
  })

  const { listOptions } = state

  useEffect(() => {
    if (!id || !securityId) {
      return
    }

    getContactResearch({ securityId, contact: id, ...listOptions })

    return () => {
      clearContact()
    }
  }, [id, securityId, listOptions, getContactResearch, clearContact])

  const loading = get(research, 'status') === statusType.IN_PROGRESS
  const data = get(research, 'data', [])
  const total = get(research, 'total', 0)

  return (
    <>
      <ContactResearchToolbar
        toolbarTheme={toolbarTheme}
        toolTheme={toolTheme}
      />
      <ContactResearchTable
        pageSizeId={PAGE_SIZE_ID}
        loading={loading}
        data={data}
        total={total}
        listOptions={listOptions}
        onQueryChange={(query = {}) => setState(query)}
      />
    </>
  )
}

ContactResearchGrid.propTypes = propTypes
ContactResearchGrid.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)
  return {
    securityId: get(ticker, '_security'),
    research: get(state, 'contact.research')
  }
}

const mapDispatchToProps = (dispatch) => ({
  getContactResearch: bindActionCreators(getContactResearch, dispatch),
  clearContact: bindActionCreators(clearContact, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactResearchGrid)
