import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getClassName, THEMES } from '../../../utils'

import './checkbox.component.scss'

const propTypes = {
  dataId: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT, THEMES.WHITE, THEMES.Q4_BLUE]),
  size: PropTypes.oneOf(['medium', 'large']),
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

const defaultProps = {
  theme: THEMES.LIGHT,
  isSelected: false,
  disabled: false
}

/**
 * Checkbox Component
 * @param props
 */
function Checkbox (props) {
  const { dataId, className, theme, size, label, id, name, isSelected, onChange, disabled, inline } = props

  const baseClassName = getClassName('checkbox', [
    { condition: className, trueClassName: className },
    { condition: theme, trueClassName: `checkbox--${theme}` },
    { condition: size, trueClassName: `checkbox--${size}` },
    { condition: isSelected, trueClassName: 'checkbox--selected' },
    { condition: disabled, trueClassName: 'checkbox--disabled' },
    { condition: inline, trueClassName: 'checkbox--inline' }
  ])

  return (
    <div data-id={dataId} className={baseClassName}>
      <label className='checkbox_label'>
        <input
          className='checkbox_input'
          type='checkbox'
          id={id}
          name={name}
          checked={isSelected}
          onChange={(event => onChange(event.target.checked, event))}
          disabled={disabled}
        />
        {label}
      </label>
    </div>
  )
}

Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps

export default memo(Checkbox)
