import { CRM_EMAIL_ROUTE, CRM_MANAGE_EMAIL_ROUTE } from '../../utils/routes'

/**
 * Maps CRM Email routes with corresponding react route
 */
export const crmEmailRoutes = [
  { reactPath: CRM_EMAIL_ROUTE.EMAIL, crmEmailPath: '/email', fullscreen: false },
  { reactPath: CRM_EMAIL_ROUTE.MANAGE_EMAIL_CONNECTION, crmEmailPath: '/manage-email-connection', fullscreen: false },
]

/**
 * Maps CRM Manage Email routes with corresponding react route
 */
export const manageEmailRoutes = [
  { reactPath: CRM_MANAGE_EMAIL_ROUTE.MANAGE_CONNECTION, crmManageEmailPath: '/manage-email-connection', fullscreen: false }
]