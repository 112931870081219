import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { addQueryParams } from '../../../utils/http/http.util'

export const FETCH_UPCOMING_ACTIVITIES_REQUEST = 'FETCH_UPCOMING_ACTIVITIES_REQUEST'
export const FETCH_UPCOMING_ACTIVITIES_SUCCESS = 'FETCH_UPCOMING_ACTIVITIES_SUCCESS'
export const FETCH_UPCOMING_ACTIVITIES_ERROR = 'FETCH_UPCOMING_ACTIVITIES_ERROR'

export const FETCHING = 'FETCHING'
export const IDLE = 'IDLE'
export const FETCHED = 'FETCHED'
export const FAILED = 'FAILED'

const resources = {
  activities: '/crm/activity'
}

const _fetchUpcomingActivities = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_UPCOMING_ACTIVITIES_REQUEST,
      FETCH_UPCOMING_ACTIVITIES_SUCCESS,
      FETCH_UPCOMING_ACTIVITIES_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

export const fetchUpcomingActivities = (params) => {
  const url = addQueryParams(resources.activities, params)

  return (dispatch) => {
    return dispatch(_fetchUpcomingActivities(url))
  }
}
