import {
  GET_INSTITUTION_REQUEST,
  GET_INSTITUTION_SUCCESS,
  GET_INSTITUTION_FAILURE,
  RESET_INSTITUTION,
  statusType
} from '../../actions'

const initial = {
  _id: null,
  factsetId: null,
  entityId: null,
  status: statusType.IDLE
}

const detail = (state = initial, action) => {
  const { payload = {}, type } = action

  switch (type) {
    case GET_INSTITUTION_REQUEST:
      return {
        ...initial,
        status: statusType.IN_PROGRESS
      }

    case GET_INSTITUTION_SUCCESS:
      return {
        ...state,
        _id: payload._id || null,
        factsetId: payload.factset_entity_id || null,
        entityId: payload.q4_entity_id || null,
        status: statusType.SUCCESS
      }

    case GET_INSTITUTION_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case RESET_INSTITUTION:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default detail
