import React from 'react'
import EditorUrlSelector from './components/editorUrlSelector.component'
import './activityHtmlEditor.component.css'

/**
 * @param options {}
 */
const linkComponent = (props) => {
  const { link } = props.currentState
  const changeLink = (action, label, url, target) => {
    props.onChange(action, label, url, target)
  }

  return (
    <EditorUrlSelector
      value={link ? link.target || '' : ''}
      newTarget
      onChange={changeLink}
      onExpand={props.onExpandEvent}
      expandedState={props.expanded}
      currentState={props.currentState}
      disabled={false}
      icon={props.config.link.icon}
    />
  )
}

const buttonClass = 'editor-button'

export const toolbarOptions = {
  options: ['fontSize', 'inline', 'list', 'textAlign', 'link'],
  fontSize: {
    inDropdown: true,
    className: 'editor-fontsize',
    options: [11, 13, 15, 18, 24]
  },
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline'],
    bold: { className: `${buttonClass}--bold` },
    italic: { className: `${buttonClass}--italic` },
    underline: { className: `${buttonClass}--underline end` }
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
    unordered: { className: `${buttonClass}--bullet` },
    ordered: { className: `${buttonClass}--numbered end` }
  },
  textAlign: {
    inDropdown: false,
    options: ['left', 'center', 'right'],
    left: { className: `${buttonClass}--left` },
    center: { className: `${buttonClass}--center` },
    right: { className: `${buttonClass}--right end` }
  },
  link: {
    inDropdown: false,
    component: linkComponent
  }
}

export default toolbarOptions
