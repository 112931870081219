import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'


export const GET_INST_FUND_MANAGER = gql`
  query InstFundManager(
    $id: String!
    $limit: Int
    $page: Int
    $sortBy: String
    $sortDir: String
  ) {
    instFundManager(
        id: $id
        limit: $limit
        page: $page
        sortBy: $sortBy
        sortDir: $sortDir
      ) {
      items {
        id
        name
        currentAum        
        currentPeerHoldingsValues
        averageCurrentPeerHoldingsValue
        peerHoldingsChangePercent
      }
    }
  }`

/**
 * Get targeting search
 * @param options
 */
export function useInstFundManagerQuery (options) {
  return useQuery(GET_INST_FUND_MANAGER, options)
}
