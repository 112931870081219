import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getIconClass } from '../../../../../utils/report'

/**
 * Report FilterSummary Component
 * @param props
 * @returns {*}
 */
function FilterSummary (props) {
  const { entityType, fieldLabel, summaryLabel, isBeingRemoved, onToggle, onRemove } = props

  return (
    <div className='report-builder-filter_summary'>
      <span className='report-builder-filter_toggle' onClick={onToggle}>
        <i className='q4i-caret-sm-down-4pt' />
      </span>
      <div className='report-builder-filter_details' onClick={onToggle}>
        {entityType && (
          <span className={`report-builder-filter_type report-builder-filter_type--${entityType}`}>
            <i className={getIconClass(entityType)} />
          </span>
        )}
        <span className='report-builder-filter_field-label'>{fieldLabel}</span>
        <span className='report-builder-filter_value-label'>{summaryLabel}</span>
      </div>
      {onRemove && !isBeingRemoved && (
        <span className='report-builder-filter_close' onClick={onRemove}>
          <i className='q4i-close-4pt' />
        </span>
      )}
    </div>
  )
}

FilterSummary.propTypes = {
  entityType: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  summaryLabel: PropTypes.string.isRequired,
  isBeingRemoved: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default memo(FilterSummary)
