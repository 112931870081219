import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { addQueryParams } from '../../utils/http/http.util'

export const FETCH_ESTIMATES_CURRENT_RATING_REQUEST = 'FETCH_ESTIMATES_CURRENT_RATING_REQUEST'
export const FETCH_ESTIMATES_CURRENT_RATING_SUCCESS = 'FETCH_ESTIMATES_CURRENT_RATING_SUCCESS'
export const FETCH_ESTIMATES_CURRENT_RATING_ERROR = 'FETCH_ESTIMATES_CURRENT_RATING_ERROR'

const resources = {
  currentRating: '/estimates/v2/rating'
}

const _fetchCurrentRating = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_ESTIMATES_CURRENT_RATING_REQUEST,
      FETCH_ESTIMATES_CURRENT_RATING_SUCCESS,
      FETCH_ESTIMATES_CURRENT_RATING_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

export const fetchCurrentRating = (params) => (dispatch) => {
  const url = `${resources.currentRating}`
  return dispatch(_fetchCurrentRating(
    addQueryParams(url, params)
  ))
}
