import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { DOWNLOAD_API } from '../../middleware/download.middleware'

export const GET_ITINERARY_REQUEST = 'GET_ITINERARY_REQUEST'
export const GET_ITINERARY_SUCCESS = 'GET_ITINERARY_SUCCESS'
export const GET_ITINERARY_FAILURE = 'GET_ITINERARY_FAILURE'

export const GENERATE_ITINERARY_REQUEST = 'GENERATE_ITINERARY_REQUEST'
export const GENERATE_ITINERARY_SUCCESS = 'GENERATE_ITINERARY_SUCCESS'
export const GENERATE_ITINERARY_FAILURE = 'GENERATE_ITINERARY_FAILURE'

export const GET_ITINERARY_PDF_REQUEST = 'GET_ITINERARY_PDF_REQUEST'
export const GET_ITINERARY_PDF_SUCCESS = 'GET_ITINERARY_PDF_SUCCESS'
export const GET_ITINERARY_PDF_FAILURE = 'GET_ITINERARY_PDF_FAILURE'

export const RESET_ITINERARY = 'RESET_ITINERARY'

/**
 * Get Itinerary by activity id
 * @param activityId
 * @param params
 * @returns {{}}
 * @private
 */
const _getItinerary = (activityId, params) => ({
  [CALL_API]: {
    types: [
      GET_ITINERARY_REQUEST,
      GET_ITINERARY_SUCCESS,
      GET_ITINERARY_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/crm/activity/${activityId}/itinerary`,
    payload: params
  }
})

/**
 * Generate Itinerary PDF
 * @param activityId
 * @param params
 * @returns {{}}
 * @private
 */
const _generateItinerary = (activityId, params) => ({
  [DOWNLOAD_API]: {
    types: [
      GENERATE_ITINERARY_REQUEST,
      GENERATE_ITINERARY_SUCCESS,
      GENERATE_ITINERARY_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/crm/activity/${activityId}/itinerary/pdf`,
    options: {
      headers: {
        'Content-Type': 'application/pdf'
      }
    },
    payload: params
  }
})

/**
 * Get Itinerary PDF Data
 * @param activityId
 * @returns {{}}
 * @private
 */
const _getItineraryData = (activityId) => ({
  [CALL_API]: {
    types: [
      GET_ITINERARY_PDF_REQUEST,
      GET_ITINERARY_PDF_SUCCESS,
      GET_ITINERARY_PDF_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/crm/activity/${activityId}/itinerary/pdf/data`
  }
})

/**
 * Dispatch GET_ITINERARY_REQUEST
 */
export const getItinerary = (activityId, params) => (dispatch) => {
  return dispatch(_getItinerary(activityId, params))
}

/**
 * Dispatch GENERATE_ITINERARY_REQUEST
 */
export const generateItinerary = (activityId, params) => (dispatch) => {
  return dispatch(_generateItinerary(activityId, params))
}

/**
 * Dispatch GET_ITINERARY_PDF_REQUEST
 */
export const getItineraryData = (activityId) => (dispatch) => {
  return dispatch(_getItineraryData(activityId))
}

export const resetItinerary = () => (dispatch) => {
  return dispatch({ type: RESET_ITINERARY })
}
