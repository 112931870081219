import { get } from 'lodash'
import moment from 'moment-timezone'
import { isParent as _isParent } from '../../utils/activity'
import {
  DELETE_ACTIVITY_REQUEST,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAILURE,
  SAVE_ACTIVITY_REQUEST,
  SAVE_ACTIVITY_SUCCESS,
  SAVE_ACTIVITY_SUCCESS_BUILD_ITINERARY,
  SAVE_ACTIVITY_SUCCESS_ADD_ANOTHER,
  SAVE_ACTIVITY_FAILURE,
  RESET_ACTIVITY_FORM,
  SAVE_CONTACTS_FAILURE,
  SAVE_CONTACTS_SUCCESS,
  SAVE_CONTACTS_REQUEST,
  statusType
} from '../../actions'
import ActivityFormInitialValues from '../../components/activity/form/activityFormInitialValues.const'

const ERROR_ACTIVITY_SAVE = 'There was an issue when trying to save this Activity. Please try again or contact us if you see this message again.'
const ERROR_ACTIVITY_DELETE = 'There was an issue when trying to delete this Activity. Please try again or contact us if you see this message again.'

const initial = {
  data: {},
  isParent: false,
  parent: null,
  itinerary: null,
  deleted: false,
  deleteError: false,
  error: null,
  status: statusType.IDLE
}

const activityForm = (state = initial, action) => {
  switch (action.type) {
    case RESET_ACTIVITY_FORM:
      return initial

    case DELETE_ACTIVITY_REQUEST:
      return {
        ...state,
        error: initial.error,
        status: statusType.IN_PROGRESS
      }

    case DELETE_ACTIVITY_FAILURE:
      return {
        ...state,
        error: ERROR_ACTIVITY_DELETE,
        status: statusType.ERROR
      }

    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        status: statusType.SUCCESS,
        close: true,
        data: initial.data
      }

    case SAVE_ACTIVITY_FAILURE:
    case SAVE_CONTACTS_FAILURE:
      return {
        ...state,
        error: ERROR_ACTIVITY_SAVE,
        status: statusType.ERROR
      }

    case SAVE_ACTIVITY_REQUEST:
      return {
        ...state,
        data: get(action, 'payload') || initial.data,
        status: statusType.IN_PROGRESS
      }

    case SAVE_ACTIVITY_SUCCESS:
      let parent = {}

      if (!_isParent(get(action, 'payload.category')) && (state.parent || get(action, 'payload._activity'))) {
        parent = {
          _activity: {
            item: get(action, 'payload._activity.item._id') || get(state, 'parent._id'),
            type: get(action, 'payload._activity.type') || get(state, 'parent.category'),
            start: get(action, 'payload._activity.item.start') || get(state, 'parent.start'),
            end: get(action, 'payload._activity.item.end') || get(state, 'parent.end'),
            title: get(action, 'payload._activity.item.title') || get(state, 'parent.title')
          }
        }
      }

      return {
        ...state,
        error: initial.error,
        type: action.type,
        data: Object.assign({}, action.payload, parent),
        isParent: _isParent(get(action, 'payload.category')),
        parent: (_isParent(get(action, 'payload.category')) ? action.payload : state.parent),
        itinerary: state.itinerary || null,
        status: statusType.SUCCESS
      }

    case SAVE_ACTIVITY_SUCCESS_BUILD_ITINERARY:
      return {
        ...state,
        error: initial.error,
        type: action.type,
        data: Object.assign({}, ActivityFormInitialValues, {
          address: ((get(action, 'payload.address') || []).length === 1 ? get(action, 'payload.address') : []),
          virtual: get(action, 'payload.virtual'),
          suggestions: get(action, 'payload.address'),
          participants: get(action, 'payload.participants'),
          start: {
            date_time: moment(get(action, 'payload.start.date_time')).format(),
            timezone: get(action, 'payload.start.timezone')
          },
          end: {
            date_time: moment(get(action, 'payload.start.date_time')).add(30, 'minutes').format(),
            timezone: get(action, 'payload.end.timezone')
          },
          _activity: {
            item: get(action, 'payload._id'),
            type: get(action, 'payload.category'),
            address: get(action, 'payload.address'),
            virtual: get(action, 'parent.virtual'),
            start: get(action, 'payload.start'),
            end: get(action, 'payload.end'),
            title: get(action, 'payload.title')
          }
        }),
        isParent: false,
        parent: get(action, 'payload'),
        itinerary: [],
        status: statusType.SUCCESS
      }

    case SAVE_ACTIVITY_SUCCESS_ADD_ANOTHER:
      const itinerary = state.itinerary || []
      itinerary.push(action.payload)

      return {
        ...state,
        error: initial.error,
        type: action.type,
        data: Object.assign({}, ActivityFormInitialValues, {
          address: ((get(state, 'parent.address') || []).length === 1 ? get(state, 'parent.address') : []),
          virtual: get(state, 'parent.virtual'),
          suggestions: get(state, 'parent.address'),
          participants: get(state, 'parent.participants') || get(action, 'payload._activity.item.participants'),
          start: {
            date_time: get(itinerary[itinerary.length - 1], 'end.date_time'),
            timezone: get(action, 'payload.start.timezone')
          },
          end: {
            date_time: moment(get(itinerary[itinerary.length - 1], 'end.date_time')).add(30, 'minutes').format(),
            timezone: get(action, 'payload.end.timezone')
          },
          _activity: get(state, 'parent') ? {
            item: get(state, 'parent._id'),
            type: get(state, 'parent.category'),
            address: get(state, 'parent.address'),
            virtual: get(state, 'parent.virtual'),
            start: get(state, 'parent.start'),
            end: get(state, 'parent.end'),
            title: get(state, 'parent.title')
          } : {
            item: get(action, 'payload._activity.item._id'),
            type: get(action, 'payload._activity.item.category'),
            address: get(action, 'payload._activity.item.address'),
            virtual: get(action, 'payload.virtual'),
            start: get(action, 'payload._activity.item.start'),
            end: get(action, 'payload._activity.item.end'),
            title: get(action, 'payload._activity.item.title')
          }
        }),
        isParent: false,
        parent: state.parent || get(action, 'payload._activity.item'),
        itinerary,
        status: statusType.SUCCESS
      }
    case SAVE_CONTACTS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }
    
    case SAVE_CONTACTS_SUCCESS:
      return {
        ...state,
        status: statusType.SUCCESS
      }
    default:
      return state
  }
}

export default activityForm
