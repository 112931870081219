import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import {config} from '../../../config';
import {sendMailV1, SENT, FAILED} from '../../../actions/shared';
import { Message } from '../../../components';
import './widgetUnsubscribed.container.css';

class WidgetUnsubscribed extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            isMailSent: false
        };
    }

    /**
     * Send Mail Request
     */
    sendMail = () => {
        const {subscriptions, profile, sendMail} = this.props;
        const {isMailSent} = this.state;

        if (!profile || !profile.client || isMailSent) {
            return;
        }

        sendMail({
            to: config.widget.subscriptionInquiryList || [],
            subject: 'Feature Inquiry',
            body: [
                `User Name: ${profile.firstName} ${profile.lastName}`,
                `User Company: ${profile.client.Name}`,
                `User Email: ${profile.user}`,
                `Subscription Inquiry: ${subscriptions.join(', ')}`
            ].join('<br>')
        });

        this.setState({
            isModalOpen: true,
            isMailSent: true
        });
    };

    /**
     * Close Confirmation Modal
     */
    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    /**
     * Get Classes
     * @return {Array|*}
     */
    getClasses = () => {
        const {theme} = this.props;
        const classes = ['q4-fade-in widget-unsubscribed'];

        if (theme) {
            classes.push(`widget-unsubscribed--${theme}`);
        }

        return classes.join(' ');
    };

    render() {
        const {title, status} = this.props;
        const {isModalOpen, isMailSent} = this.state;
        const message = `Looks like you haven't subscribed to this feature. Interested?`;

        return (
            <div className={this.getClasses()}>
                <div className='widget-unsubscribed_indicator'>
                    <i className='q4i-lock-4pt'/>
                </div>
                <h3 className='widget-unsubscribed_warning'>SUBSCRIPTION REQUIRED</h3>
                <h2 className='widget-unsubscribed_title'>{title}</h2>
                <div className="widget-error_message">
                    <span>{message}</span>
                    <span className={`widget-unsubscribed_link ${isMailSent ? 'widget-unsubscribed_link--sent' : ''}`}
                          onClick={this.sendMail}>Learn More</span>
                </div>
                <Message
                    visible={isModalOpen && (status === SENT || status === FAILED)}
                    renderExitButton={true}
                    type={status === SENT ? null : 'error'}
                    title={status === SENT ? 'Message sent' : 'Failed to send'}
                    message={status === SENT ?
                        'Your request has been successfully submitted. A member of our team will be in touch shortly to discuss your inquiry.' :
                        'Oops, something went wrong while trying to send this request. Please try again or contact us if you see this message again.'
                    }
                    onClose={this.closeModal}
                    buttons={[{
                        ui: 'citrus',
                        label: 'close',
                        onClick: this.closeModal
                    }]}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.dashboard.dashboardState.theme,
        profile: state.shared.profile,
        status: state.shared.mail.status
    };
};

const mapDispatchToProps = (dispatch) => ({
    sendMail: bindActionCreators(sendMailV1, dispatch)
});

WidgetUnsubscribed.propTypes = {
    title: PropTypes.string.isRequired,
    subscriptions: PropTypes.array.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetUnsubscribed);
