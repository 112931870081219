import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { q4Blue } from '../../../resources/materialui-overrides/colors'
import ReportConfig from '../config/reportConfig.container'
import ReportSnapshot from '../snapshot/reportSnapshot.container'
import { Message, Modal } from '../../../components'
import { EditableText } from '../../../components/shared'
import {
  getAvailableEntityTypes,
  getAvailableFields,
  getAvailablePivotFields,
  getAvailableFilters,
  getAvailableIndices,
  getEntityQuartersMap,
  getFlattenedData,
  getIsPivotAvailable,
  parsePopulatedEntityTypes,
  parsePopulatedFields,
  parsePopulatedFilters,
  parsePopulatedIndices,
  parseXAxis,
  parseSeries,
  getCompleteSeries,
  getUnwindData
} from '../../../utils/report'
import {
  getReportEntityTypes,
  setReportDataConfig,
  setReportDataItemFetched,
  resetReportSnapshot,
  resetReportDataConfig,
  resetReportDataVisualization,
  getReportFields,
  resetReportDataItem,
  FETCHED,
  READY,
  getReportDataItem,
  storeReportDataVisualization,
  clearPeers
} from '../../../actions/report'
import ReportDataVisualizer from '../dataVisualizer/reportDataVisualizer.container'
import './reportDataEditor.container.css'

class ReportDataEditor extends Component {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      initialLoad: false,
      isReportOutdated: false
    }
  }

  /**
   * componentDidMount
   */
  componentDidMount () {
    const { reportId, report, getReportEntityTypes, getReportFields } = this.props

    // get prerequisite data for parsing, and then begin work on the report
    Promise.all([getReportEntityTypes(), getReportFields()]).then(() => {
      if (reportId) {
        this.fetchReportById(reportId)
      } else if (report && Object.keys(report).length) {
        this.initializeReport(report)
      } else {
        this.setupNewReport()
      }
    })
  }

  /**
   * Fetch existing report by id
   * @param reportId
   */
  fetchReportById = (reportId) => {
    this.props.getReportDataItem(reportId).then(() => {
      this.initializeReport(this.props.report)
    })
  }

  /**
   * Initialize ReportDataItem into working state and place update reducers
   * @param reportDataItem
   */
  initializeReport = (reportDataItem) => {
    const { reportEntityTypes, reportFields, subscriptionServices, setReportDataConfig, storeReportDataVisualization } = this.props
    const { title, _entityType, fields, filters, limit, sort, indices, widgetType, chartOptions, showTitle, isPivot, pivotFields, isLocked } = reportDataItem
    const { chartType, aggregationModel, xAxis, yAxis, series } = chartOptions || {}

    const parsedEntityTypes = parsePopulatedEntityTypes(reportEntityTypes, _entityType)
    const parsedFields = parsePopulatedFields(reportFields, fields)
    const parsedPivotFields = parsePopulatedFields(reportFields, pivotFields)
    const parsedFilters = parsePopulatedFilters(reportFields, filters)
    const parsedIndices = parsePopulatedIndices(indices)

    const isPivotAvailable = getIsPivotAvailable(parsedEntityTypes, parsedFields)
    const _isPivot = isPivotAvailable ? typeof isPivot === 'undefined' ? true : isPivot : false

    setReportDataConfig({
      title,
      entityType: parsedEntityTypes,
      fields: parsedFields,
      pivotFields: parsedPivotFields,
      filters: parsedFilters,
      limit,
      sort,
      indices: parsedIndices,
      isPivot: _isPivot,
      availableEntityTypes: getAvailableEntityTypes(reportEntityTypes, parsedEntityTypes, subscriptionServices),
      availableFields: getAvailableFields(reportFields, parsedEntityTypes, parsedFields),
      availablePivotFields: getAvailablePivotFields(parsedFields),
      availableFilters: getAvailableFilters(reportFields, parsedFields),
      availableIndices: getAvailableIndices(parsedIndices),
      _pivotQuarters: getEntityQuartersMap(parsedFilters, parsedEntityTypes),
      isLocked,
      status: READY
    })

    storeReportDataVisualization({
      widgetType: widgetType || 'table',
      chartOptions: {
        chartType,
        aggregationModel,
        yAxis,
        xAxis: parseXAxis(reportFields, xAxis),
        series: parseSeries(reportFields, series)
      },
      showTitle
    })
  }

  /**
   * Setup the configuration and state for a new report
   */
  setupNewReport = () => {
    const { report, reportEntityTypes, subscriptionServices, setReportDataConfig, setReportDataItemFetched } = this.props
    const { title, _entityType, limit, sort, indices, isLocked } = report

    const entityType = parsePopulatedEntityTypes(reportEntityTypes, getFlattenedData(_entityType))
    const availableEntityTypes = getAvailableEntityTypes(reportEntityTypes, entityType, subscriptionServices)

    setReportDataConfig({
      title: title || 'New Source Data',
      entityType,
      limit: limit || [],
      sort: sort || [],
      indices: [],
      availableEntityTypes,
      availableIndices: getAvailableIndices(indices),
      isLocked,
      status: READY
    })
    setReportDataItemFetched()
  }

  /**
   * Reset report snapshot and config
   */
  resetReport = () => {
    const {
      resetReportSnapshot,
      resetReportDataConfig,
      resetReportDataVisualization,
      resetReportDataItem,
      clearPeers
    } = this.props

    resetReportSnapshot()
    resetReportDataConfig()
    resetReportDataVisualization()
    resetReportDataItem()
    clearPeers()
  }

  /**
   * Handle ReportConfig onChange event
   * @param changeType
   * @param data
   * @param shouldFetchSnapshot
   */
  handleConfigChange = (changeType, data, shouldFetchSnapshot) => {
    this.setState({
      isReportOutdated: true
    })

    shouldFetchSnapshot && this.fetchSnapshot && this.fetchSnapshot()
  }

  /**
   * Handle item save
   * @param shouldCloseModal
   */
  handleSave = (shouldCloseModal) => {
    const { reportDataConfig, reportDataVisualization, reportSnapshot, reportSnapshotMeta, peers, onItemSave, report } = this.props
    const { title, entityType, fields, pivotFields, filters, sort, limit, indices, isPivot, isLocked } = reportDataConfig
    const { widgetType, chartOptions, showTitle } = reportDataVisualization

    const editItem = {
      _id: report._id,
      snapshot: {
        title,
        _entityType: entityType,
        fields,
        pivotFields,
        filters,
        sort,
        limit,
        peers,
        indices,
        isPivot,
        isLocked
      },
      meta: reportSnapshotMeta,
      widgetType,
      showTitle,
      chartOptions: {
        ...chartOptions,
        series: getCompleteSeries(chartOptions.series)
      },
      data: reportSnapshot,
      unwoundData: getUnwindData(reportSnapshot)
    }

    onItemSave && onItemSave(editItem, shouldCloseModal)

    this.setState({
      isReportOutdated: false
    })

    if (shouldCloseModal) {
      this.resetReport()
    }
  }

  /**
   * Fetch snapshot load
   */
  handleSnapshotFetched = () => {
    if (!this.state.initialLoad) {
      this.setState({
        initialLoad: true
      })
    }
  }

  /**
   * Handle closing of the modal
   */
  handleModalClose = () => {
    const { isReportOutdated } = this.state

    if (isReportOutdated) {
      return this.handleMessageOpen('discard')
    }

    this.resetReport()
    this.props.onModalClose()
  }

  /**
   * Handle Opening the Message component
   * Passed scope determines component params
   * @param scope
   */
  handleMessageOpen = (scope) => {
    this.setState({
      isMessageOpen: true,
      _messageScope: scope || 'discard'
    })
  }

  /**
   * Handle closing of the Message modal
   */
  handleMessageClose = () => {
    this.setState({
      isMessageOpen: false
    })
  }

  /**
   * Handle title submit
   * @param value
   */
  handleTitleSubmit = (value) => {
    const { reportDataConfig, setReportDataConfig } = this.props
    const { title } = reportDataConfig

    setReportDataConfig({
      title: value
    })

    if (!this.state.isReportOutdated) {
      this.setState({
        isReportOutdated: value !== title
      })
    }
  }

  /**
   * Handle visualization change
   */
  handleVisualizationChange = () => {
    if (!this.state.isReportOutdated) {
      this.setState({
        isReportOutdated: true
      })
    }
  }

  /**
   * Get action buttons
   * @param saveEnabled
   * @return {*[]}
   */
  getActionButtons = (saveEnabled) => {
    const { saveMode, isSaving } = this.props

    switch (saveMode) {
      case 'local':
        return [{
          label: 'Cancel',
          ui: 'ink',
          onClick: this.handleModalClose
        }, {
          label: 'Save',
          ui: 'citrus',
          disabled: !saveEnabled,
          onClick: () => {
            const shouldCloseModal = true
            this.handleSave(shouldCloseModal)
          }
        }]
      case 'api':
      default:
        return [{
          label: 'Cancel',
          ui: 'ink',
          onClick: this.handleModalClose
        }, {
          label: 'Save',
          ui: 'citrus',
          disabled: !saveEnabled,
          loading: isSaving,
          onClick: () => {
            const shouldCloseModal = false
            this.handleSave(shouldCloseModal)
          }
        }, {
          label: 'Save and Close',
          ui: 'citrus',
          disabled: !saveEnabled,
          onClick: () => {
            const shouldCloseModal = true
            this.handleSave(shouldCloseModal)
          }
        }]
    }
  }

  /**
   * Get props for Message component based on scope
   * @param scope
   * @returns {*}
   */
  getMessageProps = (scope) => {
    switch (scope) {
      case 'discard':
      default:
        const { onModalClose } = this.props

        return {
          type: 'warning',
          title: 'Discard Changes?',
          message: 'Are you sure you want to discard your changes? Any unsaved changes will be lost.',
          buttons: [{
            ui: 'shaded',
            label: 'cancel',
            onClick: this.handleMessageClose
          }, {
            ui: 'spice',
            label: 'confirm',
            onClick: () => {
              this.resetReport()
              onModalClose && onModalClose()
            }
          }]
        }

    }
  }

  /**
   * Render the container
   * @return {*}
   */
  render () {
    const { reportDataConfig, reportStatus, reportDataConfigStatus, reportSnapshotStatus } = this.props
    const { initialLoad, isMessageOpen, _messageScope } = this.state
    const { title, isLocked } = reportDataConfig

    const reportLoad = reportStatus === FETCHED && initialLoad
    const configLoad = reportDataConfigStatus === READY
    const saveEnabled = reportLoad && reportSnapshotStatus === FETCHED
    const headerActionButtons = this.getActionButtons(saveEnabled)
    const messageProps = this.getMessageProps(_messageScope)

    return (
      <Modal
        visible={true}
        loading={!reportLoad || !configLoad}
        spinnerProps={{ theme: 'white', includeMessage: true, maskColor: q4Blue, maskOpacity: 1 }}
        fullscreen={true}
        disableEscapeKeyDown={true}
        disableRestoreFocus={true}
        badge='q4i-chart-2pt'
        title={(
          <EditableText
            theme='white'
            invisible={true}
            inputHeight={26}
            value={title}
            minLength={1}
            shouldDisposeValue={title === 'New Source Data'}
            onSubmit={this.handleTitleSubmit}
          />
        )}
        headerButtons={headerActionButtons}
        closeIcon={false}
        contentPadding='0px'
        onClose={this.handleModalClose}
      >
        {configLoad && (
          <div className='report-data-editor'>
            <section className='report-data-editor_visualizer'>
              <ReportDataVisualizer
                title={title}
                onChange={this.handleVisualizationChange}
              />
            </section>
            <section className='report-data-editor_builder'>
              <ReportConfig
                onChange={this.handleConfigChange}
                isLocked={isLocked}
              />
              <div className='report-data-editor_snapshot'>
                <ReportSnapshot
                  reportTitle={title}
                  setFetchSnapshot={(fetchSnapshotFunc) => this.fetchSnapshot = fetchSnapshotFunc}
                  onSnapshotFetched={this.handleSnapshotFetched}
                />
              </div>
            </section>
          </div>
        )}

        <Message
          visible={isMessageOpen}
          type={messageProps.type}
          title={messageProps.title}
          message={messageProps.message}
          buttons={messageProps.buttons}
          onClose={this.handleMessageClose}
        />
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const reportState = state.report
  const snapshot = reportState.reportSnapshot
  const { reportDataItem, reportDataConfig, reportDataVisualization, reportPeerConfig, reportFields } = reportState
  const { data, status } = reportDataItem
  const profileData = state.profile && state.profile.data
  const services = (profileData.services || []).reduce((acc, each) => {
    acc[each.type] = each.enabled
    return acc
  }, {})

  return {
    report: data,
    subscriptionServices: services,
    reportEntityTypes: reportState.reportEntityTypes.data || [],
    reportFields: reportFields.data || [],
    reportDataConfig,
    reportDataConfigStatus: reportDataConfig.status,
    reportDataVisualization,
    reportSnapshot: snapshot.data || [],
    reportSnapshotMeta: snapshot.meta,
    reportSnapshotStatus: snapshot.status,
    reportStatus: status,
    peers: reportPeerConfig.peers || []
  }
}

const mapDispatchToProps = (dispatch) => ({
  getReportDataItem: bindActionCreators(getReportDataItem, dispatch),
  setReportDataConfig: bindActionCreators(setReportDataConfig, dispatch),
  getReportEntityTypes: bindActionCreators(getReportEntityTypes, dispatch),
  getReportFields: bindActionCreators(getReportFields, dispatch),
  setReportDataItemFetched: bindActionCreators(setReportDataItemFetched, dispatch),
  resetReportSnapshot: bindActionCreators(resetReportSnapshot, dispatch),
  resetReportDataConfig: bindActionCreators(resetReportDataConfig, dispatch),
  resetReportDataVisualization: bindActionCreators(resetReportDataVisualization, dispatch),
  resetReportDataItem: bindActionCreators(resetReportDataItem, dispatch),
  storeReportDataVisualization: bindActionCreators(storeReportDataVisualization, dispatch),
  clearPeers: bindActionCreators(clearPeers, dispatch)
})

ReportDataEditor.propTypes = {
  onModalClose: PropTypes.func,
  onItemSave: PropTypes.func.isRequired,
  saveMode: PropTypes.oneOf(['local', 'api']).isRequired,
  reportId: PropTypes.string,

  /**
   * If parent container performs the saving to API - notify via props
   */
  isSaving: PropTypes.bool
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportDataEditor))
