import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { debounce, throttle, isEqual, uniqBy, get, flatten, isEmpty } from 'lodash'
import { generate } from 'shortid'
import { getActiveTicker } from '../../../utils'
import {
  getDefaultLimitValue,
  getFlattenedData,
  getFormattedFilters,
  getIsPrimaryField,
  getFormattedSort,
  getFormattedIndices,
  getConfigRows,
  getHeaderString,
  getHeaderRows,
  getLastRevisionData,
  getAutoGroupColumnDef,
  getFormattedColumns,
  processPivotHeaders,
  processPivotGroupHeaders,
  getIsGroupedMode,
  processExportedCell,
  getOverwrittenData,
  getNoRowsOverlayParams,
  emojiStrip,
  stockEntityType,
  getIsReportType
} from '../../../utils/report'
import { FETCHED, FETCHING, IDLE, FAILED, getReportSnapshot, setReportDataConfig } from '../../../actions/report'
import { AgGrid, PopoverMenu, Button, NoContentMessage, SectionHeader, Spinner } from '../../../components'
import { SectionSearch } from '../../../components/shared'
import './reportSnapshot.container.css'

class ReportSnapshotContainer extends Component {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      isPopoverOpen: false,
      columnDefs: [],
      rowData: [],
      rowCount: getDefaultLimitValue(),
      drawId: generate()
    }

    this.fetchSnapshot = debounce(this.fetchSnapshot, 1500)
    this.redrawGrid = throttle(this.redrawGrid, 500)

    this.exportButton = React.createRef()
    this.gridSettingsButton = React.createRef()
  }

  /**
   * ComponentDidMount
   */
  componentDidMount () {
    this.props.setFetchSnapshot && this.props.setFetchSnapshot(this.fetchSnapshot)

    const { reportSnapshotStatus, onSnapshotFetched } = this.props

    if (reportSnapshotStatus === FETCHED) {
      onSnapshotFetched && onSnapshotFetched()
    }
  }

  /**
   * Limit re-rendering component to only when data has changed
   * Classic PureComponent comparison logic with a bit of flavour for eliminating ag-grid rendering
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate (nextProps, nextState) {
    const { reportDataConfig, reportSnapshotStatus, reportSnapshotId } = this.props
    const { isPivot, pivotFields } = reportDataConfig
    const { isPopoverOpen, drawId } = this.state

    let shouldComponentUpdate = !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)

    if (shouldComponentUpdate) {
      shouldComponentUpdate = (
        (!reportDataConfig.fields.length && !!nextProps.reportDataConfig.fields.length) ||
        (!isEqual(pivotFields, nextProps.reportDataConfig.pivotFields)) ||
        (isPivot !== nextProps.reportDataConfig.isPivot) ||
        (reportSnapshotStatus !== nextProps.reportSnapshotStatus) ||
        (reportSnapshotId !== nextProps.reportSnapshotId) ||
        (drawId !== nextState.drawId) ||
        (isPopoverOpen !== nextState.isPopoverOpen)
      )
    }

    return shouldComponentUpdate
  }

  /**
   * ComponentDidUpdate
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    const { reportSnapshotId, reportDataConfig } = this.props
    const { entityType, fields, pivotFields, isPivot } = reportDataConfig

    if (!entityType || !entityType.length) {
      this.agGrid && this.agGrid.api.setColumnDefs([])
    }

    if (!entityType || !entityType.length || !fields || !fields.length) {
      this.agGrid && this.agGrid.api.setRowData([])
    }

    if (
      (reportSnapshotId !== prevProps.reportSnapshotId) ||
      (isPivot !== prevProps.reportDataConfig.isPivot) ||
      (!isEqual(pivotFields, prevProps.reportDataConfig.pivotFields))
    ) {
      this.redrawGrid()
    }
  }

  /**
   * ComponentWillUnmount
   */
  componentWillUnmount () {
    this.fetchSnapshot.cancel()
    this.redrawGrid.cancel()
  }

  /**
   * Fetch a new Report Snapshot
   * @param options
   */
  fetchSnapshot = (options) => {
    const { getReportSnapshot, onSnapshotFetched } = this.props

    const body = this.getSnapshotBody()
    const params = this.getSnapshotParams(options)

    // need to notify the parent component to remove the spinner
    if (!body || !body._entityType || !body._entityType.length) {
      onSnapshotFetched && onSnapshotFetched()
    } else {
      getReportSnapshot(body, params).then(() => {
        this.redrawGrid()
        onSnapshotFetched && onSnapshotFetched()
      })
    }
  }

  /**
   * Re-calculate rows and columns and re-draw grid
   */
  redrawGrid = () => {
    const { reportSnapshot, reportDataConfig } = this.props
    const { entityType, fields, limit, isPivot } = reportDataConfig

    const columnDefs = getFormattedColumns(reportDataConfig)
    const rowData = getOverwrittenData(reportSnapshot, fields)

    const entityTypeIds = getFlattenedData(entityType)
    const autoGroupColumnDef = getAutoGroupColumnDef(fields, entityType)
    const isGroupedMode = getIsGroupedMode(entityType, fields)
    const isPinned = columnDefs.find((column) => column.rowGroup)

    const newState = {
      isPinned,
      isGroupedMode,
      autoGroupColumnDef,
      rowCount: this.getRowCountFromLimit(limit, entityTypeIds),
      drawId: generate()
    }

    this.setState(newState, () => {
      if (this.agGrid) {
        this.agGrid.api.setColumnDefs(columnDefs)
        this.agGrid.api.setRowData(rowData)
        this.agGrid.columnApi.setPivotMode(isPivot)
        this.handleSizeColumnsToFit(this.agGrid)
      }
    })
  }

  /**
   * Get Snapshot request body
   * @returns {{entityTypes, fields, filters}}
   */
  getSnapshotBody = () => {
    const { entityType, fields, filters, sort, limit, indices, isPivot, isLocked, pivotFields } = this.props.reportDataConfig

    const snapshotBody = {
      _entityType: getFlattenedData(entityType),
      pivotFields: getFlattenedData(pivotFields),
      fields: getFlattenedData(fields),
      filters: getFormattedFilters(filters),
      sort: getFormattedSort(sort, fields),
      peers: getFlattenedData(this.props.peers, '_security'),
      indices: getFormattedIndices(indices),
      isPivot
    }

    if (!isLocked) {
      snapshotBody.limit = limit
    }

    return snapshotBody
  }

  /**
   * Get Snapshot request query params
   * @param options
   */
  getSnapshotParams = (options) => {
    const { securityId } = this.props

    return {
      securityId,
      ...options
    }
  }

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   */
  handleGridReady = (grid) => {
    const { reportSnapshotStatus } = this.props

    this.agGrid = grid

    if (reportSnapshotStatus === FETCHED) {
      this.redrawGrid()
    } else if (reportSnapshotStatus === IDLE) {
      this.fetchSnapshot()
    }
  }

  /**
   * Set the column being moved inside agGrid
   * @param grid
   */
  handleColumnMoved = (grid) => {
    if (!this.agGrid) {
      return
    }

    // columns and toIndex only exist as part of agGrid's onColumnMoved
    // to avoid to many re-renders magic happens after onDragEnd
    this.agGrid.columns = grid.columns
    this.agGrid.toIndex = grid.toIndex
  }

  /**
   * Handle AgGrid column drag (on release)
   * @param grid
   */
  handleDragStopped = (grid) => {
    const api = grid && grid.columnApi
    const { reportDataConfig, setReportDataConfig } = this.props
    const { fields, isPivot } = reportDataConfig
    let newFields

    if (!api || !this.agGrid || !this.agGrid.columns || !this.agGrid.columns.length) {
      return
    }

    const currentOrder = api.getAllGridColumns() // the current order of the columns based off agGrid
    const movedColumn = currentOrder[this.agGrid.toIndex] // the column that moved

    if (isPivot) {
      const pivotOrder = movedColumn.parent.children.map((column) => column.colDef)
      const pivotIds = (pivotOrder || []).reduce((_map, item) => {
        _map.push(get(item, 'pivotValueColumn.colDef.field'))
        return _map
      }, [])

      newFields = fields.filter((each) => {
        return pivotIds.indexOf(each.name) < 0
      })

      const newPivotsOrdered = pivotIds.map((eachName) => {
        return fields.filter((eachField) => {
          return eachField.name === eachName
        })
      })

      newFields = newFields.concat(flatten(newPivotsOrdered.filter((each) => !isEmpty(each))))
    } else {
      const mappedOrder = uniqBy(currentOrder.map((column) => get(column, 'colDef.field')))
      const _grouped = currentOrder.filter((column) => get(column, 'colDef.rowGroup'))
        .map((column) => get(column, 'colDef.field'))

      const groupedFields = fields.filter((eachField) => {
        return _grouped.indexOf(eachField.name) >= 0
      })

      newFields = flatten(mappedOrder.map((eachName) => {
        return fields.filter((eachField) => {
          return eachField.name === eachName && _grouped.indexOf(eachField.name) < 0
        })
      }))

      newFields = groupedFields.concat(newFields)
    }

    setReportDataConfig({
      fields: newFields
    }).then(this.redrawGrid)
  }

  /**
   * Handle auto sizing all of Ag-grid's columns
   * @param grid
   */
  handleSizeColumnsToFit = (grid) => {
    const { reportSnapshot } = this.props

    if (!grid || !reportSnapshot || !reportSnapshot.length) {
      return
    }

    grid.api.sizeColumnsToFit()
  }

  /**
   * Handle collapsing of all Ag-grid's row groups
   * @param grid
   */
  handleCollapseAllGroups = (grid) => {
    grid && grid.api.collapseAll()
  }

  /**
   * Handle expanding of all Ag-grid's row groups
   * @param grid
   */
  handleExpandAllGroups = (grid) => {
    grid && grid.api.expandAll()
  }

  /**
   *
   * Get rowCount from limit of primary entityType
   * @param limit
   * @param entityTypeIds
   * @returns {number}
   */
  getRowCountFromLimit = (limit, entityTypeIds) => {
    const defaultRowCount = getDefaultLimitValue()

    if (!limit || !limit.length || !entityTypeIds || !entityTypeIds.length) {
      return defaultRowCount
    }

    const primaryEntityType = entityTypeIds[0]
    const limitItem = limit.find((limitItem) => limitItem._entityType === primaryEntityType)

    return (limitItem && limitItem.value) || defaultRowCount
  }

  /**
   * Parse column id
   * @param item
   * @return {*}
   */
  getColId = (item) => {
    if (!item || !item.colId) {
      return
    }

    if (item.colId.search(/.+_\d/) > -1) {
      return item.colId.substr(0, item.colId.lastIndexOf('_'))
    } else {
      return item.colId
    }
  }

  /**
   * Get formatted sort items from ag-grid's sortModel
   * @param sortModel {Array}
   * @param secondaryColumns {Array}
   * @param topColumns {Array}
   * @param fields {Array}
   */
  getFormattedSortItems = (sortModel, secondaryColumns, topColumns, fields) => {
    if (!sortModel || !sortModel.length) {
      return
    }

    const sortItems = [];

    (sortModel || []).forEach((item) => {

      if (item.colId.indexOf('AutoColumn') > -1) {
        const autoGroupColumns = (topColumns || []).filter((column) => column.rowGroupActive)

        if (autoGroupColumns && autoGroupColumns.length) {
          autoGroupColumns.forEach((autoGroupColumn) => {
            const sortedColumnDefId = this.getColId(autoGroupColumn)
            const sortedField = (fields || []).find((field) => field.name === sortedColumnDefId)

            if (sortedField) {
              const autoGroupColumn = {
                _field: sortedField._id,
                direction: item.sort
              }

              sortItems.push(autoGroupColumn)
            }
          })
        }
      } else if (item.colId.indexOf('pivot_') > -1) {
        const sortedPivotColumn = (secondaryColumns || []).find((column) => column.colId === sortModel[0].colId)

        if (sortedPivotColumn) {
          const sortedColumnDefId = this.getColId(sortedPivotColumn.colDef.pivotValueColumn)

          const allFields = (fields || []).filter((field) => field.name === sortedColumnDefId)

          allFields.forEach((field) => {

            const pivotColumn = {
              _field: field._id,
              direction: item.sort
            }

            if (field.name.indexOf('holdings.') > -1) {
              pivotColumn.quarter = moment(sortedPivotColumn.colDef.pivotKeys[0]).utc().format('YYYYMMDD')
            }

            sortItems.push(pivotColumn)
          })
        }
      } else {

        let colDetails = this.getColId(item)

        const field = fields.find((field) => field.name === colDetails)

        if (field) {
          const column = {}
          column._field = field._id
          column.direction = item.sort
          sortItems.push(column)
        }
      }
    })

    return sortItems
  }

  /**
   * Handle setting of the new the sort state
   * @param sort
   */
  handleSortSet = (sort) => {
    this.props.setReportDataConfig({
      sort
    }).then(this.fetchSnapshot)
  }

  /**
   * Handle AgGrid onSortChanged event
   * @param grid
   */
  handleSortChanged = (grid) => {
    const { entityType, fields, sort } = this.props.reportDataConfig
    const api = grid && grid.api

    if (!api) {
      return
    }

    const newSort = [...(sort || [])]
    const sortModel = api.sortController.getSortModel()
    const secondaryColumns = grid.columnApi.getSecondaryColumns()
    const topColumns = grid.columnApi.getAllColumns()

    const newSortItems = this.getFormattedSortItems(sortModel, secondaryColumns, topColumns, fields)

    if (!newSortItems || !newSortItems.length) {
      return
    }

    if (newSortItems.length > 1 || !newSort.length) {
      this.handleSortSet(newSortItems)
    } else {
      const newSortItem = newSortItems[0]
      const isPrimaryField = getIsPrimaryField(entityType, fields, newSortItem._field)
      const matchedIndex = newSort.findIndex((sortItem) => getIsPrimaryField(entityType, fields, sortItem._field) === isPrimaryField)

      if (matchedIndex > -1) {
        newSort.splice(matchedIndex, 1)
      }

      newSort.push(newSortItem)
      this.handleSortSet(newSort)
    }
  }

  /**
   * Handle opening of Generate button Popover
   */
  handleExportPopoverOpen = () => {
    this.setState({
      isPopoverOpen: true,
      _popoverScope: 'export'
    })
  }

  /**
   * Handle opening of Settings Popover
   */
  handleSettingsPopoverOpen = () => {
    this.setState({
      isPopoverOpen: true,
      _popoverScope: 'gridSettings'
    })
  }

  /**
   * Handle closing of Generate button Popover
   */
  handlePopoverClose = () => {
    this.setState({
      isPopoverOpen: false
    })
  }

  /**
   * Handle AgGrid filtering clear
   */
  handleQueryClear = () => {
    if (!this.agGrid) {
      return
    }

    this.agGrid.api.setQuickFilter('')
  }

  /**
   * Handle AgGrid filtering
   * @param query
   */
  handleQueryChange = (query) => {
    if (!this.agGrid) {
      return
    }

    this.agGrid.api.setQuickFilter(query)
  }

  /**
   * Handle report export
   * @param event
   * @param option
   */
  handleExport = (event, option) => {
    const { reportDataConfig, reportRevisions, reportTitle } = this.props
    const { entityType, filters, sort } = reportDataConfig
    const { columnDefs } = this.state

    if (!option || !option.type || !columnDefs) {
      return
    }

    // remove emoji and restricted characters including dots, everything after dot is treating as extension
    const title = emojiStrip(reportTitle).trim().replace(/[\W_]+/g, '_')
    const fileName = `${title} ${moment().format('DD-MM-YYYY')}`

    // Determine Revision and Column Count For Header and Footer content
    const lastRevision = getLastRevisionData(reportRevisions)
    const displayedColumns = this.agGrid && this.agGrid.columnApi && this.agGrid.columnApi.getAllDisplayedColumns()
    const columnCount = (displayedColumns && displayedColumns.length) || 1
    const reportFields = reportDataConfig.fields

    const defaultOptions = {
      columnGroups: true,
      allColumns: true,
      fileName,
      processCellCallback: processExportedCell
    }

    switch (option.type) {
      case 'csv':
        this.agGrid.api.exportDataAsCsv({
          ...defaultOptions,
          customHeader: getHeaderString(columnCount, title, lastRevision, reportFields, entityType, filters, sort)
        })
        break
      case 'xlsx':
      default:
        this.agGrid.api.exportDataAsExcel({
          ...defaultOptions,
          sheetName: 'Sheet1',
          customHeader: [
            ...getHeaderRows(columnCount, title, lastRevision),
            ...getConfigRows(columnCount, reportFields, entityType, filters, sort)
          ]
        })
    }

    this.handlePopoverClose()
  }

  /**
   * Handle Grid Settings - Currently this only deals with display density
   * @param event
   * @param option
   */
  handleGridSettings = (event, option) => {
    if (!option || !option.type) {
      return this.handlePopoverClose()
    }

    switch (option.type) {
      case 'expand':
        this.handleExpandAllGroups(this.agGrid)
        break
      case 'collapse':
        this.handleCollapseAllGroups(this.agGrid)
        break
      case 'autosize':
      default:
        break
    }

    this.handleSizeColumnsToFit(this.agGrid)
    this.handlePopoverClose()
  }

  /**
   * Get props for the NoRowsOverlay component
   */
  _getNoRowsOverlayParams = () => {
    const { reportDataConfig, reportSnapshotStatus } = this.props
    const { fields } = reportDataConfig

    return getNoRowsOverlayParams(fields, reportSnapshotStatus, this.fetchSnapshot)
  }

  /**
   * Get props for PopoverMenu component based on scope
   * @param scope
   * @returns {*}
   */
  getPopoverProps = (scope) => {
    switch (scope) {
      case 'export':
        return {
          anchorEl: this.exportButton && this.exportButton.current,
          items: [
            { icon: 'q4i-table-4pt', label: 'CSV', type: 'csv' },
            { icon: 'q4i-xls-4pt', label: 'Excel', type: 'xlsx' }
          ],
          onClick: this.handleExport
        }
      case 'gridSettings':
      default:
        const { isGroupedMode } = this.state

        const items = [
          { icon: 'q4i-sync-4pt', label: 'Autosize Columns', type: 'autosize' }
        ]

        if (isGroupedMode) {
          items.unshift(
            { icon: 'q4i-fullscreen-4pt', label: 'Expand Row Groups', type: 'expand' },
            { icon: 'q4i-fullscreen-exit-4pt', label: 'Collapse Row Groups', type: 'collapse' }
          )
        }

        return {
          anchorEl: this.gridSettingsButton && this.gridSettingsButton.current,
          items,
          onClick: this.handleGridSettings
        }
    }
  }

  /**
   * Render Results title based off of ReportSnapshotTotal
   * @param noData
   * @returns {XML}
   */
  renderHeaderTitle = (noData) => {
    const { reportSnapshotTotal, reportDataConfig } = this.props
    let { rowCount } = this.state
    const { isLocked } = reportDataConfig

    if (rowCount > reportSnapshotTotal || isLocked) {
      rowCount = reportSnapshotTotal
    }

    let resultsCountText = `${rowCount} of ${reportSnapshotTotal}`
    const isStockReport = getIsReportType(reportDataConfig.entityType, stockEntityType)

    if (isStockReport) {
      resultsCountText = reportSnapshotTotal
    }

    return (
      <>
        <span className='report-snapshot_results-label'>Results</span>
        {(!noData && reportSnapshotTotal > 0) && (
          <span className='report-snapshot_results-count'>({resultsCountText})</span>
        )}
      </>
    )
  }

  /**
   * Render Snapshot
   * @returns {XML}
   */
  render () {
    const { reportSnapshot, reportSnapshotStatus, reportDataConfig } = this.props
    const { autoGroupColumnDef, isPinned, isPopoverOpen, _popoverScope } = this.state
    const { fields } = reportDataConfig

    const noData = !reportSnapshot || !reportSnapshot.length || !fields || !fields.length || reportSnapshotStatus === FAILED
    const popoverProps = this.getPopoverProps(_popoverScope)

    return (
      <section className='report-snapshot'>
        {(reportSnapshotStatus === FETCHING) && (
          <Spinner
            theme='rain'
            mask={true}
          />
        )}

        <SectionHeader
          theme='rain'
          title={this.renderHeaderTitle(noData)}
          size='thin'
          isBordered={false}
        >
          <SectionSearch
            width={200}
            focusWidth={360}
            onQueryChange={this.handleQueryChange}
            onClear={this.handleQueryClear}
          />
          <Button
            reference={this.exportButton}
            theme='steel'
            icon='q4i-download-4pt'
            label='Export'
            disabled={noData || reportSnapshotStatus === FETCHING}
            onClick={this.handleExportPopoverOpen}
          />
          <Button
            reference={this.gridSettingsButton}
            theme='steel'
            icon='q4i-utility-4pt'
            onClick={this.handleSettingsPopoverOpen}
          />
        </SectionHeader>

        <div className='report-snapshot_grid'>
          <AgGrid
            className='report-builder-grid'
            suppressPropertyNamesCheck={true}
            isPinned={isPinned}
            rowDensity='comfy'
            frameworkComponents={{
              customNoRowsOverlay: NoContentMessage
            }}
            noRowsOverlayComponent='customNoRowsOverlay'
            noRowsOverlayComponentParams={this._getNoRowsOverlayParams}

            columnDefs={[]}
            rowData={[]}

            autoGroupColumnDef={autoGroupColumnDef}
            processSecondaryColGroupDef={processPivotGroupHeaders}
            processSecondaryColDef={processPivotHeaders}
            groupHideOpenParents={true}
            suppressAggFuncInHeader={true}
            groupDefaultExpanded={-1}

            asyncTransactionWaitMillis={1500}
            enableRangeSelection={true}
            suppressCellSelection={false}
            pagination={false}

            onGridReady={this.handleGridReady}
            onRowDataChanged={this.handleSizeColumnsToFit}
            onSortChanged={this.handleSortChanged}
            onColumnMoved={this.handleColumnMoved}
            onDragStopped={this.handleDragStopped}
          />
        </div>

        <PopoverMenu
          open={isPopoverOpen}
          theme='citrus'
          isMargin
          items={popoverProps.items}
          anchorEl={popoverProps.anchorEl}
          onClick={popoverProps.onClick}
          onClose={this.handlePopoverClose}
        />
      </section>
    )
  }
}

const mapStateToProps = (state) => {
  const reportState = state.report
  const profileData = state.profile && state.profile.data
  const ticker = getActiveTicker(profileData)
  const { reportSnapshot, reportDataItem, reportDataConfig, reportEntityTypes, reportPeerConfig } = reportState
  const { data } = reportDataItem
  const { total, _id } = reportSnapshot.meta || {}

  return {
    securityId: ticker && ticker._security,
    report: data || {},
    reportDataConfig,
    reportSnapshotStatus: reportSnapshot.status,
    reportSnapshot: reportSnapshot.unwoundData || [],
    reportEntityTypes: reportEntityTypes.data || [],
    reportSnapshotTotal: total,
    reportSnapshotId: _id,
    reportRevisions: (data && data.revisions) || [],
    peers: reportPeerConfig.peers || [],
  }
}

const mapDispatchToProps = (dispatch) => ({
  getReportSnapshot: bindActionCreators(getReportSnapshot, dispatch),
  setReportDataConfig: bindActionCreators(setReportDataConfig, dispatch)
})

ReportSnapshotContainer.propTypes = {
  reportTitle: PropTypes.string,
  onSnapshotFetched: PropTypes.func,
  setFetchSnapshot: PropTypes.func
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportSnapshotContainer))
