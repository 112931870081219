import {
  FETCH_VOLUME_ANALYSES_REQUEST,
  FETCH_VOLUME_ANALYSES_SUCCESS,
  FETCH_VOLUME_ANALYSES_ERROR,
  FETCH_RELATIVE_PERFORMANCE_REQUEST,
  FETCH_RELATIVE_PERFORMANCE_SUCCESS,
  FETCH_RELATIVE_PERFORMANCE_ERROR,
  FETCH_NLG_COMMENTARY_ERROR,
  NLG_SET_CURRENT_PEER,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED

} from '../../../actions/widget/iris/index'

const initial = {
  status: IDLE,
  returnAnalysis: {}
}

const nlgState = (state = initial, action) => {
  switch (action.type) {
    case NLG_SET_CURRENT_PEER:
      return {
        ...state,
        returnAnalysis: {},
        error: null
      }
    case FETCH_RELATIVE_PERFORMANCE_REQUEST:
    case FETCH_VOLUME_ANALYSES_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_VOLUME_ANALYSES_SUCCESS:
    case FETCH_RELATIVE_PERFORMANCE_SUCCESS:
      return {
        ...state,
        returnAnalysis: { ...state.returnAnalysis, ...action.payload },
        status: FETCHED
      }
    case FETCH_VOLUME_ANALYSES_ERROR:
    case FETCH_RELATIVE_PERFORMANCE_ERROR:
    case FETCH_NLG_COMMENTARY_ERROR:
      return {
        ...state,
        error: action.error,
        returnAnalysis: {},
        status: FAILED
      }
    default:
      return state
  }
}

export default nlgState
