import React from 'react'
import { truncate } from '../../../../utils'

/**
 * Header Component
 * @param title
 * @returns {*}
 * @constructor
 */
const Header = ({ title }) => {
  return (
    <header className='page_header'>
      <i className='page_header-icon q4i-itinerary-2pt' />
      <div className='page_header-title'>{truncate(title, 45)}</div>
    </header>
  )
}

export default Header
