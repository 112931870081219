import React, { PureComponent } from 'react'
import ActivitySidebarFilter from './filter/filter.component'
import { Sidebar } from '../../../../../components'

/**
 * Sidebar Header
 * @param props
 * @return {*}
 * @constructor
 */
const Header = (props) => {
  const { onFilterReset } = props

  return (
    <>
      <div className='sidebar_header-title'>Activity Filters</div>
      <button className='button button--ghost' onClick={onFilterReset}>Reset
      </button>
    </>
  )
}

class ActivitySidebar extends PureComponent {
  /**
     * Render Activity Sidebar Component
     * @return {XML}
     */
  render () {
    const { counts, filters, participants, isSidebarCollapsed, onFilterChange, onFilterReset, contactSelector, institutionSelector } = this.props

    return (
      <Sidebar
        collapsed={isSidebarCollapsed}
        headerTemplate={<Header onFilterReset={onFilterReset} />}
      >
        <ActivitySidebarFilter
          contactSelector={contactSelector}
          institutionSelector={institutionSelector}
          counts={counts}
          filters={filters}
          participants={participants}
          onFilterChange={onFilterChange}
        />
      </Sidebar>
    )
  }
}

export default ActivitySidebar
