import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Modal, Select } from '../../../../components'
import { THEMES } from '../../../../utils/ui'

class EstimatesBrokerDetailEditModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            broker: 'Restricted',
            broker_id: null,
            analyst: 'Restricted',
            analyst_id: '',
            quarter_est_date: null,
            quarter_est: 0,
            year_est: 0,
            target: 0,
            ratingOptions: ['Buy', 'Sell', 'Hold'],
            resetData: props.data
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
    }

    /**
     * Overwrite default values with data from props
     */
    UNSAFE_componentWillMount(){
        this.setState(this.props.data);
    }

    /**
     * Handle text or checkbox input change
     * @param event
     */
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ?
            target.checked : (typeof this.state[target.name] === 'object' ?
                Object.assign({}, this.state[target.name], {name: target.value}) : target.value);
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    /**
     * Handle selection of date
     * @param date
     */
    handleDateChange = (date) => {
        this.setState({
            quarter_est_date: date
        });
    };

    /**
     * Handle selection of new rating
     * @param option
     */
    handleDropdownChange = (option) => {
        this.setState({
            rating: option
        });
    };

    /**
     * Close the modal
     * @param event
     */
    onClose = (event) => {
        event.preventDefault();
        this.props.onClose();
    };

    /**
     * Pass along data and close the modal
     * @param event
     */
    onSave = (event) => {
        event.preventDefault();

        const {broker, analyst, quarter_est_date, quarter_est, year_est, target, rating} = this.state;
        this.props.onSave({
            broker,
            analyst,
            quarter_est_date,
            quarter_est,
            year_est,
            target,
            rating
        });
        this.props.onClose();
    };

    render() {
        const {visible} = this.props;
        const {broker, analyst, quarter_est_date, quarter_est, year_est, target, rating, ratingOptions, broker_id} = this.state;
        const isValid = (broker && broker.length) && (analyst && analyst.length);

        return (
            <Modal
                visible={visible}
                containerWidth={600}
                contentHeight={360}
                contentPadding='0 20px 20px 20px'
                title='Edit Record'
                onClose={this.onClose}
                footerButtons={[{
                    label: 'Cancel',
                    ui: 'shaded',
                    onClick: this.onClose
                }, {
                    label: 'Save',
                    ui: 'citrus',
                    disabled: !isValid,
                    onClick: this.onSave
                }]}
            >
                <div className='field field--text field--full'>
                    <label className='field_label' htmlFor='brokerName'>Broker Name*</label>
                    <input className='field_input'
                           id='brokerName'
                           name='broker'
                           placeholder='Add Broker'
                           maxLength={64}
                           value={broker}
                           onChange={this.handleInputChange}/>
                </div>
                <div className='field field--text field--full'>
                    <label className='field_label' htmlFor='analyst'>Analyst*</label>
                    <input className='field_input'
                           id='analyst'
                           name='analyst'
                           placeholder='Add Analyst'
                           maxLength={64}
                           value={analyst}
                           onChange={this.handleInputChange}/>
                </div>

                <div>
                    <div className='field field--date'>
                        <label className='field_label'>Date</label>
                        <div className='field_input' style={{width: 150}}>
                            <DatePicker
                                value={quarter_est_date}
                                disabled={!!broker_id}
                                onChange={this.handleDateChange}
                            />
                        </div>
                    </div>
                    <div className='field field--dropdown field--spacer'>
                        <label className='field_label'>Rating</label>
                        <div className='field_input'>
                          <Select
                            theme={THEMES.RAIN}
                            placeholder='Select Rating'
                            disabled={!!broker_id}
                            clearable={false}
                            searchable={false}
                            value={rating && { label: rating, value: rating }}
                            options={(ratingOptions || []).map((option) => option && { label: option, value: option })}
                            onChange={(option) => option && option.value && this.setState({ rating: option.value })}
                          />
                        </div>
                    </div>
                </div>

                <div className='field field--text'>
                    <label className='field_label' htmlFor='quarter_est'>Quarter</label>
                    <input className='field_input field_input--short'
                           type='number'
                           step='0.1'
                           min='0'
                           id='quarter_est'
                           name='quarter_est'
                           value={quarter_est}
                           readOnly={!!broker_id}
                           onChange={this.handleInputChange}/>
                </div>
                <div className='field field--text field--spacer'>
                    <label className='field_label' htmlFor='year_est'>Fiscal Year</label>
                    <input className='field_input field_input--short'
                           type='number'
                           step='0.1'
                           min='0'
                           id='year_est'
                           name='year_est'
                           value={year_est}
                           readOnly={!!broker_id}
                           onChange={this.handleInputChange}/>
                </div>
                <div className='field field--text field--spacer'>
                    <label className='field_label' htmlFor='target'>Target</label>
                    <input className='field_input field_input--short'
                           type='number'
                           min='0'
                           id='target'
                           name='target'
                           value={target}
                           readOnly={!!broker_id}
                           onChange={this.handleInputChange}/>
                </div>
            </Modal>
        );
    }
}

EstimatesBrokerDetailEditModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    data: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired
};

export default EstimatesBrokerDetailEditModal;
