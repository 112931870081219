import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import CustomCell from './cell/cell.component'
import { AgGrid, Message, PopoverMenu, Spinner } from '../../../../../components'

import { getDefaultColumnDef, preventDefaultRowClick, savedGridBulkActions } from '../../../../../utils'

class SavedTargetsGrid extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      popoverMenuProps: null,
      columns: [
        {
          ...getDefaultColumnDef({ columnIndex: 0, isAlternating: false }),
          field: 'entity_name',
          headerName: 'Name',
          minWidth: 320,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          sortable: true
        },
        {
          ...getDefaultColumnDef({ columnIndex: 1 }),
          field: 'locations',
          headerName: 'Location',
          minWidth: 180,
          maxWidth: 220,
          sortable: true
        },
        {
          ...getDefaultColumnDef({ columnIndex: 2 }),
          field: 'create_date',
          headerName: 'Date Added',
          minWidth: 180,
          maxWidth: 220,
          sortable: true
        }
      ]
    }
  }

  componentWillUnmount () {
    clearTimeout(this.interval)
  }

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  handleGridReady = (grid) => {
    this.interval = setTimeout(() => this.agGrid = grid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      this.interval = setTimeout(() => this.agGrid && this.agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * On Expand Menu Click
   * Displays expandable menu for contact/institutions and tags
   * @param event
   * @param popoverMenuProps
   */
  onExpandMenuClick = (event, popoverMenuProps) => {
    event.stopPropagation()

    this.setState({ popoverMenuProps })
  }

  /**
   * On Expand Menu Close Click
   * @param event
   */
  onExpandMenuCloseClick = (event) => {
    event.stopPropagation()

    this.setState({ popoverMenuProps: null })
  }

  /**
   * Renders CustomCell Component
   */
  renderCustomCell = (props) => {
    return (
      <CustomCell
        {...props}
        onExpandMenuClick={this.onExpandMenuClick}
      />
    )
  }

  onExpandMenuItemClick = () => {
    // do nothing for now
    return
  }

  /**
   * Handle onSortChanged event (multi-sort included)
   * @param grid
   */
  handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel || !sortModel[0]) {
      return
    }

    const sortParams = sortModel[0]
    this.props.handleSortChange({ property: sortParams.colId, direction: sortParams.sort })
  }

  /**
   * Propagate delete action to parent on confirm
   */
  handleDeleteConfirm = () => {
    const { handleBulkAction } = this.props

    const selectedRows = this.agGrid.api.getSelectedRows()

    if (!selectedRows || !selectedRows.length) {
      return
    }

    handleBulkAction(savedGridBulkActions.DELETE_TARGETS, selectedRows)
    this.closeDeleteModal()
  }

  closeDeleteModal = () => {
    this.setState({
      isMessageOpen: false,
      modalProps: {}
    })
  }

  /**
   * Handle row click
   * @param data
   */
  handleRowClick = (row) => {
    if (!row) {
      return
    }

    const { handleRowClick } = this.props
    handleRowClick(row)
  }

  /**
   * Handle bulk actions
   **/
  handleBulkAction = (actionId) => {
    if (!this.agGrid || !actionId) {
      return
    }

    const selectedRows = this.agGrid.api.getSelectedRows()

    if (!selectedRows || !selectedRows.length) {
      return
    }

    const { handleBulkAction } = this.props

    switch (actionId) {
      case savedGridBulkActions.DELETE_TARGETS:
        this.setState({
          isMessageOpen: true,
          modalProps: {
            visible: true,
            type: 'warning',
            title: 'Delete Target(s)?',
            message: 'Are you sure you want to delete selected target(s)?',
            buttons: [
              {
                ui: 'shaded',
                label: 'cancel',
                onClick: this.closeDeleteModal
              },
              {
                ui: 'spice',
                label: 'confirm',
                onClick: this.handleDeleteConfirm
              }
            ]
          }
        })
        break
      default:
        handleBulkAction(actionId, selectedRows)
        break
    }
  }

  render () {
    const { data, dataTotal, query, handleQueryChange, isLoading, isGridReact } = this.props
    const { columns } = this.state

    return (
      <div className='targeting-page-tab_body'>
        {isLoading && <Spinner mask={true} theme='rain' />}
        {isGridReact && (<AgGrid

          className='saved-targets-grid'
          domLayout='autoHeight'
          sizeToFit={true}

          // suppress configs
          suppressMovableColumns
          suppressContextMenu
          enableSorting={false}

          // columns and data
          columnDefs={columns}
          rowData={data}

          defaultColDef={{
            suppressMenu: true,
            cellRenderer: 'CustomCellRender'
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: this.renderCustomCell
          }}

          // bulk actions
          bulkActions={[{
            id: savedGridBulkActions.DELETE_TARGETS,
            icon: 'q4i-trashbin-4pt',
            onSelect: () => this.handleBulkAction(savedGridBulkActions.DELETE_TARGETS)
          }, {
            id: savedGridBulkActions.ADD_TO_ACTIVITY,
            icon: 'q4i-activity-4pt',
            onSelect: () => this.handleBulkAction(savedGridBulkActions.ADD_TO_ACTIVITY)
          }, {
            id: savedGridBulkActions.ADD_TO_BRIEFING_BOOK,
            icon: 'q4i-book-4pt',
            onSelect: () => this.handleBulkAction(savedGridBulkActions.ADD_TO_BRIEFING_BOOK)
          }]}

          pagination
          paginationProps={{
            pageSizeId: 'saved-targets-grid',
            forcePage: query.page,
            initialPageSize: query.limit,
            showPageSizeSelection: true,
            total: dataTotal,
            onPageChange: ({ selected: newPage }) => handleQueryChange({ page: newPage }),
            onPageSizeChange: ({ selected: newPageSize }) => handleQueryChange({ limit: newPageSize, page: 1 })
          }}

          // event listeners
          onSortChanged={this.handleSortChange}
          onGridReady={this.handleGridReady}
          onGridSizeChanged={this.handleGridResize}
          onRowClicked={preventDefaultRowClick(
            this.handleRowClick,
            ['expandable-cell']
          )}
        />)}

        {/* Popover Menu for Custom Cell */}
        {this.state.popoverMenuProps && (
          <PopoverMenu
            arrowClass='bottom-right'
            onClose={this.onExpandMenuCloseClick}
            onClick={this.onExpandMenuItemClick}
            scrollable={true}
            {...this.state.popoverMenuProps}
          />
        )}
        <Message {...this.state.modalProps} />
      </div>
    )
  }
}

SavedTargetsGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    entity_name: PropTypes.string,
    locations: PropTypes.string,
    create_date: PropTypes.string,
  })),
  dataTotal: PropTypes.number,
  query: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  handleQueryChange: PropTypes.func.isRequired,
  handleBulkAction: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isGridReact: PropTypes.bool
}

SavedTargetsGrid.defaultProps = {
  data: [],
  dataTotal: 0,
  query: {}
}

export default SavedTargetsGrid
