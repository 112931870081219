import {
  fetchInstitutionCurrentPosition,
  fetchFundCurrentPosition
} from '../..'

export const TARGETING_GET_CURRENT_POSITION_REQUEST = 'TARGETING_GET_CURRENT_POSITION_REQUEST'
export const TARGETING_GET_CURRENT_POSITION_SUCCESS = 'TARGETING_GET_CURRENT_POSITION_SUCCESS'
export const TARGETING_GET_CURRENT_POSITION_FAILURE = 'TARGETING_GET_CURRENT_POSITION_FAILURE'

/**
 * Get Current Position
 * @param entityType
 * @param params
 */
export const fetchCurrentPosition = (entityType, params) => (dispatch) => {
  const _fetchCurrentPosition = entityType === 'institution'
    ? fetchInstitutionCurrentPosition
    : fetchFundCurrentPosition

  return dispatch(_fetchCurrentPosition(params, {
    types: [
      TARGETING_GET_CURRENT_POSITION_REQUEST,
      TARGETING_GET_CURRENT_POSITION_SUCCESS,
      TARGETING_GET_CURRENT_POSITION_FAILURE
    ]
  }))
}
