import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

export const GET_TEARSHEET_REQUEST = gql`
  query TearsheetRequest($id: String!) {
    tearsheetRequest(id: $id) {
      items {
        id
        title
        note
        itinerary
        reference {
          item
          type
          referenceConnection {
            items {
            ...on BriefingBookDTO {
                entity {
                  entityId
                  entityType
                  institutionId
                }
                activityConnection {
                  items {
                    id
                    category
                  }
                }   
              }
            }
          }
        }
        template {
          id
          type
          items {
            type
            options {
              type
              selected
            }
          }
        }
      }
    }
  }`

export const GET_PROFILE = gql`
  query Profile {
    me {
      id
      firstName
      lastName
      services {
        type
        enabled
      }
      organizationConnection {
        name
        tickers {
          symbol
          exchange
          tickerId
          legacySecurityId
        }
      }
    }
  }`

const GET_SECURITY = gql`
  query Security(
    $tickerId: [String]!
  ) {
    security(
      tickerId: $tickerId
    ) {
      items {
        id
        tickerId
        securityName
        industry
        capGroup
        region
        sector
        symbol
        exchangeCode
        exchangeName
      }
    }
  }`

/**
 * Tearsheet Request
 * @param options
 */
export function useTearsheetRequestQuery (options) {
  return useQuery(GET_TEARSHEET_REQUEST, options)
}

/**
 * User Profile
 * @param options
 */
export function useProfileQuery (options) {
  return useQuery(GET_PROFILE, options)
}

/**
 * Security
 * @param options
 */
export function useSecurityQuery (options) {
  return useQuery(GET_SECURITY, options)
}
