import React from 'react'
import PropTypes from 'prop-types'
import { Toolbar, ToolbarRow, Button } from '../../../../../components'
import { SectionSearch, RangeTab } from '../../../../../components/shared'
import { THEMES } from '../../../../../utils/ui'

const TargetingSavedTabToolbar = ({ query, noData, resultsTotalCounts, handleQueryChange, handleExport }) => {
  const { type, search } = query

  const handleSearchChange = (search) => handleQueryChange({ search, page: 1 })
  const handleTypeChange = (event, type) => handleQueryChange({ type, page: 1 })

  return (
    <Toolbar className='targeting-toolbar'>
      <ToolbarRow justified>
        <div className='toolbar_group'>
          <RangeTab
            theme={THEMES.STEEL}
            buttons={[{
              value: 'All',
              label: `All (${resultsTotalCounts.all || 0})`,
              active: type === 'All',
              onClick: handleTypeChange
            }, {
              value: 'Institution',
              label: `Institutions (${resultsTotalCounts.institution || 0})`,
              active: type === 'Institution',
              onClick: handleTypeChange
            }, {
              value: 'Fund',
              label: `Funds (${resultsTotalCounts.fund || 0})`,
              active: type === 'Fund',
              onClick: handleTypeChange
            }, {
              value: 'Contact',
              label: `Contacts (${resultsTotalCounts.contact || 0})`,
              active: type === 'Contact',
              onClick: handleTypeChange
            }]}
          />
        </div>
        <div className='toolbar_group'>
          <SectionSearch
            value={search}
            onQueryChange={handleSearchChange}
            onClear={handleSearchChange}
          />
          <Button
            theme={THEMES.STEEL}
            label='Export'
            icon='q4i-download-4pt'
            disabled={noData}
            onClick={handleExport}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

TargetingSavedTabToolbar.propTypes = {
  query: PropTypes.shape({
    type: PropTypes.oneOf(['All', 'Institution', 'Fund', 'Contact']),
    search: PropTypes.string
  }),
  noData: PropTypes.bool,
  resultsTotalCounts: PropTypes.shape({
    all: PropTypes.number,
    institution: PropTypes.number,
    fund: PropTypes.number,
    contact: PropTypes.number
  }),
  handleQueryChange: PropTypes.func,
  handleExport: PropTypes.func
}

export default TargetingSavedTabToolbar
