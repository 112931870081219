import { combineReducers } from 'redux'
import generate from './generate.reducer'
import grid from './grid.reducer'
import pdf from './pdf.reducer'

export default combineReducers({
  generate,
  grid,
  pdf
})
