import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'

const GET_PEER = gql`
  query Peer(
    $search: String
    $searchField: [String]
    $page: Int
    $limit: Int
  ) {
    peer(
      search: $search
      searchField: $searchField
      page: $page
      limit: $limit
    ) {
      items {
        id
        tickerId
        exchange
        name
        symbol
      }
      count
    }
  }`

const DELETE_PEERS = gql`
  mutation Peer($id: [String]!) {
    peer {
      delete(id: $id) {
        count
      }
    }
  }`

/**
 * Peer
 * @param options
 */
export function usePeerQuery (options) {
  return useQuery(GET_PEER, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Peer List Item Delete
 * @param options
 */
export function usePeerDeleteQuery (options) {
  return useMutation(DELETE_PEERS, options)
}
