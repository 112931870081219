import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class DataTableRow extends PureComponent {

    /**
     * Handle onClick event for DataTableRow
     * @param event
     */
    handleRowClick = (event) => {
        const {index, data, onRowClick} = this.props;
        onRowClick && onRowClick(event, data, index);
    };

    /**
     * Render DataTableFooter Component
     * @returns {XML}
     */
    render() {
        const {className, children, data, onRowClick} = this.props;

        const {expanded} = data || {};

        const baseClassName = [
            className ? `data-table_row ${className}` : 'data-table_row',
            onRowClick ? 'data-table_row--clickable' : '',
            expanded ? 'data-table_row--expanded': ''
        ].join(' ');

        return (
            <tr className={baseClassName} onClick={this.handleRowClick}>
                {children}
            </tr>
        );
    }
}

DataTableRow.propTypes = {
    /**
     * A custom className to add to the component
     */
    className: PropTypes.string,

    /**
     * Data used to render the DataTableRow content
     * Used to supply onRowClick callback
     */
    data: PropTypes.object,

    /**
     * Rendering index of the DatTableRow component
     * Used to supply onRowClick callback
     */
    index: PropTypes.number,

    /**
     * A callback for when the user clicks on a row
     * Adds a visual indicator for the row (cursor: pointer)
     */
    onRowClick: PropTypes.func
};

export default DataTableRow;