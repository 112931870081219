import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getClassName, getFromXigniteToStandard } from '../../../utils'

import { ComboBox } from '../../../components'
import { DATA_IDS } from '../peerConfigModal.definition'
const { PEER_CONFIG_ADD_PEERS_INPUT, PEER_CONFIG_ADD_PEERS_MENU } = DATA_IDS

/**
 * ReportPeerLabel Component
 * @param type
 * @param peer
 * @returns {*}
 */
function ReportPeerLabel (type, peer) {
  const { symbol, exchange, security_name } = peer

  return (
    <span className={`peer-config_item peer-config_item--${type}`}>
      <span className='peer-config_item-symbol'>{symbol}</span>
      <span className='peer-config_item-exchange'>{getFromXigniteToStandard(exchange)}</span>
      <span className='peer-config_item-separator'>|</span>
      <span className='peer-config_item-name'>{security_name}</span>
    </span>
  )
}

/**
 * ReportPeers Component
 * @param props
 * @returns {*}
 */
function PeerConfig (props) {
  const {
    isDetailed,
    peers,
    availablePeers,
    onSearchQueryChange,
    onSearchQueryClear,
    onSearchPeerSelect,
    onRemovePeer,
    onResetPeers,
    onClearPeers
  } = props

  const ListLabel = (peer) => ReportPeerLabel('list', peer)

  const selectedPeers = [].concat(peers || [])
    .map((peer) => peer && { value: peer._security, item: peer })
    .sort((a, b) => a.item?.symbol < b.item.symbol ? -1 : 1)

  const baseClassName = getClassName('peer-config', [
    { condition: isDetailed, trueClassName: 'peer-config--detailed' }
  ])

  const options = [].concat(availablePeers || [])
    .map((peer) => {
      const { symbol = '', exchange = '', company_name = '' } = peer
      return peer && {
        value: peer,
        label: `${symbol} ${getFromXigniteToStandard(exchange)} | ${company_name}`
      }
    })

  return (
    <div className={baseClassName}>
      <div className='peer-config_header'>
        <div className='peer-config_title-container'>
          <h3 className='peer-config_title'>Peers {isDetailed && `(${peers ? peers.length : 0})`}</h3>
          {peers?.length <20 ? isDetailed && (
            <h4 className='peer-config_subtitle'>Select up to 20 peers</h4>
          ) : isDetailed && (
            <div className='peer-config_subtitle-error'>
              <i className={`cell_icon q4i-volatility-4pt`} />
              <h4>You have reached the maximum. Remove a peer to continue.</h4>
            </div>
          )}
        </div>
        <div className='peer-config_action-container'>
          <span className='peer-config_action' onClick={onResetPeers}>Reset</span>
          <span className='peer-config_action' onClick={onClearPeers}>Clear</span>
        </div>
      </div>
      <div className='peer-config_search'>
        <ComboBox
          selectProps={{
            dataId: {
              inputId: PEER_CONFIG_ADD_PEERS_INPUT,
              menuId: PEER_CONFIG_ADD_PEERS_MENU
            },
            disabled: peers.length >= 20,
            placeholder: 'Type a security or symbol',
            size: isDetailed ? null : 'thin',
            itemHeight: isDetailed ? 40 : 30,
            value: '',
            options,
            closeMenuOnSelect: false,
            onInputChange: (query) => query && query.length ? onSearchQueryChange(query) : onSearchQueryClear(),
            onChange: (selection) => selection && selection.value && onSearchPeerSelect(selection.value)
          }}
          removableListProps={{
            size: 'small',
            items: selectedPeers,
            customItemRender: (item) => ListLabel(item),
            onRemove: onRemovePeer
          }}
        />
      </div>
    </div>
  )
}

PeerConfig.propTypes = {
  isDetailed: PropTypes.bool,
  peers: PropTypes.array.isRequired,
  availablePeers: PropTypes.array.isRequired,
  onSearchQueryChange: PropTypes.func.isRequired,
  onSearchQueryClear: PropTypes.func.isRequired,
  onSearchPeerSelect: PropTypes.func.isRequired,
  onRemovePeer: PropTypes.func.isRequired,
  onResetPeers: PropTypes.func.isRequired,
  onClearPeers: PropTypes.func.isRequired
}

PeerConfig.defaultProps = {
  isDetailed: false,
  peers: [],
  availablePeers: []
}

export default memo(PeerConfig)
