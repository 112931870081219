import React from 'react'
import { Select } from '../../../../../../components'
import { Checkbox } from '../../../../../../components/shared/form'
import { THEMES } from '../../../../../../utils'

/**
 * Events & Transcripts Page
 * @param props
 */
function EventTypeFilter (props) {
  const { filters, handleFilterChange } = props
  const { transcripts, peers, eventTypeOptions } = filters

  /**
   * Get placeholder text from selected eventTypes
   */
  const getPlaceholderText = () => {
    const selectedOptions = eventTypeOptions.filter(x => x?.selected)
    const placeholderText = selectedOptions.length > 0
      ? selectedOptions.map((eventType, idx) => {
          return (idx === 0 ? '' : ', ') + `${eventType.label}`
        }).join('')
      : null
    return placeholderText ? <span className='value-eventTypes'> {placeholderText} </span> : <span className='placeholder-eventTypes'>Select event type</span>
  }

  /**
   * Handle filter update for event type
   * @param options
   */
  const handleEventTypeChange = (selectedItems, event) => {
    const { action, option } = event
    const { value, label } = option

    const selected = (eventTypeOptions || []).map((item) => {
      if (item && item.value === value) {
        return action === 'select-option'
          ? { ...item, selected: true }
          : Object.assign({}, { value, label })
      }
      return item
    })

    handleFilterChange({
      eventTypeOptions: selected
    })
  }

  return (
    <section className='eventsTranscripts-eventtype-filter'>
      <div className='field field--full field--text'>
        <label className='field_label'>Event Type</label>
        <div className='field field--full field--grouped'>
          <Select
            placeholder={getPlaceholderText()}
            options={eventTypeOptions}
            value={(eventTypeOptions || []).filter((item) => item && !!item.selected)}
            isMulti
            isDefaultList
            searchable={false}
            closeMenuOnSelect={false}
            clearable={false}
            theme={THEMES.WHITE}
            onChange={handleEventTypeChange}
          />
        </div>
        <div className='field field--full field--grouped'>
          <Checkbox
            theme={THEMES.SOFT_GREY}
            id='eventFilterTranscript'
            isChecked={transcripts === true}
            label='Transcript'
            onChange={() => {
              handleFilterChange({
                transcripts: !transcripts
              })
            }}
          />
        </div>
        <div className='field field--full field--grouped'>
          <Checkbox
            theme={THEMES.SOFT_GREY}
            id='eventFilterPeers'
            isChecked={peers === true}
            label='Peers'
            onChange={() => handleFilterChange({
              peers: !peers
            })}
          />
        </div>
      </div>
    </section>
  )
}

export default EventTypeFilter
