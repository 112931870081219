import React, { useState, memo, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

// components
import { Modal, TextField, EntitySearch } from '../../index'

// utils
import { THEMES } from '../../../utils'
import { get, uniqBy } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  loading: PropTypes.bool,
  book: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    entities: PropTypes.array
  }),
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

const defaultProps = {
  loading: false,
  book: {}
}

/**
 * Briefing Book Create/Edit Entity Component
 * @param props
 */
function EditModal (props) {
  const { dataId, loading, book, onSave, onClose } = props
  const { id, entities } = book

  const getEntity = (entities || []).map((entity) => {
    const { entityId, institutionId, entityConnection = {} } = entity

    return {
      ...entity,
      entityConnection: {
        ...entityConnection,
        selectedInstitution: institutionId ? `${institutionId}:${entityId}` : null
      }
    }
  })

  const [title, setTitle] = useState(book.title || '')
  const [entity, setEntity] = useState(getEntity)
  const [errors, setErrors] = useState({
    title: false,
    entity: false
  })

  /**
   * Set or clear errors
   */
  useEffect(() => {
    if ((errors.title && title.length) || (errors.entity && entity.length)) {
      setErrors({
        title: !(title.trim() && title.trim().length),
        entity: !entity.length
      })
    }
  }, [title, entity, errors, setErrors])

  /**
   * Handle briefing book create/edit submit
   */
  const handleSubmit = () => {
    if (!(title.trim() && title.trim().length) || !entity.length) {
      setErrors({
        title: !(title.trim() && title.trim().length),
        entity: !entity.length
      })
      return
    }

    const entities = uniqBy(entity, (entity) => entity.entityId)

    onSave && onSave({
      title,
      entity: (entities || []).map((entity) => {
        const selectedInstitution = get(entity, 'entityConnection.selectedInstitution', null)
        const institutionId = selectedInstitution && selectedInstitution.split(':')[0] !== ''
          ? selectedInstitution.split(':')[0]
          : null
        return {
          entityId: entity.entityId,
          entityType: entity.entityType,
          institutionId
        }
      })
    })
  }

  return (
    <Modal
      dataId={dataId}
      visible
      scrollable
      disableRestoreFocus
      loading={loading}
      title={`${id ? 'Edit' : 'Create'} Briefing Book`}
      footerButtons={[
        {
          label: 'Cancel',
          ui: 'shaded',
          onClick: onClose
        },
        {
          label: 'Save',
          ui: THEMES.CITRUS,
          onClick: handleSubmit
        }]}
      onClose={onClose}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            error={{ isError: errors.title }}
            label='Title'
            value={title}
            placeholder='My Briefing Book'
            onChange={(event) => setTitle(get(event, 'target.value', ''))}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <EntitySearch
            entities={entity}
            error={{ isError: errors.entity }}
            onChange={(entity) => setEntity(entity)}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}

EditModal.propTypes = propTypes
EditModal.defaultProps = defaultProps

export default memo(EditModal)
