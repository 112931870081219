import React, {PureComponent} from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import {getLocalStorageItem} from '../../utils/localStorage.util';
import './pagination.component.css';

/**
 * Pagination Component
 */
class Pagination extends PureComponent {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        const options = props.pageSizeOptions;
        const pageSize = props.initialPageSize || ((options && options.length > 0) ? options[0] : 10);

        this.state = {
            pageCount: this.getPageCount(props.total, pageSize),
            pageSize
        };
    }

    /**
     * ComponentDidUpdate
     * @param prevProps
     */
    componentDidUpdate = (prevProps) => {
        if (prevProps.total !== this.props.total) {
            this.setState({
                pageCount: this.getPageCount(this.props.total, this.state.pageSize)
            });
        }
    };

    /**
     * Handle Page Change
     * @param event
     */
    handlePageChange = (event) => {
        const {startFromZero, onPageChange} = this.props;
        const size = event.selected;

        onPageChange && onPageChange({
            selected: startFromZero ? size : size + 1
        });
    };

    /**
     * Handle Page Size Change
     * @param event
     * @param size
     */
    handlePageSizeChange = (event, size) => {
        event.preventDefault();

        const {total, pageSizeId, onPageSizeChange} = this.props;
        const {pageSize} = this.state;

        if (pageSize === size) {
            return;
        }

        if (pageSizeId) {
            this.savePageSizeToStorage(pageSizeId, size);
        }

        this.setState({
            pageSize: size,
            pageCount: this.getPageCount(total, size)
        });

        onPageSizeChange && onPageSizeChange({selected: size});
    };

    /**
     * Calculate page count based on page size
     * @param total
     * @param pageSize
     * @returns {number}
     */
    getPageCount = (total, pageSize) => {
        if (!total) {
            return 1;
        }

        return Math.ceil(total / pageSize) || 1;
    };

    /**
     * Save Page Size to Local Storage
     * @param pageSizeId
     * @param pageSize
     */
    savePageSizeToStorage = (pageSizeId, pageSize) => {
        const storage = getLocalStorageItem('pagination');
        let data = {};

        if (!storage) {
            data[pageSizeId] = pageSize;
        } else {
            data = Object.assign({}, storage);
            data[pageSizeId] = pageSize;
        }

        localStorage.setItem('pagination', JSON.stringify(data));
    };

    /**
     * Render Pagination Component
     * @returns {*}
     */
    render() {
        const {startFromZero, initialPage, forcePage, showPageSizeSelection, pageSizeOptions, style} = this.props;
        const {pageCount, pageSize} = this.state;

        return (
            <section style={style} className={`pagination ${showPageSizeSelection ? 'pagination--with-page-sizes' : ''}`}>
                <ReactPaginate
                    disableInitialCallback={true}
                    initialPage={startFromZero ? initialPage : initialPage - 1}
                    forcePage={startFromZero ? forcePage : forcePage - 1}
                    pageCount={pageCount}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={1}
                    containerClassName='pagination_actions pagination_actions--pages'
                    subContainerClassName='pagination_list'
                    pageClassName='pagination_item pagination_action pagination_action--page'
                    activeClassName='pagination_action--active'
                    pageLinkClassName='pagination_link'
                    previousClassName='pagination_item pagination_action pagination_action--prev'
                    nextClassName='pagination_item pagination_action pagination_action--next'
                    previousLinkClassName='pagination_link'
                    nextLinkClassName='pagination_link'
                    disabledClassName='pagination_action--disabled'
                    breakClassName='pagination_item pagination_item--break'
                    previousLabel={<i className='q4i-arrow-left-4pt'/>}
                    nextLabel={<i className='q4i-arrow-right-4pt'/>}
                    breakLabel={<span>...</span>}
                    onPageChange={this.handlePageChange}
                />
                {showPageSizeSelection && (
                    <div className='pagination_actions pagination_actions--page-sizes'>
                        <span className='pagination_item pagination_item--per-page'>Per Page</span>
                        <ul className='pagination_list'>
                            {(pageSizeOptions || []).map((option) => {
                                const itemClassName = `pagination_item pagination_action ${option === pageSize ? 'pagination_action--active' : ''}`;
                                return (
                                    <li key={`pagination_page-size-item--${option}`} className={itemClassName}>
                                        <span className='pagination_link' onClick={(event) => this.handlePageSizeChange(event, option)}>
                                            {option}
                                        </span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </section>
        );
    }
}

Pagination.propTypes = {
    /**
     * This component starts pagination from 0 (visually correct, however), but it has been forced to start from 1
     * Use this prop to overwrite the functionality back to traditional zero start
     */
    startFromZero: PropTypes.bool,

    /**
     * The total amount of data records
     * Used to determine page count
     */
    total: PropTypes.number,

    /**
     * Used to set an initial page on the component's first render
     */
    initialPage: PropTypes.number,

    /**
     * Used to set an overwrite page control with prop
     */
    forcePage: PropTypes.number,

    /**
     * Used to set an initial page size on the component's first render
     */
    initialPageSize: PropTypes.number,

    /**
     * Used to overwrite the default page size options
     * Default: [10, 25, 50, 100]
     */
    pageSizeOptions: PropTypes.array,

    /**
     * A unique ID string used to store the user's last page size selection
     * Stored in localStorage under 'pagination' object
     */
    pageSizeId: PropTypes.string,

    /**
     * Override whether or not to show the page size options
     */
    showPageSizeSelection: PropTypes.bool,

    /**
     * A callback for when the user changes pages
     */
    onPageChange: PropTypes.func,

    /**
     * A callback for when the user changes page size
     * if pageSizeId is provided, this also backs up the user's choice to localStorage under that ID
     */
    onPageSizeChange: PropTypes.func
};

Pagination.defaultProps = {
    startFromZero: false,
    total: 0,
    initialPageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
    showPageSizeSelection: false
};

export default Pagination;
