import {
  FETCH_AVGVOLUME_REQUEST,
  FETCH_AVGVOLUME_SUCCESS,
  FETCH_AVGVOLUME_ERROR,
  IDLE,
  FETCHED,
  FETCHING,
  ERROR
} from '../../../actions/widget/stock'

const averageVolume = (state = { status: IDLE }, action) => {
  switch (action.type) {
    case FETCH_AVGVOLUME_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_AVGVOLUME_SUCCESS:
      return {
        ...state,
        volume: action.payload,
        status: FETCHED
      }
    case FETCH_AVGVOLUME_ERROR:
      return {
        error: action.error,
        status: ERROR
      }
    default:
      return state
  }
}

export default averageVolume
