import React from 'react'
import { Link } from 'react-router-dom'

import { statusType } from '../../../../../actions'

import Grid from '@material-ui/core/Grid'
import { OwnershipEntityChart, RenderHTML } from '../../../../index'

import { ENTITY_INSTITUTION, format, defaultIfEmpty } from '../../../../../utils'
import { config } from '../../../../../config'

function DefaultLayout (props) {
  const {
    entityId,
    jobTitle,
    institutionName,
    institutionType,
    address,
    phones,
    email,
    functions,
    strategy,
    position,
    ownershipLabel,
    details,
  } = props

  return (
    <>
      {/* first row */}
      <Grid container item xs={12} className='institution_header'>

        {/* institution header */}
        <Grid item xs={6} className='card_section card_section--reverse'>
          {entityId
            ? <Link className='card_section-title' to={`/institution/${entityId}`}>
              {defaultIfEmpty(institutionName)}
            </Link>
            : <div className='card_section-title'>{defaultIfEmpty(institutionName)}</div>}
          <div className='card_section-detail'>{defaultIfEmpty(institutionType)}</div>
        </Grid>

        <Grid item xs={6}>
          <Grid container item xs={12}>
            {position && position.status && (position.status === statusType.SUCCESS) && position[entityId] !== undefined
              ? <Grid item xs={12} className='institution_positions q4-fade-in'>
                <div className='institution_position'>
                  <div className='card_section--reverse card_section--theme'>
                    <div className='card_section-subtitle'>Institution Position</div>
                    <div className='card_section-detail'>{ownershipLabel}</div>
                  </div>
                  <div className='institution_position-position'>
                    {defaultIfEmpty(format(position[entityId]))}
                  </div>
                </div>
                <div className='institution_position'>
                  <div className='card_section--reverse card_section--theme'>
                    <div className='card_section-subtitle'>Contact Position</div>
                    <div className='card_section-detail'>13F</div>
                  </div>
                  <div className='institution_position-position'>{format(position.data)}</div>
                </div>
              </Grid>
              : null}
          </Grid>
        </Grid>
      </Grid>

      {/* second row */}
      <Grid container item xs={12} className='institution_content'>
        {/* institution detail */}
        <Grid item xs={5}>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6} className='card_section'>
              <h2 className='card_section-subtitle'>Address</h2>
              <div className='card_section-detail'><RenderHTML html={defaultIfEmpty(address)} /></div>
            </Grid>
            <Grid item xs={6}>
              <div className='card_section'>
                <h2 className='card_section-subtitle'>Corporate Phone(s)</h2>
                <div className='card_section-detail'><RenderHTML html={defaultIfEmpty(phones)} /></div>
              </div>
              <div className='card_section--grouped'>
                <h2 className='card_section-subtitle'>Corporate Email</h2>
                {(email && email.length)
                  ? <a
                    className='card_section-detail'
                    href={`mailto:${email}?Subject=Contact%20from%20Q4%20Desktop&bcc=${config.loggerEmail}`}>{email}</a>
                  : '-'}
              </div>
            </Grid>

            <Grid item xs={6} className='card_section'>
              <h2 className='card_section-subtitle'>Job Title</h2>
              <div className='card_section-detail'>{defaultIfEmpty(jobTitle)}</div>
            </Grid>
            <Grid item xs={6} className='card_section'>
              <h2 className='card_section-subtitle'>Function(s)</h2>
              {(functions && Array.isArray(functions) && functions.length)
                ? (functions).map((item, index) => <div key={index} className='card_section-detail card_section-detail-text'>{item}</div>)
                : '-'}
            </Grid>

            <Grid item xs={12} className='card_section'>
              <h2 className='card_section-subtitle'>Coverage/Strategy</h2>
              <div className='card_section-detail card_section-detail-text'>{strategy}</div>
            </Grid>
          </Grid>
        </Grid>

        {/* ownership chart */}
        <Grid item xs={7}>
          <OwnershipEntityChart
            entityType={ENTITY_INSTITUTION}
            entityId={entityId}
            contactId={details._id}
            height={267}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default DefaultLayout
