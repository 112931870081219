const contactQ4IRManager = 'contact your Q4 IR Manager if you have any questions along the way.'

const getInactiveMessage = (entityType) => {
  return `Looks like this ${entityType} is no longer active so we don't have anything to display. Try exploring a different
          ${entityType}'s page, or ${contactQ4IRManager}` 
}

export const PAGE_MESSAGE = {
  FUND_PAGE_INACTIVE: getInactiveMessage('fund'),
  INST_PAGE_INACTIVE: getInactiveMessage('institution'),
  SEC_PAGE_INACTIVE: getInactiveMessage('security'),
  SEC_PAGE_NONIPO: `Looks like this company hasn’t started trading yet so we don't have anything to display. Try exploring a different 
                    security's page, or ${contactQ4IRManager}`
}