import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'

const GET_DEAL = gql`
  query Deal(
    $id: [String]!
    $securityId: [String]!
    $tickerId: [String]!
    $source: String!
    $quarters: Int!
  ) {
    deal(id: $id) {
      items {
        id
        type
        title
        start {
          date
          timezone
        }
        end {
          date
          timezone
        }
        amount
        usePurchasingPower
        description
        entity {
          entityId
          entityType
          entityConnection {
            ... on ContactDTO {
              id
              fullName
              jobs {
                entityId
                institutionName
              }
              contactHoldingCurrentConnection(
                securityId: $securityId
              ) {
                items {
                  current
                }
              }
            }
            ... on FundDTO {
              id
              fundName
              institutionName
              institutionId
              fundHoldingCurrentConnection(
                tickerId: $tickerId
                source: $source
              ) {
                items {
                  current
                }
              }
            }
            ... on InstitutionDTO {
              id
              institutionName
              institutionHoldingCurrentConnection(
                tickerId: $tickerId
                source: $source
              ) {
                items {
                  current
                }
              }
            }
          }
          entityHoldingHistoricalConnection(
            tickerId: $tickerId
            securityId: $securityId
            source: $source
            quarters: $quarters
          ) {
            ...on ContactHoldingHistoricalDTO {
              current
            }
            ...on FundHoldingHistoricalDTO {
              q1Value
            }
            ...on InstHoldingHistoricalDTO {
              q1Value
            }
          }
        }
        activity
        archive
        stage
        pipelineConnection {
          id
          index
          label
          probability
          lost
        }
        profileConnection {
          items {
            firstName
            lastName
          }
        }
        revisions {
          date
          stage
          profileConnection {
            items {
              firstName
              lastName
            }
          }
        }
        created
      }
    }
  }`

export const DELETE_DEAL = gql`
  mutation Deal(
    $id: [String]!
  ) {
    deal {
      delete(
        id: $id
      ) {
        count
      }
    }
  }`

const GET_PIPELINE = gql`
  query pipeline {
    pipeline {
      items {
        id
        index
        label
        lost
      }
    }
  }`

/**
 * Deal
 * @param options
 */
export function useDealQuery (options) {
  return useQuery(GET_DEAL, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Deal delete
 * @param options
 */
export function useDealDeleteQuery (options) {
  return useMutation(DELETE_DEAL, options)
}

/**
 * Pipeline
 * @param options
 */
export function usePipelineQuery (options) {
  return useQuery(GET_PIPELINE, options)
}
