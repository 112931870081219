import {
  GET_ADVANCED_SEARCH_REQUEST,
  GET_ADVANCED_SEARCH_SUCCESS,
  GET_ADVANCED_SEARCH_FAILURE,
  GET_ADVANCED_SEARCH_GQL_SUCCESS,
  GET_ADVANCED_SEARCH_GQL_REQUEST,
  GET_ADVANCED_SEARCH_GQL_FAILURE,
  GET_ADVANCED_SEARCH_V3_REQUEST,
  GET_ADVANCED_SEARCH_V3_SUCCESS,
  GET_ADVANCED_SEARCH_V3_FAILURE,
  GET_ADVANCED_SEARCH_FACETS_REQUEST,
  GET_ADVANCED_SEARCH_FACETS_SUCCESS,
  GET_ADVANCED_SEARCH_FACETS_FAILURE,
  GET_ELASTIC_SEARCH_REQUEST,
  GET_ELASTIC_SEARCH_SUCCESS,
  GET_ELASTIC_SEARCH_FAILURE,
  GET_TAG_SEARCH_REQUEST,
  GET_TAG_SEARCH_SUCCESS,
  GET_TAG_SEARCH_FAILURE,
  ADD_CONTACT_TO_ADDRESS_BOOK_SUCCESS,
  CREATE_TARGET_SUCCESS,
  REMOVE_TARGET_SUCCESS,
  CLEAR_ADVANCED_SEARCH,
  statusType
} from '../../actions'

import { getSearchPayload, getSearchPayloadV3, getGqlSearchPayload } from '../../utils/search/advanced.util'

const initial = {
  data: [],
  filter: {},
  facet: {
    data: {},
    status: statusType.IDLE
  },
  status: statusType.IDLE,
  timestamp: null
}

function actionIsValid (state, data) {
  return data.timestamp >= state.timestamp
}

const advancedSearch = (state = initial, action) => {
  const { type, payload = {} } = action

  switch (type) {
    case GET_ADVANCED_SEARCH_REQUEST:
    case GET_ADVANCED_SEARCH_GQL_REQUEST:
    case GET_ADVANCED_SEARCH_V3_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }
    case GET_ADVANCED_SEARCH_GQL_SUCCESS:
      if (actionIsValid(state, action)) {
        return {
          ...state,
          data: getGqlSearchPayload(payload),
          total: action.meta.total,
          status: statusType.SUCCESS,
          timestamp: action.timestamp
        }
      }
      return state

    case GET_ADVANCED_SEARCH_SUCCESS:
      if (actionIsValid(state, action)) {
        return {
          ...state,
          data: getSearchPayload(payload),
          total: action.meta.total,
          status: statusType.SUCCESS,
          timestamp: action.timestamp
        }
      }
      return state

    case GET_ADVANCED_SEARCH_V3_SUCCESS:
      if (actionIsValid(state, action)) {
        return {
          ...state,
          data: getSearchPayloadV3(payload),
          total: action.meta.total,
          status: statusType.SUCCESS,
          timestamp: action.timestamp
        }
      }
      return state

    case GET_ADVANCED_SEARCH_FACETS_REQUEST:
      return {
        ...state,
        facet: {
          data: {},
          status: statusType.IN_PROGRESS
        }
      }

    case GET_ADVANCED_SEARCH_FACETS_SUCCESS:
      return {
        ...state,
        facet: {
          data: payload,
          status: statusType.SUCCESS
        }
      }

    case GET_ADVANCED_SEARCH_FACETS_FAILURE:
      return {
        ...state,
        facet: {
          data: {},
          status: statusType.ERROR
        }
      }

    case GET_ADVANCED_SEARCH_FAILURE:
    case GET_ADVANCED_SEARCH_GQL_FAILURE:
    case GET_ADVANCED_SEARCH_V3_FAILURE:
      return {
        ...initial,
        status: statusType.ERROR
      }

    case GET_ELASTIC_SEARCH_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_ELASTIC_SEARCH_SUCCESS:
      if (actionIsValid(state, action)) {
        return {
          ...state,
          data: getSearchPayload(payload),
          total: action.meta.total,
          status: statusType.SUCCESS,
          timestamp: action.timestamp
        }
      }
      return state

    case GET_ELASTIC_SEARCH_FAILURE:
      return {
        ...initial,
        status: statusType.ERROR
      }

    case GET_TAG_SEARCH_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_TAG_SEARCH_SUCCESS:
      return {
        ...state,
        data: getSearchPayload(payload),
        total: action.meta.total,
        status: statusType.SUCCESS
      }

    case GET_TAG_SEARCH_FAILURE:
      return {
        ...initial,
        status: statusType.ERROR
      }

    case CREATE_TARGET_SUCCESS:
      const { reference = {} } = payload
      return {
        ...state,
        data: (state.data || []).map((item) => {
          if (item && item._id === reference.item) {
            item._target = payload._id
          }

          return item
        })
      }

    case REMOVE_TARGET_SUCCESS:
      return {
        ...state,
        data: (state.data || []).map((item) => {
          if (item && item._target === payload._id) {
            item._target = null
          }

          return item
        })
      }

    case ADD_CONTACT_TO_ADDRESS_BOOK_SUCCESS:
      const { _contact } = payload
      return {
        ...state,
        data: (state.data || []).map((item) => {
          if (item && item._id === _contact) {
            item._favorite = payload._id
          }

          return item
        })
      }

    case CLEAR_ADVANCED_SEARCH:
      return {
        ...initial,
        facet: state.facet
      }

    default:
      return state
  }
}

export default advancedSearch
