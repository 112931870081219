import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { addQueryParams } from '../../utils/http/http.util'

export const FETCH_ESTIMATES_PEER_REQUEST = 'FETCH_ESTIMATES_PEER_REQUEST'
export const FETCH_ESTIMATES_PEER_SUCCESS = 'FETCH_ESTIMATES_PEER_SUCCESS'
export const FETCH_ESTIMATES_PEER_ERROR = 'FETCH_ESTIMATES_PEER_ERROR'
export const ESTIMATE_PEER_CHANGED = 'ESTIMATE_PEER_CHANGED'

const resources = {
  peer: '/peer'
}

const _fetchPeer = (url, params) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_ESTIMATES_PEER_REQUEST,
      FETCH_ESTIMATES_PEER_SUCCESS,
      FETCH_ESTIMATES_PEER_ERROR
    ],
    method: METHOD_TYPE.GET,
    payload: params
  }
})

export const peerChanged = (currentPeer) => (dispatch) => {
  return dispatch({ type: ESTIMATE_PEER_CHANGED, payload: currentPeer })
}

export const fetchPeer = (params) => (dispatch) => {
  const url = addQueryParams(resources.peer, params)
  return dispatch(_fetchPeer(url))
}
