import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { withFormik } from 'formik'
import { get } from 'lodash'

import { isParent as _isParent, isItinerary as _isItinerary } from '../../../utils/activity'

import { Modal } from '../../../components'
import ActivityType from './activityType/activityType.component'
import ActivityTitle from './activityTitle/activityTitle.component'
import ActivityDate from './activityDate/activityDate.component'
import ActivityDeal from './activityDeal/activityDeal.component'
import ActivityLocation from './activityLocation/activityLocation.component'
import ActivityMultiLocation from './activityMultiLocation/activityMultiLocation.component'
import ActivityHtmlEditor from './activityHtmlEditor/activityHtmlEditor.component'
import ActivityTags from './activityTags/activityTags.component'
import ActivityFormInitialValues from './activityFormInitialValues.const'
import ActivityAttendees from './activityAttendees/activityAttendees.component'
import ActivityParticipants from './activityParticipants/activityParticipants.component'
import { DATA_IDS } from './activityForm.definition'

class ActivityForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      modalType: null,
      isContactModalVisible: false,
      deal: null
    }
  }

  componentDidMount () {
    const { values: { _deal } } = this.props

    _deal && this.setState({ deal: _deal })
  }

  getModalButtons = () => {
    const { values, isLoading } = this.props
    const buttons = [
      {
        label: 'Cancel',
        ui: 'shaded',
        dataId: DATA_IDS.ACTIVITY_MODAL_CANCEL_BUTTON,
        onClick: this.onClose
      },
      {
        label: 'Save',
        ui: 'citrus',
        dataId: DATA_IDS.ACTIVITY_MODAL_SAVE_BUTTON,
        onClick: this.onSave,
        disabled: isLoading
      },
      {
        label: 'Save & Close',
        ui: 'citrus',
        dataId: DATA_IDS.ACTIVITY_MODAL_SAVE_AND_CLOSE_BUTTON,
        onClick: this.onSaveAndClose,
        disabled: isLoading
      }
    ]

    if (_isParent(get(values, 'category'))) {
      buttons.push({
        label: 'Save & Build Itinerary',
        ui: 'citrus',
        onClick: this.onBuildItinerary,
        disabled: isLoading
      })
    }

    if (_isItinerary(get(values, '_activity'))) {
      buttons.push({
        label: 'Save & Add Another',
        ui: 'citrus',
        onClick: this.onItineraryAddAnother,
        disabled: isLoading
      })
    }

    return buttons
  }

  onClose = () => {
    const { onClose, dirty } = this.props
    onClose && onClose(dirty)
  }

  onSave = () => {
    const { onSave, values } = this.props
    onSave && onSave(values)
  }

  onSaveAndClose = () => {
    const { onSaveAndClose, values } = this.props
    onSaveAndClose && onSaveAndClose(values)
  }

  onBuildItinerary = () => {
    const { onBuildItinerary, values } = this.props
    onBuildItinerary && onBuildItinerary(values)
  }

  onItineraryAddAnother = () => {
    const { onItineraryAddAnother, values } = this.props
    onItineraryAddAnother && onItineraryAddAnother(values, false, () => {
      this.editDeal(null)
    })
  }

  openCloseContactModal = (type) => {
    const { isContactModalVisible } = this.state
    this.setState({
      isContactModalVisible: !isContactModalVisible,
      modalType: type
    })
  }

  /**
   * Adds/Removes deal from UI state and Formik value
   * @param option
   */
  editDeal = (option) => {
    const { clearSearchData, deals: { data }, setFieldValue } = this.props
    const deal = option && option.value ? (data || []).find((deal) => deal && deal._id === option.value) : option

    this.setState({ deal }, () => {
      setFieldValue('_deal', (deal && deal._id) || null)
      clearSearchData()
    })
  }

  render () {
    const {
      isEdit,
      isLoading,
      isModalVisible,
      setFieldValue,
      values,
      errors,
      serverError,
      touched,
      handleChange,
      handleBlur,
      getDeals,
      searchLocation,
      locations,
      deals,
      onSearchEntity,
      searchEntityState,
      activityFormState,
      corporateParticipants,
      findParticipants,
      findTags,
      searchedTags,
      clearSearchData,
      newParticipant,
      clearCreatedParticipant,
      onSearchEntityClear,
      isParent,
      isFieldsHidden,
      isParentOptionHidden,
      profile
    } = this.props

    const { deal, modalType, isContactModalVisible } = this.state
    const isItinerary = _isItinerary(get(values, '_activity'))
    let title = `${isEdit ? 'Edit' : 'Create New'} Activity`
    let badge = 'q4i-activity-2pt'
    let subtitle = ''

    if (isItinerary) {
      title = `${isEdit ? 'Edit' : 'Create'} Itinerary Activity`
      subtitle = get(values, '_activity.item.title') || get(values, '_activity.title')
      badge = 'q4i-itinerary-2pt'
    }

    return (
      <Modal
        disableRestoreFocus
        dataId={DATA_IDS.ACTIVITY_MODAL_FORM}
        visible={isModalVisible}
        loading={isLoading}
        fullscreen={true}
        badge={badge}
        title={title}
        subtitle={subtitle}
        footerButtons={this.getModalButtons()}
        contentPadding={'24px 32px 56px'}
        onClose={this.onClose}
      >
        <Grid container spacing={3} className='full-screen-form activity-form'>
          {/* LEFT COLUMN */}
          <Grid item xs={8} className='full-screen-form_column full-screen-form_column--left'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ActivityType
                  isEdit={isEdit}
                  isParent={isParent}
                  isParentOptionHidden={isParentOptionHidden}
                  isItinerary={isItinerary}
                  setFieldValue={setFieldValue}
                  value={get(values, 'category')}
                />
              </Grid>
              <Grid item xs={12}>
                <ActivityTitle
                  values={values}
                  errors={errors}
                  serverError={serverError}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <ActivityDate
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              {!_isParent(values.category) && (
                <Grid item xs={12}>
                  <ActivityLocation
                    values={values}
                    setFieldValue={setFieldValue}
                    searchLocation={searchLocation}
                    locations={locations}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    clearSearchData={clearSearchData}
                  />
                </Grid>
              )}

              {_isParent(values.category) && (
                <Grid item xs={12}>
                  <ActivityMultiLocation
                    address={get(values, 'address')}
                    itinerary={get(values, '_itinerary')}
                    virtual={get(values, 'virtual')}
                    setFieldValue={setFieldValue}
                    searchLocation={searchLocation}
                    search={locations}
                    clearSearchData={clearSearchData}
                    isEdit={isEdit}
                    activityFormState={activityFormState}
                  />
                </Grid>
              )}
              {!isFieldsHidden.includes('_deal') && !_isParent(values.category) && (
                <Grid item xs={12}>
                  <ActivityDeal
                    deal={deal}
                    deals={deals}
                    getDeals={getDeals}
                    editDeal={this.editDeal}
                    clearSearchData={clearSearchData}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <ActivityHtmlEditor
                  body={values.body}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* RIGHT COLUMN */}
          <Grid item xs={4} className='full-screen-form_column full-screen-form_column--right'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ActivityTags
                  data={searchedTags}
                  tags={get(values, 'tag', [])}
                  setFieldValue={setFieldValue}
                  clearSearchData={clearSearchData}
                  findTags={findTags}
                  activityFormState={activityFormState}
                />
              </Grid>

              <Grid item xs={12}>
                <ActivityParticipants
                  data={corporateParticipants.data}
                  loading={corporateParticipants.status}
                  participants={values.participants}
                  findParticipants={findParticipants}
                  setFieldValue={setFieldValue}
                  clearSearchData={clearSearchData}
                  newParticipant={newParticipant}
                  clearCreatedParticipant={clearCreatedParticipant}
                  isContactModalVisible={isContactModalVisible}
                  openCloseParticipantModal={this.openCloseContactModal}
                  modalType={modalType}
                  activityFormState={activityFormState}
                />
              </Grid>

              <Grid item xs={12}>
                {!_isParent(values.category) && (
                  <ActivityAttendees
                    links={get(values, 'links')}
                    addressBookLinks={get(values, 'addressBookLinks')}
                    setFieldValue={setFieldValue}
                    searchEntityState={searchEntityState}
                    activityFormState={activityFormState}
                    onSearchEntity={onSearchEntity}
                    onSearchEntityClear={onSearchEntityClear}
                    isContactModalVisible={isContactModalVisible}
                    openCloseContactModal={this.openCloseContactModal}
                    isEdit={isEdit}
                    modalType={modalType}
                    profile={profile}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    )
  }
}

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: (props) => Object.assign({}, ActivityFormInitialValues || {}, props.values || {})
})(ActivityForm)
