import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { addQueryParams } from '../../utils/http/http.util'

export const PEER_CONFIG_DEFAULT_PEERS_REQUEST = 'PEER_CONFIG_DEFAULT_PEERS_REQUEST'
export const PEER_CONFIG_DEFAULT_PEERS_SUCCESS = 'PEER_CONFIG_DEFAULT_PEERS_SUCCESS'
export const PEER_CONFIG_DEFAULT_PEERS_FAILURE = 'PEER_CONFIG_DEFAULT_PEERS_FAILURE'

export const PEER_CONFIG_SEARCH_PEERS_REQUEST = 'PEER_CONFIG_SEARCH_PEERS_REQUEST'
export const PEER_CONFIG_SEARCH_PEERS_SUCCESS = 'PEER_CONFIG_SEARCH_PEERS_SUCCESS'
export const PEER_CONFIG_SEARCH_PEERS_FAILURE = 'PEER_CONFIG_SEARCH_PEERS_FAILURE'

export const PEER_CONFIG_CLEAR_PEERS = 'PEER_CONFIG_CLEAR_PEERS'
export const PEER_CONFIG_REMOVE_PEER = 'PEER_CONFIG_REMOVE_PEER'
export const PEER_CONFIG_ADD_PEER = 'PEER_CONFIG_ADD_PEER'
export const PEER_CONFIG_FOUND_PEERS_RESET = 'PEER_CONFIG_FOUND_PEERS_RESET'
export const PEER_CONFIG_SET_PEERS = 'PEER_CONFIG_SET_PEERS'
export const PEER_CONFIG_RESET = 'PEER_CONFIG_RESET'

const resources = {
  peer: '/peer',
  search: '/search/quick',
  searchV2: '/search/v2/watchlist'
}

const fetchPeers = (url, options) => ({
  [CALL_API]: {
    types: options && options.types
      ? options.types
      : [PEER_CONFIG_DEFAULT_PEERS_REQUEST, PEER_CONFIG_DEFAULT_PEERS_SUCCESS, PEER_CONFIG_DEFAULT_PEERS_FAILURE],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
})

const quickSearchPeers = (url, options) => ({
  [CALL_API]: {
    types: options && options.types
      ? options.types
      : [PEER_CONFIG_SEARCH_PEERS_REQUEST, PEER_CONFIG_SEARCH_PEERS_SUCCESS, PEER_CONFIG_SEARCH_PEERS_FAILURE],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
})

export const loadPeers = (params, types) => (dispatch) => {
  const url = addQueryParams(resources.peer, params)
  return dispatch(fetchPeers(url, { types }))
}

export const searchPeers = (params, types) => (dispatch) => {
  const url = addQueryParams(resources.search, params)
  return dispatch(quickSearchPeers(url, { types }))
}

export const searchPeersV2 = (params, types) => (dispatch) => {
  const url = addQueryParams(resources.searchV2, params)
  return dispatch(quickSearchPeers(url, { types }))
}

export const clearPeers = (type = PEER_CONFIG_CLEAR_PEERS) => (dispatch) => {
  dispatch({ type })
}

export const removePeer = (securityId, type = PEER_CONFIG_REMOVE_PEER) => (dispatch) => {
  dispatch({ type, payload: securityId })
}

export const addPeer = (peer, type = PEER_CONFIG_ADD_PEER) => (dispatch) => {
  dispatch({ type, payload: peer })
}

export const resetAutoCompletePeers = (type = PEER_CONFIG_FOUND_PEERS_RESET) => (dispatch) => {
  dispatch({ type })
}

export const setPeers = (peers, type = PEER_CONFIG_SET_PEERS) => (dispatch) => {
  dispatch({ type, payload: peers })
}

export const resetPeerConfig = (type = PEER_CONFIG_RESET) => (dispatch) => {
  dispatch({ type })
}
