import React, { memo } from 'react'
import PropTypes from 'prop-types'

// utils
import { getClassName } from '../../../../utils/ui'
import { chartTypeOptions } from '../../../../utils/report'

// css
import './chartStyle.component.css'

function ReportChartStyleConfig (props) {
  const handleChartTypeChange = (chartTypeOption) => (event) => props.onChange(chartTypeOption, event)

  let propsWidgetType = props.widgetType
  const mappedChartTypeOptions = chartTypeOptions.map((chartTypeOption) => {
    if (props.isActivityReport && chartTypeOption.widgetType !== 'table') {
      return {
        ...chartTypeOption,
        disabled: true
      }
    }
    return chartTypeOption
  })

  const selected = mappedChartTypeOptions.find((chartTypeOption) => {
    const { widgetType, disabled } = chartTypeOption
    return !disabled && propsWidgetType === widgetType
  })

  if (!selected) {
    propsWidgetType = 'table'
    const found = mappedChartTypeOptions.find((chartTypeOption) => {
      return chartTypeOption.widgetType === propsWidgetType
    })
    found && props.onChange(found)
  }

  return (
    <ul className='report-chart-type-list'>
      {mappedChartTypeOptions.map((chartTypeOption) => {
        const { label, icon, widgetType, chartType, disabled } = chartTypeOption

        const isTableWidget = widgetType === 'table'
        const isSelected = isTableWidget ? propsWidgetType === widgetType : props.chartType === chartType
        const itemClassName = getClassName('report-chart-type-list_item', [
          { condition: isSelected, trueClassName: 'report-chart-type-list_item--selected' },
          { condition: chartType, trueClassName: `report-chart-type-list_item--${chartType}` },
          { condition: !!disabled, trueClassName: 'report-chart-type-list_item--disabled' }
        ])

        return (
          <li
            key={`report-chart-type--${isTableWidget ? widgetType : chartType}`}
            className={itemClassName}
            onClick={handleChartTypeChange(chartTypeOption)}
          >
            <div className='report-chart-type-list_block'><i className={icon}/></div>
            <span className='report-chart-type-list_label'>{label}</span>
          </li>
        )
      })}
    </ul>
  )
}

ReportChartStyleConfig.propTypes = {
  widgetType: PropTypes.oneOf(['table', 'chart']),
  chartType: PropTypes.oneOf(['bar', 'column', 'pie', 'line', 'combo']),
  onChange: PropTypes.func.isRequired,
  isActivityReport: PropTypes.bool
}

export default memo(ReportChartStyleConfig)
