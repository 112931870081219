import React, { useState, useRef, memo } from 'react'
import PropTypes from 'prop-types'

// components
import { Button, PopoverMenu, Select, Tag } from '../index'

// utils
import { getClassName, removeUnescapedCharacters } from '../../utils'
import { get } from 'lodash'

import './tagInput.component.scss'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.string,
  search: PropTypes.string,
  isSearching: PropTypes.bool.isRequired,
  suggestions: PropTypes.array.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  limit: PropTypes.number.isRequired,
  onSearch: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  tags: [],
  isSearching: false,
  suggestions: []
}

const ENTER = [13, 32]
const ARROWS = [38, 40]

/**
 * Normalize suggestions
 * @param suggestions
 */
function getOptions (suggestions) {
  return (suggestions || []).map(({ id, name }) => id && ({ value: id, label: name }))
}

/**
 * Tag Input Component
 * @param props
 */
function TagInputComponent (props) {
  const { dataId, theme, search, isSearching, suggestions, tags, limit, onSearch, onCreate, onDelete, history } = props
  const options = getOptions(suggestions)

  const selectReference = useRef()
  const select = get(selectReference, 'current.select')

  const [state, setState] = useState({ isInput: false, more: {} })
  const { isInput, more } = state

  let previous = null
  let isInputValue = false

  /**
   * Handle add new tag button click
   */
  const handleAddClick = () => {
    const input = select && select.inputRef

    setState({ ...state, isInput: true })
    input && input.focus()
  }

  /**
   * Handle new tag create
   * @param value
   */
  const handleCreate = (value) => {
    const name = removeUnescapedCharacters(value)
    const exist = tags.some((tag) => get(tag, 'name', '').toLowerCase() === (name || '').toLowerCase())

    !exist && onCreate({ name, onSuccess: () => { isInputValue = false } })
  }

  /**
   * Handle tag delete
   * @param event
   * @param tag
   */
  const handleDelete = (event, tag) => {
    event.stopPropagation()

    const { id } = (tag || {})
    id && onDelete({ id })
  }

  /**
   * Handle suggestion select
   * Called every time suggestion is selected via mouse or keyboard
   * @param option
   */
  const handleSelectionChange = (option) => {
    const { label } = (option || {})
    !isInputValue && handleCreate(label)
  }

  /**
   * Handle key down event
   * @param event
   */
  const handleKeyDown = (event) => {
    const { keyCode } = (event || {})

    if (ENTER.includes(keyCode) && !ARROWS.includes(previous)) {
      isInputValue = true
      select && select.setValue(null)
      handleCreate(search)
    }

    previous = keyCode
  }

  /**
   * Handle tag click
   * @param event
   * @param tag
   */
  const handleTagClick = (event, tag) => {
    event.stopPropagation()
    history.push(`/search?query=${encodeURIComponent('#' + tag.name)}`)
  }

  /**
   * Handle more tags click
   * @param event
   * @param id
   */
  const handleMoreClick = (event, id) => {
    event.stopPropagation()
    setState({ ...state, more: { [id]: event.currentTarget } })
  }

  /**
   * Handle more tags close
   * @param event
   */
  const handleMoreClose = (event) => {
    event.stopPropagation()
    setState({ ...state, more: {} })
  }

  return (
    <>
      <div
        data-id={dataId}
        className={getClassName('tag-input', [
          { condition: theme, trueClassName: `tag-input--${theme}` }
        ])}>
        <header className='tag-input_header'>Tags</header>
        <Tag
          id='tag-input-component'
          theme={theme}
          className={`tag-input-component tags--thin tags--wide tags--in-component`}
          items={tags}
          limit={limit}
          deletable
          onClick={handleTagClick}
          onDelete={handleDelete}
          onMoreClick={handleMoreClick}
        />
        <Button
          className={getClassName('tag-input_add', [
            { condition: isInput, trueClassName: 'tag-input_add--hidden' },
            { condition: theme, trueClassName: `tag-input_add--${theme}` }
          ])}
          icon='q4i-add-4pt'
          onClick={handleAddClick}
        />

        <div className={getClassName('tag-input_search', [
          { condition: isInput, trueClassName: 'tag-input_search--expanded' }
        ])}>
          <Select
            dataId={{ inputId: `${dataId}TagSearchInput` }}
            size='thin'
            theme={theme}
            selectReference={selectReference}
            placeholder='Press ENTER or SPACE to add tags'
            loading={isSearching}
            value={null}
            options={options}
            onInputChange={onSearch}
            onChange={handleSelectionChange}
            onKeyDown={handleKeyDown}
            onBlur={() => setState({ ...state, isInput: false })}
            showDropdownIndicator={false}
          />
        </div>
      </div>

      <PopoverMenu
        theme={theme}
        items={tags.slice(limit)}
        anchorEl={more['tag-input-component']}
        open={Boolean(more['tag-input-component'])}
        arrowClass='bottom-right'
        onClick={handleTagClick}
        onClose={handleMoreClose}
        onDelete={handleDelete}
        renderLabel={(item) => item.name}
        deletable
        scrollable
        isMargin
      />
    </>
  )
}

TagInputComponent.propTypes = propTypes
TagInputComponent.defaultProps = defaultProps

export default memo(TagInputComponent)
