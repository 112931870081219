import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { addQueryParams } from '../../../utils/http/http.util'

export const FETCH_INDEX_REQUEST = 'FETCH_INDEX_REQUEST'
export const FETCH_INDEX_SUCCESS = 'FETCH_INDEX_SUCCESS'
export const FETCH_INDEX_ERROR = 'FETCH_INDEX_ERROR'

export const SET_CURRENT_INDEX = 'SET_CURRENT_INDEX'

export const REMOVE_STOCK_INDEX = 'REMOVE_STOCK_INDEX'

const resources = {
  index: '/stock/index'
}

const setCurrentIndex = (payload) => ({
  type: SET_CURRENT_INDEX,
  payload
})

const fetchStockIndex = (url) => ({
  [CALL_API]: {
    types: [FETCH_INDEX_REQUEST, FETCH_INDEX_SUCCESS, FETCH_INDEX_ERROR],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
})

const removeIndex = (payload) => ({
  type: REMOVE_STOCK_INDEX,
  payload
})

export const loadStockIndex = (params) => (dispatch) => {
  const requestParams = {
    symbol: params.symbol,
    group: params.group,
    category: params.category,
    limit: 365
  }

  const newIndex = {
    name: params.name,
    symbol: params.symbol,
    group: params.group,
    category: params.category
  }

  const url = addQueryParams(resources.index, requestParams)

  dispatch(setCurrentIndex(newIndex))
  return dispatch(fetchStockIndex(url))
}

export const removeStockIndex = (params) => (dispatch) => {
  return dispatch(removeIndex(params))
}
