import React from 'react'
import { Banner } from '../../../../../../../components'
import { THEMES } from '../../../../../../../utils/ui'
import { ENTITY_CONTACT } from '../../../../../../../utils/entity'

/**
 * Detail Banner Component
 * @param props
 * @returns {*}
 * @constructor
 */

function FlyoutBanner ({ title, icon, theme, badgeType, details, data, handleClose, entityType, targetId, gdpr, hasDeal, handleTargetUtilAction, handleGDPRmodal, flyoutDetailsHistory }) {
  const utilityMenuOptions = [
    { action: 'CREATE_ACTIVITY' },
    {
      icon: 'q4i-savedtargets-2pt',
      label: targetId ? 'Remove from Targets' : 'Save as Target',
      hide: hasDeal,
      onClick: () => handleTargetUtilAction(targetId, { type: entityType, item: data._id })
    },
    { action: 'BRIEFING_BOOK' },
    { action: 'DOWNLOAD_TEARSHEET' },
    { action: 'REQUEST_ENTITY_UPDATE' },
    {
      action: 'ADDRESS_BOOK',
      hide: entityType !== ENTITY_CONTACT
    }
  ].filter((action) => !action.hide)

  const disclosure = gdpr ? {
    type: 'button',
    theme: THEMES.CHERRY,
    label: 'GDPR Forgotten',
    onClick: () => handleGDPRmodal()
  } : null

  const entity = {
    ...data,
    id: data.q4_entity_id || data._id,
    targetId
  }

  const controls = [
    {
      type: 'utility',
      theme: THEMES.LIGHT_GREY,
      className: `${entityType}-detail-page_utility`,
      entity,
      entityType: (entityType || '').toLowerCase(),
      items: utilityMenuOptions
    },
    {
      type: 'button',
      theme: THEMES.LIGHT_GREY,
      className: `${entityType}-detail-page_close`,
      icon: 'q4i-close-4pt',
      square: true,
      onClick: handleClose
    }
  ]

  return (
    <Banner
      title={title}
      icon={icon}
      size='medium'
      theme={theme}
      badgeType={badgeType}
      details={details}
      controls={controls}
      disclosure={disclosure}
    />
  )
}

export default FlyoutBanner
