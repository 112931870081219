import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'

import CustomCell from './cell/cell.component'
import { AgGrid, NoContentMessage, Spinner } from '../../../../../components'
import { getDefaultColumnDef, preventDefaultRowClick, isYetToFile, THEMES, getLocalizedCurrency } from '../../../../../utils'
import { get } from 'lodash'

const propTypes = {
  pageSizeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number,
  listOptions: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  onQueryChange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  loading: false,
  data: [],
  total: 0,
  listOptions: {}
}

/**
 * Current Holding Table Component
 * @param props
 */
function CurrentHoldingTable (props) {
  const { pageSizeId, loading, data, total, listOptions, onQueryChange, history } = props
  const [agGrid, setAgGrid] = useState({})

  const columns = [
    {
      field: 'securityName',
      headerName: 'Name',
      type: 'text',
      minWidth: 440,
      pinned: 'left',
      lockPinned: true
    },
    {
      field: 'securityCountryName',
      headerName: 'Location',
      type: 'text',
      minWidth: 220,
      maxWidth: 220
    },
    {
      field: 'current',
      headerName: 'Pos',
      type: 'number',
      minWidth: 140,
      maxWidth: 140
    },
    {
      field: 'qtrChange',
      headerName: 'Chg',
      type: 'change',
      minWidth: 140,
      maxWidth: 140
    },
    {
      field: 'marketValue',
      headerName: `Mkt Val (MM ${getLocalizedCurrency()})`,
      type: 'number',
      minWidth: 140,
      maxWidth: 140
    },
    {
      field: 'marketValueChange',
      headerName: `Chg (MM ${getLocalizedCurrency()})`,
      type: 'change',
      minWidth: 140,
      maxWidth: 140
    },
    {
      field: 'securityCapGroup',
      headerName: 'Mkt Cap',
      type: 'centered',
      minWidth: 100,
      maxWidth: 100
    },
    {
      field: 'percentTSO',
      headerName: '%OS',
      type: 'number',
      minWidth: 100,
      maxWidth: 100
    },
    {
      field: 'percentPortfolio',
      headerName: '%PORT',
      type: 'number',
      minWidth: 100,
      maxWidth: 100
    },
    {
      field: 'reportDate',
      headerName: 'As Of',
      type: 'date',
      minWidth: 120,
      maxWidth: 120
    },
    {
      field: 'filingSource',
      headerName: 'Source',
      type: 'centered',
      minWidth: 220,
      maxWidth: 220,
    }
  ]

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click event
   * @param id {String} - security ticker id
   */
  const handleRowClick = ({ tickerId }) => {
    history && tickerId && history.push(`/security/${tickerId}`)
  }

  /**
   * Handle page change
   * @param selected
   */
  const handlePageChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, page: selected }
    })
  }

  /**
   * Handle page size change
   * @param selected
   */
  const handlePageSizeChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, limit: selected, page: 1 }
    })
  }

  /**
   * Handle Column Sort
   * @param grid
   */
  const handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel && !sortModel.length) {
      return
    }

    onQueryChange({
      listOptions: {
        ...listOptions,
        sortBy: sortModel[0].colId,
        sortDir: sortModel[0].sort,
        page: 1
      }
    })
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell {...props} />
  }

  /**
   * Returns grid columns
   */
  const getColumnsDefinition = () => {
    return columns.map((column, idx) => {
      const { type } = column
      const columnDef = {
        ...getDefaultColumnDef({ columnIndex: (idx > 0 ? idx + 1 : idx), type }),
        ...column
      }

      if (type === 'qtrChange') {
        columnDef.cellClassRules['ag-cell-value--increase'] = ({ value, data }) => isYetToFile(get(data, 'reportDate'), get(data, 'qtrChange')) ? false : value > 0
        columnDef.cellClassRules['ag-cell-value--decrease'] = ({ value, data }) => isYetToFile(get(data, 'reportDate'), get(data, 'qtrChange')) ? false : value < 0
      }

      return columnDef
    })
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage />
        : <AgGrid
          domLayout='autoHeight'
          sizeToFit

          // suppress configs
          suppressMovableColumns
          suppressContextMenu

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            sortable: true,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={getColumnsDefinition()}
          rowData={data}

          // pagination
          pagination
          paginationProps={{
            pageSizeId,
            forcePage: listOptions.page,
            initialPageSize: listOptions.limit,
            showPageSizeSelection: true,
            total,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: renderCustomCell
          }}

          // event listeners
          onGridReady={handleGridReady}
          onGridSizeChanged={handleGridResize}
          onSortChanged={handleSortChange}
          onRowClicked={preventDefaultRowClick(
            handleRowClick, ['']
          )}

          isPinned={data.length}
        />}
    </div>
  )
}

CurrentHoldingTable.propTypes = propTypes
CurrentHoldingTable.defaultProps = defaultProps

export default memo(CurrentHoldingTable)
