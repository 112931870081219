import {
  SET_REPORT_DATA_CONFIG,
  RESET_REPORT_DATA_CONFIG,
  IDLE
} from '../../actions'

const initial = {
  entityType: [],
  availableEntityTypes: [],
  fields: [],
  pivotFields: [],
  availableFields: [],
  filters: [],
  availableFilters: [],
  limit: [],
  sort: [],
  indices: [],
  isPivot: false,
  _pivotQuarters: {},
  status: IDLE
}

const reportDataConfig = (state = initial, action) => {
  switch (action.type) {
    case SET_REPORT_DATA_CONFIG:
      return {
        ...state,
        ...action.payload
      }
    case RESET_REPORT_DATA_CONFIG:
      return initial
    default:
      return state
  }
}

export default reportDataConfig
