import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getClassName, THEMES } from '../../../utils'

import './rangeTab.component.scss'

const propTypes = {
  theme: PropTypes.oneOf([
    THEMES.SOFT_GREY, // deprecate soft_grey theme
    THEMES.RAIN,
    THEMES.Q4_BLUE,
    THEMES.STEEL,
    THEMES.INK,
    THEMES.LIGHT,
    THEMES.DARK
  ]),
  buttons: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
    icon: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    counter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired
  }))
}

const defaultProps = {
  theme: THEMES.Q4_BLUE
}

/**
 * Range Tab Component
 * @param props
 */
function RangeTab (props) {
  const { buttons, theme } = props

  return (
    <div className={`range-tab range-tab--${theme}`}>
      {(buttons || []).map((button, index) => {
        const { dataId, active, icon, label, value, counter, disabled, onClick } = button

        const className = getClassName('range-tab_button', [
          { condition: active, trueClassName: 'range-tab_button--active' },
          { condition: disabled, trueClassName: 'range-tab_button--disabled' },
          { condition: !label, trueClassName: 'range-tab_button--empty' }
        ])

        return (
          <button
            data-id={dataId || null}
            key={`range-tab_button-${index}`}
            className={className}
            disabled={disabled}
            onClick={(event) => onClick(event, value)}
          >
            {icon ? <i className={`range-tab_icon ${icon}`} /> : null}
            <div className='range-tab_label'>
              {label}
              {!isNaN(counter) ? <div className='range-tab_counter'>{counter}</div> : null}
            </div>
          </button>
        )
      })}
    </div>
  )
}

RangeTab.propTypes = propTypes
RangeTab.defaultProps = defaultProps

export default memo(RangeTab)
