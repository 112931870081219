import { config } from '../../config'
import { isUndefined, find } from 'lodash'
import { widgetMinH, widgetMinW } from './report.const'

/**
 * Basic rectangular collision detection
 * @param newRect
 * @param existingRect
 * @returns {boolean}
 * @private
 */
const _getIsColliding = (newRect, existingRect) => {
  const hCollision = (newRect.x < existingRect.x + existingRect.w) && (newRect.x + newRect.w > existingRect.x)
  const vCollision = (newRect.y < existingRect.y + existingRect.h) && (newRect.y + newRect.h > existingRect.y)

  return hCollision && vCollision
}

/**
 * Get next available 'i' value for react-grid-layout template item
 * @param template
 */
export const getLayoutItemId = (template) => {
  let idx = 0
  let i = idx

  const _findId = (each) => each.i === idx.toString()
  do {
    const layoutItem = find(template, _findId)
    if (isUndefined(layoutItem)) {
      i = idx.toString()
      break
    }
    idx++
  }
  while (true)

  return i
}

/**
 * Get next available layoutItem based off of desired width and height
 * If no space is available for the desired item, null will be returned
 * @param template
 * @param colCount
 * @param rowCount
 * @param desiredW
 * @param desiredH
 */
export const getAvailableLayoutItem = (template, colCount, rowCount, desiredW = 1, desiredH = 1) => {
  const defaultRectProps = {
    maxW: colCount,
    maxH: rowCount,
    minW: widgetMinW,
    minH: widgetMinH
  }

  if (!template || !template.length) {
    return {
      x: 0,
      y: 0,
      w: desiredW,
      h: desiredH,
      ...defaultRectProps
    }
  }

  if (!colCount || !rowCount) {
    return null
  }

  let gridX = 0
  let gridY = 0
  let newGridRect
  let collidingRect

  const findCollidingRect = function (newGridRect) {
    return template.find((existingGridRect) => {
      return _getIsColliding(newGridRect, existingGridRect)
    })
  }

  // Simulate a grid item for every possible co-ordinate
  for (gridX; gridX <= colCount - desiredW; gridX++) {
    for (gridY = 0; gridY <= rowCount - desiredH; gridY++) {
      newGridRect = { x: gridX, y: gridY, w: desiredW, h: desiredH, ...defaultRectProps }

      // determine if the new block will collide with any existing grid blocks
      collidingRect = findCollidingRect(newGridRect)

      // if no colliding block is found, break the sequence
      if (!collidingRect) {
        break
      }
    }

    if (!collidingRect) {
      break
    }
  }

  // lastly, check to see if the new rectangle actually fits inside the grid bounds
  return !collidingRect && _getIsColliding(newGridRect, { x: 0, y: 0, w: colCount, h: rowCount }) ? newGridRect : null
}

/**
 * Get desiredW value based on widget type
 * @param widgetType
 * @param maxW
 */
export const getDesiredW = (widgetType, maxW) => {
  const widthMap = {
    data: maxW,
    text: maxW
  }

  return widthMap[widgetType] || maxW
}

/**
 * Get desiredH value based on widget type
 * @param widgetType
 * @param maxH
 */
export const getDesiredH = (widgetType, maxH) => {
  const widthMap = {
    data: Math.floor(maxH / 2),
    text: 5
  }

  return widthMap[widgetType] || maxH
}

/**
 * Get minimum desiredW value based on widget type if regular desiredW is not available
 * @param widgetType
 * @param maxW
 */
export const getMinDesiredW = (widgetType, maxW) => {
  const widthMap = {
    data: Math.floor(maxW / 2),
    text: Math.floor(maxW / 2)
  }

  return widthMap[widgetType] || maxW
}

/**
 * Calculate whether or not to update the layout based on differences in layoutItem
 * @param oldItem
 * @param newItem
 */
export const getShouldUpdateLayout = (oldItem, newItem) => {
  if (!oldItem || !newItem) {
    return true
  }

  return (
    oldItem.x !== newItem.x ||
        oldItem.y !== newItem.y ||
        oldItem.w !== newItem.w ||
        oldItem.h !== newItem.h
  )
}

/**
 * Get ReportWidget height based on
 * @param layoutItem
 * @param layout
 */
export const getWidgetHeight = (layoutItem, layout) => {
  const { pageHeight, rowCount } = config.pageBuilder
  const { h } = layoutItem

  const rowHeight = pageHeight / rowCount[layout || 'landscape']

  return Math.floor((h || 1) * rowHeight)
}
