import React from 'react'
import moment from 'moment-timezone'
import { ExpandableCell } from '../../../../agGrid/cell'
import { format } from '../../../../../utils/number.util'
import { config } from '../../../../../config'

import './cell.component.css'

import { formatLocalizedDate } from '../../../../../utils/date.util'

const EMPTY_PLACEHOLDER = '-'

/**
 * Text Cell with truncation styles
 * @param value
 * @return {*}
 * @constructor
 */
const TextCell = ({ value }) => {
  return (
    <div className='cell'>
      <div className='cell_value'>{value}</div>
    </div>
  )
}

/**
 * Email Custom Cell Component
 * @param email
 */
const EmailCell = ({ email }) => {
  return (
    <div className='cell cell--email'>
      {email ? (<a href={`mailto:${email}?Subject=Contact%20from%20Q4%20Desktop&bcc=${config.loggerEmail}`}>{email}</a>) : EMPTY_PLACEHOLDER}
    </div>
  )
}

const TitleCell = ({ item }) => {
  const name = item.name
  const isTarget = Array.isArray(item._target) && item._target.length
  const isDeal = Array.isArray(item._deal) && item._deal.length

  return <div className='cell cell--title'>
    <div className='cell_title'>
      {name}
    </div>
    {
      isTarget ? <i className={'cell_icon cell_icon--target q4i-targeting-4pt'} /> : null
    }
    {
      isDeal ? <i className={'cell_icon cell_icon--deal q4i-deal-4pt'} /> : null
    }
  </div>
}

const CustomCell = ({ data = {}, column = {}, onExpandMenuClick }) => {
  switch (column.colId) {
    case 'full_name':
      return <TitleCell item={data} />
    case 'title':
    case 'location': {
      return <TextCell value={data[column.colId]} />
    }
    case 'investmentFocus': {
      const investmentFocus = (data.investmentFocus || []).map((item) => ({ label: item }))
      return <ExpandableCell items={investmentFocus} onClick={onExpandMenuClick} />
    }
    case 'currentHolding': {
      return (!isNaN(data.currentHolding) && data.currentHolding !== null) ? format(data.currentHolding, 0) : EMPTY_PLACEHOLDER
    }
    case 'email': {
      return <EmailCell email={data.email} />
    }
    case 'lastActivity': {
      return data.lastActivity ? formatLocalizedDate(moment(data.lastActivity).format('MM/DD/YY'), 'MM/DD/YY') : EMPTY_PLACEHOLDER
    }
    default: {
      const cellData = data[column.colId]
      return cellData || EMPTY_PLACEHOLDER
    }
  }
}

export default CustomCell
