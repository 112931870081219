import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { getAllSavedTargets } from '../../targeting/savedTarget.actions'
import { getStages } from '../../../actions/stage/stage.actions'

/**
 * Pipeline Action Type Constants
 * @type {string}
 */
export const GET_PIPELINE_WIDGET_REQUEST = 'GET_PIPELINE_WIDGET_REQUEST'
export const GET_PIPELINE_WIDGET_SUCCESS = 'GET_PIPELINE_WIDGET_SUCCESS'
export const GET_PIPELINE_WIDGET_FAILURE = 'GET_PIPELINE_WIDGET_FAILURE'

/**
 * Get Pipeline for user's organization
 * @param params.pageSize
 * @param params.stage
 * @returns {{[p: string]: *}}
 * @private
 */
const _getPipeline = (params) => ({
  [CALL_API]: {
    types: [
      GET_PIPELINE_WIDGET_REQUEST,
      GET_PIPELINE_WIDGET_SUCCESS,
      GET_PIPELINE_WIDGET_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/pipeline',
    payload: Object.assign({
      pageSize: 200,
      sortField: 'create_date',
      sortDirection: 'ASC'
    }, params)
  }
})

/**
 * Dispatch GET_PIPELINE_WIDGET_REQUEST
 */
export const getPipeline = (params) => (dispatch) => {
  dispatch(getStages())
  dispatch(_getPipeline(params))
  dispatch(getAllSavedTargets())
}
