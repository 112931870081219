import { useEffect } from 'react';

const useBeforeUnload = (preventReload) => {
  useEffect(() => {
    const handler = (event) => {
      event.preventDefault();
      if (preventReload) {
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handler);
    return () => {
      window.removeEventListener('beforeunload', handler);
    };
  }, [preventReload]);
}

export default useBeforeUnload;
