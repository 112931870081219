import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

import { DOWNLOAD_API } from '../../../middleware/download.middleware'

import { addQueryParams } from '../../../utils/http/http.util'

export const PEER_ALERTS_REQUEST = 'PEER_ALERTS_REQUEST'
export const PEER_ALERTS_SUCCESS = 'PEER_ALERTS_SUCCESS'
export const PEER_ALERTS_FAILURE = 'PEER_ALERTS_FAILURE'

export const PEER_LIST_FETCH_REQUEST = 'PEER_LIST_FETCH_REQUEST'
export const PEER_LIST_FETCH_SUCCESS = 'PEER_LIST_FETCH_SUCCESS'
export const PEER_LIST_FETCH_FAILURE = 'PEER_LIST_FETCH_FAILURE'

export const PEER_ALERTS_RESEACH_DOWNLOAD_REQUEST = 'PEER_ALERTS_RESEACH_DOWNLOAD_REQUEST'
export const PEER_ALERTS_RESEACH_DOWNLOAD_SUCCESS = 'PEER_ALERTS_RESEACH_DOWNLOAD_SUCCESS'
export const PEER_ALERTS_RESEACH_DOWNLOAD_FAILURE = 'PEER_ALERTS_RESEACH_DOWNLOAD_FAILURE'

export const FETCHING = 'FETCHING'
export const FETCHED = 'FETCHED'
export const IDLE = 'IDLE'
export const ERROR = 'ERROR'

const resources = {
  peer: '/peer',
  peerAlerts: '/peerAlerts',
  research: '/research'
}

const fetchPeerAlerts = (url) => ({
  [CALL_API]: {
    types: [PEER_ALERTS_REQUEST, PEER_ALERTS_SUCCESS, PEER_ALERTS_FAILURE],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
})

const fetchPeerList = (url) => ({
  [CALL_API]: {
    types: [PEER_LIST_FETCH_REQUEST, PEER_LIST_FETCH_SUCCESS, PEER_LIST_FETCH_FAILURE],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
}
)

const downloadResearchDoc = (url, file) => ({
  [DOWNLOAD_API]: {
    endpoint: url,
    types: [
      PEER_ALERTS_RESEACH_DOWNLOAD_REQUEST,
      PEER_ALERTS_RESEACH_DOWNLOAD_SUCCESS,
      PEER_ALERTS_RESEACH_DOWNLOAD_FAILURE
    ],
    payload: {
      file
    },
    options: {
      headers: {
        'Content-Type': 'application/pdf'
      }
    }
  }
})

export const loadPeerAlerts = (params) => (dispatch) => {
  const url = addQueryParams(resources.peerAlerts, params)
  return dispatch(fetchPeerAlerts(url))
}

export const loadPeerList = (params) => (dispatch) => {
  const url = addQueryParams(resources.peer, params)
  return dispatch(fetchPeerList(url))
}

export const downloadResearch = (params) => (dispatch) => {
  const url = addQueryParams(`${resources.research}/story?key=${params.key}`)
  return dispatch(downloadResearchDoc(url, params.file))
}
