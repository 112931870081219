import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_COMMENT = gql`
  query Comment($entityId: [String]!) {
    comment(entityId: $entityId) {
      items {
        id
        comment
      }
    }
  }`

/**
 * Comment
 * @param options
 */
export function useCommentQuery (options) {
  return useQuery(GET_COMMENT, options)
}
