import {
  PEER_ALERTS_REQUEST,
  PEER_ALERTS_FAILURE,
  PEER_ALERTS_SUCCESS,
  IDLE,
  FETCHED,
  FETCHING,
  ERROR
} from '../../../actions/widget/watchlist/peer.actions'

const initial = {
  items: [],
  status: IDLE
}

const peerAlerts = (state = initial, action) => {
  switch (action.type) {
    case PEER_ALERTS_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case PEER_ALERTS_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        items: action.payload
      }
    case PEER_ALERTS_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ERROR
      }
    default:
      return state
  }
}

export default peerAlerts
