import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const GET_ENTITY_HIGHLIGHTS_REQUEST = 'GET_ENTITY_HIGHLIGHTS_REQUEST'
export const GET_ENTITY_HIGHLIGHTS_SUCCESS = 'GET_ENTITY_HIGHLIGHTS_SUCCESS'
export const GET_ENTITY_HIGHLIGHTS_FAILURE = 'GET_ENTITY_HIGHLIGHTS_FAILURE'
export const RESET_ENTITY_HIGHLIGHTS = 'RESET_ENTITY_HIGHLIGHTS'

const _fetchEntityHighlights = (entityId, endpoint, params) => ({
  [CALL_API]: {
    types: [
      GET_ENTITY_HIGHLIGHTS_REQUEST,
      GET_ENTITY_HIGHLIGHTS_SUCCESS,
      GET_ENTITY_HIGHLIGHTS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint,
    payload: params,
    queryOptions: { arrayFormat: 'index' }
  }
})

export const fetchEntityHighlights = (entityId, entityType, params) => (dispatch) => {
  const endpoint = entityType === 'contact'
    ? `/${entityType}/${entityId}/highlights`
    : `/ownership/v2/${entityType}/${entityId}/highlights`
  return dispatch(_fetchEntityHighlights(entityId, endpoint, params))
}

/**
 * Reset Entity Highlights
 */
export const resetEntityHighlights = () => (dispatch) => {
  return dispatch({ type: RESET_ENTITY_HIGHLIGHTS })
}
