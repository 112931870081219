import React from 'react'
import { Button, Toolbar, ToolbarRow } from '../../../../components'
import { RangeTab, SectionSearch } from '../../../../components/shared'

const CategoryFilter = ({ onClick, selectedFilter = '' }) => {
  const options = [
    { value: '', label: 'All' },
    { value: 'favorites', label: 'My Contacts' },
    { value: 'holders', label: 'Holders' },
    { value: 'peerHolders', label: 'Peer Holders' }
  ]

  const buttons = options.map((option) => ({
    ...option,
    active: selectedFilter === option.value,
    onClick: () => onClick({ value: option.value })
  }))

  return <RangeTab theme='ink' buttons={buttons} />
}

const ContactToolbar = ({ searchValue, filter, noData, handleQueryChange, handleExport }) => {
  const handleSearchChange = (search) => handleQueryChange({ query: search, page: 1 })
  const handleFilterChange = (filter) => handleQueryChange({ filter: filter.value, page: 1 })

  return (
    <Toolbar theme='q4-blue'>
      <ToolbarRow justified>
        <div className='toolbar_group'>
          <CategoryFilter onClick={handleFilterChange} selectedFilter={filter} />
        </div>
        <div className='toolbar_group'>
          <SectionSearch
            theme='ink'
            value={searchValue}
            onQueryChange={handleSearchChange}
            onClear={handleSearchChange}
          />
          <Button
            theme='ink'
            icon='q4i-download-4pt'
            label='Export'
            disabled={noData}
            onClick={handleExport}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

export default ContactToolbar
