import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { getClassName, getQ4LogoIcon, THEMES } from '../../utils/ui'
import './spinner.component.css'

/**
 * Spinner Component
 */
class Spinner extends PureComponent {
  /**
   * Render Spinner
   * @returns {*}
   */
  render () {
    const { fixed, theme, includeMessage, mask, maskOpacity, maskColor, className } = this.props

    const baseClassName = getClassName('spinner', [
      { condition: className, trueClassName: className },
      { condition: theme, trueClassName: `spinner--${theme}` },
      { condition: fixed, trueClassName: 'spinner--fixed' }
    ])

    const styles = {
      backgroundColor: (theme === THEMES.LIGHT) ? '#fff' : maskColor || '#22272b',
      opacity: isNaN(maskOpacity) ? 0.25 : maskOpacity
    }

    return (
      <div className={baseClassName}>
        {includeMessage && (
          <>
            <i className={`spinner_logo ${getQ4LogoIcon()}`} />
            <span className='spinner_message'>One moment... Making magic happen.</span>
          </>
        )}
        <div className='spinner_pulse' />
        {mask && (
          <div className='spinner_mask' style={styles} />
        )}
      </div>
    )
  }
}

Spinner.propTypes = {

  /**
   * Used to apply className to spinner
   */
  className: PropTypes.string,

  /**
   * Used to determine whether or not the loader renders above all window content using position: fixed
   * By default,
   */
  fixed: PropTypes.bool,

  /**
   * Used to provide the Spinner component with a specific theme
   */
  theme: PropTypes.string,

  /**
   * Used to determine whether or not a mask container is rendered alongside the spinner
   */
  mask: PropTypes.bool,

  /**
   * User to provide additional properties for the companion mask container
   */
  maskOpacity: PropTypes.number,
  maskColor: PropTypes.string,

  /**
   * User to provide an additional Q4 message alongside the pulse
   */
  includeMessage: PropTypes.bool
}

Spinner.defaultProps = {
  fixed: false,
  includeMessage: false
}

export default Spinner
