import _ from 'lodash';
import {config} from '../../../config';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {
    cloneDashboard,
    loadDashboard,
    removeDashboard,
    setActiveDashboard,
    updateDashboardById,
    cancelDashboardEdit
} from '../../../actions/dashboard/index';
import {sendMail} from '../../../actions/shared';
import {createToast} from '../../../actions';
import {getUrlSearchParams} from '../../../utils/http';
import ReactGridLayout from 'react-grid-layout-modified';
import {WidgetList} from '../../../data/widget';
import WidgetOptions from '../../widget/index';

//components
import { Message, Feedback } from '../../../components';
import WidgetError from '../../../components/widget/error/widgetError.component';
import WidgetConfiguration from '../../../components/widget/config/widgetConfig.component';
import WidgetConfigForm from '../../../components/widget/config/form/widgetConfigForm.component';
import WidgetLibrary from '../../../components/dashboard/widgetLibrary/widgetLibrary.component';
import DashboardEditHeader from '../../../components/dashboard/editHeader/dashboardEditHeader.component';

//css
import '../../../components/dashboard/view/dashboardView.component.css';

class Dashboard extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        const originDashboard = [...props.dashboards, ...props.defaultDashboards].find((d) => d._id === props.dashboardId);

        if (!originDashboard) {
            this.cancelEditDashboard();
            return;
        }

        const editLayout = [...originDashboard.layout.template];

        const mappedWidgets = _.filter(originDashboard.widget, (eachWidget) => {
            return _.indexOf(_.map(editLayout, 'i'), eachWidget.layout_id.toString()) >= 0;
        });

        this.state = {
            scrollMemory: 0,
            isConfigModalOpen: false,
            activeWidgetConfig: {},
            isNewDashboard: originDashboard && originDashboard.type === 1,
            isWidgetLibraryOpen: false,
            isDeleteWarningOpen: false,
            isModifyingState: false,
            isNewWidget: false,
            isFeedbackModalOpen: false,
            editLayoutItem: {},
            dashboardId: props.dashboardId,
            originDashboard,
            editDashboard: {
                ...originDashboard,
                layout: {
                    ...originDashboard.layout,
                    template: editLayout
                },
                widget: mappedWidgets
            }
        };
    }

    /**
     * ComponentWillMount
     */
    UNSAFE_componentWillMount() {
        if (!this.state || !this.state.dashboardId) {
            this.props.cancelEditDashboard();
        }

        if (!this.props.subscription) {
            const securityId = getUrlSearchParams().get('securityId');
            this.props.loadDashboard({
                securityId
            });
        }
    }

    /**
     * Update Dashboard
     * @param values
     */
    updateDashboard = (values) => {
        const {editDashboard, originDashboard, isModifyingState} = this.state;
        const {cloneDashboard, updateDashboardById} = this.props;

        if (isModifyingState) {
            return;
        }

        const dashboard = editDashboard;
        const dashboardId = editDashboard._id;

        this.setState({
            isModifyingState: true
        });

        const _body = {
            layout: dashboard.layout,
            widget: dashboard.widget
        };

        if ((values && values.title) !== originDashboard.title) {
            _body.title = values.title;
        }

        if (dashboard.type !== 2) {
            return cloneDashboard(dashboardId, _body, true);
        } else {
            return updateDashboardById(dashboardId, _body, true);
        }
    };

    /**
     * Cancel Dashboard Edit and return to Dashboard View
     */
    cancelEditDashboard = () => {
        const activeDashboard = _.find(this.props.dashboard || [], (item) => item._id === this.props.dashboardId);

        if (activeDashboard && activeDashboard.type === 1) {
            this.props.cancelEditDashboard(this.props.prevActiveDashboard);
        } else {
            this.props.cancelEditDashboard(this.props.dashboardId);
        }
    };

    /**
     * Delete the current Dashboard
     */
    removeDashboard = () => {
        const {isModifyingState} = this.state;
        const dashboardId = this.state.editDashboard._id;
        this.props.removeDashboard(dashboardId);

        if (isModifyingState) {
            return;
        }

        this.setState({isModifyingState: true});
    };

    /**
     * Open widget config modal
     * @param widget
     */
    openWidgetConfig = (widget) => {
        if (!widget) {
            return;
        }

        this.setState({
            activeWidgetConfig: widget,
            isConfigModalOpen: true
        });
    };

    /**
     * Close config Modal
     * Clear active widget scope
     */
    closeConfigModal = () => {
        this.setState({
            isConfigModalOpen: false,
            activeWidgetConfig: {}
        });
    };

    /**
     * Update widget config with new datas
     * @param data
     */
    updateWidgetConfigChange = (data) => {
        const {activeWidgetConfig, editDashboard} = this.state;
        const currentWidget = _.find((editDashboard.widget || []), (widget) => {
            return (
                parseInt(widget.id, 10) === parseInt(activeWidgetConfig.id, 10) &&
                parseInt(widget.layout_id, 10) === parseInt(activeWidgetConfig.layout_id, 10)
            );
        });

        if (currentWidget) {
            Object.assign(currentWidget, {
                config: data,
                edited: true
            });
        }
    };

    /**
     * Add new layout item to dashboard
     */
    addLayoutItem = () => {
        const {editDashboard} = this.state;
        const template = editDashboard.layout.template;
        let idx = 0;
        let i = idx;

        const maxY = _.max(_.map(template, (each) => {
            return each.y;
        })) || 0;

        const _findId = (each) => each.i === idx.toString();

        do {
            let layoutItem = _.find(template, _findId);
            if (_.isUndefined(layoutItem)) {
                i = idx.toString();
                break;
            }
            idx++;
        }
        while (true);

        const newLayoutItem = {i: i, h: 2, w: 2, x: 0, y: maxY + 1};

        template.push(newLayoutItem);
        this.openWidgetLibrary(newLayoutItem, true);

        this.setState({
            editDashboard: _.cloneDeep(editDashboard)
        });
    };

    /**
     * Add new Widget to the appropriate layout item
     * @param newWidget
     */
    addWidget = (newWidget) => {
        const {editDashboard, editLayoutItem, isNewWidget} = this.state;
        const dashboard = editDashboard;

        let currentWidget = _.find((dashboard.widget || []), (widget) => parseInt(widget.layout_id, 10) === parseInt(editLayoutItem.i, 10));
        let layoutItem = _.find((dashboard.layout.template || []), (item) => parseInt(item.i, 10) === parseInt(editLayoutItem.i, 10));

        if (currentWidget) {
            Object.assign(currentWidget, {
                id: parseInt(newWidget.id, 10),
                edited: true
            });
        }
        else {
            dashboard.widget.push({
                id: parseInt(newWidget.id, 10),
                layout_id: parseInt(editLayoutItem.i, 10),
                edited: true
            });
        }

        if (layoutItem) {
            layoutItem.minW = newWidget.minWidth;
            layoutItem.minH = newWidget.minHeight;
        }

        if (isNewWidget) {
            layoutItem.w = newWidget.idealWidth;
            layoutItem.h = newWidget.idealHeight;

            this.closeWidgetLibrary(true);
        } else {
            this.closeWidgetLibrary();
        }

        if (newWidget.config) {
            this.openWidgetConfig({
                id: newWidget.id,
                layout_id: editLayoutItem.i
            });
        }
    };

    /**
     * Delete Widget from appropriate layout item
     * @param layout
     */
    deleteWidget = (layout) => {
        const {editDashboard} = this.state;

        editDashboard.layout.template = editDashboard.layout.template.filter((l) => {
            return parseInt(l.i, 10) !== parseInt(layout.i, 10);
        });

        editDashboard.widget = editDashboard.widget.filter((w) => {
            return parseInt(w.layout_id, 10) !== parseInt(layout.i, 10);
        });

        this.setState({
            editDashboard: _.cloneDeep(editDashboard)
        });
    };

    /**
     * Open Feedback Modal
     */
    openFeedbackModal = () => {
        this.setState({
            isFeedbackModalOpen: true
        });
    };

    /**
     * Close Feedback Modal
     */
    closeFeedbackModal = () => {
        this.setState({
            isFeedbackModalOpen: false
        });
    };

    /**
     * Render Feedback Modal
     * @returns {function(): *}
     */
    renderFeedbackModal = () => {
        const {mail, sendMail, createToast} = this.props;
        const {isFeedbackModalOpen} = this.state;

        return () => {
            return (
                <Feedback
                    visible={isFeedbackModalOpen}
                    title='Suggest a Widget'
                    onClose={this.closeFeedbackModal}
                    mail={mail}
                    sendMail={sendMail}
                    createToast={createToast}/>
            );
        };
    };

    /**
     * Open Widget Library
     * @param editLayoutItem
     * @param isNewWidget
     */
    openWidgetLibrary = (editLayoutItem, isNewWidget) => {
        const body = document.getElementById('app-scrollable');
        const scrollMemory = body && body.scrollTop;

        this.setState({
            scrollMemory,
            editLayoutItem,
            isWidgetLibraryOpen: true,
            isNewWidget
        });
    };

    /**
     * Close Widget Library
     * @param scrollToBottom
     */
    closeWidgetLibrary = (scrollToBottom) => {
        const {scrollMemory} = this.state;
        const body = document.getElementById('app-scrollable');

        this.setState({
            isWidgetLibraryOpen: false
        }, () => {
            let top = scrollMemory;

            if (scrollToBottom) {
                top = body && body.scrollHeight;
            }

            body && body.scrollTo ? body.scrollTo({
                top,
                behavior: 'smooth'
            }) : body.scrollTop = top;
        });
    };

    /**
     * Open Delete Warning
     */
    openDeleteWarning = () => {
        this.setState({
            isDeleteWarningOpen: true
        });
    };

    /**
     * Close Delete Warning
     */
    closeDeleteWarning = () => {
        this.setState({
            isDeleteWarningOpen: false,
            dashboardToDelete: null
        });
    };

    /**
     * Save an updated layout on change
     * @param currentLayout
     */
    layoutChange = (currentLayout) => {
        const {editDashboard} = this.state;
        editDashboard.layout.template = _.cloneDeep(currentLayout);
        this.setState({
            editDashboard: _.cloneDeep(editDashboard)
        });
    };

    /**
     * Render Grid layout
     * @param dashboard
     */
    renderGridLayout = (dashboard) => {
        const {theme, subscription} = this.props;
        const dashboardWidgets = dashboard.widget || [];
        const dashboardLayout = (dashboard.layout && dashboard.layout.template) || [];
        const activeSubscription = _.reduce((subscription || []), (accumulator, item) => {
            if (item.enabled) {
                accumulator.push(item.type);
            }
            return accumulator;
        }, []);

        return dashboardLayout.map((layoutItem) => {
            const widget = _.find(dashboardWidgets, (widget) => parseInt(widget.layout_id, 10) === parseInt(layoutItem.i, 10));
            const actions = [
                {
                    icon: 'q4i-add-4pt',
                    onClick: () => this.openWidgetLibrary(layoutItem, false)
                },
                {
                    icon: 'q4i-trashbin-4pt',
                    onClick: () => this.deleteWidget(layoutItem)
                }
            ];

            if (widget) {
                const _Widget = _.find(WidgetList, (Widget) => Widget.id === widget.id);
                const Component = _Widget && WidgetOptions[_Widget.name];
                const _options = (_Widget && _Widget.options) || {};
                const availableSubscriptions = _.intersection(activeSubscription, (_Widget && _Widget.subscription) || []);
                const isUnlocked = (_Widget && (availableSubscriptions.length === _Widget.subscription.length)) ||
                    (_options.isPartialSubscription && availableSubscriptions.length > 0);

                // add config button if widget config is enabled
                if (_Widget && _Widget.config) {
                    actions.unshift({
                        icon: 'q4i-cog-4pt',
                        onClick: () => this.openWidgetConfig(widget)
                    });
                }

                if (isUnlocked) {
                    return (
                        <div className={`dashboard_widget ${widget.edited ? 'dashboard_widget--edited' : ''}`}
                             key={layoutItem.i}>
                            <Component
                                config={widget.config || {}}
                                options={_options}
                                layout={layoutItem}
                                subscriptions={availableSubscriptions}
                                dashboardId={dashboard._id}
                                isEdit={true}
                            />
                            <WidgetConfiguration
                                actions={actions}
                                widget={_Widget}
                                theme={theme}
                            />
                        </div>
                    );
                } else {
                    const errorMessage = !_Widget ? 'We\'re sorry but something went wrong.' : 'A subscription is required to view this widget. Contact us to learn more.';
                    return (
                        <div className='dashboard_widget' key={layoutItem.i}>
                            <WidgetConfiguration
                                actions={actions}
                                widget={_Widget}
                                theme={theme}
                            />
                            <WidgetError
                                theme={theme}
                                message={errorMessage}
                            />
                        </div>
                    );
                }
            }
            else {
                return (
                    <div className='dashboard_widget' key={layoutItem.i}>
                        <WidgetConfiguration
                            actions={actions}
                            theme={theme}
                        />
                    </div>
                );
            }
        });
    };

    /**
     * Render Dashboard Edit View
     * @returns {XML}
     */
    render() {
        // todo: find a better solution for scrolling and positioning when widget library is open
        if (!this.state || !this.state.dashboardId) {
            return null;
        }

        const {theme, region, subscription, dashboards} = this.props;
        const {
            editLayoutItem, editDashboard, isWidgetLibraryOpen, isDeleteWarningOpen, isModifyingState,
            isNewDashboard, isConfigModalOpen, activeWidgetConfig
        } = this.state;

        const widgetConfig = activeWidgetConfig && activeWidgetConfig.config;
        const widgets = WidgetList;
        const FeedbackModal = this.renderFeedbackModal();

        if (!editDashboard) {
            return (
                <div className='q4-fade-in'>
                    <Redirect to='error/404'/>
                </div>
            );
        }

        if (!subscription) {
            return null;
        }

        const layout = _.cloneDeep(editDashboard.layout.template);
        const deleteAvailable = !(editDashboard.type === 0 || editDashboard.type === 1 || dashboards.length < 2);
        const isAddDisabled = (editDashboard.layout.template.length >= config.dashboard.maxWidgetsPerDashboard);
        const buttonClassName = `button button--square button--tall ${theme === 'dark' ? '' : 'button--rain'} ${isAddDisabled ? 'button--disabled' : ''}`;
        const baseClassName = [
            'dashboard dashboard--edit',
            theme ? `dashboard--${theme}` : '',
            isWidgetLibraryOpen ? 'dashboard--locked' : ''
        ].join(' ');

        return (
            <div className={baseClassName}>
                <DashboardEditHeader
                    theme={theme}
                    dashboard={editDashboard}
                    deleteAvailable={deleteAvailable}
                    isModifyingState={isModifyingState}
                    isNewDashboard={isNewDashboard}
                    onSave={this.updateDashboard}
                    onCancel={this.cancelEditDashboard}
                    onDelete={this.openDeleteWarning}
                />
                <div className='dashboard_contain dashboard_inner'>
                    <div className={`dashboard_add ${isAddDisabled ? 'dashboard_add--disabled' : ''}`}>
                        <button
                            className={buttonClassName}
                            onClick={this.addLayoutItem}>
                            <i className='q4i-plus-4pt'/>
                            {isAddDisabled && (
                                <div className='tooltip tooltip--top-left'>Widget limit reached</div>
                            )}
                        </button>
                    </div>
                    <ReactGridLayout
                        className='react-grid'
                        layout={layout}
                        autoSize={true}
                        containerPadding={[0, 0]}
                        cols={4}
                        isDraggable={true}
                        isResizable={true}
                        width={config.dashboard.width}
                        onLayoutChange={this.layoutChange}
                        onResizeStop={this.layoutChange}>
                        {this.renderGridLayout(editDashboard)}
                    </ReactGridLayout>
                </div>

                <WidgetLibrary
                    layout={editLayoutItem}
                    widgets={widgets}
                    region={region}
                    subscription={subscription}
                    hidden={!isWidgetLibraryOpen}
                    onAdd={this.addWidget}
                    onSuggestion={this.openFeedbackModal}
                    onClose={this.closeWidgetLibrary}
                />

                {isConfigModalOpen && (
                    <WidgetConfigForm
                        data={widgetConfig || {}}
                        visible={isConfigModalOpen}
                        contentHeight={300}
                        containerWidth={500}
                        onClose={this.closeConfigModal}
                        onChange={this.updateWidgetConfigChange}
                    />
                )}

                <Message
                    visible={isDeleteWarningOpen}
                    type='warning'
                    title='Delete Dashboard?'
                    message='Are you sure you want to delete this dashboard? All widget configurations will be lost.'
                    onClose={this.closeDeleteWarning}
                    buttons={[
                        {
                            ui: 'shaded',
                            label: 'cancel',
                            onClick: this.closeDeleteWarning
                        },
                        {
                            ui: 'spice',
                            label: 'confirm',
                            onClick: this.removeDashboard
                        }
                    ]}
                />

                <FeedbackModal/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const dashboards = _.filter(state.dashboard.list.data || [], (each) => each.type === 2);
    const defaultDashboards = _.filter(state.dashboard.list.data || [], (each) => each.type !== 2);

    return {
        theme: state.dashboard.dashboardState.theme,
        dashboardId: state.dashboard.dashboardState.activeDashboard,
        prevActiveDashboard: state.dashboard.dashboardState.prevActiveDashboard,
        dashboards: (dashboards.length > 0) ? dashboards : defaultDashboards,
        defaultDashboards,
        subscription: state.shared.profile.services,
        region: state.shared.profile.region,
        mail: state.shared.mail,
        toast: state.toast
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadDashboard: bindActionCreators(loadDashboard, dispatch),
    updateDashboardById: bindActionCreators(updateDashboardById, dispatch),
    cloneDashboard: bindActionCreators(cloneDashboard, dispatch),
    removeDashboard: bindActionCreators(removeDashboard, dispatch),
    setActiveDashboard: bindActionCreators(setActiveDashboard, dispatch),
    cancelEditDashboard: bindActionCreators(cancelDashboardEdit, dispatch),
    sendMail: bindActionCreators(sendMail, dispatch),
    createToast: bindActionCreators(createToast, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
