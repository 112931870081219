import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { RangeTab, SectionSearch } from '../../../../shared'
import { Button, Select, Toolbar, ToolbarRow } from '../../../../index'
import { FUNCTIONS, THEMES } from '../../../../../utils'
import { isEmpty } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  noData: PropTypes.bool,
  search: PropTypes.string,
  filter: PropTypes.shape({
    all: PropTypes.bool,
    favorite: PropTypes.bool,
    holder: PropTypes.bool,
    peerHolder: PropTypes.bool,
    functions: PropTypes.array
  }),
  onQueryChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired
}

const defaultProps = {
  toolbarTheme: THEMES.Q4_BLUE,
  toolTheme: THEMES.INK,
  filter: {
    all: true,
    favorite: false,
    holder: false,
    peerHolder: false,
    functions: []
  }
}

const FILTER = [
  { label: 'All', value: 'all', dataId: 'FilterAll' },
  { label: 'My Contacts', value: 'favorite', dataId: 'FilterMyContacts' },
  { label: 'Holders', value: 'holder', dataId: 'FilterHolders' },
  { label: 'Peer Holders', value: 'peerHolder', dataId: 'FilterPeerHolders' }
]

/**
 * Contact Toolbar Component
 * @param props
 */
function ContactToolbar (props) {
  const { dataId, toolbarTheme, toolTheme, noData, filter, search, onQueryChange, onExport, exporting } = props
  const { functions } = filter || {}

  const functionSelected = FUNCTIONS.filter((option) => (functions || []).includes(option.value))

  const handleSearchChange = (search) => onQueryChange({ search })
  const handleFilterChange = (value, type) => {
    if (type === 'functions') {
      const types = (value || []).map((item) => item && item.value)
      return onQueryChange({ filter: { ...filter, functions: !isEmpty(types) ? types : null } })
    }

    Object.keys(filter).forEach((option) => { (option !== 'functions') && (filter[option] = (option === value)) })
    return onQueryChange({ filter })
  }
  const handleFilterReset = () => onQueryChange({
    filter: { all: true, favorite: false, holder: false, peerHolder: false, functions: [] }
  })

  return (
    <Toolbar theme={toolbarTheme}>
      <ToolbarRow justified>
        <div className='toolbar_group'>
          <RangeTab
            theme={toolTheme}
            buttons={FILTER.map((option) => ({
              ...option,
              dataId: `${dataId}${option.dataId}`,
              active: filter[option.value],
              onClick: (event, value) => handleFilterChange(value)
            }))}
          />
          <Select
            dataId={{
              inputId: `${dataId}FilterJobFunctionInput`,
              menuId: `${dataId}FilterJobFunctionMenu`
            }}
            className='grid_toolbar-dropdown--large'
            theme={toolTheme}
            size='thin'
            placeholder='Job Function'
            value={functionSelected}
            options={FUNCTIONS}
            isMulti
            isDefaultList
            searchable={false}
            clearable={false}
            closeMenuOnSelect={false}
            onChange={(options) => handleFilterChange(options, 'functions')}
          />
          <Button
            dataId={`${dataId}FilterReset`}
            theme={toolTheme}
            label='Reset'
            onClick={handleFilterReset}
          />
        </div>
        <div className='toolbar_group'>
          <SectionSearch
            dataId={`${dataId}Search`}
            theme={toolTheme}
            value={search}
            onQueryChange={handleSearchChange}
            onClear={handleSearchChange}
          />
          <Button
            dataId={`${dataId}Export`}
            theme={toolTheme}
            loading={exporting}
            icon='q4i-download-4pt'
            label='Export'
            disabled={noData}
            onClick={onExport}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

ContactToolbar.propTypes = propTypes
ContactToolbar.defaultProps = defaultProps

export default memo(ContactToolbar)
