import React, { memo } from 'react'
import PropTypes from 'prop-types'

// utils
import { THEMES } from '../../../../utils/ui'

// components
import Checkbox from '../../../shared/form/checkbox/checkbox.component'

/**
 * ReportTableConfig
 * @param titleConfig
 * @constructor
 */
function ReportTableConfig ({ titleConfig }) {
  return (
    <section>
      <div>
        <Checkbox
          key='report-chart-data-config--title'
          theme={THEMES.WHITE_STEEL}
          label='Show Title'
          name='showTitle'
          id='showTitle'
          isChecked={titleConfig.showTitle}
          onChange={titleConfig.onChange}
        />
      </div>
    </section>
  )
}

ReportTableConfig.propTypes = {
  titleConfig: PropTypes.shape({
    showTitle: PropTypes.bool,
    onChange: PropTypes.func.isRequired
  })
}

ReportTableConfig.defaultProps = {
  titleConfig: {}
}

export default memo(ReportTableConfig)
