import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

// components
import { Card, EntityBadge } from '../../../../components'

import { formatPhone } from '../../../../utils'
import { config } from '../../../../config'

/**
 * Contact phone or dash
 * @param phone
 * @returns {*|string}
 */
const getPhone = (phone) => {
  return (phone && phone.length) ? isNaN(phone) ? phone : formatPhone(phone) : '-'
}

/**
 * Corporate Participant Overview Component
 * @param props
 * @returns {*}
 * @constructor
 */
const ParticipantOverview = ({ participant }) => {
  const { _id, phone, mobile, email } = participant

  return (
    <div className='overview-layout'>
      <Grid container spacing={3}>
        <EntityBadge
          dataId='ParticipantPage'
          data={{ favorite: _id }}
        />

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Card
                title='Contact Information'>

                <Grid container spacing={3}>

                  <Grid item xs={6} className='card_section'>
                    <h2 className='card_section-subtitle'>Primary Phone</h2>
                    <div className='card_section-detail'>{getPhone(phone)}</div>
                  </Grid>
                  <Grid item xs={6} className='card_section'>
                    <h2 className='card_section-subtitle'>Secondary Phone</h2>
                    <div className='card_section-detail'>{getPhone(mobile)}</div>
                  </Grid>
                  <Grid item xs={12} className='card_section'>
                    <h2 className='card_section-subtitle'>Email</h2>
                    {(email && email.length)
                      ? <a
                        className='card_section-detail'
                        href={`mailto:${email}?Subject=Contact%20from%20Q4%20Desktop&bcc=${config.loggerEmail}`}
                      >{email}
                      </a>
                      : '-'}
                  </Grid>

                </Grid>
              </Card>

            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  )
}

ParticipantOverview.propTypes = {
  participant: PropTypes.object.isRequired
}

ParticipantOverview.defaultProps = {
  participant: {}
}

export default ParticipantOverview
