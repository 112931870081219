import {
  FETCH_STOCKQUOTE_HISTORICAL_REQUEST,
  FETCH_STOCKQUOTE_HISTORICAL_SUCCESS,
  FETCH_STOCKQUOTE_HISTORICAL_ERROR,
  IDLE,
  FETCHED,
  FETCHING,
  ERROR
} from '../../../actions/widget/stock'
import { formatCurrency, formatStockPrice } from '../../../utils'

const initialState = {
  status: IDLE
}

const stockQuoteHistorical = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STOCKQUOTE_HISTORICAL_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_STOCKQUOTE_HISTORICAL_SUCCESS:
      const historicalStock = action.payload.historical || []
      return {
        ...state,
        items: historicalStock.map(record=>({
          ...record,
          Currency: formatCurrency(record.Currency),
          Last:  formatStockPrice(record.Last, record.Currency)
        })),
        status: FETCHED
      }
    case FETCH_STOCKQUOTE_HISTORICAL_ERROR:
      return {
        ...state,
        error: action.error,
        status: ERROR
      }
    default:
      return state
  }
}

export default stockQuoteHistorical
