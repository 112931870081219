import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import { Card } from '../../../index'
import { defaultIfEmpty, THEMES } from '../../../../utils'
import { config } from '../../../../config'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    phone: PropTypes.string,
    extension: PropTypes.string,
    mobile: PropTypes.string,
    email: PropTypes.string
  }).isRequired
}

const defaultProps = {
  isLoading: false,
  data: {}
}

/**
 * Attribute Component
 */
function Attribute ({ label, value }) {
  return (
    <Grid item xs={6} className='card_section'>
      <h2 className='card_section-subtitle'>{label}</h2>
      <div className='card_section-detail'>{defaultIfEmpty(value)}</div>
    </Grid>
  )
}

/**
 * Contact Attributes Card Component
 * @param props
 */
function ContactAttribute (props) {
  const { dataId, theme, isLoading, data } = props
  const { phone, extension, mobile, email } = data

  const attributes = [
    { label: 'Primary Phone', value: phone },
    { label: 'Extension', value: extension },
    { label: 'Mobile Phone', value: mobile }
  ]

  return (
    <Card
      dataId={`${dataId}InformationCard`}
      theme={theme}
      isLoading={isLoading}
      title='Contact Information'
    >
      <Grid container spacing={3}>
        {attributes.map((attribute) => {
          return <Attribute key={attribute.label} {...attribute} />
        })}
        <Grid item xs={6} className='card_section'>
          <h2 className='card_section-subtitle'>Email</h2>
          {(email && email.length)
            ? <a
                className='card_section-detail'
                href={`mailto:${email}?Subject=Contact%20from%20Q4%20Desktop&bcc=${config.loggerEmail}`}>{email}</a>
            : '-'}
        </Grid>
      </Grid>
    </Card>
  )
}

ContactAttribute.propTypes = propTypes
ContactAttribute.defaultProps = defaultProps

export default memo(ContactAttribute)
