import React, { Component } from 'react'
import { ExpansionPanel, ExpansionPanelSummary } from '@material-ui/core'

/**
 * Popover Component
 */
class ExpandableItem extends Component {
  render () {
    const { type, icon, label, children } = this.props

    const rootClass = `popover-item popover-item-expandable ${type ? `popover-item--${type}` : ''}`

    return (
      <ExpansionPanel classes={{ root: rootClass }}>
        <ExpansionPanelSummary classes={{
          content: 'popover-item-expandable_button',
          expanded: 'popover-item-expandable_button--open'
        }}
        >
          {icon ? <i className={`popover-item_icon ${icon}`} /> : null}
          <div className='popover-item-expandable_label'>{label}</div>
          <i className='popover-item-expandable_caret q4i-caret-sm-down-4pt' />
        </ExpansionPanelSummary>
        <div className='popover-item-expandable_content'>
          {children}
        </div>
      </ExpansionPanel>
    )
  }
}

export default ExpandableItem
