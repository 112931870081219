import {
  GET_ENTITY_TOP_SELLS_REQUEST,
  GET_ENTITY_TOP_SELLS_SUCCESS,
  GET_ENTITY_TOP_SELLS_FAILURE,
  RESET_ENTITY_TOP_SELLS,
  statusType
} from '../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const topSells = (state = initial, action) => {
  const { payload = {}, type } = action

  switch (type) {
    case GET_ENTITY_TOP_SELLS_REQUEST:
      return {
        ...initial,
        status: statusType.IN_PROGRESS
      }

    case GET_ENTITY_TOP_SELLS_SUCCESS:
      return {
        ...state,
        data: payload,
        status: statusType.SUCCESS
      }

    case GET_ENTITY_TOP_SELLS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case RESET_ENTITY_TOP_SELLS:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default topSells
