import {
  ESTIMATE_PEER_CHANGED,
  FAILED,
  FETCH_ESTIMATES_HISTORICAL_ERROR,
  FETCH_ESTIMATES_HISTORICAL_REQUEST,
  FETCH_ESTIMATES_HISTORICAL_SUCCESS,
  SET_ESTIMATES_HISTORICAL_METRIC,
  FETCHED,
  FETCHING,
  IDLE, RESET_ESTIMATES
} from '../../actions/estimates'

const initialState = {
  status: IDLE,
  items: [],
  activeMetric: null
}

const estimatesHistorical = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ESTIMATES:
      return {
        ...initialState
      }
    case SET_ESTIMATES_HISTORICAL_METRIC:
      return {
        ...state,
        activeMetric: action.metric
      }
    case ESTIMATE_PEER_CHANGED:
      return {
        ...state,
        items: [],
        activeMetric: null
      }
    case FETCH_ESTIMATES_HISTORICAL_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_ESTIMATES_HISTORICAL_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        items: action.payload
      }
    case FETCH_ESTIMATES_HISTORICAL_ERROR:
      return {
        ...state,
        status: FAILED,
        error: action.error
      }
    default:
      return state
  }
}

export default estimatesHistorical
