import React from 'react'
import PropTypes from 'prop-types'

import { Select, Button, Toolbar, ToolbarRow } from '../../../../index'
import { THEMES, PEER_ANALYSIS_OPTIONS } from '../../../../../utils'

/**
 * Activity Toolbar
 * @param props
 * @returns {*}
 * @constructor
 */
const PeerAnalysisToolbar = (props) => {
  const {
    toolbarTheme, toolTheme, noData, handlePeersModal, handleExport, handleFilterChange, metric
  } = props
  const options = PEER_ANALYSIS_OPTIONS
  const onPositionChange = (option) => handleFilterChange({ metric: option.value, isChange: option.isChange })

  return (
    <Toolbar theme={toolbarTheme}>
      <ToolbarRow justified>
        <div className='toolbar_group'>
          <Select
            theme={toolTheme}
            size='thin'
            placeholder='Category'
            value={(options || []).find((option) => option && option.value === metric)}
            options={options}
            isDefaultList
            onChange={onPositionChange}
            searchable={false}
            clearable={false}
          />
          <Button
            theme='ink'
            label='peers'
            onClick={handlePeersModal}
          />
        </div>
        <div className='toolbar_group'>
          <Button
            theme={toolTheme}
            icon='q4i-download-4pt'
            label='Export'
            disabled={noData}
            onClick={handleExport}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

PeerAnalysisToolbar.propTypes = {
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  noData: PropTypes.bool,
  category: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  handlePeersModal: PropTypes.func,
  handleFilterChange: PropTypes.func,
  handleExport: PropTypes.func
}

PeerAnalysisToolbar.defaultProps = {
  metric: 'current'
}

export default PeerAnalysisToolbar
