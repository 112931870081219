import React, { PureComponent } from 'react'
import PropType from 'prop-types'

import ListEditable from './listEditable/listEditable.component'
import ListEditableItem from './listEditable/listItem/listItem.component'

import Dropdown from '../dropdown/dropdown.component'
import DropdownOptions from '../dropdown/dropdownOptions/dropdownOptions.component'
import DropdownOptionItem from '../dropdown/dropdownOptionItem/dropdownOptionItem.component'
import DropdownSelect from '../dropdown/dropdownSelect/dropdownSelect.component'

import { getClassName, THEMES } from '../../../../utils/ui'

import './comboBox.component.css'
class ComboBox extends PureComponent {
  constructor (props) {
    super(props)
    this.dropdownComponentRef = React.createRef()
    this.inputValue = null
  }

  onRemove = (item) => {
    const { onRemove } = this.props
    onRemove && onRemove(item)
  };

  onInputChange = (val) => {
    const { onInputChange, disabled } = this.props
    onInputChange && onInputChange(val)
    const dropdown = this.dropdownComponentRef.current
    this.inputValue = val
    if (!disabled && val.length && dropdown.state.expanded === false) {
      dropdown.handleExpand()
    }
  };

  onSuggestedItemSelect = (item) => {
    const { onSuggestedItemSelect } = this.props
    this.dropdownComponentRef.current.resetValue()

    if (typeof item === 'function') {
      return item()
    }

    onSuggestedItemSelect && onSuggestedItemSelect(item)
  };

  onDropdownCollapse = () => {
    const { onDropdownCollapse } = this.props
    onDropdownCollapse && onDropdownCollapse()
  };

  isInListOptionsVisible () {
    const { loading } = this.props
    return this.inputValue && this.inputValue.length && !loading
  }

  renderOptionItems () {
    const {
      suggestedValues,
      suggestedItemTemplate,
      suggestedInListActions,
      listKey
    } = this.props

    const suggestedOptions = (suggestedValues || []).map((item, index) =>
      <DropdownOptionItem value={item} key={item[listKey] || index}>
        {suggestedItemTemplate(item)}
      </DropdownOptionItem>
    )

    const suggestedInListOptions = (suggestedInListActions || []).map((actionItem, index) => (
      <DropdownOptionItem value={actionItem.onSelect} isAction={true} key={`${actionItem.label}-${index}`}>
        <button className={(actionItem.className || '') + ' button button--tall button--citrus'}>
          {!!actionItem.icon && <i className={actionItem.icon} />}
          {actionItem.label}
        </button>
      </DropdownOptionItem>
    ))

    return suggestedOptions.concat(this.isInListOptionsVisible() ? suggestedInListOptions : [])
  }

  render () {
    const {
      selectedItemTemplateContent,
      suggestedFixedActions,
      selectedValues,
      selectedItemTemplate,
      value,
      placeholder,
      listKey,
      onInputEnter,
      disabled,
      prepend,
      suggestedItemHeight,
      loading,
      dropdownHeight,
      theme,
      infoComponent,
      optionSubComponents,
      inputId
    } = this.props

    const baseClassName = getClassName('combobox', [
      { condition: theme, trueClassName: `combobox--${theme}` }
    ])

    return (
      <div className={baseClassName}>
        <Dropdown
          ref={this.dropdownComponentRef}
          input={<input id={inputId} placeholder={placeholder || 'Enter an option...'} />}
          value={value}
          prepend={prepend}
          onSelect={this.onSuggestedItemSelect}
          onInputChange={this.onInputChange}
          onInputEnter={onInputEnter}
          onCollapse={this.onDropdownCollapse}
          arrow={false}
          loading={loading}
          height={dropdownHeight}
          itemHeight={suggestedItemHeight}
          disabled={disabled}
        >
          <DropdownSelect>
            <DropdownOptions>
              {this.renderOptionItems()}
            </DropdownOptions>
            {(suggestedFixedActions || []).map((actionItem) => actionItem)}
          </DropdownSelect>
        </Dropdown>
        {infoComponent && infoComponent()}
        <ListEditable>
          {(selectedValues || []).map((item, index) =>
            <ListEditableItem
              optionSubComponents={optionSubComponents}
              rawValues={{ index: index, item: item }}
              text={selectedItemTemplate(item)}
              content={selectedItemTemplateContent && selectedItemTemplateContent(item)}
              key={item[listKey] || index}
              onRemove={() => this.onRemove(item)} />
          )}
        </ListEditable>
      </div>
    )
  }
}

ComboBox.propTypes = {
  suggestedValues: PropType.array.isRequired,
  suggestedItemTemplate: PropType.func.isRequired,
  suggestedItemHeight: PropType.oneOfType([PropType.string, PropType.number]),
  selectedItemTemplate: PropType.any.isRequired,
  selectedItemTemplateContent: PropType.any,
  listKey: PropType.string,
  suggestedInListActions: PropType.any,
  suggestedFixedActions: PropType.any,
  selectedValues: PropType.array,
  onInputChange: PropType.func,
  onInputEnter: PropType.func,
  onSuggestedItemSelect: PropType.func,
  placeholder: PropType.string,
  value: PropType.string,
  disabled: PropType.bool,
  onRemove: PropType.func,
  prepend: PropType.string,
  arrow: PropType.bool,
  loading: PropType.bool,
  onDropdownCollapse: PropType.func,
  theme: PropType.oneOf([THEMES.PALE_GREY, THEMES.WHITE]),
  infoComponent: PropType.func,
  inputId: PropType.string
}

ComboBox.defaultProps = {
  suggestedValues: [],
  selectedValues: [],
  placeholder: 'Select a value',
  arrow: true,
  loading: false,
  theme: THEMES.PALE_GREY
}

export default ComboBox
