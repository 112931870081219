import React, { PureComponent } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { config } from '../../../config'
import { omit, cloneDeep } from 'lodash'
import shortid from 'shortid'
import update from 'immutability-helper'
import { getActiveTicker } from '../../../utils'
import { formatDate } from '../../../utils/date.util'
import { getClassName, debounce } from '../../../utils/ui/ui.util'
import { formatTitle } from '../../../utils/regex.util'
import { base64Encode, convertBlobToBase64, getCssLinks } from '../../../utils/browser.util'
import {
  parsePopulatedFields,
  parsePopulatedFilters,
  parsePopulatedEntityTypes,
  getLayoutItemId,
  getAvailableLayoutItem,
  getDesiredW,
  getDesiredH,
  getMinDesiredW,
  getShouldUpdateLayout,
  getWidgetHeight,
  getFlattenedData,
  getFormattedFilters,
  getFormattedSort,
  getParsedFilters,
  getFilteredTemplates,
  getUnwindData,
  parseXAxis,
  parseSeries,
  getFormattedChartOptions,
  applyColorToSeries,
  getInitialSeries,
  getEntityQuartersMap,
  getFormattedIndices,
  parsePopulatedIndices,
  colorOptions,
  reportPageLimit
} from '../../../utils/report'
import {
  getReportBuilderItem,
  saveReportBuilderItem,
  storeReportBuilderItem,
  storeReportDataItem,
  storeReportDataVisualization,
  setReportBuilderItemFetched,
  resetReportBuilderItemStatus,
  getReportDataTemplates,
  setAppFullscreen,
  createToast,
  getReportEntityTypes,
  getReportBuilderFields,
  getReportSnapshot,
  getCustomTemplates,
  setPeers,
  clearPeers,
  storeReportSnapshot,
  loadPeers,
  getReportPageThumbnail,
  removeReportPageThumbnail,
  resetReportDataItem,
  IDLE,
  FAILED,
  FETCHED,
  FETCHING,
} from '../../../actions'
import { downloadExportData } from '../../../actions/shared/index'
import { EditableText } from '../../../components/shared'
import { Banner, DrawerLibrary, Message, PageManager, PageEditor, Spinner } from '../../../components'
import ReportDataEditor from '../dataEditor/reportDataEditor.container'
import ReportWidget from '../../../components/report/widget/reportWidget.component'
import ReportTextEditModal from '../../../components/report/textEditModal/reportTextEditModal.component'

import './reportBuilder.container.css'

class ReportBuilderContainer extends PureComponent {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      initialLoad: false,
      isReportOutdated: false,
      isSaving: false,
      isExporting: false,
      isPageManagerExpanded: true,
      isPageEditorExpanded: false,

      isDrawerLibraryOpen: false,
      isMessageOpen: false,
      isTextEditModalOpen: false,
      isReportDataEditorOpen: false,

      selectedPageIndex: 0,
      selectedWidgetIndex: null,
      nextAvailableLayoutItem: null,

      delayId: null,
      delayTimeout: 1000,
      delaySaveCount: 0,
      delaySaveCountLimit: 5,

      isThumbnailGenerating: false,
      pagesThumbnailStatus: {},
      isThumbnailOutdated: false
    }

    // Thumbnail generation
    this.paperContainer = React.createRef()
    this.generateThumbnail = debounce(this.generateThumbnail)
  }

  /**
   * ComponentDidMount
   */
  componentDidMount () {
    const { resetReportBuilderItemStatus, setAppFullscreen } = this.props

    this.fetchReport()
    this.fetchCustomDataTemplates()
    this.fetchReportDataTemplates()

    resetReportBuilderItemStatus()
    setAppFullscreen(true)
  }

  /**
   * ComponentDidUpdate
   * @param prevProps
   * @param prevState
   */
  componentDidUpdate (prevProps, prevState) {
    const { fullscreenEnabled } = this.props
    const { isPageEditorExpanded, isPageManagerExpanded } = this.state

    const fullscreenChanged = prevProps.fullscreenEnabled !== fullscreenEnabled
    const editorSizeChanged = prevState.isPageEditorExpanded !== isPageEditorExpanded
    const managerSizeChanged = prevState.isPageManagerExpanded !== isPageManagerExpanded
    const isThumbnailGenerating = Object.values(this.state.pagesThumbnailStatus).some((thumbnailStatus) => thumbnailStatus === FETCHING)

    if (fullscreenChanged || editorSizeChanged || managerSizeChanged) {
      this.updatePaperScale && this.updatePaperScale()
    }

    if (isThumbnailGenerating !== this.state.isThumbnailGenerating) {
      this.setState({ isThumbnailGenerating })
    }
  }

  /**
   * ComponentWillUnmount
   */
  componentWillUnmount () {
    this.props.setAppFullscreen(false)
    this.props.resetReportBuilderItemStatus()
    if (this.state.delayId) {
      clearTimeout(this.state.delayId)
    }
  }

  /**
   * Fetch Report data for ReportDetails Container
   */
  fetchReport = () => {
    const { match, getReportBuilderItem, getReportEntityTypes, getReportBuilderFields } = this.props
    const id = match.params.id

    getReportEntityTypes().then(() => {
      const { reportEntityTypes } = this.props
      const entityType = getFlattenedData(reportEntityTypes)

      getReportBuilderFields({ entityType }).then(() => {
        if (id === 'new') {
          this.setupNewReport()
        } else {
          getReportBuilderItem(id).then(() => {
            this.setState({
              initialLoad: true
            })
          })
        }
      })
    })
  }

  /**
   * Perform Report Save
   * @return {*}
   */
  saveReport = async () => {
    const { reportBuilderItem, saveReportBuilderItem } = this.props

    const body = await this.getReportBody()
    const _id = (reportBuilderItem && reportBuilderItem._id) || null

    return saveReportBuilderItem(body, _id)
  }

  /**
   * Setup the configuration and state for a new report
   */
  setupNewReport = () => {
    const { reportBuilderItem, storeReportBuilderItem, setReportBuilderItemFetched, resetReportDataItem } = this.props
    const { title } = reportBuilderItem

    this.setState({
      initialLoad: true
    }, () => {
      storeReportBuilderItem({
        _id: null,
        title: title || 'Blank Report',
        pages: [this.getDefaultPage()]
      })
      resetReportDataItem()
      setReportBuilderItemFetched()
    })
  }

  /**
   * Load Report Data Templates for the Container
   */
  fetchReportDataTemplates = () => {
    const { getReportDataTemplates } = this.props
    getReportDataTemplates && getReportDataTemplates()
  }

  /**
   * Load Custom Data Templates for the Container
   */
  fetchCustomDataTemplates = () => {
    const { getCustomTemplates } = this.props

    getCustomTemplates && getCustomTemplates({
      limit: 20,
      page: 1
    })
  }

  /**
   * Triggers thumbnail generation
   */
  handleGenerateThumbnail = () => {
    if (!this.paperContainer || !this.paperContainer.current) {
      return
    }

    const { pages } = this.props
    const { selectedPageIndex } = this.state

    const thumbnailHTML = this.paperContainer.current.innerHTML
    const selectedPage = cloneDeep(pages[selectedPageIndex])
    const selectedPageId = selectedPage._id || selectedPage.tempId

    // don't generate thumbnail if page is empty
    if (!selectedPage || !selectedPage.widget || !selectedPage.widget.length) {
      return
    }

    this.setState({ isThumbnailGenerating: true })
    return this.generateThumbnail({ thumbnailHTML, selectedPageId })
  }

  /**
   * Generate Thumbnail Image
   */
  generateThumbnail = async ({ thumbnailHTML, selectedPageId }) => {
    if (!thumbnailHTML || !selectedPageId) {
      return
    }

    const thumbnailId = `${selectedPageId}:${new Date().toISOString()}`

    this.setState({
      pagesThumbnailStatus: {
        ...this.state.pagesThumbnailStatus,
        [thumbnailId]: FETCHING
      }
    })

    const { storeReportBuilderItem } = this.props

    const thumbnailBlob = await getReportPageThumbnail({
      htmlBase64: base64Encode(thumbnailHTML),
      cssLinks: getCssLinks()
    })

    if (!thumbnailBlob) {
      this.resetThumbnailStatus(thumbnailId)
      return
    }

    // don't assign thumbnail to a page, if while waiting for a thumbnail to generate user removed all widgets
    const selectedPage = this.props.pages.find((page) => [page._id, page.tempId].includes(selectedPageId))
    if (!selectedPage || !selectedPage.widget || !selectedPage.widget.length) {
      this.resetThumbnailStatus(thumbnailId)
      return
    }

    storeReportBuilderItem({
      pages: this.props.pages.map((page) => {
        if ([page._id, page.tempId].includes(selectedPageId)) {
          return {
            ...page,
            thumbnail: thumbnailBlob
          }
        }
        return page
      }),
      isThumbnailOutdated: false
    })

    this.resetThumbnailStatus(thumbnailId)
  }

  /**
   * Resets thumbnail generation status
   * @param thumbnailId
   */
  resetThumbnailStatus = (thumbnailId) => {
    delete this.state.pagesThumbnailStatus[thumbnailId]
    this.setState({ pagesThumbnailStatus: { ...this.state.pagesThumbnailStatus } })
  }

  /**
   * Remove Page
   * @param pageId
   */
  removePage = (pageId) => {
    const { storeReportBuilderItem, pages } = this.props
    const pageIndex = pages.findIndex((page) => (page._id === pageId) || page.tempId === pageId)

    if (pageIndex < 0) {
      return
    }

    const _pages = update(pages, { $splice: [[pageIndex, 1]] })

    storeReportBuilderItem({
      pages: _pages
    })

    this.setState({
      isReportOutdated: true,
      pageToDeleteId: null,
      isMessageOpen: false,
      selectedPageIndex: (_pages.length <= pageIndex) ? pageIndex - 1 : pageIndex
    })
  }

  /**
   * Handle Opening the Message component
   * Passed scope determines component params
   * @param scope
   */
  handleMessageOpen = (scope) => {
    this.setState({
      isMessageOpen: true,
      _messageScope: scope || 'discard'
    })
  }

  /**
   * Handle closing of the Message modal
   */
  handleMessageClose = () => {
    this.setState({
      isMessageOpen: false
    })
  }

  /**
   * Handle Title Submit
   * @param value
   */
  handleTitleSubmit = (value) => {
    const { reportBuilderItem, storeReportBuilderItem } = this.props
    const { title } = reportBuilderItem

    storeReportBuilderItem({
      title: value
    })

    this.setState({
      isReportOutdated: value !== title
    })
  }

  /**
   * Handle title change
   * @param value
   */
  handleTitleChange = (value) => {
    const { reportBuilderItem } = this.props
    const { title } = reportBuilderItem

    this.setState({
      isReportOutdated: value !== title
    })
  }

  /**
   * Handle Cancel button click event
   */
  handleCancel = () => {
    const { isReportOutdated } = this.state

    if (isReportOutdated) {
      return this.handleMessageOpen('discard')
    }

    const { history } = this.props

    history && history.push('/builder')
  }

  /**
   * Delays function execution
   * @param func
   * @param ms
   */
  delaySave = (func, ms) => {
    if (this.state.delayId) {
      clearTimeout(this.state.delayId)
    }

    const delayId = setTimeout(func, ms)

    this.setState({
      delayId,
      delaySaveCount: this.state.delaySaveCount + 1
    })
  }

  /**
   * Saves report
   */
  handleSave = async () => {
    const response = await this.saveReport()

    const { history, reportBuilderItem, storeReportBuilderItem, createToast } = this.props
    const isNewReport = !reportBuilderItem._id
    const _reportBuilderItem = typeof response.payload === 'object' && response.payload
    const _id = _reportBuilderItem && _reportBuilderItem._id

    if (!_reportBuilderItem) {
      this.setState({
        isMessageOpen: true,
        _messageScope: 'error',
        isSaving: false,
      })
      return
    }

    createToast({ text: `${_reportBuilderItem.title || 'The report'} has been successfully saved.` })

    this.setState({
      isSaving: false,
      isReportOutdated: false
    })

    if (isNewReport) {
      (_reportBuilderItem.pages || []).forEach((eachPage) => {
        (eachPage.widget || []).forEach((eachWidget) => {
          eachWidget.unwoundData = eachWidget.widgetType !== 'text' ? getUnwindData(eachWidget.data) : eachWidget.data
        })
      })
      storeReportBuilderItem(_reportBuilderItem)
      history.push(`/builder/${_id}`)
    }
  }

  /**
   * Delays saving until all async actions are done
   */
  handleSaveWithDelay = async () => {
    const { isThumbnailGenerating, delaySaveCount, delaySaveCountLimit, delayTimeout } = this.state

    if (isThumbnailGenerating && delaySaveCount <= delaySaveCountLimit) {
      this.delaySave(this.handleSaveWithDelay, delayTimeout)
      return
    }

    await this.handleSave()

    this.setState({
      delaySaveCount: 0,
      pagesThumbnailStatus: {}
    })
  }

  /**
   * Handles save on click event
   */
  handleSaveClick = () => {
    const { reportBuilderItem } = this.props
    const { isSaving } = this.state

    if (!reportBuilderItem) {
      return
    }

    if (!isSaving) {
      this.setState({ isSaving: true })
    }

    this.handleSaveWithDelay()
  }

  /**
   * Handle Exporting of the report
   */
  handleExport = () => {
    const { reportBuilderItem } = this.props
    const { _id, title } = reportBuilderItem

    this.setState({
      isExporting: true
    })

    this.props.downloadExportData({
      url: `/report/export/pptx/${_id}`,
      contentType: 'text/csv',
      file: {
        name: formatTitle(title),
        type: 'pptx'
      }
    }).then(() => {
      this.setState({
        isExporting: false
      })
    })
  }

  /**
   * Handle PageManager onPageAdd event
   */
  handlePageAdd = () => {
    const { pages, storeReportBuilderItem } = this.props
    const { selectedPageIndex } = this.state

    const defaultPage = this.getDefaultPage()
    const _selectedPageIndex = pages.length > 0 ? selectedPageIndex : -1

    storeReportBuilderItem({
      pages: update((pages || []), {
        $splice: [[_selectedPageIndex + 1, 0, defaultPage]]
      })
    })

    this.setState({
      isReportOutdated: true,
      nextAvailableLayoutItem: null,
      selectedPageIndex: _selectedPageIndex + 1
    })
  }

  /**
   * Handle Page Remove by Id
   * @param pageId
   */
  handlePageRemove = (pageId) => {
    const { pages } = this.props
    const page = pages.find((page) => (page._id === pageId) || (page.tempId === pageId))

    if (!page) {
      return
    }

    // check to see if the page is blank before considering a message
    if (page.layout && page.layout.template && page.layout.template.length) {
      this.setState({
        isReportOutdated: true,
        isMessageOpen: true,
        _messageScope: 'removePage',
        pageToDeleteId: pageId
      })
    } else {
      this.removePage(pageId)
    }
  }

  /**
   * Handle Page Duplicate
   * @param id
   */
  handlePageDuplicate = (id) => {
    const { pages, storeReportBuilderItem } = this.props

    const idx = pages.findIndex((eachPage) => {
      return eachPage._id === id || (eachPage.tempId === id)
    })

    if (idx < 0) {
      return
    }

    this.setState({
      isReportOutdated: true,
      selectedPageIndex: idx + 1
    })

    storeReportBuilderItem({
      pages: update(pages, {
        $splice: [[idx + 1, 0,
          update(pages[idx], {
            tempId: {
              $set: shortid.generate()
            },
            _id: {
              $set: undefined
            }
          })
        ]]
      })
    })
  }

  /**
   * Handle PageManager onPageClick event
   * @param pageIndex
   */
  handlePageSelect = (pageIndex) => {
    this.setState({
      selectedPageIndex: pageIndex,
      selectedWidgetIndex: null
    })
  }

  /**
   * Handle Report Builder page move
   * @param dragIndex
   * @param dropIndex
   */
  handlePageMove = (dragIndex, dropIndex) => {
    const { pages, storeReportBuilderItem } = this.props

    const draggedPage = pages[dragIndex]

    this.setState({
      isReportOutdated: true,
      selectedPageIndex: dropIndex
    })

    storeReportBuilderItem({
      pages: update(pages, {
        $splice: [[dragIndex, 1], [dropIndex, 0, draggedPage]]
      })
    })
  }

  /**
   * Handle PageEditor size change event
   */
  handleEditorSizeToggle = () => {
    const { isPageEditorExpanded } = this.state

    this.setState({
      isPageEditorExpanded: !isPageEditorExpanded
    })
  }

  /**
   * Handle PageManager size change event
   */
  handleManagerSizeToggle = () => {
    const { isPageManagerExpanded } = this.state

    this.setState({
      isPageManagerExpanded: !isPageManagerExpanded
    })
  }

  /**
   * Handle Data Widget Add
   * @param widgetType
   */
  handleWidgetAdd = (widgetType) => {
    const nextAvailableLayoutItem = this.getNextAvailableLayoutItem(widgetType)

    if (!nextAvailableLayoutItem) {
      return this.handleMessageOpen('noAvailableSpace')
    }

    switch (widgetType) {
      case 'text':
        this.handleTextWidgetAdd(nextAvailableLayoutItem)
        break
      case 'data':
      default:
        this.handleDrawerLibraryOpen(nextAvailableLayoutItem)
    }
  }

  /**
   * Handle onWidgetDuplicate or action toolbar's duplicate button click event
   * @param widgetIndex
   */
  handleWidgetDuplicateRequest = (widgetIndex) => {
    this.setState({
      selectedWidgetIndex: !isNaN(widgetIndex) ? widgetIndex : this.state.selectedWidgetIndex
    }, () => this.handleWidgetDuplicate())
  }

  /**
   * Handle onWidgetRemove or action toolbar's delete button click event
   * @param widgetIndex
   */
  handleWidgetRemoveRequest = (widgetIndex) => {
    this.setState({
      selectedWidgetIndex: !isNaN(widgetIndex) ? widgetIndex : this.state.selectedWidgetIndex
    }, () => this.handleMessageOpen('removeWidget'))
  }

  /**
   * Handle widget duplication action
   * @param widgetIndex
   */
  handleWidgetDuplicate = () => {
    const selectedWidget = this.getSelectedWidget()
    if (!selectedWidget || !selectedWidget.widgetType) {
      return
    }

    const { pages, storeReportBuilderItem } = this.props
    const { selectedPageIndex } = this.state

    const layoutItemIndex = pages[selectedPageIndex].layout.template.findIndex((layoutItem) => {
      return layoutItem.i === selectedWidget.layout_id.toString()
    })

    const widgetType = selectedWidget.widgetType === 'text' ? 'text' : 'data'
    const nextAvailableLayoutItem = this.getNextAvailableLayoutItem(widgetType, layoutItemIndex)

    if (!nextAvailableLayoutItem) {
      this.handleMessageOpen('noAvailableSpace')
      return
    }

    const selectedPage = pages[selectedPageIndex] || {}
    const selectedPageId = selectedPage._id || selectedPage.tempId

    if (!selectedPageId) {
      return
    }

    const layoutItemId = getLayoutItemId(selectedPage.layout.template)
    const updatedPages = pages.map((page) => {
      if ([page._id, page.tempId].includes(selectedPageId)) {
        return {
          ...page,
          layout: {
            template: [...page.layout.template, {
              ...nextAvailableLayoutItem,
              i: layoutItemId
            }]
          },
          widget: [...page.widget, {
            ...selectedWidget,
            layout_id: layoutItemId,
            _id: shortid.generate()
          }]
        }
      }
      return page
    })

    storeReportBuilderItem({ pages: updatedPages, isThumbnailOutdated: true })
  }

  /**
   * Handle Widget Remove
   */
  handleWidgetRemove = () => {
    const selectedWidget = this.getSelectedWidget()

    if (!selectedWidget) {
      return
    }

    const { pages, storeReportBuilderItem } = this.props
    const { selectedPageIndex, selectedWidgetIndex } = this.state

    const layoutItemIndex = pages[selectedPageIndex].layout.template.findIndex((layoutItem) => {
      return layoutItem.i === selectedWidget.layout_id.toString()
    })
    const updatedPages = update(pages, {
      $splice: [[selectedPageIndex, 1, update(pages[selectedPageIndex], {
        layout: {
          template: {
            $splice: [[layoutItemIndex, 1]]
          }
        },
        widget: {
          $splice: [[selectedWidgetIndex, 1]]
        }
      })]]
    })

    this.setState({
      isReportOutdated: true,
      isMessageOpen: false,
      selectedWidgetIndex: null
    }, () => {
      const selectedPage = updatedPages[selectedPageIndex]

      storeReportBuilderItem({ pages: updatedPages, isThumbnailOutdated: true })

      // remove thumbnail if page doesn't have any widgets
      if (!selectedPage || !selectedPage.widget || !selectedPage.widget.length) {
        this.props.removeReportPageThumbnail(selectedPage._id || selectedPage.tempId)
      }
    })
  }

  /**
   * Handle Opening the DrawerLibrary component
   * @param layoutItem
   */
  handleDrawerLibraryOpen = (layoutItem) => {
    this.setState({
      isDrawerLibraryOpen: true,
      nextAvailableLayoutItem: layoutItem
    })
  }

  /**
   * Handle closing of the Message modal
   */
  handleDrawerLibraryClose = () => {
    this.setState({
      isDrawerLibraryOpen: false,
      nextAvailableLayoutItem: null
    })
  }

  /**
   * Add selected drawer widget to the report builder
   * @param item
   * @param peers
   */
  addWidgetItem = (item, peers) => {
    if (!item || !item.snapshot) {
      return
    }

    const { pages, securityId, getReportSnapshot, storeReportBuilderItem } = this.props
    const { selectedPageIndex, nextAvailableLayoutItem } = this.state

    const { snapshot, title, showTitle, chartOptions, widgetType } = item
    const { _entityType, fields, pivotFields, filters, sort, limit, indices, isPivot, isLocked } = snapshot

    const _filters = getParsedFilters(fields, filters)
    const _pivotQuarters = getEntityQuartersMap(_filters, _entityType)
    const selectedPage = pages[selectedPageIndex]
    const template = selectedPage.layout.template
    const layoutItemId = getLayoutItemId(template)
    const _id = shortid.generate()
    const _parsedFilters = getParsedFilters(fields, filters)

    const formattedSnapshot = {
      _entityType: getFlattenedData(_entityType),
      fields: getFlattenedData(fields),
      pivotFields,
      filters: getFormattedFilters(_parsedFilters, _pivotQuarters),
      sort: getFormattedSort(sort, fields, _pivotQuarters),
      peers: getFlattenedData(peers || [], '_security'),
      indices: getFormattedIndices(indices),
      limit,
      isPivot,
      isLocked,
      _id
    }

    this.setState({
      isReportOutdated: true,
      isDrawerLibraryOpen: false,
      nextAvailableLayoutItem: null
    }, () => {
      storeReportBuilderItem({
        pages: update(pages, {
          $splice: [[selectedPageIndex, 1, update(pages[selectedPageIndex], {
            layout: {
              template: {
                $push: [{
                  i: layoutItemId,
                  ...nextAvailableLayoutItem
                }]
              }
            },
            widget: {
              $push: [{
                layout_id: layoutItemId,
                _id,
                title,
                showTitle: (typeof showTitle === 'undefined') ? true : showTitle,
                widgetType: widgetType || 'table',
                snapshot: formattedSnapshot,
                peers,
                chartOptions
              }]
            }
          })]]
        }),
        isThumbnailOutdated: true
      })

      getReportSnapshot({...formattedSnapshot, limit: (!isLocked ? limit : 0)}, { securityId })
    })

  }
  /**
   * Returns promise with peers
   * @param template - selected drawer item
   */
  getPeers = (template) => new Promise((resolve => {
    const { loadPeers, setPeers } = this.props
    const { shouldFetchPeers, snapshot } = template

    if (shouldFetchPeers) {
      const peerParams = {
        select: ['symbol', 'exchange', 'security_name'],
        sortField: 'Symbol',
        sortOrder: 1,
        limit: 20,
        excludeMyCompany: true
      }

      return loadPeers(peerParams).then(() => resolve(this.props.peers))
    }

    setPeers(snapshot.peers)

    resolve(snapshot.peers)
  }))

  /**
   * Returns formatted chart options
   * @param template - selected drawer item
   * @param peers
   */
  formatChartOptions = (template, peers) => {
    const { ticker } = this.props
    const { chartOptions, snapshot } = template
    const { filters, _entityType, fields, indices } = snapshot
    const _filters = getParsedFilters(fields, filters)
    const _pivotQuarters = getEntityQuartersMap(_filters, _entityType)

    if (!template.categories.includes('custom') && chartOptions && chartOptions.series && chartOptions.series.length) {
      chartOptions.series = getInitialSeries({
        ticker,
        series: chartOptions.series,
        peers,
        indices,
        entityType: _entityType && _entityType[0],
        pivotQuarters: _pivotQuarters
      })
      chartOptions.series = applyColorToSeries(chartOptions.series, colorOptions)
    }

    return chartOptions
  }

  /**
   * Handle Widget Add
   * @param item
   */
  handleDrawerItemSelect = async (item) => {
    if (!item || !item.snapshot) {
      return
    }
    const selectedItem = cloneDeep(item)

    const peers = await this.getPeers(selectedItem)

    if (selectedItem.chartOptions) {
      selectedItem.chartOptions = this.formatChartOptions(selectedItem, peers)
    }

    this.addWidgetItem(selectedItem, peers)
    this.handleDrawerLibraryClose()
  }

  /**
   * Handle DrawerLibrary category action click
   */
  handleReportDataAdd = () => {
    this.props.clearPeers()
    this.setState({
      isReportDataEditorOpen: true,
      isDrawerLibraryOpen: false,
      selectedWidgetIndex: null
    })
  }

  /**
   * Handle widget item save
   * @param item
   */
  handleDataItemSave = (item) => {
    if (!item || !item.snapshot || !item.data) {
      return
    }

    const { storeReportBuilderItem, pages } = this.props
    const { selectedPageIndex, selectedWidgetIndex, nextAvailableLayoutItem } = this.state

    const { widgetType, snapshot, chartOptions, showTitle } = item
    const { _entityType, fields, pivotFields, filters, sort, limit, peers, indices, title, isPivot, isLocked } = snapshot || {}
    const _filters = getParsedFilters(fields, filters)
    const _pivotQuarters = getEntityQuartersMap(_filters, _entityType)
    const _id = shortid.generate()

    const formattedSnapshot = {
      _id,
      _entityType: getFlattenedData(_entityType),
      fields: getFlattenedData(fields),
      pivotFields: getFlattenedData(pivotFields),
      filters: getFormattedFilters(filters),
      sort: getFormattedSort(sort, fields, _pivotQuarters),
      peers: getFlattenedData(peers || [], '_security'),
      indices: getFormattedIndices(indices),
      limit,
      isPivot,
      isLocked
    }

    if (selectedWidgetIndex !== null) {
      storeReportBuilderItem({
        pages: update(pages, {
          $splice: [[selectedPageIndex, 1,
            update(pages[selectedPageIndex], {
              widget: {
                [selectedWidgetIndex]: {
                  title: {
                    $set: title
                  },
                  showTitle: {
                    $set: showTitle
                  },
                  widgetType: {
                    $set: widgetType || 'table'
                  },
                  snapshot: {
                    $set: formattedSnapshot
                  },
                  chartOptions: {
                    $set: getFormattedChartOptions(chartOptions)
                  },
                  data: {
                    $set: item.data
                  },
                  unwoundData: {
                    $set: item.unwoundData
                  },
                  meta: {
                    $set: item.meta
                  },
                  peers: {
                    $set: peers
                  }
                }
              }
            })
          ]]
        }),
        isThumbnailOutdated: true
      })
    } else {
      const selectedPage = pages[selectedPageIndex]
      const template = selectedPage.layout.template
      const layoutItemId = getLayoutItemId(template)

      storeReportBuilderItem({
        pages: update(pages, {
          $set: update(pages, {
            $splice: [[selectedPageIndex, 1, update(pages[selectedPageIndex], {
              layout: {
                template: {
                  $push: [{
                    i: layoutItemId,
                    ...nextAvailableLayoutItem
                  }]
                }
              },
              widget: {
                $push: [{
                  layout_id: layoutItemId,
                  _id,
                  title,
                  showTitle,
                  widgetType: widgetType || 'table',
                  snapshot: formattedSnapshot,
                  chartOptions: getFormattedChartOptions(chartOptions),
                  data: item.data,
                  unwoundData: item.unwoundData,
                  meta: item.meta,
                  peers
                }]
              }
            })]
            ]
          })
        }),
        isThumbnailOutdated: true
      })
    }

    this.setState({
      isReportOutdated: true,
      isReportDataEditorOpen: false,
      selectedWidgetIndex: null,
      nextAvailableLayoutItem: null
    })
  }

  /**
   * Handle Text Widget Add
   * @param nextAvailableLayoutItem
   */
  handleTextWidgetAdd = (nextAvailableLayoutItem) => {
    const { pages, storeReportBuilderItem } = this.props
    const { selectedPageIndex } = this.state

    const selectedPage = pages[selectedPageIndex]
    const template = selectedPage.layout.template
    const layoutItemId = getLayoutItemId(template)
    const _id = shortid.generate()

    this.setState({
      isReportOutdated: true
    }, () => {
      storeReportBuilderItem({
        pages: update(pages, {
          $splice: [[selectedPageIndex, 1, update(pages[selectedPageIndex], {
            layout: {
              template: {
                $push: [{
                  i: layoutItemId,
                  ...nextAvailableLayoutItem
                }]
              }
            },
            widget: {
              $push: [{
                layout_id: layoutItemId,
                _id,
                widgetType: 'text',
                data: {
                  text: '',
                  html: '',
                  styles: {}
                }
              }]
            }
          })]]
        }),
        isThumbnailOutdated: true
      })
    })
  }

  /**
   * Handle opening of ReportTextEditModal component
   * @param widgetIndex
   */
  handleTextEditModalOpen = (widgetIndex) => {
    this.setState({
      isTextEditModalOpen: true,
      selectedWidgetIndex: !isNaN(widgetIndex) ? widgetIndex : this.state.selectedWidgetIndex
    })
  }

  /**
   * Handle closing of ReportTextEditModal component
   */
  handleTextEditModalClose = () => {
    this.setState({
      isTextEditModalOpen: false
    })
  }

  /**
   * Handle PageEditor onLayoutChange event
   * @param templateLayout
   * @param oldItem
   * @param newItem
   */
  handleWidgetLayoutUpdate = (templateLayout, oldItem, newItem) => {
    const { pages, storeReportBuilderItem } = this.props
    const { selectedPageIndex } = this.state

    const shouldUpdateLayout = getShouldUpdateLayout(oldItem, newItem)

    if (shouldUpdateLayout) {
      storeReportBuilderItem({
        pages: update(pages, {
          $splice: [[selectedPageIndex, 1,
            update(pages[selectedPageIndex], {
              layout: {
                template: {
                  $merge: templateLayout
                }
              }
            })
          ]]
        }),
        isThumbnailOutdated: true
      })

      this.setState({
        isReportOutdated: true
      })
    }
  }

  /**
   * Handle PageEditor PageWidget onSelect event
   * @param widgetIndex
   */
  handleWidgetSelect = (widgetIndex) => {
    const { selectedWidgetIndex } = this.state

    if (selectedWidgetIndex !== widgetIndex) {
      this.setState({
        selectedWidgetIndex: widgetIndex
      })
    }
  }

  /**
   * Handle PageEditor PageWidget onSelect event
   * @param widgetIndex
   */
  handleWidgetEdit = (widgetIndex) => {
    const selectedWidget = this.getSelectedWidget(widgetIndex)

    if (!selectedWidget) {
      return
    }

    switch (selectedWidget.widgetType) {
      case 'text':
        this.handleTextEditModalOpen(widgetIndex)
        break
      case 'table':
      case 'chart':
      default:
        this.handleDataItemEdit(widgetIndex)
    }
  }

  /**
   * Handle widget data edit
   * @param widgetIndex
   */
  handleDataItemEdit = (widgetIndex) => {
    const selectedWidget = this.getSelectedWidget(widgetIndex)

    if (!selectedWidget || selectedWidget.widgetType === 'text') {
      return
    }

    const { storeReportDataItem, storeReportSnapshot, storeReportDataVisualization, setPeers, clearPeers } = this.props

    const { title, showTitle, widgetType, chartOptions, snapshot, unwoundData, data, meta, peers } = selectedWidget || {}
    const { _entityType, filters, fields, limit, sort, indices, isPivot, pivotFields, isLocked } = snapshot || {}

    storeReportDataItem({
      title,
      _entityType,
      fields,
      filters,
      pivotFields,
      limit,
      sort,
      indices,
      isPivot,
      isLocked,
      chartOptions,
      widgetType,
      showTitle,
      peers: snapshot.peers
    })

    storeReportSnapshot({
      unwoundData,
      data,
      meta
    })

    if (peers && peers.length) {
      setPeers(peers)
    } else {
      clearPeers()
    }

    storeReportDataVisualization({
      widgetType
    })

    this.setState({
      isReportDataEditorOpen: true,
      selectedWidgetIndex: !isNaN(widgetIndex) ? widgetIndex : this.state.selectedWidgetIndex
    })
  }

  /**
   * Handle ReportDataEditor onClose event
   */
  handleDataEditorClose = () => {
    this.setState({
      isReportDataEditorOpen: false
    })
  }

  /**
   * Handle ReportWidget onDataChange event
   * @param unwoundData
   */
  handleWidgetDataChange = (unwoundData) => {
    const { pages, storeReportBuilderItem } = this.props
    const { selectedPageIndex, selectedWidgetIndex } = this.state

    const selectedPage = pages[selectedPageIndex]
    const widgets = selectedPage && selectedPage.widget
    const selectedWidget = widgets && widgets[selectedWidgetIndex]
    const data = selectedWidget.widgetType === 'text' ? unwoundData : selectedWidget.data

    if (!selectedWidget) {
      return
    }

    this.setState({
      isReportOutdated: true,
    }, () => {
      storeReportBuilderItem({
        pages: update(pages, {
          $splice: [[selectedPageIndex, 1,
            update(pages[selectedPageIndex], {
              $merge: {
                widget: update(widgets, {
                  $splice: [[selectedWidgetIndex, 1, {
                    ...selectedWidget,
                    data,
                    unwoundData
                  }]]
                })
              }
            })
          ]]
        }),
        isThumbnailOutdated: true
      })
    })
  }

  /**
   * Handle Page Editor Update
   */
  handlePageUpdate = () => {
    const { isThumbnailOutdated, reportSnapshotStatus, storeReportBuilderItem } = this.props
    if (reportSnapshotStatus !== FETCHING && isThumbnailOutdated) {
      storeReportBuilderItem({ isThumbnailOutdated: false })
      // HACK: add delay to allow complex widgets to re-render and update react references
      setTimeout(this.handleGenerateThumbnail, 0)
    }
  }

  /**
   * Get ReportBuilderItem Request Body
   */
  getReportBody = async () => {
    const { title, pageLayout } = this.props
    const pages = cloneDeep(this.props.pages)

    return {
      title,
      pageLayout,
      pages: await Promise.all(pages.map(async (eachPage) => {

        // serialize thumbnail
        if (eachPage.thumbnail) {
          try {
            eachPage.thumbnail = await convertBlobToBase64(eachPage.thumbnail)
          } catch (err) {
            eachPage.thumbnail = ''
          }
        }

        // serialize widgets
        eachPage.widget = (eachPage.widget || []).map((eachWidget) => {
          delete eachWidget._id
          delete eachWidget.unwoundData

          if (eachWidget.chartOptions) {
            eachWidget.chartOptions = {
              ...eachWidget.chartOptions,
              series: (eachWidget.chartOptions.series || []).map((eachSeries) => omit(eachSeries, '_id'))
            }
          }

          return {
            ...eachWidget
          }
        })

        return omit(eachPage, 'tempId')
      }))
    }
  }

  /**
   * Get default page item
   * @returns {{_id: *, thumbnail: string, widget: Array, layout: {template: Array}}}
   */
  getDefaultPage = () => {
    return {
      tempId: shortid.generate(),
      thumbnail: '',
      widget: [],
      layout: {
        template: []
      }
    }
  }

  /**
   * Get Selected Widget
   * @param widgetIndex
   * @returns {*}
   */
  getSelectedWidget = (widgetIndex) => {
    const { pages } = this.props
    const { selectedPageIndex } = this.state
    let { selectedWidgetIndex } = this.state

    if (!isNaN(widgetIndex)) {
      selectedWidgetIndex = widgetIndex
    }

    const selectedPage = pages[selectedPageIndex]
    const selectedWidget = selectedPage && selectedPage.widget[selectedWidgetIndex]
    if (selectedWidget) {
      return selectedWidget
    }
  }

  /**
   * Get nextAvailableLayoutItem
   * @param widgetType
   * @param widgetItem
   */
  getNextAvailableLayoutItem = (widgetType, widgetIndex ) => {
    let { nextAvailableLayoutItem } = this.state

    if (nextAvailableLayoutItem) {
      return nextAvailableLayoutItem
    }

    const { pages, pageLayout } = this.props
    const { selectedPageIndex } = this.state
    const { columnCount, rowCount, marginCount } = config.pageBuilder

    const selectedPage = pages[selectedPageIndex]
    const template = selectedPage.layout.template

    const maxW = columnCount[pageLayout] - (marginCount * 2)
    const maxH = rowCount[pageLayout] - (marginCount * 2)

    let desiredW = getDesiredW(widgetType, maxW)
    let desiredH = getDesiredH(widgetType, maxH)

    if(!isNaN(widgetIndex) && template && template.length){
      desiredW = template[widgetIndex].w
      desiredH = template[widgetIndex].h
    }

    let availableLayoutItem = getAvailableLayoutItem(template, maxW, maxH, desiredW, desiredH)

    if (!availableLayoutItem) {
      const minDesiredW = getMinDesiredW(widgetType, maxW)
      availableLayoutItem = getAvailableLayoutItem(template, maxW, maxH, minDesiredW, desiredH)
    }

    return availableLayoutItem
  }

  /**
   * Get latest revision data
   * @param revisions
   */
  getLastRevisionData = (revisions) => {
    const revision = revisions && revisions.length && revisions[0]

    if (!revision) {
      return null
    }

    const isUTC = true
    const date = formatDate(revision.date, 'MMMM DD, YYYY', isUTC)
    const name = revision._profile && `${revision._profile.firstName} ${revision._profile.lastName}`

    return {
      date,
      name
    }
  }

  /**
   * Get Report Categories for DrawerLibrary component
   */
  getDrawerLibraryCategories = () => {
    return [{
      value: 'ownership',
      label: 'Ownership',
      icon: 'q4i-ownership-2pt',
      children: [{
        value: 'buyers',
        label: 'Buyers'
      }, {
        value: 'sellers',
        label: 'Sellers'
      }, {
        value: 'shareholders',
        label: 'Shareholders'
      }, {
        value: 'style',
        label: 'Style'
      }, {
        value: 'type',
        label: 'Type'
      }, {
        value: 'turnover',
        label: 'Turnover'
      }, {
        value: 'region',
        label: 'Region'
      }]
    }, {
      value: 'stock',
      label: 'Pricing',
      icon: 'q4i-stock-2pt'
    }, {
      value: 'custom',
      label: 'My Source Data',
      icon: 'q4i-reports-2pt'
    }]
  }

  /**
   * Get template
   * @param template
   */
  getFormattedTemplate = (template) => {
    const {
      _id, title, description, reportCategories,
      _entityType, fields, pivotFields, filters, peers, indices, limit, sort, isPivot,
      showTitle, chartOptions, widgetType, shouldFetchPeers, isLocked
    } = template
    const defaultItem = {
      isLockedTemplate: false,
      isDisabled: false,
      categories: reportCategories || ['custom']
    }

    return {
      id: _id,
      title: title || 'No title available',
      description: description || '',
      snapshot: {
        _entityType: _entityType || [],
        fields: fields || [],
        pivotFields: pivotFields || [],
        filters: filters || [],
        peers: peers || [],
        indices: indices || [],
        limit: limit || [],
        sort: sort || [],
        isPivot,
        isLocked
      },
      showTitle,
      chartOptions,
      widgetType,
      shouldFetchPeers,
      ...defaultItem
    }
  }

  /**
   * Get Report Selection for DrawerLibrary component
   */
  getDrawerLibraryItems = () => {
    const { reportDataTemplates, reportCustomTemplates, subscriptionsService } = this.props

    const filteredDataTempaltes = getFilteredTemplates(subscriptionsService, reportDataTemplates || [])
    const allTemplates = [].concat(filteredDataTempaltes).concat(reportCustomTemplates)

    return allTemplates.map((template) => this.getFormattedTemplate(template))
  }

  /**
   * Get toolbar Button actions for PageEditor component
   * @returns {[null]}
   */
  getToolbarActions = () => {
    const selectedWidget = this.getSelectedWidget()

    if (selectedWidget) {
      const widgetType = selectedWidget.widgetType

      return [widgetType === 'text' ? {
        tall: false,
        label: 'Edit Text',
        onClick: this.handleTextEditModalOpen
      } : {
        tall: false,
        label: 'Edit Source Data',
        onClick: this.handleDataItemEdit
      }, {
        tall: false,
        label: 'Duplicate',
        onClick: this.handleWidgetDuplicateRequest
      }, {
        tall: false,
        label: 'Delete',
        onClick: this.handleWidgetRemoveRequest
      }]
    } else {
      return [{
        tall: false,
        label: 'Source Data',
        onClick: () => this.handleWidgetAdd('data')
      }, {
        tall: false,
        label: 'Text Box',
        onClick: () => this.handleWidgetAdd('text')
      }]
    }
  }

  /**
   * Get props for the NoContentMessage component that renders when no widgets are present on the selected page
   * @returns {{icon: string, title: string, message: *, actions: {}[]}}
   */
  getNoContentMessageProps = () => {
    const { selectedPageIndex } = this.state

    return {
      theme: 'light',
      image: require('../../../resources/images/noContent/report.png').default,
      title: !selectedPageIndex ? 'Build a report' : 'Add additional content',
      message: (
        <span>Visualize your source data and add custom text<br />commentary to your reports.</span>
      ),
      actions: [{
        tall: false,
        theme: 'rain',
        label: 'Add Source Data',
        onClick: () => this.handleWidgetAdd('data')
      }, {
        tall: false,
        theme: 'rain',
        label: 'Add Text Box',
        onClick: () => this.handleWidgetAdd('text')
      }]
    }
  }

  /**
   * Get props for Message component based on scope
   * @param scope
   * @returns {*}
   */
  getMessageProps = (scope) => {
    switch (scope) {
      case 'error':
        return {
          type: 'error',
          title: 'Error',
          message: 'There was an issue when trying to update this Report. Please try again or contact us if you see this message again.',
          buttons: [{
            ui: 'shaded',
            label: 'close',
            onClick: this.handleMessageClose
          }]
        }
      case 'removePage':
        return {
          type: 'warning',
          title: 'Delete page?',
          message: 'Are you sure you want to delete this page? All widget configurations will be lost.',
          buttons: [{
            ui: 'shaded',
            label: 'cancel',
            onClick: this.handleMessageClose
          }, {
            ui: 'spice',
            label: 'confirm',
            onClick: () => this.removePage(this.state.pageToDeleteId)
          }]
        }
      case 'removeWidget':
        return {
          type: 'warning',
          title: 'Delete widget?',
          message: 'Are you sure you want to delete this widget?',
          buttons: [{
            ui: 'shaded',
            label: 'cancel',
            onClick: this.handleMessageClose
          }, {
            ui: 'spice',
            label: 'confirm',
            onClick: this.handleWidgetRemove
          }]
        }
      case 'noAvailableSpace':
        return {
          type: 'warning',
          title: 'No available space',
          message: 'We can\'t find any more room on the current page. Try moving things around, or consider adding a new page.',
          buttons: [{
            ui: 'shaded',
            label: 'ok',
            onClick: this.handleMessageClose
          }]
        }
      case 'discard':
      default:
        const { history } = this.props

        return {
          type: 'warning',
          title: 'Discard Changes?',
          message: 'Are you sure you want to discard your changes? Any unsaved changes will be lost.',
          buttons: [{
            ui: 'shaded',
            label: 'cancel',
            onClick: this.handleMessageClose
          }, {
            ui: 'spice',
            label: 'confirm',
            onClick: () => history.push('/builder')
          }]
        }
    }
  }

  /**
   * Render Page's Widget Item
   * @param layoutItem
   * @param widgetItem
   */
  renderWidget = (layoutItem, widgetItem) => {
    if (!widgetItem) {
      return null
    }

    const { pageLayout, reportBuilderFields, reportEntityTypes, reportSnapshotId, reportSnapshotStatus } = this.props
    const { _id, title, showTitle, unwoundData, meta, snapshot, widgetType, chartOptions } = widgetItem
    const { fields, filters, _entityType, limit, sort, indices, isPivot, pivotFields } = snapshot || {}
    const { chartType, aggregationModel, yAxis, xAxis, series } = chartOptions || {}

    return (
      <ReportWidget
        height={getWidgetHeight(layoutItem, pageLayout)}
        title={title}
        showTitle={showTitle}
        widgetType={widgetType}
        entityTypes={parsePopulatedEntityTypes(reportEntityTypes, _entityType)}
        fields={parsePopulatedFields(reportBuilderFields, fields)}
        pivotFields={parsePopulatedFields(reportBuilderFields, pivotFields)}
        filters={parsePopulatedFilters(reportBuilderFields, filters)}
        isPivot={isPivot}
        limit={limit}
        indices={parsePopulatedIndices(indices)}
        sort={sort}
        chartOptions={{
          chartType,
          aggregationModel,
          yAxis,
          xAxis: parseXAxis(reportBuilderFields, xAxis),
          series: parseSeries(reportBuilderFields, series)
        }}
        data={(reportSnapshotStatus === FAILED) ? [] : unwoundData}
        dataStatus={_id === reportSnapshotId ? reportSnapshotStatus : IDLE}
        dataId={meta && meta._id}
      />
    )
  }

  /**
   * Render ReportTextEditModal component
   * @param visible
   * @returns {*}
   */
  renderTextEditModal = (visible) => {
    const selectedWidget = this.getSelectedWidget()

    if (!selectedWidget || selectedWidget.widgetType !== 'text') {
      return
    }

    const data = selectedWidget.data && typeof selectedWidget.data === 'object'
      ? selectedWidget.data
      : { html: selectedWidget.data }

    return (
      <ReportTextEditModal
        data={data || {}}
        visible={visible}
        onSave={this.handleWidgetDataChange}
        onClose={this.handleTextEditModalClose}
      />
    )
  }

  /**
   * Render Prepopulated Report Builder Modal
   * @returns {boolean|XML}
   */
  render () {
    const {
      reportBuilderItem, title, pages, pageLayout, reportBuilderItemRevisions, reportBuilderItemStatus,
      fullscreenEnabled, reportSnapshotStatus
    } = this.props
    const {
      selectedPageIndex, selectedWidgetIndex,
      isPageEditorExpanded, isDrawerLibraryOpen, isTextEditModalOpen, isReportDataEditorOpen, isMessageOpen, _messageScope,
      isReportOutdated, initialLoad, isSaving, isExporting
    } = this.state

    if (reportBuilderItemStatus === FAILED) {
      return (
        <Redirect to='/error/404' />
      )
    }

    if (!initialLoad && reportBuilderItemStatus !== FETCHED) {
      return (
        <Spinner />
      )
    }

    if (isReportDataEditorOpen) {
      return (
        <ReportDataEditor
          saveMode='local'
          onModalClose={this.handleDataEditorClose}
          onItemSave={this.handleDataItemSave}
        />
      )
    }

    const lastRevision = this.getLastRevisionData(reportBuilderItemRevisions)
    const messageProps = this.getMessageProps(_messageScope)
    const reportExist = reportBuilderItem && reportBuilderItem._id

    const baseClassName = getClassName('report-builder', [
      { condition: isPageEditorExpanded, trueClassName: 'report-builder--no-banner' },
      { condition: fullscreenEnabled, trueClassName: 'report-builder--fullscreen' }
    ])

    return (
      <div className={baseClassName}>
        <Banner
          size='thin'
          title={(
            <EditableText
              theme='citrus'
              invisible={true}
              inputHeight={20}
              value={title}
              minLength={1}
              shouldDisposeValue={title === 'Blank Report'}
              onSubmit={this.handleTitleSubmit}
              onQueryChange={this.handleTitleChange}
            />
          )}
          icon='q4i-reports-2pt'
          details={lastRevision && (
            <span>Updated <span>{lastRevision.date}</span> by <span>{lastRevision.name}</span></span>
          )}
          controls={[{
            type: 'button',
            theme: 'shaded',
            label: (reportBuilderItem && reportBuilderItem._id) ? 'Back' : 'Cancel',
            onClick: this.handleCancel
          }, {
            type: 'button',
            theme: 'citrus',
            loading: isSaving,
            disabled: !initialLoad || reportSnapshotStatus === FETCHING,
            label: 'save',
            onClick: this.handleSaveClick
          }, {
            type: 'button',
            theme: 'citrus',
            loading: isExporting,
            disabled: !reportExist || !initialLoad || isReportOutdated,
            label: 'export',
            onClick: this.handleExport
          }]}
        />

        <section className='report-builder_body'>
          <PageManager
            pages={pages}
            selectedPageIndex={selectedPageIndex}
            pageLayout={pageLayout}
            pageLimit={reportPageLimit}
            onSizeToggle={this.handleManagerSizeToggle}
            onPageAdd={this.handlePageAdd}
            onPageSelect={this.handlePageSelect}
            onPageDuplicate={this.handlePageDuplicate}
            onPageRemove={this.handlePageRemove}
            onPageMove={this.handlePageMove}
          />
          <PageEditor
            layout={pageLayout}
            isFullscreen={fullscreenEnabled}
            isExpanded={isPageEditorExpanded}
            toolbarActions={this.getToolbarActions()}
            noContentMessageProps={this.getNoContentMessageProps()}
            pages={pages}
            selectedPageIndex={selectedPageIndex}
            selectedWidgetIndex={selectedWidgetIndex}
            paperRef={this.paperContainer}
            renderWidget={this.renderWidget}
            onSizeToggle={this.handleEditorSizeToggle}
            onWidgetUpdate={this.handleWidgetLayoutUpdate}
            onWidgetEdit={this.handleWidgetEdit}
            onWidgetSelect={this.handleWidgetSelect}
            onWidgetRemove={this.handleWidgetRemoveRequest}
            onWidgetDuplicate={this.handleWidgetDuplicateRequest}
            onPageUpdate={this.handlePageUpdate}
            setUpdatePaperScale={(updatePaperScaleFunc) => this.updatePaperScale = updatePaperScaleFunc}
          />
        </section>

        <DrawerLibrary
          isOpen={isDrawerLibraryOpen}
          libraryTitle='Source Data'
          categories={this.getDrawerLibraryCategories()}
          categoryAction={{
            label: 'Create Source Data',
            onClick: this.handleReportDataAdd
          }}
          items={this.getDrawerLibraryItems()}
          noItemMessage='No Source Data to choose from.'
          onItemSelect={this.handleDrawerItemSelect}
          onClose={this.handleDrawerLibraryClose}
        />

        {isTextEditModalOpen && this.renderTextEditModal(isTextEditModalOpen)}

        <Message
          visible={isMessageOpen}
          type={messageProps.type}
          title={messageProps.title}
          message={messageProps.message}
          buttons={messageProps.buttons}
          onClose={this.handleMessageClose}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { profile, report } = state

  const { reportBuilderItem, reportDataTemplates, reportSnapshot, reportPeerConfig } = report
  const { data, status } = reportBuilderItem
  const { title, pages, pageLayout, revisions, isThumbnailOutdated } = data || {}

  const profileData = profile && profile.data
  const ticker = getActiveTicker(profileData)
  const services = (profileData.services || []).reduce((acc, each) => {
    acc[each.type] = each.enabled
    return acc
  }, {})

  return {
    securityId: ticker && ticker._security,
    reportBuilderItem: data || {},
    title,
    pages: pages || [],
    pageLayout: pageLayout || 'landscape',
    reportBuilderItemRevisions: revisions || [],
    peers: reportPeerConfig.peers || [],
    ticker,
    reportBuilderItemStatus: status,
    reportDataTemplates: reportDataTemplates.data || [],
    reportDataTemplateStatus: reportDataTemplates.status,
    subscriptionServices: services,
    reportEntityTypes: report.reportEntityTypes.data || [],
    reportBuilderFields: report.reportBuilderFields.data || [],
    fullscreenEnabled: state.ui.fullscreen.enabled,
    reportCustomTemplates: report.reportCustomTemplates.data || [],
    reportCustomTemplatesStatus: report.reportCustomTemplates.status,
    reportSnapshotId: reportSnapshot.meta._id,
    reportSnapshotStatus: reportSnapshot.status,
    subscriptionsService: (services.surveillance && 'surveillance') || (services.shareholder_id && 'shareholder_id'),
    isThumbnailOutdated
  }
}

const mapDispatchToProps = (dispatch) => ({
  getReportBuilderItem: bindActionCreators(getReportBuilderItem, dispatch),
  saveReportBuilderItem: bindActionCreators(saveReportBuilderItem, dispatch),
  storeReportBuilderItem: bindActionCreators(storeReportBuilderItem, dispatch),
  setReportBuilderItemFetched: bindActionCreators(setReportBuilderItemFetched, dispatch),
  resetReportBuilderItemStatus: bindActionCreators(resetReportBuilderItemStatus, dispatch),
  getReportDataTemplates: bindActionCreators(getReportDataTemplates, dispatch),
  setAppFullscreen: bindActionCreators(setAppFullscreen, dispatch),
  createToast: bindActionCreators(createToast, dispatch),
  getReportBuilderFields: bindActionCreators(getReportBuilderFields, dispatch),
  getReportEntityTypes: bindActionCreators(getReportEntityTypes, dispatch),
  getReportSnapshot: bindActionCreators(getReportSnapshot, dispatch),
  storeReportDataItem: bindActionCreators(storeReportDataItem, dispatch),
  storeReportDataVisualization: bindActionCreators(storeReportDataVisualization, dispatch),
  getCustomTemplates: bindActionCreators(getCustomTemplates, dispatch),
  setPeers: bindActionCreators(setPeers, dispatch),
  clearPeers: bindActionCreators(clearPeers, dispatch),
  loadPeers: bindActionCreators(loadPeers, dispatch),
  storeReportSnapshot: bindActionCreators(storeReportSnapshot, dispatch),
  downloadExportData: bindActionCreators(downloadExportData, dispatch),
  removeReportPageThumbnail: bindActionCreators(removeReportPageThumbnail, dispatch),
  resetReportDataItem: bindActionCreators(resetReportDataItem, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportBuilderContainer))
