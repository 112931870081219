import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import CustomCell from './cell/cell.component'
import { AgGrid, NoContentMessage, Spinner } from '../../../../../components'
import { getDefaultColumnDef, DEFAULT_DATE_FORMAT, THEMES, setColumnDefinition } from '../../../../../utils'
import { groupBy } from 'lodash'

const propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired
}

const defaultProps = {
  loading: false,
  data: []
}

/**
 * Get estimate table headers
 * @param data
 * @returns {Array}
 */
const getEstimateHeader = (data) => {
  const limit = 11
  const header = (data || [])
    .reduce((acc, current) => {
      const duplicate = acc.find((item) => moment(item.date).isSame(moment(current.date)))
      return duplicate ? acc : acc.concat([current])
    }, [])
    .map((value) => {
      const { date, frequency, quarter } = value
      const period = moment.isMoment(date) ? date : moment.utc(date)
      const suffix = (frequency === 'annual') ? 'FY' : (frequency === 'quarterly') ? 'Q' + quarter : ''

      return {
        field: `${period.format(DEFAULT_DATE_FORMAT)}-${frequency}`,
        headerName: `${period.format(DEFAULT_DATE_FORMAT)} ${suffix}`,
        type: 'number',
        minWidth: 110,
        maxWidth: 110,
        ...value
      }
    })

  const length = header.length

  return (length > limit) ? header.slice(length - limit, length) : header
}

/**
 * Get estimate data
 * @param data
 * @returns {*}
 */
const getEstimateData = (data) => {
  const header = getEstimateHeader(data)
  const groups = groupBy(data, (option) => (option && option.item))

  return Object.values(groups).map((group) => {
    return {
      category: group[0].item,
      data: header.map((range) => {
        const value = group.find((value) => {
          return range.frequency === value.frequency && moment(value.date).isSame(range.date)
        })

        return value || '-'
      })
    }
  })
}

/**
 * Contact Estimate Table
 * @param props
 */
function ContactEstimateTable (props) {
  const { pageSizeId, loading, data } = props
  const [agGrid, setAgGrid] = useState({})

  const header = getEstimateHeader(data)
  header.unshift({ field: 'category', headerName: 'Category', minWidth: 360 })
  const columns = (header || []).map(({ type, ...column }, idx) => ({
    ...getDefaultColumnDef({ idx, type }),
    ...column
  }))

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell {...props} />
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage />
        : <AgGrid
          domLayout='autoHeight'
          sizeToFit

          // suppress configs
          suppressMovableColumns
          suppressContextMenu

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={setColumnDefinition({ columns, isLocked: false })}
          rowData={getEstimateData(data)}

          // custom components
          frameworkComponents={{
            CustomCellRender: renderCustomCell
          }}

          // event listeners
          onGridReady={handleGridReady}
          onGridSizeChanged={handleGridResize}
        />}
    </div>
  )
}

ContactEstimateTable.propTypes = propTypes
ContactEstimateTable.defaultProps = defaultProps

export default memo(ContactEstimateTable)
