import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// actions
import { sendMail, openModal, modalType, statusType, MAIL_SUCCESS, MAIL_ERROR } from '../../../actions'

// components
import UpdateRequestModal from './updateRequest.component'
import { get } from 'lodash'
import { MESSAGE_TYPE } from '../../../utils/message'

const propTypes = {
  dataId: PropTypes.string,
  onClose: PropTypes.func
}

const defaultProps = {
  dataId: 'EntityUpdate'
}

/**
 * Convert request form values to model expected from API
 * @param values
 * @returns {*}
 */
function getPayload (values) {
  const { entityId, entityType, name, changes, message } = (values || {})

  return {
    to: 'desktop@q4inc.com',
    template: 'iris-update-request',
    subject: `Update Request ${name ? `- ${name}` : ''}`,
    date: new Date(),
    entity_id: entityId,
    entity_type: entityType,
    entity_name: name,
    changeType: changes,
    message
  }
}

/**
 * Entity Update Request
 * @param props
 */
function EntityUpdateRequest (props) {
  const { dataId, loading, values, onClose, sendMail, openModal } = props

  /**
   * Handle update request submit
   * @param values
   */
  const handleSubmit = (values) => {
    const payload = getPayload(values)

    sendMail(payload).then((data) => {
      switch (data.type) {
        case MAIL_SUCCESS:
          onClose && onClose()
          return openModal({
            type: modalType.INFO_MODAL,
            props: {
              type: MESSAGE_TYPE.SUCCESS,
              content: {
                title: 'Request Successfully Submitted',
                message: 'Thank you for your request. A support agent has received it and will get back to you with an update.'
              }
            }
          })
        case MAIL_ERROR:
          return openModal({ type: modalType.ERROR_MODAL })
        default:
          break
      }
    })
  }

  return (
    <UpdateRequestModal
      dataId={dataId}
      loading={loading}
      values={values}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  )
}

EntityUpdateRequest.propTypes = propTypes
EntityUpdateRequest.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  loading: get(state, 'shared.mail.status') === statusType.IN_PROGRESS
})

const mapDispatchToProps = (dispatch) => ({
  sendMail: bindActionCreators(sendMail, dispatch),
  openModal: bindActionCreators(openModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(EntityUpdateRequest)
