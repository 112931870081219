import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { useAIScoreQuery } from '../hook'
import { openModal } from '../../../actions/ui'

// components
import EntityScore from '../score/score.component'
import { getActiveTicker, isPassive, AISCORE, THEMES } from '../../../utils'
import { get } from 'lodash'

const propTypes = {
  theme: PropTypes.oneOf([THEMES.LIGHT, THEMES.DARK]),
  dataId: PropTypes.string,
  securityId: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  style: PropTypes.string
}

const defaultProps = {
  theme: THEMES.DARK
}

/**
 * Drivers Component
 * @param props
 */
const Drivers = ({ drivers }) => {
  return (
    (drivers && drivers.length)
      ? <>
        <header className='message_list-row message_list-row--header'>Investment Drivers</header>
        {(drivers || []).map((driver) => {
          const { importance, variable } = driver
          return <div key={importance} className='message_list-row'>{variable}</div>
        })}
      </>
      : <span className='message_list--no-data'>No investment drivers available</span>
  )
}

/**
 * AIScore Component
 * @param props
 */
function AIScore (props) {
  const { theme, dataId, securityId, entityId, entityType, style, openModal } = props

  const { loading, data } = useAIScoreQuery({
    variables: {
      securityId,
      entityId,
      entityType
    }
  })

  const aiScore = get(data, 'aiScore.items[0].score')
  const drivers = get(data, 'aiScore.items[0].rankConnection.items')

  /**
   * Render Attributes Component
   */
  return (
    <EntityScore
      dataId={`${dataId}AiScore`}
      theme={theme}
      loading={loading}
      data={{
        title: 'AI Score',
        score: isPassive({ style, aiScore }),
        message: AISCORE,
        list: <Drivers drivers={drivers} />
      }}
      openModal={openModal}
    />
  )
}

AIScore.propTypes = propTypes
AIScore.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const ticker = getActiveTicker(get(state, 'profile.data'))
  return {
    securityId: get(ticker, 'q4_entity_id', '1234567890') // TODO: tmp default value for pre-IPO
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AIScore)
