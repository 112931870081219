import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isEqual, get } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ContactForm from './contactForm.component';
import { Message } from '../../../components';
import {
  createContact,
  updateContact,
  createCorporateParticipant,
  updateCorporateParticipant,
  fetchEditContact,
  fetchEditCorporateParticipant,
  dismissError,
  resetForm,
  createToast,
  searchInstitution,
  clearSuggestions,
  messageSencha,
  openModal,
  getContactSuggestions,
  clearContactSuggestions,
  CREATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_SUCCESS,
  CREATE_CORPORATE_PARTICIPANT_SUCCESS,
  UPDATE_CORPORATE_PARTICIPANT_SUCCESS,
  statusType
} from '../../../actions';

import { debounce, CORPORATE_PARTICIPANT } from '../../../utils'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../../services/launchDarkly.service';

class ContactFormContainer extends Component {

  /**
   * ComponentDidMount
   * Fetch contact or coporate participant to edit
   */
  componentDidMount = () => {
    const { fetchContact, fetchCorporateParticipant, id, type } = this.props;
    if (id) {
      type === CORPORATE_PARTICIPANT ? fetchCorporateParticipant(id) : fetchContact(id);
    }
  };

  /**
   * ComponentWillUnmount
   */
  componentWillUnmount() {
    this.onSearch.cancel(); // reset debounce
    this.props.resetForm();
  }

  /**
   * Map data to form fields
   * Transforms contact from api to form values when editing
   * @param payload
   */
  mapToFieldValues = (data) => {
    if (!data) {
      return {};
    }

    const { type } = this.props;
    const job = data.jobs && data.jobs[0];

    if (type === CORPORATE_PARTICIPANT) {
      const fullName = data && data.full_name;
      let firstName = data && data.first_name;
      let lastName = data && data.last_name;

      if ((!firstName || !lastName) && fullName) {
        data.first_name = firstName || data.full_name.split(' ')[0] || '';
        const parsedArray = data.full_name.split(' ');
        parsedArray.shift();
        data.last_name = lastName || parsedArray.join(' ');
      }
    }

    return {
      id: data._id,
      firstName: data.first_name,
      lastName: data.last_name,
      nickName: data.nickname,
      mobile: data.mobile,
      bio: data.bio,
      street: job && job.address1,
      city: job && job.city,
      zip: job && job.postal_code,
      state: job && job.state_province,
      countryName: job && job.country_name,
      country: job && job.country,
      phone: job && job.direct_telephone,
      email: job && job.email,
      institutionName: job && job.institution_name,
      institutionId: job && job._id,
      factsetEntityId: job && job.factset_entity_id,
      q4EntityId: job && job.q4_entity_id,
      institutionType: job && job.institution_type,
      corporatePhone: job && job.phone,
      jobTitle: job && job.title,
    };
  };

  /**
   * Search for duplicate contact
   */
  searchDuplicates = ({ firstName, lastName, institutionName }) => {
    const isNewSearchEngine = !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)

    const { getContactSuggestions } = this.props
    const query = isNewSearchEngine
      ? (institutionName ? `"${institutionName}" ` : '') + `${firstName} ${lastName}`
      : `"${firstName} ${lastName}"` + (institutionName ? ` + "${institutionName}"` : '')
    
    getContactSuggestions(query)
  }

  /**
   * Clear duplicate suggestions
   */
  clearDuplicates = () => {
    const { clearContactSuggestions } = this.props
    clearContactSuggestions()
  }

  /**
   * On Change
   * @param values
   * @param prevValues
   */
  onChange = debounce((values, prevValues) => {
    const { firstName, lastName, institutionName, type } = values
    const { firstName: prevFirstName, lastName: prevLastName, institutionName: previnstitutionName } = prevValues
    const isContact = (type === 'contact')

    const isChanged = !isEqual({
      firstName,
      lastName,
      institutionName
    }, {
      firstName: prevFirstName,
      lastName: prevLastName,
      institutionName: previnstitutionName
    })

    if (isContact && isChanged) {
      if (firstName && lastName) {
        this.searchDuplicates({ firstName, lastName, institutionName })
      }
      else {
        this.clearDuplicates()
      }
    }
  })

  /**
   * On Close
   */
  onClose = () => {
    const { onClose, resetForm } = this.props;
    onClose && onClose();
    resetForm();
  };

  /**
   * On Save
   * @param values - form values
   */
  onSave = (values) => {
    const isNew = !(values && values.id);
    isNew ? this.create(values) : this.update(values.id, values);
  };

  /**
   * On Save Success
   * Create toast, pass created contact to Activity form, and remove modal component
   */
  onSaveSuccess = () => {
    const { messageSencha, createToast, contact, onSaveSuccess } = this.props;
    messageSencha({ type: 'contactSave', params: { contact } });
    onSaveSuccess && onSaveSuccess(contact);
    this.onClose();
    createToast({ text: 'Contact saved successfully.' });
  };

  /**
   * Institution Search
   */
  onSearch = debounce((query) => this.props.searchInstitution(query))

  /**
   * Create Contact
   * @param data
   */
  create = (data) => {
    const { type } = data;
    const { createCorporateParticipant, createContact } = this.props;
    const handler = (type === CORPORATE_PARTICIPANT) ? createCorporateParticipant : createContact;
    handler(data).then((data) => {
      const success = [
        CREATE_CONTACT_SUCCESS,
        CREATE_CORPORATE_PARTICIPANT_SUCCESS
      ].includes(data.type);
      success && this.onSaveSuccess();
    });
  };

  /**
   * Update Contact
   * @param data
   */
  update = (id, data) => {
    const { type } = data;
    const { updateCorporateParticipant, updateContact } = this.props;
    const handler = (type === CORPORATE_PARTICIPANT) ? updateCorporateParticipant : updateContact;
    handler(id, data).then((data) => {
      const success = [
        UPDATE_CONTACT_SUCCESS,
        UPDATE_CORPORATE_PARTICIPANT_SUCCESS
      ].includes(data.type);

      success && this.onSaveSuccess();
    });
  };

  /**
   * Close Error Message
   */
  onErrorMessageClose = () => {
    const { dismissError } = this.props;
    dismissError();
  };

  /**
   * Handle Select Contact Suggestion
   */
  handleSelectContactSuggestion = (contact) => {
    if (!contact || !contact._id) {
      return
    }

    const { fetchContact, onSaveSuccess, onSelectSuccess, messageSencha, resetForm } = this.props;
    fetchContact(contact._id).then(({payload}) => {
      if (payload) {
        messageSencha({ type: 'contactSave', params: { contact: payload } });
        onSaveSuccess && onSaveSuccess(payload)
        onSelectSuccess && onSelectSuccess(payload)
        resetForm();
      }
    });

    this.onClose();
  }

  /**
   * Render Error Message
   * @param props.visible
   * @return {function()}
   */
  renderErrorMessage = ({ visible }) => {
    return (
      <Message
        visible={visible}
        type='error'
        title={'Oops, something went wrong!'}
        message={'Please try again.'}
        buttons={[{
          ui: 'shaded',
          label: 'close',
          onClick: this.onErrorMessageClose
        }]} />
    );
  };

  /**
   * Render
   * @return {XML}
   */
  render() {
    const { form, searchSuggestions, clearSuggestions, contact, type, isTypeSelectable, contactSuggestions, openModal } = this.props;
    const loading = form && form.status === statusType.IN_PROGRESS;
    const showErrorMessage = Boolean(form.error);
    const ErrorMessage = this.renderErrorMessage;

    return (
      <React.Fragment>
        <ContactForm
          type={type}
          isTypeSelectable={isTypeSelectable}
          values={this.mapToFieldValues(contact)}
          loading={loading}
          visible={true}
          onSearch={this.onSearch}
          searchSuggestions={searchSuggestions}
          clearSuggestions={clearSuggestions}
          contactSuggestions={contactSuggestions}
          onSelectContactSuggestion={this.handleSelectContactSuggestion}
          openModal={openModal}
          onChange={this.onChange}
          onClose={this.onClose}
          onSave={this.onSave}
        />
        <ErrorMessage visible={showErrorMessage} />
      </React.Fragment>
    );
  }
}

ContactFormContainer.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  isTypeSelectable: PropTypes.bool,
  onClose: PropTypes.func,
  onSaveSuccess: PropTypes.func
};

ContactFormContainer.defaultProps = {
  type: 'contact',
  isTypeSelectable: true
};

const mapStateToProps = (state) => {
  const form = state.contact && state.contact.form;
  const profile = get(state, 'profile.data')
  
  return {
    profile,
    form,
    searchSuggestions: get(form, 'searchSuggestions'),
    contactSuggestions: get(form, 'contactSuggestions'),
    contact: get(form, 'data')
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchContact: bindActionCreators(fetchEditContact, dispatch),
  createContact: bindActionCreators(createContact, dispatch),
  updateContact: bindActionCreators(updateContact, dispatch),
  fetchCorporateParticipant: bindActionCreators(fetchEditCorporateParticipant, dispatch),
  createCorporateParticipant: bindActionCreators(createCorporateParticipant, dispatch),
  updateCorporateParticipant: bindActionCreators(updateCorporateParticipant, dispatch),
  dismissError: bindActionCreators(dismissError, dispatch),
  createToast: bindActionCreators(createToast, dispatch),
  resetForm: bindActionCreators(resetForm, dispatch),
  searchInstitution: bindActionCreators(searchInstitution, dispatch),
  getContactSuggestions: bindActionCreators(getContactSuggestions, dispatch),
  clearContactSuggestions: bindActionCreators(clearContactSuggestions, dispatch),
  clearSuggestions: bindActionCreators(clearSuggestions, dispatch),
  messageSencha: bindActionCreators(messageSencha, dispatch),
  openModal: bindActionCreators(openModal, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactFormContainer));
