import React, { useState, useEffect, memo } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

// components
import { Modal, TextField, Select } from '../../index'

// utils
import { THEMES } from '../../../utils'
import { get, uniqBy } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  entities: PropTypes.array.isRequired,
  isSearching: PropTypes.bool,
  suggestions: PropTypes.array.isRequired,
  onSearch: PropTypes.func.isRequired,
  isAddToProgress: PropTypes.bool,
  onAddToBriefingBook: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

const defaultProps = {
  entities: [],
  suggestions: []
}

/**
 * Normalize briefing book suggestions
 * @param suggestions
 */
function getOptions (suggestions) {
  const actions = [{
    type: 'action',
    theme: THEMES.CITRUS,
    label: 'Create New Briefing Book',
    onClick: () => {}
  }]

  const options = (suggestions || []).map((suggestion) => {
    return (suggestion && suggestion.id) ? {
      value: suggestion.id,
      label: suggestion.title,
      data: { ...suggestion }
    } : null
  })

  return [].concat(options, actions)
}

/**
 * Add Entity To Briefing Book Component
 * @param props
 */
function AddToModal (props) {
  const { dataId, entities, isSearching, suggestions, onSearch, isAddToProgress, onAddToBriefingBook, onClose } = props

  const [state, setState] = useState({
    title: '',
    isNewBook: false,
    selectedBook: null
  })
  const [errors, setErrors] = useState({
    book: false,
    title: false
  })

  const { title, isNewBook, selectedBook } = state
  const options = getOptions(suggestions)

  /**
   * Set or clear errors
   */
  useEffect(() => {
    if ((errors.title && title.length) || (errors.book && selectedBook)) {
      setErrors({
        book: !isNewBook && (!selectedBook || !selectedBook.id),
        title: isNewBook && !(title.trim() && title.trim().length)
      })
    }
  }, [title, isNewBook, selectedBook, errors, setErrors])

  /**
   * Add entity(ies) to selected briefing book
   * @param suggestion
   */
  const handleSuggestionSelect = (suggestion) => {
    const { type, data } = (suggestion || {})
    const { id, title } = (data || {})

    const isAction = type && type === 'action'
    const allEntities = get(data, 'entity', [])
      .map(({ entityId, entityType, institutionId }) => ({ entityId, entityType, institutionId })).concat(entities)
    const entity = uniqBy(allEntities, (entity) => entity.entityId)

    setState({
      title: '',
      isNewBook: !!isAction,
      selectedBook: id ? { id, title, entity } : null
    })
  }

  /**
   * Handle modal submit
   */
  const handleSubmit = () => {
    const isTitle = title.trim() && title.trim().length
    const params = isNewBook ? { title, entity: entities } : selectedBook

    if ((isNewBook && !isTitle) || (!isNewBook && !selectedBook)) {
      setErrors({
        book: !isNewBook && (!selectedBook || !selectedBook.id),
        title: isNewBook && !isTitle
      })

      return
    }

    onAddToBriefingBook(params)
  }

  return (
    <Modal
      dataId={dataId}
      visible
      disableRestoreFocus
      loading={isAddToProgress}
      title='Add to Briefing Book'
      footerButtons={[
        {
          dataId: `${dataId}Cancel`,
          label: 'Cancel',
          ui: 'shaded',
          onClick: onClose
        },
        {
          dataId: `${dataId}Save`,
          label: 'Save',
          ui: THEMES.CITRUS,
          onClick: handleSubmit
        }]}
      onClose={onClose}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Select
            error={{ isError: errors.book }}
            label='Briefing Book'
            placeholder='Search'
            loading={isSearching}
            options={options}
            onInputChange={onSearch}
            onChange={handleSuggestionSelect}
            showDropdownIndicator={false}
            required={!isNewBook}
          />
        </Grid>
        {isNewBook && <Grid item xs={12}>
          <TextField
            error={{ isError: errors.title }}
            label='Title'
            value={title}
            placeholder='My Briefing Book'
            onChange={(event) => setState({ ...state, title: get(event, 'target.value', '') })}
            required
          />
        </Grid>}
      </Grid>
    </Modal>
  )
}

AddToModal.propTypes = propTypes
AddToModal.defaultProps = defaultProps

export default memo(AddToModal)
