import React, { useState } from 'react'
import { Tag, PopoverMenu } from '../../../../components'
import { getActivityDate, THEMES } from '../../../../utils'

/**
 * Activity
 * @param props
 * @returns {*}
 */
export const Activity = (props) => {
  const [showMore, setShowMore] = useState(false)
  const { _id, title, start, end, allDay, tags, history } = props
  const limit = 3

  /**
   * On tag click
   * @param event
   * @param tag
   */
  const onTagClick = (event, tag) => {
    event.stopPropagation()
    event.preventDefault()

    history && history.push(`/search?query=${encodeURIComponent('#' + tag.name)}`)
  }

  /**
   * On more item (tags) click
   * @param event
   * @param id
   */
  const onMoreItemClick = (event, id) => {
    event.stopPropagation()
    event.preventDefault()
    setShowMore({ [id]: event.target })
  }

  /**
   * On more item (tags) close click
   * @param event
   */
  const onMoreItemCloseClick = (event) => {
    event.stopPropagation()
    setShowMore({})
  }

  return (
    <>
      <div className='item_content-title'>{title}</div>
      <div className='item_content-subtitle'>{getActivityDate({ start, end, allDay })}</div>
      {!!(tags && tags.length) && <div className='item_content-body'>
        <div className='item_content-detail'>
          <Tag
            id={`tag-input-component--${_id}`}
            theme={THEMES.LIGHT}
            className={`tags--thin tags--wide`}
            isHeader
            items={tags}
            limit={limit}
            onClick={onTagClick}
            onMoreClick={onMoreItemClick}
          />
        </div>
      </div>}

      <PopoverMenu
        arrowClass='bottom-right'
        items={tags.slice(limit)}
        anchorEl={showMore[`tag-input-component--${_id}`]}
        open={Boolean(showMore[`tag-input-component--${_id}`])}
        scrollable
        isMargin
        onClose={onMoreItemCloseClick}
        onClick={onTagClick}
        renderLabel={(item) => item.name}
      />
    </>
  )
}
