import React from 'react'
import PropTypes from 'prop-types'

// components
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'

const propTypes = {
  stockTrendRecords: PropTypes.array.isRequired
}

const defaultProps = {
  stockTrendRecords: []
}

/**
 * Stock Trend Chart
 * @param props
 */
function StockTrendChart (props) {
  return <HighchartsReact highcharts={Highcharts} options={_getChartConfig(props)} />
}

/**
 * Highcharts config for stock trend chart
 * @param props
 */
const _getChartConfig = (props) => {
  const { stockTrendRecords } = props

  return {
    chart: {
      type: 'line',
      backgroundColor: 'none',
      width: 105,
      height: 55,
      spacing: [5, 5, 5, 5],
      reflow: false
    },
    noData: {
      style: { display: 'none' }
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    rangeSelector: { enabled: false },
    title: { text: null },
    subtitle: { text: null },
    legend: { enabled: false },
    xAxis: {
      lineColor: '#22272b',
      tickColor: '#22272b',
      tickLength: 0,
      lineWidth: 0,
      labels: { enabled: false }
    },
    yAxis: {
      gridLineWidth: 0,
      labels: { enabled: false },
      title: { text: null }
    },
    tooltip: { enabled: false },
    plotOptions: {
      series: {
        animation: false,
        allowPointSelect: false,
        enableMouseTracking: false,
        marker: {
          enabled: false
        }
      }
    },
    series: [{
      name: 'Stock',
      id: 'stock',
      type: 'spline',
      color: '#3498db',
      lineWidth: 2,
      data: stockTrendRecords
    }]
  }
}

StockTrendChart.propTypes = propTypes
StockTrendChart.defaultProps = defaultProps

export default StockTrendChart
