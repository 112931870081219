import {
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE,
  CLEAR_SEARCH_DATA,
  statusType
} from '../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const activityLocation = (state = initial, action) => {
  switch (action.type) {
    case GET_LOCATIONS_REQUEST:
      return {
        ...initial,
        status: statusType.IN_PROGRESS
      }

    case GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        status: statusType.SUCCESS,
        data: action.payload
      }

    case GET_LOCATIONS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case CLEAR_SEARCH_DATA:
      return { ...initial }

    default:
      return state
  }
}

export default activityLocation
