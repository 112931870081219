import {
  POST_PASSWORD_RESET_FAILURE,
  POST_PASSWORD_RESET_SUCCESS,
  POST_PASSWORD_RESET_REQUEST,
  RESET_PASSWORD_RESET,
  statusType
} from '../../actions'

const initial = {
  data: {},
  error: null,
  status: statusType.IDLE
}

const resetPasswordEmail = (state = initial, action) => {
  switch (action.type) {
    case POST_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        error: initial.error,
        data: initial.data,
        status: statusType.IN_PROGRESS
      }
    case POST_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        error: initial.error,
        data: action.payload.data,
        status: statusType.SUCCESS
      }
    case POST_PASSWORD_RESET_FAILURE:
      return {
        ...state,
        status: statusType.ERROR,
        data: initial.data,
        error: action.payload.error
      }
    case RESET_PASSWORD_RESET:
      return initial
    default:
      return state
  }
}

export default resetPasswordEmail
