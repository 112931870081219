import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Banner, Message } from '../../components'
import { SectionTab} from '../../components/shared'
import AllContainer from './tab/all/all.container'
import SavedContainer from './tab/saved/saved.container'
import {
  openModal,
  resetTargetingFilter,
  resetSearchTargets,
  resetPeers,
  resetPaginatedSavedTargets
} from '../../actions'
import { isSubscribed, TARGETING_TABS, THEMES } from '../../utils'
import { get } from 'lodash'
import qs from 'querystring'
import history from '../../utils/history'
import './targetingOld.page.css'


/**
 * Info Modal
 * @param onClose
 * @return {*}
 * @constructor
 */
const InfoModal = ({ onClose }) => {
  return (
    <Message
      visible={true}
      type='info'
      title='AI Targeting'
      message={
        '<p>AI Targeting is a revolutionary solution for predicting future investor behaviors and tendencies. <a href="https://vimeo.com/272363292" target="_blank">Watch Video</a></p>' +
        '<p>AI Target Scores are created each day for over 5K institutional investors and 40K mutual funds. This is a first of its kind model that, through a deep analysis of historical correlation and regression, generates probabilities and matches these scores between institutional investment firms / funds, and publicly traded companies.</p>'
      }
      useHtml={true}
      onClose={onClose}
      renderExitButton={true}
      buttons={[{
        ui: 'citrus',
        label: 'Ok',
        onClick: onClose
      }]}
    />
  )
}

/**
 * Targeting Page Component
 */
class TargetingOldPage extends PureComponent {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    const queryParams = qs.parse(history.location.search.replace(/^\?*/, ''))
    const activeTab = get(queryParams, 'activeTab', '').toLowerCase()
    const validActiveTab = (activeTab === TARGETING_TABS.SAVED || activeTab === TARGETING_TABS.ALL)

    this.state = {
      activeTab: validActiveTab ? activeTab : TARGETING_TABS.ALL
    }
  }

  componentWillUnmount () {
    this.props.resetTargetingFilter()
    this.props.resetSearchTargets()
    this.props.resetPeers()
    this.props.resetPaginatedSavedTargets()
  }

  /**
   * Show Info Modal
   */
  handleInfoClick = () => {
    const { openModal } = this.props
    openModal({
      component: InfoModal
    })
  }

  /**
   * Handles tab change
   * @param tab
   */
  handleTabChange = (tab) => {
    this.setState({ activeTab: tab.id })

    const hasQueryString = Object.keys(qs.parse(history.location.search.replace(/^\?*/, ''))).length > 0
    if (hasQueryString) {
      this.props.history.push('/targeting')
    }
  }

  /**
   * Render Targeting Page
   * @returns {*}
   */
  render = () => {
    const { profile } = this.props
    const { activeTab } = this.state
    const hasSubscription = isSubscribed(profile, 'ai_targeting')
    const pageTitle = hasSubscription ? 'AI Targeting' : 'Targeting'

    return (
      <div className='targeting-page-old'>
        <Banner
          title={pageTitle}
          icon='q4i-targeting-2pt'
          size='medium'
          disclosure={hasSubscription ? {
            type: 'info',
            onClick: this.handleInfoClick
          } : null}
        />
        <SectionTab
          compact
          rememberMounted
          theme={THEMES.SLATE}
          tabs={[{
            id: TARGETING_TABS.ALL,
            label: 'All Targets',
            view: <AllContainer />
          }, {
            id: TARGETING_TABS.SAVED,
            label: 'Saved Targets',
            view: <SavedContainer />
          }]}
          initialActiveTab={TARGETING_TABS.ALL}
          forceActiveTab={activeTab}
          onTabChange={this.handleTabChange}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile.data
})

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch),
  resetTargetingFilter: bindActionCreators(resetTargetingFilter, dispatch),
  resetSearchTargets: bindActionCreators(resetSearchTargets, dispatch),
  resetPeers: bindActionCreators(resetPeers, dispatch),
  resetPaginatedSavedTargets: bindActionCreators(resetPaginatedSavedTargets, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TargetingOldPage))
