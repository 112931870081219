import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'

// actions
import { useInstitutionQuery, useTargetQuery, useDealQuery, usePipelineQuery } from './hook'
import { getInstitutionAnalytics, openModal, statusType } from '../../actions'

// components
import InstitutionBanner from './banner/banner.component'
import InstitutionOverview from './overview/overview.component'
import { SectionTab } from '../../components/shared'
import {
  ActivityGrid,
  ContactGrid,
  CurrentHoldingGrid,
  FundGrid,
  HistoricalHoldingGrid,
  PeerAnalysisGrid,
  Spinner,
  PageMessage
} from '../../components'

import { ENTITY_TYPE, THEMES, PAGE_MESSAGE, getLocalizedCurrency } from '../../utils'
import { get } from 'lodash'

// Data Ids
import { DATA_IDS } from '../../tests-e2e/pages/institution/institution.definition'

const {
  CONTACT_TAB,
  CONTACT_GRID,
  ACTIVITY_TAB,
  ACTIVITY_GRID,
  CURRENT_HOLDING_TAB,
  CURRENT_HOLDING_GRID,
  FUND_TAB,
  FUND_GRID,
  HISTORICAL_HOLDING_TAB,
  HISTORICAL_HOLDING_GRID,
  PEER_ANALYSIS_TAB,
  PEER_ANALYSIS_GRID
} = DATA_IDS

const dataIdPrefix = 'InstitutionPage'

const propTypes = {}
const defaultProps = {}

const { INSTITUTION } = ENTITY_TYPE
const TABS = {
  CONTACT: 'contact',
  ACTIVITY: 'activity',
  FUND: 'fund',
  CURRENT: 'current',
  HISTORICAL: 'historical',
  PEER: 'peer'
}

/**
 * Institution Profile Page
 */
function InstitutionPage (props) {
  const { entity, isAnalytics, getInstitutionAnalytics, isTargetSuccess, openModal } = props
  const { q4_entity_id: entityId, factset_entity_id, _id } = entity // TODO: remove when search migrated

  const { loading, error, data } = useInstitutionQuery({
    variables: { id: entityId, currencyCode: getLocalizedCurrency() }
  })

  const institution = get(data, 'institution.items[0]', {})
  const { id, active } = institution

  const [getTarget, { data: target }] = useTargetQuery({
    variables: { entityId: id }
  })

  const { loading: isPipeline, data: stages } = usePipelineQuery()
  const [getDeals, { loading: isDeals, data: deal }] = useDealQuery({
    variables: { entityId: id }
  })

  const targetId = get(target, 'target.items[0].id', null)
  const deals = get(deal, 'deal.items', [])
  const pipeline = get(stages, 'pipeline.items', [])
  const lost = pipeline.find((item) => item.lost)
  const activeDeal = deals.find((deal) => deal.stage !== (lost && lost.id))

  useEffect(() => {
    if (!id) {
      return
    }

    getTarget()
    getDeals()
    getInstitutionAnalytics(id)
  }, [id, getTarget, getDeals, getInstitutionAnalytics])

  useEffect(() => {
    isTargetSuccess && getTarget()
  }, [isTargetSuccess, getTarget])

  const handleDealUpdate = () => {
    getTarget()
    getDeals()
  }

  /**
   * Render Institution Page
   */
  return (
    <>
      {(error || (data && !id)) && <Redirect to='/error/404' />}
      {loading && <Spinner mask theme={THEMES.DARK} />}
      {!!id && 
        (active
          ? <div className='institution-page'>
              <InstitutionBanner
                dataIdPrefix={dataIdPrefix}
                // TODO remove factset_entity_id after search migrate
                // TODO remove _id after deal purchasing power migration
                institution={{ ...institution, factset_entity_id, _id }}
                targetId={targetId}
                hasActiveDeal={!!activeDeal}
                onDealSaveSuccess={handleDealUpdate}
                openModal={openModal}
              />
              <InstitutionOverview
                dataIdPrefix={dataIdPrefix}
                institution={institution}
                targetId={targetId}
                hasActiveDeal={!!activeDeal}
                deals={{
                  isLoading: [isDeals, isPipeline].some((item) => !!item),
                  deals,
                  pipeline
                }}
                onDealSaveSuccess={handleDealUpdate}
                isAnalytics={isAnalytics}
                openModal={openModal}
              />

              <SectionTab
                theme={THEMES.RAIN}
                tabs={[
                  {
                    id: TABS.CONTACT,
                    label: 'Contacts',
                    dataId: {
                      label: CONTACT_TAB,
                      view: CONTACT_GRID
                    },
                    view: (
                      <ContactGrid
                        dataIdPrefix={dataIdPrefix}
                        entityId={id}
                      />
                    )
                  },
                  {
                    id: TABS.ACTIVITY,
                    label: 'Activity',
                    dataId: {
                      label: ACTIVITY_TAB,
                      view: ACTIVITY_GRID
                    },
                    view: (
                      <ActivityGrid
                        dataIdPrefix={dataIdPrefix}
                        entityId={id}
                        entityType={INSTITUTION}
                        // TODO remove factset_fund_id after search migrate
                        entity={{ ...institution, factset_entity_id }}
                        showChildren
                      />
                    )
                  },
                  {
                    id: TABS.FUND,
                    label: 'Funds',
                    dataId: {
                      label: FUND_TAB,
                      view: FUND_GRID
                    },
                    view: (
                      <FundGrid
                        dataIdPrefix={dataIdPrefix}
                        entityId={id}
                      />
                    )
                  },
                  {
                    id: TABS.CURRENT,
                    label: 'Current Holdings',
                    dataId: {
                      label: CURRENT_HOLDING_TAB,
                      view: CURRENT_HOLDING_GRID
                    },
                    view: (
                      <CurrentHoldingGrid
                        dataIdPrefix={dataIdPrefix}
                        entityId={id}
                      />
                    )
                  },
                  {
                    id: TABS.HISTORICAL,
                    label: 'Historical Holdings',
                    dataId: {
                      label: HISTORICAL_HOLDING_TAB,
                      view: HISTORICAL_HOLDING_GRID
                    },
                    view: (
                      <HistoricalHoldingGrid
                        dataIdPrefix={dataIdPrefix}
                        entityId={id}
                      />
                    )
                  },
                  {
                    id: TABS.PEER,
                    label: 'Peer Analysis',
                    dataId: {
                      label: PEER_ANALYSIS_TAB,
                      view: PEER_ANALYSIS_GRID
                    },
                    view: (
                      <PeerAnalysisGrid
                        dataIdPrefix={dataIdPrefix}
                        entityId={id}
                      />
                    )
                  }
                ]}
              />
            </div>
          : <PageMessage
              message = {PAGE_MESSAGE.INST_PAGE_INACTIVE}
            />
        )
      }
    </>
  )
}

InstitutionPage.propTypes = propTypes
InstitutionPage.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  isTargetSuccess: get(state, 'targeting.target.status') === statusType.SUCCESS,
  isAnalytics: get(state, 'institution.analytics.visited')
})

const mapDispatchToProps = (dispatch) => ({
  getInstitutionAnalytics: bindActionCreators(getInstitutionAnalytics, dispatch),
  openModal: bindActionCreators(openModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionPage)
