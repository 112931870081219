import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'

// components
import EventDetail from './detail.component'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderLightThumb, renderTrackVertical } from '../../../../resources/theme/q4.custom-scrollbar'

// utils
import { EVENT_TYPES, formatDate, getEventIcon, getTimeWithZone, isMidnight } from '../../../../utils'
import { openModal as openV2Modal, closeModal as closeV2Modal, modalType } from '../../../../actions'
import { useDispatch } from 'react-redux'
import { useDispatchAction } from '../../../../hook'

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  openModal: PropTypes.func.isRequired,
  useGraphQL: PropTypes.bool
}

const RECENT_EVENT_DATE_FORMAT = 'dddd, MMMM DD, YYYY'

const defaultProps = {
  events: []
}

/**
 * REST API
 * Get event icon based on event type
 * @param type
 */
function getIconType (type) {
  const icon = {
    'Earnings Call': 'phone',
    'Guidance Call': 'phone',
    'Sales and Revenue Call': 'phone',
    'Earnings Release': 'releases',
    'Sales and Revenue Release': 'releases',
    Conference: 'map-marker',
    Presentation: 'map-marker',
    'Analyst, Investor and Shareholder Meeting': 'map-marker',
    'Special Situation, M&A and Other': 'map-marker'
  }

  return icon[type] || 'map-marker'
}

/**
 * REST API
 * Get detailed date of the event
 * @param event
 */
function getDate (event) {
  const { date, event_type, market_time, projected } = event || {}

  let eventDate = moment.utc(date).format(RECENT_EVENT_DATE_FORMAT)

  if (event_type !== 'Conference') {
    eventDate = market_time === 'Specific Time' ? `${eventDate} ${moment(date).format('LT')}` : eventDate
  }

  return `${eventDate}${projected ? ' (projected)' : ''}`
}

/**
 * GQL
 * Get detailed date of the event
 * @param event
 */
function getDateGQL (event) {
  const { eventDate, eventTypeName, projected } = (event || {})
  const isZeroTime = isMidnight(eventDate, true)
  let date = formatDate(eventDate, RECENT_EVENT_DATE_FORMAT, isZeroTime, true)

  if (!([EVENT_TYPES.CONFERENCES, EVENT_TYPES.COMPANY_CONFERENCE_PRESENTATIONS]).includes(eventTypeName)) {
    date = `${date}  ${!isZeroTime ? getTimeWithZone(eventDate) : ''}`
  }

  return `${date}${projected ? ' (projected)' : ''}`
}

/**
 * Create event row based on database source
 * @param event
 * @returns
 */
function createEventRow (event, useGraphQL, openRestModal, openGQLModal, closeGQLModal) {
  if (useGraphQL) {
    const { id, eventTitle, eventTypeName } = event || {}
    return (
      <div
        key={id}
        className='security-recent-update_item security-recent-update_item'
        onClick={() =>
          openGQLModal({
            type: modalType.EVENT_DETAIL_MODAL,
            props: {
              event,
              onClose: closeGQLModal
            }
          })}
      >
        <i className={`security-recent-update_icon ${getEventIcon(eventTypeName)}`} />
        <div className='security-recent-update_title'>{eventTitle}</div>
        <div className='security-recent-update_date'>{getDateGQL(event)}</div>
      </div>
    )
  } else {
    // REST API implementation
    const { id, title, event_type } = event || {}
    return (
      <div
        key={id}
        className='security-recent-update_item security-recent-update_item'
        onClick={() =>
          openRestModal({
            component: ({ onClose }) => (
              <EventDetail
                event={event}
                badge={`q4i-${getIconType(event_type)}-2pt`}
                date={getDate(event)}
                onClose={onClose}
              />
            )
          })}
      >
        <i className={`security-recent-update_icon q4i-${getIconType(event_type)}-4pt`} />
        <div className='security-recent-update_title'>{title}</div>
        <div className='security-recent-update_date'>{getDate(event)}</div>
      </div>
    )
    // --------------------------------------------------
  }
}

/**
 * Security Recent Updates Card
 * @param props
 */
function Events (props) {
  const { isLoading, events, openModal, useGraphQL } = props

  const dispatch = useDispatch()
  const handleModalOpen = useDispatchAction(openV2Modal, dispatch)
  const handleModalClose = useDispatchAction(closeV2Modal, dispatch)

  const hasEvents = !isLoading && events.length

  return !isLoading && !hasEvents
    ? (<div className='security-recent-update_no-date'>No data available</div>)
    : (
      <>
        <div className='security-recent-update_content'>
          <Scrollbars
            className='react-scrollbar'
            autoHide
            autoHeight
            autoHeightMax={172}
            hideTracksWhenNotNeeded
            renderThumbVertical={renderLightThumb}
            renderTrackVertical={renderTrackVertical}
          >
            {(events || []).map((event) =>
              createEventRow(event, useGraphQL, openModal, handleModalOpen, handleModalClose)
            )}
          </Scrollbars>
        </div>
        <footer className='security-recent-update_footer'>
          <Link to='/event' className='security-recent-update_footer-link'>
            View All
          </Link>
        </footer>
      </>
      )
}

Events.propTypes = propTypes
Events.defaultProps = defaultProps

export default Events
