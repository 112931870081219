import React, {Component} from 'react';
import { Modal } from '../../components';

import './trialAgreementModal.component.css';

class TrialAgreementModal extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            visible: true
        };
    }

    logout = () => {
        const {history} = this.props;
        this.setState({visible: false});
        history.push('/logout');
    };

    /**
     * On Accept
     */
    onAccept = () => {
        this.props.acknowledgeTerms();
        this.setState({visible: false});
    };

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const {visible} = this.state;

        return (
            <Modal
                className='modal-root--trial-agreement'
                visible={visible}
                title='Terms of Use'
                subtitle={'By clicking "ACCEPT" below, you hereby acknowledge that you have read and agree to the Terms of Use.'}
                scrollable={true}
                containerWidth={575}
                contentPadding={'20px 20px 30px'}
                contentBackground={true}
                footerButtons={[
                    {
                        label: 'Decline',
                        ui: 'shaded',
                        onClick: this.logout
                    },
                    {
                        label: 'Accept',
                        ui: 'citrus',
                        onClick: this.onAccept
                    }
                ]}
            >
                <h4>Desktop Free Trial Agreement</h4>
                <p>This Desktop Free Trial Agreement (“Agreement”) is entered into as of the date of acceptance of these terms and conditions, and will remain in effect for the duration of the Free Trial Period (as defined below). By clicking on “I accept” below, the individual who has registered to use Q4 Inc.’s (“Q4’s”) Desktop Service (the “Service”), represents and warrants that he or she has the authority to bind “Customer” (meaning the company on behalf of which the individual is accepting this Agreement) to the terms of this Agreement.</p>
                <p>Notwithstanding any prior existing agreement that may be in place between Customer and Q4, Customer’s use of the Service during the free trial will be governed solely by the terms of this Agreement.</p>
                <h2><span>1.</span> Free Trial.</h2>
                <p>Q4 will provide Customer with access to its Service, free of charge on a trial basis until the earlier of (a) the end date of Customer’s “Free Trial Period” (the duration starting from acceptance of this Agreement and ending at thirty (30) days, or until such later date to which Q4 has expressly agreed to extend access to the Service); (b) the subscription start date if a subscription to the Service is purchased; or (c) the termination of Customer’s free trial by Q4, in its sole discretion.</p>
                <p>Customer acknowledges and agrees that at the end of the Free Trial Period, Customer’s access to the Service will be terminated, unless Customer purchases a subscription to the Service from Q4.</p>
                <h2><span>2.</span> Modifications; Updates.</h2>
                <p>Q4 may, in its sole discretion and without prior notice, apply updates, upgrades, enhancements, improvements, releases, corrections, bug fixes, patches, and modifications to the Service (collectively, the “<strong>Updates</strong>”), and such Update may result in changes in the appearance and/or functionality of the Service (including the addition, modification, or removal of functionality, features, or content). </p>
                <h2><span>3.</span> Customer Responsibilities</h2>
                <p>Q4 will issue Customer, one or more Named User accounts to access the Service during the Free Trial Period. Customer shall be responsible for protecting the security and integrity of any username and password associated with a Named User Account (“Access Methods”). Customer shall hold in strict confidence, all Access Methods. Customer is responsible for any acts or omissions occurring under any Access Methods.</p>
                <h2><span>4.</span> Use Restrictions.</h2>
                <p>During the Free Trial Period, Customer agrees that it will not (i) provide access to the Service to anyone other than the Named User during the Free Trial Period; (ii) make any data, information, reports or materials contained in or made available through the Service (collectively the “Content”) available to anyone else; (iii) sell, resell, license, sublicense, distribute, make available, rent or lease any Service or Content; (iii) download, copy or create derivative works based on the Service or the Content; or (iv) use the Service for any purpose or in any manner that is unlawful or prohibited by this Agreement.</p>
                <h2><span>5.</span> Ownership; Intellectual Property.</h2>
                <p>As between the parties, Q4 shall own all right, title and interest in and to the Service and Q4 Materials (as defined below), including, without limitation, all intellectual property rights and other proprietary rights therein, are and shall remain the sole and exclusive property of Q4 and its affiliates.  This Agreement does not grant any intellectual property right or license under any intellectual property right in or to the Service or Q4 Materials.  All rights not expressly granted by Q4 to Customer are reserved by Q4.  “Q4 Materials” means Q4’s software, the information technology infrastructure used by or on behalf of Q4 in performing and/or making available the Service, and any and all other technology, know-how, information, data, content, materials, and other property pertaining thereto.</p>
                <h2><span>6.</span> Customer Data.</h2>
                <ol>
                <li>The Service may include functionality which allows Customer to enter data, information or other material (“Customer Data”) into the Service. Q4 will use Customer Data only for the purpose of providing the Service. Customer assumes sole responsibility for any and all Customer Data entered into the Service. Q4 does not accept any liability for damage, loss or deletion of Customer Data.</li>
                <li>Customer is solely responsible for exporting Customer Data from the Services prior to the end of the Free Trial Period. Failure to retrieve such Data during Customer’s Free Trial Period will result in the loss of Customer Data and Q4 may delete Customer Data without liability to Customer.</li>
                </ol>
                <h2><span>7.</span> Suspension; Termination.</h2>
                <p>Customer agrees that Q4, in its sole discretion, may at any time, with or without cause and without any liability to Customer, terminate Customer’s access to the Service or any part thereof. Any use of the Service in breach of the Agreement may result in the immediate suspension or termination of the Services.</p>
                <h2><span>8.</span> Confidentiality.</h2>
                <p>Each Party (the “Receiving Party”) agrees that it shall not, at any time during or after the Free Trial Period, disclose or disseminate to any other person or entity, or use except as permitted by this Agreement, any information regarding the business, data, processes, technology, software or products of the other party (the “Disclosing Party”) obtained during the course of performance under this Agreement (the “Confidential Information”).  Confidential Information shall not include information which: (i) is, or has become, publicly available without breach of this Agreement or any other confidentiality obligation; (ii) has been given to the Receiving Party by a third party with a legal right to so disclose; (iii) was known to the Receiving Party at the time of disclosure as evidenced by its written records; or (iv) was independently developed by the Receiving Party without reference to or use of the Confidential Information.   The parties hereto acknowledge that (A) the Confidential Information of Q4 shall include, but not be limited to, the Work Product, Q4 Materials and any related materials, the terms, conditions and pricing of this Agreement (including any applicable Order Forms), Q4’s methodologies, technology, infrastructure, security practices, policies, products, test results and reports, Q4’s third party provider certificates and attestations, and (ii) the Confidential Information of Customer shall include, but not be limited to documents any non-public data provided by Customer to Q4 pursuant to the terms of this Agreement. Each Receiving Party’s confidentiality obligations with respect to such Confidential Information, shall remain in effect for the term of this Agreement and for a period of three (3) years after the termination or expiration of this Agreement.</p>
                <h2><span>9.</span> Disclaimers of Warranties; Limitations and Exclusions of Liability</h2>
                <ol>
                <li>
                    <h3>DISCLAIMERS.</h3>
                    <ol>
                        <li>NOTWITHSTANDING ANYTHING TO THE CONTRARY WHETHER IN ANY PRIOR AGREEMENT BETWEEN CUSTOMER AND Q4 OR OTHERWISE, THE SERVICE, Q4 MATERIALS AND ANY CONTENT ARE PROVIDED “AS-IS” WITH ALL FAULTS AND DEFECTS CONTAINED THEREIN, WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, AND CUSTOMER USES THE SERVICE AT ITS OWN RISK.</li>
                        <li>Q4 DOES NOT WARRANT THAT THE SERVICE AND/OR Q4 MATERIALS, WILL BE ACCURATE, UNINTERRUPTED OR ERROR-FREE, NOR DOES Q4 MAKE ANY WARRANTIES AS TO THE RESULTS TO BE OBTAINED FROM USE OF THE SERVICE, THE CONTENT AND/OR THE Q4 MATERIALS.</li>
                        </ol>
                    </li>
                <li>
                    <h3>LIMITATIONS, EXCLUSIONS OF LIABILITY.</h3>
                    <ol>
                        <li>IN NO EVENT SHALL Q4, ITS AFFILIATES OR ITS LICENSORS BE LIABLE FOR ANY DAMAGES WHATSOEVER, AS A RESULT OF THIS AGREEMENT OR THE SERVICES, INCLUDING BUT NOT LIMITED TO ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, PROFITS, USE, GOODWILL OR DATA), ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THIS AGREEMENT OR USE OF THE SERVICE, INCLUDING BUT NOT LIMITED TO THE USE OR INABILITY TO USE THE SERVICE, FOR ANY CONTENT OBTAINED FROM THE SERVICE, OR FOR ANY INACCURACY, ACT OR OMISSION, REGARDLESS OF CAUSE.</li>
                        <li>WITHOUT LIMITING THE GENERALITY OF ANY OTHER PROVISIONS IN THIS AGREEMENT, Q4 SHALL NOT BE LIABLE TO CUSTOMER, AND ASSUMES NO RESPONSIBILITY FOR ANY LOSS OR DAMAGE RESULTING FROM: (A) FAILURES OF THIRD PARTY TOOLS, NETWORKS OR SERVICES, OR OF CUSTOMER’S SYTEMS (INCLUDING BUT NOT LIMITED TO, ANY FAILURE TO SECURE AND SAFEGUARD CUSTOMER’S SYSTEMS, USER IDENTIFICATION OR PASSWORDS); (B) UNAUTHORIZED ACCESS TO OR ALTERATION, THEFT, LOSS, CORRUPTION OR DESTRUCTION OF CUSTOMER DATA; OR (C) ANY MALFEASANT OR WILFUL ACT OR OMISSION BY CUSTOMER OR OF ANY THIRD PARTY.</li>
                        </ol>
                    </li>
                </ol>
                <h2><span>10.</span> Indemnification.</h2>
                <ol>
                <li>Q4 SHALL HAVE NO OBLIGATION TO INDEMNIFY CUSTOMER, WHATSOEVER, WITH RESPECT TO THE SERVICE FOR THE FREE TRIAL PERIOD.</li>
                <li>Customer hereby agrees to defend, indemnify and hold Q4 and Q4’s affiliates, subcontractors and agents (and each of their shareholders, officers, directors, employees and contractors) harmless from and against any and all damages, costs, losses, liabilities and expenses (including reasonable outside lawyers’ fees) resulting from, relating to or arising out of any claim: (i) regarding any Customer’s access to or use of the Service in contravention of this Agreement; (ii) breach by Customer of any applicable law, rules or regulation; (iii) the Customer Data; or (iv) the gross negligence, fraud or wilful misconduct of Customer or its employees, officers, directors, agents or contractors.</li>
                <li>The indemnification obligations of Customer are conditional upon: (i) Q4 giving prompt written notice of the claim (provided that any delay in notification shall not limit Customer’s obligations in this Section 10, unless such delay materially prejudices Customer’s ability to defend such claim); and (ii) Q4 giving Customer the right to sole responsibility for carriage of the claim, including choice of counsel and settlement negotiations/decisions; provided in all cases, however, that Customer may not settle any such claim in a manner that imposes any on-going monetary obligation or admission of guilt or liability on Q4 without the written consent of Q4.</li>
                </ol>
                <h2><span>11.</span> Miscellaneous.</h2>
                <ol>
                <li>Choice of Law and Venue. This Agreement shall be governed by and construed in accordance with the laws of the State of New York.  The parties irrevocably attorn to the exclusive jurisdiction of the federal courts of the United States located in the Southern District of the State of New York for the purposes of adjudicating any matter arising from or in connection with this Agreement.</li>
                <li>Feedback. Customer may from time to time, provide suggestions, comments, or other feedback to Q4 with respect to the Services (“Feedback”). Customer shall, and hereby does, grant to Q4 a non-exclusive, worldwide, perpetual, irrevocable, transferable, sub-licensable, royalty-free, fully paid-up license to use and exploit the Feedback for any purpose.</li>
                <li>Survivability. The provisions of this Agreement that, either expressly or by their nature, are intended to remain in effect beyond the termination of this Agreement, shall survive its termination.</li>
                <li>Assignment. Customer may not assign this Agreement to any third party, except with the prior written consent of Q4. </li>
                <li>Entire Agreement. This Agreement and Q4s policies constitute the entire agreement and understanding between the parties regarding the subject matter of the Agreement.</li>
                </ol>
            </Modal>
        );
    }
}

export default TrialAgreementModal;
