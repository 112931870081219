import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

// components
import { Checkbox } from '../../index'

// utils
import { getClassName, THEMES } from '../../../utils'

import './bulkActionBar.component.scss'

const propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  isBulkSelected: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    icon: PropTypes.string,
    label: PropTypes.string,
    onSelect: PropTypes.func.isRequired
  })).isRequired,
  onChange: PropTypes.func.isRequired
}

const defaultProps = {
  actions: [],
  isVisible: false
}

/**
 * BulkActionBar Wrapping Component
 * @param props
 */
function BulkActionBar (props) {
  const { className, isVisible, isBulkSelected, actions, onChange } = props

  const componentId = shortid.generate()
  const baseClassName = getClassName('bulk-action-bar', [
    { condition: className, trueClassName: className },
    { condition: isVisible, falseClassName: 'bulk-action-bar--hidden' }
  ])

  return (
    <section className={baseClassName}>
      <div className='bulk-action-bar_checkbox'>
        <Checkbox
          theme={THEMES.Q4_BLUE}
          id={`bulk-action-checkbox--${componentId}`}
          isSelected={isBulkSelected}
          onChange={onChange}
        />
      </div>
      <ul className='bulk-action-bar_actions'>
        {(actions || []).map((item) => {
          const { dataId, id, className, icon, label, onSelect } = (item || {})
          return (
            <li
              key={`bulk-action--${id}`}
              data-id={dataId}
              className={`bulk-action-bar_actions-item ${className || ''}`}
              onClick={onSelect}
            >
              {icon && <i className={`bulk-action-bar_actions-icon ${icon}`} />}
              {label && <span className='bulk-action-bar_actions-label'>{label}</span>}
            </li>
          )
        })}
      </ul>
    </section>
  )
}

BulkActionBar.propTypes = propTypes
BulkActionBar.defaultProps = defaultProps

export default BulkActionBar
