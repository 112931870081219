import { getMatches } from './regex.util'
import _ from 'lodash'

export const getUserDefaultMetric = (profile, securityId) => {
  const _organization = profile._organization
  const tickers = (_organization && (_organization.tickers || []))
  const company = _.find((tickers), (ticker) => ticker._security === securityId && ticker.primary)
  return ((company && _organization && _organization.metric) || 'EPS').toLowerCase()
}

/**
 * Map user id to a username
 * @param userId
 * @param usersMap
 * @return {*}
 */
export const getNormalizedUserName = (userId, usersMap) => {
  if (!usersMap) {
    return null
  }

  const mappedUser = usersMap[userId]

  if (!mappedUser) {
    return null
  }

  if (mappedUser.profile.display_name_normalized) {
    return mappedUser.profile.display_name_normalized
  } else {
    return mappedUser.profile.real_name_normalized
  }
}

/**
 * Format text message
 * @param message
 * @param users
 */
export const formatMessage = (message, users) => {
  message = replaceUserIdsWithUsernames(message, users)
  message = replaceChannelIdsWithChannelNames(message)
  message = replaceSlackURLWithUrl(message)
  message = replaceURLWithHTMLLinks(message)

  return message
}

function replaceSlackURLWithUrl (message) {
  const slackUrlRegex = /(<http:\/\/.*?>|<https:\/\/.*?>)/gi

  const slackUrlMatches = getMatches(message, slackUrlRegex, 1)

  slackUrlMatches.forEach((slackUrlMatch) => {
    const urlMatchRegex = /<https?:\/\/(.*?)[|>]/gi

    const urlMatches = getMatches(slackUrlMatch, urlMatchRegex, 1)

    urlMatches.forEach((urlMatch) => {
      message = message.replace(slackUrlMatch, urlMatch)
    })
  })

  return message
}

function replaceChannelIdsWithChannelNames (message) {
  // find all channel ids
  const chanelIdsFullRegex = /(<#[\w\d]+\|[^>]+>)/g
  const matches = getMatches(message, chanelIdsFullRegex, 1)

  // map channel ids to channel names
  const idNameMap = _.filter(matches.map((channelIdMatch) => {
    const channelNameMatches = getMatches(channelIdMatch, /\|(.*?)>/g)

    if (!channelNameMatches || !channelNameMatches.length) {
      return null
    }
    return {
      channelId: channelIdMatch,
      channelName: channelNameMatches[0]
    }
  }), null)

  // replace original user ids with user names
  idNameMap.forEach((idName) => {
    message = message.replace(idName.channelId, `<span class="message-item_body--name">#${idName.channelName}</span>`)
  })

  return message
}

function replaceUserIdsWithUsernames (message, users) {
  // find all user ids
  const userIdsFullRegex = /(<@[\w\d]+>)/g
  const matches = getMatches(message, userIdsFullRegex, 1)

  // map user ids to user names
  const idNameMap = _.filter(matches.map((userIdMatch) => {
    const userIdMatches = getMatches(userIdMatch, /<@(.*?)>/g)

    // special case for slackbot
    if (userIdMatch === '<@USLACKBOT>') {
      return {
        username: 'slackbot',
        userId: userIdMatch
      }
    }

    if (userIdMatches && userIdMatches.length && users[userIdMatches[0]]) {
      const user = users[userIdMatches[0]]

      let displayName = user.profile &&
                user.profile.display_name_normalized

      if (!displayName) {
        displayName = user.profile.real_name
      }
      return {
        username: displayName,
        userId: userIdMatch
      }
    }
    return null
  }), null)

  // replace original user ids with user names
  idNameMap.forEach((idName) => {
    message = message.replace(idName.userId, `<span class="message-item_body--name">@${idName.username}</span>`)
  })

  return message
}

function replaceURLWithHTMLLinks (text) {
  // replace any &amp
  text = text.replace(/&amp;/g, '&')

  const re = /(^|\s?)[-a-zA-Z0-9@:%_+.~#?&//=]{1,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?(\s|$)/gi
  return text.replace((re), match => {
    let httpUrl

    // add http if it doesn't exist
    if (!/^https?:\/\//i.test(match)) {
      httpUrl = 'http://' + match.trim()
    }
    httpUrl = httpUrl || match.trim()
    return " <a href='" + httpUrl + "' target=\"_blank\">" + match.trim() + '</a> '
  })
}

/**
 * Normalizes user name
 * @param message
 */
export const getNormalizedUserNameMap = (userId, usersMap) => {
  if (!usersMap) {
    return null
  }

  const mappedUser = usersMap[userId]

  if (!mappedUser) {
    return null
  }

  let normalizedUserName = null

  if (mappedUser.profile.display_name_normalized) {
    normalizedUserName = mappedUser.profile.display_name_normalized
  } else {
    normalizedUserName = mappedUser.profile.real_name_normalized
  }

  return {
    normalizedUserName,
    username: mappedUser.name
  }
}

/**
 * Check if a service is enabled from a list of services
 * @param serviceType
 * @param availableServices
 */
export const isServiceEnabled = (serviceType, availableServices = []) => {
  return (availableServices.findIndex((service) => (service.type === serviceType && service.enabled)) > -1)
}

/**
 * Get Ownership Type from a User Profile
 * @param profile
 * @returns {*}
 */
export const getOwnershipType = (profile, tickerId = null) => {
  if (!profile || !profile.services) {
    return { name: '13f', value: '13f', label: '13F' }
  }

  const { services, _organization } = profile
  let isOrganizationTicker = _organization?.tickers?.find(ticker => ticker.q4_ticker_id === tickerId)

  if (!tickerId || isOrganizationTicker) {
    if (isServiceEnabled('shareholder_id', services)) {
      return { name: 'shareholder_id', value: 'shareholderId', label: 'SID' }
    }

    if (isServiceEnabled('surveillance', services)) {
      return { name: 'surveillance', value: 'surveillance', label: 'SV' }
    }
  }

  return { name: '13f', value: '13f', label: '13F' }
}
