import React, { memo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// utils
import { format, DEFAULT_DATE_FORMAT } from '../../../../utils'
import { groupBy } from 'lodash'

const EMPTY_PLACEHOLDER = '-'

const propTypes = {
  entityId: PropTypes.string.isRequired,
  estimate: PropTypes.array.isRequired
}

const defaultProps = {
  estimate: []
}

/**
 * Get estimate headers
 * @param data
 */
function getHeader (data) {
  const limit = 9
  const header = (data || [])
    .reduce((acc, current) => {
      const duplicate = acc.find((item) => moment(item.date).isSame(moment(current.date)))
      return duplicate ? acc : acc.concat([current])
    }, [])
    .map((value) => {
      const { date, frequency, quarter } = value
      const period = moment.isMoment(date) ? date : moment.utc(date)
      const suffix = (frequency === 'annual') ? 'FY' : (frequency === 'quarterly') ? 'Q' + quarter : ''

      return {
        headerName: `${period.format(DEFAULT_DATE_FORMAT)} ${suffix}`,
        ...value
      }
    })

  const length = header.length

  return (length > limit) ? header.slice(length - limit, length) : header
}

/**
 * Get estimate data
 * @param data
 */
function getData (data) {
  const header = getHeader(data)
  const groups = groupBy(data, (option) => (option && option.item))

  return Object.values(groups).map((group) => {
    return {
      category: group[0].item,
      data: header.map((range) => {
        const value = group.find((value) => {
          return range.frequency === value.frequency && moment(value.date).isSame(range.date)
        })

        return value || EMPTY_PLACEHOLDER
      })
    }
  })
}

/**
 * Estimate EPS/Sales Cell Component
 * @param data
 */
function Estimate ({ data }) {
  const { item, estimate, actual_value } = (data || {})
  const defaultCell = <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>{EMPTY_PLACEHOLDER}</div>

  switch (item) {
    case 'SALES':
      return estimate
        ? <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>{format(estimate, 2)}</div>
        : defaultCell
    case 'EPS':
      return (estimate || actual_value)
        ? <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>
          <div className='tearsheet_grid-cell_content'>
            <div>{estimate ? `${format(estimate, 2)} E` : ''}</div>
            <div>{actual_value ? `${format(actual_value, 2)} A` : EMPTY_PLACEHOLDER}</div>
          </div>
        </div>
        : defaultCell
    default:
      return defaultCell
  }
}

/**
 * Estimate Grid
 * @param props
 */
function EstimateGrid ({ entityId, estimate }) {
  if (!estimate) {
    return null
  }

  return (
    <div className='tearsheet_grid tearsheet_grid--ten-columns'>
      <div className='tearsheet_grid-row tearsheet_grid-row--header'>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--text'>Category</div>
        {(getHeader(estimate) || []).map(({ headerName }, index) => (
          <div
            key={`estimate-header-${headerName}-${entityId}`}
            className='tearsheet_grid-cell tearsheet_grid-cell--number'>{headerName}</div>
        ))}
      </div>
      {getData(estimate).map(({ category, data }, index) => (
        <div className='tearsheet_grid-row' key={category || `estimate-${index}`}>
          <div className='tearsheet_grid-cell tearsheet_grid-cell--text'>{category}</div>
          {(data || []).map((item, index) => <Estimate key={`${entityId}-${index}`} data={item} />)}
        </div>
      ))}
    </div>
  )
}

EstimateGrid.propTypes = propTypes
EstimateGrid.defaultProps = defaultProps

export default memo(EstimateGrid)
