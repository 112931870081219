import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import UpcomingActivitiesItem from '../upcomingActivitiesItem/upcomingActivitiesItem.component'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderDarkThumb, renderLightThumb, renderTrackVertical } from '../../../../resources/theme/q4.custom-scrollbar'
import moment from 'moment-timezone'
import './upcomingActivitiesList.component.css'
import { getLocalizedFormat } from '../../../../utils'

class UpcomingActivitiesList extends Component {
  render () {
    const { activities, currentActivityDate, theme, options, history } = this.props
    const renderThumb = theme === 'dark' ? renderLightThumb : renderDarkThumb

    return (
      <div className={`upcoming-activities_list upcoming-activities_list--${theme}`}>
        <header className='upcoming-activities_header'>
          {options.showCalendar
            ? <span className='upcoming-activities_header--date'>
              {moment(currentActivityDate).format(getLocalizedFormat('dddd, MMM D, YYYY'))}
            </span>
            : <span
              className='upcoming-activities_header-title'
              onClick={() => history.push('/activity')}
            >Upcoming Activity
            </span>}
        </header>
        <section className='upcoming-activities_items'>
          <Scrollbars
            className='react-scrollbar'
            autoHide
            hideTracksWhenNotNeeded
            renderThumbVertical={renderThumb}
            renderTrackVertical={renderTrackVertical}
          >
            {
              activities && activities.length ? <ul>
                {
                  activities.map((item) => {
                    return (
                      <UpcomingActivitiesItem
                        key={item._id}
                        onItemClick={(id) => history.push(`/activity/${id}`)}
                        item={item}
                      />
                    )
                  })
                }
              </ul> : <div className='upcoming-activities_nodata q4-fade-in'>
                                                  <i className='q4i-nodata-4pt' />
                                                  <span>No activities available.</span>
              </div>
            }
          </Scrollbars>
        </section>
        {(!options.showCalendar) &&
          <footer className='upcoming-activities_footer'>
            <Link to='/calendar'>View all</Link>
          </footer>}
      </div>
    )
  }
}

export default withRouter(UpcomingActivitiesList)
