import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {config} from '../../../../config';
import {getClassName} from '../../../../utils/ui/ui.util';
import { convertBlobToObjectURL } from '../../../../utils/browser.util'
import {Button, PopoverMenu} from '../../../../components';
import './pageManagerItem.component.css';

/**
 * Pages Manager Item Component
 */
class PageManagerItem extends PureComponent {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            isPopoverMenuOpen: false
        };

        this.settingsButton = React.createRef();
    }

    /**
     * Handle PageManagerItem click event
     */
    handleItemClick = () => {
        const {index, onPageSelect} = this.props;

        onPageSelect && onPageSelect(index);
    };

    /**
     * Handle opening of the PopoverMenu
     */
    handlePopoverMenuOpen = () => {
        this.setState({
            isPopoverMenuOpen: true
        });
    };

    /**
     * Handle closing of the PopoverMenu
     */
    handlePopoverMenuClose = () => {
        this.setState({
            isPopoverMenuOpen: false
        });
    };

    /**
     * Handle Popover Menu Click
     * @param event
     * @param option
     */
    handlePopoverMenuClick = (event, option) => {
        event.stopPropagation();
        const {onPageRemove, onPageDuplicate, id} = this.props;

        if (!option || !option.type) {
            return;
        }

        switch (option.type) {
            case 'duplicate':
                onPageDuplicate && onPageDuplicate(id);
                break;
           case 'delete':
                onPageRemove && onPageRemove(id);
                break;
            default:
                break;
        }

        this.handlePopoverMenuClose();
    };

    /**
     * Get props for PopoverMenu component
     * @param isPageLimitReached
     * @returns {*}
     */
    getPopoverProps = (isPageLimitReached) => {
        const popoverProps = {
            anchorEl: this.settingsButton && this.settingsButton.current,
            items: [
                {icon: 'q4i-trashbin-2pt', label: 'Delete', type: 'delete'}
            ],
            onClick: this.handlePopoverMenuClick
        };

        if (!isPageLimitReached) {
            popoverProps.items.unshift({icon: 'q4i-clone-duplicate-2pt', label: 'Duplicate Page', type: 'duplicate'})
        }

        return popoverProps;
    };

    /**
     * Render page grid layout preview
     * @param pageLayout
     * @param gridLayout
     * @returns {*}
     */
    renderGridPreview = (pageLayout, gridLayout) => {
        let {columnCount, rowCount, marginCount} = config.pageBuilder;
        columnCount = columnCount[pageLayout];
        rowCount = rowCount[pageLayout];

        return (
            <div className='page-manager-item_grid-container'>
                {(gridLayout || []).map((item, index) => {
                    const {_id, x, y, w, h} = item;
                    const style = {
                        top: `${(y + marginCount) / rowCount * 100}%`,
                        left: `${(x + marginCount) / columnCount * 100}%`,
                        width: `${w / columnCount * 100}%`,
                        height: `${h / rowCount * 100}%`,
                    };

                    return (
                        <div
                            key={`grid-preview-item--${_id || index}`}
                            className='page-manager-item_grid-item'
                            style={style}
                        />
                    );
                })}
            </div>
        );
    };

    /**
     * Render Widget Library
     * @returns {*}
     */
    render() {
        const {index, pageLayout, gridLayout, isCollapsed, isPageLimitReached, isSelected, isDragging} = this.props;
        const {isPopoverMenuOpen} = this.state;
        const thumbnail = convertBlobToObjectURL(this.props.thumbnail)

        const popoverProps = this.getPopoverProps(isPageLimitReached);
        const baseClassName = getClassName('page-manager-item', [
            {condition: pageLayout, trueClassName: `page-manager-item--${pageLayout}`},
            {condition: isCollapsed, trueClassName: 'page-manager-item--collapsed'},
            {condition: isPopoverMenuOpen, trueClassName: 'page-manager-item--popoveropen'},
            {condition: isSelected, trueClassName: 'page-manager-item--selected'},
            {condition: isDragging, trueClassName: 'page-manager-item--dragging'}
        ]);

        return (
            <article className={baseClassName} onClick={this.handleItemClick}>
                <label className='page-manager-item_index'>{index + 1}</label>
                <div className='page-manager-item_inner'>
                  {thumbnail
                    ? <img className='page-manager-item_thumbnail' alt='page thumbnail' src={thumbnail} />
                    : gridLayout && this.renderGridPreview(pageLayout, gridLayout)
                  }
                    <label className='page-manager-item_index page-manager-item_index--inner'>{index + 1}</label>
                </div>
                {!isCollapsed && (
                    <React.Fragment>
                        <div className='page-manager-item_settings'>
                            <Button
                                reference={this.settingsButton}
                                theme='rain'
                                square={true}
                                tall={false}
                                icon='q4i-utility-4pt'
                                onClick={this.handlePopoverMenuOpen}
                            />
                        </div>
                        <PopoverMenu
                            open={isPopoverMenuOpen}
                            theme='citrus'
                            isMargin
                            items={popoverProps.items}
                            anchorEl={popoverProps.anchorEl}
                            onClick={popoverProps.onClick}
                            onClose={this.handlePopoverMenuClose}
                        />
                    </React.Fragment>
                )}
            </article>
        );
    }
}

PageManagerItem.propTypes = {
    index: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    pageLayout: PropTypes.oneOf(['landscape', 'portrait']),
    gridLayout: PropTypes.arrayOf(PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
        w: PropTypes.number,
        h: PropTypes.number
    })),
    thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isCollapsed: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    isPageLimitReached: PropTypes.bool.isRequired,
    onPageSelect: PropTypes.func.isRequired,
    onPageDuplicate: PropTypes.func.isRequired,
    onPageRemove: PropTypes.func.isRequired
};

export default PageManagerItem;
