import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { getClassName } from '../../../utils'

/**
 * ToolbarRow Component
 */
class ToolbarRow extends PureComponent {
  /**
   * Render ToolbarRow component
   * @returns {*}
   */
  render () {
    const { className, justified, right, children } = this.props

    const baseClassName = getClassName('toolbar_row', [
      { condition: className, trueClassName: className },
      { condition: justified, trueClassName: 'toolbar_row--justified' },
      { condition: right, trueClassName: 'toolbar_row--right' }
    ])

    return (
      <div className={baseClassName}>{children}</div>
    )
  }
}

ToolbarRow.propTypes = {
  /**
   * A custom className to pass into the component
   */
  className: PropTypes.string,

  /**
   * Used to determine whether or not children will render with justify-content: space-between styling
   */
  justified: PropTypes.bool,

  /**
   * Used to determine whether or not children will render with justify-content: flex-end styling
   */
  right: PropTypes.bool,
  /**
   * Children to render within the ToolbarRow
   */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

export default ToolbarRow
