import './errorMessage.component.css'
import '../placeholderMessage/placeholderMessage.doc'
import React, { memo } from 'react'
import PlaceholderMessage from '../placeholderMessage/placeholderMessage.component'
import ErrorImage from '../../../resources/images/error/error.svg'

/**
 * Error CTA Message Component
 * @param {PlaceholderMessageProps} props
 * @returns {JSX.Element}
 */
const ErrorMessage = (props) => {
  return (
    <PlaceholderMessage {...props} />
  )
}

ErrorMessage.propTypes = PlaceholderMessage.prototype

ErrorMessage.defaultProps = {
  image: ErrorImage,
  className: 'error-message-placeholder',
  title: 'Failed To Load Data',
  message: 'Please refresh the page. If issue is not fixed please contact support.'
}

export default memo(ErrorMessage)
