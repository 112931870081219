import {
  FAILED,
  FETCH_ESTIMATES_PEER_ERROR,
  FETCH_ESTIMATES_PEER_REQUEST,
  FETCH_ESTIMATES_PEER_SUCCESS,
  ESTIMATE_PEER_CHANGED,
  FETCHED,
  FETCHING,
  IDLE
} from '../../actions/estimates'

const initialState = {
  status: IDLE,
  currentPeer: null,
  items: []
}

const estimatesPeer = (state = initialState, action) => {
  switch (action.type) {
    case ESTIMATE_PEER_CHANGED:
      return {
        ...state,
        currentPeer: action.payload
      }
    case FETCH_ESTIMATES_PEER_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_ESTIMATES_PEER_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        items: action.payload
      }
    case FETCH_ESTIMATES_PEER_ERROR:
      return {
        ...state,
        status: FAILED,
        error: action.error
      }
    default:
      return state
  }
}

export default estimatesPeer
