import { combineReducers } from 'redux'
import topmovers from './topmovers.reducer'
import buyers from './buyers.reducer'
import buyersQr from './buyersQr.reducer'
import sellers from './sellers.reducer'
import sellersQr from './sellersQr.reducer'
import current from './current.reducer'

// We combine the reducers here so that they can be left split apart above
const ownershipReducer = combineReducers({
  topmovers,
  buyers,
  buyersQr,
  sellers,
  sellersQr,
  current
})

export default ownershipReducer
