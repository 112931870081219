import React from 'react'
import { format, formatDate, formatTitleCase, inMillions, isYetToFile, HOLDING_CAP_GROUP, YET_TO_FILE, ENTITY_TYPE } from '../../../../../../utils'
import { get } from 'lodash'
const { FUND } = ENTITY_TYPE
const EMPTY_PLACEHOLDER = '-'
const ZERO = '0.00'

/**
 * CapGroup Component
 * @param capGroup
 */
const CapGroup = ({ capGroup }) => {
  const group = HOLDING_CAP_GROUP.find((option) => option.value === capGroup)
  return (group && group.label) || 'Other'
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 */
const CustomCell = ({ data = {}, column = {} }) => {
  const cellData = data[column.colId]
  const holderType = get(data, 'holderType')
  let sourceData = null
  
  switch (column.colId) {
    case 'securityName':
    case 'securityCountryName':
      return cellData || EMPTY_PLACEHOLDER
    case 'current':
      return (cellData || cellData === 0) ? format(cellData, 0) : EMPTY_PLACEHOLDER
    case 'qtrChange':
      if (isYetToFile(data.reportDate, data.qtrChange)) {
        return YET_TO_FILE
      }
      return (cellData || cellData === 0) ? format(cellData, 0) : EMPTY_PLACEHOLDER
    case 'marketValue':
    case 'marketValueChange':
      return cellData ? inMillions(cellData, 2) : EMPTY_PLACEHOLDER
    case 'securityCapGroup':
      return <CapGroup capGroup={cellData} />
    case 'percentTSO':
    case 'percentPortfolio':
      return cellData ? format(cellData, 2) : ZERO
    case 'reportDate':
      return cellData ? formatDate(cellData, undefined, true, true) : EMPTY_PLACEHOLDER
    case 'filingSource':
      if (holderType === FUND) {
        sourceData = get(data, 'filingType') || EMPTY_PLACEHOLDER
      } else {
        sourceData = get(data, `${column.colId}`) || EMPTY_PLACEHOLDER
      }
      return sourceData ? formatTitleCase(sourceData) : EMPTY_PLACEHOLDER
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
