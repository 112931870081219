import React, { PureComponent } from 'react';
import PropType from 'prop-types';
import './listItem.component.css';

class ListEditableItem extends PureComponent {
  onRemoveClick = () => {
    const { onRemove } = this.props;
    onRemove && onRemove();
  };

  render () {
    const { rawValues, content, text, optionSubComponents } = this.props;

    return (
      <div className='list-editable_item'>
        <div className='item-header'>
          <div className='item-header_delete-btn' onClick={this.onRemoveClick}>
            <div className='item-header_delete-icon'>
              <i className='q4i-minus-4pt' />
            </div>
          </div>
          <div className='item-header_text'>
            {text}
          </div>
          {optionSubComponents && optionSubComponents(rawValues.index,rawValues.item)}
        </div>
        {content && <div className='item-content'>
          {content}
        </div>}
      </div>
    );
  }
}

ListEditableItem.propTypes = {
  text: PropType.any.isRequired,
  content: PropType.any,
  rawValues: PropType.object,
  optionSubComponents: PropType.func
};

ListEditableItem.defaultProps = {
  text: null,
  content: null,
  rawValues:{
    index:null,
    item:null
  },
  optionSubComponents:null,
};

export default ListEditableItem;
