import {
  GET_ACTIVITY_REQUEST,
  GET_ACTIVITY_SUCCESS,
  GET_ACTIVITY_FAILURE,
  REMOVE_ACTIVITY_SUCCESS,
  REMOVE_ACTIVITY_FAILURE,
  statusType, RESET_ACTIVITY
} from '../../actions'

const initial = {
  data: {},
  deleted: false,
  deleteError: false,
  error: null,
  status: statusType.IDLE
}

const activity = (state = initial, action) => {
  switch (action.type) {
    case GET_ACTIVITY_REQUEST:
      return {
        ...state,
        error: initial.error,
        status: statusType.IN_PROGRESS
      }

    case GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        error: initial.error,
        type: action.type,
        data: action.payload,
        deleted: action.payload.data === null,
        status: statusType.SUCCESS
      }

    case GET_ACTIVITY_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case REMOVE_ACTIVITY_SUCCESS:
      return {
        ...state,
        deleted: true,
        status: statusType.SUCCESS
      }

    case REMOVE_ACTIVITY_FAILURE:
      return {
        ...state,
        deleteError: action.error,
        status: statusType.ERROR
      }

    case RESET_ACTIVITY:
      return initial

    default:
      return state
  }
}

export default activity
