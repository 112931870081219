import {
  GET_ENTITY_HIGHLIGHTS_REQUEST,
  GET_ENTITY_HIGHLIGHTS_SUCCESS,
  GET_ENTITY_HIGHLIGHTS_FAILURE,
  RESET_FLYOUT_DETAILS_ITEM,
  RESET_ENTITY_HIGHLIGHTS,
  statusType
} from '../../../actions'
import { takeLatest } from '../../../utils/redux.util'

const initial = {
  entityType: 'institution',
  status: statusType.IDLE
}

const institutionHighlightsReducer = (state = initial, action) => {
  switch (action.type) {
    case GET_ENTITY_HIGHLIGHTS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }
    case GET_ENTITY_HIGHLIGHTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: statusType.SUCCESS
      }
    case GET_ENTITY_HIGHLIGHTS_FAILURE:
      return {
        ...initial,
        status: statusType.ERROR
      }
    case RESET_FLYOUT_DETAILS_ITEM:
    case RESET_ENTITY_HIGHLIGHTS:
      return {
        ...initial,
        status: statusType.IDLE
      }
    default:
      return state
  }
}

export default takeLatest(
  institutionHighlightsReducer,
  initial,
  [GET_ENTITY_HIGHLIGHTS_REQUEST, GET_ENTITY_HIGHLIGHTS_SUCCESS, GET_ENTITY_HIGHLIGHTS_FAILURE, RESET_ENTITY_HIGHLIGHTS, RESET_FLYOUT_DETAILS_ITEM]
)
