import React, { Component } from 'react'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { setClassNames } from '../../../../utils/widget.util'
import './digital.container.css'
import { getLocalizedFormat } from '../../../../utils'

class DigitalClock extends Component {
  constructor (props) {
    super(props)
    this.state = {
      date: moment().format('dddd MMM Do, YYYY'),
      hour: moment().format('h'),
      minute: moment().format('mm'),
      second: moment().format('ss'),
      meridiem: moment().format('a')
    }
  }

  componentDidMount () {
    this.clockTimer = setInterval(() => {
      this.setState({
        date: moment().format(getLocalizedFormat('dddd, MMM Do, YYYY')),
        hour: moment().format('h'),
        minute: moment().format('mm'),
        second: moment().format('ss'),
        meridiem: moment().format('a')
      })
    }, 1000)
  }

  componentWillUnmount () {
    if (this.clockTimer) {
      clearInterval(this.clockTimer)
    }
  }

  render () {
    const { layout, theme } = this.props
    const classes = setClassNames('digital-clock-widget', layout, theme)

    return (
      <div className={classes.base}>
        <header className={classes.header}>
          <h2 className={`${classes.name}_title`}>{this.state.date}</h2>
        </header>
        <section className={`${classes.name}_container`}>
          <h3 className={`${classes.name}_block`}>{this.state.hour}</h3>
          <span className={`${classes.name}_spacer`} />
          <h3 className={`${classes.name}_block`}>{this.state.minute}</h3>
          <span className={`${classes.name}_spacer`} />
          <h3 className={`${classes.name}_block`}>{this.state.second}</h3>
          <h3 className={`${classes.name}_meridiem`}>{this.state.meridiem}</h3>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.dashboard.dashboardState.theme
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DigitalClock)
