import { format, isReportingWindow } from '../../../../../../utils'
import { get } from 'lodash'

const EMPTY_PLACEHOLDER = '-'

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 */
const CustomCell = ({ data = {}, column = {} }) => {
  const colId = get(column, 'colId', '').toLowerCase()
  const cellData = get(data, `${column.colId}`)

  if (colId.includes('value')) {
    return (cellData || cellData === 0) ? format(cellData, 0) : EMPTY_PLACEHOLDER
  } else if (colId.includes('change')) {
    const isYetToFile = isReportingWindow() && cellData === 0
    const isLatestQuarterCol = (colId === 'q1change')

    if (isLatestQuarterCol && isYetToFile) {
      return 'Yet to File'
    }

    return (cellData || cellData === 0) ? format(cellData, 0) : EMPTY_PLACEHOLDER
  }

  return cellData || EMPTY_PLACEHOLDER
}

export default CustomCell
