import {
  GET_CONTACT_STRATEGY_REQUEST,
  GET_CONTACT_STRATEGY_SUCCESS,
  GET_CONTACT_STRATEGY_FAILURE,
  RESET_CONTACT,
  statusType,
} from '../../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const strategy = (state = initial, action) => {
  const { payload } = action

  switch (action.type) {
    case GET_CONTACT_STRATEGY_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_CONTACT_STRATEGY_SUCCESS:
      return {
        ...state,
        data: payload,
        status: statusType.SUCCESS
      }

    case GET_CONTACT_STRATEGY_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }
    case RESET_CONTACT:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default strategy
