import {
  FETCH_TOP_SELLERS_REQUEST,
  FETCH_TOP_SELLERS_SUCCESS,
  FETCH_TOP_SELLERS_ERROR,
  FETCH_TOP_SELLERS_SNOWFLAKE_SUCCESS,
  FETCHING,
  FETCHED,
  FAILED,
  IDLE
} from '../../../actions/widget/ownership'
import { get } from 'lodash'

const initial = {
  holders: [],
  status: IDLE
}

const sellers = (state = initial, action) => {
  switch (action.type) {
    case FETCH_TOP_SELLERS_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_TOP_SELLERS_SUCCESS:
      let holders = []

      if (action.payload.report) {
        holders = action.payload.report.topSellers
      } else {
        holders = action.payload
      }

      return {
        ...state,
        holders,
        status: FETCHED
      }
    case FETCH_TOP_SELLERS_SNOWFLAKE_SUCCESS:
      return {
        ...state,
        holders: get(action, 'payload.data.mover.items', []),
        status: FETCHED
      }
    case FETCH_TOP_SELLERS_ERROR:
      return {
        ...state,
        error: action.error,
        status: FAILED
      }
    default:
      return state
  }
}

export default sellers
