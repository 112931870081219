import { INDICES } from './stock.const'

/**
 * Xignite to Standard exchange mapping
 * @param exchange
 * @return {*}
 * @private
 */
export const getFromXigniteToStandard = (exchange) => {
  switch (exchange) {
    case 'XNYS':
    case 'NYS':
      return 'NYSE'
    case 'XNAS':
    case 'NAS':
      return 'NASDAQ'
    case 'XTSE':
    case 'TSE':
      return 'TSX'
    case 'XTSX':
    case 'TSX':
      return 'TSX-V'
    case 'XASE':
    case 'ASE':
      return 'NYSEMKT'
    case 'ARCX':
      return 'NYSEARCA'
    case 'XLON':
    case 'LON':
      return 'LSE'
    case 'XSTO':
    case 'STO':
      return 'OME'
    case 'XMIL':
      return 'MIL'
    case 'XSWX':
      return 'SWX'
    case 'XETR':
      return 'ETR'
    case 'XASX':
      return 'ASX'
    case 'XPAR':
      return 'PAR'
    case 'XAMS':
      return 'AMS'
    case 'XOTC':
      return 'OOTC'
    default:
      return (exchange && exchange.charAt(0) && exchange.charAt(0).toLowerCase() === 'x'
        ? exchange.substr(1)
        : exchange
      )
  }
}

/**
 * Xignite to Factset standard symbol mapping
 * @param xigniteSymbol
 */
export const getSymbolFromXigniteToStandard = (xigniteSymbol) => {
  const [symbol, symbolSuffix] = xigniteSymbol.replace(' ', '.').split('.')

  switch (symbolSuffix) {
    case 'UN':
      return `${symbol}.UT`
    default:
      return xigniteSymbol
  }
}

export const getCurrencyCode = (iso) => {
  if (typeof iso !== 'string') {
    return '$'
  }

  switch (iso.toUpperCase()) {
    case 'EUR':
      return '&euro;'

    case 'CNY':
    case 'JPY':
      return '&yen;'

    case 'SEK':
    case 'ISK':
      return 'kr'

    case 'EGP':
    case 'FKP':
    case 'GIP':
    case 'GBP':
    case 'LBP':
    case 'SHP':
    case 'SDG':
    case 'SYP':
      return '&pound'

    case 'USD':
    case 'CAD':
    default:
      return '$'
  }
}

/**
   * Format Currency
   * @param currency
   * @returns {string|*}
   */
export const formatCurrency = (currency) => {
  if (currency === 'GBP') {
    return 'GBX'
  }
  return currency
}

/**
   * Format Stock Price
   * @param value {number | string}
   * @param currency {string}
   * @param asString {boolean}
   * @returns {number | string|*}
   */
export const formatStockPrice = (value, currency, asString) => {
  let _value = parseFloat(value || 0);
  const _currency = currency || 'USD'

  if (_value || _value === 0) {
    if (_currency === 'GBP') {
      _value *= 100
    }
    _value = Number(Math.round(_value + 'e2') + 'e-2')
    if (asString) {
      _value = _value.toFixed(2)
    }
    return _value
  }
  return value
}

export const getIndices = () => INDICES

/**
 * Return symbol and exchange by parsing stock identifier
 * @param identifier
 */
export const parseStockIdentifier = (identifier = '') => {
  const [exchange, ...symbols] = identifier.replace(' ', '.').split('.').reverse()
  const symbol = symbols.reverse().join('.')

  return (symbol && exchange)
    ? { symbol, exchange }
    : identifier
}

/**
 * Returns formatted symbol and exchange
 * @param symbol
 * @param exchange
 */
export const formatStockIdentifier = ({ symbol, exchange } = {}) => {
  if (!symbol || !exchange) {
    return ''
  }
  return `${getSymbolFromXigniteToStandard(symbol)}.${getFromXigniteToStandard(exchange)}`
}

/**
 * Helper function to compare stock identifiers.
 * @param a
 * @param b
 * @return {boolean}
 */
export const isStockIdentifierEqual = (a = {}, b = {}) => {
  if (!a || !a.symbol || !a.exchange || !b || !b.symbol || !b.exchange) {
    return false
  }

  const Identifier = ({ symbol, exchange }) => (symbol && exchange) ? `${symbol}.${exchange}` : ''

  return (
    Identifier(a) === Identifier(b) ||
    Identifier({ ...a, exchange: getFromXigniteToStandard(a.exchange) }) === Identifier({ ...b, exchange: getFromXigniteToStandard(b.exchange) }) ||
    Identifier(a) === Identifier({ ...b, exchange: getFromXigniteToStandard(b.exchange) }) ||
    Identifier({ ...a, exchange: getFromXigniteToStandard(a.exchange) }) === Identifier(b)
  )
}
