import { get } from 'lodash'
import { SWIFTYPE_ENTITY_TYPES, SWIFTYPE_TO_ENTITY_TYPE_MAP } from '../../../../utils'

import './searchResultMapper.doc'

/**
 * Maps the institution/fund addresses of GraphQL search results to match the structure of REST API
 * @param {GqlAddress[]} addresses
 * @return {RestAddress[]}
 */
function _mapAddresses (addresses) {
  return (addresses || []).map((address) => {
    return {
      address1: address.addressLine1,
      address2: address.addressLine2,
      address3: address.addressLine3,
      city: address.city,
      country: address.countryCode,
      country_name: address.countryName,
      fax: address.fax,
      hq: address.hq === 'true',
      postal_code: address.postalCode,
      region: address.region,
      state_province: address.stateProvinceCode,
      telephone: address.telephone
    }
  })
}

/**
 * Maps the contact jobs of GraphQL search results to match the structure of REST API
 * @param {GqlJob[]} jobs
 * @return {RestJob[]}
 */
function _mapJobs (jobs) {
  return (jobs || []).map((job) => {
    return {
      _id: job.id,
      active: job.active,
      address1: job.address,
      address2: job.address,
      address3: job.address,
      city: job.city,
      city_state_zip: job.cityStateZip,
      country: job.country,
      country_name: job.countryName,
      direct_telephone: job.directPhone,
      email: job.email,
      fax: job.fax,
      functions: job.functions,
      institution_name: job.institutionName,
      institution_type: job.institutionType,
      metro: job.metro,
      phone: job.phone,
      postal_code: job.postalCode,
      q4_entity_id: job.entityId,
      region: job.region,
      state_province: job.stateProvince,
      title: job.title
    }
  })
}

/**
 * Maps the result of GraphQL search to match the structure of REST API
 * @param {*} data
 * @return {*}
 */
function gqlToRestSearchMapper (data) {
  const entityConnection = get(data, 'entityConnection')
  const entityType = get(entityConnection, 'type')

  const _id = get(data, 'id', null) || get(entityConnection, 'entityId', null) || get(data, 'entity.id', null)
  const _score = get(data, 'score', null)
  const _type = SWIFTYPE_TO_ENTITY_TYPE_MAP[entityType]
  const _source = {}

  switch (entityType) {
    case SWIFTYPE_ENTITY_TYPES.INSTITUTIONS: {
      const addresses = get(entityConnection, 'entity.addressConnection.items', [])
      _source.address = _mapAddresses(addresses)
      _source.country = get(entityConnection, 'country')
      _source.institution_name = get(entityConnection, 'institutionName')
      _source.q4_entity_id = get(entityConnection, 'entityId')
      _source.type = get(entityConnection, 'entity.institutionType')
      _source._id = _id
      break
    }
    case SWIFTYPE_ENTITY_TYPES.FUNDS: {
      const addresses = get(entityConnection, 'entity.institutionConnection.items[0].addressConnection.items', [])
      _source.address = _mapAddresses(addresses)
      _source.factset_fund_id = get(entityConnection, 'sourceId')
      _source.factset_institution_id = get(entityConnection, 'institutionId')
      _source.fund_name = get(entityConnection, 'fundName')
      _source.institution_name = get(entityConnection, 'entity.institutionConnection.items[0].institutionName') || get(entityConnection, 'entity.instituteFund')
      _source.q4_entity_id = get(entityConnection, 'entityId') || get(entityConnection, 'entity.id')
      _source.q4_inst_id = get(entityConnection, 'entity.institutionConnection.items[0].id')
      _source.type = get(entityConnection, 'entity.institutionConnection.items[0].institutionType')
      _source._id = _id
      break
    }
    case SWIFTYPE_ENTITY_TYPES.CONTACTS: {
      const jobs = get(entityConnection, 'entity.jobs', [])
      _source.first_name = get(entityConnection, 'entity.firstName')
      _source.full_name = get(entityConnection, 'entity.fullName')
      _source.favorites = get(entityConnection, 'entity.favoriteConnection', [])
      _source.jobs = _mapJobs(jobs)
      _source.last_name = get(entityConnection, 'entity.lastName')
      _source.source = get(entityConnection, 'entity.source')
      _source._id = get(entityConnection, 'entity.id')
      break
    }
    default:
      return null
  }

  return {
    _id,
    _score,
    _type,
    _source
  }
}

export { gqlToRestSearchMapper }
