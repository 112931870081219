import React from 'react'
import { ExpandableCell } from '../../../../agGrid/cell'
import { Tag } from '../../../../index'
import { formatDate, getClassName, ENTITY_TYPE, formatLocalizedDate, DEFAULT_DATE_FORMAT } from '../../../../../utils'
import { get, uniqBy } from 'lodash'

const EMPTY_PLACEHOLDER = '-'

/**
 * Category Cell Component
 * @param category
 * @returns {*}
 * @constructor
 */
const CategoryCell = ({ category }) => {
  const icon = {
    comment: 'q4i-note-4pt',
    phone: 'q4i-phone-4pt',
    email: 'q4i-mail-4pt',
    meeting: 'q4i-meeting-4pt',
    earnings: 'q4i-earnings-4pt',
    other: 'q4i-report-blank-4pt',
    roadshow: 'q4i-roadshow-4pt',
    conference: 'q4i-conference-4pt'
  }[category]

  return <i className={`cell_category ${icon}`} />
}

/**
 * Title Cell Component
 * @param props
 * @returns {*}
 * @constructor
 */
const TitleCell = ({ title, parent, isExpandable, isOpen, onExpand }) => {
  return <div className='cell'>
    <div className='cell_content'>
      <div className='cell_content-name'>{title || EMPTY_PLACEHOLDER}</div>
      {parent ? <div className='cell_content-detail'>{parent}</div> : null}
    </div>
    {isExpandable && (
      <div
        className={getClassName('cell_toggle', [
          { condition: isOpen, trueClassName: 'cell_toggle--open' }
        ])}
        onClick={onExpand}>
        <i className='q4i-caret-sm-down-4pt' />
      </div>
    )}
  </div>
}

/**
 * Assign data for cell
 * @param cellType
 * @param data
 * @returns {Array|*}
 * @private
 */
const _assignDataForCell = (cellType, data) => {
  if (cellType === 'address') {
    let _addresses = []

    if (data[cellType] && data[cellType].length) {
      _addresses = _addresses.concat(data[cellType])
    }

    if (data._itinerary && data._itinerary.length) {
      data._itinerary.forEach((itinerary) => {
        if (itinerary.address && itinerary.address.length) {
          _addresses = _addresses.concat(itinerary.address)
        }
      })
    }

    return uniqBy(_addresses, (address) => address.location)
  }

  if (['institution', 'contact'].includes(cellType)) {
    return (data[cellType] || []).map((row) => {
      const connection = row.entityConnection || {}
      const type = row.entityType.toLowerCase()

      return {
        entityId: row.entityId,
        entityType: row.entityType,
        ...connection,
        fullName: type === ENTITY_TYPE.CONTACT && (connection.fullName || row.entityName),
        institutionName: type === ENTITY_TYPE.INSTITUTION && (connection.institutionName || row.entityName)
      }
    })
  }

  return data[cellType]
}

/**
 * AgGrid Custom Cell Render Component
 * @param props
 */
const CustomCell = (props) => {
  const { data = {}, column = {}, node, api, onPopoverClick, onPopoverItemClick, onTagClick } = props
  const cellData = _assignDataForCell(column.colId, data)

  switch (column.colId) {
    case 'category':
      return <CategoryCell category={cellData} />
    case 'title':
      return (
        <TitleCell
          title={cellData}
          parent={get(data, '_activity.activityConnection.items[0].title', null)}
          isExpandable={Boolean(data._itinerary && data._itinerary.length)}
          isOpen={node.expanded}
          onExpand={(event) => {
            event.stopPropagation()
            node.setExpanded(!node.expanded)
            api.redrawRows({ rowNodes: [node] })
          }}
        />
      )
    case 'contact':
      return (
        <ExpandableCell
          items={cellData}
          label='fullName'
          onClick={onPopoverClick}
          onItemClick={onPopoverItemClick}
        />
      )
    case 'institution':
      return (
        <ExpandableCell
          items={cellData}
          label='institutionName'
          onClick={onPopoverClick}
          onItemClick={onPopoverItemClick}
        />
      )
    case 'address':
      return (data && data.virtual)
        ? <div className='cell--disabled'>Virtual</div>
        : <ExpandableCell
          items={cellData}
          label='location'
          onClick={onPopoverClick}
        />
    case 'start':
      const date = (cellData && cellData.date) ? new Date(Number(cellData.date)) : null
      return date ? formatLocalizedDate(formatDate(date), DEFAULT_DATE_FORMAT) : EMPTY_PLACEHOLDER
    case 'tag':
      const tags = (cellData || []).map((item) => { return { tagType: true, name: item } })

      return (tags.length === 1)
        ? <Tag items={tags} onClick={onTagClick} />
        : (tags.length > 1)
          ? <Tag
            items={tags}
            limit={2}
            onClick={onTagClick}
            onMoreClick={(event) => {
              onPopoverClick(event, {
                isMargin: true,
                items: (tags || []).map((tag) => {
                  return {
                    ...tag,
                    tagType: true,
                    label: `#${tag.name}`
                  }
                }).slice(2),
                open: true,
                anchorEl: event.currentTarget
              })
            }}
          />
          : EMPTY_PLACEHOLDER
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
