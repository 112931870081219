import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { addQueryParams } from '../../../utils/http/http.util'

export const FETCH_INSTITUTION_ANALYTICS_REQUEST = 'FETCH_INSTITUTION_ANALYTICS_REQUEST'
export const FETCH_INSTITUTION_ANALYTICS_SUCCESS = 'FETCH_INSTITUTION_ANALYTICS_SUCCESS'
export const FETCH_INSTITUTION_ANALYTICS_ERROR = 'FETCH_INSTITUTION_ANALYTICS_ERROR'

const resources = {
  institutionAnalytics: '/analytics/institution'
}

const _fetchInstitutionAnalytics = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_INSTITUTION_ANALYTICS_REQUEST,
      FETCH_INSTITUTION_ANALYTICS_SUCCESS,
      FETCH_INSTITUTION_ANALYTICS_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

export const fetchInstitutionAnalytics = (params) => (dispatch) => {
  const url = addQueryParams(resources.institutionAnalytics, params)
  return dispatch(_fetchInstitutionAnalytics(url))
}
