import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { RangeTab, SectionSearch } from '../../../../../shared'
import { Button, Select, Toolbar, ToolbarRow } from '../../../../../index'
import { ENTITY_TYPE, HOLDER, PEER_METRIC, THEMES } from '../../../../../../utils'

const { FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string.isRequired,
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  noData: PropTypes.bool,
  search: PropTypes.string,
  metric: PropTypes.string.isRequired,
  holder: PropTypes.string.isRequired,
  onHolderChange: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onPeerUpdate: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  exporting: PropTypes.bool
}

const defaultProps = {
  toolbarTheme: THEMES.Q4_BLUE,
  toolTheme: THEMES.INK,
  metric: 'current'
}

/**
 * Holder Peer Analysis Toolbar Component
 * @param props
 */
function HolderPeerAnalysisToolbar (props) {
  const {
    dataId, toolbarTheme, toolTheme, noData, metric, holder, search,
    onHolderChange, onQueryChange, onPeerUpdate, onExport, exporting
  } = props

  const santizedMetric = metric === "change" ? PEER_METRIC[1].value : metric

  const selected = PEER_METRIC.find((option) => option.value === santizedMetric)
  const handleChange = (option) => option && onQueryChange({ metric: option.value })
  const handleSearchChange = (search) => onQueryChange({ search })

  return (
    <Toolbar theme={toolbarTheme}>
      <ToolbarRow justified>
        <div className='toolbar_group toolbar_group--spaced'>
          <RangeTab
            theme={toolTheme}
            buttons={HOLDER.filter((holder) => [FUND, INSTITUTION].includes(holder.value)).map((option) => ({
              ...option,
              dataId: `${dataId}${option.dataId}`,
              active: holder === option.value,
              onClick: (event, value) => onHolderChange(value)
            }))}
          />
          <Select
            dataId={{
              inputId: `${dataId}MetricFilterInput`,
              menuId: `${dataId}MetricFilterMenu`
            }}
            theme={toolTheme}
            size='thin'
            value={selected}
            options={[
              { label: 'Position', value: 'current' },
              { label: 'Position Change', value: 'change' },
            ]}
            searchable={false}
            clearable={false}
            onChange={handleChange}
          />
          <Button
            dataId={`${dataId}ModifyPeers`}
            theme={toolTheme}
            label='Peers'
            onClick={onPeerUpdate}
          />
        </div>
        <div className='toolbar_group'>
          <SectionSearch
            dataId={`${dataId}Search`}
            theme={toolTheme}
            value={search}
            onQueryChange={handleSearchChange}
            onClear={handleSearchChange}
          />
          <Button
            dataId={`${dataId}Export`}
            theme={toolTheme}
            loading={exporting}
            icon='q4i-download-4pt'
            label='Export'
            disabled={noData}
            onClick={onExport}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

HolderPeerAnalysisToolbar.propTypes = propTypes
HolderPeerAnalysisToolbar.defaultProps = defaultProps

export default memo(HolderPeerAnalysisToolbar)
