import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const SEARCH_TAGS_REQUEST = 'SEARCH_TAGS_REQUEST'
export const SEARCH_TAGS_SUCCESS = 'SEARCH_TAGS_SUCCESS'
export const SEARCH_TAGS_FAILURE = 'SEARCH_TAGS_FAILURE'

export const GET_TAG_REQUEST = 'GET_TAG_REQUEST'
export const GET_TAG_SUCCESS = 'GET_TAG_SUCCESS'
export const GET_TAG_FAILURE = 'GET_TAG_FAILURE'

export const CREATE_TAG_REQUEST = 'CREATE_TAG_REQUEST'
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS'
export const CREATE_TAG_FAILURE = 'CREATE_TAG_FAILURE'

export const DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST'
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS'
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE'

export const RESET_TAGS = 'RESET_TAGS'
export const RESET_SEARCH_TAGS = 'RESET_SEARCH_TAGS'

/**
 * Search Tags
 * @param params
 * @returns {{}}
 * @private
 */
const _searchTags = (params) => ({
  [CALL_API]: {
    types: [
      SEARCH_TAGS_REQUEST,
      SEARCH_TAGS_SUCCESS,
      SEARCH_TAGS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/tag/autocomplete',
    payload: {
      term: `${params}`,
      page: 1,
      start: 0,
      limit: 25
    }
  }
})

/**
 * Get Tags
 * @param params
 * @returns {{}}
 * @private
 */
const _getTags = (params) => ({
  [CALL_API]: {
    types: [
      GET_TAG_REQUEST,
      GET_TAG_SUCCESS,
      GET_TAG_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/tag',
    payload: {
      ...params,
      page: 1,
      start: 0,
      limit: 20
    }
  }
})

/**
 * Create Tag
 * @param params
 * @returns {{}}
 * @private
 */
const _createTag = (params) => ({
  [CALL_API]: {
    types: [
      CREATE_TAG_REQUEST,
      CREATE_TAG_SUCCESS,
      CREATE_TAG_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/tag',
    payload: params
  }
})

/**
 * Delete Tag
 * @param id
 */
const _deleteTag = (id) => ({
  [CALL_API]: {
    types: [
      DELETE_TAG_REQUEST,
      DELETE_TAG_SUCCESS,
      DELETE_TAG_FAILURE
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: `/tag/${id}`
  }
})

/**
 * Get entity object based on id length
 * @param params
 * @return {{}|*}
 * @private
 */
const _getEntity = (params) => {
  const isQ4Id = params['reference.item'] && params['reference.item'].length === 32
  if (isQ4Id) {
    params['reference.entityId'] = params['reference.item']
    delete params['reference.item']
  }
  return params
}

/**
 * Dispatch SEARCH_TAGS_REQUEST
 * @param params
 */
export const searchTags = (params) => (dispatch) => {
  return dispatch(_searchTags(params))
}

/**
 * Dispatch RESET_SEARCH_TAGS
 */
export const resetSearchTags = () => (dispatch) => {
  return dispatch({ type: RESET_SEARCH_TAGS })
}

/**
 * Dispatch GET_TAG_REQUEST
 * @param params
 */
export const getTags = (params) => (dispatch) => {
  return dispatch(_getTags(_getEntity(params)))
}

/**
 * Dispatch CREATE_TAG_REQUEST
 * @param params
 */
export const createTag = (params) => (dispatch) => {
  return dispatch(_createTag(params))
}

/**
 * Dispatch DELETE_TAG_REQUEST
 * @param id
 */
export const deleteTag = (id) => (dispatch) => {
  return dispatch(_deleteTag(id))
}

/**
 * Dispatch RESET_TAGS
 */
export const resetTags = () => (dispatch) => {
  return dispatch({ type: RESET_TAGS })
}
