import React from 'react'

// utils
import { getClassName, ENTITY_TYPE, formatTitleCase } from '../../../../../../../utils'
import { get } from 'lodash'

const { FUND, INSTITUTION } = ENTITY_TYPE
const EMPTY_PLACEHOLDER = '-'

/**
 * Title Cell Component
 * @param props
 */
const TitleCell = ({ data, onUtilityClick, isExpandable, isOpen, onExpand }) => {
  const {
    fundId, institutionId, fundName, institutionName, holderType, holdingType, activist, isNested, dealConnection, targetConnection
  } = (data || {})
  const source = (holderType === FUND) ? FUND : INSTITUTION

  const name = get(data, `${source}Name`, '')
  const country = get(data, `${source}CountryName`)
  const entityType = (holdingType === 'stakes')
    ? (holderType === 'person') ? 'insider' : 'insider-institution'
    : holderType || source

  const className = getClassName('cell_toggle', [
    { condition: isOpen, trueClassName: 'cell_toggle--open' }
  ])

  const target = get(targetConnection, 'items[0].id')
  const hasDeals = !!(dealConnection && dealConnection.items && dealConnection.items.length)
  const entity = { entityType, hasDeals, target }

  return (
    <div className={`cell ${(holdingType === 'stakes') ? 'cell--non-clickable' : ''}`}>
      <i className={`cell_icon cell_icon--${entityType} q4i-${entityType}-2pt`} />
      <div className='cell_content'>
        <div className='cell_content-name'>{name}</div>
        {country ? <div className='cell_content-detail'>{country}</div> : null}
      </div>

      {activist && <i className='cell_indicator cell_indicator--activist q4i-activist-4pt' />}
      {!!target && <i className='cell_indicator cell_indicator--target q4i-targeting-4pt' />}
      {hasDeals && <i className='cell_indicator cell_indicator--deal q4i-deal-4pt' />}
      {[FUND, INSTITUTION].includes(entityType) &&
        <i
          className='cell_indicator cell_indicator--utility q4i-utility-4pt' onClick={(event) =>
            onUtilityClick(event, {
              ...(source === FUND)
                ? { id: fundId, fundName, ...entity }
                : { id: institutionId, institutionName, ...entity }
            })}
        />}

      {isNested && <div className='cell_space' />}
      {isExpandable && <div className={className} onClick={onExpand}><i className='q4i-caret-sm-down-4pt' /></div>}
      {(entityType === INSTITUTION) && !isExpandable && <div className='cell_space' />}
    </div>
  )
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 * @param api
 * @param node
 * @param agGridReact
 * @param onUtilityClick
 */
const CustomCell = ({ data = {}, column = {}, api, node, agGridReact, onUtilityClick }) => {
  const cellData = get(data, `${column.colId}`)
  const holderType = get(data, 'holderType')
  const pagination = get(agGridReact, 'props.paginationProps', {})
  const page = get(pagination, 'forcePage', 1)
  const limit = get(pagination, 'initialPageSize', 10)
  let sourceData = null

  switch (column.colId) {
    case 'rank':
      if (!cellData) return null
      return (page - 1) * limit + cellData
    case 'fundName':
    case 'institutionName':
      return (
        <TitleCell
          data={data}
          onUtilityClick={onUtilityClick}
          isExpandable={Boolean((get(data, 'fundHoldingCurrentConnection.items') || []).length)}
          isOpen={node.expanded}
          onExpand={(event) => {
            event.stopPropagation()
            node.setExpanded(!node.expanded)
            api.redrawRows({ rowNodes: [node] })
          }}
        />
      )
    case 'filingSource':
      if (holderType === FUND) {
        sourceData = get(data, 'filingType') || EMPTY_PLACEHOLDER
      } else {
        sourceData = get(data, `${column.colId}`) || EMPTY_PLACEHOLDER
      }
      return sourceData ? formatTitleCase(sourceData) : EMPTY_PLACEHOLDER
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
