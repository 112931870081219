export const CARD_CLASS_NAME = {
  BASE: 'card',
  HEADER: 'card_header',
  HEADER_NOTE:' card_header-note',
  LIST: 'card_list',
  LIST_LABEL: 'card_list-label',
  LIST_WITH_DISABLED_MODIFIER: 'card_list--disabled',
  LIST_VALUE: 'card_list-value',
  LIST_VALUE_WITH_POSITIVE_MODIFIER: 'card_list-value--positive',
  LIST_VALUE_WITH_NEGATIVE_MODIFIER: 'card_list-value--negative',
}
