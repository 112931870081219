import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Banner, Message } from '../../../../components'
import { CORPORATE_PARTICIPANT, MESSAGE } from '../../../../utils'
import {
  modalType,
  REMOVE_CORPORATE_PARTICIPANT_SUCCESS,
  REMOVE_CORPORATE_PARTICIPANT_FAILURE
} from '../../../../actions'

/**
 * Delete Confirm Modal
 * @param onClose
 * @param title
 * @param message
 * @param onDeleteConfirm
 * @returns {*}
 * @constructor
 */
const ConfirmModal = ({ onClose, title, message, onDeleteConfirm }) => {
  return (
    <Message
      visible
      type='warning'
      title={title}
      message={message}
      onClose={onClose}
      buttons={[
        {
          ui: 'shaded',
          label: 'cancel',
          onClick: onClose
        },
        {
          ui: 'spice',
          label: 'delete',
          onClick: () => onDeleteConfirm()
        }
      ]}
    />
  )
}

/**
 * Error Message Modal
 * @param onClose
 * @returns {*}
 * @constructor
 */
const ErrorModal = ({ onClose }) => {
  return (
    <Message
      visible
      type='error'
      title='Error'
      message={MESSAGE.ERROR}
      onClose={onClose}
      buttons={[{
        ui: 'spice',
        label: 'close',
        onClick: onClose
      }]}
    />
  )
}

/**
 * Corporate Participant Banner Component
 * @param props
 * @returns {*}
 * @constructor
 */
class ParticipantBanner extends PureComponent {
  /**
   * On Corporate Participant Delete
   */
  onDelete () {
    const { openModal } = this.props

    openModal({
      component: ({ onClose }) => ConfirmModal({
        onClose,
        title: 'Delete Corporate Participant?',
        message: 'Are you sure you want to delete this Corporate Participant?',
        onDeleteConfirm: this.onDeleteConfirm.bind(this)
      })
    })
  }

  /**
   * On Corporate Participant Delete Confirm
   */
  onDeleteConfirm () {
    const { history, participant, removeParticipant } = this.props
    const { _id } = participant

    this._handleMessageClose()

    _id && removeParticipant(_id).then((data) => {
      switch (data && data.type) {
        case REMOVE_CORPORATE_PARTICIPANT_SUCCESS:
          return history.push('/contact')
        case REMOVE_CORPORATE_PARTICIPANT_FAILURE:
          return this._handleError()
        default:
          break
      }
    })
  }

  /**
   * Handle close message modal
   * @private
   */
  _handleMessageClose () {
    const { closeModal } = this.props
    closeModal({
      component: ConfirmModal
    })
  }

  /**
   * Handle request error
   * @private
   */
  _handleError () {
    const { openModal } = this.props
    openModal({
      component: ErrorModal
    })
  }

  /**
   * Render Participant Detail Banner Component
   * @returns {*}
   */
  render () {
    const { name, participant, handleParticipantUpdate, openModal } = this.props
    const { _id, nickname, title } = participant

    const controls = [
      {
        type: 'button',
        icon: 'q4i-edit-4pt',
        square: true,
        onClick: () => openModal({
          type: modalType.CONTACT_MODAL,
          props: {
            id: _id,
            type: CORPORATE_PARTICIPANT,
            onSaveSuccess: handleParticipantUpdate
          }
        })
      },
      {
        type: 'utility',
        entityType: CORPORATE_PARTICIPANT,
        entity: { _id, _corporateparticipant: _id, name },
        items: [
          { action: 'CREATE_ACTIVITY' },
          {
            icon: 'q4i-trashbin-2pt',
            label: 'Delete',
            onClick: () => this.onDelete()
          }
        ]
      }
    ]

    return (
      <Banner
        size='medium'
        title={name}
        icon='q4i-contact-2pt'
        details={
          <>
            {title && <span>{title}</span>}
            {title && nickname && <br />}
            {nickname && <span>Nickname - {nickname}</span>}
          </>
        }
        controls={controls} />
    )
  }
}

ParticipantBanner.propTypes = {
  name: PropTypes.string,
  participant: PropTypes.object.isRequired,
  handleParticipantUpdate: PropTypes.func.isRequired,
  removeParticipant: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  history: PropTypes.object
}

ParticipantBanner.defaultProps = {
  participant: {}
}

export default ParticipantBanner
