import React, { Component } from 'react'
import { Modal } from '../../../components'

class PipelineMarketingModal extends Component {
  /**
     * Render
     * @returns {XML}
     */
  render () {
    const { visible, onClose } = this.props

    return (
      <Modal
        className='modal-root--centered modal-root--marketing'
        visible={visible}
        title='Welcome to Pipeline.'
        onClose={onClose}
        footerButtons={[
          {
            label: 'Get Started',
            ui: 'citrus',
            onClick: onClose
          }
        ]}
      >
        <div className='message'>Pipeline allows you to manage your investor relations efforts like a sales pipeline.
                    Create<br />a new Deal with any fund or firm and then update the stage through to investment.<br />
                    Start by dragging your Saved Targets into the first stage of "Qualified Lead".
        </div>
        <iframe
          src='https://player.vimeo.com/video/272369298?title=0&byline=0&portrait=0'
          title='pipeline' width='560' height='315' frameBorder='0'
          webkitallowfullscreen='true' mozallowfullscreen='true' allowFullScreen={true}
        />
      </Modal>
    )
  }
}

export default PipelineMarketingModal
