import React from 'react'
import moment from 'moment-timezone'

import { getEntityCategoryIcon, getUniqEntityLocations } from '../../../../../../../utils/entity'
import { ExpandableCell } from '../../../../../../agGrid/cell'
import { format, getAiScore, inMillions, formatLocalizedDate } from '../../../../../../../utils'
import './cell.component.css'

const EMPTY_PLACEHOLDER = '-'

/**
 * Title Custom Cell Component
 */
const TitleCell = ({ item }) => {
  const isTarget = !!item._target
  const isDeal = !!item._deal
  return (
    <div className='cell cell--title'>
      <i className={`cell_icon cell_icon--fund ${getEntityCategoryIcon('fund')}`} />
      <div className='cell_title'>
        <div className='cell_title--name'>
          {item.fund_name}
        </div>
        <div className='cell_title--detail'>
          {item.country_name}
        </div>
      </div>
      {isTarget && <i className='cell_icon cell_icon--target q4i-targeting-4pt' />}
      {isDeal && <i className='cell_icon cell_icon--deal q4i-deal-4pt' />}
    </div>
  )
}

/**
 * Quality Rating Custom Cell Component
 */
const QualityRatingCell = ({ rating }) => {
  if (!rating) {
    return EMPTY_PLACEHOLDER
  }

  return (
    <div className='cell_rating'>
      {rating}
    </div>
  )
}

/**
 * AI score Custom Cell Component
 */
const AiScoreCell = ({ aiScore }) => {
  return (
    <div className='ai-score'>
      {aiScore}
    </div>
  )
}

/**
 * Location Custom Cell Component
 */
const LocationCell = ({ item, onExpandMenuClick }) => {
  const locations = (getUniqEntityLocations(item.address) || []).map((addressItem) => {
    return {
      label: addressItem
    }
  })

  return (
    <ExpandableCell
      items={locations}
      onClick={onExpandMenuClick}
    />
  )
}

/**
 * Contact Custom Cell Component
 */
const ContactCell = ({ item, onExpandMenuClick, onExpandMenuItemClick }) => {
  const contacts = (item.contacts || []).map((contact) => ({
    _id: contact._id,
    label: contact.full_name
  }))
  return (
    <ExpandableCell
      items={contacts}
      onClick={onExpandMenuClick}
      onItemClick={onExpandMenuItemClick}
    />
  )
}

/**
 * Date Added Custom Cell Component
 */
const AsOfCell = ({ date_time }) => {
  return date_time ? 
  formatLocalizedDate(moment(date_time).utc().format('MM/DD/YY'), 'MM/DD/YY') 
  : '-'
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 */
const CustomCell = ({ data = {}, column = {}, onExpandMenuClick, onExpandMenuItemClick }) => {
  const cellData = data[column.colId]

  switch (column.colId) {
    case 'fund_name':
      return <TitleCell item={data} />
    case 'current': {
      return !isNaN(data.current) ? format(data.current, 0) : EMPTY_PLACEHOLDER
    }
    case 'style':
      return cellData || EMPTY_PLACEHOLDER
    case 'turnover':
      return cellData || EMPTY_PLACEHOLDER
    case 'equity_aum':
      return inMillions(cellData, 2) || EMPTY_PLACEHOLDER
    case 'portfolio_value':
      return inMillions(cellData, 2) || EMPTY_PLACEHOLDER
    case 'quality_rating':
      return <QualityRatingCell rating={cellData} />
    case 'aiScore':
      return <AiScoreCell aiScore={getAiScore(data, cellData)} />
    case 'purchasingPower':
      return (cellData && format(cellData))|| EMPTY_PLACEHOLDER
    case 'report_date':
      return <AsOfCell date_time={cellData} />
    case 'address':
      return <LocationCell item={data} onExpandMenuClick={onExpandMenuClick} />
    case 'contact':
      return <ContactCell item={data} onExpandMenuClick={onExpandMenuClick} onExpandMenuItemClick={onExpandMenuItemClick}/>
    case 'lastActivity': {
      return data.lastActivity ? 
        formatLocalizedDate(moment(data.lastActivity).format('MM/DD/YY'), 'MM/DD/YY') : 
        EMPTY_PLACEHOLDER
    }
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
