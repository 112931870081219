import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Error } from '../index'
import { getClassName, THEMES } from '../../../utils/ui'

import './textArea.component.scss'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT, THEMES.WHITE]),
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  required: PropTypes.bool
}

const defaultProps = {
  theme: THEMES.LIGHT,
  placeholder: '',
  rows: 6,
  required: false,
  error: {}
}

/**
 * Text Area Component
 * @param props
 */
function TextArea (props) {
  const {
    dataId, theme, label, id, name, placeholder, value, error: { isError, message },
    rows, maxLength, onChange, onBlur, required
  } = props

  const baseClassName = getClassName('text-area', [
    { condition: theme, trueClassName: `text-area--${theme}` },
    { condition: isError, trueClassName: 'text-area--error' }
  ])

  return (
    <div data-id={dataId} className={baseClassName}>
      {label && <label className='text-area_label'>
        {label} {!!required && <span className='text-area_label--required'>(required)</span>}
      </label>}
      <textarea
        className='text-area_field'
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        rows={rows}
        maxLength={maxLength}
        onChange={onChange}
        onBlur={onBlur}
      />

      {isError && <Error message={message} />}
    </div>
  )
}

TextArea.propTypes = propTypes
TextArea.defaultProps = defaultProps

export default memo(TextArea)
