import React from 'react'
import { Toolbar, ToolbarRow } from '../../../../../components'
import { SectionSearch } from '../../../../../components/shared'

const ActivityToolbar = ({ handleFilterChange, handleExport, toggleSidebar, noData }) => {
  const handleSearchChange = (search) => handleFilterChange({ search, page: 1 })

  return (
    <Toolbar>
      <ToolbarRow justified>
        <div className='toolbar_group'>
          <button className='button button--steel' onClick={toggleSidebar}>
            <i className='button_icon q4i-funnel-filter-4pt' /> Filter
          </button>
        </div>

        <div className='toolbar_group'>
          <SectionSearch onQueryChange={handleSearchChange} onClear={handleSearchChange} />

          <button className={`button button--steel ${noData ? 'button--disabled' : ''}`} onClick={handleExport}>
            <i className='button_icon q4i-download-4pt' /> Export
          </button>
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

export default ActivityToolbar
