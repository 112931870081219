import React from 'react'
import PropTypes from 'prop-types'
import renderHTML from 'react-render-html'

import './renderHTML.component.css'

const RenderHTML = (props) => {
  const { theme, html } = props

  return (
    <div className={`render-html render-html--${theme}`}>
      {renderHTML(html)}
    </div>
  )
}

RenderHTML.propTypes = {
  html: PropTypes.string.isRequired
}

export default RenderHTML
