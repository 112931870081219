import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Button, PopoverMenu } from '../../components'
import { THEMES } from '../../utils'

/**
 * UtilityMenu Component
 */
class UtilityMenu extends PureComponent {
  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      utility: null
    }
  }

  /**
   * Handle utility menu click display menu
   * @param event
   */
  handleUtilityClick (event) {
    this.setState({ utility: event.currentTarget })
  }

  /**
   * Handle utility menu close click
   * @param event
   */
  handleUtilityClose (event) {
    const { hasButton, onClose } = this.props

    if (!hasButton) {
      this.setState({ utility: null })
    }

    onClose && onClose(event)
  }

  /**
   * Handle utility menu item click
   * @param event
   * @param data
   */
  handleUtilityItemClick (event, data) {
    const { onClick, ...rest } = data

    this.handleUtilityClose(event)
    onClick && onClick(rest)
  }

  /**
   * Render Utility Menu
   * @returns {XML}
   */
  render () {
    const { dataId, theme, hasButton, icon, items, anchor, anchorEl, transform } = this.props
    const { utility } = this.state
    const element = !hasButton ? utility : anchorEl

    return (
      <>
        {!hasButton && <Button
          dataId={dataId}
          type={`${!icon && 'utility'}`}
          theme={theme}
          icon={icon || 'q4i-utility-4pt'}
          onClick={this.handleUtilityClick.bind(this)}
        />}

        <PopoverMenu
          dataId={dataId}
          isMargin
          theme={theme}
          items={items}
          open={Boolean(element)}
          onClose={this.handleUtilityClose.bind(this)}
          onClick={(event, data) => this.handleUtilityItemClick(event, data)}
          anchorEl={element}
          anchor={anchor}
          transform={transform}
        />
      </>
    )
  }
}

UtilityMenu.propTypes = {
  /**
   * Used for tracking purposes
   */
  dataId: PropTypes.string,

  /**
   * Used to apply custom styles to Popover Component
   */
  theme: PropTypes.oneOf([THEMES.CITRUS, THEMES.RAIN, THEMES.LIGHT, THEMES.LIGHT_GREY]),

  /**
   * Utility button icon (if different from default)
   */
  icon: PropTypes.string,

  /**
   * Used to display items in PopoverMenu
   */
  items: PropTypes.arrayOf(PropTypes.shape({
    /**
     * Used to provide type of menu item
     */
    type: PropTypes.oneOf(['expandable']),

    /**
     * Used to provide icon for menu item
     */
    icon: PropTypes.string,

    /**
     * Used to provide label for menu item
     */
    label: PropTypes.string,

    /**
     * Used to add onClick event to menu item
     */
    onClick: PropTypes.func,

    /**
     * Used to provide content component to expandable menu item
     */
    content: PropTypes.func
  })),

  /**
   * This is the point on the anchor where the popover's anchorEl will attach to
   */
  anchor: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string
  }),

  /**
   * This is the point on the popover which will attach to the anchor's origin
   */
  transform: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string
  }),

  /**
   * Used to identify if utility has its own button
   */
  hasButton: PropTypes.bool,

  /**
   * Used when utility has its own button
   */
  onClose: PropTypes.func
}

UtilityMenu.defaultProps = {
  hasButton: false,
  theme: THEMES.CITRUS,
  anchor: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transform: {
    vertical: 'top',
    horizontal: 'right'
  }
}

export default UtilityMenu
