import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getActiveTicker } from '../../../utils'
import { getAvailablePeers } from '../../../utils/report'
import {
  loadPeers,
  clearPeers,
  removePeer,
  searchPeers,
  addPeer,
  resetAutoCompletePeers
} from '../../../actions/report'
import { ReportPeers } from '../../../components/report/config'
import './peerConfig.container.css'

/**
 * Report Peer Configuration Container
 */
class ReportPeerConfigContainer extends PureComponent {

  componentDidMount () {
    this.fetchPeers()
  }

  fetchPeers = () => {
    const { loadPeers, resetAutoCompletePeers } = this.props

    const peerParams = {
      select: ['symbol', 'exchange', 'security_name'],
      sortField: 'Symbol',
      sortOrder: 1,
      limit: 20,
      excludeMyCompany: true
    }

    loadPeers(peerParams)
    resetAutoCompletePeers()
  }

  searchPeers = (searchValue) => {
    const { searchPeers, resetAutoCompletePeers } = this.props

    if (!searchValue) {
      resetAutoCompletePeers()
    } else {
      const params = {
        limit: 5,
        type: 'security',
        query: searchValue
      }
      searchPeers(params)
    }
  }

  handlePeerReset = () => {
    this.fetchPeers()
  }

  handlePeerClear = () => {
    this.props.clearPeers()
  }

  handlePeerRemove = (securityId) => {
    this.props.removePeer(securityId)
  }

  handleSearchQueryChange = (searchValue) => {
    this.searchPeers(searchValue)
  }

  handleSearchClear = () => {
    this.searchPeers()
  }

  handleSearchSelect = (selectedPeers) => {
    const { addPeer } = this.props
    const peers = [].concat(selectedPeers || []).map((peer) => {
      const { mongo_id, symbol, exchange, security_name } = peer
      return {
        _security: mongo_id,
        symbol,
        exchange,
        security_name
      }
    })

    addPeer(peers)
  }

  /**
   * Render Report Peer Config Container
   * @return {XML}
   */
  render () {
    const { peers, foundPeers, ticker } = this.props

    return (
      <div className='report-peer-config'>
        <ReportPeers
          isDetailed
          peers={peers}
          availablePeers={getAvailablePeers(foundPeers, peers, ticker)}
          onSearchQueryChange={this.handleSearchQueryChange}
          onSearchQueryClear={this.handleSearchClear}
          onSearchPeerSelect={this.handleSearchSelect}
          onRemovePeer={this.handlePeerRemove}
          onResetPeers={this.handlePeerReset}
          onAddDefaultPeers={this.handlePeerReset}
          onClearPeers={this.handlePeerClear}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const peerConfig = state.report.reportPeerConfig
  const profileData = state.profile && state.profile.data
  const ticker = getActiveTicker(profileData)

  return {
    peers: peerConfig.peers,
    foundPeers: peerConfig.foundPeers,
    ticker
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadPeers: bindActionCreators(loadPeers, dispatch),
  clearPeers: bindActionCreators(clearPeers, dispatch),
  removePeer: bindActionCreators(removePeer, dispatch),
  searchPeers: bindActionCreators(searchPeers, dispatch),
  addPeer: bindActionCreators(addPeer, dispatch),
  resetAutoCompletePeers: bindActionCreators(resetAutoCompletePeers, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportPeerConfigContainer))
