// Deprecate all that's below
/**
 * @deprecated
 * @type {string}
 */
export const TARGETING_URL = '/targeting'
export const TARGETING_TABS = {
  ALL: 'all',
  SAVED: 'saved'
}

/**
 * @deprecated
 * @type {string}
 */
export const allGridBulkActions = {
  ADD_TO_SAVED_TARGETS: 'all/ADD_TO_SAVED_TARGETS',
  ADD_TO_BRIEFING_BOOK: 'all/ADD_TO_BRIEFING_BOOK'
}

/**
 * @deprecated
 * @type {string}
 */
export const savedGridBulkActions = {
  DELETE_TARGETS: 'saved/DELETE_TARGETS',
  ADD_TO_ACTIVITY: 'saved/ADD_TO_ACTIVITY',
  ADD_TO_BRIEFING_BOOK: 'saved/ADD_TO_BRIEFING_BOOK'
}

/**
 * @deprecated
 * @type {string}
 */
export const SUBSCRIPTION = {
  AI_TARGETING: 'ai_targeting',
}

/**
 * @deprecated
 * @type {string}
 */
export const TARGETING_PAGE_DEFAULT = {
  TITLE: 'Targeting',
  SUBSCRIPTION_TITLE: 'AI Targeting',
  SUBSCRIPTION: SUBSCRIPTION.AI_TARGETING,
  BANNER_ICON: 'q4i-targeting-2pt',
}

/**
 * @deprecated
 * @type {string}
 */
export const TARGETING_PAGE_QUERY = {
  ACTIVE_TAB: 'activeTab',
}

/**
 * @deprecated
 * @type {string}
 */
export const TARGETING_CLASSNAME = {
  BASE: 'targeting-page',
}

/**
 * @deprecated
 * TODO: REMOVE
 * This makes no sense. Are we creating individual types for each component now?
 */
export const FLYOUT_ENTITY_TYPES = {
  INSTITUTION: 'institution',
  FUND: 'fund',
  CONTACT: 'contact'
}
