import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// components
import { RenderHTML } from '../../../../index'

// utils
import { formatDateByTimezone, isSameDate, ACTIVITY_TYPE, DEFAULT_DATE_FORMAT, formatLocalizedDate } from '../../../../../utils'
import { get } from 'lodash'

import './activity.component.scss'

const { COMMENT, PHONE, EMAIL, MEETING, EARNINGS, OTHER } = ACTIVITY_TYPE

const propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.oneOf([COMMENT, PHONE, EMAIL, MEETING, EARNINGS, OTHER]).isRequired,
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
    links: PropTypes.array,
    participants: PropTypes.array,
    virtual: PropTypes.bool,
    tag: PropTypes.array,
    body: PropTypes.string
  }),
  isNotes: PropTypes.bool,
  isSection: PropTypes.bool
}

const defaultProps = {
  activity: {}
}

/**
 * Activity Header
 * @param props
 */
const Header = ({ category, title, parent }) => {
  const icon = {
    comment: 'q4i-note-4pt',
    phone: 'q4i-phone-4pt',
    email: 'q4i-mail-4pt',
    meeting: 'q4i-meeting-4pt',
    earnings: 'q4i-earnings-4pt',
    other: 'q4i-report-blank-4pt'
  }[category]

  return (
    <div className='tearsheet_activity_content tearsheet_activity_header'>
      <i className={`tearsheet_activity-category ${icon}`} />
      <div className='tearsheet_activity-title'>
        {parent ? `${parent}: ${title}` : title}
      </div>
    </div>
  )
}

/**
 * Activity Date
 * @param props
 */
const ActivityDate = ({ start, end }) => {
  const startDate = start && start.date ? new Date(Number(start.date)) : null
  const endDate = end && end.date ? new Date(Number(end.date)) : null
  const startTimezone = (start && start.timezone) || null
  const endTimezone = (end && end.timezone) || null
  const dateFormat = 'MMMM D, YYYY'
  const timeFormat = 'h:mma'

  return (
    <div className='tearsheet_activity_content'>
      {startDate && isSameDate(startDate, endDate, DEFAULT_DATE_FORMAT)
        ? <>
          <span className='tearsheet_activity-date'>
            {formatLocalizedDate(formatDateByTimezone(startDate, dateFormat, startTimezone), dateFormat)}
          </span>
          <span className='tearsheet_activity-time'>
            {formatDateByTimezone(startDate, timeFormat, startTimezone)} - {formatDateByTimezone(endDate, timeFormat, startTimezone)}
          </span>
        </>
        : <>
          <span className='tearsheet_activity-date tearsheet_activity-date--double-spaced'>
            {formatLocalizedDate(formatDateByTimezone(startDate, dateFormat, startTimezone), dateFormat)} {formatDateByTimezone(startDate, timeFormat, startTimezone)}
          </span>
          <span className='tearsheet_activity-date'>
            {formatLocalizedDate(formatDateByTimezone(endDate, dateFormat, endTimezone), dateFormat)} {formatDateByTimezone(endDate, timeFormat, startTimezone)}
          </span>
        </>}
    </div>
  )
}

/**
 * Activity Attendees
 * @param props
 */
const Attendees = ({ attendees }) => {
  const limit = 9

  return (
    <Grid item xs={5} className='tearsheet_activity_content tearsheet_activity_content--top-aligned'>
      <div className='tearsheet_activity-label'>Attendees:</div>
      {(attendees && attendees.length)
        ? <div className='tearsheet_activity-attendees'>
          {(attendees || []).map((attendee, index) => {
            const { entityId, entityName, entityType } = attendee
            const type = entityType && entityType.toLowerCase()
            return (index < limit) &&
              <div key={`${entityId}-${index}`} className='tearsheet_activity-attendee'>
                <i className={`tearsheet_activity-attendee-icon tearsheet_activity-attendee-icon--${type} q4i-${type}-4pt`} />
                <div className='tearsheet_activity-attendee-name'>{entityName}</div>
              </div>
          })}
          {(attendees.length > limit) &&
          <div className='tearsheet_activity-more'>+{attendees.length - limit} more</div>}
        </div>
        : <div className='tearsheet_activity_content'>No attendees available</div>}
    </Grid>
  )
}

/**
 * Corporate Participants
 * @param props
 */
const Participants = ({ participants }) => {
  const limit = 99

  return (
    <Grid item xs={7} className='tearsheet_activity_content tearsheet_activity_content--top-aligned'>
      <div className='tearsheet_activity-label'>Corporate Participants:</div>
      {(participants && participants.length)
        ? <div className='tearsheet_activity-participants'>
          {(participants || []).map((participant, index) => {
            const { participantId } = (participant || {})
            const entityName = get(participant, 'corporateParticipantConnection.items[0].fullName', '')

            return (index < limit) &&
              <div key={`${participantId}-${index}`} className='tearsheet_activity-participant'>
                <div className='tearsheet_activity-participant-name'>{entityName}</div>
              </div>
          })}
          {(participants.length > limit) &&
          <div className='tearsheet_activity-more'>+{participants.length - limit} more</div>}
        </div>
        : <div className='tearsheet_activity_content'>No participants available</div>}
    </Grid>
  )
}

/**
 * Activity Tags
 * @param props
 */
const Tags = ({ tags }) => {
  return (
    <Grid item xs={12} className='tearsheet_activity_content tearsheet_activity_content--top-aligned'>
      <div className='tearsheet_activity-label'>Tags:</div>
      <div className='tearsheet_activity-tags'>
        {(tags || []).map((tag, index) => (
          <span key={index} className='tearsheet_activity-tag'>
            #{tag}{(index < tags.length - 1) ? ', ' : ''}
          </span>
        ))}
      </div>
    </Grid>
  )
}

/**
 * Activity Component
 * @param props
 */
function ActivityComponent ({ activity, isNotes, isSection }) {
  const { category, title, start, end, links, participants, tag, body, _activity } = activity
  const parent = get(_activity, 'activityConnection.items[0].title')

  return (
    <Grid container spacing={2} className='tearsheet_activity'>
      {isSection && <Grid item xs={12} className='tearsheet_section-title'>Recent Activity</Grid>}
      <Grid item xs={12}>
        <Header category={category} title={title} parent={parent} />
        <ActivityDate start={start} end={end} />
      </Grid>
      <Attendees attendees={links} />
      <Participants participants={participants} />
      {tag && tag.length ? <Tags tags={tag} /> : null}
      {isNotes && (body && body.length) ? <Grid item xs={12} className='tearsheet_section-content-editor'>
        <div className='tearsheet_activity-label'>Note:</div>
        <RenderHTML html={body} />
      </Grid> : null}
    </Grid>
  )
}

ActivityComponent.propTypes = propTypes
ActivityComponent.defaultProps = defaultProps

export default memo(ActivityComponent)
