import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AutoSuggest } from '../..'
import { debounce, THEMES } from '../../../utils'
import { get } from 'lodash'
import { useQuickSearchQuery } from '../hook'
import qs from 'query-string'

import './search.component.css'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../../services/launchDarkly.service'

const initialState = {
  searchValue: '',
  currentValue: '',
  isSuggestionSelected: false
}

/**
 * Quick Search Component
 * @param props
 */
function QuickSearch (props) {
  const [searchState, setSearchState] = useState(initialState)
  const [suggestionResults, setSuggestionResults] = useState([])
  const [handleQuickSearch, { loading: suggestionsLoading, data: quickSearchResults }] = useQuickSearchQuery()

  useEffect(() => {
    const { searchValue } = searchState
    const { history } = props
    const queryParams = qs.parse(get(history, 'location.search'))

    if (queryParams.query && queryParams.query !== searchValue) {
      setSearchState((previousState) => ({
        ...previousState,
        searchValue: queryParams.query,
        currentValue: queryParams.query || ''
      }))
    }
  }, [])

  useEffect(() => {
    setSearchState((previousState) => ({
      ...previousState,
      isSuggestionSelected: false
    }))
  }, [searchState.searchValue])

  useEffect(() => {
    const { location } = props
    const queryParams = qs.parse(get(location, 'search'))
    setSearchState((previousState) => ({
      ...previousState,
      searchValue: queryParams.query,
      currentValue: queryParams.query || ''
    }))
  }, [props.location])

  useEffect(() => {
    setSuggestionResults(quickSearchResults?.quickSearch?.items || [])
  }, [quickSearchResults])

  useEffect(() => {
    return () => {
      getSuggestions.cancel() // reset debounce
    }
  }, [])

  /**
   * Fires API call to SwiftType
   * @param value
   */
  const getSuggestions = debounce((value) => {
    const query = value && value.trim()
    if (query === '' || value === searchState.currentValue) {
      clearSuggestions()
      return []
    }
    handleQuickSearch({ variables: { 
      query,
      useElasticSearch: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)
    } })
  }, 600)

  /**
   * Get input value when suggestion is selected
   * @param suggestion
   * @returns {*|string}
   */
  const getSuggestionValue = (suggestion) => {
    const value = (suggestion && suggestion.name) || ''

    setCurrentValue(value)
    return value
  }

  /**
   * Render suggestion item
   * @param suggestion
   * @returns {*}
   */
  const renderSuggestion = (suggestion) => {
    const { type, name, institution, country } = suggestion
    let title = ''
    let company = ''

    switch (type) {
      case 'security':
        return (
          <div className='link'>
            <i className={`link-icon link-icon--${type} q4i-${type}-4pt`} />
            <span className='link-name'>{suggestion.symbol} {suggestion.exchange}</span>
            <span className='link-detail'>{name}</span>
          </div>
        )
      case 'institution':
        return (
          <div className='link'>
            <i className={`link-icon link-icon--${type} q4i-${type}-4pt`} />
            <span className='link-name'>{name}</span>
            <span className='link-detail'>{country}</span>
          </div>
        )
      case 'contact':
        title = suggestion.title?.length && suggestion.title[0] ? `${suggestion.title[0]} at ` : ''
        company = institution?.length && institution[0] ? institution[0] : ''
        return (
          <div className='link'>
            <i className={`link-icon link-icon--${type} ${suggestion.source === 'q4desktop' ? 'q4i-custom-4pt' : `q4i-${type}-4pt`}`} />
            <span className='link-name'>{name} </span>
            <div className='link-detail'>
              <span>{`${title}${company}`}</span>
            </div>
          </div>
        )
      case 'fund':
        return (
          <div className='link'>
            <i className={`link-icon link-icon--${type} q4i-${type}-4pt`} />
            <span className='link-name'>{name}</span>
            <span className='link-detail'>{institution} • {country}</span>
          </div>
        )

      default:
        return null
    }
  }

  /**
   * Handle cases where debounce may cause suggestions to apear when user has already completed search
   */
  const getSuggestionData = () => {
    const { query } = qs.parse(get(props.location, 'search'))
    if (query === searchState.searchValue) {
      return []
    } else {
      return suggestionResults
    }
  }

  /**
   * Triggers on value change
   * @param event
   * @param newValue
   */
  const onChange = (_event, { newValue }) => {
    if (!newValue || !newValue.length) {
      props.clearSearch()
    }

    setSearchState((previousState) => ({
      ...previousState,
      searchValue: newValue
    }))
  }

  /**
   * Get the suggestion's url according to the entity type
   * @param suggestion
   */
  const getSuggestionUrl = (suggestion) => {
    const { type, entityId: q4EntityId, id } = suggestion
    const link = `/${type}/`

    switch (type) {
      case 'institution':
      case 'fund':
        return link + q4EntityId
      case 'contact':
      case 'security':
      default:
        return link + id
    }
  }

  /**
   * Sends the user to chosen link and clears input field
   * @param event
   * @param suggestion
   */
  const onSuggestionSelected = (_event, { suggestion, method }) => {
    const { history } = props
    const { name } = suggestion
    const action = (method === 'click') ? history.replace : history.push

    setSearchState((previousState) => ({
      ...previousState,
      isSuggestionSelected: true
    }))

    action({
      pathname: getSuggestionUrl(suggestion),
      search: `?query=${encodeURIComponent(name)}`
    })
  }

  /**
   * On Enter press
   * @param event
   * @param value
   */
  const onKeyUp = (event, value) => {
    const { isSuggestionSelected } = searchState
    const keyCode = (event.which) ? event.which : event.keyCode

    if (!isSuggestionSelected && keyCode === 13 && (value && value.length)) {
      advancedSearch(value)
    }
  }

  /**
   * On Show More button click
   */
  const onShowMore = () => {
    const { searchValue } = searchState
    advancedSearch(searchValue)
  }

  /**
   * Handles redirect to advanced search
   * @param value
   */
  const advancedSearch = (value) => {
    const { history } = props

    history.push(`/search?query=${encodeURIComponent(value)}`)
    setCurrentValue(value)
    clearSuggestions()
  }

  /**
   * Clears returned SwiftType results
   */
  const clearSuggestions = () => {
    const { clearSearch } = props
    clearSearch()
    setSuggestionResults([])
  }

  /**
   * Clear search input
   */
  const clearInputValue = () => {
    setSearchState((previousState) => ({
      ...previousState,
      searchValue: ''
    }))
  }

  /**
   * Set current input value
   * @param value
   */
  const setCurrentValue = (value) => {
    setSearchState((previousState) => ({
      ...previousState,
      currentValue: value
    }))
  }

  const { searchValue: searchInputValue } = searchState
  const actions = [{
    theme: THEMES.SOFT_GREY,
    key: 'search-action',
    icon: 'q4i-filter-4pt',
    label: 'Show More Results',
    onClick: onShowMore
  }]

  const suggestions = getSuggestionData()

  return (
    <AutoSuggest
      theme={THEMES.INK}
      loading={suggestionsLoading}
      value={searchInputValue}
      clearable
      suggestions={suggestions}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestions={getSuggestions}
      clearSuggestions={clearSuggestions}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      suggestionHeight={460}
      suggestionLimit={10}
      actionItemHeight={60}
      actions={actions}
      onClear={clearInputValue}
      onChange={onChange}
      onKeyUp={(e) => onKeyUp(e, searchInputValue)}
    />
  )
}

QuickSearch.propTypes = {
  loading: PropTypes.bool,
  suggestions: PropTypes.array.isRequired,
  getQuickSearch: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

QuickSearch.defaultProps = {
  loading: false,
  suggestions: []
}

export default QuickSearch
