import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createToast } from '../../actions'
import {
  storeReportDataItem,
  storeReportBuilderItem,
  saveReportDataItem
} from '../../actions/report'
import {
  getFlattenedData,
  getFormattedChartOptions,
  getFormattedFilters,
  getFormattedIndices,
  getFormattedSort
} from '../../utils/report'
import { getActiveTicker } from '../../utils'
import { Banner, Message, PopoverMenu } from '../../components'
import { SectionTab } from '../../components/shared'
import ReportBuilderListContainer from './builderList/reportBuilderList.container.js'
import ReportDataListContainer from './dataList/reportDataList.container.js'
import ReportWizardModal from './wizard/reportWizard.container.js'
import ReportDataEditor from './dataEditor/reportDataEditor.container'
import './report.container.css'

class ReportContainer extends PureComponent {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      activeTab: 'reports',
      reportWizardType: 'report',
      isPopoverOpen: false,
      isReportWizardModalOpen: false,
      isReportDetailsModalOpen: false,
      isMessageOpen: false,
      isSaving: false,

      reportDataItemId: null
    }

    this.bannerButton = React.createRef()
  }

  /**
   * Handle opening of Settings Popover
   */
  handlePopoverOpen = () => {
    this.setState({
      isPopoverOpen: true
    })
  }

  /**
   * Handle closing of Generate button Popover
   */
  handlePopoverClose = () => {
    this.setState({
      isPopoverOpen: false
    })
  }

  /**
   * Handle Popover Menu Select
   * @param event
   * @param item
   */
  handlePopoverMenuSelect = (event, item) => {
    if (!item || !item.type) {
      return
    }

    if (item.type === 'report') {
      this.handleCreateNewReport()
    } else {
      this.handleModalOpen(item.type)
    }

    this.handlePopoverClose()
  }

  /**
   * Handle Create New Report
   */
  handleCreateNewReport = () => {
    const { history, storeReportBuilderItem } = this.props

    storeReportBuilderItem({
      title: 'Blank Report'
    })
    history.push('/builder/new')
  }

  /**
   * Handle opening of the report wizard modal
   * @param reportWizardType
   */
  handleModalOpen = (reportWizardType) => {
    this.setState({
      isReportWizardModalOpen: true,
      reportWizardType
    })
  }

  /**
   * Handle closing of the report wizard modal
   */
  handleModalClose = () => {
    const { isReportWizardModalOpen } = this.state

    if (isReportWizardModalOpen) {
      this.setState({
        isReportWizardModalOpen: false
      })
    }
  }

  /**
   * Handle addition of new Report using preconfigured data
   * @param type
   * @param template
   */
  handleReportWizardCreate = (type, template) => {
    if (!template) {
      return
    }

    const { history, storeReportBuilderItem, storeReportDataItem } = this.props

    if (type === 'report') {
      storeReportBuilderItem(template)
      history.push('/builder/new')
    } else {
      const { title, _entityType, filters, fields, limit, sort, peers, indices, isPivot, chartOptions, pivotFields, widgetType, showTitle, isLocked } = template

      const reportDataItem = {
        title,
        _entityType,
        fields,
        filters,
        limit,
        sort,
        peers: getFlattenedData(peers || [], '_security'),
        indices,
        isPivot,
        pivotFields,
        widgetType,
        showTitle,
        chartOptions,
        isLocked
      }

      storeReportDataItem(reportDataItem)

      this.setState({
        isReportDetailsModalOpen: true,
        reportDataItemId: null
      })
    }
  }

  /**
   * Handle report details close
   */
  handleReportDataEditorClose = () => {
    this.setState({
      isReportDetailsModalOpen: false,
      reportDataItemId: null
    })
  }

  /**
   * Handle data item click
   * @param reportDataItemId
   */
  handleDataItemClick = (reportDataItemId) => {
    this.setState({
      isReportDetailsModalOpen: true,
      reportDataItemId
    })
  }

  /**
   * Save report data item
   * @param item
   * @param shouldCloseModal
   */
  handleReportDataItemSave = (item, shouldCloseModal) => {
    if (!item || !item.snapshot || !item.data) {
      return
    }

    this.setState({
      isSaving: true
    })

    const { saveReportDataItem, createToast } = this.props
    const { widgetType, snapshot, chartOptions, showTitle } = item
    const { _entityType, fields, pivotFields, filters, sort, limit, peers, indices, title, isPivot, isLocked } = snapshot || {}
    const { reportDataItemId } = this.state

    const body = {
      _entityType: getFlattenedData(_entityType),
      fields: getFlattenedData(fields),
      pivotFields: getFlattenedData(pivotFields),
      filters: getFormattedFilters(filters),
      sort: getFormattedSort(sort, fields),
      peers: getFlattenedData(peers || [], '_security'),
      indices: getFormattedIndices(indices),
      limit,
      chartOptions: getFormattedChartOptions(chartOptions),
      showTitle,
      widgetType,
      isPivot,
      isLocked
    }

    const reportId = reportDataItemId || item._id

    if (reportId) {
      body._id = reportId
    }

    const params = this.getSnapshotParams()

    saveReportDataItem(Object.assign({}, body, { title }), reportId, params).then((resp) => {
      const _reportDataItem = typeof resp.payload === 'object' && resp.payload
      const newState = {
        isSaving: false
      }

      if (!_reportDataItem) {
        Object.assign(newState, {
          isMessageOpen: true,
          _messageScope: 'error'
        })
      } else {
        createToast({ text: `${_reportDataItem.title || 'The report'} has been successfully saved.` })
      }

      if (shouldCloseModal) {
        Object.assign(newState, {
          isReportDetailsModalOpen: false
        })
      }

      this.setState(newState)
    })
  }

  /**
   * Get Snapshot request query params
   * @param options
   */
  getSnapshotParams = (options) => {
    const { securityId } = this.props

    return {
      securityId,
      ...options
    }
  }

  /**
   * Handle Section Tab change
   * @param tab
   */
  handleTabChange = (tab) => {
    const { activeTab } = this.state

    if (activeTab !== tab.id) {
      this.setState({
        activeTab: tab.id
      })
    }
  }

  /**
   * Handle closing of the Message modal
   */
  handleMessageClose = () => {
    this.setState({
      isMessageOpen: false
    })
  }

  /**
   * Get props for Message component based on scope
   * @param scope
   * @returns {*}
   */
  getMessageProps = (scope) => {
    switch (scope) {
      case 'error':
        return {
          type: 'error',
          title: 'Error',
          message: 'There was an issue when trying to save this Source Data. Please try again or contact us if you see this message again.',
          buttons: [{
            ui: 'shaded',
            label: 'close',
            onClick: this.handleMessageClose
          }]
        }
      default:
        return {}
    }
  }

  /**
   * Render Report List
   * @returns {XML}
   */
  render () {
    const { reportBuilderListTotal, reportDataListTotal } = this.props
    const { activeTab, reportWizardType, isPopoverOpen, isReportWizardModalOpen, isReportDetailsModalOpen, reportDataItemId, isMessageOpen, _messageScope, isSaving } = this.state
    const messageProps = this.getMessageProps(_messageScope)

    return (
      <div className='report-page q4-fade-in'>
        <Banner
          title='Reports'
          icon='q4i-reports-2pt'
          controls={[{
            reference: this.bannerButton,
            type: 'button',
            theme: 'citrus',
            icon: 'q4i-add-4pt',
            onClick: this.handlePopoverOpen
          }]}
        />

        <SectionTab
          tabs={[{
            id: 'reports',
            label: `All Reports ${reportBuilderListTotal ? `(${reportBuilderListTotal})` : ''}`,
            view: (
              <ReportBuilderListContainer
                onReportAdd={this.handleCreateNewReport}
              />
            )
          }, {
            id: 'data-sets',
            label: `Source Data ${reportDataListTotal ? `(${reportDataListTotal})` : ''}`,
            view: (
              <ReportDataListContainer
                onReportAdd={() => this.handleModalOpen('data')}
                onListItemClick={this.handleDataItemClick}
              />
            )
          }]}
          forceActiveTab={activeTab}
          onTabChange={this.handleTabChange}
        />

        <PopoverMenu
          open={isPopoverOpen}
          theme='citrus'
          isMargin
          items={[
            { icon: 'q4i-reports-4pt', label: 'Create New Report', type: 'report' },
            { icon: 'q4i-chart-4pt', label: 'Create Source Data', type: 'data' }
          ]}
          anchorEl={this.bannerButton && this.bannerButton.current}
          onClick={this.handlePopoverMenuSelect}
          onClose={this.handlePopoverClose}
        />

        <ReportWizardModal
          visible={isReportWizardModalOpen}
          type={reportWizardType}
          onCreate={this.handleReportWizardCreate}
          onClose={this.handleModalClose}
        />

        {isReportDetailsModalOpen && (
          <ReportDataEditor
            reportId={reportDataItemId}
            saveMode='api'
            onModalClose={this.handleReportDataEditorClose}
            onItemSave={this.handleReportDataItemSave}
            isSaving={isSaving}
          />
        )}

        <Message
          visible={isMessageOpen}
          type={messageProps.type}
          title={messageProps.title}
          message={messageProps.message}
          buttons={messageProps.buttons}
          onClose={this.handleMessageClose}
        />

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { report, profile } = state
  const { reportDataList, reportBuilderList } = report
  const profileData = profile && profile.data
  const ticker = getActiveTicker(profileData)

  return {
    securityId: ticker && ticker._security,
    reportDataListTotal: reportDataList.meta && reportDataList.meta.total,
    reportBuilderListTotal: reportBuilderList.meta && reportBuilderList.meta.total
  }
}

const mapDispatchToProps = (dispatch) => ({
  storeReportDataItem: bindActionCreators(storeReportDataItem, dispatch),
  storeReportBuilderItem: bindActionCreators(storeReportBuilderItem, dispatch),
  saveReportDataItem: bindActionCreators(saveReportDataItem, dispatch),
  createToast: bindActionCreators(createToast, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportContainer))
