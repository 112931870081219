import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { getDefaultColumnDef, THEMES } from '../../../../utils'
import { AgGrid, Spinner } from '../../../../components'
import CustomCell from './cell/cell.component'

import { statusType } from '../../../../actions'

/**
 * Managed Fund Component
 */
class ManagedFundGrid extends PureComponent {
  /**
   * constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      columns: [
        { ...getDefaultColumnDef({ columnIndex: 0 }), field: 'fund_name', headerName: 'Name', minWidth: 360 },
        { ...getDefaultColumnDef({ columnIndex: 1, type: 'number' }), field: 'current_position', headerName: 'POS', minWidth: 130, maxWidth: 130 },
        { ...getDefaultColumnDef({ columnIndex: 2, type: 'number' }), field: 'portfolio_value', headerName: 'AUM ($MM)', minWidth: 130, maxWidth: 130, sort: 'desc' },
        { ...getDefaultColumnDef({ columnIndex: 3, type: 'centered' }), field: 'style', headerName: 'Style', minWidth: 130, maxWidth: 130 },
        { ...getDefaultColumnDef({ columnIndex: 4, type: 'centered' }), field: 'turnover', headerName: 'Turnover', minWidth: 130, maxWidth: 130, sortable: false },
        { ...getDefaultColumnDef({ columnIndex: 5, type: 'date' }), type: 'date', field: 'report_date', headerName: 'Report Date', minWidth: 130, maxWidth: 130 }
      ],
      filters: {
        limit: 10,
        page: 1
      }
    }
  }

  /**
   * Renders CustomCell Component
   * @param props
   */
  renderCustomCell = (props) => {
    return <CustomCell {...props} />
  }

  /**
   * On Row Click
   * @param rowData
   */
  onRowClick = (rowData) => {
    const { history } = this.props
    const id = rowData && rowData.data && rowData.data.factset_fund_id

    this.props.handleRowClick
      ? this.props.handleRowClick({ entityId: id })
      : history.push(`/fund/${id}`)
  }

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  handleGridReady = (grid) => {
    setTimeout(() => this.agGrid = grid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => this.agGrid && this.agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Get managed funds
   */
  getData = () => {
    const { params, getManagedFunds } = this.props
    getManagedFunds({ ...params, ...this.state.filters })
  }

  /**
   * Handle onSortChanged event (multi-sort included)
   * @param grid
   */
  handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel && !sortModel.length) {
      return
    }

    this.setState({
      filters: {
        ...this.state.filters,
        sort: JSON.stringify([{
          property: sortModel[0].colId,
          direction: sortModel[0].sort
        }]),
        page: 1
      }
    }, () => this.getData())
  }

  /**
   * Handle Page Change event
   * @param page
   */
  handlePageChange = (page) => {
    this.setState({
      filters: { ...this.state.filters, ...page }
    }, () => this.getData())
  }

  /**
   * Returns grid columns
   */
  getColumnsDefinition = () => {
    return this.state.columns.map(({ type, ...column }, idx) => ({
      ...getDefaultColumnDef({ columnIndex: idx, type }),
      ...column
    }))
  }

  /**
   * Render
   * @returns {*}
   */
  render () {
    const { filters: { limit, page } } = this.state
    const { funds } = this.props
    const { status, data, total } = funds

    return (
      <section className='contact-detail-grid'>
        { (status === statusType.IN_PROGRESS) && <Spinner theme={THEMES.RAIN} mask /> }
        <AgGrid
          className='contact-fund-grid'
          domLayout='autoHeight'
          sizeToFit

          // suppress configs
          suppressMovableColumns
          suppressContextMenu

          // columns and data
          defaultColDef={{
            sortable: true,
            suppressMenu: true,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={this.getColumnsDefinition()}
          rowData={data}

          // pagination
          pagination
          paginationProps={{
            forcePage: page,
            initialPageSize: limit,
            total: total || 0,
            onPageChange: ({ selected }) => this.handlePageChange({ page: selected })
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: this.renderCustomCell
          }}

          onRowClicked={this.onRowClick}
          onGridReady={this.handleGridReady}
          onGridSizeChanged={this.handleGridResize}
          onSortChanged={this.handleSortChange} />
      </section>
    )
  }
}

ManagedFundGrid.propTypes = {
  history: PropTypes.object,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
    securityId: PropTypes.string.isRequired
  }),
  funds: PropTypes.shape({
    status: PropTypes.string,
    data: PropTypes.array.isRequired,
    total: PropTypes.number
  }),
  getManagedFunds: PropTypes.func.isRequired
}

ManagedFundGrid.defaultProps = {
  params: {},
  funds: {}
}

export default ManagedFundGrid
