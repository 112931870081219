import React, { memo } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// components
import { RangeTab } from '../../shared'
import { Card, DatePicker } from '../../index'
import Index from './index/index.component'
import HistoricalStockChart from './chart/chart.component'

import './overview.component.scss'

const propTypes = {
  dataId: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    stock: PropTypes.array.isRequired,
    news: PropTypes.array,
    indices: PropTypes.array
  }).isRequired,
  range: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dates: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object
  }),
  onRangeChange: PropTypes.func.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onIndexAdd: PropTypes.func.isRequired,
  onIndexDelete: PropTypes.func.isRequired
}

const defaultProps = {
  isLoading: false,
  range: 3,
  data: {}
}

const RANGE = [
  { label: '1w', value: 'week', dataId: 'Week' },
  { label: '1m', value: 1, dataId: 'Month' },
  { label: '3m', value: 3, dataId: 'ThreeMonths' },
  { label: '6m', value: 6, dataId: 'SixMonths' },
  { label: '1y', value: 12, dataId: 'Year' },
  { label: 'All', value: 'all', dataId: 'All' }
]

/**
 * Security Stock Overview Card
 * @param props
 */
function SecurityStockOverviewCard (props) {
  const { dataId, theme, isLoading, data, range, dates, onRangeChange, onDatesChange, onIndexAdd, onIndexDelete } = props
  const { stock, news, indices } = data
  const { start, end } = (dates || {})

  return (
    <Card
      dataId={`${dataId}StockOverviewCard`}
      theme={theme}
      className='security-stock-overview'
      isLoading={isLoading}
      title='Overview'
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <RangeTab
            dataId={`${dataId}Range`}
            theme={theme}
            buttons={RANGE.map((option) => ({
              ...option,
              dataId: `${dataId}${option.dataId}`,
              active: range === option.value,
              onClick: (event, value) => onRangeChange(value)
            }))}
          />
        </Grid>
        <Grid item xs={6}>
          <div className='security-stock-overview_date-range'>
            <div className='security-stock-overview_date-range_label'>From</div>
            <DatePicker
              dataId={`${dataId}StartDate`}
              theme={theme}
              size='default'
              value={start}
              min={moment().subtract(5, 'years')}
              max={moment(end).subtract(5, 'days')}
              onChange={(value) => onDatesChange(value, 'start')}
            />
            <div className='security-stock-overview_date-range_label'>To</div>
            <DatePicker
              dataId={`${dataId}EndDate`}
              theme={theme}
              size='default'
              value={end}
              min={moment(start).add(5, 'days')}
              max={moment()}
              onChange={(value) => onDatesChange(value, 'end')}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <HistoricalStockChart
            stock={stock}
            news={news}
            indices={indices}
            height={280}
          />
        </Grid>

        <Grid item xs={9}>
          <Index
            dataId={`${dataId}StockOverviewChart`}
            indices={indices}
            onIndexAdd={onIndexAdd}
            onIndexDelete={onIndexDelete}
          />
        </Grid>

        <Grid item xs={3}>
          <div className='security-stock-overview_legend'>
            <div className='security-stock-overview_legend-item'>
              <div className='security-stock-overview_legend-circle security-stock-overview_legend-circle--stock' />
              <div className='security-stock-overview_legend-label'>Stock</div>
            </div>
            <div className='security-stock-overview_legend-item'>
              <div className='security-stock-overview_legend-circle security-stock-overview_legend-circle--news' />
              <div className='security-stock-overview_legend-label'>Press Releases</div>
            </div>
          </div>
        </Grid>

      </Grid>
    </Card>
  )
}

SecurityStockOverviewCard.propTypes = propTypes
SecurityStockOverviewCard.defaultProps = defaultProps

export default memo(SecurityStockOverviewCard)
