/**
 * Toast Action Type Constants
 * @type {string}
 */
export const CREATE_TOAST = 'CREATE_TOAST'
export const DELETE_TOAST = 'DELETE_TOAST'

/**
 * Create toast
 * @param message
 * @returns {{type: string, payload: *}}
 * @private
 */
export const _createToast = (message) => ({
  type: CREATE_TOAST,
  payload: message
})

/**
 * Delete toast
 * @param id
 */
const _deleteToast = (id) => ({
  type: DELETE_TOAST,
  payload: id
})

/**
 * Dispatch CREATE_TOAST
 * @param message
 */
export const createToast = (message, _testId) => (dispatch) => {
  message.id = new Date().getTime()
  return dispatch(_createToast(message))
}

/**
 * Dispatch DELETE_TOAST
 * @param id
 */
export const deleteToast = (id) => (dispatch) => {
  return dispatch(_deleteToast(id))
}
