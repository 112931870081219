import React from 'react'
import { Toolbar, ToolbarRow, Button } from '../../../../../components'
import { format } from '../../../../../utils'
import './toolbar.component.css'

const TargetingAllTabToolbar = ({ handleFilterChange, handleExport, toggleSidebar, noData, resultsTotalCount, collapsed }) => {
  // const handleSearchChange = (search) => handleFilterChange({search, page: 1});

  return (
    <Toolbar className='targeting-toolbar'>
      <ToolbarRow justified>
        <div className='toolbar_group'>
          <div className='targeting-toolbar_result'>
            <span className='targeting-toolbar_result-label'>Targets</span>
            <span className='targeting-toolbar_result-count'>{typeof (resultsTotalCount) !== 'undefined' && `(${format(resultsTotalCount)})`}</span>
          </div>
          <Button
            active={!collapsed}
            className='targeting-toolbar_filter-button'
            theme='steel'
            label='Filter'
            icon='q4i-funnel-filter-4pt'
            onClick={toggleSidebar}
          />
        </div>

        <div className='toolbar_group'>
          {/* <SectionSearch onQueryChange={handleSearchChange} onClear={handleSearchChange} /> */}
          <Button
            theme='steel'
            label='Export'
            icon='q4i-download-4pt'
            disabled={noData}
            onClick={handleExport}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

export default TargetingAllTabToolbar
