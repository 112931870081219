import React, {Component} from 'react';
import { Message, Modal } from '../index';
import {FormikTextArea} from '../shared/form';
import {withFormik} from 'formik';
import * as Yup from 'yup';
import './feedback.component.css';

/**
 * Feedback Component
 */
class _Feedback extends Component {

    /**
     * Close send request error modal
     */
    closeModal = () => {
        const {setStatus} = this.props;
        setStatus(null);
    };

    /**
     * Render Feedback Modal
     * @returns {*}
     */
    render() {
        const {visible, title, status, handleSubmit, isSubmitting, onClose} = this.props;

        return (
            <div className='feedback-modal'>
                <Modal visible={visible}
                       title={title || 'Suggest an Edit'}
                       contentHeight={161}
                       onClose={onClose}
                       footerButtons={[{
                           label: 'Cancel',
                           ui: 'shaded',
                           onClick: onClose
                       }, {
                           label: 'Submit',
                           ui: 'citrus',
                           disabled: isSubmitting,
                           onClick: handleSubmit
                       }]}>
                    <form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
                    <FormikTextArea
                        id='message'
                        placeholder='Message (required)'
                        {...this.props}/>
                    </form>
                </Modal>
                <Message visible={(status && !status.success)}
                         type='error'
                         title='Error'
                         message='Oops, something went wrong while trying to send this request. Please try again or contact us if you see this message again.'
                         onClose={this.closeModal}
                         buttons={[{
                             ui: 'spice',
                             label: 'close',
                             onClick: this.closeModal
                         }]}/>
            </div>
        );
    }
}

const Feedback = withFormik({
    validateOnChange: true,
    validateOnBlur: true,
    mapPropsToValues: () => ({
        message: ''
    }),
    validationSchema: Yup.object().shape({
        message: Yup.string()
            .trim()
            .required('You didn\'t provide any suggestions!')
    }),
    handleSubmit: (values, {props, setStatus, setSubmitting}) => {
        const {message} = values;
        const {sendMail, onClose, createToast} = props;

        sendMail({
            message,
            url: window.location.href,
            template: 'touch-feedback',
            subject: 'Touch Feedback',
            to: 'desktop@q4inc.com'
        }).then((response) => {

            switch (response.type) {
                case 'MAIL_SUCCESS':
                    setSubmitting(false);
                    setStatus({success: true});
                    createToast({text: 'Suggestion has been successfully sent.'});
                    onClose();
                    break;

                case 'MAIL_ERROR':
                    setSubmitting(false);
                    setStatus({success: false});
                    break;

                default:
                    break;
            }
        });
    },
    displayName: 'FeedbackForm', // helps with debugging (React DevTools)
})(_Feedback);

export default Feedback;
