import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

/**
 * Profile Action Type Constants
 * @type {string}
 */
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE'
export const SET_ACTIVE_TICKER = 'SET_ACTIVE_TICKER'
export const ACKNOWLEDGE_TERMS_REQUEST = 'ACKNOWLEDGE_TERMS_REQUEST'
export const ACKNOWLEDGE_TERMS_SUCCESS = 'ACKNOWLEDGE_TERMS_SUCCESS'
export const ACKNOWLEDGE_TERMS_FAILURE = 'ACKNOWLEDGE_TERMS_FAILURE'
export const TICKER_ID = 'ticker_id'

const _getProfile = (orgId) => ({
  [CALL_API]: {
    types: [
      GET_PROFILE_REQUEST,
      GET_PROFILE_SUCCESS,
      GET_PROFILE_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/profile',
    orgIdVerifier: orgId
  }
})

const _setActiveTicker = (securityId) => ({
  type: SET_ACTIVE_TICKER,
  payload: securityId
})

const _acknowledgeTerms = (payload) => ({
  [CALL_API]: {
    types: [
      ACKNOWLEDGE_TERMS_REQUEST,
      ACKNOWLEDGE_TERMS_SUCCESS,
      ACKNOWLEDGE_TERMS_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/profile/acknowledgeTerms',
    payload
  }
})

/**
 * Dispatch GET_PROFILE_REQUEST
 */
export const getProfile = () => (dispatch, getState) => {
  const orgId = getState()?.profile?.data?._organization?._id
  return dispatch(_getProfile(orgId))
}

/**
 * Dispatch SET_ACTIVE_TICKER
 * @param tickerId
 */
export const setActiveTicker = (tickerId) => (dispatch) => {
  localStorage.setItem(TICKER_ID, tickerId)
  return dispatch(_setActiveTicker(tickerId))
}

/**
 * Dispatch acknowledgeTerms
 */
export const acknowledgeTerms = () => (dispatch) => {
  return dispatch(_acknowledgeTerms({
    acknowledged: true,
    date: new Date().getTime()
  }))
}
