import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'

// redux actions
import { useInstFundManagerQuery } from '../../../../../hook'

import TopManagersComponent from './topManagers.component'

const propTypes = {
  /**
   * Institution entity
   */
  entity: PropTypes.object.isRequired
}

const topManagersLimit = 5

const TopManagersContainer = (props) => {
  const {
    entity,
    handleRowClick
  } = props

  const entityId = entity.id

  const { loading, data } = useInstFundManagerQuery({
    variables: { id: entityId, limit: topManagersLimit }
  })

  const topManagers = get(data, 'instFundManager.items', [])

  return (
    <TopManagersComponent
      isLoading={loading}
      managers={topManagers}
      onRowClick={(e, topManager) => handleRowClick({ contactId: topManager.id })}
    />)
}

TopManagersContainer.propTypes = propTypes

export default TopManagersContainer

