import {
  PASSWORD_RESET_SUCCESS,
  statusType
} from '../../actions'

const initial = {
  status: statusType.IDLE,
  passwordResetMessage: ''
}

const login = (state = initial, action) => {
  const { payload } = action

  switch (action.type) {
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        data: payload,
        passwordResetMessage: 'Success! You can now login with your new password',
        status: statusType.SUCCESS
      }

    default:
      return state
  }
}

export default login
