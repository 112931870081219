import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Button, NoContentMessage, Spinner, RouteLink } from '../../../components'
import { Activity, Institution, Fund, Contact, Research, Security, Transcript } from './item'
import withOptions from '../../../components/utilityMenu/helper/withOptions'
import UtilityMenu from '../../../components/utilityMenu/utilityMenu.component'
import { getClassName, ENTITY_TYPE, THEMES } from '../../../utils'

import './list.component.scss'

const Utility = withOptions(UtilityMenu)
const { CONTACT } = ENTITY_TYPE

/**
 * Result Detail
 * @param props
 */
const ResultDetail = (props) => {
  switch (props.type) {
    case 'contact':
      return <Contact {...props} />
    case 'institution':
      return <Institution {...props} />
    case 'fund':
      return <Fund {...props} />
    case 'security':
      return <Security {...props} />
    case 'research':
      return <Research {...props} />
    case 'activity':
      return <Activity {...props} />
    case 'transcript':
      return <Transcript {...props} />
    default:
      return null
  }
}

/**
 * Get the entity's url according to the entity type, excluding transcript and research
 * @param entity
 */
const getResultUrl = (entity) => {
  const { id: q4EntityId, _id, type } = entity
  const link = `/${type}/`

  switch (type) {
    case 'institution':
    case 'fund':
      return link + q4EntityId
    case 'contact':
    case 'security':
    case 'activity':
    default:
      return link + _id
  }
}

/**
 * Search Result
 * @param props
 */
const Result = ({ entity, handleUtilityClick, history, isEdwSearch }) => {
  const { _id, type, source, url } = entity
  const link = url || getResultUrl(entity)
  const linkTarget = url ? '_blank' : '_self'

  return (
    (_id && type)
      ? (
        <RouteLink className='item' to={link} target={linkTarget}>
          <i className={`item_icon item_icon-${type} q4i-${(type === 'contact' && source === 'q4desktop') ? 'custom' : type}-2pt`} />
          <div className='item_content'>
            <ResultDetail
              {...entity}
              history={history}
            />
          </div>
          {['contact', 'institution', 'fund', 'security'].includes(type) &&
            <div className='item_actions'>
              <Button
                type='utility'
                theme={THEMES.LIGHT_GREY}
                className={`${type}-${_id}`}
                icon='q4i-utility-4pt'
                onClick={handleUtilityClick}
              />
            </div>}
        </RouteLink>
        )
      : null
  )
}

/**
 * Search Result List Component
 */
const SearchResultList = ({ loading, data, ownership, peers, history, subscriptions, type: filterType, profile }) => {
  const [utility, setUtility] = useState(null)
  const [utilityItems, setUtilityItems] = useState([
    { action: 'TARGET' },
    { action: 'BRIEFING_BOOK' },
    { action: 'DOWNLOAD_TEARSHEET' },
    { action: 'ADDRESS_BOOK' },
    { action: 'PEER_LIST' }
  ])

  /**
   * Handle utility item click
   * @param event
   * @param entity
   */
  const handleUtilityClick = (event, entity) => {
    event.stopPropagation()
    event.preventDefault()

    const { _id, type, _target } = entity

    const isDeal = !!(entity._deal && entity._deal.length)
    const isSecurity = type === 'security'
    const peer = (peers || []).find((peer) => (get(peer, '_security._id') || get(peer, '_security')) === _id)

    const items = utilityItems.map((item) => ({
      ...item,
      hide:
        (isDeal && item.action === 'TARGET') ||
        (type !== CONTACT && item.action === 'ADDRESS_BOOK') ||
        (isSecurity && item.action !== 'PEER_LIST') || (!isSecurity && item.action === 'PEER_LIST')
    }))

    const utility = {
      entity: {
        ...entity,
        institutionId: (type === CONTACT) ? get(entity, 'jobs[0].entityId') : null,
        targetId: _target,
        peerId: isSecurity ? (peer && peer._id) : null
      },
      anchorEl: event.currentTarget
    }

    setUtility(utility)
    setUtilityItems(items)
  }

  /**
   * Render Utility Menu
   */
  const getUtilityMenu = () => {
    const { entity, anchorEl } = utility

    return entity
      ? (
        <Utility
          theme={THEMES.LIGHT_GREY}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleUtilityClose()}
          items={utilityItems.filter((item) => !item.hide)}
          entityType={entity.type}
          entity={entity}
          hasButton
        />
        )
      : null
  }

  /**
   * Handle Utility Close Click
   */
  const handleUtilityClose = () => {
    setUtility(null)
  }

  /**
   * Render Search Result List
   */
  return (
    <div className={getClassName('search-page_list', [
      { condition: (loading && !data.length), trueClassName: 'search-page_list--loading' },
      { condition: data.length && data.length <= 2, trueClassName: 'search-page_list--few' }
    ])}
    >
      {loading && <Spinner theme={THEMES.RAIN} />}
      {(!loading && !data.length) || (get(subscriptions, 'estimates_research') === true && filterType.includes('research'))
        ? <NoContentMessage
            image={require('../../../resources/images/noContent/search_result.png').default}
            title='Broaden your search terms'
          />
        : (data || [])
            .reduce((acc, current) => !acc.find((item) => item._id === current._id) ? acc.concat([current]) : acc, [])
            .map((item, i) => {
              const { _id, type } = item

              if (['fund', 'institution'].includes(type)) {
                item.ownership = ownership
              }

              return (
                <Result
                  key={_id + i}
                  entity={item}
                  handleUtilityClick={(event) => handleUtilityClick(event, item)}
                  history={history}
                />
              )
            })}
      {utility && getUtilityMenu()}
    </div>
  )
}

SearchResultList.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array.isRequired,
  ownership: PropTypes.string,
  peers: PropTypes.array,
  history: PropTypes.object
}

SearchResultList.defaultProps = {
  loading: false,
  data: [],
  ownership: '13F',
  peers: []
}

export default SearchResultList
