import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// actions
import { getInstitution, statusType, GET_INSTITUTION_SUCCESS } from '../../actions'

// components
import { Spinner } from '../../components'
import InstitutionPage from './institution.page'
import { THEMES } from '../../utils/ui'
import { get } from 'lodash'

/**
 * Institution Page Preload
 */
function InstitutionPagePreload (props) {
  const { match, loading, getInstitution } = props
  const id = get(match, 'params.id')

  const [entity, setEntity] = useState(null)
  const [prevEntityId, setPrevEntityId] = useState(id)

  // Reset institution
  useEffect(() => {
    if (!id) {
      return
    }

    if (prevEntityId !== id) {
      setEntity(null)
      setPrevEntityId(id)
    }
  }, [id, prevEntityId])

  useEffect(() => {
    if (!id) {
      return
    }

    getInstitution(id).then((data) => {
      const { type, payload = {} } = data

      if (type === GET_INSTITUTION_SUCCESS && payload.q4_entity_id) {
        setEntity(payload)
      }
    })
  }, [id, setEntity, getInstitution])

  return (
    <>
      {loading && <Spinner mask theme={THEMES.DARK} />}
      {(!loading && entity && entity._id) && (
        <InstitutionPage
          entity={entity}
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  loading: get(state, 'institution.detail.status') === statusType.IN_PROGRESS
})

const mapDispatchToProps = (dispatch) => ({
  getInstitution: bindActionCreators(getInstitution, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionPagePreload)
