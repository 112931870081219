import { config } from '../../../config'
import { ENTITY_TYPE } from '../../../utils/entity'
import { DATA_IDS as ACTIVITY_DATA_IDS } from '../../../components/activity/form/activityForm.definition'

const dataIdPrefix = 'ContactPage'

export const DATA_IDS = {
  // BANNER
  REQUEST_UPDATE_BUTTON: `${dataIdPrefix}BannerEntityUpdate`,
  UTILITY_BUTTON: `${dataIdPrefix}BannerUtility`,
  UTILITY_LOG_ACTIVITY: `${dataIdPrefix}BannerUtilityActivityCreate`,
  UTILITY_SAVE_AS_TARGET: `${dataIdPrefix}BannerUtilityTarget`,
  UTILITY_CREATE_NEW_DEAL: `${dataIdPrefix}BannerUtilityDealCreate`,
  UTILITY_GENERATE_TEARSHEET: `${dataIdPrefix}BannerUtilityTearsheetGenerate`,
  UTILITY_ADD_TO_BRIEFING_BOOK: `${dataIdPrefix}BannerUtilityBriefingBook`,
  UTILITY_SAVE_TO_ADDRESS_BOOK: `${dataIdPrefix}BannerUtilityAddressBook`,

  // BADGES
  TARGET_BADGE: `${dataIdPrefix}BannerTargetBadge`,
  DEAL_BADGE: `${dataIdPrefix}BannerDealBadge`,
  CUSTOM_CONTACT_BADGE: `${dataIdPrefix}BannerCustomContactBadge`,
  FAVORITE_BADGE: `${dataIdPrefix}BannerAddressBookBadge`,
  WEBCAST_BADGE: `${dataIdPrefix}BannerWebcastBadge`,

  // OVERVIEW
  CARD_HEADER: 'CardHeader',
  CONTACT_INSTITUTION: `${dataIdPrefix}InstitutionCard`,
  CONTACT_INSTITUTION_POSITION: `${dataIdPrefix}InstitutionCurrentPosition`,
  OWNERSHIP: `${dataIdPrefix}OwnershipCard`,
  CONTACT_POSITION: `${dataIdPrefix}CurrentPosition`,
  HIGHLIGHTS: `${dataIdPrefix}HighlightsCard`,
  CONTACT_INFORMATION: `${dataIdPrefix}InformationCard`,
  DEALS: `${dataIdPrefix}DealsCard`,
  COMMENTS: `${dataIdPrefix}CommentCard`,
  COMMENTS_BUTTON: `${dataIdPrefix}CommentEdit`,
  COMMENTS_EDIT: {
    MODAL: 'EntityCommentEditModal',
    EDITOR: 'EntityCommentEditEditor',
    SAVE: 'EntityCommentEditSave',
    CANCEL: 'EntityCommentEditCancel'
  },
  BIOGRAPHY: `${dataIdPrefix}BiographyCard`,
  TAG: `${dataIdPrefix}Tags`,
  TAG_SEARCH_INPUT: `${dataIdPrefix}TagsTagSearchInput`,

  // ACTIVITY
  ACTIVITY_TAB: `${dataIdPrefix}ActivityTab`,
  ACTIVITY_GRID: `${dataIdPrefix}ActivityGrid`,
  ACTIVITY_CATEGORY_FILTER_INPUT: `${dataIdPrefix}ActivityCategoryFilterInput`,
  ACTIVITY_CATEGORY_FILTER_MENU: `${dataIdPrefix}ActivityCategoryFilterMenu`,
  ACTIVITY_SEARCH: `${dataIdPrefix}ActivitySearch`,
  ACTIVITY_EXPORT: `${dataIdPrefix}ActivityExport`,
  ACTIVITY_CREATE: `${dataIdPrefix}ActivityCreate`,

  // FUND
  FUND_TAB: `${dataIdPrefix}FundTab`,
  FUND_GRID: `${dataIdPrefix}FundGrid`,
  FUND_SEARCH: `${dataIdPrefix}FundSearch`,
  FUND_FILTER_ALL: `${dataIdPrefix}FundFilterAll`,
  FUND_FILTER_HOLDERS: `${dataIdPrefix}FundFilterHolders`,
  FUND_FILTER_PEER_HOLDERS: `${dataIdPrefix}FundFilterPeerHolders`,
  FUND_EXPORT: `${dataIdPrefix}FundExport`,

  // WEBCAST
  WEBCAST_TAB: `${dataIdPrefix}WebcastTab`,
  WEBCAST_GRID: `${dataIdPrefix}WebcastGrid`,

  // COVERAGE
  COVERAGE_TAB: `${dataIdPrefix}CoverageTab`,
  COVERAGE_GRID: `${dataIdPrefix}CoverageGrid`,

  // RESEARCH
  RESEARCH_TAB: `${dataIdPrefix}ResearchTab`,
  RESEARCH_GRID: `${dataIdPrefix}ResearchGrid`,

  // UTILITY MENU MODALS
  DEAL_MODAL: {
    MODAL: 'DealCreate',
    SAVE: 'DealCreateSave',
    CANCEL: 'DealCreateCancel',
  },
  REQUEST_UPDATE: {
    MODAL: 'EntityUpdateModal',
    CANCEL: 'EntityUpdateCancel',
    SAVE: 'EntityUpdateSave',
    TYPE: 'EntityUpdateTypeInput'
  },
  TEARSHEET_EXPORT: {
    MODAL: 'TearsheetGenerateExportModal',
    EXPORT: 'TearsheetGenerateExportModalExport',
    CANCEL: 'TearsheetGenerateExportModalClose'
  },
  BRIEFING_BOOK: {
    MODAL: 'AddToBriefingBook',
    CANCEL: 'AddToBriefingBookCancel',
    SAVE: 'AddToBriefingBookSave'
  },
  ADDRESS_BOOK: {
    MODAL: 'AddToAddressBookModal',
    CANCEL: 'AddToAddressBookCancel',
    SAVE: 'AddToAddressBookSave',
    LIST_SELECT: {
      INPUT: 'AddToAddressBookListSelectInput',
      MENU: 'AddToAddressBookListSelectMenu'
    },
    BOOKS: 'AddToAddressBookList'
  },
  TOAST: 'ToastMessage'
}

const page = '.contact-page'
const spinner = '.spinner_mask'
const tag = `div[data-id='${DATA_IDS.TAG}']`
const activityTab = `div[data-id='${DATA_IDS.ACTIVITY_TAB}']`
const activityGrid = `div[data-id='${DATA_IDS.ACTIVITY_GRID}']`
const fundsTab = `div[data-id='${DATA_IDS.FUND_TAB}']`
const fundsGrid = `div[data-id='${DATA_IDS.FUND_GRID}']`
const webcastTab = `div[data-id='${DATA_IDS.WEBCAST_TAB}']`
const webcastGrid = `div[data-id='${DATA_IDS.WEBCAST_GRID}']`
const coverageTab = `div[data-id='${DATA_IDS.COVERAGE_TAB}']`
const coverageGrid = `div[data-id='${DATA_IDS.COVERAGE_GRID}']`
const researchTab = `div[data-id='${DATA_IDS.RESEARCH_TAB}']`
const researchGrid = `div[data-id='${DATA_IDS.RESEARCH_GRID}']`
const commentsModal = `div[data-id='${DATA_IDS.COMMENTS_EDIT.MODAL}']`
const dealEditModal = `div[data-id='${DATA_IDS.DEAL_MODAL.MODAL}']`
const tearSheetModal = `div[data-id='${DATA_IDS.TEARSHEET_EXPORT.MODAL}']`
const briefingBookModal = `div[data-id='${DATA_IDS.BRIEFING_BOOK.MODAL}']`
const messageModal = '.message'
const requestUpdateModal = `div[data-id='${DATA_IDS.REQUEST_UPDATE.MODAL}']`
const addressBookModal = `div[data-id='${DATA_IDS.ADDRESS_BOOK.MODAL}']`
const toastMessage = `div[data-id='${DATA_IDS.TOAST}']`

export const Url = `${config.baseUrl}/${ENTITY_TYPE.CONTACT}/57d38c7391aea376a7b7501c`

export const Locator = {
  Page: `${page}`,
  Splash: {
    Button: '.splash-screen_loading'
  },
  Toast: `${toastMessage}`,
  Spinner: `${page} ${spinner}`,
  Badge: {
    Deal: `${page} div[data-id='${DATA_IDS.DEAL_BADGE}']`,
    Target: `${page} div[data-id='${DATA_IDS.TARGET_BADGE}']`,
    CustomContact: `${page} div[data-id='${DATA_IDS.CUSTOM_CONTACT_BADGE}']`,
    Favorite: `${page} div[data-id='${DATA_IDS.FAVORITE_BADGE}']`,
    Webcast: `${page} div[data-id='${DATA_IDS.WEBCAST_BADGE}']`
  },
  Banner: {
    RequestUpdateButton: `${page} button[data-id='${DATA_IDS.REQUEST_UPDATE_BUTTON}']`,
    Utility: {
      Button: `${page} button[data-id='${DATA_IDS.UTILITY_BUTTON}']`,
      Menu: {
        LogActivity: `div[data-id='${DATA_IDS.UTILITY_LOG_ACTIVITY}']`,
        CreateDeal: `div[data-id='${DATA_IDS.UTILITY_CREATE_NEW_DEAL}']`,
        SaveOrRemoveTarget: `div[data-id='${DATA_IDS.UTILITY_SAVE_AS_TARGET}']`,
        DownloadTearsheet: `div[data-id='${DATA_IDS.UTILITY_GENERATE_TEARSHEET}']`,
        AddToBriefingBook: `div[data-id='${DATA_IDS.UTILITY_ADD_TO_BRIEFING_BOOK}']`,
        SaveToAddressBook: `div[data-id='${DATA_IDS.UTILITY_SAVE_TO_ADDRESS_BOOK}']`
      }
    }
  },
  ContactInstitution: {
    Card: `${page} article[data-id='${DATA_IDS.CONTACT_INSTITUTION}']`,
    Title: `${page} article[data-id='${DATA_IDS.CONTACT_INSTITUTION}'] header[data-id=${DATA_IDS.CARD_HEADER}]`,
    Position: {
      Institution: `${page} div[data-id='${DATA_IDS.CONTACT_INSTITUTION_POSITION}']`,
      Contact: `${page} div[data-id='${DATA_IDS.CONTACT_POSITION}']`
    },
    Ownership: {
      Chart: `${page} article[data-id='${DATA_IDS.OWNERSHIP}']`
    }
  },
  Highlights: {
    Card: `${page} article[data-id='${DATA_IDS.HIGHLIGHTS}']`,
    Title: `${page} article[data-id='${DATA_IDS.HIGHLIGHTS}'] header[data-id='${DATA_IDS.CARD_HEADER}']`
  },
  ContactInformation: {
    Card: `${page} article[data-id='${DATA_IDS.CONTACT_INFORMATION}']`,
    Title: `${page} article[data-id='${DATA_IDS.CONTACT_INFORMATION}'] header[data-id='${DATA_IDS.CARD_HEADER}']`
  },
  Comment: {
    Card: `${page} article[data-id='${DATA_IDS.COMMENTS}']`,
    Title: `${page} article[data-id='${DATA_IDS.COMMENTS}'] header[data-id='${DATA_IDS.CARD_HEADER}']`,
    Edit: `${page} article[data-id='${DATA_IDS.COMMENTS}'] button[data-id='${DATA_IDS.COMMENTS_BUTTON}']`
  },
  Biography: {
    Card: `${page} article[data-id='${DATA_IDS.BIOGRAPHY}']`,
    Title: `${page} article[data-id='${DATA_IDS.BIOGRAPHY}'] header[data-id='${DATA_IDS.CARD_HEADER}']`
  },
  Tags: {
    Body: `${page} ${tag}`,
    Input: `${page} ${tag} div[data-id='${DATA_IDS.TAG_SEARCH_INPUT}']`,
    InputValue: `${page} ${tag} div[data-id='${DATA_IDS.TAG_SEARCH_INPUT}'] input`,
    Title: `${page} ${tag} .tag-input_header`,
    Item: `${page} ${tag} .tags-item_label`,
    ItemDelete: `${page} ${tag} .tags-item_delete`,
    Add: `${page} ${tag} .tag-input_add`,
    Search: `${page} ${tag} .tag-input_search`
  },
  Activity: {
    Tab: `${page} ${activityTab}`,
    Title: `${page} ${activityTab} .section-tab_nav-item`,
    Grid: {
      Body: `${page} ${activityGrid}`,
      Toolbar: {
        Category: {
          Input: `${page} ${activityGrid} div[data-id='${DATA_IDS.ACTIVITY_CATEGORY_FILTER_INPUT}']`,
          Menu: `${page} ${activityGrid} div[data-id='${DATA_IDS.ACTIVITY_CATEGORY_FILTER_MENU}']`
        },
        Search: {
          Input: `${page} ${activityGrid} div[data-id='${DATA_IDS.ACTIVITY_SEARCH}'] .section-search_input`,
          Cancel: `${page} ${activityGrid} div[data-id='${DATA_IDS.ACTIVITY_SEARCH}'] .section-search_clear`
        },
        Export: `${page} ${activityGrid} button[data-id='${DATA_IDS.ACTIVITY_EXPORT}']`,
        Create: `${page} ${activityGrid} button[data-id='${DATA_IDS.ACTIVITY_CREATE}']`
      },
      Header: {
        StartDate: `${page} ${activityGrid} .ag-header-row div[col-id='start']`,
        Sort: {
          StartDate: {
            SortAsc: `${page} ${activityGrid} .ag-header-row div[col-id='start'] .ag-header-cell-sorted-asc`,
            SortDesc: `${page} ${activityGrid} .ag-header-row div[col-id='start'] .ag-header-cell-sorted-desc`
          }
        },
        Tags: `${page} ${activityGrid} .ag-header-row div[col-id='tag']`
      },
      Spinner: `${page} ${activityGrid} ${spinner}`,
      Pagination: `${page} ${activityGrid} .pagination`
    }
  },
  Funds: {
    Tab: `${page} ${fundsTab}`,
    Title: `${page} ${fundsTab} .section-tab_nav-item`,
    Grid: {
      Toolbar: {
        Search: {
          Input: `${page} ${fundsGrid} div[data-id='${DATA_IDS.FUND_SEARCH}'] .section-search_input`,
          Cancel: `${page} ${fundsGrid} div[data-id='${DATA_IDS.FUND_SEARCH}'] .section-search_clear`
        },
        Filter: {
          All: `${page} ${fundsGrid} button[data-id='${DATA_IDS.FUND_FILTER_ALL}']`,
          Holders: `${page} ${fundsGrid} button[data-id='${DATA_IDS.FUND_FILTER_HOLDERS}']`,
          PeerHolders: `${page} ${fundsGrid} button[data-id='${DATA_IDS.FUND_FILTER_PEER_HOLDERS}']`
        },
        Export: `${page} ${fundsGrid} button[data-id='${DATA_IDS.FUND_EXPORT}']`
      },
      Header: {
        Pos: `${page} ${fundsGrid} .ag-header-row div[col-id='current']`,
        Sort: {
          Pos: {
            SortAsc: `${page} ${fundsGrid} .ag-header-row div[col-id='current'] .ag-header-cell-sorted-asc`,
            SortDesc: `${page} ${fundsGrid} .ag-header-row div[col-id='current'] .ag-header-cell-sorted-desc`
          }
        }
      },
      Body: `${page} ${fundsGrid}`,
      NoContentMessage: `${page} ${fundsGrid} .no-content-message`,
      Pagination: `${page} ${fundsGrid} .pagination`,
      Spinner: `${page} ${fundsGrid} ${spinner}`
    }
  },
  Webcast: {
    Tab: `${page} ${webcastTab}`,
    Title: `${page} ${webcastTab} .section-tab_nav-item`,
    Grid: {
      Body: `${page} ${webcastGrid}`,
      Spinner: `${page} ${webcastGrid} ${spinner}`
    }
  },
  Coverage: {
    Tab: `${page} ${coverageTab}`,
    Title: `${page} ${coverageTab} .section-tab_nav-item`,
    Grid: {
      Body: `${page} ${coverageGrid}`,
      Spinner: `${page} ${coverageGrid} ${spinner}`
    }
  },
  Research: {
    Tab: `${page} ${researchTab}`,
    Title: `${page} ${researchTab} .section-tab_nav-item`,
    Grid: {
      Body: `${page} ${researchGrid}`,
      Spinner: `${page} ${researchGrid} ${spinner}`
    }
  },
  ActivityModal: {
    Modal: `.MuiDialog-root`,
    SaveAndCloseButton: `button[data-id='${ACTIVITY_DATA_IDS.ACTIVITY_MODAL_SAVE_AND_CLOSE_BUTTON}']`,
    Cancel: `button[data-id='${ACTIVITY_DATA_IDS.ACTIVITY_MODAL_CANCEL_BUTTON}']`,
    Title: `.modal_title`,
    ToastMessage: `${toastMessage}`
  },
  DealModal: {
    Modal: `${dealEditModal}`,
    Save: `${dealEditModal} button[data-id='${DATA_IDS.DEAL_MODAL.SAVE}']`,
    Cancel: `${dealEditModal} button[data-id='${DATA_IDS.DEAL_MODAL.CANCEL}']`
  },
  CommentModal: {
    Modal: `${commentsModal}`,
    Title: `${commentsModal} .modal_title`,
    Editor: `${commentsModal} div[data-id='${DATA_IDS.COMMENTS_EDIT.EDITOR}']`,
    Cancel: `${commentsModal} button[data-id='${DATA_IDS.COMMENTS_EDIT.CANCEL}']`,
    Save: `${commentsModal} button[data-id='${DATA_IDS.COMMENTS_EDIT.SAVE}']`
  },
  MessageModal: {
    Modal: `${messageModal}`,
    Title: `${messageModal} .message_title`,
    Text: `${messageModal} .message_text`,
    Button: `${messageModal} .message_actions .button`,
    List: `${messageModal} .message_list`
  },
  RequestUpdateModal: {
    Modal: `${requestUpdateModal}`,
    Cancel: `${requestUpdateModal} button[data-id='${DATA_IDS.REQUEST_UPDATE.CANCEL}']`,
    Save: `${requestUpdateModal} button[data-id='${DATA_IDS.REQUEST_UPDATE.SAVE}']`,
    Type: `${requestUpdateModal} div[data-id='${DATA_IDS.REQUEST_UPDATE.TYPE}']`
  },
  TearsheetModal: {
    Modal: `${tearSheetModal}`,
    Cancel: `${tearSheetModal} button[data-id='${DATA_IDS.TEARSHEET_EXPORT.CANCEL}']`,
    Export: `${tearSheetModal} button[data-id='${DATA_IDS.TEARSHEET_EXPORT.EXPORT}']`
  },
  BriefingBookModal: {
    Modal: `${briefingBookModal}`,
    Cancel: `${briefingBookModal} button[data-id='${DATA_IDS.BRIEFING_BOOK.CANCEL}']`,
    Save: `${briefingBookModal} button[data-id='${DATA_IDS.BRIEFING_BOOK.SAVE}']`
  },
  AddressBookModal: {
    Modal: `${addressBookModal}`,
    Cancel: `${addressBookModal} button[data-id='${DATA_IDS.ADDRESS_BOOK.CANCEL}']`,
    Save: `${addressBookModal} button[data-id='${DATA_IDS.ADDRESS_BOOK.SAVE}']`,
    Select: {
      Input: `${addressBookModal} div[data-id='${DATA_IDS.ADDRESS_BOOK.LIST_SELECT.INPUT}']`,
      Menu: `${addressBookModal} div[data-id='${DATA_IDS.ADDRESS_BOOK.LIST_SELECT.MENU}']`,
      Item: `${addressBookModal} div[data-id='${DATA_IDS.ADDRESS_BOOK.LIST_SELECT.MENU}'] .select__option--multi`
    },
    Books: {
      List: `${addressBookModal} ul[data-id='${DATA_IDS.ADDRESS_BOOK.BOOKS}']`,
      Remove: `${addressBookModal} ul[data-id='${DATA_IDS.ADDRESS_BOOK.BOOKS}'] i`
    }
  },
  Footer: {
    ScrollUp: '.footer .scroll-up-button'
  }
}
