import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { SectionSearch } from '../../../shared'
import { Button, Select, Toolbar, ToolbarRow } from '../../../index'
import { ACTIVITY_TYPE_OPTIONS, ALL, THEMES } from '../../../../utils'
import { isEmpty } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  noData: PropTypes.bool,
  isEntity: PropTypes.bool,
  search: PropTypes.string,
  filter: PropTypes.object,
  counts: PropTypes.object,
  onQueryChange: PropTypes.func.isRequired,
  onActivityCreate: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired
}

const defaultProps = {
  toolbarTheme: THEMES.Q4_BLUE,
  toolTheme: THEMES.INK,
  filter: {},
  counts: {}
}

/**
 * Get category options
 * @param isEntity
 * @param counts
 * @returns {{label: string, value: *}[]}
 */
function getCategory (isEntity, counts) {
  return ACTIVITY_TYPE_OPTIONS
    .filter((category) => category.value !== ALL)
    .filter((category) => isEntity ? !category.isParent : category)
    .map((category) => {
      return {
        label: `${category.label} (${(counts && counts[category.value]) || 0})`,
        value: category.value
      }
    })
}

/**
 * Activity Toolbar Component
 * @param props
 */
function ActivityToolbar (props) {
  const {
    dataId, toolbarTheme, toolTheme, isEntity, search, filter, counts,
    onQueryChange, onActivityCreate, noData, onExport, exporting
  } = props
  const { category } = filter

  const options = getCategory(isEntity, counts)
  const selected = (options || []).filter((option) => [].concat(category || []).includes(option.value))

  const handleSearchChange = (search) => onQueryChange({ search })
  const handleFilterChange = (options) => {
    const category = (options || []).map((item) => item && item.value)
    return onQueryChange({ filter: { category: !isEmpty(category) ? category : null } })
  }

  return (
    <Toolbar theme={toolbarTheme}>
      <ToolbarRow justified>
        <div className='toolbar_group'>
          <Select
            theme={toolTheme}
            dataId={{
              inputId: `${dataId}CategoryFilterInput`,
              menuId: `${dataId}CategoryFilterMenu`
            }}
            size='thin'
            placeholder='Category'
            value={selected}
            options={options}
            isMulti
            isDefaultList
            searchable={false}
            clearable={false}
            closeMenuOnSelect={false}
            onChange={(options) => handleFilterChange(options)}
          />
        </div>
        <div className='toolbar_group'>
          <SectionSearch
            dataId={`${dataId}Search`}
            theme={toolTheme}
            value={search}
            onQueryChange={handleSearchChange}
            onClear={handleSearchChange}
          />
          <Button
            dataId={`${dataId}Export`}
            theme={toolTheme}
            loading={exporting}
            icon='q4i-download-4pt'
            label='Export'
            disabled={noData}
            onClick={onExport}
          />
          <Button
            dataId={`${dataId}Create`}
            theme={THEMES.CITRUS}
            icon='q4i-plus-4pt'
            onClick={onActivityCreate}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

ActivityToolbar.propTypes = propTypes
ActivityToolbar.defaultProps = defaultProps

export default memo(ActivityToolbar)
