import { getActivityList } from '../activity'
import { getStockHistorical } from '../stock'
import { getNews } from '../news'
import { getEvents } from '../event'
import { fetchInstitutionHistoricalPosition, fetchFundHistoricalPosition } from '../ownership'
import { ENTITY_FUND } from '../../utils'

/**
 * Entity Ownership Action Types
 */

export const GET_ENTITY_OWNERSHIP_POSITION_REQUEST = 'GET_ENTITY_OWNERSHIP_POSITION_REQUEST'
export const GET_ENTITY_OWNERSHIP_POSITION_SUCCESS = 'GET_ENTITY_OWNERSHIP_POSITION_SUCCESS'
export const GET_ENTITY_OWNERSHIP_POSITION_FAILURE = 'GET_ENTITY_OWNERSHIP_POSITION_FAILURE'

export const GET_ENTITY_OWNERSHIP_STOCK_REQUEST = 'GET_ENTITY_OWNERSHIP_STOCK_REQUEST'
export const GET_ENTITY_OWNERSHIP_STOCK_SUCCESS = 'GET_ENTITY_OWNERSHIP_STOCK_SUCCESS'
export const GET_ENTITY_OWNERSHIP_STOCK_FAILURE = 'GET_ENTITY_OWNERSHIP_STOCK_FAILURE'

export const GET_ENTITY_OWNERSHIP_NEWS_REQUEST = 'GET_ENTITY_OWNERSHIP_NEWS_REQUEST'
export const GET_ENTITY_OWNERSHIP_NEWS_SUCCESS = 'GET_ENTITY_OWNERSHIP_NEWS_SUCCESS'
export const GET_ENTITY_OWNERSHIP_NEWS_FAILURE = 'GET_ENTITY_OWNERSHIP_NEWS_FAILURE'

export const GET_ENTITY_OWNERSHIP_EVENTS_REQUEST = 'GET_ENTITY_OWNERSHIP_EVENTS_REQUEST'
export const GET_ENTITY_OWNERSHIP_EVENTS_SUCCESS = 'GET_ENTITY_OWNERSHIP_EVENTS_SUCCESS'
export const GET_ENTITY_OWNERSHIP_EVENTS_FAILURE = 'GET_ENTITY_OWNERSHIP_EVENTS_FAILURE'

export const GET_ENTITY_OWNERSHIP_ACTIVITY_REQUEST = 'GET_ENTITY_OWNERSHIP_ACTIVITY_REQUEST'
export const GET_ENTITY_OWNERSHIP_ACTIVITY_SUCCESS = 'GET_ENTITY_OWNERSHIP_ACTIVITY_SUCCESS'
export const GET_ENTITY_OWNERSHIP_ACTIVITY_FAILURE = 'GET_ENTITY_OWNERSHIP_ACTIVITY_FAILURE'

export const RESET_ENTITY_OWNERSHIP_CHART_DATA = 'RESET_ENTITY_OWNERSHIP_CHART_DATA'

/**
 * Dispatch GET_ENTITY_OWNERSHIP_POSITION_REQUEST
 * @param params
 */
const _getPosition = (params) => (dispatch) => {
  const positionAction = (params.type === ENTITY_FUND)
    ? fetchFundHistoricalPosition : fetchInstitutionHistoricalPosition

  if (!params.entityId) {
    return Promise.resolve()
  }

  return dispatch(positionAction({
    securityId: params.securityId,
    entityId: params.entityId,
    startDate: params.startDate.toISOString(),
    endDate: params.endDate.toISOString()
  }, {
    types: [
      GET_ENTITY_OWNERSHIP_POSITION_REQUEST,
      GET_ENTITY_OWNERSHIP_POSITION_SUCCESS,
      GET_ENTITY_OWNERSHIP_POSITION_FAILURE
    ]
  }))
}

/**
 * Dispatch GET_ENTITY_OWNERSHIP_STOCK_REQUEST
 * @param params
 */
const _getStock = (params) => (dispatch) => {
  return dispatch(getStockHistorical(params, {
    types: [
      GET_ENTITY_OWNERSHIP_STOCK_REQUEST,
      GET_ENTITY_OWNERSHIP_STOCK_SUCCESS,
      GET_ENTITY_OWNERSHIP_STOCK_FAILURE
    ]
  }))
}

/**
 * Dispatch GET_ENTITY_OWNERSHIP_EVENTS_REQUEST
 * @param params
 */
const _getEvents = (params) => (dispatch) => {
  return dispatch(getEvents({
    securityId: params.securityId,
    fields: ['title', 'date', 'event_type'],
    startDate: params.startDate.toISOString(),
    endDate: params.endDate.toISOString(),
    limit: 500
  }, {
    types: [
      GET_ENTITY_OWNERSHIP_EVENTS_REQUEST,
      GET_ENTITY_OWNERSHIP_EVENTS_SUCCESS,
      GET_ENTITY_OWNERSHIP_EVENTS_FAILURE
    ]
  }))
}

/**
 * Dispatch GET_ENTITY_OWNERSHIP_NEWS_REQUEST
 * @param params
 */
const _getNews = (params) => (dispatch) => {
  return dispatch(getNews({
    type: 'press',
    securityId: params.securityId,
    startDate: params.startDate.toISOString(),
    endDate: params.endDate.toISOString(),
    limit: 500
  }, {
    types: [
      GET_ENTITY_OWNERSHIP_NEWS_REQUEST,
      GET_ENTITY_OWNERSHIP_NEWS_SUCCESS,
      GET_ENTITY_OWNERSHIP_NEWS_FAILURE
    ]
  }))
}

/**
 * Dispatch GET_ENTITY_OWNERSHIP_ACTIVITY_REQUEST
 * @param params
 */
const _getActivity = (params) => (dispatch) => {
  return dispatch(getActivityList({
    entityId: params.contactId || params.entityId,
    startDate: params.startDate.toISOString(),
    endDate: params.endDate.toISOString(),
    limit: 500,
    showChildren: true
  }, {
    types: [
      GET_ENTITY_OWNERSHIP_ACTIVITY_REQUEST,
      GET_ENTITY_OWNERSHIP_ACTIVITY_SUCCESS,
      GET_ENTITY_OWNERSHIP_ACTIVITY_FAILURE
    ]
  }))
}

/**
 * Get Entity Ownership Chart Data
 * @param params
 * @param params.entityType
 * @param params.entityId
 * @param params.contactId
 * @param params.securityId
 * @param params.startDate
 * @param params.endDate
 * @return {function(*): Promise<[]>}
 */
export const getEntityOwnershipChartData = (params) => (dispatch) => {
  return Promise.all([
    dispatch(_getPosition(params)),
    dispatch(_getStock(params)),
    dispatch(_getNews(params)),
    dispatch(_getEvents(params)),
    dispatch(_getActivity(params))
  ])
}

/**
 * Get Entity Ownership Stock Chart Data (Events and News)
 * @param params
 * @param params.entityType
 * @param params.entityId
 * @param params.contactId
 * @param params.securityId
 * @param params.startDate
 * @param params.endDate
 * @return {function(*): Promise<[]>}
 */
export const getEntityOwnershipStock = (params) => (dispatch) => {
  return Promise.all([
    dispatch(_getStock(params)),
    dispatch(_getNews(params)),
    dispatch(_getEvents(params))
  ])
}

/**
 * Reset Entity Ownership Chart Data
 */
export const resetEntityOwnershipChartData = () => (dispatch) => {
  return dispatch({ type: RESET_ENTITY_OWNERSHIP_CHART_DATA })
}
