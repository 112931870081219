import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/react-hooks'

const GET_EVENT_TRANSCRIPT = gql`
  query Event(
    $search: String
    $searchField: [String]
    $startDate: String
    $timezoneOffset: Int
    $endDate: String
    $tickerId: [String]
    $transcript: Boolean
    $peers: Boolean
    $eventType: [String]
    $sortDir: String
    $page: Int
    $limit: Int
  ) {
    event(
      search: $search,
      searchField: $searchField,
      filter: {
        startDate: $startDate,
        endDate: $endDate,
        timezoneOffset: $timezoneOffset,
        tickerId: $tickerId,
        transcript: $transcript,
        peers: $peers,
        eventType: $eventType
      },
      sortDir: $sortDir
      page: $page,
      limit: $limit
    ) {
      items { 
        id
        eventTitle
        eventDate
        eventConferenceEndDate
        eventTypeName
        eventMarketTime
        companyId
        companyName
        companyRoleTypeName
        tickerId
        symbol
        exchange
        phoneLiveUS
        passcodeLiveUS
        phoneReplayUS
        phoneLiveInternational
        passcodeLiveInternational
        phoneReplayInternational
        transcriptUrl
        webcastLiveUrl
        webcastReplayUrl
      }
      count
    }
  }`

/**
 * Events & Transcripts
 * @param options
 */
export function useEventTranscriptQuery () {
  return useLazyQuery(GET_EVENT_TRANSCRIPT, {
    fetchPolicy: 'no-cache'
  })
}
