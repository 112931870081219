import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

// actions
import { useContactQuery, GET_CONTACTS } from '../../hook'
import { modalType, openModal } from '../../../../actions'
import { useExport } from '../../../../services/csvExport/csvExport.service'

// components
import ContactToolbar from './toolbar/toolbar.component'
import ContactTable from './table/table.component'
import { combine, getActiveTicker, getPageSizeFromStorage, formatDate, ENTITY_TYPE, EXPORT_DATE_FORMAT } from '../../../../utils'
import { get, isNull, omitBy } from 'lodash'

const { FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string,
  toolbarTheme: PropTypes.string,
  toolTheme: PropTypes.string,
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf([INSTITUTION, FUND]).isRequired
}

const defaultProps = {
  entityType: INSTITUTION
}

const PAGE_SIZE_ID = 'contact-grid'

/**
 * Contact Grid Component
 * @param props
 */
function ContactGrid (props) {
  const { dataIdPrefix, toolbarTheme, toolTheme, entityId, entityType, securityId, openModal, history, handleRowClick } = props
  const [state, setState] = useState({
    filter: {
      all: true,
      favorite: false,
      holder: false,
      peerHolder: false,
      functions: []
    },
    search: null,
    listOptions: {
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
  })

  const { listOptions, filter, search } = state
  const variables = omitBy({
    entityId,
    entityType,
    securityId,
    search,
    ...filter,
    ...listOptions
  }, isNull)

  const { loading, data, client } = useContactQuery({ variables })
  const { generateExport, exporting } = useExport({
    onError: () => {
      openModal({
        type: modalType.ERROR_MODAL
      })
    }
  })

  const source = (entityType === FUND) ? FUND : 'inst'
  const contacts = get(data, 'contact.items', [])
  const count = get(data, 'contact.count', 0)

  /**
   * Handle query change
   * @param query
   */
  const handleQueryChange = (query = {}) => {
    const options = query.listOptions ? { ...query } : { ...query, listOptions: { ...listOptions, page: 1 } }
    setState({ ...state, ...options })
  }

  /**
   * Handle bulk action: add selected contacts to BB
   * @param entities
   */
  const handleBulkAction = ({ entities }) => {
    if (!entities || !entities.length) {
      return
    }

    openModal({
      type: modalType.ADD_TO_BRIEFING_BOOK_MODAL,
      props: { entities }
    })
  }

  /**
   * Format mapper for CSV export
   * @param contact
   * @returns {*}
   */
  const contactCSVMapper = (contact) => {
    const job = get(contact, 'jobs[0]', {})
    const { title, phone, email, city, stateProvince, investmentFocus, functions } = job
    const activity = get(contact, 'activityConnection.items[0].start.date', null)

    return {
      Name: get(contact, 'fullName', '-'),
      Title: title,
      'Job Function': combine(functions || []),
      Location: combine([city, stateProvince]),
      'Investment Focus': combine(investmentFocus || []),
      Position: get(contact, 'contactHoldingCurrentConnection.items[0].current', null),
      Phone: phone,
      Email: email,
      'Last Activity': activity ? formatDate(activity, EXPORT_DATE_FORMAT, undefined, true) : '-'
    }
  }

  /**
   * Handle export
   */
  const handleExport = () => {
    const params = {
      client,
      variables: { ...variables, limit: 0 },
      query: GET_CONTACTS,
      dataPath: 'data.contact.items',
      fileName: `${source}_contacts.csv`,
      formatter: contactCSVMapper
    }

    generateExport(params)
  }

  return (
    <>
      <ContactToolbar
        dataId={`${dataIdPrefix}Contact`}
        toolbarTheme={toolbarTheme}
        toolTheme={toolTheme}
        filter={filter}
        search={search}
        entityType={entityType}
        noData={!contacts || !contacts.length}
        onQueryChange={handleQueryChange}
        onExport={handleExport}
        exporting={exporting}
      />
      <ContactTable
        dataId={`${dataIdPrefix}ContactGrid`}
        pageSizeId={PAGE_SIZE_ID}
        loading={loading}
        data={contacts}
        total={count}
        listOptions={listOptions}
        onQueryChange={handleQueryChange}
        onBulkAction={handleBulkAction}
        onRowClick={handleRowClick}
        history={history}
      />
    </>
  )
}

ContactGrid.propTypes = propTypes
ContactGrid.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)

  return {
    securityId: get(ticker, 'q4_entity_id', '1234567890') // TODO: tmp default value for pre-IPO
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactGrid))
