import { combineReducers } from 'redux'
import contacts from './contacts.reducer'
import exportContacts from './export.reducer'
import list from './list.reducer'
import lists from './lists.reducer'
import addressBookFilters from './filter.reducer'

export default combineReducers({
  contacts,
  exportContacts,
  list,
  lists,
  addressBookFilters
})
