import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { OWNERSHIP_TYPE } from '../../../utils'

export const GET_MANAGED_FUNDS = gql`
  query Fund(
    $id: [String]
    $entityId: String
    $tickerId: [String]!
    $securityId: [String]
    $currencyCode: String
    $source: String = "${OWNERSHIP_TYPE['13F']}"
    $sortBy: String
    $limit: Int!
  ) {
    fund(
      id: $id
      institutionId: $entityId
      tickerId: $tickerId
      securityId: $securityId
      currencyCode: $currencyCode
      sortBy: $sortBy
      limit: $limit
    ) {
      items {
        id
        fundName
        institutionName
        style
        turnover
        portfolioValue
        fundHoldingCurrentConnection(
          tickerId: $tickerId
          source: $source
          currencyCode: $currencyCode
        ) {
          items {
            current
            reportDate
            marketValue
            marketValueChange
          }
        }
      }
    }
  }`

/**
 * Funds
 * @param options
 */
export function useManagedFundQuery (options) {
  return useQuery(GET_MANAGED_FUNDS, options)
}
