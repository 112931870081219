import React from 'react'
import { config } from '../../config'
import { useTypedSelector } from '../../hook'
import moment from 'moment-timezone'
import ScrollUpButton from '../shared/scrollUpButton/scrollUpButton.component'

import './footer.component.css'
import { isSubscribed } from '../../utils'

/**
 * Footer Component
 * @param props
 * @returns {*}
 * @constructor
 */
const Footer = (props) => {
  const { children } = props
  const currentYear = moment().get('year')

  const profile = useTypedSelector((state) => state.profile.data)
  const snpEstimateNResearchFlag = isSubscribed(profile, 'estimates_research')
  /**
   * Render child component
   * @param children
   * @returns {function()}
   */
  const getComponent = (children) => {
    if (!children || typeof children !== 'object') {
      return null
    }

    return (
      <div className='footer_component'>
        {children}
      </div>
    )
  }
  /**
   * Returns footer text based on Feature flag and Subscription
   * @param {*} eventFlag
   * @param {*} snpEstimateSub
   * @returns string
   */
  const getFooterText = (snpEstimateSub) => {
    if (snpEstimateSub) {
      return 'Ownership data provided by FactSet. Estimates, Events, Research, and Transcripts data provided by S&P.'
    } else if (!snpEstimateSub) {
      return 'Ownership data provided by FactSet. Estimates and Research by FactSet. Events and Transcripts data provided by S&P.'
    }
  }
  /**
   * Render Footer
   * @returns {*}
   */
  return (
    <div id='app-footer' className='footer'>
      <div className='footer_inner'>
        <ScrollUpButton />
        {children ? getComponent(children) : null}
        <p>{getFooterText(snpEstimateNResearchFlag)}</p>
        <p>
          <span>Copyright &copy; {currentYear} FactSet </span>
          <span>Copyright &copy; {currentYear} S&P</span>
          <span>&copy; Copyright Q4 Inc. {currentYear}. All rights reserved.</span>
          <span>Powered By Q4 Inc. {config.version}</span>
        </p>
      </div>
    </div>
  )
}

export default Footer
