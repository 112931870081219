import React, { Component } from 'react'
import ComboBox from '../../../comboBox/comboBox.component'
import { split, uniqBy } from 'lodash'
import { hasSavedAndAddAnotherSuccess, hasChangedStateValueTo } from '../../../../utils/activity'
import { SAVE_ACTIVITY_SUCCESS_BUILD_ITINERARY } from '../../../../actions/activity/form.actions'
import { THEMES } from '../../../../utils/ui'

const enterKey = 13
const arrowKeys = [38, 40]

class ActivityTags extends Component {

  constructor (props) {
    super(props)
    this.state = {
      searchTerm: ''
    }
    this.customValueCreation = false
    this.prevKey = null
    this.selectReference = React.createRef()
  }

  componentDidUpdate (prevProps) {
    const { activityFormState } = this.props

    if (hasChangedStateValueTo(prevProps.activityFormState.type, activityFormState.type, SAVE_ACTIVITY_SUCCESS_BUILD_ITINERARY) ||
      hasSavedAndAddAnotherSuccess(prevProps, activityFormState)) {
      this.setState({ searchTerm: '' })
    }
  }

  /**
   * Format values for Select and Removable list
   * @param values
   * @return {Array|*}
   */
  getFormattedValues (values) {
    if (!values || !values.length) {
      return []
    }

    return values
      .map((tag) => {
        return {
          value: tag.name,
          label: `#${tag.name}`
        }
      })
  }

  /**
   * Handle Tag selected from search results
   * @param tag String
   */
  handleChange = (tag) => {
    if (this.customValueCreation) {
      return
    }

    this.selectTag({ name: tag.value })
  }

  /**
   * Handle Input Change
   * @param query String
   */
  handleInputChange = (query) => {
    const { findTags, clearSearchData } = this.props
    this.disableResultFocus()
    this.setState({ searchTerm: query }, query ? findTags(query) : clearSearchData())
  }

  /**
   * Filter and remove selected tag
   * @param tag {}
   */
  removeTag = (tag) => {
    const { setFieldValue, tags } = this.props
    setFieldValue('tag', (tags || []).filter((item) => item.name !== tag))
  }

  /**
   * Strips tag strings of spaces and non-alphanumeric characters
   * @param tag {}
   */
  sanitizeTag = (tag) => {
    if (typeof tag === 'string') {
      tag = tag.trim()
      return tag.replace(/[-_.!~*')(\s]/g, '')
    } else {
      return tag
    }
  }

  /**
   * Update Formik with unique tag values
   * @param tag
   */
  selectTag = (tag) => {
    const { setFieldValue, clearSearchData, tags } = this.props

    this.setState({ searchTerm: '' }, () => {
      clearSearchData()
      setFieldValue('tag', uniqBy([].concat(tags, tag), 'name'))
      this.customValueCreation = false
    })
  }

  /**
   * Handle custom tag inputs by user via Enter key
   * @param event String
   */
  handleKeyDown = (event) => {
    if (!event || !event.target.value) {
      return
    }
    const { keyCode } = event

    if (keyCode === enterKey && !arrowKeys.some((key) => key === this.prevKey)) {
      let value = this.sanitizeTag(event.target.value)

      this.customValueCreation = true
      if (value.indexOf(',') !== -1) {
        value = this.splitTags(value)
        this.selectTag(value)
      } else {
        value && value.length
          ? this.selectTag({ name: value })
          : this.setState({ searchTerm: '' }, this.customValueCreation = false)
      }
    }

    this.prevKey = keyCode
  }

  /**
   * Separate tags by comma
   * @param value
   * @return {{name: T}[]}
   */
  splitTags (value) {
    return split(value, ',')
      .filter((tag) => tag && tag.length )
      .map((tag) => ({ name: tag }))
  }

  /**
   * Disable first result focus on menu open
   */
  disableResultFocus () {
    this.selectReference.current.select.getNextFocusedOption = () => null
  }

  render () {
    const { data, tags } = this.props
    const { searchTerm } = this.state

    return (
      <div className='activity-form__activity-tags activity-form--section'>
        <div className='field--text field field--full'>
          <label htmlFor='activity-tags' className='field_label'>Tags</label>
          <ComboBox
            theme={THEMES.LIGHT_GREY}
            selectProps={{
              placeholder: 'ex. lunch, 1x1, group, travel',
              value: null,
              inputValue: searchTerm,
              options: this.getFormattedValues(data),
              searchable: true,
              onInputChange: this.handleInputChange,
              onChange: this.handleChange,
              onKeyDown: this.handleKeyDown,
              selectReference: this.selectReference
            }}
            removableListProps={{
              items: this.getFormattedValues(tags),
              onRemove: this.removeTag
            }} />
        </div>
      </div>
    )
  }
}

export default ActivityTags
