import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './textArea.component.css'

/**
 * TextArea
 * @param id
 * @param name
 * @param value
 * @param error
 * @param errorMessage
 * @param onBlur
 * @param onChange
 * @param className
 * @param placeholder
 * @param maxLength
 * @param type
 */
class TextArea extends PureComponent {

  /**
   * Render error tooltip
   * @return {*}
   */
  renderErrorTooltip = (props) => {
    const { message } = props
    return message
      ? <div className='tooltip tooltip--error tooltip--error--right'>{message}</div>
      : null
  }

  render () {
    const {
      dataId, id, name, className, error, placeholder, value, maxLength, rows, onChange, onBlur
    } = this.props

    const ErrorTooltip = this.renderErrorTooltip

    return (
      <div className={`text-area-field ${(error && error.isError) ? 'text-area-field--error' : ''} ${className || ''}`} data-id={dataId}>
        <textarea
          id={id}
          name={name}
          className='text-area-field_field'
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          rows={rows}
          onChange={onChange}
          onBlur={onBlur} />
        <ErrorTooltip
          message={error && error.message} />
      </div>
    )
  }
}

TextArea.propTypes = {
  dataId: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  maxLength: PropTypes.number,
  rows: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

TextArea.defaultProps = {
  placeholder: '',
  rows: 6
}

export default TextArea
