import {
  GET_ENTITY_OWNERSHIP_NEWS_REQUEST,
  GET_ENTITY_OWNERSHIP_NEWS_SUCCESS,
  GET_ENTITY_OWNERSHIP_NEWS_FAILURE,
  RESET_ENTITY_OWNERSHIP_CHART_DATA,
  statusType
} from '../../../actions'

const moment = require('moment-timezone')

/**
 * Entity Ownership Chart - News Reducer
 * @param state
 * @param action
 * @returns
 * {
 *    [securityId]: {
 *      data: [],
 *      status: 'SUCCESS'
 *    },
 *    [securityId]: {
 *      data: [],
 *      status: 'SUCCESS'
 *    }
 * }
 */
const news = (state = {}, action) => {
  const { payload, request } = action

  switch (action.type) {
    case GET_ENTITY_OWNERSHIP_NEWS_REQUEST:
      return {
        ...state,
        [payload.securityId]: {
          status: statusType.IN_PROGRESS
        }
      }

    // News
    case GET_ENTITY_OWNERSHIP_NEWS_SUCCESS:
      return {
        ...state,
        [request.securityId]: {
          data: (payload || [])
            .filter((record) => record.date && record.headline)
            .map((record) => ({
              x: moment.utc(record.date).valueOf(),
              title: ' ',
              text: record.headline,
              id: record._id
            }))
            .reverse(),
          status: statusType.SUCCESS
        }
      }

    case GET_ENTITY_OWNERSHIP_NEWS_FAILURE:
      return {
        ...state,
        [request.securityId]: {
          status: statusType.ERROR
        }
      }

    case RESET_ENTITY_OWNERSHIP_CHART_DATA:
      return {
        ...state,
        [payload.securityId]: undefined
      }

    default:
      return state
  }
}

export default news
