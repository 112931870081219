import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { hasIn } from 'lodash'
import { addQueryParams } from '../../utils/http/http.util'
import { getAllSavedTargets } from '../targeting/savedTarget.actions'
import { getStages } from '../stage/stage.actions'
import { DOWNLOAD_API } from '../../middleware/download.middleware'

/**
 * Pipeline Action Type Constants
 * @type {string}
 */
export const GET_PIPELINE_REQUEST = 'GET_PIPELINE_REQUEST'
export const GET_PIPELINE_SUCCESS = 'GET_PIPELINE_SUCCESS'
export const GET_PIPELINE_FAILURE = 'GET_PIPELINE_FAILURE'
export const GET_PIPELINE_LOST_REQUEST = 'GET_PIPELINE_LOST_REQUEST'
export const GET_PIPELINE_LOST_SUCCESS = 'GET_PIPELINE_LOST_SUCCESS'
export const GET_PIPELINE_LOST_FAILURE = 'GET_PIPELINE_LOST_FAILURE'

export const EXPORT_PIPELINE_REQUEST = 'EXPORT_PIPELINE_REQUEST'
export const EXPORT_PIPELINE_SUCCESS = 'EXPORT_PIPELINE_SUCCESS'
export const EXPORT_PIPELINE_ERROR = 'EXPORT_PIPELINE_ERROR'

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB'

/**
 * Get Pipeline for user's organization
 * @param params.pageSize
 * @param params.stage
 * @returns {*}
 * @private
 */
const _getPipeline = (params) => ({
  [CALL_API]: {
    types: [
      GET_PIPELINE_REQUEST,
      GET_PIPELINE_SUCCESS,
      GET_PIPELINE_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/pipeline',
    payload: Object.assign({
      pageSize: 200,
      sortField: 'create_date',
      sortDirection: 'ASC'
    }, params)
  }
})

/**
 * Get Pipeline Lost for user's organization
 * @param params.pageSize
 * @param params.stage
 * @returns {*}
 * @private
 */
const _getPipelineLost = (params) => ({
  [CALL_API]: {
    types: [
      GET_PIPELINE_LOST_REQUEST,
      GET_PIPELINE_LOST_SUCCESS,
      GET_PIPELINE_LOST_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/pipeline',
    payload: Object.assign({
      pageSize: 200,
      sortField: 'create_date',
      sortDirection: 'ASC'
    }, params)
  }
})

const _setActiveTab = (payload) => ({
  type: SET_ACTIVE_TAB,
  payload
})

const _exportPipelineCsv = ({ url, filename }) => ({
  [DOWNLOAD_API]: {
    types: [
      EXPORT_PIPELINE_REQUEST,
      EXPORT_PIPELINE_SUCCESS,
      EXPORT_PIPELINE_ERROR
    ],
    method: METHOD_TYPE.GET,
    endpoint: url,
    payload: {
      file: {
        name: filename,
        type: 'csv'
      }
    },
    options: {
      headers: {
        'Content-Type': 'text/csv'
      },
      credentials: 'include'
    }
  }
})

export const setActiveTab = (params) => (dispatch) => {
  dispatch(_setActiveTab(params))
  if (params.value === 'lost') {
    dispatch(getPipelineLost(params))
  }
}

/**
 * Dispatch GET_PIPELINE_REQUEST
 */
export const getPipeline = (params) => (dispatch) => {
  dispatch(getStages())
  dispatch(_getPipeline(params))
  dispatch(getAllSavedTargets())
}

/**
 * Dispatch GET_PIPELINE_REQUEST
 */
export const getPipelineLost = (params) => (dispatch) => {
  dispatch(getStages())
  dispatch(_getPipelineLost(params))
}

export const exportPipelineCsv = ({ securityId }) => (dispatch, getState) => {
  const state = getState()
  const security = (hasIn(state, 'stock.quote.data.Security.Name')) ? state.stock.quote.data.Security.Name : securityId

  dispatch(_exportPipelineCsv({
    url: addQueryParams('/pipeline/export', { securityId }),
    filename: `export_pipeline_${security}`
  }))
}
