import { combineReducers } from 'redux'
import institutionWebAnalytics from './institutionWebAnalytics.reducer'
import downloadsWebAnalytics from './downloadsWebAnalytics.reducer'
import tradingAnalytics from './tradingAnalytics.reducer'

const analyticsReducer = combineReducers({
  institutionWebAnalytics,
  downloadsWebAnalytics,
  tradingAnalytics
})

export default analyticsReducer
