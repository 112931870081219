import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { EditorState, Modifier } from 'draft-js'
import { selectAllContent } from '../../../../../utils/htmlEditor.util'
import { Button } from '../../../../../components'

import './textAlignmentControls.component.css'

/**
 * React-Draft-Wysiwyg Component to control textAlignment
 *
 * @see { @link: https://jpuri.github.io/react-draft-wysiwyg/#/docs } - React Wrapper around DraftJS
 * @see { @link: https://draftjs.org/docs/api-reference-editor-state } - EditorState API
 * @see { @link: https://draftjs.org/docs/api-reference-modifier } - Modifier API
 */
function TextAlignmentControlsComponent ({ textAlignmentOptions, handleTextAlignmentChange, onChange, editorState }) {

  /**
   * Applies text alignment to all content in the editor
   * @param textAlignmentOption
   */
  function setTextAlignment (textAlignmentOption) {
    const selection = selectAllContent(editorState)
    const newEditorState = EditorState.acceptSelection(editorState, selection)
    const newContentState = Modifier.setBlockData(
      newEditorState.getCurrentContent(),
      selection,
      { 'text-align': textAlignmentOption }
    )

    // update state
    handleTextAlignmentChange(textAlignmentOption)

    // apply styles to content
    onChange(EditorState.push(
      newEditorState,
      newContentState,
      'change-block-data'
    ))
  }

  return (
    <div className='text-alignment-controls'>
      {textAlignmentOptions.map((option) =>
        <Button
          key={option}
          className={`text-alignment-controls_button--align-${option}`}
          theme='steel'
          square
          onClick={() => setTextAlignment(option)}
        />
      )}
    </div>
  )
}

TextAlignmentControlsComponent.propTypes = {
  textAlignmentOptions: PropTypes.arrayOf(PropTypes.string),
  handleTextAlignmentChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  editorState: PropTypes.object.isRequired
}

export default memo(TextAlignmentControlsComponent)
