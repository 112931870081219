import { useEffect, useRef } from 'react'

/**
 * Custom hook to reference to a previous state
 * @param {*} value
 */
export function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}
