import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// actions
import { useContactQuery, useContactsQuery, useFundQuery, useInstitutionQuery, useInstitutionContactQuery } from './hook'

// components
import {
  Activity,
  Address,
  Attribute,
  Biography,
  Comment,
  Coverage,
  Estimate,
  Header,
  IndustryAnalysis,
  InvestmentApproach,
  ManagedFund,
  Ownership,
  PeerHolding,
  Tag,
  TopHolding,
  TopIndustry
} from './components'

// utils
import { DEFAULT_TICKER, ENTITY_TYPE, getLocalizedCurrency, isTradingTicker, THEMES } from '../../utils'
import { get } from 'lodash'

import './tearsheet.container.css'
import { EntityBadge } from '../entity'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf([CONTACT, FUND, INSTITUTION]).isRequired,
  subscription: PropTypes.object.isRequired,
  institutionId: PropTypes.string,
  entities: PropTypes.array,
  selectedCards: PropTypes.array,
  security: PropTypes.shape({
    id: PropTypes.string.isRequired,
    tickerId: PropTypes.string.isRequired,
    securityName: PropTypes.string,
    capGroup: PropTypes.string,
    region: PropTypes.string,
    sector: PropTypes.string,
    industry: PropTypes.string
  }).isRequired,
  ticker: PropTypes.shape({
    symbol: PropTypes.string,
    exchange: PropTypes.string,
    legacySecurityId: PropTypes.string
  }).isRequired
}

const defaultProps = {
  security: {
    id: DEFAULT_TICKER, // TODO: tmp default value for pre-IPO
    tickerId: DEFAULT_TICKER
  },
  ticker: {},
  selectedCards: [],
  entities: [],
  entityType: INSTITUTION,
  subscription: { value: '13f', label: '13F' }
}

/**
 * Get contact of current fund entity
 * @param entityId
 * @param contacts
 */
function getFundContact ({ entityId, contacts }) {
  return get(contacts, 'contact.items', [])
    .find((contact) => (contact.managedFunds || []).find((fund) => fund.fundId === entityId))
}

/**
 * Get selected cards
 * @param components - all possible components
 * @param cards - selected cards
 */
function getCards ({ components = [], cards }) {
  return components
    .filter((component) => (cards || []).find((card) => card.type === component.key))
    .map((component) => {
      const card = (cards || []).find((card) => card.type === component.key)
      const options = (card && card.options) || []

      return {
        ...component,
        props: {
          ...component.props,
          ...options.reduce((options, option) => {
            const { type, selected } = (option || {})
            return { ...options, [type]: selected }
          }, {})
        }
      }
    })
}

/**
 * Left Column Components
 * @param props
 */
function LeftColumnCards (props) {
  const { selectedCards, entityId, entityType, tickerId, institutionId, subscription, ticker, entityName } = (props || {})
  const id = [CONTACT].includes(entityType) ? institutionId : entityId
  const OwnershipComponent = id &&
    <Ownership
      key='ownershipChart'
      entityId={id}
      entityType={[CONTACT].includes(entityType) ? INSTITUTION : entityType}
      tickerId={tickerId}
      subscription={subscription}
      ticker={ticker}
      entityName={entityName}
    />

  return getCards({
    components: [OwnershipComponent].filter((component) => component),
    cards: selectedCards
  })
}

/**
 * Full Column Components
 * @param props
 */
function FullColumnCards (props) {
  const { selectedCards, entityId, entityType, security, investmentApproach, managedFunds, coverage, gdpr, organization } = (props || {})
  const { securityName, industry } = (security || {})
  const tickerId = isTradingTicker(security.tickerId) ? security.tickerId : DEFAULT_TICKER
  const securityId = isTradingTicker(tickerId) ? security.id : security._id
  const organizationName = securityName || organization.name

  const TopHoldingComponent = (entityType !== CONTACT)
    ? <TopHolding
        key='topHoldings'
        entityId={entityId}
        entityType={entityType}
        tickerId={tickerId}
        securityName={organizationName}
      /> : null

  const ManagedFundComponent = (entityType !== FUND) && !coverage && !gdpr
    ? <ManagedFund
        key='managedFunds'
        id={managedFunds}
        entityId={(entityType === INSTITUTION) ? entityId : null}
        tickerId={tickerId}
        securityId={securityId}
      /> : null

  const PeerHoldingComponent = (entityType !== CONTACT)
    ? <PeerHolding
        key='peerHoldings'
        entityId={entityId}
        entityType={entityType}
        tickerId={tickerId}
        securityName={organizationName}
      /> : null

  const IndustryAnalysisComponent = (entityType !== CONTACT)
    ? <IndustryAnalysis
        key='industryAnalysis'
        entityId={entityId}
        entityType={entityType}
        security={security}
      /> : null

  const TopIndustryComponent = (entityType !== CONTACT)
    ? <TopIndustry
        key='topIndustryBuySell'
        entityId={entityId}
        entityType={entityType}
        industry={industry}
      /> : null

  const InvestmentApproachComponent = (entityType === INSTITUTION)
    ? <InvestmentApproach key='investmentApproach' investmentApproach={investmentApproach} />
    : null
  const CommentComponent = <Comment key='entityComment' entityId={entityId} />
  const ActivityComponent = <Activity key='activity' entityId={entityId} />

  return getCards({
    components: [
      TopHoldingComponent,
      ManagedFundComponent,
      PeerHoldingComponent,
      IndustryAnalysisComponent,
      TopIndustryComponent,
      InvestmentApproachComponent,
      CommentComponent,
      ActivityComponent
    ].filter((component) => component),
    cards: selectedCards
  })
}

/**
 * Tearsheet
 * @param props
 */
function Tearsheet (props) {
  const { selectedCards, entityId, entityType, institutionId, ticker, security, subscription, entities, organization } = props
  const tickerId = isTradingTicker(security.tickerId) ? security.tickerId : DEFAULT_TICKER
  const securityId = isTradingTicker(tickerId) ? security.id : security._id
  const variables = { id: entityId, securityId, currencyCode: getLocalizedCurrency() }
  const request = (entityType === CONTACT) ? useContactQuery : (entityType === FUND) ? useFundQuery : useInstitutionQuery
  const { data } = request({ variables })

  const contactIds = (entities || []).filter((entity) => entity.entityType === CONTACT).map((contact) => contact.entityId)
  const { data: contacts } = useContactsQuery({
    skip: entityType !== FUND || !contactIds || !contactIds.length,
    variables: { id: contactIds }
  })

  const entity = get(data, `${entityType}.items[0]`, {})
  const {
    id,
    fullName,
    fundName,
    institutionName,
    institutionType,
    investmentApproach,
    activist,
    managedFunds,
    source,
    status,
    bio,
    coverage,
    jobs
  } = entity

  const custom = entityType === CONTACT && (source === 'q4desktop')
  const gdpr = entityType === CONTACT && (status === 'gdpr')
  const isCustomJob = !institutionId && jobs && !jobs[0].entityId
  const job = isCustomJob 
    ? jobs[0] 
    : (jobs || []).find((job) => job && (job.entityId === institutionId))
  const fundIds = entityType === CONTACT ? (managedFunds || []).map((fund) => get(fund, 'fundId')).filter((id) => id) : null
  const institutionContact = (entities || []).find((entity) => (entity.entityType === CONTACT) && (entity.institutionId === entityId))
  const fundContact = (entityType === FUND) && contacts ? getFundContact({ entityId, contacts }) : {}

  const [getInstitutionContact, { data: contact }] = useInstitutionContactQuery({
    variables: { id: get(institutionContact, 'entityId') }
  })

  /**
   * Get Institution Contact from the same Briefing Book
   */
  useEffect(() => {
    institutionContact && getInstitutionContact()
  }, [institutionContact, getInstitutionContact])

  return (
    <>
      {!!id && (
        <div className='tearsheet'>

          <section className='columns columns--left'>
            <Header
              data={{
                entityType,
                entityName: (fullName || fundName || institutionName),
                institutionName,
                institutionType,
                address: entityType === INSTITUTION
                  ? get(entity, 'addressConnection.items[0]', {})
                  : get(entity, 'institutionConnection.items[0].addressConnection.items[0]', {}),
                gdpr,
                job
              }}
            />
          </section>

          <section className='columns columns--right'>
            <Grid container spacing={2}>
              <EntityBadge
                theme={THEMES.LIGHT}
                className='tearsheet_section-content--right'
                data={{
                  activist,
                  custom,
                  favorite: get(entity, 'favoriteConnection.items[0].id', null)
                }}
              />

              {(selectedCards || []).find((card) => card.type === 'tags') && <Tag entityId={entityId} />}
            </Grid>
          </section>

          <section className='columns columns--left'>
            <Attribute
              entityId={entityId}
              entityType={entityType}
              entity={entity}
              tickerId={tickerId}
              securityId={securityId}
              institutionId={institutionId}
              subscription={subscription}
              gdpr={gdpr}
            />

            <Biography bio={bio} />

            {/* Ownership */}
            <LeftColumnCards
              selectedCards={selectedCards}
              entityId={entityId}
              entityType={entityType}
              tickerId={tickerId}
              institutionId={institutionId}
              subscription={subscription}
              ticker={ticker}
              entityName={(entityType === CONTACT) ? get(job, 'institutionName') : fundName || institutionName} // TODO: remove after SID migration
            />
          </section>

          <section className='columns columns--right'>
            <Address
              entityId={entityId}
              entityType={entityType}
              entityContact={entityType === INSTITUTION ? get(contact, 'contact.items[0]', {}) : fundContact}
              institutionId={entityType === FUND ? get(entity, 'institutionId', null) : null}
              job={job}
            />
          </section>

          <section className='column column--full'>
            {/* Contact Coverage and Historical Estimates => TODO: Remove when estimates migration is completed */}
            {(entityType === CONTACT) && coverage && !gdpr &&
              <>
                <Coverage
                  entityId={entityId}
                  legacySecurityId={get(ticker, 'legacySecurityId')}
                />
                <Estimate
                  entityId={entityId}
                  legacySecurityId={get(ticker, 'legacySecurityId')}
                />
              </>}

            {/* Top Holdings, Managed Funds, Peer Holdings, Industry Analysis, Industry Sells/Buys/Holdings */}
            {/* Investment Approach, Comments, Activities */}
            <FullColumnCards
              selectedCards={selectedCards}
              entityId={entityId}
              entityType={entityType}
              security={security}
              investmentApproach={investmentApproach}
              managedFunds={fundIds}
              coverage={coverage}
              gdpr={gdpr}
              organization={organization}
            />
          </section>

        </div>
      )}
    </>
  )
}

Tearsheet.propTypes = propTypes
Tearsheet.defaultProps = defaultProps

export default Tearsheet
