import {
  FETCH_DOWNLOADS_ANALYTICS_ERROR,
  FETCH_DOWNLOADS_ANALYTICS_REQUEST,
  FETCH_DOWNLOADS_ANALYTICS_SUCCESS,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../../actions/widget/analytics/downloadsWebAnalytics.actions'

import {
  sunshine,
  citrus,
  tangerine,
  spice,
  cherry,
  lime,
  avocado,
  sky,
  q4Blue,
  plum
} from '../../../resources/materialui-overrides/colors'

const initialState = {
  status: IDLE,
  items: [],
  colors: [
    { value: sunshine },
    { value: citrus },
    { value: tangerine },
    { value: spice },
    { value: cherry },
    { value: lime },
    { value: avocado },
    { value: sky },
    { value: q4Blue },
    { value: plum }
  ]
}

const downloadsWebAnalytics = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOWNLOADS_ANALYTICS_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_DOWNLOADS_ANALYTICS_SUCCESS:
      return {
        ...state,
        items: (action.payload && action.payload.rows),
        status: FETCHED
      }
    case FETCH_DOWNLOADS_ANALYTICS_ERROR:
      return {
        ...state,
        error: action.error,
        status: FAILED
      }
    default:
      return state
  }
}

export default downloadsWebAnalytics
