import gql from 'graphql-tag'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'

const GET_TAGS = gql`
  query Tag(
    $entityId: String
    $entityType: String
    $search: String
    $limit: Int! = ${23}
  ) {
    tag(
      entityId: $entityId
      entityType: $entityType
      search: $search
      limit: $limit
    ) {
      items {
        id
        name
      }
    }
  }`

const CREATE_TAG = gql`
  mutation Tag(
    $entity: TagEntity!
    $name: String!
  ) {
    tag {
      create(
        entity: $entity
        name: $name
      ) {
        items {
          id
        }
      }
    }
  }`

const DELETE_TAG = gql`
  mutation Tag(
    $id: [String]!
    $entityId: [String]!
    $entityType: String!
  ) {
    tag {
      delete(
        id: $id
        entityId: $entityId
        entityType: $entityType
      ) {
        count
      }
    }
  }`

/**
 * Tag get
 * @param options
 */
export function useTagQuery (options) {
  return useQuery(GET_TAGS, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Tag search
 * @param options
 */
export function useTagSearchQuery (options) {
  return useLazyQuery(GET_TAGS, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Tag create
 * @param options
 */
export function useTagCreateQuery (options) {
  return useMutation(CREATE_TAG, options)
}

/**
 * Tag delete
 * @param options
 */
export function useTagDeleteQuery (options) {
  return useMutation(DELETE_TAG, options)
}
