import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

/**
 * Quick Search Action Type Constants
 * @type {string}
 */
export const GET_QUICK_SEARCH_REQUEST = 'GET_QUICK_SEARCH_REQUEST'
export const GET_QUICK_SEARCH_SUCCESS = 'GET_QUICK_SEARCH_SUCCESS'
export const GET_QUICK_SEARCH_FAILURE = 'GET_QUICK_SEARCH_FAILURE'

/**
 * Get quick search
 * @param params
 * @returns {{[p: string]: *}}
 * @private
 */
const _getQuickSearch = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_QUICK_SEARCH_REQUEST,
      GET_QUICK_SEARCH_SUCCESS,
      GET_QUICK_SEARCH_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/search/quick',
    payload: Object.assign({
      pageSize: 5
    }, params)
  }
})

/**
 * Dispatch GET_QUICK_SEARCH_REQUEST
 */
export const getSecQuickSearch = (query, options) => (dispatch) => {
  return dispatch(_getQuickSearch(query, options))
}
