import React from 'react'
import moment from 'moment-timezone'
import { flatten, uniqBy } from 'lodash'
import { formatEstimatesSalesValue, format } from './number.util'

/**
 * Format rating as to not have very large box size
 * @param rating
 * @returns {*}
 */
export const formatRating = (rating) => {
  switch (rating) {
    case 'Overweight':
      return 'Ovwt'
    case 'Underweight':
      return 'Unwt'
    default:
      return rating
  }
}

/**
 * Get metric option from metric key
 * @param metric
 * @returns {*}
 */
export const getMetricOption = (metric) => {
  let option = { label: 'Earnings Per Share', value: 'eps' }

  switch (metric) {
    case 'ffo':
      option = { label: 'Funds From Operations', value: 'ffo' }
      break
    case 'ebitda':
      option = { label: 'EBITDA', value: 'ebitda' }
      break
    case 'eps':
    default:
      option = { label: 'Earnings Per Share', value: 'eps' }
  }

  return option
}

/**
 * Get unique data points, sorted by date and frequency
 * @param items
 * @returns {Array}
 */
export const getUniquelySortedDataPoints = (items) => {
  // Flatten all the data points
  const dataArrays = flatten((items || []).map((item) => item && item.data))

  // Pick out the unique dates
  const uniqueDataArrays = uniqBy((dataArrays || []), (dataItem) => {
    return dataItem && `${dataItem.date}_${dataItem.frequency}`
  })

  // Sort unique data points by date or frequency (annual always after quarter)
  uniqueDataArrays.sort((a, b) => {
    const dateDiff = moment.utc(a.date).diff(moment.utc(b.date))
    return dateDiff === 0 ? (b.frequency && b.frequency.length) - (a.frequency && a.frequency.length) : dateDiff
  })

  return uniqueDataArrays
}

/**
 * Generate column key
 * @param date
 * @param type
 * @param frequency
 * @param quarter
 * @returns {string}
 */
export const getPeriodKey = (date, type, frequency, quarter) => {
  const formattedDate = date && moment.utc(date).format('YYYY')
  const formattedType = type && type === 'actual' ? 'A' : 'E'

  if (frequency === 'annual') {
    return `${formattedDate}FY${formattedType}`
  } else {
    return `${formattedDate}Q${quarter}${formattedType}`
  }
}

/**
 * Get JSX label for each table column
 * @param date
 * @param type
 * @param frequency
 * @param quarter
 * @param noBreak
 * @returns {XML}
 */
export const getPeriodLabel = (date, type, frequency, quarter, noBreak) => {
  const formattedDate = date && moment.utc(date).format('MMM YY')
  const formattedType = type ? type === 'actual' ? '(A)' : '(E)' : ''

  if (frequency === 'annual') {
    return <span>{formattedDate}{noBreak ? ' ' : <br />}FY {formattedType}</span>
  } else {
    return <span>{formattedDate}{noBreak ? ' ' : <br />}Q{quarter} {formattedType}</span>
  }
}

/**
 * Format value based on metric type
 * @param value
 * @param metric
 * @returns {*}
 */
export const formatValue = (value, metric) => {
  if (!value) {
    return '-'
  }

  if (metric === 'eps' || metric === 'ffo' || metric === 'pershare') {
    return format(value, 2, 'dash')
  } else {
    return formatEstimatesSalesValue(value)
  }
}
