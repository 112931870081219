import React from 'react'
import { format, roundSmallNum, formatLocalizedDate } from '../../../../../../utils'
import './cell.component.css'

const EMPTY_PLACEHOLDER = '-'

/**
 * Title Custom Cell Component
 */
const TitleCell = ({ item }) => {
  return (
    <div className='cell cell--title'>
      <i className={`cell_icon q4i-security-2pt`} />
      <div className='cell_title'>
        <div className='cell_title--name'>
          {item.company_name}
        </div>
      </div>
    </div>
  )
}

/**
 * Render cell color depend on number's value.
 * @param item
 * @returns span
 */
const RenderCellDataColor = ({item, isChange}) => {
  const roundData = roundSmallNum(item)
  const rowData = item === undefined  ? EMPTY_PLACEHOLDER : format(roundData)
  const itemClassName = isChange ? item > 0 ? '--increase' : item < 0 ? '--decrease' : '' : ''

  return (
    <span className={`peer-analysis_table${itemClassName}`}>{rowData}</span>
  )
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 */
const CustomCell = ({ data = {}, column = {} }) => {
  const themeChange = data.isChange
  const cellData = data[column.colId]
  switch (column.colId) {
    case 'company_name':
      return <TitleCell item={data} />
    case 'latest': {
      return <RenderCellDataColor item={cellData} isChange={themeChange} />
    }
    case 'latest_date': {
      const date = cellData && formatLocalizedDate(cellData, 'MM/DD/YY')
      return date || EMPTY_PLACEHOLDER
    }
    case 'qrt_date_1':
    case 'qrt_date_2':
    case 'qrt_date_3':
    case 'qrt_date_4':
      return <RenderCellDataColor item={cellData} isChange={themeChange} />
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
