import React from 'react'

// style
import './cell.component.css'

// utils
import { getClassName, ENTITY_TYPE } from '../../../../../../../utils'
import { get } from 'lodash'

const { FUND, INSTITUTION } = ENTITY_TYPE
const EMPTY_PLACEHOLDER = '-'

/**
 * Title Cell Component
 * @param props
 */
const TitleCell = ({ data, onUtilityClick, isExpandable, isOpen, onExpand }) => {
  const {
    fundId, institutionId, fundName, institutionName, holderType, activist, isNested, dealConnection, targetConnection
  } = (data || {})
  const entityType = (holderType === FUND) ? FUND : INSTITUTION

  const isNotIdDefined = holderType === FUND ? !fundId : !institutionId

  const name = get(data, `${entityType}Name`, '')
  const country = get(data, `${entityType}CountryName`)

  const className = getClassName('cell_toggle', [
    { condition: isOpen, trueClassName: 'cell_toggle--open' }
  ])

  const disableNameClassName = getClassName('cell_content-name', [
    { condition: isNotIdDefined, trueClassName: 'disable-name' }
  ])

  const target = get(targetConnection, 'items[0].id')
  const hasDeals = !!(dealConnection && dealConnection.items && dealConnection.items.length)
  const entity = { entityType, hasDeals, target }

  return (
    <div className='cell'>
      <i className={`cell_icon cell_icon--${entityType} q4i-${entityType}-2pt`} />
      <div className='cell_content'>
        <div className={disableNameClassName}>{name}</div>
        {country ? <div className='cell_content-detail'>{country}</div> : null}
      </div>

      {activist && <i className='cell_indicator cell_indicator--activist q4i-activist-4pt' />}
      {!!target && <i className='cell_indicator cell_indicator--target q4i-targeting-4pt' />}
      {(entityType !== 'insider' && !isNotIdDefined) &&
        <i
          className='cell_indicator cell_indicator--utility q4i-utility-4pt'
          onClick={(event) => onUtilityClick(event, {
            ...(entityType === FUND)
              ? { id: fundId, fundName, ...entity }
              : { id: institutionId, institutionName, ...entity }
          })}
        />}

      {isNested && <div className='cell_space' />}
      {isExpandable && <div className={className} onClick={onExpand}><i className='q4i-caret-sm-down-4pt' /></div>}
      {(entityType === INSTITUTION) && !isExpandable && <div className='cell_space' />}
    </div>
  )
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 * @param api
 * @param node
 * @param agGridReact
 * @param onUtilityClick
 */
const CustomCell = ({ data = {}, column = {}, api, node, agGridReact, onUtilityClick }) => {
  const cellData = get(data, `${column.colId}`)
  const pagination = get(agGridReact, 'props.paginationProps', {})
  const page = get(pagination, 'forcePage', 1)
  const limit = get(pagination, 'initialPageSize', 10)

  switch (column.colId) {
    case 'rank':
      if (!cellData) return null
      return (page - 1) * limit + cellData
    case 'fundName':
    case 'institutionName':
      return (
        <TitleCell
          data={data}
          onUtilityClick={onUtilityClick}
          isExpandable={Boolean((get(data, 'fundHoldingConnection.items') || []).length)}
          isOpen={node.expanded}
          onExpand={(event) => {
            event.stopPropagation()
            node.setExpanded(!node.expanded)
            api.redrawRows({ rowNodes: [node] })
          }}
        />
      )
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
