import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { DatePicker as Picker } from 'antd'
import { Error } from '../../form'
import { getClassName, THEMES, getLocalizedFormat } from '../../../utils'
import '../datePicker.scss'
import './datePicker.component.scss'

const propTypes = {
  dataId: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.oneOf([
    THEMES.LIGHT,
    THEMES.DARK,
    THEMES.WHITE,
    THEMES.RAIN,
    THEMES.INK
  ]),
  label: PropTypes.string,
  size: PropTypes.oneOf(['default', 'large']), // 32, 40
  mode: PropTypes.oneOf(['date', 'month', 'year']),
  format: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  min: PropTypes.object,
  max: PropTypes.object,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

const defaultProps = {
  theme: THEMES.LIGHT,
  size: 'large',
  mode: 'date',
  format: 'MM/DD/YYYY',
  placeholder: 'Select date',
  error: {},
  clearable: false,
  disabled: false,
  required: false
}

/**
 * SomeComponent
 * @param props
 */
function DatePicker (props) {
  const {
    dataId, className, theme, size, label, mode, format, placeholder, error: { isError, message },
    defaultValue, value, min, max, clearable, disabled, required, onChange
  } = props

  const dateFormat = getLocalizedFormat(format)
  return (
    <>
      {label && <label className='date-picker_label'>
        {label} {!!required && <span className='date-picker_label--required'>(required)</span>}
      </label>}

      <Picker
        data-id={dataId}
        className={getClassName('date-picker', [
          { condition: className, trueClassName: className },
          { condition: theme, trueClassName: `date-picker--${theme}` },
          { condition: isError, trueClassName: 'date-picker--error' }
        ])}
        dropdownClassName={getClassName('date-picker-modal', [
          { condition: className, trueClassName: className },
          { condition: theme, trueClassName: `date-picker-modal--${theme}` }
        ])}
        size={size}
        mode={mode}
        format={dateFormat}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        allowClear={clearable}
        disabledDate={(current) => (min || max) && (current < min || current > max)}
        disabled={disabled}
        showToday={false}
        onChange={onChange}
      />

      {isError && <Error message={message} />}
    </>
  )
}

DatePicker.propTypes = propTypes
DatePicker.defaultProps = defaultProps

export default memo(DatePicker)
