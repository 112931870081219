import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { isEqual, throttle } from 'lodash'
import Highcharts from 'highcharts'
import Highstock from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { getClassName, THEMES } from '../../../utils/ui'
import {
  getFormattedColumns,
  processPivotHeaders,
  processPivotGroupHeaders,
  getAutoGroupColumnDef,
  getChartConfigLayout,
  getChartConfig,
  getOverwrittenData,
  getCompleteSeries,
  getIsReportType,
  getNoRowsOverlayParams,
  stockEntityType,
  emptyStateTitle,
  emptyStateMessages
} from '../../../utils/report'
import { FETCHING, FAILED } from '../../../actions/report'
import { AgGrid, NoContentMessage, RenderHTML, Spinner } from '../../../components'
import './reportWidget.component.css'

/**
 * Report Widget Item Component
 */
class ReportWidget extends Component {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.handleGridResize = throttle(this.handleGridResize, 1000)
    this.state = {
      chartConfig: null
    }
  }

  /**
   * ShouldComponentUpdate
   * Classic PureComponent comparison logic with a bit of flavour for eliminating ag-grid rendering
   * @param nextProps
   * @param nextState
   */
  shouldComponentUpdate (nextProps, nextState) {
    const { title, widgetType, fields, data, dataId, dataStatus, isPivot, pivotFields, showTitle } = this.props

    let shouldComponentUpdate = (
      !isEqual(this.props, nextProps) ||
      !isEqual(this.state, nextState) ||
      this.props.chartOptions !== nextProps.chartOptions
    )

    if (shouldComponentUpdate && (widgetType === 'table' && nextProps.widgetType === 'table')) {
      shouldComponentUpdate = (
        !isEqual(fields, nextProps.fields) ||
        (dataId !== nextProps.dataId) ||
        (dataStatus !== nextProps.dataStatus) ||
        (title !== nextProps.title) ||
        (showTitle !== nextProps.showTitle) ||
        (isPivot !== nextProps.isPivot) ||
        (!isEqual(pivotFields, nextProps.pivotFields)) ||
        (!isEqual(data, nextProps.data))
      )
    }

    return shouldComponentUpdate
  }

  /**
   * ComponentDidUpdate
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    const { height, showTitle, fields, data, dataStatus, chartOptions, widgetPadding, widgetType, pivotFields, isPivot } = this.props
    const isFieldsChanged = !isEqual(fields, prevProps.fields)
    const isPivotFieldsChanged = !isEqual(pivotFields, prevProps.pivotFields)
    const isChartOptionsChanged = !isEqual(chartOptions, prevProps.chartOptions)
    const isPivotChanged = isPivot !== prevProps.isPivot
    const isDataStatusChanged = dataStatus !== prevProps.dataStatus

    if (isFieldsChanged || isPivotFieldsChanged || isPivotChanged) {
      this.handleGridResize()
    }

    if (prevProps.widgetType === 'table' && widgetType !== 'table') {
      this.agGrid = null
    }

    if (!this.state.chartConfig || isDataStatusChanged || isFieldsChanged || isChartOptionsChanged) {
      this.setState({
        chartConfig: data && data.length
          ? getChartConfig(height, fields, data, showTitle, chartOptions, widgetPadding)
          : null
      })
    }
  }

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   */
  handleGridReady = (grid) => {
    this.agGrid = grid
    this.handleGridResize()
  }

  /**
   * Handle AgGrid onRowDataChanged event
   */
  handleGridResize = () => {
    if (this.props.widgetType === 'table' && this.agGrid) {
      this.agGrid.api.sizeColumnsToFit()
    }
  }

  /**
   * Get props for the NoRowsOverlay component
   */
  _getNoRowsOverlayParams = () => {
    const { fields, dataStatus } = this.props
    return getNoRowsOverlayParams(fields, dataStatus)
  }

  /**
   * Render Widget Item
   * @param widgetType
   */
  renderWidgetItem = (widgetType) => {
    switch (widgetType) {
      case 'text':
        this.agGrid = null
        return this.renderTextWidget()
      case 'chart':
        this.agGrid = null
        return this.renderChartWidget()
      case 'table':
      default:
        return this.renderTableWidget()
    }
  }

  /**
   * Render Text Widget
   */
  renderTextWidget = () => {
    const { data } = this.props
    const placeholder = (
      '<p style=\'margin: 4px 0; font-size: 48px; color: #939BA0; text-align: center;\'>Click and edit to add text</p>'
    )
    const html = data && typeof data === 'object'
      ? data.html
      : data

    return (
      <div className='report-widget_inner'>
        <RenderHTML
          html={html || placeholder}
        />
      </div>
    )
  }

  /**
   * Render Table Widget
   */
  renderTableWidget = () => {
    const { title, showTitle, data, sort, fields, entityTypes, isPivot, pivotFields } = this.props

    const reportDataConfig = {
      entityType: entityTypes,
      sort,
      fields,
      isPivot,
      pivotFields
    }

    const columnDefs = getFormattedColumns(reportDataConfig)
    const rowData = getOverwrittenData(data, fields)

    const autoGroupColumnDef = getAutoGroupColumnDef(fields, entityTypes)
    const isPinned = columnDefs.find((column) => column.rowGroup)

    return (
      <>
        {showTitle && (
          <h3 className='report-widget_title'>{title}</h3>
        )}
        <div className='report-widget_inner'>
          <AgGrid
            className='report-builder-grid'
            suppressPropertyNamesCheck={true}
            isPivot={isPivot}
            isPinned={isPinned}
            width='100%'
            height='100%'
            rowDensity='comfy'
            sizeToFit={true}
            frameworkComponents={{
              customNoRowsOverlay: NoContentMessage
            }}
            noRowsOverlayComponent='customNoRowsOverlay'
            noRowsOverlayComponentParams={this._getNoRowsOverlayParams}
            columnDefs={columnDefs}
            rowData={rowData}
            rowBuffer={0}
            alwaysShowVerticalScroll={true}

            autoGroupColumnDef={autoGroupColumnDef}
            processSecondaryColGroupDef={processPivotGroupHeaders}
            processSecondaryColDef={processPivotHeaders}
            suppressAggFuncInHeader={true}
            groupHideOpenParents={true}
            groupDefaultExpanded={-1}

            asyncTransactionWaitMillis={1500}
            pagination={false}

            onGridReady={this.handleGridReady}
            onRowDataChanged={this.handleGridResize}
            onGridSizeChanged={this.handleGridResize}
          />
        </div>
      </>
    )
  }

  /**
   * Render NoContentMessage component
   * @param fields
   * @param dataStatus
   * @param noOptions
   * @returns {*}
   */
  renderNoContentMessage = (fields, dataStatus, noOptions) => {
    const isFailed = dataStatus === FAILED
    let emptyStateMessage = isFailed ? emptyStateMessages.snapshotFailed : emptyStateMessages.noDataAvailable

    if (noOptions) {
      emptyStateMessage = emptyStateMessages.missingChartConfig
    }

    if (!fields.length) {
      emptyStateMessage = emptyStateMessages.missingReportConfig
    }

    return (
      <NoContentMessage
        theme={THEMES.TRANSPARENT}
        image={require('../../../resources/images/noContent/report_no_color.png').default}
        title={emptyStateTitle}
        message={emptyStateMessage}
      />
    )
  }

  /**
   * Render Bar Chart
   */
  renderChartWidget = () => {
    const { height, title, showTitle, entityTypes, fields, data, chartOptions, widgetPadding, dataStatus } = this.props
    const { chartType, xAxis, series } = chartOptions
    const config = this.state.chartConfig
      ? { ...this.state.chartConfig, ...getChartConfigLayout(height, showTitle, chartOptions, widgetPadding) }
      : getChartConfig(height, fields, data, showTitle, chartOptions, widgetPadding)

    const isStockReport = getIsReportType(entityTypes, stockEntityType)
    const completeSeries = getCompleteSeries(series)
    const noData = !fields || !fields.length || !data || !data.length
    const noOptions = !chartType || !xAxis || !completeSeries || !completeSeries.length

    if (noData || noOptions) {
      return (
        <Fragment>
          {showTitle && (
            <h3 className='report-widget_title'>{title}</h3>
          )}
          <div className='report-widget_inner'>
            {this.renderNoContentMessage(fields, dataStatus, noOptions)}
          </div>
        </Fragment>
      )
    }

    return (
      <Fragment>
        {showTitle && (
          <h3 className='report-widget_title'>{title}</h3>
        )}
        <div className='report-widget_inner'>
          <HighchartsReact
            className='report-widget_chart'
            highcharts={isStockReport ? Highstock : Highcharts}
            constructorType={isStockReport ? 'stockChart' : 'chart'}
            options={config}
            immutable={true}
          />
        </div>
      </Fragment>
    )
  }

  /**
   * Render ReportWidget component
   * @returns {XML}
   */
  render () {
    const { widgetType, dataStatus } = this.props

    const baseClassName = getClassName('report-widget', [
      { condition: widgetType, trueClassName: `report-widget--${widgetType}` }
    ])

    return (
      <section className={baseClassName}>
        {dataStatus === FETCHING && widgetType !== 'text' ? (
          <Spinner
            theme='rain'
            mask={true}
          />
        ) : this.renderWidgetItem(widgetType)}
      </section>
    )
  }
}

const ChartWidgetDataType = PropTypes.array
const DepricatedTextEditWidgetDataType = PropTypes.string
const TextEditWidgetDataType = PropTypes.shape({
  text: PropTypes.string,
  html: PropTypes.string,
  styles: PropTypes.object
})

ReportWidget.propTypes = {
  /**
   * Used to determine what style the widget will render the data in
   */
  widgetType: PropTypes.oneOf(['table', 'text', 'chart']),

  /**
   * Used to determine what height the widget should render with
   */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Used to determine the amount of padding within the widget
   */
  widgetPadding: PropTypes.number,

  /**
   * The title of the widget
   */
  title: PropTypes.string,

  /**
   * Entity types
   */
  entityTypes: PropTypes.arrayOf(PropTypes.shape({
    isPrimary: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired
  })),

  /**
   * Fields
   */
  fields: PropTypes.arrayOf(PropTypes.shape({
    columnName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sortable: PropTypes.bool,
    type: PropTypes.string.isRequired,
    width: PropTypes.number,
    _entityType: PropTypes.object,
    _filter: PropTypes.object
  })),

  /**
   * Filters
   */
  filters: PropTypes.arrayOf(PropTypes.shape({
    filterOptions: PropTypes.array,
    filterType: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    _field: PropTypes.object
  })),

  /**
   * Limiting
   */
  limit: PropTypes.arrayOf(PropTypes.shape({
    _entityType: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  })),

  /**
   * Sorting
   */
  sort: PropTypes.arrayOf(PropTypes.shape({
    _field: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired
  })),

  /**
   * Indices
   */
  indices: PropTypes.arrayOf(PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    group: PropTypes.string.isRequired,
    fullname: PropTypes.string.isRequired
  })),

  /**
   * Used to determine whether or not the grid has pivot enabled
   */
  isPivot: PropTypes.bool,

  /**
   * Used to supply the widget with data
   * Note: Expected data format is different based on widgetType
   */
  data: PropTypes.oneOfType([ChartWidgetDataType, TextEditWidgetDataType, DepricatedTextEditWidgetDataType]),

  /**
   * Used to identify the set of data
   */
  dataId: PropTypes.string,

  /**
   * Used to determine the status of the data (loading, idle, etc.)
   */
  dataStatus: PropTypes.string,

  /**
   * Chart Options
   */
  chartOptions: PropTypes.shape({
    chartType: PropTypes.oneOf(['bar', 'column', 'pie', 'line', 'spline', 'combo']),
    labelField: PropTypes.string,
    valueFields: PropTypes.arrayOf(PropTypes.string)
  })
}

ReportWidget.defaultProps = {
  title: 'New Source Data',
  widgetPadding: 0,
  widgetType: 'table',

  dataId: null,
  dataStatus: null,

  entityTypes: [],
  fields: [],
  filters: [],
  limit: [],
  sort: [],
  indices: [],
  isPivot: false,

  chartOptions: {
    chartType: 'bar',
    labelField: null,
    valueFields: [],
    quarter: null
  }
}

export default ReportWidget
