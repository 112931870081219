import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { map } from 'lodash'
import Switch from '../../shared/switch/switch.component'
import RangeTab from '../../shared/rangeTab/rangeTab.component'

class DashboardHeader extends Component {

    /**
     * Render RangeTab of available user dashboards
     * @returns {XML}
     */
    renderDashboardNavigation = () => {
        const { dashboards, activeDashboardId, setActiveDashboard, openDashboardManager, theme } = this.props

        const buttons = map((dashboards || []), (dashboard) => {
            return {
                label: dashboard.title,
                value: dashboard._id,
                active: dashboard._id === activeDashboardId,
                onClick: (event, value) => setActiveDashboard(value)
            }
        })

        return (
            <RangeTab
                buttons={[
                    ...buttons,
                    {
                        label: '',
                        icon: 'q4i-more-4pt',
                        value: null,
                        active: false,
                        onClick: openDashboardManager
                    }
                ]}
                theme={theme === 'dark' ? 'dark' : 'soft-grey'}
            />
        )
    }

    /**
     * Render Dashboard View Header
     * @returns {XML}
     */
    render () {
        const { theme, fullscreen, onChangeTheme, onSetFullscreen } = this.props

        return (
            <div className='dashboard_header'>
                <div className='dashboard_contain'>
                    <div className='dashboard_actions dashboard_actions--left'>
                        <div className='dashboard_views'>
                            {this.renderDashboardNavigation()}
                        </div>
                    </div>
                    <div className='dashboard_actions dashboard_actions--right'>
                        <Switch
                            className='dashboard_action'
                            onChange={onChangeTheme}
                            isChecked={theme === 'dark'}
                            theme={theme}
                            labelLeft='LIGHT'
                            labelRight='DARK'
                        />
                        <Link className='dashboard_action' to='/dashboard/edit'>
                            <i className='q4i-cog-4pt'/>
                        </Link>
                        <span className='dashboard_action' onClick={() => onSetFullscreen(!fullscreen)}>
                            <i className={fullscreen ? 'q4i-fullscreen-exit-4pt' : 'q4i-fullscreen-4pt'}/>
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

DashboardHeader.propTypes = {
    theme: PropTypes.string.isRequired,
    dashboards: PropTypes.array.isRequired,
    activeDashboardId: PropTypes.string.isRequired,
    fullscreen: PropTypes.bool.isRequired,
    setActiveDashboard: PropTypes.func.isRequired,
    onChangeTheme: PropTypes.func.isRequired,
    onSetFullscreen: PropTypes.func.isRequired,
    openDashboardManager: PropTypes.func.isRequired,
}

export default DashboardHeader
