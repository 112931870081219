import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { getClassName } from '../../../utils/ui'
import { Message } from '../../../components'
import RemovableListItem from './item/removableListItem.component'
import './removableList.component.css'

/**
 * Removable List component
 * Parent of RemovableListItem component
 */
class RemovableList extends PureComponent {

  constructor (props) {
    super(props)

    this.state = {
      isMessageOpen: false,
      itemValueToRemove: null
    }

    // initialize node height management if 'auto' size is used
    if (props.size === 'auto') {
      this.itemNodeHeights = {}
    }
  }

  /**
   * Handle removal of the list item
   */
  handleRemove = () => {
    const { onRemove } = this.props
    const { itemValueToRemove } = this.state

    onRemove && onRemove(itemValueToRemove)
    this.handleMessageClose()
  }

  /**
   * Handle opening of delete confirmation for modal
   * @param value
   */
  handleMessageOpen = (value) => {
    this.setState({
      isMessageOpen: true,
      itemValueToRemove: value
    })
  }

  /**
   * Handle closing of delete confirmation for modal
   */
  handleMessageClose = () => {
    this.setState({
      isMessageOpen: false,
      itemValueToRemove: null
    })
  }

  /**
   * Set RemovableListItem node height for styling reference
   * Note: only applicable to 'auto' size lists
   * @param removableListItemNode
   */
  setItemNodeHeight = (removableListItemNode) => {
    if (!removableListItemNode || !removableListItemNode.props || !removableListItemNode.componentRef) {
      return
    }

    const { props, componentRef } = removableListItemNode
    const { value } = props
    const { current } = componentRef

    this.itemNodeHeights[value] = current.clientHeight
  }

  /**
   * Get RemovableListItem transition styles
   * Note: only applicable to 'auto' size lists
   * @param state
   * @param value
   * @returns {*}
   */
  getItemStyles = (state, value) => {
    let styles

    switch (state) {
      case 'entering':
      case 'entered':
        styles = {
          height: this.itemNodeHeights[value],
          opacity: 1
        }
        break
      case 'exiting':
      case 'exited':
        styles = {
          height: 0,
          opacity: 0
        }
        break
      default:
        styles = null
    }

    return styles
  }

  render () {
    const { dataId, className, theme, size, items, removeMessageProps, customItemRender, onRemove } = this.props
    const { isMessageOpen } = this.state

    if (!items || !Array.isArray(items)) {
      return null
    }

    const baseClassName = getClassName('removable-list', [
      { condition: className, trueClassName: className },
      { condition: theme, trueClassName: `removable-list--${theme}` },
    ])

    return (
      <Fragment>
        <TransitionGroup data-id={dataId} className={baseClassName} component='ul'>
          {items.map((removableItem, index) => {
            if (!removableItem) {
              return null
            }

            const { className, value, label, isLocked, shouldWarnOnRemove, item } = removableItem

            return (
              <CSSTransition
                key={`removable-list-item--${value || index}`}
                classNames='removable-list-item-'
                appear={true}
                timeout={300}
                mountOnEnter
                unmountOnExit
              >{(state) => (
                <RemovableListItem
                  ref={size === 'auto' ? this.setItemNodeHeight : null}
                  value={value}
                  className={className}
                  style={size === 'auto' ? this.getItemStyles(state, value) : null}
                  theme={theme}
                  size={size}
                  label={label}
                  isLocked={isLocked}
                  shouldWarnOnRemove={shouldWarnOnRemove}
                  customItemRender={customItemRender}
                  item={item}
                  onRemoveWarn={this.handleMessageOpen}
                  onRemove={onRemove}
                />
              )}</CSSTransition>
            )
          })}
        </TransitionGroup>

        <Message
          visible={isMessageOpen}
          type='warning'
          onClose={this.handleMessageClose}
          buttons={[{
            ui: 'shaded',
            label: 'cancel',
            onClick: this.handleMessageClose
          }, {
            ui: 'spice',
            label: 'confirm',
            onClick: this.handleRemove
          }]}
          {...removeMessageProps}
        />
      </Fragment>
    )
  }
}

RemovableList.propTypes = {
  /**
   * Component data id
   */
  dataId: PropTypes.string,

  /**
   * A custom className to pass into the component
   */
  className: PropTypes.string,

  /**
   * Used to paint the list items using a specific theme
   */
  theme: PropTypes.string,

  /**
   * Used to paint the list items using a specific height
   * Small: 30px; Default: 40px; Large: 60px;
   */
  size: PropTypes.oneOf(['small', 'default', 'large', 'auto']),

  /**
   * Items to pass into the component
   */
  items: PropTypes.arrayOf(PropTypes.shape({
    /**
     * A custom className to pass into the component
     */
    className: PropTypes.string,

    /**
     * A unique value string used to identify the component
     * Note: this value will be passed when attempting to remove the item
     */
    value: PropTypes.string.isRequired,

    /**
     * The default label prop to determine label text or render
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    /**
     * Used to determine whether or not the item can be removed
     */
    isLocked: PropTypes.bool,

    /**
     * Used to determine whether or not a confirmation message is rendered upon attempted removal of the item
     */
    shouldWarnOnRemove: PropTypes.bool,

    /**
     * The object passed in as an argument when executing the customItemRender function
     */
    item: PropTypes.object
  })).isRequired,

  /**
   * Props to pass into the Message component when looking to warn the user that the items will be removed
   */
  removeMessageProps: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string
  }),

  /**
   * A method used to render custom elements within the component's label container
   * Note: the item key of the items list will be passed as an argument into this function
   */
  customItemRender: PropTypes.func,

  /**
   * A callback for when the user attempts to remove the item
   */
  onRemove: PropTypes.func.isRequired
}

RemovableList.defaultProps = {
  removeMessageProps: {
    title: 'Delete Item?',
    message: 'Are you sure you would like to remove this item?'
  }
}

export default RemovableList
