export const DEBOUNCE_DEFAULT_DELAY = 400

export const THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
  SLATE: 'slate',
  SPICE: 'spice',
  DARK_SLATE: 'dark-slate',
  CHARCOAL: 'charcoal',
  LIGHT_SLATE: 'light-slate',
  LIGHT_GREY: 'light-grey',
  PALE_GREY: 'pale-grey',
  SOFT_GREY: 'soft-grey',
  STEEL: 'steel',
  TEAL: 'teal',
  RAIN: 'rain',
  Q4_BLUE: 'q4-blue',
  INK: 'ink',
  DEEP_BLUE: 'deep-blue',
  SILVER: 'silver',
  CITRUS: 'citrus',
  CHERRY: 'cherry',
  APPLE: 'apple',
  EGGPLANT: 'eggplant',
  WALNUT: 'walnut',
  WHITE: 'white',
  WHITES: 'whites', // dropdown list has 'whites'
  WHITE_STEEL: 'white-steel',
  WHITE_RAIN: 'white-rain',
  Q4_BLUE_WHITE: 'q4-blue-white',
  TRANSPARENT: 'transparent'
}
