import React, { useRef, useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import moment from 'moment-timezone'
import { Button } from '../../../index'
import { getClassName, isSubscribed, isReportingWindow, DEFAULT_DATE_FORMAT, formatDate, formatLocalizedDate } from '../../../../utils'
import { capitalize, get } from 'lodash'

import './chart.component.scss'

const propTypes = {
  active: PropTypes.bool.isRequired,
  height: PropTypes.number,
  position: PropTypes.array,
  peers: PropTypes.array,
  stock: PropTypes.array,
  events: PropTypes.array,
  activity: PropTypes.array,
  news: PropTypes.array,
  subscription: PropTypes.array,
  ownership: PropTypes.string,
  inYears: PropTypes.bool,
  quarters: PropTypes.number,
  startDate: PropTypes.instanceOf(Date),
  config: PropTypes.object
}

const defaultProps = {
  active: true,
  ownership: '13F',
  inYears: false,
  quarters: 5,
  config: {
    isLegend: true,
    isTearSheet: false,
    spacing: [24, 0, 20, 0],
    pointWidth: 60,
    animationDuration: 750,
    stockAxis: {
      labels: { x: 20, y: 12 },
      title: { x: 0, y: -12 }
    },
    positionAxis: {
      labels: { x: 0, y: 12 },
      title: { x: 0, y: -12 }
    },
    axisColor: '#939ba0',
    axisTitleColor: '#939ba0',
    axisLabelColor: '#939ba0',
    gridLineColor: '#373B41',
    positionColor: '#0f5ca3',
    peerPositionColor: '#94e9f6',
    stockColor: '#3498DB',
    yetStockColor: 'rgba(0, 0, 0, 0.2)',
    yetStockLabel: {
      y: 65,
      align: 'center'
    },
    labelFontSize: '11px',
    labelColor: '#ffffff',
    labelWeight: 'normal'
  }
}

const SERIES_ID = {
  POSITION: 'position',
  STOCK: 'stock',
  EVENTS: 'events',
  ACTIVITY: 'activity',
  NEWS: 'news'
}

const SERIES_NAME = {
  POSITION: 'My Position',
  STOCK: 'Stock',
  EVENTS: 'Events',
  ACTIVITY: 'Activity',
  NEWS: 'News'
}

/**
 * Entity Ownership Chart Component
 * @param props
 */
function Chart (props) {
  const highcharts = useRef()
  const initialFlags = {
    isActivity: true,
    isEvents: true,
    isNews: true
  }

  const { active, isLoading, subscription, theme } = props
  const [config, setConfig] = useState(null)
  const [isStock, setIsStock] = useState(true)
  const [flags, setFlags] = useState(initialFlags)

  useEffect(() => {
    props && setConfig(_getChartConfig(props, { isStock, flags }))
  }, [props, isStock, flags])

  /**
   * Handle Legend Item Click
   * @param event
   * @param id
   */
  function handleLegendClick (event, id) {
    const chart = get(highcharts, 'current.chart')
    const current = chart && chart.get(id)

    if (!current) {
      return
    }

    const classes = get(event, 'target.classList')
    classes && classes.toggle('button--off')

    if (id === SERIES_ID.STOCK) {
      setIsStock(!isStock)
      isStock && setFlags(initialFlags)
    }

    if ([SERIES_ID.ACTIVITY, SERIES_ID.EVENTS, SERIES_ID.NEWS].includes(id)) {
      setFlags({ ...flags, [`is${capitalize(id)}`]: !flags[`is${capitalize(id)}`] })
    }
  }

  /**
   * Render Chart Legend
   * @returns {*}
   */
  function getLegend () {
    const isCRMSubscribed = isSubscribed({ services: subscription }, 'crm')

    return (
      <div className={`entity-ownership_chart-legend ${!isLoading ? 'q4-fade-in' : ''}`}>
        {!isLoading && ((config && config.series) || []).map((item) => {
          const { id, name, dataId } = item
          return (id === SERIES_ID.POSITION)
            ? <div key={id} className='entity-ownership_chart-legend--position'>My Position</div>
            : (!id.includes('peer'))
                ? <Button
                    theme={theme}
                    key={id}
                    className={`button--${id}`}
                    dataId={dataId}
                    label={name}
                    hidden={(id === SERIES_ID.ACTIVITY) && !isCRMSubscribed}
                    disabled={(id !== SERIES_ID.STOCK) && !isStock}
                    onClick={(event) => handleLegendClick(event, id)}
                  />
                : null
        })}
      </div>
    )
  }

  return (config
    ? (
      <div className={getClassName('entity-ownership_chart q4-fade-in', [
        { condition: active, trueClassName: 'entity-ownership_chart--active' }
      ])}
      >
        <HighchartsReact
          ref={highcharts}
          highcharts={Highcharts}
          options={config}
        />
        {get(props.config, 'isLegend') ? getLegend() : null}
      </div>)
    : null
  )
}

/**
 * Get latest reported quarter from holdings data
 * @param position
 * @param inYears
 * @returns {Date}
 * @private
 */
const _getLatestReportedQtr = ({ position, inYears }) => {
  const latestReportedQtr = position && position.length && position[position.length - 1]
  const latestReportedQtrDate = inYears
    ? moment.utc().endOf('year').endOf('day')
    : latestReportedQtr
      ? moment.utc(latestReportedQtr.x).endOf('quarter').startOf('day')
      : moment.utc().subtract(1, 'quarter').endOf('quarter').startOf('day')

  return latestReportedQtrDate.toDate()
}

/**
 * Get min start date for chart to render
 * Offset chart start date using latest available reported qtr (differs between 13f/Surveillance)
 * @param position
 * @param quarters
 * @param inYears
 * @returns {Date}
 * @private
 */
const _getOffsetStartDate = ({ position, quarters, inYears }) => {
  const latestReportedQtrDate = _getLatestReportedQtr({ position, inYears })
  const offsetStartDate = moment.utc(latestReportedQtrDate).subtract(quarters - 1, 'quarter').startOf('day')
  const minStartDate = moment.utc().endOf('quarter').subtract(quarters, 'quarter').startOf('day')

  return minStartDate.isAfter(offsetStartDate) ? minStartDate.toDate() : offsetStartDate.toDate()
}

/**
 * Add 'Yet to File' if applicable, otherwise return original series
 * @param series
 * @param config
 * @param ownership
 * @param inYears
 * @returns []
 * @private
 */
const _getSeries = (series, { config, ownership = '13F', inYears }) => {
  const currentQtr = moment.utc().endOf('quarter').startOf('day')
  const latestQuarter = moment.utc(_getLatestReportedQtr({ position: series, inYears }))
  const prevQtr = inYears ? latestQuarter : currentQtr.clone().subtract(1, 'quarter').endOf('quarter').startOf('day')
  const hasNotFiled = latestQuarter.isBefore(currentQtr)
  const position = (series || []).find((series) => series.x === latestQuarter.valueOf())
  const hasLatestHistQtr = series.find((item) => moment.utc(item.x).isSame(prevQtr, 'quarter'))

  if (isReportingWindow()) {
    const sorted = series.map((item) => item.y).sort((a, b) => a - b)

    // TODO: inYears flag until logic for inYears is not confirmed
    if (ownership !== '13F' && !inYears && hasNotFiled && !(hasLatestHistQtr && hasLatestHistQtr.y)) {
      series = series.map((item) => {
        const isPrevQtr = moment.utc(item.x).isSame(prevQtr, 'quarter')

        if (isPrevQtr) {
          item.y = series.every((item) => (item && item.y === 0)) ? 1 : sorted[series.length - 1] // align 'Yet To File' with largest position
          item.z = 'Yet to File'
          item.color = config.yetStockColor
          item.dataLabels = config.yetStockLabel
        }
        return item
      })
    } else if (hasNotFiled && (position && position.y === 0) && !hasLatestHistQtr) {
      series.push({
        x: prevQtr.valueOf(),
        y: series.every((item) => (item && item.y === 0)) ? 1 : sorted[series.length - 1], // align 'Yet To File' with largest position
        z: 'Yet to File',
        color: config.yetStockColor,
        dataLabels: config.yetStockLabel
      })
    } else if (hasLatestHistQtr && position && position.change === 0) {
      series = series.map((item) => {
        const isPrevQtr = moment.utc(item.x).isSame(prevQtr, 'quarter')

        if (isPrevQtr) {
          item.z = 'Yet to File'
          item.color = config.yetStockColor
          item.dataLabels = config.yetStockLabel
        }
        return item
      })
    }
  }

  return series
}

/**
 * Filters series by subscription
 * @param series
 * @param subscription
 * @returns {[]}
 * @private
 */
const _filterSeriesBySubscription = (series, subscription) => {
  const isCRMSubscribed = isSubscribed({ services: subscription }, 'crm')
  return (series || []).filter((item) => !(item.id === SERIES_ID.ACTIVITY && !isCRMSubscribed))
}

/**
 * Format Position (Label)
 * @param position
 * @param decimal
 * @returns {string|*}
 * @private
 */
const _formatPosition = (position, decimal = 1) => {
  if (position >= 1000000) {
    return (position / 1000000).toFixed(decimal) + 'M'
  } else if (position >= 1000) {
    return (position / 1000).toFixed(decimal) + 'K'
  }

  return position.toFixed(0)
}

/**
 * Peer series config
 * @param peer
 * @param params
 * @returns {{}}
 * @private
 */
const _getPeerConfig = (peer, { config }) => {
  const { security, position } = peer
  const { tickerId, symbol, exchange } = security
  return {
    id: `peer-${tickerId}`,
    name: `Peer ${symbol} ${exchange}`,
    type: 'column',
    yAxis: 'position-axis',
    xAxis: 0,
    clip: false,
    color: config.peerPositionColor,
    zIndex: 1,
    data: _getSeries(position || [], { config })
  }
}

/**
 * Highstock chart config for series
 * @param props
 * @param state
 * @returns {*}
 * @private
 */
const _getSeriesConfig = (props, { isStock, flags }) => {
  const { config, position, peers, stock, news, events, activity, ownership, subscription, startDate, inYears } = props
  const { isActivity, isEvents, isNews } = flags

  const series = [
    {
      id: SERIES_ID.POSITION,
      name: SERIES_NAME.POSITION,
      type: 'column',
      yAxis: 'position-axis',
      xAxis: 0,
      clip: false,
      color: config.positionColor,
      zIndex: 1,
      data: _getSeries(position || [], { config, ownership, inYears })
    },
    {
      id: SERIES_ID.STOCK,
      name: SERIES_NAME.STOCK,
      dataId: SERIES_ID.STOCK,
      type: 'spline',
      yAxis: 'stock-axis',
      xAxis: 1,
      color: config.stockColor,
      lineWidth: 2,
      zIndex: 2,
      visible: isStock,
      data: stock || []
    },
    {
      id: SERIES_ID.ACTIVITY,
      name: SERIES_NAME.ACTIVITY,
      dataId: SERIES_ID.ACTIVITY,
      type: 'flags',
      xAxis: 1,
      color: '#f1af0f',
      fillColor: '#f1af0f',
      shape: 'circle',
      onSeries: SERIES_ID.STOCK,
      stackDistance: 0,
      showInLegend: true,
      visible: isStock && isActivity,
      style: {
        width: '8px',
        height: '8px'
      },
      y: -4,
      states: {
        hover: {
          fillColor: '#f1af0f'
        }
      },
      zIndex: 3,
      data: stock && (activity || [])
    },
    {
      id: SERIES_ID.EVENTS,
      name: SERIES_NAME.EVENTS,
      dataId: SERIES_ID.EVENTS,
      type: 'flags',
      xAxis: 1,
      color: '#1abc9c',
      fillColor: '#1abc9c',
      shape: 'circle',
      onSeries: SERIES_ID.STOCK,
      stackDistance: 0,
      showInLegend: true,
      visible: isStock && isEvents,
      style: {
        width: '8px',
        height: '8px'
      },
      y: -4,
      states: {
        hover: {
          fillColor: '#1abc9c'
        }
      },
      zIndex: 4,
      allowOverlapX: true,
      data: stock && (events || [])
    },
    {
      id: SERIES_ID.NEWS,
      name: SERIES_NAME.NEWS,
      dataId: SERIES_ID.NEWS,
      type: 'flags',
      xAxis: 1,
      color: '#fc7e26',
      fillColor: '#fc7e26',
      shape: 'circle',
      onSeries: SERIES_ID.STOCK,
      stackDistance: 0,
      showInLegend: true,
      visible: isStock && isNews,
      style: {
        width: '8px',
        height: '8px'
      },
      y: -4,
      states: {
        hover: {
          fillColor: '#fc7e26'
        }
      },
      zIndex: 4,
      data: stock && (news || [])
    }
  ]

  const peerSeries = (peers || []).map((peer) => 
    get(peer, 'security.tickerId') && _getPeerConfig(peer, { startDate, config })) 
  series.push(...peerSeries)

  return subscription ? _filterSeriesBySubscription(series, subscription) : series
}

/**
 * Base highstock chart config
 * @param props
 * @param state
 * @returns {*}
 * @private
 */
const _getChartConfig = (props, { isStock, flags }) => {
  const { config, width, height, position, ownership, quarters, inYears, stock } = props
  const startQtr = _getOffsetStartDate({ position, quarters, inYears })

  const getCurrency = () => {
    const currency = stock?.[0]?.currency
    return currency ? `(${currency})` : ''
  }
  const label = {
    stock: config.isLegend
      ? `Share Price ${getCurrency()}`
      : `<div class="entity-ownership_chart-label entity-ownership_chart-label--stock"></div> Share Price ${getCurrency()}`,
    position: config.isLegend
      ? 'Position'
      : `<div class="entity-ownership_chart-label entity-ownership_chart-label--position"></div> Position (${ownership})`
  }

  return {
    chart: {
      animation: false,
      spacing: config.spacing,
      backgroundColor: null,
      style: {
        fontFamily: 'Open Sans'
      },
      width,
      height
    },
    noData: {
      style: { display: 'none' }
    },
    credits: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    legend: { enabled: false },
    navigation: {
      buttonOptions: {
        enabled: false
      }
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        color: '#4696E0',
        maxPointWidth: config.pointWidth,
        groupPadding: 0.05,
        pointPadding: 0.05,
        minPointLength: 1,
        enableMouseTracking: false,
        allowPointSelect: false,
        dataLabels: {
          inside: false,
          enabled: config.isLegend,
          align: 'left',
          shadow: false,
          rotation: -90,
          y: -10,
          x: -5,
          style: {
            color: config.labelColor,
            fontWeight: config.labelWeight,
            textOutline: 'none'
          },
          zIndex: 3,
          formatter: function () {
            const symbol = get(this, 'point.description', '')
            if (this.point && this.point.z) {
              return config.isTearSheet ? `${this.point.z}` : `${this.point.z}<br /><span>${symbol}</span>`
            }

            if (this.y || this.y === 0) {
              return `${_formatPosition(this.y)}<br /><span>${symbol}</span>`
            }
          }
        }
      },
      flags: {
        allowPointSelect: true,
        enableMouseTracking: true,
        animation: false
      },
      spline: {
        allowPointSelect: false,
        enableMouseTracking: false,
        states: {
          hover: {
            halo: {
              opacity: 0,
              size: 0
            }
          }
        }
      },
      series: {
        minPointLength: 2,
        animation: {
          duration: Number(config.animationDuration)
        },
        marker: {
          enabled: false
        },
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    title: {
      text: null
    },
    tooltip: {
      borderRadius: 5,
      backgroundColor: '#ffffff',
      crosshairs: false,
      useHTML: true,
      animation: true,
      hideDelay: 800,
      formatter: function () {
        const formattedDate = formatDate(this.x, 'dddd, MMM D YYYY', true, true)
        const id = get(this, 'point.id')
        const text = get(this, 'point.options.text')

        return `<div class='chart_tip' data-id=${id}>
          <div class='chart_tip_header'>${formattedDate}</div>
          <div class='chart_tip_body'>${text}</div>
        </div>`
      },
      shared: true
    },
    xAxis: [
      {
        id: 'datetime-axis',
        type: 'datetime',
        lineWidth: 0,
        tickWidth: 0,
        floor: moment.utc(startQtr).subtract(1, 'quarter').add(1, 'week').valueOf(),
        ceiling: inYears
          ? moment.utc().endOf('year').subtract(1, 'week').valueOf()
          : moment.utc().endOf('quarter').subtract(1, 'week').valueOf(),
        title: {
          text: null
        },
        labels: {
          rotation: 0,
          formatter: function () {
            const dateFormat = inYears ? 'YYYY' : DEFAULT_DATE_FORMAT
            return formatLocalizedDate(moment.tz(this.value, 'America/New_York').format(dateFormat), dateFormat)
          },
          style: {
            color: config.axisLabelColor,
            fontSize: config.labelFontSize
          }
        },
        units: inYears ? [['year', null]] : [['month', [3]]]
      },
      {
        id: 'datetime-axis-stock',
        type: 'datetime',
        lineWidth: 0,
        tickWidth: 0,
        title: {
          text: null
        },
        labels: {},
        visible: false
      }
    ],
    yAxis: [
      { // Stock Axis
        id: 'stock-axis',
        labels: {
          format: '{value}',
          useHTML: true,
          style: {
            color: config.axisColor,
            fontSize: config.labelFontSize
          },
          x: get(config, 'stockAxis.labels.x'),
          y: get(config, 'stockAxis.labels.y')
        },
        plotLines: false,
        gridLineWidth: 1,
        lineColor: config.lineColor,
        lineWidth: 0,
        tickWidth: 0,
        gridLineColor: config.gridLineColor,
        opposite: false,
        maxPadding: 0.05,
        title: {
          useHTML: true,
          text: label.stock,
          align: 'high',
          rotation: 0,
          offset: -80,
          x: get(config, 'stockAxis.title.x'),
          y: get(config, 'stockAxis.title.y'),
          style: {
            color: config.axisTitleColor
          }
        }
      },
      { // position axis
        id: 'position-axis',
        labels: {
          formatter: function () {
            return _formatPosition(this.value, 0)
          },
          useHTML: true,
          align: 'right',
          style: {
            color: config.axisColor,
            fontSize: config.labelFontSize
          },
          x: get(config, 'positionAxis.labels.x'),
          y: get(config, 'positionAxis.labels.y')
        },
        gridLineWidth: 1,
        plotLines: false,
        lineWidth: 0,
        tickWidth: 0,
        gridLineColor: config.gridLineColor,
        opposite: true,
        maxPadding: 0.4,
        max: get(config, 'positionAxis.max'),
        title: {
          useHTML: true,
          text: label.position,
          align: 'high',
          rotation: 0,
          offset: -45,
          x: get(config, 'positionAxis.title.x'),
          y: get(config, 'positionAxis.title.y'),
          style: {
            color: config.axisTitleColor
          }
        }
      }
    ],
    series: _getSeriesConfig(props, { isStock, flags })
  }
}

Chart.propTypes = propTypes
Chart.defaultProps = defaultProps

export default memo(Chart)
