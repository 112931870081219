import {
  GET_PEERS_ANALYSIS_REQUEST,
  GET_PEERS_ANALYSIS_SUCCESS,
  GET_PEERS_ANALYSIS_FAILURE,
  statusType
} from '../../../actions'
import { getDefaultPeerAnalysisData } from '../../../utils'

const initial = {
  data: [],
  status: statusType.IDLE
}

const peerAnalysis = (state = initial, action) => {
  const { payload, error } = action

  switch (action.type) {
    case GET_PEERS_ANALYSIS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_PEERS_ANALYSIS_SUCCESS:
      return {
        ...state,
        data: payload ? getDefaultPeerAnalysisData(payload) : [],
        total: action.meta.total,
        status: statusType.SUCCESS
      }

    case GET_PEERS_ANALYSIS_FAILURE:
      return {
        error,
        status: statusType.ERROR
      }

    default:
      return state
  }
}

export default peerAnalysis
