import React, { Component } from 'react'

import './splashScreen.component.css'
import { getQ4LogoIcon } from '../../utils'

export default class SplashScreen extends Component {
  render () {
    return (
      <div className='splash-screen q4-fade-in'>
        <i className={`splash-screen_logo ${getQ4LogoIcon()}`} />
        <div className='splash-screen_text'>All-in-one market intelligence and investor engagement</div>
        <div className='splash-screen_loading'>Loading</div>
      </div>
    )
  }
}
