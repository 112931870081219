import { config } from '../config'
import Pusher from 'pusher-js'

export default class PusherService {
  constructor ({ token, profileId, onNewNotification }) {
    this.pusher = new Pusher(config.pusherToken || null, {
      authEndpoint: config.apiUrl + '/pusher/auth',
      auth: {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    })

    this.pusher.connection.bind('connected', () => {
      const privateChannel = this.pusher.subscribe(`private-${profileId}`)

      // bind to channels
      privateChannel.bind('notification_event', onNewNotification)
    })
  }
}
