import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// actions
import { usePeerAnalysisQuery, GET_PEER_ANALYSIS } from '../../hook'
import { useExport } from '../../../../services/csvExport/csvExport.service'
import { openModal, modalType } from '../../../../actions'

// components
import PeerAnalysisToolbar from './toolbar/toolbar.component'
import PeerAnalysisTable from './table/table.component'
import {
  getActiveTicker,
  getLocalStorageItem,
  getPageSizeFromStorage,
  getQuarterEndDates,
  formatDate,
  ENTITY_TYPE,
  EXPORT_DATE_FORMAT,
  getLocalizedCurrency,
  isMetricCurrencyEnabled
} from '../../../../utils'
import { get, isNull, omitBy } from 'lodash'

const { FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string,
  toolbarTheme: PropTypes.string,
  toolTheme: PropTypes.string,
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf([FUND, INSTITUTION]).isRequired
}

const defaultProps = {
  entityType: INSTITUTION
}

const PAGE_SIZE_ID = 'peer-analysis-grid'

/**
 * Peer Analysis Grid Component
 * @param props
 */
function PeerAnalysisGrid (props) {
  const { dataIdPrefix, toolbarTheme, toolTheme, tickerId, entityId, entityType, openModal, history } = props
  const [state, setState] = useState({
    peers: getLocalStorageItem('peers')?.map((peer) => peer.q4_ticker_id) || null,
    metric: 'current',
    listOptions: {
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
  })
  const { peers, metric, listOptions } = state
  const variables = omitBy({
    entityId,
    entityType,
    tickerId,
    peers,
    metric,
    currencyCode: getLocalizedCurrency(),
    ...listOptions
  }, isNull)

  const { data, loading, client } = usePeerAnalysisQuery({ variables })
  const { generateExport, exporting } = useExport({
    onError: () => {
      openModal({
        type: modalType.ERROR_MODAL
      })
    }
  })

  const analysis = get(data, 'peerAnalysis.items')
  const total = get(data, 'peerAnalysis.count', 0)

  /**
   * Handle query change
   * @param query
   */
  const handleQueryChange = (query = {}) => {
    const options = query.listOptions ? { ...query } : { ...query, listOptions: { ...listOptions, page: 1 } }
    setState({ ...state, ...options })
  }

  /**
   * Update peers on peer list update
   * @param securities
   */
  const updatePeers = (securities) => {
    handleQueryChange({
      peers: (securities || []).map((security) => security.q4_ticker_id)
    })
  }

  /**
   * Handle peer list update
   */
  const handlePeerUpdate = () => {
    openModal({
      type: modalType.PEER_CONFIG_MODAL,
      props: {
        rememberSelectionKey: 'peers',
        onSave: ({ peers }) => updatePeers(peers)
      }
    })
  }

  /**
   * Map data to csv
   * @param peer
   * @return {*}
   */
  const peerCSVMapper = (peer) => {
    if (!peer) {
      return
    }

    const metricCurrency = () => {
      return isMetricCurrencyEnabled(metric) ? `(${getLocalizedCurrency()})` : ""
    }

    const { securityName, reportDate, q0 } = peer
    const quarters = 4
    const fields = (getQuarterEndDates(quarters, moment.utc()) || [])
      .map((date, idx) => {
        const index = idx + 1
        return { [`${formatDate(date, undefined, undefined, true)} ${metricCurrency()}`]: peer[`q${index}`] }
      })
      .reduce((obj, item) => { return { ...obj, ...item } }, {})

    return {
      Name: securityName,
      [`Latest ${metricCurrency()}`]: q0,
      'Latest Date': reportDate ? formatDate(new Date(Number(reportDate)), EXPORT_DATE_FORMAT, true, true) : '-',
      ...fields
    }
  }

  /**
   * Format and Export CSV
   */
  const handleExport = () => {
    const params = {
      client,
      variables: { ...variables, limit: 0 },
      query: GET_PEER_ANALYSIS,
      dataPath: 'data.peerAnalysis.items',
      fileName: `${entityType === FUND ? FUND : INSTITUTION}_peer_analysis.csv`,
      formatter: peerCSVMapper
    }

    generateExport(params)
  }

  return (
    <>
      <PeerAnalysisToolbar
        dataId={`${dataIdPrefix}PeerAnalysis`}
        toolbarTheme={toolbarTheme}
        toolTheme={toolTheme}
        metric={metric}
        noData={!analysis || !analysis.length}
        onQueryChange={handleQueryChange}
        onPeerUpdate={handlePeerUpdate}
        onExport={handleExport}
        exporting={exporting}
      />
      <PeerAnalysisTable
        pageSizeId={PAGE_SIZE_ID}
        loading={loading}
        data={analysis}
        total={total}
        listOptions={listOptions}
        onQueryChange={handleQueryChange}
        onPeerUpdate={handlePeerUpdate}
        history={history}
      />
    </>
  )
}

PeerAnalysisGrid.propTypes = propTypes
PeerAnalysisGrid.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const ticker = getActiveTicker(get(state, 'profile.data'))
  return {
    tickerId: get(ticker, 'q4_ticker_id')
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PeerAnalysisGrid))
