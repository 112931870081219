import { bindActionCreators } from 'redux'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { get } from 'lodash'

// redux actions
import { fetchTopInstitutionManagers } from '../../../../../../../actions/ownership/institution'
import { statusType } from '../../../../../../../actions'

import TopManagersComponent from './topManagers.component'

const propTypes = {
  /**
   * Institution entity
   */
  entity: PropTypes.object.isRequired
}

const TopManagersContainer = (props) => {
  const {
    topManagers,
    entity,
    status,
    fetchTopManagers,
    handleRowClick
  } = props

  const entityId = entity.entityId
  const topManagersLimit = 5

  useEffect(() => {
    fetchTopManagers({ entity_id: entityId, limit: topManagersLimit })
  }, [entityId, fetchTopManagers])

  return (
    <TopManagersComponent
      isLoading={status === statusType.IN_PROGRESS}
      managers={topManagers}
      onRowClick={(e, data) => handleRowClick({ contactId: data._id })}
    />)
}

TopManagersContainer.propTypes = propTypes

const mapDispatchToProps = (dispatch) => ({
  fetchTopManagers: bindActionCreators(fetchTopInstitutionManagers, dispatch)
})

const mapStateToProps = (state) => {
  return {
    status: get(state, 'targeting.detail.topInstitutionManagers.status'),
    topManagers: get(state, 'targeting.detail.topInstitutionManagers.data'),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopManagersContainer)

