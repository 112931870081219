/**
 * Convert 'false' or 'true' string to boolean
 * @param value
 * @returns {Date}
 */
export function convertStringToBoolean (value) {
  return value && value.toString() === 'true'
}

/**
 * Check if id is q4 id or mongo by length
 * @param id
 * @return {*|boolean}
 */
export const isQ4Id = (id) => {
  return id && id.length === 32
}
