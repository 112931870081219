import _ from 'lodash';
import React, {Component} from 'react';
import {config} from '../../config';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ReactGridLayout from 'react-grid-layout-modified';
import {Switch} from '../../components/shared';
import WidgetOptions from './index';
import {WidgetList} from '../../data/widget';
import {changeTheme} from '../../actions/dashboard';
import '../../components/dashboard/view/dashboardView.component.css';

// Testing Layout
const layout = [
    {i: 'widget-0', x: 0, y: 0, w: 1, h: 1},
    {i: 'widget-1', x: 1, y: 0, w: 2, h: 1},

    {i: 'widget-2', x: 0, y: 1, w: 1, h: 2},
    {i: 'widget-3', x: 1, y: 1, w: 2, h: 2},

    {i: 'widget-4', x: 0, y: 3, w: 1, h: 3},
    {i: 'widget-5', x: 1, y: 3, w: 2, h: 3}
    //{i: 'widget-6', x: 0, y: 4, w: 1, h: 4},
    //{i: 'widget-7', x: 1, y: 4, w: 2, h: 4}
];

// Overview Layout
//const layout = [
//    {i: 'widget-0', x: 0, y: 0, w: 2, h: 2},
//    {i: 'widget-1', x: 2, y: 0, w: 2, h: 2},
//    {i: 'widget-2', x: 0, y: 2, w: 1, h: 3},
//    {i: 'widget-3', x: 1, y: 2, w: 1, h: 3},
//    {i: 'widget-4', x: 2, y: 2, w: 1, h: 3},
//    {i: 'widget-5', x: 3, y: 2, w: 1, h: 3},
//    {i: 'widget-6', x: 0, y: 5, w: 2, h: 3},
//    {i: 'widget-7', x: 2, y: 5, w: 2, h: 3}
//];

// Earnings Layout
//const layout = [
//    {i: 'widget-0', x: 0, y: 0, w: 1, h: 3},
//    {i: 'widget-1', x: 1, y: 0, w: 1, h: 3},
//    {i: 'widget-2', x: 2, y: 0, w: 1, h: 3},
//    {i: 'widget-3', x: 3, y: 0, w: 1, h: 3},
//    {i: 'widget-4', x: 0, y: 3, w: 1, h: 3},
//    {i: 'widget-5', x: 1, y: 3, w: 2, h: 1},
//    {i: 'widget-6', x: 1, y: 3, w: 2, h: 2},
//    {i: 'widget-7', x: 3, y: 3, w: 1, h: 3},
//    {i: 'widget-8', x: 0, y: 7, w: 2, h: 2},
//    {i: 'widget-9', x: 2, y: 7, w: 2, h: 2}
//];

class Dashboard extends Component {

    /**
     * Change Theme
     */
    changeTheme = () => {
        const {theme, changeTheme} = this.props;
        changeTheme((theme === 'dark') ? 'light' : 'dark');
    };

    /**
     * Render Widgets
     * @returns {Array}
     */
    renderGridLayout = () => {
        const id = parseInt(window.location.pathname.split('/').pop(), 10);

        return layout.map((item) => {
            const _Widget = _.find(WidgetList, (Widget) => Widget.id === id);
            const Component = _Widget && WidgetOptions[_Widget.name];

            if (_Widget && item.h >= _Widget.minHeight && item.w >= _Widget.minWidth) {
                return (
                    <div className='dashboard_widget' key={item.i}>
                        <Component options={_Widget.options} layout={item}/>
                    </div>
                );
            } else {
                return (
                    <div className='dashboard_widget' key={item.i}>
                        <div className='dashboard_unavailable'>
                            <i className='q4i-nodata-2pt'/>
                        </div>
                    </div>
                );
            }

        });
    };

    /**
     * Render Widget Simulation
     * @returns {XML}
     */
    render() {
        const {theme} = this.props;

        return (
            <div className={`dashboard dashboard--${theme}`}>
                <div className='dashboard_header'>
                    <div className='dashboard_contain dashboard_inner'>
                        <div className='dashboard_actions'>
                            <Switch onChange={this.changeTheme}
                                    isChecked={theme === 'dark'}
                                    theme={theme}
                                    labelLeft={'LIGHT'}
                                    labelRight={'DARK'}
                            />
                        </div>
                    </div>
                </div>
                <div className='dashboard_contain dashboard_inner'>
                    <ReactGridLayout
                        className='react-grid'
                        layout={layout}
                        width={config.dashboard.width}
                        containerPadding={[0, 0]}
                        autoSize={true}
                        cols={4}
                        isDraggable={false}
                        isResizable={false}
                    >
                        {this.renderGridLayout()}
                    </ReactGridLayout>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.dashboard.dashboardState.theme
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeTheme: bindActionCreators(changeTheme, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);