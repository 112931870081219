import {
  FETCH_TRADING_ANALYTICS_REQUEST,
  FETCH_EXPECTED_RANGES_REQUEST,
  FETCH_STOCK_REQUEST,
  FETCH_TRADING_ANALYTICS_SUCCESS,
  FETCH_TRADING_ANALYTICS_ERROR,
  FETCH_EXPECTED_RANGES_ERROR,
  FETCH_STOCK_ERROR,
  FETCH_EXPECTED_RANGES_SUCCESS,
  FETCH_STOCK_SUCCESS,
  FETCH_ALL_TRADING_ANALYTICS_SUCCESS,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED

} from '../../../actions/widget/analytics/index'
import _ from 'lodash'

const initial = {
  data: {},
  status: IDLE,
  stock: {},
  expectedRanges: {}
}

const tradingAnalytics = (state = initial, action) => {
  switch (action.type) {
    case FETCH_TRADING_ANALYTICS_REQUEST:
    case FETCH_EXPECTED_RANGES_REQUEST:
    case FETCH_STOCK_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_TRADING_ANALYTICS_ERROR:
    case FETCH_EXPECTED_RANGES_ERROR:
    case FETCH_STOCK_ERROR:
      return {
        ...state,
        error: action.error,
        status: FAILED
      }
    case FETCH_ALL_TRADING_ANALYTICS_SUCCESS:
      const payload = action.payload
      let data = {}; let stock = {}; let expectedRanges = {}

      _.map(payload, (each) => {
        switch (each.type) {
          case FETCH_TRADING_ANALYTICS_SUCCESS:
            data = each.payload
            break
          case FETCH_EXPECTED_RANGES_SUCCESS:
            expectedRanges = each.payload
            break
          case FETCH_STOCK_SUCCESS:
            stock = each.payload
            break
          default:
        }
      })

      return {
        ...state,
        data: data,
        stock: stock,
        expectedRanges: expectedRanges,
        status: FETCHED
      }

    default:
      return state
  }
}

export default tradingAnalytics
