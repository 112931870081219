import moment from 'moment-timezone'
import _ from 'lodash'
import {
  GET_DEAL_REQUEST,
  GET_DEAL_SUCCESS,
  GET_DEAL_FAILURE,
  DELETE_DEAL_SUCCESS,
  DELETE_DEAL_FAILURE,
  FILTER_DEAL_ACTIVITY,
  statusType
} from '../../actions'

const initial = {
  data: {},
  deleted: false,
  deleteError: false,
  error: '',
  status: statusType.IDLE
}

const deal = (state = initial, action) => {
  switch (action.type) {
    case GET_DEAL_REQUEST:
      return {
        ...state,
        deleted: false,
        deleteError: false,
        error: '',
        status: statusType.IN_PROGRESS
      }

    case GET_DEAL_SUCCESS:
      const { payload } = action
      const activities = ((payload && payload._activity) || []).map((activity) => {
        return Object.assign(activity, {
          contact: _filterLinksByType(activity.links, 'contact'),
          institution: _filterLinksByType(activity.links, 'institution'),
          stage: _getActivityStage(activity, action.payload)
        })
      })
      return {
        ...state,
        deleted: false,
        deleteError: false,
        error: '',
        type: action.type,
        data: Object.assign({}, action.payload, {
          _activity: _filterActivities(state.filter, activities),
          _allActivity: activities
        }),
        status: statusType.SUCCESS
      }

    case GET_DEAL_FAILURE:
      return {
        ...state,
        deleted: false,
        deleteError: false,
        error: action.error,
        status: statusType.ERROR
      }

    case DELETE_DEAL_SUCCESS:
      return {
        ...state,
        deleted: true,
        status: statusType.SUCCESS
      }

    case DELETE_DEAL_FAILURE:
      return {
        ...state,
        deleteError: true,
        error: action.error,
        status: statusType.SUCCESS
      }

    case FILTER_DEAL_ACTIVITY:
      const filter = action.payload
      const allActivity = state.data && state.data._allActivity
      const filteredActivities = _filterActivities(filter, allActivity)

      return {
        ...state,
        type: action.type,
        data: Object.assign({}, state.data, {
          _activity: filteredActivities
        }),
        filter,
        status: statusType.SUCCESS
      }

    default:
      return state
  }
}

/**
 * Filter Activities
 * @param filterType
 * @param allActivity
 * @return {*}
 * @private
 */
const _filterActivities = (filterType = 'all', allActivity) => {
  const filter = ['next', 'past'].find((x) => x === filterType)

  if (!filter || !allActivity || !allActivity.length) {
    return allActivity || []
  }

  const now = moment.utc()
  return allActivity.filter((x) => {
    const endDate = moment.utc((x.end && x.end.date_time) || new Date())

    if (filter === 'next') {
      return now.isSameOrBefore(endDate)
    } else if (filter === 'past') {
      return now.isAfter(endDate)
    }
    return true
  })
}

/**
 * Filter activity links by entity type
 * @param links
 * @param type
 * @return {Array.<T>}
 */
const _filterLinksByType = (links, type) => {
  return (links || []).filter((link) => link.entity_type && link.entity_type.toLowerCase() === type.toLowerCase())
}

/**
 * Map stage when activity was created
 * @param activity
 * @param deal
 * @return {T}
 * @private
 */
const _getActivityStage = (activity, deal) => {
  const activityCreateDate = activity && activity.create_date

  if (!activityCreateDate || !deal) {
    return
  }

  const activityStage = _.orderBy(deal.revisions || [], ['date'], ['desc'])
    .filter((revision) => revision && revision._stage)
    .find((revision) => {
      const createOnOrAfter = moment.utc(activityCreateDate).isSameOrAfter(moment.utc(revision.date))
      return createOnOrAfter
    })

  return activityStage && activityStage._stage
}

export default deal
