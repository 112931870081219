import gql from 'graphql-tag'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'

const GET_INSTITUTION = gql`
  query Institution($id: [String]!) {
    institution(id: $id) {
      items {
        id
        institutionName
        institutionType
        style
        turnover
        totalAUM
        equityAUM
        qualityRating
        profile
        investmentApproach
        activist
        addressConnection {
          items {
            addressLine1
            addressLine2
            addressLine3
            city
            stateProvinceCode
            stateProvinceName
            postalCode
            countryCode
            countryName
            region
            metro
            phone
            fax
            hq
          }        
        }
      }
    }
  }`

const GET_TARGET = gql`
  query Target($entityId: [String]!) {
    target(entityId: $entityId) {
      items {
        id
      }
    }
  }`

const GET_PIPELINE = gql`
  query Pipeline {
    pipeline {
      items {
        id
        index
        label
        lost
      }
    }
  }`

const GET_DEALS = gql`
  query Deal(
    $entityId: String!
    $limit: Int! = ${10}
  ) {
    deal(
      entityId: $entityId
      limit: $limit
    ) {
      items {
        id
        title
        stage
      }
    }
  }`

/**
 * Institution by Id
 * @param options
 */
export function useInstitutionQuery (options) {
  return useQuery(GET_INSTITUTION, options)
}

/**
 * Target
 * @param options
 */
export function useTargetQuery (options) {
  return useLazyQuery(GET_TARGET, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Pipeline
 * @param options
 */
export function usePipelineQuery (options) {
  return useQuery(GET_PIPELINE, options)
}

/**
 * Deal
 * @param options
 */
export function useDealQuery (options) {
  return useLazyQuery(GET_DEALS, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}
