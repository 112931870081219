import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { get } from 'lodash'

// components
import {
  Biography,
  EntityComment,
  ContactInstitution,
  EntityBadge,
  EntityHighlight,
  TagInput
} from '../../../../../../../components'
import { ENTITY_TYPE } from '../../../../../../../utils'

import './overview.component.scss'

const { CONTACT } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  contact: PropTypes.object.isRequired,
  targetId: PropTypes.string,
  hasActiveDeal: PropTypes.bool,
  deals: PropTypes.shape({
    isLoading: PropTypes.bool,
    deals: PropTypes.array,
    pipeline: PropTypes.array
  }).isRequired,
  onDealSaveSuccess: PropTypes.func.isRequired
}

const defaultProps = {
  dataIdPrefix: 'ContactFlyout',
  contact: {},
  hasActiveDeal: false,
  deals: {}
}

/**
 * Contact Detail Overview Component
 * @param props
 */
function ContactDetailOverview (props) {
  const { dataIdPrefix, theme, contact, targetId, hasActiveDeal } = props
  const { id, bio, status, jobs } = contact

  const gdpr = status === 'gdpr'

  return (
    <div className='overview-layout overview-layout--flyout'>
      <Grid container spacing={3}>
        <EntityBadge
          dataId={dataIdPrefix}
          data={{
            favorite: get(contact, 'favoriteConnection.items[0].id', null),
            targetId,
            hasActiveDeal,
            gdpr
          }}
        />

        {(jobs || []).map((job, index) => job.institutionName
          ? <Grid item xs={12} key={job.entityId || index}>
            <ContactInstitution
              dataId={dataIdPrefix}
              theme={theme}
              job={job}
              contactPosition={get(contact, 'contactHoldingCurrentConnection.items[0].current')}
              strategy={get(contact, 'strategyConnection.items', []).find((strategy) => strategy.entityId === job.entityId)}
              showEntityOwnershipChart={false}
            />
          </Grid>
          : null)}

        <Grid item xs={12}>
          <Grid container spacing={3}>
            {!gdpr && <Grid item xs={6}>
              <EntityHighlight
                dataId={dataIdPrefix}
                theme={theme}
                entity={contact}
                entityId={id}
                entityType={CONTACT}
              />
            </Grid>}

            <Grid item xs={6}>
              <EntityComment
                dataId={dataIdPrefix}
                theme={theme}
                entity={{
                  entityId: id,
                  entityType: CONTACT
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Biography
                dataId={dataIdPrefix}
                theme={theme}
                bio={bio}
              />
            </Grid>

          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TagInput
            dataId={dataIdPrefix}
            theme={theme}
            entityId={id}
            entityType={CONTACT}
          />
        </Grid>

      </Grid>
    </div>
  )
}

ContactDetailOverview.propTypes = propTypes
ContactDetailOverview.defaultProps = defaultProps

export default memo(ContactDetailOverview)
