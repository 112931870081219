import {setSenchaFullscreen, openModal, closeModal, closeAllModals} from '../actions';
import {_createToast} from '../actions';

export default class SenchaListenerService {
    history =  null;

    /**
     * Initializes post message listener
     * @param config.history
     */
    constructor(config) {
        this.history = config.history;
        this.store = config.store;
        window.addEventListener('message', this.onMessage.bind(this), false);
    }


    /**
     * Triggers when post message is received
     * @param event.type
     * @param event.data - payload from sencha
     * @param event.data.source - origin of this postMessage
     * @param event.data.type - action type
     * @param event.data.params - additional params from sencha
     */
    onMessage = (event) => {
        if (!event || !event.data) {
            return;
        }

        const {source, type, modal, params} = event.data;

        if (source !== 'sencha' || !type) {
            return;
        }

        switch (type.toLowerCase()) {
            case 'redirect':
                this.history.push(params.src, {
                    fromSencha: true
                });
                break;
            case 'fullscreen':
                const {ui} = this.store.getState() || {};
                const fullscreen = ui && ui.fullscreen && ui.fullscreen.senchaEnabled;

                // toggle fullscreen view
                if (fullscreen && modal !== 'opened') {
                    this.store.dispatch(setSenchaFullscreen(false));
                } else {
                    this.store.dispatch(setSenchaFullscreen(true));
                }
                break;
            case 'show-toast':
                // trigger toast reducer to show toast notification
                this.store.dispatch(_createToast({
                    id: new Date().getTime(),
                    params
                }));
                break;
            case 'openmodal':
                // trigger modal reducer to show requested modal;
                const {modalType, ...props} = params || {};
                this.store.dispatch(openModal({
                    type: modalType,
                    props
                }));
                break;
            case 'closemodal':
                // trigger modal reducer to hide requested modal
                this.store.dispatch(closeModal());
                break;
            case 'closeallmodals':
                // trigger modal reducer to hide all modals
                this.store.dispatch(closeAllModals());
                break;
            default:
                break;
        }
    }
}