import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../services/launchDarkly.service'
import { getQuickSearch } from '../search'

/**
 * Targeting Filter Action Types
 * @type {string}
 */
export const TARGETING_UPDATE_FILTER = 'TARGETING_UPDATE_FILTER'
export const TARGETING_RESET_FILTER = 'TARGETING_RESET_FILTER'
export const TARGETING_SET_FILTER = 'TARGETING_SET_FILTER'

export const TARGETING_LOCATION_SEARCH_REQUEST = 'TARGETING_LOCATION_SEARCH_REQUEST'
export const TARGETING_LOCATION_SEARCH_SUCCESS_FACTSET = 'TARGETING_LOCATION_SEARCH_SUCCESS_FACTSET'
export const TARGETING_LOCATION_SEARCH_SUCCESS = 'TARGETING_LOCATION_SEARCH_SUCCESS'
export const TARGETING_LOCATION_SEARCH_FAILURE = 'TARGETING_LOCATION_SEARCH_FAILURE'
export const TARGETING_LOCATION_SEARCH_RESET = 'TARGETING_LOCATION_SEARCH_RESET'

export const TARGETING_SECURITY_SEARCH_REQUEST = 'TARGETING_SECURITY_SEARCH_REQUEST'
export const TARGETING_SECURITY_SEARCH_SUCCESS = 'TARGETING_SECURITY_SEARCH_SUCCESS'
export const TARGETING_SECURITY_SEARCH_FAILURE = 'TARGETING_SECURITY_SEARCH_FAILURE'
export const TARGETING_SECURITY_SEARCH_RESET = 'TARGETING_SECURITY_SEARCH_RESET'
export const TARGETING_SET_FILTER_ERRORS = 'TARGETING_SET_FILTER_ERRORS'

/**
 * Search Locations Factset
 * @param query - search term
 * @return {{}}
 * @private
 */
const _searchTargetLocationFactset = (query) => ({
  [CALL_API]: {
    types: [
      TARGETING_LOCATION_SEARCH_REQUEST,
      TARGETING_LOCATION_SEARCH_SUCCESS_FACTSET,
      TARGETING_LOCATION_SEARCH_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/targeting/location/search/metro',
    payload: {
      query
    }
  }
})

/**
 * Search Locations
 * @param query - search term
 * @return {{}}
 * @private
 */
 const _searchTargetLocation = (query) => ({
  [CALL_API]: {
    types: [
      TARGETING_LOCATION_SEARCH_REQUEST,
      TARGETING_LOCATION_SEARCH_SUCCESS,
      TARGETING_LOCATION_SEARCH_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/targeting/v2/location/search',
    payload: {
      query
    }
  }
})

/**
 * Update Targeting Filters
 * @param filter - new/updated filter values
 * @return {{payload: *, type: string}}
 */
export const updateTargetingFilter = (filter) => {
  return {
    type: TARGETING_UPDATE_FILTER,
    payload: filter
  }
}

/**
 * Set Targeting Filters
 * @param filter - new filter values
 * @return {{payload: *, type: string}}
 */
export const setTargetingFilter = (filter) => {
  return {
    type: TARGETING_SET_FILTER,
    payload: filter
  }
}

/**
 * Dispatch TARGETING_RESET_FILTER
 */
export const resetTargetingFilter = () => (dispatch) => {
  return dispatch({ type: TARGETING_RESET_FILTER })
}

/**
 * Dispatch TARGETING_SET_FILTER_ERRORS
 * @param errors
 * @returns {function(*): *}
 */
export const setTargetingFilterErrors = (errors) => (dispatch) => {
  return dispatch({ type: TARGETING_SET_FILTER_ERRORS, payload: errors })
}

/**
 * Dispatch TARGETING_LOCATION_SEARCH_RESET
 */
export const resetTargetLocationSuggestions = () => (dispatch) => {
  return dispatch({ type: TARGETING_LOCATION_SEARCH_RESET })
}

/**
 * Search Locations Factset (Metro)
 * @param query - search term
 */
export const searchTargetLocationFactset = (query) => (dispatch) => {
  return dispatch(_searchTargetLocationFactset(query))
}

/**
 * Search Locations (Country/City)
 * @param query - search term
 */
export const searchTargetLocation = (query) => (dispatch) => {
 return dispatch(_searchTargetLocation(query))
}

/**
 * Get Security Filter Suggestions
 * @param query - current filters
 */
export const searchTargetSecurity = (query) => (dispatch) => {
  return dispatch(getQuickSearch({
    type: 'security',
    limit: 20,
    query,
    useElasticSearch: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)
  }, {
    types: [
      TARGETING_SECURITY_SEARCH_REQUEST,
      TARGETING_SECURITY_SEARCH_SUCCESS,
      TARGETING_SECURITY_SEARCH_FAILURE
    ]
  }))
}

/**
 * Dispatch TARGETING_SECURITY_SEARCH_RESET
 */
export const resetTargetSecuritySuggestions = () => (dispatch) => {
  return dispatch({ type: TARGETING_SECURITY_SEARCH_RESET })
}
