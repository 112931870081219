import React from 'react'
import { DateRangePicker } from '../../../../../../components'
import { THEMES } from '../../../../../../utils'

/**
 * Events & Transcripts Page
 * @param props
 */
function DateFilter (props) {
  const { filters, errors, onError } = props
  const { startDate, endDate } = filters

  /**
   * Trigger filter update for Date Range (start and end) change
   * @param value
   */
  const onDateChange = (value) => {
    const { handleFilterChange } = props

    if (!Array.isArray(value) || (!value[0] || !value[1])) {
      onError('date_picker', 'A valid date range is required')
    } else {
      onError('date_picker', null)
    }

    handleFilterChange({
      startDate: Array.isArray(value) ? value[0] : null,
      endDate: Array.isArray(value) ? value[1] : null
    })
  }

  return (
    <section className='eventsTranscripts-date-filter'>
      <div className='field field--text field--coupled'>
        <label className='field_label'>Date <span className='asterisk'>*</span></label>
        <DateRangePicker
          clearable
          error={{
            isError: !!errors
          }}
          theme={THEMES.WHITE}
          value={[startDate, endDate]}
          onChange={onDateChange}
        />
        {errors && (
          <div className='field_error'>
            {errors}
          </div>
        )}
      </div>
    </section>
  )
}

export default DateFilter
