import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// actions
import { usePositionQuery, usePurchasingPowerQuery } from '../hook'

// components
import AttributeCard from './attribute.component'
import { getActiveTicker, getOwnershipType, ENTITY_TYPE, THEMES, DEFAULT_TICKER, isTradingTicker } from '../../../utils'
import { get } from 'lodash'

const { FUND } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
}

const defaultProps = {
  data: {}
}

/**
 * Entity Attribute Component
 * @param props
 */
function EntityAttribute (props) {
  const { dataId, theme, isLoading, entityId, entityType, tickerId, securityId, data, ownershipType } = props
  const { value = '13f', label = '13F' } = (ownershipType || {})
  const source = [FUND].includes(entityType) ? FUND : 'inst'

  const { loading: positionLoading, data: position } = usePositionQuery(entityType, {
    skip: !isTradingTicker(tickerId),
    variables: {
      tickerId,
      entityId,
      source: value
    }
  })

  const { loading: purchasingPowerLoading, data: purchasingPower } = usePurchasingPowerQuery({
    variables: {
      securityId,
      entityId,
      entityType
    }
  })

  /**
   * Render Attributes Component
   */
  return (
    <AttributeCard
      dataId={`${dataId}AttributesCard`}
      theme={theme}
      isLoading={[isLoading, positionLoading, purchasingPowerLoading].some((item) => !!item)}
      entity={{
        ...data,
        position: get(position, `${source}HoldingCurrent.items[0].current`),
        purchasingPower: get(purchasingPower, 'purchasingPower.items[0].purchasingPower')
      }}
      entityType={entityType}
      ownershipType={label}
    />
  )
}

EntityAttribute.propTypes = propTypes
EntityAttribute.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)

  return {
    tickerId: get(ticker, 'q4_ticker_id', DEFAULT_TICKER),
    securityId: get(ticker, 'q4_entity_id', '1234567890'), // TODO: tmp default value for pre-IPO
    ownershipType: getOwnershipType(profile)
  }
}

export default connect(mapStateToProps)(EntityAttribute)
