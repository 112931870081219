import React, { Component } from 'react'
import './activityTitle.component.css'
import TextInput from '../../../shared/form/textInput/textInput.component'

class ActivityTitle extends Component {
  render () {
    const {
      values,
      errors,
      serverError,
      touched,
      handleChange,
      handleBlur
    } = this.props

    return (
      <div className='activity-form__activity-title activity-form--section'>
        <div className='field field--text field--full'>
          <label className='field_label' htmlFor='activity-title'>Title</label>
          <TextInput
            id='title'
            placeholder='New Activity'
            maxLength='80'
            values={values}
            value={values.title || ''}
            errors={errors}
            serverError={serverError}
            touched={touched}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
    )
  }
}

export default ActivityTitle
