import { isEmpty, fill, get } from 'lodash'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { format } from '../../../../utils/number.util'
import { FETCHED, FETCHING, fetchTopMovers, resetStatus } from '../../../../actions/widget/ownership'
import { Scrollbars } from 'react-custom-scrollbars'
import {
  renderDarkThumb,
  renderLightThumb,
  renderTrackVertical
} from '../../../../resources/theme/q4.custom-scrollbar'
import { getCurrentDate, getLocalizedFormat, getPreviousQuarter } from '../../../../utils/date.util'
import { Message, Spinner } from '../../../../components'
import { Checkbox } from '../../../../components/shared'
import WidgetError from '../../../../components/widget/error/widgetError.component'
import { withRouter } from 'react-router-dom'
import './peerMovers.container.css'
import { DEFAULT_TICKER, getActiveTicker, isTradingTicker } from '../../../../utils'

class PeerMovers extends Component {

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props)

    this.state = {
      isInfoModalOpen: false
    }
  }

  /**
   * ComponentDidMount
   * fetch data and initialize fetch interval
   */
  componentDidMount = () => {
    const { isEdit, status } = this.props
    if (isEdit && status === FETCHED) {
      return
    }

    const refreshInterval = this.props.options.refreshInterval
    if (refreshInterval) {
      this.timer = setInterval(this._fetchData.bind(this), refreshInterval)
    }

    this._fetchData()
  }

  /**
   * ComponentDidUpdate
   * Re-fetch data when security changed
   * @param prevProps
   */
  componentDidUpdate = (prevProps) => {
    if (prevProps.tickerId !== this.props.tickerId) {
      this._fetchData()
    }
  }

  /**
   * ComponentWillUnmount
   * clear data refetch interval
   */
  componentWillUnmount = () => {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  /**
   * Fetch Peer Movers data
   * @private
   */
  _fetchData = () => {
    const { fetchTopMovers, isSecurity, tickerId } = this.props

    fetchTopMovers({
      tickerId,
      isSecurity
    })
  }

  /**
   * Open info / description Modal
   */
  openInfoModal = () => {
    this.setState({
      isInfoModalOpen: true
    })
  }

  /**
   * Close info / description Modal
   */
  closeInfoModal = () => {
    this.setState({
      isInfoModalOpen: false
    })
  }

  /**
   * Render footer caption based on surveillance date logic
   * @returns {XML}
   */
  renderFooterCaption = () => {
    const ownershipType = '13F'
    const currentDate = getCurrentDate()
    const prevQuarter = getPreviousQuarter(currentDate)
    const startDate = prevQuarter.startOf('quarter').format(getLocalizedFormat('MM/DD/YY'))
    const endDate = prevQuarter.endOf('quarter').format(getLocalizedFormat('MM/DD/YY'))

    return (
      <span className='q4-fade-in'>{`${ownershipType} ${startDate} - ${endDate}`}</span>
    )
  }

  /**
   * Handle company change
   * @param value
   */
  handleChange = (value) => {
    const { resetStatus, fetchTopMovers, tickerId } = this.props

    resetStatus()
    fetchTopMovers({
      tickerId,
      isSecurity: value
    })
  }

  /**
   * Assign classes based on layout
   * @param layout
   */
  getClasses = (layout) => {
    const base = 'peer-movers-widget'
    let itemClasses = (layout.w > 1) ? [`${base}_item`] : [`${base}_item ${base}_item--narrow`]

    if ((layout.w < 2 && layout.h > 3) || (layout.w > 1 && layout.h > 2)) {
      itemClasses.push(`${base}_item--tall`)
    }

    return {
      base,
      items: (layout.w === 1) ? `${base}_items ${base}_items--tall` : `${base}_items`,
      item: itemClasses.join(' '),
      footer: (layout.w === 1) ? `${base}_footer ${base}_footer--small` : `${base}_footer`
    }
  }

  /**
   * Redirect to the institution page
   * @param institutionId
   * @param holderType
   * @returns {*}
   */
  redirect = (institutionId, holderType) => {
    const { history } = this.props
    if (holderType && holderType !== 'person' && institutionId) {
      return history.push(`/institution/${institutionId}`)
    }
  }

  /**
   * Render individual items
   * @param movers
   * @param classes
   * @returns {Array}
   */
  renderItems = (movers, classes) => {
    const items = movers && movers.length ? movers.concat(fill(Array(10 - movers.length), {})) : fill(Array(10), {})

    return (items).map((mover, index) => {
      const { institutionId, institutionName, holderType, change, absChange } = (mover || {})
      const key = `peer-mover_${institutionId || index}`
      const isPerson = holderType === 'person'
      const className = isPerson ? `${classes.item} ${classes.base}_item--person` : `${classes.item}`
      const changeClass = (change < 0) ? 'down' : 'up'

      return (
        <article key={key} className={`${className} q4-fade-in`} onClick={() => this.redirect(institutionId, holderType)}>
          {!change ? [
            <span key={`${key}-icon`} className={`${classes.base}_icon ${classes.base}_icon--empty`} />,
            <span key={`${key}-change`} className={`${classes.base}_change ${classes.base}_change--empty`}>-</span>
          ] : [
            <span key={`${key}-icon`} className={`${classes.base}_icon ${classes.base}_icon--${changeClass}`}>
              <i className={`q4i-arrow-${changeClass}-4pt`} />
            </span>,
            <span key={`${key}-change`} className={`${classes.base}_change ${classes.base}_change--${changeClass}`}>
              {format(absChange)}
            </span>
          ]}
          <span className='peer-movers-widget_name'>{institutionName || 'Data Not Available'}</span>
        </article>
      )
    })
  }

  /**
   * Render Peer Movers Widget
   * @returns {XML}
   */
  render () {
    const { movers, theme, description, isSecurity, layout, status, ticker, tickerId, history } = this.props
    const { isInfoModalOpen } = this.state

    const is_loading = status === FETCHING
    const footerCaption = this.renderFooterCaption()
    const classes = this.getClasses(layout)
    const renderThumb = theme === 'dark' ? renderLightThumb : renderDarkThumb

    return (
      <div className={`peer-movers-widget peer-movers-widget--${theme}`}>
        <header className='peer-movers-widget_header'>
          <section>
            <h2
              className='peer-movers-widget_title'
              onClick={() => history.push(`/security/${tickerId}`)}>Peer Movers</h2>
            <span className='peer-movers-widget_details'>Pos Chg</span>
          </section>
          <div className='peer-movers-widget_company-switch q4-fade-in'>
            <Checkbox
              theme={theme}
              id='company-toggle'
              label={`Include ${(ticker && ticker.symbol) || ''}${layout.w > 1 ? ' Holders' : ''}`}
              labelAlign='left'
              size='small'
              isChecked={isSecurity}
              onChange={this.handleChange}
            />
          </div>
        </header>
        <section className={classes.items}>
          {(!is_loading && isEmpty(movers)) ? (
            <WidgetError
              theme={theme}
              linkText={'Add Peers'}
              linkUrl={`/peerlist`}
              message={('Looks like you don\'t have any Peers added to your Peerlist.')}
            />
          ) : isEmpty(movers) ? <Spinner /> : (
            <Scrollbars
              className='react-scrollbar'
              autoHide
              hideTracksWhenNotNeeded
              renderThumbVertical={renderThumb}
              renderTrackVertical={renderTrackVertical}
            >
              {this.renderItems(movers, classes)}
            </Scrollbars>
          )}
        </section>
        {(!is_loading && isEmpty(movers)) ? '' : (
          <footer className={classes.footer}>
            {footerCaption}
            <span className='peer-movers-widget_link' onClick={this.openInfoModal}>Info</span>
          </footer>
        )}

        <Message
          visible={isInfoModalOpen}
          type='info'
          title='Peer Movers'
          message={description}
          renderExitButton={true}
          buttons={[{
            ui: 'citrus',
            label: 'ok',
            onClick: this.closeInfoModal
          }]}
          onClose={this.closeInfoModal}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)
  const movers = get(state, 'widget.ownership.topmovers', {})
  const dashboard = get(state, 'dashboard.dashboardState', {})
  const tickerId = dashboard.tickerId && isTradingTicker(dashboard.tickerId) ? dashboard.tickerId : DEFAULT_TICKER 

  return {
    ticker,
    movers: movers.list,
    status: movers.status,
    error: movers.error,
    isSecurity: movers.isSecurity,
    theme: dashboard.theme,
    securityId: dashboard.securityId,
    tickerId: tickerId,
    entityId: dashboard.entityId
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTopMovers: bindActionCreators(fetchTopMovers, dispatch),
    resetStatus: bindActionCreators(resetStatus, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PeerMovers))
