import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { formatValue } from '../../../../utils/estimates.util'
import { _isFinite, format } from '../../../../utils/number.util'
import { isServiceEnabled } from '../../../../utils/user.util'
import { Modal, RouteLink } from '../../../../components'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderDarkThumb, renderTrackVertical } from '../../../../resources/theme/q4.custom-scrollbar'
import DataTableHead from '../../../dataTable/head/head.component'
import DataTableBody from '../../../dataTable/body/body.component'
import './estimatesBrokerDetailModal.component.css'

class EstimatesBrokerDetailModal extends Component {

  /**
   * Close the modal
   * @param event
   */
  onClose = (event) => {
    event.preventDefault()
    this.props.onClose()
  }

  /**
   * Format Consensus columns
   */
  formatColumns = () => {
    const { profile } = this.props

    const columns = [
      { id: 'name', label: 'Broker Name', width: 'auto', truncate: true, dense: true },
      { id: 'analyst', label: 'Analyst', width: 'wide', truncate: true },
      { id: 'current', label: 'Current', width: 'narrow', textAlign: 'right' },
      { id: 'prev', label: 'Prev', width: 'narrow', textAlign: 'right' },
      { id: 'targetPrice', label: 'Target', width: 'narrow', textAlign: 'right' },
      { id: 'rating', label: 'Rating', width: 'narrow', textAlign: 'center' }
    ]

    const services = profile && profile.services
    const isSubscribed = isServiceEnabled('research', services || [])

    if (isSubscribed) {
      columns.splice(1, 0, { id: 'research', label: 'Research', width: 'default', textAlign: 'center' })
    }

    return columns
  }

  /**
   * Format Broker Details data
   * @param items
   * @param metric
   * @param frequency
   */
  formatData = (items, metric, frequency) => {
    return (items || []).map((item) => {

      const formattedItem = {
        name: item.broker,
        research: item.research,
        analyst: item.analyst,
        analyst_id: item.analyst_id,
        broker: item.broker,
        broker_id: item.broker_id,
        quarter: formatValue(item.quarter_est, metric),
        prevQuarter: formatValue(item.quarter_prev_est, metric),
        year: formatValue(item.year_est, metric),
        prevYear: formatValue(item.year_prev_est, metric),
        current: formatValue(item.quarter_est, metric),
        prev: formatValue(item.quarter_prev_est, metric),
        targetPrice: format(item.target, 2, 'dash'),
        rating: item.rating,
      }

      switch (frequency) {
        case 'annual':
          formattedItem.current = formatValue(item.year_est, metric)
          formattedItem.prev = formatValue(item.year_prev_est, metric)
          break
        case 'quarterly':
        default:
          formattedItem.current = formatValue(item.quarter_est, metric)
          formattedItem.prev = formatValue(item.quarter_prev_est, metric)
      }

      return formattedItem
    })
  }

  /**
   * Regenerate Modal's subtitle
   * @param item
   * @param metric
   * @returns {string}
   */
  generateModalSubtitle = (item, metric) => {
    if (!item) {
      return
    }

    const formattedDate = item.date && moment.utc(item.date).format('MMMM YY')
    const formattedType = item.type ? item.type === 'actual' ? '(A)' : '(E)' : ''

    if (item.frequency === 'annual') {
      return `${format(item.value, 2)} ${metric.toUpperCase()} - ${formattedDate} FY ${formattedType}`
    } else {
      return `${format(item.value, 2)} ${metric.toUpperCase()} - ${formattedDate} Q${item.quarter} ${formattedType}`
    }
  }

  /**
   * Get change label class
   * @param value
   * @param prevValue
   * @returns {*}
   */
  getChangeLabel = (value, prevValue) => {
    let label
    let change = 0

    if (_isFinite(value) && _isFinite(prevValue)) {
      change = value - prevValue
    }

    if (change > 0) {
      label = 'up'
    } else if (change < 0) {
      label = 'down'
    }

    return label
  }

  /**
   * Custom table cell render function
   * Used to customize cell rendering for specific cells
   * @param columnId
   * @param data
   * @param rowIndex
   * @param rows
   */
  customCellRender = (columnId, data, rowIndex, rows) => {
    if (!data && columnId !== 'edit') {
      return
    }

    const record = rows[rowIndex]
    let className
    let changeLabel

    switch (columnId) {
      case 'edit':
        const editable = ((record.name === 'Restricted') || (record.analyst === 'Restricted'))
        const _editCls = (record.edited ? '--saved' : (!editable ? '--disabled' : ''))

        return (
          <i
            className={`estimates-broker-details_edit ${_editCls ? `estimates-broker-details_edit${_editCls}` : ''} q4i-edit-4pt`}
            onClick={() => this.openEditModal(record, rowIndex)}/>
        )
      case 'research':
        if (data.uri) {
          return (
            <RouteLink className='estimates-broker-details_research-link' to={data.uri} target='_blank'>
              <i className='q4i-reportno-4pt'/>
            </RouteLink>
          )
        } else {
          return '-'
        }
      case 'analyst':
        if (!data || !data.length) {
          return '-'
        }

        return (data !== 'Restricted' && record.analyst_id) ? (
          <RouteLink className='estimates-broker-details_analyst-link'
                to={`/contact/${record.analyst_id}`}>{data}</RouteLink>
        ) : data.toString()
      case 'current':
        changeLabel = this.getChangeLabel(parseFloat(record.current), parseFloat(record.prev))
        className = [
          'estimates-broker-details_value',
          changeLabel ? `estimates-broker-details_value--${changeLabel}` : ''
        ].join(' ').trim()

        return (
          <span className={className}>
                        {changeLabel && <i className={`q4i-arrow-${changeLabel}-4pt`}/>}
            {data.toString()}
                    </span>
        )
      default:
        return data.toString()
    }
  }

  /**
   * Render
   * @returns {XML}
   */
  render () {
    const { visible, broker, brokerItem, metric, loading } = this.props
    const columns = this.formatColumns(broker && broker.estimates)
    const estimates = [...((broker && broker.estimates) || [])]
    const data = this.formatData(estimates, metric, brokerItem && brokerItem.frequency)
    const subtitle = this.generateModalSubtitle(brokerItem, metric)

    return (
      <Modal
        visible={visible}
        loading={loading}
        scrollable={false}
        includeChin={true}
        containerMaxWidth={'md'}
        contentHeight={360}
        containerWidth={960}
        contentPadding='0'
        title='Broker Details'
        subtitle={subtitle}
        onClose={this.onClose}
      >
        <div className='data-table-root estimates-broker-details_modal'>
          <table className='data-table data-table--alternating data-table--fixed'>
            <DataTableHead
              columns={columns}
              alternating={true}
            />
          </table>
          <Scrollbars
            className='react-scrollbar'
            autoHide
            hideTracksWhenNotNeeded
            renderThumbVertical={renderDarkThumb}
            renderTrackVertical={renderTrackVertical}
          >
            <table className='data-table data-table--alternating data-table--fixed'>
              <DataTableBody
                columns={columns}
                data={loading ? [] : data}
                noResultsText={loading ? ' ' : 'No data available'}
                customCellRender={this.customCellRender}
              />
            </table>
          </Scrollbars>
        </div>
      </Modal>
    )
  }
}

EstimatesBrokerDetailModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  profile: PropTypes.object,
  brokerItem: PropTypes.object,
  metric: PropTypes.string,
  loading: PropTypes.bool
}

export default EstimatesBrokerDetailModal


