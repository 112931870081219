import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Select from '../../../select/select.component'
import { statusType } from '../../../../actions'

class ActivityDeal extends PureComponent {

  /**
   * Get Deals
   * @param query
   * @param action
   */
  handleInputChange (query, action) {
    const { clearSearchData, getDeals } = this.props
    query && query.length ? getDeals(query) : clearSearchData()
  }

  /**
   * Set value from selected list item
   * @param deal
   */
  handleChange (deal) {
    const { editDeal } = this.props
    editDeal(deal)
  }

  /**
   * Format options for component
   * @param deals
   * @return {(T | {label: *, value: *})[]}
   */
  getOptions (deals) {
    return (deals || []).map((deal) => deal && { label: deal.title, value: deal._id })
  }

  render () {
    const { deal, deals } = this.props
    const { data, status } = deals
    const options = this.getOptions(data)
    const value = (deal && { label: deal.title, value: deal._id }) || null

    return (
      <div className='activity-form--section'>
        <div className='field field--text field--half'>
          <label className='field_label'>
            Deal
          </label>
          <Select
            placeholder='Title'
            loading={status === statusType.IN_PROGRESS}
            options={options}
            value={value}
            onInputChange={this.handleInputChange.bind(this)}
            onChange={this.handleChange.bind(this)}
            portal
            showDropdownIndicator={false}
          />
        </div>
      </div>
    )
  }
}

ActivityDeal.propTypes = {
  deal: PropTypes.object,
  deals: PropTypes.object,
  editDeal: PropTypes.func,
  getDeals: PropTypes.func,
  clearSearchData: PropTypes.func
}

ActivityDeal.defaultProps = {
  deal: null,
  deals: {}
}

export default ActivityDeal
