import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getLocalStorageItem, getPageSizeFromStorage } from '../../../utils/localStorage.util'
import { getDefaultColumnDef } from '../../../utils/agGrid.util'
import {
  deleteReportDataItem,
  getReportDataList,
  resetCustomTemplates,
  FETCHING
} from '../../../actions/report'
import { AgGrid, Message, NoContentMessage, Spinner, Toolbar, ToolbarRow } from '../../../components'
import {
  SectionSearch,
  Checkbox
} from '../../../components/shared'
import { THEMES } from '../../../utils/ui'
import './reportDataList.container.css'

class ReportDataListContainer extends Component {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      isMessageOpen: false,
      showNoContentMessage: false,

      columnDefs: [{
        ...getDefaultColumnDef({ columnIndex: 0, type: 'string' }),
        field: 'title',
        headerName: 'Title',
        headerCheckboxSelection: true,
        checkboxSelection: true
      }, {
        ...getDefaultColumnDef({ columnIndex: 1, type: 'string' }),
        field: 'author',
        headerName: 'Author',
        maxWidth: 240
      }, {
        ...getDefaultColumnDef({ columnIndex: 2, type: 'date' }),
        field: 'create_date',
        headerName: 'Created',
        maxWidth: 160
      }, {
        ...getDefaultColumnDef({ columnIndex: 3, type: 'date' }),
        field: 'updated_date',
        headerName: 'Last updated',
        sort: 'desc',
        maxWidth: 160
      }],

      selectedReportIds: [],

      filters: {
        limit: getPageSizeFromStorage('report-data-list') || 10,
        page: 1,
        isMyReport: this.getIsMyContentFromStorage(),
        search: null
      }
    }
  }

  /**
   * ComponentWillReceiveProps
   * @param nextProps
   * @param nextContext
   */
  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.reportDataList && nextProps.reportDataList.length && this.state.showNoContentMessage) {
      this.setState({ showNoContentMessage: false })
    }
  }

  /**
   * ComponentDidMount
   */
  componentDidMount () {
    this.fetchData()
  }

  /**
   * Load Reports page data
   * @param params
   */
  fetchData = (params) => {
    const { filters } = this.state

    const queryParams = {
      ...filters,
      ...params
    }

    this.props.getReportDataList(queryParams).then((response) => {
      const noReportData = !response.payload || !response.payload.length
      const isReportFetchFailed = response.error
      const newState = {
        showNoContentMessage: false
      }

      if (isReportFetchFailed) {
        Object.assign(newState, {
          isMessageOpen: true,
          _messageScope: 'reportFetchError'
        })
      }

      if (!filters.search && noReportData) {
        newState.showNoContentMessage = true
      }

      this.setState(newState)
    })
  }

  /**
   * Handle closing of Delete Confirmation modal
   */
  handleMessageClose = () => {
    this.setState({
      isMessageOpen: false
    })
  }

  /**
   * Handle toggle of my / my organization's report view
   */
  handleMyContentToggle = () => {
    const { filters } = this.state
    const isMyReport = !filters.isMyReport

    this.setState({
      filters: {
        ...filters,
        isMyReport,
        page: 1
      }
    }, () => {
      this.fetchData()
      this.saveIsMyContentToStorage(isMyReport)
    })
  }

  /**
   * Handle Pagination onPageChange event
   * @param event
   */
  handlePageChange = (event) => {
    this.setState({
      filters: {
        ...this.state.filters,
        page: event.selected
      }
    }, () => {
      this.fetchData()
    })
  }

  /**
   * Handle Pagination onPageChange event
   * @param event
   */
  handlePageSizeChange = (event) => {
    this.setState({
      filters: {
        ...this.state.filters,
        limit: event.selected,
        page: 1
      }
    }, () => {
      this.fetchData()
    })
  }

  /**
   * Handle Search Query Change
   * @param query
   */
  handleQueryChange = (query) => {
    this.setState({
      filters: {
        ...this.state.filters,
        search: query,
        page: 1
      }
    }, () => {
      this.fetchData()
    })
  }

  /**
   * Handle Search Query Clear
   */
  handleQueryClear = () => {
    this.setState({
      filters: {
        ...this.state.filters,
        search: null,
        page: 1
      }
    }, () => {
      this.fetchData()
    })
  }

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   */
  handleGridReady = (grid) => {
    this.agGrid = grid
  }

  /**
   * Handle onSortChanged event (multi-sort included)
   * @param grid
   */
  handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel) {
      return
    }

    this.setState({
      filters: {
        ...this.state.filters,
        page: 1,
        sort: this.getStrSortFilters(sortModel)
      }
    }, () => {
      this.fetchData()
    })
  }

  /**
   * Handle clicking on table row to access details page
   * @param params
   */
  handleRowClicked = (params) => {
    const { onListItemClick } = this.props
    const { data } = params

    onListItemClick && onListItemClick(data && data._id)
  }

  /**
   * Handle Report deletion event
   * Open confirmation modal
   */
  handleReportDelete = () => {
    if (!this.agGrid) {
      return
    }

    const selectedRows = this.agGrid.api.getSelectedRows()
    const selectedReportIds = (selectedRows || []).map((row) => row._id)

    this.setState({
      isMessageOpen: true,
      _messageScope: 'reportDelete',
      selectedReportIds
    })
  }

  /**
   * Handle delete confirmation
   */
  handleDeleteConfirmation = () => {
    const { deleteReportDataItem, resetCustomTemplates } = this.props
    const { selectedReportIds } = this.state

    if (!selectedReportIds || !selectedReportIds.length) {
      return
    }

    const tasks = selectedReportIds.map((reportId) => {
      return deleteReportDataItem(reportId)
    })

    resetCustomTemplates()
    Promise.all(tasks).then((response) => {
      const isReportDeleteFailed = response.length && response.find((responseItem) => responseItem.error)

      if (isReportDeleteFailed) {
        this.setState({
          isMessageOpen: true,
          _messageScope: 'reportDeleteError'
        })
      } else {
        this.setState({
          filters: {
            ...this.state.filters,
            page: 1
          }
        }, () => {
          this.fetchData()
        })
      }
    })

    this.setState({
      isMessageOpen: false,
      selectedReportIds: []
    })
  }

  /**
   * Get isMyReport from Local Storage
   */
  getIsMyContentFromStorage = () => {
    const storage = getLocalStorageItem('myContent')

    return !!(storage && storage.reportDataList)
  }

  /**
   * Save isMyReport to Local Storage
   * @param value
   */
  saveIsMyContentToStorage = (value) => {
    const storage = getLocalStorageItem('myContent')
    let data = {}

    if (!storage) {
      data.reportDataList = value
    } else {
      data = Object.assign({}, storage)
      data.reportDataList = value
    }

    localStorage.setItem('myContent', JSON.stringify(data))
  }

  /**
   * Get props for Message component based on scope
   * @param scope
   * @returns {*}
   */
  getMessageProps = (scope) => {
    switch (scope) {
      case 'reportDeleteError':
        return {
          type: 'error',
          title: 'Error',
          message: 'There was an issue when trying to delete the report. Please try again or contact us if you see this message again.',
          buttons: [{
            ui: 'shaded',
            label: 'close',
            onClick: this.handleMessageClose
          }]
        }
      case 'reportFetchError':
        return {
          type: 'error',
          title: 'Error',
          message: 'There was an issue when trying to load reports. Please try again or contact us if you see this message again.',
          buttons: [{
            ui: 'shaded',
            label: 'close',
            onClick: this.handleMessageClose
          }]
        }
      case 'reportDelete':
        return {
          type: 'warning',
          title: 'Delete report(s)?',
          message: 'Are you sure you want to delete these report(s)?',
          buttons: [{
            ui: 'shaded',
            label: 'cancel',
            onClick: this.handleMessageClose
          }, {
            ui: 'spice',
            label: 'confirm',
            onClick: this.handleDeleteConfirmation
          }]
        }
      default:
        return {
          type: '',
          title: '',
          message: ''
        }
    }
  }

  /**
   * Get sorting as a string from array of sort objects
   * @param sortArr
   * @returns {*}
   */
  getStrSortFilters = (sortArr) => {
    return sortArr.reduce((acc, eachSortPairs) => {
      acc.push(`${eachSortPairs['colId']}:${eachSortPairs['sort']}`)
      return acc
    }, []).join(',')
  }

  /**
   * Render Report List
   * @returns {XML}
   */
  render () {
    const { reportDataList, reportDataListTotal, reportDataListStatus, onReportAdd } = this.props
    const { columnDefs, filters, _messageScope, isMessageOpen, showNoContentMessage } = this.state

    const isLoading = reportDataListStatus === FETCHING
    const messageProps = this.getMessageProps(_messageScope)

    return (
      <div className='report-data-list'>
        <Toolbar theme={THEMES.Q4_BLUE}>
          <ToolbarRow justified={true}>
            <Checkbox
              id='report-data-list-checkbox'
              theme='ink'
              label='My Source Data'
              isChecked={filters.isMyReport}
              isDisabled={isLoading}
              onChange={this.handleMyContentToggle}
            />
            <SectionSearch
              theme='ink'
              disabled={isLoading}
              onQueryChange={this.handleQueryChange}
              onClear={this.handleQueryClear}
            />
          </ToolbarRow>
        </Toolbar>

        <section
          className={showNoContentMessage ? 'report-data-list_table report-data-list_table--empty' : 'report-data-list_table'}>
          {isLoading && (
            <Spinner
              theme='rain'
              mask={true}
            />
          )}

          {showNoContentMessage ? (
            <NoContentMessage
              theme={THEMES.TRANSPARENT}
              image={require('../../../resources/images/noContent/report_no_color.png').default}
              title='No Source Data'
              message={(
                <span>Imagine the possibilities.<br />Add your own custom data.</span>
              )}
              actions={[{ theme: THEMES.RAIN, label: 'Create Source Data', onClick: onReportAdd }]}
            />
          ) : (
            <AgGrid
              domLayout='autoHeight'
              sizeToFit={true}
              columnDefs={columnDefs}
              defaultColDef={{ sortable: true }}
              rowData={reportDataList}
              immutableData={true}
              getRowNodeId={(data) => data._id}
              bulkActions={[{
                id: 'report-list-delete',
                icon: 'q4i-trashbin-2pt',
                onSelect: this.handleReportDelete
              }]}
              pagination={true}
              paginationProps={{
                pageSizeId: 'report-data-list',
                forcePage: filters.page,
                initialPageSize: filters.limit,
                showPageSizeSelection: true,
                total: reportDataListTotal,
                onPageChange: this.handlePageChange,
                onPageSizeChange: this.handlePageSizeChange
              }}
              onGridReady={this.handleGridReady}
              onSortChanged={this.handleSortChange}
              onRowClicked={this.handleRowClicked}
            />
          )}
        </section>

        <Message
          visible={isMessageOpen}
          type={messageProps.type}
          title={messageProps.title}
          message={messageProps.message}
          buttons={messageProps.buttons}
          onClose={this.handleMessageClose}
        />
      </div>
    )
  }
}

ReportDataListContainer.propTypes = {
  onReportAdd: PropTypes.func.isRequired,
  onListItemClick: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  const profile = state.profile && state.profile.data
  const reportDataList = state.report.reportDataList

  return {
    profile,
    reportDataList: reportDataList.data || [],
    reportDataListTotal: reportDataList.meta && reportDataList.meta.total,
    reportDataListStatus: reportDataList.status
  }
}

const mapDispatchToProps = (dispatch) => ({
  getReportDataList: bindActionCreators(getReportDataList, dispatch),
  deleteReportDataItem: bindActionCreators(deleteReportDataItem, dispatch),
  resetCustomTemplates: bindActionCreators(resetCustomTemplates, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportDataListContainer))
