import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Banner } from '../../../../components'
import { getActivityCategoryIcon, getActivityDate } from '../../../../utils/activity'
import { isNil } from 'lodash'
import './banner.component.css'

/**
 * Activity Detail Banner Component
 * @param props
 * @returns {*}
 * @constructor
 */
const ActivityDetailBanner = (props) => {
  const {
    activity: { title, category, start, end, all_day, _activity, outlook_id },
    hasItinerary,
    hasAttendees,
    handleExport,
    handleEdit,
    openDeleteModal,
    handlePrintItinerary,
    handleCreateBriefingBook
  } = props


  const controls = [
    {
      type: 'button',
      icon: 'q4i-download-4pt',
      label: 'Export',
      tall: false,
      onClick: handleExport
    },
    {
      type: 'button',
      icon: 'q4i-edit-4pt',
      square: true,
      tall: false,
      onClick: handleEdit
    },
    {
      type: 'utility',
      items: [
        {
          icon: 'q4i-trashbin-2pt',
          label: 'Delete',
          onClick: openDeleteModal
        },
        {
          icon: 'q4i-print-2pt',
          label: 'Print Itinerary',
          onClick: handlePrintItinerary,
          hide: !hasItinerary
        },
        {
          icon: 'q4i-book-2pt',
          label: 'Create Briefing Book',
          onClick: handleCreateBriefingBook,
          hide: !hasAttendees
        }
      ].filter((control) => !control.hide)
    }
  ]

  /**
     * Banner Detail Component
     * @param start
     * @param end
     * @param allDay
     * @param parent
     * @return {*}
     */
  const Details = ({ start, end, allDay, parent }) => {
    return (
      <>
        {getActivityDate({ start, end, allDay })}
        {parent && parent.item && (
          <Link className='reverse' to={`/activity/${parent.item._id}`}>
            {parent.item.title}
          </Link>
        )}
      </>
    )
  }

  const Title = ({ title, outlookId }) => {
    return (
      <>
        <span>{title}</span>
        {!isNil(outlookId) && <div className="email-sync"><i className="sync-icon q4i-sync-4pt" />outlook</div>}
      </>
    )
  }

  return (
    <Banner
      size='medium'
      title={<Title title={title} outlookId={outlook_id} />}
      icon={getActivityCategoryIcon(category)}
      details={<Details start={start} end={end} allDay={all_day} parent={_activity} />}
      controls={controls}
    />
  )
}

export default withRouter(ActivityDetailBanner)
