import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// components
import { Button, Checkbox, Sidebar, Select } from '../../../../../index'
import {
  HOLDER_POSITION,
  HOLDER_TURNOVER,
  THEMES
} from '../../../../../../utils'
import { isEmpty } from 'lodash'

const propTypes = {
  dataId: PropTypes.string.isRequired,
  theme: PropTypes.string,
  filter: PropTypes.shape({
    isActivist: PropTypes.bool,
    position: PropTypes.string,
    style: PropTypes.array,
    turnover: PropTypes.array,
    type: PropTypes.array
  }).isRequired,
  filterLoading: PropTypes.bool,
  typeOptions: PropTypes.array,
  styleOptions: PropTypes.array,
  isSidebar: PropTypes.bool.isRequired,
  onQueryChange: PropTypes.func.isRequired
}

const defaultProps = {
  theme: THEMES.WHITE,
  isSidebar: false,
  filter: {},
  filterLoading: false,
  typeOptions: [],
  styleOptions: []
}

/**
 * Sidebar Header Component
 * @param props
 */
const Header = ({ onReset }) => (
  <>
    <div className='sidebar_header-title'>Filters</div>
    <Button
      theme={THEMES.SOFT_GREY}
      label='Reset'
      onClick={onReset}
    />
  </>
)

/**
 * ShareholderId Holder Sidebar
 * @param props
 */
function ShareholderIdSidebar (props) {
  const { dataId, theme, filter, isSidebar, onQueryChange, filterLoading, typeOptions, styleOptions } = props
  const { isActivist, position, style, turnover, type } = filter

  const typeSelected = typeOptions.filter((option) => (type || []).includes(option.value))
  const styleSelected = styleOptions.filter((option) => (style || []).includes(option.value))
  const turnoverSelected = HOLDER_TURNOVER.filter((option) => (turnover || []).includes(option.value))

  /**
   * Handle filter reset
   */
  const handleFilterReset = () => onQueryChange({
    filter: {
      isActivist: false,
      position: null,
      style: null,
      turnover: null,
      type: null
    }
  })

  /**
   * Handle filter change
   * @param value
   * @param type
   */
  const handleFilterChange = (value, type) => {
    if (Array.isArray(value)) {
      const types = (value || []).map((item) => item && item.value)
      return onQueryChange({
        filter: { ...filter, [type]: !isEmpty(types) ? types : null }
      })
    }

    return onQueryChange({ filter: { ...filter, [type]: value } })
  }

  return (
    <Sidebar
      collapsed={!isSidebar}
      headerTemplate={<Header onReset={handleFilterReset} />}
    >
      <Grid container spacing={6}>

        <Grid item xs={12} className='sidebar_inner-section'>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Checkbox
                dataId={`${dataId}ActivistFilter`}
                theme={theme}
                id='isActivist'
                label='Activist'
                isSelected={isActivist}
                onChange={(value) => handleFilterChange(value, 'isActivist')}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                dataId={{
                  inputId: `${dataId}PositionFilterInput`,
                  menuId: `${dataId}PositionFilterMenu`
                }}
                theme={theme}
                placeholder='Position'
                value={HOLDER_POSITION.find(({ value }) => value === position) || null}
                options={HOLDER_POSITION}
                searchable={false}
                clearable={false}
                onChange={(option) => option && handleFilterChange(option.value, 'position')}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className='sidebar_inner-section'>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Select
                dataId={{
                  inputId: `${dataId}StyleFilterInput`,
                  menuId: `${dataId}StyleFilterMenu`
                }}
                theme={theme}
                loading={filterLoading}
                placeholder='Style'
                value={styleSelected}
                options={styleOptions}
                isMulti
                isDefaultList
                searchable={false}
                clearable={false}
                closeMenuOnSelect={false}
                onChange={(options) => handleFilterChange(options, 'style')}
                />
            </Grid>
            <Grid item xs={12}>
              <Select
                dataId={{
                  inputId: `${dataId}TurnoverFilterInput`,
                  menuId: `${dataId}TurnoverFilterMenu`
                }}
                theme={theme}
                placeholder='Turnover'
                value={turnoverSelected}
                options={HOLDER_TURNOVER}
                isMulti
                isDefaultList
                searchable={false}
                clearable={false}
                closeMenuOnSelect={false}
                onChange={(options) => handleFilterChange(options, 'turnover')}
                />
            </Grid>
            <Grid item xs={12}>
              <Select
                dataId={{
                  inputId: `${dataId}TypeFilterInput`,
                  menuId: `${dataId}TypeFilterMenu`
                }}
                theme={theme}
                loading={filterLoading}
                placeholder='Type'
                portal
                value={typeSelected}
                options={typeOptions}
                isMulti
                isDefaultList
                searchable={false}
                clearable={false}
                closeMenuOnSelect={false}
                onChange={(options) => handleFilterChange(options, 'type')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Sidebar>
  )
}

ShareholderIdSidebar.propTypes = propTypes
ShareholderIdSidebar.defaultProps = defaultProps

export default memo(ShareholderIdSidebar)
