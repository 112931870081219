import React from 'react'
import { getEntityCategoryIcon, getUniqEntityLocations } from '../../../../../../utils/entity'
import { formatLocalizedDate } from '../../../../../../utils'
import { ExpandableCell } from '../../../../../../components/agGrid/cell'
import './cell.component.css'
import moment from 'moment-timezone'

const EMPTY_PLACEHOLDER = '-'

/**
 * Title Custom Cell Component
 */
const TitleCell = ({ item }) => {
  const entityType = item.entityType.toLowerCase()
  const entityName = item.entity_name
  let entityDetail

  switch (entityType) {
    case 'fund':
    case 'institution':
      entityDetail = item.referenceItem.type
      break
    case 'contact':
      const job = Array.isArray(item.referenceItem.jobs) && item.referenceItem.jobs[0]
      entityDetail = job ? `${[job.title, job.institution_name].filter((each) => each).join(', ')}` : EMPTY_PLACEHOLDER
      break
    default:
      break
  }

  return (
    <div className='cell cell--title'>
      <i className={`cell_icon cell_icon--${entityType} ${getEntityCategoryIcon(entityType)}`} />
      <div className='cell_title'>
        <div className='cell_title--name'>
          {entityName}
        </div>
        <div className='cell_title--detail'>
          {entityDetail}
        </div>
      </div>
    </div>
  )
}

/**
 * Location Custom Cell Component
 */
const LocationCell = ({ item, onExpandMenuClick }) => {

  const entityType = item.entityType.toLowerCase()
  let address

  switch (entityType) {
    case 'fund':
    case 'institution':
      address = item.referenceItem.address
      break
    case 'contact':
      address = item.referenceItem.jobs
      break
    default:
      break
  }

  const locations = (getUniqEntityLocations(address) || []).map((addressItem) => {
    return {
      label: addressItem
    }
  })

  return (
    <ExpandableCell
      items={locations}
      onClick={onExpandMenuClick}
    />
  )
}

/**
 * Date Added Custom Cell Component
 */
const AsOfCell = ({ dateTime }) => {
  return dateTime ? formatLocalizedDate(moment(dateTime).format('MM/DD/YYYY'), 'MM/DD/YYYY') : EMPTY_PLACEHOLDER
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 */
const CustomCell = ({ data = {}, column = {}, onExpandMenuClick }) => {
  const cellData = data[column.colId]

  switch (column.colId) {
    case 'entity_name':
      return <TitleCell item={data} />
    case 'locations':
      return <LocationCell item={data} onExpandMenuClick={onExpandMenuClick} />
    case 'create_date':
      return <AsOfCell dateTime={cellData} />
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
