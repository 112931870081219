import React from 'react'
import { getFromXigniteToStandard, defaultIfEmpty } from '../../../../utils'

const UNKNOWN = 'Unknown'

/**
 * Security
 * @param props
 * @returns {*}
 */
export const Security = (props) => {
  const { securityName, symbol, exchange, industry, capGroup } = props

  return (
    <>
      <div className='item_content-title'>{securityName}</div>
      <div className='item_content-subtitle item_content-subtitle'>
        {symbol} <span>{getFromXigniteToStandard(exchange)}</span>
      </div>
      <div className='item_content-subtitle item_content-subtitle--small'>
        {defaultIfEmpty(industry, UNKNOWN)}
      </div>
      <div className='item_content-body'>
        <div className='item_content-detail'>
          <div className='item_content-label'>MKT CAP</div>
          <div className='item_content-value'>{defaultIfEmpty(capGroup, UNKNOWN)}</div>
        </div>
      </div>
    </>
  )
}
