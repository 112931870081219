import React, { PureComponent } from 'react'
import './activityType.component.css'
import RadioButton from '../../../shared/radioButton/radioButton.component'
import { isParent as _isParent } from '../../../../utils/activity/activity.util'
import {
  COMMENT,
  PHONE,
  EMAIL,
  MEETING,
  EARNINGS,
  OTHER,
  ROADSHOW,
  CONFERENCE,
  getActivityTypeOptions
} from '../../../../utils/activity'

class ActivityType extends PureComponent {

  getActivityTypesPrimary = () => {
    return getActivityTypeOptions([COMMENT, PHONE, EMAIL, MEETING, EARNINGS, OTHER])
  }

  getActivityTypesSecondary = () => {
    return getActivityTypeOptions([ROADSHOW, CONFERENCE])
  }

  isTypeSelected = (selectedValue) => {
    const { value } = this.props
    return selectedValue === value
  }

  onTypeSelect = (value) => {
    const { setFieldValue } = this.props
    setFieldValue('category', value)
  }

  renderRadioButton = (type) => {
    return (
      <RadioButton
        key={`key--${type.value}`}
        id={`id--${type.value}`}
        value={type.value}
        label={type.label}
        groupName='activity-type'
        theme={type.color}
        isSelected={this.isTypeSelected(type.value)}
        onSelect={this.onTypeSelect}/>
    )
  }

  isParentFromReducer () {
    const { isParent } = this.props
    return isParent
  }

  isParentEdit () {
    const { isEdit, value } = this.props
    return _isParent(value) && isEdit
  }

  isChildEdit () {
    const { isEdit, value } = this.props
    return !_isParent(value) && isEdit
  }

  render () {
    const { isItinerary, isParentOptionHidden } = this.props

    return (
      <div className='activity-form__activity-type activity-form--section'>
        <div className='field field--radio'>
          <label className='field_label'>Activity Type</label>
          <div className='field_input'>
            {!this.isParentFromReducer() && !this.isParentEdit() && this.getActivityTypesPrimary().map((type) => this.renderRadioButton(type))}
            {!this.isParentFromReducer() && !this.isParentEdit() && !this.isChildEdit() && !isParentOptionHidden && !isItinerary &&
            <div className='type-divider'/>}
            {!this.isChildEdit() && !isParentOptionHidden && !isItinerary && this.getActivityTypesSecondary().map((type) => this.renderRadioButton(type))}
          </div>
        </div>
      </div>
    )
  }
}

export default ActivityType
