import {
  GET_ITINERARY_REQUEST,
  GET_ITINERARY_SUCCESS,
  GET_ITINERARY_FAILURE,
  RESET_ITINERARY,
  statusType
} from '../../../actions'
import { unwindActivityLinks } from '../../../utils/activity'

const initial = {
  data: [],
  status: statusType.IDLE,
  total: 0
}

/**
 * Format activities in itinerary for grid columns
 * @param activities
 * @returns {*}
 * @private
 */
const _transformActivities = (activities) => {
  return activities.map((activity) => {
    activity = unwindActivityLinks(activity)
    const title = activity.title || ''
    const start_date = activity.start || {}
    const location = (activity.address && activity.address[0] && activity.address[0].venue) || ''
    const time = {
      start: activity.start,
      end: activity.end
    }

    return {
      start_date,
      time,
      title,
      contact: activity.contact,
      institution: activity.institution,
      participants: activity.participants,
      location,
      _id: activity._id
    }
  })
}

const grid = (state = initial, action) => {
  const { payload, error, meta } = action

  switch (action.type) {
    case GET_ITINERARY_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_ITINERARY_SUCCESS:
      return {
        ...state,
        items: _transformActivities(payload),
        counts: (meta && meta.counts) || {},
        total: (meta && meta.total) || 0,
        status: statusType.SUCCESS
      }

    case GET_ITINERARY_FAILURE:
      return {
        error,
        status: statusType.ERROR
      }

    case RESET_ITINERARY:
      return initial

    default:
      return state
  }
}

export default grid
