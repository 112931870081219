import React, { memo } from 'react'
import PropTypes from 'prop-types'

// utils
import { getClassName } from '../../../../../utils'
import { get } from 'lodash'

import './progress.component.scss'

const propTypes = {
  dataId: PropTypes.string.isRequired,
  stage: PropTypes.object.isRequired,
  pipeline: PropTypes.array.isRequired,
  revisions: PropTypes.array.isRequired
}

const defaultProps = {
  stage: {},
  pipeline: [],
  revisions: []
}

/**
 * Deal Progress Component
 * @param props
 */
function Progress ({ dataId, stage, pipeline, revisions }) {
  const lanes = [...pipeline]
  const stageId = get(stage, 'id', null)
  const stageLost = get(stage, 'lost', false)

  if (!stageId || !pipeline.length) {
    return null
  }

  // show objective not met stage if deal is lost
  (stageLost) ? lanes.splice(pipeline.length - 2, 1) : lanes.pop()

  const progress = lanes.map((item) => {
    const { id, index, label, lost } = (item || {})
    const revision = revisions.length && revisions[revisions.length - 2]
    const prevStage = pipeline.find(({ id }) => id === (get(revision, 'stage') || stageId))

    const className = getClassName('stage', [
      { condition: (!stageLost && stageId === id) || (stageLost && id === prevStage.id), trueClassName: 'stage--active' },
      { condition: (!stageLost && index < stage.index) || (stageLost && index < prevStage.index), trueClassName: 'stage--past' },
      { condition: stageLost && (stageId === id), trueClassName: 'stage--lost' }
    ])

    return (
      <div key={`progress-${id}`} className={className}>
        <div className='stage_label'>{label}</div>
        <div className='stage_status'>
          <div className='stage_status--indicator'>
            <i className={`${!lost ? 'q4i-checkmark-4pt' : 'q4i-close-4pt'}`} />
          </div>
        </div>
      </div>
    )
  })

  return <div data-id={`${dataId}Progress`} className='deal-detail-page_progress'>{progress}</div>
}

Progress.propTypes = propTypes
Progress.defaultProps = defaultProps

export default memo(Progress)
