import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getClassName, THEMES } from '../../../utils/ui'

import './radio.component.scss'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  color: PropTypes.oneOf([
    THEMES.RAIN,
    THEMES.TEAL,
    THEMES.CITRUS,
    THEMES.CHERRY,
    THEMES.APPLE,
    THEMES.EGGPLANT,
    THEMES.WALNUT
  ]),
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

const defaultProps = {
  theme: THEMES.LIGHT,
  color: THEMES.RAIN
}

/**
 * Radio Component
 * @param props
 */
function Radio (props) {
  const { dataId, theme, label, id, name, value, color, isSelected, onChange, disabled } = props

  const baseClassName = getClassName('radio', [
    { condition: theme, trueClassName: `radio--${theme}` },
    { condition: color, trueClassName: `radio--${color}` },
    { condition: isSelected, trueClassName: 'radio--selected' },
    { condition: disabled, trueClassName: 'radio--disabled' }
  ])

  return (
    <div data-id={dataId} className={baseClassName}>
      <label className='radio_label'>
        <input
          className='radio_field'
          type='radio'
          id={id}
          name={name}
          value={value}
          checked={isSelected}
          onChange={onChange}
          disabled={disabled}
        />
        {label}
      </label>
    </div>
  )
}

Radio.propTypes = propTypes
Radio.defaultProps = defaultProps

export default memo(Radio)
