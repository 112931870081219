import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_AISCORE = gql`
  query AIScore($securityId: [String]!, $entityId: [String]!, $entityType: String!) {
    aiScore(securityId: $securityId, entityId: $entityId, entityType: $entityType) {
      items {
        score
        rankConnection(limit: 5) {
          items {
            importance
            variable
            rank
          }
        }
      }
    }
  }`

/**
 * AI Score
 * @param options
 * @returns {{*}}
 */
export function useAIScoreQuery (options) {
  return useQuery(GET_AISCORE, options)
}
