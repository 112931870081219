import {
  FETCH_CURRENT_ACTIVITIES_ERROR,
  FETCH_CURRENT_ACTIVITIES_REQUEST,
  FETCH_CURRENT_ACTIVITIES_SUCCESS,
  SET_CURRENT_ACTIVITY_DATE,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../../actions/widget/activity/currentActivity.actions'
import moment from 'moment-timezone'

const initial = {
  activities: [],
  status: IDLE,
  currentActivityDate: moment.utc()
}

const currentActivitiesState = (state = initial, action) => {
  switch (action.type) {
    case FETCH_CURRENT_ACTIVITIES_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_CURRENT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: action.payload,
        status: FETCHED
      }
    case FETCH_CURRENT_ACTIVITIES_ERROR:
      return {
        ...state,
        error: action.error,
        status: FAILED
      }

    case SET_CURRENT_ACTIVITY_DATE: {
      return {
        ...state,
        currentActivityDate: action.payload
      }
    }
    default:
      return state
  }
}

export default currentActivitiesState
