import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

/**
 * Targeting Saved Search Action Types
 * @type {string}
 */
export const GET_TARGETING_SAVED_SEARCH_REQUEST = 'GET_TARGETING_SAVED_SEARCH_REQUEST'
export const GET_TARGETING_SAVED_SEARCH_SUCCESS = 'GET_TARGETING_SAVED_SEARCH_SUCCESS'
export const GET_TARGETING_SAVED_SEARCH_FAILURE = 'GET_TARGETING_SAVED_SEARCH_FAILURE'

export const CREATE_TARGETING_SAVED_SEARCH_REQUEST = 'CREATE_TARGETING_SAVED_SEARCH_REQUEST'
export const CREATE_TARGETING_SAVED_SEARCH_SUCCESS = 'CREATE_TARGETING_SAVED_SEARCH_SUCCESS'
export const CREATE_TARGETING_SAVED_SEARCH_FAILURE = 'CREATE_TARGETING_SAVED_SEARCH_FAILURE'

export const UPDATE_TARGETING_SAVED_SEARCH_REQUEST = 'UPDATE_TARGETING_SAVED_SEARCH_REQUEST'
export const UPDATE_TARGETING_SAVED_SEARCH_SUCCESS = 'UPDATE_TARGETING_SAVED_SEARCH_SUCCESS'
export const UPDATE_TARGETING_SAVED_SEARCH_FAILURE = 'UPDATE_TARGETING_SAVED_SEARCH_FAILURE'

export const REMOVE_TARGETING_SAVED_SEARCH_REQUEST = 'REMOVE_TARGETING_SAVED_SEARCH_REQUEST'
export const REMOVE_TARGETING_SAVED_SEARCH_SUCCESS = 'REMOVE_TARGETING_SAVED_SEARCH_SUCCESS'
export const REMOVE_TARGETING_SAVED_SEARCH_FAILURE = 'REMOVE_TARGETING_SAVED_SEARCH_FAILURE'

export const RESET_TARGETING_SAVED_SEARCH = 'RESET_TARGETING_SAVED_SEARCH'

/**
 * Get saved search
 * @return {{}}
 * @private
 */
const _getTargetingSavedSearch = () => ({
  [CALL_API]: {
    types: [
      GET_TARGETING_SAVED_SEARCH_REQUEST,
      GET_TARGETING_SAVED_SEARCH_SUCCESS,
      GET_TARGETING_SAVED_SEARCH_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/targeting/filter'
  }
})

/**
 * Create saved search
 * @param data
 * @returns {{}}
 * @private
 */
const _createTargetingSavedSearch = (data) => ({
  [CALL_API]: {
    types: [
      CREATE_TARGETING_SAVED_SEARCH_REQUEST,
      CREATE_TARGETING_SAVED_SEARCH_SUCCESS,
      CREATE_TARGETING_SAVED_SEARCH_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/targeting/filter',
    payload: data
  }
})

/**
 * Update saved search
 * @param id
 * @param data
 * @returns {{}}
 * @private
 */
const _updateTargetingSavedSearch = (id, data) => ({
  [CALL_API]: {
    types: [
      UPDATE_TARGETING_SAVED_SEARCH_REQUEST,
      UPDATE_TARGETING_SAVED_SEARCH_SUCCESS,
      UPDATE_TARGETING_SAVED_SEARCH_FAILURE
    ],
    method: METHOD_TYPE.PUT,
    endpoint: `/targeting/filter/${id}`,
    payload: data
  }
})

/**
 * Delete saved search
 * @param id
 * @returns {{}}
 * @private
 */
const _deleteTargetingSavedSearch = (id) => ({
  [CALL_API]: {
    types: [
      REMOVE_TARGETING_SAVED_SEARCH_REQUEST,
      REMOVE_TARGETING_SAVED_SEARCH_SUCCESS,
      REMOVE_TARGETING_SAVED_SEARCH_FAILURE
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: `/targeting/filter/${id}`
  }
})

/**
 * Dispatch GET_TARGETING_SAVED_SEARCH_REQUEST
 */
export const getTargetingSavedSearch = () => (dispatch) => {
  return dispatch(_getTargetingSavedSearch())
}

/**
 * Dispatch CREATE_TARGETING_SAVED_SEARCH_REQUEST
 */
export const createTargetingSavedSearch = (data) => (dispatch) => {
  return dispatch(_createTargetingSavedSearch(data))
}

/**
 * Dispatch UPDATE_TARGETING_SAVED_SEARCH_REQUEST
 * @param data
 * @returns {function(*): *}
 */
export const updateTargetingSavedSearch = (data) => (dispatch) => {
  const id = data && data.id
  return dispatch(_updateTargetingSavedSearch(id, data))
}

/**
 * Dispatch REMOVE_TARGETING_SAVED_SEARCH_REQUEST
 * @param id
 * @returns {function(*): *}
 */
export const deleteTargetingSavedSearch = (id) => (dispatch) => {
  return dispatch(_deleteTargetingSavedSearch(id))
}

/**
 * Dispatch RESET_TARGETING_SAVED_SEARCH
 */
export const resetTargetingSavedSearch = () => (dispatch) => {
  return dispatch({ type: RESET_TARGETING_SAVED_SEARCH })
}
