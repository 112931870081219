export const ACTIVITY_TYPE = {
  COMMENT: 'comment',
  PHONE: 'phone',
  EMAIL: 'email',
  MEETING: 'meeting',
  EARNINGS: 'earnings',
  OTHER: 'other',
  ROADSHOW: 'roadshow',
  CONFERENCE: 'conference'
}

export const ALL = 'all'
export const COMMENT = 'comment'
export const PHONE = 'phone'
export const EMAIL = 'email'
export const MEETING = 'meeting'
export const EARNINGS = 'earnings'
export const OTHER = 'other'
export const ROADSHOW = 'roadshow'
export const CONFERENCE = 'conference'

export const ACTIVITY_TYPE_OPTIONS = [
  { value: ALL, label: 'All' },
  { value: MEETING, label: 'Meeting', color: 'teal', isParent: false },
  { value: PHONE, label: 'Phone', color: 'sky', isParent: false },
  { value: EMAIL, label: 'Email', color: 'tangerine', isParent: false },
  { value: EARNINGS, label: 'Earnings', color: 'apple', isParent: false },
  { value: COMMENT, label: 'Note', color: 'citrus', isParent: false },
  { value: OTHER, label: 'Other', color: 'walnut', isParent: false },
  { value: ROADSHOW, label: 'Roadshow', color: 'cherry', isParent: true },
  { value: CONFERENCE, label: 'Conference', color: 'eggplant', isParent: true }
]

export const VIRTUAL = 'Virtual'
