import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// actions
import { useHistoricalStockQuery, useHistoricalPositionQuery } from '../../../hook'

// components
import OwnershipLanguage from './language/language.component'
import OwnershipChart from './chart/chart.component'

// utils
import { fitStockDataInChartDates, chartStock, DEFAULT_TICKER, ENTITY_TYPE, getStockChartMargins, QUARTER_TO_YEAR_LIMIT } from '../../../../../utils'
import { get } from 'lodash'

const { CONTACT, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  tickerId: PropTypes.string.isRequired,
  subscription: PropTypes.object.isRequired,
  ticker: PropTypes.object.isRequired,
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

const defaultProps = {
  ticker: {},
  limit: 4
}

const LANGUAGE_QUARTERS = 20 // 5 years

/**
 * Ownership Component
 * @param props
 */
function Ownership (props) {
  const { entityId, entityType, tickerId, subscription, ticker, limit } = props
  const source = [CONTACT, INSTITUTION].includes(entityType) ? 'inst' : entityType
  const { value = '13f' } = (subscription || {})
  const chartRange = parseInt(limit.toString()) < QUARTER_TO_YEAR_LIMIT ? 'quarter' : 'year'
  const [range] = useState({
    startDate: chartRange === 'quarter'
      ? moment.utc().endOf('quarter').startOf('day').subtract(Number(limit), 'quarter').subtract(getStockChartMargins(chartRange), 'day').toDate()
      : moment.utc().startOf('year').startOf('day').subtract(Number(limit), 'quarter').subtract(getStockChartMargins(chartRange), 'day').toDate(),
    endDate: moment.utc().startOf('day').toDate()
  })
  const { data: stock } = useHistoricalStockQuery({ variables: { tickerId, ...range } })
  const { data: languagePosition } = useHistoricalPositionQuery(entityType, {
    variables: {
      tickerId,
      entityId,
      quarters: LANGUAGE_QUARTERS,
      source: value
    }
  })
  const { data: chartPosition } = useHistoricalPositionQuery(entityType, {
    variables: { tickerId, entityId, quarters: Number(limit) }
  })

  return (
    <Grid container spacing={2} className='tearsheet_section'>
      <Grid item xs={12} className='tearsheet_section-title'>Ownership</Grid>
      <Grid item xs={12} className='tearsheet_section-content'>
        <OwnershipLanguage
          ticker={ticker}
          subscription={subscription}
          quarters={LANGUAGE_QUARTERS}
          position={tickerId !== DEFAULT_TICKER
            ? get(languagePosition, `${source}HoldingHistorical.items[0]`, {})
            : {}
          }
          entityName={props.entityName}
        />
        <OwnershipChart
          range={range}
          quarters={Number(limit)}
          subscription={subscription}
          position={tickerId !== DEFAULT_TICKER
            ? get(chartPosition, `${source}HoldingHistorical.items[0]`, {})
            : {}
          }
          stock={fitStockDataInChartDates(chartStock(get(stock, 'stock.items')), chartRange)}
        />
      </Grid>
    </Grid>
  )
}

Ownership.propTypes = propTypes
Ownership.defaultProps = defaultProps

export default Ownership
