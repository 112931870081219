import gql from 'graphql-tag'

export const GET_ACTIVITIES = gql`
  query Activity($limit: Int!) {
    activity(limit: $limit) {
      items {
        id
        category
        title
        start {
          date
          timezone
        }
        links {
          entityType
          entityId
          entityName
        }
      }
    }
  }`
