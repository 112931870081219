import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment-timezone';
import {
    FETCHED,
    FETCHING,
    loadAvgVolume,
    loadPeers,
    loadPeerStockQuoteHistorical,
    loadStockIndex,
    loadStockQuote,
    loadStockQuoteHistorical,
    removePeerStockQuoteHistorical,
    removeStockIndex
} from '../../../../actions/widget/stock';
import {getTickers} from '../../../../utils';
import {getFromXigniteToStandard, getIndices} from '../../../../utils/stock/stock.util';
import {inSequence} from '../../../../utils/promise.util';
import {Scrollbars} from 'react-custom-scrollbars';
import {renderDarkThumb, renderLightThumb, renderTrackVertical} from '../../../../resources/theme/q4.custom-scrollbar';
import { Select, Spinner } from '../../../../components'
import { Checkbox, Chips } from '../../../../components/shared';
import { THEMES } from '../../../../utils/ui'
import StockQuoteHeader from '../../../../components/widget/stock/quoteHeader/quoteHeader.component';
import StockChart from '../../../../components/widget/stock/chart/chart.component';
import WidgetError from '../../../../components/widget/error/widgetError.component';
import './chart.container.css';

class StockChartContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            query: '',
            indices: getIndices(),
            _indices: []
        };
    }

    /**
     * Triggered when component mounted
     * Enable auto-reloading if enabled
     */
    componentDidMount = () => {
        const {isEdit, status} = this.props;
        if (isEdit && status === FETCHED) {
            return;
        }

        const refreshInterval = this.props.options.refreshInterval;
        if (refreshInterval) {
            this.timer = setInterval(this._fetchData.bind(this), refreshInterval);
        }

        this._fetchData();
    };

    /**
     * ComponentDidUpdate
     * Re-fetch data when security changed
     * @param prevProps
     */
    componentDidUpdate = (prevProps) => {
        if (prevProps.securityId !== this.props.securityId) {
            this._fetchData();
        }
    };

    /**
     * clear fetch interval on unmount
     */
    componentWillUnmount = () => {
        if (this.timer) {
            clearInterval(this.timer);
        }
    };

    /**
     * Fetch all relevant data
     * @private
     */
    _fetchData = () => {
        const {
            profile, peers, loadPeerStockQuoteHistorical, loadStockQuoteHistorical,
            loadStockQuote, loadAvgVolume, loadPeers, loadStockIndex, selectedIndices,
            securityId
        } = this.props;

        const params = {
            securityId,
            startDate: moment.utc().subtract(1, 'year').subtract(5, 'days').toISOString(),
            endDate: moment.utc().toDate().toISOString(),
        };

        loadStockQuoteHistorical(params);
        loadStockQuote(params);
        loadAvgVolume(params);

        const securities = getTickers(profile)
            .filter((ticker) => !ticker.primary)
            .map((ticker) => ticker._security)
            .slice(0, 5);

        const peerParams = {
            select: ['symbol', 'exchange'],
            sortField: 'Symbol',
            sortOrder: 1,
            limit: 20,
            security: securities
        };

        loadPeers(peerParams).then(() => {
            const securityIds = peers.filter((peer) => peer.items && peer.items.length).map((peer) => peer._security);

            if (securityIds && securityIds.length) {
                const params = {
                    startDate: moment.utc().subtract(1, 'year').subtract(5, 'days').toISOString(),
                    endDate: moment.utc().toDate().toISOString(),
                    securityId: securityIds,
                    limit: 365
                };

                loadPeerStockQuoteHistorical(params);
            }
        });

        const tasks = _.map((selectedIndices || []), (index) => {
            const indexParams = {
                symbol: index.symbol,
                group: index.group,
                category: index.category,
                name: index.name,
                color: index.color
            };
            return loadStockIndex.bind(this, indexParams);
        });

        inSequence(tasks);
    };

    /**
     * Handle autocomplete search query change
     * @param query
     */
    handleQueryChange = (query) => {
        const _indices = query && query.length ?
          (this.state.indices || []).filter((index) => {
            const indexString = index ? `${index.name} ${index.fullname}` : '';
            return indexString.toLowerCase().indexOf(query.toLowerCase()) >= 0;
          }) : []

        this.setState({ _indices, query });
    };

    /**
     * Handle adding a new stock index
     * @param item
     */
    handleIndexSelect = (item) => {
      const {loadStockIndex, selectedIndices} = this.props;
      const existingIndex = (selectedIndices || []).find((index) => index.name === (item && item.label));

      if (!existingIndex && (item && item.value)) {
        loadStockIndex(item.value);
      }
    };

    /**
     * Handle removing of stock index
     * @param index
     */
    handleIndexRemove = (index) => {
        if (!index) {
            return;
        }

        this.props.removeStockIndex(index);
    };

    /**
     * Load Peer Stock
     * @param isChecked
     * @param id
     */
    loadPeerStock = (isChecked, id) => {
        if (!id) {
            return;
        }

        let item = this.props.peers.find((item) => item._security === id);

        if (!item) {
            return;
        }

        if (isChecked) {
            item.checked = true;

            const params = {
                securityId: item._security,
                startDate: moment.utc().subtract(1, 'year').subtract(5, 'days').toISOString(),
                endDate: moment.utc().toDate().toISOString(),
                limit: 365
            };

            this.props.loadPeerStockQuoteHistorical(params);
        } else {
            item.checked = false;
            this.props.removePeerStockQuoteHistorical(item);
        }
    };

    /**
     * Check to see checkbox value
     * @param peerId
     * @returns {boolean|*}
     */
    getIsPeerCheckboxCheck = (peerId) => {
        const item = this.props.peers.find((item, index) => {
            return this.uniqueId((item._security) || index) === peerId;
        });
        return (item && item.checked) || (item && item.items && item.items.length && true) || false;
    };

    /**
     * Remove my company from the peer list since it's rendered by default
     * @param peers
     * @return {*|Array}
     */
    getFilteredPeers(peers) {
        const peerItems = peers || [];
        const {securityId} = this.props;

        if (!securityId || !peerItems.length) {
            return peerItems;
        }

        return peerItems.filter((peerItem) => peerItem._security && peerItem._security !== securityId);
    }

    /**
     * Format indices to include a properly coloured label
     * @param indices
     * @returns {Array}
     */
    formatChips = (indices) => {
        return (indices || []).map((index) => {
            return {
                ...index,
                label: <span style={{color: index.color}}>{index.name}</span>
            };
        });
    };

    /**
     * Generate a unique id
     * @param id
     * @returns {string}
     */
    uniqueId = (id) => {
        const {layout, dashboardId} = this.props;
        return `${dashboardId}-${layout.i}-${id}`;
    };

  /**
   * Get Formatted Options for Select component
   * @param _indices
   * @return {(T | {label: *, value: *})[]}
   */
    getSelectOptions = (_indices) => {
      return (_indices || []).map((index) => index && { label: index.name, value: index })
    }

    render() {
        const {theme, status, stockQuote, peers, selectedIndices, stockHistorical, securityId} = this.props;
        const {_indices, query} = this.state;
        const indices = selectedIndices || [];
        const options = this.getSelectOptions(_indices)
        const is_loading = status === FETCHING;
        const symbol = (stockQuote && stockQuote.Security && stockQuote.Security.Symbol) || '';
        const peersExist = peers && peers.length && (peers.length > 1 || peers[0]._security !== securityId);
        const isMaxIndices = selectedIndices && selectedIndices.length >= 3;
        const chartClass = peersExist ? 'narrow' : 'full';
        const chartProps = {peers: peers.filter((peer) => peer.items && peer._security !== securityId), stockHistorical, indices, theme, peersExist};
        const renderThumb = theme === 'dark' ? renderLightThumb : renderDarkThumb;

        if (!is_loading && _.isEmpty(stockHistorical)) {
            return (
                <WidgetError
                    theme={theme}
                    header={'Stock Chart'}
                    message={'No stock data available.'}
                />
            );
        }

        return (
            (_.isEmpty(stockHistorical)) ?
                <Spinner/> :
                <div className={`stock-chart stock-chart--${theme}`}>
                    <StockQuoteHeader {...this.props} />

                    <div className='stock-chart_wrapper'>
                        <span className={`stock-chart_ticker stock-chart_ticker--${chartClass}`}>{symbol}</span>
                        <div className={`stock-chart_container stock-chart_container--${chartClass}`}>
                            <StockChart {...chartProps} />
                            {peersExist ? (
                                <div className='stock-chart_peer-container'>
                                    <Scrollbars
                                        className='react-scrollbar'
                                        autoHide
                                        hideTracksWhenNotNeeded
                                        renderThumbVertical={renderThumb}
                                        renderTrackVertical={renderTrackVertical}>
                                        <h2 className='stock-chart_title'>Peers</h2>
                                        <ul className='stock-chart_peer-list'>
                                            {this.getFilteredPeers(peers).map((peer, index) => {
                                                const uniqueId = this.uniqueId((peer._security) || index);

                                                return (
                                                    <li key={uniqueId} className='stock-chart_peer-item'>
                                                        <Checkbox
                                                            className='stock-chart_checkbox'
                                                            id={uniqueId}
                                                            theme={chartProps.theme === 'dark' ? 'light-slate' : 'soft-grey'}
                                                            customCheckboxColor={peer.color}
                                                            size='xsmall'
                                                            label={(
                                                                <span className='stock-chart_peer-label'>
                                                                    <span className='stock-chart_peer-symbol'>
                                                                        {peer && peer.symbol && peer.symbol.slice(0, 7)}
                                                                    </span>
                                                                    <span className='stock-chart_peer-exchange'>
                                                                        {peer && getFromXigniteToStandard(peer.exchange)}
                                                                    </span>
                                                                </span>
                                                            )}
                                                            isChecked={this.getIsPeerCheckboxCheck(uniqueId)}
                                                            onChange={(isChecked) => this.loadPeerStock(isChecked, peer._security)}
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </Scrollbars>
                                </div>
                            ) : null}
                        </div>

                        <div className='stock-chart_index'>
                            <Select
                              className='stock-chart_autocomplete'
                              theme={theme === 'light' ? THEMES.LIGHT_GREY : THEMES.SLATE}
                              placeholder={isMaxIndices ? '3 Max' : 'Add index (Max 3)'}
                              size='thin'
                              menuPlacement='top'
                              maxHeight={160}
                              value={query}
                              options={options}
                              onInputChange={this.handleQueryChange}
                              onChange={this.handleIndexSelect}
                              showDropdownIndicator={false}
                              disabled={isMaxIndices}
                            />
                          <Chips
                            className='stock-chart_chips'
                            theme={theme}
                            items={this.formatChips(selectedIndices)}
                            itemKey='label'
                            size='input'
                            onRemove={this.handleIndexRemove}
                            />
                        </div>

                        <div className='stock-chart_footer'>
                            <p>Stock based on 15 minute delay. Volume change based on 1 day. Average volume based on 1
                                month.</p>
                        </div>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = (state) => {
    const stock = state.widget.stock;

    return {
        stockHistorical: (stock.historical && stock.historical.items) || [],
        status: stock.historical.status,
        selectedIndices: stock.indices && stock.indices.indices,
        stockQuote: (stock.quote && stock.quote.items) || [],
        averageVolume: (stock.volume && stock.volume.volume && stock.volume.volume.volumeAverage) || 'N/A',
        peers: stock.peerHistorical.peers || [],
        peerColors: stock.peerHistorical.peerColors,
        theme: state.dashboard.dashboardState.theme,
        region: state.shared.profile.region,
        profile: state.shared.profile,
        securityId: state.dashboard.dashboardState.securityId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadStockQuoteHistorical: bindActionCreators(loadStockQuoteHistorical, dispatch),
        loadStockIndex: bindActionCreators(loadStockIndex, dispatch),
        removeStockIndex: bindActionCreators(removeStockIndex, dispatch),
        loadStockQuote: bindActionCreators(loadStockQuote, dispatch),
        loadAvgVolume: bindActionCreators(loadAvgVolume, dispatch),
        loadPeers: bindActionCreators(loadPeers, dispatch),
        loadPeerStockQuoteHistorical: bindActionCreators(loadPeerStockQuoteHistorical, dispatch),
        removePeerStockQuoteHistorical: bindActionCreators(removePeerStockQuoteHistorical, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StockChartContainer);

