import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {rain, white, silver, lightSlate, citrus} from '../../../resources/materialui-overrides/colors';
import ReactHighmaps from 'react-highcharts/ReactHighmaps';
import USMapData from '../../../utils/map/mapdata/us';
import EuropeMapData from '../../../utils/map/mapdata/europe';
import CanadaMapData from '../../../utils/map/mapdata/canada';

class AiTargetingMap extends Component {
    /**
     * Handle map click
     * @param data
     */
    handleMapClick = (data) => {
        let options = data && data.point;
        if (options.value && options.value === '0'){
            data.preventDefault();
            return;
        }
        this.props.onMapClick(options);
    };

    /**
     * Render AI Targeting Map Component
     * @returns
     */
    render() {
        const {data, region, country} = this.props;
        let MapData;

        const _region = region || country;

        switch (_region) {
            case 'europe':
                MapData = EuropeMapData;
                break;
            case 'us':
                MapData = USMapData;
                break;
            case 'ca':
                MapData = CanadaMapData;
                break;
            default:
                break;
        }

        const config = {
            chart: {
                map: MapData,
                reflow: false,
                backgroundColor: null,
                className: 'ai-targeting-widget_map'
            },
            credits: false,
            title: null,
            legend: false,
            mapNavigation: {
                enabled: false
            },
            colors: [
                '#2a3035',
                '#93e8f5',
                '#8addee',
                '#7ccde3',
                '#6cbbd6',
                '#5ba6c9',
                '#4892ba',
                '#377ead',
                '#276ca0',
                '#1a5d96',
                '#10528f'
            ],
            tooltip: {
                useHTML: false,
                padding: 20,
                borderWidth: 0,
                shadow: false,
                borderRadius: 8,
                formatter: function () {
                    return (`
                        <span style="font-size: 13px; color:${rain};">${this.point.name}</span><br>
                        <span style="font-size: 13px; color:${silver};">Top Targets Avg. AI Score 
                            <span style="color: ${lightSlate};">${parseInt(this.point.value, 10)}</span>
                        </span>
                    `);
                }
            },
            colorAxis: {
                min: 1,
                type: 'logarithmic',
                dataClassColor: 'category',
                dataClasses: [
                {
                    to: 0
                },
                {
                    from: 1,
                    to: 10
                }, {
                    from: 10,
                    to: 20
                }, {
                    from: 20,
                    to: 30
                }, {
                    from: 30,
                    to: 40
                }, {
                    from: 40,
                    to: 50
                }, {
                    from: 50,
                    to: 60
                }, {
                    from: 60,
                    to: 70
                }, {
                    from: 70,
                    to: 80
                }, {
                    from: 80,
                    to: 90
                }, {
                    from: 90
                }]
            },
            plotOptions: {
                series: {
                    allowPointSelect: true,
                    states: {
                        hover: {
                            color: citrus
                        }
                    },
                    point: {
                        events: {
                            click: this.handleMapClick
                        }
                    }
                },
                map: {
                    allAreas: false
                }
            }
        };

        // add map series
        config.series = [{
            animation: false,
            cursor: 'pointer',
            data: data,
            joinBy: [region ? 'iso-a2' : 'postal-code', 'code'],
            dataLabels: {
                enabled: false,
                color: white,
                format: '{point.code}'
            },
            name: 'AI Targets',
            tooltip: {
                pointFormat: '{point.code}: {point.value}'
            }
        }];

        return (
            <ReactHighmaps config={config}/>
        );
    }
}

AiTargetingMap.propTypes = {
    onMapClick: PropTypes.func
};

export default AiTargetingMap;