import React from 'react'
import { formatDate } from '../../../../utils'

/**
 * Security
 * @param props
 * @returns {*}
 */
export const Research = (props) => {
  const { date, headline } = props
  return (
    <>
      <div className='item_content-title'>
        {headline}
      </div>
      {date && <div className='item_content-subtitle'>
        {formatDate(date, 'MMMM DD, YYYY h:mm A')}
      </div>}
    </>
  )
}
