import {
  GET_REPORT_ENTITY_TYPES_REQUEST,
  GET_REPORT_ENTITY_TYPES_SUCCESS,
  GET_REPORT_ENTITY_TYPES_ERROR,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../actions'

const initial = {
  data: [],
  status: IDLE
}

const reportEntityTypes = (state = initial, action) => {
  switch (action.type) {
    case GET_REPORT_ENTITY_TYPES_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_REPORT_ENTITY_TYPES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: FETCHED
      }
    case GET_REPORT_ENTITY_TYPES_ERROR:
      return {
        ...state,
        status: FAILED
      }
    default:
      return state
  }
}

export default reportEntityTypes
