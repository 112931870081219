import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { getFilteredAvailableIndices } from '../../../../utils/report'
import { ComboBox } from '../../../index'
import './reportIndices.component.css'

/**
 * ReportIndexLabel Component
 * @param type
 * @param index
 * @returns {*}
 */
function ReportIndexLabel (index) {
  const { symbol, fullname } = index

  return (
    <span className='report-indices_label report-indices_label--list'>
      <span className='report-indices_label-symbol'>{symbol}</span>
      <span className='report-indices_label-separator'>|</span>
      <span className='report-indices_label-name'>{fullname}</span>
    </span>
  )
}

/**
 * ReportIndices Component
 * @param indices
 * @param availableIndices
 * @param onIndexAdd
 * @param onIndexRemove
 * @returns {*}
 */
function ReportIndices ({ indices, availableIndices, onIndexAdd, onIndexRemove }) {
  const [_availableIndices, setAvailableIndices] = useState([])
  const [query, setQuery] = useState('')

  useEffect(() => {
    setAvailableIndices(getFilteredAvailableIndices(availableIndices, query))
  }, [availableIndices, query])

  const selectedIndices = [].concat(indices || [])
    .map((index) => index && {
      value: `${index.symbol}.${index.group}`,
      label: ReportIndexLabel(index)
    })

  const options = [].concat(_availableIndices || [])
    .map((item) => {
      const { fullname = '', symbol = '' } = item
      return item && {
        value: item,
        label: `${symbol} | ${fullname}`
      }
    })

  return (
    <section className='report-indices'>
      <div className='report-indices_search'>
        <ComboBox
          selectProps={{
            placeholder: 'Add an index',
            size: 'thin',
            itemHeight: 30,
            maxHeight: 180,
            options,
            closeMenuOnSelect: false,
            value: query,
            onInputChange: (query) => setQuery(query),
            onChange: (selection) => selection && selection.value && onIndexAdd(selection.value)
          }}
          removableListProps={{
            items: selectedIndices,
            onRemove: onIndexRemove
          }}
        />
      </div>
    </section>
  )
}

ReportIndices.propTypes = {
  indices: PropTypes.array.isRequired,
  availableIndices: PropTypes.array.isRequired,
  onIndexAdd: PropTypes.func.isRequired,
  onIndexRemove: PropTypes.func.isRequired
}

ReportIndices.defaultProps = {
  indices: [],
  availableIndices: []
}

export default memo(ReportIndices)
