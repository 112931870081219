import React from 'react'
import PropTypes from 'prop-types'
import { ComboBox } from '../../../../components'
import { THEMES } from '../../../../utils'
import { Checkbox } from '../../../../components/shared/form'
import { isEqual, uniqWith } from 'lodash'

const CORPORATE_ACCESS_VALUES = ['Buy-Side Corporate Access', 'Head of Buy-Side Corporate Access']

/**
 * Search Function Filter
 * @param props
 * @returns {*}
 * @constructor
 */
const Function = (props) => {
  const { isLoading, options, selectedOptions, onFilterChange } = props

  const all = [].concat(options || [])
    .map((option) => ({ ...option, label: option.value }))

  const selected = [].concat(options || [])
    .filter((option) => [].concat(selectedOptions || []).includes(option.value))
    .map((option) => ({ ...option, label: option.value }))

  const corporateAccessValues = all.filter((option) => {
    return option && option.value === CORPORATE_ACCESS_VALUES.find((value) => value === option.value)
  })

  /**
   * Handle filter change
   * @param options - array of selected values
   */
  const handleOnChange = (options) => {
    const values = [].concat(options || []).map((option) => option && option.value)
    onFilterChange('functions', values)
  }

  /**
   * Handle removing selected option
   * @param {String} removedOption - value to remove
   */
  const handleOnRemove = (removedOption) => {
    const values = [].concat(selected || [])
      .filter((option) => option.value !== removedOption)
      .map((option) => option && option.value)

    onFilterChange('functions', values)
  }

  /**
   * Handle add/remove multiple corporate access values via checkbox
   * @param value - Boolean
   */
  const handleCorporateAccessCheck = (value) => {
    const functions = value
      ? uniqWith([].concat(selected, corporateAccessValues), isEqual)
      : (selected || []).filter((item) => !(CORPORATE_ACCESS_VALUES.includes(item && item.value)))

    handleOnChange(functions)
  }

  return (
    <section className='search-page_filter-section q4-fade-in'>
      <div className='search-page_filter-header'>Filter By Function</div>
      <div className='search-page_filter-content'>
        <div className='search-page_filter-note'>Based on related Contacts</div>
        <div className='field field--full field--text'>
          <label className='field_label'>Job Function</label>
          <ComboBox
            theme={THEMES.LIGHT_GREY}
            selectProps={{
              loading: isLoading,
              disabled: isLoading,
              placeholder: 'Select',
              value: selected,
              options: all,
              isMulti: true,
              isDefaultList: true,
              closeMenuOnSelect: false,
              showDropdownIndicator: true,
              onChange: handleOnChange
            }}
            removableListProps={{
              items: selected,
              onRemove: handleOnRemove
            }}
          />
        </div>
        <div className='field field--full'>
          <Checkbox
            id='corporate'
            label='Buy-Side Corporate Access'
            isChecked={CORPORATE_ACCESS_VALUES.every((item) => (selectedOptions || []).includes(item))}
            onChange={handleCorporateAccessCheck}
          />
        </div>
      </div>
    </section>
  )
}

Function.propTypes = {
  isLoading: PropTypes.bool,
  functions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    count: PropTypes.number
  })),
  onFilterChange: PropTypes.func.isRequired
}

Function.defaultProps = {
  isLoading: false,
  corporate: false,
  functions: []
}

export default Function
