import gql from 'graphql-tag'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { getHistQtrFields, ENTITY_TYPE, OWNERSHIP_TYPE } from '../../../utils'
import { get } from 'lodash'

const GET_FUND_HISTORICAL_POSITION = (quarters) => {
  const quarterlyFields = getHistQtrFields(quarters)

  return gql`
    query FundHoldingHistorical(
      $entityId: [String]!
      $tickerId: [String]!
      $quarters: Int!
      $source: String = "${OWNERSHIP_TYPE['13F']}"
    ) {
      fundHoldingHistorical(
        fundId: $entityId
        tickerId: $tickerId
        quarters: $quarters
        source: $source
      ) {
        items {
          fundId
          fundName
          tickerId
          securityName
          ${quarterlyFields}
        }
        count
      }
    }`
}

export const GET_FUND_HISTORICAL_HOLDINGS = (quarters) => {
  const quarterlyFields = getHistQtrFields(quarters)

  return gql`
    query FundHoldingHistorical(
      $entityId: [String]!
      $tickerId: [String]
      $search: String
      $quarters: Int!
      $source: String = "${OWNERSHIP_TYPE['13F']}"
      $sortBy: String
      $sortDir: String
      $page: Int
      $limit: Int
    ) {
      fundHoldingHistorical(
        fundId: $entityId
        tickerId: $tickerId
        search: $search
        quarters: $quarters
        source: $source
        sortBy: $sortBy
        sortDir: $sortDir
        page: $page
        limit: $limit
      ) {
        items {
          fundId
          fundName
          tickerId
          securityName
          ${quarterlyFields}
        }
        count
      }
    }`
}

const GET_INST_HISTORICAL_POSITION = (quarters) => {
  const quarterlyFields = getHistQtrFields(quarters)

  return gql`
    query InstitutionHoldingHistorical(
      $entityId: [String]!
      $tickerId: [String]!
      $quarters: Int!
      $source: String = "${OWNERSHIP_TYPE['13F']}"
    ) {
      instHoldingHistorical(
        institutionId: $entityId
        tickerId: $tickerId
        quarters: $quarters
        source: $source
      ) {
        items {
          institutionId
          institutionName
          tickerId
          securityName
          ${quarterlyFields}
        }
        count
      }
    }`
}

export const GET_INST_HISTORICAL_HOLDINGS = (quarters) => {
  const quarterlyFields = getHistQtrFields(quarters)

  return gql`
    query InstitutionHoldingHistorical(
      $entityId: [String]!
      $tickerId: [String]
      $search: String
      $quarters: Int!
      $source: String = "${OWNERSHIP_TYPE['13F']}"
      $sortBy: String
      $sortDir: String
      $page: Int
      $limit: Int
    ) {
      instHoldingHistorical(
        institutionId: $entityId
        tickerId: $tickerId
        search: $search
        quarters: $quarters
        source: $source
        sortBy: $sortBy
        sortDir: $sortDir
        page: $page
        limit: $limit
      ) {
        items {
          institutionId
          institutionName
          tickerId
          securityName
          ${quarterlyFields}
        }
        count
      }
    }`
}

/**
 * Historical Holdings
 * @param type
 * @param options
 */
export function useHistoricalQuery (type, options) {
  const quarters = get(options, 'variables.quarters', 5)
  const query = (type === ENTITY_TYPE.FUND)
    ? GET_FUND_HISTORICAL_HOLDINGS(quarters)
    : GET_INST_HISTORICAL_HOLDINGS(quarters)
  return useQuery(query, options)
}

/**
 * Historical Holdings (Peers)
 * @param type
 * @param options
 */
export function usePeerHistoricalQuery (type, options) {
  const quarters = get(options, 'variables.quarters', 5)
  const query = (type === ENTITY_TYPE.FUND)
    ? GET_FUND_HISTORICAL_POSITION(quarters)
    : GET_INST_HISTORICAL_POSITION(quarters)
  return useLazyQuery(query, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}
