import {
  SEARCH_TAGS_REQUEST,
  SEARCH_TAGS_SUCCESS,
  SEARCH_TAGS_FAILURE,
  RESET_SEARCH_TAGS
} from '../../actions/tag'
import { statusType, CLEAR_SEARCH_DATA } from '../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const search = (state = initial, action) => {
  switch (action.type) {
    case SEARCH_TAGS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case SEARCH_TAGS_SUCCESS:
      return {
        ...state,
        status: statusType.SUCCESS,
        data: action.payload
      }

    case SEARCH_TAGS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR,
        data: initial.data
      }

      // TODO: remove CLEAR_SEARCH_DATA
    case CLEAR_SEARCH_DATA:
      return { ...initial }

    case RESET_SEARCH_TAGS:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default search
