import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { isEmpty } from 'lodash'

// components
import { Button, Checkbox, Sidebar, Select } from '../../../../../index'
import {
  ENTITY_TYPE,
  HOLDER_POSITION,
  HOLDER_STYLE,
  HOLDER_TURNOVER,
  HOLDER_TYPE,
  THEMES
} from '../../../../../../utils'

const { INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string.isRequired,
  theme: PropTypes.string,
  filter: PropTypes.shape({
    isActivist: PropTypes.bool,
    position: PropTypes.string,
    style: PropTypes.array,
    turnover: PropTypes.array,
    type: PropTypes.array
  }).isRequired,
  holderType: PropTypes.string,
  isSidebar: PropTypes.bool.isRequired,
  onQueryChange: PropTypes.func.isRequired
}

const defaultProps = {
  filter: {},
  isSidebar: false,
  theme: THEMES.WHITE
}

/**
 * Sidebar Header Component
 * @param props
 */
const Header = ({ onReset }) => (
  <>
    <div className='sidebar_header-title'>Filters</div>
    <Button
      theme={THEMES.SOFT_GREY}
      label='Reset'
      onClick={onReset}
    />
  </>
)

/**
 * Surveillance Holder Sidebar
 * @param props
 */
function SurveillanceHolderSidebar (props) {
  const { dataId, holderType, theme, filter, isSidebar, onQueryChange } = props
  const { isActivist, position, style, turnover, type } = filter

  const typeSelected = HOLDER_TYPE.filter((option) => (type || []).includes(option.value))
  const styleSelected = HOLDER_STYLE.filter((option) => (style || []).includes(option.value))
  const turnoverSelected = HOLDER_TURNOVER.filter((option) => (turnover || []).includes(option.value))

  /**
   * Handle filter reset
   */
  const handleFilterReset = () => onQueryChange({
    filter: {
      isActivist: false,
      position: null,
      style: null,
      turnover: null,
      type: null
    }
  })

  /**
   * Handle filter change
   * @param value
   * @param type
   */
  const handleFilterChange = (value, type) => {
    if (Array.isArray(value)) {
      const types = (value || []).map((item) => item && item.value)
      return onQueryChange({
        filter: { ...filter, [type]: !isEmpty(types) ? types : null }
      })
    }

    return onQueryChange({ filter: { ...filter, [type]: value } })
  }

  return (
    <Sidebar
      collapsed={!isSidebar}
      headerTemplate={<Header onReset={handleFilterReset} />}
    >
      <Grid container spacing={6}>
        <Grid item xs={12} className='sidebar_inner-section'>
          <Grid container spacing={1}>
            {['all', INSTITUTION].includes(holderType) && (
              <Grid item xs={12}>
                <Checkbox
                  dataId={`${dataId}ActivistFilter`}
                  theme={theme}
                  id='isActivist'
                  label='Activist'
                  isSelected={isActivist}
                  onChange={(value) => handleFilterChange(value, 'isActivist')}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Select
                dataId={{
                  inputId: `${dataId}PositionFilterInput`,
                  menuId: `${dataId}PositionFilterMenu`
                }}
                theme={theme}
                placeholder='Position'
                value={HOLDER_POSITION.find(({ value }) => value === position) || null}
                options={HOLDER_POSITION}
                searchable={false}
                clearable={false}
                onChange={(option) => option && handleFilterChange(option.value, 'position')}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className='sidebar_inner-section'>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Select
                dataId={{
                  inputId: `${dataId}StyleFilterInput`,
                  menuId: `${dataId}StyleFilterMenu`
                }}
                theme={theme}
                placeholder='Style'
                value={styleSelected}
                options={HOLDER_STYLE}
                isMulti
                isDefaultList
                searchable={false}
                clearable={false}
                closeMenuOnSelect={false}
                onChange={(options) => handleFilterChange(options, 'style')}
                />
            </Grid>
            <Grid item xs={12}>
              <Select
                dataId={{
                  inputId: `${dataId}TurnoverFilterInput`,
                  menuId: `${dataId}TurnoverFilterMenu`
                }}
                theme={theme}
                placeholder='Turnover'
                value={turnoverSelected}
                options={HOLDER_TURNOVER}
                isMulti
                isDefaultList
                searchable={false}
                clearable={false}
                closeMenuOnSelect={false}
                onChange={(options) => handleFilterChange(options, 'turnover')}
                />
            </Grid>
            <Grid item xs={12}>
              <Select
                dataId={{
                  inputId: `${dataId}TypeFilterInput`,
                  menuId: `${dataId}TypeFilterMenu`
                }}
                theme={theme}
                placeholder='Type'
                portal
                value={typeSelected}
                options={HOLDER_TYPE}
                isMulti
                isDefaultList
                searchable={false}
                clearable={false}
                closeMenuOnSelect={false}
                onChange={(options) => handleFilterChange(options, 'type')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Sidebar>
  )
}

SurveillanceHolderSidebar.propTypes = propTypes
SurveillanceHolderSidebar.defaultProps = defaultProps

export default memo(SurveillanceHolderSidebar)
