import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

// actions
import { modalType } from '../../../../actions'

// components
import { Banner } from '../../../../components'
import { formatDate, formatLocalizedDate } from '../../../../utils'
import { get } from 'lodash'

const propTypes = {
  dataIdPrefix: PropTypes.string.isRequired,
  book: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  dataIdPrefix: 'BriefingBookDetailPage',
  book: {}
}

/**
 * Banner Detail Component
 * @param created
 * @param activity
 */
const Details = ({ created, activity }) => {
  const { id, title } = activity
  const dateFormat = formatLocalizedDate( formatDate(created, 'MMMM D, YYYY'), 'MMMM D, YYYY' )
  return (
    <>
      <span>Created on {(dateFormat)} {(id && title) ? ' from ' : ''}</span>
      {(id && title)
        ? <Link className='banner_details--link' to={`/activity/${id}`}>{title}</Link>
        : null}
    </>
  )
}

/**
 * Briefing Book Banner Component
 * @param props
 */
function BriefingBookBanner (props) {
  const { dataIdPrefix, book, onEdit, onDelete, onExport, openModal } = props
  const { title, created, activityConnection } = book

  /**
   * Handle briefing book delete
   */
  const handleDelete = () => {
    openModal({
      type: modalType.CONFIRM_MODAL,
      props: {
        content: {
          title: 'Delete Briefing Book?',
          message: 'Are you sure you want to delete this Briefing Book?'
        },
        onConfirm: onDelete
      }
    })
  }

  const controls = [
    {
      type: 'button',
      dataId: `${dataIdPrefix}Export`,
      label: 'Export',
      onClick: onExport
    },
    {
      dataId: `${dataIdPrefix}Edit`,
      type: 'button',
      icon: 'q4i-edit-4pt',
      onClick: onEdit
    },
    {
      dataId: `${dataIdPrefix}BannerUtility`,
      type: 'utility',
      items: [{
        icon: 'q4i-trashbin-2pt',
        dataId: `${dataIdPrefix}UtilityDelete`,
        label: 'Delete',
        onClick: handleDelete
      }]
    }]

  return (
    <Banner
      icon='q4i-book-2pt'
      title={title}
      details={
        <Details
          created={created}
          activity={get(activityConnection, 'items[0]', {})}
        />}
      controls={controls}
    />
  )
}

BriefingBookBanner.propTypes = propTypes
BriefingBookBanner.defaultProps = defaultProps

export default memo(BriefingBookBanner)
