import { combineReducers } from 'redux'
import institution from './institution'
import fund from './fund'
import peerAnalysis from './peerAnalysis'
import topBuys from './buy.reducer'
import topSells from './sell.reducer'
import entityChart from './chart/entity'

export default combineReducers({
  institution,
  fund,
  peerAnalysis,
  topBuys,
  topSells,
  entityChart
})
