import React from 'react'
import PropTypes from 'prop-types'

// components
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'

const propTypes = {
  performanceData: PropTypes.array.isRequired
}

const defaultProps = {
  performanceData: []
}

/**
 * Price Performance Chart
 * @param props
 */
function PricePerformanceChart (props) {
  return <HighchartsReact highcharts={Highcharts} options={_getChartConfig(props)} />
}

/**
 * Highcharts config for price performance chart
 * @param props
 */
const _getChartConfig = (props) => {
  const { performanceData } = props
  const yRange = Math.max(...performanceData.map(Math.abs))

  return {
    chart: {
      type: 'column',
      backgroundColor: 'none',
      width: 125,
      height: 55,
      spacing: [0, 20, 0, 20],
      reflow: false
    },
    noData: {
      style: { display: 'none' }
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    rangeSelector: { enabled: false },
    title: { text: null },
    subtitle: { text: null },
    legend: { enabled: false },
    xAxis: {
      tickLength: 0,
      gridLineWidth: 0,
      lineWidth: 0,
      labels: {
        style: {
          cursor: 'pointer'
        },
        formatter: function () {
          const label = this.isFirst ? performanceData[0].toFixed(2) : performanceData[1].toFixed(2)
          return label
        }
      }
    },
    yAxis: {
      gridLineWidth: 0,
      min: yRange * -1,
      max: yRange,
      plotLines: [{
        color: '#939ba0',
        width: 1,
        value: 0,
        zIndex: 4,
        label: {
          align: 'right',
          text: '0',
          style: {
            fontSize: '11px',
            color: '#666666'
          },
          y: 3
        }
      }],
      labels: { enabled: false },
      title: { text: null },
      height: 40
    },
    tooltip: { enabled: false },
    plotOptions: {
      series: {
        animation: false,
        allowPointSelect: false,
        enableMouseTracking: false,
        marker: {
          enabled: false
        }
      }
    },
    series: [{
      name: 'Performance',
      id: 'performance',
      type: 'column',
      color: '#1abc9c',
      borderWidth: 0,
      pointPadding: 0,
      negativeColor: '#e74b3c',
      data: performanceData,
      pointWidth: 33
    }]
  }
}

PricePerformanceChart.propTypes = propTypes
PricePerformanceChart.defaultProps = defaultProps

export default PricePerformanceChart
