import React, { useState, memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { capitalize } from 'lodash'

// components
import { Card, Checkbox, SectionTab } from '../../index'
import Events from './events/events.component'
import News from './news/news.component'

import './recentUpdate.component.scss'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.string,
  isLoading: PropTypes.bool,
  useGraphQL: PropTypes.bool,
  data: PropTypes.shape({
    events: PropTypes.array,
    news: PropTypes.array
  }),
  hideNews: PropTypes.bool,
  hasTranscript: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
}

const defaultProps = {
  isLoading: false,
  data: {},
  hideNews: false
}

const TABS = {
  EVENTS: 'events',
  TRANSCRIPTS: 'transcripts',
  NEWS: 'news'
}

/**
 * Security Recent Updates Card
 * @param props
 */
function SecurityRecentUpdateCard (props) {
  const {
    dataId,
    theme,
    isLoading,
    useGraphQL,
    data,
    transcript,
    hasTranscript,
    hideNews: hideNewsProp,
    openModal
  } = props
  const { events, news } = data || {}
  const [active, setActive] = useState(TABS.EVENTS)

  const hideNews = useMemo(() => !!hideNewsProp, [hideNewsProp])

  return (
    <Card
      dataId={`${dataId}UpdateCard`}
      theme={theme}
      className='security-recent-update'
      isLoading={isLoading}
      title='Recent Updates'
      headerActions={[() =>
        <>
          {(active === TABS.EVENTS) &&
            <Checkbox
              theme={theme}
              className='card_actions--checkbox'
              label='Transcripts'
              isSelected={transcript}
              onChange={hasTranscript}
            />}

          <SectionTab
            compact
            theme={theme}
            activeTab={active}
            onChange={(event, value) => setActive(value)}
            tabs={[
              {
                label: capitalize(TABS.EVENTS),
                value: TABS.EVENTS
              },
              {
                label: capitalize(TABS.NEWS),
                value: TABS.NEWS,
                hidden: hideNews
              }
            ]}
          />
        </>
      ]}
    >
      {(active === TABS.EVENTS)
        ? <Events
            isLoading={isLoading}
            events={events}
            openModal={openModal}
            useGraphQL={useGraphQL}
          />
        : null}
      {(active === TABS.NEWS)
        ? <News
            isLoading={isLoading}
            news={news}
            openModal={openModal}
          />
        : null}
    </Card>
  )
}

SecurityRecentUpdateCard.propTypes = propTypes
SecurityRecentUpdateCard.defaultProps = defaultProps

export default memo(SecurityRecentUpdateCard)
