import {
  FETCH_INSTITUTION_REQUEST,
  FETCH_INSTITUTION_SUCCESS,
  FETCH_INSTITUTION_FAILURE,
  statusType
} from '../../actions'

const initialState = {
  data: {},
}

const institution = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INSTITUTION_REQUEST: {
      const { entityId } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          [entityId]: {
            status: statusType.IN_PROGRESS
          }
        }
      }
    }
    case FETCH_INSTITUTION_SUCCESS: {
      const { factset_entity_id } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          [factset_entity_id]: {
            data: {
              ...action.payload
            },
            status: statusType.SUCCESS
          }
        }
      }
    }
    case FETCH_INSTITUTION_FAILURE: {
      const { entityId } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          [entityId]: {
            status: statusType.ERROR,
            error: action.error
          }
        }
      }
    }
    default:
      return state
  }
}

export default institution
