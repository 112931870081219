import { combineReducers } from 'redux'
import upcomingActivities from './upcomingActivities.reducer'
import currentActivities from './currentActivities.reducer'
import calendar from './calendar.reducer'

export default combineReducers({
  upcomingActivities,
  currentActivities,
  calendar
})
