import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { differenceWith } from 'lodash'

import { peerConfig } from '../../actions'
import { getActiveTicker } from '../../utils'
import { Modal } from '../../components'
import PeerConfigAutocomplete from './peerConfigAutocomplete/peerConfigAutocomplete.component'

import './peerConfigModal.container.css'
import { DATA_IDS } from './peerConfigModal.definition'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../services/launchDarkly.service'
const { PEER_CONFIG_MODAL, PEER_CONFIG_UPDATE_BUTTON, PEER_CONFIG_CANCEL_BUTTON } = DATA_IDS

const {
  addPeer,
  clearPeers,
  loadPeers,
  removePeer,
  resetAutoCompletePeers,
  searchPeers,
  setPeers,
  resetPeerConfig
} = peerConfig

class PeerConfigModalContainer extends PureComponent {
  componentDidMount () {
    const { rememberSelectionKey, setPeers } = this.props
    const savedSelection = rememberSelectionKey
      ? this.loadSelection()
      : []

    if (savedSelection) {
      setPeers(savedSelection)
      return
    }

    this.fetchPeers()
  }

  componentWillUnmount () {
    this.props.resetPeerConfig()
  }

  fetchPeers = () => {
    const { loadPeers, resetAutoCompletePeers, excludeMyCompany } = this.props

    const peerParams = {
      select: ['symbol', 'exchange', 'security_name', 'q4_entity_id', 'q4_ticker_id'],
      sortField: 'Symbol',
      sortOrder: 1,
      limit: 20,
      ...(excludeMyCompany !== false && {excludeMyCompany: true})
    }

    loadPeers(peerParams)
    resetAutoCompletePeers()
  }

  searchPeers = (searchValue) => {
    const { searchPeers, resetAutoCompletePeers } = this.props

    if (!searchValue) {
      resetAutoCompletePeers()
    } else {
      const params = {
        limit: 5,
        type: 'security',
        query: searchValue,
        useElasticSearch: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)
      }
      searchPeers(params)
    }
  }

  handlePeerReset = () => {
    this.fetchPeers()
  }

  handlePeerClear = () => {
    this.props.clearPeers()
  }

  handlePeerRemove = (securityId) => {
    this.props.removePeer(securityId)
  }

  handleSearchQueryChange = (searchValue) => {
    this.searchPeers(searchValue)
  }

  handleSearchClear = () => {
    this.searchPeers()
  }

  handleSearchSelect = (selectedPeer) => {
    const { addPeer } = this.props
    const { mongo_id, _security, q4_entity_id, q4_ticker_id, symbol, exchange, security_name } = selectedPeer

    addPeer({
      _security: _security || mongo_id,
      symbol,
      exchange,
      security_name,
      q4_entity_id,
      q4_ticker_id
    })
  }

  handlePeerSave = () => {
    const { rememberSelectionKey, onSave, onClose, peers } = this.props
    if (rememberSelectionKey) {
      this.saveSelection()
    }

    onSave && onSave({ peers })
    onClose && onClose()
  }

  getAvailablePeers = (allPeers, selectedPeers) => {
    return differenceWith(
      allPeers,
      selectedPeers,
      (a, b) => a.mongo_id === (b._security || b.mongo_id)
    )
  }

  saveSelection = () => {
    const { rememberSelectionKey, peers } = this.props
    if (rememberSelectionKey && peers) {
      localStorage.setItem(rememberSelectionKey, JSON.stringify(peers))
    }
  }

  loadSelection = () => {
    const { rememberSelectionKey } = this.props
    return rememberSelectionKey
      ? JSON.parse(localStorage.getItem(rememberSelectionKey))
      : []
  }

  render () {
    const { peers, foundPeers, ticker, onClose: onModalClose } = this.props

    return (
      <Modal
        dataId={PEER_CONFIG_MODAL}
        visible
        title='Modify Peers'
        className='peer-config-modal'
        containerWidth={600}
        onClose={onModalClose}
        scrollable
        contentPadding='0px'
        footerButtons={[{
          dataId: PEER_CONFIG_CANCEL_BUTTON,
          label: 'Cancel',
          ui: 'shaded',
          onClick: onModalClose
        }, {
          dataId: PEER_CONFIG_UPDATE_BUTTON,
          label: 'update',
          ui: 'citrus',
          onClick: this.handlePeerSave
        }]}
      >
        <PeerConfigAutocomplete
          isDetailed
          peers={peers}
          availablePeers={this.getAvailablePeers(foundPeers, peers, ticker)}
          onSearchQueryChange={this.handleSearchQueryChange}
          onSearchQueryClear={this.handleSearchClear}
          onSearchPeerSelect={this.handleSearchSelect}
          onRemovePeer={this.handlePeerRemove}
          onResetPeers={this.handlePeerReset}
          onAddDefaultPeers={this.handlePeerReset}
          onClearPeers={this.handlePeerClear}
        />
      </Modal>
    )
  }
}

PeerConfigModalContainer.propTypes = {
  /**
   * Used as a localStorage key to preserve previously selected items
   */
  rememberSelectionKey: PropTypes.string,

  /**
   * Used as a callback function to list the state up to the parent component
   */
  onSave: PropTypes.func,

  /**
   * Used as a callback function to fire on modal close event
   */
  onClose: PropTypes.func,

  /**
   * Currently selected peers
   */
  peers: PropTypes.arrayOf(PropTypes.shape({
    security_name: PropTypes.string.isRequired,
    exchange: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    _security: PropTypes.string.isRequired
  })),

  /**
   * Peers from Search API
   */
  foundPeers: PropTypes.arrayOf(PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    exchange: PropTypes.string.isRequired,
    security_name: PropTypes.string.isRequired,
    mongo_id: PropTypes.string.isRequired,
  })),
  ticker: PropTypes.shape({
    _security: PropTypes.string.isRequired
  }).isRequired,
  loadPeers: PropTypes.func.isRequired,
  clearPeers: PropTypes.func.isRequired,
  removePeer: PropTypes.func.isRequired,
  searchPeers: PropTypes.func.isRequired,
  addPeer: PropTypes.func.isRequired
}

PeerConfigModalContainer.defaultProps = {
  peers: [],
  foundPeers: []
}

const mapStateToProps = (state) => {
  const peerConfig = state.peerConfig
  const profileData = state.profile && state.profile.data
  const ticker = getActiveTicker(profileData)

  return {
    peers: peerConfig.peers,
    foundPeers: peerConfig.foundPeers,
    ticker
  }
}
const mapDispatchToProps = (dispatch) => ({
  setPeers: bindActionCreators(setPeers, dispatch),
  loadPeers: bindActionCreators(loadPeers, dispatch),
  clearPeers: bindActionCreators(clearPeers, dispatch),
  removePeer: bindActionCreators(removePeer, dispatch),
  searchPeers: bindActionCreators(searchPeers, dispatch),
  addPeer: bindActionCreators(addPeer, dispatch),
  resetAutoCompletePeers: bindActionCreators(resetAutoCompletePeers, dispatch),
  resetPeerConfig: bindActionCreators(resetPeerConfig, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PeerConfigModalContainer)
