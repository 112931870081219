import { round } from 'lodash'

/**
 * Returns true if object is a finite Number.
 * From Underscore.
 *
 * @param {Number|String} number A number
 * @returns {boolean}
 */
export const _isFinite = (number) => {
  if (!number && number !== 0) {
    return false
  } else {
    return isFinite(number) && !isNaN(parseFloat(number))
  }
}

/**
 * Retrun a value in Thousands
 * @param number
 * @param decimal
 * @returns {String}
 */
export const inThousands = (number, decimal) => {
  const scaled = getScaledNumber(number, decimal, {
    scale: Math.pow(10, 3)
  })

  return format(scaled, decimal)
}

/**
 * Round the value we always get into thresholds
 *
 * @param value
 * @returns {number}
 */
export const getRoundValue = (value) => {
  if (!value) {
    return value
  }

  return Math.round(value)
}

/**
 * Returns a number in a fixed point notation string format.
 *
 * @param {Number} number The number to format.
 * @param {Number} decimal The decimal points. Default: 2
 * @param {Object} options
 * @param {Boolean} options.round If the number should be rounded. Default: true
 * @returns {String} Formatted number
 */
export const toFixed = (number, decimal, options) => {
  options = options || {}
  decimal = (decimal || decimal === 0 ? decimal : 2)

  if (!_isFinite(number)) {
    return number
  }

  if (options.round === false) {
    const regex = new RegExp('^\\-?\\d+(?:\\.\\d{0,' + decimal + '})?', 'g')
    return Number(number).toString().match(regex)
  } else {
    return Number(number).toFixed(decimal)
  }
}

/**
 * Returns a number in Millions.
 *
 * @param {Number|String} number A number
 * @param {Number} decimal Optional decimal points
 * @returns {Number} Number in millions
 */
export const inMillions = (number, decimal) => {
  const scaled = getScaledNumber(number, decimal, {
    scale: Math.pow(10, 6)
  })

  return scaled
}

/**
 * Returns a number in Billions.
 *
 * @param {Number|String} number A number
 * @param {Number} decimal Optional decimal points
 * @returns {Number} Number in billions
 */
export const inBillions = (number, decimal) => {
  const scaled = getScaledNumber(number, decimal, {
    scale: Math.pow(10, 9)
  })

  return scaled
}

/**
 * Returns a number in different scale units (thousands, millions, billions, etc...).
 * This is only used internally.
 *
 * @param {Number} number The number to scale.
 * @param {Number} decimal The decimal places to get fixed.
 * @param {Object} options Parameters
 * @param {Number} options.scale The amount to scale (1000 for thousands, 1000000 for millions, etc...)
 * @returns {Number} The scaled number
 */
export const getScaledNumber = (number, decimal, options) => {
  // TODO: this logic exists in format, any reason we're repeating it here???
  if (!_isFinite(number)) {
    return number
  }

  let scaledNumber = number / options.scale

  // TODO: this logic exists in format, any reason we're repeating it here???
  if (typeof decimal === 'number') {
    scaledNumber = toFixed(round(scaledNumber, decimal), decimal)
  }

  return format(scaledNumber, decimal)
}

/**
 * Return if a value is Up, Down or Netural
 * @param value
 * @returns {string}
 */
export const getValueSign = (value) => {
  const number = parseFloat(value)

  if (number > 0) {
    return 'up'
  } else if (number < 0) {
    return 'down'
  } else {
    return 'neutral'
  }
}

/**
 * Return if a value is Above, Below or Met the expectations
 * @param value
 * @returns {string}
 */
export const aboveOrBelow = (value) => {
  const number = parseFloat(value)

  if (number > 0) {
    return 'above'
  } else if (number < 0) {
    return 'below'
  } else {
    return 'met'
  }
}

/**
 * Convert a number to it's abbreviated version (k, m, b, t)
 * @param number
 * @param decPlaces
 * @returns {*}
 */
export const abbreviate = (number, decPlaces) => {
  decPlaces = Math.pow(10, decPlaces)

  const abbrev = ['K', 'M', 'B', 'T']

  for (let i = abbrev.length - 1; i >= 0; i--) {
    const size = Math.pow(10, (i + 1) * 3)

    if (size <= number) {
      number = Math.round(number * decPlaces / size) / decPlaces

      if ((number === 1000) && (i < abbrev.length - 1)) {
        number = 1
        i++
      }

      number += abbrev[i]

      break
    }
  }

  return number
}

/**
 * Returns a number with comma separated thousands.
 *
 * @param number number A number
 * @param decimal
 * @param format
 * @param dropNegative
 * @returns {String} Number with commas
 */
export const format = (number, decimal, format, dropNegative) => {
  if (format && format === 'dash' && (isNaN(number) || !number)) {
    return '-'
  }

  const regex = /^(\d+)(\d{3})/
  const isNegativeMatched = (typeof decimal === 'number' ? Number(number).toFixed(decimal) < 0 : Number(number) < 0)
  let isNegative

  if (!_isFinite(number)) {
    return number
  }

  if (isNegativeMatched) {
    number = Math.abs(Number(number))
    isNegative = true
  }

  if (typeof decimal === 'number') {
    number = Number(round(number, decimal)).toFixed(decimal)
    number = Number(number) ? number : Number(0).toFixed(decimal)
  }

  if (typeof number === 'number') {
    number = number.toString()
  }

  while (regex.test(number)) {
    number = number.replace(regex, '$1,$2')
  }

  if (isNegative === true && !dropNegative) {
    number = '-' + number
  }

  return number
}

/**
 * Return a value in Millions
 * @param value
 * @returns {string}
 * @deprecated // TODO: inMillions already exists... why are we blindly adding new utils???
 */
export const intToMillions = (value) => {
  return (value / 1000000).toFixed(2)
}

/**
 * Formats estimates sales values based on specific criteria.
 * @param value
 * @returns {*}
 */
export const formatEstimatesSalesValue = (value) => {
  if (!value) {
    return '-'
  }

  if (_isFinite(value)) {
    if (Math.abs(value) > 1000) {
      return inThousands(value, 2) + 'B' // Estimates values comes in millions
    } else {
      return format(value, 2) + 'M'
    }
  } else {
    return value
  }
}

/**
 * Formats small number and round in 5 decimal place
 * @param num
 * @param isChange
 * @returns {string|*}
 */
export const roundSmallNum = (num) => {
  if (isNaN(num)) {
    return
  }
  if(!Number.isInteger(num)){
    return num.toFixed(5)
  }
  return num
}
