import React, {useEffect} from 'react';
import {isServiceEnabled, getEaIntegrationEnabledFlag } from '../../utils';
import { Banner, NoSubscriptionMessage } from '../../components';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import { config } from '../../config'

function EngagementPage (props) {
    const {profile} = props;
    const services = profile && profile.data.services
    const isEngagementSubscribed = isServiceEnabled('webcast', services) || isServiceEnabled('website', services)

    useEffect(() => {
        if (getEaIntegrationEnabledFlag() && services && services.length && isEngagementSubscribed) {
            window.location.href = '/'
            window.open(config.capitalConnectUrl ? `${config.capitalConnectUrl}/app/engagement` : '/', '_blank').focus();
        }
    }, [services, window, config, isEngagementSubscribed, getEaIntegrationEnabledFlag])

    if (getEaIntegrationEnabledFlag() && services && services.length && !isEngagementSubscribed) {
        return (
            <div className='engagement-page q4-fade-in'>
                <Banner
                    title='Engagement Analytics'
                    icon='q4i-star-2pt'
                />
                <NoSubscriptionMessage image={require('../../resources/images/subscription/engagement.png').default}/>
            </div>
        );
    }
    return null
}

const mapStateToProps = (state, props) => {
    const profile = state.profile;
    return {
        profile,
    };
};
export default withRouter(connect(mapStateToProps)(EngagementPage));
