const components = {
  Iris: require('./iris/nlg/nlg.container').default,
  Iframe: require('./iframe/iframe.container').default,
  Clock: require('./clock/clock.container').default,
  UpcomingActivities: require('./activity/upcomingActivities/upcomingActivities.container').default,
  TopBuyersSellers: require('./ownership/topBuyersSellers/topBuyersSellers.container').default,
  PeerMovers: require('./ownership/peerMovers/peerMovers.container').default,
  CurrentTopShareholders: require('./ownership/topShareholders/current/currentTopShareholders.container').default,
  WatchList: require('./watchlist/watchlist.container').default,
  Estimates: require('./estimates/estimates.container').default,
  StockChart: require('./stock/chart/chart.container').default,
  StockQuote: require('./stock/quote/quote.container').default,
  ActivityCalendar: require('./activity/calendar/calendar.container').default,
  Events: require('./events/events.container').default,
  Pipeline: require('./pipeline/pipeline.container').default,
  News: require('./news/news.container').default,
  AITargeting: require('./aiTargeting/aiTargeting.container').default,
  InstitutionWebAnalytics: require('./analytics/institutionWebAnalytics/institutionWebAnalytics.container').default,
  DownloadsWebAnalytics: require('./analytics/downloadsWebAnalytics/downloadsWebAnalytics.container').default,
  TradingAnalytics: require('./analytics/tradingAnalytics/tradingAnalytics.container').default,
  BrokerDetails: require('./estimates/brokerDetails/brokerDetails.container').default
}

export default components
