import React from 'react'
import PropTypes from 'prop-types'

import { useMoverQuery } from '../../hook'

import { Card } from '../../../index'
import RouteLink from '../../../routeLink/routeLink.component'
import { defaultIfEmpty, inMillions, MOVER_TYPE, THEMES, getLocalizedCurrency } from '../../../../utils'
import { get } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired
}

/**
 * Top Sells Component
 * @param props
 */
function TopSells (props) {
  const { dataId, theme, entityId, entityType } = props

  const { loading, data } = useMoverQuery({
    variables: {
      entityId,
      entityType,
      moverType: MOVER_TYPE.SELL,
      currencyCode: getLocalizedCurrency()
    }
  })

  const movers = get(data, 'mover.items', [])
  const total = get(data, 'mover.sum', null)
  const hasMovers = !loading && movers.length

  /**
   * Render Top Sells Component
   */
  return (
    <Card
      dataId={`${dataId}TopSellsCard`}
      className='card--medium'
      theme={theme}
      isLoading={loading}
      title={
        <>
          <div className='card_list-label'>
            Top Sells <span className='card_header-note'>{`3 Month Mkt Value Chg (MM ${getLocalizedCurrency()})`}</span>
          </div>
          <div className='card_list-value card_list-value--negative'>{hasMovers ? inMillions(total, 2) : null}</div>
        </>
      }
    >
      {(!loading && !hasMovers)
        ? 'No data available'
        : (movers || []).map((item) => {
          const { tickerId, securityName, marketValueChange } = item
          return (
            <RouteLink key={tickerId} className='card_list' to={tickerId && `/security/${tickerId}`}>
              <div className='card_list-label'>{defaultIfEmpty(securityName)}</div>
              <div className='card_list-value card_list-value--negative'>
                {inMillions(marketValueChange, 2)}
              </div>
            </RouteLink>
          )
        })}
    </Card>
  )
}

TopSells.propTypes = propTypes

export default TopSells
