import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

/**
 * Peer Action Types
 * @type {string}
 */
export const GET_PEERS_REQUEST = 'GET_PEERS_REQUEST'
export const GET_PEERS_SUCCESS = 'GET_PEERS_SUCCESS'
export const GET_PEERS_FAILURE = 'GET_PEERS_FAILURE'

export const CREATE_PEER_REQUEST = 'CREATE_PEER_REQUEST'
export const CREATE_PEER_SUCCESS = 'CREATE_PEER_SUCCESS'
export const CREATE_PEER_FAILURE = 'CREATE_PEER_FAILURE'

export const REMOVE_PEER_REQUEST = 'REMOVE_PEER_REQUEST'
export const REMOVE_PEER_SUCCESS = 'REMOVE_PEER_SUCCESS'
export const REMOVE_PEER_FAILURE = 'REMOVE_PEER_FAILURE'

export const RESET = 'RESET'

/**
 * Get Peers
 * @return {{}}
 * @private
 */
const _getPeers = (payload = {}) => ({
  [CALL_API]: {
    types: [
      GET_PEERS_REQUEST,
      GET_PEERS_SUCCESS,
      GET_PEERS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/watchlist/v1',
    payload
  }
})

/**
 * Create Peer
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _createPeer = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      CREATE_PEER_REQUEST,
      CREATE_PEER_SUCCESS,
      CREATE_PEER_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/watchlist/v2',
    payload: params
  }
})

/**
 * Delete Peer
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _deletePeer = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      REMOVE_PEER_REQUEST,
      REMOVE_PEER_SUCCESS,
      REMOVE_PEER_FAILURE
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: `/watchlist/v2/${params._id}`
  }
})

/**
 * Dispatch GET_PEERS_REQUEST
 */
export const getPeers = (params) => (dispatch) => {
  return dispatch(_getPeers(params))
}

/**
 * Dispatch CREATE_PEER_REQUEST
 */
export const createPeer = (params, options) => (dispatch) => {
  return dispatch(_createPeer(params, options))
}

/**
 * Dispatch REMOVE_PEER_REQUEST
 */
export const deletePeer = (id, options) => (dispatch) => {
  return dispatch(_deletePeer(id, options))
}

/**
 * Dispatch RESET
 */
export const resetPeers = () => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({ type: RESET })
    resolve()
  })
}
