import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fetchTradingAnalytics} from '../../../../actions/widget/analytics/tradingAnalytics.action';
import {bindActionCreators} from 'redux';
import {Scrollbars} from 'react-custom-scrollbars';
import {renderDarkThumb, renderLightThumb, renderTrackVertical} from '../../../../resources/theme/q4.custom-scrollbar';
import TradingAnalytics from '../../../../components/widget/analytics/tradingAnalytics/tradingAnalytics.component';
import {setClassNames} from '../../../../utils/widget.util';
import {Spinner} from '../../../../components';
import WidgetError from '../../../../components/widget/error/widgetError.component';
import {formatStockPrice, getCurrencyCode, getFromXigniteToStandard} from '../../../../utils';
import {FAILED, FETCHED, FETCHING} from '../../../../actions/widget/analytics';
import './tradingAnalytics.container.css';

class tradingAnalytics extends Component {

    /**
     * Fetch trading indicators data
     */
    fetchData() {
        const {securityId} = this.props;
        this.props.fetchTradingAnalytics(securityId);
    }

    /**
     * Triggered when component mounted
     * Enable auto-reloading if enabled
     */
    componentDidMount() {
        const {isEdit, status} = this.props;
        if (isEdit && status === FETCHED) {
            return;
        }

        const refreshInterval = this.props.options.refreshInterval;
        if (refreshInterval) {
            this.timer = setInterval(this.fetchData.bind(this), refreshInterval);
        }
        this.fetchData();
    }

    /**
     * ComponentDidUpdate
     * Re-fetch data when security changed
     * @param prevProps
     */
    componentDidUpdate = (prevProps) => {
        if (prevProps.securityId !== this.props.securityId) {
            this.fetchData();
        }
    };

    /**
     * clear fetch interval on unmount
     */
    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    getNaturalLanguage = () => {
        const {latestRange, indicators, stock} = this.props;
        const symbol = this.getSymbol();
        const currency = getCurrencyCode(stock.Currency);
        const low = formatStockPrice(latestRange && latestRange.low, currency, true);
        const high = formatStockPrice(latestRange && latestRange.high, currency, true);
        const {ovi, osi, oaa} = indicators;

        let html = [];

        if (!latestRange || !indicators || !symbol) {
            return '<span>There is not enough stock option data to provide Trading Analytics for this stock.</span>';
        }

        html.push('<span>');
        html.push(symbol);
        html.push('is subject to');

        if (osi >= -10 && osi <= 10 && ovi <= 26) {
            html.push('minimal');
        }
        else {
            html.push('potential');
        }

        html.push('stock price movement');

        if (high && low) {
            if (osi >= -50 && osi < -30) {
                if (ovi > 66) {
                    html.push('below', low, 'in the expected range of', low, 'and', high);
                }
                else {
                    html.push('near', low, 'in the expected range of', low, 'and', high);
                }
            }
            else if (osi >= -30 && osi < -10) {
                if (ovi > 66) {
                    html.push('near', low, 'in the expected range of', low, 'and', high);
                }
                else if (ovi <= 66) {
                    html.push('between', low, 'and', high);
                }
            }
            else if (osi >= -10 && osi <= 10) {
                if (ovi <= 26) {
                    html.push('around', formatStockPrice(stock.Last, currency, true), 'in the expected range of', low, 'and', high);
                } else if (ovi > 26) {
                    html.push('between', low, 'and', high);
                }
            }
            else if (osi > 10 && osi <= 30) {
                if (ovi > 66) {
                    html.push('near', high, 'in the expected range of', low, 'and', high);
                }
                else {
                    html.push('between', low, 'and', high);
                }
            }
            else if (osi > 30 && osi <= 50) {
                if (ovi > 66) {
                    html.push('above', high, 'in the expected range of', low, 'and', high);
                }
                else {
                    html.push('near', high, 'in the expected range of', low, 'and', high);
                }
            }
        }

        html.push('in the near future based upon');

        if (osi >= -10 && osi <= 10 && ovi <= 26) {
            html.push('low expected volatility and neutral expected volatility and forward-looking sentiment,');
        } else {
            if (osi >= -10 && osi <= 10) {
                html.push('neutral');
            }
            else if (osi > 10 && osi <= 30) {
                html.push('bullish');
            }
            else if (osi > 30) {
                html.push('very bullish');
            }
            else if (osi < -11 && osi >= -30) {
                html.push('bearish');
            }
            else if (osi < -30) {
                html.push('very bearish');
            }

            html.push('forward-looking sentiment and');

            if (ovi <= 26) {
                html.push('low');
            }
            else if (ovi > 26 && ovi <= 66) {
                html.push('moderate');
            }
            else if (ovi > 66) {
                html.push('high');
            }

            html.push('expected volatility');
        }

        html.push('with a');

        if (oaa <= 2) {
            html.push('low');
        } else if (oaa > 2 && oaa < 6) {
            html.push('moderate');
        } else if (oaa >= 6) {
            html.push('high');
        }

        html.push('risk of activist involvement.');
        html.push('</span>');

        return html.join(' ');
    };


    getSymbol = () => {
        return this.props.stock.Security && this.props.stock.Security.Symbol;
    };

    render() {
        const {theme, layout, indicators, status, profile, securityId, tickerId} = this.props;
        const tickers = (profile && profile._organization && (profile._organization.tickers || []));
        const classes = setClassNames('trading-analytics-container', layout, theme);
        const renderThumb = theme === 'dark' ? renderLightThumb : renderDarkThumb;
        const is_loading = status === FETCHING;
        const failed = status === FAILED;

        // get active company from organization's tickers, fallback to primary ticker
        const company = _.find((tickers), (ticker) => ticker._security === this.props.securityId)
            || _.find((tickers), (ticker) => ticker.primary);

        if (!is_loading && _.isEmpty(indicators)) {
            return (
                <WidgetError
                    theme={theme}
                    header={'Trading Indicators'}
                    message={'No Trading Indicators data available.'}
                />
            );
        }

        if (failed) {
            return (
                <WidgetError
                    theme={theme}
                    header={'Trading Indicators'}
                    message={'Trading Indicators had failed to load.'}
                />
            )
        }

        return (
            <div className={classes.base}>
                <header className={`${classes.name}_header`}>
                    <h2 className='trading-analytics-container_title'>Trading Analytics</h2>
                    <span
                        className='trading-analytics-container_company'>{company ? `${company.symbol} ${getFromXigniteToStandard(company.exchange)}` : null}</span>
                </header>
                <section className={`${classes.name}_wrapper`}>
                    {(is_loading && _.isEmpty(indicators)) ?
                        <Spinner/> :
                        <Scrollbars
                            className='react-scrollbar'
                            autoHide
                            hideTracksWhenNotNeeded
                            renderThumbVertical={renderThumb}
                            renderTrackVertical={renderTrackVertical}>
                            <TradingAnalytics
                                classes={classes}
                                indicators={this.props.indicators}
                                layout={layout}
                                securityId={securityId}
                                tickerId={tickerId}
                                theme={theme}
                                subscriptions={this.props.subscriptions}
                                getCommentary={this.props.options.includeCommentary && (() => {
                                    return this.getNaturalLanguage();
                                })}
                            />
                        </Scrollbars>
                    }
                </section>
                {this.props.options.includeCommentary ? <footer className={classes.footer}/> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const tradingAnalytics = state.widget.analytics.tradingAnalytics;

    return {
        theme: state.dashboard.dashboardState.theme,
        securityId: state.dashboard.dashboardState.securityId,
        profile: state.shared.profile,
        status: tradingAnalytics.status,
        indicators: tradingAnalytics.data,
        stock: tradingAnalytics.stock,
        latestRange: tradingAnalytics.expectedRanges
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchTradingAnalytics: bindActionCreators(fetchTradingAnalytics, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(tradingAnalytics);
