import { combineReducers } from 'redux'
import coverage from './coverage.reducer'
import estimate from './estimate.reducer'
import position from './position.reducer'

export default combineReducers({
  coverage,
  estimate,
  position
})
