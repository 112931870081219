import React, { PureComponent } from 'react';
import { get, isEmpty } from 'lodash';
import { Checkbox, TextInput } from '../../../shared'
import { Select } from '../../../index'

import { combine, THEMES } from '../../../../utils'
import './activityLocation.component.css';

class ActivityLocation extends PureComponent {

    constructor(props) {
        super(props);
        const addresses = get(props.values, 'address') || [];
        this.state = {
            isLoading: false,
            venue: this.hasMultipleAddresses(addresses) ? '' : (get(props.values, 'address[0].venue') || '')
        };
    }

    componentDidUpdate(prevProps) {
        const {locations, values} = this.props;
        const locationStatus = get(locations,'status');
        const venue = get(values, 'address[0].venue');

        if (locationStatus !== get(prevProps.locations, 'status') && locationStatus !== 'IN_PROGRESS') {
            this.setState({isLoading: false});
        }

        if (venue !== get(prevProps.values, 'address[0].venue')) {
            this.setState({
                venue: venue || ''
            });
        }
    }

    /**
     * Get Options
     * Check for parent addresses and return relevant suggestions based on input value
     */
    getOptions () {
      const { locations: { data }, values } = this.props
      const address = get(values, 'address[0].location', [])
      const parentAddress = get(values, '_activity.address') || get(values, '_activity.item.address') || []

      // If no search data AND no parent addresses, return null
      if ((!data || !Array.isArray(data)) && !parentAddress.length) {
          return null;
      }

      // If activity has parent locations and input is empty, return parent locations as default suggestions
      return (parentAddress.length && !address.length)
        ? parentAddress.map((address) => {
            return this.getValue(address && address.location)
        })
        : data.map((location) => {
            const city = get(location, 'location_city', '')
            const province = get(location, 'state_province_name', '')
            const code = get(location, 'state_province_code', '')
            const country = get(location, 'country_name', '')
            const locationName = combine([city, province && code, country], ', ')

            return this.getValue(locationName)
          })
    };

    /**
     * Handle Input Change
     * @param value
     * @param action
     */
    handleInputChange = (value, action) => {
          const {clearSearchData, searchLocation, setFieldValue} = this.props;
          const isValueEmptyString = isEmpty(value);
          const actions = ['input-blur', 'menu-close', 'set-value']

          this.setState({
              isLoading: !isValueEmptyString
          });

          isValueEmptyString ? clearSearchData() : searchLocation(value);

          if (action && action.action && !actions.includes(action.action)) {
            setFieldValue('address[0].location', value)
          }
      };

    /**
     * Return string value as object
     * @param location
     * @return {*}
     */
    getValue = (location) => {
        return (location ? {
          label: location,
          value: location
        } : null)
      }

    /**
     * Set value from selected list item
     * @param selection
     */
    handleChange = (selection) => {
        const { setFieldValue } = this.props
        const value = (selection && selection.value) || ''
        setFieldValue('address[0].location', value)
      }

    /**
     * Check to determine if form contains multiple locations
     * @param [addresses]
     */
    hasMultipleAddresses = (addresses) => {
        return addresses.length > 1;
    }

    render() {
        const {values: { address, virtual }, handleChange, setFieldValue} = this.props;
        const {isLoading, venue} = this.state;
        const location = get(address, '[0].location', '')
        const options = this.getOptions()

        return (
            <div className='activity-form__activity-location'>
              <div className='activity-location_container'>
                <div className='field--text field--half'>
                  <label className='field_label'>
                    Location
                  </label>
                  <Select
                    placeholder='City, State, Country'
                    disabled={virtual}
                    loading={isLoading}
                    options={options}
                    value={virtual ? null : this.getValue(location)}
                    onFocus={() => setFieldValue('address[0].location', '')}
                    onInputChange={this.handleInputChange}
                    onChange={this.handleChange}
                    error={{ isError: Boolean(!virtual && !location && venue) }}
                    showDropdownIndicator={false}
                  />
                </div>
                <div className='field--text field--half'>
                  <label className='field_label'>
                    Venue
                  </label>
                  <TextInput
                    readOnly={virtual}
                    name={'address[0].venue'}
                    value={virtual ? '' : venue}
                    onChange={handleChange}
                    placeholder='Address'
                  />
                </div>
              </div>
                <Checkbox
                  theme={THEMES.LIGHT}
                  id='activity-location_virtual'
                  isChecked={!!virtual}
                  label='Virtual'
                  onChange={(value) => setFieldValue('virtual', value)}
                />
            </div>
        );
    }
}

export default ActivityLocation;
