import { combineReducers } from 'redux'
import advanced from './advanced.reducer'
import entity from './entity.reducer'
import quick from './quick.reducer'

export default combineReducers({
  advanced,
  entity,
  quick
})
