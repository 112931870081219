import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// actions
import { usePeerQuery } from '../../../hook'

// components
import PeerHoldingComponent from './peerHolding.component'
import { get } from 'lodash'
import { isTradingTicker, getLocalizedCurrency } from '../../../../../utils'

const propTypes = {
  tickerId: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const defaultProps = {
  limit: 5
}

/**
 * Peer Holdings
 * @param props
 */
function PeerHolding (props) {
  const { entityId, entityType, tickerId, limit, securityName } = props

  const { data } = usePeerQuery({
    variables: {
      tickerId,
      entityId,
      entityType,
      holder: true,
      limit: Number(limit),
      sortBy: 'q0',
      sortDir: 'desc',
      currencyCode: getLocalizedCurrency()
    }
  })

  const peers = get(data, 'peerCurrent.items', [])
  const security = isTradingTicker(tickerId) ? get(data, 'securityCurrent.items[0]', {}) : {"securityName": securityName}
  const peersChange = get(data, 'peerQtrChange.items', [])
  const securityChange = get(data, 'securityQtrChange.items[0]', {})
  const peersOnly = peers.filter((peer) => peer.tickerId !== security.tickerId)
  const peersOnlyChange = peersChange.filter((peer) => peer.tickerId !== security.tickerId)

  return (
    <Grid container spacing={3} className='tearsheet_section'>
      <Grid item xs={12} className='tearsheet_section-title'>{`Top ${limit} Peer Holdings`}</Grid>
      <Grid item xs={12} className='tearsheet_section-content'>
        <PeerHoldingComponent
          data={{
            tickerId,
            securityName,
            position: peers.length ? [security, ...peersOnly] : [{}],
            change: peersChange.length ? [securityChange, ...peersOnlyChange] : peersChange,
            marketValue: get(data, 'marketValue', {})
          }}
        />
      </Grid>
    </Grid>
  )
}

PeerHolding.propTypes = propTypes
PeerHolding.defaultProps = defaultProps

export default PeerHolding
