import {
  statusType,
  GET_TOP_INSTITUTION_MANAGERS_FAILURE,
  GET_TOP_INSTITUTION_MANAGERS_REQUEST,
  GET_TOP_INSTITUTION_MANAGERS_SUCCESS
} from '../../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const topInstitutionManagersReducer = (state = initial, action) => {
  switch (action.type) {
    case GET_TOP_INSTITUTION_MANAGERS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }
    case GET_TOP_INSTITUTION_MANAGERS_SUCCESS:
      return {
        ...state,
        data: (action.payload || [])
      .filter((contact) => contact)
      .map((contact) => {
          return {
            _id: contact._id,
            name: contact['full_name'],
            currentPeerHoldingsValues: contact.currentPeerHoldingsValues,
            averageCurrentPeerHoldingsValue: contact.averageCurrentPeerHoldingsValue,
            peerHoldingsChangePercent: contact.peerHoldingsChangePercent,
            currentAum: contact.currentAum,
          }
        }),
        status: statusType.SUCCESS
      }
    case GET_TOP_INSTITUTION_MANAGERS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }
    default:
      return state
  }
}

export default topInstitutionManagersReducer
