import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const GET_REPORT_FIELDS_REQUEST = 'GET_REPORT_FIELDS_REQUEST'
export const GET_REPORT_FIELDS_SUCCESS = 'GET_REPORT_FIELDS_SUCCESS'
export const GET_REPORT_FIELDS_ERROR = 'GET_REPORT_FIELDS_ERROR'

export const GET_REPORT_BUILDER_FIELDS_REQUEST = 'GET_REPORT_BUILDER_FIELDS_REQUEST'
export const GET_REPORT_BUILDER_FIELDS_SUCCESS = 'GET_REPORT_BUILDER_FIELDS_SUCCESS'
export const GET_REPORT_BUILDER_FIELDS_ERROR = 'GET_REPORT_BUILDER_FIELDS_ERROR'

/**
 * Get Report Fields
 * @private
 */
const _getReportFields = (payload) => ({
  [CALL_API]: {
    types: [
      GET_REPORT_FIELDS_REQUEST,
      GET_REPORT_FIELDS_SUCCESS,
      GET_REPORT_FIELDS_ERROR
    ],
    payload,
    method: METHOD_TYPE.GET,
    endpoint: '/report/dataItem/reportFields'
  }
})

/**
 * Get Report Builder Fields
 * @private
 */
const _getReportBuilderFields = (payload) => ({
  [CALL_API]: {
    types: [
      GET_REPORT_BUILDER_FIELDS_REQUEST,
      GET_REPORT_BUILDER_FIELDS_SUCCESS,
      GET_REPORT_BUILDER_FIELDS_ERROR
    ],
    payload,
    method: METHOD_TYPE.GET,
    endpoint: '/report/dataItem/reportFields'
  }
})

/**
 * Get report data item fields
 * @param params
 * @returns {function(*): *}
 */
export const getReportFields = (params) => (dispatch) => {
  return dispatch(_getReportFields(params))
}

/**
 * Get report builder fields
 * @param params
 * @returns {Function}
 */
export const getReportBuilderFields = (params) => (dispatch) => {
  return dispatch(_getReportBuilderFields(params))
}
