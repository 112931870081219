import { trim } from 'lodash'

const buttonClass = 'editor-button'

export const toolbarOptions = {
  options: ['fontSize', 'inline', 'list', 'textAlign'],
  fontSize: {
    inDropdown: true,
    className: 'editor-fontsize',
    options: [11, 13, 15, 18, 24, 36, 48]
  },
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline'],
    bold: { className: `${buttonClass}--bold` },
    italic: { className: `${buttonClass}--italic` },
    underline: { className: `${buttonClass}--underline end` }
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
    unordered: { className: `${buttonClass}--bullet` },
    ordered: { className: `${buttonClass}--numbered end` }
  },
  textAlign: {
    inDropdown: false,
    options: ['left', 'center', 'right'],
    left: { className: `${buttonClass}--left` },
    center: { className: `${buttonClass}--center` },
    right: { className: `${buttonClass}--right end` }
  }
}

/**
 * Determine if body provided from DraftJS is empty
 * The plugin currently has two ways to show empty editor
 * eg <p></p> or <p attribute...></p>
 * @param body
 * @return boolean
 * @private
 */
export const isEmptyBody = (body = '') => {
  // empty string or null values
  if (!body.length) { return true }

  const element = document.createElement('div')
  element.innerHTML = body.trim()

  // has multiple html elements in body
  if (element.childElementCount > 1) { return false }

  // has one child, eg <p></p> default behaviour for draftJS
  if (element.children.length) {
    const firstChildElement = element.children[0]

    // check for plain text and no inner children
    return !firstChildElement.innerText?.length && firstChildElement.childElementCount === 0
  }
}

export const normalizeBody = (body) => {
  const _body = trim(body)
  return isEmptyBody(_body) ? '' : _body
}

/**
 * Returns new EditorState with all content selected
 */
export const selectAllContent = (editorState) => {
  if (!editorState) {
    return
  }

  const currentContent = editorState.getCurrentContent()

  return editorState.getSelection().merge({
    anchorKey: currentContent.getFirstBlock().getKey(),
    anchorOffset: 0,

    focusOffset: currentContent.getLastBlock().getText().length,
    focusKey: currentContent.getLastBlock().getKey()
  })
}
