import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

// actions
import { useTagQuery, useTagSearchQuery, useTagCreateQuery, useTagDeleteQuery } from './hook'
import { openModal, modalType } from '../../actions'

// components
import TagInputComponent from './tagInput.component'

// utils
import { TAG_TYPE, THEMES } from '../../utils'
import { get } from 'lodash'

const { CONTACT, FUND, INSTITUTION, SECURITY, ACTIVITY } = TAG_TYPE

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf([CONTACT, FUND, INSTITUTION, SECURITY, ACTIVITY]).isRequired,
  limit: PropTypes.number
}

const defaultProps = {
  theme: THEMES.DARK,
  limit: 3
}

/**
 * Tag Input Component
 * @param props
 */
function TagInput (props) {
  const { dataId, theme, entityId, entityType, limit, openModal, history } = props
  const [search, setSearch] = useState(null)

  const { data, refetch } = useTagQuery({ variables: { entityId, entityType } })
  const [handleCreate] = useTagCreateQuery()
  const [handleDelete] = useTagDeleteQuery()

  const [handleSearch, { loading: isSearching, data: suggestions }] = useTagSearchQuery()
  const handleTagSearch = (value) => {
    setSearch((value && value.length) ? value : null)
    search && handleSearch({ variables: { search } })
  }

  const tags = get(data, 'tag.items', [])

  /**
   * Handle tag create
   * @param value
   */
  const handleTagCreate = ({ name, onSuccess }) => {
    if (!name || !entityId || !entityType) {
      return
    }

    handleCreate({ variables: { name, entity: { entityId, entityType } } }).then((response) =>
      get(response, 'errors') ? handleFailure() : handleSuccess(onSuccess))
  }

  /**
   * Handle tag delete
   * @param variables
   */
  const handleTagDelete = (variables) => {
    const { id } = (variables || {})
    if (!id || !entityId || !entityType) {
      return
    }

    handleDelete({ variables: { id, entityId: [entityId], entityType } }).then((response) =>
      get(response, 'errors') ? handleFailure() : handleSuccess())
  }

  /**
   * Handle action success
   */
  const handleSuccess = (onSuccess) => {
    refetch().then(() => {
      setSearch(null)
      onSuccess && onSuccess()
    })
  }

  /**
   * Handle action error
   */
  const handleFailure = () => {
    openModal({ type: modalType.ERROR_MODAL })
  }

  return (
    <TagInputComponent
      dataId={`${dataId}Tags`}
      theme={theme}
      search={search}
      isSearching={isSearching}
      suggestions={search ? get(suggestions, 'tag.items', []) : []}
      tags={tags}
      limit={limit}
      onSearch={handleTagSearch}
      onCreate={handleTagCreate}
      onDelete={handleTagDelete}
      history={history}
    />
  )
}

TagInput.propTypes = propTypes
TagInput.defaultProps = defaultProps

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

export default withRouter(connect(null, mapDispatchToProps)(TagInput))
