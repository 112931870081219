import {
  FETCH_NLG_COMMENTARY_REQUEST,
  FETCH_NLG_COMMENTARY_SUCCESS,
  FETCH_NLG_COMMENTARY_ERROR,
  NLG_SET_CURRENT_PEER,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../../actions/widget/iris/index'

const initial = {
  commentary: {},
  status: IDLE
}

const commentary = (state = initial, action) => {
  switch (action.type) {
    case NLG_SET_CURRENT_PEER:
      return {
        ...state,
        commentary: {},
        error: null
      }
    case FETCH_NLG_COMMENTARY_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_NLG_COMMENTARY_SUCCESS:
      return {
        ...state,
        commentary: action.payload.tpl,
        status: FETCHED,
        error: null
      }
    case FETCH_NLG_COMMENTARY_ERROR:
      return {
        ...state,
        error: action.error,
        commentary: {},
        status: FAILED
      }
    default:
      return state
  }
}

export default commentary
