import React from 'react'
import { Badge } from '../../../../../../../components'
import { THEMES } from '../../../../../../../utils/ui'
import Grid from '@material-ui/core/Grid'

/**
 * Detail Badge Component
 * @param props
 * @returns {*}
 * @constructor
 */
function FlyoutBadge ({ targetId }) {
  const items = [{
    icon: 'q4i-targeting-4pt',
    label: 'Saved Target',
    hidden: !targetId
  }].filter((badge) => !badge.hidden)

  return items.length
    ? <Grid item xs={12}>
      <Badge theme={THEMES.LIGHT} items={items} />
    </Grid>
    : null
}

export default FlyoutBadge
