import React, { Component } from 'react'
import './quote.component.css'
import { getValueSign, intToMillions } from '../../../../utils/number.util'
import moment from 'moment-timezone'

export default class StockQuoteItem extends Component {
  render () {
    const security = this.props.stockQuote && this.props.stockQuote.Security
    const last = parseFloat(this.props.stockQuote.Last || 0).toFixed(2)
    const change = parseFloat(this.props.stockQuote.ChangeFromPreviousClose || 0).toFixed(2)
    const changePercent = parseFloat(this.props.stockQuote.PercentChangeFromPreviousClose || 0).toFixed(2)

    let size = 'large'

    switch (last.toString().length) {
      case 6:
        size = 'medium'
        break
      case 7:
        size = 'small'
        break
      default:
        size = 'large'
    }

    const date = new Date(this.props.stockQuote.Date).getDay() === new Date().getDay() ? '15m del'
      : moment(this.props.stockQuote.TradeDate).format('MMM DD, YYYY')

    return (
      <div>
        <h2 className={`stock-quote_price stock-quote_price--${size} q4-fade-in`}>
          {last}
          <span className='stock-quote_date'>{date}</span>
        </h2>
        <div className='stock-quote_details'>
          <span className='stock-quote_item stock-quote_item--security'>
            {security && security.Symbol}
            <span className='stock-quote_separator' />
            {security && security.Market}
          </span>
          <span
            className={'stock-quote_item stock-quote_item--change stock-quote_item--' + getValueSign(this.props.stockQuote.ChangeFromPreviousClose)}
          >
            {change} ({changePercent}%)
          </span>
          <span className='stock-quote_item stock-quote_item--volume'>
            {intToMillions(this.props.stockQuote.Volume)}M Vol 1d
          </span>
          <span className='stock-quote_item stock-quote_item--volume'>
            {intToMillions(this.props.averageVolume)}M Avg Vol 1m
          </span>
          {
            this.props.children
          }
        </div>
      </div>
    )
  }
}
