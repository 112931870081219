import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { getHistQtrFields, ENTITY_TYPE, OWNERSHIP_TYPE } from '../../../utils'
import { get, isNil } from 'lodash'

const { FUND } = ENTITY_TYPE

export const GET_INST_CURRENT_HOLDERS = gql`
  query InstitutionHoldingCurrent(
    $tickerId: [String]!
    $search: String
    $source: String = "${OWNERSHIP_TYPE['13F']}"
    $currencyCode: String
    $excludeStalePositions: Boolean
    $isActivist: Boolean
    $holdingType: [String]
    $activity: Boolean
    $startDate: String
    $endDate: String
    $position: String
    $style: [String]
    $type: [String]
    $turnover: [String]
    $sortBy: String
    $sortDir: String
    $page: Int!
    $limit: Int!
  ) {
    instHoldingCurrent(
      tickerId: $tickerId
      search: $search
      source: $source
      currencyCode: $currencyCode
      excludeStalePositions: $excludeStalePositions
      filter: {
        isActivist: $isActivist
        holdingType: $holdingType
        position: $position
        institutionStyle: $style
        institutionType: $type
        institutionTurnover: $turnover
        activity: $activity
        activityDate: {
          startDate: $startDate
          endDate: $endDate
        }
      }
      sortBy: $sortBy
      sortDir: $sortDir
      page: $page
      limit: $limit
    ) {
      items {
        institutionId
        institutionName
        institutionStyle
        institutionType
        institutionTurnover
        institutionQualityRating
        institutionTotalAUM
        institutionEquityAUM
        institutionRegion
        institutionCountryName
        filingType
        activist
        current
        change
        qtrChange
        marketValue
        marketValueChange
        marketValueQtrChange
        percentTSO
        percentPortfolio
        reportDate
        currentQtrDate
        prevReportDate
        previousQtrPosition
        holderType
        holdingType
        filingSource
        filingOrigin

        activityConnection {
          items {
            start {
              date
            }
          }
        }

        dealConnection {
          items {
            id
          }
        }

        targetConnection {
          items {
            id
          }
        }

        fundHoldingCurrentConnection(
          tickerId: $tickerId
          source: $source
          currencyCode: $currencyCode
        ) {
          items {
            fundId
            institutionId
            fundName
            fundType
            fundStyle
            fundTurnover
            fundQualityRating
            fundPortfolioValue
            fundEquityAUM
            fundRegion
            fundCountryName
            fundCountryCode
            filingType
            current
            change
            qtrChange
            marketValue
            marketValueChange
            marketValueQtrChange
            percentTSO
            percentPortfolio
            reportDate
            currentQtrDate
            prevReportDate
            holderType
          }
        }
      }
      count
    }
  }`

export const GET_INST_CURRENT_HOLDERS_EXPORT = gql`
  query InstitutionHoldingCurrent(
    $tickerId: [String]!
    $search: String
    $source: String = "${OWNERSHIP_TYPE['13F']}"
    $currencyCode: String
    $excludeStalePositions: Boolean
    $isActivist: Boolean
    $holdingType: [String]
    $activity: Boolean
    $startDate: String
    $endDate: String
    $position: String
    $style: [String]
    $type: [String]
    $turnover: [String]
    $sortBy: String
    $sortDir: String
    $page: Int!
    $limit: Int!
  ) {
    instHoldingCurrent(
      tickerId: $tickerId
      search: $search
      source: $source
      currencyCode: $currencyCode
      excludeStalePositions: $excludeStalePositions
      filter: {
        isActivist: $isActivist
        holdingType: $holdingType
        position: $position
        institutionStyle: $style
        institutionType: $type
        institutionTurnover: $turnover
        activity: $activity
        activityDate: {
          startDate: $startDate
          endDate: $endDate
        }
      }
      sortBy: $sortBy
      sortDir: $sortDir
      page: $page
      limit: $limit
    ) {
      items {
        institutionName
        institutionStyle
        institutionTurnover
        institutionType
        institutionQualityRating
        institutionTotalAUM
        institutionEquityAUM
        filingType
        current
        change
        qtrChange
        marketValue
        marketValueChange
        percentTSO
        percentPortfolio
        reportDate
        previousQtrPosition
        filingSource
        filingOrigin

        activityConnection {
          items {
            start {
              date
            }
          }
        }
      }
      count
    }
  }`

export const GET_INST_HISTORICAL_HOLDERS = (quarters) => {
  const quarterlyFields = getHistQtrFields(quarters)
  return gql`
    query InstitutionHoldingHistorical(
      $tickerId: [String]!
      $quarters: Int!
      $search: String
      $source: String = "${OWNERSHIP_TYPE['13F']}"
      $holdingType: [String]
      $sortBy: String
      $sortDir: String
      $page: Int!
      $limit: Int!
    ) {
      instHoldingHistorical(
        tickerId: $tickerId
        quarters: $quarters
        search: $search
        source: $source
        filter: {
          holdingType: $holdingType
        }
        sortBy: $sortBy
        sortDir: $sortDir
        page: $page
        limit: $limit
      ) {
        items {
          institutionId
          institutionName
          holderType
          holdingType
          institutionStyle
          institutionTurnover
          institutionType
          ${quarterlyFields}
        }
        count
      }
    }`
}

export const GET_FUND_CURRENT_HOLDERS = gql`
  query FundHoldingCurrent(
    $tickerId: [String]!
    $activity: Boolean
    $startDate: String
    $endDate: String
    $search: String
    $excludeStalePositions: Boolean
    $source: String = "${OWNERSHIP_TYPE['13F']}"
    $currencyCode: String
    $position: String
    $style: [String]
    $type: [String]
    $turnover: [String]
    $sortBy: String
    $sortDir: String
    $page: Int!
    $limit: Int!
  ) {
    fundHoldingCurrent(
      tickerId: $tickerId
      search: $search
      source: $source
      excludeStalePositions: $excludeStalePositions
      currencyCode: $currencyCode
      filter: {
        position: $position
        fundStyle: $style
        fundType: $type
        fundTurnover: $turnover
        activity: $activity
        activityDate: {
          startDate: $startDate
          endDate: $endDate
        }
      }
      sortBy: $sortBy
      sortDir: $sortDir
      page: $page
      limit: $limit
    ) {
      items {
        fundId
        fundName
        fundType
        fundStyle
        fundTurnover
        fundQualityRating
        fundPortfolioValue
        fundEquityAUM
        fundRegion
        fundCountryName
        fundCountryCode
        filingType
        current
        change
        qtrChange
        marketValue
        marketValueChange
        marketValueQtrChange
        percentTSO
        percentPortfolio
        reportDate
        currentQtrDate
        prevReportDate
        previousQtrPosition
        holderType
        
        activityConnection {
          items {
            start {
              date
            }
          }
        }
      }
      count
    }
  }`

export const GET_FUND_HISTORICAL_HOLDERS = (quarters) => {
  const quarterlyFields = getHistQtrFields(quarters)

  return gql`
    query FundHoldingHistorical(
      $tickerId: [String]!
      $quarters: Int!
      $search: String
      $source: String = "${OWNERSHIP_TYPE['13F']}"
      $sortBy: String
      $sortDir: String
      $page: Int!
      $limit: Int!
    ) {
      fundHoldingHistorical(
        tickerId: $tickerId
        quarters: $quarters
        search: $search
        source: $source
        sortBy: $sortBy
        sortDir: $sortDir
        page: $page
        limit: $limit
      ) {
        items {
          fundId
          fundName
          holderType
          fundStyle
          fundTurnover
          fundType
          ${quarterlyFields}
        }
        count
      }
    }`
}

export const GET_SHAREHOLDERID_HOLDERS = gql`
  query ShareholderIdHolding(
    $tickerId: [String]!
    $startDate: String
    $source: String = "${OWNERSHIP_TYPE.SHAREHOLDER}"
    $search: String
    $isActivist: Boolean
    $position: String
    $style: [String]
    $type: [String]
    $turnover: [String]
    $sortBy: String
    $sortDir: String
    $page: Int!
    $limit: Int!
  ) {
    instHolding(
      tickerId: $tickerId
      startDate: $startDate
      source: $source
      search: $search
      filter: {
        isActivist: $isActivist
        position: $position
        institutionStyle: $style
        institutionType: $type
        institutionTurnover: $turnover
      }
      sortBy: $sortBy
      sortDir: $sortDir
      page: $page
      limit: $limit
    ) {
      items {
        institutionId
        institutionName
        institutionStyle
        institutionType
        institutionTurnover
        institutionQualityRating
        institutionRegion
        institutionCountryName
        entityId
        currentOrd
        currentAdr
        activist
        holderType
        holdings {
          change
          current
          qtrChange
          marketValue
          marketValueChange
          marketValueQtrChange
          reportDate
          prevReportDate
          currentQtrDate
          percentTSO
          percentPortfolio
        }

        dealConnection {
          items {
            id
          }
        }

        targetConnection {
          items {
            id
          }
        }
      }
      count
    }
  }`

export const GET_SHAREHOLDERID_FUND_HOLDERS = gql`
  query FundHolding (
    $startDate: String,
    $institutionId: [String],
    $tickerId: [String],
    $source: String
  ) {
    fundHolding (
      startDate: $startDate,
      tickerId: $tickerId,
      institutionId: $institutionId,
      source: $source,
      limit: 500
    ) {
      items {
        fundId
        institutionId
        fundName
        fundStyle
        fundType
        fundTurnover
        fundQualityRating
        fundTotalAUM
        fundEquityAUM
        fundRegion
        fundCountryName
        entityId
        currentOrd
        currentAdr
        holderType
        holdings {
          current
          qtrChange
          marketValue
          marketValueChange
          marketValueQtrChange
          reportDate
          prevReportDate
          currentQtrDate
          percentTSO
          percentPortfolio
        }
      }
    }
  }
`

export const GET_SHAREHOLDERID_HOLDERS_EXPORT = gql`
  query ShareholderIdHolding(
    $tickerId: [String]!
    $startDate: String
    $source: String = "${OWNERSHIP_TYPE.SHAREHOLDER}"
    $search: String
    $isActivist: Boolean
    $position: String
    $style: [String]
    $type: [String]
    $turnover: [String]
    $sortBy: String
    $sortDir: String
    $page: Int!
    $limit: Int!
  ) {
    instHolding(
      tickerId: $tickerId
      startDate: $startDate
      source: $source
      search: $search
      filter: {
        isActivist: $isActivist
        position: $position
        institutionStyle: $style
        institutionType: $type
        institutionTurnover: $turnover
      }
      sortBy: $sortBy
      sortDir: $sortDir
      page: $page
      limit: $limit
    ) {
      items {
        institutionName
        institutionStyle
        institutionType
        institutionTurnover
        institutionQualityRating
        currentOrd
        currentAdr
        holdings {
          change
          current
          qtrChange
          marketValue
          marketValueChange
          reportDate
          percentTSO
        }
      }
      count
    }
  }`

export const GET_OWNERSHIP = gql`
  query SecurityOwnership(
    $tickerId: [String]!
    $source: String = "${OWNERSHIP_TYPE['13F']}"
    $metric: String!
    $limit: Int!
    $quarterDate: String
  ) {
    style: instHoldingHistorical(
      tickerId: $tickerId
      source: $source
      metric: $metric
      group: {
        by: "institutionStyle"
        operator: sum
      }
      limit: $limit
      quarterDate: $quarterDate
    ) {
      items {
        institutionStyle
        q1Value
        q2Value
        q3Value
        q4Value
      }
    }
    type: instHoldingHistorical(
      tickerId: $tickerId
      source: $source
      metric: $metric
      group: {
        by: "institutionType"
        operator: sum
      }
      limit: $limit
      quarterDate: $quarterDate
    ) {
      items {
        institutionType
        q1Value
        q2Value
        q3Value
        q4Value
      }
    }
    turnover: instHoldingHistorical(
      tickerId: $tickerId
      source: $source
      metric: $metric
      group: {
        by: "institutionTurnover"
        operator: sum
      }
      limit: $limit
      quarterDate: $quarterDate
    ) {
      items {
        institutionTurnover
        q1Value
        q2Value
        q3Value
        q4Value
      }
    }
    region: instHoldingHistorical(
      tickerId: $tickerId
      source: $source
      metric: $metric
      group: {
        by: "institutionRegion"
        operator: sum
      }
      limit: $limit
      quarterDate: $quarterDate
    ) {
      items {
        institutionRegion
        q1Value
        q2Value
        q3Value
        q4Value
      }
    }
  }`

const GET_SHAREHOLDERID_FILTER = gql`
  query ShareholderIdFilter(
    $tickerId: [String]!
    $source: String = "${OWNERSHIP_TYPE.SHAREHOLDER}"
    $limit: Int = 100
  ) {
    institutionStyle: instHolding(
      tickerId: $tickerId
      source: $source
      limit: $limit
      sortBy: "institutionStyle"
      sortDir: "asc"
      group: {
        by: "institutionStyle"
        operator: sum
      }
    ) {
      items {
        institutionStyle
      }
    }

    institutionType: instHolding(
      tickerId: $tickerId
      source: $source
      limit: $limit
      sortBy: "institutionType"
      sortDir: "asc"
      group: {
        by: "institutionType"
        operator: sum
      }
    ) {
      items {
        institutionType
      }
    }
  }`

export const GET_SECURITY_PEER_ANALYSIS = gql`
  query securityPeerAnalysis(
    $tickerId: String!
    $peerTickerId: [String]!
    $entityType: String!
    $metric: String!
    $search: String
    $page: Int!
    $limit: Int!
  ) { 
    securityPeerAnalysis: securityPeerAnalysis (
      tickerId: $tickerId, 
      peerTickerId: $peerTickerId, 
      entityType: $entityType, 
      metric: $metric, 
      search: $search, 
      page: $page, 
      limit: $limit
    ) {
      items {
        entityId
        entityType
        entityName
        holdings {
          securityName
          securityId
          tickerId
          exchange
          securitySymbol
          value
          reportDate
        }
      }
      count
    }
  }`

/**
 * Current Holders
 * @param type
 * @param options
 */
export function useCurrentHolderQuery (type, options) {
  const query = type === FUND ? GET_FUND_CURRENT_HOLDERS : GET_INST_CURRENT_HOLDERS
  return handleQueryResult(useQuery(query, options))
}

/**
 * Historical Holders
 * @param type
 * @param options
 */
export function useHistoricalHolderQuery (type, options) {
  const quarters = get(options, 'variables.quarters', 4)
  const query =
    type === FUND
      ? GET_FUND_HISTORICAL_HOLDERS(quarters)
      : GET_INST_HISTORICAL_HOLDERS(quarters)
  return handleQueryResult(useQuery(query, options))
}

/**
 * Surveillance Holders
 * @param type
 * @param options
 */
export function useSurveillanceHolderQuery (type, options) {
  const query = type === FUND ? GET_FUND_CURRENT_HOLDERS : GET_INST_CURRENT_HOLDERS
  return handleQueryResult(useQuery(query, options))
}

/**
 * ShareholderId Holders
 * @param options
 */
export function useShareholderIdHolderQuery (options) {
  return handleQueryResult(useQuery(GET_SHAREHOLDERID_HOLDERS, options))
}

/**
 * Security Ownership
 * @param options
 */
export function useOwnershipQuery (options) {
  return handleQueryResult(useQuery(GET_OWNERSHIP, options))
}

/**
 * ShareholderId Filter Options
 * @param options
 */
export function useShareholderIdFilterQuery (options) {
  return useQuery(GET_SHAREHOLDERID_FILTER, options)
}

/**
 * Security Peer Analysis
 * @param options
 */
export function useSecurityPeerAnalysisQuery (options) {
  return useQuery(GET_SECURITY_PEER_ANALYSIS, options)
}

/**
 * @typedef { import('@apollo/react-common').QueryResult } QueryResult
 *
 * @param {QueryResult} queryResult
 * @returns {QueryResult}
 */
function handleQueryResult (queryResult) {
  if (isNil(queryResult?.refetch)) return queryResult

  return {
    ...queryResult,
    refetch: (options) => queryResult.refetch(options).catch((e) => e)
  }
}
