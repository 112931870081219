import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import { Card } from '../../index'
import { defaultIfEmpty, format, formatCurrency, formatStockPrice, getClassName, THEMES } from '../../../utils'

import './attribute.component.scss'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired
}

const defaultProps = {
  isLoading: false,
  data: {}
}

/**
 * Get stock value
 * @param price
 * @param currency
 */
function getStockValue (price = 0, currency) {
  const value = formatStockPrice(price, currency)
  return format(Math.abs(value), 2)
}

/**
 * Banner Title Component
 * @param props
 */
const Stock = ({ data }) => {
  const { last, currency, lastCloseChange, lastCloseChangePercent } = data

  return (
    <div className='security-attribute_stock'>
      <div className='security-attribute_stock-price'>
        {getStockValue(last, currency)} {formatCurrency(currency)}
      </div>
      <i className={getClassName('security-attribute_stock-arrow', [
        { condition: lastCloseChange > 0, trueClassName: 'security-attribute_stock-arrow--positive' },
        { condition: lastCloseChange < 0, trueClassName: 'security-attribute_stock-arrow--negative' }
      ])}
      />
      <div className='security-attribute_stock-change'>
        {getStockValue(lastCloseChange, currency)} ({format(lastCloseChangePercent, 2)}%)
      </div>
    </div>
  )
}

/**
 * Attribute Component
 */
function Attribute ({ dataId, label, value }) {
  return (
    <Grid data-id={dataId} item xs={12} className='card_section'>
      <h2 className='card_section-subtitle'>{label}</h2>
      <div className='card_section-detail'>{defaultIfEmpty(value)}</div>
    </Grid>
  )
}

/**
 * Security Attributes Card Component
 * @param props
 */
function SecurityAttribute (props) {
  const { dataId, theme, isLoading, data } = props
  const { volume, averageVolume } = data

  const attributes = [
    { label: 'Stock', value: <Stock data={data} />, dataId: `${dataId}Stock` },
    { label: 'Daily Volume', value: format(volume), dataId: `${dataId}DailyVolume` },
    { label: 'Monthly Average Volume',
      value: <>
        {format(averageVolume)}
        {averageVolume ? <span className='card_section-detail--neutral'>Delayed by 15min</span> : ''}
      </>,
      dataId: `${dataId}MonthlyAverageVolume`
    }
  ]

  return (
    <Card
      dataId={`${dataId}AttributesCard`}
      theme={theme}
      className='security-attribute q4-fade-in'
      isLoading={isLoading}
      title='Attributes'
    >
      <Grid container spacing={3}>
        {attributes.map((attribute) => {
          return <Attribute key={attribute.label} {...attribute} />
        })}
      </Grid>
    </Card>
  )
}

SecurityAttribute.propTypes = propTypes
SecurityAttribute.defaultProps = defaultProps

export default memo(SecurityAttribute)
