import {
  GET_CONTACT_FUNDS_REQUEST,
  GET_CONTACT_FUNDS_SUCCESS,
  GET_CONTACT_FUNDS_FAILURE,
  RESET_CONTACT,
  statusType
} from '../../../actions'

const initial = {
  data: [],
  totalPosition: 0,
  total: 0,
  status: statusType.IDLE
}

const fundReducer = (state = initial, action) => {
  const { payload, meta } = action

  switch (action.type) {
    case GET_CONTACT_FUNDS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_CONTACT_FUNDS_SUCCESS: {
      return {
        ...state,
        data: payload,
        totalPosition: meta.totalPosition,
        total: meta.total,
        status: statusType.SUCCESS
      }
    }

    case GET_CONTACT_FUNDS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case RESET_CONTACT:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default fundReducer
