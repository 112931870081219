import _ from 'lodash'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FETCHED, FETCHING, loadTopBuyersSellers, loadTopBuyersSellersQr } from '../../../../actions/widget/ownership'
import { getOwnershipType } from '../../../../utils/user.util'
import { format } from '../../../../utils/number.util'
import { Scrollbars } from 'react-custom-scrollbars'
import {
  renderDarkThumb,
  renderLightThumb,
  renderTrackVertical
} from '../../../../resources/theme/q4.custom-scrollbar'
import moment from 'moment-timezone'
import { Spinner } from '../../../../components'
import WidgetError from '../../../../components/widget/error/widgetError.component'
import { withRouter } from 'react-router-dom'
import './topBuyersSellers.container.css'

class TopBuyersSellers extends Component {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      startDate: moment().subtract(3, 'month'),
      endDate: moment()
    }
  }

  /**
   * Triggered when component mounted
   * Enable auto-reloading if enabled
   */
  componentDidMount = () => {
    const { isEdit, fetched } = this.props
    if (isEdit && fetched) {
      return
    }

    const refreshInterval = this.props.options.refreshInterval
    if (refreshInterval) {
      this.timer = setInterval(this._fetchData.bind(this), refreshInterval)
    }

    this._fetchData()
  }

  /**
   * ComponentDidUpdate
   * Re-fetch data when security changed
   * @param prevProps
   */
  componentDidUpdate = (prevProps) => {
    if (prevProps.tickerId !== this.props.tickerId) {
      this._fetchData()
    }
  }

  /**
   * clear fetch interval on unmount
   */
  componentWillUnmount = () => {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  /**
   * Fetch Top Buyers and Sellers data
   * @private
   */
  _fetchData = () => {
    const params = {
      'tickerId': this.props.tickerId,
      'startDate': this.state.startDate.toISOString(),
      'page': 1,
      'start': 0,
      'limit': 5
    }

    this.props.loadTopBuyersSellers(params)
    this.props.loadBuyersSellersQR(params)
  }

  /**
   * Assign classes based on layout
   */
  getClasses = (layout, theme) => {
    const base = 'top-buyers-sellers'
    const widgetClass = [
      (layout.w > 1) ? `${base}` : `${base} ${base}--narrow`,
      (layout.w > 1 && layout.h > 2) ? `${base}--large` : '',
      (layout.w === 1 && layout.h > 2) ? `${base} ${base}--tall` : ''
    ]

    return {
      base: `${widgetClass.join(' ')} ${base}--${theme}`,
      widget: widgetClass.join(' '),
      header: (layout.w > 1 && layout.h < 2) ? `${base}_header` : `${base}_header ${base}_header--break`,
      footer: `${base}_footer`,
      items: (layout.w > 1) ? `${base}_items` : `${base}_items ${base}_items--break`,
      item: (layout.w > 1) ?
        (layout.h > 1) ?
          (layout.h > 2) ? `${base}_item ${base}_item--detailed`
            : `${base}_item ${base}_item--tall`
          : `${base}_item`
        : `${base}_item`
    }
  }

  /**
   * Redirect to the institution page
   * @param holder
   * @returns {*}
   */
  redirect = (holder) => {
    const { history } = this.props
    if (holder && holder.factset_entity_id) {
      return history.push(`/institution/${holder.factset_entity_id}`)
    }
    if (holder && holder.entityId) {
      return history.push(`/institution/${holder.entityId}`)
    }
  }

  /**
   * Render items
   * @param type
   * @param holders
   * @param classes
   */
  renderItems = (type, holders, classes) => {
    const items = holders && holders.length ? holders.concat(_.fill(Array(5 - holders.length), {})) : _.fill(Array(5), {})
    const dropNegative = true

    return (items).map((holder, index) => {
      return (
        <article
          key={holder.factset_entity_id || holder._id || `top-buyers-sellers-item--${index}`}
          className={`${classes.item} q4-fade-in`}
          onClick={() => this.redirect(holder)}
        >
                    <span className='top-buyers-sellers_name'>
                        {holder.institution_name || holder.holder_name || holder.entityName || 'No Data Available'}
                    </span>
          {holder.change ? (
            <span className={`top-buyers-sellers_change top-buyers-sellers_change--${type}`}>
                            {format(holder.change, null, null, dropNegative)}
                        </span>
          ) : (
            <span className='top-buyers-sellers_change top-buyers-sellers_change--empty'>-</span>
          )}
          <span className='top-buyers-sellers_prev'>
                        {holder.previous ? `${format(holder.previous)} Prev` : ''}
                    </span>
        </article>
      )
    })
  }

  /**
   * Render Top Buyers and Sellers Widget Container
   * @returns {XML}
   */
  render () {
    const { buyers, sellers, profile, theme, layout, fetching, tickerId, history } = this.props
    const ownershipType = profile ? getOwnershipType(profile).label : '13F'
    const classes = this.getClasses(layout, theme)
    const renderThumb = theme === 'dark' ? renderLightThumb : renderDarkThumb

    if (!fetching && (_.isEmpty(buyers) && _.isEmpty(sellers))) {
      return (
        <WidgetError
          theme={theme}
          header={'Top Buyers & Sellers'}
          message={`No ${ownershipType} data available.`}
        />
      )
    }

    return (_.isEmpty(buyers) && _.isEmpty(sellers)) ?
      <Spinner/> : (
        <div className={classes.base}>
          <header className={classes.header}>
            <h2 className='top-buyers-sellers_title'
                onClick={() => history.push(`/security/${tickerId}`)}>Top
              Buyers &amp; Sellers</h2>
          </header>
          <section className={'top-buyers-sellers_content'}>
            <Scrollbars
              className='react-scrollbar'
              autoHide
              hideTracksWhenNotNeeded
              renderThumbVertical={renderThumb}
              renderTrackVertical={renderTrackVertical}>
              <div className={classes.items}>
                <div className='top-buyers-sellers_buyers'>
                  {this.renderItems('buyers', buyers, classes)}
                </div>
                <div className='top-buyers-sellers_sellers'>
                  {this.renderItems('sellers', sellers, classes)}
                </div>
              </div>
            </Scrollbars>
          </section>
          <footer className={classes.footer}>
            <span className='top-buyers-sellers_details'>{ownershipType} 3 Months</span>
          </footer>
        </div>
      )
  }
}

const mapStateToProps = (state) => {
  const fetching = (state.widget.ownership.sellers.status === FETCHING) && (state.widget.ownership.buyers.status === FETCHING)
  const fetched = (state.widget.ownership.sellers.status === FETCHED) && (state.widget.ownership.buyers.status === FETCHED)

  return {
    buyers: state.widget.ownership.buyers.holders || [],
    sellers: state.widget.ownership.sellers.holders || [],
    buyersQr: state.widget.ownership.buyersQr.qr || {},
    sellersQr: state.widget.ownership.sellersQr.qr || {},
    fetching,
    fetched,
    profile: state.shared.profile,
    theme: state.dashboard.dashboardState.theme,
    securityId: state.dashboard.dashboardState.securityId,
    tickerId: state.dashboard.dashboardState.tickerId
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadTopBuyersSellers: bindActionCreators(loadTopBuyersSellers, dispatch),
  loadBuyersSellersQR: bindActionCreators(loadTopBuyersSellersQr, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopBuyersSellers))
