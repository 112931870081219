import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

export const FETCH_NLG_COMMENTARY_REQUEST = 'FETCH_NLG_COMMENTARY_REQUEST'
export const FETCH_NLG_COMMENTARY_SUCCESS = 'FETCH_NLG_COMMENTARY_SUCCESS'
export const FETCH_NLG_COMMENTARY_ERROR = 'FETCH_NLG_COMMENTARY_ERROR'

const resources = {
  commentary: '/nlp'
}

const _fetchNlgCommentary = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_NLG_COMMENTARY_REQUEST,
      FETCH_NLG_COMMENTARY_SUCCESS,
      FETCH_NLG_COMMENTARY_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

export const fetchNlgCommentary = (id) => {
  return (dispatch, getState) => {
    const state = getState()

    if (!id) {
      id = state.dashboard.dashboardState.securityId
    }
    dispatch(_fetchNlgCommentary(`${resources.commentary}/${id}`))
  }
}
