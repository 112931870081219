import React, { memo } from 'react'
import PropTypes from 'prop-types'

// actions
import { modalType } from '../../../../actions'

// components
import { Banner } from '../../../../components'

const propTypes = {
  dataIdPrefix: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
}

const defaultProps = {
  title: ''
}

/**
 * Deal Banner Component
 * @param props
 */
function DealBanner (props) {
  const { dataIdPrefix, title, onEdit, onDelete, openModal } = props

  /**
   * Handle briefing book delete
   */
  const handleDelete = () => {
    openModal({
      type: modalType.CONFIRM_MODAL,
      props: {
        content: {
          title: 'Delete Deal?',
          message: 'Are you sure you want to delete this Deal?'
        },
        onConfirm: onDelete
      }
    })
  }

  const controls = [
    {
      dataId: `${dataIdPrefix}Edit`,
      type: 'button',
      icon: 'q4i-edit-4pt',
      onClick: onEdit
    },
    {
      dataId: `${dataIdPrefix}BannerUtility`,
      type: 'utility',
      items: [{
        icon: 'q4i-trashbin-2pt',
        dataId: `${dataIdPrefix}UtilityDelete`,
        label: 'Delete',
        onClick: handleDelete
      }]
    }]

  return (
    <Banner
      size='small'
      icon='q4i-deal-2pt'
      title={title}
      controls={controls}
    />
  )
}

DealBanner.propTypes = propTypes
DealBanner.defaultProps = defaultProps

export default memo(DealBanner)
