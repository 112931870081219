import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

import { fetchInstitutionCurrentPosition } from '../../ownership/institution'

export const GET_CONTACT_REQUEST = 'GET_CONTACT_REQUEST'
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS'
export const GET_CONTACT_FAILURE = 'GET_CONTACT_FAILURE'

export const REMOVE_CONTACT_REQUEST = 'REMOVE_CONTACT_REQUEST'
export const REMOVE_CONTACT_SUCCESS = 'REMOVE_CONTACT_SUCCESS'
export const REMOVE_CONTACT_FAILURE = 'REMOVE_CONTACT_FAILURE'

export const GET_CONTACT_INSTITUTION_POSITION_REQUEST = 'GET_CONTACT_INSTITUTION_POSITION_REQUEST'
export const GET_CONTACT_INSTITUTION_POSITION_SUCCESS = 'GET_CONTACT_INSTITUTION_POSITION_SUCCESS'
export const GET_CONTACT_INSTITUTION_POSITION_FAILURE = 'GET_CONTACT_INSTITUTION_POSITION_FAILURE'

export const GET_CONTACT_FUNDS_REQUEST = 'GET_CONTACT_FUNDS_REQUEST'
export const GET_CONTACT_FUNDS_SUCCESS = 'GET_CONTACT_FUNDS_SUCCESS'
export const GET_CONTACT_FUNDS_FAILURE = 'GET_CONTACT_FUNDS_FAILURE'

export const GET_CONTACT_WEBCAST_REQUEST = 'GET_CONTACT_WEBCAST_REQUEST'
export const GET_CONTACT_WEBCAST_SUCCESS = 'GET_CONTACT_WEBCAST_SUCCESS'
export const GET_CONTACT_WEBCAST_FAILURE = 'GET_CONTACT_WEBCAST_FAILURE'

export const GET_CONTACT_COVERAGE_REQUEST = 'GET_CONTACT_COVERAGE_REQUEST'
export const GET_CONTACT_COVERAGE_SUCCESS = 'GET_CONTACT_COVERAGE_SUCCESS'
export const GET_CONTACT_COVERAGE_FAILURE = 'GET_CONTACT_COVERAGE_FAILURE'

export const GET_CONTACT_ESTIMATE_REQUEST = 'GET_CONTACT_ESTIMATE_REQUEST'
export const GET_CONTACT_ESTIMATE_SUCCESS = 'GET_CONTACT_ESTIMATE_SUCCESS'
export const GET_CONTACT_ESTIMATE_FAILURE = 'GET_CONTACT_ESTIMATE_FAILURE'

export const GET_CONTACT_RESEARCH_REQUEST = 'GET_CONTACT_RESEARCH_REQUEST'
export const GET_CONTACT_RESEARCH_SUCCESS = 'GET_CONTACT_RESEARCH_SUCCESS'
export const GET_CONTACT_RESEARCH_FAILURE = 'GET_CONTACT_RESEARCH_FAILURE'

export const GET_CONTACT_STRATEGY_REQUEST = 'GET_CONTACT_STRATEGY_REQUEST'
export const GET_CONTACT_STRATEGY_SUCCESS = 'GET_CONTACT_STRATEGY_SUCCESS'
export const GET_CONTACT_STRATEGY_FAILURE = 'GET_CONTACT_STRATEGY_FAILURE'

export const RESET_CONTACT = 'RESET_CONTACT'

/**
 * Get Contact
 * @param id - contact id
 * @param options.types - action types
 * @private
 */
const _fetchContact = (id, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_CONTACT_REQUEST,
      GET_CONTACT_SUCCESS,
      GET_CONTACT_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/contact/${id}`
  }
})

/**
 * Remove Custom Contact
 * @param id
 * @param options
 * @returns {{}}
 * @private
 */
const _removeCustomContact = (id, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      REMOVE_CONTACT_REQUEST,
      REMOVE_CONTACT_SUCCESS,
      REMOVE_CONTACT_FAILURE
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: `/contact/${id}`
  }
})

/**
 * Get Contact Managed Funds
 * @param params.id - contact id
 * @param params.securityId
 */
const _getManagedFunds = (params = {}, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_CONTACT_FUNDS_REQUEST,
      GET_CONTACT_FUNDS_SUCCESS,
      GET_CONTACT_FUNDS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/contact/fund',
    payload: params
  }
})

/**
 * Get Contact Webcast
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _getContactWebcast = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_CONTACT_WEBCAST_REQUEST,
      GET_CONTACT_WEBCAST_SUCCESS,
      GET_CONTACT_WEBCAST_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/contact/webcast',
    payload: params
  }
})

/**
 * Get Contact Coverage
 * @param params.securityId
 * @param params.analystId
 * @param params.item
 * @param options.types - action types
 * @private
 */
const _getContactCoverage = (params = {}, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_CONTACT_COVERAGE_REQUEST,
      GET_CONTACT_COVERAGE_SUCCESS,
      GET_CONTACT_COVERAGE_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/contact/coverage/all',
    payload: params
  }
})

/**
 * Get Contact Estimate
 * @param params.securityId
 * @param params.analystId
 * @param params.item
 * @param options.types - action types
 * @private
 */
const _getContactEstimate = (params = {}, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_CONTACT_ESTIMATE_REQUEST,
      GET_CONTACT_ESTIMATE_SUCCESS,
      GET_CONTACT_ESTIMATE_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/contact/coverage/company',
    payload: params
  }
})

/**
 * Get Contact Research
 * @param params.securityId
 * @param params.contactId
 * @param params.item
 * @param options.types - action types
 * @private
 */
const _getContactResearch = (params = {}, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_CONTACT_RESEARCH_REQUEST,
      GET_CONTACT_RESEARCH_SUCCESS,
      GET_CONTACT_RESEARCH_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/research/company',
    payload: params
  }
})

/**
 * Get Contact Strategy Coverage
 * @param id
 * @param options
 * @return {{}}
 * @private
 */
const _getContactStrategy = (id, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_CONTACT_STRATEGY_REQUEST,
      GET_CONTACT_STRATEGY_SUCCESS,
      GET_CONTACT_STRATEGY_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/contact/${id}/strategy`
  }
})

/**
 * Dispatch Get Contact
 * @param id
 * @param options
 * @param options.types - action types
 */
export const fetchContact = (id, options) => (dispatch) => {
  return dispatch(_fetchContact(id, options))
}

/**
 * Dispatch REMOVE_CUSTOM_CONTACT_REQUEST
 * @param id
 * @param options
 */
export const removeCustomContact = (id, options) => (dispatch) => {
  return dispatch(_removeCustomContact(id, options))
}

/**
 * Dispatch GET_CONTACT_INSTITUTION_POSITION_REQUEST
 * @param params
 */
export const getCurrentPosition = (params) => (dispatch) => {
  return dispatch(fetchInstitutionCurrentPosition(params, {
    types: [
      GET_CONTACT_INSTITUTION_POSITION_REQUEST,
      GET_CONTACT_INSTITUTION_POSITION_SUCCESS,
      GET_CONTACT_INSTITUTION_POSITION_FAILURE
    ]
  }))
}

/**
 * Dispatch GET_CONTACT_FUNDS_REQUEST
 * @param params
 * @param options
 * @returns {function(*): *}
 */
export const getManagedFunds = (params, options) => (dispatch) => {
  return dispatch(_getManagedFunds(params, options))
}

/**
 * Dispatch GET_CONTACT_WEBCAST_REQUEST
 * @param params
 * @param options
 * @returns {function(*): *}
 */
export const getContactWebcast = (params, options) => (dispatch) => {
  return dispatch(_getContactWebcast(params, options))
}

/**
 * Dispatch GET_CONTACT_COVERAGE_REQUEST
 * @param params
 * @param options
 */
export const getContactCoverage = (params, options) => (dispatch) => {
  return dispatch(_getContactCoverage(params, options))
}

/**
 * Dispatch GET_CONTACT_ESTIMATE_REQUEST
 * @param params
 * @param options
 */
export const getContactEstimate = (params, options) => (dispatch) => {
  return dispatch(_getContactEstimate(params, options))
}

/**
 * Dispatch GET_CONTACT_RESEARCH_REQUEST
 * @param params
 * @param options
 */
export const getContactResearch = (params, options) => (dispatch) => {
  return dispatch(_getContactResearch(params, options))
}

/**
 * Dispatch GET_CONTACT_STRATEGY_REQUEST
 * @param id
 * @return {function(*): *}
 */
export const getContactStrategy = (id) => (dispatch) => {
  return dispatch(_getContactStrategy(id))
}

/**
 * Dispatch RESET_CONTACT
 */
export const clearContact = () => (dispatch) => {
  return dispatch({ type: RESET_CONTACT })
}
