import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { getClassName } from '../../utils'

import './sectionHeader.component.css'

/**
 * Section Header Component
 * @deprecated
 */
// TODO: clean app, should not be in use any longer
class SectionHeader extends PureComponent {
  /**
   * Renders Section Header
   * @return {XML}
   */
  render () {
    const { title, isBordered, size, theme, annotationText, annotationIcon, children } = this.props

    const baseClassName = getClassName('section-header', [
      { condition: isBordered, trueClassName: 'section-header--bordered' },
      { condition: theme, trueClassName: `section-header--${theme}` },
      { condition: size, trueClassName: `section-header--${size}` }
    ])

    return (
      <div className={baseClassName}>
        <div className='section-header_details'>
          <h2 className='section-header_title'>{title}</h2>
          {(annotationText || annotationIcon) && (
            <div className='section-header_annotation'>
              {annotationIcon ? <i className={annotationIcon} /> : null}
              {annotationText}
            </div>
          )}
        </div>
        <div className='section-header_component'>
          {children}
        </div>
      </div>
    )
  };
}

SectionHeader.propTypes = {
  /**
   * The title to display for the SectionHeader
   */
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,

  /**
   * Used to determine whether or not a border is rendered below the component
   */
  isBordered: PropTypes.bool,

  /**
   * Used to determine thickness of the SectionHeader
   */
  size: PropTypes.oneOf(['default', 'thin', 'thick']),

  /**
   * Used to paint the component in a specific theme
   */
  theme: PropTypes.string,

  /**
   * The text to display as an annotation for the SectionHeader
   */
  annotationText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

  /**
   * The icon to display before the annotationText
   */
  annotationIcon: PropTypes.string
}

SectionHeader.defaultProps = {
  isBordered: true
}

export default SectionHeader
