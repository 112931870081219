import React from 'react'
import { Select, Toolbar, ToolbarRow } from '../../../../../../components'
import { getActivityFilters, THEMES } from '../../../../../../utils'

/**
 * Itinerary Toolbar Component
 */
const ItineraryToolbar = ({ counts, selectedType, handleItineraryItemCreate, handleActivitiesTypeChange }) => {
  /**
   * Handle Dropdown change for categories
   * @param option
   */
  const onDropdownChange = (option) => {
    handleActivitiesTypeChange && handleActivitiesTypeChange({ category: option && option.value })
  }

  const options = getActivityFilters(counts, false)

  return (
    <Toolbar theme={THEMES.Q4_BLUE}>
      <ToolbarRow justified>
        <Select
          theme={THEMES.INK}
          size='thin'
          options={options}
          value={options.find((option) => option && option.value === selectedType)}
          onChange={onDropdownChange}
          searchable={false}
          clearable={false}
        />
        <button className='button button--square button--citrus' onClick={handleItineraryItemCreate}>
          <i className='q4i-add-4pt' />
        </button>
      </ToolbarRow>
    </Toolbar>
  )
}

export default ItineraryToolbar
