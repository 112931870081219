import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  FETCHED,
  FETCHING,
  fetchNlgCommentary,
  fetchPeers,
  fetchRelativePerformance,
  IDLE,
  setCurrentPeer
} from '../../../../actions/widget/iris'

import { Scrollbars } from 'react-custom-scrollbars'
import {
  renderDarkThumb,
  renderLightThumb,
  renderTrackVertical
} from '../../../../resources/theme/q4.custom-scrollbar'
import Commentary from '../../../../components/widget/iris/commentary/commentary.component'
import PerformanceChart from '../../../../components/widget/iris/relativePerformance/relativePerformance.component'
import WidgetError from '../../../../components/widget/error/widgetError.component'
import { Spinner } from '../../../../components'
import { Dropdown } from '../../../../components/shared'
import { getFromXigniteToStandard } from '../../../../utils/stock/stock.util'
import { setClassNames } from '../../../../utils/widget.util'
import { getTickers } from '../../../../utils'
import { withRouter } from 'react-router-dom'
import './nlg.container.css'

class Iris extends Component {

  constructor (props) {
    super(props)

    this.state = {
      performanceTab: 'price'
    }
    this.updateTab = this.updateTab.bind(this)
  }

  /**
   * Fetch Commentary and relative performance data
   * @param securityId
   */
  fetchData (securityId) {
    // 1) if security id passed - peer was changed from the dropdown
    // 2) if current peer is available - we use it on auto-refresh
    // 3) otherwise we use security passed to iframe as default
    const security = securityId || this.getCurrentPeerSecurityId()

    this.props.fetchNlgCommentary(security)

    if (this.props.options.relativePerformance) {
      this.props.fetchRelativePerformance(security)
    }
  }

  getCurrentPeerSecurityId () {
    return (this.props.currentPeer && this.props.currentPeer._security) || this.props.securityId
  }

  /**
   * clear fetch interval on unmount
   */
  componentWillUnmount () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  /**
   * Trigger fetch data on mount component
   */
  componentDidMount () {
    const { isEdit, status, profile, securityId } = this.props

    if (isEdit && status === FETCHED) {
      return
    }

    const refreshInterval = this.props.options.refreshInterval
    if (refreshInterval) {
      this.timer = setInterval(this.fetchData.bind(this), refreshInterval)
    }

    const that = this
    const tickers = getTickers(profile)

    const securities = tickers
      .sort((a) => a.primary ? -1 : 1)
      .map((ticker) => ticker._security)
      .slice(0, 5)

    const peerParams = {
      security: securities,
      select: ['symbol', 'exchange', 'q4_ticker_id'],
      limit: 20
    }

    this.props.fetchPeers(peerParams).then(() => {
      // my own company will always be at the top of the peer list
      const myCompany = that.props.peers && that.props.peers.length && that.props.peers[0]
      let selectedPeer

      if (that.props.currentPeer) {
        const id = that.props.currentPeer._security && that.props.currentPeer._security
        selectedPeer = that.props.peers.find((peer) => peer._security === id)
      }

      if (!selectedPeer) {
        that.props.setCurrentPeer(myCompany)
        that.fetchData(securityId)
      } else {
        that.fetchData(selectedPeer._security)
      }
    })
  }

  /**
   * ComponentDidUpdate
   * Re-fetch data when security changed
   * @param prevProps
   */
  componentDidUpdate = (prevProps) => {
    const { profile } = this.props

    if (prevProps.securityId !== this.props.securityId) {
      const currentPeer = getTickers(profile).find((ticker) => ticker._security === this.props.securityId)
      this.props.setCurrentPeer(currentPeer)
      this.fetchData(this.props.securityId)
    }
  }

  /**
   * Toggle which performance chart is visible
   */
  updateTab = (event) => {
    const type = event.currentTarget.textContent.indexOf('Price') > -1 ? 'price' : 'volume'

    this.setState({
      performanceTab: type
    })
  }

  /**
   * Get my company
   * @return {*}
   */
  getMyCompany = () => {
    const { profile, currentSecurityId } = this.props
    const tickers = getTickers(profile)
    return _.find((tickers), (ticker) => ticker._security === currentSecurityId) || _.find((tickers), (ticker) => ticker.primary)
  }

  /**
   * Render the tabs
   */
  renderTabs = () => {
    const priceTab = this.state.performanceTab === 'price' ? 'active' : 'inactive'
    const volumeTab = this.state.performanceTab === 'volume' ? 'active' : 'inactive'
    return (
      <ul className='nlg_tabs'>
        <li onClick={this.updateTab} className={`nlg_tab-item nlg_tab-item--${priceTab}`}>Price (%)</li>
        <li onClick={this.updateTab} className={`nlg_tab-item nlg_tab-item--${volumeTab}`}>Volume (M)</li>
      </ul>
    )
  }

  /**
   * Generate JSX for peer dropdown option
   * @param peer
   * @returns {*}
   */
  generatePeerDropdownItem = (peer) => {
    if (!peer || !peer._security) {
      return null
    }

    return (
      <label className={`nlg_peer-label nlg_peer-label--${this.props.theme}`}>
                <span className='nlg_peer-label-symbol'>
                    {peer.symbol && peer.symbol.slice(0, 5)}
                </span>
        <span className='nlg_peer-label-exchange'>
                    {getFromXigniteToStandard(peer.exchange)}
                </span>
      </label>
    )
  }

  /**
   * Map peers to add generated JSX for dropdown option
   * @param peers
   * @returns {Array}
   */
  getDropdownOptions = (peers) => {

    const company = this.getMyCompany()

    if (company) {
      const myCompanyPeer = (peers || []).find((peerItem) => peerItem._security === company._security)

      // put my company in the first position
      if (myCompanyPeer) {
        peers = peers.filter((peerItem) => peerItem._security !== myCompanyPeer._security)
        peers.unshift(myCompanyPeer)
      }
    }

    return (peers || []).map((peer) => {
      return {
        ...peer,
        peerDropdownItem: this.generatePeerDropdownItem(peer)
      }
    })
  }

  /**
   * Handle Dropown Change
   * @param selectedPeer
   */
  handleDropdownChange = (selectedPeer) => {
    if (!selectedPeer) {
      return
    }

    // we cannot store react elements in the reducer, so we need to delete it from the selected peer object before saving
    delete selectedPeer.peerDropdownItem
    this.props.setCurrentPeer(
      selectedPeer
    )

    this.fetchData(selectedPeer._security)
  }

  /**
   * Redirect to desktop security section
   * @returns {*}
   */
  redirect = () => {
    const { history, currentPeer, tickerId } = this.props
    const _tickerId = (currentPeer && currentPeer.q4_ticker_id) || tickerId
    return _tickerId && history.push(`/security/${_tickerId}`)
  }

  render () {
    const { options, status, theme, layout, returnAnalysis, commentary, peers, currentPeer, commentaryError } = this.props
    const is_loading = status === FETCHING || status === IDLE
    const renderThumb = theme === 'dark' ? renderLightThumb : renderDarkThumb
    const classes = setClassNames('nlg', layout, theme)
    const dropdownHeight = layout && layout.h === 1 ? 95 : 155
    const dropdownValue = currentPeer ? currentPeer : peers && peers.length && peers[0]
    const hasPerformance = returnAnalysis.stockspecificreturn && returnAnalysis.stockspecificvolume
    let data = returnAnalysis

    let relativePerformance = !_.isEmpty(data) ? [
      (data.stockspecificreturn + data.expectedreturn) || 0,
      data.returnduetopeers || 0,
      data.returnduetosector || 0,
      data.returnduetomarket || 0
    ] : []

    let volume = !_.isEmpty(data) ? [
      data.stockspecificvolume || 0,
      data.volumeduetopeers || 0,
      data.volumeduetosector || 0,
      data.volumeduetomarket || 0
    ] : []

    return (
      <div className={`${classes.base} q4-fade-in`} ref='commentaryView'>
        <header className='nlg_header'>
          <h2 className='nlg_title' onClick={() => this.redirect()}>Trading
            Commentary</h2>
          {this.props.peers.length ?
            <div className='nlg_peer-dropdown-container'>
              <Dropdown
                value={{
                  ...dropdownValue,
                  peerDropdownItem: this.generatePeerDropdownItem(dropdownValue)
                }}
                options={this.getDropdownOptions(peers)}
                optionKey='peerDropdownItem'
                className='nlg_peer-dropdown'
                onSelect={this.handleDropdownChange}
                theme={theme === 'dark' ? 'slate' : 'pale-grey'}
                width={128}
                height={30}
                itemHeight={30}
                dropdownHeight={dropdownHeight}/>
            </div> : null
          }
        </header>
        {(is_loading && _.isEmpty(commentary) && !commentaryError) ?
          <Spinner/> : commentaryError ?
            <WidgetError
              theme={theme}
              message={'No trading commentary available.'}/> :
            <div className='nlg_container'>
              <Scrollbars
                className='react-scrollbar'
                autoHide
                hideTracksWhenNotNeeded
                renderThumbVertical={renderThumb}
                renderTrackVertical={renderTrackVertical}>
                <Commentary
                  commentary={commentary || ''}
                  onCommentaryClick={() => this.redirect()}/>
                {options.relativePerformance && hasPerformance ?
                  <div className={`nlg_performance nlg_performance--${options.tabs ? 'tabs' : 'default'}`}>
                    {options.tabs && !_.isEmpty(data) ? this.renderTabs() : ''}
                    <div className='nlg_charts q4-fade-in'>
                      <PerformanceChart
                        theme={theme}
                        symbol={data.symbol}
                        active={this.state.performanceTab === 'price' ? 'active' : 'inactive'}
                        data={relativePerformance}
                        change={data.returnondate || 0}
                        title='price'
                        categories={['STOCK', 'PEER', 'INDUSTRY', 'MARKET']}
                        onRelativePerformanceClick={() => this.redirect()}/>

                      <PerformanceChart
                        theme={theme}
                        symbol={data.symbol}
                        active={this.state.performanceTab === 'volume' ? 'active' : 'inactive'}
                        data={volume}
                        change={(data.volumeondate - data.expectedvolume) || 0}
                        title='volume'
                        categories={['STOCK', 'PEER', 'INDUSTRY', 'MARKET']}
                        formatNumber={true}
                        onRelativePerformanceClick={() => this.redirect()}/>
                    </div>
                  </div> : ''
                }
              </Scrollbars>
              <footer className={classes.footer}/>
            </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const nlg = state.widget.nlg
  return {
    currentSecurityId: state.dashboard.dashboardState.securityId,
    commentary: (nlg && nlg.commentaryState && nlg.commentaryState.commentary) || '',
    status: nlg.commentaryState.status,
    commentaryError: nlg.commentaryState.error,
    returnAnalysis: nlg.analysis.returnAnalysis,
    peers: nlg.peers.items || [],
    currentPeer: nlg.peers.currentPeer,
    theme: state.dashboard.dashboardState.theme,
    profile: state.shared.profile
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchNlgCommentary: bindActionCreators(fetchNlgCommentary, dispatch),
  fetchRelativePerformance: bindActionCreators(fetchRelativePerformance, dispatch),
  fetchPeers: bindActionCreators(fetchPeers, dispatch),
  setCurrentPeer: bindActionCreators(setCurrentPeer, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Iris))
