import {
  FETCH_AI_TARGETING_REQUEST,
  FETCH_AI_TARGETING_SUCCESS,
  FETCH_AI_TARGETING_ERROR,
  RESET_AI_TARGETING_STATUS,
  SET_REQUEST_PARAMETERS_IN_AI_TARGETING,
  SET_COUNTRY_IN_AI_TARGETING,
  SET_REGION_IN_AI_TARGETING,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../../actions/widget/aiTargeting/aiTargeting.actions'

const initial = {
  status: IDLE,
  type: 'institution',
  region: null,
  country: null,
  data: {
    US: [],
    CA: [],
    EU: []
  }
}

const targets = (state = initial, action) => {
  switch (action.type) {
    case FETCH_AI_TARGETING_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_AI_TARGETING_SUCCESS:

      const data = {
        US: action.payload.US,
        CA: action.payload.CA,
        EU: action.payload.EU
      }

      return {
        ...state,
        data,
        status: FETCHED
      }
    case FETCH_AI_TARGETING_ERROR:
      return {
        ...state,
        error: action.error,
        status: FAILED
      }
    case RESET_AI_TARGETING_STATUS:
      return {
        ...state,
        data: {
          US: [],
          CA: [],
          EU: []
        },
        status: IDLE
      }
    case SET_REQUEST_PARAMETERS_IN_AI_TARGETING:
      return {
        ...state,
        ...action.payload
      }
    case SET_COUNTRY_IN_AI_TARGETING:
      return {
        ...state,
        country: action.payload
      }
    case SET_REGION_IN_AI_TARGETING:
      return {
        ...state,
        region: action.payload
      }
    default:
      return state
  }
}

export default targets
