import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { setColumnDefinition, getDefaultColumnDef, preventDefaultRowClick, getFromXigniteToStandard, isReportingWindow } from '../../../../../../utils'
import { isEmpty, sortBy } from 'lodash'
import { useAgGrid } from '../../../../../agGrid/hook/agGrid.hook'

// components
import { AgGrid, ErrorMessage, LoadingState, NoContentMessage } from '../../../../../index'
import CustomCell from './cell/cell.component'

import './table.component.scss'

const propTypes = {
  pageSizeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number,
  listOptions: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  onQueryChange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired
  }),
  security: PropTypes.object.isRequired,
  peers: PropTypes.array.isRequired,
  metric: PropTypes.string.isRequired
}

const defaultProps = {
  loading: false,
  data: [],
  total: 0,
  listOptions: {}
}

/**
 * Return the key for each column with exchange and metric
 * @param securityId
 * @param exchangeCode
 * @param metric
 * @returns {string}
 */
function getHoldingFieldKey (securityId, exchangeCode, metric) {
  return `${securityId}_${exchangeCode}_${metric}`
}

/**
 * return the column header name for each security and exchange
 * @param symbol
 * @param exchangeCode
 * @returns {string}
 */
function getColumnHeaderName (symbol, exchangeCode) {
  return `${symbol} ${getFromXigniteToStandard(exchangeCode)}`
}

/**
 * Holder Peer Analysis Table Component
 * @param props
 */
function HolderPeerAnalysisTable (props) {
  const { data, peers, metric, total, error, listOptions, loading, pageSizeId, onQueryChange, history, primaryTicker } = props

  const getColumnDefinitions = (columnIndex, type) => {
    const defaultDefinitions = getDefaultColumnDef({ columnIndex, type })
    if (metric === 'change' && !loading) {
      defaultDefinitions.cellClassRules['ag-cell-value--increase'] = ({ value }) => isReportingWindow() && value === 0 ? false : value > 0
      defaultDefinitions.cellClassRules['ag-cell-value--decrease'] = ({ value }) => isReportingWindow() && value === 0 ? false : value < 0
    }
    return defaultDefinitions
  }

  /**
   * get columns according to the peer list
   */
  const getColumns = () => {
    const { q4_entity_id: securityId, exchange: securityExchange, symbol: securitySymbol } = primaryTicker

    const sortFilteredPeers = sortBy(peers, (p) => getColumnHeaderName(p.symbol, p.exchange)).filter((p) => {
      if (p.q4_entity_id === primaryTicker.q4_entity_id) {
        return p.exchange !== primaryTicker.exchange
      }
      return true
    })

    const holdingWidth = 150

    return setColumnDefinition({
      columns: [
        {
          ...getColumnDefinitions(0, 'text'),
          field: 'entityName',
          headerName: 'Name',
          minWidth: 400,
          pinned: 'left',
          lockPinned: true
        },
        {
          ...getColumnDefinitions(1, 'text'),
          field: getHoldingFieldKey(securityId, securityExchange, metric),
          colId: getHoldingFieldKey(securityId, securityExchange, metric),
          headerName: getColumnHeaderName(securitySymbol, securityExchange),
          minWidth: holdingWidth,
          maxWidth: holdingWidth,
          pinned: 'left',
          lockPinned: true,
          cellStyle: {
            'text-align': 'right'
          }
        },
        ...sortFilteredPeers.map((peer, idx) => {
          const { q4_entity_id: peerSecurityId, exchange: peerSecurityExchange, symbol: peerSymbol } = peer

          return {
            ...getColumnDefinitions(idx + 2, 'number'),
            colId: getHoldingFieldKey(peerSecurityId, peerSecurityExchange, metric),
            field: getHoldingFieldKey(peerSecurityId, peerSecurityExchange, metric),
            headerName: getColumnHeaderName(peerSymbol, peerSecurityExchange),
            minWidth: holdingWidth,
            maxWidth: holdingWidth
          }
        })
      ]
    })
  }

  const columns = getColumns()

  const rowData = useMemo(() => data.map(entity => ({
    ...entity,
    ...entity.holdings?.reduce((holdingData, holding) =>
      isEmpty(holding)
        ? holdingData
        : { ...holdingData, [getHoldingFieldKey(holding.securityId, holding.exchange, metric)]: holding.value }
    , {})
  })), [data, metric])

  const {
    handleGridReady,
    handleGridResize,
    handlePageChange,
    handlePageSizeChange,
    forceGridResize
  } = useAgGrid({ listOptions, onQueryChange })

  /**
   * Handle row click event
   * @param {SecurityTableRowClickEvent} event
   * @returns {void}
   */
  const handleRowClick = ({ entityId, entityType }) => {
    entityType && entityId && history && history.push(`/${entityType}/${entityId}`)
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell {...props} metric={metric} />
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      <LoadingState
        loading={loading}
        count={data?.length}
        error={error}
        emptyState={<NoContentMessage key='peer-analysis_error-state' />}
        errorState={(
          <ErrorMessage
            key='peer-analysis_error-state'
          />
        )}
        loadedState={(
          <AgGrid
            key='peer-analysis_loaded-state'
            domLayout='autoHeight'
            sizeToFit
            // suppress configs
            suppressMovableColumns
            suppressContextMenu
            // columns and data
            defaultColDef={{
              suppressMenu: true,
              sortable: false,
              cellRenderer: 'CustomCellRender'
            }}
            applyColumnDefOrder
            columnDefs={columns}
            rowData={rowData}
            // pagination
            pagination
            paginationProps={{
              pageSizeId,
              forcePage: listOptions.page,
              initialPageSize: listOptions.limit,
              showPageSizeSelection: true,
              total,
              onPageChange: handlePageChange,
              onPageSizeChange: handlePageSizeChange
            }}
            // custom components
            frameworkComponents={{
              CustomCellRender: renderCustomCell
            }}
            // event listeners
            onGridColumnsChanged={forceGridResize}
            onGridReady={handleGridReady}
            onGridSizeChanged={handleGridResize}
            onRowClicked={preventDefaultRowClick(
              handleRowClick, ['']
            )}
            isPinned={rowData.length}
          />
        )}
      />
    </div>
  )
}

HolderPeerAnalysisTable.propTypes = propTypes
HolderPeerAnalysisTable.defaultProps = defaultProps

export default memo(HolderPeerAnalysisTable)
