import {filter, find} from 'lodash';
import {connect} from 'react-redux';
import React, {Component} from 'react';
import {withRouter, Redirect} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {
    loadDashboard,
    setActiveDashboard,
    editActiveDashboard,
    removeDashboard,
    changeTheme
} from '../../actions/dashboard';
import {FAILED} from '../../actions/shared/profile.actions';
import {FETCHED, ERROR} from '../../actions/dashboard';
import {setAppFullscreen} from '../../actions/ui';
import {getActiveTicker, getEaIntegrationEnabledFlag} from '../../utils';
import DashboardView from '../../components/dashboard/view/dashboardView.component';
import {Footer} from '../../components';
import DashboardHeader from '../../components/dashboard/header/dashboardHeader.component';
import DashboardManager from '../../components/dashboard/manager/dashboardManager.component';
import {filterDashboardWidgets} from './dashboardHelpers'
class Dashboard extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            isManagerOpen: false
        };
    }

    /**
     * ComponentWillMount
     */
    UNSAFE_componentWillMount() {
        this.fetchData();
    }

    /**
     * ComponentDidUpdate
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {dashboards, activeDashboardId, prevActiveDashboardId, setActiveDashboard} = this.props;

        if (!dashboards || !dashboards.length) {
            return;
        }

        if (prevProps.securityId !== this.props.securityId) {
            this.fetchData();
        }

        if (activeDashboardId === null) {
            setActiveDashboard(dashboards[0]._id);
        }
        else if (!find(dashboards, (each) => each._id === activeDashboardId) &&
            find(dashboards, (each) => each._id === prevActiveDashboardId)) {
            setActiveDashboard(prevActiveDashboardId);
        }
        else if (!find(dashboards, (dashboard) => dashboard._id === activeDashboardId)) {
            setActiveDashboard(dashboards[0]._id);
        }
    }

    /**
     * Load Dashboard Data
     */
    fetchData = () => {
        const {tickerId, securityId, entityId, loadDashboard} = this.props;

        loadDashboard && loadDashboard({
            tickerId,
            securityId,
            entityId
        });
    };

    /**
     * Open Dashboard Manager
     */
    openDashboardManager = () => {
        this.setState({
            isManagerOpen: true
        });
    };

    /**
     * Close Dashboard Manager
     */
    closeDashboardManager = () => {
        this.setState({
            isManagerOpen: false
        });
    };

    /**
     * Add New Dashboard
     */
    addNewDashboard = () => {
        const {emptyDashboard, editActiveDashboard} = this.props;
        emptyDashboard && editActiveDashboard(emptyDashboard._id);
    };

    /**
     * Change Theme
     */
    changeTheme = () => {
        const {theme, changeTheme} = this.props;
        changeTheme((theme === 'dark') ? 'light' : 'dark');
    };

    /**
     * Set Fullscreen mode
     * @param value
     */
    setAppFullscreen = (value) => {
        const {fullscreen, setAppFullscreen} = this.props;

        if (fullscreen !== value) {
            setAppFullscreen(value);
        }
    };

    /**
     * Render Dashboards
     */
    renderDashboardView = () => {
        const {dashboards, subscription, theme, activeDashboardId, editActiveDashboard, tickerId, securityId, profile} = this.props;
        let activeDashboard = (dashboards || []).find((dashboard) => dashboard._id === activeDashboardId);

        if (!activeDashboard) {
            return null;
        }

        if (getEaIntegrationEnabledFlag()) {
            const { 
              filteredWidgets, 
              filteredDashboardLayoutTemplate
            } = filterDashboardWidgets(activeDashboard.widget, activeDashboard.layout.template);

            activeDashboard.widget = filteredWidgets;
            activeDashboard.layout.template = filteredDashboardLayoutTemplate;
        }

        return (
            <DashboardView
                dashboard={activeDashboard}
                subscription={subscription}
                theme={theme}
                editActiveDashboard={() => {
                    editActiveDashboard(activeDashboard._id);
                }}
                key={activeDashboard._id}
                tickerId={tickerId}
                securityId={securityId}
                profile={profile}
            />
        );
    };

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const {
            dashboards, activeDashboardId, subscription, setActiveDashboard, status,
            removeDashboard, editActiveDashboard, theme, fullscreen, profileFailed
        } = this.props;
        const {isManagerOpen} = this.state;

        if (profileFailed || status === ERROR) {
            return (
                <div className='q4-fade-in'>
                    <Redirect to='error/404'/>
                </div>
            );
        }

        if (status === FETCHED && !dashboards.length) {
            return (
                <div className='q4-fade-in'>
                    <Redirect to='error/404'/>
                </div>
            );
        }

        if (!subscription || !activeDashboardId) {
            return null;
        }

        const baseClassName = [
            'dashboard',
            theme ? `dashboard--${theme}` : '',
            isManagerOpen ? 'dashboard--locked' : '',
            'q4-fade-in'
        ].join(' ');

        return (
            <div className={baseClassName}>
                <DashboardHeader
                    theme={theme}
                    activeDashboardId={activeDashboardId}
                    dashboards={dashboards}
                    fullscreen={fullscreen}
                    onChangeTheme={this.changeTheme}
                    onSetFullscreen={this.setAppFullscreen}
                    setActiveDashboard={setActiveDashboard}
                    openDashboardManager={this.openDashboardManager}
                />
                {this.renderDashboardView()}
                {!fullscreen && (
                  <Footer />
                )}
                <DashboardManager
                    hidden={!isManagerOpen}
                    onClose={this.closeDashboardManager}
                    setActiveDashboard={setActiveDashboard}
                    editActiveDashboard={editActiveDashboard}
                    addNewDashboard={this.addNewDashboard}
                    removeDashboard={removeDashboard}
                    dashboards={dashboards}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const dashboards = filter(state.dashboard.list.data || [], (each) => each.type === 2);
    const defaultDashboards = filter(state.dashboard.list.data || [], (each) => each.type !== 2);
    const emptyDashboard = find(state.dashboard.list.data || [], (each) => each.type === 1);
    const ticker = getActiveTicker(state.profile && state.profile.data);
    const profile = state.shared.profile;

    return {
        theme: state.dashboard.dashboardState.theme,
        fullscreen: state.ui.fullscreen.enabled,
        activeDashboardId: state.dashboard.dashboardState.activeDashboard,
        prevActiveDashboardId: state.dashboard.dashboardState.prevActiveDashboard,
        dashboards: (dashboards.length > 0) ? dashboards : filter(defaultDashboards, (each) => each.type === 0),
        emptyDashboard,
        profile,
        subscription: profile.services,
        profileFailed: profile.status === FAILED,
        profileRegion: profile.region,
        status: state.dashboard.list.status,
        tickerId: ticker && ticker.q4_ticker_id,
        securityId: ticker && ticker._security,
        entityId: ticker && ticker.q4_entity_id
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadDashboard: bindActionCreators(loadDashboard, dispatch),
    changeTheme: bindActionCreators(changeTheme, dispatch),
    setAppFullscreen: bindActionCreators(setAppFullscreen, dispatch),
    setActiveDashboard: bindActionCreators(setActiveDashboard, dispatch),
    editActiveDashboard: bindActionCreators(editActiveDashboard, dispatch),
    removeDashboard: bindActionCreators(removeDashboard, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
