import {get, isPlainObject} from 'lodash';
import {ENTITY_CONTACT, ENTITY_FUND, ENTITY_INSTITUTION} from '../../../../utils/entity';

class EntityLink {

    _id;
    item;
    entity_type;
    institution_id = null;

    /**
     * Creates an entity class with consistent properties, independently from the source,
     * and helper methods to retrieve entity information.
     *
     * Data passed to the constructor could be four different objects, depending on the source.
     * Either the link quick search result, the link retrieved from get activity results, entity from deal or the request payload to save.
     *
     * @param data {Object} Search result object, or activity result link object.
     *
     * Search link result
     * @param data._id {String} Entity id
     * @param data._index {Number} Elastic search index?
     * @param data._score {Number}
     * @param data._source {Object} The full document
     * @param data._type institution, contact, fund
     *
     * Activity link result
     * @param data._id {String} Entity id
     * @param data.entity_type {String} Entity id
     * ...
     *
     * Request payload link (POST)
     * @param data._id {String} Entity id
     * @param data.entity_type {String} Entity id
     * @param data.item {Object} The full document
     * @param data.institution_id {String} For contacts, we send institution_id as well.
     *
     * Deal entity
     * @param data._id {String} Entity id
     * @param data.type {String} Entity id
     * @param data.item {Object} The full document
     */
    constructor(data = {}) {
        this._id = get(data, '_source.mongo_id') || get(data, 'item._id') || data._id || data.entityId;
        this.entity_type = this._getEntityType(data);
        this.item = this._getItem(data);
        this.q4_entity_id = data.q4_entity_id || get(data, 'item.q4_entity_id') || null;
        this.institution_id = data.q4_institution_id || data.institution_id || get(data, 'item.q4_inst_id') || null;
    }

    getPayload() {
        const payload = {
            _id: this._id,
            entity_type: this.getEntityType(),
            item: this.item
        };

        if (this.q4_entity_id) {
            payload.q4_entity_id = this.q4_entity_id;
        }

        if (this.institution_id) {
            payload.institution_id = this.institution_id;
        }

        return payload;
    }

    /**
     * Sometimes entity type is lowercase and other times is camelcase.
     * Returns consistent entity type name, from the constant values.
     * @returns {String}
     */
    getEntityType() {
        switch (this.entity_type.toLowerCase()) {
            case(ENTITY_INSTITUTION.toLowerCase()):
                return ENTITY_INSTITUTION;
            case(ENTITY_CONTACT.toLowerCase()):
                return ENTITY_CONTACT;
            case(ENTITY_FUND.toLowerCase()):
                return ENTITY_FUND;
            default:
                return null;
        }
    }

    getTitle() {
        switch (this.entity_type.toLowerCase()) {
            case(ENTITY_INSTITUTION.toLowerCase()):
                return get(this.item, 'institution_name') || get(this.item, 'institutionName');
            case(ENTITY_CONTACT.toLowerCase()):
                return get(this.item, 'full_name') || get(this.item, 'fullName');
            case(ENTITY_FUND.toLowerCase()):
                return get(this.item, 'fund_name') || get(this.item, 'fundName');
            default:
                return '-';
        }
    }

    getSubtitle() {
        switch (this.entity_type.toLowerCase()) {
            case(ENTITY_INSTITUTION.toLowerCase()):
            case(ENTITY_FUND.toLowerCase()):
                return this._getAddressFormatted(this.item);
            case(ENTITY_CONTACT.toLowerCase()):
                return get(this.item, 'jobs[0].institution_name') || get(this.item, 'jobs[0].institutionName');
            default:
                return '-';
        }
    }

    getIconClass(pt = 2) {
        switch (this.entity_type.toLowerCase()) {
            case(ENTITY_INSTITUTION.toLowerCase()):
                return `q4i-institution-${pt}pt`;
            case(ENTITY_CONTACT.toLowerCase()):
                return `${get(this.item, 'source') === 'q4desktop' ? `q4i-custom-${pt}pt` : `q4i-contact-${pt}pt`}`;
            case(ENTITY_FUND.toLowerCase()):
                return `q4i-fund-${pt}pt`;
            default:
                return '';
        }
    }

    getFactsetId() {
        switch (this.entity_type.toLowerCase()) {
            case(ENTITY_INSTITUTION.toLowerCase()):
                return get(this.item, 'factset_entity_id');
            case(ENTITY_FUND.toLowerCase()):
                return get(this.item, 'factset_entity_id') || get(this.item, 'factset_institution_id');
            case(ENTITY_CONTACT.toLowerCase()):
                return get(this.item, 'jobs[0].factset_entity_id');
            default:
                return null;
        }
    }

    getInstitutionId() {
        return this.institution_id;
    }

    setInstitutionId(id) {
        this.institution_id = id;
    }

    isCustomContact() {
        return (
            this.entity_type.toLowerCase() === ENTITY_CONTACT.toLowerCase() &&
            get(this.item, 'source') === 'q4desktop'
        );
    }

    _getAddressFormatted(entity) {
        let address = get(entity, 'address')
        if (Array.isArray(address)) {
            address = address.find((item) => get(item, 'hq') === true) || address[0];
        }

        return (address ? [address.city, address.state_province, address.country_name].filter((val) => {
            return !!(val && val.trim().length);
        }).join(', ') : get(entity,'country_name', '-'));
    }

    _getEntityType(data) {

        const entityTypes = [
            ENTITY_INSTITUTION.toLowerCase(),
            ENTITY_FUND.toLowerCase(),
            ENTITY_CONTACT.toLowerCase()
        ];

        const possibleProperties = [
            '_type',
            'entity_type',
            'type'
        ];

        return data[possibleProperties.find((prop) => {
            return typeof data[prop] === 'string' && entityTypes.includes(data[prop].toLowerCase());
        })] || '';
    }

    _getItem(data) {

        if (data._source) {
            return Object.assign({}, data._source || {}, {
                _id: get(data, '_source._id') || get(data, '_source.mongo_id')
            });
        }

        if (isPlainObject(data.item)) {
            return data.item;
        }

        return {...data};
    }
}

export default EntityLink;
