import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import { bindActionCreators } from 'redux'
import { config } from '../../../../../config'
import { getActiveTicker, getPageSizeFromStorage } from '../../../../../utils'
import { getClassName, THEMES } from '../../../../../utils/ui'
import { Spinner } from '../../../../../components'
import { fetchFundsForInstitution, statusType } from '../../../../../actions'
import FundTable from './table/table.component'
import Toolbar from './toolbar/toolbar.component'

import './grid.container.css'
import { getActiveToken } from '../../../../../utils/auth/auth.util'

const PAGE_SIZE_ID = 'fund-grid'

/**
 * @deprecated
 */
class FundGridOld extends PureComponent {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.initialQuery = {
      search: '',
      filter: '',
      sort: { property: 'current', direction: 'DESC' },
      services: ['lastActivity', 'contacts', 'targeting'],
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }

    if (props.region === 'north_america') {
      this.initialQuery.services.push('aiScore')
      this.initialQuery.services.push('purchasingPower')
    }

    this.state = {
      query: this.initialQuery
    }
  }

  componentDidMount () {
    this.getFunds()
  }

  /**
   * ComponentDidUpdate
   * @param prevProps
   * @param prevState
   */
  componentDidUpdate (prevProps, prevState) {
    const { entityId } = this.props
    const { entityId: prevEntityId } = prevProps

    if (this.state.query !== prevState.query) {
      this.getFunds()
    } else if (entityId !== prevEntityId) {
      this.resetQuery()
    }
  }

  /**
   * Reset filters and re-fetch activities
   */
  resetQuery () {
    const query = {
      ...this.initialQuery,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
    this.setState({
      query
    }, () => {
      this.getFunds()
    })
  }

  /**
   * Get query params for Funds list
   * @param params
   * @returns {Promise<{}>}
   */
  getQueryParams = async (params = {}) => {
    const queryParams = { ...this.state.query }

    const { securityId, token } = this.props
    
    const exportToken = await getActiveToken(token)

    if (queryParams.sort) {
      queryParams.sort = JSON.stringify([queryParams.sort])
    }

    if (params.export && exportToken) {
      delete queryParams.page
      delete queryParams.limit
      queryParams.token = exportToken
    }

    return {
      ...queryParams,
      securityId
    }
  }

  /**
   * Handle query change event
   * @param query
   */
  handleQueryChange = (query) => {
    this.setState({
      query: {
        ...this.state.query,
        ...query
      }
    })
  }

  /**
   * Handle row click
   * Navigate to fund entity page
   * @param rowData
   */
  handleRowClick = (rowData) => {
    if (!rowData || !rowData.factset_fund_id) {
      return
    }
    const { history } = this.props

    this.props.handleRowClick
      ? this.props.handleRowClick({ entityId: rowData.factset_fund_id })
      : history.push(`/fund/${rowData.factset_fund_id}`)
  }

  /**
   * Handles popover item click
   * @param data
   */
  handlePopoverItemClick = (data) => {
    if (!data || !data.contactId) {
      return
    }

    const { history } = this.props
    const { contactId } = data

    this.props.handlePopoverItemClick
      ? this.props.handlePopoverItemClick({ contactId })
      : history.push(`/fund/${contactId}`)
  }

  /**
   * Handle sort change
   * @param property
   * @param direction
   */
  handleSortChange = ({ property, direction }) => {
    this.handleQueryChange({
      sort: { property, direction: direction.toUpperCase() }
    })
  }

  /**
   * Fetch funds for institution
   * @param query
   */
  getFunds = async () => {
    const query = await this.getQueryParams()
    const { fetchFundsForInstitution, entityId, securityId } = this.props
    entityId && securityId && fetchFundsForInstitution(entityId, query)
  }

  /**
   * Export institution funds
   */
  handleExport = async () => {
    const { entityId } = this.props
    if (!entityId) {
      return
    }
    const query =  await this.getQueryParams({ export: true })

    window.open(`${config.apiUrl}/ownership/v2/fund/${entityId}/current/export?${queryString.stringify(query)}`, '_self')
  }

  render () {
    const { funds, total, status, region } = this.props
    const { query } = this.state
    return (<div className={getClassName('fund-grid')}>
      {(status === statusType.IN_PROGRESS) && (
        <Spinner mask theme={THEMES.RAIN} />
      )}
      <Toolbar
        searchValue={query.search}
        filter={query.filter}
        noData={!funds || !funds.length}
        handleQueryChange={this.handleQueryChange}
        handleExport={this.handleExport}
      />
      <FundTable
        pageSizeId={PAGE_SIZE_ID}
        data={funds}
        dataTotal={total}
        query={{
          page: query.page,
          limit: query.limit
        }}
        handleQueryChange={this.handleQueryChange}
        handleSortChange={this.handleSortChange}
        handleRowClick={this.handleRowClick}
        handlePopoverItemClick={this.handlePopoverItemClick}
        region={region}
      />
    </div>)
  }
}

FundGridOld.propTypes = {
  entityId: PropTypes.string.isRequired
}

const mapStateToProps = (state) => {
  const current = state.ownership.fund.current
  const profile = state.profile
  const ticker = getActiveTicker(profile.data)
  const region = get(profile, 'data.region') || 'north_america'
  const securityId = ticker && ticker._security

  return {
    token: state.token,
    status: current.status,
    funds: current.data,
    total: current.total,
    securityId,
    region
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchFundsForInstitution: bindActionCreators(fetchFundsForInstitution, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FundGridOld))
