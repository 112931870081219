import {
  EVENT_SECURITY_SEARCH_REQUEST,
  EVENT_SECURITY_SEARCH_SUCCESS,
  EVENT_SECURITY_SEARCH_FAILURE,
  EVENT_SECURITY_SEARCH_RESET,
  statusType
} from '../../actions'

const initial = {
  securitySuggestions: [],
  securitySuggestionStatus: statusType.IDLE
}

const securitySearch = (state = initial, action) => {
  switch (action.type) {
    case EVENT_SECURITY_SEARCH_REQUEST:
      return {
        ...state,
        securitySuggestionStatus: statusType.IN_PROGRESS
      }

    case EVENT_SECURITY_SEARCH_FAILURE:
      return {
        ...state,
        securitySuggestionStatus: statusType.ERROR
      }

    case EVENT_SECURITY_SEARCH_SUCCESS:
      return {
        ...state,
        securitySuggestions: (action.payload || []).map((record) => ({
          _id: record._id || record.id,
          name: record.name || record.companyName,
          symbol: record.symbol,
          exchange: record.exchange,
          entityId: record.q4_entity_id || record.tickerId
        })),
        securitySuggestionStatus: statusType.SUCCESS
      }

    case EVENT_SECURITY_SEARCH_RESET:
      return {
        ...state,
        securitySuggestions: initial.securitySuggestions,
        securitySuggestionStatus: statusType.IDLE
      }

    default:
      return state
  }
}

export default securitySearch
