import { config } from '../config'

const images = [
  'austin.jpg',
  'boston.jpg',
  'chicago.jpg',
  'denver.jpg',
  'hongkong.jpg',
  'houston.jpg',
  'newyork.jpg',
  'nyse.jpg',
  'sandiego.jpg',
  'sanfrancisco2.jpg',
  'seattle.jpg',
  'singapore.jpg',
  'timesquare.jpg',
  'tokyo.jpg',
  'toronto.jpg'
]

/**
 * Get background image
 * @param marketingCampaign
 * @returns {string}
 */
export function getBackgroundImage (marketingCampaign) {
  if (marketingCampaign) {
    return `${config.s3StaticFiles}/q4marketing/darrell-on-stage.jpg`
  }

  return `${config.s3StaticFiles}/q4splash/${images[Math.floor(Math.random() * images.length)]}`
}
