import React from 'react'

// utils
import { ENTITY_TYPE } from '../../../../../../../utils'
import { get } from 'lodash'

const { FUND, INSTITUTION } = ENTITY_TYPE
const EMPTY_PLACEHOLDER = '-'

/**
 * Title Cell Component
 * @param props
 */
const TitleCell = ({ data, onUtilityClick }) => {
  const { fundId, institutionId, fundName, institutionName, holderType, activist, dealConnection, targetConnection } = (data || {})
  const entityType = (holderType === FUND) ? FUND : INSTITUTION

  const name = get(data, `${entityType}Name`, '')
  const country = get(data, `${entityType}CountryName`)

  const target = get(targetConnection, 'items[0].id')
  const hasDeals = !!(dealConnection && dealConnection.items && dealConnection.items.length)
  const entity = { entityType, hasDeals, target }

  return (
    <div className='cell'>
      <i className={`cell_icon cell_icon--${entityType} q4i-${entityType}-2pt`} />
      <div className='cell_content'>
        <div className='cell_content-name'>{name}</div>
        {country ? <div className='cell_content-detail'>{country}</div> : null}
      </div>

      {activist && <i className='cell_indicator cell_indicator--activist q4i-activist-4pt' />}
      {!!target && <i className='cell_indicator cell_indicator--target q4i-targeting-4pt' />}
      {(entityType !== 'insider') &&
        <i
          className='cell_indicator cell_indicator--utility q4i-utility-4pt'
          onClick={(event) => onUtilityClick(event, {
            ...(entityType === FUND)
              ? { id: fundId, fundName, ...entity }
              : { id: institutionId, institutionName, ...entity }
          })}
        />}
    </div>
  )
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 * @param rowIndex
 * @param agGridReact
 * @param onUtilityClick
 */
const CustomCell = ({ data = {}, column = {}, rowIndex, agGridReact, onUtilityClick }) => {
  const pagination = get(agGridReact, 'props.paginationProps', {})
  const page = get(pagination, 'forcePage', 1)
  const limit = get(pagination, 'initialPageSize', 10)

  switch (column.colId) {
    case 'rank':
      return (page - 1) * limit + (rowIndex || 0) + 1
    case 'fundName':
    case 'institutionName':
      return (
        <TitleCell
          data={data}
          onUtilityClick={onUtilityClick}
        />
      )
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
