import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const GET_TARGETING_SEARCH_REQUEST = 'GET_TARGETING_SEARCH_REQUEST'
export const GET_TARGETING_SEARCH_SUCCESS = 'GET_TARGETING_SEARCH_SUCCESS'
export const GET_TARGETING_SEARCH_FAILURE = 'GET_TARGETING_SEARCH_FAILURE'

export const RESET_TARGETING_SEARCH = 'RESET_TARGETING_SEARCH'

const _search = (params) => ({
  [CALL_API]: {
    types: [
      GET_TARGETING_SEARCH_REQUEST,
      GET_TARGETING_SEARCH_SUCCESS,
      GET_TARGETING_SEARCH_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/targeting/search',
    payload: params
  }
})

export const searchTargets = (params) => (dispatch) => {
  dispatch(_search(params))
}

export const resetSearchTargets = () => (dispatch) => {
  dispatch({ type: RESET_TARGETING_SEARCH })
}

export const updateSearchTargets = (type, payload) => (dispatch) => {
  dispatch({ type, payload })
}
