import ReactHighcharts from 'react-highcharts/ReactHighcharts';
import React, {Component} from 'react';
import _ from 'lodash';
import {getRoundValue} from '../../../../utils/number.util';
import renderHTML from 'react-render-html';
import {
    cherry,
    darkTeal,
    raisinBlack,
    slate,
    softGrey,
    coldGrey,
    spice,
    tangerine,
    teal
} from "../../../../resources/materialui-overrides/colors";

class TradingAnalytics extends Component {

    constructor(props) {
        super(props);

        this.classNameMap = {
            activist: 'q4i-activist-2pt',
            volatility: 'q4i-volatility-2pt',
            sentiment: 'q4i-sentiment-2pt'
        };

        this.subscriptionTargetMap = {
            activist: 'activism',
            volatility: 'volatility',
            sentiment: 'sentiment'
        };

        this.subscriptionMap = _.zipObject(props.subscriptions, _.map(props.subscriptions, () => true));
    }

    shouldComponentUpdate(nextProps) {
        const {layout, theme, indicators} = this.props;
        const osi = nextProps.indicators.osi !== indicators.osi;
        const ovi = nextProps.indicators.ovi !== indicators.ovi;
        const oaa = nextProps.indicators.oaa !== indicators.oaa;

        // If layout widget / height are updated, redraw chart
        if (nextProps.layout.w !== layout.w || nextProps.layout.h !== layout.h) {
            return true;
        }

        // If theme changes update
        if (nextProps.theme !== theme) {
            return true;
        }

        return (osi || ovi || oaa);
    }

    /**
     * Get Sentiment level from very bearish to ver bullish.
     * @param value
     * @returns {*}
     */
    getSentimentLevel = (value) => {
        const {theme} = this.props;

        const colorMap = {
            none: theme === 'dark' ? slate : softGrey,
            veryLow: cherry,
            low: spice,
            medium: tangerine,
            high: teal,
            veryHigh: darkTeal
        };

        value = getRoundValue(value);
        let color = theme === 'dark' ? raisinBlack : coldGrey;

        if (value >= 31) {
            // very bullish
            color = colorMap.veryHigh;
        }
        if (value >= 11 && value <= 30) {
            // bullish
            color = colorMap.high;
        }
        if (value >= -10 && value <= 10) {
            // neutral
            color = colorMap.medium;
        }
        if (value <= -11 && value >= -30) {
            // bearish
            color = colorMap.low;
        }
        if (value <= -31) {
            // very bearish
            color = colorMap.veryLow;
        }

        return (value < 0) ? [colorMap.none, color] : [color, colorMap.none];
    };

    /**
     * Get Volatility from low to high.
     * @param value
     * @returns {*}
     */
    getVolatilityColor = (value) => {
        const {theme} = this.props;

        const colorMap = {
            none: theme === 'dark' ? slate : softGrey,
            low: teal,
            moderate: tangerine,
            high: cherry
        };

        value = getRoundValue(value);

        let color = theme === 'dark' ? raisinBlack : coldGrey;

        if (value >= 1 && value <= 25) {
            color = colorMap.low;
        }
        if (value >= 26 && value <= 65) {
            color = colorMap.moderate;
        }
        if (value >= 66 && value <= 100) {
            color = colorMap.high;
        }

        return [color, colorMap.none];
    };

    /**
     * Get Activism from low risk to high risk.
     * @param value
     * @returns {*}
     */
    getActivismLevel = (value) => {
        const {theme} = this.props;

        const colorMap = {
            none: theme === 'dark' ? slate : softGrey,
            low: teal,
            medium: tangerine,
            high: cherry
        };

        value = getRoundValue(value);
        let color = theme === 'dark' ? raisinBlack : coldGrey;

        if (value >= 1 && value <= 2) {
            // low risk
            color = colorMap.low;
        }
        if (value >= 3 && value <= 5) {
            // moderate risk
            color = colorMap.medium;
        }
        if (value >= 6 && value <= 10) {
            // high risk
            color = colorMap.high;
        }
        return [color, colorMap.none];
    };

    getData = (value, subscription, rate) => {
        let _tmpArr;

        if (!this.subscriptionMap[subscription]) {
            _tmpArr = [100, 0];
        } else {
            rate = rate ? rate : 1;
            _tmpArr = [rate * Math.abs(value), 100 - rate * Math.abs(value)];
        }

        return _.map((value < 0 ? _.reverse(_tmpArr) : _tmpArr), (each) => {
            return Object.assign({y: each}, {sliced: false, selected: true});
        });
    };

    getTradingItem = (data) => {
        const _target = data.target;
        const _className = this.classNameMap[_target] ? this.classNameMap[_target] : '';

        return (
            <div className="item-wrapper"
                 onClick={() => {
                     return this.redirectToSecurity();
                 }}
            >
                {data.showIcon && <div className={`trading-icon`}>
                    <i className={`${_className}`}/>
                </div>}
                <div
                    className={`trading-value`}>{this.subscriptionMap[this.subscriptionTargetMap[_target]] ? data.value : '-'}</div>
                <div className={`trading-title`}>{data.title}</div>
            </div>
        );
    };

    getNarrow = () => {
        const {classes, indicators} = this.props;

        return (
            <div className={`${classes.items}`}>
                <div className={`${classes.item}`}>{this.getTradingItem({
                    value: indicators.osi, title: `Sentiment`,
                    target: 'sentiment', showIcon: true
                })}</div>
                <div className={`${classes.item}`}>{this.getTradingItem({
                    value: indicators.ovi, title: `Volatility`,
                    target: 'volatility', showIcon: true
                })}</div>
                <div className={`${classes.item}`}>{this.getTradingItem({
                    value: indicators.oaa, title: `Activism`,
                    target: 'activist', showIcon: true
                })}</div>
            </div>
        );
    };

    getItems = (extra, rate, innerSize) => {
        const {classes, indicators, getCommentary} = this.props;
        const itemsClasses = !extra ? classes.items : `${classes.items} ${classes.items}--${extra}`;

        return (
            <div className={itemsClasses}>
                <div className={`${classes.item} sentiment-item`}>
                    <ReactHighcharts config={
                        this.getChartConfig(
                            'sentiment',
                            this.getData(indicators.osi, 'sentiment'),
                            this.getSentimentLevel(indicators.osi),
                            rate,
                            innerSize
                        )}/>
                    {this.getTradingItem({value: indicators.osi, title: `Sentiment`, target: 'sentiment'})}
                </div>
                <div className={`${classes.item}`}>
                    <ReactHighcharts config={
                        this.getChartConfig(
                            'volatility',
                            this.getData(indicators.ovi, 'volatility'),
                            this.getVolatilityColor(indicators.ovi),
                            rate,
                            innerSize
                        )}/>
                    {this.getTradingItem({value: indicators.ovi, title: `Volatility`, target: 'volatility'})}
                </div>
                <div className={`${classes.item}`}>
                    <ReactHighcharts config={
                        this.getChartConfig(
                            'activist',
                            this.getData(indicators.oaa, 'activism', 10),
                            this.getActivismLevel(indicators.oaa),
                            rate,
                            innerSize
                        )}/>
                    {this.getTradingItem({value: indicators.oaa, title: `Activism`, target: 'activist'})}
                </div>
                {getCommentary && (
                    <div className={`${classes.name}_commentary q4-fade-in`}>
                        {renderHTML(this.props.getCommentary())}
                    </div>
                )}
            </div>
        );
    };

    redirectToSecurity = () => {
        const {tickerId} = this.props;
        tickerId && window.open(`/security/${tickerId}`, '_parent');
    };

    getChartConfig = (target, data, colors, rate, innerSize) => {
        let size = 70;
        innerSize = !innerSize ? 80 : innerSize;
        size = !rate ? size : rate * size;
        const _className = this.classNameMap[target] ? this.classNameMap[target] : '';

        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                padding: 0,
                backgroundColor: 'transparent',
                spacingTop: 0,
                spacingBottom: 0,
                spacingLeft: 0,
                spacingRight: 0,
                type: 'pie',
                width: size,
                height: size,
                animation: false,
                events: {
                    click: () => {
                        return this.redirectToSecurity();
                    }
                }
            },
            title: {
                text: `<i style="cursor: pointer" class="${_className}">`,
                verticalAlign: 'middle',
                floating: true,
                useHTML: true,

            },
            credits: {enabled: false},
            tooltip: {
                enabled: false,
                crosshairs: false
            },
            navigator: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            rangeSelector: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    margin: 0,
                    borderWidth: 0,
                    size: '100%',
                    colors: colors,
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            series: [{
                animation: false,
                innerSize: `${innerSize}%`,
                size: size,
                data: data
            }]
        }
    };

    render() {
        const {classes, layout} = this.props;
        return (<div className={`${classes.name}_body`}>
            {(layout.w === 1 && layout.h === 1) ? this.getNarrow() :
                (layout.w >= 2 && layout.h === 1) ? this.getItems() :
                    (layout.w >= 2 && layout.h >= 3) ? this.getItems('2x3', 1.45, 86) :
                        (layout.w >= 2 && layout.h >= 2) ? this.getItems('2x2', 1.45, 86) :
                            (layout.w >= 1 && layout.h >= 4) ? this.getItems('1x4') :
                                (layout.w >= 1 && layout.h >= 3) ? this.getItems('1x3') :
                                    (layout.w >= 1 && layout.h >= 2) ? this.getItems('1x2') : null
            }
        </div>)
    }
};

export default TradingAnalytics;
