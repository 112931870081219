import React, { PureComponent } from 'react'
import Truncate from 'react-truncate'
import PropTypes from 'prop-types'
import { RenderHTML } from '../../components'
import { getClassName, THEMES } from '../../utils'

import './readMore.component.css'

/**
 * Read More Component
 * https://github.com/One-com/react-truncate
 */
class ReadMore extends PureComponent {
  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      expanded: false
    }

    this.toggleLines = this.toggleLines.bind(this)
  }

  /**
   * Toggle Lines: More/Less Click Event
   */
  toggleLines () {
    const { expanded } = this.state
    this.setState({ expanded: !expanded })
  }

  /**
   * Render Read More
   * @returns {*}
   */
  render () {
    const { theme, lines, text, renderHTML, more } = this.props
    const { expanded } = this.state

    return (
      <div className={getClassName('read-more', [
        { condition: theme, trueClassName: `read-more--${theme}` }
      ])}>
        <Truncate
          trimWhitespace
          lines={!expanded && lines}
          ellipsis={(
            <span>... <span className='read-more_link' onClick={this.toggleLines}>{more}</span></span>
          )}
        >
          {renderHTML ? <RenderHTML html={text} /> : text}
        </Truncate>
      </div>
    )
  }
}

ReadMore.propTypes = {
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  lines: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  more: PropTypes.string,
  renderHTML: PropTypes.bool
}

ReadMore.defaultProps = {
  theme: THEMES.DARK,
  lines: 2,
  more: 'Read More',
  renderHTML: false
}

export default ReadMore
