import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// actions
import { useDealQuery, useDealUpdateQuery } from '../hook'
import { createToast, closeModal, openModal, modalType } from '../../../actions'

// components
import DealCard from './deal.component'
import { THEMES } from '../../../utils'
import { get } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  entityType: PropTypes.string.isRequired,
  data: PropTypes.shape({
    isLoading: PropTypes.bool,
    deals: PropTypes.array,
    pipeline: PropTypes.array
  }).isRequired,
  onSaveSuccess: PropTypes.func
}

const defaultProps = {
  theme: THEMES.DARK,
  data: {}
}

/**
 * Entity Deal Component
 * @param props
 */
function EntityDeal (props) {
  const { dataId, theme, entityType, data, onSaveSuccess, createToast, openModal, closeModal } = props
  const { isLoading, deals, pipeline } = data
  const [id, setId] = useState(null)

  const [getDeal, { loading: isDeal, data: deal, refetch }] = useDealQuery()
  const [updateDeal, { loading: isUpdate }] = useDealUpdateQuery()

  // set initial deal id (get first active deal)
  useEffect(() => {
    const lost = (pipeline || []).find((item) => item.lost)
    const deal = (deals || []).find((deal) => deal.stage !== (lost && lost.id))
    const id = deal && deal.id

    id && setId(id)
  }, [deals, pipeline])

  // get deal by id
  useEffect(() => {
    id && getDeal({ variables: { id } })
  }, [id, getDeal])

  /**
   * Update deal stage
   * @param stage
   */
  const handleDealUpdate = (stage) => {
    if (!id || !stage) {
      return
    }

    updateDeal({ variables: { id, stage } })
      .then((response) => get(response, 'errors') ? handleFailure() : handleSuccess())
  }

  /**
   * On Action Success
   */
  function handleSuccess () {
    refetch().then(() => {
      createToast({ text: 'Deal updated successfully.' })
      onSaveSuccess && onSaveSuccess()
    })
  }

  /**
   * On Action Completion Failure display an error
   */
  function handleFailure () {
    openModal({ type: modalType.ERROR_MODAL })
  }

  /**
   * Render Deal Card Component
   */
  return (
    <DealCard
      dataId={`${dataId}DealsCard`}
      theme={theme}
      isLoading={[isLoading, isDeal].some((item) => !!item)}
      isUpdate={isUpdate}
      entityType={entityType}
      pipeline={pipeline}
      deals={deals}
      deal={get(deal, 'deal.items[0]', {})}
      onDealSelect={(id) => setId(id)}
      onDealUpdate={handleDealUpdate}
      openModal={openModal}
      closeModal={closeModal}
    />
  )
}

EntityDeal.propTypes = propTypes
EntityDeal.defaultProps = defaultProps

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  createToast: bindActionCreators(createToast, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(EntityDeal)
