import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import { sendMail, createToast, openModal, modalType } from '../../../actions'

import { TextArea } from '../../shared'
import { Modal } from '../../../components'
import { THEMES } from '../../../utils/ui'

const dataIdPrefix = 'EntitySearchRequestProfile'

/**
 * Entity Search Request Profile Component
 */
class EntitySearchRequestProfile extends Component {

  /**
   * Render Modal
   * @returns {*}
   */
  render () {
    const { values, errors, isSubmitting, handleChange, handleSubmit, onClose } = this.props

    return (
      <Modal
        dataId={dataIdPrefix}
        visible
        title='Request Profile'
        footerButtons={[
          {
            dataId: `${dataIdPrefix}Cancel`,
            label: 'Cancel',
            ui: 'shaded',
            onClick: onClose
          },
          {
            dataId: `${dataIdPrefix}Save`,
            label: 'Save',
            ui: THEMES.CITRUS,
            disabled: isSubmitting,
            onClick: handleSubmit
          }]}
        onClose={onClose}
      >
        <form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
          <div className='field field--text field--full'>
            <label className='field_label'>
              Message <span className='required'>(required)</span>
            </label>
            <TextArea
              dataId={`${dataIdPrefix}Message`}
              id='message'
              error={{ isError: !!(errors.message && errors.message.length) }}
              placeholder='Profile details'
              value={values.message}
              onChange={handleChange}
              maxLength={700}
            />
          </div>
        </form>
      </Modal>
    )
  }
}

const formikParams = {
  mapPropsToValues: () => ({
    message: ''
  }),
  validationSchema: Yup.object().shape({
    message: Yup.string().trim().required('Please provide a message.')
  }),
  handleSubmit: (values, { props, setStatus, setSubmitting }) => {
    const { message } = values
    const { sendMail, onClose, openModal, createToast } = props

    sendMail({
      message,
      template: 'iris-content-coverage',
      subject: 'Content Request',
      to: 'desktop@q4inc.com'
    }).then((response) => {

      switch (response.type) {
        case 'MAIL_SUCCESS':
          setSubmitting(false)
          setStatus({ success: true })
          createToast({ text: 'Request has been successfully sent.' })
          onClose()
          break

        case 'MAIL_ERROR':
          setSubmitting(false)
          setStatus({ success: false })
          openModal({
            type: modalType.ERROR_MODAL
          })
          break

        default:
          break
      }
    })
  },
  displayName: 'RequestProfileForm'
}

/**
 * Maps App Store State to Container's Props
 * @param state - store state
 */
const mapStateToProps = (state) => ({
  shared: state.shared,
  toast: state.toast
})

const mapDispatchToProps = (dispatch) => ({
  sendMail: bindActionCreators(sendMail, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  createToast: bindActionCreators(createToast, dispatch)
})

EntitySearchRequestProfile.propTypes = {}

export default connect(mapStateToProps, mapDispatchToProps)(withFormik(formikParams)(EntitySearchRequestProfile))
