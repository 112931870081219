import React from 'react'

import { statusType } from '../../../../../actions'

import Grid from '@material-ui/core/Grid'

import { RenderHTML, Spinner } from '../../../../../components'
import { defaultIfEmpty, format, THEMES } from '../../../../../utils'
import { config } from '../../../../../config'

function CompactLayout (props) {
  const {
    entityId,
    jobTitle,
    institutionName,
    institutionType,
    address,
    phones,
    email,
    functions,
    strategy,
    position,
    ownershipLabel,
    handleTitleClick
  } = props

  return (
    <Grid container item xs={12}>
      {/* LEFT COLUMN WITH CONTACT DETAILS INFORMATION */}
      <Grid item xs={6} sm={7}>
        <div className='card_section card_section--reverse institution_header'>
          <div
            className='card_section-title'
            onClick={() => handleTitleClick({ entityId })}
          >
            {defaultIfEmpty(institutionName)}
          </div>
          <div className='card_section-detail'>{defaultIfEmpty(institutionType)}</div>
        </div>

        <Grid container item xs={12} spacing={3}>
          <Grid item xs={6} className='card_section'>
            <h2 className='card_section-subtitle'>Address</h2>
            <div className='card_section-detail'><RenderHTML html={defaultIfEmpty(address)} /></div>
          </Grid>
          <Grid item xs={6}>
            <div className='card_section'>
              <h2 className='card_section-subtitle'>Corporate Phone(s)</h2>
              <div className='card_section-detail'><RenderHTML html={defaultIfEmpty(phones)} /></div>
            </div>
            <div className='card_section--grouped'>
              <h2 className='card_section-subtitle'>Corporate Email</h2>
              {(email && email.length)
                ? <a
                  className='card_section-detail'
                  href={`mailto:${email}?Subject=Contact%20from%20Q4%20Desktop&bcc=${config.loggerEmail}`}
                >{email}
                </a>
                : '-'}
            </div>
          </Grid>
          <Grid item xs={6} className='card_section'>
            <h2 className='card_section-subtitle'>Job Title</h2>
            <div className='card_section-detail'>{defaultIfEmpty(jobTitle)}</div>
          </Grid>
          <Grid item xs={6} className='card_section'>
            <h2 className='card_section-subtitle'>Function(s)</h2>
            {(functions && Array.isArray(functions) && functions.length)
              ? (functions).map((item, index) => <div
                key={index}
                className='card_section-detail card_section-detail-text'
              >{item}
              </div>)
              : '-'}
          </Grid>

          <Grid item xs={12} className='card_section'>
            <h2 className='card_section-subtitle'>Coverage/Strategy</h2>
            <div className='card_section-detail card_section-detail-text'>{strategy}</div>
          </Grid>
        </Grid>
      </Grid>

      {/* RIGHT COLUMN WITH OWNERSHIP INFORMATION */}
      <Grid item xs={6} sm={5} className='card_section-positions'>
        {!position || !position.status || (position.status === statusType.IN_PROGRESS) || position[entityId] === undefined
          ? <Spinner theme={THEMES.RAIN} />
          : (<>
            <Grid container item xs={12} className='card_section-position q4-fade-in'>
              <div className='institution_position'>
                <div className='card_section--reverse card_section--theme'>
                  <div className='card_section-subtitle'>Institution Position</div>
                  <div className='card_section-detail'>{ownershipLabel}</div>
                </div>
                <div className='institution_position-position'>
                  {defaultIfEmpty(format(position[entityId]))}
                </div>
              </div>
            </Grid>
            <Grid container item xs={12} className='card_section-position q4-fade-in'>
              <div className='institution_position'>
                <div className='card_section--reverse card_section--theme'>
                  <div className='card_section-subtitle'>Contact Position</div>
                  <div className='card_section-detail'>13F</div>
                </div>
                <div className='institution_position-position'>{format(position.data)}</div>
              </div>
            </Grid>
          </>
          )}
      </Grid>
    </Grid>
  )
}

export default CompactLayout
