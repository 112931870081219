import React, { memo } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

// utils
import { combine } from '../../../../utils'
import { get } from 'lodash'

const propTypes = {
  profile: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  note: PropTypes.string
}

const defaultProps = {
  title: 'Briefing Book'
}

/**
 * Briefing Book Cover
 * @param tprops
 */
function Cover ({ profile, title, note }) {
  const { firstName, lastName } = (profile || {})
  const name = combine([firstName, lastName], ' ')
  const organization = get(profile, 'organizationConnection.name', null)

  return (
    <section className='tearsheet-cover'>
      <div className='tearsheet-cover_title'>{title}</div>
      <div className='tearsheet-cover_text tearsheet-cover_text--profile'>
        <div className='tearsheet-cover_text'>{moment.utc(new Date()).format('MMMM YYYY')}</div>
        {name ? <div className='tearsheet-cover_text'>{name}</div> : null}
        {organization ? <div className='tearsheet-cover_text'>{organization}</div> : null}
        {note &&
        <>
          <span className='tearsheet-cover_subtitle'>Commentary</span>
          <span className='tearsheet-cover_text tearsheet-cover_text--note'>{note}</span>
        </>}
      </div>
    </section>
  )
}

Cover.propTypes = propTypes
Cover.defaultProps = defaultProps

export default memo(Cover)
