import {
  GET_ADDRESS_BOOK_INSTITUTION_REQUEST,
  GET_ADDRESS_BOOK_INSTITUTION_SUCCESS,
  GET_ADDRESS_BOOK_INSTITUTION_FAILURE,
  RESET_ADDRESS_BOOK_INSTITUTION,
  GET_ADDRESS_BOOK_CONTACT_LOCATION_REQUEST,
  GET_ADDRESS_BOOK_CONTACT_LOCATION_SUCCESS,
  GET_ADDRESS_BOOK_CONTACT_LOCATION_FAILURE,
  RESET_ADDRESS_BOOK_CONTACT_LOCATION,
  statusType
} from '../../actions'

const initial = {
  institutionData: [],
  totalInst: 0,
  institutionStatus: statusType.IDLE,
  institutionLoading: false,
  locationData: [],
  locationStatus: statusType.IDLE,
  locationLoading: false
}

const addressBookFilters = (state = initial, action) => {
  const { payload, meta } = action

  switch (action.type) {
    case GET_ADDRESS_BOOK_INSTITUTION_REQUEST:
      return {
        ...state,
        institutionStatus: statusType.IN_PROGRESS,
        institutionLoading: true
      }

    case GET_ADDRESS_BOOK_INSTITUTION_SUCCESS:
      return {
        ...state,
        institutionData: (payload || []).map(inst => {
          return {
            value: inst?.entityConnection?.entityId,
            label: inst?.entityConnection?.institutionName
          }
        }),
        totalInst: meta && meta.total,
        institutionStatus: statusType.SUCCESS,
        institutionLoading: false
      }

    case GET_ADDRESS_BOOK_INSTITUTION_FAILURE:
      return {
        ...state,
        institutionData: [],
        institutionStatus: statusType.ERROR,
        institutionLoading: false
      }

    case RESET_ADDRESS_BOOK_INSTITUTION:
      return {
        ...state,
        institutionData: []
      }

    case GET_ADDRESS_BOOK_CONTACT_LOCATION_REQUEST:
      return {
        ...state,
        locationStatus: statusType.IN_PROGRESS,
        locationLoading: true
      }

    case GET_ADDRESS_BOOK_CONTACT_LOCATION_SUCCESS:
      return {
        ...state,
        locationData: (payload || []).map(contact => {
          return {
            city: contact?.entityConnection?.city,
            country: contact?.entityConnection?.country,
            countryCode: contact?.entityConnection?.countryCode,
            state: contact?.entityConnection?.state
          }
        }),
        locationStatus: statusType.SUCCESS,
        locationLoading: false
      }

    case GET_ADDRESS_BOOK_CONTACT_LOCATION_FAILURE:
      return {
        ...state,
        locationData: [],
        locationStatus: statusType.ERROR,
        locationLoading: false
      }

    case RESET_ADDRESS_BOOK_CONTACT_LOCATION:
      return {
        ...state,
        locationData: []
      }

    default:
      return state
  }
}

export default addressBookFilters
