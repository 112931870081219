import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import gql from 'graphql-tag'
import { get } from 'lodash'
import client from '../../middleware/graphql.middleware'
import { SWIFTYPE_ENTITY_TYPES } from '../../utils'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../services/launchDarkly.service'

export const GET_ACTIVITY_REQUEST = 'GET_ACTIVITY_REQUEST'
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS'
export const GET_ACTIVITY_FAILURE = 'GET_ACTIVITY_FAILURE'
export const GET_ACTIVITY_LIST_REQUEST = 'GET_ACTIVITY_LIST_REQUEST'
export const GET_ACTIVITY_LIST_SUCCESS = 'GET_ACTIVITY_LIST_SUCCESS'
export const GET_ACTIVITY_LIST_FAILURE = 'GET_ACTIVITY_LIST_FAILURE'
export const REMOVE_ACTIVITY_REQUEST = 'REMOVE_ACTIVITY_REQUEST'
export const REMOVE_ACTIVITY_SUCCESS = 'REMOVE_ACTIVITY_SUCCESS'
export const REMOVE_ACTIVITY_FAILURE = 'REMOVE_ACTIVITY_FAILURE'
export const RESET_ACTIVITY_LIST = 'RESET_ACTIVITY_LIST'
export const RESET_ACTIVITY = 'RESET_ACTIVITY'
export const GET_CONTACT_FILTER_REQUEST_GQL = 'GET_CONTACT_FILTER_REQUEST_GQL'
export const GET_CONTACT_FILTER_SUCCESS_GQL = 'GET_CONTACT_FILTER_SUCCESS_GQL'
export const GET_CONTACT_FILTER_FAILURE_GQL = 'GET_CONTACT_FILTER_FAILURE_GQL'
export const RESET_CONTACT_FILTER = 'RESET_CONTACT_FILTER'
export const GET_INSTITUTION_FILTER_REQUEST_GQL = 'GET_INSTITUTION_FILTER_REQUEST_GQL'
export const GET_INSTITUTION_FILTER_SUCCESS_GQL = 'GET_INSTITUTION_FILTER_SUCCESS_GQL'
export const GET_INSTITUTION_FILTER_FAILURE_GQL = 'GET_INSTITUTION_FILTER_AILURE_GQL'
export const RESET_INSTITUTION_FILTER = 'RESET_INSTITUTION_FILTER'

const _get = (id, params) => ({
  [CALL_API]: {
    types: [
      GET_ACTIVITY_REQUEST,
      GET_ACTIVITY_SUCCESS,
      GET_ACTIVITY_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/crm/activity/${id}`,
    payload: params
  }
})

const _getList = (params, options) => ({
  [CALL_API]: {
    types: options.types || [
      GET_ACTIVITY_LIST_REQUEST,
      GET_ACTIVITY_LIST_SUCCESS,
      GET_ACTIVITY_LIST_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/crm/activity',
    payload: params
  }
})

const _removeActivities = (ids, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      REMOVE_ACTIVITY_REQUEST,
      REMOVE_ACTIVITY_SUCCESS,
      REMOVE_ACTIVITY_FAILURE
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: `/crm/activity?${ids.map((id) => 'ids=' + id + '&').join('').slice(0, -1)}`
  }
})

const _fetchInstitutionFilter = (query) => (dispatch) => {
  dispatch({ type: GET_INSTITUTION_FILTER_REQUEST_GQL })
  return client
    .query({
      query: ADVANCED_SEARCH_INST_QUERY,
      variables: {
        query: query,
        entity: SWIFTYPE_ENTITY_TYPES.INSTITUTIONS,
        filter: { "activityLogged": true },
        page: 1,
        limit: 10,
        useElasticSearch: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)
      },
      fetchPolicy: 'no-cache'
    })
    .then((data) => {
      const searchResults = get(data, 'data.advancedSearch.items')
      dispatch({
        payload: searchResults,
        type: GET_INSTITUTION_FILTER_SUCCESS_GQL,
      })
    })
    .catch((error) => {
      dispatch({ error, type: GET_INSTITUTION_FILTER_FAILURE_GQL })
    })
}

export const getActivity = (id, params) => (dispatch) => {
  dispatch(_get(id, params))
}

/**
 * Get the list of Activities
 * @param params
 * @param options
 * @param options.types - action types
 * @private
 */
export const getActivityList = (params, options = {}) => (dispatch) => {
  return dispatch(_getList(params, options))
}

/**
 * Reset data in Activity Detail Page
 */
export const resetActivity = () => (dispatch) => {
  return dispatch({ type: RESET_ACTIVITY })
}

/**
 * Reset Data in Activity Table
 */
export const resetActivityList = () => (dispatch) => {
  return dispatch({ type: RESET_ACTIVITY_LIST })
}

/**
 * Remove Activities By list of ids
 * @param payload.ids
 * @returns {function(*): *}
 */
export const removeActivities = (ids) => (dispatch) => {
  return dispatch(_removeActivities(ids))
}

export const fetchInstitutionFilter = (query) => (dispatch) => {
  if (!query) {
    return dispatch({
      payload: [],
      type: GET_INSTITUTION_FILTER_SUCCESS_GQL,
    })
  }
  return dispatch(_fetchInstitutionFilter(query))
}

/**
 * Dispatch RESET_INSTITUTION_FILTER
 */
 export const resetInstitutionFilter = () => (dispatch) => {
  return dispatch({ type: RESET_INSTITUTION_FILTER })
}

const _fetchContactFilter = (query) => (dispatch) => {
  dispatch({ type: GET_CONTACT_FILTER_REQUEST_GQL })
  return client
    .query({
      query: ADVANCED_SEARCH_CONTACT_QUERY,
      variables: {
        query: query,
        entity: [SWIFTYPE_ENTITY_TYPES.CONTACTS],
        filter: { "activityLogged": true },
        page: 1,
        limit: 10,
        useElasticSearch: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)
      },
      fetchPolicy: 'no-cache'
    })
    .then((data) => {
      const searchResults = get(data, 'data.advancedSearch.items')
      dispatch({
        payload: searchResults,
        type: GET_CONTACT_FILTER_SUCCESS_GQL,
      })
    })
    .catch((error) => {
      dispatch({ error, type: GET_CONTACT_FILTER_FAILURE_GQL })
    })
}

/**
 * Dispatch GET_CONTACT_FILTER_REQUEST_GQL
 */
export const fetchContactFilter = (query) => (dispatch) => {
  if (!query) {
    return dispatch({
      payload: [],
      type: GET_CONTACT_FILTER_SUCCESS_GQL,
    })
  }
  return dispatch(_fetchContactFilter(query))
}

/**
 * Dispatch RESET_CONTACT_FILTER
 */
export const resetContactSuggestions = () => (dispatch) => {
  return dispatch({ type: RESET_CONTACT_FILTER })
}

/**
 * ADVANCED_SEARCH GraphQL query for institution search
 */
const ADVANCED_SEARCH_INST_QUERY = gql`
  query AdvancedSearch(
    $entity: [String]!, 
    $filter: SearchFilter, 
    $query: String,
    $page: Int, 
    $limit: Int,
    $useElasticSearch: Boolean,
  ) {
    advancedSearch(
      entity: $entity, 
      filter: $filter, 
      query: $query,
      page: $page, 
      limit: $limit,
      useElasticSearch: $useElasticSearch,
    ) {
      items {
        id
        entityConnection {
          ...institutionsFragmentActivity
        }
      }
      count
    }
  }
  fragment institutionsFragmentActivity on InstitutionSearchDTO {
    type
    sourceId
    institutionName
    entityId
  }
`

/**
 * ADVANCED_SEARCH GraphQL query for contact search
 */
const ADVANCED_SEARCH_CONTACT_QUERY = gql`
  query AdvancedSearch(
    $entity: [String]!, 
    $query: String, 
    $filter: SearchFilter, 
    $page: Int, 
    $limit: Int,
    $useElasticSearch: Boolean
  ) {
    advancedSearch(
      entity: $entity, 
      query: $query, 
      filter: $filter, 
      page: $page, 
      limit: $limit,
      useElasticSearch: $useElasticSearch
    ) {
      items {
        id
        entityConnection {
          ...contactsFragmentActivity
        }
      }
      count
    }
  }
  fragment contactsFragmentActivity on ContactSearchDTO {
    fullName
  }
`
