import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Message } from '../../../components'
import { MESSAGE_TYPE, THEMES } from '../../../utils'

const { INFO, SUCCESS } = MESSAGE_TYPE

const propTypes = {
  type: PropTypes.oneOf([INFO, SUCCESS]),
  content: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  onClose: PropTypes.func.isRequired
}

const defaultProps = {
  type: INFO,
  content: {}
}

/**
 * General Information Modal
 * @param type - info or success
 * @param onClose
 * @param content: title, message, button label
 */
const InfoModal = ({ onClose, type, content }) => {
  const { title, message, label } = content
  return (
    <Message
      visible
      useHtml
      renderExitButton
      type={type}
      title={title}
      message={message}
      onClose={onClose}
      buttons={[
        {
          ui: (type === SUCCESS) ? THEMES.TEAL : THEMES.CITRUS,
          label: label || 'ok',
          onClick: onClose
        }
      ]}
    />
  )
}

InfoModal.propTypes = propTypes
InfoModal.defaultProps = defaultProps

export default memo(InfoModal)
