import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { snakeCase } from 'lodash'

// utils
import {
  defaultIfEmpty,
  format,
  getChangeClassName,
  getClassName,
  getLocalizedCurrency,
  inMillions,
  isYetToFile
} from '../../../../../utils'

const EMPTY_PLACEHOLDER = '-'

const propTypes = {
  securityName: PropTypes.string.isRequired,
  security: PropTypes.object.isRequired,
  holdings: PropTypes.array.isRequired,
  limit: PropTypes.number.isRequired
}

const defaultProps = {
  security: {},
  holdings: []
}

/**
 * Compare a holding and a security, compare by tickerID
 * if possible, if not compare by security name as backup
 * @param holding
 * @param security
 */
function compareSecurities (holding, security) {
  if (!holding || !security) return false

  if (holding?.tickerId && security?.tickerId) {
    return holding?.tickerId === security?.tickerId
  }

  return snakeCase(holding?.securityName) === snakeCase(security?.securityName)
}

/**
 * Top Holding Grid
 * @param props
 */
function TopHoldingGrid ({ securityName, security, holdings, limit }) {
  const data = holdings.filter((holding) => !compareSecurities(holding, security))
  data.length = limit
  const currency = getLocalizedCurrency()

  const preparedSecurities = [
    security?.securityName 
      ? security 
      : { securityName }, 
    ...data
  ]

  return (
    <div className='tearsheet_grid'>
      <div className='tearsheet_grid-row tearsheet_grid-row--header'>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--text'>Security</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--narrowest tearsheet_grid-cell--number'>POS</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--narrowest tearsheet_grid-cell--number'>VAL (MM {currency})</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--narrowest tearsheet_grid-cell--number'>CHG</div>
      </div>
      {(holdings?.length ? preparedSecurities : [{}]).map((holding, index) => {
        const { tickerId, securityName, current, marketValue, qtrChange, reportDate } = (holding || {})
        const sameSecurity = compareSecurities(holding, security)
        return (
          <div
            key={tickerId || `managedFund-${index}`}
            className={getClassName('tearsheet_grid-row', [
              { condition: sameSecurity, trueClassName: 'tearsheet_grid-row--first' }
            ])}
          >
            <div className={getClassName('tearsheet_grid-cell tearsheet_grid-cell--text', [
              { condition: sameSecurity, trueClassName: 'tearsheet_grid-cell--bold' }
            ])}
            >
              {defaultIfEmpty(securityName)}
            </div>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--narrowest tearsheet_grid-cell--number'>
              {format(current) || EMPTY_PLACEHOLDER}
            </div>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--narrowest tearsheet_grid-cell--number'>
              {inMillions(marketValue, 2) || EMPTY_PLACEHOLDER}
            </div>
            {isYetToFile(reportDate, qtrChange)
              ? <div className='tearsheet_grid-cell tearsheet_grid-cell--narrowest tearsheet_grid-cell--number'>Yet to File</div>
              : <div className={`tearsheet_grid-cell tearsheet_grid-cell--narrowest tearsheet_grid-cell--number ${getChangeClassName(qtrChange)}`}>
                  {format(qtrChange) || EMPTY_PLACEHOLDER}
                </div>}
          </div>
        )
      })}
    </div>
  )
}

TopHoldingGrid.propTypes = propTypes
TopHoldingGrid.defaultProps = defaultProps

export default memo(TopHoldingGrid)
