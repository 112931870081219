import gql from 'graphql-tag'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'

const GET_DEAL = gql`
  query Deal($id: [String]!) {
    deal(id: $id) {
      items {
        id
        title
        amount
        start {
          date
        }
        end {
          date
        }
        stage
        pipelineConnection {
          id
          index
          label
        }
      }
    }
  }`

export const UPDATE_DEAL = gql`
  mutation Deal(
    $id: String!
    $stage: String!
  ) {
    deal {
      update(
        id: $id
        stage: $stage
      ) {
        count
      }
    }
  }`

/**
 * Deal
 * @param options
 */
export function useDealQuery (options) {
  return useLazyQuery(GET_DEAL, options)
}

/**
 * Deal Update
 * @param options
 */
export function useDealUpdateQuery (options) {
  return useMutation(UPDATE_DEAL, options)
}
