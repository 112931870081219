export const MESSAGE_TYPE = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning'
}

export const MESSAGE = {
  ERROR: 'Oops, something went wrong while trying to send this request. Please try again or contact us if you see this message again.'
}
