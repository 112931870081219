import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import { bindActionCreators } from 'redux'
import {
  fetchConsensusTable,
  fetchBrokerTable,
  updateRestricted,
  createRestricted,
  setBrokerDates,
  fetchConsensusDetails,
  FETCHING
} from '../../../actions/estimates'
import { downloadExportData } from '../../../actions/shared/index'
import { openModal } from '../../../actions'
import { getMetricOption, getPeriodLabel, formatValue } from '../../../utils/estimates.util'
import { _isFinite, format } from '../../../utils/number.util'
import { getFromXigniteToStandard } from '../../../utils/stock/stock.util'
import { getTickers } from '../../../utils'
import { getUserDefaultMetric, isServiceEnabled } from '../../../utils/user.util'
import { DataTable, Spinner, Toolbar, RouteLink, Select } from '../../../components'
import EstimatesBrokerDetailEditModal
  from '../../../components/estimates/brokerDetail/edit/estimatesBrokerDetailEdit.component'
import { THEMES } from '../../../utils/ui'
import './estimatesBrokerDetail.container.css'

class EstimatesBrokerDetail extends Component {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      metrics: [
        { label: 'Earnings Per Share', value: 'eps' },
        { label: 'Sales', value: 'sales' },
        { label: 'EBITDA', value: 'ebitda' },
        { label: 'SG&A', value: 'sga' },
        { label: 'Funds From Operations', value: 'ffo' }
      ],
      dates: [],
      activeDate: null,
      isEditModalOpen: false,
      exporting: false,
      defaultMetric: getUserDefaultMetric(props.profile, props.securityId)
    }
  }

  /**
   * ComponentDidMount
   */
  componentDidMount () {
    this._fetchData()
  }

  /**
   * ComponentDidUpdate
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    const { currentPeer, securityId, profile } = this.props
    const _securityId = (currentPeer && currentPeer._security) || securityId

    if (prevProps.currentPeer && prevProps.currentPeer._security !== _securityId) {
      const defaultMetric = getUserDefaultMetric(profile, _securityId)
      this.setState({ defaultMetric }, () => {
        this._fetchData()
      })

    }
  }

  /**
   * Fetch Broker Detail Data
   * @param metric
   * @private
   */
  _fetchData = (metric) => {
    const { brokerMetric, securityId } = this.props
    const { defaultMetric } = this.state
    const _brokerMetric = metric || brokerMetric || defaultMetric

    this.props.fetchConsensusDetails({
      metric: _brokerMetric,
      securityId
    }).then(() => {
      this.getDatesFromConsensus(() => {
        this.props.fetchBrokerTable(Object.assign({}, this.getBrokerParams(), metric ? { metric } : {}))
      })
    })
  }

  /**
   * Format date options using consensus data
   * @param callback
   */
  getDatesFromConsensus = (callback) => {
    const { selectedDate, dataPoints } = this.props
    let activeIndex = 0

    const dates = (dataPoints || []).map((data, index) => {

      if (selectedDate) {
        if (!activeIndex && selectedDate === moment.utc(data.date).format('YYYY-MM-DD')) {
          activeIndex = index
        }
      } else if (!activeIndex && (data.type && data.type === 'estimate')) {
        activeIndex = index
      }

      return {
        date: moment.utc(data.date).format('YYYY-MM-DD'),
        frequency: data.frequency,
        quarter: data.quarter,
        label: getPeriodLabel(data.date, data.type, data.frequency, data.quarter, true),
        value: getPeriodLabel(data.date, data.type, data.frequency, data.quarter, true)
      }
    })

    if (!dates.length) {
      return
    }

    const _dates = {
      activeDate: dates[activeIndex].date,
      frequency: dates[activeIndex].frequency
    }

    this.props.setBrokerDates(_dates)

    this.setState({
      dates,
      activeDate: dates[activeIndex]
    }, () => {
      callback && callback()
    })
  }

  /**
   * Get Broker Params
   * @returns {{metric: *, id: *, frequency: *, date: *, quarter: *, limit: number}}
   */
  getBrokerParams = () => {
    const { currentPeer, brokerMetric, securityId } = this.props
    const { activeDate, defaultMetric } = this.state
    const _brokerMetric = brokerMetric || defaultMetric
    const _securityId = (currentPeer && currentPeer._security) || securityId

    return {
      metric: _brokerMetric,
      id: _securityId,
      frequency: activeDate && activeDate.frequency,
      date: activeDate && activeDate.date,
      quarter: activeDate && activeDate.quarter,
      limit: 50
    }
  }

  /**
   * Set new active metric and refetch the data
   * @param option
   */
  handleMetricChange = (option) => {
    this._fetchData(option.value)
  }

  /**
   * Set new active date and refetch the data
   * @param option
   */
  handleDateChange = (option) => {
    this.props.setBrokerDates({
      activeDate: option.date,
      frequency: option.frequency
    })

    this.setState({
      activeDate: option
    }, () => {
      this.props.fetchBrokerTable(this.getBrokerParams())
    })
  }

  /**
   * Open Broker Details edit modal
   * @param record
   * @param rowIndex
   */
  openEditModal = (record, rowIndex) => {
    this.setState({
      activeIdx: rowIndex,
      isEditModalOpen: true
    })
  }

  /**
   * Close Broker Details edit modal
   */
  closeEditModal = () => {
    this.setState({
      isEditModalOpen: false
    })
  }

  /**
   * On Broker Details edit save action
   * @param data
   * @returns {*}
   */
  onSave = (data) => {
    const { activeIdx } = this.state
    const { broker } = this.props
    let body = { ...data, edited: true }
    const params = this.getBrokerParams()
    const originalEstimate = { ...broker.estimates[activeIdx] }

    if (((broker && broker.estimates) || []).length > activeIdx && (originalEstimate._id || originalEstimate.broker_id)) {
      body = Object.assign(originalEstimate, body, (!originalEstimate._id ? { _id: '' } : {}))
      return this.props.updateRestricted(body, params)
    } else {
      const original = this.restrictedRow()
      this.props.createRestricted(Object.assign({ original }, original, body), params)
    }
  }

  /**
   * On Export, disable the button until fetch
   */
  onExport = () => {
    const { currentPeer, myOrganization, brokerMetric, peers, securityId, profile } = this.props
    const { activeDate } = this.state
    const _security = (currentPeer && currentPeer._security) || securityId
    const peerObj = [...getTickers(profile), ...peers].find((each) => {
      return each._security === _security
    })

    const activeSymbol = peerObj ? peerObj.symbol : myOrganization.symbol
    const activeExchange = peerObj ? getFromXigniteToStandard(peerObj.exchange) : getFromXigniteToStandard(myOrganization.exchange)

    this.setState({
      exporting: true
    })

    this.props.downloadExportData({
      url: '/estimates/v2/export/broker',
      contentType: 'text/csv',
      params: this.getBrokerParams(),
      file: {
        name: [
          activeSymbol,
          activeExchange,
          brokerMetric,
          'broker_details',
          activeDate.date
        ].join('_'),
        type: 'csv'
      }
    }).then(() => {
      this.setState({
        exporting: false
      })
    })
  }

  // /**
  // * Show Info Modal
  // * @param {string} name
  // * @param {string} status
  // */
  // handleExcludedInfoClick = ({name, status}) => {
  //   const { openModal } = this.props
  //   openModal({
  //     type: modalType.INFO_MODAL,
  //     props: {
  //       content: {
  //         title: 'Why is this Broker Excluded?',
  //         message: status
  //           ? `<p>${status}</p>`
  //           : `<p>${name} no longer covers this security or is inactive.</p>`
  //       }
  //     }
  //   })
  // }

  /**
   * Get label based on quarter date
   * @param date
   * @param quarter
   * @returns {XML}
   */
  getQuarterLabel = (date, quarter) => {
    return (
      <span>{moment.utc(new Date(date)).format('MMM YY')} Q{quarter}</span>
    )
  }

  /**
   * Get annual label based on date
   * @param date
   * @param quarter
   * @returns {XML}
   */
  getAnnualLabel = (date, quarter) => {
    const quartersRemaining = 4 - quarter
    const fiscalYearEndDate = moment.utc(date).add(quartersRemaining * 3, 'month').endOf('month')

    return (
      <span>{moment.utc(fiscalYearEndDate).format('MMM YY')} FY</span>
    )
  }

  /**
   * Get Up / Down direction
   * @param value
   * @param prevValue
   * @returns {*}
   */
  getChangeLabel = (value, prevValue) => {
    let label
    let change = 0

    if (_isFinite(value) && _isFinite(prevValue)) {
      change = value - prevValue
    }

    if (change > 0) {
      label = 'up'
    } else if (change < 0) {
      label = 'down'
    }

    return label
  }

  /**
   * Format Consensus columns
   */
  formatColumns = () => {
    const { activeDate } = this.state
    const { profile } = this.props

    const quarterLabel = activeDate && this.getQuarterLabel(activeDate.date, activeDate.quarter)
    const annualLabel = activeDate && this.getAnnualLabel(activeDate.date, activeDate.quarter)
    const columns = [
      { id: 'edit', label: <i className='q4i-edit-4pt'/>, width: 'icon', textAlign: 'center' },
      { id: 'name', label: 'Broker Name', width: 'auto', truncate: true },
      { id: 'analyst', label: 'Analyst', width: 'wide', truncate: true },
      { id: 'date', label: 'Date', width: 'narrow', textAlign: 'center' },
      { id: 'quarter', label: quarterLabel, width: 'narrow', textAlign: 'right' },
      { id: 'prevQuarter', label: 'prev', width: 'narrow', textAlign: 'right' },
      { id: 'year', label: annualLabel, width: 'narrow', textAlign: 'right' },
      { id: 'prevYear', label: 'prev', width: 'narrow', textAlign: 'right' },
      { id: 'targetPrice', label: 'Target', width: 'narrow', textAlign: 'right' },
      { id: 'rating', label: 'Rating', width: 'narrow', textAlign: 'center' }
    ]

    const services = profile && profile.services
    const isSubscribed = isServiceEnabled('research', services || [])

    if (isSubscribed) {
      columns.splice(2, 0, {
        id: 'research',
        label: (
          <span className='estimates-broker-details_research'>
                        <span>Research</span>
                        <i className='q4i-info-fill'/>
                        <div
                          className='tooltip tooltip--right'>The most recent research published by this Brokerage</div>
                    </span>
        ),
        textAlign: 'center',
        width: 'default'
      })
    }

    return columns
  }

  /**
   * Format Broker Details data
   * @param items
   * @param metric
   */
  formatData = (items, metric) => {
    return (items || []).map((item) => {
      return {
        edit: '',
        edited: item.edited,
        name: item.broker,
        broker: item.broker,
        broker_id: item.broker_id,
        research: item.research,
        analyst: item.analyst,
        analyst_id: item.analyst_id,
        date: item.quarter_est_date && moment.utc(item.quarter_est_date).format('MM/DD/YY'),
        quarter_est_date: item.quarter_est_date && moment.utc(item.quarter_est_date),
        quarter: formatValue(item.quarter_est, metric),
        prevQuarter: formatValue(item.quarter_prev_est, metric),
        year: formatValue(item.year_est, metric),
        prevYear: formatValue(item.year_prev_est, metric),
        targetPrice: format(item.target, 2, 'dash'),
        rating: item.rating,
        year_est: item.year_est || 0,
        quarter_est: item.quarter_est || 0,
        target: item.target || 0,
        excluded: item.excluded,
        status: item.status
      }
    })
  }

  /**
   * Custom table cell render function
   * Used to customize cell rendering for specific cells
   * @param columnId
   * @param data
   * @param rowIndex
   * @param rows
   */
  customCellRender = (columnId, data, rowIndex, rows) => {
    if (!data && columnId !== 'edit') {
      return
    }

    const record = rows[rowIndex]
    let className
    let changeLabel

    switch (columnId) {
      case 'edit':
        const editable = ((record.name === 'Restricted') || (record.analyst === 'Restricted'))
        const _editCls = (record.edited ? '--saved' : (!editable ? '--disabled' : ''))

        return (
          <i
            className={`estimates-broker-details_edit ${_editCls ? `estimates-broker-details_edit${_editCls}` : ''} q4i-edit-4pt`}
            onClick={() => this.openEditModal(record, rowIndex)}/>
        )
      // case 'name':
      //   return record.excluded
      //     ? <>{data} (Excluded) <span className='button--info button--soft-grey'  onClick={() => this.handleExcludedInfoClick(record)} /></>
      //     : data

      case 'research':
        if (data.uri) {
          return (
            <RouteLink className='estimates-broker-details_research-link' to={data.uri} target='_blank'>
              <i className='q4i-reportno-4pt'/>
            </RouteLink>
          )
        } else {
          return '-'
        }
      case 'analyst':
        if (!data || !data.length) {
          return '-'
        }

        return (data !== 'Restricted' && record.analyst_id) ? (
          <RouteLink className='estimates-broker-details_analyst-link'
                     to={`/contact/${record.analyst_id}`}>{data}</RouteLink>
        ) : data.toString()
      case 'quarter':
        changeLabel = this.getChangeLabel(parseFloat(record.quarter), parseFloat(record.prevQuarter))
        className = [
          'estimates-broker-details_value',
          changeLabel ? `estimates-broker-details_value--${changeLabel}` : ''
        ].join(' ').trim()

        return (
          <span className={className}>
                        {changeLabel && <i className={`q4i-arrow-${changeLabel}-4pt`}/>}
            {data.toString()}
                    </span>
        )
      case 'year':
        changeLabel = this.getChangeLabel(parseFloat(record.year), parseFloat(record.prevYear))
        className = [
          'estimates-broker-details_value',
          changeLabel ? `estimates-broker-details_value--${changeLabel}` : ''
        ].join(' ').trim()

        return (
          <span className={className}>
                        {changeLabel && <i className={`q4i-arrow-${changeLabel}-4pt`}/>}
            {data.toString()}
                    </span>
        )
      default:
        return data.toString()
    }
  }

  /**
   * Returns a fake row for restricted analysts
   * @returns {{edit: string, analyst: {name: string, _id: string}, quarter: string, year: string}}
   */
  restrictedRow = () => {
    const { activeDate } = this.state
    const quarter = activeDate && activeDate.quarter
    const quarterDate = activeDate && activeDate.date
    const frequency = activeDate && activeDate.frequency && (activeDate.frequency === 'quarterly' ? 'quarter' : 'year')

    return Object.assign({}, {
      broker: 'Restricted',
      analyst: 'Restricted',
      broker_id: '',
      analyst_id: '',
      quarter_date: '',
      quarter_est: 0.00,
      quarter_est_date: '',
      quarter_prev_est: 0.00,
      year_date: '',
      year_est: 0.00,
      year_est_date: '',
      year_prev_est: 0.00,
      target: 0.00,
      rating: '',
      quarter: quarter,
      estimateType: '',
      edited: false
    }, { [`${frequency}_date`]: quarterDate })
  }

  /**
   * Render
   * @returns {XML}
   */
  render () {
    const { metrics, isEditModalOpen, activeIdx, exporting, activeDate, dates, defaultMetric } = this.state
    const { broker, loading, brokerMetric, consensusDetails, currentPeer, securityId } = this.props
    const _security = (currentPeer && currentPeer._security) || securityId
    const _brokerMetric = brokerMetric || defaultMetric
    const consensusPeer = consensusDetails[_security]
    const _consensusDetails = consensusPeer && consensusPeer[_brokerMetric]

    let columns = this.formatColumns(broker && broker.estimates)
    const estimates = [...((broker && broker.estimates) || [])]

    const frequency = activeDate && activeDate.frequency && (activeDate.frequency === 'quarterly' ? 'quarter' : 'year')
    const quarter = activeDate && activeDate.quarter

    if (estimates.length > 0 && frequency && _consensusDetails && _consensusDetails[frequency] && (quarter === (_consensusDetails.quarter && _consensusDetails.quarter.quarter))) {
      const restrictedCount = (_consensusDetails[frequency].analysts || 0) - estimates.length
      if (restrictedCount > 0) {
        [...Array(restrictedCount).keys()].forEach(() => {
          estimates.push(this.restrictedRow())
        })
      }
    }
    const data = this.formatData(estimates, _brokerMetric)

    const _metric = metrics.find((each) => {
      return each.value === _brokerMetric
    }) || getMetricOption(_brokerMetric)

    const _rowData = { ...data[activeIdx] }

    if (!_rowData.quarter_est_date) {
      _rowData.quarter_est_date = moment.utc()
    }

    // todo: refactor to easily provide better UX with table load
    // Adjust for unavailable data
    if (!data || !data.length) {
      columns = [{ id: 'empty' }]
    }

    return (
      <div className='estimates-broker-details'>
        <div className='estimates-broker-details_list'>
          {!data.length && loading && (
            <Spinner
              theme={'rain'}
              mask={true}
            />
          )}
          <Toolbar theme={THEMES.Q4_BLUE}>
            <div className='estimates_toolbar'>
              <div className='toolbar_group'>
                <Select
                  className='estimates-broker-details_dropdown estimates-broker-details_dropdown--metric'
                  theme={THEMES.INK}
                  size='thin'
                  value={_metric}
                  options={metrics}
                  isDefaultList
                  closeMenuOnSelect
                  onChange={this.handleMetricChange}
                  searchable={false}
                  clearable={false}
                />
                <Select
                  className='estimates-broker-details_dropdown estimates-broker-details_dropdown--date'
                  placeholder='Select Date'
                  theme={THEMES.INK}
                  size='thin'
                  value={activeDate}
                  options={dates}
                  isDefaultList
                  closeMenuOnSelect
                  onChange={this.handleDateChange}
                  searchable={false}
                  clearable={false}
                />
              </div>
              <button
                className={[
                  'button button--ink',
                  exporting ? 'button--loading' : '',
                  !data.length ? 'button--disabled' : ''
                ].join(' ')}
                onClick={this.onExport}>
                <i className='button_icon q4i-download-4pt'/>
                <span className='button_label'>Export</span>
              </button>
            </div>
          </Toolbar>
          <DataTable
            data={data}
            columns={columns}
            noResultsText={loading ? ' ' : 'No data available'}
            fixed={true}
            customCellRender={this.customCellRender}
          />
        </div>

        {isEditModalOpen && (
          <EstimatesBrokerDetailEditModal
            visible={isEditModalOpen}
            data={_rowData}
            onClose={this.closeEditModal}
            onSave={this.onSave}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const profile = state.shared.profile
  const broker = state.estimates.broker
  const brokerMetric = (broker.selected && broker.selected.metric)
  const currentPeer = state.estimates.peers.currentPeer
  const consensusDetails = state.estimates.consensusDetails
  const peers = state.estimates.peers.items
  const myOrganization = peers && peers[0]

  return {
    myOrganization,
    profile,
    brokerMetric,
    consensusDetails,
    currentPeer,
    peers,
    dataPoints: broker.dates,
    selectedDate: broker.selected.activeDate,
    broker: broker.items || [],
    loading: broker.status === FETCHING,
    securityId: props.match.params.securityId
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchConsensusTable: bindActionCreators(fetchConsensusTable, dispatch),
  fetchConsensusDetails: bindActionCreators(fetchConsensusDetails, dispatch),
  fetchBrokerTable: bindActionCreators(fetchBrokerTable, dispatch),
  downloadExportData: bindActionCreators(downloadExportData, dispatch),
  updateRestricted: bindActionCreators(updateRestricted, dispatch),
  createRestricted: bindActionCreators(createRestricted, dispatch),
  setBrokerDates: bindActionCreators(setBrokerDates, dispatch),
  openModal: bindActionCreators(openModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EstimatesBrokerDetail))
