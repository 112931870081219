import React, { memo } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

// components
import { Modal } from '../../index'
import TemplateThumbnail from './thumbnail/thumbnail.component'
import TemplateOption from './option/option.component'
import { THEMES } from '../../../utils'

const propTypes = {
  dataId: PropTypes.string,
  loading: PropTypes.bool,
  entityType: PropTypes.string,
  isItinerary: PropTypes.bool,
  cover: PropTypes.object,
  templates: PropTypes.array.isRequired,
  settings: PropTypes.arrayOf(PropTypes.shape({
    templateId: PropTypes.string,
    output: PropTypes.string,
    isCover: PropTypes.bool,
    title: PropTypes.string,
    note: PropTypes.string,
    itinerary: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired
      }))
    }))
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  onCoverChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

const defaultProps = {
  loading: false,
  settings: [],
  templates: []
}

/**
 * Tearsheet Export Modal
 * @param props
 */
function ExportModal (props) {
  const {
    dataId, loading, entityType, isItinerary, cover, settings, templates,
    onChange, onCoverChange, onExport, rememberSettings, onRememberSettingsChange, onClose
  } = props

  return (
    <Modal
      dataId={dataId}
      visible
      fullscreen
      disableRestoreFocus
      loading={loading}
      badge='q4i-book-2pt'
      title='Choose Template'
      footerButtons={[
        {
          dataId: `${dataId}Remember`,
          type: 'checkbox',
          label: 'Remember Selected Options',
          isChecked: rememberSettings,
          onClick: onRememberSettingsChange
        },
        {
          dataId: `${dataId}Close`,
          label: 'Cancel',
          ui: 'shaded',
          onClick: onClose
        },
        {
          dataId: `${dataId}Export`,
          label: 'Export',
          ui: THEMES.CITRUS,
          onClick: onExport
        }]}
      onClose={onClose}
    >
      <Grid container spacing={3} className='fullscreen-modal-layout tearsheet-export-modal'>
        <Grid item xs={6}>
          <TemplateThumbnail
            dataId={dataId}
            settings={settings}
            templates={templates}
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={6}>
          <TemplateOption
            dataId={dataId}
            entityType={entityType}
            isItinerary={isItinerary}
            cover={cover}
            settings={settings}
            templates={templates}
            onChange={onChange}
            onCoverChange={onCoverChange}
          />
        </Grid>
      </Grid>
    </Modal>
  )
}

ExportModal.propTypes = propTypes
ExportModal.defaultProps = defaultProps

export default memo(ExportModal)
