import { launchDarklyHelper, LD_FEATURE_FLAGS } from "../../services/launchDarkly.service"
import { ALLOWED_CURRENCIES, LOCALIZATION_KEY, DEFAULT_CURRENCY } from "./currencyLocalization.const"

/**
 * Validates if provided currency abb
 * @param {string} currency // currency abbreviation (i.e. EUR)
 * @returns {boolean}
 */
export const isValidLocalizedCurrency = (currency) => {
  return ALLOWED_CURRENCIES.includes(currency)
}

/**
 * Get the localized currency from the user profile based on allowed currencies.
 *
 * @param {Object} profile - The user profile object.
 * @param {string} profile._organization.currency - The organization's preferred currency.
 * @returns {string} The user's preferred currency, or the default currency if the organization's preferred currency is not allowed.
 */
export const getProfileLocalizedCurrency = (profile) => {
  if (
    !profile?._organization?.currency ||
    !launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_LOCALIZED_CURRENCY) // ToDo: FF Dependency. Remove once final CurrencyLocalization release
  ) {
    return DEFAULT_CURRENCY
  }

  const currency = profile._organization.currency

  return ALLOWED_CURRENCIES.includes(currency)
    ? currency
    : DEFAULT_CURRENCY
}

/**
 * Saves the localization data object to local storage.
 *
 * @param {Object} localizationData - The localization data object to save.
 * @param {string} localizationData.currency - The user's preferred currency.
 * @returns {void}
 */
export const setLocalizationLocalStorage = (localizationData) => {
  if (!localizationData) return

  localStorage.setItem(LOCALIZATION_KEY, JSON.stringify(localizationData))
}


/**
 * Retrieves the localization data from local storage.
 *
 * @returns {Object|null} The localization data object, or null if it does not exist in local storage.
 */
export const getLocalizationLocalStorage = () => {
  const localizationData = localStorage.getItem(LOCALIZATION_KEY)
    ? JSON.parse(localStorage.getItem(LOCALIZATION_KEY))
    : null

  return localizationData
}

/**
 * Removes the localization data object from local storage.
 *
 * @returns {void}
 */
export const removeLocalizationLocalStorage = () => {
  localStorage.removeItem(LOCALIZATION_KEY)
}

/**
 * Retrieves the user's preferred currency from local storage.
 *
 * @returns {string|null} The user's preferred currency, or null if it does not exist in local storage.
 */
export const getLocalizedCurrency = () => {
  const localizationData = getLocalizationLocalStorage()

  return localizationData?.currency || null
}

/**
 * Updates the currency in local storage based on user profile data.
 *
 * @param {Object} profile - The user profile object.
 * @param {Object} profile._organization - The user organization object.
 * @param {string} profile._organization.currency - The user's preferred currency.
 * @returns {void}
 */
export const updateCurrencyLocalStorage = (profile) => {
  if (!profile) return

  const localizationData = getLocalizationLocalStorage()

  if (
    profile &&
    localizationData?.currency !== getProfileLocalizedCurrency(profile)
  ) {
    setLocalizationLocalStorage({
      ...localizationData,
      currency: getProfileLocalizedCurrency(profile)
    })
  }
}
