import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'

import CustomCell from './cell/cell.component'
import { AgGrid, NoContentMessage, PopoverMenu, Spinner } from '../../../../index'
import { preventDefaultRowClick, setColumnDefinition, THEMES, getLocalizedCurrency } from '../../../../../utils'

const propTypes = {
  pageSizeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number,
  listOptions: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  onQueryChange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  loading: false,
  data: [],
  total: 0,
  listOptions: {}
}

/**
 * Get sorted field
 * @param field
 * @returns {string|*}
 */
function getSortField (field) {
  switch (field) {
    case 'current':
      return 'fundHoldingCurrentConnection.current'
    case 'reportDate':
      return 'fundHoldingCurrentConnection.reportDate'
    case 'purchasingPower':
      return 'purchasingPowerConnection.purchasingPower'
    case 'aiScore':
      return 'aiScoreConnection.score'
    case 'activity':
      return 'activityConnection.start'
    default:
      return field
  }
}

/**
 * Fund Table Component
 * @param props
 */
function FundTable (props) {
  const { pageSizeId, loading, data, total, listOptions, onQueryChange, history } = props
  const [agGrid, setAgGrid] = useState({})
  const [popoverMenu, setPopoverMenu] = useState(null)

  const columns = [
    {
      field: 'fundName',
      headerName: 'Name',
      type: 'text',
      minWidth: 440,
      pinned: 'left',
      lockPinned: true
    },
    {
      field: 'current',
      headerName: 'Pos',
      type: 'number',
      minWidth: 140,
      maxWidth: 140
    },
    {
      field: 'contact',
      headerName: 'Contacts',
      type: 'text',
      minWidth: 220,
      maxWidth: 220,
      sortable: false
    },
    {
      field: 'style',
      headerName: 'Style',
      type: 'centered',
      minWidth: 140,
      maxWidth: 140
    },
    {
      field: 'turnover',
      headerName: 'Turnover',
      type: 'centered',
      minWidth: 140,
      maxWidth: 140
    },
    {
      field: 'portfolioValue',
      headerName: `AUM (MM ${getLocalizedCurrency()})`,
      type: 'number',
      minWidth: 140,
      maxWidth: 140
    },
    {
      field: 'equityAUM',
      headerName: `EAUM (MM ${getLocalizedCurrency()})`,
      type: 'number',
      minWidth: 140,
      maxWidth: 140
    },
    {
      field: 'reportDate',
      headerName: 'As Of',
      type: 'date',
      minWidth: 120,
      maxWidth: 120
    },
    {
      field: 'aiScore',
      headerName: 'AI',
      type: 'centered',
      minWidth: 80,
      maxWidth: 80
    },
    {
      field: 'purchasingPower',
      headerName: 'Purchasing Power',
      type: 'number',
      minWidth: 174,
      maxWidth: 174
    },
    {
      field: 'qualityRating',
      headerName: 'QR',
      type: 'centered',
      minWidth: 80,
      maxWidth: 80
    },
    {
      field: 'activity',
      headerName: 'Last Activity',
      type: 'date',
      minWidth: 134,
      maxWidth: 134
    }
  ]

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click event
   * @param id {String} - fund id
   */
  const handleRowClick = ({ id }) => {
    id && history.push(`/fund/${id}`)
  }

  /**
   * Handle page change
   * @param selected
   */
  const handlePageChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, page: selected }
    })
  }

  /**
   * Handle page size change
   * @param selected
   */
  const handlePageSizeChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, limit: selected, page: 1 }
    })
  }

  /**
   * Handle Column Sort
   * @param grid
   */
  const handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel && !sortModel.length) {
      return
    }

    onQueryChange({
      listOptions: {
        ...listOptions,
        sortBy: getSortField(sortModel[0].colId),
        sortDir: sortModel[0].sort,
        page: 1
      }
    })
  }

  /**
   * Handle Popover Menu click
   * @param event
   * @param popoverMenu
   */
  const handlePopoverClick = (event, popoverMenu) => {
    event.stopPropagation()
    setPopoverMenu(popoverMenu)
  }

  /**
   * Handle Popover Menu Item Click
   * @param event
   * @param data
   */
  const handlePopoverItemClick = (event, data) => {
    event.stopPropagation()
    const { id } = data || {}
    id && history.push(`/contact/${id}`)
    setPopoverMenu(null)
  }

  /**
   * Handle Popover Menu close
   * @param event
   */
  const handlePopoverClose = (event) => {
    event.stopPropagation()
    setPopoverMenu(null)
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell
      {...props}
      onPopoverClick={handlePopoverClick}
      onPopoverItemClick={handlePopoverItemClick}
    />
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage />
        : <AgGrid
          domLayout='autoHeight'
          sizeToFit

          // suppress configs
          suppressMovableColumns
          suppressContextMenu

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            sortable: true,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={setColumnDefinition({ columns })}
          rowData={data}

          // pagination
          pagination
          paginationProps={{
            pageSizeId,
            forcePage: listOptions.page,
            initialPageSize: listOptions.limit,
            showPageSizeSelection: true,
            total,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: renderCustomCell
          }}

          // event listeners
          onGridReady={handleGridReady}
          onGridSizeChanged={handleGridResize}
          onSortChanged={handleSortChange}
          onRowClicked={preventDefaultRowClick(
            handleRowClick, ['expandable-cell--clickable']
          )}

          isPinned={data.length}
        />}

      {/* Popover Menu for Custom Cell */}
      {popoverMenu && (
        <PopoverMenu
          scrollable
          onClose={handlePopoverClose}
          onClick={handlePopoverItemClick}
          {...popoverMenu}
        />
      )}
    </div>
  )
}

FundTable.propTypes = propTypes
FundTable.defaultProps = defaultProps

export default memo(FundTable)
