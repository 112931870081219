import { combineReducers } from 'redux'
import quote from './stockQuote.reducer'
import volume from './averageVolume.reducer'
import historical from './stockQuoteHistorical.reducer'
import indices from './stockIndex.reducer'
import peerHistorical from './peerStockQuoteHistorical.reducer'

// We combine the reducers here so that they can be left split apart above
const stockReducer = combineReducers({ quote, volume, historical, indices, peerHistorical })

export default stockReducer
