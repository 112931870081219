import {
  FETCH_WIDGET_ESTIMATES_CONSENSUS_REQUEST,
  FETCH_WIDGET_ESTIMATES_CONSENSUS_SUCCESS,
  FETCH_WIDGET_ESTIMATES_CONSENSUS_ERROR,
  SET_WIDGET_CONSENSUS_DATA,
  FAILED,
  FETCHED,
  FETCHING,
  IDLE
} from '../../../actions/widget/estimates'

const initialState = {
  status: IDLE,
  eps: [],
  ffo: [],
  income: [],
  selected: {
    metric: 'eps',
    frequency: 'quarterly'
  }
}

const consensusMetricMap = {
  sales: 'income',
  ebitda: 'income',
  sga: 'income'
}

const consensus = (state = initialState, action) => {
  const metric = consensusMetricMap[state.selected.metric] || state.selected.metric

  switch (action.type) {
    case FETCH_WIDGET_ESTIMATES_CONSENSUS_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_WIDGET_ESTIMATES_CONSENSUS_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        [metric]: action.payload
      }
    case FETCH_WIDGET_ESTIMATES_CONSENSUS_ERROR:
      return {
        ...state,
        status: FAILED,
        error: action.error
      }
    case SET_WIDGET_CONSENSUS_DATA:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload
        }
      }
    default:
      return state
  }
}

export default consensus
