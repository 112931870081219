import React from 'react'

import './error.page.css'

/**
 * Get Icon and Message details based off of error code
 * @param code
 */
const getErrorProps = (code) => {
  switch (code) {
    case '404':
      return {
        icon: 'q4i-nodata-2pt',
        message: 'There is no data for this page.'
      }
    default:
      return {
        icon: 'q4i-nodata-2pt',
        message: 'Oops, something went wrong.'
      }
  }
}

/**
 * Error Page Component
 */
const ErrorPage = ({ match }) => {
  const code = match && match.params && match.params.code
  const { icon, message } = getErrorProps(code)

  return (
    <div className={`error-page error-page--${code || ''}`}>
      <i className={`error-page_badge ${icon}`} />
      <div className='error-page_message'>{message}</div>
    </div>
  )
}

export default ErrorPage
