import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import { Card, EntityOwnership, RenderHTML } from '../../../index'
import { format, getClassName, getAddress, getPhones, defaultIfEmpty, THEMES } from '../../../../utils'
import { config } from '../../../../config'

import './institution.component.scss'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    entityId: PropTypes.string,
    institutionName: PropTypes.string,
    institutionType: PropTypes.string,
    title: PropTypes.string,
    phone: PropTypes.string,
    directPhone: PropTypes.string,
    email: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    city: PropTypes.string,
    stateProvince: PropTypes.string,
    countryName: PropTypes.string,
    postalCode: PropTypes.string,
    functions: PropTypes.array,
    contactPosition: PropTypes.number,
    position: PropTypes.number,
    strategy: PropTypes.string
  }).isRequired,
  subscription: PropTypes.string.isRequired,
  showEntityOwnershipChart: PropTypes.bool
}

const defaultProps = {
  theme: THEMES.DARK,
  isLoading: false,
  data: {}
}

/**
 * Contact Institution Component
 * @param props
 */
function ContactInstitutionComponent (props) {
  const { dataId, theme, isLoading, data, subscription, showEntityOwnershipChart } = props
  const {
    entityId, institutionName, institutionType, title, phone, directPhone, email, functions,
    contactPosition, position, strategy
  } = data

  const address = getAddress(data)
  const phones = getPhones(phone, directPhone)

  const className = getClassName('contact-institution', [
    { condition: theme, trueClassName: `contact-institution--${theme}` }
  ])

  return (
    <Card
      dataId={`${dataId}InstitutionCard`}
      theme={theme}
      className={className}
      title='Institution'
    >
      {/* first row */}
      <Grid container item xs={12} className='contact-institution_header'>

        {/* institution header */}
        <Grid item xs={6} className='card_section card_section--reverse'>
          {entityId
            ? <Link className='card_section-title' to={`/institution/${entityId}`}>
              {defaultIfEmpty(institutionName)}
            </Link>
            : <div className='card_section-title'>{defaultIfEmpty(institutionName)}</div>}
          <div className='card_section-detail'>{defaultIfEmpty(institutionType)}</div>
        </Grid>

        {/* position */}
        <Grid item xs={6}>
          {!isLoading
            ? <Grid container item xs={12} className={`contact-institution_position contact-institution_position--${theme} q4-fade-in`}>
              <div className='contact-institution_position-item' data-id={`${dataId}InstitutionCurrentPosition`}>
                <div className='card_section--reverse card_section--theme'>
                  <div className='card_section-subtitle'>Institution Position</div>
                  <div className='card_section-detail'>{subscription}</div>
                </div>
                <div className='contact-institution_position-value'>{defaultIfEmpty(format(position))}</div>
              </div>
              <div className='contact-institution_position-item' data-id={`${dataId}CurrentPosition`}>
                <div className='card_section--reverse card_section--theme'>
                  <div className='card_section-subtitle'>Contact Position</div>
                  <div className='card_section-detail'>13F</div>
                </div>
                <div className='contact-institution_position-value'>{defaultIfEmpty(format(contactPosition))}</div>
              </div>
            </Grid>
            : null}
        </Grid>
      </Grid>

      {/* second row */}
      <Grid container item xs={12}>

        {/* institution detail */}
        <Grid item xs={5}>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6} className='card_section'>
              <h2 className='card_section-subtitle'>Address</h2>
              <div className='card_section-detail'><RenderHTML html={defaultIfEmpty(address)} /></div>
            </Grid>
            <Grid item xs={6}>
              <div className='card_section'>
                <h2 className='card_section-subtitle'>Corporate Phone(s)</h2>
                <div className='card_section-detail'><RenderHTML html={defaultIfEmpty(phones)} /></div>
              </div>
              <div className='card_section--grouped'>
                <h2 className='card_section-subtitle'>Corporate Email</h2>
                {(email && email.length)
                  ? <a
                    className='card_section-detail'
                    href={`mailto:${email}?Subject=Contact%20from%20Q4%20Desktop&bcc=${config.loggerEmail}`}>{email}</a>
                  : '-'}
              </div>
            </Grid>

            <Grid item xs={6} className='card_section'>
              <h2 className='card_section-subtitle'>Job Title</h2>
              <div className='card_section-detail'>{defaultIfEmpty(title)}</div>
            </Grid>
            <Grid item xs={6} className='card_section'>
              <h2 className='card_section-subtitle'>Function(s)</h2>
              {(functions && Array.isArray(functions) && functions.length)
                ? (functions).map((item, index) => <div
                  key={index}
                  className='card_section-detail card_section-detail-text'>{item}</div>)
                : '-'}
            </Grid>

            <Grid item xs={12} className='card_section'>
              <h2 className='card_section-subtitle'>Coverage/Strategy</h2>
              <div className='card_section-detail card_section-detail-text'>{strategy}</div>
            </Grid>
          </Grid>
        </Grid>

        {/* ownership chart */}
        {showEntityOwnershipChart ?
          <Grid item xs={7}>
            <EntityOwnership
              dataId={dataId}
              entityId={entityId}
              isEntityLoading={isLoading}
              isHeader={false}
              isPeerComponent={false}
            />
          </Grid> : null
        }
      </Grid>
    </Card>
  )
}

ContactInstitutionComponent.propTypes = propTypes
ContactInstitutionComponent.defaultProps = defaultProps

export default memo(ContactInstitutionComponent)
