import _ from 'lodash'
import { DEBOUNCE_DEFAULT_DELAY } from './ui.const'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../services/launchDarkly.service'

/**
 * Return a className string based on array of conditions and results
 * @param array.condition
 * @param array.trueClassName
 * @param array.falseClassName
 * @returns {*}
 */
export const getClassName = (baseClassName, array) => {
  let className = baseClassName || '';

  (array || []).forEach((classItem) => {
    if (classItem.condition && classItem.trueClassName) {
      className += ` ${classItem.trueClassName}`
    }

    if (!classItem.condition && classItem.falseClassName) {
      className += ` ${classItem.falseClassName}`
    }
  })

  return className
}

/**
 * Creates and returns a new debounced version of the provided function
 * @param func
 * @param wait
 * @return {((...args: any[]) => any) & "../index".Cancelable}
 */
export const debounce = (func, wait = DEBOUNCE_DEFAULT_DELAY) => {
  return _.debounce(func, wait)
}

/**
 * Combine strings
 * @param array
 * @param by - join using comma, space etc.
 * @returns {*}
 */
export const combine = (array, by = ', ') => {
  return (array || []).filter((value) => value && value.trim()).join(by)
}

/**
 * Use default value if empty
 * @param value
 * @param placeholder
 * @returns {*|string}
 */
export const defaultIfEmpty = (value, placeholder = '-') => {
  if ((!value && !(value === 0)) || (typeof value === 'string' && !value.length)) {
    return placeholder
  }
  return value
}

/**
 *
 * @param {string} value
 * @param {string} currency
 * @returns  nothing if value is empty, otherwise currency
 */
export const hideCurrencyIfEmpty = (value, currency) => {
  return value === undefined || value === null || value === '' || value === '-'
    ? ''
    : currency
}

/**
* remove all non-numbers from input and passes to the next OnChange event
* @param event
* @param nextOnChange (optional) next function to pass event to
*/
export const allowOnlyIntergersOnChange = (event, nextOnChange) => {
  event.target.value = event.target.value.replace(/[^\d]/g, '')
  if (nextOnChange) { return nextOnChange(event) }
}

/**
 * Prevents onKeyDownEvent for 'number' type input if it contains non-numbers
 * @param event
 * @param nextOnKeyDown (optional) next function to pass event to
 */
export const allowOnlyIntergersOnKeyDown = (event, nextOnKeyDown) => {
  const invalidNumbers = ['e', '.', '+', '-']
  if (invalidNumbers.includes(event.key)) { event.preventDefault() }
  if (nextOnKeyDown) { return nextOnKeyDown(event) }
}

/**
 * Returns the appropriate logo based on the `USE_REBRANDED_UI` feature flag.
 * @function
 * @returns {string} - The name of the logo
 */
export const getQ4LogoIcon = () => {
  const logo = 'q4i-logo'
  const logo_nrhwnjehsd = 'q4i-nrhwnjehsd'

  return !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_REBRANDED_UI)
    ? logo_nrhwnjehsd
    : logo
}

/**
 * Set the favicon links in the HTML document with the specified version
 * based on the `USE_REBRANDED_UI` feature flag.
 * @function
 */
export const setFavicon = () => {
  const sizes = ["96x96", "32x32", "16x16"]
  const publicUrl = process.env.PUBLIC_URL

  const version = !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_REBRANDED_UI)
    ? "nrhwnjehsd"
    : 1

  sizes.forEach((size) => {
    // Find the existing favicon link elements
    const faviconLink = document.querySelector(
      `link[rel="icon"][sizes="${size}"]`
    )

    if (faviconLink) {
      // Update the href attribute with the new favicon path
      faviconLink.href = `${publicUrl}/favicon-${version}-${size}.png`
    }
  })
}
