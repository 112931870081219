import {
  GET_ENTITY_OWNERSHIP_CHART_NEWS_REQUEST,
  GET_ENTITY_OWNERSHIP_CHART_NEWS_SUCCESS,
  GET_ENTITY_OWNERSHIP_CHART_NEWS_FAILURE,
  RESET_ENTITY_OWNERSHIP_CHART,
  statusType
} from '../../../../actions'

const moment = require('moment-timezone')
const initial = {
  data: [],
  status: statusType.IDLE
}

/**
 * Entity Ownership Chart - News Reducer
 */
const news = (state = {}, action) => {
  const { payload } = action

  switch (action.type) {
    case GET_ENTITY_OWNERSHIP_CHART_NEWS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    // News
    case GET_ENTITY_OWNERSHIP_CHART_NEWS_SUCCESS:
      return {
        data: (payload || [])
          .filter((record) => record.date && record.headline)
          .map((record) => ({
            x: moment.utc(record.date).valueOf(),
            title: ' ',
            text: record.headline,
            id: record._id
          }))
          .reverse(),
        status: statusType.SUCCESS
      }

    case GET_ENTITY_OWNERSHIP_CHART_NEWS_FAILURE:
      return {
        status: statusType.ERROR
      }

    case RESET_ENTITY_OWNERSHIP_CHART:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default news
