import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { cloneDeep } from 'lodash'
import { bindActionCreators } from 'redux'
import {
  getDefaultLimitValue,
  getFlattenedData,
  getFormattedFilters,
  getFormattedSort,
  getParsedFilters,
  getFilteredTemplates,
  getEntityQuartersMap,
  getInitialSeries
} from '../../../utils/report'
import { clearPeers, FETCHING, getReportDataTemplates } from '../../../actions/report'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderDarkThumb, renderTrackVertical } from '../../../resources/theme/q4.custom-scrollbar'
import { Modal } from '../../../components'
import { ProgressDots } from '../../../components/shared'
import { ReportCategoryList, ReportTemplateList } from '../../../components/report/wizard'
import { ReportLimit, ReportFilters } from '../../../components/report/config'
import ReportPeerConfig from '../peerConfig/peerConfig.container'
import './reportWizard.container.css'
import { getActiveTicker } from '../../../utils'

/**
 * Report Builder Modal Container
 */
class ReportWizardModal extends Component {

  /**
   * Constructor
   */
  constructor (props) {
    super(props)

    this.state = {
      title: '',

      reportCategories: [{
        label: 'Blank Report',
        value: 'blank',
        icon: 'q4i-report-blank-2pt'
      }],
      dataCategories: [{
        label: 'Ownership',
        value: 'ownership',
        icon: 'q4i-ownership-2pt'
      }, {
        label: 'Pricing',
        value: 'stock',
        icon: 'q4i-stock-2pt'
      }, {
        label: 'Create Source Data',
        value: 'blank',
        icon: 'q4i-report-blank-2pt'
      }],
      selectedCategory: props.type === 'report' ? 'blank' : 'ownership',

      selectedLimit: getDefaultLimitValue(),

      selectedReportTemplate: null,

      stageCount: 3,
      stagePosition: 1
    }

    this.inputReference = React.createRef()
  }

  /**
   * ComponentDidMount
   */
  componentDidMount () {
    this.loadData()
  }

  /**
   * ComponentDidUpdate
   */
  componentDidUpdate (prevProps) {
    const { type } = this.props

    if (type !== prevProps.type) {
      this.setState({
        selectedCategory: type === 'report' ? 'blank' : 'ownership'
      })
    }
  }

  /**
   * Load Data for Container
   */
  loadData = () => {
    const { getReportDataTemplates, subscriptionsService } = this.props
    getReportDataTemplates && getReportDataTemplates().then(() => {
      this.setState({
        filteredTemplates: getFilteredTemplates(subscriptionsService, this.props.reportDataTemplates)
      })
    })
  }

  /**
   * Handle category item onClick event
   * @param category
   */
  handleCategorySelect = (category) => {
    const value = category && category.value

    if (!value) {
      return
    }

    this.setState({
      selectedCategory: category.value
    })
  }

  /**
   * Handle template item onClick event
   * @param template
   */
  handleTemplateSelect = (template) => {
    if (!template) {
      return
    }

    const { selectedReportTemplate } = this.state

    if ((selectedReportTemplate && selectedReportTemplate._id) === template._id) {
      return this.setState({ selectedReportTemplate: null })
    }

    const { _entityType, fields, filters, limit, isLocked } = template
    const _filters = getParsedFilters(fields, filters)
    const _pivotQuarters = getEntityQuartersMap(_filters, _entityType)

    this.setState({
      selectedReportTemplate:  cloneDeep(template),
      selectedLimit: (limit && limit.length && limit[0].value) || getDefaultLimitValue(),
      isLocked,
      _filters,
      _pivotQuarters
    })
  }

  /**
   * Handle input change
   * @param event
   */
  handleInputChange = (event) => {
    const target = event.target
    const name = target.name
    let value = target.value

    this.setState({
      [name]: value
    })
  }

  /**
   * Handle Limit Change from ReportLimit
   * @param value
   */
  handleLimitChange = (value) => {
    this.setState({
      selectedLimit: value
    })
  }

  /**
   * Get prev action for board reports based on stage position
   * @param stagePosition
   */
  getPrevBoardReportAction = (stagePosition) => {
    const { onClose } = this.props

    switch (stagePosition) {
      case 1:
        onClose && onClose()
        break
      case 2:
        this.setState({
          stagePosition: 1,
          selectedReportTemplate: null
        })
        break
      case 3:
        this.setState({
          stagePosition: 2,
          title: ''
        })
        break
      default:
        return
    }
  }

  /**
   * Get prev action for stock reports based on stage position
   * @param stagePosition
   */
  getPrevStockReportAction = (stagePosition) => {
    const { onClose } = this.props

    switch (stagePosition) {
      case 1:
        onClose && onClose()
        break
      case 2:
        this.setState({
          stagePosition: 1,
          selectedReportTemplate: null
        })
        break
      case 3:
        this.setState({
          stagePosition: 2,
        })
        break
      case 4:
        this.setState({
          stagePosition: 3,
          title: ''
        })
        break
      default:
        return
    }
  }

  /**
   * Handle Moving to the previous stage of the ReportWizard
   */
  handlePrevStage = () => {
    const { stagePosition, selectedCategory } = this.state

    switch (selectedCategory) {
      case 'ownership':
        this.getPrevBoardReportAction(stagePosition)
        break
      case 'stock':
        this.getPrevStockReportAction(stagePosition)
        break
      case 'blank':
      default:
        break
    }
  }

  /**
   * Get next action for board reports based on stage position
   * @param stagePosition
   */
  getNextBoardReportAction = (stagePosition) => {
    switch (stagePosition) {
      case 1:
        this.setState({
          stagePosition: 2
        })
        break
      case 2:
        this.setState({
          stagePosition: 3
        }, () => {
          const Input = this.inputReference && this.inputReference.current
          Input && Input.focus()
        })
        break
      case 3:
        this.handleModalClose()
        this.handleReportAdd()
        break
      default:
        return
    }
  }

  /**
   * Get next action for stock reports based on stage position
   * @param stagePosition
   */
  getNextStockReportAction = (stagePosition) => {
    switch (stagePosition) {
      case 1:
        this.setState({
          stagePosition: 2
        })
        break
      case 2:
        this.setState({
          stagePosition: 3
        })
        break
      case 3:
        this.setState({
          stagePosition: 4
        })
        break
      case 4:
        this.handleModalClose()
        this.handleReportAdd()
        break
      default:
        return
    }
  }

  /**
   * Get next action for blank report based on stage position and type
   * @param stagePosition
   */
  getNextBlankReportAction = (stagePosition) => {
    const { reportDataTemplates, type, onCreate } = this.props

    switch (stagePosition) {
      case 1:
        let template

        if (type === 'report') {
          template = {
            title: 'Blank Report'
          }
        } else {
          template = reportDataTemplates.find((reportDataTemplate) => {
            return reportDataTemplate.reportCategories.includes('blank')
          })
        }

        onCreate && onCreate(type, template)
        this.handleModalClose()
        break
      default:
        return
    }
  }

  /**
   * Handle Moving to the next stage of the ReportWizard
   */
  handleNextStage = () => {
    const { clearPeers } = this.props
    const { stagePosition, selectedCategory } = this.state

    switch (selectedCategory) {
      case 'ownership':
        clearPeers()
        this.getNextBoardReportAction(stagePosition)
        break
      case 'stock':
        this.getNextStockReportAction(stagePosition)
        break
      case 'blank':
        clearPeers()
        this.getNextBlankReportAction(stagePosition)
        break
      default:
        break
    }
  }

  /**
   * On Report Builder Filter value change
   * @param filterId
   * @param newState
   */
  handleFilterChange = (filterId, newState) => {
    const { selectedReportTemplate, _filters } = this.state

    const newFilters = _filters.map((filter) => {
      if (filter._id !== filterId) {
        return filter
      }

      return {
        ...filter,
        state: {
          ...filter.state,
          ...newState
        }
      }
    })

    this.setState({
      _filters: newFilters,
      _pivotQuarters: getEntityQuartersMap(newFilters, selectedReportTemplate._entityType)
    })
  }

  /**
   * Handle addition of the new report
   */
  handleReportAdd = () => {
    const { type, peers, ticker, onCreate } = this.props
    const { selectedReportTemplate, title, _filters, _pivotQuarters, selectedLimit } = this.state
    const { _entityType, fields, sort, indices, isPivot, chartOptions, widgetType, showTitle, isLocked, pivotFields } = selectedReportTemplate

    if (chartOptions && chartOptions.series && chartOptions.series.length) {
      chartOptions.series = getInitialSeries({
        ticker,
        series: chartOptions.series,
        peers,
        indices,
        entityType: _entityType && _entityType[0],
        pivotQuarters: _pivotQuarters
      })
    }

    onCreate && onCreate(type, {
      title: title || `${selectedReportTemplate.title} Report`,
      _entityType: getFlattenedData(_entityType),
      fields: getFlattenedData(fields),
      filters: getFormattedFilters(_filters, _pivotQuarters),
      limit: _entityType ? _entityType.map((entityType) => {
        return {
          _entityType: entityType._id,
          value: selectedLimit
        }
      }) : [],
      sort: getFormattedSort(sort, fields, _pivotQuarters),
      indices,
      isPivot,
      pivotFields,
      widgetType,
      showTitle,
      chartOptions,
      isLocked
    })
  }

  /**
   * Handle ReportWizardModal close
   */
  handleModalClose = () => {
    const { type, onClose } = this.props

    this.setState({
      stagePosition: 1,
      selectedCategory: type === 'report' ? 'blank' : 'ownership',
      selectedReportTemplate: null,
      title: '',
      selectedLimit: getDefaultLimitValue()
    })

    onClose && onClose()
  }

  /**
   * Get date filters
   * @param _filters
   * @returns {T[]}
   */
  getDateFilters = (_filters) => {
    return (_filters || []).filter((filter) => {
      const entityType = filter._field && filter._field._entityType && filter._field._entityType.name
      const fieldName = filter._field && filter._field.name

      if (filter.filterType !== 'date') {
        return false
      }

      return !(entityType === 'institutionSurveillance' && fieldName === 'holdings.report_date')
    })
  }

  /**
   * Render Report or Source Data Category Selection Stage
   * @param type
   * @returns {XML}
   */
  renderCategorySelection = (type) => {
    const { reportCategories, dataCategories, selectedCategory } = this.state

    return (
      <div className='report-wizard_stage'>
        <ReportCategoryList
          categories={type === 'report' ? reportCategories : dataCategories}
          selectedCategory={selectedCategory}
          onSelect={this.handleCategorySelect}
        />
      </div>
    )
  }

  /**
   * Render Board Report Selection
   * @returns {XML}
   */
  renderDataSelection = () => {
    const { selectedReportTemplate, filteredTemplates, selectedCategory } = this.state

    return (
      <div className='report-wizard_stage report-wizard_stage--scrolling'>
        <Scrollbars
          className='react-scrollbar'
          autoHide
          hideTracksWhenNotNeeded
          renderThumbVertical={renderDarkThumb}
          renderTrackVertical={renderTrackVertical}>
          <ReportTemplateList
            templates={filteredTemplates}
            selectedTemplate={selectedReportTemplate}
            selectedCategory={selectedCategory}
            onSelect={this.handleTemplateSelect}
          />
        </Scrollbars>
      </div>
    )
  }

  /**
   * Render Peer Configuration
   * @return {*}
   */
  renderPeerConfiguration = () => {
    return (
      <div className='report-wizard_stage report-wizard_stage--scrolling'>
        <Scrollbars
          className='react-scrollbar'
          autoHide
          hideTracksWhenNotNeeded
          renderThumbVertical={renderDarkThumb}
          renderTrackVertical={renderTrackVertical}>
          <ReportPeerConfig />
        </Scrollbars>
      </div>
    )
  }

  /**
   * Render stock configuration pages
   * @return {*[]}
   */
  renderStockConfiguration = () => {
    return (
      <Fragment>
        {this.renderPeerConfiguration()}
        {this.renderDataConfiguration()}
      </Fragment>
    )
  }

  /**
   * Render report configuration by category
   */
  renderConfigurationByCategory = (reportCategory) => {
    switch (reportCategory) {
      case 'stock':
        return this.renderStockConfiguration()
      case 'ownership':
        return this.renderDataConfiguration()
      case 'blank':
      default:
        return null
    }
  }

  /**
   * Render Source Data Configuration
   */
  renderDataConfiguration = () => {
    const { selectedReportTemplate, title, selectedLimit, _filters, isLocked } = this.state

    return (
      <div className='report-wizard_stage'>
        {selectedReportTemplate && (
          <div className='report-wizard_form'>
            <div className='field field--full field--text'>
              <label className='field_label' htmlFor='report-wizard_title'>Report Title</label>
              <input
                ref={this.inputReference}
                className='field_input'
                type='text'
                id='report-wizard_title'
                name='title'
                placeholder={selectedReportTemplate.title}
                maxLength={60}
                value={title}
                onChange={this.handleInputChange}
              />
            </div>

            <ReportLimit
              entityTypeLabel=''
              entityTypeId='wizard'
              initialValue={selectedLimit}
              onChange={this.handleLimitChange}
              isLocked={isLocked}
            />

            {(_filters.length > 0) && (
              <ReportFilters
                isBasic={true}
                filters={this.getDateFilters(_filters)}
                onFilterChange={this.handleFilterChange}
                isLocked={isLocked}
              />
            )}
          </div>
        )}
      </div>
    )
  }

  /**
   * Get header title based on selected report category
   * @param selectedCategory
   * @return {string}
   */
  getCategoryTitleByTemplate = (selectedCategory) => {
    switch (selectedCategory) {
      case 'ownership':
        return 'Board Reports'
      case 'stock':
        return 'Stock Reports'
      default :
        return ''
    }
  }

  /**
   * Get dot count by selected category
   * @param selectedCategory
   * @return {number}
   */
  getDotCountByTemplate = (selectedCategory) => {
    switch (selectedCategory) {
      case 'ownership':
        return 3
      case 'stock':
        return 4
      case 'blank':
        return 1
      default:
        return 0
    }
  }

  /**
   * Render Wizard header by category
   * @param selectedCategory
   * @param typeLabel
   */
  renderHeaderByCategory = (selectedCategory, typeLabel) => {
    switch (selectedCategory) {
      case 'ownership':
        return (
          <Fragment>
            <span>Select a Category</span>
            <span>Select a {typeLabel}</span>
            <span>Configure {typeLabel}</span>
          </Fragment>
        )
      case 'stock':
        return (
          <Fragment>
            <span>Select a Category</span>
            <span>Select a {typeLabel}</span>
            <span>Select Peers</span>
            <span>Configure {typeLabel}</span>
          </Fragment>
        )
      case 'blank':
        return (
          <span>Select a Category</span>
        )
      default:
        return null
    }
  }

  /**
   * Render
   * @return {XML}
   */
  render () {
    const { visible, type, loading } = this.props
    const { stagePosition, selectedCategory, selectedReportTemplate } = this.state

    const typeLabel = type === 'report' ? 'Report' : 'Source Data'

    const nextStageLabel = ((selectedCategory === 'blank')
      || (stagePosition === 4)
      || (stagePosition === 3 && selectedCategory === 'ownership')) ? 'Create' : 'Continue'

    const nextStageDisabled = (
      (stagePosition === 1 && !selectedCategory) || (stagePosition === 2 && !selectedReportTemplate)
    )

    return (
      <Modal
        visible={visible}
        loading={loading}
        containerWidth={450}
        contentPadding='0px'
        footerButtons={[{
          icon: 'q4i-arrow-left-4pt',
          ui: 'dark-slate',
          align: 'left',
          invisible: stagePosition === 1,
          onClick: this.handlePrevStage
        }, {
          label: 'Cancel',
          ui: 'shaded',
          onClick: this.handleModalClose
        }, {
          label: nextStageLabel,
          ui: 'citrus',
          disabled: nextStageDisabled,
          onClick: this.handleNextStage
        }]}
        onClose={this.handleModalClose}>
        <section className='report-wizard'>
          <header className={`report-wizard_header report-wizard_header--${stagePosition}`}>
            <ProgressDots
              dotCount={this.getDotCountByTemplate(selectedCategory)}
              dotPosition={stagePosition} verticalPadding={25}
            />
            <h2 className='report-wizard_header-title'>
              {this.renderHeaderByCategory(selectedCategory, typeLabel)}
            </h2>
            <h3 className='report-wizard_header-details'>
              <span>{(stagePosition === 3 && selectedReportTemplate) ? selectedReportTemplate.title : (stagePosition === 2 && selectedCategory) ? this.getCategoryTitleByTemplate(selectedCategory) : 'Board Reports'}</span>
            </h3>
          </header>
          <div className={`report-wizard_stages report-wizard_stages--${stagePosition}`}>
            {this.renderCategorySelection(type)}
            {this.renderDataSelection()}
            {this.renderConfigurationByCategory(selectedCategory)}
          </div>
        </section>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  const { profile, report } = state
  const { reportDataTemplates, reportPeerConfig } = report
  const services = (profile.data.services || []).reduce((acc, each) => {
    acc[each.type] = each.enabled
    return acc
  }, {})

  return {
    services,
    peers: reportPeerConfig.peers || [],
    ticker: getActiveTicker(profile && profile.data),
    subscriptionsService: (services.surveillance && 'surveillance') || (services.shareholder_id && 'shareholder_id'),
    reportDataTemplates: reportDataTemplates.data,
    loading: reportDataTemplates.status === FETCHING
  }
}

const mapDispatchToProps = (dispatch) => ({
  getReportDataTemplates: bindActionCreators(getReportDataTemplates, dispatch),
  clearPeers: bindActionCreators(clearPeers, dispatch)
})

ReportWizardModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['report', 'data']).isRequired,
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportWizardModal))
