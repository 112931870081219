import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'

import CustomCell from './cell/cell.component'
import { AgGrid, NoContentMessage, Spinner } from '../../../../components'
import { getFromXigniteToStandard, setColumnDefinition, THEMES, preventDefaultRowClick } from '../../../../utils'

import './table.component.scss'

const propTypes = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  listOptions: PropTypes.object.isRequired,
  handleListOptionsChange: PropTypes.func.isRequired
}

const defaultProps = {
  loading: false,
  data: []
}

const columns = [
  {
    field: 'eventDate',
    headerName: 'DATE',
    headerClass: 'ag-header-cell--odd ag-header-cell--first',
    cellStyle: {
      'background-color': '#FFFFFF',
      padding: '0 16px 0 32px',
      'text-align': 'left'
    },
    type: 'date',
    minWidth: 150,
    maxWidth: 250,
    cellRenderer: 'CustomCellRender'
  },
  {
    field: 'companyName',
    headerName: 'SECURITY',
    headerClass: 'ag-header-cell--odd',
    cellStyle: {
      'background-color': '#FFFFFF',
      padding: '0 16px'
    },
    type: 'text',
    valueFormatter: ({ data }) => {
      const { symbol, exchange, companyName } = data
      const formattedSymbol = symbol ? `${symbol} | ` : ''
      const formattedExchange = exchange ? `${getFromXigniteToStandard(exchange)} | ` : ''
      return `${formattedSymbol}${formattedExchange}${companyName}`
    }
  },
  {
    field: 'eventTitle',
    headerName: 'EVENT NAME',
    headerClass: 'col-multiline ag-header-cell--odd',
    cellStyle: {
      'background-color': '#FFFFFF',
      padding: '0 16px'
    },
    type: 'text'
  },
  {
    field: 'eventTypeName',
    headerName: 'EVENT TYPE',
    headerClass: 'col-multiline ag-header-cell--odd',
    cellStyle: {
      'background-color': '#FFFFFF',
      padding: '0 16px'
    },
    minWidth: 90,
    maxWidth: 250,
    type: 'text',
    cellRenderer: 'CustomCellRender'
  },
  {
    field: 'resources',
    headerName: 'RESOURCES',
    headerClass: 'col-multiline ag-header-cell--odd',
    cellStyle: {
      'background-color': '#FFFFFF',
      padding: '0 16px'
    },
    type: 'text',
    minWidth: 90,
    maxWidth: 130,
    cellRenderer: 'CustomCellRender'
  }
]

/**
 * Events & Transcripts Table Component
 * @param props
 */
function EventTranscriptTable(props) {
  const { loading, data, listOptions, handleListOptionsChange, total, onRowClick } = props
  const [agGrid, setAgGrid] = useState({})
  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Get NoContentMessage Props
   * @returns {{*}}
   */
  const getNoContentMessageProps = () => {
    return {
      title: loading ? ' ' : 'There were no Events found for the dates you have selected'
    }
  }

  /**
   * Handle row click event
   * @param {Object} obj
   * @param {string} obj.id
   * @param {string} obj.tickerId
   */
  const handleRowClick = ({ id, tickerId }) => onRowClick({ id, tickerId })

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell {...props} />
  }

  /**
   * Handle Page Change
   * @param selected
   */
  const handlePageChange = ({ selected }) => {
    handleListOptionsChange({
      ...listOptions, page: selected
    })
  }

  return (
    <div className='grid_table event-transcript-grid_table'>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage {...getNoContentMessageProps()} />
        : <AgGrid
          domLayout='autoHeight'
          sizeToFit
          className='event-transcript-grid'
          // suppress configs
          suppressMovableColumns
          suppressContextMenu
          // columns and data
          defaultColDef={{
            suppressMenu: true,
            sortable: false
          }}
          columnDefs={setColumnDefinition({ columns, isLocked: false })}
          rowData={data}
          pagination
          paginationProps={{
            forcePage: listOptions.page,
            initialPageSize: listOptions.limit,
            showPageSizeSelection: false,
            total,
            onPageChange: handlePageChange
          }}
          custom components
          frameworkComponents={{
            CustomCellRender: renderCustomCell
          }}
          // event listeners
          onGridReady={handleGridReady}
          onGridSizeChanged={handleGridResize}
          onRowClicked={preventDefaultRowClick(
            handleRowClick,
            ['resources_content']
          )}
        />}
    </div>
  )
}

EventTranscriptTable.propTypes = propTypes
EventTranscriptTable.defaultProps = defaultProps

export default memo(EventTranscriptTable)
