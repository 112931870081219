import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { capitalize, get } from 'lodash'

// utils
import { THEMES } from '../../../../utils/ui'
import { AGGREGATION_ENUM } from '../../../../utils/report/report.const'
import {
  getDefaultSeries,
  getIsAggregationAvailable,
  getPivotLabel,
  getIdentifierLabel,
  getXAxisFieldLabel
} from '../../../../utils/report'

// components
import Checkbox from '../../../shared/form/checkbox/checkbox.component'
import { Select } from '../../../../components'

import './pieConfig.component.css'

/**
 * ReportPieConfig
 * @param titleConfig
 * @param seriesConfig
 * @param aggregationConfig
 * @param xAxisConfig
 * @constructor
 */
function ReportPieConfig ({ titleConfig, xAxisConfig, aggregationConfig, seriesConfig }) {
  const {
    availableSeriesFields,
    availablePivotValues,
    availablePeers,
    availableIndices,
    series,
    onChange
  } = seriesConfig

  const {
    availableXAxisFields,
    onChange: onXAxisChange,
    xAxis
  } = xAxisConfig

  const seriesPieItem = series[0] || {}
  const showSelectPeerDropdown = (availablePeers && availablePeers.length > 0)
  const showSelectPivotDropdown = (availablePivotValues && availablePivotValues.length > 0)
  const showAggregationConfig = getIsAggregationAvailable(get(xAxisConfig, 'xAxis._field.type'))

  /**
   * Updates a Series / adds a default series if it's empty
   * @param seriesId
   * @param newSeriesItem
   */
  const updateSeriesItem = (seriesId, newSeriesItem) => {
    if (!seriesId && !series.length) {
      return onChange([{ ...getDefaultSeries(), ...newSeriesItem }])
    }

    onChange(series.map((eachSeries) => eachSeries._id === seriesId ? newSeriesItem : eachSeries))
  }

  /**
   * Handles Dropdown Select
   * @param key
   */
  const handleSelect = (key) => (option) => {
    const { value } = option
    const newKey = key === 'peerIndex' ? (value.exchange ? 'peer' : 'index') : key
    const newItem = { ...seriesPieItem }

    if (newKey === 'peer') {
      delete newItem.index
    } else if (newKey === 'index') {
      delete newItem.peer
    }

    return value && updateSeriesItem(seriesPieItem._id, { ...newItem, chartType: 'pie', [newKey]: value })
  }

  /**
   * Returns Formatted Series Field Label
   * @param seriesField
   */
  const getSeriesFieldLabel = (seriesField) => seriesField && seriesField.label

  const selectAxisOptions = [].concat(availableXAxisFields || [])
    .map((option) => {
      return {
        label: getXAxisFieldLabel(option),
        value: option
      }
    })

  const selectPeerOptions = [].concat((availablePeers || []), (availableIndices || []))
    .map((option) => {
      return {
        label: getIdentifierLabel(option),
        value: option
      }
    })

  const selectPivotOptions = [].concat(availablePivotValues || [])
    .map((option) => option && { label: getPivotLabel(option), value: option })

  const selectSeriesOptions = [].concat(availableSeriesFields || [])
    .map((option) => option && { label: getSeriesFieldLabel(option), value: option })

  const selectAggregationOptions = [].concat(AGGREGATION_ENUM || [])
    .map((option) => option && { label: capitalize(option), value: option })

  return (
    <section className='pie-config'>
      <div className='pie-config-item'>
        <Checkbox
          key='report-chart-data-config--title'
          theme={THEMES.WHITE_STEEL}
          label='Show Title'
          name='showTitle'
          id='showTitle'
          isChecked={titleConfig.showTitle}
          onChange={titleConfig.onChange}
        />
      </div>
      <div className='pie-config-item'>
        <div className='pie-config-item_label'>Label</div>
        <Select
          theme={THEMES.WHITE}
          placeholder='Select'
          options={selectAxisOptions}
          noOptionsMessage='No Data Available'
          value={xAxis && xAxis._field && { label: getXAxisFieldLabel(xAxis._field), value: xAxis.field }}
          onChange={(option) => option.value && onXAxisChange && onXAxisChange(option.value)}
          clearable={false}
          searchable={false}
        />
      </div>
      {showAggregationConfig && (
        <div className='pie-config-item'>
          <div className='pie-config-item_label'>Aggregate</div>
          <Select
            theme={THEMES.WHITE}
            maxHeight={220}
            options={selectAggregationOptions}
            noOptionsMessage='No Data Available'
            value={(aggregationConfig.aggregationModel && { label: capitalize(aggregationConfig.aggregationModel), value: aggregationConfig.aggregationModel }) || null}
            onChange={(option) => option.value && aggregationConfig.onChange(option.value)}
            clearable={false}
            searchable={false}
          />
        </div>
      )}
      <div className='pie-config-item pie-config-item--card'>
        <div className='pie-config-item_label'> Value </div>
        <div className='pie-config-item_value'>
          {showSelectPeerDropdown && (
            <Select
              placeholder='Select Peer or Index'
              size='thin'
              options={selectPeerOptions}
              noOptionsMessage='No Data Available'
              value={(seriesPieItem.peer && { label: getIdentifierLabel(seriesPieItem.peer || seriesPieItem.index), value: seriesPieItem }) || null}
              onChange={handleSelect('peerIndex')}
              clearable={false}
              searchable={false}
            />
          )}
          {showSelectPivotDropdown && (
            <Select
              size='thin'
              maxHeight={177}
              placeholder='Select Pivot'
              options={selectPivotOptions}
              noOptionsMessage='No Data Available'
              value={(seriesPieItem.pivotValue && { label: getPivotLabel(seriesPieItem.pivotValue), value: seriesPieItem.pivotValue }) || null}
              onChange={handleSelect('pivotValue')}
              clearable={false}
              searchable={false}
            />
          )}
          <Select
            placeholder='Select Field'
            size='thin'
            maxHeight={177}
            options={selectSeriesOptions}
            noOptionsMessage='No Data Available'
            value={(seriesPieItem._field && { label: getSeriesFieldLabel(seriesPieItem._field), value: seriesPieItem._field }) || null}
            onChange={handleSelect('_field')}
            clearable={false}
            searchable={false}
          />
        </div>
      </div>
    </section>
  )
}

ReportPieConfig.propTypes = {
  titleConfig: PropTypes.shape({
    showTitle: PropTypes.bool,
    onChange: PropTypes.func.isRequired
  }),
  xAxisConfig: PropTypes.shape({
    xAxis: PropTypes.shape({
      _field: PropTypes.object
    }),
    availableXAxisFields: PropTypes.array
  }),
  seriesConfig: PropTypes.shape({
    series: PropTypes.array,
    availablePeers: PropTypes.array,
    availableIndices: PropTypes.array,
    availableSeriesFields: PropTypes.array,
    onChange: PropTypes.func.isRequired
  }),
  aggregationConfig: PropTypes.shape({
    aggregationModel: PropTypes.oneOf(AGGREGATION_ENUM),
    onChange: PropTypes.func.isRequired
  })
}

ReportPieConfig.defaultProps = {
  titleConfig: {},
  xAxisConfig: {},
  seriesConfig: {},
  aggregationConfig: {}
}

export default memo(ReportPieConfig)
