import React, { Component } from 'react'

/**
 * Commentary Component
 */
class Commentary extends Component {
  render () {
    const { commentary } = this.props
    const language = (commentary && commentary.length) ? commentary.replace('/href/g', 'target="_parent" href') : ''

    return (
      <div className='nlg_wrapper' onClick={(e) => this.props.onCommentaryClick && this.props.onCommentaryClick(e)}>
        <div className='nlg_commentary' dangerouslySetInnerHTML={{ __html: language }} />
      </div>
    )
  }
}

export default Commentary
