import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { addQueryParams } from '../../utils/http/http.util'

export const FETCH_CONSENSUS_DETAILS_REQUEST = 'FETCH_CONSENSUS_DETAILS_REQUEST'
export const FETCH_CONSENSUS_DETAILS_SUCCESS = 'FETCH_CONSENSUS_DETAILS_SUCCESS'
export const FETCH_CONSENSUS_DETAILS_ERROR = 'FETCH_CONSENSUS_DETAILS_ERROR'

export const RESET_ESTIMATES = 'RESET_ESTIMATES'

const resources = {
  consensusdetails: '/estimates/v2/consensusdetails'
}

const _fetchConsensusDetails = (url, params) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_CONSENSUS_DETAILS_REQUEST,
      FETCH_CONSENSUS_DETAILS_SUCCESS,
      FETCH_CONSENSUS_DETAILS_ERROR
    ],
    method: METHOD_TYPE.GET,
    payload: params
  }
})

export const resetEstimates = (exclude) => (dispatch) => {
  return dispatch({ type: RESET_ESTIMATES, exclude })
}

export const fetchConsensusDetails = (params) => (dispatch) => {
  const url = addQueryParams(resources.consensusdetails, params)
  return dispatch(_fetchConsensusDetails(url))
}
