import React from 'react'
import PropTypes from 'prop-types'
import { Card, DataTable } from '../../../../../../../components'
import { format, inMillions } from '../../../../../../../utils/number.util'
import { getFundAUM, THEMES } from '../../../../../../../utils'

import './topManagers.component.css'

const propTypes = {
  managers: PropTypes.arrayOf(PropTypes.shape({
      /**
       * Contact's full name
       */
      name: PropTypes.string.isRequired,

      /**
       * Total aum
       */
      currentAum: PropTypes.number,

      /**
       * Total peer holdings values
       */
      currentPeerHoldingsValues: PropTypes.number,

      /**
       * Average peer holdings value
       */
      averageCurrentPeerHoldingsValue: PropTypes.number,

      /**
       * Peer Holdings Change Percent Value
       */
      peerHoldingsChangePercent: PropTypes.number
    }
  )),
  isLoading: PropTypes.bool
}

/**
 * Custom table cell render function
 * Used to customize cell rendering for specific cells
 * @param columnId
 * @param data
 */
const customCellRender = (columnId, data) => {

  if (!data) {
    return
  }

  switch (columnId) {
    case 'currentAum':
      return <span>{getFundAUM(data)}</span>
    case 'currentPeerHoldingsValues':
    case 'averageCurrentPeerHoldingsValue':
      return <span>{inMillions(data, 2)}</span>
    case 'peerHoldingsChangePercent':
      const changeClass = (data === 0) ? 'change' : data < 0 ? 'decrease' : 'increase'
      const changeSign = (data === 0) ? '' : data < 0 ? '-' : '+'
      return <span className={`peer-holdings-percent--${changeClass}`}>{changeSign}{format(data, 2, null, true)}</span>
    default:
      return data.toString()
  }
}

const TopManagersComponent = ({ managers, isLoading, ...props }) => {

  const columns = [
    { id: 'name', label: 'Name', textAlign: 'left', width: 'wider' },
    { id: 'currentAum', label: 'AUM', textAlign: 'right', width: 'narrow' },
    { id: 'currentPeerHoldingsValues', label: 'Peer Holdings ($MM)', textAlign: 'right' },
    { id: 'averageCurrentPeerHoldingsValue', label: 'AVG. Peer Position ($MM)', textAlign: 'right' },
    { id: 'peerHoldingsChangePercent', label: 'Peer Group CHG (%)', textAlign: 'right' }
  ]

  return (
    <Card
      theme={THEMES.LIGHT}
      isLoading={isLoading}
      title='Top Fund Managers'
    >
      <div className='top-managers'>
        <DataTable
          columns={columns}
          data={managers}
          customCellRender={customCellRender}
          fixed={true}
          showPagination={false}
          {...props}
        />
      </div>

    </Card>)
}

TopManagersComponent.propTypes = propTypes

export default TopManagersComponent
