import React from 'react'
import { format, formatDate, getFromXigniteToStandard } from '../../../../../../utils'
import { get } from 'lodash'

const EMPTY_PLACEHOLDER = '-'

/**
 * Title Custom Cell Component
 * @param company
 * @param symbol
 * @param exchange
 * @returns {*}
 * @constructor
 */
const TitleCell = ({ company, symbol, exchange }) => {
  return <div className='cell'>
    <i className='cell_icon cell_icon--security q4i-security-2pt' />
    <div className='cell_content'>
      <div className='cell_content-name'>{company || EMPTY_PLACEHOLDER}</div>
      {symbol
        ? <div className='cell_content-detail'>
          <span className='cell_content-detail--symbol'>{symbol || EMPTY_PLACEHOLDER}</span>
          <span className='cell_content-detail--exchange'>
            {exchange ? getFromXigniteToStandard(exchange) : EMPTY_PLACEHOLDER}
          </span>
        </div>
        : null}
    </div>
  </div>
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 * @param onExpandMenuClick
 */
const CustomCell = ({ data = {}, column = {} }) => {
  const cellData = data[column.colId]
  switch (column.colId) {
    case 'company':
      return <TitleCell {...data} />
    case 'rating':
      return cellData || EMPTY_PLACEHOLDER
    case 'EPS':
    case 'target':
      return cellData ? format(cellData, 2) : EMPTY_PLACEHOLDER
    case 'date':
      const date = get(data, 'research.date')
      return date ? formatDate(date) : EMPTY_PLACEHOLDER
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
