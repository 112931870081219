import React, { PureComponent } from 'react'
import { Select, ComboBox, DateRangePicker } from '../../../../../../components'
import Checkbox from '../../../../../../components/shared/form/checkbox/checkbox.component'
import { THEMES, getActivityFilters } from '../../../../../../utils'

class ActivitySidebarFilter extends PureComponent {
  /**
   * Trigger filter update for My Activity flag change
   * @param isChecked
   */
  onMyActivityChange = (isChecked) => {
    const { onFilterChange } = this.props
    onFilterChange({ myActivity: isChecked, page: 1 })
  }

  /**
   * Trigger filter update for Category change
   * @param option
   */
  onCategoryChange = (option) => {
    const { onFilterChange } = this.props
    onFilterChange({ category: option && option.value, page: 1 })
  }

  /**
   * Trigger filter update for Date Range (start and end) change
   * @param value
   */
  onDateRangeChange = (value) => {
    const { onFilterChange } = this.props

    onFilterChange({
      startDate: Array.isArray(value) ? value[0] : null,
      endDate: Array.isArray(value) ? value[1] : null,
      page: 1
    })
  }

  /**
   * Trigger filter update for corporate participants multi-select dropdown
   * @param participants
   */
  onParticipantChange = (participants) => {
    const { onFilterChange } = this.props
    const values = (participants || []).map((participant) => participant && participant.value)
    onFilterChange({ participants: values, page: 1 })
  }

  /**
   * Trigger filter update for contacts search
   * @param contacts
   */
  onContactChange = (contacts) => {
    const { onFilterChange } = this.props
    onFilterChange({ contacts: contacts, page: 1 })
  }

  /**
   * Trigger filter update for institution search
   * @param institutions
   */
  onInstitutionsChanged = (institutions) => {
    const { onFilterChange } = this.props
    onFilterChange({ institutions: institutions, page: 1 })
  }

  /**
   * Render Activity Filters
   * @returns {XML}
   */
  render () {
    const { counts, filters, participants, contactSelector, institutionSelector } = this.props
    const { myActivity, category, startDate, endDate, participants: selectedParticipants, contacts: selectedContacts, institutions: selectedInstitutions } = filters
    const { getContactSuggestions, contactFilter, contactFilterLoading, resetContactSuggestions } = contactSelector
    const categories = getActivityFilters(counts)

    const selectedParticipant = (participants || [])
      .filter((participant) => selectedParticipants.includes(participant && participant.value))
    const onParticipantRemove = (value) => this.onParticipantChange(selectedParticipant.filter((item) => item && item.value !== value))

    const getContactLabel = selectedContacts && selectedContacts.length ? `Contact (${selectedContacts.length})` : 'Contact'
    const getInstitutionLabel = selectedInstitutions && selectedInstitutions.length ? `Institution (${selectedInstitutions.length})` : 'Institution'

    const handleContactInputChange = (query, event) => {
      if (event.action === 'input-change') {
        getContactSuggestions(query)
      } else if (event.action === 'menu-close') {
        resetContactSuggestions()
      }
    }

    // Filter out any suggested contacts which are already selected and displayed as removable items
    const processContactSuggestions = () => contactFilter.filter(suggested => !selectedContacts.some((selected) => suggested.value == selected.value))

    const onContactAdd = (items) => {
      const newItem = items.filter((contact) => !selectedContacts.includes(contact))
      if (newItem && newItem.length) {
        this.onContactChange([].concat(selectedContacts, newItem))
      }
      resetContactSuggestions()
    }

    const onContactRemove = (value) => this.onContactChange(selectedContacts.filter((item) => item && item.value !== value))

    const handleInstitutionInputChange = (query, event) => {
      if (event.action === 'input-change') {
        institutionSelector.onInputChange(query)
      } else if (event.action === 'menu-close') {
        institutionSelector.resetInstitutionFilter()
      }
    }

    const onInstitutionsAdd = (item) => {
      this.onInstitutionsChanged([].concat(selectedInstitutions, item))
      institutionSelector.resetInstitutionFilter()
    }
    const onInstitutionsRemove = (value) => this.onInstitutionsChanged(selectedInstitutions.filter((item) => item && item.value !== value))
    const institutionOptions = () => {
      return institutionSelector.institutionFilterValues.filter(inst =>
        !selectedInstitutions.some(selectedInst => inst.value == selectedInst.value)
      )
    }

    const MAX_LIST_SIZE = 10
    const isContactSearchDisabled = selectedContacts.length >= MAX_LIST_SIZE
    const isInstitutionSearchDisabled = selectedInstitutions.length >= MAX_LIST_SIZE

    const isContactError = isContactSearchDisabled ?
      {
        isError: true,
        message: "You have reached the maximum. Remove a contact to continue."
      } :
      null
    const isInstitutionError = isInstitutionSearchDisabled ?
      {
        isError: true,
        message: "You have reached the maximum. Remove an institution to continue."
      } :
      null

    return (
      <section className='activity-page_sidebar_filter'>
        <div className='field field--full'>
          <Checkbox
            id='myActivity'
            label='Your Activity'
            theme={THEMES.WHITE_RAIN}
            isChecked={myActivity}
            onChange={this.onMyActivityChange} />
        </div>

        <div className='field field--full field--text'>
          <label className='field_label'>Date Range</label>
          <DateRangePicker
            theme={THEMES.WHITE}
            clearable
            value={[startDate, endDate]}
            onChange={this.onDateRangeChange} />
        </div>

        <div className={`field field--full ${(participants && participants.length) ? 'field--grouped' : ''}`}>
          <Select
            theme={THEMES.WHITE}
            value={categories.find((option) => option && option.value === category)}
            options={categories}
            dataId={{ inputId: "activity-filter-type-input", menuId: "activity-filter-type-menu" }}
            searchable={false}
            clearable={false}
            onChange={this.onCategoryChange} />
        </div>

        {(participants && participants.length) ?
          <div className='field field--full'>
            <ComboBox
              theme={THEMES.WHITE}
              selectProps={{
                placeholder: 'Corporate Participants',
                showInFieldValues: false,
                value: selectedParticipant,
                options: participants,
                isMulti: true,
                isDefaultList: true,
                closeMenuOnSelect: false,
                showDropdownIndicator: true,
                onChange: this.onParticipantChange
              }}
              removableListProps={{
                items: selectedParticipant,
                onRemove: onParticipantRemove
              }}
            />
          </div> : null}

        <div className='field field--full'>
          <ComboBox
            id="contacts"
            theme={THEMES.WHITE}
            selectProps={{
              inputId: 'activity-filter-contact-input',
              label: getContactLabel,
              name: 'name',
              placeholder: 'Search',
              isMulti: true,
              loading: contactFilterLoading,
              options: processContactSuggestions(),
              value: selectedContacts,
              closeMenuOnSelect: true,
              disabled: isContactSearchDisabled,
              error: isContactError,
              onInputChange: handleContactInputChange,
              onChange: onContactAdd
            }}
            removableListProps={{
              items: selectedContacts,
              onRemove: onContactRemove
            }}
          />
        </div>

        <div className='field field--full field--text'>
          <ComboBox
            theme={THEMES.WHITE}
            selectProps={{
              placeholder: 'Search',
              label: getInstitutionLabel,
              disabled: isInstitutionSearchDisabled,
              inputId: 'activity-filter-institution-input',
              dataId: { inputId: "activity-filter-institution-wrapper", menuId: "activity-filter-institution-menu" },
              value: null,
              options: institutionOptions(),
              loading: institutionSelector.loading,
              closeMenuOnSelect: true,
              error: isInstitutionError,
              onInputChange: handleInstitutionInputChange,
              onChange: onInstitutionsAdd
            }}
            removableListProps={{
              items: selectedInstitutions,
              onRemove: onInstitutionsRemove
            }}
          />
        </div>

      </section>
    )
  }
}

export default ActivitySidebarFilter
