import { combineReducers } from 'redux'
import stock from './stock.reducer'
import news from './news.reducer'
import position from './position.reducer'
import events from './events.reducer'
import language from './language.reducer'

export default combineReducers({
  stock,
  news,
  position,
  events,
  language
})
