import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { closeModal, createToast } from '../../actions'
import { Message } from '../../components'
import { THEMES } from '../../utils'

import './emailUndeliveredModal.component.scss'

const propTypes = {
  /**
   * onClose handler from modal provider
   */
  onClose: PropTypes.func,
  /**
   * email addresses of undelivered recipients
   */
  undeliveredRecipients: PropTypes.arrayOf(PropTypes.string)
}

export const MODAL_TITLE = 'We are unable to send to some recipients'
export const MODAL_SUB_HEADING = 'We are unable to send the email to the recipients listed below. You can copy these email addresses and try again later.'

/**
 * Email Undelievered Modal
 * @param {object} props
 * @param {function} props.onClose
 * @param {string[]} props.undeliveredRecipients
 * @returns {JSX.Element}
 */
const EmailUndeliveredModal = (props) => {
  const { onClose, undeliveredRecipients = [] } = props

  const dispatch = useDispatch()

  const handleCopyEmailAddresses = () => {
    navigator?.clipboard?.writeText(undeliveredRecipients.join(', '))
    dispatch(createToast({ text: 'Email Addresses copied successfully.' }))
    dispatch(closeModal())
  }

  const renderModalTitle = () => (
    <div className='undelivered_heading'>
      <i className='undelivered_icon q4i-mail-2pt' />
      <span className='undelivered_title'>{MODAL_TITLE}</span>
      <div className='undelivered_subheading'>{MODAL_SUB_HEADING}</div>
    </div>
  )

  const renderModalMessage = () => (
    <div className='undelivered_addresses'>
      {undeliveredRecipients.join(', ')}
    </div>
  )

  const buttons = [
    {
      ui: THEMES.DARK_SLATE,
      label: 'CLOSE',
      onClick: onClose
    },
    {
      ui: THEMES.CITRUS,
      label: 'COPY EMAIL ADDRESSES',
      onClick: handleCopyEmailAddresses
    }
  ]

  return (
    <Message
      className="modal-root"
      visible
      renderExitButton
      onClose={onClose}
      title={renderModalTitle()}
      message={renderModalMessage()}
      buttons={buttons}
    />
  )
}

EmailUndeliveredModal.propTypes = propTypes

export default memo(EmailUndeliveredModal)
