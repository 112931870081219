import { generate } from 'shortid'
import {
  GET_REPORT_SNAPSHOT_REQUEST,
  GET_REPORT_SNAPSHOT_SUCCESS,
  GET_REPORT_SNAPSHOT_ERROR,
  STORE_REPORT_SNAPSHOT,
  RESET_SNAPSHOT,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../actions'
import { getUnwindData } from '../../utils/report'

const initial = {
  data: [],
  unwoundData: [],
  meta: {
    page: 0,
    total: 0,
    _id: generate()
  },
  status: IDLE,
  timestamp: null
}

function actionIsValid (state, data) {
  return data.timestamp >= state.timestamp
}

const reportSnapshot = (state = initial, action) => {
  switch (action.type) {
    case GET_REPORT_SNAPSHOT_REQUEST:
      state.timestamp = action.timestamp

      // if the snapshot request came from a report builder widget, assign the widget's _id to the snapshot
      return {
        ...state,
        meta: (action.payload && action.payload._id) ? {
          ...state.meta,
          _id: action.payload._id
        } : state.meta,
        status: FETCHING
      }
    case GET_REPORT_SNAPSHOT_SUCCESS:
      // if the snapshot request came from a report builder widget, reset the snapshot
      if (action.meta && action.meta._id) {
        return initial
      }

      if (actionIsValid(state, action)) {
        return {
          ...state,
          data: action.payload,
          unwoundData: getUnwindData(action.payload),
          meta: {
            ...(action.meta || { total: (action.payload || []).length }),
            _id: generate()
          },
          status: FETCHED
        }
      }

      return state
    case GET_REPORT_SNAPSHOT_ERROR:
      return {
        ...state,
        status: FAILED,
        data: [],
        unwoundData: [],
        meta: {
          page: 0,
          total: 0,
          _id: generate()
        }
      }
    case STORE_REPORT_SNAPSHOT:
      return {
        ...state,
        unwoundData: action.payload.unwoundData,
        data: action.payload.data,
        meta: {
          ...(action.payload.meta || { total: (action.payload.data || []).length }),
          _id: generate()
        },
        status: FETCHED
      }
    case RESET_SNAPSHOT:
      return {
        ...state,
        data: [],
        unwoundData: [],
        meta: {
          page: 0,
          total: 0,
          _id: generate()
        },
        status: IDLE
      }
    default:
      return state
  }
}

export default reportSnapshot
