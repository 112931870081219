import {
  FETCH_NEWS_REQUEST,
  FETCH_NEWS_SUCCESS,
  FETCH_NEWS_ERROR,
  RESET_NEWS_STATUS,
  SET_REQUEST_PARAMETERS_IN_NEWS,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../../actions/widget/news/news.actions'

const initial = {
  status: IDLE,
  list: [],
  inWatchlist: false
}

const news = (state = initial, action) => {
  switch (action.type) {
    case FETCH_NEWS_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_NEWS_SUCCESS:
      return {
        ...state,
        list: [...action.payload],
        status: FETCHED
      }
    case FETCH_NEWS_ERROR:
      return {
        ...state,
        error: action.error,
        status: FAILED
      }
    case RESET_NEWS_STATUS:
      return {
        ...state,
        list: [],
        status: IDLE
      }
    case SET_REQUEST_PARAMETERS_IN_NEWS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default news
