import { SENCHA_ROUTE } from '../../utils/routes/'

/**
 * Sencha Route Mapping
 * Maps sencha routes with corresponding react route
 */
const senchaRoutes = [
  { reactPath: SENCHA_ROUTE.ANALYTICS, senchaPath: '#analytics' },
  { reactPath: SENCHA_ROUTE.ANALYTICS_WITH_DAYS, senchaPath: '#analytics/:days' },
  { reactPath: SENCHA_ROUTE.ANALYTICS_WITH_DAYS_AND_ID, senchaPath: '#analytics/:days/:id' },
  { reactPath: SENCHA_ROUTE.CALENDAR, senchaPath: '#calendar' },
  // TODO: /company/* routes to be deprecated after complete migration to new security page
  { reactPath: SENCHA_ROUTE.COMPANY_WITH_ID, senchaPath: '#company/:id' },
  { reactPath: SENCHA_ROUTE.COMPANY_OWNERSHIP_WITH_ID, senchaPath: '#company/:id/ownership' },
  { reactPath: SENCHA_ROUTE.COMPANY_SENTIMENT_WITH_ID, senchaPath: '#company/:id/sentiment' },
  { reactPath: SENCHA_ROUTE.COMPANY_VOLATILITY_WITH_ID, senchaPath: '#company/:id/volatility' },
  { reactPath: SENCHA_ROUTE.COMPANY_ACTIVIST_WITH_ID, senchaPath: '#company/:id/activist' },
  { reactPath: SENCHA_ROUTE.COMPANY_RANGES_WITH_ID, senchaPath: '#company/:id/expected-ranges' },
  { reactPath: SENCHA_ROUTE.COMPANY_PERFORMANCE_WITH_ID, senchaPath: '#company/:id/relative-performance' },
  { reactPath: SENCHA_ROUTE.RESEARCH, senchaPath: '#research' },
  { reactPath: SENCHA_ROUTE.RESEARCH_WITH_ID, senchaPath: '#research/:id' },
  { reactPath: SENCHA_ROUTE.SHAREHOLDERID, senchaPath: '#shareholderid' },
  { reactPath: SENCHA_ROUTE.TEAM, senchaPath: '#team' },
  { reactPath: SENCHA_ROUTE.WEBCAST, senchaPath: '#webcast' },
  { reactPath: SENCHA_ROUTE.WEBCAST_WITH_ID, senchaPath: '#webcast/:id' },

  // sencha forms
  { reactPath: SENCHA_ROUTE.CREATE_DEAL, senchaPath: '#deal/create', fullscreen: true },
  { reactPath: SENCHA_ROUTE.EDIT_DEAL, senchaPath: '#deal/edit', fullscreen: true }
]

export default senchaRoutes