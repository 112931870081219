import React, { memo } from 'react'
import './cell.component.css'
import { StockTrendChart, PricePerformanceChart } from '../../../../../components'
import { inMillions, formatStockPrice, formatCurrency, format } from '../../../../../utils'

const EMPTY_PLACEHOLDER = '-'

/**
 * Title Custom Cell Component
 */
const TitleCell = ({ data }) => {
  const { exchangeName, name, symbol } = (data || {})
  return (
    <div className='cell'>
      <div className='cell_content'>
        <div className='cell_content-detail'>
          <span className='cell_content-detail--symbol'>{symbol || EMPTY_PLACEHOLDER}</span>
          <span className='cell_content-detail--exchange'>{exchangeName || EMPTY_PLACEHOLDER}</span>
        </div>
        <div className='cell_content-name'>{name || EMPTY_PLACEHOLDER}</div>
      </div>
    </div>
  )
}

/**
 * Stock Custom Cell Component
 */
const StockCell = ({ data }) => {
  const { volume, currency, lastCloseChange, last } = (data || {})
  const entityType = (lastCloseChange > 0) ? 'up' : (lastCloseChange < 0) ? 'down' : ''
  const chevronIcon = `q4i-caret-sm-${entityType}-4pt`
  const formattedStockPrice = format(Math.abs(formatStockPrice(last, currency)), 2)
  const formattedLastCloseChange = formatStockPrice(lastCloseChange, currency, true)

  return (
    <div className='cell'>
      {last
        ? (
          <div className='cell_content stacked'>
            <div className='cell_content-name'>{`${formattedStockPrice} ${formatCurrency(currency)}`}</div>
            <div className='cell_content-detail'>
              {entityType && <i className={`cell_content-indicator change-${entityType} ${chevronIcon}`} />}
              <span>{formattedLastCloseChange}</span>
            </div>
            <div className='cell_content-detail'>
              <span>{`${inMillions(volume, 2)}M Vol`}</span>
            </div>
          </div>
          )
        : <div className='cell_content no-data'>{EMPTY_PLACEHOLDER}</div>}
    </div>
  )
}

/**
 * Trend Custom Cell Component
 */
const TrendCell = ({ data, history }) => {
  const { trendData } = (data || {})

  return (
    <div className='cell'>
      {trendData?.length > 1
        ? (
          <div className='cell_content stacked' onClick={() => history?.push(`/security/${data.tickerId}`)}>
            <StockTrendChart stockTrendRecords={trendData} />
          </div>
          )
        : <div className='cell_content no-data'>{EMPTY_PLACEHOLDER}</div>}
    </div>
  )
}

/**
 * Performance Custom Cell Component
 */
const PerformanceCell = ({ data, history }) => {
  const { expectedReturn, returnOnDate: actualPerformance, stockSpecificReturn } = (data || {})
  const stockPerformance = stockSpecificReturn + expectedReturn
  const performanceData = [actualPerformance, stockPerformance]

  return (
    <div className='cell'>
      {actualPerformance && stockPerformance
        ? (
          <div className='cell_content stacked' onClick={() => history?.push(`/security/${data.tickerId}`)}>
            <PricePerformanceChart performanceData={performanceData} />
          </div>
          )
        : <div className='cell_content no-data'>{EMPTY_PLACEHOLDER}</div>}
    </div>
  )
}

/**
 * AgGrid Custom Cell Render Component
 * @param props
 */
const CustomCell = ({ dataId, data = {}, column = {}, history }) => {
  switch (column.colId) {
    case 'security':
      return <TitleCell dataId={dataId} data={data} history={history} />
    case 'stock':
      return <StockCell dataId={dataId} data={data} history={history} />
    case 'trend':
      return <TrendCell dataId={dataId} data={data} history={history} />
    case 'performance':
      return <PerformanceCell dataId={dataId} data={data} history={history} />
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default memo(CustomCell)
