import {
  GET_CONTACT_POSITION_REQUEST,
  GET_CONTACT_POSITION_SUCCESS,
  GET_CONTACT_POSITION_FAILURE,
  GET_CONTACT_INSTITUTION_POSITION_REQUEST,
  GET_CONTACT_INSTITUTION_POSITION_SUCCESS,
  GET_CONTACT_INSTITUTION_POSITION_FAILURE,
  RESET_CONTACT_POSITION,
  statusType
} from '../../../actions'

const initial = {
  data: null,
  status: statusType.IDLE
}

const position = (state = initial, action) => {
  const { payload, request } = action

  switch (action.type) {
    case GET_CONTACT_INSTITUTION_POSITION_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_CONTACT_INSTITUTION_POSITION_SUCCESS:
      const { factsetEntityId } = request
      return {
        ...state,
        [factsetEntityId]: payload.current,
        status: statusType.SUCCESS
      }

    case GET_CONTACT_INSTITUTION_POSITION_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case GET_CONTACT_POSITION_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_CONTACT_POSITION_SUCCESS:
      return {
        ...state,
        data: payload.current,
        status: statusType.SUCCESS
      }

    case GET_CONTACT_POSITION_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case RESET_CONTACT_POSITION:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default position
