import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Select } from '../../../../../components'
import { getPivotLabel } from '../../../../../utils/report'

/**
 * Report Builder Pivot Filter Component
 */
class PivotFilter extends PureComponent {

  /**
   * ComponentDidUpdate
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    const { filter, pivotQuarters, selectedPivotQuarter, onChange } = this.props
    const newEntityType = filter._field._entityType.name

    if (!pivotQuarters[newEntityType] || !pivotQuarters[newEntityType].length) {
      return onChange && onChange(null)
    }

    const isQuartersChanged = this.getIsQuartersChanged(newEntityType, prevProps.pivotQuarters, pivotQuarters)

    // if the quarters have been updated, and the old value is unavailable, switch to a new option
    if (isQuartersChanged && pivotQuarters[newEntityType].indexOf(selectedPivotQuarter) === -1) {
      onChange && onChange(pivotQuarters[newEntityType][0] || null)
    }
  }

  /**
   * Check if pivot quarters for current filter were changed
   * @param newEntityType
   * @param prevPivotQuarters
   * @param newPivotQuarters
   * @return {boolean}
   */
  getIsQuartersChanged = (newEntityType, prevPivotQuarters, newPivotQuarters) => {
    return (
      !newPivotQuarters[newEntityType] ||
      !prevPivotQuarters[newEntityType] ||
      !newPivotQuarters[newEntityType].length ||
      (newPivotQuarters[newEntityType].length !== prevPivotQuarters[newEntityType].length)
    )
  }

  /**
   * Render dropdown items with proper format
   * @param option
   */
  getFormattedPivotQuarterLabel = (option) => {
    return getPivotLabel(option)
  }

  render () {
    const { filter, pivotQuarters, selectedPivotQuarter, onChange } = this.props
    const entityType = filter._field._entityType.name
    const _pivotQuarters = pivotQuarters[entityType]
    const placeholder = _pivotQuarters && _pivotQuarters.length ? 'Select quarter' : 'No quarters available'

    return (
      <div className='field field--full field--dropdown'>
        <label className='field_label'>Quarter</label>
        <Select
          placeholder={placeholder}
          options={(_pivotQuarters || []).map((pivot) => pivot && { label: getPivotLabel(pivot), value: pivot })}
          value={{ label: getPivotLabel(selectedPivotQuarter), value: selectedPivotQuarter }}
          onChange={(option) => option.value && onChange(option.value)}
          clearable={false}
          searchable={false}
        />
      </div>
    )
  }
}

PivotFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  pivotQuarters: PropTypes.object.isRequired,
  selectedPivotQuarter: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default PivotFilter
