import React, { memo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// utils
import { DEFAULT_DATE_FORMAT, formatLocalizedDate, getFromXigniteToStandard, getQuarterEndDates } from '../../../../../../utils'
import { get } from 'lodash'

const propTypes = {
  position: PropTypes.object,
  ticker: PropTypes.object.isRequired,
  quarters: PropTypes.number.isRequired
}

const defaultProps = {
  position: {},
  ticker: {},
  quarters: 20
}

/**
 * Get mapped historical position
 * @param position
 * @param quarters
 */
function getPosition (position, quarters) {
  const dates = getQuarterEndDates(quarters, moment.utc())

  return (dates || []).map((date, idx) => ({
    date,
    position: position[`q${idx + 1}Value`]
  }))
}

/**
 * Ownership Language Component
 * @param props
 */
function OwnershipLanguage (props) {
  const { position, ticker, quarters } = props
  const { symbol, exchange } = ticker

  const { fundName, institutionName } = (position || {})
  const entityName = (fundName || institutionName)

  const historical = getPosition(position, quarters)

  const current = (historical || []).find((item) => item.position) || {}
  const largest = (historical || []).reduce((max, item) => item.position > max.position ? item : max, current)
  const smallest = (historical || []).reduce((min, item) => item.position < min.position ? item : min, current)

  return (
    <div className='tearsheet_section-subtitle'>
      {get(current, 'date') && get(current, 'position', null)
        ? <>
          <span className='q4-blue'>{props.entityName || entityName}</span> held their greatest position in
          <span className='q4-blue'> {`${symbol}:${getFromXigniteToStandard(exchange)}`}</span> in
          <span className='q4-blue'> {formatLocalizedDate(get(largest, 'date'), DEFAULT_DATE_FORMAT)}</span> and smallest in
          <span className='q4-blue'> {formatLocalizedDate(get(smallest, 'date'), DEFAULT_DATE_FORMAT)}</span> within the past 5 years.
        </>
        : <>
          <span className='q4-blue'>{props.entityName || entityName}</span> has no position in
          <span className='q4-blue'> {`${symbol}:${getFromXigniteToStandard(exchange)}`}.</span>
        </>
      }
    </div>
  )
}

OwnershipLanguage.propTypes = propTypes
OwnershipLanguage.defaultProps = defaultProps

export default memo(OwnershipLanguage)
