import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LEVEL } from '../../../../../../src/hook'

// actions
import { useSurveillanceHolderQuery, GET_FUND_CURRENT_HOLDERS, GET_INST_CURRENT_HOLDERS_EXPORT } from '../../../hook'
import { openModal, modalType } from '../../../../../actions'
import { useExport } from '../../../../../services/csvExport/csvExport.service'

// components
import SurveillanceHolderSidebar from './sidebar/sidebar.component'
import SurveillanceHolderToolbar from './toolbar/toolbar.component'
import SurveillanceHolderTable from './table/table.component'

// utils
import {
  getPageSizeFromStorage, ENTITY_TYPE, OWNERSHIP_TYPE, formatDate,
  getCurrentOwnershipDate, format, EXPORT_DATE_FORMAT,
  HOLDER_TYPE,
  getLabelByValue
} from '../../../../../utils'
import { get, isNil, omitBy, pick } from 'lodash'

const { FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string,
  toolbarTheme: PropTypes.string,
  toolTheme: PropTypes.string,
  tickerId: PropTypes.string.isRequired,
  history: PropTypes.object,
  persistence: PropTypes.object,
}

const defaultProps = {}

const PAGE_SIZE_ID = 'surveillance-grid'

const ZERO = '0.00'

/**
 * Surveillance Holder Grid Component
 * @param props
 */
function SurveillanceHolderGrid (props) {
  const { dataIdPrefix, toolbarTheme, toolTheme, tickerId, history, openModal, persistence } = props

  const [isSidebar, setIsSidebar] = useState(!!persistence.getGridFiltersDecoded() || false)
  const [holderType, setHolderType] = useState(persistence.getGridWithDefault(LEVEL.GRID_SUBTAB, INSTITUTION))

  const entityType = (holderType === FUND) ? FUND : INSTITUTION
  const [state, setState] = useState(persistence.getGridFiltersDecoded({
    search: null,
    filter: {
      isActivist: false,
      position: null,
      style: null,
      turnover: null,
      type: null
    },
    listOptions: {
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
  }));
  const { search, filter, listOptions } = state
  const { isActivist, position } = (filter || {})
  const variables = omitBy({
    tickerId,
    source: OWNERSHIP_TYPE.SURVEILLANCE,
    search,
    position,
    isActivist: (holderType === INSTITUTION) ? isActivist : null,
    ...pick(filter, ['style', 'turnover', 'type']),
    ...listOptions
  }, isNil)

  const { data, loading, client } = useSurveillanceHolderQuery(entityType, { variables })
  const { generateExport, exporting } = useExport({
    onError: () => {
      openModal({
        type: modalType.ERROR_MODAL
      })
    }
  })

  const source = (entityType === FUND) ? FUND : 'inst'
  const holders = get(data, `${source}HoldingCurrent.items`, [])
  const total = get(data, `${source}HoldingCurrent.count`, 0)

  /**
   * Handle query change
   * @param query
   */
  const handleQueryChange = (query = {}) => {
    const options = query.listOptions ? { ...query } : { ...query, listOptions: { ...listOptions, page: 1 } }
    setState({ ...state, ...options })
    persistence.setGridFilters({ ...state, ...options })
  }

  /**
   * Format mapper for CSV export
   * @param holder
   */
  const holdersCSVMapper = (holder) => {
    const dynamicKeys = {
      name: `${entityType}Name`,
      style: `${entityType}Style`,
      turnover: `${entityType}Turnover`,
      type: `${entityType}Type`,
      equityAUM: `${entityType}EquityAUM`,
      qualityRating: `${entityType}QualityRating`,
      AUM: (entityType === FUND) ? 'fundPortfolioValue' : 'institutionTotalAUM'
    }

    const {
      current,
      change,
      previousQtrPosition,
      marketValue,
      marketValueChange,
      percentTSO,
      percentPortfolio,
      reportDate
    } = holder

    return {
      'Holder Name': get(holder, dynamicKeys.name),
      [formatDate(getCurrentOwnershipDate(), EXPORT_DATE_FORMAT, true, true)]: current,
      'Change': change,
      'Position': previousQtrPosition,
      'Market Value': marketValue,
      'Market Value Change': marketValueChange,
      '%OS': percentTSO ? format(percentTSO, 2) : ZERO,
      '%PORT': percentPortfolio ? format(percentPortfolio, 2) : ZERO,
      'Style': get(holder, dynamicKeys.style),
      'Turnover': get(holder, dynamicKeys.turnover),
      'Type': getLabelByValue(HOLDER_TYPE, get(holder, dynamicKeys.type)),
      'AUM': get(holder, dynamicKeys.AUM),
      'Equity AUM': get(holder, dynamicKeys.equityAUM),
      'As Of': reportDate ? formatDate(reportDate, EXPORT_DATE_FORMAT, true, true) : null,
      'QR': get(holder, dynamicKeys.qualityRating)
    }
  }

  /**
   * Handle export
   */
  const handleExport = () => {
    const params = {
      client,
      variables: { ...variables, limit: 0 },
      query: (entityType === FUND) ? GET_FUND_CURRENT_HOLDERS : GET_INST_CURRENT_HOLDERS_EXPORT,
      dataPath: `data.${source}HoldingCurrent.items`,
      fileName: `${source}_surveillance_holders.csv`,
      formatter: holdersCSVMapper
    }

    generateExport(params)
  }

  /**
   * handle set holder type
   * @param {string} value
   */
  const handleSetHolderType = (value) => {
    setHolderType(value)
    persistence.setGridSubTab(value)
    handleQueryChange({ listOptions: { page: 1, limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10 } })
  }

  return (
    <div className='surveillance-holder-grid grid--sidebar'>
      <SurveillanceHolderSidebar
        dataId={`${dataIdPrefix}SurveillanceHolders`}
        isSidebar={isSidebar}
        filter={{ ...filter }}
        onQueryChange={handleQueryChange}
        holderType={holderType}
      />
      <div className='grid_body'>
        <SurveillanceHolderToolbar
          dataId={`${dataIdPrefix}SurveillanceHolders`}
          toolbarTheme={toolbarTheme}
          toolTheme={toolTheme}
          holderType={holderType}
          search={search}
          noData={!(holders || []).length}
          onSidebarToggle={() => setIsSidebar(!isSidebar)}
          collapsed={isSidebar}
          onHolderChange={(holderType) => handleSetHolderType(holderType)}
          onQueryChange={handleQueryChange}
          onExport={handleExport}
          exporting={exporting}
        />
        <SurveillanceHolderTable
          pageSizeId={PAGE_SIZE_ID}
          loading={loading}
          data={holders}
          total={total}
          listOptions={listOptions}
          onQueryChange={handleQueryChange}
          holderType={holderType}
          history={history}
        />
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

SurveillanceHolderGrid.propTypes = propTypes
SurveillanceHolderGrid.defaultProps = defaultProps

export default withRouter(connect(mapDispatchToProps)(SurveillanceHolderGrid))
