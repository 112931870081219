import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Checkbox} from '../../../../../../../components/shared';
import {convertStringToBoolean, THEMES} from '../../../../../../../utils';

/**
 * Exclude Activist Filter
 */
class ExcludeActivist extends PureComponent {

    /**
     * Handle Change
     * @param value
     */
    handleChange = (value) => {
        const {onChange} = this.props;
        onChange(value);
    };

    /**
     * Render
     * @return {*}
     */
    render() {
        const {value} = this.props;

        return (
            <div className='field field--full field--grouped'>
                <Checkbox
                    id='excludeActivists'
                    theme={THEMES.WHITE_RAIN}
                    isChecked={convertStringToBoolean(value)}
                    label='Exclude Activists'
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

ExcludeActivist.propTypes = {
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    onChange: PropTypes.func.isRequired
};

export default ExcludeActivist;