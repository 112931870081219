import { capitalize, get, set, uniqBy, isUndefined, isNull, memoize } from 'lodash'
import { compose } from 'lodash/fp'
import moment from 'moment-timezone'
import shortid from 'shortid'
import { format } from '../number.util'
import { formatDate } from '../date.util'
import { convertToChartArray, getMultiSeriesTrendLine, getRelativeValues } from '../chart.util'
import {
  rain,
  darkTeal,
  mustard,
  olympic,
  amber,
  apple,
  eggplant,
  lime,
  ice,
  walnut,
  lightSlate
} from '../../resources/materialui-overrides/colors'
import {
  getIsAggregationAvailable,
  widgetTitleHeight,
  getOverwrittenData,
  getCompleteSeries,
  getSeriesLabel,
  stockEntityType
} from '../report'
import { parseStockIdentifier, isStockIdentifierEqual } from '../stock/stock.util'

/**
 * Default chart widget font style
 * @type {{fontFamily: string, color: string, fontWeight: number}}
 */
const chartFontStyle = {
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 400,
  color: lightSlate
}

/**
 * Returns end of quarter in UTC format.
 * The function is memoized to prevent extra computations
 */
const getEndOfQuarterUTCMemoized = memoize((date) => {
  return date && moment.utc(date, moment.ISO_8601).endOf('quarter').format('YYYYMMDD')
})

/**
 * Get default Series properties
 * @returns
 */
export const getDefaultSeries = () => {
  return {
    _id: shortid.generate(),
    chartType: null,
    color: null,
    dataLabelsColor: [],
    _field: null,
    peer: null,
    index: null,
    pivotValue: null,
    yAxis: 'left'
  }
}

/**
 * Get min/floor and max/ceiling for highcharts yAxis config
 * @param floor
 * @param ceiling
 * @returns {{min: (null|number), max: (null|number)}}
 * @private
 */
const _getMinMax = (floor, ceiling) => {
  const min = isNaN(parseFloat(floor)) ? null : parseFloat(floor)
  let max = isNaN(parseFloat(ceiling)) ? null : parseFloat(ceiling)

  if ((typeof min === 'number' && typeof max === 'number') && (min > max || max < min)) {
    max = min
  }

  return {
    min,
    max
  }
}

/**
 * Get formatted value based off of the type of value
 * @param type
 * @param value
 */
const _getFormattedValue = (type, value) => {
  let formattedValue

  switch (type) {
    case 'boolean':
      formattedValue = value

      if (typeof value === 'boolean') {
        formattedValue = value ? 'Yes' : 'No'
      }
      break
    case 'number':
      formattedValue = Math.abs((Number(value) === value && value % 1 === 0) ? format(value, 0) : format(value, 2))
      break
    case 'date':
      const isUTC = true
      const dateFormat = 'MM/DD/YY'

      formattedValue = value ? formatDate(value, dateFormat, isUTC) : ''
      break
    default:
      formattedValue = value
  }

  return (isUndefined(formattedValue) || isNull(formattedValue)) ? '' : formattedValue
}

/**
 * Extract row data based on field name
 * @param data
 * @param field
 * @param defaultValue
 * @returns {number[]}
 * @private
 */
const _extractDataValues = (data, field, defaultValue) => {
  return (data || []).map((row) => {
    return get(row, field && field.name, defaultValue)
  })
}

/**
 * Get converted stock data
 * @param data
 * @param seriesField
 * @param xAxisField
 * @param shouldRenderAbsoluteValues
 * @return {*}
 * @private
 */
const _getStockData = (data, seriesField, xAxisField, shouldRenderAbsoluteValues) => {
  if (!seriesField || !xAxisField) {
    return []
  }

  switch (xAxisField.type) {
    case 'date':
      return convertToChartArray(data, xAxisField.name, seriesField.name).map((historicalDataPoint) => {
        const dataPointDate = historicalDataPoint && historicalDataPoint[0]
        const dataPointValue = historicalDataPoint && historicalDataPoint[1]

        return [
          dataPointDate,
          shouldRenderAbsoluteValues ? Math.abs(dataPointValue || 0) : dataPointValue
        ]
      })
    default:
      return _extractDataValues(data, seriesField, 0)
  }
}

/**
 * Get highcharts categories
 * @param data
 * @param field
 * @returns {*[]}
 */
const _getCategories = (data, field) => {
  if (!field) {
    return []
  }

  const uniqueData = field.type === 'boolean'
    ? uniqBy(data, (row) => _getFormattedValue('boolean', get(row, field.name))).filter((row) => _getFormattedValue('boolean', get(row, field.name)))
    : uniqBy(data, (row) => get(row, field.name)).filter((row) => get(row, field.name))

  return (uniqueData || [])
    .map((row) => {
      const { type, name, _entityType } = field
      const defaultValue = _entityType ? `Unnamed ${capitalize(_entityType.label)}` : 'Category'

      return field ? _getFormattedValue(type, get(row, name, defaultValue)) : null
    })
    .filter((category) => {
      return category
    })
}

/**
 * Get formatted xAxis as highcharts would expect it
 * @param chartOptions
 * @param data
 * @return {*}
 * @private
 */
const _getXAxis = (chartOptions, data) => {
  const { xAxis } = chartOptions || {}
  const xAxisField = xAxis && xAxis._field
  const xAxisEntityType = xAxisField && xAxisField._entityType

  if (!xAxisEntityType) {
    return {}
  }

  const defaultXAxis = {
    title: {
      text: null
    },
    labels: {
      style: chartFontStyle
    }
  }

  if (xAxisEntityType.name === stockEntityType) {
    return {
      ...defaultXAxis,
      lineWidth: 0,
      tickLength: 0,
      type: 'datetime',
      dateTimeLabelFormats: {
        millisecond: '%m/%d',
        second: '%m/%d',
        minute: '%m/%d',
        hour: '%m/%d',
        day: '%m/%d',
        week: '%m/%d',
        month: '%m/%d',
        year: '%m/%d'
      }
    }
  }

  return {
    ...defaultXAxis,
    categories: _getCategories(data, xAxisField)
  }
}

/**
 * Get formatted yAxis as highcharts would expect it
 * @param chartOptions
 * @private
 */
const _getYAxis = (chartOptions) => {
  const { chartType, yAxis, series } = chartOptions
  return (yAxis || []).map((axis) => {
    const { id, label, floor, ceiling, shouldRenderRelativeValues } = axis
    const matchingSeriesItem = (series || []).find((seriesItem) => seriesItem.yAxis === id)
    const { min, max } = _getMinMax(floor, ceiling)
    const text = label || (matchingSeriesItem && matchingSeriesItem._field && matchingSeriesItem._field.label) || null
    const formattedText = (text && shouldRenderRelativeValues)
      ? `${text} %`
      : text

    return {
      id,
      opposite: id === 'right',
      title: {
        text: formattedText,
        ...(chartType === 'bar' ? {} : {
          align: 'high',
          rotation: 0,
          y: -20,
          x: 0,
          reserveSpace: false,
          textAlign: id,
          style: {
            width: '200%'
          }
        })
      },
      maxPadding: 0,
      minPadding: 0,
      floor: min,
      min,
      ceiling: max,
      max,
      endOnTick: (!min && !max) || shouldRenderRelativeValues,
      gridLineWidth: id === 'right' ? 0 : 1
    }
  })
}

/**
 * Get formatted series as highcharts would expect it
 * @param chartOptions
 * @param data
 * @returns {*[]}
 */
const _getSeries = (chartOptions, data) => {
  const { series } = chartOptions || {}
  const parentChartType = chartOptions.chartType
  const completeSeries = parentChartType === 'pie'
    ? getCompleteSeries(series.slice(0, 1))
    : getCompleteSeries(series)

  return (completeSeries || []).map((seriesItem) => {
    const { color, _field: seriesField } = seriesItem
    const chartType = parentChartType === 'combo' ? seriesItem.chartType : parentChartType
    let seriesData = getHighChartsDataPerSeries({ reportSnapshot: data, seriesItem, chartOptions })

    // apply colors to pie chart data labels
    if (chartType === 'pie') {
      const dataLabelsColorMap = (seriesItem.dataLabelsColor || []).reduce((acc, curr) => {
        acc[curr.label] = curr.color
        return acc
      }, {})

      seriesData = seriesData.map((dataLabel) => ({
        ...dataLabel,
        color: dataLabelsColorMap[dataLabel.name]
      }))
    }

    return seriesField ? {
      name: getSeriesLabel(seriesItem),
      data: seriesData,
      type: chartType,
      yAxis: seriesItem.yAxis,
      animation: false,
      _field: seriesField,
      color
    } : null
  }).filter((seriesItem) => seriesItem)
}

/**
 * Returns an array of xAxis categories populated with data
 * @param xAxisLabels
 * @param data
 * @param xAxisField
 * @return {Array|*}
 * @private
 */
const _mapDataToXAxisLabels = (xAxisLabels, xAxisField, data) => {
  return (xAxisLabels || []).map((category) => {
    const dataPoint = data.find((dataPoint) => category === get(dataPoint, xAxisField.name))
    if (dataPoint) {
      return dataPoint
    }
    return { [xAxisField.name]: category }
  })
}

/**
 * Constructs visualizer config
 * @param chartOptions
 * @param seriesItem
 * @param reportSnapshot
 * @return {Object|*}
 * @private
 */
const _getVisualizerConfig = ({ chartOptions, seriesItem, reportSnapshot }) => {
  if (!chartOptions || !chartOptions.xAxis || !seriesItem || !reportSnapshot || !reportSnapshot.length) {
    return {}
  }

  const { xAxis, yAxis } = chartOptions
  const {
    _field: seriesItemField,
    pivotValue: seriesItemPivotValue,
    peer: seriesItemPeer,
    index: seriesItemIndex
  } = seriesItem
  const { categories } = _getXAxis(chartOptions, reportSnapshot)
  const supportAggregation = getIsAggregationAvailable(get(xAxis, '_field.type'))

  return {
    entityType: get(seriesItemField, '_entityType.name'),
    chartType: chartOptions.chartType === 'combo' ? seriesItem.chartType : chartOptions.chartType,
    aggregationModel: supportAggregation ? chartOptions.aggregationModel || 'sum' : null,
    xAxisLabels: categories,
    xAxisField: xAxis && xAxis._field,
    seriesAxis: (yAxis || []).find((axis) => axis.id === seriesItem.yAxis),
    seriesField: seriesItemField,
    pivotValue: seriesItemPivotValue,
    peer: seriesItemPeer,
    index: seriesItemIndex
  }
}

/**
 * Returns seriesData from snapshot
 * @param entityType
 * @param pivotValue
 * @param peer
 * @param index
 * @param reportSnapshot
 * @return {Array|*}
 * @private
 */
const _getDataFromSnapshot = ({ entityType, pivotValue, peer, index }, reportSnapshot) => {
  if (!entityType) {
    return []
  }

  return (reportSnapshot || []).filter((row) => {
    const holdingsDateValue = get(row, 'holdings.current_qtr_date')
    const stockIdentifierValue = get(row, 'ID', '')

    const pivotValueToMatch = getEndOfQuarterUTCMemoized(holdingsDateValue)
    const entityTypeToMatch = get(row, 'entity_type')
    const stockIdentifierValueToMatch = parseStockIdentifier(stockIdentifierValue)

    const entityTypeMatch = !entityTypeToMatch || (entityTypeToMatch === entityType)
    const pivotValueMatch = !pivotValueToMatch || (pivotValueToMatch === pivotValue)
    const stockIdentifierValueMatch = !stockIdentifierValueToMatch || (peer
      ? isStockIdentifierEqual(parseStockIdentifier(`${peer.symbol}.${peer.exchange}`), stockIdentifierValueToMatch)
      : index ? `${index.symbol}.${index.group}` === stockIdentifierValue : false)

    return entityTypeMatch && pivotValueMatch && stockIdentifierValueMatch
  })
}

/**
 * Applies aggregation logic to seriesData
 * @param entityType
 * @param xAxisField
 * @param seriesField
 * @param aggregationModel
 * @param snapshotData
 * @return {Array|*}
 * @private
 */
const _aggregateData = ({ entityType, xAxisField, seriesField, aggregationModel }, snapshotData) => {
  if (!xAxisField || !seriesField || !getIsAggregationAvailable(xAxisField.type) || !aggregationModel) {
    return snapshotData
  }

  /**
   * Helper function to aggregate data based on operator provided
   * @param data
   * @param operator
   */
  const aggregate = (data, operator) => {
    return Object.entries(
      data.reduce((acc, curr) => {
        const xAxisFieldValue = _getFormattedValue(xAxisField.type, get(curr, xAxisField.name))
        const seriesFieldValue = get(curr, seriesField.name)

        if (!isNull(seriesFieldValue) && !isUndefined(seriesFieldValue)) {
          acc[xAxisFieldValue] = operator(acc[xAxisFieldValue], seriesFieldValue)
        }

        return acc
      }, {}))
  }

  const aggregationOptions = {
    average: (data) => {
      return aggregate(
        data,
        (value, nextValue) => value ? [...value, nextValue] : [nextValue]
      ).map(([label, values]) => {
        const average = values.reduce((acc, curr) => acc + curr, 0) / values.length
        return [label, average]
      })
    },
    count: (data) => {
      return aggregate(
        data,
        (value) => value ? value + 1 : 1
      )
    },
    max: (data) => {
      return aggregate(
        data,
        (value, nextValue) => value ? Math.max(value, nextValue) : nextValue
      )
    },
    median: (data) => {
      return aggregate(
        data,
        (value, nextValue) => value ? [...value, nextValue] : [nextValue]
      ).map(([label, values]) => {
        const mid = Math.floor(values.length / 2)
        const sorted = [...values].sort((a, b) => a - b)
        const median = sorted.length % 2 !== 0 ? sorted[mid] : (sorted[mid - 1] + sorted[mid]) / 2
        return [label, median]
      })
    },
    min: (data) => {
      return aggregate(
        data,
        (value, nextValue) => value ? Math.min(value, nextValue) : nextValue
      )
    },
    sum: (data) => {
      return aggregate(
        data,
        (value, nextValue) => value ? value + nextValue : nextValue
      )
    }
  }

  snapshotData = uniqBy(snapshotData, (row) => row._id)

  const aggregatedResults = aggregationOptions[aggregationModel.toLowerCase()](snapshotData)

  return aggregatedResults.map(([label, value]) => {
    const obj = {}
    set(obj, xAxisField.name, label)
    set(obj, seriesField.name, value)
    return obj
  })
}

/**
 * Formats seriesData to mach highCharts schema
 * @param entityType
 * @param seriesField
 * @param chartType
 * @param xAxisField
 * @param seriesAxis
 * @param xAxisLabels
 * @param data
 * @return {Array|*}
 * @private
 */
const _formatData = ({ entityType, seriesField, chartType, xAxisField, seriesAxis, xAxisLabels }, data) => {
  if (!seriesField || !chartType || !xAxisField || !seriesAxis) {
    return []
  }

  const format = compose(
    /**
     * Formats seriesData based on seriesAxis config
     */
    function valueFormatter (data) {
      if (!data || !data.length || !seriesAxis || !chartType) {
        return []
      }

      if (chartType === 'pie') {
        return data
      }

      if (seriesAxis.shouldRenderAbsoluteValues) {
        data = (entityType === stockEntityType && xAxisField.type === 'date')
          ? (data || []).map(([timestamp, value]) => [timestamp, Math.abs(value)])
          : (data || []).map(Math.abs)
      }

      if (seriesAxis.shouldRenderRelativeValues && entityType === stockEntityType) {
        data = getRelativeValues(data)
      }

      return data
    },
    /**
     * Converts zero values to null as to not show up on the charts despite minPointLength
     * @param data
     */
    function zeroValuesFormatter (data) {
      if (!['line', 'pie'].includes(chartType)) {
        data = data.map((dataPoint) => dataPoint === 0 ? null : dataPoint)
      }
      return data
    },
    /**
     * Formats snapshot data to fit highcharts schema
     */
    function highchartChartTypeFormatter (data) {
      if (!data || !data.length || !seriesField || !chartType) {
        return []
      }

      switch (chartType) {
        case 'pie':
          return (data || []).map((row) => {
            const { _entityType, name, type } = xAxisField || {}
            const undefinedLabel = _entityType ? `Unnamed ${capitalize(_entityType.label)}` : 'Category'
            const labelValue = get(row, name, undefinedLabel)

            return {
              name: _getFormattedValue(type, labelValue),
              y: Math.abs(get(row, seriesField.name, 0)) // convert negative values to positive ones
            }
          })
        case 'line':
        case 'bar':
        case 'column':
        default:
          const entityTypeName = seriesField._entityType && seriesField._entityType.name
          return entityTypeName === stockEntityType
            ? _getStockData(data, seriesField, xAxisField)
            : _extractDataValues(data, seriesField, 0)
      }
    },
    /**
     * Formats seriesData to match xAxis labels
     */
    function missingSeriesDataFormatter (data) {
      if (entityType !== stockEntityType) {
        data = _mapDataToXAxisLabels(xAxisLabels, xAxisField, data)
      }
      return data
    }
  )

  return format(data)
}

/**
 * This function extracts series data from snapshot and formats it according to highcharts schema requirements
 * @param reportSnapshot
 * @param seriesItem
 * @param chartOptions
 * @return {Array|*}
 */
export const getHighChartsDataPerSeries = ({ reportSnapshot, seriesItem, chartOptions = {} }) => {
  if (!reportSnapshot || !seriesItem || !chartOptions) {
    return []
  }

  const visualizerConfig = _getVisualizerConfig({ chartOptions, seriesItem, reportSnapshot })

  const getSeriesData = compose(
    (data) => _formatData(visualizerConfig, data),
    (data) => _aggregateData(visualizerConfig, data),
    (data) => _getDataFromSnapshot(visualizerConfig, data)
  )

  return getSeriesData(reportSnapshot)
}

/**
 * Get Highcharts layout config
 * @param height
 * @param showTitle
 * @param chartOptions
 * @param widgetPadding
 */
export const getChartConfigLayout = (height, showTitle, chartOptions, widgetPadding) => {
  return {
    chart: {
      type: chartOptions.chartType,
      backgroundColor: 'transparent',
      animation: false,
      height: (showTitle ? (height || 415) - widgetTitleHeight : (height || 415)) - (widgetPadding * 2),
      style: chartFontStyle,
      spacingTop: chartOptions.chartType !== 'bar' ? 30 : 0
    }
  }
}

/**
 * Get Highcharts config
 * @param height
 * @param fields
 * @param data
 * @param showTitle
 * @param chartOptions
 * @param widgetPadding
 * @returns {*}
 */
export const getChartConfig = (height, fields, data, showTitle, chartOptions, widgetPadding) => {
  const overwrittenData = getOverwrittenData(data, fields)
  const xAxis = _getXAxis(chartOptions, overwrittenData)
  const yAxis = _getYAxis(chartOptions)
  const series = _getSeries(chartOptions, overwrittenData)
  const trendlines = (chartOptions && chartOptions.chartType && chartOptions.chartType !== 'pie')
    ? getMultiSeriesTrendLine(series, chartOptions.yAxis, xAxis.categories)
    : []

  return {
    title: {
      text: null
    },
    colors: [
      rain,
      darkTeal,
      mustard,
      olympic,
      amber,
      apple,
      eggplant,
      lime,
      ice,
      walnut
    ],
    chart: {
      type: chartOptions.chartType,
      backgroundColor: 'transparent',
      animation: false,
      height: (showTitle ? (height || 415) - widgetTitleHeight : (height || 415)) - (widgetPadding * 2),
      style: chartFontStyle,
      spacingTop: chartOptions.chartType !== 'bar' ? 30 : 0
    },
    plotOptions: {
      series: {
        enableMouseTracking: false,
        dataLabels: {
          style: chartFontStyle
        }
      },
      line: {
        marker: {
          enabled: false
        }
      },
      bar: {
        borderWidth: 0,
        minPointLength: 2
      },
      column: {
        borderWidth: 0,
        minPointLength: 2
      },
      pie: {
        dataLabels: {
          enabled: true,
          format: '{point.name} - {point.percentage:.1f}%'
        }
      }
    },
    legend: {
      enabled: true,
      align: 'left',
      itemStyle: chartFontStyle
    },
    credits: {
      enabled: false
    },
    navigator: {
      enabled: false
    },
    rangeSelector: {
      enabled: false
    },
    scrollbar: {
      enabled: false
    },
    xAxis,
    yAxis,
    series: [
      ...trendlines,
      ...series
    ]
  }
}
