import React, { memo } from 'react'
import { Tooltip } from '@material-ui/core'
import moment from 'moment-timezone'

import { formatDate, getEventIcon, getTranscriptLink, getTimeWithZone, isMidnight } from '../../../../../utils'

import './cell.component.scss'

const EMPTY_PLACEHOLDER = '-'

/**
 * Event Date Cell Component
 */
const EventDateCell = ({ data }) => {
  const { eventDate, eventConferenceEndDate } = (data || {})
  const fromFormat = ['MM/DD/YY', 'YYYY-MM-DD hh:mm:ss ZZ', 'x'] // for accepting unix and ISO time formats

  const isZeroTime = isMidnight(eventDate, true)
  const startDate = formatDate(eventDate, undefined, isZeroTime, true)
  const endDate = formatDate(eventConferenceEndDate, undefined, isMidnight(eventConferenceEndDate, true), true)
  const startTime = getTimeWithZone(eventDate)
  const rangeDate = `${startDate} - ${endDate}`

  const endOfEventDay = moment(startDate).endOf('day')
  const isEndDateInFutureDate = moment(endDate, fromFormat).diff(endOfEventDay) > 0
  const isRangeDate = eventConferenceEndDate && isEndDateInFutureDate

  return (
    <div className='cell'>
      <div className='cell_content'>
        {
          isRangeDate
            ? <span>{rangeDate}</span>
            : (
              <>
                <span className='date'>{startDate}</span>
                {!isZeroTime && <span>{startTime}</span>}
              </>
              )
        }
      </div>
    </div>
  )
}

/**
 * Event Type Name Cell Component
 */
const EventTypeNameCell = ({ data }) => {
  const { eventTypeName } = (data || {})

  return (
    <div className='cell'>
      <div className='cell_content'>
        <i className={`event-icon ${getEventIcon(eventTypeName)}`} />
        <span>{eventTypeName}</span>
      </div>
    </div>
  )
}

/**
 * Resource Button
 */
const ResourceButton = ({ tooltipTitle, url, icon }) => {
  return (
    <Tooltip
      classes={{
        popper: 'event_transcript-tooltip',
        tooltip: 'event_transcript-tooltip_item',
        arrow: 'event_transcript-tooltip_arrow'
      }}
      title={tooltipTitle}
      placement='bottom'
      arrow
    >
      <a href={url} target='_blank' rel='noreferrer'>
        <i className={`event-icon q4i-${icon}-4pt`} />
      </a>
    </Tooltip>
  )
}

/**
 * Resources Cell Component
 */
const ResourcesCell = ({ data }) => {
  const { transcriptUrl, webcastLiveUrl, webcastReplayUrl } = (data || {})
  const webcastUrl = webcastLiveUrl ?? webcastReplayUrl
  const webcastTitle = webcastLiveUrl ? 'Webcast' : 'Webcast (replay)'

  return (
    <div className='cell'>
      <div className='cell_content resources_content'>
        {webcastUrl &&
          <ResourceButton tooltipTitle={webcastTitle} url={webcastUrl} icon='webcast' />}
        {transcriptUrl &&
          <ResourceButton tooltipTitle='Transcript' url={getTranscriptLink(transcriptUrl, true)} icon='transcript' />}
      </div>
    </div>
  )
}

/**
 * AgGrid Custom Cell Render Component
 * @param props
 */
const CustomCell = ({ data = {}, column = {} }) => {
  switch (column.colId) {
    case 'eventDate':
      return <EventDateCell data={data} />
    case 'eventTypeName':
      return <EventTypeNameCell data={data} />
    case 'resources':
      return <ResourcesCell data={data} />
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default memo(CustomCell)
