import {
  GENERATE_ITINERARY_REQUEST,
  GENERATE_ITINERARY_SUCCESS,
  GENERATE_ITINERARY_FAILURE,
  statusType
} from '../../../actions'

const initial = {
  data: {},
  status: statusType.IDLE
}

const generate = (state = initial, action) => {
  const { payload = {} } = action

  switch (action.type) {
    case GENERATE_ITINERARY_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GENERATE_ITINERARY_SUCCESS:
      return {
        ...state,
        data: payload,
        status: statusType.SUCCESS
      }

    case GENERATE_ITINERARY_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    default:
      return state
  }
}

export default generate
