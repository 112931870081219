import {
  GET_STOCK_HISTORICAL_REQUEST,
  GET_STOCK_HISTORICAL_SUCCESS,
  GET_STOCK_HISTORICAL_FAILURE,
  RESET_STOCK,
  statusType
} from '../../actions'

const moment = require('moment-timezone')
const initial = {
  data: [],
  status: statusType.IDLE
}

const historical = (state = initial, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_STOCK_HISTORICAL_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_STOCK_HISTORICAL_SUCCESS:
      const historicalData = (payload.historical || []).filter(record=> record.Date && record.Last)
      return {
        data: chartStockLegacy(historicalData),
        status: statusType.SUCCESS
      }

    case GET_STOCK_HISTORICAL_FAILURE:
      return {
        status: statusType.ERROR
      }

    case RESET_STOCK:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default historical
