import React, {Component} from 'react';
import PropTypes from 'prop-types';
import validUrl from 'valid-url';
import { Modal } from '../../../../components';

/**
 * Widget Library Component
 */
class WidgetConfigModal extends Component {
    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            isConfigModalOpen: true,
            url: '',
            title: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateConfig = this.updateConfig.bind(this);
    }

    UNSAFE_componentWillMount(){
        this.setState(this.props.data);
    }

    /**
     * Handle text or checkbox input change
     * @param event
     */
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    /**
     * Get modal config and pass to the widget
     * @param e
     */
    updateConfig(e) {
        e.preventDefault();
        const {title, url} = this.state;
        this.props.onChange({title, url});
        this.props.onClose();
    }

    /**
     * Close config Modal view
     */
    closeConfigModal = () => {
        this.props.onClose();
    };

    /**
     * Render Widget Library
     * @returns {*}
     */
    render() {
        const {visible} = this.props;
        const {title, url} = this.state;

        const isValid = validUrl.isUri(url) || (url.length === 0);

        return (
            <Modal
                visible={visible}
                contentHeight={300}
                containerWidth={500}
                contentPadding='0 20px 20px 20px'
                title='Configure Iframe Widget'
                onClose={this.closeConfigModal}
                footerButtons={[{
                    label: 'Cancel',
                    ui: 'shaded',
                    onClick: this.closeConfigModal
                }, {
                    label: 'Save',
                    ui: 'citrus',
                    disabled: !isValid,
                    onClick: this.updateConfig
                }]}
            >
                <div className='field field--text field--full'>
                    <label className='field_label' htmlFor='configTitle'>Name</label>
                    <input className='field_input'
                           id='configTitle'
                           name='title'
                           placeholder='Add Title'
                           maxLength={32}
                           value={title}
                           onChange={this.handleInputChange}/>
                </div>
                <div className='field field--text field--full'>
                    <label className='field_label' htmlFor='configUrl'>URL</label>
                    <input className='field_input'
                           id='configUrl'
                           name='url'
                           placeholder='https://www.youtube.com/embed/M1f8uPmsYrM'
                           value={url}
                           onChange={this.handleInputChange}/>
                </div>
            </Modal>
        );
    }
}


WidgetConfigModal.propTypes = {
    title: PropTypes.string,
    onChange: PropTypes.func,
    data: PropTypes.object
};

export default WidgetConfigModal;
