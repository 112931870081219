import {
  CREATE_ADDRESS_BOOK_LIST_REQUEST,
  CREATE_ADDRESS_BOOK_LIST_SUCCESS,
  CREATE_ADDRESS_BOOK_LIST_FAILURE,
  UPDATE_ADDRESS_BOOK_LIST_REQUEST,
  UPDATE_ADDRESS_BOOK_LIST_SUCCESS,
  UPDATE_ADDRESS_BOOK_LIST_FAILURE,
  DELETE_ADDRESS_BOOK_LIST_REQUEST,
  DELETE_ADDRESS_BOOK_LIST_SUCCESS,
  DELETE_ADDRESS_BOOK_LIST_FAILURE,
  statusType
} from '../../actions'

const initial = {
  data: {},
  status: statusType.IDLE
}

const list = (state = initial, action) => {
  const { payload = {} } = action

  switch (action.type) {
    case CREATE_ADDRESS_BOOK_LIST_REQUEST:
    case UPDATE_ADDRESS_BOOK_LIST_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case CREATE_ADDRESS_BOOK_LIST_SUCCESS:
    case UPDATE_ADDRESS_BOOK_LIST_SUCCESS:
      return {
        ...state,
        data: payload,
        status: statusType.SUCCESS
      }

    case CREATE_ADDRESS_BOOK_LIST_FAILURE:
    case UPDATE_ADDRESS_BOOK_LIST_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case DELETE_ADDRESS_BOOK_LIST_REQUEST:
      return {
        status: statusType.IN_PROGRESS
      }

    case DELETE_ADDRESS_BOOK_LIST_SUCCESS:
      return {
        status: statusType.SUCCESS
      }

    case DELETE_ADDRESS_BOOK_LIST_FAILURE:
      return {
        status: statusType.ERROR
      }

    default:
      return state
  }
}

export default list
