import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_SECURITY = gql`
  query Security(
    $tickerId: [String]!
  ) {
    security(
      tickerId: $tickerId
    ) {
      items {
        id
        tickerId
        active
        securityName
        securityTypeCode
        securityTypeName
        industry
        capGroup
        sector
        filingType
        assetClassName
        assetClassCode
        countryName
        countryCode
        region
        symbol
        exchangeCode
        exchangeName
      }
    }
  }`

const GET_STOCK = gql`
  query Stock(
    $tickerId: [String]!
    $startDate: String!
    $endDate: String!
  ) {
    stock: stock(tickerId: $tickerId) {
      items {
        last
        volume
        currency
        lastCloseChange
        lastCloseChangePercent
      }
    }
    averageVolume: stock(
      tickerId: $tickerId
      startDate: $startDate
      endDate: $endDate
    ) {
      average (field: "volume")
    }
  }`

const GET_PEER = gql`
  query Peer(
    $tickerId: [String]!
  ) {
    peer(
      tickerId: $tickerId
    ) {
      items {
        id
      }
    }
  }`

/**
 * Security
 * @param options
 */
export function useSecurityQuery (options) {
  return useQuery(GET_SECURITY, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Stock
 * @param options
 */
export function useStockQuery (options) {
  return useQuery(GET_STOCK, options)
}

/**
 * Peer
 * @param options
 */
export function usePeerQuery (options) {
  return useQuery(GET_PEER, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}
