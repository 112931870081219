import React, { memo } from 'react'
import { Select, RemovableList } from '../../components'
import PropTypes from 'prop-types'
import { getClassName, THEMES } from '../../utils/ui'
import './comboBox.component.css'

/**
 * ComboBox component
 * @param className
 * @param theme
 * @param selectProps
 * @param removableListProps
 * @returns {*}
 */
function ComboBox ({ className, theme, selectProps, removableListProps }) {
  const baseClassName = getClassName('combo-box', [
    { condition: className, trueClassName: className },
    { condition: theme, trueClassName: `combo-box--${theme}` }
  ])

  return (
    <div className={baseClassName}>
      <div className='combo-box_input'>
        <Select
          theme={theme}
          searchable
          clearable={false}
          closeMenuOnSelect
          showDropdownIndicator={false}
          options={[]}
          {...selectProps}
        />
      </div>
      <div className='combo-box_list'>
        <RemovableList
          theme={theme}
          size='small'
          items={[]}
          {...removableListProps}
        />
      </div>
    </div>
  )
}

ComboBox.propTypes = {
  /**
   * A custom className to pass into the component
   */
  className: PropTypes.string,

  /**
   * Used to paint the Select and RemovableList components using a specific theme
   */
  theme: PropTypes.oneOf([THEMES.WHITE, THEMES.LIGHT_GREY, THEMES.LIGHT, THEMES.DARK]),

  /**
   * List of props for the Select component
   * Documented props can be found here: src/components/select/select.component.js
   */
  selectProps: PropTypes.object.isRequired,

  /**
   * List of props for the RemovableList component
   * Documented props can be found here: src/components/list/removeable/removableList.component.js
   */
  removableListProps: PropTypes.object.isRequired
}

ComboBox.defaultProps = {
  theme: THEMES.WHITE,
  selectProps: {
    searchable: true,
    clearable: true,
    closeMenuOnSelect: true,
    showDropdownIndicator: false,
    options: []
  },
  removableListProps: {
    theme: THEMES.WHITE,
    size: 'small',
    items: []
  }
}

export default memo(ComboBox)
