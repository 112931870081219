import React, { Component } from 'react'
import moment from 'moment-timezone'
import { getValueSign } from '../../../utils/number.util'
import WidgetError from '../error/widgetError.component'
import './estimates.component.css'

class EstimatesPanel extends Component {
  render () {
    const { theme, quarter, prevQuarter, year, prevYear, layout, success, message } = this.props

    if (success === false) {
      return <WidgetError theme={theme} message={message || 'No Data available.'} />
    }

    const monthAbbreviations = moment.monthsShort()
    const currentMonth = monthAbbreviations[moment(quarter.date).month()]
    const monthOfTheYear = monthAbbreviations[moment(year.date).month()]
    const narrowStyle = layout && layout.w === 1 ? 'narrow' : 'wide'

    let fontSize = 'large'

    if (quarter.estimate.toString().length > 6 || year.estimate.toString().length > 6) {
      fontSize = 'small'
    } else if (quarter.estimate.toString().length > 5 || year.estimate.toString().length > 5) {
      fontSize = 'medium'
    }

    return (
      <div className={`estimates-panel estimates-panel--${narrowStyle} estimates-panel--${fontSize}`}>
        <div className='estimates-panel_item estimates-panel_item--quarter'>
          <div className={`estimates-panel_value estimates-panel_value--${getValueSign(quarter.change)}`}>
            {quarter.estimate}
          </div>
          <div className='estimates-panel_info'>
            <p>{currentMonth} {moment(quarter.date).format('YY')} Q{quarter.quarter}</p>
            <p className='estimates-panel_label'>{prevQuarter.actual} Prev</p>
          </div>
        </div>
        <div className='estimates-panel_item estimates-panel_item--year'>
          <div className={`estimates-panel_value estimates-panel_value--${getValueSign(year.change)}`}>
            {year.estimate}
          </div>
          <div className='estimates-panel_info'>
            <p>{monthOfTheYear} {moment(year.date).format('YY')} FY </p>
            <p className='estimates-panel_label'>{prevYear.actual} Prev</p>
          </div>
        </div>
      </div>
    )
  }
}

export default EstimatesPanel
