import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/react-hooks'

export const GET_BRIEFING_BOOKS = gql`
  query BriefingBook(
    $search: String!
    $type: BriefingBookTypeDTO!
  ) {
    briefingBook(
      search: $search
      filter: {
        type: $type
      }
    ) {
      items {
        id
        title
        entity {
          entityId
          entityType
          institutionId
        }
      }
    }
  }`

/**
 * Search Briefing Book(s)
 * @param options
 */
export function useSearchBriefingBooksQuery (options) {
  return useLazyQuery(GET_BRIEFING_BOOKS, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}
