import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

export const GET_HOLDINGS_FOR_FUND_REQUEST = 'GET_HOLDINGS_FOR_FUND_REQUEST'
export const GET_HOLDINGS_FOR_FUND_SUCCESS = 'GET_HOLDINGS_FOR_FUND_SUCCESS'
export const GET_HOLDINGS_FOR_FUND_FAILURE = 'GET_HOLDINGS_FOR_FUND_FAILURE'

/**
 * Get holdings for fund
 * @param factsetEntityId
 * @param params
 * @private
 */
const _fetchHoldingsForFund = (factsetEntityId, params) => ({
  [CALL_API]: {
    types: [
      GET_HOLDINGS_FOR_FUND_REQUEST,
      GET_HOLDINGS_FOR_FUND_SUCCESS,
      GET_HOLDINGS_FOR_FUND_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/ownership/v2/fund/${factsetEntityId}/holdings`,
    payload: params,
    queryOptions: { arrayFormat: 'index' }
  }
})

/**
 * Get holdings for fund
 * @param factsetEntityId
 * @param params
 */
export const fetchHoldingsForFund = (factsetEntityId, params) => (dispatch) => {
  return dispatch(_fetchHoldingsForFund(factsetEntityId, params))
}
