import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import qs from 'querystring'
import { get } from 'lodash'
import { setPasswordResetSuccess, statusType } from '../../actions'
import PasswordResetComponent from './components/passwordReset/passwordReset.component'

class PasswordResetPage extends PureComponent {

  constructor (props) {
    super(props)
    this.state = {
      errorMessage: ''
    }
  }

  /**
   * Apply form validation
   * @param newPass
   * @param confirmPass
   * @return {String}
   */
  validateItems = (newPass, confirmPass) => {
    const passwordPattern = /^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s])\S{10,}$/

    if (!newPass || !confirmPass) {
      return 'Both fields are required'
    } else if (newPass.length < 10 || passwordPattern.test(newPass) === false) {
      return 'Password does not meet above requirements'
    } else if (newPass !== confirmPass) {
      return 'New password and confirmation password do not match'
    }
  }

  /**
   * Get token parameter from url call setPasswordResetSuccess action function to post
   * @param password
   */
  handleSubmit = (password, confirmPassword) => {
    const { history } = this.props
    const queryParams = qs.parse(history.location.search.replace(/^\?*/, ''))
    const token = get(queryParams, 'token')
    const validationErrorMessage = this.validateItems(password, confirmPassword)

    if (validationErrorMessage) {
      this.setState({
        errorMessage: validationErrorMessage
      })
      return
    }
    this.passwordResetFetch(token, password)
  }

  /**
   * call fetch to check password , if success, redirect to login page. if fail, show error message
   * @param token
   * @param password
   */
  passwordResetFetch (token, password) {
    const { auth, setPasswordResetSuccess, history } = this.props

    auth.passwordReset(token, password)
      .then((data) => {
        setPasswordResetSuccess()
        history.push('/login')
      })
      .catch((error) => {
        this.setState(prevState => ({
          errorMessage: (
            <span>Password reset token is invalid or has expired <br/> Go back to the homepage to request a new link</span>)
        }))

      })

  }

  render () {
    const { status } = this.props
    const { errorMessage } = this.state
    const isSubmitting = status === statusType.IN_PROGRESS
    return <PasswordResetComponent
      onSubmit={this.handleSubmit}
      isSubmitting={isSubmitting}
      errorMessage={errorMessage}
    />
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => ({
  setPasswordResetSuccess: bindActionCreators(setPasswordResetSuccess, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage))