import React from 'react'
import PropTypes from 'prop-types'

// components
import NewsDetail from './detail.component'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderLightThumb, renderTrackVertical } from '../../../../resources/theme/q4.custom-scrollbar'
import { formatDate } from '../../../../utils'

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  news: PropTypes.array.isRequired,
  openModal: PropTypes.func.isRequired
}

const defaultProps = {
  news: []
}

/**
 * Security Recent News Card
 * @param props
 */
function News (props) {
  const { isLoading, news, openModal } = props
  const hasNews = !isLoading && news.length

  return (!isLoading && !hasNews)
    ? <div className='security-recent-update_no-date'>No data available</div>
    : <Scrollbars
        className='react-scrollbar'
        autoHide
        autoHeight
        autoHeightMax={208}
        hideTracksWhenNotNeeded
        renderThumbVertical={renderLightThumb}
        renderTrackVertical={renderTrackVertical}
      >
      {(news || []).map((item) => {
        const { id, type, headline, date } = (item || {})
        return (
          <div
            key={id}
            className='security-recent-update_item security-recent-update_item'
            onClick={() => openModal({
              component: ({ onClose }) => <NewsDetail
                news={item}
                onClose={onClose}
              />
            })}
          >
            {(type === 'press') && <i className='security-recent-update_icon q4i-press-releases-4pt' />}
            <div className='security-recent-update_title'>{headline}</div>
            {date && <div className='security-recent-update_date'>{formatDate(date, 'dddd, MMMM DD, YYYY', true, true)}</div>}
          </div>
        )
      })}
      </Scrollbars>
}

News.propTypes = propTypes
News.defaultProps = defaultProps

export default News
