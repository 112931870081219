import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { Button, Select, Toolbar, ToolbarRow } from '../../../../index'
import { SectionSearch } from '../../../../shared'
import { HISTORICAL_QUARTER, THEMES } from '../../../../../utils'

const propTypes = {
  dataId: PropTypes.string,
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  noData: PropTypes.bool,
  search: PropTypes.string,
  quarters: PropTypes.number,
  onQueryChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired
}

const defaultProps = {
  toolbarTheme: THEMES.Q4_BLUE,
  toolTheme: THEMES.INK,
  quarters: 4
}

/**
 * Historical Holding Toolbar Component
 * @param props
 */
function HistoricalHoldingToolbar (props) {
  const { dataId, toolbarTheme, toolTheme, noData, search, quarters, onQueryChange, onExport, exporting } = props

  const selected = HISTORICAL_QUARTER.find((option) => option.value === quarters)
  const handleFilterChange = (option) => option && onQueryChange({ quarters: option.value })
  const handleSearchChange = (search) => onQueryChange({ search })

  return (
    <Toolbar theme={toolbarTheme} className='historical-holding-grid_toolbar'>
      <ToolbarRow justified>
        <div className='toolbar_group'>
          <Select
            theme={toolTheme}
            dataId={{
              inputId: `${dataId}QuarterFilterInput`,
              menuId: `${dataId}QuarterFilterMenu`
            }}
            size='thin'
            value={selected}
            options={HISTORICAL_QUARTER}
            searchable={false}
            clearable={false}
            onChange={handleFilterChange}
          />
        </div>
        <div className='toolbar_group'>
          <SectionSearch
            dataId={`${dataId}Search`}
            theme={toolTheme}
            value={search}
            onQueryChange={handleSearchChange}
            onClear={handleSearchChange}
          />
          <Button
            dataId={`${dataId}Export`}
            theme={toolTheme}
            loading={exporting}
            icon='q4i-download-4pt'
            label='Export'
            disabled={noData}
            onClick={onExport}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

HistoricalHoldingToolbar.propTypes = propTypes
HistoricalHoldingToolbar.defaultProps = defaultProps

export default memo(HistoricalHoldingToolbar)
