import './sectionTab.component.css'
import './sectionTab.doc'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { getClassName, THEMES } from '../../../utils/ui'
import { LEVEL } from '../../../hook'
/**
 * Range Tab Component
 */
class SectionTab extends PureComponent {

  constructor (props) {
    super(props)

    let activeTab = props.tabs && props.tabs[0] && props.tabs[0].id

    if (props.initialActiveTab) {
      activeTab = props.initialActiveTab
    }

    if (props.forceActiveTab) {
      activeTab = props.forceActiveTab
    }

    this.state = {
      activeTab,
      mountedTabs: [activeTab]
    }
  }

  /**
   * Handle tab change
   * @param tab
   */
  handleChange = (tab) => {
    const { rememberMounted, onTabChange, setGridTab } = this.props
    const { mountedTabs } = this.state

    // Generic grid tab persistence, only set if parent page provides persistence support
    if (setGridTab) {
      setGridTab(tab.id)
    }

    const newState = {
      activeTab: tab.id
    }

    if (rememberMounted && !mountedTabs.includes(tab.id)) {
      newState.mountedTabs = [...mountedTabs, tab.id]
    }

    this.setState(newState)
    onTabChange && onTabChange(tab)
  }

  /**
   * Render Tab Navigation
   * @param {Tab[]} tabs
   * @param activeTab
   * @returns {Array}
   */
  renderTabNavigation = (tabs, activeTab) => {
    return (tabs || []).map((tab) => {
      const { dataId, id, label, hidden } = tab

      const tabClassName = getClassName('section-tab_nav-item', [
        { condition: id === activeTab, trueClassName: 'section-tab_nav-item--active' }
      ])

      return (hidden ? null :
        <div
          key={`section-tab_nav-item--${id}`}
          className={tabClassName}
          data-id={dataId && dataId.label}
          onClick={() => this.handleChange(tab)}>
          <span className='section-tab_label'>{label}</span>
        </div>
      )
    })
  }

  /**
   * Render Tab View
   * @param {Tab[]} tabs
   * @param activeTab
   * @returns {*}
   */
  renderTabView = (tabs, activeTab) => {
    const { rememberMounted } = this.props
    const { mountedTabs } = this.state

    if (rememberMounted) {
      return tabs.map((tab) => {
        const { dataId, id, view, hidden } = tab

        if (rememberMounted && !mountedTabs.includes(id)) {
          return null
        }

        const viewClassName = getClassName('section-tab_view-item', [
          { condition: id === activeTab, trueClassName: 'section-tab_view-item--active' }
        ])


        return (hidden ? null :
          <div
            key={`section-tab_view-item--${id}`}
            className={viewClassName}
            data-id={dataId && dataId.view}>
            {view}
          </div>
        )
      })
    } else {
      const tab = tabs.find((tab) => tab.id === activeTab)
      const { dataId } = tab

      return (
        <div className='section-tab_view-item section-tab_view-item--standalone' data-id={dataId}>{tab.view}</div>
      )
    }
  }

  /**
   * Render range tab
   * @returns {*}
   */
  render () {
    const { tabs, theme, compact, className, forceActiveTab } = this.props
    let { activeTab } = this.state

    if (!tabs || !tabs.length) {
      return
    }

    if (forceActiveTab) {
      activeTab = forceActiveTab
    }

    if (!tabs.find((tab) => tab.id === activeTab)) {
      activeTab = tabs && tabs[0] && tabs[0].id
    }

    const baseClassName = getClassName('section-tab', [
      { condition: theme, trueClassName: `section-tab--${theme}` },
      { condition: compact, trueClassName: 'section-tab--compact' },
      { condition: className, trueClassName: className }
    ])

    return (
      <section className={baseClassName}>
        <header className='section-tab_nav'>
          {this.renderTabNavigation(tabs, activeTab)}
        </header>
        <section className='section-tab_view'>
          {this.renderTabView(tabs, activeTab)}
        </section>
      </section>
    )
  }
}

SectionTab.propTypes = {
  /**
   * The tabs to render in the SectionTab component
   */
  tabs: PropTypes.arrayOf(PropTypes.shape({
    /**
     * The unique identification string for the tab
     */
    id: PropTypes.string,
    hidden: PropTypes.bool,

    /**
     * The label to render within the tab navigation item
     */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),

    /**
     * Used to determine what to render below the SectionTab component when the corresponding tab is active
     */
    view: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),

    /**
     * Used for tracking and test automation
     */
    dataId: PropTypes.shape({
      label: PropTypes.string,
      view: PropTypes.string
    })
  })).isRequired,

  /**
   * Optional class name
   */
  className: PropTypes.string,

  /**
   * Used to determine the initially active tab
   */
  initialActiveTab: PropTypes.string,

  /**
   * Used to overwrite component's internal activeTab logic
   */
  forceActiveTab: PropTypes.string,

  /**
   * Used to determine whether or not SectionTab remembers which tabbed components have been previously mounted
   * Use this if you don't want to unmount the inactive tab views after onTabChange event
   */
  rememberMounted: PropTypes.bool,

  /**
   * A callback fired for when the user clicks to change tabs
   */
  onTabChange: PropTypes.func,

  /**
   * Used to apply specific theme
   */
  theme: PropTypes.oneOf([THEMES.RAIN, THEMES.STEEL, THEMES.SLATE]),

  /**
   * True to use compact tab layout
   */
  compact: PropTypes.bool

}

SectionTab.defaultProps = {
  rememberMounted: true,
  theme: THEMES.RAIN
}

export default SectionTab
