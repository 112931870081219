import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, capitalize } from 'lodash'

// actions
import { usePeerCreateQuery, usePeerDeleteQuery } from '../hook/utilityQuery.hook'
import {
  createPeer,
  createTarget,
  deletePeer,
  deleteTarget,
  createToast,
  openModal,
  CREATE_PEER_SUCCESS,
  CREATE_PEER_FAILURE,
  REMOVE_PEER_SUCCESS,
  REMOVE_PEER_FAILURE,
  CREATE_TARGET_SUCCESS,
  CREATE_TARGET_FAILURE,
  REMOVE_TARGET_SUCCESS,
  REMOVE_TARGET_FAILURE,
  modalType
} from '../../../actions'

import { getEntity, getActivityAttendee, isQ4Id, CORPORATE_PARTICIPANT, ENTITY_TYPE } from '../../../utils'

const { CONTACT } = ENTITY_TYPE

/**
 * Utility Menu with Options (Higher-Order Component)
 * @param UtilityMenuComponent
 */
function withOptions (UtilityMenuComponent) {
  const propTypes = {
    entity: PropTypes.shape({
      _id: PropTypes.string,
      entityType: PropTypes.string,
      id: PropTypes.string,

      /**
       * q4EntityId
       */
      q4EntityId: (props, propName, componentName) => {
        const q4EntityId = props[propName]
        if (q4EntityId && !isQ4Id(q4EntityId)) {
          return new Error(
            'Invalid prop `' + propName + '` supplied to' +
            ' `' + componentName + '`. q4EntityId validation failed.'
          )
        }
      },

      /**
       * Deprecated factset entity id
       */
      entityId: (props, propName, componentName) => {
        const entityId = props[propName]
        if (entityId && isQ4Id(entityId)) {
          return new Error(
            'Invalid prop `' + propName + '` supplied to' +
            ' `' + componentName + '`. entityId validation failed. Try to use q4EntityId prop instead.'
          )
        }
      }
    })
  }

  function Options (props) {
    const {
      dataId, items, entityType, entity, favorite, openModal, createToast,
      createPeer, deletePeer, createTarget, deleteTarget // @deprecated => use graphql
    } = props

    const [handlePeerCreate] = usePeerCreateQuery()
    const [handlePeerDelete] = usePeerDeleteQuery()

    /**
     * Add/remove target for entity
     * @param targetId
     * @param reference
     */
    const onTargetOptionTap = (targetId, reference) => {
      const action = targetId ? deleteTarget(targetId) : createTarget({ reference })
      const message = `${capitalize(reference.type)} ${targetId ? 'removed from targets' : 'saved as target'} successfully.`

      action.then((data) => {
        const { type } = data
        const success = [CREATE_TARGET_SUCCESS, REMOVE_TARGET_SUCCESS].includes(type)
        const failure = [CREATE_TARGET_FAILURE, REMOVE_TARGET_FAILURE].includes(type)

        success && handleSuccess(message)
        failure && handleFailure()
      })
    }

    /**
     * Add/remove security to/from Peer List
     * @deprecated
     * @param security
     */
    const onPeerListOptionTap = (security) => {
      const { _id, securityName, symbol, exchange, peerId } = security
      const message = `Security ${peerId ? 'removed from' : 'added to'} Peer List successfully.`

      const params = {
        Exchange: exchange,
        Symbol: symbol,
        Name: securityName,
        _security: _id
      }

      const action = peerId ? deletePeer({ _id: peerId }) : createPeer(params)
      action.then((data) => {
        const { type } = data
        const success = [CREATE_PEER_SUCCESS, REMOVE_PEER_SUCCESS].includes(type)
        const failure = [CREATE_PEER_FAILURE, REMOVE_PEER_FAILURE].includes(type)

        success && handleSuccess(message)
        failure && handleFailure()
      })
    }

    /**
     * Create/delete security to/from peer list
     * @param peerId
     * @param entity
     * @param onSaveSuccess
     */
    const handlePeerOption = ({ peerId, entity, onSuccess }) => {
      const { tickerId, legacySecurityId, securityName, symbol, exchangeCode } = entity
      const message = `Security ${peerId ? 'removed from' : 'added to'} Peer List successfully.`

      const request = peerId
        ? handlePeerDelete({ variables: { id: peerId } })
        : handlePeerCreate({
          skip: !tickerId,
          variables: {
            tickerId,
            entityId: entity.id,
            legacySecurityId,
            symbol,
            exchange: exchangeCode,
            name: securityName
          }
        })

      request.then((response) => get(response, 'errors')
        ? handleFailure()
        : handlePeerResponse({ message, onSuccess })
      )
    }

    /**
     * Handle peer create/delete response
     * @param message
     * @param onSuccess
     */
    const handlePeerResponse = ({ message, onSuccess }) => {
      handleSuccess(message)
      onSuccess && onSuccess()
    }

    /**
     * On Save Success show toast success message and close the modal
     * @param message
     */
    function handleSuccess (message) {
      createToast({ text: message })
    }

    /**
     * On Action Completion Failure display an error
     */
    function handleFailure () {
      openModal({ type: modalType.ERROR_MODAL })
    }

    const sharedActions = (entityType && entity && (entity.id || entity._id)) && {
      CREATE_ACTIVITY: () => ({
        dataId: dataId ? `${dataId}ActivityCreate` : '',
        icon: 'q4i-activity-2pt',
        label: 'Log Activity',
        onClick: () => openModal({
          type: modalType.ACTIVITY_MODAL,
          props: {
            isParentOptionHidden: (entityType !== CORPORATE_PARTICIPANT),
            values: (entityType !== CORPORATE_PARTICIPANT) ? getActivityAttendee(entityType, entity) : { participants: [entity] }
          }
        })
      }),
      TARGET: () => ({
        dataId: dataId ? `${dataId}Target` : '',
        icon: 'q4i-savedtargets-2pt',
        label: (entity._target || entity.target) ? 'Remove from Targets' : 'Save as Target',
        onClick: onTargetOptionTap.bind(this, (entity._target || entity.target), { type: entityType, ...(entityType === CONTACT) ? { item: (entity._id || entity.id) } : { entityId: entity.id } })
      }),
      CREATE_DEAL: (item) => ({
        dataId: dataId ? `${dataId}DealCreate` : '',
        icon: 'q4i-deal-2pt',
        label: 'Create New Deal',
        onClick: () => openModal({
          type: modalType.DEAL_EDIT_MODAL,
          props: {
            dataId: 'DealCreate',
            entities: getEntity(entityType, entity),
            onSaveSuccess: item.onSaveSuccess
          }
        })
      }),
      DOWNLOAD_TEARSHEET: () => ({
        dataId: dataId ? `${dataId}TearsheetGenerate` : '',
        icon: 'q4i-reports-2pt',
        label: 'Download Tear Sheet',
        onClick: () => openModal({
          type: modalType.TEARSHEET_EXPORT_MODAL,
          props: {
            dataId: 'TearsheetGenerate',
            entity: {
              entityType,
              entityId: entity.id,
              entityName: entity.fullName || entity.fundName || entity.institutionName || entity.name,
              ...(entityType === CONTACT) && { institutionId: get(entity, 'jobs[0].entityId', null) || get(entity, 'jobs[0].q4_entity_id', null) }
            }
          }
        })
      }),
      BRIEFING_BOOK: () => ({
        dataId: dataId ? `${dataId}BriefingBook` : '',
        icon: 'q4i-book-2pt',
        label: 'Add to Briefing Book',
        onClick: () => openModal({
          type: modalType.ADD_TO_BRIEFING_BOOK_MODAL,
          props: {
            entities: [{
              entityType,
              entityId: entity.id,
              ...(entityType === CONTACT) && { institutionId: get(entity, 'jobs[0].entityId', null) || get(entity, 'jobs[0].q4_entity_id', null) }
            }]
          }
        })
      }),
      ADDRESS_BOOK: () => {
        const favoriteId = entity._favorite || get(favorite, '_id')
        return {
          dataId: dataId ? `${dataId}AddressBook` : '',
          icon: 'q4i-contact-list-2pt',
          label: favoriteId ? 'Edit Address Book' : 'Save to Address Book',
          onClick: () => openModal({
            type: modalType.ADD_TO_ADDRESS_BOOK_MODAL,
            props: {
              dataId: 'AddToAddressBook',
              contactId: (entity._id || entity.id),
              favoriteId,
              favoriteIds: get(favorite, '_lists')
            }
          })
        }
      },
      REQUEST_ENTITY_UPDATE: () => ({
        dataId: dataId ? `${dataId}EntityUpdate` : '',
        icon: 'q4i-suggest-an-edit-2pt',
        label: 'Request Update',
        onClick: () => openModal({
          type: modalType.ENTITY_UPDATE_REQUEST_MODAL,
          props: {
            dataId: 'EntityUpdate',
            values: {
              entityType,
              entityId: entity._id || entity.id,
              name: entity.name
            }
          }
        })
      }),
      PEER: (item) => ({
        dataId: dataId ? `${dataId}PeerList` : '',
        icon: 'q4i-watchlist-2pt',
        label: (item.peerId) ? 'Remove from Peer List' : 'Add to Peer List',
        onClick: () => handlePeerOption({ peerId: item.peerId, entity, onSuccess: item.onSuccess })
      }),
      // @deprecated
      PEER_LIST: () => ({
        dataId: dataId ? `${dataId}PeerList` : '',
        icon: 'q4i-watchlist-2pt',
        label: entity.peerId ? 'Remove from Peer List' : 'Add to Peer List',
        onClick: onPeerListOptionTap.bind(this, entity)
      })
    }

    /**
     * Formats Utility Menu Actions
     * @param items
     */
    const getMenuItems = (items) => {
      return (items || [])
        .filter((item) => !item.hide)
        .map((item) => item.action ? sharedActions[item.action](item) : item)
    }

    return (
      <UtilityMenuComponent
        {...props}
        items={getMenuItems(items)}
      />
    )
  }

  Options.propTypes = propTypes

  const mapStateToProps = (state) => ({
    profile: get(state, 'profile.data'),
    favorite: get(state, 'contact.favorite.data')
  })

  const mapDispatchToProps = (dispatch) => ({
    createPeer: bindActionCreators(createPeer, dispatch),
    createTarget: bindActionCreators(createTarget, dispatch),
    deletePeer: bindActionCreators(deletePeer, dispatch),
    deleteTarget: bindActionCreators(deleteTarget, dispatch),
    createToast: bindActionCreators(createToast, dispatch),
    openModal: bindActionCreators(openModal, dispatch)
  })

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(Options))
}

export default withOptions
