import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// components
import {
  EntityAttribute,
  EntityBadge,
  EntityHighlight,
  TagInput,
} from '../../../../../../../components'
import { ENTITY_TYPE, THEMES } from '../../../../../../../utils'

const propTypes = {
  dataIdPrefix: PropTypes.string.isRequired,
  fund: PropTypes.object.isRequired,
  targetId: PropTypes.string,
  hasActiveDeal: PropTypes.bool,
  deals: PropTypes.shape({
    isLoading: PropTypes.bool,
    deals: PropTypes.array,
    pipeline: PropTypes.array
  }).isRequired,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  onDealSaveSuccess: PropTypes.func
}

const defaultProps = {
  basic: false,
  dataIdPrefix: 'FundFlyout',
  fund: {},
  hasActiveDeal: false,
  deals: {},
  theme: THEMES.DARK,
}

const { FUND } = ENTITY_TYPE

/**
 * Fund Overview Component
 * @param props
 */
function FundOverview (props) {
  const { dataIdPrefix, fund, targetId, hasActiveDeal, theme } = props
  const { id, portfolioValue, equityAUM } = fund

  return (
    <div className='overview-layout'>
      <Grid container spacing={3}>
        <EntityBadge
          dataId={dataIdPrefix}
          theme={theme}
          data={{
            targetId,
            hasActiveDeal
          }}
        />
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <EntityAttribute
                dataId={dataIdPrefix}
                entityId={id}
                entityType={FUND}
                theme={theme}
                data={{
                  aum: portfolioValue,
                  equityAUM
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <EntityHighlight
                dataId={dataIdPrefix}
                entityId={id}
                entityType={FUND}
                theme={theme}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TagInput
            dataId={dataIdPrefix}
            entityId={id}
            entityType={FUND}
            theme={theme}
          />
        </Grid>

      </Grid>
    </div>
  )
}

FundOverview.propTypes = propTypes
FundOverview.defaultProps = defaultProps

export default memo(FundOverview)
