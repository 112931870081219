import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const FETCH_CURRENT_SECURITY_REQUEST = 'FETCH_CURRENT_SECURITY_REQUEST'
export const FETCH_CURRENT_SECURITY_SUCCESS = 'FETCH_CURRENT_SECURITY_SUCCESS'
export const FETCH_CURRENT_SECURITY_ERROR = 'FETCH_CURRENT_SECURITY_ERROR'

const resources = {
  currentRating: '/security'
}

const _fetchCurrentSecurity = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_CURRENT_SECURITY_REQUEST,
      FETCH_CURRENT_SECURITY_SUCCESS,
      FETCH_CURRENT_SECURITY_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

export const fetchCurrentSecurity = (security) => (dispatch) => {
  const url = `${resources.currentRating}/${security}`
  return dispatch(_fetchCurrentSecurity(url))
}
