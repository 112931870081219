export const ENTITY_TYPE = {
  CONTACT: 'contact',
  INSTITUTION: 'institution',
  FUND: 'fund',
  SECURITY: 'security'
}

export const SWIFTYPE_ENTITY_TYPES = {
  CONTACTS: 'contacts',
  INSTITUTIONS: 'institutions',
  FUNDS: 'funds',
  SECURITIES: 'securities',
  CONTACT_LOCATION: 'contactlocation'
}

export const SWIFTYPE_TO_ENTITY_TYPE_MAP = {
  contacts: 'contact',
  institutions: 'institution',
  funds: 'fund',
  securities: 'security'
}

export const ENTITY_TYPE_TO_SWIFTYPE_MAP = {
  contact: 'contacts',
  institution: 'institutions',
  fund: 'funds',
  security: 'securities'
}

export const ENTITY_TYPES = [
  ENTITY_TYPE.CONTACT,
  ENTITY_TYPE.INSTITUTION,
  ENTITY_TYPE.FUND,
  ENTITY_TYPE.SECURITY
]

export const AISCORE = `The AI Targeting Investment Drivers represent the top attractors (factors) for a given firm/fund, 
in a given security. The model, which is updated weekly, consists of roughly 700 individual factors in total and 
focuses on 5 key areas - Balance Sheet, Income Statement, Cash Flow Statement, Valuation Ratios & Trading Analysis.`

export const QR = `Q4's Quality Rating (QR) is a proprietary scoring system based upon a 0-100 scale, where 100 represents 
the 'Highest Quality' firm/fund. These scores are calculated quarterly and are quantitatively-driven, taking subjectivity 
and human bias out of the equation. Amongst other things an investor's QR score is determined by examining its: Size (AUMs), 
Average Holding Period, Management Style, Number of Holdings, Turnover, and Distribution.`

/**
 * @deprecated
 * TODO: deprecate capitalized types
 */
export const ENTITY_INSTITUTION = 'Institution'
export const ENTITY_CONTACT = 'Contact'
export const ENTITY_FUND = 'Fund'
