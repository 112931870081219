import '../../security.doc'
import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// components
import CustomCell from './cell/cell.component'
import withOptions from '../../../../../utilityMenu/helper/withOptions'
import UtilityMenu from '../../../../../utilityMenu/utilityMenu.component'
import { AgGrid, Spinner, NoContentMessage } from '../../../../../index'
import { getLocalizedCurrency } from '../../../../../../utils/currencyLocalization'

// utils
import {
  format,
  formatDate,
  getDefaultColumnDef,
  preventDefaultRowClick,
  inMillions,
  ENTITY_TYPE,
  THEMES
} from '../../../../../../utils'
import { capitalize, get, uniq } from 'lodash'

const Utility = withOptions(UtilityMenu)

const propTypes = {
  pageSizeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number,
  listOptions: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  onQueryChange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired
  })
}

const defaultProps = {
  loading: false,
  data: [],
  total: 0,
  listOptions: {}
}

const { FUND, INSTITUTION } = ENTITY_TYPE
const EMPTY_PLACEHOLDER = '-'
const ZERO = '0.00'

function formatted (value, formatter = format) {
  return (value || value === 0) ? formatter(value, 0) : EMPTY_PLACEHOLDER
}

/**
 * Get quarter columns definition
 * @param data
 */
function getQuarterColumnDefinition (data) {
  if (!data || !data.length) {
    return []
  }

  const holdings = get(data, '0.holdings', [])
  const quarters = uniq((holdings || [])
    .filter((holding) => get(holding, 'reportDate'))
    .map(({ reportDate }) => reportDate))

  return (quarters || []).map((date, index) => date && ({
    field: `holdings.${index}.current`,
    headerName: formatDate(moment.utc(date), undefined, undefined, true),
    type: 'number',
    minWidth: 140,
    maxWidth: 140,
    sortable: false,
    valueFormatter: ({ value }) => formatted(value)
  }))
}

/**
 * Get columns based on holderType
 * @param data
 */
function getColumns (data) {
  const quarters = getQuarterColumnDefinition(data)

  return [
    {
      field: 'rank',
      headerName: '#',
      type: 'centered',
      minWidth: 72,
      maxWidth: 72,
      pinned: 'left',
      lockPinned: true,
      sortable: false,
      cellRenderer: 'CustomCell'
    },
    {
      field: 'institutionName',
      headerName: 'Name',
      type: 'text',
      minWidth: 484,
      pinned: 'left',
      lockPinned: true,
      cellRenderer: 'CustomCell'
    },
    {
      field: 'holdings.0.current',
      headerName: 'Pos',
      type: 'number',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value }) => formatted(value)
    },
    {
      field: 'holdings.0.change',
      headerName: 'Chg',
      type: 'change',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value }) => formatted(value)
    },
    {
      field: 'currentOrd',
      headerName: 'Ord',
      type: 'number',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value }) => formatted(value)
    },
    {
      field: 'currentAdr',
      headerName: 'Adr',
      type: 'number',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value }) => formatted(value)
    },
    {
      field: 'holdings.0.marketValue',
      headerName: `MKT VAL (MM ${getLocalizedCurrency()})`,
      type: 'number',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value }) => value ? inMillions(value, 2) : EMPTY_PLACEHOLDER
    },
    {
      field: 'holdings.0.percentTSO',
      headerName: '%OS',
      type: 'number',
      minWidth: 100,
      maxWidth: 100,
      valueFormatter: ({ value }) => value ? format(value, 2) : ZERO
    },
    {
      field: 'institutionStyle',
      headerName: 'Style',
      type: 'centered',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value, data }) => {
        const { isNested, fundStyle } = (data || {})
        const style = isNested ? fundStyle : value
        return style || EMPTY_PLACEHOLDER
      }
    },
    {
      field: 'institutionTurnover',
      headerName: 'Turnover',
      type: 'centered',
      minWidth: 140,
      maxWidth: 140,
      valueFormatter: ({ value, data }) => {
        const { isNested, fundTurnover } = (data || {})
        const turnover = isNested ? fundTurnover : value
        return turnover
          ? turnover !== 'NA'
              ? capitalize(turnover)
              : turnover
          : EMPTY_PLACEHOLDER
      }
    },
    {
      field: 'institutionQualityRating',
      headerName: 'QR',
      type: 'centered',
      minWidth: 80,
      maxWidth: 80,
      valueFormatter: ({ value, data }) => {
        const { isNested, fundQualityRating } = (data || {})
        const qualityRating = isNested ? fundQualityRating : value
        return qualityRating || EMPTY_PLACEHOLDER
      }
    },
    ...quarters
  ]
}

/**
 * Shareholder ID Table Component
 * @typedef { import('./table.doc').ShareholderIdTableProps } ShareholderIdTableProps
 *
 * @param {ShareholderIdTableProps} props
 * @returns {JSX.Element}
 */
function ShareholderIdTable (props) {
  const { pageSizeId, loading, data, total, listOptions, history, onQueryChange } = props
  const [agGrid, setAgGrid] = useState({})
  const [utility, setUtility] = useState({
    menu: null,
    items: [
      { action: 'CREATE_ACTIVITY' },
      { action: 'TARGET' },
      { action: 'BRIEFING_BOOK' },
      { action: 'DOWNLOAD_TEARSHEET' }
    ]
  })

  const { menu, items } = (utility || {})
  const columns = getColumns(data)

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = grid => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click event
   * @param {SecurityTableRowClickEvent} event
   * @returns {void}
   */
  const handleRowClick = ({ fundId, institutionId, holderType }) => {
    const id = (holderType === FUND) ? fundId : (holderType === INSTITUTION) ? institutionId : null
    id && history && history.push(`/${holderType}/${id}`)
  }

  /**
   * Handle page change
   * @param selected
   */
  const handlePageChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, page: selected }
    })
  }

  /**
   * Handle page size change
   * @param selected
   */
  const handlePageSizeChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, limit: selected, page: 1 }
    })
  }

  /**
   * Handle Column Sort
   * @param grid
   */
  const handleSortChange = grid => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel && !sortModel.length) {
      return
    }

    onQueryChange({
      listOptions: {
        ...listOptions,
        sortBy: sortModel[0].colId,
        sortDir: sortModel[0].sort,
        page: 1
      }
    })
  }

  /**
   * Handle utility item click
   * @param event
   * @param entity
   */
  const handleUtilityClick = (event, entity) => {
    event.stopPropagation()

    const { hasDeals } = (entity || {})
    setUtility({
      menu: { entity, anchorEl: event.currentTarget },
      items: items.map((item) => ({ ...item, hide: (hasDeals && item.action === 'TARGET') }))
    })
  }

  /**
   * Render Utility Menu
   */
  const getUtilityMenu = () => {
    const { menu, items } = utility
    const { entity, anchorEl } = menu || {}

    return entity
      ? <Utility
          theme={THEMES.LIGHT}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleUtilityClose()}
          items={items.filter(item => !item.hide)}
          entityType={entity.entityType}
          entity={entity}
          hasButton
        />
      : null
  }

  /**
   * Handle Utility Close Click
   */
  const handleUtilityClose = () => {
    setUtility({ ...utility, menu: null })
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = props => {
    return <CustomCell {...props} onUtilityClick={handleUtilityClick} />
  }

  /**
   * Returns grid columns
   */
  const getColumnsDefinition = () => {
    return columns.map((column, idx) => {
      const { type } = column
      return {
        ...getDefaultColumnDef({ columnIndex: idx === 1 ? idx + 1 : idx, type }),
        ...column
      }
    })
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage />
        : <AgGrid
            key='shareholderId_loaded-state'
            domLayout='autoHeight'
            sizeToFit
            // suppress configs
            suppressMovableColumns
            suppressContextMenu
            // columns and data
            defaultColDef={{
              suppressMenu: true,
              sortable: true
            }}
            columnDefs={getColumnsDefinition()}
            rowData={data}
            // pagination
            pagination
            paginationProps={{
              pageSizeId,
              forcePage: listOptions.page,
              initialPageSize: listOptions.limit,
              showPageSizeSelection: true,
              pageSizeOptions: [10, 25],
              total,
              onPageChange: handlePageChange,
              onPageSizeChange: handlePageSizeChange
            }}
            // custom components
            frameworkComponents={{
              CustomCell: renderCustomCell
            }}
            // hierarchy grouping for expandable rows
            treeData
            groupDefaultExpanded={0}
            getDataPath={data => data?.hierarchy}
            // event listeners
            onGridReady={handleGridReady}
            onGridSizeChanged={handleGridResize}
            onSortChanged={handleSortChange}
            onRowClicked={preventDefaultRowClick(
              handleRowClick, ['cell_indicator--utility']
            )}
            rowClassRules={{ 'ag-row--expanded': ({ data, node }) => data?.isNested || node?.expanded }}
            isPinned={data.length}
          />}
      {menu && getUtilityMenu()}
    </div>
  )
}

ShareholderIdTable.propTypes = propTypes
ShareholderIdTable.defaultProps = defaultProps

export default memo(ShareholderIdTable)
