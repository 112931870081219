import { useState } from 'react'

import { saveAs } from 'file-saver'
import { get, flatten } from 'lodash'

import worker from 'workerize-loader!./worker'

// Create an instance of the worker
const workerInstance = worker()

/**
 * Export CSV file using WebWorker
 * @param data
 */
const exportCSV = (data, filename) => {
  if (!data || !filename) {
    return
  }

  return new Promise((resolve, reject) => {
    // Start CSV generation
    workerInstance.generateCsv(data).then((blob) => {
      // save generated blob to a file
      saveAs(blob, filename)
      resolve()
    }).catch((error) => {
      reject(error)
    })
  })
}

/**
 * Export Hook
 * @param onComplete
 * @param onError
 * @return {{generateExport: generateExport}}
 */
export function useExport ({ onComplete, onError }) {
  const [exporting, setExporting] = useState(false)

  const generateExport = ({ client, variables, query, dataPath, formatter, fileName, extraData = [] }) => {
    if (!client || !variables || !query || !dataPath || !formatter || !fileName) {
      return onError && onError(new Error('missing required params'))
    }

    setExporting(true)

    client.query({
      query,
      variables,
      fetchPolicy: 'no-cache'
    }).then((response) => {
      if (response && get(response, dataPath)) {
        exportCSV(flatten([].concat(extraData, (get(response, dataPath) || []).map(formatter))), fileName)
          .then(() => {
            setExporting(false)
            onComplete && onComplete()
          }).catch((e) => {
            setExporting(false)
            onError && onError(e)
          }
          )
      } else {
        setExporting(false)
        onError && onError(new Error('Unable to get data'))
      }
    })
  }
  return { generateExport, exporting }
}
