import {
  GET_INSTITUTION_CONTACTS_REQUEST,
  GET_INSTITUTION_CONTACTS_SUCCESS,
  GET_INSTITUTION_CONTACTS_FAILURE,
  RESET_CONTACT_GRID,
  statusType
} from '../../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const grid = (state = initial, action) => {
  const { payload = {}, meta = {} } = action

  switch (action.type) {
    case GET_INSTITUTION_CONTACTS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_INSTITUTION_CONTACTS_SUCCESS:
      return {
        ...state,
        data: (payload || [])
          .filter((contact) => contact)
          .map((contact) => {
            const job = contact.jobs && contact.jobs[0]
            return {
              _id: contact._id,
              entityId: contact._id,
              name: contact.full_name,
              title: contact.title,
              institutionId: job && job.q4_entity_id,
              location: job && [job.city, job.state_province].filter((location) => location).join(', '),
              investmentFocus: job && job['investment_focus'],
              currentHolding: contact.currentHolding,
              phone: job && job.phone,
              email: job && job.email,
              direct_phone: contact.direct_phone,
              mobile: contact.mobile,
              phone_extension: contact.phone_extension,
              direct_email: contact.email,
              lastActivity: contact.lastActivity && contact.lastActivity.date,
              _deal: contact._deal,
              _target: contact._target,
              source: contact.source,
              entityType: 'contact'
            }
          }),
        dataTotal: meta.total,
        status: statusType.SUCCESS
      }

    case GET_INSTITUTION_CONTACTS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }
    case RESET_CONTACT_GRID: {
      return {
        ...initial
      }
    }
    default:
      return state
  }
}

export default grid
