import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { EditorState, Modifier } from 'draft-js'
import { selectAllContent } from '../../../../utils/htmlEditor.util'
import { THEMES } from '../../../../utils/ui'
import { Select } from '../../../index'

/**
 * React-Draft-Wysiwyg Component to control fontSize
 *
 * @see { @link: https://jpuri.github.io/react-draft-wysiwyg/#/docs } - React Wrapper around DraftJS
 * @see { @link: https://draftjs.org/docs/api-reference-editor-state } - EditorState API
 * @see { @link: https://draftjs.org/docs/api-reference-modifier } - Modifier API
 */
function FontSizeControls ({ fontSizeOptions, selectedFontSize, handleFontSizeChange, onChange, editorState }) {
  const options = getOptions(fontSizeOptions)
  const value = getValue(selectedFontSize, options)

  /**
   * Applies font size option to all content in the editor
   */
  function setFontSize ({ value: fontSizeOption }) {
    const selection = selectAllContent(editorState)
    let newEditorState = EditorState.acceptSelection(editorState, selection)

    // remove current fontSize
    if (selectedFontSize) {
      newEditorState = EditorState.createWithContent(
        Modifier.removeInlineStyle(
          newEditorState.getCurrentContent(),
          selection,
          fontSizeOptions[selectedFontSize]
        )
      )
    }

    // update state
    handleFontSizeChange(fontSizeOption)

    // apply styles to content
    onChange(EditorState.push(
      newEditorState,
      Modifier.applyInlineStyle(
        newEditorState.getCurrentContent(),
        selection,
        fontSizeOptions[fontSizeOption]
      ),
      'change-inline-style'
    ))
  }

  /**
   * Helper function to get value for AutoComplete
   */
  function getValue (selectedValue, options) {
    return options.find((option) => option.value === selectedValue)
  }

  /**
   * Helper function to format options for AutoComplete
   */
  function getOptions (options) {
    return Object.entries(options)
      .map(([key]) => ({ value: key, label: key }))
  }

  return (
    <Select
      theme={THEMES.STEEL}
      size='thin'
      value={value || ''}
      options={options}
      placeholder='Heading'
      onChange={setFontSize}
      searchable={false}
      clearable={false}
    />
  )
}

FontSizeControls.propTypes = {
  selectedFontSize: PropTypes.string,
  fontSizeOptions: PropTypes.object.isRequired,
  handleFontSizeChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  editorState: PropTypes.object.isRequired
}

export default memo(FontSizeControls)
