export const SENCHA_INITIALIZED = 'SENCHA_INITIALIZED'
export const POST_MESSAGE_REQUEST = 'POST_MESSAGE_REQUEST'
export const POST_MESSAGE_SUCCESS = 'POST_MESSAGE_SUCCESS'

/**
 * User logged out
 * @private
 */
const _notifySenchaInitialized = () => ({
  type: SENCHA_INITIALIZED
})

/**
 * Create request to message sencha
 * @param type
 * @param params
 * @private
 */
const _messageSencha = ({ type, params }) => ({
  type: POST_MESSAGE_REQUEST,
  payload: {
    source: 'react',
    type,
    params
  }
})

/**
 * Clear message from redux state after postMessage sent
 * @param type
 * @param params
 * @private
 */
const _clearMessage = () => ({
  type: POST_MESSAGE_SUCCESS
})

/**
 * Dispatch SENCHA_INITIALIZED
 */
export const notifySenchaInitialized = () => (dispatch) => {
  return dispatch(_notifySenchaInitialized())
}

/**
 *  Dispatch POST_MESSAGE_REQUEST
 * @param params
 */
export const messageSencha = (params) => (dispatch) => {
  return dispatch(_messageSencha(params))
}

/**
 * Dispatch POST_MESSAGE_SUCCESS
 */
export const clearMessage = (params) => (dispatch) => {
  return dispatch(_clearMessage())
}
