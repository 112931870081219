import {
  ADD_CONTACT_TO_ADDRESS_BOOK_REQUEST,
  ADD_CONTACT_TO_ADDRESS_BOOK_SUCCESS,
  ADD_CONTACT_TO_ADDRESS_BOOK_FAILURE,
  REMOVE_CONTACT_FROM_ADDRESS_BOOK_REQUEST,
  REMOVE_CONTACT_FROM_ADDRESS_BOOK_SUCCESS,
  REMOVE_CONTACT_FROM_ADDRESS_BOOK_FAILURE,
  GET_SAVED_CONTACT_REQUEST,
  GET_SAVED_CONTACT_SUCCESS,
  GET_SAVED_CONTACT_FAILURE,
  UPDATE_SAVED_CONTACT_LIST_REQUEST,
  UPDATE_SAVED_CONTACT_LIST_SUCCESS,
  UPDATE_SAVED_CONTACT_LIST_FAILURE,
  RESET_SAVED_CONTACT,
  statusType
} from '../../../actions'

const initial = {
  data: null,
  status: statusType.IDLE
}

const favorite = (state = initial, action) => {
  const { payload = {} } = action

  switch (action.type) {
    case ADD_CONTACT_TO_ADDRESS_BOOK_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case ADD_CONTACT_TO_ADDRESS_BOOK_SUCCESS:
      return {
        ...state,
        data: payload,
        status: statusType.SUCCESS
      }

    case ADD_CONTACT_TO_ADDRESS_BOOK_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case REMOVE_CONTACT_FROM_ADDRESS_BOOK_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case REMOVE_CONTACT_FROM_ADDRESS_BOOK_SUCCESS:
      return {
        ...state,
        data: null,
        status: statusType.SUCCESS
      }

    case REMOVE_CONTACT_FROM_ADDRESS_BOOK_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case GET_SAVED_CONTACT_REQUEST:
      return {
        ...state,
        status: statusType.SUCCESS
      }

    case GET_SAVED_CONTACT_SUCCESS:
      return {
        ...state,
        data: payload,
        status: statusType.SUCCESS
      }

    case GET_SAVED_CONTACT_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case UPDATE_SAVED_CONTACT_LIST_REQUEST:
      return {
        ...state,
        status: statusType.SUCCESS
      }

    case UPDATE_SAVED_CONTACT_LIST_SUCCESS:
      return {
        ...state,
        data: payload,
        status: statusType.SUCCESS
      }

    case UPDATE_SAVED_CONTACT_LIST_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case RESET_SAVED_CONTACT:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default favorite
