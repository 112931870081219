import {
  GET_REPORT_DATA_ITEM_REQUEST,
  GET_REPORT_DATA_ITEM_SUCCESS,
  GET_REPORT_DATA_ITEM_ERROR,
  STORE_REPORT_DATA_ITEM,
  SET_REPORT_DATA_ITEM_FETCHED,
  RESET_REPORT_DATA_ITEM_STATUS,
  RESET_REPORT_DATA_ITEM,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED, SAVE_REPORT_DATA_ITEM_SUCCESS
} from '../../actions'

const initial = {
  data: {},
  status: IDLE
}

const reportDataItem = (state = initial, action) => {
  switch (action.type) {
    case GET_REPORT_DATA_ITEM_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_REPORT_DATA_ITEM_SUCCESS:
    case STORE_REPORT_DATA_ITEM:
      return {
        ...state,
        data: action.payload,
        status: FETCHED
      }
    case GET_REPORT_DATA_ITEM_ERROR:
      return {
        ...state,
        status: FAILED
      }
    case SET_REPORT_DATA_ITEM_FETCHED:
      return {
        ...state,
        status: FETCHED
      }
    case RESET_REPORT_DATA_ITEM_STATUS:
      return {
        ...state,
        status: IDLE
      }
    case RESET_REPORT_DATA_ITEM:
      return {
        ...state,
        data: {},
        status: IDLE
      }
    case SAVE_REPORT_DATA_ITEM_SUCCESS:
      if (!action.payload || !action.payload._id) {
        return {
          ...state
        }
      }

      return {
        ...state,
        data: action.payload,
        status: FETCHED
      }
    default:
      return state
  }
}

export default reportDataItem
