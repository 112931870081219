import { generate } from 'shortid'
import { getUnwindData, widgetMinH, widgetMinW } from '../../utils/report'
import {
  GET_REPORT_BUILDER_ITEM_REQUEST,
  GET_REPORT_BUILDER_ITEM_SUCCESS,
  GET_REPORT_BUILDER_ITEM_ERROR,
  STORE_REPORT_BUILDER_ITEM,
  SET_REPORT_BUILDER_ITEM_FETCHED,
  RESET_REPORT_BUILDER_ITEM_STATUS,
  GET_REPORT_SNAPSHOT_SUCCESS,
  REMOVE_REPORT_BUILDER_PAGES_THUMBNAIL,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../actions'
import { convertBase64ToBlob } from '../../utils/browser.util'

const initial = {
  data: {
    pages: []
  },
  status: IDLE
}

const reportBuilderItem = (state = initial, action) => {
  switch (action.type) {
    case GET_REPORT_BUILDER_ITEM_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_REPORT_BUILDER_ITEM_SUCCESS:
      const data = action.payload

      return {
        ...state,
        data: {
          ...data,
          pages: data.pages.map((page) => {
            return {
              ...page,
              thumbnail: page.thumbnail ? convertBase64ToBlob(page.thumbnail) : page.thumbnail,
              widget: page.widget ? page.widget.map((eachWidget) => {
                eachWidget.unwoundData = (eachWidget.widgetType !== 'text' && getUnwindData(eachWidget.data)) || eachWidget.data
                return eachWidget
              }) : page.widget,
              layout: {
                template: page.layout.template.map((layoutItem) => {
                  return {
                    ...layoutItem,
                    minW: widgetMinW,
                    minH: widgetMinH
                  }
                })
              }
            }
          })
        },
        status: FETCHED
      }
    case STORE_REPORT_BUILDER_ITEM:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    case GET_REPORT_BUILDER_ITEM_ERROR:
      return {
        ...state,
        status: FAILED
      }
    case SET_REPORT_BUILDER_ITEM_FETCHED:
      return {
        ...state,
        status: FETCHED
      }
    case RESET_REPORT_BUILDER_ITEM_STATUS:
      return {
        ...state,
        status: IDLE
      }
    case GET_REPORT_SNAPSHOT_SUCCESS:
      const { meta, payload } = action

      const pages = (state.data.pages || []).map((page) => {
        if ((page.widget || []).find((widget) => widget._id === meta._id)) {
          return {
            ...page,
            widget: page.widget.map((widget) => {
              if (widget._id === meta._id) {
                return {
                  ...widget,
                  unwoundData: getUnwindData(payload),
                  data: payload,
                  meta: {
                    ...meta,
                    _id: generate()
                  }
                }
              }

              return widget
            })
          }
        }

        return page
      })

      return {
        ...state,
        data: {
          ...state.data,
          pages
        }
      }
    case REMOVE_REPORT_BUILDER_PAGES_THUMBNAIL: {
      const { pageId } = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          pages: (state.data.pages || []).map((page) => {
            if ([page._id, page.tempId].includes(pageId)) {
              page.thumbnail = ''
              return { ...page }
            }
            return page
          })
        }
      }
    }

    default:
      return state
  }
}

export default reportBuilderItem
