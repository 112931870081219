import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { addQueryParams } from '../../../utils/http/http.util'
import { push } from 'connected-react-router'

export const FETCH_STOCKQUOTE_REQUEST = 'FETCH_STOCKQUOTE_REQUEST'
export const FETCH_STOCKQUOTE_SUCCESS = 'FETCH_STOCKQUOTE_SUCCESS'
export const FETCH_STOCKQUOTE_ERROR = 'FETCH_STOCKQUOTE_ERROR'

export const FETCH_STOCKQUOTE_HISTORICAL_REQUEST = 'FETCH_STOCKQUOTE_HISTORICAL_REQUEST'
export const FETCH_STOCKQUOTE_HISTORICAL_SUCCESS = 'FETCH_STOCKQUOTE_HISTORICAL_SUCCESS'
export const FETCH_STOCKQUOTE_HISTORICAL_ERROR = 'FETCH_STOCKQUOTE_HISTORICAL_ERROR'

const resources = {
  delay: '/stock',
  historical: '/stock/historical'
}

const fetchStockQuote = (url) => ({
  [CALL_API]: {
    types: [FETCH_STOCKQUOTE_REQUEST, FETCH_STOCKQUOTE_SUCCESS, FETCH_STOCKQUOTE_ERROR],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
})

const fetchStockQuoteHistorical = (url) => ({
  [CALL_API]: {
    types: [FETCH_STOCKQUOTE_HISTORICAL_REQUEST, FETCH_STOCKQUOTE_HISTORICAL_SUCCESS, FETCH_STOCKQUOTE_HISTORICAL_ERROR],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
})

const stockError = (error) => ({
  type: error.type,
  error: error.error,
  status: error.status
})

export const loadStockQuote = (params) => (dispatch, getState) => {
  const state = getState()
  const securityId = params.securityId || state.dashboard.dashboardState.securityId
  if (!securityId) {
    return Promise.all([
      dispatch(stockError({
        type: FETCH_STOCKQUOTE_ERROR,
        error: 'You must provide a valid securityId',
        status: 404
      })),
      dispatch(push('/error'))
    ])
  }

  return dispatch(fetchStockQuote(`${resources.delay}/${securityId}`))
}

export const loadStockQuoteHistorical = (params) => (dispatch, getState) => {
  const state = getState()
  const securityId = params.securityId || state.dashboard.dashboardState.securityId
  if (!securityId) {
    return Promise.all([dispatch(stockError({
      type: FETCH_STOCKQUOTE_HISTORICAL_ERROR,
      error: 'You must provide a valid securityId',
      status: 404
    })), dispatch(push('/error'))
    ])
  }

  const url = addQueryParams(resources.historical, { securityId, startDate: params.startDate, endDate: params.endDate })
  return dispatch(fetchStockQuoteHistorical(url))
}
