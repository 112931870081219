import { combineReducers } from 'redux'
import commentary from './commentary.reducer'
import analysis from './analysis.reducer'
import peers from './peers.reducer'

export default combineReducers({
  commentaryState: commentary,
  analysis,
  peers
})
