import { formatDate } from '../../../../../../utils'
import { get } from 'lodash'

const EMPTY_PLACEHOLDER = '-'

const CustomCell = ({ data = {}, column = {} }) => {
  const cellData = data[column.colId]

  switch (column.colId) {
    case 'date':
      return formatDate(cellData, 'MM/DD/YY', null, true) || EMPTY_PLACEHOLDER
    case 'time':
      return formatDate(cellData, 'h:mma') || EMPTY_PLACEHOLDER
    case 'attendeeStats':
      return get(data, 'attendeeStats.attended_count') || EMPTY_PLACEHOLDER
    case 'registrationStats':
      return get(data, 'registrationStats.registrant_count') || EMPTY_PLACEHOLDER
    default:
      return cellData || EMPTY_PLACEHOLDER
  }
}

export default CustomCell
