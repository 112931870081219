import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Select } from '../index'
import { THEMES } from '../../utils/ui'
import { getTimeZones, getCompatibleTimeZone, guessTimeZone } from '../../utils'
import './timezonePicker.component.css'

const propTypes = {
  timezone: PropTypes.string,
  theme: PropTypes.string,
  onSelect: PropTypes.func,
  date: PropTypes.instanceOf(Date)
}

const defaultProps = {
  date: new Date(),
  theme: THEMES.RAIN,
  timezone: guessTimeZone()
}

/**
 * Timezone Picker Component
 * @param app
 */
const TimezonePicker = ({ date, timezone, onSelect, theme }) => {

  const [timezones, setTimezones] = useState([])
  const [timeZoneOption, setTimezoneOption] = useState({})

  useEffect(() => {
    const timeZones = getTimeZones(date)
    const compatibleTimeZone = getCompatibleTimeZone({ date_time: date, timezone })
    setTimezones(timeZones)
    setTimezoneOption(timeZones.find((each) => each.value === compatibleTimeZone))
  }, [date, timezone])

  return (
    <Select
      className='timezone-picker'
      theme={theme}
      value={timeZoneOption}
      options={timezones}
      onChange={(option) => option.value && onSelect(option.value)}
      searchable
      clearable={false}
    />
  )
}

TimezonePicker.propTypes = propTypes
TimezonePicker.defaultProps = defaultProps

export default memo(TimezonePicker)
