import React from 'react'
import moment from 'moment-timezone'

import { ExpandableCell } from '../../../../../../../components/agGrid/cell'
import { guessTimeZone, getCompatibleTimeZoneOffSet, formatDate } from '../../../../../../../utils'
const EMPTY_PLACEHOLDER = '-'

const CustomCell = ({ data = {}, column = {}, rowIndex, onExpandMenuClick }) => {
  const cellData = data[column.colId]

  switch (column.colId) {
    case 'start_date':
      const { date_time } = cellData
      return date_time ? formatDate(date_time, 'MM/DD/YYYY', false, true) : EMPTY_PLACEHOLDER
    case 'time':
      if (!cellData.start) {
        return EMPTY_PLACEHOLDER
      }
      const start = cellData && cellData.start
      const end = cellData && cellData.end
      const startTimezone = start.timezone ? start.timezone : guessTimeZone()
      const endTimezone = end.timezone ? end.timezone : guessTimeZone()
      const startTzOffset = `(GMT${getCompatibleTimeZoneOffSet(start)})`
      const endTzOffset = `(GMT${getCompatibleTimeZoneOffSet(end)})`

      const startTime = moment.utc(start.date_time).tz(startTimezone).format('hh:mma')
      const endTime = (end.date_time) ? ' - ' + moment.utc(end.date_time).tz(endTimezone).format('hh:mma') : ''
      return `${startTime} ${startTzOffset}${endTime} ${endTzOffset}`
    case 'title':
      return cellData || EMPTY_PLACEHOLDER
    case 'contact':
      return (
        <ExpandableCell
          items={cellData}
          label='full_name'
          onClick={onExpandMenuClick}
        />
      )
    case 'institution':
      return (
        <ExpandableCell
          items={cellData}
          label='institution_name'
          onClick={onExpandMenuClick}
        />
      )
    case 'participants':
      return (
        <ExpandableCell
          items={cellData}
          label='full_name'
          onClick={onExpandMenuClick}
        />
      )
    case 'location':
      return cellData || EMPTY_PLACEHOLDER
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
