import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {DateRangePicker} from '../../../../../../../components';
import {RadioButton} from '../../../../../../../components/shared/form';
import {convertStringToBoolean, THEMES} from '../../../../../../../utils';

/**
 * Logged Activity Filter
 */
class LoggedActivity extends PureComponent {

    /**
     * Handle Change
     * @param event
     */
    handleChange = (event) => {
        const {value} = event.currentTarget;
        const {onChange, onDateRangeChange} = this.props;

        if (value === 'all') {
            onDateRangeChange({startDate: null, endDate: null});
        }

        onChange(value);
    };

    /**
     * Trigger filter update for Date Range (start and end) change
     * @param value
     */
    onDateChange = (value) => {
        const {onDateRangeChange} = this.props;

        onDateRangeChange({
            startDate: Array.isArray(value) ? value[0] : null,
            endDate: Array.isArray(value) ? value[1] : null
        });
    };

    /**
     * Render
     * @return {*}
     */
    render() {
        const {value, startDate, endDate} = this.props;

        return (
            <React.Fragment>
                <div className='field field--full field--radio'>
                    <label className='field_label'>Logged Activity</label>
                    <RadioButton
                        className='radio-button--inline'
                        id='loggedActivityAll'
                        value='all'
                        label='All'
                        name='logged_activity'
                        isSelected={value === 'all'}
                        onChange={this.handleChange}
                    />
                    <RadioButton
                        className='radio-button--inline'
                        id='loggedActivityYes'
                        value='true'
                        label='Yes'
                        name='logged_activity'
                        isSelected={(value !== 'all') && convertStringToBoolean(value)}
                        onChange={this.handleChange}
                    />
                    <RadioButton
                        className='radio-button--inline'
                        id='loggedActivityNo'
                        value='false'
                        label='No'
                        name='logged_activity'
                        isSelected={(value !== 'all') && !convertStringToBoolean(value)}
                        onChange={this.handleChange}
                    />
                </div>

                <div className='field field--full field--text'>
                    <DateRangePicker
                        theme={THEMES.WHITE}
                        clearable
                        disabled={value === 'all'}
                        value={[startDate, endDate]}
                        onChange={this.onDateChange}/>
                </div>
            </React.Fragment>
        );
    }
}

LoggedActivity.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    onChange: PropTypes.func.isRequired,
    startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default LoggedActivity;
