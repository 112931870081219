import {
  SET_TOKEN,
  statusType
} from '../../actions'

const initial = {
  data: {},
  status: statusType.IDLE
}

const token = (state = initial, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        type: action.type,
        data: action.payload,
        status: statusType.SUCCESS
      }

    default:
      return state
  }
}

export default token
