/**
 * Get Local Storage Item
 * Will return parsed if applicable
 * @param key
 * @returns {*}
 */
export function getLocalStorageItem (key) {
  const localStorageItem = localStorage.getItem(key)

  if (!localStorageItem) {
    return null
  }

  let parsedLsItem

  try {
    parsedLsItem = JSON.parse(localStorageItem)
  } catch (e) {
    parsedLsItem = localStorageItem
  }
  return parsedLsItem
}

/**
 * Get Page Size from Local Storage
 * @param pageSizeId
 */
export function getPageSizeFromStorage (pageSizeId) {
  const storage = getLocalStorageItem('pagination')

  if (!storage || !storage[pageSizeId]) {
    return false
  }

  return storage[pageSizeId]
}
