import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { ENTITY_TYPE, OWNERSHIP_TYPE } from '../../../utils'

const GET_INSTITUTION_TOP_INDUSTRY = gql`
  query TopInstitutionIndustry(
    $entityId: String!
    $entityType: String!
    $industry: [String!]
    $currencyCode: String
    $source: String = "${OWNERSHIP_TYPE['13F']}"
    $limit: Int!
  ) {
    buy: mover(
      entityId: $entityId
      entityType: $entityType
      currencyCode: $currencyCode
      moverType: "buy"
      filter: {
        securityIndustry: $industry
      }
      limit: $limit
    ) {
      items {
        securityId
        securityName
        percentPortfolio
        marketValue
        marketValueChange
      }
    }
    sell: mover(
      entityId: $entityId
      entityType: $entityType
      currencyCode: $currencyCode
      moverType: "sell"
      filter: {
        securityIndustry: $industry
      }
      limit: $limit
    ) {
      items {
        securityId
        securityName
        percentPortfolio
        marketValue
        marketValueChange
      }
    }
    holding: instHoldingCurrent(
      institutionId: [$entityId]
      source: $source
      currencyCode: $currencyCode
      filter: {
        securityIndustry: $industry
      }
      sortBy: "marketValue"
      limit: $limit
    ) {
      items {
        securityId
        securityName
        percentPortfolio
        marketValue
      }
    }
  }`

const GET_FUND_TOP_INDUSTRY = gql`
  query TopFundIndustry(
    $entityId: String!
    $entityType: String!
    $industry: [String]!
    $currencyCode: String
    $limit: Int!
    $source: String = "${OWNERSHIP_TYPE['13F']}"
  ) {
    buy: mover(
      entityId: $entityId
      entityType: $entityType
      currencyCode: $currencyCode
      moverType: "buy"
      filter: {
        securityIndustry: $industry
      }
      limit: $limit
    ) {
      items {
        securityId
        securityName
        percentPortfolio
        marketValue
        marketValueChange
      }
    }
    sell: mover(
      entityId: $entityId
      entityType: $entityType
      currencyCode: $currencyCode
      moverType: "sell"
      filter: {
        securityIndustry: $industry
      }
      limit: $limit
    ) {
      items {
        securityId
        securityName
        percentPortfolio
        marketValue
        marketValueChange
      }
    }
    holding: fundHoldingCurrent(
      fundId: [$entityId]
      source: $source
      currencyCode: $currencyCode
      filter: {
        securityIndustry: $industry
      }
      sortBy: "marketValue"
      limit: $limit
    ) {
      items {
        securityId
        securityName
        percentPortfolio
        marketValue
      }
    }
  }`

/**
 * Industry Analysis
 * @param type
 * @param options
 */
export function useTopIndustryQuery (type, options) {
  const query = (type === ENTITY_TYPE.FUND) ? GET_FUND_TOP_INDUSTRY : GET_INSTITUTION_TOP_INDUSTRY
  return useQuery(query, options)
}
