import gql from 'graphql-tag'
import { get } from 'lodash'
import client from '../../middleware/graphql.middleware'

export const EVENT_SECURITY_SEARCH_REQUEST = 'EVENT_SECURITY_SEARCH_REQUEST'
export const EVENT_SECURITY_SEARCH_SUCCESS = 'EVENT_SECURITY_SEARCH_SUCCESS'
export const EVENT_SECURITY_SEARCH_FAILURE = 'EVENT_SECURITY_SEARCH_FAILURE'
export const EVENT_SECURITY_SEARCH_RESET = 'EVENT_SECURITY_SEARCH_RESET'

/**
 * Get Security Suggestions for event filter
 * @param query - current filters
 */
export const searchEventSecurity = (query) => (dispatch) => {
  
  // ignore empty query or query with only spaces
  if (!query || query.trim().length === 0) {
    return
  }

  return dispatch(_searchEventSecurity(query))

  // return dispatch(getQuickSearch({
  //   type: 'security',
  //   limit: 20,
  //   query
  // }, {
  //   types: [
  //     EVENT_SECURITY_SEARCH_REQUEST,
  //     EVENT_SECURITY_SEARCH_SUCCESS,
  //     EVENT_SECURITY_SEARCH_FAILURE
  //   ]
  // },
  //   'v3'
  // ))
}

const _searchEventSecurity = (query) => (dispatch) => {
  dispatch({ type: EVENT_SECURITY_SEARCH_REQUEST })
  return client
    .query({
      query: EVENT_SECURITY_SEARCH_QUERY,
      variables: {
        search: query,
        page: 1,
        limit: 10
      },
      fetchPolicy: 'no-cache'
    })
    .then((data) => {
      const searchResults = get(data, 'data.eventsSecuritySearch.items')
      dispatch({
        payload: searchResults,
        type: EVENT_SECURITY_SEARCH_SUCCESS
      })
    })
    .catch((error) => {
      dispatch({ error, type: EVENT_SECURITY_SEARCH_FAILURE })
    })
}

/**
 * Dispatch EVENT_SECURITY_SEARCH_RESET
 */
export const resetEventSecuritySuggestions = () => (dispatch) => {
  return dispatch({ type: EVENT_SECURITY_SEARCH_RESET })
}

const EVENT_SECURITY_SEARCH_QUERY = gql`
  query EventsSecuritySearch($search: String!) {
    eventsSecuritySearch(search: $search) {
      items {
        id
        symbol
        exchange
        tickerId
        companyName
    }
    }
  }
`
