import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

/**
 * Quick Search Action Type Constants
 * @type {string}
 */
export const GET_ENTITY_SEARCH_REQUEST = 'GET_ENTITY_SEARCH_REQUEST'
export const GET_ENTITY_SEARCH_SUCCESS = 'GET_ENTITY_SEARCH_SUCCESS'
export const GET_ENTITY_SEARCH_FAILURE = 'GET_ENTITY_SEARCH_FAILURE'
export const ENTITY_SEARCH_CLEAR = 'ENTITY_SEARCH_CLEAR'

const _searchEntity = (params) => ({
  [CALL_API]: {
    types: [
      GET_ENTITY_SEARCH_REQUEST,
      GET_ENTITY_SEARCH_SUCCESS,
      GET_ENTITY_SEARCH_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/search/v2/entity',
    payload: Object.assign({}, {
      page: 1,
      limit: 10
    }, params)
  }
})

const _searchEntityClear = () => ({
  type: ENTITY_SEARCH_CLEAR
})

/**
 *
 * @param params {Object} Search parameters
 * @param params.type {String} The entity type, institution, contact or fund
 * @param params.query {String} The search query
 * @param params.page {Number} Pagination number of pages to load
 * @param params.start {Number} Pagination start page
 * @param params.limit {Number} Pagination page size
 * @param params.limit {Number} Pagination page size
 * @returns {Object}
 */
export const searchEntity = (params) => (dispatch) => {
  return dispatch(_searchEntity(params))
}

export const searchEntityClear = () => (dispatch) => {
  return dispatch(_searchEntityClear())
}
