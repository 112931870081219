import { getSecQuickSearch } from '../search/securitySearch.actions'

export const UPDATE_CALENDAR_FILTER = 'UPDATE_CALENDAR_FILTER'
export const RESET_CALENDAR_FILTER = 'RESET_CALENDAR_FILTER'

export const GET_SECURITY_FILTER_SUGGESTIONS_REQUEST = 'GET_SECURITY_FILTER_SUGGESTIONS_REQUEST'
export const GET_SECURITY_FILTER_SUGGESTIONS_SUCCESS = 'GET_SECURITY_FILTER_SUGGESTIONS_SUCCESS'
export const GET_SECURITY_FILTER_SUGGESTIONS_FAILURE = 'GET_SECURITY_FILTER_SUGGESTIONS_FAILURE'
export const RESET_SECURITY_FILTER_SUGGESTIONS = 'RESET_SECURITY_FILTER_SUGGESTIONS'

/**
 * Update Calendar Filters
 * @param filter - new/updated filter values
 * @return {{payload: *, type: string}}
 */
export const updateCalendarFilter = (filter) => {
  return {
    type: UPDATE_CALENDAR_FILTER,
    payload: filter
  }
}

/**
 * Update Calendar Filters
 * @param filters - current filters
 */
export const resetCalendarFilter = () => (dispatch) => {
  return dispatch({ type: RESET_CALENDAR_FILTER })
}

/**
 * Get Security Filter Suggestions
 * @param filters - current filters
 */
export const getSecurityFilterSuggestions = (query) => (dispatch) => {
  return dispatch(getSecQuickSearch({
    type: 'security',
    limit: 5,
    query
  }, {
    types: [
      GET_SECURITY_FILTER_SUGGESTIONS_REQUEST,
      GET_SECURITY_FILTER_SUGGESTIONS_SUCCESS,
      GET_SECURITY_FILTER_SUGGESTIONS_FAILURE
    ]
  }))
}

/**
* Reset Security Filter Suggestions
* @param filters - current filters
*/
export const resetSecurityFilterSuggestions = () => (dispatch) => {
  return dispatch({ type: RESET_SECURITY_FILTER_SUGGESTIONS })
}
