import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { Message } from '../../../../../../../components';
import {RadioButton} from '../../../../../../../components/shared/form';

/**
 * Info Modal
 * @param onClose
 * @return {*}
 * @constructor
 */
const InfoModal = ({onClose}) => {
    return (
        <Message
            visible={true}
            type='info'
            title='Peer Activity'
            message={
                '<p>An Institution or Fund that is either a net buyer or net seller of stocks in your peers as defined by your peer list.</p>'
            }
            useHtml={true}
            onClose={onClose}
            renderExitButton={true}
            buttons={[{
                ui: 'citrus',
                label: 'Ok',
                onClick: onClose
            }]}
        />
    );
};

/**
 * Peer Activity Filter
 */
class PeerActivity extends PureComponent {

    /**
     * Handle Change
     * @param event
     */
    handleChange = (event) => {
        const {onChange} = this.props;
        onChange(event.currentTarget.value);
    };

    /**
     * Show Info Modal
     */
    onInfoClick = () => {
        const {openModal} = this.props;
        openModal({
            component: InfoModal
        });
    };

    /**
     * Render
     * @return {*}
     */
    render() {
        const {value} = this.props;

        return (
            <div className='field field--full field--radio'>
                <label className='field_label'>
                    Peer Activity
                    <span className='button--info button--soft-grey' onClick={this.onInfoClick}/>
                </label>
                <RadioButton
                    className='radio-button--inline'
                    id='peerActivityAll'
                    value='all'
                    label='All'
                    name='peer_activity'
                    isSelected={value === 'all'}
                    onChange={this.handleChange}
                />
                <RadioButton
                    className='radio-button--inline'
                    id='peerActivityBuyer'
                    value='buyer'
                    label='Net Buyer'
                    name='peer_activity'
                    isSelected={value === 'buyer'}
                    onChange={this.handleChange}
                />
                <RadioButton
                    className='radio-button--inline'
                    id='peerActivitySeller'
                    value='seller'
                    label='Net Seller'
                    name='peer_activity'
                    isSelected={value === 'seller'}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

PeerActivity.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired
};

export default PeerActivity;
