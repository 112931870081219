import React, { memo } from 'react'
import PropTypes from 'prop-types'

// utils
import { defaultIfEmpty, format } from '../../../../utils'

const EMPTY_PLACEHOLDER = '-'

const propTypes = {
  coverage: PropTypes.arrayOf(PropTypes.shape({
    securityId: PropTypes.string,
    securityName: PropTypes.string,
    eps: PropTypes.number,
    target: PropTypes.number,
    rating: PropTypes.string,
    date: PropTypes.string
  })).isRequired
}

const defaultProps = {
  coverage: []
}

/**
 * Coverage Grid Component
 * @param props
 */
function CoverageGrid ({ coverage }) {
  return (
    <div className='tearsheet_grid'>
      <div className='tearsheet_grid-row tearsheet_grid-row--header'>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--text'>Security</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--narrowest tearsheet_grid-cell--number'>Recent</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--narrowest tearsheet_grid-cell--number'>Target Price</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--narrowest tearsheet_grid-cell--center'>Rating</div>
      </div>
      {(coverage.length ? coverage : [{}]).map((item, index) => {
        const { securityId, securityName, eps, target, rating } = (item || {})

        return (
          <div className='tearsheet_grid-row' key={securityId || `coverage-${index}`}>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--text'>
              {defaultIfEmpty(securityName)}
            </div>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--narrowest tearsheet_grid-cell--number'>
              {format(eps, 2) || EMPTY_PLACEHOLDER}
            </div>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--narrowest tearsheet_grid-cell--number'>
              {format(target, 2) || EMPTY_PLACEHOLDER}
            </div>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--narrowest tearsheet_grid-cell--center'>
              {defaultIfEmpty(rating)}
            </div>
          </div>
        )
      })}
    </div>
  )
}

CoverageGrid.propTypes = propTypes
CoverageGrid.defaultProps = defaultProps

export default memo(CoverageGrid)
