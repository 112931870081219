import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'

import {
  debounce,
  isFormDataValid,
  isConfirmationRequired,
  isVenueButNoAddress,
  getEntityValues,
  getFormSavePayload,
  getFormBuildItineraryPayload,
  getFormItineraryAddAnotherPayload
} from '../../../utils'

import {
  resetActivityForm,
  createActivity,
  createActivityBuildItinerary,
  createActivityAddAnother,
  updateActivity,
  updateActivityBuildItinerary,
  updateActivityAddAnother,
  searchLocation,
  clearSearchData,
  searchDeals,
  addContactsToAddressBook,
  SAVE_ACTIVITY_SUCCESS,
  SAVE_ACTIVITY_SUCCESS_BUILD_ITINERARY,
  SAVE_ACTIVITY_SUCCESS_ADD_ANOTHER,
  SAVE_CONTACTS_SUCCESS
} from '../../../actions/activity'
import {
  searchEntity,
  searchEntityClear
} from '../../../actions/search/entity.actions'
import { searchParticipants } from '../../../actions/corporateParticipant'
import { searchTags } from '../../../actions/tag'
import { Message } from '../../../components'
import {
  createToast,
  messageSencha,
  statusType
} from '../../../actions'
import ActivityForm from '../../../components/activity/form/activityForm.component'

class ActivityFormContainer extends Component {
  constructor (props) {
    super(props)
    const { values, isLoading, isFieldsHidden, activityState } = props

    this.state = {
      isEdit: false,
      isLoading: !!isLoading,
      close: false,
      error: activityState.error,
      Confirmation: null,
      values: values || {},
      isFieldsHidden: (isFieldsHidden && !Array.isArray(isFieldsHidden) ? [isFieldsHidden] : isFieldsHidden || []),
      _deal: null
    }
  }

  componentDidMount () {
    const { values } = this.props

    if (values) {
      const isEdit = !!(values && values._id)

      this.setState({
        originalDate: values?.end
      })

      this.setState({
        values: getEntityValues(values, isEdit),
        isEdit
      })
    }
  }

  componentDidUpdate (prevProps) {
    const { activityState } = this.props
    const { close, error, _deal } = this.state

    if (activityState.status !== prevProps.activityState.status) {
      this.setState({
        isLoading: (activityState.status === statusType.IN_PROGRESS)
      })
    }

    if (activityState.status !== prevProps.activityState.status && activityState.status === statusType.SUCCESS) {
      this.setState({
        values: {
          ...activityState.data,
          _deal
        }
      })
    }

    if (activityState.error !== prevProps.activityState.error) {
      this.setState({
        error: activityState.error
      })
    }

    if (close && !error && activityState.status === statusType.SUCCESS) {
      this.onModalClose()
    }

    if (activityState.status !== prevProps.activityState.status &&
      activityState.status === statusType.SUCCESS &&
      [SAVE_ACTIVITY_SUCCESS_BUILD_ITINERARY, SAVE_ACTIVITY_SUCCESS_ADD_ANOTHER].includes(activityState.type)) {
      this.setState({
        isEdit: false
      })
    }
  }

  onSaveSuccess = (data, contactsSuccess = false) => {
    const { messageSencha, createToast, onSaveSuccess } = this.props
    const { _id, title } = data

    messageSencha({
      type: 'activitySave',
      params: {
        activity: data
      }
    })

    onSaveSuccess && onSaveSuccess(_id)

    createToast({
      text: contactsSuccess
        ? <>All selected contacts have been successfully added to the <Link to='/contact' onClick={this.onModalClose}>Address Book</Link></>
        : <><Link to={`/activity/${_id}`} onClick={this.onModalClose}>{title}</Link> saved successfully.</>,
      testId: contactsSuccess
        ? 'contact'
        : 'activity'
    })
  };

  onMessageClose = () => {
    this.setState({
      error: null
    })
  };

  onModalClose = (requireConfirmation = false) => {
    const { onClose, resetActivityForm } = this.props

    if (requireConfirmation === true) {
      return this.showDialogConfirmation(
        'Discard changes?',
        'Any unsaved changes will be lost.',
        this.onModalClose.bind(this, false)
      )
    }

    setTimeout(() => {
      resetActivityForm && resetActivityForm()
    }, 0)
    onClose && onClose()
  };

  showDialogConfirmation (title, text, fn, btnLabel = 'Proceed', close = false) {
    this.setState({
      Confirmation: this.renderConfirmation(title, text, fn, btnLabel, close)
    })
  }

  hideDialogConfirmation (close = false) {
    this.setState({
      Confirmation: null,
      close
    })
  }

  onSave = (activity, confirmed = false, close = false) => {
    const err = isFormDataValid(activity)
    const confirmation = (!confirmed ? isConfirmationRequired(activity, this.state.isEdit) : null)

    if (err) {
      return this.setState({
        error: err
      })
    }

    if (isVenueButNoAddress(activity)) {
      return
    }

    if (confirmation) {
      return this.showDialogConfirmation(
        confirmation.title,
        confirmation.text,
        this.onSave.bind(this, activity, true),
        confirmation.btnLabel,
        close
      )
    }
    
    const {
      createActivity: createActivityAction,
      updateActivity: updateActivityAction,
      addContactsToAddressBook: addContactsToAddressBookAction
    } = this.props

    const activityPayload = getFormSavePayload(activity)

    // Normalize only when EndDate was not manually updated
    const normalizeEndDateOptions = {
      apply: !activity.modifiedEndDate,
      originalDate: this.state.originalDate,
      affectedDate: activityPayload.end
    }

    const handler = activityPayload._id
      ? updateActivityAction.bind(this, activityPayload._id, activityPayload, normalizeEndDateOptions)
      : createActivityAction.bind(this, activityPayload)

    this.setState({
      close,
      _deal: activityPayload._deal || null
    })

    handler().then((response) => {
      if (activity.addressBookLinks && activity.addressBookLinks.length) {
        addContactsToAddressBookAction(activity.addressBookLinks).then((addressbookResponse) => {
          if (addressbookResponse.type === SAVE_CONTACTS_SUCCESS) {
            this.handleSaveResponse(response, true)
          }
        })
      }
      else {
        this.handleSaveResponse(response)
      }
    })
  };

  handleSaveResponse = (response, contactsSuccess = false) => {
    const success = [
      SAVE_ACTIVITY_SUCCESS,
      SAVE_ACTIVITY_SUCCESS_BUILD_ITINERARY,
      SAVE_ACTIVITY_SUCCESS_ADD_ANOTHER
    ].includes(response.type)

    success && this.onSaveSuccess(response.payload, contactsSuccess)
  };

  onSaveAndClose = (activity) => {
    this.onSave(activity, false, true)
  };

  onBuildItinerary = (activity, confirmed = false) => {
    const err = isFormDataValid(activity)
    const confirmation = (!confirmed ? isConfirmationRequired(activity) : null)

    if (err) {
      return this.setState({
        error: err
      })
    }

    if (confirmation) {
      return this.showDialogConfirmation(
        confirmation.title,
        confirmation.text,
        this.onBuildItinerary.bind(this, activity, true),
        confirmation.btnLabel
      )
    }

    const { createActivityBuildItinerary, updateActivityBuildItinerary } = this.props
    const activityPayload = getFormBuildItineraryPayload(activity)

    // Normalize only when EndDate was not manually updated
    const normalizeEndDateOptions = {
      apply: !activity.modifiedEndDate,
      originalDate: this.state.originalDate,
      affectedDate: activityPayload.end
    }

    const handler = activityPayload._id
      ? updateActivityBuildItinerary.bind(this, activityPayload._id, activityPayload, normalizeEndDateOptions)
      : createActivityBuildItinerary.bind(this, activityPayload)

    this.setState({
      _deal: null
    })

    handler().then((response) => {
      this.handleSaveResponse(response)
    })
  };

  onItineraryAddAnother = (activity, confirmed = false, cb) => {
    const err = isFormDataValid(activity)
    const confirmation = (!confirmed ? isConfirmationRequired(activity) : null)

    if (err) {
      return this.setState({
        error: err
      })
    }

    if (isVenueButNoAddress(activity)) {
      return
    }

    if (confirmation) {
      return this.showDialogConfirmation(
        confirmation.title,
        confirmation.text,
        this.onItineraryAddAnother.bind(this, activity, true, cb),
        confirmation.btnLabel
      )
    }

    const { createActivityAddAnother, updateActivityAddAnother, addContactsToAddressBook: addContactsToAddressBookAction } = this.props
    const activityPayload = getFormItineraryAddAnotherPayload(activity)
    const handler = activityPayload._id
      ? updateActivityAddAnother.bind(this, activityPayload._id, activityPayload)
      : createActivityAddAnother.bind(this, activityPayload)

    this.setState({
      _deal: null
    })
    handler().then((response) => {
      if (activity.addressBookLinks && activity.addressBookLinks.length) {
        addContactsToAddressBookAction(activity.addressBookLinks).then((addressbookResponse) => {
          if (addressbookResponse.type === SAVE_CONTACTS_SUCCESS) {
            this.handleSaveResponse(response, true)
            if (typeof cb === 'function') {
              cb()
            }
          }
        })
      }
      else {
        this.handleSaveResponse(response)
        if (typeof cb === 'function') {
          cb()
        }
      }
    })
  };

  renderMessage = (text, title, type) => {
    return () => {
      return (
        <Message
          visible={true}
          type={type || 'error'}
          title={title || 'Error'}
          message={text || 'Oops, something went wrong while trying to create this Activity. Please try again or contact us if you see this message again.'}
          buttons={[{
            ui: 'shaded',
            label: 'close',
            onClick: this.onMessageClose
          }]} />
      )
    }
  };

  renderConfirmation = (title, text, fn, btnLabel, close) => {
    return () => {
      return (
        <Message
          visible={true}
          type='warning'
          title={title}
          message={text}
          buttons={[
            {
              ui: 'shaded',
              label: 'Cancel',
              onClick: () => {
                this.hideDialogConfirmation()
              }
            },
            {
              ui: 'spice',
              label: btnLabel,
              onClick: () => {
                fn.call(this)
                this.hideDialogConfirmation(close)
              }
            }
          ]} />
      )
    }
  };

  onSearchLocation = debounce((query) => {
    this.props.searchLocation({
      query,
      page: 1,
      start: 0,
      limit: 20
    })
  });

  onSearchDeal = debounce((query) => {
    this.props.getDeals(query)
  });

  onSearchEntity = debounce((params) => {
    this.props.searchEntity(params)
  });

  onSearchEntityClear = () => {
    this.props.searchEntityClear()
  };

  onSearchParticipants = debounce((name) => {
    this.props.searchParticipants(name)
  });

  onSearchTags = debounce((tag) => {
    this.props.searchTags(tag)
  });

  clearSearchData = () => {
    this.props.clearSearchData()
  };

  render () {
    const {
      searchedTags,
      searchEntityState,
      corporateParticipants,
      deals,
      locations,
      activityState,
      isParentOptionHidden,
      profile
    } = this.props

    const {
      isLoading,
      error,
      Confirmation,
      values,
      isFieldsHidden,
      isEdit
    } = this.state
    const { isParent } = activityState
    const Message = this.renderMessage(error)

    return (
      <React.Fragment>
        <ActivityForm
          isFieldsHidden={isFieldsHidden}
          values={values}
          isEdit={isEdit}
          isParent={isParent}
          isParentOptionHidden={isParentOptionHidden}
          isLoading={isLoading}
          isModalVisible={true}
          onClose={this.onModalClose}
          onSave={this.onSave}
          onSaveAndClose={this.onSaveAndClose}
          onBuildItinerary={this.onBuildItinerary}
          onItineraryAddAnother={this.onItineraryAddAnother}
          getDeals={this.onSearchDeal}
          onSearchEntity={this.onSearchEntity}
          onSearchEntityClear={this.onSearchEntityClear}
          searchLocation={this.onSearchLocation}
          locations={locations}
          deals={deals}
          findTags={this.onSearchTags}
          searchedTags={searchedTags}
          searchEntityState={searchEntityState}
          activityFormState={activityState}
          findParticipants={this.onSearchParticipants}
          corporateParticipants={corporateParticipants}
          clearSearchData={this.clearSearchData}
          profile={profile}
        />
        {!!error && <Message />}
        {!!Confirmation && <Confirmation />}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { tag, activity, profile } = state
  return {
    activityState: state.activity.activityForm || {},
    corporateParticipants: activity.activityParticipants,
    searchedTags: (tag.search && tag.search.data) || [],
    searchEntityState: state.searchEntity || [],
    deals: activity.activityDeal || [],
    locations: activity.activityLocation || [],
    profile: profile.data || {}
  }
}

const mapDispatchToProps = (dispatch) => ({
  createActivity: bindActionCreators(createActivity, dispatch),
  createActivityBuildItinerary: bindActionCreators(createActivityBuildItinerary, dispatch),
  createActivityAddAnother: bindActionCreators(createActivityAddAnother, dispatch),
  updateActivity: bindActionCreators(updateActivity, dispatch),
  updateActivityBuildItinerary: bindActionCreators(updateActivityBuildItinerary, dispatch),
  updateActivityAddAnother: bindActionCreators(updateActivityAddAnother, dispatch),
  resetActivityForm: bindActionCreators(resetActivityForm, dispatch),
  searchLocation: bindActionCreators(searchLocation, dispatch),
  getDeals: bindActionCreators(searchDeals, dispatch),
  searchParticipants: bindActionCreators(searchParticipants, dispatch),
  clearSearchData: bindActionCreators(clearSearchData, dispatch),
  searchEntity: bindActionCreators(searchEntity, dispatch),
  searchTags: bindActionCreators(searchTags, dispatch),
  searchEntityClear: bindActionCreators(searchEntityClear, dispatch),
  messageSencha: bindActionCreators(messageSencha, dispatch),
  createToast: bindActionCreators(createToast, dispatch),
  addContactsToAddressBook: bindActionCreators(addContactsToAddressBook, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActivityFormContainer)
