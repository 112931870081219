import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import {
  renderDarkThumb,
  renderLightThumb,
  renderTrackHorizontal,
  renderTrackVertical
} from '../../../../resources/theme/q4.custom-scrollbar'
import { bindActionCreators } from 'redux'
import { FAILED, FETCHED, FETCHING, fetchInstitutionAnalytics } from '../../../../actions/widget/analytics'
import { setClassNames } from '../../../../utils/widget.util'
import { getFromXigniteToStandard } from '../../../../utils/stock/stock.util'
import InstitutionWebAnalytics
  from '../../../../components/widget/analytics/institutionWebAnalytics/institutionWebAnalytics.component'
import { Spinner } from '../../../../components'
import WidgetError from '../../../../components/widget/error/widgetError.component'
import _ from 'lodash'
import './institutionWebAnalytics.container.css'

class InstitutionWebAnalyticsContainer extends Component {

  constructor (props) {
    super(props)
    this.state = {
      timer: null
    }
  }

  /**
   * Triggered when component mounted
   * Enable auto-reloading if enabled
   */
  componentDidMount = () => {
    const { isEdit, status } = this.props
    if (isEdit && status === FETCHED) {
      return
    }

    const refreshInterval = this.props.options.refreshInterval
    if (refreshInterval) {
      this.setState({
        timer: setInterval(this.fetchData.bind(this), refreshInterval)
      })
    }

    this.fetchData()
  }

  /**
   * ComponentDidUpdate
   * Re-fetch data when security changed
   * @param prevProps
   */
  componentDidUpdate = (prevProps) => {
    if (prevProps.securityId !== this.props.securityId) {
      this.fetchData()
    }
  }

  /**
   * clear fetch interval on unmount
   */
  componentWillUnmount = () => {
    if (this.state.timer) {
      clearInterval(this.state.timer)
    }
  }

  /**
   * Fetch web analytics data
   */
  fetchData = () => {
    const params = {
      'start-date': '90daysAgo',
      'end-date': null
    }

    this.props.fetchInstitutionWebAnalytics(params)
  }

  render () {
    const { theme, layout, profile, status, history } = this.props
    const classes = setClassNames('institution-web-analytics-container', layout, theme)
    const tickers = (profile && profile._organization && (profile._organization.tickers || []))
    const is_loading = status === FETCHING
    const failed = status === FAILED
    const renderThumb = theme === 'dark' ? renderLightThumb : renderDarkThumb
    const isNarrowLayout = layout && layout.w === 1

    // get active company from organization's tickers, fallback to primary ticker
    const company = _.find((tickers), (ticker) => ticker._security === this.props.securityId)
      || _.find((tickers), (ticker) => ticker.primary)

    if (!is_loading && _.isEmpty(this.props.institutionAnalyticItems)) {
      return (
        <WidgetError
          theme={theme}
          header={'Institutions - Web Analytics'}
          message={'Looks like you don\'t have any analytics available.'}
        />
      )
    }

    if (failed) {
      return (
        <WidgetError
          theme={theme}
          header={'Institutions - Web Analytics'}
          message={'Looks like you don\'t have any analytics available.'}
        />
      )
    }

    return (
      <div className={classes.base}>
        <header className={`${classes.name}_header`}>
          <h2 className='institution-web-analytics-container_title'
              onClick={() => history.push('/analytics')}>
            Institutions - Web Analytics
          </h2>
          <span className='institution-web-analytics-container_company'>
                        {company ? `${company.symbol} ${getFromXigniteToStandard(company.exchange)}` : null}
                    </span>
        </header>
        <section className={`${classes.name}_body ${!isNarrowLayout ? `${classes.name}_body--wide` : ''}`}>
          {is_loading && _.isEmpty(this.props.institutionAnalyticItems) ?
            <Spinner/> :
            <Scrollbars
              className='react-scrollbar'
              autoHide
              hideTracksWhenNotNeeded
              renderThumbVertical={renderThumb}
              renderThumbHorizontal={renderThumb}
              renderTrackVertical={renderTrackVertical}
              renderTrackHorizontal={renderTrackHorizontal}
            >
              <div className={`${classes.name}_chart`}>
                <InstitutionWebAnalytics
                  items={this.props.institutionAnalyticItems}
                  colors={this.props.colors}
                  theme={this.props.theme}
                  layout={this.props.layout}
                />
              </div>
            </Scrollbars>
          }
        </section>
        <footer className={`${classes.name}_footer`}>
          <p>Based on the last 90 days of traffic.</p>
          <div className='institution-web-analytics-container_footer-detail'>
            <Link to={`/analytics`}>VIEW DETAIL</Link>
          </div>
        </footer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.dashboard.dashboardState.theme,
    securityId: state.dashboard.dashboardState.securityId,
    status: state.widget.analytics.institutionWebAnalytics.status,
    profile: state.shared.profile,
    institutionAnalyticItems: state.widget.analytics.institutionWebAnalytics.items || [],
    colors: state.widget.analytics.institutionWebAnalytics.colors || []
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInstitutionWebAnalytics: bindActionCreators(fetchInstitutionAnalytics, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InstitutionWebAnalyticsContainer))
