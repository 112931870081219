import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import gql from 'graphql-tag'
import { get } from 'lodash'
import client from '../../middleware/graphql.middleware'
import { SEARCH_TYPE, _prepareGqlVariables } from '../../utils'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../services/launchDarkly.service'

export const GET_ADVANCED_SEARCH_REQUEST = 'GET_ADVANCED_SEARCH_REQUEST'
export const GET_ADVANCED_SEARCH_SUCCESS = 'GET_ADVANCED_SEARCH_SUCCESS'
export const GET_ADVANCED_SEARCH_FAILURE = 'GET_ADVANCED_SEARCH_FAILURE'
export const GET_ADVANCED_SEARCH_V3_REQUEST = 'GET_ADVANCED_SEARCH_V3_REQUEST'
export const GET_ADVANCED_SEARCH_V3_SUCCESS = 'GET_ADVANCED_SEARCH_V3_SUCCESS'
export const GET_ADVANCED_SEARCH_V3_FAILURE = 'GET_ADVANCED_SEARCH_V3_FAILURE'
export const GET_ADVANCED_SEARCH_GQL_REQUEST = 'GET_ADVANCED_SEARCH_GQL_REQUEST'
export const GET_ADVANCED_SEARCH_GQL_SUCCESS = 'GET_ADVANCED_SEARCH_GQL_SUCCESS'
export const GET_ADVANCED_SEARCH_GQL_FAILURE = 'GET_ADVANCED_SEARCH_GQL_FAILURE'
export const GET_ADVANCED_SEARCH_FACETS_REQUEST = 'GET_ADVANCED_SEARCH_FACETS_REQUEST'
export const GET_ADVANCED_SEARCH_FACETS_SUCCESS = 'GET_ADVANCED_SEARCH_FACETS_SUCCESS'
export const GET_ADVANCED_SEARCH_FACETS_FAILURE = 'GET_ADVANCED_SEARCH_FACETS_FAILURE'
export const GET_ELASTIC_SEARCH_REQUEST = 'GET_ELASTIC_SEARCH_REQUEST'
export const GET_ELASTIC_SEARCH_SUCCESS = 'GET_ELASTIC_SEARCH_SUCCESS'
export const GET_ELASTIC_SEARCH_FAILURE = 'GET_ELASTIC_SEARCH_FAILURE'
export const GET_TAG_SEARCH_REQUEST = 'GET_TAG_SEARCH_REQUEST'
export const GET_TAG_SEARCH_SUCCESS = 'GET_TAG_SEARCH_SUCCESS'
export const GET_TAG_SEARCH_FAILURE = 'GET_TAG_SEARCH_FAILURE'
export const CLEAR_ADVANCED_SEARCH = 'CLEAR_ADVANCED_SEARCH'

/**
 * Get Advanced Search
 * @param params
 * @param options
 * @returns {{}}
 * @private
 * @deprecated
 */
const _getAdvancedSearch = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_ADVANCED_SEARCH_REQUEST,
      GET_ADVANCED_SEARCH_SUCCESS,
      GET_ADVANCED_SEARCH_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: params && params.securityId
      ? `/search/v2/advanced?securityId=${params.securityId}`
      : '/search/v2/advanced',
    payload: {
      limit: params.limit || 25,
      type: [].concat(params.type || []),
      page: params.page,
      filter: params.filter,
      sort: params.sort,
      query: params.query,
      ...params
    }
  }
})

/**
 * Get Advanced Search V3
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _getAdvancedSearchV3 = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_ADVANCED_SEARCH_V3_REQUEST,
      GET_ADVANCED_SEARCH_V3_SUCCESS,
      GET_ADVANCED_SEARCH_V3_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: params && params.securityId
      ? `/search/v3/advanced?securityId=${params.securityId}`
      : '/search/v3/advanced',
    payload: {
      limit: params.limit || 25,
      type: [].concat(params.type || []),
      page: params.page,
      filter: params.filter,
      sort: params.sort,
      query: params.query,
      ...params
    }
  }
})

/**
 * Get Advanced Search Facets
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _getAdvancedSearchFacets = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_ADVANCED_SEARCH_FACETS_REQUEST,
      GET_ADVANCED_SEARCH_FACETS_SUCCESS,
      GET_ADVANCED_SEARCH_FACETS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/search/v2/facets',
    payload: {
      entityType: [].concat(params.entityType || []),
      facet: [].concat(params.facet || [])
    }
  }
})

/**
 * Get Elastic Search
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _getElasticSearch = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_ELASTIC_SEARCH_REQUEST,
      GET_ELASTIC_SEARCH_SUCCESS,
      GET_ELASTIC_SEARCH_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/search',
    payload: {
      limit: params.limit || 25,
      type: [].concat(params.type || []),
      page: params.page,
      filter: params.filter,
      query: params.query,
      ...params
    }
  }
})

/**
 * Get Tag Search
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _getTagSearch = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_TAG_SEARCH_REQUEST,
      GET_TAG_SEARCH_SUCCESS,
      GET_TAG_SEARCH_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/tag/search',
    payload: {
      limit: params.limit || 25,
      type: [].concat(params.type || []),
      page: params.page,
      query: params.query,
      securityId: params.securityId
    }
  }
})

/**
 * Create specific query (choose required fragment) bases on filter variables
 * @param {*} variables
 * @returns
 */
const _generateGqlQuery = (variables) => {
  const { type } = variables || { type: [] }
  const includeFragments = {
    institutions: type.includes(SEARCH_TYPE.INSTITUTION),
    funds: type.includes(SEARCH_TYPE.FUND),
    contacts: type.includes(SEARCH_TYPE.CONTACT),
    securities: type.includes(SEARCH_TYPE.SECURITY)
  }

  const fundsFragment = gql` 
    fragment fundsFragment on FundSearchDTO{
      type
      fundName
      sourceId
      entity{
        id
        turnover
        style
        fundTypeDesc
        institutionName
      }
      purchasingPower{
        purchasingPower
      }
      position{
        current
      }
      target{
        id
      }
      deal{
        id
      }
    }
  `

  const securitiesFragment = gql` 
    fragment securitiesFragment on SecuritySearchDTO{
      type
      entity {
        securityName
        capGroup
        sector
        symbol
        exchangeCode
        industry
      }
    }
  `

  const contactsFragment = gql` 
    fragment contactsFragment on ContactSearchDTO{
      type
      fullName
      entity{
        source
        jobs{
          address1
          entityId
          institutionName
          title
          functions
          directPhone
          address1
          phone
          email
        }
      }  
      target{
        id
      }
      deal{
        id
      }
      favorite{
        id
      }
    }
  `

  const institutionsFragment = gql` 
    fragment institutionsFragment on InstitutionSearchDTO{
      type
      sourceId
      institutionName
      purchasingPower{
        purchasingPower
      }
      position{
        current
      }
      entity{
        id
        turnover
        style
        totalAUM
        equityAUM
        institutionType
      }
      target{
        id
      }
      deal{
        id
      }
    }
  `

  return gql`
    query AdvancedSearch(
      $entity: [String]!,
      $query: String,
      $securityId: [String],
      $tickerId: [String],
      $filter: SearchFilter,
      $page: Int, 
      $currencyCode: String,
      $limit: Int,
      $byTag: Boolean,
      $useElasticSearch: Boolean
    ) {
      advancedSearch(
        entity: $entity,
        query: $query,
        filter: $filter,
        page: $page,
        limit: $limit,
        byTag: $byTag,
        useElasticSearch: $useElasticSearch
      ) {
        items {
          id
          score
          entityConnection(securityId: $securityId, tickerId: $tickerId, currencyCode: $currencyCode, byTag: $byTag){
            ${includeFragments.institutions ? '...institutionsFragment' : ''}
            ${includeFragments.funds ? '...fundsFragment' : ''}
            ${includeFragments.contacts ? '...contactsFragment' : ''}
            ${includeFragments.securities ? '...securitiesFragment' : ''}
            __typename
          }
          __typename
        }
        count
        __typename
      }
    }
    ${includeFragments.institutions ? institutionsFragment : ''}
    ${includeFragments.funds ? fundsFragment : ''}
    ${includeFragments.contacts ? contactsFragment : ''}
    ${includeFragments.securities ? securitiesFragment : ''}
  `
}

/**
 * Dispatch ADVANCED_SEARCH_GQL
 */
const _getAdvancedSearchGQL = (variables, controller) => (dispatch) => {
  dispatch({ type: GET_ADVANCED_SEARCH_GQL_REQUEST })
  return client
    .query({
      query: _generateGqlQuery(variables),
      variables: _prepareGqlVariables(variables),
      fetchPolicy: 'no-cache',
      context: {
        fetchOptions: {
          signal: controller?.signal
        }
      }
    })
    .then((data) => {
      const searchResults = get(data, 'data.advancedSearch.items')
      const count = get(data, 'data.advancedSearch.count')
      dispatch({
        payload: searchResults,
        type: GET_ADVANCED_SEARCH_GQL_SUCCESS,
        timestamp: new Date().getTime(),
        meta: {
          total: count || 0,
          page: variables.page || 1
        }
      })
    })
    .catch((error) => {
      dispatch({ error, type: GET_ADVANCED_SEARCH_GQL_FAILURE })
    })
}

/**
 * Dispatch GET_ADVANCED_SEARCH_REQUEST
 * @param params
 * @param options
 * @deprecated
 * @returns {function(*): *}
 */
export const getAdvancedSearch = (params, options) => (dispatch) => {
  return dispatch(_getAdvancedSearch(params, options))
}

/**
 * Dispatch GET_ADVANCED_SEARCH__GQL_REQUEST
 * @param params
 * @param controller
 * @returns {function(*): *}
 */
export const getAdvancedSearchGql = (params, controller) => (dispatch) => {
  return dispatch(_getAdvancedSearchGQL(params, controller))
}

/**
 * Dispatch GET_ADVANCED_SEARCH_REQUEST
 * @param params
 * @param options
 * @returns {function(*): *}
 */
export const getAdvancedSearchV3 = (params, options) => (dispatch) => {
  return dispatch(_getAdvancedSearchV3(params, options))
}

/**
 * Dispatch GET_ADVANCED_SEARCH_FACETS_REQUEST
 * @param params
 * @param options
 * @returns {function(*): *}
 */
export const getAdvancedSearchFacets = (params, options) => (dispatch) => {
  return dispatch(_getAdvancedSearchFacets(params, options))
}

/**
 * Dispatch GET_ELASTIC_SEARCH_REQUEST
 * @param params
 * @param options
 * @returns {function(*): *}
 */
export const getElasticSearch = (params, options) => (dispatch) => {
  return dispatch(_getElasticSearch(params, options))
}

/**
 * Dispatch GET_TAG_SEARCH_REQUEST
 * @param params
 * @param options
 * @returns {function(*): *}
 */
export const getTagSearch = (params, options) => (dispatch) => {
  return dispatch(_getTagSearch(params, options))
}

/**
 * Dispatch CLEAR_ADVANCED_SEARCH
 * @returns {function(*): *}
 */
export const clearSearchResults = () => (dispatch) => {
  return dispatch({ type: CLEAR_ADVANCED_SEARCH })
}
