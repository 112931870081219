import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './selectListItem.component.css';

class SelectListItem extends PureComponent {

    handleSelect = (item) => {
        const {onSelect} = this.props;
        onSelect && onSelect(item);
    };

    render() {
        const {height, value, isHighlighted, isAction} = this.props;

        const style = {};

        if (!isAction) {
            style.height = height;
        }

        const cls = [
            'select-list__item',
            isAction ? 'select-list__item--action' : '',
            isHighlighted ? 'select-list__item--highlighted' : '',
            this.props.className || ''
        ];

        return (
            <div
                className={cls.join(' ').trim()}
                style={style}
                onClick={this.props.onClick.bind(this, value)}>
                {this.props.children}
            </div>
        );
    }
}

SelectListItem.propTypes = {
    value: PropTypes.any,
    isHighlighted: PropTypes.bool,
    isAction: PropTypes.bool
};

SelectListItem.defaultProps = {
    value: null,
    isHighlighted: false,
    isAction: false
};

export default SelectListItem;