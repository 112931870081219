import 'core-js/stable'
import 'react-app-polyfill/ie11'
import pathToRegexp from 'path-to-regexp'
import { get } from 'lodash'

const path = get(window, 'location.pathname')

const routes = [
  '/tearsheet/export',
  '/activity/:id/itinerary'
]

const isExportPath = (path) => {
  return routes.some((route) => {
    const re = pathToRegexp(route)
    return re.exec(path)
  })
}

if (isExportPath(path)) {
  require('./index.export')
} else {
  require('./index.root')
}
