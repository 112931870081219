import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { get } from 'lodash'

// redux actions
import {
  fetchEntityHighlights,
  resetEntityHighlights,
  statusType
} from '../../../../../../../actions'

// utils
import { getActiveTicker } from '../../../../../../../utils'

// component
import HighlightsComponent from '../highlights/highlights.component'

const HIGHLIGHTS_SERVICES = ['ownership', 'analytics', 'webcast', 'stock']

const HighlightsContainer = (props) => {
  const {
    securityId,
    entity,
    fetchEntityHighlights,
    resetEntityHighlights,
    analytics,
    webcast,
    ownership,
    status
  } = props

  const { entityId, entityType } = entity

  useEffect(() => {
    fetchEntityHighlights(entityId, entityType, { type: HIGHLIGHTS_SERVICES, securityId })
    return () => {
      resetEntityHighlights()
    }
  }, [entityId, entityType, securityId, fetchEntityHighlights, resetEntityHighlights])

  return (
    <HighlightsComponent
      entity={{
        ...entity,
        analytics,
        webcast,
        ownership
      }}
      isLoading={status === statusType.IN_PROGRESS}
    />
  )
}

const mapDispatchToProps = (dispatch) => ({
  fetchEntityHighlights: bindActionCreators(fetchEntityHighlights, dispatch),
  resetEntityHighlights: bindActionCreators(resetEntityHighlights, dispatch)
})

const mapStateToProps = (state) => {
  const ticker = getActiveTicker(get(state, 'profile.data'))
  return {
    securityId: get(ticker, '_security'),
    analytics: get(state, 'targeting.detail.highlights.analytics'),
    ownership: get(state, 'targeting.detail.highlights.ownership'),
    webcast: get(state, 'targeting.detail.highlights.webcast'),
    status: get(state, 'targeting.detail.highlights.status')
  }
}

HighlightsContainer.propTypes = {
  entityType: PropTypes.oneOf(['institution', 'fund']).isRequired
}

HighlightsContainer.defaultProps = {
  entityType: 'institution'
}

export default connect(mapStateToProps, mapDispatchToProps)(HighlightsContainer)
