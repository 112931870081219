import React from 'react'
import { formatDate } from '../../../../utils'

/**
 * Transcript
 * @param props
 * @returns {*}
 */
export const Transcript = (props) => {
  const { date, title, security, highlight } = props
  const header = `${security}${(security && title) ? ' - ' : ''}${title}`
  const formattedDate = formatDate(date, 'MMMM DD, YYYY h:mm A', false, true)
  return (
    <>
      {(header && header.length) && <div className='item_content-title'>{header}</div>}
      {date && <div className='item_content-subtitle'>{date && formattedDate}</div>}
      {!!(highlight && highlight.length) && <div className='item_content-body'>
        <div className='item_content-detail'>
          <div className='item_content-value' dangerouslySetInnerHTML={{ __html: highlight }} />
        </div>
      </div>}
    </>
  )
}
