import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { ENTITY_TYPE, OWNERSHIP_TYPE } from '../../../utils'

export const GET_INST_CURRENT_HOLDINGS = gql`
  query InstitutionHoldingCurrent(
    $entityId: [String]!
    $tickerId: [String]!
    $currencyCode: String
    $source: String = "${OWNERSHIP_TYPE['13F']}"
    $limit: Int!
    $is13F: Boolean = true
  ) {
    security: instHoldingCurrent(
      institutionId: $entityId
      tickerId: $tickerId
      currencyCode: $currencyCode
      source: $source
    ) {
      items {
        tickerId @include(if: $is13F)
        securityName
        current
        qtrChange
        marketValue
        reportDate
      }
    }
    holding: instHoldingCurrent(
      institutionId: $entityId
      currencyCode: $currencyCode
      source: $source
      limit: $limit
    ) {
      items {
        tickerId @include(if: $is13F)
        securityName
        current
        qtrChange
        marketValue
        reportDate
      }
    }
  }`

export const GET_FUND_CURRENT_HOLDINGS = gql`
  query FundHoldingCurrent(
    $entityId: [String]!
    $tickerId: [String]!
    $currencyCode: String
    $source: String = "${OWNERSHIP_TYPE['13F']}"
    $limit: Int!
    $is13F: Boolean = true
  ) {
    security: fundHoldingCurrent(
      fundId: $entityId
      tickerId: $tickerId
      currencyCode: $currencyCode
      source: $source
    ) {
      items {
        tickerId @include(if: $is13F)
        securityName
        current
        qtrChange
        marketValue
        reportDate
      }
    }
    holding: fundHoldingCurrent(
      fundId: $entityId
      currencyCode: $currencyCode
      source: $source
      limit: $limit
    ) {
      items {
        tickerId @include(if: $is13F)
        securityName
        current
        qtrChange
        marketValue
        reportDate
      }
    }
  }`

/**
 * Current Holdings
 * @param type
 * @param options
 */
export function useHoldingQuery (type, options) {
  const query = (type === ENTITY_TYPE.FUND) ? GET_FUND_CURRENT_HOLDINGS : GET_INST_CURRENT_HOLDINGS
  const { variables } = options
  const source = variables?.source || OWNERSHIP_TYPE['13F']
  options = {
    variables: {
      ...variables,
      source,
      is13F: source === OWNERSHIP_TYPE['13F']
    }
  }
  return useQuery(query, options)
}
