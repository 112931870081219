import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

export const GET_TOP_INSTITUTION_MANAGERS_REQUEST = 'GET_TOP_INSTITUTION_MANAGERS_REQUEST'
export const GET_TOP_INSTITUTION_MANAGERS_SUCCESS = 'GET_TOP_INSTITUTION_MANAGERS_SUCCESS'
export const GET_TOP_INSTITUTION_MANAGERS_FAILURE = 'GET_TOP_INSTITUTION_MANAGERS_FAILURE'

const _fetchTopInstitutionManagers = (params) => ({
  [CALL_API]: {
    types: [
      GET_TOP_INSTITUTION_MANAGERS_REQUEST,
      GET_TOP_INSTITUTION_MANAGERS_SUCCESS,
      GET_TOP_INSTITUTION_MANAGERS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/institution/contact/v2/topManagers',
    payload: params
  }
})

export const fetchTopInstitutionManagers = (params) => (dispatch) => {
  return dispatch(_fetchTopInstitutionManagers(params))
}
