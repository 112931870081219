import React from 'react'
import moment from 'moment-timezone'
import { Tag } from '../../../../../../components'
import { ExpandableCell } from '../../../../../../components/agGrid/cell'
import { uniqBy } from 'lodash'

import './cell.component.css'
import { THEMES } from '../../../../../../utils/ui'
import { formatLocalizedDate } from '../../../../../../utils/'

const EMPTY_PLACEHOLDER = '-'

const CategoryCell = ({ category }) => {
  const icon = {
    comment: 'q4i-note-4pt',
    phone: 'q4i-phone-4pt',
    email: 'q4i-mail-4pt',
    meeting: 'q4i-meeting-4pt',
    earnings: 'q4i-earnings-4pt',
    other: 'q4i-report-blank-4pt',
    roadshow: 'q4i-roadshow-4pt',
    conference: 'q4i-conference-4pt'
  }[category]

  return (
    <div className='cell-category'>
      <i className={`cell-category_icon ${icon}`} />
    </div>
  )
}

const TitleCell = ({ title, isExpandable, isOpen, onExpandOrCollapse }) => {
  return (
    <div className='cell-title'>
      <div className='cell-title_text'>{title || EMPTY_PLACEHOLDER}</div>
      {isExpandable && (
        <div
          className={[
            'cell-title_toggle',
            `${isOpen ? 'cell-title_toggle--open' : ''}`
          ].filter((item) => item).join(' ')}
          onClick={onExpandOrCollapse}
        >
          <i className='q4i-caret-sm-down-4pt' />
        </div>
      )}
    </div>
  )
}

const _assignDataForCell = (cellType, data) => {
  switch (cellType) {
    case 'address':
      let _addresses = []

      if (data[cellType] && data[cellType].length) {
        _addresses = _addresses.concat(data[cellType])
      }

      if (data._itinerary && data._itinerary.length) {
        data._itinerary.forEach((itinerary) => {
          if (itinerary.address && itinerary.address.length) {
            _addresses = _addresses.concat(itinerary.address)
          }
        })
      }

      return uniqBy(_addresses, (address) => address.location)
    default:
      return data[cellType]
  }
}

const CustomCell = ({ data = {}, column = {}, rowIndex, node, api, onExpandMenuClick, onTagClick, onItemClick }) => {
  const cellData = _assignDataForCell(column.colId, data)

  switch (column.colId) {
    case 'ag-Grid-AutoColumn':
      return ''
    case 'category':
      return <CategoryCell category={cellData} />
    case 'title':
      return (
        <TitleCell
          title={cellData}
          isExpandable={Boolean(data._itinerary && data._itinerary.length)}
          isOpen={node.expanded}
          onExpandOrCollapse={(event) => {
            event.stopPropagation()
            node.setExpanded(!node.expanded)
            api.redrawRows({ rowNodes: [node] })
          }}
        />
      )
    case 'contact':
      return (
        <ExpandableCell
          items={cellData}
          label='full_name'
          onItemClick={onItemClick}
          onClick={onExpandMenuClick}
        />
      )
    case 'institution':
      return (
        <ExpandableCell
          items={cellData}
          label='institution_name'
          onItemClick={onItemClick}
          onClick={onExpandMenuClick}
        />
      )
    case 'address':
      return (data && data.virtual)
        ? <div className='cell--disabled'>Virtual</div>
        : <ExpandableCell
          items={cellData}
          label='location'
          onClick={onExpandMenuClick}
        />
    case 'start':
      return (cellData && cellData.date_time) ? formatLocalizedDate(moment(cellData.date_time).format('MM/DD/YY'),'MM/DD/YY') : EMPTY_PLACEHOLDER
    case 'tag':
      if (cellData && cellData.length > 1) {
        return (
          <Tag
            items={cellData} limit={2} onClick={onTagClick} theme={THEMES.LIGHT} onMoreClick={(event) => {
              onExpandMenuClick(event, {
                items: (cellData || [])
                  .map((tag) => {
                    tag.label = `#${tag.name}`
                    return tag
                  })
                  .slice(2),
                open: true,
                anchorEl: event.currentTarget
              })
            }}
          />
        )
      }
      return (cellData && cellData.length) ? <Tag items={cellData} theme={THEMES.LIGHT} onClick={onTagClick} /> : EMPTY_PLACEHOLDER
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
