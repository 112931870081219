import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Message } from '../../components'

/**
 * @deprecated
 * TODO: info used only on gdpr contact page
 * General gdpr Modal
 * @param onClose
 * @param title
 * @param message
 */
const GdprModal = ({ onClose, title }) => {
  return (
    <Message
      visible
      type='info'
      title={title}
      message={
        '<p>GDPR Forgotten (aka the right to erasure) mean that individuals can request that their personally identifiable information be deleted from an applicable record.</p>' +
        '<p>Any company that is subject to the GDPR has an obligation to delete personal data based on such request.</p>'
      }
      useHtml
      onClose={onClose}
      renderExitButton
      buttons={[{
        ui: 'citrus',
        label: 'Ok',
        onClick: onClose
      }]}
    />
  )
}

GdprModal.propTypes = {
  onClose: PropTypes.func.isRequired
}

GdprModal.defaultProps = {
  title: 'GDPR Forgotten',
}

export default memo(GdprModal)
