import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { SectionSearch } from '../../../../shared'
import { Button, Select, Toolbar, ToolbarRow } from '../../../../index'
import { HOLDING_REGION, HOLDING_SECTOR, HOLDING_CAP_GROUP, THEMES } from '../../../../../utils'
import { isEmpty } from 'lodash'

const propTypes = {
  dataId: PropTypes.string.isRequired,
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  noData: PropTypes.bool,
  search: PropTypes.string,
  filter: PropTypes.object,
  onQueryChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired
}

const defaultProps = {
  toolbarTheme: THEMES.Q4_BLUE,
  toolTheme: THEMES.INK,
  filter: {}
}

/**
 * Current Holding Toolbar Component
 * @param props
 */
function CurrentHoldingToolbar (props) {
  const { dataId, toolbarTheme, toolTheme, noData, search, filter, onQueryChange, onExport, exporting } = props
  const { sector, capGroup, region } = filter

  const regionSelected = HOLDING_REGION.filter((option) => (region || []).includes(option.value))
  const sectorSelected = HOLDING_SECTOR.filter((option) => (sector || []).includes(option.value))
  const capGroupSelected = HOLDING_CAP_GROUP.filter((option) => (capGroup || []).includes(option.value))

  const handleSearchChange = (search) => onQueryChange({ search })
  const handleFilterReset = () => onQueryChange({ filter: { sector: null, capGroup: null, region: null } })
  const handleFilterChange = (options, type) => {
    const types = (options || []).map((item) => item && item.value)
    return onQueryChange({
      filter: { ...filter, [type]: !isEmpty(types) ? types : null }
    })
  }

  return (
    <Toolbar theme={toolbarTheme}>
      <ToolbarRow justified>
        <div className='toolbar_group'>
          <Select
            dataId={{
              inputId: `${dataId}RegionFilterInput`,
              menuId: `${dataId}RegionFilterMenu`
            }}
            theme={toolTheme}
            size='thin'
            placeholder='Region'
            value={regionSelected}
            options={HOLDING_REGION}
            isMulti
            isDefaultList
            searchable={false}
            clearable={false}
            closeMenuOnSelect={false}
            onChange={(options) => handleFilterChange(options, 'region')}
            />
          <Select
            dataId={{
              inputId: `${dataId}SectorFilterInput`,
              menuId: `${dataId}SectorFilterMenu`
            }}
            className='grid_toolbar-dropdown--large'
            theme={toolTheme}
            size='thin'
            placeholder='Sector'
            value={sectorSelected}
            options={HOLDING_SECTOR}
            isMulti
            isDefaultList
            searchable={false}
            clearable={false}
            closeMenuOnSelect={false}
            onChange={(options) => handleFilterChange(options, 'sector')}
            />
          <Select
            dataId={{
              inputId: `${dataId}MarketCapFilterInput`,
              menuId: `${dataId}MarketCapFilterMenu`
            }}
            theme={toolTheme}
            size='thin'
            placeholder='Market Cap'
            value={capGroupSelected}
            options={HOLDING_CAP_GROUP}
            isMulti
            isDefaultList
            searchable={false}
            clearable={false}
            closeMenuOnSelect={false}
            onChange={(options) => handleFilterChange(options, 'capGroup')}
          />
          <Button
            dataId={`${dataId}ResetFilter`}
            theme={toolTheme}
            label='Reset'
            onClick={handleFilterReset}
          />
        </div>
        <div className='toolbar_group'>
          <SectionSearch
            dataId={`${dataId}Search`}
            theme={toolTheme}
            value={search}
            onQueryChange={handleSearchChange}
            onClear={handleSearchChange}
          />
          <Button
            dataId={`${dataId}Export`}
            theme={toolTheme}
            loading={exporting}
            icon='q4i-download-4pt'
            label='Export'
            disabled={noData}
            onClick={onExport}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

CurrentHoldingToolbar.propTypes = propTypes
CurrentHoldingToolbar.defaultProps = defaultProps

export default memo(CurrentHoldingToolbar)
