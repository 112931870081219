import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'

import CustomCell from './cell/cell.component'
import { AgGrid, NoContentMessage, PopoverMenu, Spinner } from '../../../index'
import { getDefaultColumnDef, preventDefaultRowClick, THEMES } from '../../../../utils'
import { capitalize } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  pageSizeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  listOptions: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  entityType: PropTypes.string,
  noFilter: PropTypes.bool.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onBulkAction: PropTypes.func.isRequired,
  onActivityCreate: PropTypes.func.isRequired,
  handlePopoverMenuItemClick: PropTypes.func,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  loading: false,
  data: [],
  total: 0,
  listOptions: {},
  noFilter: true
}

const columns = [
  {
    field: 'category',
    headerName: 'Type',
    type: 'icon',
    minWidth: 142,
    maxWidth: 142,
    pinned: 'left',
    lockPinned: true,
    headerCheckboxSelection: true,
    checkboxSelection: true
  },
  {
    pivotIndex: 2,
    field: 'title',
    type: 'text',
    minWidth: 300,
    pinned: 'left',
    lockPinned: true
  },
  {
    pivotIndex: 2,
    field: 'contact',
    type: 'text',
    minWidth: 220,
    maxWidth: 220
  },
  {
    field: 'institution',
    type: 'text',
    minWidth: 220,
    maxWidth: 220
  },
  {
    field: 'address',
    headerName: 'Location',
    type: 'text',
    minWidth: 220,
    maxWidth: 220
  },
  {
    field: 'start',
    headerName: 'Start Date',
    type: 'date',
    minWidth: 120,
    maxWidth: 120
  },
  {
    field: 'tag',
    headerName: 'Tags',
    minWidth: 260,
    maxWidth: 260,
    sortable: false
  }]

/**
 * Activity Table Component
 * @param props
 */
function ActivityTable (props) {
  const {
    dataId, pageSizeId, loading, data, total, listOptions, entityType, noFilter,
    onQueryChange, onBulkAction, onActivityCreate, handlePopoverMenuItemClick, history
  } = props
  const [agGrid, setAgGrid] = useState({})
  const [popoverMenu, setPopoverMenu] = useState(null)

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click event
   * @param id {String}
   */
  const handleRowClick = ({ id }) => {
    history && history.push(`/activity/${id}`)
  }

  /**
   * Handle page change
   * @param selected
   */
  const handlePageChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, page: selected }
    })
  }

  /**
   * Handle page size change
   * @param selected
   */
  const handlePageSizeChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, limit: selected, page: 1 }
    })
  }

  /**
   * Handle Column Sort
   * @param grid
   */
  const handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel && !sortModel.length) {
      return
    }

    onQueryChange({
      listOptions: {
        ...listOptions,
        sortBy: (sortModel[0].colId === 'start')
          ? 'start.date_time'
          : sortModel[0].colId,
        sortDir: sortModel[0].sort,
        page: 1
      }
    })
  }

  /**
   * Handle Tag Click
   * @param event
   * @param tag
   */
  const handleTagClick = (event, tag) => {
    event.stopPropagation()
    history && history.push(`/search?query=${encodeURIComponent('#' + tag.name)}`)
  }

  /**
   * Handle Popover Menu click
   * @param event
   * @param popoverMenu
   */
  const handlePopoverClick = (event, popoverMenu) => {
    event.stopPropagation()
    setPopoverMenu(popoverMenu)
  }

  /**
   * Handle Popover Menu Item Click
   * @param event
   * @param data
   */
  const handlePopoverItemClick = (event, data) => {
    event.stopPropagation()
    const { entityId, entityType, tagType } = data || {}

    if (tagType) {
      return handleTagClick(event, data)
    }
    const type = (entityType || '').toLowerCase()

    entityId && handlePopoverMenuItemClick
      ? handlePopoverMenuItemClick({ entityType: type, entityId })
      : history.push(`/${type}/${entityId}`)
  }

  /**
   * Handle Popover Menu close
   * @param event
   */
  const handlePopoverClose = (event) => {
    event.stopPropagation()
    setPopoverMenu(null)
  }

  /**
   * Handle AgGrid bulk delete
   */
  const handleBulkDelete = () => {
    const selectedRows = agGrid.api && agGrid.api.getSelectedRows()
    const selectedIds = (selectedRows || []).map((row) => row && row.id)

    if (!selectedIds || !selectedIds.length) {
      return
    }

    onBulkAction(selectedIds)
  }

  /**
   * Get NoContentMessage Props
   * @returns {{*}}
   */
  const getNoContentMessageProps = () => {
    const entity = entityType ? `for this ${capitalize(entityType)}` : ''
    return noFilter ? {
      title: 'No Activity Recorded',
      message: `Start adding activity ${entity}`,
      actions: [{
        label: 'Add Activity',
        dataId: `${dataId}Create`,
        onClick: onActivityCreate
      }]
    } : null
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell
      {...props}
      onTagClick={handleTagClick}
      onPopoverClick={handlePopoverClick}
      onPopoverItemClick={handlePopoverItemClick}
    />
  }

  /**
   * Returns grid columns
   */
  const getColumnsDefinition = () => {
    return columns.map((column, idx) => {
      const { pivotIndex, type } = column
      return {
        ...getDefaultColumnDef({ columnIndex: (pivotIndex || idx), type }),
        ...column
      }
    })
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage {...getNoContentMessageProps()} />
        : <AgGrid
          domLayout='autoHeight'
          sizeToFit

          // suppress configs
          suppressMovableColumns
          suppressContextMenu

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            sortable: true,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={getColumnsDefinition()}
          rowData={data}

          // bulk actions
          bulkActions={[{
            id: 'entity-activity-list-delete',
            icon: 'q4i-trashbin-2pt',
            onSelect: handleBulkDelete
          }]}

          // pagination
          pagination
          paginationProps={{
            pageSizeId,
            forcePage: listOptions.page,
            initialPageSize: listOptions.limit,
            showPageSizeSelection: true,
            total,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: renderCustomCell
          }}

          // event listeners
          onGridReady={handleGridReady}
          onGridSizeChanged={handleGridResize}
          onSortChanged={handleSortChange}
          onRowClicked={preventDefaultRowClick(
            handleRowClick,
            ['cell_toggle', 'expandable-cell--clickable', 'tags-item']
          )}

          // css overwrites
          rowClassRules={{
            'ag-row--expanded': ({ data, node }) => (node.expanded || node.parent.expanded)
          }}
          isPinned={data.length} />}

      {/* Popover Menu for Custom Cell */}
      {popoverMenu && (
        <PopoverMenu
          scrollable
          onClose={handlePopoverClose}
          onClick={handlePopoverItemClick}
          {...popoverMenu}
        />
      )}
    </div>
  )
}

ActivityTable.propTypes = propTypes
ActivityTable.defaultProps = defaultProps

export default memo(ActivityTable)
