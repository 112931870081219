import { queue } from 'async'

/**
 * ThrottledFetch
 * Optimizes number of similtanious fetch requests, limiting to specified
 * concurrency (default 10)
 *
 * Note: Without throttling fetch requests for pages that require
 * a large number of fetch requests, Chrome becomes unstable and throws
 * net::ERR_INSUFFICIENT_RESOURCES error
 */
export class ThrottledFetch {
  /**
   * Constructor
   * @param {number} options.concurrency
   * @param {boolean} options.debug
   */
  constructor (options) {
    const { concurrency, debug } = {
      concurrency: 10,
      debug: false,
      ...options
    }

    this.concurrency = concurrency
    this.debug = debug
    this.queue = this._createQueue()
  }

  /**
   * Create Queue
   * @returns
   */
  _createQueue () {
    return queue(async (task, callback) => {
      this.log('Task added to queue', task)
      const taskResult = await fetch(task.arg1, task.arg2)
      callback(null, taskResult)
    }, this.concurrency)
  }

  /**
   * Fetch - adds request to queue
   * @param {RequestInfo} input
   * @param {RequestInit} init
   * @returns
   */
  async fetch (input, init) {
    const result = await this.queue.pushAsync({ arg1: input, arg2: init })
    this.log('Task completed with result', result)
    return result
  }

  /**
   * Logging
   * @param {string} message
   * @param  {any} optionalArgs
   */
  log (message, ...optionalArgs) {
    this.debug && console.log(message, ...optionalArgs)
  }
}