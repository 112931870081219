import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './dropdownSelect.component.css';
import DropdownOptions from '../dropdownOptions/dropdownOptions.component';

class DropdownSelect extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {};
    }

    getPropsByChild(child) {
        const {theme, height, optionHeight} = this.props;
        let childProps;

        switch (child.type.prototype) {
            case DropdownOptions.prototype:
                childProps = {
                    handleSelect: this.handleSelect,
                    height,
                    optionHeight
                };
                break;
            default:
                childProps = {};
        }

        return Object.assign({}, childProps || {}, {
            theme
        });
    }

    handleCollapse = () => {
        const {handleCollapse} = this.props;
        handleCollapse && handleCollapse();
    };

    handleSelect = (value) => {
        const {handleSelect, onSelect} = this.props;
        onSelect && onSelect(value);
        handleSelect && handleSelect(value);
    };

    render() {

        const {children, height, width, dropdownRef, optionHeight, toggleHeight} = this.props;
        const Component = dropdownRef && dropdownRef.current;
        const ComponentRect = Component && Component.getBoundingClientRect();
        let {fillerHeight} = this.props;
        fillerHeight = fillerHeight || toggleHeight || optionHeight;

        const dropdownStyles = {
            top: ((ComponentRect && ComponentRect.top) || 0) + height,
            left: (ComponentRect && ComponentRect.left) || 0,
            width: (ComponentRect && ComponentRect.width) ? ComponentRect.width : width,
            marginTop: height * -1
        };

        return (
            <React.Fragment>
                <div className='dropdown-select_mask' onClick={this.handleCollapse}/>
                <div className='dropdown-select' style={dropdownStyles}>
                    <div className='dropdown-select_filler'
                         style={{height: fillerHeight}}
                         onClick={this.handleCollapse}/>
                    {React.Children.map(children, ((child) => {
                        return React.cloneElement(child, this.getPropsByChild(child));
                    }))}
                </div>
            </React.Fragment>
        );
    }
}

DropdownSelect.propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fillerHeight: PropTypes.number,
    optionHeight: PropTypes.number,
    toggleHeight: PropTypes.number,
    dropdownRef: PropTypes.any,
    theme: PropTypes.string
};

DropdownSelect.defaultProps = {
    height: 220,
    width: 160,
    optionHeight: 40,
    toggleHeight: 40
};

export default DropdownSelect;