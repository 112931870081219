import gql from 'graphql-tag'
import { get } from 'lodash'
import client from '../../middleware/graphql.middleware'

import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { mapActivityAttendeeGqlData } from '../../utils'
export const GET_ATTENDEE_LIST_REQUEST = 'GET_ATTENDEE_LIST_REQUEST'
export const GET_ATTENDEE_LIST_SUCCESS = 'GET_ATTENDEE_LIST_SUCCESS'
export const GET_ATTENDEE_LIST_FAILURE = 'GET_ATTENDEE_LIST_FAILURE'
export const RESET_ATTENDEE_LIST = 'RESET_ATTENDEE_LIST'

const _getList = (activityId, params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_ATTENDEE_LIST_REQUEST,
      GET_ATTENDEE_LIST_SUCCESS,
      GET_ATTENDEE_LIST_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/crm/activity/${activityId}/attendee`,
    payload: params
  }
})

const _getItineraryAttendeeList = (activityId, params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_ATTENDEE_LIST_REQUEST,
      GET_ATTENDEE_LIST_SUCCESS,
      GET_ATTENDEE_LIST_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/crm/activity/${activityId}/itineraryAttendee`,
    payload: params
  }
})
const _getListGql = (activityId, params) => (dispatch) => {
  dispatch({ type: GET_ATTENDEE_LIST_REQUEST })
  return client
    .query({
      query: _generateGqlQuery(params.type),
      variables: {
        id: activityId,
        isParent: params.isParent,
        securityId: params.securityId,
        tickerId: params.tickerId,
        page: params.page,
        limit: params.limit,
        filter: {
          entityType: params.type === 'all' ? undefined : params.type
        }
      },
      fetchPolicy: 'no-cache'
    })
    .then((data) => {
      const payload = {
        data: get(data, 'data.activityAttendee.items', []).map(result => mapActivityAttendeeGqlData(result)),
        counts: get(data, 'data.activityAttendee.typeCount', []),
        page: params.page,
        total: get(data, 'data.activityAttendee.count', 0)
      }
      dispatch({
        payload,
        type: GET_ATTENDEE_LIST_SUCCESS,
      })
    })
    .catch((error) => {
      dispatch({ error, type: GET_ATTENDEE_LIST_FAILURE })
    })
}


export const getAttendeeList = (activityId, params, useGQL) => (dispatch) => {
  if (useGQL) {
    dispatch(_getListGql(activityId, params))
    return
  }
  return dispatch(_getList(activityId, params))
}

export const getItineraryAttendeeList = (activityId, params, useGQL) => (dispatch) => {
  if (useGQL) {
    dispatch(_getListGql(activityId, { ...params, isParent: true }))
    return
  }

  return dispatch(_getItineraryAttendeeList(activityId, params))
}

export const resetAttendee = () => (dispatch) => {
  return dispatch({ type: RESET_ATTENDEE_LIST })
}


/**
 * Create specific query (choose required fragment) based on the incoming entity type 
 * @param {string} type 
 * @returns 
 */
const _generateGqlQuery = (type) => {
  const includeFragments = {
    institutions: false,
    funds: false,
    contacts: false,
  }

  switch (type) {
    case 'institution':
      includeFragments.institutions = true
      break
    case 'fund':
      includeFragments.funds = true
      break
    case 'contact':
      includeFragments.contacts = true
      break
    default:
      includeFragments.institutions = true
      includeFragments.funds = true
      includeFragments.contacts = true
      break
  }

  const institutionFragment = gql`
  fragment institutionFragment  on InstitutionDTO {
    id
    institutionName
    active
    addressConnection {
      items {
        phone
        city
        stateProvinceCode
        countryCode
        hq
      }
    }
    institutionHoldingCurrentConnection(tickerId: [$tickerId]) {
      items {
        current
      }
    }
  }`
  const contactFragment = gql`
  fragment contactFragment on ContactDTO {
    id
    fullName
    directPhone
    phoneExtension
    mobile
    email
    jobs {
      entityId
      institutionName
      phone
      directPhone
      email
      stateProvince
      city
      country
    }
    contactHoldingCurrentConnection(securityId: [$securityId]) {
      items {
        current
      }
    }
  }`
  const fundFragment = gql`
  fragment fundFragment on FundDTO {
    id
    fundName
    fundTypeDesc
    style
    turnover
    institutionId
    institutionName
    portfolioValue
    equityAUM
    qualityRating
    institutionConnection {
      items {
        addressConnection {
          items {
            city
            stateProvinceCode
            countryCode
            phone
            hq
          }
        }
      }
    }
    fundHoldingCurrentConnection(tickerId: [$tickerId]) {
      items {
        current
      }
    }
  }`

  return gql`
  query ActivityAttendee($id: String, $isParent: Boolean, $filter: ActivityAttendeeFilter, $page: Int, $limit: Int, $securityId: String, $tickerId: String) {
    activityAttendee(id: $id, isParent: $isParent, filter: $filter, page: $page, limit: $limit, securityId: $securityId, tickerId: $tickerId) {
      count
      typeCount {
        all
        contact
        fund
        institution
      }
      items {
        entityId
        entityType
        institutionId
        entityConnection {
          ${includeFragments.funds ? '...fundFragment' : ''}
          ${includeFragments.contacts ? '...contactFragment' : ''}
          ${includeFragments.institutions ? '...institutionFragment' : ''}       
          
        }
      }
    }
  }
  ${includeFragments.institutions ? institutionFragment : ''}
  ${includeFragments.funds ? fundFragment : ''}
  ${includeFragments.contacts ? contactFragment : ''}
`}