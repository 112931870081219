import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { Toolbar } from '../../../../index'
import { THEMES } from '../../../../../utils'

const propTypes = {
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK])
}

const defaultProps = {
  toolbarTheme: THEMES.Q4_BLUE,
  toolTheme: THEMES.INK
}

/**
 * Contact Research Toolbar Component
 * @param props
 */
function ContactResearchToolbar (props) {
  const { toolbarTheme } = props
  return (
    <Toolbar theme={toolbarTheme}></Toolbar>
  )
}

ContactResearchToolbar.propTypes = propTypes
ContactResearchToolbar.defaultProps = defaultProps

export default memo(ContactResearchToolbar)
