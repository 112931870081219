import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { Card, RenderHTML } from '../../../index'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderLightThumb, renderTrackVertical } from '../../../../resources/theme/q4.custom-scrollbar'

// utils
import { THEMES } from '../../../../utils'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  isLoading: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  description: PropTypes.string

}

const defaultProps = {
  theme: THEMES.DARK,
  isLoading: false,
  placeholder: 'No data available'
}

/**
 * Deal Description Component
 * @param props
 */
function DealDescription ({ dataId, theme, isLoading, placeholder, description }) {
  return (
    <Card
      dataId={`${dataId}DescriptionCard`}
      theme={theme}
      isLoading={isLoading}
      title='Description'
    >
      {(description && description.length)
        ? <Scrollbars
          className='react-scrollbar'
          autoHide
          autoHeight
          autoHeightMax={200}
          hideTracksWhenNotNeeded
          renderThumbVertical={renderLightThumb}
          renderTrackVertical={renderTrackVertical}
        >
          <RenderHTML theme={theme} html={description} />
        </Scrollbars>
        : placeholder}
    </Card>
  )
}

DealDescription.propTypes = propTypes
DealDescription.defaultProps = defaultProps

export default memo(DealDescription)
