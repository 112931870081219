import React from 'react'
import { format, defaultIfEmpty } from '../../../../utils'

const UNKNOWN = 'Unknown'

/**
 * Fund
 * @param props
 * @returns {*}
 */
export const Fund = (props) => {
  const { fundName, fundType, style, turnover, position, purchasingPower, institutionName, ownership } = props

  return (
    <>
      <div className='item_content-title'>{fundName}</div>
      <div className='item_content-subtitle'>
        {institutionName}
      </div>
      <div className='item_content-subtitle item_content-subtitle--small'>
        {defaultIfEmpty(fundType, UNKNOWN)}
        <span className='pipe'>Style: {defaultIfEmpty(style, UNKNOWN)}</span>
        <span className='pipe'>Turnover: {defaultIfEmpty(turnover, UNKNOWN)}</span>
      </div>
      <div className='item_content-body'>
        <div className='item_content-detail'>
          <div className='item_content-label'>Purchasing Power</div>
          <div className='item_content-value'>{format(purchasingPower, 0, 'dash')}</div>
        </div>
        <div className='item_content-detail'>
          <div className='item_content-label'>Current Position <span className='item_content-label--ownership'>({ownership})</span></div>
          <div className='item_content-value'>{format(position, 0, 'dash')}</div>
        </div>
      </div>
    </>
  )
}
