import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// components
import Chart from '../../../../../ownership/chart/entity/chart.component'

// utils
import { entityChartPosition, QUARTER_TO_YEAR_LIMIT } from '../../../../../../utils'

const propTypes = {
  position: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  stock: PropTypes.array.isRequired,
  subscription: PropTypes.object.isRequired,
  quarters: PropTypes.number.isRequired
}

const defaultProps = {
  stock: []
}

const config = {
  isLegend: false,
  isTearSheet: true,
  spacing: [24, -32, 0, -8],
  pointWidth: 38,
  animationDuration: 0,
  stockAxis: {
    labels: { x: 18, y: 12 },
    title: { x: 10, y: -8 }
  },
  positionAxis: {
    labels: { x: 0, y: 12 },
    title: { x: -38, y: -8 }
  },
  axisColor: '#939ba0',
  axisLabelColor: '#545b62',
  axisTitleColor: '#545b62',
  gridLineColor: '#939ba0',
  positionColor: '#4696E0',
  stockColor: '#0f5ca3',
  yetStockColor: '#e0e0e0',
  yetStockLabel: {
    enabled: true,
    y: 65,
    x: 0,
    align: 'center',
    style: {
      color: '#545b62'
    }
  },
  labelFontSize: '10px'
}

/**
 * Ownership Chart Component
 * @param props
 */
function OwnershipChart (props) {
  const { position, stock, subscription, range, quarters } = props
  const inYears = (quarters >= QUARTER_TO_YEAR_LIMIT)

  const [scale, setScale] = useState(null)

  // check the subscription type to use specific grouping behaviour when dealing with SID records
  const label = subscription.label
  const historical = entityChartPosition({ position, quarters, inYears, getLatestRecord: label === 'SID' })

  // When difference between historical positions is great (1.5x), largest position overlaps yAxis.
  // positionAxis.max forces highcharts calculate positionAxis based on max position.
  useEffect(() => {
    const min = Math.min(...(historical || []).map((item) => item.y || 0))
    const max = Math.max(...(historical || []).map((item) => item.y || 0))

    if (min && max && (max / min > 1.5) && !scale) {
      setScale(max * 1.3)
    }
  }, [historical, scale, setScale])

  const options = {
    config: { ...config, positionAxis: { ...config.positionAxis, max: scale } },
    inYears,
    quarters,
    ...range,
    stock,
    position: historical,
    ownership: label,
    height: 200
  }

  return <Chart {...options} />
}

OwnershipChart.propTypes = propTypes
OwnershipChart.defaultProps = defaultProps

export default memo(OwnershipChart)
