import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DataTableRow from '../row/row.component';
import DataTableCell from '../cell/cell.component';

class DataTableBody extends Component {

    /**
     * Get TableCell content based on props
     * @param column
     * @param row
     * @param rowIndex
     * @param rows
     */
    getDataTableCellContent = (column, row, rowIndex, rows) => {
        const {customCellRender} = this.props;
        let cellContent;

        if (customCellRender) {
            cellContent = customCellRender(column.id, row[column.id], rowIndex, rows);
        } else {
            cellContent = row[column.id] && row[column.id].toString();
        }

        if (column.truncate) {
            return (
                <div className='data-table_value data-table_value--truncate'>
                    {cellContent || '-'}
                </div>
            );
        } else {
            return cellContent || '-';
        }
    };

    /**
     * Render no results message
     * @param columns
     * @param rows
     * @return {function(*): XML}
     */
    renderRows = (columns, rows) => {
        const {alternating, onRowClick, onCellClick} = this.props;

        return rows.map((row, rowIndex) => {
            return (
                <DataTableRow
                    key={`row-${rowIndex}`}
                    index={onRowClick && rowIndex}
                    data={onRowClick && row}
                    onRowClick={onRowClick}
                >
                    {columns.map((column, columnIndex) => {
                        return (
                            <DataTableCell
                                key={`data-table-body-cell--${column.id}-${rowIndex}`}
                                id={column.id}
                                width={column.width}
                                textAlign={column.textAlign}
                                dense={column.dense}
                                isAlternate={alternating && (column.isAlternate || !!(columnIndex % 2))}
                                rowData={onCellClick && row}
                                onCellClick={onCellClick}
                            >
                                {this.getDataTableCellContent(column, row, rowIndex, rows)}
                            </DataTableCell>
                        );
                    }, this)}
                </DataTableRow>
            );
        });
    };

    /**
     * Render no results message
     * @param columns
     * @param rows
     * @param groupBy
     * @return {function(*): XML}
     */
    renderGroupedRows = (columns, rows, groupBy) => {
        const {alternating, onRowClick, onCellClick} = this.props;

        return rows.map((row, rowIndex) => {
            const isParent = !!row._grouping;

            return (
                <DataTableRow
                    key={`data-table-row-${rowIndex}`}
                    className={typeof isParent === 'boolean' ? isParent ? 'data-table_row--parent' : 'data-table_row--child' : null}
                    index={onRowClick && rowIndex}
                    data={onRowClick && row}
                    onRowClick={onRowClick}
                >
                    {columns.map((column, columnIndex) => {
                        const isParentCell = column._groupKey && column._groupKey === groupBy;

                        if (!isParent && isParentCell) {
                            return null;
                        }

                        const rowSpan = isParentCell ? row._grouping.rowSpan : null;

                        return (
                            <DataTableCell
                                key={`data-table-body-cell--${column.id}-${rowIndex}`}
                                id={column.id}
                                width={column.width}
                                textAlign={column.textAlign}
                                dense={column.dense}
                                isAlternate={alternating && (column.isAlternate || !!(columnIndex % 2))}
                                rowSpan={rowSpan}
                                rowData={onCellClick && row}
                                onCellClick={onCellClick}
                            >
                                {this.getDataTableCellContent(column, row, rowIndex, rows)}
                            </DataTableCell>
                        );
                    }, this)}
                </DataTableRow>
            );
        });
    };

    /**
     * Render DataTableBody
     * @returns {XML}
     */
    render() {
        const {data, columns, groupBy, noResultsText} = this.props;
        const noData = !data || !data.length;

        return (
            <tbody className='data-table_body'>
                {noData  ? (
                    <DataTableRow className='data-table_row--no-results'>
                        <DataTableCell textAlign='center' colSpan={(columns && columns.length) || 1}>
                            {noResultsText || 'No data available'}
                        </DataTableCell>
                    </DataTableRow>
                ) : groupBy ?  this.renderGroupedRows(columns, data, groupBy) : this.renderRows(columns, data)}
            </tbody>
        );
    }
}

DataTableBody.propTypes = {
    /**
     * Array of data to display in the table
     */
    data: PropTypes.array,

    /**
     * Array of columns used to display the data
     * Columns require an id property
     */
    columns: PropTypes.array,

    /**
     * Used to render an excel style grouped table
     * props.data should include a _grouping object with key and rowspan properties (key to match with groupBy)
     * props.columns should include a _groupKey property
     */
    groupBy: PropTypes.string,

    /**
     * Text used when displaying no available data
     * Default: No data available
     */
    noResultsText: PropTypes.string,

    /**
     * Used to determine whether the cells are tinted for alternation
     */
    alternating: PropTypes.bool,

    /**
     * A function used to overwrite what is rendered within the table cells
     */
    customCellRender: PropTypes.func,

    /**
     * A callback for when the user clicks on a row
     * Adds a visual indicator for the row (cursor: pointer)
     */
    onRowClick: PropTypes.func,

    /**
     * A callback for when the user clicks on a cell
     */
    onCellClick: PropTypes.func
};

DataTableBody.defaultProps = {
    data: [],
    columns: [],
    noResultsText: 'No data available'
};

export default DataTableBody;