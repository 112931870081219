import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Select } from '../../../../../../../components'
import { THEMES } from '../../../../../../../utils/ui'

/**
 * Type Filter
 */
class Type extends PureComponent {

  /**
   * Handle filter update for type
   * @param selectedItems
   * @param event
   */
  handleTypeChange = (selectedItems, event) => {
    const { onTypeChange, options } = this.props
    const { action, option } = event
    const { value, label } = option

    const selected = (options || []).map((item) => {
      if (item && item.value === value) {
        return action === 'select-option'
          ? { ...item, selected: true }
          : Object.assign({}, { value, label })
      }
      return item
    })

    onTypeChange({ typeOptions: selected })
  }

  /**
   * Render
   * @return {*}
   */
  render () {
    const { options } = this.props

    return (
      <div className='field field--full field--grouped'>
        <Select
          placeholder='Type'
          options={options}
          value={(options || []).filter((item) => item && !!item.selected)}
          isMulti
          isDefaultList
          searchable={false}
          closeMenuOnSelect={false}
          clearable={false}
          theme={THEMES.WHITE}
          onChange={this.handleTypeChange}
        />
      </div>
    )
  }
}

Type.propTypes = {
  options: PropTypes.array.isRequired,
  onTypeChange: PropTypes.func.isRequired
}

export default Type
