import React, { Component } from 'react'
import { config } from '../../../config'
import { Message } from '../../../components'
import './dashboardManager.component.css'

/**
 * Widget Library Component
 */
class DashboardManager extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor (props) {
        super(props)

        this.state = {
            isDeleteWarningOpen: false,
            dashboardToDelete: null
        }
    }

    /**
     * Open Delete Warning
     * @param id
     */
    openDeleteWarning = (id) => {
        this.setState({
            isDeleteWarningOpen: true,
            dashboardToDelete: id
        })
    }

    /**
     * Close Delete Warning
     */
    closeDeleteWarning = () => {
        this.setState({
            isDeleteWarningOpen: false,
            dashboardToDelete: null
        })
    }

    /**
     * Triggered when user confirms to delete a dashboard
     */
    onDeleteConfirm = () => {
        const { dashboardToDelete } = this.state
        const { removeDashboard, onClose } = this.props

        removeDashboard(dashboardToDelete)
        this.closeDeleteWarning()
        onClose()
    }

    onSelectDashboard = (dashboardId) => {
        const { setActiveDashboard, onClose } = this.props
        setActiveDashboard(dashboardId)
        onClose()
    }

    /**
     * Render User Dashboards
     * @param dashboards
     * @returns {Array}
     */
    renderUserDashboards = (dashboards) => {
        const { editActiveDashboard } = this.props

        const userDashboardCount = dashboards.length
        return (dashboards || []).map((dashboard) => {
            const isDisabled = userDashboardCount <= 1

            return (
                <li key={dashboard._id} className='dashboard-manager_dashboard'>
                    <div className='dashboard-manager_dashboard-icon'
                         onClick={() => this.onSelectDashboard(dashboard._id)}>
                        <i className='q4i-dashboard-2pt'/>
                    </div>
                    <div className='dashboard-manager_label'>{dashboard.title}</div>
                    <div className='dashboard-manager_actions'>
                        <span className='dashboard-manager_action'
                              onClick={() => editActiveDashboard(dashboard._id)}>
                            <i className='q4i-cog-4pt'/>
                        </span>
                        <span
                            className={`dashboard-manager_action ${isDisabled ? 'dashboard-manager_action--disabled' : ''}`}
                            onClick={isDisabled ? null : () => {
                                this.openDeleteWarning(dashboard._id)
                            }}>
                            <i className='q4i-trashbin-4pt'/>
                        </span>
                    </div>
                </li>
            )
        })
    }

    /**
     * Render Dashboard Manager
     * @returns {*}
     */
    render () {
        const { dashboards, hidden, addNewDashboard, onClose } = this.props
        const { isDeleteWarningOpen } = this.state

        const userDashboardCount = dashboards.length
        const isAddNewAvailable = userDashboardCount < config.dashboard.maxUserDashboards
        const addNewClass = `dashboard-manager_new ${isAddNewAvailable ? '' : 'dashboard-manager_new--disabled'}`

        return (
            <div className={hidden ? 'dashboard-manager dashboard-manager--hidden' : 'dashboard-manager'}>
                <div className='dashboard-manager_inner'>
                    <header className='dashboard-manager_header'>
                        <h2 className='dashboard-manager_title'>Manage Dashboards</h2>
                        <div className='dashboard-manager_back' onClick={onClose}>
                            <i className='q4i-arrow-up-2pt'/>
                        </div>
                    </header>
                    <ul className='dashboard-manager_dashboards'>
                        {this.renderUserDashboards(dashboards)}
                    </ul>
                    <div className={addNewClass}>
                        <button className='button button--tall button--circle button--citrus'
                                onClick={isAddNewAvailable ? addNewDashboard : null}>
                            <i className='q4i-plus-4pt'/>
                            {!isAddNewAvailable && (
                                <div className='tooltip tooltip--top-left'>Dashboard limit reached</div>
                            )}
                        </button>
                        <div className='dashboard-manager_new-label'>New</div>
                    </div>
                </div>
                <div className='dashboard-manager_mask' onClick={onClose}/>
                <Message visible={isDeleteWarningOpen}
                         type='warning'
                         title='Delete Dashboard?'
                         message='Are you sure you want to delete this dashboard? All widget configurations will be lost.'
                         onClose={this.closeDeleteWarning}
                         buttons={[{
                             ui: 'shaded',
                             label: 'cancel',
                             onClick: this.closeDeleteWarning
                         }, {
                             ui: 'spice',
                             label: 'confirm',
                             onClick: this.onDeleteConfirm
                         }]}
                />
            </div>
        )
    }
}

export default DashboardManager
