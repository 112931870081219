import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {fetchCurrentRating, fetchConsensusDetails, fetchCurrentSecurity, FETCHING} from '../../../actions/estimates';
import {format, formatEstimatesSalesValue} from '../../../utils/number.util';
import {formatValue, formatRating, getPeriodLabel} from '../../../utils/estimates.util';
import {getUserDefaultMetric} from '../../../utils/user.util';
import { DataTable, Spinner } from '../../../components';
import {isEmpty} from 'lodash';
import './estimatesOverview.container.css';

const columns = [
    {id: 'metric', label: '', width: 'auto'},
    {id: 'quarterEstimate', label: 'Est', width: 'narrow', textAlign: 'right'},
    {id: 'quarterPrev', label: 'Prev', width: 'narrow', textAlign: 'right'},
    {id: 'quarterAnalysts', label: 'Analysts', width: 'narrow', textAlign: 'center'},
    {id: 'fiscalEstimate', label: 'Est', width: 'narrow', textAlign: 'right'},
    {id: 'fiscalPrev', label: 'Prev', width: 'narrow', textAlign: 'right'},
    {id: 'fiscalAnalysts', label: 'Analysts', width: 'narrow', textAlign: 'center'}
];

class EstimatesOverview extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
           defaultMetric: getUserDefaultMetric(props.profile, props.securityId)
        };
    }

    /**
     * ComponentDidMount
     */
    componentDidMount() {
        this._fetchData();
    }

    /**
     * ComponentDidUpdate
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {securityId, profile} = this.props;

        if (prevProps.securityId !== securityId) {
            const defaultMetric = getUserDefaultMetric(profile, securityId);

            this.setState({defaultMetric}, () => {
                this._fetchData();
            });
        }
    }

    /**
     * Fetch Data
     * @private
     */
    _fetchData = () => {
        const {securityId} = this.props;
        const {defaultMetric} = this.state;

        this.props.fetchCurrentRating({securityId});

        ['sales', defaultMetric].map((eachMetric) => {
            return this.props.fetchConsensusDetails({
                metric: eachMetric,
                securityId
            });
        });
    };

    /**
     * Format table data
     * @param metric
     * @param sales
     * @returns {*}
     */
    formatData = (metric, sales) => {
        if (!metric) {
            return [];
        }

        return [{
            metric: (metric.metric && metric.metric.toUpperCase()) || 'EPS',
            quarterEstimate: formatValue((metric.quarter && metric.quarter.estimate), metric.metric),
            quarterPrev: formatValue((metric.prevQuarter && metric.prevQuarter.actual), metric.metric),
            quarterAnalysts: (metric.quarter && metric.quarter.analysts) || '-',
            fiscalEstimate: formatValue((metric.year && metric.year.estimate), metric.metric),
            fiscalPrev: formatValue((metric.prevYear && metric.prevYear.actual), metric.metric),
            fiscalAnalysts: (metric.year && metric.year.analysts) || '-'
        }, {
            metric: 'Sales',
            quarterEstimate: formatEstimatesSalesValue(sales.quarter && sales.quarter.estimate),
            quarterPrev: formatEstimatesSalesValue(sales.prevQuarter && sales.prevQuarter.actual),
            quarterAnalysts: (sales.quarter && sales.quarter.analysts) || '-',
            fiscalEstimate: formatEstimatesSalesValue(sales.year && sales.year.estimate),
            fiscalPrev: formatEstimatesSalesValue(sales.prevYear && sales.prevYear.actual),
            fiscalAnalysts: (sales.year && sales.year.analysts) || '-'
        }];
    };

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const {rating, consensus, securityId} = this.props;
        const {defaultMetric} = this.state;

        const securityConsensus = consensus[securityId] || {};
        const metric = securityConsensus[defaultMetric] || {};
        const sales = securityConsensus['sales'] || {};

        const isAnyEmpty = isEmpty(rating) || isEmpty(metric) || isEmpty(sales);
        const loading = (rating.status === FETCHING) || (consensus.status === FETCHING);

        const data = this.formatData(metric, sales);
        const quarterLabel = (metric && metric.quarter && getPeriodLabel(metric.quarter.date, null, 'quarter', metric.quarter.quarter, true)) || '';
        const yearLabel = (metric && metric.year && getPeriodLabel(metric.year.date, null, 'annual', null, true)) || '';

        return (
            <div className='estimates-overview'>
                {isAnyEmpty && loading && (
                    <Spinner
                        theme={'rain'}
                        mask={true}
                    />
                )}
                <section className='overview'>
                    <div className='overview_inner'>
                        <section className='overview_section overview_section--contained'>
                            <section className='estimates-overview_box'>
                                <div className='estimates-overview_box-row'>
                                    <h3>{(rating.rating && formatRating(rating.rating)) || '-'}</h3>
                                    <div className='estimates-overview_date'>
                                        <h5>{quarterLabel}</h5>
                                        <h5>Rating</h5>
                                    </div>
                                </div>
                                <div className='estimates-overview_box-row'>
                                    <div>
                                        <h4>{format(rating.targetPrice, 2, 'dash')}</h4>
                                        <h5>Target</h5>
                                    </div>
                                    <div>
                                        <h4>{rating.analysts || '-'}</h4>
                                        <h5>Analysts</h5>
                                    </div>
                                </div>
                            </section>
                        </section>
                        <section className='overview_section'>
                            <div className='estimates-overview_table'>
                                <div className='estimates-overview_table-cell'>{quarterLabel}</div>
                                <div className='estimates-overview_table-cell'>{yearLabel}</div>
                            </div>
                            <DataTable
                                columns={columns}
                                data={data}
                                size='small'
                                alternating={true}
                            />
                        </section>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {rating} = state.estimates.rating;
    const consensus = state.estimates.consensusDetails;
    const profile = state.shared.profile;

    return {
        rating,
        consensus,
        profile,
        securityId: props.match.params.securityId
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchCurrentRating: bindActionCreators(fetchCurrentRating, dispatch),
    fetchConsensusDetails: bindActionCreators(fetchConsensusDetails, dispatch),
    fetchCurrentSecurity: bindActionCreators(fetchCurrentSecurity, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EstimatesOverview));
