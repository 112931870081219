import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get, isEqual, isEmpty } from 'lodash'

// actions
import {
  getSavedContact,
  getContactLists,
  addToAddressBook,
  updateSavedContactLists,
  searchEntity,
  searchEntityClear,
  clearSavedContact,
  createToast,
  openModal,
  ADD_CONTACT_TO_ADDRESS_BOOK_SUCCESS,
  ADD_CONTACT_TO_ADDRESS_BOOK_FAILURE,
  UPDATE_SAVED_CONTACT_LIST_SUCCESS,
  UPDATE_SAVED_CONTACT_LIST_FAILURE,
  statusType
} from '../../../actions'

import { ComboBox, Message, Modal, Select } from '../../../components'
import { TextInput } from '../../../components/shared'
import { debounce, ENTITY_CONTACT, MESSAGE, THEMES } from '../../../utils'

/**
 * Error Message Modal
 * @param onClose
 * @returns {*}
 * @constructor
 */
const ErrorModal = ({ onClose }) => {
  return (
    <Message
      visible
      type='error'
      title='Error'
      message={MESSAGE.ERROR}
      onClose={onClose}
      buttons={[{
        ui: 'spice',
        label: 'close',
        onClick: onClose
      }]} />
  )
}

/**
 * Add To Address Book Modal
 */
class AddToAddressBook extends Component {
  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      errors: {
        contact: false,
        lists: false,
        newList: false,
        errorMessage: ''
      },
      contact: '',
      contacts: [],
      favoriteIds: props && props.favoriteIds,
      isNewList: false,
      newList: ''
    }
  }

  /**
   * Component Did Mount
   */
  componentDidMount () {
    const { contactId, favorite, getSavedContact, getContactLists } = this.props
    getContactLists({ page: 1, limit: 25 })

    if (contactId && !(favorite && favorite._id)) {
      getSavedContact(contactId)
    }
  }

  /**
   * Component Did Update
   * @param prevProps
   * @param prevState
   */
  componentDidUpdate (prevProps, prevState) {
    const { favorite, suggestions } = this.props
    const { contact, favoriteIds, newList } = this.state

    if (!prevProps.favorite && !isEqual(favorite, prevProps.favorite)) {
      this.setState({
        favoriteIds: favorite._lists
      })
    }

    if (!isEqual(suggestions, prevProps.suggestions)) {
      this.setState({
        contacts: this.getContactOptions(suggestions)
      })
    }

    if (!isEqual(contact, prevState.contact) || !isEqual(favoriteIds, prevState.favoriteIds) || !isEqual(newList, prevState.newList)) {
      this.handleValidate()
    }
  }

  /**
   * ComponentWillUnmount
   */
  componentWillUnmount () {
    const { clearSavedContact, searchEntityClear } = this.props
    this.searchContacts.cancel() // reset debounce
    searchEntityClear()
    clearSavedContact()
  }

  /**
   * On field value change search contacts
   */
  searchContacts = debounce((value) => {
    const { searchEntity, searchEntityClear } = this.props;
    (value && value.length) ?
      searchEntity({ query: value, type: ENTITY_CONTACT.toLowerCase() }) : searchEntityClear()
  })

  /**
   * Normalize contact dropdown options
   * @param suggestions
   * @returns {{label: string, value: *}[]}
   */
  getContactOptions = (suggestions) => {
    return (suggestions || []).map((suggestion) => {
      const job = get(suggestion,'_source.jobs[0]', {})

      return {
        label: <>
          {get(suggestion, '_source.full_name', '')}
          <span className='select__detail'>{job.institution_name}</span>
        </>,
        value: suggestion._id
      }
    })
  }

  /**
   * Normalize lists dropdown options
   * @param lists
   * @returns {{label: *, value: {}}[]}
   */
  getListOptions (lists) {
    const actions = [{
      type: 'action',
      label: 'Create New Contact List',
      onClick: () => this.setState({ isNewList: true, newList: '' })
    }]

    const options = (lists || []).map((list) => {
      return (list && list._id) ? {
        label: list.name,
        value: list._id
      } : null
    })

    return [].concat(options || [], actions)
  }

  /**
   * Handle Add Contact to Address Book
   */
  handleAddToAddressBook () {
    const { favoriteIds, newList, contact, errors } = this.state
    const { addToAddressBook, updateSavedContactLists, contactId, contactIds, favoriteId } = this.props
    const ids = contactId || contactIds || contact

    if (!ids || (contactIds && isEmpty(favoriteIds) && !newList.length) || Object.values(errors).includes(true)) {
      this.handleValidate()
      return false
    }

    // Check if updating existing favorite(s), or creating new
    const copyToLists = contactIds && !!contactIds.length
    const updateRecord = copyToLists || favoriteId

    const params = {
      contact: ids,
      list: favoriteIds,
      newList,
      copy: copyToLists
    }

    const action = updateRecord ? updateSavedContactLists : addToAddressBook

    action(params).then((data) => {
      const { type } = data

      switch (type) {
        case ADD_CONTACT_TO_ADDRESS_BOOK_SUCCESS:
        case UPDATE_SAVED_CONTACT_LIST_SUCCESS:
          return this.onSaveSuccess()
        case ADD_CONTACT_TO_ADDRESS_BOOK_FAILURE:
        case UPDATE_SAVED_CONTACT_LIST_FAILURE:
          return this.onSaveFailure()
        default:
          break
      }
    })
  }

  /**
   * On Save Success
   */
  onSaveSuccess () {
    const { createToast, onSaveSuccess, onClose } = this.props
    onSaveSuccess && onSaveSuccess()
    createToast({ text: 'Contact saved successfully to Address Book.' })
    onClose()
  }

  /**
   * On Save Failure display error
   */
  onSaveFailure () {
    const { openModal } = this.props
    openModal({
      component: ErrorModal
    })
  }

  /**
   * Handle contact selection change
   * @param option
   */
  handleContactChange (option) {
    this.setState({ contact: option && option.value })
  }

  /**
   * Handle list selection change
   * @param lists
   */
  handleListChange (lists) {
    this.setState({
      favoriteIds: (lists || []).filter((list) => list && !list.type).map((list) => list && list.value)
    })
  }

  /**
   * Handle list remove
   * @param id
   */
  handleListRemove (id) {
    const { favoriteIds } = this.state

    this.setState({
      favoriteIds: (favoriteIds || []).filter((listId) => listId !== id)
    })
  }

  /**
   * Handle new list name change
   * @param event
   */
  handleNewListChange (event) {
    const name = (event.target && event.target.value) || ''
    this.setState({ newList: name })
  }

  /**
   * Handle validation
   */
  handleValidate () {
    const { contactId, contactIds, lists } = this.props
    const { contact, newList, favoriteIds } = this.state
    const isExist = (lists || []).some((list) => list && list.name === newList.trim())

    this.setState({
      errors: {
        contact: (contactId || contactIds) ? false : !contact,
        lists: contactIds && isEmpty(favoriteIds) && !newList.length,
        newList: isExist,
        errorMessage: isExist ? 'List name must be unique.' : ''
      }
    })
  }

  /**
   * Render
   * @return {*}
   */
  render () {
    const { errors, contacts, favoriteIds, newList, isNewList } = this.state
    const { dataId, contactId, contactIds, favoriteId, loadingSuggestions, loadingLists, lists, onClose } = this.props

    const options = this.getListOptions(lists) || []
    const selected = options
      .filter((option) => option && !option.type && (favoriteIds || []).find((id) => option.value === id))

    const buttons = [
      {
        dataId: `${dataId}Cancel`,
        label: 'Cancel',
        ui: 'shaded',
        onClick: onClose
      },
      {
        dataId: `${dataId}Save`,
        label: (favoriteId || contactIds) ? 'Apply' : 'Save',
        ui: THEMES.CITRUS,
        onClick: this.handleAddToAddressBook.bind(this)
      }
    ]

    return (
      <Modal
        dataId={`${dataId}Modal`}
        visible
        disableRestoreFocus
        onClose={onClose}
        title={(favoriteId || contactIds) ? 'Edit Address Book' : 'Save to Address Book'}
        footerButtons={buttons}>
        {(!contactId && !contactIds) && <div className='field field--text field--full'>
          <label className='field_label'>Contact <span className='required'>(required)</span></label>
          <Select
            portal
            error={{ isError: errors.contact }}
            loading={loadingSuggestions}
            showDropdownIndicator={false}
            options={contacts}
            onInputChange={this.searchContacts}
            onChange={this.handleContactChange.bind(this)} />
        </div>}
        <div className='field field--text field--full'>
          <label className='field_label'>Contact Lists</label>
          <ComboBox
            theme={THEMES.LIGHT_GREY}
            selectProps={{
              dataId: {
                menuId: `${dataId}ListSelectMenu`,
                inputId: `${dataId}ListSelectInput`
              },
              loading: loadingLists,
              placeholder: 'Select',
              error: { isError: errors.lists },
              value: selected,
              options,
              isMulti: true,
              isDefaultList: true,
              closeMenuOnSelect: false,
              showDropdownIndicator: true,
              onChange: this.handleListChange.bind(this)
            }}
            removableListProps={{
              dataId: `${dataId}List`,
              items: selected,
              onRemove: this.handleListRemove.bind(this)
            }}
          />
        </div>
        {isNewList
          ? <div className='field field--text field--full'>
            <label className='field_label'>New Contact List Name</label>
            <TextInput
              error={errors.newList}
              errorMessage={errors.errorMessage}
              placeholder='ex. Top Prospects'
              value={newList}
              onChange={this.handleNewListChange.bind(this)} />
          </div>
          : null}
      </Modal>
    )
  }
}

AddToAddressBook.propTypes = {
  dataId: PropTypes.string,
  contactId: PropTypes.string,
  contactIds: PropTypes.array,
  favoriteId: PropTypes.string,
  favoriteIds: PropTypes.array,
  onSaveSuccess: PropTypes.func,
  onClose: PropTypes.func.isRequired
}

AddToAddressBook.defaultProps = {
  favoriteIds: []
}

const mapStateToProps = (state) => {
  const { addressBook, contact, search } = state
  return {
    favorite: get(contact, 'favorite.data'),
    loadingSuggestions: get(search, 'entity.status') === statusType.IN_PROGRESS,
    suggestions: get(search, 'entity.data'),
    loadingLists: get(addressBook, 'lists.status') === statusType.IN_PROGRESS,
    lists: get(addressBook, 'lists.data')
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSavedContact: bindActionCreators(getSavedContact, dispatch),
  searchEntity: bindActionCreators(searchEntity, dispatch),
  getContactLists: bindActionCreators(getContactLists, dispatch),
  addToAddressBook: bindActionCreators(addToAddressBook, dispatch),
  updateSavedContactLists: bindActionCreators(updateSavedContactLists, dispatch),
  searchEntityClear: bindActionCreators(searchEntityClear, dispatch),
  clearSavedContact: bindActionCreators(clearSavedContact, dispatch),
  createToast: bindActionCreators(createToast, dispatch),
  openModal: bindActionCreators(openModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AddToAddressBook)
