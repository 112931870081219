export const PUSH_FLYOUT_DETAILS_ITEM = 'PUSH_FLYOUT_DETAILS_ITEM'
export const POP_FLYOUT_DETAILS_ITEM = 'POP_FLYOUT_DETAILS_ITEM'
export const RESET_FLYOUT_DETAILS_ITEM = 'RESET_FLYOUT_DETAILS_ITEM'
export const SET_FLYOUT_DETAILS_ITEM = 'SET_FLYOUT_DETAILS_ITEM'

export const pushFlyoutDetailsItem = (data) => (dispatch) => {
  dispatch({ type: PUSH_FLYOUT_DETAILS_ITEM, payload: data })
}

export const popFlyoutDetailsItem = () => (dispatch) => {
  dispatch({ type: POP_FLYOUT_DETAILS_ITEM })
}

export const setFlyoutDetailsItem = (data) => (dispatch) => {
  dispatch({ type: SET_FLYOUT_DETAILS_ITEM, payload: data })
}

export const resetFlyoutDetailsItem = () => (dispatch) => {
  dispatch({ type: RESET_FLYOUT_DETAILS_ITEM })
}