import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { DetailList, Modal, RouteLink } from '../../../index'

// utils
import { getFromXigniteToStandard, getTranscriptLink } from '../../../../utils'
import { isEmpty } from 'lodash'
import { EVENT_DOCUMENT_LABEL } from '../../../../utils/calendar/calendar.const'

const propTypes = {
  event: PropTypes.object.isRequired,
  badge: PropTypes.string.isRequired,
  date: PropTypes.string,
  onClose: PropTypes.func.isRequired
}

const defaultProps = {
  event: {},
  badge: 'q4i-map-marker-2pt'
}

/**
 * Get event subtitle
 * @param event
 */
function getSubtitle (event) {
  const { company_name, tickers } = (event || {})
  const ticker = (tickers || []).find((ticker) => ticker.primary) || {}
  const { symbol, exchange } = ticker

  return (
    <span>
      {company_name ? `${company_name} ` : ''}
      {(symbol && exchange) ? `${symbol} ${getFromXigniteToStandard(exchange)}` : ''}
    </span>
  )
}

/**
 * Get event modal phone number
 * @param phone
 * @param passcode
 */
function getPhone (phone, passcode) {
  return (passcode) ? [
    <span key={`phone-number--${phone}`}>{phone}</span>,
    <span key={`phone-pass--${passcode}`}><i className='q4i-lock-4pt'/>{passcode}</span>
  ] : phone
}

/**
 * Render event modal routeLink
 * @param href
 * @param text
 */
function getLink (href, text) {
  return <RouteLink to={href} target='_blank'>{text}</RouteLink>
}

/**
 * Get event details
 * @param event
 * @param date
 */
function getDetails (date, event) {
  const { reports, press_release_url } = (event || {})

  if (!date) {
    return {}
  }

  const information = [{
    icon: 'q4i-calendar-4pt',
    label: 'Date',
    value: date
  }]

  const report = reports && reports.length ? reports[0] : null
  if (!report) {
    return information
  }

  const {
    phone_live_us, phone_live_int, phone_replay_us, phone_replay_int, passcode_live_us, passcode_live_int, passcode_replay_us, passcode_replay_int,
    webcast_live, webcast_replay, webcast_slides, transcript_url
  } = report

  return {
    information,
    audio: [
      phone_live_us ? {
        icon: 'q4i-phone-4pt',
        label: 'Live (United States)',
        value: getPhone(phone_live_us, passcode_live_us)
      } : null,
      phone_live_int ? {
        icon: 'q4i-phone-4pt',
        label: 'Live (International)',
        value: getPhone(phone_live_int, passcode_live_int)
      } : null,
      phone_replay_us ? {
        icon: 'q4i-phone-4pt',
        label: 'Replay (United States)',
        value: getPhone(phone_replay_us, passcode_replay_us)
      } : null,
      phone_replay_int ? {
        icon: 'q4i-phone-4pt',
        label: 'Replay (International)',
        value: getPhone(phone_replay_int, passcode_replay_int)
      } : null
    ].filter((item) => item),
    documents: [
      webcast_live ? {
        icon: 'q4i-webcast-4pt',
        value: getLink(webcast_live, EVENT_DOCUMENT_LABEL.WEBCAST_LIVE)
      } : null,
      webcast_replay ? {
        icon: 'q4i-webcast-4pt',
        value: getLink(webcast_replay, EVENT_DOCUMENT_LABEL.WEBCAST_REPLAY)
      } : null,
      webcast_slides ? {
        icon: 'q4i-presentation-4pt',
        value: getLink(webcast_slides,  EVENT_DOCUMENT_LABEL.PRESENTATION)
      } : null,
      transcript_url ? {
        icon: 'q4i-transcript-4pt',
        value: getLink(getTranscriptLink(transcript_url),  EVENT_DOCUMENT_LABEL.TRANSCRIPT)
      } : null,
      press_release_url ? {
        icon: 'q4i-press-releases-4pt',
        value: getLink(press_release_url,  EVENT_DOCUMENT_LABEL.PRESS_RELEASE)
      } : null
    ].filter((item) => item)
  }
}

/**
 * Event Detail Modal
 * @param props
 */
function EventDetail ({ event, badge, date, onClose }) {
  const { title } = (event || {})

  const details = getDetails(date, event)
  const { audio, documents, information } = (details || {})
  const labelWidth = !isEmpty(audio) ? 135 : 50

  return (
    <Modal
      visible
      scrollable
      containerMaxWidth={'md'}
      contentPadding='20px'
      contentBackground
      badge={badge}
      title={title || 'Event Details'}
      subtitle={getSubtitle(event)}
      onClose={onClose}
    >
      {(information && information.length) ?
        <DetailList
          title='Information'
          items={details.information}
          labelWidth={labelWidth}
        /> : null}
      {!isEmpty(audio) ?
        <DetailList
          title='Audio'
          items={audio}
          labelWidth={labelWidth}
        /> : null}
      {!isEmpty(documents) ?
        <DetailList
          title='Documents'
          items={documents}
          labelWidth={labelWidth}
        /> : null}
    </Modal>
  )
}

EventDetail.propTypes = propTypes
EventDetail.defaultProps = defaultProps

export default memo(EventDetail)
