import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Scrollbars } from 'react-custom-scrollbars'
import { get, isEmpty, uniqBy } from 'lodash'
import {
  storeReportDataVisualization,
  resetReportSnapshot,
  resetReportDataConfig,
  resetReportDataItem,
  clearPeers
} from '../../../actions/report'
import { THEMES } from '../../../utils/ui'
import { getActiveTicker } from '../../../utils'
import {
  getAvailableSeriesPeers,
  getAvailableXAxisFields,
  getAvailableSeriesFields,
  getDefaultYAxis,
  getDefaultXAxisField,
  getIsReportType,
  chartTypeOptions,
  getSeriesFromIdentifiers,
  colorOptions,
  applyColorToSeries,
  getFlattenedData,
  getDefaultSeries,
  getCompleteSeries,
  getHighChartsDataPerSeries,
  applyColorToDataLabels,
  activityEntityType,
  stockEntityType,
  getIsAggregationAvailable,
  getShouldRenderRelativeValues
} from '../../../utils/report'

// components
import {
  ReportChartAxis,
  ReportChartConfig,
  ReportChartSeries,
  ReportChartStyle,
  ReportPieConfig,
  ReportPieSlices,
  ReportTableConfig,
} from '../../../components/report/chartEditor'

import { ToggleList } from '../../../components'
import ReportWidget from '../../../components/report/widget/reportWidget.component'
import { renderDarkThumb, renderTrackVertical } from '../../../resources/theme/q4.custom-scrollbar'

import './reportDataVisualizer.container.css'

// internal constants
const comboChartTypeOptions = chartTypeOptions.filter((option) => option.availableInCombo).map((option) => option.chartType)

/**
 * Report Data Visualizer Container
 */
class ReportDataVisualizer extends PureComponent {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      availableXAxisFields: [],
      availableSeriesFields: [],
      availablePivotValues: [],
      availablePeers: []
    }
  }

  /**
   * ComponentDidMount
   */
  componentDidMount () {
    const { storeReportDataVisualization } = this.props
    this.setState(
      this.deriveStateFromProps(),
      () => storeReportDataVisualization(this.deriveReduxStateFromProps())
    )
  }

  /**
   * ComponentDidUpdate
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    const { storeReportDataVisualization } = this.props
    const { fields, xAxisField, _pivotQuarters, chartType, peers, reportSnapshot, indices } = this.getCurrentProps(this.props)
    const { prevFields, prevXAxisField, _prevPivotQuarters, prevChartType, prevPeers, prevReportSnapshot, prevIndices } = this.getPrevProps(prevProps)


    const fieldsChanged = fields.length !== prevFields.length
    const chartTypeChanged = chartType !== prevChartType
    const xAxisChanged = (xAxisField && xAxisField._id) !== (prevXAxisField && prevXAxisField._id)
    const pivotQuartersChanged = (
      (_pivotQuarters[xAxisField && xAxisField._entityType.name] || []).length !==
      (_prevPivotQuarters[prevXAxisField && prevXAxisField._entityType.name] || []).length
    )
    const peersChanged = (peers || []).length !== (prevPeers || []).length
    const indicesChanged = (indices || []).length !== (prevIndices || []).length
    const snapshotChanged = reportSnapshot !== prevReportSnapshot

    if (xAxisChanged || fieldsChanged || chartTypeChanged || pivotQuartersChanged || peersChanged || snapshotChanged || indicesChanged) {
      this.setState(
        this.deriveStateFromProps(),
        () => storeReportDataVisualization(this.deriveReduxStateFromProps({
          peersChanged,
          indicesChanged,
          fieldsChanged,
          prevProps
        }))
      )
    }
  }

  componentWillUnmount () {
    const {
      resetReportSnapshot,
      resetReportDataConfig,
      resetReportDataItem,
      clearPeers
    } = this.props

    resetReportSnapshot()
    resetReportDataConfig()
    resetReportDataItem()
    clearPeers()
  }

  /**
   * Builds the state of a report data visualizer component based on props
   * @return {Object}
   */
  deriveStateFromProps = () => {
    const { reportDataConfig, reportDataVisualization, peers, ticker } = this.props
    const { fields, _pivotQuarters, entityType, indices } = reportDataConfig
    const { xAxis } = reportDataVisualization.chartOptions || {}
    const hasPeers = getIsReportType(entityType, stockEntityType)

    const xAxisField = xAxis && xAxis._field
    const availableXAxisFields = getAvailableXAxisFields(fields)
    const availableSeriesFields = getAvailableSeriesFields(fields, xAxisField ? xAxisField : availableXAxisFields[0])
    const availablePeers = hasPeers ? getAvailableSeriesPeers(peers, ticker) : []
    const availablePivotValues = (xAxisField && _pivotQuarters[xAxisField._entityType && xAxisField._entityType.name]) || []

    return {
      availableXAxisFields,
      availableSeriesFields,
      availablePeers,
      availableIndices: indices,
      availablePivotValues
    }
  }

  /**
   * Builds the redux state of a report data visualizer component based on props
   * @return {Object}
   */
  deriveReduxStateFromProps = ({ peersChanged, indicesChanged, fieldsChanged, prevProps } = {}) => {
    const { reportDataVisualization, reportDataConfig, reportSnapshot } = this.props
    const { chartOptions, widgetType } = reportDataVisualization
    const isStockReport = getIsReportType(reportDataConfig.entityType, stockEntityType)
    const xAxis = this.getXAxisField()
    let chartType = chartOptions.chartType
    let series = this.getSeriesField()

    const supportAggregation = widgetType !== 'table' && getIsAggregationAvailable(get(xAxis, '_field.type'))
    const supportComboChart = Boolean(
      widgetType !== 'table' &&
      chartType !== 'pie' &&
      !getFlattenedData(series, 'yAxis').includes('right') &&
      series.length
    )


    // Automatically generate series based on newly added fields
    // Convert to combo chart if not already
    if (fieldsChanged && supportComboChart) {
      const comboField = this.getComboField({ xAxis, prevProps })

      if (comboField) {
        series = (chartType !== 'combo' ? this.convertToComboSeries(series) : series).concat(this.getComboSeries({
          series,
          comboField,
          isStockReport
        }))
        chartType = 'combo'
      }
    }

    // Automatically generate series based on provided peers or indices
    if (isStockReport && (peersChanged || indicesChanged)) {
      const { peers, indices } = this.getCurrentProps(this.props)
      const { prevPeers, prevIndices } = this.getPrevProps(prevProps)
      const addedSeries = []

      peersChanged && addedSeries.push(...getSeriesFromIdentifiers({
        type: 'peer',
        identifiers: this.getAddedIdentifiers('peer', peers, prevPeers),
        series,
        baseSeries: series[0]
      }))

      indicesChanged && addedSeries.push(...getSeriesFromIdentifiers({
        type: 'index',
        identifiers: this.getAddedIdentifiers('index', indices, prevIndices),
        series,
        baseSeries: series[0]
      }))

      series = [...series, ...addedSeries]
    }

    series = applyColorToSeries(series, colorOptions)

    // Apply color to pie chart data labels (pie slices)
    if (chartType === 'pie' && series.length && reportSnapshot.length) {
      const completeSeries = getCompleteSeries(series)
      const seriesItem = completeSeries[0]
      const seriesData = getHighChartsDataPerSeries({ reportSnapshot, seriesItem, chartOptions })

      series[0].dataLabelsColor = applyColorToDataLabels(seriesData, seriesItem.dataLabelsColor, colorOptions)
    }

    const aggregationModel = supportAggregation ? chartOptions.aggregationModel || 'sum' : null

    return {
      chartOptions: {
        ...chartOptions,
        aggregationModel,
        chartType,
        xAxis,
        series,
        yAxis: this.deriveYAxisStateFromProps()
      }
    }
  }

  /**
   * Builds the yAxis field based on provided state
   * @param params.series
   */
  deriveYAxisStateFromProps = (params = {}) => {
    const { reportDataVisualization, reportDataConfig } = this.props
    const { chartOptions, widgetType } = reportDataVisualization
    const isStockReport = getIsReportType(reportDataConfig.entityType, stockEntityType)
    const xAxis = this.getXAxisField()
    let chartType = chartOptions.chartType
    let yAxis = (chartOptions.yAxis && chartOptions.yAxis.length && xAxis && xAxis._field) ? chartOptions.yAxis : getDefaultYAxis()
    const supportTrendLine = widgetType !== 'table' && !['pie', 'bar'].includes(chartType)
    const supportRelativeValues = !!isStockReport
    const series = params.series || this.getSeriesField()
    const shouldRenderRelativeValues = getShouldRenderRelativeValues(series, reportDataConfig.entityType, chartOptions.chartType)

    // Reset TrendLine checkbox
    if (!supportTrendLine) {
      yAxis = yAxis.map((axis) => ({ ...axis, shouldRenderTrendLine: false }))
    }

    // Reset shouldRenderRelativeValues checkbox
    if (!supportRelativeValues) {
      yAxis = yAxis.map((axis) => ({ ...axis, shouldRenderRelativeValues: false }))
    }

    // Automatically enable/disable shouldRenderRelativeValues checkbox
    if (supportRelativeValues) {
      yAxis = yAxis.map((axis) => {
        return axis.id === 'left'
          ? { ...axis, shouldRenderRelativeValues }
          : axis
      })
    }

    return yAxis
  }

  /**
   * Convert series to use combo-chart friendly chartTypes
   * @param series
   * @returns
   */
  convertToComboSeries = (series) => {
    return (series || []).map((seriesItem) => {
      return {
        ...seriesItem,
        chartType: comboChartTypeOptions.includes(seriesItem.chartType) ? seriesItem.chartType : comboChartTypeOptions[0]
      }
    })
  }

  /**
   * Helper function to get a field only if the field is available in the list of options;
   * otherwise sets field value to default state
   * @param field
   * @param fieldKey
   * @param availableOptions
   * @param availableOptionKey
   * @param defaultOption
   */
  getFieldFromAvailableOptions = ({ field, fieldKey, availableOptions, availableOptionKey, defaultOption }) => {
    return availableOptions.find((option) => {
      if (Array.isArray(availableOptionKey)) {
        return fieldKey.map((key) => get(field, key)).join('.') === availableOptionKey.map((key) => get(option, key)).join('.')
      } else {
        return get(option, availableOptionKey, option) === get(field, fieldKey, field)
      }
    }) || defaultOption
  }

  /**
   * Helper function to determine peers or indices that were recently added based on current and previous peers / indices props
   * @param type
   * @param identifiers
   * @param prevIdentifiers
   * @return {T[]}
   */
  getAddedIdentifiers = (type, identifiers, prevIdentifiers) => {
    return (identifiers || []).filter((identifier) => type === 'peer'
      ? !prevIdentifiers.find((prevPeer) => prevPeer._security === identifier._security)
      : !prevIdentifiers.find((prevIndex) => prevIndex.symbol === identifier.symbol && prevIndex.group === identifier.group))
  }

  /**
   * Returns XAxis Field.
   * Contains the logic to fallback to default xAxis field in the case if the current option is not longer available
   * due to changes in report data configuration
   */
  getXAxisField = () => {
    const { reportDataVisualization, reportDataConfig } = this.props
    const { availableXAxisFields } = this.state
    const { chartOptions: { xAxis } } = reportDataVisualization
    const { entityType } = reportDataConfig
    const primaryEntityType = entityType && entityType[0]

    if (!primaryEntityType) {
      return {
        _field: null
      }
    }

    return {
      ...xAxis,
      _field: this.getFieldFromAvailableOptions({
        field: xAxis,
        fieldKey: '_field._id',
        availableOptions: availableXAxisFields,
        availableOptionKey: '_id',
        defaultOption: getDefaultXAxisField(primaryEntityType, availableXAxisFields)
      })
    }
  }

  /**
   * Returns Series Fields.
   */
  getSeriesField = () => {
    const { reportDataVisualization, reportDataConfig } = this.props
    const { availableSeriesFields, availablePivotValues, availablePeers, availableIndices } = this.state
    const { chartOptions: { series } } = reportDataVisualization
    const { entityType } = reportDataConfig
    const primaryEntityType = entityType && entityType[0]

    if (!primaryEntityType) {
      return []
    }

    return (series || [])
      .filter((series) => {
        series._field = this.getFieldFromAvailableOptions({
          field: series._field,
          fieldKey: '_id',
          availableOptions: availableSeriesFields,
          availableOptionKey: '_id',
          defaultOption: null
        })
        series.peer = this.getFieldFromAvailableOptions({
          field: series.peer,
          fieldKey: '_security',
          availableOptions: availablePeers,
          availableOptionKey: '_security',
          defaultOption: null
        })
        series.index = this.getFieldFromAvailableOptions({
          field: series.index,
          fieldKey: ['symbol', 'group'],
          availableOptions: availableIndices,
          availableOptionKey: ['symbol', 'group'],
          defaultOption: null
        })
        series.pivotValue = this.getFieldFromAvailableOptions({
          field: series.pivotValue,
          fieldKey: null,
          availableOptions: availablePivotValues,
          availableOptionKey: null,
          defaultOption: null
        })
        return series._field && (series.peer || series.index || series.pivotValue)
      })
  }

  /**
   * Get field used to create combo series
   * @param xAxis
   * @param prevProps
   * @returns {boolean|*}
   */
  getComboField = ({ xAxis, prevProps }) => {
    const { fields, latestField } = this.getCurrentProps(this.props)
    const { prevFields } = this.getPrevProps(prevProps)
    const xAxisField = xAxis && xAxis._field
    const newField = fields.length > prevFields.length && latestField
    const isAvailableSeriesField = getAvailableSeriesFields([newField], xAxisField).length

    return isAvailableSeriesField && newField
  }

  /**
   * Get series additionally generated by adding a new combo field
   * @param series
   * @param comboField
   * @param isStockReport
   * @returns {{}}
   */
  getComboSeries = ({ series, comboField, isStockReport }) => {
    const { chartType } = comboField

    const uniqSeries = uniqBy((series || []), (s) => isStockReport ? (s.peer && s.peer._security) || (s.index && s.index.symbol) : 'pivotValue')
    const seriesToAdd = uniqSeries.map((s) => {
      return isStockReport ? { peer: s.peer, index: s.index, color: s.color } : { pivotValue: s.pivotValue }
    }).filter((seriesItem) => !isEmpty(seriesItem))

    return seriesToAdd.map((seriesItem) => {
      return {
        ...getDefaultSeries(),
        ...seriesItem,
        _field: comboField,
        chartType: comboChartTypeOptions.includes(chartType) ? chartType : comboChartTypeOptions[0],
        yAxis: 'right'
      }
    })
  }

  /**
   * Get important current props for the ComponentDidUpdate callback
   * @param props
   */
  getCurrentProps = (props) => {
    const { reportDataConfig, reportDataVisualization, peers, reportSnapshot } = props
    const { entityType, fields, latestField, _pivotQuarters, indices } = reportDataConfig
    const { chartType, xAxis } = reportDataVisualization.chartOptions || {}

    return {
      entityType,
      fields,
      latestField,
      _pivotQuarters,
      chartType,
      xAxisField: xAxis && xAxis._field,
      peers,
      indices,
      reportSnapshot
    }
  }

  /**
   * Get important previous props for the ComponentDidUpdate callback
   * @param prevProps
   */
  getPrevProps = (prevProps) => {
    const { reportDataConfig, reportDataVisualization, peers, reportSnapshot } = prevProps
    const { entityType, fields, _pivotQuarters, indices } = reportDataConfig
    const { chartType, xAxis } = reportDataVisualization.chartOptions || {}

    return {
      prevEntityType: entityType,
      prevFields: fields,
      _prevPivotQuarters: _pivotQuarters,
      prevChartType: chartType,
      prevXAxisField: xAxis && xAxis._field,
      prevPeers: peers,
      prevIndices: indices,
      prevReportSnapshot: reportSnapshot
    }
  }

  /**
   * Handle ReportChartTypeConfig onChange event
   * @param option
   */
  handleChartTypeChange = (option) => {
    const { reportDataVisualization, storeReportDataVisualization, onChange } = this.props
    const { chartOptions } = reportDataVisualization
    const { series } = chartOptions || {}
    const { widgetType, chartType } = option

    const newChartOptions = widgetType === 'table' ? {
      chartType: null
    } : {
      chartType,
      series: chartType === 'combo' ? this.convertToComboSeries(series) : series
    }

    storeReportDataVisualization({
      widgetType,
      chartOptions: {
        ...reportDataVisualization.chartOptions,
        ...newChartOptions
      }
    })

    onChange && onChange()
  }

  /**
   * Handle show title checkbox change
   * @param showTitle
   */
  handleShowTitleChange = (showTitle) => {
    const { storeReportDataVisualization, onChange } = this.props

    storeReportDataVisualization({
      showTitle
    })

    onChange && onChange()
  }

  /**
   * Handle xAxis field configuration update
   * @param xAxisField
   */
  handleXAxisFieldChange = (xAxisField) => {
    const { reportDataVisualization, storeReportDataVisualization } = this.props
    const { chartOptions } = reportDataVisualization

    storeReportDataVisualization({
      chartOptions: {
        ...chartOptions,
        xAxis: {
          ...chartOptions.xAxis,
          _field: xAxisField
        }
      }
    })
  }

  /**
   * Handle series fields configuration update
   * @param series
   */
  handleSeriesChange = (series) => {
    const { reportDataVisualization, storeReportDataVisualization, reportSnapshot } = this.props
    const { chartOptions } = reportDataVisualization

    series = applyColorToSeries(series, colorOptions)

    // Apply color to pie chart data labels (pie slices)
    if (chartOptions.chartType === 'pie' && series.length && reportSnapshot.length) {
      const completeSeries = getCompleteSeries(series)
      const seriesItem = completeSeries[0] || {}
      const seriesData = getHighChartsDataPerSeries({ reportSnapshot, seriesItem, chartOptions })
      series[0].dataLabelsColor = applyColorToDataLabels(seriesData, seriesItem.dataLabelsColor, colorOptions)
    }

    storeReportDataVisualization({
      chartOptions: {
        ...chartOptions,
        series: [...series],
        yAxis: this.deriveYAxisStateFromProps({ series })
      }
    })
  }

  /**
   * Handle aggregation configuration update
   * @param aggregationModel
   */
  handleAggregationChange = (aggregationModel) => {
    const { reportDataVisualization, storeReportDataVisualization } = this.props
    const { chartOptions } = reportDataVisualization

    storeReportDataVisualization({
      chartOptions: {
        ...chartOptions,
        aggregationModel
      }
    })
  }

  /**
   * Handle yAxis configuration update
   * @param yAxis
   */
  handleYAxisChange = (yAxis) => {
    const { reportDataVisualization, storeReportDataVisualization } = this.props
    const { chartOptions } = reportDataVisualization

    storeReportDataVisualization({
      chartOptions: {
        ...chartOptions,
        yAxis: [...yAxis]
      }
    })
  }

  /**
   * Render ReportWidget component
   */
  renderReportWidget = () => {
    const { title, reportDataConfig, reportSnapshot, reportSnapshotStatus, reportSnapshotId, reportDataVisualization } = this.props
    const { fields, filters, entityType, limit, sort, isPivot, pivotFields } = reportDataConfig || {}
    const { widgetType, chartOptions, showTitle } = reportDataVisualization

    return (
      <ReportWidget
        widgetPadding={30}
        title={title}
        showTitle={showTitle}
        widgetType={widgetType}
        entityTypes={entityType}
        fields={fields}
        pivotFields={pivotFields}
        filters={filters}
        limit={limit}
        sort={sort}
        chartOptions={chartOptions}
        data={reportSnapshot}
        dataId={reportSnapshotId}
        dataStatus={reportSnapshotStatus}
        isPivot={isPivot}
      />
    )
  }

  /**
   * Render Chart Editor
   */
  renderChartEditor = () => {
    const { reportDataConfig, reportDataVisualization, reportSnapshot } = this.props
    const { availableXAxisFields, availableSeriesFields, availablePivotValues, availablePeers, availableIndices } = this.state
    const { widgetType, chartOptions, showTitle } = reportDataVisualization
    const { chartType, aggregationModel, xAxis, yAxis, series } = chartOptions || {}
    const isStockReport = getIsReportType(reportDataConfig.entityType, stockEntityType)
    const isActivityReport = getIsReportType(reportDataConfig.entityType, activityEntityType)

    /**
     * Helper function to get editor options based on chartType
     * @param chartType
     * @return {Array}
     */
    const getEditorOptions = ({ chartType }) => {
      const editorOptions = [
        {
          id: 'chartType',
          title: 'Chart Style',
          panel: (
            <ReportChartStyle
              widgetType={widgetType}
              chartType={chartType}
              onChange={this.handleChartTypeChange}
              isActivityReport={isActivityReport}
            />
          )
        }
      ]

      switch (chartType) {
        case 'bar':
        case 'column':
        case 'line':
        case 'combo':
          return [
            ...editorOptions,
            {
              id: 'data',
              title: 'Configure Chart',
              panel: (
                <ReportChartConfig
                  chartType={chartType}
                  titleConfig={{
                    showTitle,
                    onChange: this.handleShowTitleChange
                  }}
                  xAxisConfig={{
                    xAxis,
                    availableXAxisFields,
                    onChange: this.handleXAxisFieldChange
                  }}
                  seriesConfig={{
                    series,
                    availablePeers,
                    availableIndices,
                    availableSeriesFields,
                    availablePivotValues,
                    onChange: this.handleSeriesChange
                  }}
                  aggregationConfig={{
                    aggregationModel,
                    onChange: this.handleAggregationChange
                  }}
                />
              )
            },
            {
              id: 'series',
              title: 'Customize Series',
              panel: (
                <ReportChartSeries
                  chartType={chartType}
                  series={series}
                  onChange={this.handleSeriesChange}
                />
              )
            },
            {
              id: 'axis',
              title: 'Configure Axis',
              panel: (
                <ReportChartAxis
                  chartType={chartType}
                  isStockReport={isStockReport}
                  yAxis={yAxis}
                  series={series}
                  onChange={this.handleYAxisChange}
                />
              )
            }
          ]
        case 'pie':
          return [
            ...editorOptions,
            {
              id: 'pie-config',
              title: 'Configure Pie',
              panel: (
                <ReportPieConfig
                  titleConfig={{
                    showTitle,
                    onChange: this.handleShowTitleChange
                  }}
                  xAxisConfig={{
                    xAxis,
                    availableXAxisFields,
                    onChange: this.handleXAxisFieldChange
                  }}
                  seriesConfig={{
                    series,
                    availablePeers,
                    availableIndices,
                    availableSeriesFields,
                    availablePivotValues,
                    onChange: this.handleSeriesChange
                  }}
                  aggregationConfig={{
                    aggregationModel,
                    onChange: this.handleAggregationChange
                  }}
                />
              ),
            },
            {
              id: 'pie-slices',
              title: 'Customize Pie Slices',
              panel: (
                <ReportPieSlices
                  chartType={chartType}
                  series={series}
                  chartOptions={chartOptions}
                  reportSnapshot={reportSnapshot}
                  onChange={this.handleSeriesChange}
                />
              )
            }
          ]
        default:
          return [
            ...editorOptions,
            {
              id: 'table-config',
              title: 'Configure Table',
              panel: (
                <ReportTableConfig
                  titleConfig={{
                    showTitle,
                    onChange: this.handleShowTitleChange
                  }}
                />
              )
            }
          ]
      }
    }

    return (
      <Scrollbars
        className='react-scrollbar'
        autoHide
        hideTracksWhenNotNeeded
        renderThumbVertical={renderDarkThumb}
        renderTrackVertical={renderTrackVertical}
      >
        <header className='report-data-visualizer_header'><i className='q4i-chart-4pt' />Chart Editor</header>
        <ToggleList
          accordion
          theme={THEMES.SOFT_GREY}
          initialOpenItems={['chartType']}
          items={getEditorOptions({ chartType })}
        />
      </Scrollbars>
    )
  }

  /**
   * Render Report Config Component
   * @return {}
   */
  render () {
    return (
      <section className='report-data-visualizer'>
        <section className='report-data-visualizer_pane report-data-visualizer_pane--widget'>
          {this.renderReportWidget()}
        </section>
        <section className='report-data-visualizer_pane report-data-visualizer_pane--config'>
          {this.renderChartEditor()}
        </section>
      </section>
    )
  }
}

ReportDataVisualizer.propTypes = {
  title: PropTypes.string,

  /**
   * Notify parent of visualization change
   * This function can be extended in future to provide the context of the change
   * Currently will only trigger the empty event
   */
  onChange: PropTypes.func
}

const mapStateToProps = (state) => {
  const reportState = state.report
  const { reportDataVisualization, reportDataConfig, reportSnapshot, reportPeerConfig } = reportState

  return {
    reportDataVisualization,
    reportDataConfig,
    reportSnapshot: reportSnapshot.unwoundData || [],
    reportSnapshotStatus: reportSnapshot.status,
    reportSnapshotId: reportSnapshot.meta && reportSnapshot.meta._id,
    peers: reportPeerConfig.peers || [],
    ticker: getActiveTicker(state.profile && state.profile.data)
  }
}

const mapDispatchToProps = (dispatch) => ({
  storeReportDataVisualization: bindActionCreators(storeReportDataVisualization, dispatch),
  resetReportDataConfig: bindActionCreators(resetReportDataConfig, dispatch),
  resetReportSnapshot: bindActionCreators(resetReportSnapshot, dispatch),
  resetReportDataItem: bindActionCreators(resetReportDataItem, dispatch),
  clearPeers: bindActionCreators(clearPeers, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportDataVisualizer)
