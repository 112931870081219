import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// actions
import { useCommentQuery, useCommentCreateQuery, useCommentUpdateQuery, useCommentDeleteQuery } from '../../hook'
import { openModal, closeModal, createToast, modalType } from '../../../../actions'

// components
import EditModal from './edit.component'
import { get } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  isCard: PropTypes.bool.isRequired,
  entity: PropTypes.object.isRequired,
  comment: PropTypes.object,
  onSaveSuccess: PropTypes.func
}

const defaultProps = {
  dataId: 'EntityCommentEdit',
  isCard: false,
  entity: {}
}

/**
 * Comment Edit (Create/Update/Delete)
 * @param props
 */
function EntityCommentEdit (props) {
  const { dataId, isCard, entity, comment, openModal, closeModal, createToast, onSaveSuccess } = props
  const { entityId, entityType } = entity

  const { loading, error, data } = useCommentQuery({
    skip: comment && comment.id,
    variables: { entityId },
    onError: () => openModal({
      type: modalType.ERROR_MODAL
    })
  })
  const [handleCreate, { loading: createProgress }] = useCommentCreateQuery()
  const [handleUpdate, { loading: updateProgress }] = useCommentUpdateQuery()
  const [handleDelete, { loading: deleteProgress }] = useCommentDeleteQuery()

  const entityComment = comment || get(data, 'comment.items[0]', {})
  const { id } = entityComment

  /**
   * Handle comment save
   * @param comment
   */
  const handleCommentSave = (comment) => {
    const request = id
      ? (!comment || !comment.length)
        ? handleDelete({ variables: { id } })
        : handleUpdate({ variables: { id, comment } })
      : handleCreate({ variables: { entity: { entityId, entityType }, comment } })

    request.then((response) => get(response, 'errors')
      ? handleFailure()
      : handleSuccess(get(response, 'data.comment', {}))
    )
  }

  /**
   * On Action Success
   * @param response
   */
  function handleSuccess (response) {
    const { create, update } = response
    const actionType = id
      ? update ? 'updated' : 'deleted'
      : create && 'created'

    onSaveSuccess && onSaveSuccess()

    closeModal()
    createToast({ text: `Comment ${actionType} successfully.` })
  }

  /**
   * On Action Completion Failure display an error
   */
  function handleFailure () {
    openModal({
      type: modalType.ERROR_MODAL
    })
  }

  return (!loading && !error) && <EditModal
    dataId={dataId}
    isCard={isCard}
    entity={entity}
    entityComment={entityComment}
    loading={[createProgress, updateProgress, deleteProgress].some((item) => !!item)}
    onSave={handleCommentSave}
    onClose={closeModal}
  />
}

EntityCommentEdit.propTypes = propTypes
EntityCommentEdit.defaultProps = defaultProps

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
  createToast: bindActionCreators(createToast, dispatch)
})

export default connect(null, mapDispatchToProps)(EntityCommentEdit)
