/**
 * Modal Constants
 * @type {string}
 */

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS'

export const modalType = {
  ERROR_MODAL: 'ERROR_MODAL',
  CONFIRM_MODAL: 'CONFIRM_MODAL',
  INFO_MODAL: 'INFO_MODAL',
  ACTIVITY_MODAL: 'ACTIVITY_MODAL',
  EMAIL_PREFERENCE_MODAL: 'EMAIL_PREFERENCE_MODAL',
  EMAIL_UNDELIVERED_MODAL: 'EMAIL_UNDELIVERED_MODAL',
  ADD_TO_ADDRESS_BOOK_MODAL: 'ADD_TO_ADDRESS_BOOK_MODAL',
  ADD_TO_BRIEFING_BOOK_MODAL: 'ADD_TO_BRIEFING_BOOK_MODAL',
  BRIEFING_BOOK_EDIT_MODAL: 'BRIEFING_BOOK_EDIT_MODAL',
  TEARSHEET_EXPORT_MODAL: 'TEARSHEET_EXPORT_MODAL',
  CONTACT_MODAL: 'CONTACT_MODAL',
  DEAL_EDIT_MODAL: 'DEAL_EDIT_MODAL',
  ENTITY_COMMENT_EDIT_MODAL: 'ENTITY_COMMENT_EDIT_MODAL',
  ENTITY_UPDATE_REQUEST_MODAL: 'ENTITY_UPDATE_REQUEST_MODAL',
  PEER_CONFIG_MODAL: 'PEER_CONFIG_MODAL',
  GDPR_MODAL: 'GDPR_MODAL',
  CONFIRM_NAVIGATION_MODAL: 'CONFIRM_NAVIGATION_MODAL',
  EVENT_DETAIL_MODAL: 'EVENT_DETAIL_MODAL'
}

/**
 * Open Modal
 * @param params
 * @param params.type - modal type
 * @param params.component - render component
 * @param params.props - component props
 */
export const _openModal = (params = {}) => ({
  type: OPEN_MODAL,
  payload: params
})

/**
 * Close Modal
 */
export const _closeModal = () => ({
  type: CLOSE_MODAL
})

/**
 * Close All Modals
 */
export const _closeAllModals = () => ({
  type: CLOSE_ALL_MODALS
})

/**
 * Dispatch OPEN_MODAL
 * @param params
 * @param params.type - modal type
 * @param params.component - render component
 * @param params.props - component props
 */
export const openModal = (params) => (dispatch) => {
  return dispatch(_openModal(params))
}

/**
 * Close Modal
 */
export const closeModal = () => (dispatch) => {
  return dispatch(_closeModal())
}

/**
 * Close All Modals
 */
export const closeAllModals = () => (dispatch) => {
  return dispatch(_closeAllModals())
}
