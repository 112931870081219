import React from 'react'
import { Toolbar, ToolbarRow } from '../../../../../../components'
import { RangeTab } from '../../../../../../components/shared'

/**
 * CategoryFilter Component
 * @param options
 * @param counts
 * @param selectedFilter
 * @param onClick
 */
const CategoryFilter = ({
  options = [
    { value: 'all', label: 'All' },
    { value: 'institution', label: 'Institution' },
    { value: 'fund', label: 'Fund' },
    { value: 'contact', label: 'Contact' }
  ],
  counts = {},
  selectedFilter = 'all',
  onClick
}) => {
  const buttons = options.map((option) => ({
    ...option,
    active: selectedFilter === option.value,
    counter: counts[option.value] || 0,
    onClick: () => onClick({ type: option.value })
  }))

  return <RangeTab theme='ink' buttons={buttons} value={selectedFilter} />
}

/**
 * Attendee Toolbar Component
 * @param type
 * @param counts
 * @param handleFilterChange
 */
const AttendeeToolbar = ({ type, counts, handleFilterChange }) => {
  return (
    <Toolbar theme='q4-blue'>
      <ToolbarRow>
        <CategoryFilter selectedFilter={type} counts={counts} onClick={handleFilterChange} />
      </ToolbarRow>
    </Toolbar>
  )
}

export default AttendeeToolbar
