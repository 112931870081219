import React, { useRef, useState, useEffect } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

// components
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'

// utils
import { DEFAULT_DATE_FORMAT, format, formatDate, getLocalizedFormat, getQuarterEndDates } from '../../../../utils'
import { capitalize, get } from 'lodash'

const propTypes = {
  height: PropTypes.number,
  active: PropTypes.string.isRequired,
  ownership: PropTypes.array.isRequired,
  quarters: PropTypes.number,
  quarterDate: PropTypes.string,
  config: PropTypes.object
}

const defaultProps = {
  active: 'style',
  ownership: [],
  quarters: 4,
  config: {
    spacing: [20, 8, 20, 4],
    pointWidth: 40,
    axis: {
      title: { x: -4, y: 2 },
      labels: { x: 32, y: 2 },
      color: '#939ba0',
      titleColor: '#939ba0',
      labelColor: '#939ba0',
      labelFontSize: '11px',
      gridLineColor: '#373B41'
    },
    columnColor: [
      '#0f5ca3',
      '#1abc9c',
      '#ec6a4c',
      '#804a8c',
      '#fc7e26'
    ],
    legend: {
      position: { x: 0, y: 16 },
      symbolHeight: 10,
      symbolWidth: 10,
      symbolRadius: 16,
      style: {
        color: '#939ba0',
        fontWeight: 'normal',
        fontSize: '12px',
        cursor: 'default'
      }
    }
  }
}

/**
 * Security Ownership Chart
 * @param props
 */
function SecurityOwnershipChart (props) {
  const highcharts = useRef()
  const [config, setConfig] = useState(null)

  useEffect(() => {
    props && setConfig(_getChartConfig(props))
  }, [props])

  return config
    ? <HighchartsReact
        ref={highcharts}
        highcharts={Highcharts}
        options={config}
      />
    : null
}

/**
 * Highstock chart config for series
 * @param props
 */
const _getSeriesConfig = (props) => {
  const { active, ownership, quarters, quarterDate, config } = props

  const dates = getQuarterEndDates(quarters, quarterDate)
  const cleanOwnershipItems = ownership.filter(item => item)
  return (cleanOwnershipItems || []).map((item, index) => {
    const label = item[`institution${capitalize(active)}`] || 'Unset'
    const records = (dates || []).map((date, idx) => [
      moment.utc(date, DEFAULT_DATE_FORMAT).valueOf(),
      item[`q${idx + 1}Value`]
    ]).reverse()

    return {
      id: `${active}-${label}`,
      dataId: `${active}-${label}`,
      name: label,
      type: 'column',
      color: config.columnColor[index],
      data: records || []
    }
  })
}

/**
 * Base highcharts config for columns chart
 * @param props
 */
const _getChartConfig = (props) => {
  const { config, width, height } = props

  return {
    chart: {
      animation: false,
      spacing: config.spacing,
      backgroundColor: null,
      className: 'security-ownership_chart chart-general q4-fade-in',
      style: {
        fontFamily: 'Open Sans'
      },
      width,
      height
    },
    noData: {
      style: { display: 'none' }
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    rangeSelector: { enabled: false },
    title: { text: null },
    legend: {
      align: 'left',
      x: get(config, 'legend.position.x'),
      y: get(config, 'legend.position.y'),
      symbolHeight: get(config, 'legend.symbolHeight'),
      symbolWidth: get(config, 'legend.symbolWidth'),
      symbolRadius: get(config, 'legend.symbolRadius'),
      itemHoverStyle: { ...get(config, 'legend.style') },
      itemStyle: { ...get(config, 'legend.style') }
    },
    xAxis: {
      id: 'datetime-axis',
      type: 'datetime',
      lineWidth: 0,
      tickWidth: 0,
      title: {
        text: null
      },
      labels: {
        rotation: 0,
        style: {
          color: get(config, 'axis.labelColor'),
          fontSize: get(config, 'axis.labelFontSize')
        },
        formatter: function () {
          return moment.tz(this.value, 'America/New_York').format(getLocalizedFormat(DEFAULT_DATE_FORMAT))
        }
      },
      units: [['month', [3]]]
    },
    yAxis: {
      gridLineWidth: 1,
      lineWidth: 0,
      tickWidth: 0,
      opposite: true,
      plotLines: false,
      showLastLabel: false,
      allowDecimals: false,
      gridLineColor: get(config, 'axis.gridLineColor'),
      title: {
        text: '%OS',
        align: 'high',
        rotation: 0,
        x: get(config, 'axis.title.x'),
        y: get(config, 'axis.title.y'),
        style: {
          color: get(config, 'axis.titleColor')
        }
      },
      labels: {
        align: 'right',
        style: {
          color: get(config, 'axis.color'),
          fontSize: get(config, 'axis.labelFontSize')
        },
        x: get(config, 'axis.labels.x'),
        y: get(config, 'axis.labels.y')
      }
    },
    tooltip: {
      useHTML: true,
      shared: true,
      split: false,
      hideDelay: 350,
      formatter: function () {
        const points = get(this, 'points')
        const date = formatDate(Number(this.x), 'MMMM D, YYYY', true, true)

        return `<div class='chart-tip'>
          <div class='chart-tip_header'>${date}</div>
          ${(points || []).map((item, index) => {
            const { color, series, y } = (item || {})
            return (
              `<span key={index} class='chart-tip_body'>
                <span class='chart-tip_circle' style='color: ${color}'>\u25CF</span>
                <span class='chart-tip_name'>${get(series, 'name')}:</span>
                <span class='chart-tip_value'>${format(y, 2)}%</span>
              </span>`
            )
          }).join(' ')}
        </div>`
      }
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        maxPointWidth: config.pointWidth,
        groupPadding: 0.2,
        pointPadding: 0.05,
        minPointLength: 2,
        allowPointSelect: false,
        dataLabels: { enabled: false },
        events: {
          legendItemClick: function () { return false }
        }
      },
      flags: {
        allowPointSelect: true,
        enableMouseTracking: true,
        animation: false
      },
      series: {
        states: {
          inactive: {
            opacity: 1
          },
          hover: { enabled: false }
        },
        marker: { enabled: false }
      }
    },
    series: _getSeriesConfig(props)
  }
}

SecurityOwnershipChart.propTypes = propTypes
SecurityOwnershipChart.defaultProps = defaultProps

export default SecurityOwnershipChart
