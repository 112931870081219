import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import CustomCell from './cell/cell.component'
import { AgGrid, NoContentMessage, Spinner } from '../../../../../components'
import { getQuarterEndDates, setColumnDefinition, preventDefaultRowClick, THEMES, formatDate } from '../../../../../utils'

import './table.component.css'
import { DATA_IDS } from '../../../../../tests-e2e/pages/fund/fund.definition'
const { PEER_ANALYSIS_MODIFY_PEERS } = DATA_IDS

const propTypes = {
  pageSizeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number,
  listOptions: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onPeerUpdate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  loading: false,
  data: [],
  total: 0,
  listOptions: {}
}

/**
 * Get quarter column definition based on metric
 * @returns {{}[]}
 */
const getQuarterColumnDefinition = () => {
  const quarters = 4
  const dates = getQuarterEndDates(quarters, moment.utc())

  return (dates || []).map((quarter, idx) => ({
    field: `q${idx + 1}`,
    headerName: formatDate(quarter, undefined, undefined, true),
    type: 'number',
    minWidth: 140,
    maxWidth: 140
  }))
}

/**
 * Peer Analysis Table Component
 * @param props
 */
function PeerAnalysisTable (props) {
  const { pageSizeId, loading, data, total, listOptions, onQueryChange, onPeerUpdate, history } = props
  const [agGrid, setAgGrid] = useState({})
  const [columns] = useState([
    {
      field: 'securityName',
      headerName: 'Name',
      type: 'text',
      minWidth: 440,
      pinned: 'left',
      lockPinned: true
    },
    {
      field: 'q0',
      headerName: 'Latest',
      type: 'number',
      minWidth: 140,
      maxWidth: 140
    },
    {
      field: 'reportDate',
      headerName: 'Latest Date',
      type: 'date',
      minWidth: 140,
      maxWidth: 140
    },
    ...getQuarterColumnDefinition()
  ])

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click event
   * @param id {String} - ticker id
   */
  const handleRowClick = ({ tickerId }) => {
    history && history.push(`/security/${tickerId}`)
  }

  /**
   * Handle page change
   * @param selected
   */
  const handlePageChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, page: selected }
    })
  }

  /**
   * Handle page size change
   * @param selected
   */
  const handlePageSizeChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, limit: selected, page: 1 }
    })
  }

  /**
   * Handle Column Sort
   * @param grid
   */
  const handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel && !sortModel.length) {
      return
    }

    onQueryChange({
      listOptions: {
        ...listOptions,
        sortBy: sortModel[0].colId,
        sortDir: sortModel[0].sort,
        page: 1
      }
    })
  }

  /**
   * Get NoContentMessage Props
   * @returns {{*}}
   */
  const getNoContentMessageProps = () => {
    return !loading ? {
      title: 'No Peers Available',
      message: 'Add a security to get started',
      actions: [{
        label: 'Modify Peers',
        onClick: onPeerUpdate,
        dataId: PEER_ANALYSIS_MODIFY_PEERS
      }]
    } : null
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell {...props} />
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage {...getNoContentMessageProps()} />
        : <AgGrid
          domLayout='autoHeight'
          sizeToFit

          // suppress configs
          suppressMovableColumns
          suppressContextMenu

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            sortable: false,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={setColumnDefinition({ columns })}
          rowData={data}

          // pagination
          pagination
          paginationProps={{
            pageSizeId,
            forcePage: listOptions.page,
            initialPageSize: listOptions.limit,
            showPageSizeSelection: true,
            total,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: renderCustomCell
          }}

          // event listeners
          onGridReady={handleGridReady}
          onGridSizeChanged={handleGridResize}
          onSortChanged={handleSortChange}
          onRowClicked={preventDefaultRowClick(
            handleRowClick, ['']
          )}

          isPinned={data.length} />}
    </div>
  )
}

PeerAnalysisTable.propTypes = propTypes
PeerAnalysisTable.defaultProps = defaultProps

export default memo(PeerAnalysisTable)
