import { combineReducers } from 'redux'
import quote from './quote.reducer'
import historical from './historical.reducer'
import averageVolume from './averageVolume.reducer'

export default combineReducers({
  quote,
  historical,
  averageVolume
})
