import { ENTITY_SEARCH_TYPES, SEARCH_TYPE } from './search.const'
import { get, isArray, isEqual } from 'lodash'
import { getTranscriptLink } from '../event/index'
import {
  isFeatureEnabled
} from '../../utils'
import { SEARCH_SOURCE } from '.'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../services/launchDarkly.service'

/**
 * Returns true if search query is a tag
 * @param query {String} The text query to search with
 * @returns {boolean}
 */
export const isTagSearch = (query) => {
  return !!(query && query.startsWith('#'))
}

/**
 * Get search source
 * @param query
 * @param type
 * @param profile
 * @returns {SEARCH_SOURCE}
 */
export const getSearchSource = (query, type, profile) => {
  const isSnpSearch = isFeatureEnabled(profile, 'sp_search')
  const isEntity = isEntitySearch(type)
  const isTag = isTagSearch(query)
  const isTranscript = isEqual(type, [SEARCH_TYPE.TRANSCRIPT])

  if (isTag) {
    return SEARCH_SOURCE.TAG_SEARCH
  }

  if (isEntity) {
    if (isSnpSearch) return SEARCH_SOURCE.ADVANCED_SEARCH_V3
    return SEARCH_SOURCE.GQL_ADVANCED_SEARCH
  }

  if (isTranscript) {
    return SEARCH_SOURCE.GRAPHQL_SEARCH
  }

  return SEARCH_SOURCE.ELASTIC_SEARCH
}

/**
 * Get result payload
 * @param payload
 * @returns {any[]}
 */
export const getSearchPayload = (payload = []) => {
  return (payload || []).map((item) => {
    const type = _getType(item)

    switch (type) {
      case 'contact':
        return _getContact(item)
      case 'institution':
        return _getInstitution(item)
      case 'fund':
        return _getFund(item)
      case 'security':
        return _getSecurity(item)
      case 'activity':
      case 'note':
        return _getActivity(item)
      case 'transcript':
        return _getTranscript(item)
      case 'research':
        return _getResearch(item)
      default:
        return {}
    }
  })
}

/**
 * Get GQL result payload
 * @param payload
 * @returns {any[]}
 */
export const getGqlSearchPayload = (payload = []) => {
  return (payload || []).map((item) => {
    const type = item.entityConnection?.type
    switch (type) {
      case 'contacts':
        return _getGqlContact(item)
      case 'institutions':
        return _getGqlInstitution(item)
      case 'funds':
        return _getGqlFund(item)
      case 'securities':
        return _getGqlSecurity(item)
      default:
        return {}
    }
  })
}

/**
 * Get result payload for SNP search
 * @param payload
 * @returns {any[]}
 */
export const getSearchPayloadV3 = (payload = []) => {
  return (payload || []).map((item) => {
    const type = _getType(item)

    switch (type) {
      case 'contact':
        return _getContactV3(item)
      case 'institution':
        return _getInstitutionV3(item)
      case 'fund':
        return _getFundV3(item)
      case 'security':
        return _getSecurityV3(item)
      case 'activity':
      case 'note':
        return _getActivity(item)
      case 'transcript':
        return _getTranscript(item)
      case 'research':
        return _getResearch(item)
      default:
        return {}
    }
  })
}

/**
 * Get result type
 * @param item
 * @returns {string}
 * @private
 */
const _getType = (item) => {
  const type =
    get(item, 'reference.type', '') ||
    get(item, 'type', '') ||
    get(item, '_type', '')

  return type.toLowerCase() === 'note' ? SEARCH_TYPE.ACTIVITY : type.toLowerCase()
}

/**
 * Get result data
 * @param item
 * @returns {*|{}}
 * @private
 */
const _getData = (item) => {
  return get(item, 'reference.item') || get(item, '_source') || {}
}

/**
 * Get Item Id
 * @param data
 */
const _getItemId = (data) => {
  return get(data, '_id')
}

/**
 * Check if requested type is entity search
 */
export const isEntitySearch = (type) => {
  return ENTITY_SEARCH_TYPES.some((entity) => [].concat(type).includes(entity))
}

/**
 * Check if requested type is all entity search
 */
export const isAllEntitySearch = (type) => {
  return ENTITY_SEARCH_TYPES.every((entity) => [].concat(type).includes(entity))
}
/**
 * Transform filter variables to graphQL required ones
 * @param {*} variables 
 * @returns 
 */
export const _prepareGqlVariables = (variables) => {
  const { type, entityId, query, page, limit, filter, activity, tickerId, currencyCode, byTag } = variables

  return {
    "entity": type,
    "query": query,
    "securityId": entityId,
    "tickerId": tickerId,
    "byTag": byTag,
    "filter": {
      //filter works odd, activity can be boolean or as string "true"
      "activityLogged": !!activity,
      //odd filter structure defined by swiftype - don't change
      "all": filter?.all
    },
    "currencyCode":currencyCode,
    "page": page,
    "limit": limit,
    "useElasticSearch": !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)
  }  
}


/**
 * Get Contact Payload
 * @param item
 */
const _getContact = (item) => {
  const data = _getData(item)

  return {
    _id: _getItemId(data), // @deprecated
    id: _getItemId(data),
    entityId: _getItemId(data),
    type: _getType(item),
    fullName: get(data, 'full_name'),
    jobs: get(data, 'jobs', []).map((job) => ({
      entityId: job.q4_entity_id,
      institutionName: job.institution_name,
      title: job.title,
      functions: job.functions,
      address1: job.address1,
      phone: job.phone,
      directPhone: job.direct_telephone,
      email: job.email
    })),
    source: get(data, 'source', ''),
    _favorite: get(item, '_favorite', null),
    _target: get(item, '_target', null),
    _deal: get(item, '_deal', null)
  }
}

/**
 * Get Contact Payload for V3
 * @param item
 */
const _getContactV3 = (item) => {
  const title = get(item, 'title', '')
  const phone = get(item, 'phone', '')
  const email = get(item, 'email', '')
  const address = get(item, 'address', '')
  const institution = get(item, 'institution_contact', '')

  return {
    _id: _getItemId(item), // @deprecated
    id: _getItemId(item),
    entityId: _getItemId(item),
    type: _getType(item),
    fullName: get(item, 'name'),
    jobs: [{
      entityId: get(item, 'q4_entity_id', ''),
      institutionName: (isArray(institution)) ? institution.shift() : institution,
      title: (isArray(title)) ? title.shift() : title,
      functions: get(item, 'functions', ''),
      address1: (isArray(address)) ? address.shift() : address,
      phone: (isArray(phone)) ? phone.shift() : phone,
      email: (isArray(email)) ? email.shift() : email
    }],
    _favorite: get(item, '_favorite', null),
    _target: get(item, '_target', null),
    _deal: get(item, '_deal', null)
  }
}

const _getGqlInstitution = (item) => {
  return {
    //root
    _id: item.id,
    score: item.score,
    type: "institution",
    //entityConnection
    entityId: item.entityConnection?.sourceId,
    institutionName: item.entityConnection?.institutionName,
    //position
    position: item.entityConnection?.position?.current,
    //purchasing power
    purchasingPower: item.entityConnection?.purchasingPower?.purchasingPower,
    //entity
    institutionType: item.entityConnection?.entity?.institutionType,
    style: item.entityConnection?.entity?.style,
    id: item.entityConnection?.entity?.id,
    equityAum: item.entityConnection?.entity?.equityAUM,
    totalAum: item.entityConnection?.entity?.totalAUM,
    turnover: item.entityConnection?.entity?.turnover,
    _target: item.entityConnection?.target?.id,
    _deal: item.entityConnection?.deal?.id
  }
}

const _getGqlContact = (item) => {
  return {
    _id: item.id,
    id: item.id,
    type: 'contact',
    //entityConnection
    fullName: item.entityConnection?.fullName,
    //entity
    source: item.entityConnection?.entity?.source,
    //jobs
    jobs: !item.entityConnection?.entity?.jobs ? null : [{
      entityId: item.entityConnection?.entity?.jobs[0]?.entityId,
      institutionName: item.entityConnection?.entity?.jobs[0]?.institutionName,
      title: item.entityConnection?.entity?.jobs[0]?.title,
      functions: item.entityConnection?.entity?.jobs[0]?.functions,
      address1: item.entityConnection?.entity?.jobs[0]?.address1,
      phone: item.entityConnection?.entity?.jobs[0]?.phone,
      directPhone: item.entityConnection?.entity?.jobs[0]?.directPhone,
      email: item.entityConnection?.entity?.jobs[0]?.email,
    }],
    //extras
    _favorite: item.entityConnection?.favorite?.id,
    _target: item.entityConnection?.target?.id,
    _deal: item.entityConnection?.deal?.id
  }
}

const _getGqlFund = (item) => {
  return {
    //root
    _id: item.id,
    //entityConnection
    entityId: item.entityConnection?.sourceId,
    type: 'fund',
    fundName: item.entityConnection?.fundName,
    //entity
    id: item.entityConnection?.entity?.id,
    fundType: item.entityConnection?.entity?.fundTypeDesc,
    style: item.entityConnection?.entity?.style,
    turnover: item.entityConnection?.entity?.turnover,
    institutionName: item.entityConnection?.entity?.institutionName,
    //position
    position: item.entityConnection?.position?.current,
    //purchasing power
    purchasingPower: item.entityConnection?.purchasingPower?.purchasingPower,
    //extras
    _target: item.entityConnection?.target?.id,
    _deal: item.entityConnection?.deal?.id

  }
}

const _getGqlSecurity = (item) => {
  return {
    //root
    _id: item.id,
    type: 'security',
    //entity
    securityName: item.entityConnection?.entity?.securityName,
    symbol: item.entityConnection?.entity?.symbol,
    exchange: item.entityConnection?.entity?.exchangeCode,
    capGroup: item.entityConnection?.entity?.capGroup,
    sector: item.entityConnection?.entity?.sector,
    industry: item.entityConnection?.entity?.industry
  }
}
/**
 * Get Institution Payload
 * @param item
 */
const _getInstitution = (item) => {
  const data = _getData(item)

  return {
    _id: _getItemId(data), // @deprecated
    id: get(data, 'q4_entity_id'),
    entityId: get(data, 'factset_entity_id'),
    type: _getType(item),
    institutionName: get(data, 'institution_name'),
    institutionType: get(data, 'type'),
    style: get(data, 'style'),
    turnover: get(data, 'turnover'),
    equityAum: get(data, 'equity_aum'),
    totalAum: get(data, 'total_aum'),
    position: get(item, '_position.current'),
    purchasingPower: get(item, '_purchasing_power'),
    _target: get(item, '_target', null),
    _deal: get(item, '_deal', null)
  }
}
/**
 * Get Institution Payload for V3
 * @param item
 */
const _getInstitutionV3 = (item) => {
  return {
    _id: _getItemId(item), // @deprecated
    id: get(item, 'q4_entity_id'),
    type: _getType(item),
    institutionName: get(item, 'name'),
    institutionType: get(item, 'type')
  }
}

/**
 * Get Fund Payload
 * @param item
 */
const _getFund = (item) => {
  const data = _getData(item)

  return {
    _id: _getItemId(data), // @deprecated
    id: get(data, 'q4_entity_id'),
    entityId: get(data, 'factset_fund_id'),
    type: _getType(item),
    fundName: get(data, 'fund_name'),
    institutionName: get(data, 'institution_name'),
    fundType: get(data, 'type'),
    style: get(data, 'style'),
    turnover: get(data, 'turnover'),
    position: get(item, '_position.current'),
    purchasingPower: get(item, '_purchasing_power'),
    _target: get(item, '_target', null),
    _deal: get(item, '_deal', null)
  }
}

/**
 * Get Fund Payload for V3
 * @param item
 */
const _getFundV3 = (item) => {
  return {
    _id: _getItemId(item), // @deprecated
    id: get(item, 'q4_entity_id'),
    type: _getType(item),
    fundName: get(item, 'name'),
    institutionName: get(item, 'institution'),
    fundType: get(item, 'type')
  }
}

/**
 * Get Security Payload
 * @param item
 */
const _getSecurity = (item) => {
  const data = _getData(item)

  return {
    _id: _getItemId(data),
    type: _getType(item),
    securityName: get(data, 'security_name'),
    symbol: get(data, 'symbol'),
    exchange: get(data, 'exchange'),
    capGroup: get(data, 'cap_group'),
    sector: get(data, 'sector'),
    industry: get(data, 'industry')
  }
}
/**
 * Get Security Payload for V3
 * @param item
 */
const _getSecurityV3 = (item) => {
  return {
    _id: _getItemId(item),
    type: _getType(item),
    securityName: get(item, 'name'),
    symbol: get(item, 'symbol'),
    exchange: get(item, 'exchange'),
    capGroup: get(item, 'cap_group'),
    sector: get(item, 'sector'),
    industry: get(item, 'industry')
  }
}

/**
 * Get Activity Payload
 * @param item
 */
const _getActivity = (item) => {
  const data = _getData(item)
  const tags = get(data, 'tag', [])

  return {
    _id: _getItemId(item),
    type: _getType(item),
    title: get(data, 'title'),
    category: get(data, 'category'),
    start: get(data, 'start'),
    end: get(data, 'end'),
    allDay: get(data, 'all_day'),
    tags: tags.filter((tag) => !!tag.name)
  }
}

/**
 * Get Transcript Payload
 * @param item
 */
const _getTranscript = (item) => {
  const { highlight } = item

  const data = _getData(item)
  const path = get(data, 'url', '')
  const query = get(data, 'query', '')

  return {
    _id: _getItemId(item),
    type: _getType(item),
    date: get(data, 'date'),
    title: get(data, 'title'),
    security: get(data, 'company_name'),
    highlight: get(highlight, 'pdf.content', ''),
    url: getTranscriptLink(path, false, query)
  }
}

/**
 * Get Research Payload
 * @param item
 */
const _getResearch = (item) => {
  return {
    _id: get(item, 'key'),
    type: _getType(item),
    headline: get(item, 'headline'),
    date: get(item, 'date'),
    url: get(item, 'uri')
  }
}
