import React from 'react'

const CrmEmailIFrame = (props) => {
    const {
      id,
      baseClassName,
      iframeId,
      iframeRef,
      iframeSrc,
    } = props;

    return (
      <div id={id} className={baseClassName}>
        <iframe
          id={iframeId}
          title={iframeId}
          {...iframeSrc && { src: iframeSrc }}
          {...iframeRef && {ref: iframeRef }}
        />
      </div>
    )
  }

  export default CrmEmailIFrame;
