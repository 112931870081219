import React from 'react'
import { Link } from 'react-router-dom'
import { isParent as _isParent } from '../../../../utils/activity'
import { isEmptyBody } from '../../../../utils/htmlEditor.util'
import { formatDate } from '../../../../utils/date.util'
import { RenderHTML } from '../../../../components'

import './overview.component.css'

// limit for participants, locations
const LIMIT = 8
const VIRTUAL = 'Virtual'

/**
 * Participants Component
 * @param participants
 */
const Participants = ({ participants }) => {
  if (Array.isArray(participants) && participants.length) {
    return participants.slice(0, LIMIT).map((participant, index) => {
      const { _corporateparticipant, full_name, suspended } = participant
      return (
        suspended
          ? <div
            key={`participant--${index}`}
            className='activity-detail_overview_participant'
          >{full_name}
            <span className='activity-detail_overview_participant--deleted'>(deleted)</span>
          </div>
          : <div
            key={`participant--${index}`}
            className='activity-detail_overview_participant'
          >
            <Link
              to={`/corporate-participant/${_corporateparticipant}`}
              className='activity-detail_overview_link'
            >{full_name}
            </Link>
          </div>
      )
    })
  }

  return (
    <div className='activity-detail_overview_participant'>0 participants</div>
  )
}

/**
 * Returns locations for parent, and all child itineraries
 * @param activity
 */
const getLocations = (activity) => {
  const locations = []
  let virtual = activity && activity.virtual;

  (activity.address || []).forEach((address) => {
    if (address.location) {
      locations.push(address.location)
    }
  });

  (activity._itinerary || []).forEach((itinerary) => {
    const location = itinerary.address && itinerary.address[0] && itinerary.address[0].location

    if (itinerary && itinerary.virtual && !virtual) {
      virtual = true
    }

    if (location && locations.indexOf(location) === -1) {
      locations.push(location)
    }
  })

  return virtual
    ? [].concat(VIRTUAL, locations)
    : locations
}

/**
 * Return created/update date of the activity
 * @param deal
 * @returns {*}
 */
const getActivityTimeStamp = ({ title, date, profile }) => {
  const timeStamp = `${formatDate(date, 'h:mma', false, true)} ${formatDate(date, 'MM/DD/YY', false, true)}`
  const name = profile && ` - ${profile.firstName} ${profile.lastName}`

  return (<p><span>{title}</span>{timeStamp} {name}</p>)
}

/**
 * Activity Detail Overview Component
 */
const ActivityDetailOverview = (props) => {
  const { activity, children } = props
  const { _deal, _profile, category, address, participants, body, create_date, revisions } = activity
  const venue = (address && address.length) ? address[0].venue : null
  const locations = getLocations(activity)

  return (
    <div className='activity-detail_overview'>
      <div className='activity-detail_overview_columns'>
        <div className='activity-detail_overview_columns--full'>{children}</div>
      </div>

      <div className='activity-detail_overview_columns'>
        <div className='activity-detail_overview_columns--left'>

          <div className='activity-detail_overview_section'>
            <h2 className='activity-detail_overview_subtitle'>Corporate Participant(s)</h2>
            <Participants participants={participants} />
          </div>

          {(_deal && _deal._id) && (
            <div className='activity-detail_overview_section'>
              <h2 className='activity-detail_overview_subtitle'>Deal</h2>
              <div className='activity-detail_overview_deal'>
                <Link
                  to={`/deal/${_deal._id}`}
                  className='activity-detail_overview_link'
                >{_deal.title}
                </Link>
              </div>
            </div>
          )}

        </div>

        <div className='activity-detail_overview_columns--right'>

          <div className='activity-detail_overview_section'>
            <h2 className='activity-detail_overview_subtitle'>Location</h2>
            {locations.length
              ? locations.slice(0, LIMIT).map((location, index) => {
                return (
                  <div
                    key={`location-${index}`}
                    className='activity-detail_overview_location'
                  >{location}
                  </div>
                )
              })
              : <div className='activity-detail_overview_location'>...</div>}

            {(!_isParent(category) && venue && venue.length) && (
              <div className='activity-detail_overview_venue'>{venue}</div>
            )}
          </div>

          {(_deal && _deal._id) && (
            <div className='activity-detail_overview_section'>
              <h2 className='activity-detail_overview_subtitle'>Notes</h2>
              <div className='activity-detail_overview_notes'>
                {!isEmptyBody(body) ? <RenderHTML html={body} /> : '...'}
              </div>
            </div>
          )}

        </div>
      </div>

      <div className='activity-detail_overview_columns'>
        <div className='activity-detail_overview_columns--full'>

          {!_deal && (
            <div className='activity-detail_overview_section'>

              <div className='activity-detail_overview_section'>
                <h2 className='activity-detail_overview_subtitle'>Notes</h2>
                <div className='activity-detail_overview_notes'>
                  {!isEmptyBody(body) ? <RenderHTML html={body} /> : '...'}
                </div>
              </div>
            </div>
          )}

          <div className='activity-detail_overview_section'>
            <div className='activity-detail_overview_audit-trail'>
              {getActivityTimeStamp({ title: 'Created', date: create_date, profile: _profile })}
              {(revisions && revisions.length) ? getActivityTimeStamp({ title: 'Updated', date: revisions[0].date, profile: revisions[0]._profile }) : ''}
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default ActivityDetailOverview
