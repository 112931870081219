import React from 'react'
import ReactHighstock from 'react-highcharts/ReactHighstock'
import {
  silver,
  lightSlate,
  white
} from '../../../../resources/materialui-overrides/colors'
import './institutionWebAnalytics.component.css'

const InstitutionWebAnalytics = (props) => {
  const createPlotOptions = () => {
    return {
      series: {
        stacking: 'normal',
        borderWidth: 0,
        borderRadius: 2,
        pointWidth: 15,
        maxPointWidth: 15,
        minPointLength: 1,
        colorByPoint: true
      }
    }
  }

  const createSeries = (data) => {
    return [{
      data: (data || []).map((item) => {
        return item.sessions
      })
    }]
  }

  const getChartConfig = () => {
    const categories = props.items.map((item) => item.name)
    const isNarrowLayout = props.layout && props.layout.w === 1

    const labelStyle = {
      'font-family': '"Open Sans", Arial !important',
      color: props.theme === 'dark' ? white : lightSlate,
      fontWeight: 'normal',
      fontSize: '13px',
      minWidth: isNarrowLayout ? '130px' : 'auto',
      width: isNarrowLayout ? '130px' : 'auto',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }

    return {
      chart: {
        type: 'bar',
        backgroundColor: 'transparent',
        spacing: [0, 0, 0, 0],
        height: (categories.length < 5) ? 15 + categories.length * 25 : (categories.length * 25)
      },
      credits: { enabled: false },
      colors: props.colors.map((item) => item.value),
      title: {
        text: ''
      },
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      rangeSelector: {
        enabled: false
      },
      xAxis: [{
        minorGridLineWidth: 0,
        tickLength: 0,
        tickInterval: 0.3,
        categories,
        labels: {
          style: labelStyle,
          enabled: true,
          align: 'left',
          reserveSpace: true,
          useHTML: true,
          formatter: function () {
            const item = props.items[this.value]
            const color = props.theme === 'dark' ? white : lightSlate

            if (item) {
              return `<a href="${'/institution/' + item.factsetId}" target="_parent" style="font-family: 'Open Sans', 'Arial';font-size: 13px; color: ${color};white-space: nowrap;overflow: hidden;text-overflow: ellipsis; display: block"> ${item.name}</a>`
            } else {
              return categories[this.value]
            }
          }
        },
        lineWidth: 0
      }],
      yAxis: {
        gridLineWidth: 0,
        offset: 8,
        opposite: false,
        allowDecimals: false,
        labels: {
          style: {
            color: silver,
            fontWeight: 'normal',
            fontSize: '13px'
          },
          overflow: 'justify',
          y: 17,
          x: 0
        },
        showLastLabel: true,
        lineWidth: 0.2,
        lineColor: props.theme === 'dark' ? white : lightSlate
      },
      plotOptions: createPlotOptions(),
      tooltip: {
        enabled: true,
        crosshairs: false,
        useHTML: true,
        followPointer: true,
        formatter: function () {
          return (`
                        <div class="highcharts-tooltip_wrapper">
                            <span style="color: ${this.color}; font-size: 18px; line-height: 16px;">\u25CF</span>
                            <h3>${props.items[this.point.index].name}</h3>
                            <span>${props.items[this.point.index].sessions}</span>
                        </div>
                    `)
        }
      },
      series: createSeries(props.items)
    }
  }
  return <ReactHighstock config={getChartConfig()} />
}

export default InstitutionWebAnalytics
