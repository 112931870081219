import React, { PureComponent } from 'react';
import { AgGrid, PopoverMenu } from '../../../../../../components';
import { getDefaultColumnDef, preventDefaultRowClick } from '../../../../../../utils/agGrid.util';
import CustomCellRender from './cell/cell.component';

const columns = [
    { ...getDefaultColumnDef({ columnIndex: 0 }), field: 'start_date', headerName: 'start date', minWidth: 120, maxWidth: 120 },
    { ...getDefaultColumnDef({ columnIndex: 2 }), field: 'time', headerName: 'time', minWidth: 300, maxWidth: 300 },
    { ...getDefaultColumnDef({ columnIndex: 3 }), field: 'title', headerName: 'title', minWidth: 220 },
    { ...getDefaultColumnDef({ columnIndex: 4 }), field: 'contact', headerName: 'contact', minWidth: 180, maxWidth: 220 },
    { ...getDefaultColumnDef({ columnIndex: 5 }), field: 'institution', headerName: 'institution', minWidth: 180, maxWidth: 220 },
    { ...getDefaultColumnDef({ columnIndex: 6 }), field: 'participants', headerName: 'participants', minWidth: 180, maxWidth: 220 },
    { ...getDefaultColumnDef({ columnIndex: 7 }), field: 'location', headerName: 'venue', minWidth: 160, maxWidth: 200 }
];

class ItineraryGrid extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            popoverMenuProps: null
        };
    }

    /**
     * Handle AgGrid onGridReady event
     * @param grid
     * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
     */
    handleGridReady = (grid) => {
        setTimeout(() => this.agGrid = grid, 0);
    };

    /**
     * Handle AgGrid gridSizeChanged event
     * @param type - event type
     */
    handleGridResize = ({ type }) => {
        if (type === 'gridSizeChanged') {
            setTimeout(() => this.agGrid && this.agGrid.api.sizeColumnsToFit(), 0);
        }
    };

    /**
     * On Expand Menu Click
     * Displays expandable menu for contact/institutions/participants
     * @param event
     * @param popoverMenuProps
     */
    onExpandMenuClick = (event, popoverMenuProps) => {
        event.stopPropagation();
        this.setState({ popoverMenuProps });
    };

    /**
     * On Expand Menu Close Click
     * @param event
     */
    onExpandMenuCloseClick = (event) => {
        event.stopPropagation();
        this.setState({ popoverMenuProps: null });
    };

    handleRowClicked = (data) => {
        const { _id } = data;
        const { history } = this.props;
        const link = `/activity/${_id}`;
        _id && history.push(link);
    };

    /**
     * On Expand Menu Item Click
     * Handler for activity links menu item click
     * @param event
     * @param data
     */
    onExpandMenuItemClick = (event, data) => {
        event.stopPropagation();

        const { history } = this.props;
        const type = (data && data.entity_type) || '';

        if(data._corporateparticipant) {
            return history.push(`/corporate-participant/${data._corporateparticipant}`);
        }

        switch (type.toLowerCase()) {
            case 'contact':
                history.push(`/contact/${data.item}`);
                break;
            case 'institution':
                history.push(`/institution/${data.entity_id}`);
                break;
            default:
                return;
        }
    };

    /**
     * Renders CustomCell Component
     */
    renderCustomCell = (props) => {
        return (
            <CustomCellRender
                {...props}
                onExpandMenuClick={this.onExpandMenuClick}
            />
        );
    };

    render() {
        let {
            items,
            total,
            filters: {page, limit},
            handleFilterChange
        } = this.props;

        return (
            <div className='activity-itinerary-grid'>
                <AgGrid
                    className='itinerary-grid data-table'
                    domLayout='autoHeight'
                    sizeToFit={true}

                    // suppress configs
                    suppressMovableColumns={true}
                    suppressContextMenu={true}

                    // columns and data
                    defaultColDef={{
                        suppressMenu: true,
                        sortable: false,
                        cellRenderer: 'CustomCellRender'
                    }}

                    // event listeners
                    onGridReady={this.handleGridReady}
                    onGridSizeChanged={this.handleGridResize}
                    onRowClicked={preventDefaultRowClick(
                        this.handleRowClicked,
                        ['expandable-cell']
                    )}

                    frameworkComponents={{CustomCellRender: this.renderCustomCell}}
                    columnDefs={columns}
                    rowData={items}

                    // pagination
                    pagination={true}
                    paginationProps={{
                        pageSizeId: 'itinerary-grid',
                        forcePage: page,
                        initialPageSize: limit,
                        showPageSizeSelection: true,
                        total,
                        onPageChange: ({selected}) => handleFilterChange({page: selected}),
                        onPageSizeChange: ({selected}) => handleFilterChange({page: 1, limit: selected})
                    }}
                />

                {/* Popover Menu for Custom Cell */}
                {this.state.popoverMenuProps && (
                    <PopoverMenu
                        onClose={this.onExpandMenuCloseClick}
                        onClick={this.onExpandMenuItemClick}
                        scrollable={true}
                        {...this.state.popoverMenuProps}
                    />
                )}
            </div>
        );
    }
}

export default ItineraryGrid;
