import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { getDeals as _getDeals } from '../deal'
import { normalizeBody, normalizeEndDate } from '../../utils'
export const SAVE_ACTIVITY_REQUEST = 'SAVE_ACTIVITY_REQUEST'
export const SAVE_ACTIVITY_SUCCESS = 'SAVE_ACTIVITY_SUCCESS'
export const SAVE_ACTIVITY_SUCCESS_BUILD_ITINERARY = 'SAVE_ACTIVITY_BUILD_ITINERARY_SUCCESS'
export const SAVE_ACTIVITY_SUCCESS_ADD_ANOTHER = 'SAVE_ACTIVITY_ADD_ANOTHER_SUCCESS'
export const SAVE_ACTIVITY_FAILURE = 'SAVE_ACTIVITY_FAILURE'
export const DELETE_ACTIVITY_REQUEST = 'DELETE_ACTIVITY_REQUEST'
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS'
export const DELETE_ACTIVITY_FAILURE = 'DELETE_ACTIVITY_FAILURE'
export const GET_LOCATIONS_REQUEST = 'GET_LOCATIONS_REQUEST'
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE'
export const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA'
export const RESET_ACTIVITY_FORM = 'RESET_ACTIVITY_FORM'
export const SEARCH_ACTIVITY_DEALS_REQUEST = 'SEARCH_ACTIVITY_DEALS_REQUEST'
export const SEARCH_ACTIVITY_DEALS_SUCCESS = 'SEARCH_ACTIVITY_DEALS_SUCCESS'
export const SEARCH_ACTIVITY_DEALS_FAILURE = 'SEARCH_ACTIVITY_DEALS_FAILURE'
export const SAVE_CONTACTS_REQUEST = 'SAVE_CONTACTS_REQUEST'
export const SAVE_CONTACTS_SUCCESS = 'SAVE_CONTACTS_SUCCESS'
export const SAVE_CONTACTS_FAILURE = 'SAVE_CONTACTS_FAILURE'

const _create = (data) => ({
  [CALL_API]: {
    types: [
      SAVE_ACTIVITY_REQUEST,
      SAVE_ACTIVITY_SUCCESS,
      SAVE_ACTIVITY_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/crm/activity',
    payload: data
  }
})

const _createBuildItinerary = (data) => ({
  [CALL_API]: {
    types: [
      SAVE_ACTIVITY_REQUEST,
      SAVE_ACTIVITY_SUCCESS_BUILD_ITINERARY,
      SAVE_ACTIVITY_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/crm/activity',
    payload: data
  }
})

const _createAddAnother = (data) => ({
  [CALL_API]: {
    types: [
      SAVE_ACTIVITY_REQUEST,
      SAVE_ACTIVITY_SUCCESS_ADD_ANOTHER,
      SAVE_ACTIVITY_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/crm/activity',
    payload: data
  }
})

const _update = (id, data) => ({
  [CALL_API]: {
    types: [
      SAVE_ACTIVITY_REQUEST,
      SAVE_ACTIVITY_SUCCESS,
      SAVE_ACTIVITY_FAILURE
    ],
    method: METHOD_TYPE.PUT,
    endpoint: `/crm/activity/${id}`,
    payload: data
  }
})

const _updateBuildItinerary = (id, data) => ({
  [CALL_API]: {
    types: [
      SAVE_ACTIVITY_REQUEST,
      SAVE_ACTIVITY_SUCCESS_BUILD_ITINERARY,
      SAVE_ACTIVITY_FAILURE
    ],
    method: METHOD_TYPE.PUT,
    endpoint: `/crm/activity/${id}`,
    payload: data
  }
})

const _updateAddAnother = (id, data) => ({
  [CALL_API]: {
    types: [
      SAVE_ACTIVITY_REQUEST,
      SAVE_ACTIVITY_SUCCESS_ADD_ANOTHER,
      SAVE_ACTIVITY_FAILURE
    ],
    method: METHOD_TYPE.PUT,
    endpoint: `/crm/activity/${id}`,
    payload: data
  }
})

const _delete = (id) => ({
  [CALL_API]: {
    types: [
      DELETE_ACTIVITY_REQUEST,
      DELETE_ACTIVITY_SUCCESS,
      DELETE_ACTIVITY_FAILURE
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: `/crm/activity/${id}`
  }
})

const _reset = (data) => ({
  type: RESET_ACTIVITY_FORM,
  payload: data
})

const _searchLocation = (params) => ({
  [CALL_API]: {
    types: [
      GET_LOCATIONS_REQUEST,
      GET_LOCATIONS_SUCCESS,
      GET_LOCATIONS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/targeting/location/search',
    payload: params
  }
})

const _clearSearchData = () => ({
  type: CLEAR_SEARCH_DATA
})

const _addContactsToAddressBook = (ids) => ({
  [CALL_API]: {
    types: [
      SAVE_CONTACTS_REQUEST,
      SAVE_CONTACTS_SUCCESS,
      SAVE_CONTACTS_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/addressbook',
    payload: { contact: ids, copy: null, list: [], newList: '', onlyNew: true }
  }
})

export const searchDeals = (query) => (dispatch) => {
  const params = {
    query,
    limit: 20
  }
  const options = {
    types: [
      SEARCH_ACTIVITY_DEALS_REQUEST,
      SEARCH_ACTIVITY_DEALS_SUCCESS,
      SEARCH_ACTIVITY_DEALS_FAILURE
    ]
  }
  return dispatch(_getDeals(params, options))
}

export const clearSearchData = () => (dispatch) => {
  return dispatch(_clearSearchData())
}

export const searchLocation = (params) => (dispatch) => {
  return dispatch(_searchLocation(params))
}

export const createActivity = (data) => (dispatch) => {
  // Remove any dangling empty p tag from DraftJS
  data.body = (data.body && data.body.length) ? normalizeBody(data.body) : ''

  return dispatch(_create(data))
}

export const createActivityBuildItinerary = (data) => (dispatch) => {
  return dispatch(_createBuildItinerary(data))
}

export const createActivityAddAnother = (data) => (dispatch) => {
  return dispatch(_createAddAnother(data))
}

export const deleteActivity = (id) => (dispatch) => {
  return dispatch(_delete(id))
}

export const resetActivityForm = (data) => (dispatch) => {
  return dispatch(_reset(data))
}

/**
 * @param { boolean } normalizeEndDateOptions.apply decides wheather or not to apply normalization
 * @param { date } normalizeEndDateOptions.originalEndDate recieved endDate (unchanged)
 * @param { date } normalizeEndDateOptions.affectedEndDate currentEndDate
 */
export const updateActivity = (id, data, normalizeEndDateOptions = {}) => (dispatch) => {
  // Remove any dangling empty p tag from DraftJS
  data.body = (data.body && data.body.length) ? normalizeBody(data.body) : ''

  // Normalize EndDate so it does not push to the next day
  if (normalizeEndDateOptions.apply) { normalizeEndDate(data, normalizeEndDateOptions) }

  return dispatch(_update(id, data))
}

/**
 * @param { boolean } normalizeEndDateOptions.apply decides wheather or not to apply normalization
 * @param { date } normalizeEndDateOptions.originalEndDate recieved endDate (unchanged)
 * @param { date } normalizeEndDateOptions.affectedEndDate currentEndDate
 */
export const updateActivityBuildItinerary = (id, data, normalizeEndDateOptions = {}) => (dispatch) => {
  // Normalize EndDate so it does not push to the next day
  if (normalizeEndDateOptions.apply) { normalizeEndDate(data, normalizeEndDateOptions) }

  return dispatch(_updateBuildItinerary(id, data))
}

export const updateActivityAddAnother = (id, data) => (dispatch) => {
  return dispatch(_updateAddAnother(id, data))
}

export const addContactsToAddressBook = (contactIds) => (dispatch) => {
  return dispatch(_addContactsToAddressBook(contactIds))
} 