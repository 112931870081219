import { has, cloneDeep } from 'lodash'
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FETCHED, FAILED, fetchEstimates, resetBrokerData } from '../../../actions/widget/estimates'
import { _isFinite, format, inThousands } from '../../../utils/number.util'
import { setClassNames } from '../../../utils/widget.util'
import EstimatesPanel from '../../../components/widget/estimates/estimates.component'
import { Button, Spinner } from '../../../components'
import { Dropdown } from '../../../components/shared'
import { getUserDefaultMetric } from '../../../utils/user.util'
import WidgetError from '../../../components/widget/error/widgetError.component'
import './estimates.container.css'
import { THEMES } from '../../../utils'

const allStatesIn = (status, state, props = []) => {
  for (const prop of props) {
    if (state[prop].status !== status) {
      return false
    }
  }
  return true
}

class Estimates extends Component {
  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      current: getUserDefaultMetric(props.profile, props.securityId)
    }
  };

  /**
   * ComponentDidMount
   */
  componentDidMount = () => {
    const { isEdit, isFetched } = this.props

    if (isEdit && isFetched) {
      return
    }

    const refreshInterval = this.props.options.refreshInterval
    if (refreshInterval) {
      this.timer = setInterval(this._fetchData.bind(this), refreshInterval)
    }

    this._fetchData()
  }

  /**
   * ComponentDidUpdate
   * Re-fetch data when security changed
   * @param prevProps
   */
  componentDidUpdate = (prevProps) => {
    const { securityId, profile } = this.props

    if (prevProps.securityId !== securityId) {
      this.setState({ current: getUserDefaultMetric(profile, securityId) }, () => {
        this.props.resetBrokerData()
        this._fetchData()
      })
    }
  }

  /**
   * ComponentWillUnmount
   */
  componentWillUnmount = () => {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  /**
   * Fetch Estimates data
   * @private
   */
  _fetchData = () => {
    const { securityId } = this.props
    this.props.loadEstimates(securityId)
  }

  /**
   * Handle Metric Dropdown Change
   * @param value
   */
  handleDropdownChange = (value) => {
    this.setState({
      current: value || 'eps'
    })
  }

  /**
   * Get estimates value or supply a dash
   * @param value
   * @returns {string}
   */
  getValue = (value) => {
    if (!value && value !== 0) {
      return '-'
    }

    return format(value, 2)
  }

  /**
   * Convert number to scaled figure
   * @param number
   * @returns {string}
   */
  getConvertedNumber = (number) => {
    if (_isFinite(number)) {
      if (Math.abs(number) > 100) {
        return inThousands(number, 2) + 'B'
      } else {
        return format(number, 2) + 'M'
      }
    } else {
      return '-'
    }
  }

  /**
   * Get abbreviated rating
   * @param rating
   * @returns {string|*}
   */
  getRating = (rating) => {
    switch (rating) {
      case 'Overweight':
        return 'Ovwt'
      case 'Underweight':
        return 'Unwt'
      default:
        return rating
    }
  }

  /**
   * Get Current Estimate Data
   * @returns {{quarter: {}, prevQuarter: {}, year: {}, prevYear: {}}}
   */
  getCurrentEstimate = () => {
    const { current } = this.state
    const estimates = cloneDeep(this.props[current])

    const quarter = estimates.quarter || {}
    const prevQuarter = estimates.prevQuarter || {}
    const year = estimates.year || {}
    const prevYear = estimates.prevYear || {}

    // to indicate increase/decrease in value from previous actual
    quarter.change = ((quarter.estimate || 0) - (prevQuarter.actual || 0))
    year.change = ((year.estimate || 0) - (prevYear.actual || 0))

    switch (current) {
      case 'ffo':
      case 'eps':
        quarter.estimate = this.getValue(quarter.estimate || 0)
        prevQuarter.actual = this.getValue(prevQuarter.actual || 0)
        year.estimate = this.getValue(year.estimate || 0)
        prevYear.actual = this.getValue(prevYear.actual || 0)
        break
      case 'sales':
      case 'ebitda':
        quarter.estimate = this.getConvertedNumber(quarter.estimate || 0)
        prevQuarter.actual = this.getConvertedNumber(prevQuarter.actual || 0)
        year.estimate = this.getConvertedNumber(year.estimate || 0)
        prevYear.actual = this.getConvertedNumber(prevYear.actual || 0)
        break
      default:
        return { quarter, prevQuarter, year, prevYear }
    }

    return { quarter, prevQuarter, year, prevYear }
  }

  /**
   * Render Estimates Widget
   * @returns {XML}
   */
  render () {
    const { isFetched, rating, eps, sales, ebitda, ffo, layout, theme, securityId, history, status, subscriptions } = this.props
    const { current } = this.state
    const hasAllData = has(rating, 'targetPrice') && has(eps, 'currency') && has(sales, 'currency') && has(ebitda, 'currency')

    if (subscriptions.includes('estimates_research')) {
      return (
        <div>
          <header className='estimates-container_header'>
            <h2 className='estimates-container_title' onClick={() => history.push(estimatesPagePath)}>Estimates</h2>
          </header>
          <div className='estimates-container_button-container'>
            <Button theme={THEMES.CITRUS} icon='q4i-rate-2pt' label='View Estimates' linkTo='https://www.capitaliq.com/CIQDotNet/my/dashboard.aspx' target='_blank' />
          </div>
        </div>
      )
    }

    if (status === FAILED && !hasAllData) {
      return (
        <WidgetError
          theme={theme}
          header='Estimates'
          message='There is no estimates data available.'
        />
      )
    }

    const estimatesPagePath = `/estimates/${securityId}`
    const classes = setClassNames('estimates-container', layout, theme)
    const narrowStyle = layout && layout.w === 1 ? 'narrow' : 'wide'
    const dropdownOptions = [
      has(eps, 'currency') ? 'eps' : null,
      has(sales, 'currency') ? 'sales' : null,
      has(ebitda, 'currency') ? 'ebitda' : null,
      has(ffo, 'currency') ? 'ffo' : null
    ].filter((option) => option)

    return (
      <div className={classes.base}>
        <header className='estimates-container_header'>
          <h2 className='estimates-container_title' onClick={() => history.push(estimatesPagePath)}>Estimates</h2>
          <Dropdown
            className='estimates-container_dropdown'
            value={current}
            options={dropdownOptions}
            theme={theme === 'dark' ? 'slate' : 'pale-grey'}
            width={128}
            height={30}
            itemHeight={30}
            onSelect={this.handleDropdownChange}
          />
        </header>

        <div className='estimates-container_body'>
          {!isFetched && !hasAllData && (
            <Spinner />
          )}
          <div className={`rating-container rating-container--${narrowStyle}`}>
            <div className='rating-container_value'>{this.getRating(rating.rating)}</div>
            <div className='rating-container_details'>
              <div className='target-price'>{format(rating.targetPrice, 2)}
                <span className='rating-container_label'>Target</span>
              </div>
              <div className='analysts'>{rating.analysts}
                <span className='rating-container_label'># Analysts</span>
              </div>
            </div>
          </div>
          <div className='current-estimates-container'>
            <EstimatesPanel {...this.getCurrentEstimate()} {...this.props} />
          </div>
        </div>
        <footer className='estimates-container_footer'>
          <div className='estimates-container_footer-label'>
            <span>In US dollars</span>
            <Link to={estimatesPagePath}>View Details</Link>
          </div>
        </footer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const consensusDetails = state.widget.estimates.current
  const rating = state.widget.estimates.rating
  return {
    profile: state.shared.profile,
    eps: consensusDetails.eps || {},
    ffo: consensusDetails.ffo || {},
    rating: (rating && rating.rating) || {},
    sales: consensusDetails.sales || {},
    ebitda: consensusDetails.ebitda || {},
    status: consensusDetails.status,
    isFetched: allStatesIn(FETCHED, consensusDetails, ['eps', 'sales', 'ebitda', 'ffo']) && rating.status === FETCHED,
    theme: state.dashboard.dashboardState.theme,
    securityId: state.dashboard.dashboardState.securityId
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadEstimates: bindActionCreators(fetchEstimates, dispatch),
    resetBrokerData: bindActionCreators(resetBrokerData, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Estimates))
