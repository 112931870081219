import {
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENTS_ERROR,
  FETCH_EVENTS_REQUEST_GQL,
  IDLE,
  FAILED,
  FETCHING,
  FETCHED
} from '../../../actions/widget/events/events.actions'

const initial = {
  events: [],
  status: IDLE
}

const events = (state = initial, action) => {
  switch (action.type) {
    case FETCH_EVENTS_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_EVENTS_REQUEST_GQL:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
        status: FETCHED
      }
    case FETCH_EVENTS_ERROR:
      return {
        ...state,
        error: action.error,
        status: FAILED
      }
    default:
      return state
  }
}

export default events
