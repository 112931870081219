import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { addQueryParams } from '../../../utils/http/http.util'

export const FETCH_DOWNLOADS_ANALYTICS_REQUEST = 'FETCH_DOWNLOADS_ANALYTICS_REQUEST'
export const FETCH_DOWNLOADS_ANALYTICS_SUCCESS = 'FETCH_DOWNLOADS_ANALYTICS_SUCCESS'
export const FETCH_DOWNLOADS_ANALYTICS_ERROR = 'FETCH_DOWNLOADS_ANALYTICS_ERROR'

export const FETCHING = 'FETCHING'
export const IDLE = 'IDLE'
export const FETCHED = 'FETCHED'
export const FAILED = 'FAILED'

const resources = {
  analytics: '/analytics'
}

const _fetchDownloadsAnalytics = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_DOWNLOADS_ANALYTICS_REQUEST,
      FETCH_DOWNLOADS_ANALYTICS_SUCCESS,
      FETCH_DOWNLOADS_ANALYTICS_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

export const fetchDownloadsAnalytics = (params) => (dispatch) => {
  const url = addQueryParams(resources.analytics, params)
  return dispatch(_fetchDownloadsAnalytics(url))
}
