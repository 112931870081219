import gql from 'graphql-tag'
import client from '../../../middleware/graphql.middleware'

export const FETCH_TOP_PEER_MOVERS_REQUEST = 'FETCH_TOP_PEER_MOVERS_REQUEST'
export const FETCH_TOP_PEER_MOVERS_SUCCESS = 'FETCH_TOP_PEER_MOVERS_SUCCESS'
export const FETCH_TOP_PEER_MOVERS_ERROR = 'FETCH_TOP_PEER_MOVERS_ERROR'

export const SET_INCLUDE_MY_SECURITY = 'SET_INCLUDE_MY_SECURITY'
export const RESET_TOP_PEER_MOVERS_STATUS = 'RESET_TOP_PEER_MOVERS_STATUS'

/**
 * Top peer movers
 */
const TOP_PEER_MOVERS = gql`
  query PeerMover(
    $tickerId: String!
    $isSecurity: Boolean
    $limit: Int!
  ) {
    peerMover(
      tickerId: $tickerId
      filter: {
        isSecurity: $isSecurity
      }
      limit: $limit
    ) {
      items {
        institutionId
        institutionName
        holderType
        absChange
        change
      }
      count
    }
  }
`

const _fetchTopMovers = ({ tickerId, isSecurity = false }, dispatch) => {
  dispatch({ type: FETCH_TOP_PEER_MOVERS_REQUEST })
  return client
    .query({
      query: TOP_PEER_MOVERS,
      variables: {
        tickerId,
        isSecurity,
        limit: 10
      },
      fetchPolicy: 'no-cache'
    })
    .then((data) => {
      dispatch({ payload: data, type: FETCH_TOP_PEER_MOVERS_SUCCESS })
    })
    .catch((error) => {
      dispatch({ error, type: FETCH_TOP_PEER_MOVERS_ERROR })
    })
}

const setIncludeMySecurity = (value) => ({
  type: SET_INCLUDE_MY_SECURITY,
  value
})

export const resetStatus = () => ({
  type: RESET_TOP_PEER_MOVERS_STATUS
})

export const fetchTopMovers = (params) => (dispatch, getState) => {
  const state = getState()
  const tickerId = params.tickerId || state.dashboard.dashboardState.tickerId
  dispatch(setIncludeMySecurity(params.isSecurity))
  return _fetchTopMovers({ ...params, tickerId }, dispatch)
}
