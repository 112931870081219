import React, { Component } from 'react'
import moment from 'moment-timezone'
import { map, find, isEmpty } from 'lodash'
import renderHTML from 'react-render-html'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderDarkThumb, renderLightThumb, renderTrackVertical } from '../../../resources/theme/q4.custom-scrollbar'
import { FETCHED, FETCHING, getNews, resetStatus } from '../../../actions/widget/news/news.actions'
import { getNewsModal } from '../../../actions/news/modal/newsModal.actions'
import WidgetError from '../../../components/widget/error/widgetError.component'
import { setClassNames } from '../../../utils/widget.util'
import { getFromXigniteToStandard } from '../../../utils/stock/stock.util'
import { Modal, Spinner } from '../../../components'
import { Checkbox } from '../../../components/shared'
import { getLocalizedFormat, getTickers } from '../../../utils'
import './news.container.css'
import { withRouter } from 'react-router-dom'

class News extends Component {

  constructor (props) {
    super(props)

    this.state = {
      isModalOpen: false
    }
  }

  _fetchData = () => {
    const { getNews, inWatchlist, inPressRelease, securityId } = this.props

    getNews({
      securityId,
      inWatchlist,
      inPressRelease,
      startDate: moment.utc().subtract(3, 'months').toISOString()
    })
  }

  /**
   * Triggered when component mounted
   * Enable auto-reloading if enabled
   */
  componentDidMount () {
    const { isEdit, newsStatus } = this.props
    if (isEdit && newsStatus === FETCHED) {
      return
    }

    const refreshInterval = this.props.options.refreshInterval
    if (refreshInterval) {
      this.timer = setInterval(this._fetchData.bind(this), refreshInterval)
    }

    this._fetchData()
  }

  /**
   * ComponentDidUpdate
   * Re-fetch data when security changed
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    if (prevProps.securityId !== this.props.securityId) {
      this._fetchData()
    }
  }

  /**
   * clear fetch interval on unmount
   */
  componentWillUnmount () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  /**
   * Open news details Modal
   */
  handleOpenModal = () => {
    this.setState({
      isModalOpen: true
    })
  }

  /**
   * Close news details Modal
   */
  handleCloseModal = () => {
    this.setState({
      isModalOpen: false
    })
  }

  /**
   * Handle Peer Change
   * @param value
   */
  handleChange = (value) => {
    const { resetStatus, getNews, inPressRelease, securityId } = this.props

    resetStatus()
    getNews({
      securityId,
      inWatchlist: value,
      inPressRelease,
      startDate: moment.utc().subtract(3, 'months').toISOString()

    })
  }

  /**
   * When clicking on a news item, load the modal
   * @param id
   */
  handleItemClick = (id) => {
    this.props.getNewsModal(id)
    this.handleOpenModal()
  }

  /**
   * Update Tabs
   * @param tab
   */
  updateTab = (tab) => {
    const { getNews, inWatchlist, resetStatus, securityId } = this.props
    const inPressRelease = !(tab === 'all')

    resetStatus()

    getNews({
      securityId,
      inWatchlist,
      inPressRelease,
      startDate: moment.utc().subtract(3, 'months').toISOString()
    })
  }

  /**
   * Render News Modal Subtitle
   * @param source
   * @param date
   * @returns {XML}
   */
  renderNewsModalSubtitle = (source, date) => {
    return (
      <span className='news-widget_modal-subtitle'>
                {source ? (
                  <span>{source}</span>
                ) : ''}
        {date ? (
          <span><i className='q4i-time-4pt'/>{moment(date).fromNow()}</span>
        ) : ''}
                </span>
    )
  }

  /**
   * Render News Modal Body
   * @param headline
   * @param html
   * @returns {*}
   */
  renderNewsModalBody = (headline, html) => {
    let parsedHTML = html ? html.replace(/<a href/g, '<a target="_blank" href') : 'No detail available'

    return (
      <div className='news-widget_modal-body'>
        <h3 className='news-widget_modal-headline'>{renderHTML(headline || '')}</h3>
        {renderHTML(parsedHTML || '')}
      </div>
    )
  }

  /**
   * Render Tabs
   * @param classes
   * @returns {XML}
   */
  renderTabs = (classes) => {
    const { inPressRelease } = this.props

    return (
      <ul className={`${classes.name}_tabs`}>
        <li onClick={() => this.updateTab('all')}
            className={`${classes.name}_tab` + (inPressRelease ? `` : ` ${classes.name}_tab--active`)}>ALL
        </li>
        <li onClick={() => this.updateTab('release')}
            className={`${classes.name}_tab` + (inPressRelease ? ` ${classes.name}_tab--active` : ``)}>PRESS
          RELEASES
        </li>
      </ul>
    )
  }

  /**
   * Render News Items
   * @param my_company
   * @param news
   * @param classes
   */
  renderItems = (my_company, news, classes) => {
    return map((news || []), (each) => {
      const company = each.company
      const companyClass = (my_company && my_company.symbol === (company && company.symbol)) ? `${classes.name}_company--active` : ''

      return (
        <div key={each._id} className={classes.item}>
          <div className={`${classes.name}_company ${companyClass}`}>
            {(each.type === 'press') && <i className='q4i-press-releases-4pt'/>}
            <span>{company.symbol} {getFromXigniteToStandard(company.exchange)}</span>
          </div>
          <div className={`${classes.name}_headline`} onClick={() => this.handleItemClick(each._id)}
               dangerouslySetInnerHTML={{ __html: each.headline }}/>
          <div className={`${classes.name}_date`}>{moment(each.date).format(getLocalizedFormat('LLLL'))}</div>
        </div>
      )
    })
  }

  /**
   * Render News Widget Component
   * @returns {XML}
   */
  render () {
    const { newsStatus, theme, layout, news, newsModal, newsModalStatus, profile, inWatchlist, history, tickerId } = this.props
    const { isModalOpen } = this.state

    const isLoading = newsStatus === FETCHING
    const classes = setClassNames('news-widget', layout, theme)
    const tickers = getTickers(profile)
    const renderThumb = theme === 'dark' ? renderLightThumb : renderDarkThumb
    // get active company from organization's tickers, fallback to primary ticker
    const company = find((tickers), (ticker) => ticker._security === this.props.securityId) || find((tickers), (ticker) => ticker.primary)

    const handleRedirect = () => tickerId && history.push(`/security/${tickerId}`)

    return (
      <div className={classes.base}>
        <header className={`${classes.name}_header`}>
          <h2 className='news-widget_title' onClick={handleRedirect}>NEWS</h2>
          {company ? (
            <div className='news-widget_input'>
              <Checkbox
                theme={theme}
                id='peer-toggle'
                label='Peers'
                labelAlign='left'
                size='small'
                isChecked={inWatchlist}
                onChange={this.handleChange}
              />
            </div>
          ) : null}
          {this.renderTabs(classes)}
        </header>
        <section className={classes.items}>
          {(!isLoading && isEmpty(news)) ?
            <WidgetError
              theme={theme}
              message={'There is no news to display.'}
            /> :
            (isLoading && isEmpty(news)) ? <Spinner/> :
              <Scrollbars
                className='react-scrollbar'
                autoHide
                hideTracksWhenNotNeeded
                renderThumbVertical={renderThumb}
                renderTrackVertical={renderTrackVertical}>
                {this.renderItems(company, news, classes)}
              </Scrollbars>
          }
        </section>
        <footer className={`${classes.name}_footer`}/>

        <Modal
          visible={isModalOpen}
          loading={newsModalStatus === FETCHING}
          scrollable={true}
          containerWidth={960}
          containerMaxWidth={'md'}
          contentPadding='20px'
          contentBackground={true}
          title={`${company.name} ${company.symbol} ${getFromXigniteToStandard(company.exchange)}`}
          subtitle={this.renderNewsModalSubtitle(newsModal.source, newsModal.date)}
          onClose={this.handleCloseModal}
          spinnerProps={{
            maskOpacity: 1
          }}
        >
          {this.renderNewsModalBody(newsModal.headline, newsModal.story)}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const newsModal = state.news.modal

  return {
    news: state.widget.news.list,
    newsStatus: state.widget.news.status,
    newsModal: newsModal.data,
    newsModalStatus: newsModal.status,
    theme: state.dashboard.dashboardState.theme,
    profile: state.shared.profile,
    inWatchlist: state.widget.news.inWatchlist,
    inPressRelease: state.widget.news.inPressRelease,
    securityId: state.dashboard.dashboardState.securityId
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getNews: bindActionCreators(getNews, dispatch),
    getNewsModal: bindActionCreators(getNewsModal, dispatch),
    resetStatus: bindActionCreators(resetStatus, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(News))
