import React from 'react'
import PropTypes from 'prop-types'
import { RadioButton } from '../../../../components/shared'
import { isAllEntitySearch, SEARCH_TYPE, ENTITY_SEARCH_TYPES } from '../../../../utils'
import { get, isEqual } from 'lodash'

/**
 * Search Type Filter
 * @param props
 * @returns {*}
 * @constructor
 */
const Type = (props) => {
  const { type, onChange, subscriptions } = props

  /**
   * On type filter change
   * @param event
   */
  const handleOnChange = (event) => {
    const value = (event && event.target && event.target.value) || 'all'
    const type = value !== 'all' ? [].concat(value) : ENTITY_SEARCH_TYPES
    onChange(type)
  }

  return (
    <section className='search-page_filter-section'>
      <div className='search-page_filter-header'>Filter Results</div>
      <div className='search-page_filter-content'>
        <div className='field field--full field--grouped'>
          <RadioButton
            id='searchTypeAll'
            value='all'
            label='All Results'
            name='type'
            isSelected={isAllEntitySearch(type)}
            onChange={handleOnChange}
          />
        </div>
        <div className='field field--full field--grouped'>
          <RadioButton
            id='searchTypeContact'
            value={SEARCH_TYPE.CONTACT}
            label='Contacts'
            name='type'
            isSelected={isEqual(type, [SEARCH_TYPE.CONTACT])}
            onChange={handleOnChange}
          />
        </div>
        <div className='field field--full field--grouped'>
          <RadioButton
            id='searchTypeInstitution'
            value={SEARCH_TYPE.INSTITUTION}
            label='Institutions'
            name='type'
            isSelected={isEqual(type, [SEARCH_TYPE.INSTITUTION])}
            onChange={handleOnChange}
          />
        </div>
        <div className='field field--full field--grouped'>
          <RadioButton
            id='searchTypeFund'
            value={SEARCH_TYPE.FUND}
            label='Funds'
            name='type'
            isSelected={isEqual(type, [SEARCH_TYPE.FUND])}
            onChange={handleOnChange}
          />
        </div>
        <div className='field field--full field--grouped'>
          <RadioButton
            id='searchTypeSecurity'
            value={SEARCH_TYPE.SECURITY}
            label='Securities'
            name='type'
            isSelected={isEqual(type, [SEARCH_TYPE.SECURITY])}
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className='search-page_filter-content'>
        <div className='field field--full field--grouped'>
          <RadioButton
            id='searchTypeActivity'
            value={SEARCH_TYPE.ACTIVITY}
            label='Activities'
            name='type'
            isSelected={isEqual(type, [SEARCH_TYPE.ACTIVITY])}
            onChange={handleOnChange}
          />
        </div>
        <div className='field field--full field--grouped'>
          <RadioButton
            id='searchTypeTranscript'
            value={SEARCH_TYPE.TRANSCRIPT}
            label='Transcripts'
            name='type'
            isSelected={isEqual(type, [SEARCH_TYPE.TRANSCRIPT])}
            onChange={handleOnChange}
          />
        </div>
        {subscriptions && !get(subscriptions, 'estimates_research')
          ? (
            <div className='field field--full field--grouped'>
              <RadioButton
                id='searchTypeResearch'
                value={SEARCH_TYPE.RESEARCH}
                label='Research'
                name='type'
                isSelected={isEqual(type, [SEARCH_TYPE.RESEARCH])}
                onChange={handleOnChange}
              />
            </div>
            )
          : null}
      </div>
    </section>
  )
}

Type.propTypes = {
  type: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

Type.defaultProps = {
  type: ENTITY_SEARCH_TYPES
}

export default Type
