import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { addQueryParams } from '../../../utils/http/http.util'
import { push } from 'connected-react-router'

export const FETCH_PEER_STOCKQUOTE_HISTORICAL_REQUEST = 'FETCH_PEER_STOCKQUOTE_HISTORICAL_REQUEST'
export const FETCH_PEER_STOCKQUOTE_HISTORICAL_SUCCESS = 'FETCH_PEER_STOCKQUOTE_HISTORICAL_SUCCESS'
export const FETCH_PEER_STOCKQUOTE_HISTORICAL_ERROR = 'FETCH_PEER_STOCKQUOTE_HISTORICAL_ERROR'
export const REMOVE_STOCK_HISTORICAL_PEER = 'REMOVE_STOCK_PEER_HISTORICAL'

export const STOCK_PEER_REQUEST = 'STOCK_PEER_REQUEST'
export const STOCK_PEER_SUCCESS = 'STOCK_PEER_SUCCESS'
export const STOCK_PEER_FAILURE = 'STOCK_PEER_FAILURE'

const resources = {
  historicalV2: '/stock/historical/v2',
  peer: '/peer'
}

const fetchPeerStockQuoteHistorical = (url) => ({
  [CALL_API]: {
    types: [FETCH_PEER_STOCKQUOTE_HISTORICAL_REQUEST, FETCH_PEER_STOCKQUOTE_HISTORICAL_SUCCESS, FETCH_PEER_STOCKQUOTE_HISTORICAL_ERROR],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
})

const removePeerHistorical = (payload) => ({
  type: REMOVE_STOCK_HISTORICAL_PEER,
  payload
})

const fetchPeers = (url) => ({
  [CALL_API]: {
    types: [STOCK_PEER_REQUEST, STOCK_PEER_SUCCESS, STOCK_PEER_FAILURE],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
})

const stockError = (error) => ({
  type: error.type,
  error: error.error,
  status: error.status
})

export const loadPeerStockQuoteHistorical = (params) => (dispatch) => {
  if (!params || !params.securityId) {
    return Promise.all([dispatch(stockError({
      type: FETCH_PEER_STOCKQUOTE_HISTORICAL_ERROR,
      error: 'You must provide a valid securityId',
      status: 404
    })), dispatch(push('/error'))
    ])
  }

  const url = addQueryParams(resources.historicalV2, params)
  return dispatch(fetchPeerStockQuoteHistorical(url))
}

export const removePeerStockQuoteHistorical = (params) => (dispatch) => {
  return dispatch(removePeerHistorical(params))
}

export const loadPeers = (params) => (dispatch) => {
  const url = addQueryParams(resources.peer, params)

  return dispatch(fetchPeers(url))
}
