import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  closeModal,
  emailPreference,
  getAttendeeList,
  getItineraryAttendeeList,
  modalType,
  openModal,
  setEmailRecipients,
  statusType,
  updateUserEmailPreference
} from '../../../../../actions'

import AttendeeToolbar from './toolbar/toolbar.component'
import AttendeeGrid from './grid/grid.component'

import { launchDarklyHelper, LD_FEATURE_FLAGS } from '../../../../../services/launchDarkly.service'
import { CRM_EMAIL_ROUTE, getIsBrowser } from '../../../../../utils'
import { config } from '../../../../../config'

import './attendee.page.css'

class AttendeeGridContainer extends PureComponent {
  constructor (props) {
    super(props)
    const { isParent, showPosition } = props

    this.state = {
      isParent,
      filters: {
        limit: 10,
        page: 1,
        type: 'all',
        position: showPosition
      }
    }
  }

  /**
   * Fetch Attendees data
   */
  getAttendees = () => {
    const { fetchAttendees, fetchItineraryAttendees, activityId, securityId, tickerId, useGraphQL } = this.props
    const { isParent, filters } = this.state

    isParent
      ? fetchItineraryAttendees(activityId, { ...filters, securityId, tickerId }, useGraphQL)
      : fetchAttendees(activityId, { ...filters, securityId, tickerId }, useGraphQL)
  }

  /**
   * Filter activities based on changes in filter state params
   * @param filters
   */
  handleFilterChange = (filters) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...{ page: 1 },
          ...filters
        }
      },
      () => this.getAttendees()
    )
  }

  componentDidMount () {
    this.getAttendees()
  }

  componentDidUpdate (prevProps) {
    const { activityForm, activityId, showPosition, tickerId } = this.props
    const { filters } = this.state
    const idChanged = prevProps.activityId !== activityId
    const tickerChanged = prevProps.tickerId !== tickerId

    if ((prevProps.activityForm.status !== activityForm.status && activityForm.status === statusType.SUCCESS) || idChanged || tickerChanged) {
      this.getAttendees()
    }

    if (prevProps.showPosition !== showPosition) {
      this.setState({ filters: { ...filters, position: showPosition } }, () => this.getAttendees())
    }
  }

  /**
   * Handle single email click
   * @param {object} contact
   */
  handleEmailClick = (contact) => {
    const isCrmEmailFeatureEnabled = !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.CRM_EMAIL)
    const browser = getIsBrowser();
    const isCompatibleBrowser = browser.isChrome || browser.isEdge || browser.isEdgeChromium;
    const { userEmailPreference } = this.props
    const { firstName, lastName, name: fullName, institutionName: organizationName, email, _id: contactId } = contact || {}

    if (!isCrmEmailFeatureEnabled || !isCompatibleBrowser || userEmailPreference === emailPreference.DEFAULT_EMAIL) {
      if (email) {
        const href = `mailto:${email}?Subject=Contact%20from%20Q4%20Desktop&bcc=${config.loggerEmail}`
        window.location.assign(href)
      }
    } else if (isCrmEmailFeatureEnabled && userEmailPreference === emailPreference.CRM_EMAIL) {
      const recipient = {
        firstName,
        lastName,
        fullName,
        organizationName,
        email,
        contactId,
      }

      this.props.setEmailRecipients([recipient])
      this.props.history.push(CRM_EMAIL_ROUTE.EMAIL)
    } else if (isCrmEmailFeatureEnabled && userEmailPreference === emailPreference.INITIAL) {
      this.props.openModal({
        type: modalType.EMAIL_PREFERENCE_MODAL,
        props: {
          activityContact: contact
        }
      })
    }
  }

  render () {
    const { attendeeState: { data, counts, total, status }, history } = this.props
    const { filters } = this.state

    return (
      <div className='attendee-tab'>
        <AttendeeToolbar
          type={filters.type}
          counts={counts}
          handleFilterChange={this.handleFilterChange}
        />
        <AttendeeGrid
          loading={status === statusType.IN_PROGRESS}
          data={data}
          total={total}
          filters={filters}
          handleFilterChange={this.handleFilterChange}
          history={history}
          handleEmailClick={this.handleEmailClick}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    activityForm: state.activity.activityForm,
    attendeeState: state.activity.attendee,
    userEmailPreference: state?.addressBook?.contacts?.userEmailPreference
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchAttendees: bindActionCreators(getAttendeeList, dispatch),
  fetchItineraryAttendees: bindActionCreators(getItineraryAttendeeList, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
  updateUserEmailPreference: bindActionCreators(updateUserEmailPreference, dispatch),
  setEmailRecipients: bindActionCreators(setEmailRecipients, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AttendeeGridContainer))
