import { useRef } from 'react'

export const useAgGrid = (props) => {
  const { listOptions, onQueryChange } = props
  const agGrid = useRef()

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    agGrid.current = grid
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type !== 'gridSizeChanged' || !agGrid.current?.api) return
    agGrid.current.api.sizeColumnsToFit()
  }

  /**
   * Force AgGrid gridSizeChanged event
   */
  const forceGridResize = () => {
    if (!agGrid.current?.api) return
    agGrid.current.api.sizeColumnsToFit()
  }

  /**
   * Handle page size change
   * @param selected
   */
  const handlePageSizeChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, limit: selected, page: 1 }
    })
  }

  /**
   * Handle page change
   * @param selected
   */
  const handlePageChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, page: selected }
    })
  }

  return {
    agGrid: agGrid.current,
    handleGridReady,
    handleGridResize,
    handlePageSizeChange,
    handlePageChange,
    forceGridResize
  }
}
