import React, { useState, useEffect, useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

// actions
import { useActivityQuery, useActivityDeleteQuery, GET_ACTIVITY_EXPORT } from '../hook'
import { createToast, closeModal, openModal, modalType, statusType } from '../../../actions'
import { useExport } from '../../../services/csvExport/csvExport.service'

// components
import ActivityToolbar from './toolbar/toolbar.component'
import ActivityTable from './table/table.component'
import {
  formatActivities,
  formatDate,
  getActivityAttendee,
  getPageSizeFromStorage,
  isEntityType,
  isParent,
  unwindActivityLinks,
  CORPORATE_PARTICIPANT,
  ENTITY_TYPE,
  EXPORT_DATE_FORMAT,
  formatLocalizedDate
} from '../../../utils'
import { get, omitBy, capitalize, isEmpty, isNull } from 'lodash'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string,
  toolbarTheme: PropTypes.string,
  toolTheme: PropTypes.string,
  id: PropTypes.array,
  entityId: PropTypes.string,
  entityType: PropTypes.oneOf([CORPORATE_PARTICIPANT, CONTACT, FUND, INSTITUTION]),
  entity: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  deal: PropTypes.string,
  showChildren: PropTypes.bool,
  handlePopoverItemClick: PropTypes.func
}

const defaultProps = {
  showChildren: false
}

const PAGE_SIZE_ID = 'activity-grid'
const VIRTUAL = 'Virtual'

/**
 * Get Delete Confirmation Message based on number of selected activities
 * @param selectedIds
 * @param data
 * @returns {{title: string, message: string}}
 */
function getConfirmMessage (selectedIds, data) {
  const hasItinerary = selectedIds && (data || [])
    .find((record) => (selectedIds.indexOf(record && record.id) >= 0 && isParent(record.category)))

  const title = `Delete ${(selectedIds && selectedIds.length > 1) ? 'Activities' : 'Activity'}?`
  const message = `Are you sure you want to delete ${(selectedIds && selectedIds.length > 1) ? 'these activities' : 'this activity'}? 
                    ${hasItinerary ? 'Deleting them will also delete all Itinerary Activities.' : ''}`

  return { title, message }
}

/**
 * Get initial variable object from props
 * @param id
 * @param entityId
 */
function getInitialVariables(id, entityId) {
  return omitBy({
    id,
    entityId,
    search: null,
    filter: {
      category: null
    },
    listOptions: {
      sortBy: 'start.date_time',
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10,
      page: 1
    }
  }, isNull);
}

/**
 * Activity Grid Component
 * @param props
 */
function ActivityGrid (props) {
  const {
    dataIdPrefix, toolbarTheme, toolTheme, showChildren, id, entityType, entityId, entity, deal,
    handlePopoverMenuItemClick, openModal, closeModal, createToast, history, activityFormStatus
  } = props

  const isEntity = isEntityType(entityType)
  const initialVariables = useMemo(() => getInitialVariables(id, entityId), [id, entityId])
  const [variables, setVariables] = useState(initialVariables)
  const { filter, listOptions, ...args } = variables

  const [handleActivityDelete, { loading: deleteProgress }] = useActivityDeleteQuery()
  const { loading, data, refetch, client } = useActivityQuery({
    skip: deal && (!args.id || !args.id.length),
    variables: omitBy({ ...args, ...filter, ...listOptions }, isNull)
  })
  const { generateExport, exporting } = useExport({
    onError: () => openModal({
      type: modalType.ERROR_MODAL
    })
  })

  const activities = formatActivities(get(data, 'activity.items', []))
  const categoryCount = get(data, 'activity.categoryCount', {})
  const count = get(data, 'activity.count', 0)

  /**
   * Refetch grid data if new activity was created successfully
   * TODO: complete activity api migration and use mutation success instead
   */
  useEffect(() => {
    (activityFormStatus === statusType.SUCCESS) && refetch()
  }, [activityFormStatus, refetch])

  /**
   * Reset variables state if incoming props are different
   */
  useEffect(() => {
    setVariables(initialVariables)
  }, [initialVariables])

  /**
   * Handle query change
   * @param query
   */
  const handleQueryChange = (query = {}) => {
    const options = query.listOptions ? { ...query } : { ...query, listOptions: { ...listOptions, page: 1 } }
    setVariables({ ...variables, ...options })
  }

  // TODO: complete activity api migration and refactor create activity props
  const links = deal ? (entity || []).reduce((acc, entity) => {
    const item = getActivityAttendee(entity.entityType, entity)
    return (item && item.links) ? acc.concat(item.links) : acc
  }, []) : null

  /**
   * Handle activity create
   */
  const handleActivityCreate = () => {
    openModal({
      type: modalType.ACTIVITY_MODAL,
      props: {
        values: {
          ...entity ? isEntity
            ? getActivityAttendee(entityType, entity)
            : { links } || { participants: [entity] } : null,
          _deal: deal
        },
        isParentOptionHidden: (isEntity || deal),
        isFieldsHidden: '_deal',
        // TODO: complete activity api migration and combine with mutation success
        onSaveSuccess: (activityId) => deal && handleQueryChange({ id: (args.id || []).concat(activityId) })
      }
    })
  }

  /**
   * Handle bulk action
   * @param selectedIds - array of activity id(s)
   */
  const handleBulkAction = (selectedIds) => {
    if (!selectedIds || !selectedIds.length) {
      return
    }

    openModal({
      type: modalType.CONFIRM_MODAL,
      props: {
        content: getConfirmMessage(selectedIds, activities),
        onConfirm: () => handleBulkDeleteConfirm(selectedIds)
      }
    })
  }

  /**
   * Handle confirm message
   * @param selectedIds
   */
  function handleBulkDeleteConfirm (selectedIds) {
    closeModal({ type: modalType.CONFIRM_MODAL })

    handleActivityDelete({ variables: { id: selectedIds } })
      .then((response) => get(response, 'errors')
        ? handleFailure()
        : handleSuccess(get(response, 'data.activity.delete.count', null)))
  }

  /**
   * On Delete Success show toast message and refetch activities
   * @param deletedCount
   */
  function handleSuccess (deletedCount) {
    if (!deletedCount) {
      return
    }

    refetch(omitBy({ ...args, ...filter, ...listOptions, page: 1 }, isNull)).then(() => {
      handleQueryChange({ listOptions: { ...listOptions, page: 1 } })
      createToast({ text: `${(deletedCount > 1) ? 'Activities' : 'Activity'} deleted successfully.` })
    })
  }

  /**
   * On Action Completion Failure display an error
   */
  function handleFailure () {
    openModal({ type: modalType.ERROR_MODAL })
  }

  /**
   * Get entity field values for csv based on type
   * @param item
   * @param type
   */
  function getEntityValues (item, type) {
    if (!item || !type) {
      return {}
    }

    const entity = item.entityConnection || {}
    const job = get(entity, 'jobs[0]', {})
    const institutionAddress = get(entity, 'addressConnection.items[0]', {})

    switch (type) {
      case INSTITUTION:
        return {
          ...item,
          institutionName: entity.institutionName || item.entityName,
          institutionLocation: !isEmpty(institutionAddress) ? ([institutionAddress.city, institutionAddress.stateProvinceCode].join(', ')) : null,
          institutionStyle: entity.style,
          institutionType: entity.institutionType,
          institutionTurnover: capitalize(entity.turnover)
        }
      case CONTACT:
        return {
          ...item,
          fullName: entity.fullName || item.entityName,
          email: entity.email || job.email,
          phone: entity.directPhone || job.phone,
          institutionName: job.institutionName,
          institutionLocation: [job.city, job.stateProvince].join(', '),
          institutionType: job.institutionType
        }
      case FUND:
        return {
          ...item,
          fundName: entity.fundName || item.entityName,
          fundStyle: entity.style,
          fundType: entity.fundTypeDesc,
          fundTurnover: entity.turnover
        }
      default:
        return {}
    }
  }

  /**
   * Map activity to csv
   * @param item
   */
  const activityCSVMapper = (item) => {
    const activity = unwindActivityLinks(item)
    const { contact = [], institution = [], fund = [] } = activity
    const start = new Date(Number(get(activity, 'start.date')))
    const end = new Date(Number(get(activity, 'end.date')))
    const participants = (activity.participants || []).map((participant) => participant.name)

    return []
      .concat(contact, institution, fund)
      .map((link) => {
        const entity = getEntityValues(link, get(link, 'entityType', '').toLowerCase())
        const isVirtual = activity && activity.virtual

        return {
          'Start Date': formatLocalizedDate(formatDate(start, EXPORT_DATE_FORMAT), EXPORT_DATE_FORMAT),
          'End Date': formatLocalizedDate(formatDate(end, EXPORT_DATE_FORMAT), EXPORT_DATE_FORMAT),
          Type: activity.category,
          Title: activity.title,
          Itinerary: activity._itinerary,
          Location: isVirtual ? VIRTUAL : get(activity, 'address[0].location'),
          Venue: isVirtual ? '' : get(activity, 'address[0].venue'),
          'Contact Name': entity.fullName,
          'Contact Email': entity.email,
          'Contact Phone': entity.phone,
          'Institution Name': entity.institutionName,
          'Institution Location': entity.institutionLocation,
          'Institution Style': entity.institutionStyle,
          'Institution Type': entity.institutionType,
          'Institution Turnover': entity.institutionTurnover,
          'Fund Name': entity.fundName,
          'Fund Style': entity.fundStyle,
          'Fund Type': entity.fundType,
          'Fund Turnover': entity.fundTurnover,
          'Corporate Participants': participants,
          Description: activity.text,
          Tags: activity.tag
        }
      })
  }

  /**
   * Handle export
   */
  const handleExport = () => {
    const params = {
      client,
      variables: omitBy({ ...args, ...filter, page: 1, limit: 0 }, isNull),
      query: GET_ACTIVITY_EXPORT,
      dataPath: 'data.activity.items',
      fileName: 'entity_activity.csv',
      formatter: activityCSVMapper
    }

    generateExport(params)
  }

  return (
    <>
      <ActivityToolbar
        dataId={`${dataIdPrefix}Activity`}
        toolbarTheme={toolbarTheme}
        toolTheme={toolTheme}
        filter={filter}
        search={args.search}
        isEntity={showChildren}
        counts={categoryCount}
        noData={!activities || !activities.length}
        onQueryChange={handleQueryChange}
        onActivityCreate={handleActivityCreate}
        onExport={handleExport}
        exporting={exporting}
      />
      <ActivityTable
        dataId={`${dataIdPrefix}Activity`}
        pageSizeId={PAGE_SIZE_ID}
        loading={[loading, deleteProgress].some((item) => !!item)}
        data={activities}
        total={count}
        listOptions={listOptions}
        entityType={entityType}
        noFilter={[].concat(args.search, Object.values(filter)).every((value) => !value)}
        onQueryChange={handleQueryChange}
        onBulkAction={handleBulkAction}
        onActivityCreate={handleActivityCreate}
        onPopoverItemClick={handlePopoverMenuItemClick}
        history={history}
      />
    </>
  )
}

ActivityGrid.propTypes = propTypes
ActivityGrid.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  activityFormStatus: get(state, 'activity.activityForm.status')
})

const mapDispatchToProps = (dispatch) => ({
  createToast: bindActionCreators(createToast, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
  openModal: bindActionCreators(openModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityGrid))
