import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

export const GET_FUND_CURRENT_POSITION_REQUEST = 'GET_FUND_CURRENT_POSITION_REQUEST'
export const GET_FUND_CURRENT_POSITION_SUCCESS = 'GET_FUND_CURRENT_POSITION_SUCCESS'
export const GET_FUND_CURRENT_POSITION_FAILURE = 'GET_FUND_CURRENT_POSITION_FAILURE'

export const GET_FUND_HISTORICAL_POSITION_REQUEST = 'GET_FUND_HISTORICAL_POSITION_REQUEST'
export const GET_FUND_HISTORICAL_POSITION_SUCCESS = 'GET_FUND_HISTORICAL_POSITION_SUCCESS'
export const GET_FUND_HISTORICAL_POSITION_FAILURE = 'GET_FUND_HISTORICAL_POSITION_FAILURE'

export const RESET_FUND_CURRENT_POSITION = 'RESET_FUND_CURRENT_POSITION'

/**
 * Get Fund Current Position
 * @param factsetEntityId
 * @param securityId
 * @param options
 * @private
 */
const _fetchFundCurrentPosition = ({ factsetEntityId, securityId }, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_FUND_CURRENT_POSITION_REQUEST,
      GET_FUND_CURRENT_POSITION_SUCCESS,
      GET_FUND_CURRENT_POSITION_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/ownership/v2/fund/${factsetEntityId}/position`,
    payload: {
      securityId,
      factsetEntityId
    }
  }
})

/**
 * Get Fund Historical Position
 * @param params.entityId
 * @param params.securityId
 * @param options
 * @private
 */
const _fetchFundHistoricalPosition = (params = {}, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_FUND_HISTORICAL_POSITION_REQUEST,
      GET_FUND_HISTORICAL_POSITION_SUCCESS,
      GET_FUND_HISTORICAL_POSITION_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/ownership/v2/fund/${params.entityId}/related`,
    payload: params
  }
})

/**
 * Get Fund Current Position
 * @param params
 * @param options
 */
export const fetchFundCurrentPosition = (params, options) => (dispatch) => {
  if (!params || !params.factsetEntityId || !params.securityId) {
    return
  }
  return dispatch(_fetchFundCurrentPosition(params, options))
}

/**
 * Get Fund Historical Position
 * @param params
 * @param options
 */
export const fetchFundHistoricalPosition = (params, options) => (dispatch) => {
  if (!params || !params.entityId || !params.securityId) {
    return
  }
  return dispatch(_fetchFundHistoricalPosition(params, options))
}

/**
 * Clean Fund Current POSITION
 */
export const cleanFundCurrentPosition = () => (dispatch) => {
  return dispatch({ type: RESET_FUND_CURRENT_POSITION })
}
