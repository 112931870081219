import {
  GET_TARGETING_SEARCH_REQUEST,
  GET_TARGETING_SEARCH_SUCCESS,
  GET_TARGETING_SEARCH_FAILURE,
  CREATE_TARGET_SUCCESS,
  REMOVE_TARGET_SUCCESS,
  CREATE_TARGETS_REQUEST,
  CREATE_TARGETS_SUCCESS,
  CREATE_TARGETS_FAILURE,
  RESET_TARGETING_SEARCH,
  statusType
} from '../../actions'

const initial = {
  data: [],
  createTargetStatus: statusType.IDLE,
  status: statusType.IDLE
}

const search = (state = initial, action) => {
  switch (action.type) {
    case GET_TARGETING_SEARCH_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_TARGETING_SEARCH_SUCCESS:
      return {
        data: (action.payload || []).map((entity) => {
          entity.entityType = entity.hasOwnProperty('fund_name') ? 'fund' : 'institution'
          return entity
        }),
        total: action.meta.total,
        status: statusType.SUCCESS
      }

    case GET_TARGETING_SEARCH_FAILURE:
      return {
        error: action.error,
        status: statusType.ERROR
      }

    case CREATE_TARGET_SUCCESS:
      return {
        ...state,
        data: state.data.map((entityItem) => {
          if (action.payload.reference.item === entityItem._id) {
            return {
              ...entityItem,
              _target: action.payload._id
            }
          }
          return entityItem
        })
      }
    case REMOVE_TARGET_SUCCESS:
      return {
        ...state,
        data: state.data.map((entityItem) => {
          if (entityItem._target === action.payload._id) {
            return { ...entityItem, _target: undefined }
          }
          return entityItem
        })
      }

    case CREATE_TARGETS_REQUEST:
      return {
        ...state,
        createTargetStatus: statusType.IN_PROGRESS
      }

    case CREATE_TARGETS_SUCCESS:
      const targetSet = new Set(
        (action.payload || [])
          .filter((target) => target && target._id)
          .map((target) => target.reference.item)
      )

      return {
        ...state,
        data: state.data.map((target) => {
          const updatedTarget = { ...target }
          if (targetSet.has(updatedTarget._id)) {
            updatedTarget._target = updatedTarget._id
          }
          return updatedTarget
        }),
        createTargetStatus: statusType.SUCCESS
      }

    case CREATE_TARGETS_FAILURE:
      return {
        ...state,
        createTargetStatus: statusType.ERROR
      }

    case RESET_TARGETING_SEARCH:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default search
