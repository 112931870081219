import {
  GET_ENTITY_TOP_BUYS_REQUEST,
  GET_ENTITY_TOP_BUYS_SUCCESS,
  GET_ENTITY_TOP_BUYS_FAILURE,
  RESET_ENTITY_TOP_BUYS,
  statusType
} from '../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const topBuys = (state = initial, action) => {
  const { payload = {}, type } = action

  switch (type) {
    case GET_ENTITY_TOP_BUYS_REQUEST:
      return {
        ...initial,
        status: statusType.IN_PROGRESS
      }

    case GET_ENTITY_TOP_BUYS_SUCCESS:
      return {
        ...state,
        data: payload,
        status: statusType.SUCCESS
      }

    case GET_ENTITY_TOP_BUYS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case RESET_ENTITY_TOP_BUYS:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default topBuys
