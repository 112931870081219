import React, { PureComponent } from 'react'
import { statusType } from '../../../../../../actions'
import { formatDate } from '../../../../../../utils/date.util'
import { THEMES } from '../../../../../../utils/ui'
import { Message, RemovableList, Spinner } from '../../../../../../components'

import './saved.component.css'

/**
 * Error Message Modal
 * @param onClose
 * @returns {*}
 * @constructor
 */
const ErrorModal = ({ onClose }) => {
  return (
    <Message
      visible={true}
      type='error'
      title='Error'
      message='Oops, something went wrong while trying to send this request. Please try again or contact us if you see this message again.'
      onClose={onClose}
      buttons={[{
        ui: 'spice',
        label: 'close',
        onClick: onClose
      }]}
    />
  )
}

/**
 * Saved Search
 */
class SavedSearch extends PureComponent {

  componentDidUpdate (prevProps) {
    const { savedSearch, openModal } = this.props

    if (savedSearch.status !== prevProps.savedSearch.status && savedSearch.status === statusType.ERROR) {
      openModal({
        component: ErrorModal
      })
    }
  }

  /**
   * Handle deletion of saved targeting search
   * @param id
   */
  handleDelete = (id) => {
    const { deleteSavedSearch } = this.props

    if (id && deleteSavedSearch) {
      deleteSavedSearch(id)
    }
  }

  /**
   * Get formatted savedSearch items to accommodate RemovableList component
   * @param savedSearch
   */
  getFormattedSearchItems = (savedSearch) => {
    if (!savedSearch || !savedSearch.length) {
      return []
    }

    return savedSearch.map((searchItem) => {
      const {_id, create_date, name, filter} = searchItem
      return {
        value: _id,
        item: {
          create_date,
          name,
          filter
        },
        shouldWarnOnRemove: true
      }
    })
  }

  /**
   * Render custom RemovableList item
   * @param searchItem
   * @returns {*}
   */
  renderSearchItem = (searchItem) => {
    const { loadSavedSearch } = this.props
    const { id, create_date, name, filter } = searchItem

    const handleSearchItemClick = (event) => loadSavedSearch(event, { id, name, filter })

    return (
      <div className='targeting-page_saved-search-item' onClick={handleSearchItemClick}>
        <span className='targeting-page_saved-search-detail targeting-page_saved-search-detail--name'>{name}</span>
        <span className='targeting-page_saved-search-detail targeting-page_saved-search-detail--date'>
          {formatDate(create_date, 'MMMM D, YYYY', undefined, true)}
        </span>
      </div>
    )
  }

  /**
   * Render
   * @returns {*}
   */
  render () {
    const { savedSearch } = this.props
    const loading = savedSearch.status === statusType.IN_PROGRESS

    return (
      <section className='targeting-page_saved-search'>
        <RemovableList
          theme={THEMES.WHITE}
          size='default'
          items={this.getFormattedSearchItems(savedSearch.data)}
          customItemRender={this.renderSearchItem}
          removeMessageProps={{
            title: 'Delete Filter?',
            message: 'Are you sure you want to delete this filter?'
          }}
          onRemove={this.handleDelete}
        />
        {loading && <Spinner theme='rain' />}
      </section>
    )
  }
}

export default SavedSearch
