import {
  GET_ENTITY_OWNERSHIP_STOCK_REQUEST,
  GET_ENTITY_OWNERSHIP_STOCK_SUCCESS,
  GET_ENTITY_OWNERSHIP_STOCK_FAILURE,
  RESET_ENTITY_OWNERSHIP_CHART_DATA,
  statusType
} from '../../../actions'
import { chartStockLegacy } from '../../../utils'

const moment = require('moment-timezone')

/**
 * Entity Ownership Chart - Stock Reducer
 * @param state
 * @param action
 * @returns
 * {
 *    [securityId]: {
 *      data: [],
 *      status: 'SUCCESS'
 *    },
 *    [securityId]: {
 *      data: [],
 *      status: 'SUCCESS'
 *    }
 * }
 */
const stock = (state = {}, action) => {
  const { payload, request } = action

  switch (action.type) {
    case GET_ENTITY_OWNERSHIP_STOCK_REQUEST:
      return {
        ...state,
        [payload.securityId]: {
          status: statusType.IN_PROGRESS
        }
      }

    case GET_ENTITY_OWNERSHIP_STOCK_SUCCESS:
      const historicalStock = (payload.historical || []).filter((record) => record.Date && record.Last)
      return {
        ...state,
        [request.securityId]: {
          data: chartStockLegacy(historicalStock),
          status: statusType.SUCCESS
        }
      }

    case GET_ENTITY_OWNERSHIP_STOCK_FAILURE:
      return {
        ...state,
        [request.securityId]: {
          status: statusType.ERROR
        }
      }

    case RESET_ENTITY_OWNERSHIP_CHART_DATA:
      return {
        ...state,
        [payload.securityId]: undefined
      }

    default:
      return state
  }
}

export default stock
