import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { modalType } from '../../../actions/ui'

// components
import { Banner, AIScore, EntityScore } from '../../../components'
import { ENTITY_TYPE, QR } from '../../../utils'
import { capitalize } from 'lodash'

const { FUND } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string.isRequired,
  fund: PropTypes.object.isRequired,
  targetId: PropTypes.string,
  hasActiveDeal: PropTypes.bool,
  onDealSaveSuccess: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
}

const defaultProps = {
  dataIdPrefix: 'FundPage',
  fund: {},
  hasActiveDeal: false
}

/**
 * Banner Detail Component
 * @param institution
 * @param institutionId
 * @param type
 * @param style
 * @param turnover
 */
const Details = ({ institution, institutionId, type, style, turnover }) => {
  return (
    <>
      <Link className='banner_details--link' to={`/institution/${institutionId}`}>{institution}</Link>
      {institution && (type || style || turnover) && <br />}
      {type ? <span>{type}</span> : null}
      <span className={`${type ? 'divider' : ''}`}>Style: {style || '-'}</span>
      <span className='divider'>Turnover: {turnover ? capitalize(turnover) : '-'}</span>
    </>
  )
}

/**
 * Fund Banner Component
 * @param props
 */
function FundBanner (props) {
  const { dataIdPrefix, fund, openModal, targetId, hasActiveDeal, onDealSaveSuccess } = props
  const { id, institutionId, fundName, institutionName, fundTypeDesc, style, turnover, qualityRating } = fund

  const controls = [
    {
      type: 'score',
      ScoreComponent: <AIScore
        dataId={dataIdPrefix}
        entityId={id}
        entityType={FUND}
        style={style}
      />
    },
    {
      type: 'score',
      ScoreComponent: <EntityScore
        dataId={`${dataIdPrefix}QrScore`}
        data={{
          title: 'QR Score',
          score: qualityRating,
          message: QR
        }}
        openModal={openModal}
      />
    },
    {
      type: 'button',
      dataId: `${dataIdPrefix}BannerEntityUpdate`,
      label: 'Request Update',
      onClick: () => openModal({
        type: modalType.ENTITY_UPDATE_REQUEST_MODAL,
        props: {
          values: {
            entityId: id,
            entityType: FUND,
            name: fundName
          }
        }
      })
    },
    {
      dataId: `${dataIdPrefix}BannerUtility`,
      type: 'utility',
      entity: { ...fund, _target: targetId },
      entityType: FUND,
      items: [
        { action: 'CREATE_ACTIVITY' },
        {
          action: 'TARGET',
          hide: hasActiveDeal
        },
        {
          action: 'CREATE_DEAL',
          onSaveSuccess: onDealSaveSuccess
        },
        { action: 'DOWNLOAD_TEARSHEET' },
        { action: 'BRIEFING_BOOK' }
      ]
    }
  ]

  return (
    <Banner
      size='medium'
      icon='q4i-fund-2pt'
      title={fundName}
      details={
        <Details
          institution={institutionName}
          institutionId={institutionId}
          type={fundTypeDesc}
          style={style}
          turnover={turnover}
        />}
      controls={controls}
    />
  )
}

FundBanner.propTypes = propTypes
FundBanner.defaultProps = defaultProps

export default memo(FundBanner)
