import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// actions
import { useActivityQuery } from '../../../hook'

// components
import ActivityComponent from './activity.component'

// utils
import { convertStringToBoolean } from '../../../../../utils'
import { get } from 'lodash'

const propTypes = {
  entityId: PropTypes.string.isRequired,
  limit: PropTypes.string,
  notes: PropTypes.string
}

const defaultProps = {
  limit: 'fiveMostRecent',
  notes: 'false'
}

/**
 * Get activity range
 * @param range
 */
const getActivityRange = (range = 'fiveMostRecent') => {
  let months = null

  const endDate = moment().tz('America/New_York').endOf('day').format()
  const limit = 100

  switch (range) {
    case 'threeMonths':
      months = 3
      break
    case 'sixMonths':
      months = 6
      break
    case 'oneYear':
      months = 12
      break
    default:
      break
  }

  if (months) {
    return {
      startDate: moment().tz('America/New_York').startOf('day').subtract(months, 'months').format(),
      endDate,
      limit
    }
  }

  switch (range) {
    case 'allLastActivities':
      return { endDate, limit }
    case 'lastActivity':
      return { endDate, limit: 1 }
    case 'fiveMostRecent':
    default:
      return { endDate, limit: 5 }
  }
}

/**
 * Activity Card
 * @param props
 */
function Activity (props) {
  const { entityId, limit, notes } = props

  const range = getActivityRange(limit)
  const { data } = useActivityQuery({ variables: { entityId, ...range } })
  const activity = get(data, 'activity.items', [])

  if (!activity.length) {
    return null
  }

  return (
    <div className='tearsheet_section-content'>
      {(activity || []).map((activity, index) => activity.id
        ? <ActivityComponent
          key={activity.id}
          activity={activity}
          isNotes={convertStringToBoolean(notes)}
          isSection={index === 0}
        />
        : null)}
    </div>
  )
}

Activity.propTypes = propTypes
Activity.defaultProps = defaultProps

export default Activity
