import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import './dropdownOptions.component.css';
import SelectList from '../../selectList/selectList.component';

class DropdownOptions extends PureComponent {

    onSelect = (value) => {
        const {onSelect, handleSelect} = this.props;
        onSelect && onSelect(value);
        handleSelect && handleSelect(value);
    };

    render() {
        const {
            children,
            height,
            optionHeight,
            theme,
            scrollToIndex
        } = this.props;

        return (
            <SelectList
                onSelect={this.onSelect}
                height={height}
                listItemHeight={optionHeight}
                scrollToIndex={scrollToIndex}
                theme={theme}>
                {children}
            </SelectList>
        );
    }
}

DropdownOptions.propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fillerHeight: PropTypes.number,
    optionHeight: PropTypes.number,
    dropdownRef: PropTypes.any,
    theme: PropTypes.string,
    scrollToIndex: PropTypes.number
};

DropdownOptions.defaultProps = {
    height: 200,
    width: '100%',
    optionHeight: 40,
    scrollToIndex: -1
};

export default DropdownOptions;