import * as moment from 'moment'

export const PEER_ANALYSIS_OPTIONS = [
  { label: 'Position', value: 'current', isChange: false },
  { label: 'Position Change', value: 'change', isChange: true },
  { label: 'Market Value', value: 'market_value', isChange: false },
  { label: 'Market Value Change', value: 'market_value_change', isChange: true },
  { label: '%OS (Owned Shares)', value: 'percent_tso', isChange: false },
  { label: '%OS Change', value: 'percent_tso_change', isChange: true },
  { label: '%PORT (Portfolio)', value: 'percent_portfolio', isChange: false },
  { label: '%PORT Change', value: 'percent_portfolio_change', isChange: true }
]

export const getDefaultPeerAnalysisData = (data) => {
  const peerAnalysisData = data.filter((item, index, array) => index === 0  || item.fsym_id !== array[0].fsym_id).map((item) => {
    let i = 0
    let holdingReport = {}
    let temp_key = ''
    const holding = item.holdings
    for(i ; i < holding.length; i++) {
      const date = moment.utc(holding[i].report_date).format('MM/DD/YY')
      if(holding[i].holdings_type === 'recent') {
        holdingReport.latest = holding[i].holding
        holdingReport.latest_date = date
      }else {
        temp_key = 'qrt_date_' + i
        holdingReport[temp_key] = holding[i].holding
      }
    }
    return {
      securityId:item.securityId,
      company_name: item.company_name,
      ...holdingReport,
    }
  })
  return data && peerAnalysisData
}
