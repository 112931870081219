import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const GET_CORPORATE_PARTICIPANT_REQUEST = 'GET_CORPORATE_PARTICIPANT_REQUEST'
export const GET_CORPORATE_PARTICIPANT_SUCCESS = 'GET_CORPORATE_PARTICIPANT_SUCCESS'
export const GET_CORPORATE_PARTICIPANT_FAILURE = 'GET_CORPORATE_PARTICIPANT_FAILURE'

export const GET_CORPORATE_PARTICIPANTS_REQUEST = 'GET_CORPORATE_PARTICIPANTS_REQUEST'
export const GET_CORPORATE_PARTICIPANTS_SUCCESS = 'GET_CORPORATE_PARTICIPANTS_SUCCESS'
export const GET_CORPORATE_PARTICIPANTS_FAILURE = 'GET_CORPORATE_PARTICIPANTS_FAILURE'

export const REMOVE_CORPORATE_PARTICIPANT_REQUEST = 'REMOVE_CORPORATE_PARTICIPANT_REQUEST'
export const REMOVE_CORPORATE_PARTICIPANT_SUCCESS = 'REMOVE_CORPORATE_PARTICIPANT_SUCCESS'
export const REMOVE_CORPORATE_PARTICIPANT_FAILURE = 'REMOVE_CORPORATE_PARTICIPANT_FAILURE'

export const SEARCH_PARTICIPANTS_REQUEST = 'SEARCH_PARTICIPANTS_REQUEST'
export const SEARCH_PARTICIPANTS_SUCCESS = 'SEARCH_PARTICIPANTS_SUCCESS'
export const SEARCH_PARTICIPANTS_FAILURE = 'SEARCH_PARTICIPANTS_FAILURE'

/**
 * Get Corporate Participant
 * @param id
 * @param options.types - action types
 * @private
 */
const _fetchCorporateParticipant = (id, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_CORPORATE_PARTICIPANT_REQUEST,
      GET_CORPORATE_PARTICIPANT_SUCCESS,
      GET_CORPORATE_PARTICIPANT_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/corporateparticipant/${id}`
  }
})

/**
 * Get Corporate Participants
 * @private
 */
const _fetchCorporateParticipants = () => ({
  [CALL_API]: {
    types: [
      GET_CORPORATE_PARTICIPANTS_REQUEST,
      GET_CORPORATE_PARTICIPANTS_SUCCESS,
      GET_CORPORATE_PARTICIPANTS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/corporateparticipant'
  }
})

/**
 * Remove corporate participant by id
 * suspends participant record, and doesn't remove it from collection
 * @param id
 * @param options
 * @returns {{}}
 * @private
 */
const _removeCorporateParticipant = (id, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      REMOVE_CORPORATE_PARTICIPANT_REQUEST,
      REMOVE_CORPORATE_PARTICIPANT_SUCCESS,
      REMOVE_CORPORATE_PARTICIPANT_FAILURE
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: `/corporateparticipant/${id}`
  }
})

/**
 * Search for Corporate Participants
 * @param query String
 * @param options
 * @param options.types - action types
 */
const _searchParticipants = (query, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      SEARCH_PARTICIPANTS_REQUEST,
      SEARCH_PARTICIPANTS_SUCCESS,
      SEARCH_PARTICIPANTS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/corporateparticipant/search',
    payload: { query, limit: options.limit || 10 }
  }
})

/**
 * Dispatch GET_CORPORATE_PARTICIPANT_REQUEST
 * @param id
 * @param options
 * @param options.types - action types
 */
export const fetchCorporateParticipant = (id, options) => (dispatch) => {
  return dispatch(_fetchCorporateParticipant(id, options))
}

/**
 * Dispatch GET_CORPORATE_PARTICIPANTS_REQUEST
 */
export const fetchCorporateParticipants = () => (dispatch) => {
  return dispatch(_fetchCorporateParticipants())
}

/**
 * Dispatch REMOVE_CORPORATE_PARTICIPANT_REQUEST
 * @param id
 * @param options
 * @returns {function(*): *}
 */
export const removeCorporateParticipant = (id, options) => (dispatch) => {
  return dispatch(_removeCorporateParticipant(id, options))
}

/**
 * Dispatch SEARCH_PARTICIPANTS_REQUEST
 * @param  query
 * @param  options
 * @param  options.types - action types
 */
export const searchParticipants = (query, options) => (dispatch) => {
  return dispatch(_searchParticipants(query, options))
}