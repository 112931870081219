import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST'
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS'
export const FETCH_PROFILE_ERROR = 'FETCH_PROFILE_ERROR'

export const FETCHING = 'FETCHING'
export const IDLE = 'IDLE'
export const FETCHED = 'FETCHED'
export const FAILED = 'FAILED'

const resources = {
  profile: '/profile'
}

const _fetchProfile = (url, orgId) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_PROFILE_REQUEST,
      FETCH_PROFILE_SUCCESS,
      FETCH_PROFILE_ERROR
    ],
    method: METHOD_TYPE.GET,
    orgIdVerifier: orgId
  }
})

export const fetchProfile = () => (dispatch, getState) => {
  const state = getState()

  if (state.shared.profile.status !== IDLE) {
    return Promise.resolve(state.shared.profile)
  }
  const orgId = state?.profile?.data?._organization?._id
  return dispatch(_fetchProfile(resources.profile, orgId))
}
