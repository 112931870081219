import gql from 'graphql-tag'
import { get } from 'lodash'

import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { fetchProfile } from '../../shared/index'

import client from '../../../middleware/graphql.middleware'

export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST'
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS'
export const FETCH_EVENTS_ERROR = 'FETCH_EVENTS_ERROR'
export const FETCH_EVENTS_REQUEST_GQL = 'GET_EVENTS_REQUEST_GQL'

export const FETCHING = 'FETCHING'
export const IDLE = 'IDLE'
export const FETCHED = 'FETCHED'
export const FAILED = 'FAILED'

// ------REST implementation ----- //
const resources = {
  events: '/event'
}

const _fetchEvents = (params) => ({
  [CALL_API]: {
    endpoint: resources.events,
    types: [
      FETCH_EVENTS_REQUEST,
      FETCH_EVENTS_SUCCESS,
      FETCH_EVENTS_ERROR
    ],
    method: METHOD_TYPE.GET,
    payload: params
  }
})

export const loadEvents = (params) => async (dispatch) => {
  return dispatch(fetchProfile()).then(() => {
    return dispatch(_fetchEvents(params))
  })
}

// ------GRAPHQL implementation ----- //
/**
 * Dispatch GET_EVENTS_REQUEST_GQL
 *
 */
export const loadEventsGQL = (variables) => (dispatch) => {
  dispatch({ type: FETCH_EVENTS_REQUEST_GQL })
  return client
    .query({
      query: GET_EVENT_TRANSCRIPT,
      variables,
      fetchPolicy: 'no-cache'
    })
    .then((data) => {
      const events = get(data, 'data.event.items')
      dispatch({ payload: events, type: FETCH_EVENTS_SUCCESS })
    })
    .catch((error) => {
      dispatch({ error, type: FETCH_EVENTS_ERROR })
    })
}

// GraphQL queries
const GET_EVENT_TRANSCRIPT = gql`
  query Event(
    $startDate: String!
    $endDate: String!
    $timezoneOffset: Int
    $page: Int
    $limit: Int
    $tickerId: [String]
    $transcript: Boolean
    $peers: Boolean
    $eventType: [String]
  ) {
    event(
      page: $page,
      limit: $limit,
      filter: {
        startDate: $startDate,
        endDate: $endDate,
        tickerId: $tickerId,
        timezoneOffset: $timezoneOffset,
        transcript: $transcript,
        peers: $peers,
        eventType: $eventType,
      }
    
    ) {
      items { 
        id
        eventTitle
        eventDate
        eventConferenceEndDate
        eventTypeName
        eventMarketTime
        companyId
        companyName
        companyRoleTypeName
        tickerId
        symbol
        exchange
        phoneLiveUS
        passcodeLiveUS
        phoneReplayUS
        phoneLiveInternational
        passcodeLiveInternational
        phoneReplayInternational
        transcriptUrl
        webcastLiveUrl
        webcastReplayUrl
      }
    }
  }`
