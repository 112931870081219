import React, { PureComponent } from 'react'
import { get, omitBy, isNull } from 'lodash'

import { statusType } from '../../../../actions'

import ContactFormContainer from '../../../../components/contact/form/contactForm.container'
import { Pagination } from '../../../../components'

import ActivityAttendeesRequestProfile from './activityAttendeesRequestProfile/activityAttendeesRequestProfile.component'
import EntityLink from './entityLink.class'

import RadioButton from '../../../shared/radioButton/radioButton.component'
import { RangeTab, Checkbox, ComboBox, ThreeStateCheckbox } from '../../../shared'

import {
  ENTITY_TYPE_TO_SWIFTYPE_MAP,
  ENTITY_TYPE,
  ENTITY_CONTACT,
  ENTITY_FUND,
  ENTITY_INSTITUTION
} from '../../../../utils/entity'
import { debounce, getActiveTicker, hasSavedAndAddAnotherSuccess, isProperJob, isQ4Id } from '../../../../utils'

import { client } from '../../../../middleware/graphql.middleware'
import { GET_ADVANCED_SEARCH_RESULTS } from '../../hook'
import { gqlToRestSearchMapper } from './searchResultsMapper'

import './activityAttendees.component.css'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../../../services/launchDarkly.service'

class ActivityAttendees extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      attendeeType: ENTITY_INSTITUTION.toLowerCase(),
      isSearchRelative: false,
      showRequestProfileModal: false,
      paginationPageSize: 10,
      paginationCurrentPage: 0,
      query: null,
      isLoading: false,
      gqlSearchResults: []
    }
  }

  componentDidUpdate (prevProps) {
    const { searchEntityState, activityFormState } = this.props

    if (searchEntityState.status !== prevProps.searchEntityState.status) {
      this.setState({
        isLoading: searchEntityState.status === statusType.IN_PROGRESS
      })
    }

    if (hasSavedAndAddAnotherSuccess(prevProps, activityFormState)) {
      this.handleComponentReset()
    }
  }

  handleComponentReset = () => {
    this.clearSearchInput()
    this.setState({
      attendeeType: ENTITY_INSTITUTION.toLowerCase(),
      isSearchRelative: false,
      query: null
    })
  };

  clearSearchInput = () => {
    const { onSearchEntityClear } = this.props

    this.setState({
      gqlSearchResults: []
    })

    onSearchEntityClear()
  };

  getTicker = () => getActiveTicker(this.props.profile)

  onTypeSelect = (e, value) => {
    this.clearSearchInput()
    this.setState({
      attendeeType: value
    })
  };

  handleGqlSearch = debounce((queryParams) => {
    const filter = omitBy({
      relevantReferences: queryParams.entity ? JSON.parse(queryParams.entity).map(_entity => _entity.institutionId) : null
    }, isNull)

    const variables = {
      query: queryParams.query,
      entity: [ENTITY_TYPE_TO_SWIFTYPE_MAP[queryParams.type]],
      page: 1,
      limit: 10,
      filter,
      isContactSearch: queryParams.type === ENTITY_TYPE.CONTACT,
      isFundSearch: queryParams.type === ENTITY_TYPE.FUND,
      isInstitutionSearch: queryParams.type === ENTITY_TYPE.INSTITUTION,
      tickerId: this.getTicker().q4_ticker_id,
      securityId: this.getTicker().q4_entity_id,
      useElasticSearch: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)
    }

    client.query({
      query: GET_ADVANCED_SEARCH_RESULTS,
      variables
    }).then((response) => {
      const searchData = get(response, 'data.advancedSearch.items', [])
      const mappedSearchData = searchData.map(gqlToRestSearchMapper)

      this.setState({
        gqlSearchResults: mappedSearchData,
        isLoading: false
      })
    })
  })

  onInputChange = (query) => {
    const { links } = this.props
    const { attendeeType, isSearchRelative } = this.state
    this.setState({
      isLoading: !!(query && query.length),
      query
    })

    if (!query.length) {
      this.clearSearchInput()
      return
    }

    const queryParams = {
      query,
      type: attendeeType
    }

    if (isSearchRelative && attendeeType !== ENTITY_INSTITUTION.toLowerCase() && links.length) {
      queryParams.entity = JSON.stringify((links || []).map((link) => {
        const entityLink = new EntityLink(link)
        const entityType = entityLink.getEntityType() && entityLink.getEntityType().toLowerCase()

        let institutionId
        switch (entityType) {
          case ENTITY_TYPE.INSTITUTION:
            institutionId = entityLink.q4_entity_id || null
            break
          case ENTITY_TYPE.FUND:
            institutionId = entityLink.institution_id || null
            break
          default:
            institutionId = null
        }

        return {
          entityType,
          entityId: entityLink.q4_entity_id || entityLink._id,
          institutionId
        }
      }))
    }

    this.handleGqlSearch(queryParams)
  };

  getTabs = () => {
    const { attendeeType } = this.state

    return [
      {
        active: attendeeType === ENTITY_INSTITUTION.toLowerCase(),
        value: ENTITY_INSTITUTION.toLowerCase(),
        label: 'Institutions',
        onClick: this.onTypeSelect
      },
      {
        active: attendeeType === ENTITY_FUND.toLowerCase(),
        value: ENTITY_FUND.toLowerCase(),
        label: 'Funds',
        onClick: this.onTypeSelect
      },
      {
        active: attendeeType === ENTITY_CONTACT.toLowerCase(),
        value: ENTITY_CONTACT.toLowerCase(),
        label: 'Contacts',
        onClick: this.onTypeSelect
      }
    ]
  };

  getSuggestedItemTemplate (entityLink) {
    let contactJob

    if (entityLink.getEntityType() === ENTITY_CONTACT) {
      contactJob = get(entityLink.item, 'jobs[0].title')
    }

    return (
      <div className='activity-entity-link-suggested'>
        <div className='activity-entity-link-suggested_title'>
          {entityLink.getTitle()}{contactJob ? ' - ' + contactJob : ''}
          {entityLink.isCustomContact() && <i className={`activity-entity-link-suggested_icon ${entityLink.getIconClass(4)}`}></i>}
        </div>
        <div className='activity-entity-link-suggested_subtitle'>{entityLink.getSubtitle()}</div>
      </div>
    )
  }

  getSelectedItemTemplate = (entityLink) => {
    let savedContact = false
    if (entityLink.getEntityType() === ENTITY_CONTACT) {
      const contactFavorites = entityLink.item.favorites
      savedContact = contactFavorites?.items?.length >= 1 || false
    }

    return (
      <div className='activity-entity-link'>
        <div className='activity-entity-link_icon'>
          <i className={`activity-entity-link_icon--${entityLink.getEntityType().toLowerCase()} ${entityLink.getIconClass()}`} />
        </div>
        <div className='activity-entity-link_title'>{entityLink.getTitle()}</div>
        {
          savedContact && <div className='activity-entity-link_favorite'>
            <i className={`q4i-contact-list-4pt`} />
          </div>
        }
      </div>
    )
  };

  getSelectedItemTemplateContent = (entityLink) => {
    const contactJobButtons = this.renderContactJobs(entityLink)
    return (
      entityLink.getEntityType() === ENTITY_CONTACT && !!contactJobButtons.length && (
        <div className='activity-entity-link_contact-job-list'>{contactJobButtons}</div>
      )
    )
  };

  onSuggestedItemSelect = (entityLink) => {
    this.clearSearchInput()
    const { setFieldValue, links, addressBookLinks } = this.props
    const linkExists = !!links.find((linkItem) => linkItem._id === entityLink._id)
    const payloads = []

    this.setState({
      query: null
    })

    if (linkExists) {
      return
    }

    if (entityLink.getEntityType() === ENTITY_CONTACT) {
      const job = get(entityLink, 'item.jobs[0]')
      const jobId = job && (job.q4_entity_id || job.entityId || job._id)

      if (jobId) {
        entityLink.setInstitutionId(jobId)
      }

      payloads.push(entityLink.getPayload())
      const institutionExists = !!(links || [])
        .find((link) => (get(link, 'item.q4_entity_id') || link._id) === jobId)

      if (jobId && !institutionExists) {
        const institutionLink = new EntityLink(Object.assign({}, job, {
          entity_type: ENTITY_INSTITUTION
        }))
        payloads.push(institutionLink.getPayload())
      }

      // If "Add All" is checked add, set new contact to to-be-saved list
      const contactLinks = this._getContactList(links)
      if (contactLinks.length === addressBookLinks.length && addressBookLinks.length > 0) {
        setFieldValue('addressBookLinks', [].concat(addressBookLinks, [entityLink._id]))
      }
    } else {
      payloads.push(entityLink.getPayload())
    }

    setFieldValue('links', payloads.concat(links))
  };

  onDropdownCollapse = () => {
    this.clearSearchInput()
  };

  onItemRemove = (item) => {
    const { setFieldValue, links, addressBookLinks } = this.props
    const newLinks = links?.filter((link) => link?.item?._id !== item?._id)
    const newContactsList = addressBookLinks.filter(contactId => contactId !== item?._id)
    const slicedLinks = this.getPaginated(newLinks)
    const { paginationCurrentPage } = this.state
    setFieldValue('links', newLinks)
    setFieldValue('addressBookLinks', newContactsList)

    if (!slicedLinks.length && paginationCurrentPage > 0) {
      this.setState({
        paginationCurrentPage: paginationCurrentPage - 1
      })
    }
  };

  onRelativeSearchChange = () => {
    const { isSearchRelative } = this.state
    this.setState({
      isSearchRelative: !isSearchRelative
    })
  };

  /**
   * Handle show/hide for Request Profile Modal
   * @param show - false to hide modal
   */
  showRequestProfileModal = (show = true) => {
    this.setState({ showRequestProfileModal: show })
  };

  /**
   * Handle show/hide for Create Contact modal
   */
  onCreateContact = () => {
    this.props.openCloseContactModal('contact')
  };

  /**
   * @param contact Object
   * Creates entityLink and passes newly created contact to onSuggestedItemSelect
   */
  getCreatedContact = (contact) => {
    const entity = new EntityLink({
      item: contact,
      entity_type: 'contact',
      _id: contact._id
    })
    this.onSuggestedItemSelect(entity)
  };

  getInListActions = () => {
    const { attendeeType } = this.state

    switch (attendeeType) {
      case ENTITY_INSTITUTION.toLowerCase():
      case ENTITY_FUND.toLowerCase():
        return [{
          label: 'Request Profile',
          icon: 'q4i-request-2pt',
          onSelect: this.showRequestProfileModal
        }]
      case ENTITY_CONTACT.toLowerCase():
        return [{
          label: 'Create Contact',
          icon: 'q4i-custom-2pt',
          onSelect: this.onCreateContact
        }]
      default:
        return []
    }
  };

  onContactJobSelect = (contactEntityLink, jobId) => {
    const { links, setFieldValue } = this.props
    const contactLinkIndex = links.findIndex((link) => link._id === contactEntityLink._id)
    const linksClone = [].concat(links)
    const payloads = []

    contactEntityLink.setInstitutionId(jobId)
    payloads.push(contactEntityLink.getPayload())

    if (jobId) {
      const job = (get(contactEntityLink, 'item.jobs') || [])
        .find((jobItem) => (jobItem.entityId || jobItem.q4_entity_id || jobItem._id) === jobId)

      const institutionEntityLink = new EntityLink(Object.assign({}, job, {
        entity_type: ENTITY_INSTITUTION
      }))

      const institutionLinkExists = !!links.find((link) => {
        const linkId = get(link, 'entityId') || get(link, 'q4_entity_id')
        const institutionLinkId =
          get(institutionEntityLink, 'item.entityId') ||
          get(institutionEntityLink, 'item.q4_entity_id')

        return linkId && institutionLinkId ? (linkId === institutionLinkId) : (link._id === institutionEntityLink._id)
      })

      if (!institutionLinkExists) {
        payloads.push(institutionEntityLink.getPayload())
      }
    }

    linksClone.splice(contactLinkIndex, 1, ...payloads)
    setFieldValue('links', linksClone)
  };

  renderContactJobs = (contactEntityLink) => {
    const contact = contactEntityLink.item
    const jobs = contact.jobs || []

    const buttons = jobs
      .filter((job) => isProperJob(job))
      .map((job) => {
        const contactJobId = contactEntityLink.getInstitutionId()
        const jobId = job.entityId || job.q4_entity_id || job._id
        const selected = !contactJobId
          ? false
          : isQ4Id(contactJobId)
            ? contactJobId === (job.entityId || job.q4_entity_id)
            : contactJobId === job._id

        return (
          <RadioButton
            key={jobId}
            id={`${contact._id}:${jobId}`}
            value={jobId}
            label={`${job.title ? job.title + ', ' : ''}${(job.institution_name || job.institutionName)}`}
            groupName={'contact-job-' + contact._id}
            isSelected={selected}
            onSelect={() => this.onContactJobSelect(contactEntityLink, jobId)}
          />
        )
      })

    if (buttons.length) {
      buttons.push(
        <RadioButton
          key={contact._id}
          id={contact._id}
          label='Not Applicable'
          value=''
          groupName={'contact-job-' + contact._id}
          isSelected={!contactEntityLink.getInstitutionId()}
          onSelect={() => this.onContactJobSelect(contactEntityLink, null)}
        />
      )
    }

    return buttons
  };

  onPaginatorPageUpdate = (e) => {
    this.setState({
      paginationCurrentPage: e.selected
    })
  };

  getPaginated (entityLinks) {
    const { paginationPageSize, paginationCurrentPage } = this.state
    const start = paginationCurrentPage * paginationPageSize
    const end = start + paginationPageSize
    return entityLinks.slice(start, end)
  }

  /**
   * Helper method to get only contact entities from links store
   * @param {EntityLink[]} links 
   * @returns  {string[]}
   */
  _getContactList = (links) => {
    return (links || [])
      .map((link) => new EntityLink(link))
      .filter((entityLink) => {
        return (entityLink.getEntityType() === ENTITY_CONTACT)
      }).map(contact => contact._id)
  }

  onAddAllContactsChange (currentState) {
    const { links, setFieldValue } = this.props

    if (currentState === true) {
      setFieldValue('addressBookLinks', [])
    } else if (currentState === false) {
      const contactLinks = this._getContactList(links)
      setFieldValue('addressBookLinks', contactLinks)
    } else if (currentState === null) {
      setFieldValue('addressBookLinks', [])
    }
  }

  renderAddToContactsCheckbox () {
    const { addressBookLinks, links } = this.props
    let checkBoxState = false

    const contactLinks = this._getContactList(links)
    if (contactLinks.length > addressBookLinks.length && addressBookLinks.length > 0) {
      checkBoxState = null
    }
    if (contactLinks.length === addressBookLinks.length && addressBookLinks.length > 0) {
      checkBoxState = true
    }

    const onChange = (_e, _value) => {
      this.onAddAllContactsChange(checkBoxState)
    }

    return (
      <div className='activity-form_activity-attendees all-contacts-checkbox-wrapper'>
        <ThreeStateCheckbox
          id='allContacts'
          dataId={'activity-form-all-contacts'}
          isChecked={checkBoxState === true}
          isIndeterminate={checkBoxState === null}
          onChange={onChange}
          isDisabled={contactLinks.length === 0}
          label='Save all contacts to Address Book'
        />
      </div>
    )
  }

  renderSingleContactCheckbox (index, item) {
    const showCheckBox = item.entity_type == ENTITY_CONTACT
    if (!showCheckBox) return <></>

    const { addressBookLinks, setFieldValue } = this.props
    let isChecked = addressBookLinks.includes(item._id)
    const onChange = (_e, _value) => {
      if (isChecked) {
        const tempList = [...addressBookLinks]
        tempList.splice(addressBookLinks.indexOf(item._id), 1)
        setFieldValue('addressBookLinks', tempList)
      }
      else {
        setFieldValue('addressBookLinks', [].concat(addressBookLinks, [item._id]))
      }
    }

    return (
      <Checkbox
        id={`addressBook-${index}`}
        dataId={`activity-form-addressBook-${index}`}
        isChecked={isChecked}
        onChange={onChange}
        isBoxOnly={true}
      />
    )
  }
  render () {
    const { links, openCloseContactModal, modalType, isContactModalVisible } = this.props

    const { gqlSearchResults, isSearchRelative, showRequestProfileModal, paginationPageSize, attendeeType, query, isLoading } = this.state
    const entityLinks = (links || [])
      .map((link) => new EntityLink(link))
      .filter((entityLink) => {
        return (entityLink.getEntityType() === ENTITY_INSTITUTION ? isProperJob(entityLink.item) : true)
      })
    const linkIds = (entityLinks || []).map((entityLink) => entityLink._id)
    const inListActions = this.getInListActions()
    let suggestedEntityLinks = []

    if (query?.length) {
      suggestedEntityLinks = (gqlSearchResults || [])
        .map((result) => result ? new EntityLink(result) : null)
        .filter((entityLink) => {
          return entityLink && !linkIds.includes(entityLink._id)
        })
    }

    return (
      <React.Fragment>
        <div className='activity-form_activity-attendees activity-form--section'>
          <div className='field field--text field--full'>
            <label className='field_label'>
              Attendees
            </label>
            <div className='attendees-options'>
              <div className='attendees-options_select-type'>
                <RangeTab buttons={this.getTabs()} theme='light' />
              </div>
              <div className='attendees-options_relative-search'>
                {attendeeType !== ENTITY_INSTITUTION.toLowerCase() && (
                  <Checkbox
                    id='relative'
                    isChecked={isSearchRelative}
                    onChange={this.onRelativeSearchChange}
                    label='Relevant profiles'
                  />
                )}
              </div>
            </div>

            <ComboBox
              inputId='activity-form-attendees-input'
              value={query}
              loading={isLoading}
              dropdownHeight={243}
              suggestedValues={suggestedEntityLinks}
              suggestedInListActions={inListActions}
              suggestedItemTemplate={this.getSuggestedItemTemplate}
              suggestedItemHeight={54}
              onSuggestedItemSelect={this.onSuggestedItemSelect}
              onDropdownCollapse={this.onDropdownCollapse}
              selectedValues={this.getPaginated(entityLinks)}
              selectedItemTemplate={this.getSelectedItemTemplate}
              selectedItemTemplateContent={this.getSelectedItemTemplateContent}
              onInputChange={this.onInputChange}
              onRemove={this.onItemRemove}
              placeholder='Keyword'
              listKey='_id'
              infoComponent={this.renderAddToContactsCheckbox.bind(this)}
              optionSubComponents={this.renderSingleContactCheckbox.bind(this)}
            />

            {(entityLinks.length > paginationPageSize) && (
              <div className='attendees-pagination'>
                <Pagination
                  startFromZero={true}
                  total={entityLinks.length}
                  showPageSizeSelection={false}
                  initialPageSize={paginationPageSize}
                  onPageChange={this.onPaginatorPageUpdate}
                />
              </div>
            )}
          </div>
        </div>

        {showRequestProfileModal && (
          <ActivityAttendeesRequestProfile onClose={() => this.showRequestProfileModal(false)} />
        )}

        {isContactModalVisible && modalType === 'contact' && (
          <ContactFormContainer
            isTypeSelectable={false}
            type='contact'
            onClose={() => openCloseContactModal(null)}
            getCreatedContact={this.getCreatedContact}
            onSaveSuccess={(contact) => this.getCreatedContact(contact)}
          />
        )}
      </React.Fragment>
    )
  }
}

export default ActivityAttendees
