import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { getHistQtrFields, ENTITY_TYPE } from '../../../utils'
import { get } from 'lodash'

const GET_HISTORICAL_STOCK = gql`
  query Stock(
    $tickerId: [String]
    $startDate: String!
    $endDate: String!
  ) {
    stock(
      tickerId: $tickerId
      startDate: $startDate
      endDate: $endDate
    ) {
      items {
        date
        last
        currency
      }
    }
  }`

export const GET_FUND_HISTORICAL_POSITION = (quarters) => {
  const quarterlyFields = getHistQtrFields(quarters)

  return gql`
    query FundHoldingHistoricalPosition(
      $entityId: [String]!
      $tickerId: [String]
      $quarters: Int!
      $source: String
    ) {
      fundHoldingHistorical(
        fundId: $entityId
        tickerId: $tickerId
        quarters: $quarters
        source: $source
      ) {
        items {
          fundId
          fundName
          tickerId
          securityName
          ${quarterlyFields}
        }
        count
      }
    }`
}

export const GET_INST_HISTORICAL_POSITION = (quarters) => {
  const quarterlyFields = getHistQtrFields(quarters)

  return gql`
    query InstitutionHoldingHistoricalPosition(
      $entityId: [String]!
      $tickerId: [String]
      $quarters: Int!
      $source: String
    ) {
      instHoldingHistorical(
        institutionId: $entityId
        tickerId: $tickerId
        quarters: $quarters
        source: $source
      ) {
        items {
          institutionId
          institutionName
          tickerId
          securityName
          ${quarterlyFields}
        }
        count
      }
    }`
}

/**
 * Historical Stock
 * @param options
 */
export function useHistoricalStockQuery (options) {
  return useQuery(GET_HISTORICAL_STOCK, options)
}

/**
 * Historical Holdings
 * @param type
 * @param options
 */
export function useHistoricalPositionQuery (type, options) {
  const quarters = get(options, 'variables.quarters', 4)
  const query = (type === ENTITY_TYPE.FUND)
    ? GET_FUND_HISTORICAL_POSITION(quarters)
    : GET_INST_HISTORICAL_POSITION(quarters)
  return useQuery(query, options)
}
