import React from 'react'
import { get } from 'lodash'
import { RenderHTML } from '../../../../components'
import { combine, getPhones, defaultIfEmpty } from '../../../../utils'

/**
 * Contact
 * @param props
 * @returns {*}
 */
export const Contact = (props) => {
  const { fullName, jobs } = props
  const job = jobs && jobs.length ? jobs[0] : {}
  const institution = get(job, 'institutionName', null)
  const title = get(job, 'title', null)
  const phones = getPhones(get(job, 'phone'), get(job, 'directPhone'))
  const functions = combine(job.functions || [])

  return (
    <>
      <div className='item_content-title'>{fullName}</div>
      {title || institution
        ? <div className='item_content-subtitle item_content-subtitle-small'>
          {title ? <span>{title}</span> : null} {title && institution ? <span>at</span> : null} {institution}
        </div> : null}
      <div className='item_content-body'>
        <div className='item_content-detail'>
          <div className='item_content-label'>Corporate Phone(s)</div>
          <div className='item_content-value'><RenderHTML html={defaultIfEmpty(phones)} /></div>
        </div>
        <div className='item_content-detail'>
          <div className='item_content-label'>Email</div>
          <div className='item_content-value'>{defaultIfEmpty(job.email)}</div>
        </div>
        <div className='item_content-detail'>
          <div className='item_content-label'>Function(s)</div>
          <div className='item_content-value'>{defaultIfEmpty(functions)}</div>
        </div>
      </div>
    </>
  )
}
