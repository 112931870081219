import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { addQueryParams } from '../../utils/http/http.util'

export const REPORT_BUILDER_PEER_REQUEST = 'REPORT_BUILDER_PEER_REQUEST'
export const REPORT_BUILDER_PEER_SUCCESS = 'REPORT_BUILDER_PEER_SUCCESS'
export const REPORT_BUILDER_PEER_FAILURE = 'REPORT_BUILDER_PEER_FAILURE'

export const REPORT_BUILDER_PEER_SEARCH_REQUEST = 'REPORT_BUILDER_PEER_SEARCH_REQUEST'
export const REPORT_BUILDER_PEER_SEARCH_SUCCESS = 'REPORT_BUILDER_PEER_SEARCH_SUCCESS'
export const REPORT_BUILDER_PEER_SEARCH_FAILURE = 'REPORT_BUILDER_PEER_SEARCH_FAILURE'

export const REPORT_BUILDER_CLEAR_PEERS = 'REPORT_BUILDER_CLEAR_PEERS'
export const REPORT_BUILDER_REMOVE_PEER = 'REPORT_BUILDER_REMOVE_PEER'
export const REPORT_BUILDER_ADD_PEER = 'REPORT_BUILDER_ADD_PEER'
export const REPORT_BUILDER_FOUND_PEERS_RESET = 'REPORT_BUILDER_FOUND_PEERS_RESET'
export const REPORT_BUILDER_SET_PEERS = 'REPORT_BUILDER_SET_PEERS'

const resources = {
  peer: '/peer',
  search: '/search/quick'
}

const fetchPeers = (url) => ({
  [CALL_API]: {
    types: [REPORT_BUILDER_PEER_REQUEST, REPORT_BUILDER_PEER_SUCCESS, REPORT_BUILDER_PEER_FAILURE],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
})

const quickSearchPeers = (url) => ({
  [CALL_API]: {
    types: [REPORT_BUILDER_PEER_SEARCH_REQUEST, REPORT_BUILDER_PEER_SEARCH_SUCCESS, REPORT_BUILDER_PEER_SEARCH_FAILURE],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
})

export const loadPeers = (params) => (dispatch) => {
  const url = addQueryParams(resources.peer, params)
  return dispatch(fetchPeers(url))
}

export const searchPeers = (params) => (dispatch) => {
  const url = addQueryParams(resources.search, params)
  return dispatch(quickSearchPeers(url))
}

export const clearPeers = () => (dispatch) => {
  dispatch({ type: REPORT_BUILDER_CLEAR_PEERS })
}

export const clearFoundPeers = () => (dispatch) => {
  dispatch({ type: REPORT_BUILDER_FOUND_PEERS_RESET })
}

export const removePeer = (securityId) => (dispatch) => {
  dispatch({ type: REPORT_BUILDER_REMOVE_PEER, payload: securityId })
}

export const addPeer = (peer) => (dispatch) => {
  dispatch({ type: REPORT_BUILDER_ADD_PEER, payload: peer })
}

export const resetAutoCompletePeers = () => (dispatch) => {
  dispatch({ type: REPORT_BUILDER_FOUND_PEERS_RESET })
}

export const setPeers = (peers) => (dispatch) => {
  dispatch({ type: REPORT_BUILDER_SET_PEERS, payload: peers })
}
