import { get, uniqBy } from 'lodash'

import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_PROGRESS_NOTIFICATION,
  DOWNLOAD_PROGRESS,
  DOWNLOAD_COMPLETE,
  DOWNLOAD_ERROR,
  APPEND_NEW_NOTIFICATION,
  statusType
} from '../../actions'

const initial = { status: statusType.IDLE, data: [] }

/**
 * Update existing notification by record id or create new record
 * @param record
 * @param notifications
 * @param status
 */
const updateNotifications = (record, notifications, status) => {
  record.status = status
  const recordIndex = notifications.findIndex((item) => item && item.id === get(record, 'id'))

  if (recordIndex !== -1 && status === statusType.IN_PROGRESS && get(record, 'progress') > 0) {
    record.read = get(notifications, `[${recordIndex}].read`, true)
  } else {
    record.read = false
  }

  if (recordIndex === -1) {
    return [].concat(record, notifications)
  }

  return notifications.map((item, index) => {
    if (index === recordIndex) {
      return get(record, 'timestamp') > get(item, 'timestamp') ? record : item
    }

    return item
  })
}

const notificationReducer = (state = initial, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        status: statusType.SUCCESS,
        data: action.payload
      }
    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR,
        data: []
      }
    case DOWNLOAD_PROGRESS:
      return {
        ...state,
        data: updateNotifications(action.payload, state.data, statusType.IN_PROGRESS)
      }
    case DOWNLOAD_ERROR:
      return {
        ...state,
        data: updateNotifications(action.payload, state.data, statusType.ERROR)
      }
    case DOWNLOAD_COMPLETE:
      return {
        ...state,
        data: updateNotifications(action.payload, state.data, statusType.SUCCESS)
      }
    case UPDATE_PROGRESS_NOTIFICATION:
      return {
        ...state,
        data: (state.data || []).map((notification) => notification.id ? { ...notification, read: true } : notification)
      }
    case APPEND_NEW_NOTIFICATION:
      state.data.unshift(action.payload)
      return {
        ...state,
        data: uniqBy(state.data, (notification) => (notification._id || notification.id))
      }
    case UPDATE_NOTIFICATION_SUCCESS:
      const notifications = state.data

      if (action.payload && action.payload.length) {
        action.payload.forEach(notification => {
          const i = notifications.findIndex((n) => (n._id === notification._id) || (n.id === notification.id))
          notifications[i] = notification
        })
      }

      return {
        ...state,
        data: notifications
      }
    default:
      return state
  }
}

export default notificationReducer
