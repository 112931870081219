import {
  GET_CONTACT_COVERAGE_REQUEST,
  GET_CONTACT_COVERAGE_SUCCESS,
  GET_CONTACT_COVERAGE_FAILURE,
  statusType
} from '../../actions'

const initial = {
  status: statusType.IDLE
}

const coverage = (state = initial, action) => {
  const { payload, request } = action

  switch (action.type) {
    case GET_CONTACT_COVERAGE_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_CONTACT_COVERAGE_SUCCESS:
      return {
        ...state,
        [request.analyst_id]: (payload || []).map((item) => ({
          securityId: item.security_id,
          securityName: item.company,
          eps: item.EPS,
          target: item.target,
          rating: item.rating,
          date: item.date
        })),
        status: statusType.SUCCESS
      }

    case GET_CONTACT_COVERAGE_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    default:
      return state
  }
}

export default coverage
