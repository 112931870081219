import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const GET_AI_SCORE_REQUEST = 'GET_AI_SCORE_REQUEST'
export const GET_AI_SCORE_SUCCESS = 'GET_AI_SCORE_SUCCESS'
export const GET_AI_SCORE_FAILURE = 'GET_AI_SCORE_FAILURE'

const _getAiScore = ({ entityType, entityId, tickerId, securityId }, options) => ({
  [CALL_API]: {
    types: options.types || [
      GET_AI_SCORE_REQUEST,
      GET_AI_SCORE_SUCCESS,
      GET_AI_SCORE_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/targeting/ai/score/${entityId}`,
    payload: {
      type: entityType,
      tickerId,
      securityId
    }
  }
})

export const fetchAiScore = (params, options = {}) => (dispatch) => {
  dispatch(_getAiScore(params, options))
}
