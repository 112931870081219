import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '../../../components'
import { HtmlEditor } from '../../shared'
import { FontSizeControls, TextAlignmentControls } from './textEditControls'
import './reportTextEditModal.component.css'

const STYLE_MAP = {
  'fontsize-12': { fontSize: 12 },
  'fontsize-24': { fontSize: 24 },
  'fontsize-48': { fontSize: 48 }
}

const TOOLBAR_CONFIG = {
  fontSize: {
    key: 'fontSizeOption',
    options: {
      Subheading: 'fontsize-48',
      Paragraph: 'fontsize-24',
      Caption: 'fontsize-12'
    },
  },
  textAlignment: {
    key: 'textAlignmentOption',
    options: ['left', 'center', 'right']
  }
}


class ReportTextEditModal extends PureComponent {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      text: props.data.text || '',
      html: props.data.html || '',
      styles: props.data.styles || {}
    }
  }

  /**
   * Automatically sets focus on component
   * @param ref
   */
  handleFocus = (ref) => ref && ref.focus()

  /**
   * Handle HtmlEditor's onChange event
   */
  handleChange = ({ text: newText, html: newHtml }) => {
    const { text, html } = this.state
    const isTextChanged = text !== newText
    const isHtmlChanged = html !== newHtml
    const isAllContentRemoved = isTextChanged && text && !newText
    const newState = { ...this.state }

    if (!isTextChanged && !isHtmlChanged) {
      return
    }

    if (isTextChanged) {
      newState['text'] = newText
    }

    if (isHtmlChanged) {
      newState['html'] = newHtml
    }

    if (isAllContentRemoved) {
      newState['styles'] = {}
    }

    this.setState(newState)
  }

  /**
   * Handle saving of the html
   */
  handleSave = () => {
    const { onSave, onClose } = this.props
    const { text, html, styles } = this.state

    onSave && onSave({ text, html, styles })
    onClose && onClose()
  }

  /**
   * Handle saving of the html
   */
  handleClose = () => {
    const { html, onClose } = this.props

    this.setState({
      html
    })

    onClose && onClose()
  }

  handleStyleUpdate = (key) => (option) => {
    this.setState({
      styles: {
        ...this.state.styles,
        [key]: option
      }
    })
  }

  /**
   * Render ReportTextEditModal
   */
  render () {
    const { visible, onClose } = this.props
    const { html, styles } = this.state

    return (
      <Modal
        visible={visible}
        title='Edit Text'
        containerWidth={600}
        footerButtons={[{
          label: 'Cancel',
          ui: 'shaded',
          onClick: this.handleClose
        }, {
          label: 'Save',
          ui: 'citrus',
          onClick: this.handleSave
        }]}
        onClose={onClose}
      >
        <div className='report-text-edit'>
          <HtmlEditor
            html={html}
            toolbar={{
              options: []
            }}
            editorRef={this.handleFocus}
            onChange={this.handleChange}
            customStyleMap={STYLE_MAP}
            toolbarCustomButtons={[
              <FontSizeControls
                fontSizeOptions={TOOLBAR_CONFIG.fontSize.options}
                selectedFontSize={styles[TOOLBAR_CONFIG.fontSize.key]}
                handleFontSizeChange={this.handleStyleUpdate('fontSizeOption')}
              />,
              <TextAlignmentControls
                textAlignmentOptions={TOOLBAR_CONFIG.textAlignment.options}
                handleTextAlignmentChange={this.handleStyleUpdate('textAlignmentOption')}
              />
            ]}
          />
        </div>
      </Modal>
    )
  }
}

ReportTextEditModal.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string,
    html: PropTypes.string,
    styles: PropTypes.shape({
      [TOOLBAR_CONFIG.fontSize.key]: PropTypes.oneOf(Object.keys(TOOLBAR_CONFIG.fontSize.options)),
      [TOOLBAR_CONFIG.textAlignment.key]: PropTypes.oneOf(TOOLBAR_CONFIG.textAlignment.options)
    })
  }),
  visible: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ReportTextEditModal
