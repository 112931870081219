import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import CustomCell from './cell/cell.component'
import { AgGrid, NoContentMessage, Spinner } from '../../../../../components'
import { getDefaultColumnDef, getQuarterEndDates, preventDefaultRowClick, isReportingWindow, THEMES, formatDate } from '../../../../../utils'

import './table.component.scss'

const propTypes = {
  pageSizeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number,
  listOptions: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  quarters: PropTypes.number,
  onQueryChange: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  loading: false,
  data: [],
  total: 0,
  quarters: 4,
  listOptions: {}
}

const columns = [
  {
    ...getDefaultColumnDef({ columnIndex: 0, type: 'text' }),
    field: 'securityName',
    headerName: 'Name',
    minWidth: 440,
    pinned: 'left',
    lockPinned: true
  }
]

/**
 * Get Column Change Definition
 * @param columnIndex
 * @param type
 */
const getColumnChangeDefinition = (columnIndex, type) => {
  const columnDef = getDefaultColumnDef({ columnIndex, type })

  columnDef.cellClassRules['ag-cell-value--increase'] = ({ value }) => isReportingWindow() && value === 0 ? false : value > 0
  columnDef.cellClassRules['ag-cell-value--decrease'] = ({ value }) => isReportingWindow() && value === 0 ? false : value < 0

  return columnDef
}

/**
 * Get quarter column definition based on number of quarters selected
 * @param quarters
 * @return {{}}
 */
const getQuarterColumnDefinition = (quarters) => {
  const dates = getQuarterEndDates(quarters, moment.utc())

  return (dates || []).map((quarter, idx) => {
    const index = idx + 1
    const formattedDate = formatDate(quarter, undefined, undefined, true)
    return {
      ...getDefaultColumnDef({ columnIndex: idx, type: 'date' }),
      headerName: `${formattedDate}`,
      colId: `${quarter}`,
      minWidth: 280,
      maxWidth: 280,
      children: [
        {
          ...getDefaultColumnDef({ columnIndex: 0, type: 'number' }),
          headerName: 'Position',
          field: `q${index}Value`,
          colId: `q${index}Value`,
          minWidth: 140,
          maxWidth: 140
        },
        {
          ...getColumnChangeDefinition(1, 'change'),
          headerName: 'Change',
          field: `q${index}Change`,
          colId: `q${index}Change`,
          minWidth: 140,
          maxWidth: 140
        }
      ]
    }
  })
}

/**
 * Historical Holding Table Component
 * @param props
 */
function HistoricalHoldingTable (props) {
  const { pageSizeId, loading, data, total, listOptions, quarters, onQueryChange, history } = props
  const [agGrid, setAgGrid] = useState({})
  const [quarterColumns, setQuarterColumns] = useState([])

  /**
   * Update columns on quarters change
   */
  useEffect(() => {
    quarters && setQuarterColumns(getQuarterColumnDefinition(quarters))
  }, [quarters])

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click event
   * @param id {String} - security ticker id
   */
  const handleRowClick = ({ tickerId }) => {
    history && tickerId && history.push(`/security/${tickerId}`)
  }

  /**
   * Handle page change
   * @param selected
   */
  const handlePageChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, page: selected }
    })
  }

  /**
   * Handle page size change
   * @param selected
   */
  const handlePageSizeChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, limit: selected, page: 1 }
    })
  }

  /**
   * Handle Column Sort
   * @param grid
   */
  const handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel && !sortModel.length) {
      return
    }

    onQueryChange({
      listOptions: {
        ...listOptions,
        sortBy: sortModel[0].colId,
        sortDir: sortModel[0].sort,
        page: 1
      }
    })
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell {...props} />
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage />
        : <AgGrid
          domLayout='autoHeight'
          sizeToFit

          // suppress configs
          suppressMovableColumns
          suppressContextMenu

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            sortable: true,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={[
            ...columns,
            ...quarterColumns
          ]}
          rowData={data}
          onRowDataChanged={handleGridResize}

          // pagination
          pagination
          paginationProps={{
            pageSizeId,
            forcePage: listOptions.page,
            initialPageSize: listOptions.limit,
            showPageSizeSelection: true,
            total,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: renderCustomCell
          }}

          // event listeners
          onGridReady={handleGridReady}
          onGridSizeChanged={handleGridResize}
          onSortChanged={handleSortChange}
          onRowClicked={preventDefaultRowClick(
            handleRowClick, ['']
          )}

          isPinned={data.length}
        />}
    </div>
  )
}

HistoricalHoldingTable.propTypes = propTypes
HistoricalHoldingTable.defaultProps = defaultProps

export default memo(HistoricalHoldingTable)
