import {
  GET_ENTITY_OWNERSHIP_LANGUAGE_POSITION_REQUEST,
  GET_ENTITY_OWNERSHIP_LANGUAGE_POSITION_SUCCESS,
  GET_ENTITY_OWNERSHIP_LANGUAGE_POSITION_FAILURE,
  statusType
} from '../../../../actions'
import { DEFAULT_DATE_FORMAT } from '../../../../utils'

const moment = require('moment-timezone')

/**
 * Entity Ownership Chart Language - Position Reducer
 */
const language = (state = {}, action) => {
  const { payload, request } = action
  switch (action.type) {
    case GET_ENTITY_OWNERSHIP_LANGUAGE_POSITION_REQUEST:
      return {
        ...state,
        [payload.entityId]: {
          status: statusType.IN_PROGRESS
        }
      }

    case GET_ENTITY_OWNERSHIP_LANGUAGE_POSITION_SUCCESS:
      return {
        ...state,
        [request.entityId]: {
          data: (payload || [])
            .filter((record) => record.current_qtr_date)
            .map((record) => {
              return {
                date: moment.utc(record.current_qtr_date).format(DEFAULT_DATE_FORMAT),
                position: isNaN(record.current) ? null : record.current
              }
            }),
          status: statusType.SUCCESS
        }
      }

    case GET_ENTITY_OWNERSHIP_LANGUAGE_POSITION_FAILURE:
      return {
        ...state,
        [request.entityId]: {
          status: statusType.ERROR
        }
      }

    default:
      return state
  }
}

export default language
