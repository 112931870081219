import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

export const FETCH_RELATIVE_PERFORMANCE_REQUEST = 'FETCH_NLG_REQUEST'
export const FETCH_RELATIVE_PERFORMANCE_SUCCESS = 'FETCH_NLG_SUCCESS'
export const FETCH_RELATIVE_PERFORMANCE_ERROR = 'FETCH_NLG_ERROR'

export const FETCH_VOLUME_ANALYSES_REQUEST = 'FETCH_VOLUME_ANALYSES_REQUEST'
export const FETCH_VOLUME_ANALYSES_SUCCESS = 'FETCH_VOLUME_ANALYSES_SUCCESS'
export const FETCH_VOLUME_ANALYSES_ERROR = 'FETCH_VOLUME_ANALYSES_ERROR'

const resources = {
  returnAnalysis: '/oxford/relative-performance/price',
  volumeAnalysis: '/oxford/relative-performance/volume'
}

const _fetchVolumeAnalyses = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_VOLUME_ANALYSES_REQUEST,
      FETCH_VOLUME_ANALYSES_SUCCESS,
      FETCH_VOLUME_ANALYSES_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

const _fetchRelativePerformance = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_RELATIVE_PERFORMANCE_REQUEST,
      FETCH_RELATIVE_PERFORMANCE_SUCCESS,
      FETCH_RELATIVE_PERFORMANCE_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

export const fetchRelativePerformance = (id) => {
  return (dispatch, getState) => {
    const state = getState()
    if (!id) {
      id = state.dashboard.dashboardState.securityId
    }

    dispatch(_fetchRelativePerformance(`${resources.returnAnalysis}/${id}`))
    dispatch(_fetchVolumeAnalyses(`${resources.volumeAnalysis}/${id}`))
  }
}
