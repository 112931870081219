import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST'
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS'
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE'
export const RESET = 'RESET'

/**
 * Get Events
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _getEvents = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_EVENTS_REQUEST,
      GET_EVENTS_SUCCESS,
      GET_EVENTS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/event',
    payload: {
      ...params,
      limit: params.limit || 10
    }
  }
})

/**
 * Dispatch Get Events
 * @param params
 * @param options.types - action types
 * @returns {function(*): *}
 */
export const getEvents = (params, options) => (dispatch) => {
  return dispatch(_getEvents(params, options))
}

/**
 * Reset Event Filters - resets reducer back to initial state
 * @returns {function(*): *}
 */
export const resetEvents = () => (dispatch) => {
  return dispatch({ type: RESET })
}
