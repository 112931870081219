import React, { memo, Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// utils
import { format, defaultIfEmpty, getClassName, getChangeClassName, getQuarterEndDates, formatLocalizedDate, DEFAULT_DATE_FORMAT, getLocalizedCurrency } from '../../../../../utils'

const EMPTY_PLACEHOLDER = '-'

const propTypes = {
  data: PropTypes.shape({
    marketValue: PropTypes.object,
    position: PropTypes.array,
    change: PropTypes.array
  }).isRequired
}

const defaultProps = {
  data: {}
}

/**
 * Natural Language
 * @param props
 */
const Language = ({ marketValue }) => {
  const { count, sum } = (marketValue || {})

  return (
    <div className='tearsheet_grid-language'>
      {!count ? 'Does not hold any peers' : <>
        Holds <span className='q4-blue'>{count}</span> {count > 1 ? 'peers' : 'peer'} valued at
        <span className='q4-blue'> {format(sum, 2)}</span> {getLocalizedCurrency()}
      </>}
    </div>
  )
}

/**
 * Header
 * @param props
 */
const Header = ({ quarters }) => {
  const dates = getQuarterEndDates(quarters - 1, moment.utc())

  return (
    <div className='tearsheet_grid-row tearsheet_grid-row--header'>
      <div className='tearsheet_grid-cell tearsheet_grid-cell--text'>Security</div>
      <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>Latest</div>
      <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>CHG</div>
      {(dates || []).map((quarter) => (
        <Fragment key={`${quarter}-header`}>
          <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>{formatLocalizedDate(quarter, DEFAULT_DATE_FORMAT)}</div>
          <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>CHG</div>
        </Fragment>
      ))}
    </div>
  )
}

/**
 * Peer Row
 * @param props
 */
const Peer = ({ isSecurity, quarters, position = {}, change = {} }) => {
  const dates = getQuarterEndDates(quarters, moment.utc())
  const { tickerId, securityName } = (position || {})

  return (
    <div
      key={tickerId}
      className={getClassName('tearsheet_grid-row', [
        { condition: isSecurity, trueClassName: 'tearsheet_grid-row--first' }
      ])}>
      <div className={getClassName('tearsheet_grid-cell tearsheet_grid-cell--text', [
        { condition: isSecurity, trueClassName: 'tearsheet_grid-cell--bold' }
      ])}>
        {defaultIfEmpty(securityName)}
      </div>
      {(dates || []).map((quarter, idx) => {
        const current = position[`q${idx}`]
        const qtrChange = change[`q${idx}`]

        return (
          <Fragment key={`${tickerId}-${quarter}`}>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>
              {(current || current === 0) ? format(current) : EMPTY_PLACEHOLDER}
            </div>
            <div className={`tearsheet_grid-cell tearsheet_grid-cell--number ${getChangeClassName(qtrChange)}`}>
              {(qtrChange || qtrChange === 0) ? format(qtrChange) : EMPTY_PLACEHOLDER}
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

/**
 * Peer Holding Component
 * @param props
 */
function PeerHoldingComponent ({ data }) {
  const quarters = 4
  const { tickerId, position, change, marketValue, securityName } = data

  return (
    <>
      <Language marketValue={marketValue} />
      <div className='tearsheet_grid tearsheet_grid--nine-columns'>
        <Header quarters={quarters} />
        {position.map((position) =>
          <Peer
            key={`${position.entityId}-${position.tickerId}`}
            isSecurity={tickerId === position.tickerId || securityName === position.securityName}
            quarters={quarters}
            position={position}
            change={change.find(({ tickerId }) => tickerId === position.tickerId)}
          />
        )}
      </div>
    </>
  )
}

PeerHoldingComponent.propTypes = propTypes
PeerHoldingComponent.defaultProps = defaultProps

export default memo(PeerHoldingComponent)
