import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { OWNERSHIP_TYPE } from '../../../utils'

export const GET_FUNDS = gql`
  query Fund(
    $id: [String]
    $entityId: String
    $securityId: [String]!
    $tickerId: [String]!
    $currencyCode: String
    $holder: Boolean
    $peerHolder: Boolean
    $search: String
    $searchField: [String]
    $source: String = "${OWNERSHIP_TYPE['13F']}"
    $sortBy: String
    $sortDir: String
    $page: Int!
    $limit: Int!
  ) {
    fund(
      id: $id
      institutionId: $entityId
      tickerId: $tickerId
      securityId: $securityId
      currencyCode: $currencyCode
      filter: {
        holder: $holder
        peerHolder: $peerHolder
      }
      search: $search
      searchField: $searchField
      sortBy: $sortBy
      sortDir: $sortDir
      page: $page
      limit: $limit
    ) {
      items {
        id
        fundName
        institutionName
        style
        turnover
        portfolioValue
        equityAUM
        qualityRating
        countryName
        activityConnection {
          items {   
            start {
              date
            }          
          }
        }
        contactConnection {
          items {   
            id
            fullName          
          }
        }
        aiScoreConnection(securityId: $securityId) {
          items {
            score
          }
        }
        fundHoldingCurrentConnection(
          tickerId: $tickerId
          source: $source
        ) {
          items {
            current
            reportDate
          }
        }
        purchasingPowerConnection(securityId: $securityId) {
          items {
            purchasingPower
          }
        }
      }
      count
    }
  }`

/**
 * Funds
 * @param options
 */
export function useFundQuery (options) {
  return useQuery(GET_FUNDS, options)
}
