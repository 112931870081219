import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { setClassNames } from '../../../../utils/widget.util'
import UpcomingActivities from '../../../../containers/widget/activity/upcomingActivities/upcomingActivities.container'
import Calendar from 'react-calendar'
import moment from 'moment-timezone'
import { find } from 'lodash'
import './calendar.component.css'

class ActivityCalendarItem extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeStartDate: moment().toDate()
    }
  }

  /**
   * On Date param change
   * @param date
   */
  onChange = (date) => {
    const isToday = moment(date).isSame(moment.utc(), 'day')

    let startDate

    if (isToday) {
      startDate = moment.utc().format('MM-DD-YYYY hh:mm:ss a')
    } else {
      startDate = moment.utc(date).startOf('day').format('MM-DD-YYYY hh:mm:ss a')
    }

    this.props.loadCurrentActivities(startDate)
    this.props.setCurrentActivityDate(date)
  }

  /**
   * Populate tile content
   * @param e
   */
  getTileContent = (e) => {
    if (!e || !e.date) {
      return
    }

    const hasActivitiesOnDate = find(this.props.calendar, (calendarDate) => {
      return moment.utc(calendarDate).format('YYYY-MM-DD') === moment.utc(e.date).format('YYYY-MM-DD')
    })

    if (hasActivitiesOnDate) {
      return (<div>&middot;</div>)
    } else {
      return (<div/>)
    }
  }

  /**
   * Format Weekday
   * @param date
   * @returns {string}
   */
  formatWeekday = (date) => {
    return moment(date).format('dd').slice(0, 1)
  }

  onActiveDateChange = (data) => {
    const { fetchCalendar, category } = this.props
    fetchCalendar({
      category,
      startDate: moment(data.activeStartDate).subtract(3, 'months').format('YYYY-MM-DD'),
      endDate: moment(data.activeStartDate).add(3, 'months').format('YYYY-MM-DD'),
      currentAndUpcomingActivity: false
    })
    this.setState({ activeStartDate: data.activeStartDate })
  }

  render () {
    const { options, currentActivityDate, theme, layout, history } = this.props
    const classes = setClassNames('upcoming-activities', layout, theme)
    const onlyCalendar = layout && (layout.h <= 2 && layout.w === 1)
    const fullCalendar = layout && layout.h >= 3
    const widgetClass = [
      'upcoming-activities-widget',
      `upcoming-activities-widget--${theme}`,
      onlyCalendar ? 'upcoming-activities-widget--short' : '',
      fullCalendar ? 'upcoming-activities-widget--break' : ''
    ].join(' ')

    const agenda = (layout.w > 1) ? [`upcoming-activities-widget_agenda`] : [`upcoming-activities-widget_agenda upcoming-activities-widget_agenda--narrow`]

    let params = {
      className: `react-calendar--${theme}`,
      tileContent: (e) => this.getTileContent(e),
      onChange: (date) => this.onChange(date),
      formatShortWeekday: (day) => this.formatWeekday(day),
      onActiveDateChange: this.onActiveDateChange,
      activeStartDate: this.state.activeStartDate || currentActivityDate,
      nextLabel: '',
      prevLabel: '',
    }

    const currentDate = (currentActivityDate ? moment(currentActivityDate).toDate() : new Date())

    if (moment().isSame(this.state.activeStartDate, 'month')) {
      params['value'] = moment().toDate()
    } else if (moment(currentDate).isSame(this.state.activeStartDate, 'month')) {
      params['value'] = currentDate
    }

    return (
      <div className={widgetClass}>
        <div className='upcoming-activities-widget_inner'>
          <div className='upcoming-activities-widget_calendar'>
            <header className='upcoming-activities-widget_header'>
              <h2 className='upcoming-activities-widget_heading' onClick={() => history.push('/calendar')}>Calendar</h2>
            </header>
            <Calendar {...params}/>
          </div>
          <div className={agenda}>
            <UpcomingActivities options={options} classes={classes}/>
          </div>
        </div>
        <div className='upcoming-activities-widget_footer'>
          <Link to={'/calendar'}>View all</Link>
        </div>
      </div>
    )
  }
}

export default withRouter(ActivityCalendarItem)
