import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import { defaultIfEmpty, format, formatDateByTimezone, inMillions, isPassive, DEFAULT_DATE_FORMAT, ENTITY_TYPE, formatLocalizedDate, getLocalizedCurrency } from '../../../../utils'
import { capitalize, get } from 'lodash'

const { CONTACT, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    position: PropTypes.number,
    purchasingPower: PropTypes.number,
    totalAUM: PropTypes.number,
    portfolioValue: PropTypes.number,
    equityAUM: PropTypes.number,
    style: PropTypes.string,
    turnover: PropTypes.string,
    aiScore: PropTypes.number,
    qualityRating: PropTypes.number,
    activity: PropTypes.object
  }).isRequired,
  entityType: PropTypes.string.isRequired,
  ownership: PropTypes.string.isRequired,
  gdpr: PropTypes.bool
}

const defaultProps = {
  data: {},
  gdpr: false,
  ownership: '13F'
}

/**
 * Attribute Component
 */
function Attribute ({ label, value }) {
  return (
    <Grid item xs={6} className='tearsheet-attribute'>
      {label} {defaultIfEmpty(value)}
    </Grid>
  )
}

/**
 * Entity Attributes Card Component
 * @param props
 */
function AttributeCard (props) {
  const { data, entityType, ownership, gdpr } = props
  const { position, purchasingPower, totalAUM, portfolioValue, equityAUM, style, turnover, qualityRating, aiScore, activity } = data

  const latestActivityDate = get(activity, 'activity.items[0].start', {})
  const date = latestActivityDate.date ? new Date(Number(latestActivityDate.date)) : null
  const timezone = latestActivityDate.timezone || null
  const currency = getLocalizedCurrency()

  const attributes = [
    { label: 'Last Activity:', value: date && formatLocalizedDate(formatDateByTimezone(date, DEFAULT_DATE_FORMAT, timezone), DEFAULT_DATE_FORMAT) },
    (entityType === CONTACT) && !gdpr && {
      label: 'Contact Position:',
      value: format(get(data, 'contactHoldingCurrentConnection.items[0].current'))
    },
    (entityType !== CONTACT) && {
      label: `AUM (MM ${currency}):`,
      value: entityType === INSTITUTION ? format(totalAUM, 0) : inMillions(portfolioValue, 0)
    },
    { label: 'Total Activity:', value: get(activity, 'activity.count', null) },
    (entityType !== CONTACT) && {
      label: `E AUM (MM ${currency}):`,
      value: entityType === INSTITUTION ? format(equityAUM, 0) : inMillions(equityAUM, 0)
    },
    !gdpr && { label: `Current Position (${ownership}):`, value: format(position) },
    (entityType !== CONTACT) && { label: 'Style:', value: style },
    (entityType !== CONTACT) && { label: 'Purchasing Power:', value: format(purchasingPower) },
    (entityType !== CONTACT) && { label: 'Turnover:', value: turnover ? capitalize(turnover) : '-' },
    (entityType !== CONTACT) && { label: 'AI Score:', value: isPassive({ style, aiScore }) },
    (entityType !== CONTACT) && { label: 'QR Score:', value: qualityRating }
  ]

  return (
    <Grid container spacing={2} className='tearsheet_section'>
      <Grid item xs={12} className='tearsheet_section-title'>Attributes</Grid>
      <Grid item xs={12} className='tearsheet_section-content'>
        <Grid container spacing={1}>
          {attributes.map((attribute) => {
            return attribute ? <Attribute key={attribute.label} {...attribute} /> : null
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}

AttributeCard.propTypes = propTypes
AttributeCard.defaultProps = defaultProps

export default memo(AttributeCard)
