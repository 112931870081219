import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

const GET_ACTIVITY = gql`
  query Activity(
    $id: [String]
    $entityId: String
    $search: String
    $category: [String]
    $sortBy: String
    $sortDir: String
    $page: Int!
    $limit: Int!
  ) {
    activity(
      id: $id
      entityId: $entityId
      search: $search
      filter: {
        category: $category
      }
      sortBy: $sortBy
      sortDir: $sortDir
      page: $page
      limit: $limit
    ) {
      items {
        id
        category
        title
        start {
          date
        }
        links {
          entityId
          entityType   
          entityName   
          entityConnection {
            ... on ContactDTO {
              id
              fullName
            }
            ... on InstitutionDTO {
              id
              institutionName
            }
          }         
        }
        address {
          location
        }
        virtual
        tag
        _activity {
          activityConnection {
            items {
              id
              title
            }
          }
        }
      }
      count
      categoryCount {
        comment
        phone
        email
        meeting
        earnings
        other
        roadshow
        conference
      }
    }
  }`

export const GET_ACTIVITY_EXPORT = gql`
  query Activity(
    $id: [String]
    $entityId: String
    $search: String
    $category: [String]
    $sortBy: String
    $sortDir: String
    $page: Int!
    $limit: Int!
  ) {
    activity(
      id: $id
      entityId: $entityId
      search: $search
      filter: {
        category: $category
      }
      sortBy: $sortBy
      sortDir: $sortDir
      page: $page
      limit: $limit
    ) {
      items {
        id
        category
        title
        start {
          date
        }
        end {
          date
        }
        links {
          entityId
          entityType      
          entityName
          entityConnection {
            ... on ContactDTO {
              id
              fullName
              email
              directPhone
              jobs {
                institutionName
                institutionType
                city
                stateProvince
                email
                directPhone
                phone
              }
            }
            ... on InstitutionDTO {
              id
              institutionName
              institutionType
              style
              turnover
              addressConnection {
                items {
                  city
                  stateProvinceCode
                }
              }
            }
            ... on FundDTO {
              id
              fundName
              fundTypeDesc
              style
              turnover
            }
          }         
        }
        address {
          location
          venue
        }
        virtual
        participants {
          name
        }
        tag
        text
        _itinerary
      }
    }
  }`

export const GET_ACTIVITY_DETAIL_EXPORT = gql`
query Activity($id: [String], $page: Int, $limit: Int, $parentActivity: String, $securityId: String, $tickerId: String, $sortBy: String, $sortDir:String ) {
  activity(id: $id, page: $page, limit: $limit, filter: {parentActivity: $parentActivity},  sortBy: $sortBy, sortDir: $sortDir) {
    items {  
      id
      start {
        timezone
        date
      }
      end {
        date
        timezone
      }
      category
      body  
      title
      _itinerary
      address {
        location
        venue
      }
      virtual
      links {
        entityType
        entityConnection {
          ... on ContactDTO {
            id
            fullName
            jobs {
              id
              institutionName
              institutionType
              institutionConnection{
                items{
                  style
                  turnover
                  totalAUM
                }
              }
              phone
              email
              city
              stateProvince
            }
            contactHoldingCurrentConnection(securityId: [$securityId]) {
              items {
                current
              }
            }
          }
          ... on InstitutionDTO {
            id
            institutionName
            institutionType
            style
            turnover
            totalAUM
            addressConnection {
              items {
                city
                stateProvinceCode
                hq
              }
            }
            institutionHoldingCurrentConnection(tickerId: [$tickerId]) {
              items {
                current
              }
            }
          }        
          ... on FundDTO {
            id
            fundName
            fundTypeDesc
            style
            turnover
            fundHoldingCurrentConnection(tickerId: [$tickerId]) {
              items {
                current
              }
            }
          }
        }
      }
      participants {
        id
        name
      }
      tag
      text
      _activity {
        activityConnection {
          items {
            id
            start {
              timezone
              date
            }
            end {
              date
              timezone
            }
            category
            address {
              location
              venue
            }
            virtual
            title
            tag
            text
          }
        } 
      }
    }
  }
}`

const DELETE_ACTIVITY = gql`
  mutation Activity(
    $id: [String]!
  ) {
    activity {
      delete(
        id: $id
      ) {
        count
      }
    }
  }`

/**
 * Activity
 * @param options
 */
export function useActivityQuery (options) {
  return useQuery(GET_ACTIVITY, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Activity Delete
 * @param options
 */
export function useActivityDeleteQuery (options) {
  return useMutation(DELETE_ACTIVITY, options)
}
