import {
  TARGETING_UPDATE_FILTER,
  TARGETING_RESET_FILTER,
  TARGETING_SET_FILTER_ERRORS,
  TARGETING_LOCATION_SEARCH_REQUEST,
  TARGETING_LOCATION_SEARCH_FAILURE,
  TARGETING_LOCATION_SEARCH_SUCCESS_FACTSET,
  TARGETING_LOCATION_SEARCH_SUCCESS,
  TARGETING_LOCATION_SEARCH_RESET,
  TARGETING_SECURITY_SEARCH_REQUEST,
  TARGETING_SECURITY_SEARCH_FAILURE,
  TARGETING_SECURITY_SEARCH_SUCCESS,
  TARGETING_SECURITY_SEARCH_RESET,
  TARGETING_SET_FILTER,
  statusType
} from '../../actions'

const styleOptions = [
  { label: 'Aggressive Growth', value: 'Aggressive Growth', filter: 'active' },
  { label: 'Alternative', value: 'Alternative', filter: 'active' },
  { label: 'Broker/Dealer', value: 'Broker/Dealer', filter: 'active' },
  { label: 'Deep Value', value: 'Deep Value', filter: 'active' },
  { label: 'GARP', value: 'GARP', filter: 'active' },
  { label: 'Growth', value: 'Growth', filter: 'active' },
  { label: 'Hedge Fund', value: 'Hedge Fund', filter: 'active' },
  { label: 'Income', value: 'Income', filter: 'active' },
  { label: 'Index', value: 'Index', filter: 'passive' },
  { label: 'Private Equity', value: 'Private Equity', filter: 'active' },
  { label: 'Specialty', value: 'Specialty' },
  { label: 'Value', value: 'Value', filter: 'active' },
  { label: 'Yield', value: 'Yield', filter: 'active' },
  { label: 'Quant', value: 'Quant', filter: 'passive' }
]

const turnoverOptions = [
  { label: 'Very Low', value: 'Very Low' },
  { label: 'Low', value: 'Low' },
  { label: 'Medium', value: 'Medium' },
  { label: 'High', value: 'High' },
  { label: 'Very High', value: 'Very High' }
]

const typeOptions = [
  { label: 'Arbitrage', value: 'Arbitrage', filter: 'institution' },
  { label: 'Bank Investment Division', value: 'Bank Investment Division', filter: 'institution' },
  { label: 'Broker', value: 'Broker', filter: 'institution' },
  { label: 'Corporate', value: 'Corporate', filter: 'institution' },
  { label: 'Custodial', value: 'Custodial', filter: 'institution' },
  { label: 'Fund of Funds Manager', value: 'Fund of Funds Manager', filter: 'institution' },
  { label: 'Fund of Hedge Funds Manager', value: 'Fund of Hedge Funds Manager', filter: 'institution' },
  { label: 'Foundation/Endowment Manager', value: 'Foundation/Endowment Manager', filter: 'institution' },
  { label: 'Fund Distributor', value: 'Fund Distributor', filter: 'institution' },
  { label: 'Fund', value: 'Fund', filter: 'institution' },
  { label: 'Family Office', value: 'Family Office', filter: 'institution' },
  { label: 'Govt (Fed/Local/Agency)', value: 'Govt (Fed/Local/Agency)', filter: 'institution' },
  { label: 'Hedge Fund Manager', value: 'Hedge Fund Manager', filter: 'institution' },
  { label: 'Investment Adviser', value: 'Investment Adviser', filter: 'institution' },
  { label: 'Investment Banking', value: 'Investment Banking', filter: 'institution' },
  { label: 'Investment Company', value: 'Investment Company', filter: 'institution' },
  { label: 'Insurance Company', value: 'Insurance Company', filter: 'institution' },
  { label: 'Mutual Fund Manager', value: 'Mutual Fund Manager', filter: 'institution' },
  { label: 'Master Ltd Part', value: 'Master Ltd Part', filter: 'institution' },
  { label: 'Market Maker', value: 'Market Maker', filter: 'institution' },
  { label: 'Private Banking/Wealth Mgmt', value: 'Private Banking/Wealth Mgmt', filter: 'institution' },
  { label: 'Pension Fund Manager', value: 'Pension Fund Manager', filter: 'institution' },
  { label: 'Real Estate Manager', value: 'Real Estate Manager', filter: 'institution' },
  { label: 'Research Firm', value: 'Research Firm', filter: 'institution' },
  { label: 'Subsidiary Branch', value: 'Subsidiary Branch', filter: 'institution' },
  { label: 'Stock Borrowing/Lending', value: 'Stock Borrowing/Lending', filter: 'institution' },
  { label: 'Sovereign Wealth Manager', value: 'Sovereign Wealth Manager', filter: 'institution' },
  { label: 'Venture Capital/Pvt Equity', value: 'Venture Capital/Pvt Equity', filter: 'institution' },

  // fund
  { label: 'Bank Portfolio', value: 'Bank Portfolio', filter: 'fund' },
  { label: 'Closed-End Fund', value: 'Closed-End Fund', filter: 'fund' },
  { label: 'Exchange Traded Fund', value: 'Exchange Traded Fund', filter: 'fund' },
  { label: 'Exchange Traded Notes', value: 'Exchange Traded Notes', filter: 'fund' },
  { label: '13F Parent Portfolio', value: '13F Parent Portfolio', filter: 'fund' },
  { label: 'Hedge Fund', value: 'Hedge Fund', filter: 'fund' },
  { label: 'Insurance - Diversified', value: 'Insurance - Diversified', filter: 'fund' },
  { label: 'Insurance - Life/Health', value: 'Insurance - Life/Health', filter: 'fund' },
  { label: 'Investment Management Co', value: 'Investment Management Co', filter: 'fund' },
  { label: 'Insurance - P & C', value: 'Insurance - P & C', filter: 'fund' },
  { label: 'Non-Public Fund', value: 'Non-Public Fund', filter: 'fund' },
  { label: 'Open-End Fund', value: 'Open-End Fund', filter: 'fund' },
  { label: 'Pension Fund', value: 'Pension Fund', filter: 'fund' },
  { label: 'Pension & Life Product', value: 'Pension & Life Product', filter: 'fund' },
  { label: 'Private Equity Fund', value: 'Private Equity Fund', filter: 'fund' },
  { label: 'Reinsurance', value: 'Reinsurance', filter: 'fund' },
  { label: 'Unit Investment Trust', value: 'Unit Investment Trust', filter: 'fund' },
  { label: 'Variable Annuity Fund', value: 'Variable Annuity Fund', filter: 'fund' }
]

const initial = {
  location: [],
  locationSuggestions: [],
  locationSuggestionStatus: statusType.IDLE,
  target_type: 'institution',
  quality_rating: [null, null],
  aum: [null, null],
  equity_aum: [null, null],
  purchasing_power: [null, null],
  investment_style: 'all',
  style: [],
  styleOptions,
  turnover: [],
  turnoverOptions,
  type: [],
  typeOptions: typeOptions.filter((option) => (option.filter === 'institution')),
  owns_security: [],
  securitySuggestions: [],
  securitySuggestionStatus: statusType.IDLE,
  peers: false,
  peer_activity: 'all',
  sector_activity: 'all',
  exclude_activists: false,
  ai_only: false,
  logged_activity: 'all',
  activity_start: null,
  activity_end: null,
  page: 1,
  start: 0,
  limit: 20,
  errors: {}
}

/**
 * Clear Options
 * @param state
 * @returns {...*[]}
 */
const clearOptions = (state) => {
  return [...state.map((each) => {
    delete each.selected
    return each
  })]
}

const filter = (state = initial, action) => {
  const actionPayloadArray = action.payload || []

  switch (action.type) {
    case TARGETING_UPDATE_FILTER: {
      const { payload } = action
      const style = payload.investment_style || state.investment_style
      const type = payload.target_type || state.target_type

      const styleState = payload.styleOptions || payload.investment_style
        ? getOptions(getStyleOptions(style, styleOptions), (payload.style || state.style)) 
        : state.styleOptions
      const turnoverState = payload.turnoverOptions 
        ? getOptions(turnoverOptions, (payload.turnover || state.turnover)) 
        : state.turnoverOptions
      const typeState = payload.typeOptions 
        ? getOptions(typeOptions.filter((option) => (option.filter === type)), (payload.type || state.type)) 
        : state.typeOptions

      return {
        ...state,
        styleOptions: styleState,
        turnoverOptions: turnoverState,
        typeOptions: typeState,
        ...action.payload
      }
    }
    case TARGETING_RESET_FILTER:
      return {
        ...initial,
        styleOptions: clearOptions(initial.styleOptions),
        turnoverOptions: clearOptions(initial.turnoverOptions),
        typeOptions: clearOptions(initial.typeOptions),
        errors: {}
      }

    case TARGETING_SET_FILTER_ERRORS:
      return {
        ...state,
        errors: action.payload.errors
      }

    case TARGETING_SET_FILTER: {
      const { payload } = action
      const style = payload.investment_style
      const type = payload.target_type

      return {
        ...initial,
        styleOptions: getOptions(getStyleOptions(style, styleOptions), (payload.style)),
        turnoverOptions: getOptions(turnoverOptions, (payload.turnover)),
        typeOptions: getOptions(typeOptions.filter((option) => (option.filter === type)), (payload.type)),
        ...action.payload
      }
    }

    case TARGETING_LOCATION_SEARCH_REQUEST:
      return {
        ...state,
        locationSuggestionStatus: statusType.IN_PROGRESS
      }

    case TARGETING_LOCATION_SEARCH_FAILURE:
      return {
        ...state,
        locationSuggestionStatus: statusType.ERROR
      }

    case TARGETING_LOCATION_SEARCH_SUCCESS_FACTSET:
      return {
        ...state,
        locationSuggestions: actionPayloadArray.map((record) => record.metro_desc),
        locationSuggestionStatus: statusType.SUCCESS
      }

    case TARGETING_LOCATION_SEARCH_SUCCESS:
      return {
        ...state,
        locationSuggestions: actionPayloadArray.map((record) => `${record.location_city}/${record.state_province_name}`),
        locationSuggestionStatus: statusType.SUCCESS
      }

    case TARGETING_LOCATION_SEARCH_RESET:
      return {
        ...state,
        locationSuggestions: initial.locationSuggestions,
        locationSuggestionStatus: statusType.IDLE
      }

    case TARGETING_SECURITY_SEARCH_REQUEST:
      return {
        ...state,
        securitySuggestionStatus: statusType.IN_PROGRESS
      }

    case TARGETING_SECURITY_SEARCH_FAILURE:
      return {
        ...state,
        securitySuggestionStatus: statusType.ERROR
      }

    case TARGETING_SECURITY_SEARCH_SUCCESS:
      return {
        ...state,
        securitySuggestions: actionPayloadArray.map((record) => ({
          _id: record._id,
          name: record.name,
          symbol: record.symbol,
          exchange: record.exchange
        })),
        securitySuggestionStatus: statusType.SUCCESS
      }

    case TARGETING_SECURITY_SEARCH_RESET:
      return {
        ...state,
        securitySuggestions: initial.securitySuggestions,
        securitySuggestionStatus: statusType.IDLE
      }

    default:
      return state
  }
}

/**
 * Get dropdown options
 * @param options
 * @param selected
 * @returns {(T&{selected: *})[]}
 */
const getOptions = (options, selected) => {
  return (!selected || !selected.length)
    ? options
    : (options || []).map((option) => ({ ...option, selected: [].concat(selected).includes(option.value) }))
}

/**
 * Get style options
 * @param style
 * @param options
 * @returns {*}
 */
const getStyleOptions = (style, options) => {
  return (style === 'all')
    ? options
    : (options || [])
        .filter((option) => (option.filter === style))
        .map((option) => ({ ...option, selected: true }))
}

export default filter
