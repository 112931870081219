import gql from 'graphql-tag'
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'

const CREATE_DEAL = gql`
  mutation Deal(
    $type: DealTypeDTO!
    $title: String!
    $start: DealDate!
    $end: DealDate!
    $stage: String!
    $archive: Boolean
    $amount: Int
    $usePurchasingPower: Boolean
    $description: String
    $entity: [DealEntity]!
  ) {
    deal {
      create(
        type: $type
        title: $title
        start: $start
        end: $end
        stage: $stage
        archive: $archive
        amount: $amount
        usePurchasingPower: $usePurchasingPower
        description: $description
        entity: $entity  
      ) {
        items {
          id
        }
        count
      }
    }
  }`

export const UPDATE_DEAL = gql`
  mutation Deal(
    $id: String!
    $type: DealTypeDTO
    $title: String
    $start: DealDate
    $end: DealDate
    $stage: String
    $archive: Boolean
    $amount: Int
    $usePurchasingPower: Boolean
    $description: String
    $entity: [DealEntity]
  ) {
    deal {
      update(
        id: $id
        type: $type
        title: $title
        start: $start
        end: $end
        stage: $stage
        archive: $archive
        amount: $amount
        usePurchasingPower: $usePurchasingPower
        description: $description
        entity: $entity
      ) {
        count
      }
    }
  }`

const GET_PIPELINE = gql`
  query pipeline {
    pipeline {
      items {
        id
        index
        label
        lost
      }
    }
  }`

const GET_PURCHASING_POWER = gql`
  query PurchasingPower(
    $securityId: [String]!
    $entity: [PurchasingPowerEntity]!
    $isRelative: Boolean!
  ) {
    purchasingPower(
      securityId: $securityId
      entity: $entity
    ) {
      sum(
        isRelative: $isRelative
      )
    }
  }`

/**
 * Deal create
 * @param options
 */
export function useDealCreateQuery (options) {
  return useMutation(CREATE_DEAL, options)
}

/**
 * Deal update
 * @param options
 */
export function useDealUpdateQuery (options) {
  return useMutation(UPDATE_DEAL, options)
}

/**
 * Pipeline
 * @param options
 */
export function usePipelineQuery (options) {
  return useQuery(GET_PIPELINE, options)
}

/**
 * Purchasing Power
 * @param options
 */
export function usePurchasingPowerQuery (options) {
  return useLazyQuery(GET_PURCHASING_POWER, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}
