import { combineReducers } from 'redux'
import position from './position.reducer'
import current from './current.reducer'
import historical from './historical.reducer'
import holding from './holding.reducer'

export default combineReducers({
  position,
  current,
  historical,
  holding
})
