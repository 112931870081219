import './aiTargeting.container.css'
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { round, differenceWith, isEqual, orderBy } from 'lodash'
import {
  FETCHED,
  FETCHING,
  getAiTargetingByLocation,
  resetStatus,
  setCountry,
  setRegion
} from '../../../actions/widget/aiTargeting/aiTargeting.actions'
import { setTargetingFilter } from '../../../actions/targeting'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderDarkThumb, renderTrackVertical } from '../../../resources/theme/q4.custom-scrollbar'
import AiTargetingMap from '../../../components/widget/aiTargeting/aiTargetingMap.component'
import WidgetError from '../../../components/widget/error/widgetError.component'
import { Message, Modal, Spinner } from '../../../components'
import { Dropdown } from '../../../components/shared'
import { setClassNames } from '../../../utils/widget.util'
import { countries, getCountryStates, regions } from '../../../utils/map/location.map.util'
import { TARGETING_URL } from '../../../utils'

class aiTargeting extends Component {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      isAIModalOpen: false,
      isInfoModalOpen: false,
      targets: {
        state: '',
        stateCode: '',
        value: 0,
        selected: []
      },
      defaultQuery: {
        aum: [null, null],
        quality_rating: [null, null],
        equity_aum: [null, null],
        purchasing_power: [null, null],
        investment_style: 'all',
        style: [],
        turnover: [],
        type: [],
        sector_activity: 'all',
        peers: false,
        peer_activity: 'all',
        exclude_activists: false,
        logged_activity: false,
        ai_only: true
      }
    }
  }

  /**
   * Fetch AI targets by type
   * @param params
   * @private
   */
  _fetchData = (params) => {
    const { getTargets, region, country, profileRegion, securityId } = this.props

    const props = {
      securityId,
      type: this.props.activeTab,
      region,
      country
    }

    if (!params) {
      if (!region && !country) {
        if (profileRegion === 'north_america') {
          Object.assign(props, { country: 'us' })
        } else if (profileRegion === 'europe') {
          Object.assign(props, { region: 'europe' })
        }
      } else {
        if (region) {
          Object.assign(props, { region })
        } else {
          Object.assign(props, { country })
        }
      }
    } else {
      Object.assign(props, params)
    }

    getTargets(props)
  }

  /**
   * Fetch institution targets on component mount
   */
  componentDidMount = () => {
    const { isEdit, status } = this.props
    if (isEdit && status === FETCHED) {
      return
    }

    const refreshInterval = this.props.options.refreshInterval
    if (refreshInterval) {
      this.setState({
        timer: setInterval(this._fetchData.bind(this), refreshInterval)
      })
    }

    this._fetchData()
  }

  /**
   * ComponentDidUpdate
   * Re-fetch data when security changed
   * @param prevProps
   */
  componentDidUpdate = (prevProps) => {
    if (prevProps.securityId !== this.props.securityId) {
      this._fetchData(this.props.activeTab)
    }
  }

  /**
   * clear fetch interval on unmount
   */
  componentWillUnmount = () => {
    if (this.state.timer) {
      clearInterval(this.state.timer)
    }
  }

  /**
   * Tab change @method
   * Load institution/fund based on the tabb value
   * @param value
   */
  handleEntityChange = (value) => {
    const { resetStatus } = this.props

    resetStatus()

    this._fetchData({ type: value })
  }

  /**
   * Region Dropdown change @method
   * Set region in state
   * @param option
   */
  handleDropdownChange = (option) => {
    if (!option || !option.value) {
      return
    }

    if (regions[option.value]) {
      this.props.setCountry(null)
      this.props.setRegion(option.value)
    } else if (countries[option.value]) {
      this.props.setRegion(null)
      this.props.setCountry(option.value)
    }
  }

  /**
   * Region map click event handler
   * @param data
   */
  onMapClick = (data) => {
    let options = data && data.options
    let state = {
      isAIModalOpen: true,
      targets: {
        state: data && data.name,
        stateCode: data && data.code,
        value: data && data.value,
        selected: [],
        metro_area: []
      }
    }

    if (options && options.targets) {
      state.targets.selected = options.targets.map(item => {

        if (state.targets.metro_area.indexOf(item.metro) === -1) {
          state.targets.metro_area.push(item.metro)
        }

        return {
          name: item.holder_name,
          value: item.score,
          factset_entity_id: item.factset_entity_id || item.factset_fund_id
        }
      })
    }

    this.setState(state)
  }

  /**
   * Redirect to the company page
   * @param securityId
   * @returns {*}
   */
  redirect = (securityId) => {
    const { activeTab, history } = this.props
    return securityId && history.push(`/${activeTab}/${securityId}`)
  }

  /**
   * Close AI targets Modal
   */
  closeAIModal = () => {
    this.setState({
      isAIModalOpen: false
    })
  }

  /**
   * Open info / description Modal
   */
  openInfoModal = () => {
    this.setState({
      isInfoModalOpen: true
    })
  }

  /**
   * Close info / description Modal
   */
  closeInfoModal = () => {
    this.setState({
      isInfoModalOpen: false
    })
  }

  /**
   * Render Entity Type Tabs
   * @param classes
   * @returns {JSX.Element}
   */
  renderEntityTabs = (classes) => {
    const { activeTab } = this.props

    return (
      <ul className={`${classes.name}_tabs`}>
        <li onClick={() => this.handleEntityChange('institution')}
            className={`${classes.name}_tab` + (activeTab === 'institution' ? ` ${classes.name}_tab--active` : ``)}>
          Institution
        </li>
        <li onClick={() => this.handleEntityChange('fund')}
            className={`${classes.name}_tab` + (activeTab === 'fund' ? ` ${classes.name}_tab--active` : ``)}>
          Fund
        </li>
      </ul>
    )
  }

  /**
   * Get formatted dropdown options
   */
  getDropdownOptions = () => {
    const combinedOptions = Object.assign({}, countries, regions)

    return Object.keys(combinedOptions).map((option) => {
      return {
        value: option,
        label: combinedOptions[option]
      }
    })
  }

  /**
   * Render AI Target Modal items
   * @param classes
   * @returns {*}
   */
  getSelectedAiTargets = (classes) => {
    const { targets } = this.state
    const { activeTab } = this.props

    if (!targets.selected.length) {
      return <div className={`${classes.name}_list-item`}>No suggested targets found.</div>
    }

    const items = orderBy(targets.selected, ['value', 'name'], ['desc', 'asc']).map((target, item) => {
      return (
        <li key={`ai-${item}`} className={`${classes.name}_list-item`} onClick={() => {
          this.redirect(target.factset_entity_id)
        }}>
          <span>{target.name}</span>
          <span>{target.value}</span>
        </li>
      )
    })

    return (
      <div className={`${classes.name}_list`}>
        <div className={`${classes.name}_list-header`}>
          <span><i className={`q4i-${activeTab}-4pt`} /> {activeTab}</span>
          <span>AI Score</span>
        </div>
        <Scrollbars
          className='react-scrollbar'
          autoHide
          hideTracksWhenNotNeeded
          renderThumbVertical={renderDarkThumb}
          renderTrackVertical={renderTrackVertical}>
          <ul>
            {items}
          </ul>
        </Scrollbars>
      </div>
    )
  }

  /**
   * Redirect to desktop targeting section
   * @returns {*}
   */
  redirectTargeting = () => {
    const { history } = this.props
    return history.push(TARGETING_URL)
  }

  /**
   * Redirect to desktop targeting section with default query parameters
   * @param e
   */
  onViewAllTargets = (e) => {
    e.preventDefault()
    const { history, activeTab, setTargetingFilter } = this.props
    const { defaultQuery } = this.state
    const query = {
      ...defaultQuery,
      target_type: activeTab
    }
    setTargetingFilter(query)
    history.push({
      pathname: TARGETING_URL,
      state: { blockNavigation: true }
    })
  }

  /**
   * Redirect desktop targeting section for selected location
   * @param location
   */
  handleTargetViewAll = (location) => {
    const { history, activeTab, setTargetingFilter } = this.props
    const { defaultQuery } = this.state
    const query = {
      ...defaultQuery,
      target_type: activeTab,
      location
    }
    setTargetingFilter(query)
    history.push(TARGETING_URL)
  }

  /**
   * Get target data based on country and region
   * @return {*}
   */
  getTargets = () => {
    const { targeting, country, region } = this.props

    if (!targeting) {
      return []
    }

    let location = country && country.toUpperCase()

    if (!country && region) {
      location = 'EU'
    }

    return targeting[location || 'US'] || []
  }

  /**
   * Render
   * @returns {JSX.Element}
   */
  render () {
    const { layout, theme, description, status, country, region } = this.props
    const { isAIModalOpen, isInfoModalOpen, targets } = this.state

    const isFetching = status === FETCHING
    const dropdownOptions = this.getDropdownOptions()

    // override base class after retrieving classes
    const baseClassName = 'ai-targeting-widget'
    const classes = setClassNames(baseClassName, layout, theme)
    classes.base = (layout.w < 2) ? `${baseClassName} ${baseClassName}--${theme} ${baseClassName}--narrow` : classes.base

    const states_countries = getCountryStates(region || country).sort()
    const targeting = this.getTargets()
    const isEmpty = !targeting || !targeting.length
    const prop = region === 'europe' ? 'country' : 'state_province'

    const array = (targeting || []).filter((item) => {

      if (item[prop] && states_countries.indexOf(item[prop]) > -1) {
        return item
      }
      return null
    }).map(item => {
      const targets = item.institutions || item.funds || []
      const items = targets.length

      return {
        code: item[prop] && item[prop].toUpperCase(),
        value: round(item.avg_score, 2),
        totalTargets: item.count,
        availableItems: items,
        targets: targets
      }
    })

    const existingStates = array.map(((state) => state.code)).sort()
    const missingStates = differenceWith(states_countries, existingStates, isEqual)

    if (missingStates) {
      missingStates.forEach((state) => {
        array.push({
          code: state,
          totalTargets: null,
          value: '0',
          availableItems: null,
          targets: []
        })
      })
    }

    return (
      <div className={classes.base}>
        <header className={classes.header}>
          <h2 className={`${classes.name}_title`} onClick={this.redirectTargeting}>AI Targeting</h2>
          <div className={`${classes.name}_filters`}>
            {this.renderEntityTabs(classes)}
            <Dropdown
              className={`${classes.name}_dropdown`}
              theme={theme === 'dark' ? 'slate' : 'pale-grey'}
              width={(layout.w < 2) ? '100%' : 120}
              height={30}
              itemHeight={30}
              value={dropdownOptions.find((option) => option.value === (region || country))}
              options={dropdownOptions}
              optionKey='label'
              onSelect={this.handleDropdownChange}
            />
          </div>
        </header>
        <section className={classes.items}>
          {(!isFetching && isEmpty) ?
            <WidgetError
              theme={theme}
              message={'No targets could be found'}
            /> : (isFetching && isEmpty) ?
              <Spinner /> :
              <AiTargetingMap region={region} country={country} data={array} onMapClick={this.onMapClick} />
          }
        </section>
        <footer className={classes.footer}>
          <span className={`${classes.name}_link`} onClick={this.openInfoModal}>Info</span>
          <Link className={`${classes.name}_link`} onClick={this.onViewAllTargets} to={TARGETING_URL}>View All</Link>
        </footer>

        <Message
          visible={isInfoModalOpen}
          type='info'
          title='AI Targeting'
          message={description}
          renderExitButton={true}
          buttons={[{
            ui: 'citrus',
            label: 'ok',
            onClick: this.closeInfoModal
          }]}
          onClose={this.closeInfoModal}
        />

        <Modal
          visible={isAIModalOpen}
          title={`${targets.state}`}
          subtitle={`Top Targets Avg. AI Score: ${targets.value}`}
          contentHeight={300}
          containerWidth={500}
          contentPadding='0'
          onClose={this.closeAIModal}
          footerButtons={[{
            label: `Cancel`,
            ui: 'shaded',
            onClick: this.closeAIModal
          }, {
            label: `View All`,
            ui: 'citrus',
            onClick: () => this.handleTargetViewAll(targets.metro_area)
          }]}
          spinnerProps={{
            maskOpacity: 1
          }}
        >
          {this.getSelectedAiTargets(classes)}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.dashboard.dashboardState.theme || 'dark',
    securityId: state.dashboard.dashboardState.securityId,
    targeting: state.widget.aiTargeting.data || {},
    activeTab: state.widget.aiTargeting.type,
    status: state.widget.aiTargeting.status,
    region: state.widget.aiTargeting.region,
    country: state.widget.aiTargeting.country,
    profileRegion: state.shared.profile.region
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTargets: bindActionCreators(getAiTargetingByLocation, dispatch),
    setTargetingFilter: bindActionCreators(setTargetingFilter, dispatch),
    resetStatus: bindActionCreators(resetStatus, dispatch),
    setRegion: bindActionCreators(setRegion, dispatch),
    setCountry: bindActionCreators(setCountry, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(aiTargeting))
