import gql from 'graphql-tag'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'

const GET_NEWS = gql`
  query News(
    $tickerId: String!
    $limit: Int! = ${10}
    $sortBy: String
    $sortDir: String
  ) {
    news(
      tickerId: $tickerId
      limit: $limit
      sortBy: $sortBy
      sortDir: $sortDir
    ) {
      items {
        id
        date
        type
        source
        headline
        sanitized
      }
    }
  }`

/**
 * News
 * @param options
 */
export function useNewsQuery (options) {
  return useQuery(GET_NEWS, options)
}

/**
 * News
 * @param options
 */
export function useNewsLazyQuery (options) {
  return useLazyQuery(GET_NEWS, options)
}
