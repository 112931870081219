import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'

import CustomCell from './cell/cell.component'
import { AgGrid, NoContentMessage, PopoverMenu, Spinner } from '../../../../index'
import { setColumnDefinition, preventDefaultRowClick, ENTITY_TYPE, THEMES } from '../../../../../utils'
import { get } from 'lodash'

const { CONTACT } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string,
  pageSizeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  listOptions: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  noFilter: PropTypes.bool.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onBulkAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  loading: false,
  data: [],
  total: 0,
  listOptions: {},
  noFilter: true
}

const columns = [
  {
    field: 'fullName',
    headerName: 'Name',
    type: 'text',
    minWidth: 440,
    maxWidth: 440,
    pinned: 'left',
    lockPinned: true,
    headerCheckboxSelection: true,
    checkboxSelection: true
  },
  {
    field: 'title',
    headerName: 'Title',
    type: 'text',
    minWidth: 220,
    maxWidth: 220
  },
  {
    field: 'function',
    headerName: 'Job Function',
    type: 'text',
    minWidth: 220,
    maxWidth: 220,
    sortable: false
  },
  {
    field: 'location',
    headerName: 'Location',
    type: 'text',
    minWidth: 220,
    maxWidth: 220
  },
  {
    field: 'investmentFocus',
    headerName: 'Investment Focus',
    type: 'text',
    minWidth: 220,
    maxWidth: 220,
    sortable: false
  },
  {
    field: 'current',
    headerName: 'Pos',
    type: 'number',
    minWidth: 140,
    maxWidth: 140
  },
  {
    field: 'phone',
    headerName: 'Phone',
    type: 'text',
    minWidth: 180,
    maxWidth: 180,
    sortable: false
  },
  {
    field: 'email',
    headerName: 'Email',
    type: 'text',
    minWidth: 220,
    maxWidth: 220,
    sortable: false
  },
  {
    field: 'activity',
    headerName: 'Last Activity',
    type: 'date',
    minWidth: 134,
    maxWidth: 134,
    sortable: false
  }]

/**
 * Get sorted field
 * @param field
 * @returns {string|*}
 */
function getSortField (field) {
  switch (field) {
    case 'current':
      return 'contactHoldingCurrentConnection.current'
    case 'activity':
      return 'activityConnection.start'
    default:
      return field
  }
}

/**
 * Contact Table Component
 * @param props
 */
function ContactTable (props) {
  const { dataId, pageSizeId, loading, data, total, listOptions, onQueryChange, onBulkAction, history, onRowClick } = props
  const [agGrid, setAgGrid] = useState({})
  const [popoverMenu, setPopoverMenu] = useState(null)

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click event
   * @param id {String} - contact id
   */
  const handleRowClick = ({ id }) => {
    if (!id) {
      return
    }

    onRowClick
      ? onRowClick({ contactId: id })
      : history && history.push(`/contact/${id}`)
  }

  /**
   * Handle page change
   * @param selected
   */
  const handlePageChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, page: selected }
    })
  }

  /**
   * Handle page size change
   * @param selected
   */
  const handlePageSizeChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, limit: selected, page: 1 }
    })
  }

  /**
   * Handle Column Sort
   * @param grid
   */
  const handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel && !sortModel.length) {
      return
    }

    onQueryChange({
      listOptions: {
        ...listOptions,
        sortBy: getSortField(sortModel[0].colId),
        sortDir: sortModel[0].sort,
        page: 1
      }
    })
  }

  /**
   * Handle Popover Menu click
   * @param event
   * @param popoverMenu
   */
  const handlePopoverClick = (event, popoverMenu) => {
    event.stopPropagation()
    setPopoverMenu(popoverMenu)
  }

  /**
   * Handle Popover Menu close
   * @param event
   */
  const handlePopoverClose = (event) => {
    event.stopPropagation()
    setPopoverMenu(null)
  }

  /**
   * Handle AgGrid bulk action
   */
  const handleBulkAction = () => {
    const selectedRows = agGrid.api && agGrid.api.getSelectedRows()
    const selectedIds = (selectedRows || []).map((row) => row && row.id)

    if (!selectedIds || !selectedIds.length) {
      return
    }

    const entities = selectedRows.map((row) => (row && {
      entityId: row.id,
      entityType: CONTACT,
      institutionId: get(row, 'jobs[0].entityId', null)
    }))

    onBulkAction({ entities })
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell
      {...props}
      onPopoverClick={handlePopoverClick}
    />
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage />
        : <AgGrid
          domLayout='autoHeight'
          sizeToFit

          // suppress configs
          suppressMovableColumns
          suppressContextMenu

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            sortable: true,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={setColumnDefinition({ columns })}
          rowData={data}

          // bulk actions
          bulkActions={[{
            dataId: `${dataId}AddBriefingBook`,
            id: 'contact-add-to-briefing-book',
            icon: 'q4i-book-4pt',
            onSelect: handleBulkAction
          }]}

          // pagination
          pagination
          paginationProps={{
            pageSizeId,
            forcePage: listOptions.page,
            initialPageSize: listOptions.limit,
            showPageSizeSelection: true,
            total,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: renderCustomCell
          }}

          // event listeners
          onGridReady={handleGridReady}
          onGridSizeChanged={handleGridResize}
          onSortChanged={handleSortChange}
          onRowClicked={preventDefaultRowClick(
            handleRowClick,
            ['cell--email', 'expandable-cell']
          )}
          isPinned={data.length} />}

      {/* Popover Menu for Custom Cell */}
      {popoverMenu && (
        <PopoverMenu
          scrollable
          onClose={handlePopoverClose}
          onClick={(event) => event.stopPropagation()}
          {...popoverMenu}
        />
      )}
    </div>
  )
}

ContactTable.propTypes = propTypes
ContactTable.defaultProps = defaultProps

export default memo(ContactTable)
