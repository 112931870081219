import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// actions
import { usePositionQuery } from '../../hook'

import ContactInstitutionComponent from './institution.component'
import { getActiveTicker, getOwnershipType, getStrategy, THEMES } from '../../../../utils'
import { get } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  job: PropTypes.object.isRequired,
  contactPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showEntityOwnershipChart: PropTypes.bool
}

const defaultProps = {
  theme: THEMES.DARK,
  job: {},
  showEntityOwnershipChart: true
}

/**
 * Contact Institution
 * @param props
 */
function ContactInstitution (props) {
  const { dataId, theme, job, contactPosition, strategy, tickerId, ownershipType, showEntityOwnershipChart } = props
  const { value = '13f', label = '13F' } = (ownershipType || {})
  const { entityId } = job

  const { loading: positionLoading, data: position } = usePositionQuery({
    variables: {
      tickerId,
      entityId,
      source: value
    }
  })

  return (
    <ContactInstitutionComponent
      dataId={dataId}
      theme={theme}
      isLoading={positionLoading}
      data={{
        ...job,
        contactPosition,
        position: get(position, 'instHoldingCurrent.items[0].current'),
        strategy: getStrategy(strategy)
      }}
      subscription={label}
      showEntityOwnershipChart={showEntityOwnershipChart}
    />
  )
}

ContactInstitution.propTypes = propTypes
ContactInstitution.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)

  return {
    tickerId: get(ticker, 'q4_ticker_id', '1234567890'), // TODO: tmp default value for pre-IPO
    ownershipType: getOwnershipType(profile)
  }
}

export default connect(mapStateToProps)(ContactInstitution)
