import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { get } from 'lodash'

// components
import {
  EntityAttribute,
  EntityBadge,
  EntityComment,
  EntityDeal,
  EntityHighlight,
  EntityOwnership,
  InstitutionProfile,
  TagInput,
  TopBuys,
  TopSells
} from '../../../components'
import { ENTITY_TYPE } from '../../../utils'

const { INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string.isRequired,
  institution: PropTypes.object.isRequired,
  targetId: PropTypes.string,
  hasActiveDeal: PropTypes.bool,
  deals: PropTypes.shape({
    isLoading: PropTypes.bool,
    deals: PropTypes.array,
    pipeline: PropTypes.array
  }).isRequired,
  onDealSaveSuccess: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
}

const defaultProps = {
  dataIdPrefix: 'InstitutionPage',
  institution: {},
  hasActiveDeal: false,
  deals: {}
}

/**
 * Institution Overview Component
 * @param props
 */
function InstitutionOverview (props) {
  const { dataIdPrefix, institution, targetId, hasActiveDeal, deals, onDealSaveSuccess, isAnalytics, openModal } = props
  const { id, totalAUM, equityAUM, profile, investmentApproach, activist, addressConnection } = institution

  return (
    <div className='overview-layout'>
      <Grid container spacing={3}>
        <EntityBadge
          dataId={dataIdPrefix}
          data={{
            targetId,
            hasActiveDeal,
            activist,
            isAnalytics
          }}
        />
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <EntityAttribute
                dataId={dataIdPrefix}
                entityId={id}
                entityType={INSTITUTION}
                data={{
                  aum: totalAUM,
                  equityAUM
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <EntityHighlight
                dataId={dataIdPrefix}
                entityId={id}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <EntityOwnership
                dataId={dataIdPrefix}
                entityId={id}
              />
            </Grid>

            <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TopBuys
                    dataId={dataIdPrefix}
                    entityId={id}
                    entityType={INSTITUTION}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TopSells
                    dataId={dataIdPrefix}
                    entityId={id}
                    entityType={INSTITUTION}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InstitutionProfile
                dataId={dataIdPrefix}
                profile={profile}
                approach={investmentApproach}
                address={get(addressConnection, 'items', [])}
                openModal={openModal}
              />
            </Grid>

            <Grid item xs={6}>
              <EntityDeal
                dataId={dataIdPrefix}
                entityType={INSTITUTION}
                data={deals}
                onSaveSuccess={onDealSaveSuccess}
              />
            </Grid>

            <Grid item xs={6}>
              <EntityComment
                dataId={dataIdPrefix}
                entity={{
                  entityId: id,
                  entityType: INSTITUTION
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TagInput
            dataId={dataIdPrefix}
            entityId={id}
            entityType={INSTITUTION}
          />
        </Grid>

      </Grid>
    </div>
  )
}

InstitutionOverview.propTypes = propTypes
InstitutionOverview.defaultProps = defaultProps

export default memo(InstitutionOverview)
