import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/react-hooks'
import { usePromiseQuery } from '../../../hook'

const GET_RELATIVE_PERFORMANCE = gql`
query RelativePerformance(
  $tickerId: [String]!
) {
  relativePerformance(
    tickerId: $tickerId
  ) {
    items {
      expectedReturn
      returnOnDate
      stockSpecificReturn
      tickerId
    }
  }
}`

/**
 * Relative Performance
 * @param options
 */
export function useRelativePerformanceQuery (options) {
  return useLazyQuery(GET_RELATIVE_PERFORMANCE, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Relative Performance
 * @param options
 */
export function useRelativePerformancePromiseQuery () {
  return usePromiseQuery(GET_RELATIVE_PERFORMANCE, {
    fetchPolicy: 'no-cache'
  })
}
