import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '../../../../../components'
import { TextInput } from '../../../../../components/shared'

/**
 * Address Book List Create/Edit Component
 */
class AddressBookListEdit extends PureComponent {
  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      name: (props.list && props.list.name) || '',
      error: false,
      errorMessage: ''
    }
  }

  /**
   * On change field value
   * @param event
   */
  onChange (event) {
    const { list } = this.props
    const id = (list && list._id) || null
    const name = (event.target && event.target.value) || ''

    this.setState({ name })
    this.handleValidate(name, id)
  }

  /**
   * On save button click
   */
  onSave () {
    const { name, error } = this.state
    const { list, onClose, onSave } = this.props
    const id = (list && list._id) || null

    if (!(name.trim() && name.trim().length)) {
      this.handleValidate(name, id)
      return
    }

    // name didn't change in edit mode
    if (id && list.name === name) {
      onClose()
      return
    }

    if (!error) {
      onSave({ id, name })
      onClose()
    }
  }

  /**
   * Handle validation
   * @param name
   * @param id - existing list id
   * @private
   */
  handleValidate (name, id) {
    const { lists } = this.props
    const isExist = (lists || []).some((list) => list && list.name === name.trim() && list._id !== id)

    this.setState({
      error: !(name.trim() && name.trim().length) || isExist,
      errorMessage: isExist ? 'List name must be unique.' : ''
    })
  }

  /**
   * Render Address Book List Create Component
   * @returns {*}
   */
  render () {
    const { name, error, errorMessage } = this.state
    const { list, onClose } = this.props

    return (
      <Modal
        visible
        title={`${list && list._id ? 'Edit' : 'Create New'} List`}
        onClose={onClose}
        footerButtons={[
          {
            ui: 'shaded',
            label: 'Cancel',
            onClick: onClose
          },
          {
            ui: 'citrus',
            label: 'Save',
            onClick: this.onSave.bind(this)
          }
        ]}>
        <div className='field field--text field--full'>
          <label className='field_label'>Name <span className='required'>(required)</span></label>
          <TextInput
            id='listName'
            error={error}
            errorMessage={errorMessage}
            value={name}
            placeholder='My New List'
            onChange={this.onChange.bind(this)}
            autoFocus />
        </div>
      </Modal>
    )
  }
}

AddressBookListEdit.propTypes = {
  list: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

AddressBookListEdit.defaultProps = {
  list: {}
}

export default AddressBookListEdit
