import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// actions
import { useTagQuery } from '../../../hook/tagQuery.hook'
import { get } from 'lodash'

import './tag.container.scss'

const propTypes = {
  entityId: PropTypes.string.isRequired
}

const defaultProps = {}

/**
 * Tags Component
 * @param props
 */
function Tag ({ entityId }) {
  const limit = 15

  const { data } = useTagQuery({ variables: { entityId, limit } })
  const tags = get(data, 'tag.items', [])

  return (tags.length
    ? <Grid item xs={12} className='tearsheet_section-content tearsheet_tags'>
      <span className='tearsheet_tags-label'>Tags:</span>
      {(tags || []).map((tag, index) => {
        const { id, name } = (tag || {})
        return (index < limit) &&
          <span key={id} className='tearsheet_tags-tag'>
            #{name}{(index < tags.length - 1) && (index < limit - 1) ? ', ' : ''}
          </span>
      })}
    </Grid> : null
  )
}

Tag.propTypes = propTypes
Tag.defaultProps = defaultProps

export default memo(Tag)
