import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { isMatchingRoute } from '../../utils/routes/routes.util';
import { LEVEL, LEVELS_ORDER } from './persistence.const';

/**
 * Hook for managing persistent state across different levels of navigation (grid tab, subtab, filters).
 * This hook synchronizes state with the URL's query parameters, ensuring values persist between reloads.
 */
export function useGridPersistence({ route, defaultRootTab }) {
  const location = useLocation();
  const prevLocationRef = useRef(location);

  const [storage, setStorage] = useState([]);
  const [currentPath, setCurrentPath] = useState({
    [LEVEL.GRID_TAB]: _getPersistence(LEVEL.GRID_TAB, defaultRootTab),
    [LEVEL.GRID_SUBTAB]: _getPersistence(LEVEL.GRID_SUBTAB, undefined),
    [LEVEL.GRID_FILTERS]: _getPersistence(LEVEL.GRID_FILTERS, undefined),
  });

  // Update the storage when the currentPath changes
  useEffect(() => {
    storeOrUpdateState(currentPath);
    _populateQueryParams();
  }, [currentPath]);

  // Clear query parameters when navigating away from the current route
  useEffect(() => {
    const prevLocation = prevLocationRef.current;
    return () => {
      if (isMatchingRoute(route)) {
        if (prevLocation.pathname !== location.pathname) {
          console.log("Clearing grid query params");
          clearGridQueryParams();
          prevLocationRef.current = location;
        } else {
          // logic here to handle UI navigation to self target
          _populateQueryParams(currentPath);
        }
      } else {
        clearGridQueryParams();
      }
    }
  }, [location, currentPath]);

  /**
   * Stores or updates the state in storage based on L1, L2, and L3 values.
   */
  const storeOrUpdateState = (newPath) => {
    setStorage((prevState) => {
      const existingEntryIndex = prevState.findIndex(
        (entry) => entry[LEVEL.GRID_TAB] === newPath[LEVEL.GRID_TAB]
      );

      if (existingEntryIndex !== -1) {
        const updatedState = [...prevState];
        updatedState[existingEntryIndex] = { ...newPath };
        return updatedState;
      }
      return [...prevState, newPath];
    });
  };

  /**
   * Populates the query parameters in the URL based on the currentPath.
   */
  function _populateQueryParams(overridePath) {
    const path = overridePath || currentPath;
    const url = new URL(window.location.href);
    Object.keys(path).forEach((key) => {
      if (path[key]) {
        url.searchParams.set(key, path[key])
      } else {
        url.searchParams.delete(key)
      }
    });
    window.history.replaceState({}, '', url);
  }

  /**
   * Gets the persisted value or default if no value exists.
   */
  function _getPersistence(level, defaultValue) {
    if (!isMatchingRoute(route)) return defaultValue;
    const url = new URL(window.location.href);
    return url.searchParams.get(level) || defaultValue
  }

  /**
   * Sets a value in both the URL and the currentPath.
   */
  function _setGridPersistence(level, value) {
    if (!isMatchingRoute(route)) return;

    const url = new URL(window.location.href);
    url.searchParams.set(level, value);
    _resetLowerLevels(level, url); // Reset lower levels if needed

    setCurrentPath((prevPath) => ({
      ...prevPath,
      [level]: value,
    }));

    window.history.replaceState({}, '', url);
  }

  /**
   * Resets lower levels when a higher-level grid query parameter is updated.
   */
  function _resetLowerLevels(level, url) {
    const levelIndex = LEVELS_ORDER.indexOf(level);
    if (levelIndex >= 0) {
      for (let i = levelIndex + 1; i < LEVELS_ORDER.length; i++) {
        url.searchParams.delete(LEVELS_ORDER[i]);
        setCurrentPath((prevPath) => ({
          ...prevPath,
          [LEVELS_ORDER[i]]: undefined,
        }));
      }
    }
  }

  /**
   * Retrieves and sets the grid tab from the storage or updates the currentPath.
   */
  function setGridTab(value) {
    const existingEntry = storage.find(
      (entry) => entry[LEVEL.GRID_TAB] === value
    );

    if (existingEntry) {
      setCurrentPath(existingEntry);
    } else {
      _setGridPersistence(LEVEL.GRID_TAB, value);
    }
  }

  /**
   * Retrieves and sets the grid sub-tab from the storage or updates the currentPath.
   */
  function setGridSubTab(value) {
    const existingEntry = storage.find(
      (entry) =>
        entry[LEVEL.GRID_TAB] === currentPath[LEVEL.GRID_TAB] &&
        entry[LEVEL.GRID_SUBTAB] === value
    );

    if (existingEntry) {
      setCurrentPath(existingEntry);
    } else {
      _setGridPersistence(LEVEL.GRID_SUBTAB, value);
    }
  }

  /**
   * Retrieves the grid filters from the storage or returns the default value.
   */
  function getGridFiltersDecoded(defaultValue) {
    const value = _getPersistence(LEVEL.GRID_FILTERS, undefined);
    if (value) {
      return JSON.parse(decodeURIComponent(value))
    }
    return defaultValue;
  }

  /**
   * Retrieves and sets the grid filters from the storage or updates the currentPath.
   */
  function setGridFilters(value) {
    const formattedValue = encodeURIComponent(JSON.stringify(value));

    const existingEntry = storage.find(
      (entry) => {
        const entryFilters = encodeURIComponent(entry[LEVEL.GRID_FILTERS]);
        return entry[LEVEL.GRID_TAB] === currentPath[LEVEL.GRID_TAB] &&
          entry[LEVEL.GRID_SUBTAB] === currentPath[LEVEL.GRID_SUBTAB] &&
          entryFilters === formattedValue
      }
    );

    if (existingEntry) {
      setCurrentPath(existingEntry);
    } else {
      _setGridPersistence(LEVEL.GRID_FILTERS, formattedValue);
    }
  }

  /**
   * Clears the grid-related query parameters from the URL.
   */
  function clearGridQueryParams() {
    const url = new URL(window.location.href);
    LEVELS_ORDER.forEach((level) => url.searchParams.delete(level));
    window.history.replaceState({}, '', url);
  }

  return {
    getGridWithDefault: (level, defaultValue) => _getPersistence(level, defaultValue),
    getGridFiltersDecoded,
    setGridTab,
    setGridSubTab,
    setGridFilters,
  };
}
