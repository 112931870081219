import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

/**
 * Single Event Action Type Constants
 * @type {string}
 */
export const GET_EVENT_MODAL_REQUEST = 'GET_EVENT_MODAL_REQUEST'
export const GET_EVENT_MODAL_SUCCESS = 'GET_EVENT_MODAL_SUCCESS'
export const GET_EVENT_MODAL_FAILURE = 'GET_EVENT_MODAL_FAILURE'

/**
 * Get Event
 * Returns single event by id
 * @param id
 */
const _getEventModal = (id) => ({
  [CALL_API]: {
    types: [
      GET_EVENT_MODAL_REQUEST,
      GET_EVENT_MODAL_SUCCESS,
      GET_EVENT_MODAL_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/event/${id}`
  }
})

/**
 * Dispatch GET_EVENT_MODAL_REQUEST
 * @param id
 */
export const getEventModal = (id) => (dispatch) => {
  dispatch(_getEventModal(id))
}
