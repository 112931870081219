import {
  CRM_EMAIL_APP_MOUNTED,
  SET_RECIPIENTS
} from '../../actions'

const initial = {
  mounted: false,
  recipients: []
}

const crmEmail = (state = initial, action) => {
  switch (action.type) {
    case CRM_EMAIL_APP_MOUNTED:
      return {
        ...state,
        mounted: action.payload
      }
    case SET_RECIPIENTS:
      return {
        ...state,
        recipients: action.payload
      }
    default:
      return state
  }
}

export default crmEmail
