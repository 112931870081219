const Categories = [
  {
    type: 'market',
    label: 'Market & Stock Data',
    icon: 'chart',
    region: [
      'north_america',
      'south_america',
      'europe',
      'asia',
      'middle_east'
    ]
  },
  {
    type: 'commentary',
    label: 'Trading Commentary',
    icon: 'chat',
    region: [
      'north_america',
      'south_america',
      'europe',
      'asia',
      'middle_east'
    ]
  },
  {
    type: 'alert',
    label: 'Alerts',
    icon: 'notifications',
    region: [
      'north_america',
      'south_america',
      'europe',
      'asia',
      'middle_east'
    ]
  },
  {
    type: 'crm',
    label: 'CRM',
    icon: 'activity',
    region: [
      'north_america',
      'south_america',
      'europe',
      'asia',
      'middle_east'
    ]
  },
  {
    type: 'targeting',
    label: 'Targeting',
    icon: 'targeting',
    region: [
      'north_america',
      'europe'
    ]
  },
  {
    type: 'ownership',
    label: 'Ownership',
    icon: 'ownership',
    region: [
      'north_america',
      'south_america',
      'europe',
      'asia',
      'middle_east'
    ]
  },
  {
    type: 'analytics',
    label: 'Analytics',
    icon: 'website-analytics',
    region: [
      'north_america',
      'south_america',
      'europe',
      'asia',
      'middle_east'
    ]
  },
  {
    type: 'utilities',
    label: 'Utilities',
    icon: 'time',
    region: [
      'north_america',
      'south_america',
      'europe',
      'asia',
      'middle_east'
    ]
  },
  {
    type: 'estimates',
    label: 'Estimates',
    icon: 'estimates-research',
    region: [
      'north_america',
      'south_america',
      'europe',
      'asia',
      'middle_east'
    ]
  }
]

export default Categories
