import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { addQueryParams } from '../../../utils/http/http.util'
import { fetchProfile } from '../../shared'

export const FETCH_NEWS_REQUEST = 'FETCH_NEWS_REQUEST'
export const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS'
export const FETCH_NEWS_ERROR = 'FETCH_NEWS_ERROR'
export const RESET_NEWS_STATUS = 'RESET_NEWS_STATUS'
export const SET_REQUEST_PARAMETERS_IN_NEWS = 'SET_REQUEST_PARAMETERS_IN_NEWS'

export const FETCHING = 'FETCHING'
export const IDLE = 'IDLE'
export const FETCHED = 'FETCHED'
export const FAILED = 'FAILED'

const resources = {
  news: '/news'
}

const _fetchNews = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_NEWS_REQUEST,
      FETCH_NEWS_SUCCESS,
      FETCH_NEWS_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

const setParameters = (payload) => ({
  type: SET_REQUEST_PARAMETERS_IN_NEWS,
  payload
})

export const getNews = (params) => (dispatch, getState) => {
  const state = getState()
  params = params || {}
  const securityId = params.securityId || state.dashboard.dashboardState.securityId
  params = { ...params, securityId }

  dispatch(fetchProfile()).then(() => {
    const url = addQueryParams(resources.news, params)
    dispatch(setParameters(params))
    return dispatch(_fetchNews(url))
  })
}

export const resetStatus = () => ({
  type: RESET_NEWS_STATUS
})
