import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Tooltip } from '@material-ui/core'

import './tooltip.component.css'

const styles = {}

/**
 * NavTooltip Component
 */
class NavTooltip extends Component {
  /**
     * Render
     * @return {XML}
     */
  render () {
    const { title, isNew, openInNewTab, children } = this.props

    return (
      <Tooltip
        classes={{
          popper: 'nav-tooltip',
          tooltip: `nav-tooltip_item ${isNew ? 'nav-tooltip_item--new' : ''}`
        }}
        title={openInNewTab ? `${title} - OPENS IN NEW TAB` : title}
        placement='right'
      >
        {children}
      </Tooltip>
    )
  };
}

export default withStyles(styles)(NavTooltip)
