import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

export const GET_CONTACT_POSITION_REQUEST = 'GET_CONTACT_POSITION_REQUEST'
export const GET_CONTACT_POSITION_SUCCESS = 'GET_CONTACT_POSITION_SUCCESS'
export const GET_CONTACT_POSITION_FAILURE = 'GET_CONTACT_POSITION_FAILURE'

export const RESET_CONTACT_POSITION = 'RESET_CONTACT_POSITION'

/**
 * Get Contact Position
 * @param params.contactId
 * @param params.securityId
 * @param options
 * @returns {{}}
 * @private
 */
const _getContactPosition = ({ contactId, securityId }, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_CONTACT_POSITION_REQUEST,
      GET_CONTACT_POSITION_SUCCESS,
      GET_CONTACT_POSITION_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/ownership/v2/contact/${contactId}/position`,
    payload: {
      securityId
    }
  }
})

/**
 * Dispatch GET_CONTACT_POSITION_REQUEST
 * @param params
 * @param options
 */
export const getContactPosition = (params, options) => (dispatch) => {
  return dispatch(_getContactPosition(params, options))
}

/**
 * Dispatch RESET_CONTACT_POSITION
 */
export const clearPosition = () => (dispatch) => {
  return dispatch({ type: RESET_CONTACT_POSITION })
}
