import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { config } from '../../../../config'
import queryString from 'query-string'

// actions
import {
  openModal,
  closeModal,
  statusType
} from '../../../../actions'
import PeerAnalysisToolbar from './toolbar/toolbar.component'
import {
  cleanQueryParams,
  getActiveTicker,
  getLocalStorageItem,
  getPageSizeFromStorage,
  THEMES
} from '../../../../utils'
import './grid.container.css'
import PeerAnalysisTable from './table/table.component'
import { fetchPeerAnalysis } from '../../../../actions/ownership/peerAnalysis/peerAnalysis.action'
import { Spinner } from '../../../index'
import { loadPeers, resetAutoCompletePeers } from '../../../../actions/peerConfig/peerConfig.actions'
import { getActiveToken } from '../../../../utils/auth/auth.util'

const PAGE_SIZE_ID = 'peer-analysis-grid'
/**
 * PeerAnalysisGrid
 */

const propTypes = {
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  entityId: PropTypes.string.isRequired
}

const defaultProps = {
  toolTheme: THEMES.INK,
  toolbarTheme: THEMES.Q4_BLUE
}

/**
 * @deprecated
 */
class PeerAnalysisGridOld extends PureComponent {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.initialFilters = {
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10,
      page: 1,
      holder_type: 'institution',
      start: 0,
      quarters: 4,
      sort: { property: 'current', direction: 'DESC' },
      metric: 'current',
      isChange: false
    }

    this.state = {
      filters: this.initialFilters,
      peerData: props.peerAnalysis.data,
      peers: []
    }
  }

  componentDidMount () {
    const peers  = getLocalStorageItem('peers')
    if (peers?.length) {
      this.fetchPeerAnalysis()
    } else {
      this.fetchDefaultPeers()
    }
  }

  componentDidUpdate (prevProps) {
    const { peerAnalysis, entityId } = this.props
    if (peerAnalysis.data !== prevProps.peerAnalysis.data) {
      this.setState({
        peerData: (peerAnalysis.data || []).map(item => {
          return {
            ...item,
            isChange: this.state.filters.isChange
          }
        })
      })
    }
    if(entityId !== prevProps.entityId){
      this.fetchPeerAnalysis()
    }
  }
  /**
   * Get query params for peer analysis
   * @param params
   * @returns {Promise<{}>}
   */
  getQueryParams = async (params = {}) => {
    const { filters } = this.state
    const { securityId, token } = this.props
    const queryParams = cleanQueryParams(filters)
    
    const exportToken = await getActiveToken(token)
  
    const peers = this.loadPeers()
    // If there are no peers, then at least have the current security id in the list of peers
    if(!peers || !peers.length) {
      peers.push(this.props.securityId)
    }

    if (queryParams.sort) {
      queryParams.sort = JSON.stringify([queryParams.sort])
    }

    if (params.export && exportToken) {
      delete queryParams.page
      delete queryParams.limit
      queryParams.token = exportToken
    }
    return {
      ...queryParams,
      securityId,
      metric: filters.metric,
      peer: peers
    }
  }

  async fetchPeerAnalysis () {
    const query = await this.getQueryParams()
    const { fetchPeerAnalysis, entityId, securityId } = this.props
    entityId && securityId && fetchPeerAnalysis(entityId, query)
  }

  /**
   * Filter Peer Analysis based on changes in filter state params
   * @param filters
   */
  handleFilterChange = (filters) => {
    this.setState({
      filters: {
        ...this.state.filters,
        ...filters
      }
    }, () => this.fetchPeerAnalysis())
  }

  /**
   * Fetch default Peers when user open peers analysis tab for the first time
   */
  fetchDefaultPeers = () => {
    const { loadPeers, resetAutoCompletePeers } = this.props
    const peerParams = {
      select: ['symbol', 'exchange', 'security_name'],
      sortField: 'Symbol',
      sortOrder: 1,
      limit: 20,
      excludeMyCompany: true
    }

    loadPeers(peerParams)
      .then(peerData => {
        const peerList = (peerData.payload || []).map((peer) => {
          return peer._security
        })
        this.setState({
          peers: peerList
        })
      }).then(() => this.fetchPeerAnalysis())
    resetAutoCompletePeers()
  }
  /**
   * Open create new peer analysis modal
   * @param filters
   */
  handlePeersModal = () => {
    const { openModal } = this.props
    openModal({
      type: 'PEER_CONFIG_MODAL',
      props: {
        rememberSelectionKey: 'peers',
        onSave: this.handleSavePeer
      }
    })
  }

  handleSavePeer = () => {
    this.fetchPeerAnalysis()
  }

  /**
   * Handle Peer Analysis csv export
   */
  handleExport = async () => {
    const { entityId } = this.props
    const query = await this.getQueryParams({ export: true })
    const downloadUrl = `${config.apiUrl}/ownership/v2/institution/${entityId}/peers/export?${queryString.stringify(query)}`
    window.open(downloadUrl, '_self')
  }

  loadPeers = () => {
    const peers = JSON.parse(localStorage.getItem('peers'))
    if (!peers) {
      return this.state.peers
    }
    return (peers || []).map((peer) => {
      return peer._security
    })
  }

  /**
   * Render Peer Analysis
   */
  render () {
    const { toolbarTheme, toolTheme, peerAnalysis, total, status, history } = this.props
    const { filters, peerData } = this.state

    return (
      <div className='peer-analysis-grid'>
        {(status === statusType.IN_PROGRESS) && (
          <Spinner mask theme={THEMES.RAIN}/>
        )}
        <PeerAnalysisToolbar
          toolbarTheme={toolbarTheme}
          toolTheme={toolTheme}
          metric={filters.metric}
          noData={!peerAnalysis.data || !peerAnalysis.data.length}
          handlePeersModal={this.handlePeersModal}
          handleFilterChange={this.handleFilterChange}
          handleExport={this.handleExport}/>
        <PeerAnalysisTable
          pageSizeId={PAGE_SIZE_ID}
          dataTotal={total}
          data={peerData}
          query={{
            page: filters.page,
            limit: filters.limit
          }}
          history={history}
          handleQueryChange={this.handleFilterChange}
        />
      </div>
    )
  }
}

PeerAnalysisGridOld.propTypes = propTypes
PeerAnalysisGridOld.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = state.profile
  const peerAnalysis = state.ownership.peerAnalysis.peerAnalysis
  const ticker = getActiveTicker(profile.data)
  const securityId = ticker && ticker._security
  return {
    token: state.token,
    peerAnalysis: peerAnalysis,
    status: peerAnalysis.status,

    total: peerAnalysis.total,
    securityId
  }
}
const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
  loadPeers: bindActionCreators(loadPeers, dispatch),
  resetAutoCompletePeers: bindActionCreators(resetAutoCompletePeers, dispatch),
  fetchPeerAnalysis: bindActionCreators(fetchPeerAnalysis, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PeerAnalysisGridOld))

