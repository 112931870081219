import {
  GET_FUND_CURRENT_FOR_INSTITUTION_FAILURE,
  GET_FUND_CURRENT_FOR_INSTITUTION_REQUEST,
  GET_FUND_CURRENT_FOR_INSTITUTION_SUCCESS,
  statusType
} from '../../../actions'

const initial = {
  data: [],
  total: 0,
  status: statusType.IDLE
}

const fundCurrentReducer = (state = initial, action) => {
  switch (action.type) {
    case GET_FUND_CURRENT_FOR_INSTITUTION_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }
    case GET_FUND_CURRENT_FOR_INSTITUTION_SUCCESS:
      return {
        ...state,
        data: (action.payload || []).map((fund) => {
          return {
            ...fund,
            lastActivity: fund.lastActivity && fund.lastActivity.date,
            // normalize property to be compatible with the rest of the fund pages
            ai_score: fund.aiScore,
            entityType: 'fund'
          }
        }),
        total: action.meta.total,
        status: statusType.SUCCESS
      }
    case GET_FUND_CURRENT_FOR_INSTITUTION_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }
    default:
      return state
  }
}

export default fundCurrentReducer
