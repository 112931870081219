import React from 'react'
import PropTypes from 'prop-types'
import { isUndefined, keys } from 'lodash'
import { Card } from '../../../../../../../components'
import {
  // format,
  abbreviate
} from '../../../../../../../utils/number.util'
import { THEMES } from '../../../../../../../utils/ui'
import { getEaIntegrationEnabledFlag } from '../../../../../../../utils'

import './highlights.component.css'


const HighlightsComponent = ({ entity, isLoading }) => {
  const {
    entityType,
    analytics,
    webcast,
    ownership
  } = entity

  const {
    peerCount,
    peersTotalMarketValue,
    peerAveragePosition
    // changePctInGroupQtr
    // sectorChangePtc
    // capGroup,
    // sector
  } = ownership || {}

  // const chgPct = (changePctInGroupQtr === 0) ? 'change' : changePctInGroupQtr < 0 ? 'decrease' : 'increase'
  // const sectorChgPct = (sectorChangePtc === 0) ? 'change' : sectorChangePtc < 0 ? 'decrease' : 'increase'

  const webcastVisits = keys(webcast).map((email) => webcast[email])
  const baseName = 'highlights'

  let webcastText = 'Have not attended a Webcast in the last year'
  if (webcastVisits.length && webcastVisits[0] > 0) {
    webcastText = `Attended Webcasts ${webcastVisits[0]} time${webcastVisits[0] > 1 ? 's' : ''} in the last year`
    if (getEaIntegrationEnabledFlag()) {
      webcastText = 'Attended webcast in the last year'
    }
  }

  let webAnalyticsText = 'Not a known website visitor in the last 90 days'
  if (analytics && analytics.sessions && analytics.sessions > 0) {
    webAnalyticsText = `Visited Website ${analytics.sessions} times in the last 90 days`
    if (getEaIntegrationEnabledFlag()) {
      webAnalyticsText = 'Visited website in the last 90 days'
    }
  }

  return (
    <Card
      theme={THEMES.LIGHT}
      title={`${entityType} ${baseName}`}
      isLoading={isLoading}
    >
      <ul>
        <li className={baseName}>
          <p>
            <span>{peerCount > 0 ? `Holds ${peerCount} ${peerCount > 1 ? 'peers' : 'peer'} valued at ${abbreviate(peersTotalMarketValue, 1)}`
              : 'Does not hold any peers'}
            </span>
          </p>
        </li>
        {peerAveragePosition > 0 && <li className={baseName}>
          <p>
            <span>{`Holds an average position of ${abbreviate(peerAveragePosition, 1)} in your peer group`}</span>
          </p>
        </li>}
        {/* {!isUndefined(ownership) && peerCount > 0 && <li className={baseName}>
          <p>
            <span className={`${baseName}_change-ptc--${chgPct}`}>
              {format(changePctInGroupQtr, 2, null, true)}%
            </span>
            {chgPct} in your peer group this quarter
          </p>
        </li>} */}
        {/* {!isUndefined(ownership) && <li className={baseName}>
          <p>
            <span className={`${baseName}_change-ptc--${sectorChgPct}`}>
              {format(sectorChangePtc, 2, null, true)}%
            </span>
            {sectorChgPct} in {`${capGroup} - ${sector}`} this quarter
          </p>
        </li>} */}
        {!isUndefined(analytics) && <li className={baseName}>
          <p>
            <span>{webAnalyticsText}</span>
          </p>
        </li>}
        {(!isUndefined(webcast)) && <li className={baseName}>
          <p>
            <span>{webcastText}</span>
          </p>
        </li>}

      </ul>
    </Card>
  )
}

HighlightsComponent.propTypes = {
  label: PropTypes.string.isRequired,
  entity: PropTypes.shape({
    entityType: PropTypes.oneOf(['institution', 'fund', 'contact']).isRequired,
    ownership: PropTypes.shape({
      peersTotalMarketValue: PropTypes.number,
      peerAveragePosition: PropTypes.number,
      changePctInGroupQtr: PropTypes.number,
      peerCount: PropTypes.number,
      sectorChangePtc: PropTypes.number,
      sector: PropTypes.string,
      capGroup: PropTypes.string
    }),
    analytics: PropTypes.object,
    webcast: PropTypes.object
  }),
  isLoading: PropTypes.bool.isRequired
}

HighlightsComponent.defaultProps = {
  label: 'Highlights',
  isLoading: false
}

export default HighlightsComponent
