import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route } from 'react-router-dom'
import history from './utils/history'
import ExportRoot from './containers/export/export.container'
import configureStore from './store/configure.store'
import { ApolloProvider } from '@apollo/react-hooks'
import { exportClient } from './middleware/graphql.middleware'

async function init () {
  const store = await configureStore()

  render(
    <ApolloProvider client={exportClient}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Route>
            <ExportRoot />
          </Route>
        </ConnectedRouter>
      </Provider>
    </ApolloProvider>,
    document.getElementById('root')
  )
}

init()
