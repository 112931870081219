import { cherry, lightGrey, q4Blue, softGrey, teal, white, black } from '../resources/materialui-overrides/colors'
import { format, formatDate, DEFAULT_DATE_FORMAT } from './index'

/**
 * Get default headerClass for Ag-grid column definitions
 * @param columnIndex
 * @param type
 * @param isAlternating
 * @returns {string}
 */
export const getDefaultHeaderClasses = (columnIndex, type, isAlternating) => {
  let className = ''

  if (isAlternating) {
    if (columnIndex % 2 !== 0) {
      className += 'ag-header-cell--even'
    } else {
      className += 'ag-header-cell--odd'
    }
  }

  if (columnIndex === 0) {
    className += ' ag-header-cell--first'
  }

  if (type && ['change', 'number'].includes(type)) {
    className += ' ag-numeric-header'
  }

  if (type && ['boolean', 'date', 'icon', 'centered'].includes(type)) {
    className += ' ag-header-cell--centered'
  }

  return className
}

/**
 * Get default cellClassRules for Ag-grid column definitions
 * @param columnIndex
 * @param type
 * @param isAlternating
 * @returns {*}
 */
export const getDefaultCellClassRules = (columnIndex, type, isAlternating = true) => {
  const rules = {
    'ag-column': () => true,
    'ag-column--first': () => columnIndex === 0,
    'ag-column--centered': () => type && ['boolean', 'date', 'icon', 'centered'].includes(type),
    'ag-column--number': () => type && ['change', 'number'].includes(type),
    'ag-column--date': () => type && type === 'date',
    'ag-column--text': () => type && type === 'text',
    'ag-column--flexed': () => type && type === 'flexed'
  }

  if (isAlternating) {
    rules['ag-column--odd'] = () => columnIndex % 2 === 0
    rules['ag-column--even'] = () => columnIndex % 2 !== 0
  }

  if (type && type === 'change') {
    rules['ag-cell-value--increase'] = (params) => params.value > 0
    rules['ag-cell-value--decrease'] = (params) => params.value < 0
  }

  return rules
}

/**
 * Get Default Column Definition properties
 * @param columnIndex
 * @param type
 * @param field
 * @param isAlternating
 */
export const getDefaultColumnDef = ({ columnIndex = 0, type = '', isAlternating = true }) => {
  return {
    headerClass: getDefaultHeaderClasses(columnIndex, type, isAlternating),
    cellClassRules: getDefaultCellClassRules(columnIndex, type, isAlternating),
    comparator: () => {}, // empty sorting comparator to ignore client sort
    suppressMenu: true,
    suppressMovable: true
  }
}

/**
 * Set Column Definition
 * @param props
 */
export function setColumnDefinition ({ columns, isLocked = true, isAlternating = true }) {
  return (columns || []).map(({ pivotIndex, type, ...column }, idx) => ({
    ...getDefaultColumnDef({ columnIndex: isLocked ? (idx > 0 ? idx + 1 : idx) : idx, type, isAlternating }),
    ...column
  }))
}

/**
 * Get Default rowClassRules for Ag-grid
 * @returns {{String: (function(*))}}
 */
export const getDefaultRowClassRules = () => {
  return {
    'ag-row--grouped': (params = {}) => {
      return params.data && params.data.groupedRows && params.data.groupedRows.length
    }
  }
}

/**
 * Get Ag-Grid valueFormatter function for column definitions
 * TODO: combine with getValueFormatter
 * @param type
 */
export const getDefaultValueFormatter = (type) => {
  const renderFunctionMap = {
    boolean: (params) => {
      return typeof params.value === 'boolean' ? params.value ? 'Yes' : 'No' : ''
    },
    number: (params) => {
      return (Number(params.value) === params.value && params.value % 1 === 0)
        ? format(params.value, 0)
        : format(params.value, 2)
    },
    date: (params) => {
      const isUTC = true
      return params.value ? formatDate(params.value, DEFAULT_DATE_FORMAT, isUTC) : ''
    }
  }

  return renderFunctionMap[type] || null
}

/**
 * Get column width based on filter type
 * @param type
 * @returns {number}
 */
export const getDefaultColumnWidth = (type) => {
  switch (type) {
    case 'string':
      return 270
    case 'choice':
      return 180
    case 'number':
    case 'date':
    case 'boolean':
    case 'text':
    default:
      return 120
  }
}

/**
 * Default style for agGrid Export
 * @returns {*}
 */
export const getDefaultExcelStyles = () => {
  return [
    {
      id: 'title-row',
      font: {
        color: white,
        size: 20
      },
      interior: {
        color: q4Blue,
        pattern: 'Solid'
      }
    },
    {
      id: 'subtitle-row',
      font: {
        color: black,
        bold: true
      },
      interior: {
        color: white,
        pattern: 'Solid'
      }
    },
    {
      id: 'blue-row',
      font: {
        color: white
      },
      interior: {
        color: q4Blue,
        pattern: 'Solid'
      }
    },
    {
      id: 'clear-row',
      interior: {
        color: white,
        pattern: 'Solid'
      }
    },
    {
      id: 'header',
      interior: {
        color: lightGrey,
        pattern: 'Solid'
      },
      borders: {
        borderBottom: {
          lineStyle: 'Continuous',
          weight: 1,
          color: softGrey
        },
        borderLeft: {
          lineStyle: 'Continuous',
          weight: 1,
          color: softGrey
        },
        borderTop: {
          lineStyle: 'Continuous',
          weight: 1,
          color: softGrey
        },
        borderRight: {
          lineStyle: 'Continuous',
          weight: 1,
          color: softGrey
        }
      }
    },
    {
      id: 'ag-cell-value--increase',
      font: {
        color: teal
      }
    },
    {
      id: 'ag-cell-value--decrease',
      font: {
        color: cherry
      }
    },
    {
      id: 'ag-column--centered',
      alignment: {
        horizontal: 'Center'
      }
    },
    {
      id: 'ag-column--date',
      dataType: 'dateTime',
      numberFormat: {
        format: 'mm-dd-yyyy'
      }
    },
    {
      id: 'ag-numeric-cell',
      numberFormat: {
        format: '#,##0'
      }
    },
    {
      id: 'ag-cell--decimal',
      numberFormat: {
        format: '0.00'
      }
    }]
}

/**
 * Constructs path using PointerEvent target API.
 * @param event {PointerEvent} - IE11 PointerEvent
 * @param limit {Number} - number of classes in path
 * @return {Array}
 * @private
 */
const _buildTargetPath = (event, limit = 5) => {
  const path = []
  let node = event.target
  let counter = 0

  while (node && node.className && counter < limit) {
    path.push(node)
    node = node.parentNode
    counter++
  }

  return path
}

/**
 * Handle row click event
 * Contains logic to fix AgGrid event propagation and IE11 PointerEvent API.
 * @see { @link: https://github.com/ag-grid/ag-grid/issues/2224 } for further information.
 *
 * @param onRowClick {Function} - callback with on row click logic
 * @param customClickItems {Array} - array of classNames
 */
export const preventDefaultRowClick = (onRowClick, customClickItems) => ({ data = {}, event }) => {
  let path = event.path

  // IE11 doesn't have path in event
  if (!path) {
    path = _buildTargetPath(event)
  }

  // format classNames in path
  path = path
    .map((target) => target.className)
    .filter((target) => target)
    .reduce((acc, curr) => {
      acc.push(...curr.split(' ').map((target) => target.trim()))
      return acc
    }, [])

  if (customClickItems.some((customClickItem) => path.find((target) => target === customClickItem))) {
    return
  }

  onRowClick && onRowClick(data)
}

/**
 * Matches label from the list of objects by value
 * @param {{label,value}[]} objectsArray 
 * @param {string} value 
 * @returns {string} label if found, value otherwise 
 */
export const getLabelByValue = (objectsArray, value) => {
  const holder = objectsArray.find(h => h.value === value);
  return holder?.label || value;
};