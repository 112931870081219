import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {withFormik} from 'formik';
import { Message, Modal } from '../../../../../components'
import {TextArea} from '../../../../shared';
import './activityAttendeesRequestProfile.component.css';
import {bindActionCreators} from 'redux';
import {sendMail} from '../../../../../actions/shared';
import {createToast} from '../../../../../actions/toast';

/**
 * Request Profile Component
 */
class ActivityAttendeeRequestProfile extends Component {

  /**
   * Close send request error modal
   */
  closeModal = () => {
    const {setStatus} = this.props;
    setStatus(null);
  };

  /**
   * Render Modal
   * @returns {*}
   */
  render() {
    const {status, handleSubmit, handleChange, isSubmitting, onClose, values, errors} = this.props;

    return (
      <div className='request-profile-modal'>
        <Modal visible={true}
               title='Request Profile'
               contentHeight={161}
               onClose={onClose}
               footerButtons={[{
                 label: 'Cancel',
                 ui: 'shaded',
                 onClick: onClose
               }, {
                 label: 'Save',
                 ui: 'citrus',
                 disabled: isSubmitting,
                 onClick: handleSubmit
               }]}>
          <form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
            <TextArea
              id='message'
              placeholder='Message* (max 700 characters)'
              onChange={handleChange}
              value={values.message}
              error={{ message: errors.message }}
              maxLength={700}
            />
          </form>
        </Modal>
        <Message visible={(status && !status.success)}
                 type='error'
                 title='Error'
                 message='Oops, something went wrong while trying to send this request. Please try again or contact us if you see this message again.'
                 onClose={this.closeModal}
                 buttons={[{
                   ui: 'spice',
                   label: 'close',
                   onClick: this.closeModal
                 }]}/>
      </div>
    );
  }
}

const formikParams = {
  mapPropsToValues: () => ({
    message: ''
  }),
  validationSchema: Yup.object().shape({
    message: Yup.string().trim().required('Please provide a message.')
  }),
  handleSubmit: (values, {props, setStatus, setSubmitting}) => {
    const {message} = values;
    const {sendMail, onClose, createToast} = props;

    sendMail({
      message,
      template: 'iris-content-coverage',
      subject: 'Content Request',
      to: 'desktop@q4inc.com'
    }).then((response) => {

      switch (response.type) {
        case 'MAIL_SUCCESS':
          setSubmitting(false);
          setStatus({success: true});
          createToast({text: 'Request has been successfully sent.'});
          onClose();
          break;

        case 'MAIL_ERROR':
          setSubmitting(false);
          setStatus({success: false});
          break;

        default:
          break;
      }
    });
  },
  displayName: 'RequestProfileForm'
};

/**
 * Maps App Store State to Container's Props
 * @param state - store state
 */
const mapStateToProps = (state) => ({
  shared: state.shared,
  toast: state.toast
});

const mapDispatchToProps = (dispatch) => ({
  sendMail: bindActionCreators(sendMail, dispatch),
  createToast: bindActionCreators(createToast, dispatch),
});

ActivityAttendeeRequestProfile.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withFormik(formikParams)(ActivityAttendeeRequestProfile));
