import { isEmpty } from "lodash";

/**
 * 
 * @param {string} route 
 * @param  {...{ label: string, value: string }} params 
 * @returns {string}
 */
export function mapRoutePath(route, ...params) {
  if (isEmpty(params)) return null;

  return params.reduce((route, param) => {
    return isEmpty(param) ? route : route.replace(param.label, param.value);
  }, route ?? "");
}

export function isMatchingRoute(route) {
  const path = window.location.pathname;

  // Replace dynamic variables like ${ID}, ${DAYS}, ${CODE} and also :id, :days, :code with a simple wildcard
  const simplifiedRoute = route
    .replace(/\${[^}]+}/g, '[^/]+') // Handles ${ID}, ${DAYS}, ${CODE}
    .replace(/:id/g, '[^/]+')       // Handles :id
    .replace(/:days/g, '[^/]+')     // Handles :days
    .replace(/:code/g, '[^/]+');    // Handles :code

  // Create a RegExp object that matches the static parts of the route and ignores dynamic segments
  const regex = new RegExp(`^${simplifiedRoute}$`);
  const isMatch = regex.test(path);
  return isMatch;
}
