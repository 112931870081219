import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Sidebar, Spinner, SectionTab } from '../../../../components'
import { THEMES } from '../../../../utils'
import Filter from './filter/filter.component'
import ContactLists from './contactLists/contactLists.component'

import './sidebar.component.css'

const CONTACT_PAGE_TABS = {
  FILTERS: 'Filters',
  CONTACT_LIST: 'Contact List'
}

/**
 * Render Tab Navigation
 * @param {string} activeTab
 * @param {string[]} tabs
 * @param {function} handleTabChange
 * @returns {Array}
 */
const TabNavigation = ({tabs, activeTab, handleTabChange }) =>
  <SectionTab
    theme={THEMES.RAIN}
    onChange={handleTabChange}
    activeTab={activeTab}
    tabs={[
      {
        label: tabs.FILTERS,
        value: tabs.FILTERS
      },
      {
        label: tabs.CONTACT_LIST,
        value: tabs.CONTACT_LIST
      }
    ]} />



/**
 * Address Book Sidebar Component
 */
class AddressBookSidebar extends PureComponent {
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props)
    this.state = {
      listId: 'all',
      selectedListId: 'all',
      selectedTab: CONTACT_PAGE_TABS.FILTERS
    }
  }

  /**
   * Set list ID helper
   * @param {string} listId
  */
  setSelectedListId (listId) {
    this.setState({ selectedListId: listId })
  }


  /**
   * Render Address Book Sidebar Component
   * @return {XML}
   */
  render () {
    const { selectedTab } = this.state

    const {
      loading,
      contactsLoading,
      lists,
      listCount,
      filters,
      onFilterChange,
      onParamsChange,
      onListDelete,
      onListSelect,
      onListEdit,
      openModal,
      filterControls,
      institutionSelector,
      locationSelector
    } = this.props

    const { handleFilterApply, handleFilterReset, applyIsDisabled, resetIsDisabled } = filterControls

    const footerButtons = (selectedTab === CONTACT_PAGE_TABS.FILTERS) ? [
      { label: 'Clear Filters', onClick: handleFilterReset, theme: THEMES.SOFT_GREY, disabled: resetIsDisabled },
      { label: 'Apply', onClick: handleFilterApply, theme: THEMES.CITRUS, disabled: applyIsDisabled }
    ] : null

    return (
      <Sidebar
        className='address-book-page_sidebar sidebar--tabs'
        footerButtons={footerButtons}
        headerTemplate={
          <TabNavigation
            tabs={CONTACT_PAGE_TABS}
            activeTab={selectedTab}
            handleTabChange={(_event, value) => {
              this.setState({ selectedTab: value })
            }}
          />
        }
      >
        {loading && <Spinner theme={THEMES.RAIN} />}

        <div className={`address-book-page_sidebar-content ${contactsLoading ? 'address-book-page_sidebar-content--masked' : ''}`}>
          {selectedTab == CONTACT_PAGE_TABS.FILTERS &&
            <Filter
              onFilterChange={onFilterChange}
              filters={filters}
              institutionSelector={institutionSelector}
              locationSelector={locationSelector}
            />}
          {selectedTab === CONTACT_PAGE_TABS.CONTACT_LIST &&
            <ContactLists
              lists={lists}
              listCount={listCount}
              selectedListId={this.state.selectedListId}
              setSelectedListId={this.setSelectedListId.bind(this)}
              onParamsChange={onParamsChange}
              onListDelete={onListDelete}
              onListSelect={onListSelect}
              onListEdit={onListEdit}
              openModal={openModal}
            />}
        </div>

      </Sidebar>
    )
  }
}

AddressBookSidebar.propTypes = {
  loading: PropTypes.bool,
  contactsLoading: PropTypes.bool,
  lists: PropTypes.array,
  listCount: PropTypes.object,
  onListSelect: PropTypes.func.isRequired,
  onListEdit: PropTypes.func.isRequired,
  onListDelete: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filterControls: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired
}

AddressBookSidebar.defaultProps = {
  loading: false,
  lists: []
}

export default AddressBookSidebar
