import shortid from 'shortid'
import moment from 'moment-timezone'
import { capitalize, isNumber } from 'lodash'

import { getReportQuarterEndDate, getAvailablePivotQuarters } from './reportConfig.util'
import { getRelativeQuarter } from '../date.util'
import { getFromXigniteToStandard } from '../stock/stock.util'
import { autoEnableRelativeComparisonConfig, pivotEntityTypes, stockEntityType } from './report.const'
import { getIsReportType } from './report.util'

/**
 * Maps infinite numbers of Series to Finite number of colors
 * @param seriesPos
 * @param colorOptionsLength
 * @return {number}
 */
const mapSeriesToColor = (seriesPos, colorOptionsLength) => {
  return seriesPos - (Math.floor(seriesPos / colorOptionsLength) * colorOptionsLength)
}

/**
 * Parse xAxis using reportFields for population
 * @param reportFields
 * @param xAxis
 */
export const parseXAxis = (reportFields, xAxis) => {
  const { slant, format, _field } = xAxis || {}

  return {
    slant,
    format,
    _field: (reportFields || []).find((field) => field._id === _field)
  }
}

/**
 * Parse series using reportFields for population
 * @param reportFields
 * @param series
 */
export const parseSeries = (reportFields, series) => {
  if (!series || !series.length) {
    return []
  }

  return series.map((seriesItem) => {
    const field = reportFields.find((field) => field._id === seriesItem._field)
    let pivotValue = seriesItem.pivotValue

    if (!pivotValue) {
      const entityTypeName = field && field._entityType && field._entityType.name

      pivotValue = pivotEntityTypes.includes(entityTypeName) ? getReportQuarterEndDate(entityTypeName) : null
    }

    if (!seriesItem._id) {
      seriesItem._id = shortid.generate()
    }

    return {
      ...seriesItem,
      pivotValue,
      _field: field || null
    }
  })
}

/**
 * Convert ticker into a option for the reportPeerConfig / series peers
 * @param ticker
 * @returns {{symbol: *, _security: *, exchange: *}}
 */
export const getPeerFromTicker = (ticker) => {
  const { _security, exchange, symbol, name } = ticker

  return {
    _security,
    exchange,
    symbol,
    security_name: name
  }
}

/**
 * Get formatted chartOptions for saving  report
 * @param chartOptions
 * @returns {{}}
 */
export const getFormattedChartOptions = (chartOptions) => {
  const { chartType, xAxis, aggregationModel, yAxis, series } = chartOptions

  return {
    chartType,
    aggregationModel,
    xAxis: xAxis && { _field: xAxis._field && xAxis._field._id },
    yAxis: yAxis || [],
    series: (series || []).map(({ chartType, color, _field, peer, index, pivotValue, yAxis, dataLabelsColor }) => ({
      chartType,
      color,
      yAxis,
      _field: _field && _field._id,
      peer: peer && {
        _security: peer._security,
        exchange: peer.exchange,
        symbol: peer.symbol
      },
      index: index && {
        group: index.group,
        symbol: index.symbol
      },
      pivotValue,
      dataLabelsColor
    }))
  }
}

/**
 * Calculates available fields to displaying as an xAxis in Highcharts
 * @param fields
 * @returns {*}
 */
export const getAvailableXAxisFields = (fields) => {
  if (!fields || !fields.length) {
    return []
  }

  return fields
    .map((field) => {
      const { type, _entityType, name } = field || {}
      const { name: entityName } = _entityType
      const isXAxisAvailable = (
        (entityName === stockEntityType && type === 'date') ||
        (entityName !== stockEntityType && ['choice', 'boolean'].includes(type)) ||
        (entityName !== stockEntityType && ['holder_name', 'institution_name', 'fund_name', 'title'].includes(name))
      )

      if (isXAxisAvailable) {
        return field
      }

      return null
    })
    .filter((field) => field)
}

/**
 * Calculates available fields to displaying as a series value in Highcharts
 * @param fields
 * @param xAxisField
 * @returns {[]}
 */
export const getAvailableSeriesFields = (fields, xAxisField) => {
  if (!fields || !fields.length || !xAxisField || !xAxisField._entityType) {
    return []
  }

  return fields.filter((field) => {
    const { type, _entityType } = field || {}
    return type && type === 'number' && xAxisField._entityType._id === _entityType._id
  })
}

/**
 * Calculates available peers for peer selection within the series configuration in Highcharts
 */
export const getAvailableSeriesPeers = (peers, ticker) => {
  return [
    getPeerFromTicker(ticker),
    ...peers
  ].filter((peer) => peer._security)
}

/**
 * Get default xAxis Field based on the provided entityType
 * @param entityType
 * @param availableXAxisFields
 * @return {null|*}
 */
export const getDefaultXAxisField = (entityType, availableXAxisFields) => {
  if (!entityType || !availableXAxisFields || !availableXAxisFields.length) {
    return null
  }

  let defaultLabel

  switch (entityType.name) {
    case 'stock':
      defaultLabel = 'Date'
      break
    case 'institution':
    case 'shareholderid':
      defaultLabel = 'holder_name'
      break
    case 'institutionSurveillance':
      defaultLabel = 'institution_name'
      break
    case 'fund':
    case 'fundSurveillance':
    case 'fundShareholderid':
      defaultLabel = 'fund_name'
      break
    case 'activity':
      defaultLabel = 'title'
      break
    default:
      break
  }

  return availableXAxisFields.find((labelField) => labelField.name === defaultLabel)
}

/**
 * Get default Series Field based on the provided entityType
 * @param entityType
 * @param availableSeriesFields
 * @return {*}
 */
export const getDefaultSeriesField = (entityType, availableSeriesFields) => {
  if (!entityType || !availableSeriesFields || !availableSeriesFields.length) {
    return []
  }

  let defaultName

  switch (entityType.name) {
    case 'stock':
      defaultName = 'Last'
      break
    case 'institution':
    case 'institutionSurveillance':
    case 'shareholderid':
    case 'fund':
    case 'fundSurveillance':
    case 'fundShareholderid':
      defaultName = 'holdings.current'
      break
    default:
      break
  }

  return availableSeriesFields.find((seriesField) => seriesField.name === defaultName)
}

/**
 * Get default yAxis configuration
 * @returns {*[]}
 */
export const getDefaultYAxis = () => {
  return [{
    id: 'left',
    floor: null,
    ceiling: null,
    shouldRenderAbsoluteValues: false
  }, {
    id: 'right',
    floor: null,
    ceiling: null,
    shouldRenderAbsoluteValues: false
  }]
}

/**
 * Get dropdown label for selection of the labelField
 * @param chartType
 * @returns {*}
 */
export const getChartAxisLabel = (chartType) => {
  let axisLabel

  switch (chartType) {
    case 'pie':
      axisLabel = 'Label'
      break
    case 'bar':
      axisLabel = 'Y-axis'
      break
    case 'column':
    case 'line':
    default:
      axisLabel = 'X-axis'
  }

  return axisLabel
}

/**
 * Returns formatted pivot value
 * @param pivotValue
 * @return {string}
 */
export const getPivotLabel = (pivotValue) => {
  if (!pivotValue) {
    return ''
  }

  const year = moment(pivotValue).format('YYYY')
  const quarter = moment(pivotValue).quarter()
  const currentQuarter = moment().quarter()
  const currentYear = moment().format('YYYY')
  const headerName = `Q${quarter} ${year}`

  return headerName === `Q${currentQuarter} ${currentYear}` ? 'Current' : headerName
}

/**
 * Returns Formatted Peer or Index Label
 * @param peerIndex
 */
export const getIdentifierLabel = (peerIndex) => {
  if (!peerIndex) {
    return ''
  }

  return `${peerIndex.symbol}.${peerIndex.exchange ? getFromXigniteToStandard(peerIndex.exchange) : peerIndex.group}`
}

/**
 * Get series label for highcharts legend and series customization selection labels
 * @param seriesItem
 * @returns {string|null}
 */
export const getSeriesLabel = (seriesItem) => {
  if (!seriesItem) {
    return null
  }

  const { _field, pivotValue, peer, index } = seriesItem
  const identifierString = getIdentifierLabel(peer || index)
  const pivotString = getPivotLabel(pivotValue)
  const fieldString = _field ? _field.label : ''

  return (`${identifierString} ${pivotString} ${fieldString}`).trim()
}

/**
 * Get xAxisField label used in selection components
 * @param field
 * @returns {string|null}
 */
export const getXAxisFieldLabel = (field) => {
  if (!field || !field.label) {
    return null
  }

  return `${field.label}${field._entityType ? ` (${capitalize(field._entityType.label)})` : ''}`
}

/**
 * Helper function to determine if series selection is complete.
 * Series is considered to be complete when user selected series field and series peer or series pivot option.
 * @param series
 */
export const getCompleteSeries = (series) => {
  return (series || []).filter((series) => series && series._field && (series.pivotValue || series.peer || series.index))
}

/**
 * Helper function to duplicate identifier series based on provided base series
 * @param type
 * @param identifiers
 * @param series
 * @param baseSeries
 */
export const getSeriesFromIdentifiers = ({ type, identifiers, series, baseSeries }) => {
  const hasMissingParams = !type || !identifiers || !identifiers.length || !series || !series.length || !baseSeries
  const hasValidBaseSeries = baseSeries && ((baseSeries.peer && baseSeries.peer._security) || (baseSeries.index && baseSeries.index.symbol))

  if (hasMissingParams || !hasValidBaseSeries) {
    return []
  }

  const seriesToDuplicate = series.filter((eachSeries) => {
    return ((eachSeries.peer && baseSeries.peer) && (eachSeries.peer._security === baseSeries.peer._security)) ||
      ((eachSeries.index && baseSeries.index) && (eachSeries.index.symbol === baseSeries.index.symbol && eachSeries.index.group === baseSeries.index.group))
  })

  return (identifiers || [])
    .map((identifier) => seriesToDuplicate.map((eachDuplicatedSeries) => ({
      ...eachDuplicatedSeries,
      peer: null,
      index: null,
      [type]: identifier,
      color: null,
      _id: shortid.generate()
    })))
    .reduce((acc, curr) => [...acc, ...curr], [])
}

/**
 * Applies default color to series if color is not provided
 * @param series
 * @param colorOptions
 * @return {*[]}
 */
export const applyColorToSeries = (series, colorOptions) => {
  return (series || []).map((eachSeries, idx) => {
    if (!eachSeries.color) {
      eachSeries.color = colorOptions[mapSeriesToColor(idx, colorOptions.length)]
    }
    return eachSeries
  })
}

/**
 * Applies default color to series's data labels if color is not provided
 * @param seriesData
 * @param seriesDataColors
 * @param colorOptions
 * @return {*[]}
 */
export const applyColorToDataLabels = (seriesData, seriesDataColors, colorOptions) => {
  if (!seriesData || !seriesData.length) {
    return []
  }

  const dataLabelsColorMap = (seriesDataColors || []).reduce((acc, curr) => ({ ...acc, [curr.label]: curr.color }), {})

  const newDataLabels = seriesData.map((dataLabel) => {
    const label = dataLabel.name

    // take existing color configuration if it exists
    if (dataLabelsColorMap[label]) {
      return { label, color: dataLabelsColorMap[label] }
    }

    return { label }
  })

  return applyColorToSeries(newDataLabels, colorOptions)
}

/**
 * Returns Initial Series.
 * This function includes you as a first peer and
 * creates series from peers or indices based on the constructed base series
 * @param ticker
 * @param series
 * @param peers
 * @param indices
 * @param entityType
 * @param pivotQuarters
 */
export const getInitialSeries = ({ ticker, series, peers, indices, entityType, pivotQuarters }) => {
  if (!ticker || !series || !series.length) {
    return []
  }

  const isStockReport = getIsReportType([entityType], stockEntityType)
  const availablePivotQuarters = getAvailablePivotQuarters(pivotQuarters, entityType)
  const initialPeer = getPeerFromTicker(ticker)

  const initialSeries = series.map((eachSeries) => ({
    ...eachSeries,
    peer: initialPeer,
    pivotValue: isStockReport ? null : isNumber(eachSeries.pivotValue)
      ? getRelativeQuarter(eachSeries.pivotValue, 'YYYYMMDD')
      : (eachSeries.pivotValue || availablePivotQuarters[0])
  }))
  const baseSeries = initialSeries[0]

  const peerSeries = getSeriesFromIdentifiers({
    type: 'peer',
    identifiers: [initialPeer, ...(peers || [])],
    series: initialSeries,
    baseSeries
  })
  const indexSeries = getSeriesFromIdentifiers({
    type: 'index',
    identifiers: indices || [],
    series: initialSeries,
    baseSeries
  })

  return [...peerSeries, ...indexSeries]
}

/**
 * Based on provided series determines if shouldRenderRelativeValues checkbox should be active
 * @param series - series array
 * @param entityType - report entity type
 * @param chartType - global chartOptions chartType
 * @returns {boolean}
 */
export const getShouldRenderRelativeValues = (series, entityType, chartType) => {
  const { supportedYAxis, supportedChartType, supportedFields } = autoEnableRelativeComparisonConfig
  const isStockReport = getIsReportType(entityType, stockEntityType)

  // get only completed series
  series = getCompleteSeries(series)

  // get series available for relative comparison
  series = series.filter((series) => (
    supportedYAxis.includes(series.yAxis) &&
    (supportedChartType.includes(series.chartType) || supportedChartType.includes(chartType)) &&
    supportedFields.includes(series._field.columnName)
  ))

  return (isStockReport && series.length > 1)
}
