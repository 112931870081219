import {
  FAILED,
  FETCH_ESTIMATES_CONSENSUS_ERROR,
  FETCH_ESTIMATES_CONSENSUS_REQUEST,
  FETCH_ESTIMATES_CONSENSUS_SUCCESS,
  SET_ESTIMATES_CONSENSUS_METRIC,
  RESET_ESTIMATES,
  FETCHED,
  FETCHING,
  IDLE,
  ESTIMATE_PEER_CHANGED
} from '../../actions/estimates'

const consensusMetrics = ['eps', 'income', 'balance', 'cash', 'pershare', 'ffo']
const initialState = Object.assign({
  status: IDLE,
  selected: {}
}, ...consensusMetrics.map((each) => {
  return { [each]: [] }
}))

const estimatesConsensus = (state = initialState, action) => {
  switch (action.type) {
    case ESTIMATE_PEER_CHANGED:
      return {
        ...initialState,
        selected: {
          ...state.selected,
          metric: (action.payload ? 'eps' : state.selected.metric)
        }
      }
    case RESET_ESTIMATES:
      return {
        ...initialState
      }
    case SET_ESTIMATES_CONSENSUS_METRIC:
      return {
        ...state,
        selected: {
          ...state.selected,
          metric: action.metric
        }
      }
    case FETCH_ESTIMATES_CONSENSUS_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_ESTIMATES_CONSENSUS_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        [state.selected.metric]: action.payload
      }
    case FETCH_ESTIMATES_CONSENSUS_ERROR:
      return {
        ...state,
        status: FAILED,
        error: action.error
      }
    default:
      return state
  }
}

export default estimatesConsensus
