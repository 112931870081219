export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'

/**
 * Dispatch Reset Password
 * @param params
 * @returns {function(*): *}
 */
export const setPasswordResetSuccess = (params) => (dispatch) => {
  return dispatch({ type: PASSWORD_RESET_SUCCESS })
}
