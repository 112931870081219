import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { addQueryParams } from '../../../utils/http/http.util'

export const FETCH_WIDGET_ESTIMATES_CONSENSUS_REQUEST = 'FETCH_WIDGET_ESTIMATES_CONSENSUS_REQUEST'
export const FETCH_WIDGET_ESTIMATES_CONSENSUS_SUCCESS = 'FETCH_WIDGET_ESTIMATES_CONSENSUS_SUCCESS'
export const FETCH_WIDGET_ESTIMATES_CONSENSUS_ERROR = 'FETCH_WIDGET_ESTIMATES_CONSENSUS_ERROR'
export const SET_WIDGET_CONSENSUS_DATA = 'SET_WIDGET_CONSENSUS_DATA'

const resources = {
  consensus: '/estimates/v2/consensus'
}

const _fetchConsensus = (url, params) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_WIDGET_ESTIMATES_CONSENSUS_REQUEST,
      FETCH_WIDGET_ESTIMATES_CONSENSUS_SUCCESS,
      FETCH_WIDGET_ESTIMATES_CONSENSUS_ERROR
    ],
    method: METHOD_TYPE.GET,
    payload: params
  }
})

const _setConsensusData = (data) => ({
  type: SET_WIDGET_CONSENSUS_DATA,
  payload: data
})

export const fetchConsensus = (params) => (dispatch) => {
  const url = addQueryParams(resources.consensus, params)
  return dispatch(_fetchConsensus(url))
}

export const setConsensusData = (params) => (dispatch) => {
  return dispatch(_setConsensusData(params))
}
