import React from 'react'
import { ComboBox } from '../../../../../../components'
import { THEMES, getFromXigniteToStandard, debounce } from '../../../../../../utils'

/**
 * Events & Transcripts Page
 * @param props
 */
function SecurityFilter (props) {
  const { filters, handleFilterChange, searchSecurities, securitySuggestions, resetSecuritySuggestions, isSearchSecuritiesLoading } = props
  const { tickers } = filters

  /**
   * Get suggestions in a format that ComboBox consumes
   */
  const getFormattedSuggestions = (suggestions) => {
    if (!suggestions || !suggestions.length) {
      return []
    }
    // return only suggestions that do not match currently selected results
    const selectedTickers = tickers.map(ticker => ticker._id)
    const filteredSuggestions = suggestions.filter(res => {
      return !selectedTickers.includes(res._id)
    })
    return filteredSuggestions.map((suggestion) => ({
      value: suggestion._id,
      label: renderSecurity(suggestion),
      data: suggestion
    }))
  }

  /**
   * Get items in a format that RemovableList consumes
   * @param value
   * @returns {[]}
   */
  const getFormattedListItems = (value) => {
    if (!value || !value.length) {
      return []
    }
    return value.filter((suggestion) => suggestion?._id).map((suggestion) => ({
      value: suggestion._id,
      label: renderSecurity(suggestion)
    }))
  }

  /**
   * Render Suggested/Selected Security and formatting
   * @param ticker
   * @return {*}
   */
  const renderSecurity = (ticker) => {
    const { symbol, exchange, name } = ticker

    return (
      <span className='ownership-filter_item'>
        {exchange && symbol
          ? <>
            <span className='ownership-filter_item-symbol'>{symbol}</span>
            <span className='ownership-filter_item-exchange'>{getFromXigniteToStandard(exchange)}</span>
            <span className='ownership-filter_item-separator'>|</span>
          </>
          : null}
        <span className='ownership-filter_item-name'>{name}</span>
      </span>
    )
  }

  /**
   * Handle ComboBox onChange event, updating the filter state from sidebar
   * @param selectedValue
   * @param type
   */
  const handleChange = (selectedValue, type) => {
    if (!type || !type.action || type.action !== 'select-option') {
      return
    }
    const selected = [...tickers, selectedValue.data]
    handleFilterChange({ tickers: selected })
    resetSecuritySuggestions()
  }

  /**
   * Handle RemovableList onRemove event
   * @param tickerId
   */
  const handleRemove = (tickerId) => {
    if (tickerId) {
      const selected = tickers.filter((ticker) => ticker._id !== tickerId)
      handleFilterChange({ tickers: selected })
      resetSecuritySuggestions()
    }
  }

  /**
   * Handle input search change, triggering quick search
   * @param value
   * @param event
   */
  const handleSearchChange = (value, event) => {
    if (!value) {
      resetSecuritySuggestions()
    } else if (event.action === 'input-change') {
      searchSecurities(value)
    }
  }

  return (
    <section className='eventsTranscripts-security-filter'>
      <div className='field field--full field--text'>
        <label className='field_label'>Security</label>
        <div className='event-security-filter'>
          <ComboBox
            theme={THEMES.WHITE}
            selectProps={{
              dataId: {
                inputId: 'security-filter-input',
                menuId: 'security-filter-menu'
              },
              placeholder: 'Search',
              value: null,
              options: getFormattedSuggestions(securitySuggestions),
              loading: isSearchSecuritiesLoading,
              onInputChange: debounce(handleSearchChange, 600),
              onChange: handleChange
            }}
            removableListProps={{
              items: getFormattedListItems(tickers),
              onRemove: handleRemove
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default SecurityFilter
