import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const GET_INSTITUTION_REQUEST = 'GET_INSTITUTION_REQUEST'
export const GET_INSTITUTION_SUCCESS = 'GET_INSTITUTION_SUCCESS'
export const GET_INSTITUTION_FAILURE = 'GET_INSTITUTION_FAILURE'

export const GET_INSTITUTION_ANALYTICS_REQUEST = 'GET_INSTITUTION_ANALYTICS_REQUEST'
export const GET_INSTITUTION_ANALYTICS_SUCCESS = 'GET_INSTITUTION_ANALYTICS_SUCCESS'
export const GET_INSTITUTION_ANALYTICS_FAILURE = 'GET_INSTITUTION_ANALYTICS_FAILURE'

export const FETCH_INSTITUTION_REQUEST = 'FETCH_INSTITUTION_REQUEST'
export const FETCH_INSTITUTION_SUCCESS = 'FETCH_INSTITUTION_SUCCESS'
export const FETCH_INSTITUTION_FAILURE = 'FETCH_INSTITUTION_FAILURE'

export const RESET_INSTITUTION = 'RESET_INSTITUTION'

/**
 * Fetch Institution
 * @param entityId - institution entityId
 * @param options
 * @param options.types - action types
 * @private
 */
const _fetchInstitution = (entityId, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      FETCH_INSTITUTION_REQUEST,
      FETCH_INSTITUTION_SUCCESS,
      FETCH_INSTITUTION_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/institution/${entityId}`,
    payload: {
      entityId
    }
  }
})

/**
 * Get Institution by entity id
 */
export const fetchInstitution = (entityId, options) => (dispatch) => {
  dispatch(_fetchInstitution(entityId, options))
}

/**
 * Get Institution
 * @param id
 * @param options.types - action types
 * @private
 */
const _getInstitution = (id, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_INSTITUTION_REQUEST,
      GET_INSTITUTION_SUCCESS,
      GET_INSTITUTION_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/institution/${id}`
  }
})

/**
 * Get Institution Web Analytics
 * @param id
 * @param options
 * @param options.types - action types
 * @private
 */
const _getInstitutionAnalytics = (id, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_INSTITUTION_ANALYTICS_REQUEST,
      GET_INSTITUTION_ANALYTICS_SUCCESS,
      GET_INSTITUTION_ANALYTICS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/institution/analytics/${id}`
  }
})

/**
 * Dispatch GET_INSTITUTION_REQUEST
 */
export const getInstitution = (id, options) => (dispatch) => {
  return dispatch(_getInstitution(id, options))
}

/**
 * Dispatch GET_INSTITUTION_ANALYTICS_REQUEST
 */
export const getInstitutionAnalytics = (id, options) => (dispatch) => {
  return dispatch(_getInstitutionAnalytics(id, options))
}

/**
 * Dispatch RESET_INSTITUTION
 */
export const resetInstitution = () => (dispatch) => {
  return dispatch({ type: RESET_INSTITUTION })
}
