import { DOWNLOAD_API } from '../../middleware/download.middleware'
import { addQueryParams } from '../../utils/http/http.util'

export const DOWNLOAD_EXPORT_REQUEST = 'DOWNLOAD_EXPORT_REQUEST'
export const DOWNLOAD_EXPORT_SUCCESS = 'DOWNLOAD_EXPORT_SUCCESS'
export const DOWNLOAD_EXPORT_ERROR = 'DOWNLOAD_EXPORT_ERROR'

export const FETCHING = 'FETCHING'
export const IDLE = 'IDLE'
export const FETCHED = 'FETCHED'
export const FAILED = 'FAILED'

const _downloadExportData = (url, file, type) => ({
  [DOWNLOAD_API]: {
    endpoint: url,
    types: [
      DOWNLOAD_EXPORT_REQUEST,
      DOWNLOAD_EXPORT_SUCCESS,
      DOWNLOAD_EXPORT_ERROR
    ],
    payload: { file },
    options: {
      headers: {
        'Content-Type': type
      },
      credentials: 'include'
    }
  }
})

export const downloadExportData = (data) => (dispatch) => {
  const url = addQueryParams(data.url, data.params)
  return dispatch(_downloadExportData(url, data.file, data.contentType))
}
