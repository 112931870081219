import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// redux actions
import {
  fetchInstitution,
  getTarget,
  getDealsByEntityId,
  clearEntityDeals,
  clearTarget,
  statusType,
  resetTags
} from '../../../../../../actions'
import Institution from './institution.component'
import { get } from 'lodash'

const propTypes = {
  /**
   * Used to fetch institution data
   */
  entityId: PropTypes.string.isRequired,

  /**
   * Used to show institution data
   */
  institution: PropTypes.shape({
    // data object serves as a map to store { key: value } institution data in the following format:
    // [factset_entity_id]: {
    //    ...entityData,
    // }
    data: PropTypes.object
  }),

  /**
   * Used to show target icon and provide data for utility menu
   */
  targetId: PropTypes.string,

  /**
   * Used to provide data for utility menu
   */
  hasDeal: PropTypes.bool,
}

/**
 * InstitutionContainer
 */
function InstitutionContainer (props) {
  const {
    entityId,
    entityType,
    targetId,
    hasDeal,
    institution,
    fetchInstitution,
    getTarget,
    getDealsByEntityId,
    clearEntityDeals,
    resetTags,
    clearTarget
  } = props
  const { data, status } = institution.data[entityId] || {}

  useEffect(() => {
    if (!data) {
      entityId && fetchInstitution(entityId)
    }
  }, [entityId, data, fetchInstitution])

  useEffect(() => {
    if (!data || !data._id || !entityType) {
      return
    }

    getTarget(data._id)
    getDealsByEntityId(data._id)

    return () => {
      clearEntityDeals()
      clearTarget()
      resetTags()
    }
  }, [data, entityType, getTarget, getDealsByEntityId, clearEntityDeals, clearTarget, resetTags])

  return (
    <Institution
      {...props}
      isLoading={!data || [statusType.IDLE, statusType.IN_PROGRESS].includes(status)}
      institution={data}
      targetId={targetId}
      hasDeal={hasDeal}
    />
  )
}

InstitutionContainer.propTypes = propTypes

const mapDispatchToProps = (dispatch) => ({
  fetchInstitution: bindActionCreators(fetchInstitution, dispatch),
  getTarget: bindActionCreators(getTarget, dispatch),
  clearEntityDeals: bindActionCreators(clearEntityDeals, dispatch),
  resetTags: bindActionCreators(resetTags, dispatch),
  clearTarget: bindActionCreators(clearTarget, dispatch),
  getDealsByEntityId: bindActionCreators(getDealsByEntityId, dispatch)
})

const mapStateToProps = (state) => {
  const { targeting, entityDeals, tag } = state

  return {
    institution: state.institution.institutionDetail,
    targetId: get(targeting, 'target.data._id') || get(targeting, 'target.data.targetId'),
    hasDeal: get(entityDeals, 'data', []).length > 0,
    tagSearch: tag.search
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionContainer)
