import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

/**
 * Pipeline Action Type Constants
 * @type {string}
 */
export const GET_STAGES_REQUEST = 'GET_STAGES_REQUEST'
export const GET_STAGES_SUCCESS = 'GET_STAGES_SUCCESS'
export const GET_STAGES_FAILURE = 'GET_STAGES_FAILURE'

/**
 * Get Pipeline stages for user's organization
 * @private
 */
const _getStages = () => ({
  [CALL_API]: {
    types: [
      GET_STAGES_REQUEST,
      GET_STAGES_SUCCESS,
      GET_STAGES_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/pipeline/stages'
  }
})

/**
 * Dispatch GET_STAGES_REQUEST
 */
export const getStages = (initial) => (dispatch) => {
  dispatch(_getStages())
}
