import './detail.component.css'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { pushFlyoutDetailsItem } from '../../../../../actions'

import ContactContainer from './contact/contact.container'
import InstitutionContainer from './institution/institution.container'
import FundContainer from './fund/fund.container'

import { FLYOUT_ENTITY_TYPES } from '../../../../../utils'

const propTypes = {
  data: PropTypes.object.isRequired,
  handleTargetUtilAction: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}

function TargetDetailContainer ({ data, handleClose, handleTargetUtilAction, ...props }) {
  const { entityType } = data
  const entityProps = { ...data, handleClose, handleTargetUtilAction }
  const Entity = getEntityContainer(entityType)
  /**
   * Returns flyout container based on entityType
   * @param entityType
   * @returns {*}
   */
  function getEntityContainer (entityType) {
    switch (entityType) {
      case FLYOUT_ENTITY_TYPES.INSTITUTION:
        return InstitutionContainer
      case FLYOUT_ENTITY_TYPES.FUND:
        return FundContainer
      case FLYOUT_ENTITY_TYPES.CONTACT:
        return ContactContainer
      default:
        return
    }
  }

  /**
   * Handles redirect inside of the flyout
   * @param params
   */
  function handleRedirect (params) {
    if (!params || !params.entityType) {
      return
    }

    const { entityType } = params
    const { pushFlyoutDetailsItem, flyoutDetailsHistory } = props
    const lastItem = flyoutDetailsHistory[flyoutDetailsHistory.length - 1]

    switch (entityType) {
      case FLYOUT_ENTITY_TYPES.INSTITUTION:
      case FLYOUT_ENTITY_TYPES.FUND:
        if (!lastItem || lastItem.entityId !== params.entityId) {
          pushFlyoutDetailsItem({ entityType, entityId: params.entityId })
        }
        break
      case FLYOUT_ENTITY_TYPES.CONTACT:
        if (!lastItem || lastItem.contactId !== params.contactId) {
          pushFlyoutDetailsItem({ entityType, contactId: params.contactId })
        }
        break
      default:
        return
    }
  }

  return (
    <div className='target-detail'>
      {Entity && (
        <Entity
          {...entityProps}
          handleRedirect={handleRedirect}
        />
      )}
    </div>
  )
}

TargetDetailContainer.propTypes = propTypes

const mapDispatchToProps = (dispatch) => ({
  pushFlyoutDetailsItem: bindActionCreators(pushFlyoutDetailsItem, dispatch)
})

const mapStateToProps = (state) => {
  return {
    flyoutDetailsHistory: state.targeting.flyout.data
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetDetailContainer)
