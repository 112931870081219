import {
  FETCH_UPCOMING_ACTIVITIES_ERROR,
  FETCH_UPCOMING_ACTIVITIES_REQUEST,
  FETCH_UPCOMING_ACTIVITIES_SUCCESS,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../../actions/widget/activity/upcomingActivity.actions'

const initial = {
  activities: [],
  status: IDLE
}

const upcomingActivitiesState = (state = initial, action) => {
  switch (action.type) {
    case FETCH_UPCOMING_ACTIVITIES_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_UPCOMING_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: action.payload,
        status: FETCHED
      }
    case FETCH_UPCOMING_ACTIVITIES_ERROR:
      return {
        ...state,
        error: action.error,
        status: FAILED
      }
    default:
      return state
  }
}

export default upcomingActivitiesState
