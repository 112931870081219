import React from 'react'
import { config } from '../../../../../../config'
import { ExpandableCell } from '../../../../../agGrid/cell'
import { combine, format, formatDate } from '../../../../../../utils'
import { get } from 'lodash'

const EMPTY_PLACEHOLDER = '-'

/**
 * Email Component
 * @param email
 * @returns {*}
 * @constructor
 */
const Email = ({ email }) => {
  return <div className='cell'>
    <div className='cell_content cell_content--link'>{(email && email.length)
      ? <a
        className='cell_content-name'
        href={`mailto:${email}?Subject=Contact%20from%20Q4%20Desktop&bcc=${config.loggerEmail}`}
        rel='noopener noreferrer'
        target='_blank'>{email}</a>
      : EMPTY_PLACEHOLDER}
    </div>
  </div>
}

const CustomCell = ({ data = {}, column = {}, onPopoverClick }) => {
  const cellData = data[column.colId]
  const job = get(data, 'jobs[0]', {})
  const { title, email, phone, city, stateProvince, investmentFocus, functions } = job

  switch (column.colId) {
    case 'fullName':
      return cellData || EMPTY_PLACEHOLDER
    case 'title':
      return title || EMPTY_PLACEHOLDER
    case 'email':
      return <Email email={email} />
    case 'phone':
      return phone || EMPTY_PLACEHOLDER
    case 'location':
      const location = combine([city, stateProvince])
      return location && location.length ? location : EMPTY_PLACEHOLDER
    case 'investmentFocus':
      return <ExpandableCell
        items={(investmentFocus || []).map((item) => ({ label: item }))}
        onClick={onPopoverClick}
      />
    case 'function':
      return <ExpandableCell
        items={(functions || []).map((item) => ({ label: item }))}
        onClick={onPopoverClick}
      />
    case 'current':
      const current = get(data, 'contactHoldingCurrentConnection.items[0].current', null)
      return (current || current === 0) ? format(current) : EMPTY_PLACEHOLDER
    case 'activity':
      const activity = get(data, 'activityConnection.items[0].start.date', null)
      return activity ? formatDate(activity, undefined, undefined, true) : EMPTY_PLACEHOLDER
    default:
      return cellData || EMPTY_PLACEHOLDER
  }
}

export default CustomCell
