import React from 'react'
import PropTypes from 'prop-types'

import { Select, Button, Toolbar, ToolbarRow } from '../../../../components'
import { SectionSearch } from '../../../../components/shared'
import { ACTIVITY_TYPE_OPTIONS, ALL, THEMES } from '../../../../utils'

/**
 * Get category options
 * @param isEntity
 * @param counts
 * @returns {{label: string, value: *}[]}
 */
const getCategory = (isEntity, counts) => {
  return (ACTIVITY_TYPE_OPTIONS || [])
    .filter((category) => category.value !== ALL)
    .filter((category) => isEntity ? !category.isParent : category)
    .map((category) => {
      return {
        label: `${category.label} (${(counts && counts[category.value]) || 0})`,
        value: category.value
      }
    })
}

/**
 * Activity Toolbar
 * @param props
 * @returns {*}
 * @constructor
 */
const ActivityToolbar = (props) => {
  const {
    toolbarTheme, toolTheme, isEntity, noData, searchValue, category, counts, handleActivityCreate, handleFilterChange, handleExport
  } = props
  const options = getCategory(isEntity, counts)
  const onSearchChange = (search) => handleFilterChange({ search, page: 1 })
  const onCategoryChange = (option) => handleFilterChange({ category: option && option.map((item) => item && item.value), page: 1 })

  return (
    <Toolbar theme={toolbarTheme}>
      <ToolbarRow justified>
        <div className='toolbar_group'>
          <Select
            dataId={{
              inputId: 'ContactDetailPageActivityGridCategoryInput',
              menuId: 'ContactDetailPageActivityGridCategoryMenu'
            }}
            theme={toolTheme}
            size='thin'
            placeholder='Category'
            value={category !== 'all' ? (options || []).find((option) => option && option.value === category) : ''}
            options={options}
            isMulti
            isDefaultList
            closeMenuOnSelect={false}
            onChange={onCategoryChange}
            searchable={false}
            clearable={false}
          />
        </div>

        <div className='toolbar_group'>
          <SectionSearch
            theme={toolTheme}
            value={searchValue}
            onQueryChange={onSearchChange}
            onClear={onSearchChange}
          />
          <Button
            theme={toolTheme}
            icon='q4i-download-4pt'
            label='Export'
            disabled={noData}
            onClick={handleExport}
          />
          <Button
            theme={THEMES.CITRUS}
            icon='q4i-plus-4pt'
            onClick={handleActivityCreate}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

ActivityToolbar.propTypes = {
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  noData: PropTypes.bool,
  isEntity: PropTypes.bool,
  counts: PropTypes.object,
  searchValue: PropTypes.string,
  category: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  handleActivityCreate: PropTypes.func,
  handleFilterChange: PropTypes.func,
  handleExport: PropTypes.func
}

ActivityToolbar.defaultProps = {
  searchValue: '',
  category: 'all'
}

export default ActivityToolbar
