import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import { getClassName } from '../../../../utils/ui'
import './removableListItem.component.css'

/**
 * Removable List Item Component
 * Child of RemovableList
 */
class RemovableListItem extends PureComponent {

  constructor (props) {
    super(props)

    if (props.size === 'auto') {
      this.componentRef = createRef()
    }
  }

  /**
   * Handle onClick event when attempting to remove the list item
   * @param event
   */
  handleRemoveClick = (event) => {
    const { value, onRemoveWarn, onRemove, shouldWarnOnRemove } = this.props

    if (shouldWarnOnRemove) {
      return onRemoveWarn && onRemoveWarn(value, event)
    }

    onRemove && onRemove(value, event)
  }

  /**
   * Render label
   * @returns {*}
   */
  renderLabel = () => {
    const { item, label, customItemRender } = this.props
    return customItemRender ? customItemRender(item) : label
  }

  /**
   * Render Report Field Component
   * @return {XML}
   */
  render () {
    const { className, style, theme, size, isLocked, onRemove } = this.props

    const baseClassName = getClassName('removable-list-item', [
      { condition: className, trueClassName: className },
      { condition: theme, trueClassName: `removable-list-item--${theme}` },
      { condition: size, trueClassName: `removable-list-item--${size}` },
      { condition: isLocked, trueClassName: 'removable-list-item--locked' }
    ])

    return (
      <li className={baseClassName} style={style}>
        <div className='removable-list-item_inner' ref={this.componentRef}>
          <div className='removable-list-item_label'>
            {this.renderLabel()}
          </div>
          {onRemove && !isLocked && (
            <div className='removable-list-item_icon removable-list-item_icon--remove'
                 onClick={this.handleRemoveClick}>
              <i className='q4i-close-4pt' />
            </div>
          )}
          {isLocked && (
            <div className='removable-list-item_icon removable-list-item_icon--lock'>
              <i className='q4i-lock-4pt' />
            </div>
          )}
        </div>
      </li>
    )
  }
}

RemovableListItem.propTypes = {
  /**
   * A custom className to pass into the component
   */
  className: PropTypes.string,

  /**
   * A custom style object to pass into the component
   */
  style: PropTypes.object,

  /**
   * Used to paint the component using a specific theme
   */
  theme: PropTypes.string,

  /**
   * Used to paint the list items using a specific height
   * Small: 30px; Default: 40px; Large: 60px;
   */
  size: PropTypes.oneOf(['small', 'default', 'large', 'auto']),

  /**
   * A unique ID string used to identify the component
   */
  value: PropTypes.string.isRequired,

  /**
   * The default label prop to determine label text or render
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Used to determine whether or not the component is locked
   * Locked items cannot be removed
   */
  isLocked: PropTypes.bool,

  /**
   * Used to determine whether or not a confirmation message is rendered upon attempted removal of the item
   */
  shouldWarnOnRemove: PropTypes.bool,

  /**
   * The object passed in as an argument when executing the customItemRender function
   */
  item: PropTypes.object
}

export default RemovableListItem
