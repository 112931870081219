import moment from 'moment-timezone'
import { inMillions } from '../number.util'

const EMPTY_PLACEHOLDER = '-'
const ZERO = 0

/**
 * Truncate string
 * @deprecated
 * @param value
 * @param limit
 * @param format
 * @returns {string}
 */
export const truncate = (value, limit, format = false) => {
  if (!value || typeof value !== 'string') {
    return
  }

  if (format) {
    value = value.replace(/\^\\n|\\r|\\n|<br>/g, ' ')
  }

  return (value.length > Number(limit)) ? `${value.substr(0, limit)}...` : value
}

/**
 * Returns formatted date based on timezone
 * @param date
 * @param format
 * @param timezone
 * @returns {string}
 */
export const formatDateByTimezone = (date, format, timezone) => {
  timezone = timezone || moment.tz.guess()
  return date && moment.tz(date, timezone).format(format)
}

/**
 * Get percentage value
 * @param percent
 * @returns {*}
 */
export const getPercentage = (percent) => {
  if (!percent && !(percent === 0)) {
    return EMPTY_PLACEHOLDER
  }

  return (Number(percent) < 0.01 && Number(percent) > 0) ? '< 0.01' : Number(percent).toFixed(2)
}

/**
 * Get market change value in millions
 * @param value
 */
export const getMarketChange = (value) => {
  if (!value && !(value === 0)) {
    return EMPTY_PLACEHOLDER
  }

  return (Math.abs(parseFloat(value) / 1000000) >= 0.01) ? inMillions(value, 2) : ZERO
}

/**
 * Get change value class name
 * @param number
 * @returns {string}
 */
export const getChangeClassName = (number) => {
  if (!number) {
    return ''
  }

  return (parseFloat(number) > 0) ? 'tearsheet_grid-cell--positive' : (parseFloat(number) < 0) ? 'tearsheet_grid-cell--negative' : ''
}
