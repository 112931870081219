import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { RangeTab, SectionSearch } from '../../../../shared'
import { Button, Toolbar, ToolbarRow } from '../../../../index'
import { THEMES } from '../../../../../utils'

const propTypes = {
  dataId: PropTypes.string,
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  noData: PropTypes.bool,
  search: PropTypes.string,
  filter: PropTypes.shape({
    all: PropTypes.bool,
    holder: PropTypes.bool,
    peerHolder: PropTypes.bool
  }),
  onQueryChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired
}

const defaultProps = {
  toolbarTheme: THEMES.Q4_BLUE,
  toolTheme: THEMES.INK,
  filter: {
    all: true,
    holder: false,
    peerHolder: false
  }
}

const FILTER = [
  { label: 'All', value: 'all', dataId: 'FilterAll' },
  { label: 'Holders', value: 'holder', dataId: 'FilterHolders' },
  { label: 'Peer Holders', value: 'peerHolder', dataId: 'FilterPeerHolders' }
]

/**
 * Fund Toolbar Component
 * @param props
 */
function FundToolbar (props) {
  const { dataId, toolbarTheme, toolTheme, noData, filter, search, onQueryChange, onExport, exporting } = props

  const handleSearchChange = (search) => onQueryChange({ search })
  const handleChange = (event, value) => {
    Object.keys(filter).forEach((option) => { filter[option] = (option === value) })
    return onQueryChange({ filter })
  }

  return (
    <Toolbar theme={toolbarTheme}>
      <ToolbarRow justified>
        <div className='toolbar_group'>
          <RangeTab
            theme={toolTheme}
            buttons={FILTER.map((option) => ({
              ...option,
              dataId: `${dataId}${option.dataId}`,
              active: filter[option.value],
              onClick: handleChange
            }))}
          />
        </div>
        <div className='toolbar_group'>
          <SectionSearch
            dataId={`${dataId}Search`}
            theme={toolTheme}
            value={search}
            onQueryChange={handleSearchChange}
            onClear={handleSearchChange}
          />
          <Button
            dataId={`${dataId}Export`}
            theme={toolTheme}
            loading={exporting}
            icon='q4i-download-4pt'
            label='Export'
            disabled={noData}
            onClick={onExport}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

FundToolbar.propTypes = propTypes
FundToolbar.defaultProps = defaultProps

export default memo(FundToolbar)
