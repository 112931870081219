import { config } from '../../../config'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { find, intersection, reduce, isEqual } from 'lodash'
import ReactGridLayout from 'react-grid-layout-modified'
import { WidgetList } from '../../../data/widget'
import { NoContentMessage, Spinner } from '../../../components'
import WidgetOptions from '../../../containers/widget/index'
import WidgetError from '../../../components/widget/error/widgetError.component'
import WidgetUnsubscribed from '../../../containers/widget/unsubscribed/widgetUnsubscribed.container'
import './dashboardView.component.css'

class DashboardView extends Component {
  shouldComponentUpdate (nextProps) {
    const nextWidgets = nextProps.dashboard.widget
    const widgets = this.props.dashboard.widget
    const securityId = this.props.securityId
    const nextSecurityId = nextProps.securityId

    if (nextSecurityId !== securityId) {
      return true
    } else if (nextWidgets.length && widgets.length) {
      return !isEqual(nextWidgets.sort(), widgets.sort())
    } else {
      return nextProps.theme !== this.props.theme
    }
  }

    /**
     * Render Grid Layout
     * @returns {Array}
     */
    renderGridLayout = () => {
      const { dashboard, subscription, theme, securityId, tickerId, profile } = this.props

      const dashboardWidgets = dashboard.widget || []
      const dashboardLayout = (dashboard.layout && dashboard.layout.template) || []

      const activeSubscription = reduce((subscription || []), (accumulator, item) => {
        if (item.enabled) {
          accumulator.push(item.type)
        }
        return accumulator
      }, [])

      return dashboardLayout.map((layoutItem) => {
        const widget = find(dashboardWidgets, (widget) => parseInt(widget.layout_id, 10) === parseInt(layoutItem.i, 10))

        if (widget) {
          const _Widget = find(WidgetList, (Widget) => Widget.id === widget.id)

          if (!_Widget) {
            return (
              <div className='dashboard_widget' key={layoutItem.i}>
                <WidgetError theme={theme} message={'We\'re sorry but something went wrong.'} />
              </div>
            )
          }

          const Component = WidgetOptions[_Widget.name]
          const _options = _Widget.options || {}
          const availableSubscriptions = intersection(activeSubscription, _Widget.subscription || [])
          const widgetHasActiveSubscription = (_Widget.subscription || []).some((subscription) => activeSubscription.includes(subscription))
          const isUnlocked = widgetHasActiveSubscription ||
                          !_Widget.subscription.length ||
                          (_options.isPartialSubscription && widgetHasActiveSubscription)
          if (isUnlocked) {
            return (
              <div className='dashboard_widget' key={layoutItem.i}>
                <Component
                  config={widget.config || {}}
                  options={_options}
                  layout={layoutItem}
                  subscriptions={availableSubscriptions}
                  description={_Widget.description}
                  dashboardId={dashboard._id}
                  profile={profile}
                  securityId={securityId}
                  tickerId={tickerId}
                />
              </div>
            )
          } else {
            return (
              <div className='dashboard_widget' key={layoutItem.i}>
                <WidgetUnsubscribed title={_Widget.title} subscriptions={_Widget.subscription} />
              </div>
            )
          }
        } else {
          return (
            <div className='dashboard_widget dashboard_widget--empty' key={layoutItem.i} />
          )
        }
      })
    }

    /**
     * Render Dashboard View
     * @returns {XML}
     */
    render () {
      const { dashboard, theme, editActiveDashboard } = this.props

      if (!dashboard || !dashboard.layout || !dashboard.widget) {
        return (
          <div className='dashboard_item q4-fade-in'>
            <div className='dashboard_contain dashboard_inner'>
              <Spinner />
            </div>
          </div>
        )
      }

      const isDark = theme === 'dark'
      const isEmpty = !(dashboard && dashboard.widget && dashboard.widget.length)

      return (
        <div className='dashboard_item q4-fade-in'>
          <div className={`dashboard_contain dashboard_inner ${isEmpty ? 'dashboard_inner--empty' : ''}`}>
            {!isEmpty
              ? (
                <ReactGridLayout
                  className='react-grid'
                  layout={dashboard.layout.template}
                  width={config.dashboard.width}
                  containerPadding={[0, 0]}
                  autoSize
                  cols={4}
                  isDraggable={false}
                  isResizable={false}
                >
                  {this.renderGridLayout()}
                </ReactGridLayout>
                )
              : (
                <NoContentMessage
                  theme={theme}
                  icon='q4i-dashboard-2pt'
                  title='Empty Dashboard'
                  message={(
                    <span>Imagine the possibilities.<br />Build your own custom dashboard.</span>
                          )}
                  actions={[
                    {
                      label: 'Add Widget',
                      theme: isDark ? 'citrus' : 'rain',
                      onClick: editActiveDashboard
                    }
                  ]}
                />
                )}
          </div>
        </div>
      )
    }
}

DashboardView.propTypes = {
  dashboard: PropTypes.object.isRequired
}

export default DashboardView
