import moment from 'moment-timezone'
import { getQuarterEndDates, DEFAULT_DATE_FORMAT } from '../date.util'
import { groupBy } from 'lodash'
import { formatStockPrice, formatCurrency } from '../stock'

const HALF_QUARTER = 45
const HALF_YEAR = 182
export const QUARTER_TO_YEAR_LIMIT = 8
/**
 * Format GQL stock
 * @param stock
 */
export const chartStock = (stock) => {
  return (stock || [])
    .map((value) => value && ({
      x: moment.utc(value.date, 'MM/DD/YYYY').endOf('day').valueOf(),
      y: formatStockPrice(value.last,value.currency),
      currency: formatCurrency(value.currency)
    }))
    .reverse()
}

/**
 * Format legacy API stock
 * @param stock
 */
 export const chartStockLegacy = (stock) => {
  return (stock || [])
  .map((record) => ({
    x: moment.utc(record.Date, 'M/DD/YYYY').endOf('day').valueOf(),
    y: formatStockPrice(record.Last,record.Currency),
    currency: formatCurrency(record.Currency)
  }))
  .reverse()
}

export const getStockChartMargins = (chartRange) => {
  if (chartRange === 'quarter') {
    return HALF_QUARTER
  }
  return HALF_YEAR
}

export const fitStockDataInChartDates = (stockData, chartRange) => {
  if (stockData && stockData.length) {
    const today = moment.utc().startOf('day')
    const currentQuarter = moment.utc().endOf('quarter').subtract(1, 'quarter').startOf('day')
    const currentYear = moment.utc().startOf('year').startOf('day')
    const differenceToAdd = chartRange === 'quarter'
      ? getStockChartMargins(chartRange) - today.diff(currentQuarter, 'days')
      : getStockChartMargins(chartRange) - today.diff(currentYear, 'days')
    const updatedDate = moment.utc().startOf('day')
    // add empty values to ensure chart not drawn after todays date
    for (let index = 0; index < differenceToAdd; index++) {
      updatedDate.add(1, 'day')
      stockData.push({
        x: moment.utc(updatedDate).endOf('day').valueOf(),
        y: null
      })
    }
    // remove values to ensure latest date on graph matches stock
    if (differenceToAdd < 0) {
      stockData = stockData.slice(0, stockData.length + differenceToAdd)
    }
    return stockData
  }
  return []
}

/**
 * Format historical position for entity chart
 * @param props
 * @returns {{}[]}
 */
export const entityChartPosition = ({ position = {}, quarters = 4, symbol = '', inYears, getLatestRecord }) => {
  const dates = getQuarterEndDates(quarters, moment.utc())

  const records = (dates || []).map((date, idx) => {
    return {
      description: symbol,
      x: moment.utc(date, DEFAULT_DATE_FORMAT).valueOf(),
      y: position[`q${idx + 1}Value`],
      change: position[`q${idx + 1}Change`]
    }
  })

  return inYears
    ? _getRecords(records, getLatestRecord)
      .map((record) => ({
        ...record,
        x: moment.utc(record.x).endOf('year').valueOf(),
        y: record.y || 0
      }))
      .reverse()
    : records.reverse()
}

/**
 * Format stock flag activity
 * @param data
 * @returns {{}[]}
 */
export const chartActivities = (data) => {
  return (data || [])
    .filter((record) => record.start && record.start.date)
    .map((record) => ({
      x: record.start.date,
      title: ' ',
      text: record.title,
      id: record.id,
      category: record.category
    }))
    .reverse()
}

/**
 * Get the records from current position data
 * @param records
 * @param getLatestReport
 * @returns {{}[]}
 */
const _getRecords = (records, getLatestRecord) => {
  if (getLatestRecord) {
    const groupedRecords = groupBy(records, (record) => moment.utc(record.x).format('YYYY'))
    return Object.keys(groupedRecords).map((key) =>
      groupedRecords[key].find((record) => record.y !== null) || groupedRecords[key][0]
    ).reverse()
  } else {
    return records.filter((record, idx) => (idx === 0) || (record.x && moment.utc(record.x).format('MM') === '12'))
  }
}
