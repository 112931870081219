import { get } from 'lodash'
import { useEffect } from 'react'
import { useFundQuery, useTargetQuery } from '../../../../../../fund/hook'

export const useFundTargetQuery = ({ entityId }) => {
  const { data: fundResponse, ...remainingFund } = useFundQuery({
    variables: { id: entityId }
  })

  const fund = get(fundResponse, 'fund.items[0]', {})
  const { id: fundId } = fund

  const [getTarget, { data: targetResponse, ...remainingTarget }] = useTargetQuery({
    variables: { entityId: fundId }
  })
  const target = get(targetResponse, 'target.items[0]', {})
  
  useEffect(() => {
    if (!fundId) {
      return
    }

    getTarget()
  }, [fundId, getTarget])

  return {
    fundQuery: {
      fund,
      ...remainingFund,
    },
    targetQuery: {
      target,
      getTarget,
      ...remainingTarget,
    },
  }
}
