import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const GET_REPORT_BUILDER_LIST_REQUEST = 'GET_REPORT_BUILDER_LIST_REQUEST'
export const GET_REPORT_BUILDER_LIST_SUCCESS = 'GET_REPORT_BUILDER_LIST_SUCCESS'
export const GET_REPORT_BUILDER_LIST_ERROR = 'GET_REPORT_BUILDER_LIST_ERROR'

export const GET_REPORT_BUILDER_ITEM_REQUEST = 'GET_REPORT_BUILDER_ITEM_REQUEST'
export const GET_REPORT_BUILDER_ITEM_SUCCESS = 'GET_REPORT_BUILDER_ITEM_SUCCESS'
export const GET_REPORT_BUILDER_ITEM_ERROR = 'GET_REPORT_BUILDER_ITEM_ERROR'

export const SAVE_REPORT_BUILDER_ITEM_REQUEST = 'SAVE_REPORT_BUILDER_ITEM_REQUEST'
export const SAVE_REPORT_BUILDER_ITEM_SUCCESS = 'SAVE_REPORT_BUILDER_ITEM_SUCCESS'
export const SAVE_REPORT_BUILDER_ITEM_ERROR = 'SAVE_REPORT_BUILDER_ITEM_ERROR'

export const DELETE_REPORT_BUILDER_ITEM_REQUEST = 'DELETE_REPORT_BUILDER_ITEM_REQUEST'
export const DELETE_REPORT_BUILDER_ITEM_SUCCESS = 'DELETE_REPORT_BUILDER_ITEM_SUCCESS'
export const DELETE_REPORT_BUILDER_ITEM_ERROR = 'DELETE_REPORT_BUILDER_ITEM_ERROR'

export const STORE_REPORT_BUILDER_ITEM = 'STORE_REPORT_BUILDER_ITEM'
export const SET_REPORT_BUILDER_ITEM_FETCHED = 'SET_REPORT_BUILDER_ITEM_FETCHED'
export const RESET_REPORT_BUILDER_ITEM_STATUS = 'RESET_REPORT_BUILDER_ITEM_STATUS'

/**
 * Get Report List
 * @private
 */
const _getReportBuilderList = (payload) => ({
  [CALL_API]: {
    types: [
      GET_REPORT_BUILDER_LIST_REQUEST,
      GET_REPORT_BUILDER_LIST_SUCCESS,
      GET_REPORT_BUILDER_LIST_ERROR
    ],
    payload,
    method: METHOD_TYPE.GET,
    endpoint: '/report/builderItems'
  }
})

/**
 * Get Report Fields
 * @private
 */
const _getReportBuilderItem = (_id) => ({
  [CALL_API]: {
    types: [
      GET_REPORT_BUILDER_ITEM_REQUEST,
      GET_REPORT_BUILDER_ITEM_SUCCESS,
      GET_REPORT_BUILDER_ITEM_ERROR
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/report/builderItem/${_id}`
  }
})

/**
 * Create new Report
 * @private
 */
const _saveReportBuilderItem = (payload, method, endpoint) => ({
  [CALL_API]: {
    types: [
      SAVE_REPORT_BUILDER_ITEM_REQUEST,
      SAVE_REPORT_BUILDER_ITEM_SUCCESS,
      SAVE_REPORT_BUILDER_ITEM_ERROR
    ],
    method,
    payload,
    endpoint
  }
})

/**
 * Delete Report
 * @param _id
 * @private
 */
const _deleteReportBuilderItem = (_id) => ({
  [CALL_API]: {
    types: [
      DELETE_REPORT_BUILDER_ITEM_REQUEST,
      DELETE_REPORT_BUILDER_ITEM_SUCCESS,
      DELETE_REPORT_BUILDER_ITEM_ERROR
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: `/report/builderItem/${_id}`
  }
})

/**
 * Get Report List
 * @param params
 */
export const getReportBuilderList = (params) => (dispatch) => {
  return dispatch(_getReportBuilderList(params))
}

/**
 * Get Report Data Item by ID
 * @param _id
 */
export const getReportBuilderItem = (_id) => (dispatch) => {
  return dispatch(_getReportBuilderItem(_id))
}

/**
 * Save Report
 * @param ReportBuilderItem
 * @param _id
 * @returns {function(*): *}
 */
export const saveReportBuilderItem = (ReportBuilderItem, _id) => (dispatch) => {
  const method = _id ? METHOD_TYPE.PUT : METHOD_TYPE.POST
  const endpoint = _id ? `/report/builderItem/${_id}` : '/report/builderItem'

  return dispatch(_saveReportBuilderItem(ReportBuilderItem, method, endpoint))
}

/**
 * Delete Report
 * @param _id
 */
export const deleteReportBuilderItem = (_id) => (dispatch) => {
  return dispatch(_deleteReportBuilderItem(_id))
}

export const storeReportBuilderItem = (report) => (dispatch) => {
  return dispatch({ type: STORE_REPORT_BUILDER_ITEM, payload: report })
}

export const setReportBuilderItemFetched = () => (dispatch) => {
  return dispatch({ type: SET_REPORT_BUILDER_ITEM_FETCHED })
}

export const resetReportBuilderItemStatus = () => (dispatch) => {
  return dispatch({ type: RESET_REPORT_BUILDER_ITEM_STATUS })
}
