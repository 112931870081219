import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'

// components
import { Modal } from '../../../index'
import { HtmlEditor } from '../../../shared/form'

// utils
import { combine, normalizeBody, toolbarOptions, ENTITY_TYPE, THEMES } from '../../../../utils'
import { get } from 'lodash'

import './edit.component.scss'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE
const propTypes = {
  dataId: PropTypes.string,
  isCard: PropTypes.bool,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  entity: PropTypes.shape({
    entityId: PropTypes.string,
    entityType: PropTypes.oneOf([CONTACT, FUND, INSTITUTION]),
    entityConnection: PropTypes.object
  }).isRequired,
  entityComment: PropTypes.shape({
    id: PropTypes.string,
    entity: PropTypes.object,
    comment: PropTypes.string
  }),
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

const defaultProps = {
  loading: false,
  placeholder: 'Enter some background information, investment rationale, or any over-arching note for the profile...',
  entity: {},
  entityComment: {}
}

/**
 * Entity detail component
 * @param entity
 */
const Entity = ({ entity }) => {
  const { entityType, entityConnection } = (entity || {})
  const { fullName, fundName, institutionName, jobs } = (entityConnection || {})

  const name = fullName || fundName || institutionName || '-'
  const title = (entityType === CONTACT) ? get(jobs, '[0].title', null) : null
  const institution = (entityType === CONTACT)
    ? get(jobs, '[0].institutionName', null)
    : (entityType === FUND) ? institutionName : null

  return <div className='entity'>
    <i className={`entity_icon entity_icon--${entityType} q4i-${entityType}-2pt`} />
    <div className='entity_content'>
      <div className='entity_content-name'>{combine([name, title], ' - ')}</div>
      {institution && <div className='entity_content-detail'>{institution}</div>}
    </div>
  </div>
}

/**
 * Comment Edit (Create/Update/Delete) Component
 * @param props
 */
function EditModal (props) {
  const { dataId, isCard, loading, placeholder, entity, entityComment, onSave, onClose } = props
  const { id } = entityComment

  const [comment, setComment] = useState(entityComment.comment || '')

  /**
   * Handle comment change
   * @param html
   */
  const handleChange = ({ html }) => {
    setComment(normalizeBody(html))
  }

  /**
   * Handle submit
   */
  const handleSubmit = () => {
    onSave && onSave(comment)
  }

  return (
    <Modal
      dataId={`${dataId}Modal`}
      visible
      scrollable
      loading={loading}
      headerSize='base'
      className='comment_edit-modal'
      title={`${id ? 'Edit' : 'Add'} Comment`}
      footerButtons={[
        {
          dataId: `${dataId}Cancel`,
          label: 'Cancel',
          ui: 'shaded',
          onClick: onClose
        },
        {
          dataId: `${dataId}Save`,
          label: 'Save',
          ui: THEMES.CITRUS,
          onClick: handleSubmit
        }]}
      onClose={onClose}
    >
      {!isCard ? <div className='subtext'>Any changes will also be saved to the profile's Comment card.</div> : null}
      {entity.entityConnection ? <Entity entity={entity} /> : null}
      <HtmlEditor
        dataId={`${dataId}Editor`}
        html={comment || ''}
        toolbar={toolbarOptions}
        placeholder={placeholder}
        onChange={handleChange}
      />
    </Modal>
  )
}

EditModal.propTypes = propTypes
EditModal.defaultProps = defaultProps

export default memo(EditModal)
