import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// utils
import { getClassName, TEMPLATES } from '../../../../utils'
import { chunk, get } from 'lodash'

import './thumbnail.component.scss'

const propTypes = {
  dataId: PropTypes.string,
  settings: PropTypes.array.isRequired,
  templates: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

const defaultProps = {
  settings: [],
  templates: []
}

/**
 * Thumbnail Component
 * @param props
 */
function Thumbnail ({ dataId, data, settings, onSelect }) {
  const { id, type, name, description, content } = (data || {})
  const columns = chunk(content, 5)

  const selected = settings.find((item) => item.isSelected)
  const handleSelect = () => onSelect(settings.map((item) => ({ ...item, isSelected: item.templateId === id })))

  return (
    <Grid item xs={12} data-id={dataId} onClick={handleSelect}>
      <div className={getClassName('tearsheet-export-modal_thumbnail', [
        { condition: get(selected, 'templateId') === id, trueClassName: 'tearsheet-export-modal_thumbnail--selected' }
      ])}>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <i className={`icon q4i-${type}`} />
            <div className='name'>{name}</div>
          </Grid>
          <Grid item xs={10}>
            <div className='description'>{description}</div>
            <div className='content'>
              {(columns || []).map((column, index) => <ul key={`${type}-column-${index}`}>
                {(column || []).map((row, idx) => <li key={`${type}-row-${idx}`} className='content-item'>{row}</li>)}
              </ul>)}
            </div>
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}

/**
 * Briefing Book Template Thumbnail
 * @param props
 */
function TemplateThumbnail (props) {
  const { dataId, settings, templates, onChange } = props

  return (
    <Grid container spacing={3}>
      {(templates || []).map((template) => {
        const { type, name } = (template || {})
        return <Thumbnail
          dataId={`${dataId}${name}`}
          key={type}
          data={{ ...TEMPLATES[type], ...template }}
          settings={settings}
          onSelect={onChange}
        />
      })}
    </Grid>
  )
}

TemplateThumbnail.propTypes = propTypes
TemplateThumbnail.defaultProps = defaultProps

export default memo(TemplateThumbnail)
