import {
  FETCH_PEER_STOCKQUOTE_HISTORICAL_REQUEST,
  FETCH_PEER_STOCKQUOTE_HISTORICAL_SUCCESS,
  FETCH_PEER_STOCKQUOTE_HISTORICAL_ERROR,
  REMOVE_STOCK_HISTORICAL_PEER,
  STOCK_PEER_REQUEST,
  STOCK_PEER_FAILURE,
  STOCK_PEER_SUCCESS,
  IDLE,
  FETCHED,
  FETCHING,
  ERROR
} from '../../../actions/widget/stock'

import { times, find } from 'lodash'

const initialState = {
  status: IDLE,
  peers: [],
  peerColors: Array.prototype.concat.apply([], (times(4, () => [
    {
      value: '#fc7e26'
    },
    {
      value: '#804a8c'
    },
    {
      value: '#2ecc71'
    },
    {
      value: '#aa2d40'
    },
    {
      value: '#94e9f6'
    },
    {
      value: '#297ac5'
    },
    {
      value: '#3a925f'
    },
    {
      value: '#e74c3c'
    },
    {
      value: '#f1c40f'
    },
    {
      value: '#d95608'
    },
    {
      value: '#6a3476'
    },
    {
      value: '#a4305e'
    },
    {
      value: '#0da084'
    },
    {
      value: '#10528f'
    },
    {
      value: '#935f39'
    }
  ])))
}

const peerStockQuoteHistorical = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PEER_STOCKQUOTE_HISTORICAL_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_PEER_STOCKQUOTE_HISTORICAL_SUCCESS:
      const peers = state.peers.map(
        (peer) => {
          const data = (action.payload || []).find((stockHistorical) => stockHistorical.security === peer._security)

          if (data) {
            return { ...peer, items: data.items }
          } else {
            return peer
          }
        })
      return {
        ...state,
        status: FETCHED,
        peers

      }
    case FETCH_PEER_STOCKQUOTE_HISTORICAL_ERROR:
      return {
        ...state,
        error: action.error,
        status: ERROR
      }
    case REMOVE_STOCK_HISTORICAL_PEER:
      return {
        ...state,
        peers: state.peers.map(
          (peer) => peer._security === action.payload._security ? { ...peer, items: [] }
            : peer)
      }
    case STOCK_PEER_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case STOCK_PEER_SUCCESS:
      if (action.payload && action.payload.length) {
        action.payload.forEach((peer, index, array) => {
          const item = find(state.peers, (statePeer) => statePeer._security === peer._security)

          if (item && item.items && item.color) {
            peer.items = item.items
          }

          array[index].color = initialState.peerColors[index].value
        })
      }
      return {
        ...state,
        status: FETCHED,
        peers: action.payload
      }
    case STOCK_PEER_FAILURE:
      return {
        error: action.error,
        status: ERROR
      }
    default:
      return state
  }
}

export default peerStockQuoteHistorical
