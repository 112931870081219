import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'

import './table.component.css'
import CustomCell from './cell/cell.component'
import { AgGrid, NoContentMessage, Spinner } from '../../../../components'
import { setColumnDefinition, THEMES } from '../../../../utils'

const propTypes = {
  dataId: PropTypes.string,
  pageSizeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  listOptions: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  noFilter: PropTypes.bool.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onBulkAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  loading: false,
  data: [],
  total: 0,
  listOptions: {},
  noFilter: true
}

const columns = [
  {
    field: 'select',
    headerName: '',
    maxWidth: 75,
    headerCheckboxSelection: true,
    checkboxSelection: true

  },
  {
    field: 'security',
    headerName: 'SECURITY',
    headerClass: 'col-multiline ag-header-cell--odd',
    cellStyle: {
      'background-color': '#FFFFFF'
    },
    type: 'text',
    minWidth: 520
  },
  {
    field: 'stock',
    headerName: 'STOCK',
    headerClass: 'col-multiline ag-header-cell--even ag-header-cell--centered',
    cellStyle: {
      'background-color': '#EEEEEE'
    },
    type: 'centered',
    minWidth: 120,
    maxWidth: 120
  },
  {
    field: 'trend',
    headerName: '1M TREND',
    headerClass: 'col-multiline ag-header-cell--odd ag-header-cell--centered',
    cellStyle: {
      'background-color': '#FFFFFF'
    },
    type: 'centered',
    minWidth: 120,
    maxWidth: 120
  },
  {
    field: 'performance',
    headerName: 'PERFORMANCE (ACTUAL & STOCK)',
    headerClass: 'col-multiline ag-header-cell--even',
    cellStyle: {
      'background-color': '#EEEEEE'
    },
    type: 'centered',
    minWidth: 140,
    maxWidth: 140
  }]

/**
 * Peer List Table Component
 * @param props
 */
function PeerListTable (props) {
  const {
    dataId, pageSizeId, loading, data, total, listOptions, noFilter,
    onQueryChange, onBulkAction, history
  } = props
  const [agGrid, setAgGrid] = useState({})

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click event
   * @param id {String}
   */
  const handleRowClick = (event) => {
    const { node } = event
    node && node.setSelected(!node.selected)
  }

  /**
   * Handle row click event
   * @param id {String}
   */
  const handleCellClick = (event) => {
    const { colDef, data } = event
    if (colDef && colDef.field !== 'select') {
      const { tickerId } = data
      history && history.push(`/security/${tickerId}`)
    }
  }

  /**
   * Handle page change
   * @param selected
   */
  const handlePageChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, page: selected }
    })
  }

  /**
   * Handle page size change
   * @param selected
   */
  const handlePageSizeChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, limit: selected, page: 1 }
    })
  }

  /**
   * Handle AgGrid bulk delete
   */
  const handleBulkDelete = () => {
    const selectedRows = agGrid.api && agGrid.api.getSelectedRows()
    const selectedIds = (selectedRows || []).map((row) => row && row.id)

    if (!selectedIds || !selectedIds.length) {
      return
    }

    onBulkAction(selectedIds)
  }

  /**
   * Get NoContentMessage Props
   * @returns {{*}}
   */
  const getNoContentMessageProps = () => {
    return noFilter
      ? {
          title: loading ? ' ' : 'Peer List is empty'
        }
      : null
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell dataId={dataId} history={history} {...props} />
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage {...getNoContentMessageProps()} />
        : <AgGrid
          domLayout='autoHeight'
          sizeToFit
          className='peer-list-grid'
          rowHeight={70}
          headerHeight={52}
          // suppress configs
          suppressMovableColumns
          suppressContextMenu

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            sortable: false,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={setColumnDefinition({ columns, isLocked: false })}
          rowData={data}

          // bulk actions
          bulkActions={[{
            id: 'peer-list-delete',
            icon: 'q4i-trashbin-2pt',
            onSelect: handleBulkDelete
          }]}

          // pagination
          pagination
          paginationProps={{
            pageSizeId,
            forcePage: listOptions.page,
            initialPageSize: listOptions.limit,
            total,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: renderCustomCell
          }}

          // event listeners
          onGridReady={handleGridReady}
          onGridSizeChanged={handleGridResize}
          onRowClicked={handleRowClick}
          onCellClicked={handleCellClick}
        />}
    </div>
  )
}

PeerListTable.propTypes = propTypes
PeerListTable.defaultProps = defaultProps

export default memo(PeerListTable)
