import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './routeLink.component.scss'

const propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
}

/**
 * https://github.com/ReactTraining/react-router/issues/1147#issuecomment-362964577
 * Router aware Link component
 * @param props
 * @returns {*}
 */
function RouteLink ({ to, className, children, ...props }) {
  // It is a simple element with nothing to link to
  if (!to) {
    const baseClassName = `${className || ''} route-link--disabled`
    return <span className={baseClassName} {...props}>{children}</span>
  }

  // It is intended to be an external link
  if (/^https?:\/\//.test(to)) {
    return <a href={to} className={className} {...props}>{children}</a>
  }

  // Finally, it is an internal link
  return <Link to={to} className={className} {...props}>{children}</Link>
}

RouteLink.propTypes = propTypes

export default memo(RouteLink)
