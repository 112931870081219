import React from 'react'
import { useAdvancedSearchQuery } from './hook'

// This higher order component is used to wrap the search query hook so we can use standardized
// hook implementation in the future without refactoring this existing class component.
export const SearchQueryComponent = (Component) => {
  return (props) => {
    const [getGQLSearchResults, { loading: gqlLoading, data: gqlData }] = useAdvancedSearchQuery()

    return <Component gqlData={gqlData} gqlLoading={gqlLoading} getGQLSearchResults={getGQLSearchResults} {...props} />
  }
}
