import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// components
import { DealAttribute, DealDescription, DealEntity } from '../../../../components'
import Progress from './progress/progress.component'

// utils
import { combine, formatDate, formatLocalizedDate, DEFAULT_DATE_FORMAT } from '../../../../utils'
import { get } from 'lodash'

const propTypes = {
  dataIdPrefix: PropTypes.string.isRequired,
  deal: PropTypes.object.isRequired,
  pipeline: PropTypes.array.isRequired,
  stock: PropTypes.number.isRequired,
  subscription: PropTypes.string.isRequired
}

const defaultProps = {
  deal: {},
  pipeline: []
}

/**
 * Get create/update date
 * @param props
 */
function getTimestamp ({ label, date, profile }) {
  const timestamp = combine([formatDate(date), formatDate(date, 'h:mmA')], ' ')
  const name = profile && combine([profile.firstName, profile.lastName], ' ')

  return (
    <div className='timestamp-audit'>
      <div className='timestamp-audit_label'>{label}</div>
      <div className='timestamp-audit_value'>{formatLocalizedDate(timestamp, DEFAULT_DATE_FORMAT)}{formatDate(date, 'h:mmA') && ` ${formatDate(date, 'h:mmA') }`} {(name && name.length) && ` by ${name}`}</div>
    </div>
  )
}

/**
 * Deal Overview Component
 * @param props
 */
function DealOverview (props) {
  const { dataIdPrefix, deal, pipeline, stock, subscription, tickerId } = props
  const {
    type, amount = 0, usePurchasingPower, start, end, description, entity,
    pipelineConnection, profileConnection, revisions = [], created
  } = deal
  const stage = get(pipelineConnection, '0', {})
  const latestRevision = (revisions.length && revisions.length > 1) ? revisions[revisions.length - 1] : null

  return (
    <div className='overview-layout'>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Progress
            dataId={dataIdPrefix}
            stage={stage}
            pipeline={pipeline}
            revisions={revisions}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DealAttribute
                dataId={dataIdPrefix}
                data={{ type, amount, usePurchasingPower, start, end, stage, revisions }}
                stock={stock}
              />
            </Grid>
            <Grid item xs={12}>
              <DealDescription
                dataId={dataIdPrefix}
                description={description}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DealEntity
                dataId={dataIdPrefix}
                entity={entity}
                subscription={subscription}
                tickerId={tickerId}
              />
            </Grid>
            <Grid item xs={12}>
              {getTimestamp({ label: 'Created', date: created, profile: get(profileConnection, 'items[0]') })}
              {latestRevision ? getTimestamp({
                label: 'Updated',
                date: latestRevision.date,
                profile: get(latestRevision, 'profileConnection.items[0]')
              }) : ''}
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </div>
  )
}

DealOverview.propTypes = propTypes
DealOverview.defaultProps = defaultProps

export default memo(DealOverview)
