import {
  GET_STOCK_REQUEST,
  GET_STOCK_SUCCESS,
  GET_STOCK_FAILURE,
  RESET_STOCK,
  statusType
} from '../../actions'

const initial = {
  data: {},
  status: statusType.IDLE
}

const quote = (state = initial, action) => {
  switch (action.type) {
    case GET_STOCK_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_STOCK_SUCCESS:
      return {
        data: action.payload,
        status: statusType.SUCCESS
      }

    case GET_STOCK_FAILURE:
      return {
        error: action.error,
        status: statusType.ERROR
      }

    case RESET_STOCK:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default quote
