import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AgGrid, Message, PopoverMenu, Spinner } from '../../../../../components';
import { ENTITY_TYPE, getDefaultColumnDef, preventDefaultRowClick, getActivityTypeOptions, isParent } from '../../../../../utils';
import CustomCellRender from './cell/cell.component';

import './list.component.css';

const autoGroupColumn = {
    ...getDefaultColumnDef({ columnIndex: 0, isAlternating: false }),
    pinned: 'left',
    lockPinned: true,
    minWidth: 60,
    maxWidth: 60,
    headerName: '',
    cellRendererParams: {
        suppressCount: true,
        suppressPadding: true,
    },
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: 'CustomCellRender',
    sortable: false
};

const columns = [
    // pinned columns
    { ...getDefaultColumnDef({ columnIndex: 1, type: 'icon', isAlternating: false }), field: 'category', headerName: 'Type', minWidth: 80, maxWidth: 80, pinned: 'left', lockPinned: true},
    { ...getDefaultColumnDef({ columnIndex: 2, type: 'text', isAlternating: false }), field: 'title', minWidth: 300, pinned: 'left', lockPinned: true },
    // regular columns with alternating styles
    { ...getDefaultColumnDef({ columnIndex: 4 }), field: 'contact', minWidth: 180, maxWidth: 220 },
    { ...getDefaultColumnDef({ columnIndex: 5 }), field: 'institution', minWidth: 180, maxWidth: 220 },
    { ...getDefaultColumnDef({ columnIndex: 6 }), field: 'address', headerName: 'Location', minWidth: 180, maxWidth: 220 },
    { ...getDefaultColumnDef({ columnIndex: 7, type: 'date' }), field: 'start', headerName: 'Start Date', minWidth: 120, maxWidth: 120, sort: 'desc' },
    { ...getDefaultColumnDef({ columnIndex: 8 }), field: 'tag', headerName: 'Tags', minWidth: 220, maxWidth: 260, sortable: false },
];

class ActivityListComponent extends PureComponent {

    static propTypes = {
        isGridReady: PropTypes.bool.isRequired,
        loading: PropTypes.bool.isRequired,

        data: PropTypes.arrayOf(PropTypes.shape({
            _id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            category: PropTypes.oneOf(getActivityTypeOptions().map((type) => type.value)).isRequired,
            hierarchy: PropTypes.arrayOf(PropTypes.string).isRequired
        })).isRequired,

        filters: PropTypes.shape({
            page: PropTypes.number.isRequired,
            limit: PropTypes.number.isRequired
        }).isRequired,

        total: PropTypes.number.isRequired,
        handleFilterChange: PropTypes.func.isRequired,
        handleDeleteSelection: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            popoverMenuProps: null,
            isMessageOpen: false,
            selectedIds: []
        };
    }

    /**
     * Handle AgGrid onGridReady event
     * @param grid
     * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
     */
    handleGridReady = (grid) => {
        setTimeout(() => this.agGrid = grid, 0);
    };

    /**
     * Handle AgGrid gridSizeChanged event
     * @param type - event type
     */
    handleGridResize = ({ type }) => {
        if (type === 'gridSizeChanged') {
            setTimeout(() => this.agGrid && this.agGrid.api.sizeColumnsToFit(), 0);
        }
    };

    /**
     * Handle AgGrid Column Sort
     * @param grid
     */
    handleSortChange = (grid) => {
        const { handleFilterChange } = this.props;
        const api = grid && grid.api;
        const sortModel = api && api.getSortModel();

        if (!sortModel && !sortModel.length) {
            return;
        }

        handleFilterChange && handleFilterChange({
            sort: {
                property: sortModel[0].colId,
                direction: sortModel[0].sort
            },
            page: 1
        });
    };

    /**
     * Handle confirm message closing
     */
    handleMessageClose = () => {
        this.setState({
            isMessageOpen: false,
            selectedIds: []
        });
    };

    /**
     * Handle row click event
     * @param _id {String} - activity id
     */
    handleRowClicked = ({ _id }) => {
        _id && this.props.history.push(`/activity/${_id}`);
    };

    /**
     * On Expand Menu Click
     * Displays expandable menu for contact/institutions and tags
     * @param event
     * @param popoverMenuProps
     */
    onExpandMenuClick = (event, popoverMenuProps) => {
        event.stopPropagation();

        this.setState({ popoverMenuProps });
    };

    /**
     * On Expand Menu Close Click
     * @param event
     */
    onExpandMenuCloseClick = (event) => {
        event.stopPropagation();

        this.setState({ popoverMenuProps: null });
    };

    /**
     * On Grid Item Click
     * Handler for activity links item click
     * @param event
     * @param data
     */
    onItemClick = (event, data) => {
        event.stopPropagation();

        const { history } = this.props;
        const type = (data && data.entity_type) || '';

        if (data && data.reference) {
            return this.onTagClick(event, data);
        }

        switch (type.toLowerCase()) {
            case ENTITY_TYPE.CONTACT:
                history.push(`/${type}/${data.item}`);
                break;
            case ENTITY_TYPE.INSTITUTION:
                history.push(`/${type}/${data.entity_id}`);
                break;
            default:
                return;
        }
    };

    /**
     * On Tag Click
     * @param event
     * @param tag
     */
    onTagClick = (event, tag) => {
        event.stopPropagation();

        const { history } = this.props;

        history && history.push(`/search?query=${encodeURIComponent('#' + tag.name)}`);
    };

    /**
     * Handles AgGrid bulk delete
     */
    onBulkDeleteClick = () => {
        if (!this.agGrid) {
            return;
        }

        const selectedRows = this.agGrid.api.getSelectedRows();

        if(!selectedRows || !selectedRows.length) {
            return;
        }

        this.setState({
            selectedIds: (selectedRows || []).map((row) => row._id),
            isMessageOpen: true
        });
    };

    /**
     * Handle confirm message
     */
    onBulkDeleteConfirm = () => {
        const {handleDeleteSelection} = this.props;
        const {selectedIds} = this.state;

        handleDeleteSelection(selectedIds);
        this.handleMessageClose();
    };

    /**
     * Renders CustomCell Component
     */
    renderCustomCell = (props) => {
        return (
            <CustomCellRender
                {...props}
                onTagClick={this.onTagClick}
                onItemClick={this.onItemClick}
                onExpandMenuClick={this.onExpandMenuClick}
            />
        );
    };

    /**
     * Get Delete Confirmation Message based on number of selected activities
     */
    getDeleteConfirmMessage = () => {
        const {selectedIds} = this.state;
        const {data} = this.props;

        let message = (selectedIds && selectedIds.length > 1) ? 'Are you sure you want to delete these activities?' : 'Are you sure you want to delete these activity?';
        const hasItinerary = selectedIds && data && Array.isArray(data) && data.find((record) => (selectedIds.indexOf(record._id) >=0 && isParent(record.category)));

        if(hasItinerary) {
            message += '<br /> Note: Deleting them will also delete all Itinerary Activities.';
        }

        return message;
    };

    render() {
        const {
            data,
            total,
            filters: { page, limit },
            loading,
            isGridReady,
            handleFilterChange,
        } = this.props;

        return (
            <div className='activity-page_list'>
                {loading && <Spinner mask={true} theme='rain' />}
                {isGridReady && (
                    <AgGrid
                        className='data-table'
                        domLayout='autoHeight'
                        sizeToFit={true}

                        // suppress configs
                        suppressMovableColumns={true}
                        suppressContextMenu={true}

                        // columns and data
                        defaultColDef={{
                            suppressMenu: true,
                            sortable: true,
                            cellRenderer: 'CustomCellRender'
                        }}
                        columnDefs={columns}
                        rowData={data}

                        // bulk actions
                        bulkActions={[{
                            id: 'report-list-delete',
                            icon: 'q4i-trashbin-2pt',
                            onSelect: this.onBulkDeleteClick
                        }]}

                        // pagination
                        pagination={true}
                        paginationProps={{
                            pageSizeId: 'activity-list',
                            forcePage: page,
                            initialPageSize: limit,
                            showPageSizeSelection: true,
                            total,
                            onPageChange: ({selected}) => handleFilterChange({page: selected}),
                            onPageSizeChange: ({selected}) => handleFilterChange({page: 1, limit: selected}),
                        }}

                        // custom components
                        frameworkComponents={{
                            CustomCellRender: this.renderCustomCell
                        }}

                        // hierarchy grouping for expandable rows
                        treeData={true}
                        groupDefaultExpanded={0}
                        getDataPath={(data) => data.hierarchy}
                        autoGroupColumnDef={autoGroupColumn}

                        // event listeners
                        onGridReady={this.handleGridReady}
                        onGridSizeChanged={this.handleGridResize}
                        onSortChanged={this.handleSortChange}
                        onRowClicked={preventDefaultRowClick(
                            this.handleRowClicked,
                            ['cell-title_toggle','expandable-cell', 'tags-item']
                        )}

                        // css overwrites
                        rowClassRules={{
                            'ag-row--expanded': ({ data, node }) => data.itinerary || node.expanded || node.parent.expanded
                        }}
                        isPinned={data && data.length}
                    />
                )}

                {/* Popover Menu for Custom Cell */}
                {this.state.popoverMenuProps && (
                    <PopoverMenu
                        arrowClass='bottom-right'
                        onClose={this.onExpandMenuCloseClick}
                        onClick={this.onItemClick}
                        scrollable={true}
                        {...this.state.popoverMenuProps}
                    />
                )}

                <Message
                    visible={this.state.isMessageOpen}
                    type='warning'
                    title='Delete Activities?'
                    useHtml={true}
                    message={this.getDeleteConfirmMessage()}
                    buttons={[
                        {
                            ui: 'shaded',
                            label: 'cancel',
                            onClick: this.handleMessageClose
                        }, {
                            ui: 'spice',
                            label: 'confirm',
                            onClick: this.onBulkDeleteConfirm
                        }
                    ]}
                />
            </div>
        );
    }
}
export default ActivityListComponent;
