import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

// actions
import { getContactWebcast, clearContact, statusType } from '../../../../actions'

// components
import ContactWebcastToolbar from './toolbar/toolbar.component'
import ContactWebcastTable from './table/table.component'
import { getPageSizeFromStorage } from '../../../../utils'
import { get } from 'lodash'

const propTypes = {
  toolbarTheme: PropTypes.string,
  toolTheme: PropTypes.string,
  id: PropTypes.string.isRequired
}

const defaultProps = {}

const PAGE_SIZE_ID = 'contact-webcast-grid'

/**
 * Contact Webcast Grid Component
 * @param props
 */
function ContactWebcastGrid (props) {
  const { toolbarTheme, toolTheme, id, webcast, getContactWebcast, clearContact, history } = props
  const [state, setState] = useState({
    listOptions: {
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
  })

  useEffect(() => {
    id && getContactWebcast({ id })

    return () => {
      clearContact()
    }
  }, [id, getContactWebcast, clearContact])

  const { listOptions } = state
  const loading = get(webcast, 'status') === statusType.IN_PROGRESS
  const data = get(webcast, 'data', [])
  const total = get(webcast, 'total', 0)

  return (
    <>
      <ContactWebcastToolbar
        toolbarTheme={toolbarTheme}
        toolTheme={toolTheme}
      />
      <ContactWebcastTable
        pageSizeId={PAGE_SIZE_ID}
        loading={loading}
        data={data}
        total={total}
        listOptions={listOptions}
        onQueryChange={(query = {}) => setState(query)}
        history={history}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  webcast: get(state, 'contact.webcast')
})

const mapDispatchToProps = (dispatch) => ({
  getContactWebcast: bindActionCreators(getContactWebcast, dispatch),
  clearContact: bindActionCreators(clearContact, dispatch)
})

ContactWebcastGrid.propTypes = propTypes
ContactWebcastGrid.defaultProps = defaultProps

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactWebcastGrid))
