module.exports = [
  {
    'timezone': 'Etc/GMT+11',
    'label': 'American Samoa'
  },
  {
    'timezone': 'Pacific/Niue',
    'label': 'International Date Line West'
  },
  {
    'timezone': 'Pacific/Pago_Pago',
    'label': 'Midway Island'
  },
  {
    'timezone': 'Pacific/Honolulu',
    'label': 'Hawaii'
  },
  {
    'timezone': 'America/Anchorage',
    'label': 'Alaska'
  },
  {
    'timezone': 'PST8PDT',
    'label': 'Pacific Time (US and Canada)'
  },
  {
    'timezone': 'America/Tijuana',
    'label': 'Tijuana'
  },
  {
    'timezone': 'America/Phoenix',
    'label': 'Arizona'
  },
  {
    'timezone': 'America/Los_Angeles',
    'label': 'Los Angeles'
  },
  {
    'timezone': 'America/Vancouver',
    'label': 'Vancouver'
  },
  {
    'timezone': 'America/Chihuahua',
    'label': 'Chihuahua'
  },
  {
    'timezone': 'America/Mazatlan',
    'label': 'Mazatlan'
  },
  {
    'timezone': 'MST7MDT',
    'label': 'Mountain Time (US and Canada)'
  },
  {
    'timezone': 'CST6CDT',
    'label': 'Central Time (US and Canada)'
  },
  {
    'timezone': 'America/Chicago',
    'label': 'Chicago'
  },
  {
    'timezone': 'America/Mexico_City',
    'label': 'Mexico City'
  },
  {
    'timezone': 'America/Monterrey',
    'label': 'Monterrey'
  },
  {
    'timezone': 'America/Regina',
    'label': 'Saskatchewan'
  },
  {
    'timezone': 'America/Bogota',
    'label': 'Bogota'
  },
  {
    'timezone': 'Etc/GMT+5',
    'label': 'Eastern Time (US and Canada)'
  },
  {
    'timezone': 'America/Indiana/Knox',
    'label': 'Indiana (East)'
  },
  {
    'timezone': 'America/Lima',
    'label': 'Lima'
  },
  {
    'timezone': 'America/Santo_Domingo',
    'label': 'Quito'
  },
  {
    'timezone': 'America/Caracas',
    'label': 'Caracas'
  },
  {
    'timezone': 'America/Detroit',
    'label': 'Detroit'
  },
  {
    'timezone': 'America/La_Paz',
    'label': 'La Paz'
  },
  {
    'timezone': 'America/New_York',
    'label': 'New York'
  },
  {
    'timezone': 'America/Toronto',
    'label': 'Toronto'
  },
  {
    'timezone': 'America/Santiago',
    'label': 'Santiago'
  },
  {
    'timezone': 'America/Argentina/Buenos_Aires',
    'label': 'Buenos Aires'
  },
  {
    'timezone': 'America/Halifax',
    'label': 'Halifax'
  },
  {
    'timezone': 'America/Montevideo',
    'label': 'Montevideo'
  },
  {
    'timezone': 'Etc/GMT+2',
    'label': 'Mid-Atlantic'
  },
  {
    'timezone': 'Atlantic/Azores',
    'label': 'Azores'
  },
  {
    'timezone': 'Atlantic/Cape_Verde',
    'label': 'Cape Verde Islands'
  },
  {
    'timezone': 'Africa/Casablanca',
    'label': 'Casablanca'
  },
  {
    'timezone': 'Europe/Lisbon',
    'label': 'Lisbon'
  },
  {
    'timezone': 'Europe/London',
    'label': 'London'
  },
  {
    'timezone': 'Africa/Monrovia',
    'label': 'Monrovia'
  },
  {
    'timezone': 'Etc/UTC',
    'label': 'UTC'
  },
  {
    'timezone': 'Europe/Amsterdam',
    'label': 'Amsterdam'
  },
  {
    'timezone': 'Europe/Belgrade',
    'label': 'Belgrade'
  },
  {
    'timezone': 'Europe/Berlin',
    'label': 'Berlin'
  },
  {
    'timezone': 'Europe/Brussels',
    'label': 'Brussels'
  },
  {
    'timezone': 'Europe/Budapest',
    'label': 'Budapest'
  },
  {
    'timezone': 'Europe/Copenhagen',
    'label': 'Copenhangen'
  },
  {
    'timezone': 'Europe/Dublin',
    'label': 'Dublin'
  },
  {
    'timezone': 'Europe/Madrid',
    'label': 'Madrid'
  },
  {
    'timezone': 'Europe/Paris',
    'label': 'Paris'
  },
  {
    'timezone': 'Europe/Prague',
    'label': 'Prague'
  },
  {
    'timezone': 'Europe/Rome',
    'label': 'Rome'
  },
  {
    'timezone': 'Europe/Stockholm',
    'label': 'Stockholm'
  },
  {
    'timezone': 'Europe/Vienna',
    'label': 'Vienna'
  },
  {
    'timezone': 'Europe/Warsaw',
    'label': 'Warsaw'
  },
  {
    'timezone': 'Etc/GMT-1',
    'label': 'West Central Africa'
  },
  {
    'timezone': 'Europe/Zurich',
    'label': 'Zurich'
  },
  {
    'timezone': 'Europe/Athens',
    'label': 'Athens'
  },
  {
    'timezone': 'Europe/Bucharest',
    'label': 'Bucharest'
  },
  {
    'timezone': 'Africa/Cairo',
    'label': 'Cairo'
  },
  {
    'timezone': 'Europe/Helsinki',
    'label': 'Helsinki'
  },
  {
    'timezone': 'Asia/Jerusalem',
    'label': 'Jerusalem'
  },
  {
    'timezone': 'Africa/Johannesburg',
    'label': 'Johannesburg'
  },
  {
    'timezone': 'Europe/Kaliningrad',
    'label': 'Kaliningrad'
  },
  {
    'timezone': 'Europe/Kiev',
    'label': 'Kiev'
  },
  {
    'timezone': 'Europe/Luxembourg',
    'label': 'Luxembourg'
  },
  {
    'timezone': 'Europe/Monaco',
    'label': 'Monaco'
  },
  {
    'timezone': 'Europe/Oslo',
    'label': 'Oslo'
  },
  {
    'timezone': 'Europe/Riga',
    'label': 'Riga'
  },
  {
    'timezone': 'Europe/Sofia',
    'label': 'Sofia'
  },
  {
    'timezone': 'Europe/Tallinn',
    'label': 'Tallinn'
  },
  {
    'timezone': 'Europe/Vilnius',
    'label': 'Vilnius'
  },
  {
    'timezone': 'Asia/Baghdad',
    'label': 'Baghdad'
  },
  {
    'timezone': 'Europe/Istanbul',
    'label': 'Istanbul'
  },
  {
    'timezone': 'Europe/Minsk',
    'label': 'Minsk'
  },
  {
    'timezone': 'Europe/Moscow',
    'label': 'Moscow'
  },
  {
    'timezone': 'Africa/Nairobi',
    'label': 'Nairobi'
  },
  {
    'timezone': 'Asia/Riyadh',
    'label': 'Riyadh'
  },
  {
    'timezone': 'Europe/Volgograd',
    'label': 'Volgograd'
  },
  {
    'timezone': 'Asia/Tehran',
    'label': 'Tehran'
  },
  {
    'timezone': 'Asia/Baku',
    'label': 'Baku'
  },
  {
    'timezone': 'Asia/Dubai',
    'label': 'Dubai'
  },
  {
    'timezone': 'Europe/Samara',
    'label': 'Samara'
  },
  {
    'timezone': 'Asia/Tbilisi',
    'label': 'Tbilisi'
  },
  {
    'timezone': 'Asia/Yerevan',
    'label': 'Yerevan'
  },
  {
    'timezone': 'Asia/Kabul',
    'label': 'Kabul'
  },
  {
    'timezone': 'Asia/Yekaterinburg',
    'label': 'Ekaterinburg'
  },
  {
    'timezone': 'Asia/Karachi',
    'label': 'Karachi'
  },
  {
    'timezone': 'Asia/Tashkent',
    'label': 'Tashkent'
  },
  {
    'timezone': 'Asia/Kolkata',
    'label': 'Kolkata'
  },
  {
    'timezone': 'Asia/Kathmandu',
    'label': 'Kathmandu'
  },
  {
    'timezone': 'Asia/Almaty',
    'label': 'Almaty'
  },
  {
    'timezone': 'Asia/Dhaka',
    'label': 'Dhaka'
  },
  {
    'timezone': 'Asia/Urumqi',
    'label': 'Urumqi'
  },
  {
    'timezone': 'Asia/Rangoon',
    'label': 'Rangoon'
  },
  {
    'timezone': 'Asia/Bangkok',
    'label': 'Bangkok'
  },
  {
    'timezone': 'Asia/Jakarta',
    'label': 'Jakarta'
  },
  {
    'timezone': 'Asia/Krasnoyarsk',
    'label': 'Krasnoyarsk'
  },
  {
    'timezone': 'Asia/Novosibirsk',
    'label': 'Novosibirsk'
  },
  {
    'timezone': 'Asia/Hong_Kong',
    'label': 'Hong Kong'
  },
  {
    'timezone': 'Asia/Irkutsk',
    'label': 'Irkutsk'
  },
  {
    'timezone': 'Asia/Kuala_Lumpur',
    'label': 'Kuala Lumpur'
  },
  {
    'timezone': 'Australia/Perth',
    'label': 'Perth'
  },
  {
    'timezone': 'Asia/Singapore',
    'label': 'Singapore'
  },
  {
    'timezone': 'Asia/Shanghai',
    'label': 'Shanghai'
  },
  {
    'timezone': 'Asia/Taipei',
    'label': 'Taipei'
  },
  {
    'timezone': 'Asia/Ulaanbaatar',
    'label': 'Ulaanbaatar'
  },
  {
    'timezone': 'Asia/Seoul',
    'label': 'Seoul'
  },
  {
    'timezone': 'Asia/Tokyo',
    'label': 'Tokyo'
  },
  {
    'timezone': 'Asia/Yakutsk',
    'label': 'Yakutsk'
  },
  {
    'timezone': 'Australia/Adelaide',
    'label': 'Adelaide'
  },
  {
    'timezone': 'Australia/Darwin',
    'label': 'Darwin'
  },
  {
    'timezone': 'Australia/Brisbane',
    'label': 'Brisbane'
  },
  {
    'timezone': 'Pacific/Guam',
    'label': 'Guam'
  },
  {
    'timezone': 'Australia/Hobart',
    'label': 'Hobart'
  },
  {
    'timezone': 'Australia/Melbourne',
    'label': 'Melbourne'
  },
  {
    'timezone': 'Pacific/Port_Moresby',
    'label': 'Port Moresby'
  },
  {
    'timezone': 'Australia/Sydney',
    'label': 'Sydney'
  },
  {
    'timezone': 'Asia/Vladivostok',
    'label': 'Vladivostok'
  },
  {
    'timezone': 'Asia/Magadan',
    'label': 'Magadan'
  },
  {
    'timezone': 'Asia/Srednekolymsk',
    'label': 'Srednekolymsk'
  },
  {
    'timezone': 'Pacific/Auckland',
    'label': 'Auckland'
  },
  {
    'timezone': 'Pacific/Fiji',
    'label': 'Fiji'
  },
  {
    'timezone': 'Asia/Kamchatka',
    'label': 'Kamchatka'
  },
  {
    'timezone': 'Pacific/Chatham',
    'label': 'Chatham'
  }
]