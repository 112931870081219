import React from 'react'
import { formatDateByTimezone, formatLocalizedDate, isSameDate } from '../../../../utils'

/**
 * Information Component
 * @param props
 * @returns {*}
 * @constructor
 */
const Information = ({ start, end, address, isParent }) => {
  const startDate = (start && start.date_time) || null
  const endDate = (end && end.date_time) || null
  const startTimezone = (start && start.timezone) || null
  const endTimezone = (end && end.timezone) || null
  const dateFormat = 'MMMM DD, YYYY'

  return (
    <ul className='data data--clean'>
      <li className='data_item data_item--header'>Information</li>
      <li className='data_item'>
        <span className='data_block'>
          <i className='data_icon q4i-calendar-4pt' />
          <span className='data_value'>
            {startDate && isSameDate(startDate, endDate, 'MM/DD/YYYY')
              ? formatLocalizedDate(formatDateByTimezone(startDate, dateFormat, startTimezone), dateFormat)
              : `${formatLocalizedDate(formatDateByTimezone(startDate, dateFormat, startTimezone), dateFormat)} 
                ${endDate && `- ${formatLocalizedDate(formatDateByTimezone(endDate, dateFormat, endTimezone), dateFormat)}`}`
            }
          </span>
        </span>
        <span className='data_block'>
          {address && Array.isArray(address) && address.length
            ? address.map((value, index) => {
              return (value
                ? <span
                  key={index} className={`data_block ${(index !== 0) ? 'data_block--no-icon' : ''}`}>
                  {(index === 0) ? <i className='data_icon q4i-map-marker-4pt' /> : null}
                  <span className='data_value'>{value && value.location}</span>
                </span>
                : null
              )
            })
            : <>
              <i className='data_icon q4i-map-marker-4pt' />
              <span className='data_value'>-</span>
            </>}

        </span>
      </li>
    </ul>
  )
}

export default Information
