import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

// actions
import { openModal, modalType } from '../../actions'

// components
import { Banner } from '../../components'
import { SectionTab } from '../../components/shared'
import AllContainer from './tab/all/all.container'
import SavedContainer from './tab/saved/saved.container'

// utils
import { isSubscribed, AITARGETING, MESSAGE_TYPE, THEMES } from '../../utils'

import './targeting.page.scss'

const propTypes = {}
const defaultProps = {}

const TABS = {
  ALL: 'all',
  SAVED: 'saved'
}

/**
 * Targeting Page
 * @param props
 */
function TargetingPage (props) {
  const { profile, openModal } = props

  const hasSubscription = isSubscribed(profile, 'ai_targeting')
  const [active, setActive] = useState(TABS.ALL)

  /**
   * Handle info modal
   */
  const handleInfoClick = () => {
    openModal({
      component: modalType.INFO_MODAL,
      props: {
        type: MESSAGE_TYPE.SUCCESS,
        content: {
          title: 'AI Targeting',
          message: AITARGETING
        }
      }
    })
  }

  return (
    <div className='targeting-page'>
      <Banner
        title={hasSubscription ? 'AI Targeting' : 'Targeting'}
        icon='q4i-targeting-2pt'
        size='medium'
        disclosure={hasSubscription
          ? {
              type: 'info',
              onClick: handleInfoClick
            }
          : null}
      />
      <SectionTab
        compact
        rememberMounted
        theme={THEMES.SLATE}
        tabs={[{
          id: TABS.ALL,
          label: 'All Targets',
          view: <AllContainer />
        }, {
          id: TABS.SAVED,
          label: 'Saved Targets',
          view: <SavedContainer />
        }]}
        initialActiveTab={TABS.ALL}
        forceActiveTab={active}
        onTabChange={(tab) => tab && setActive(tab.id)}
      />
    </div>
  )
}

TargetingPage.propTypes = propTypes
TargetingPage.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  profile: state.profile.data
})

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TargetingPage))
