import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_ACTIVITY = gql`
  query Activity(
    $entityId: String
    $startDate: String
    $endDate: String
    $limit: Int!
  ) {
    activity(
      entityId: $entityId
      filter: {
        startDate: $startDate
        endDate: $endDate
      }
      limit: $limit
    ) {
      items {
        id
        category
        title
        start {
          date
        }
      }
    }
  }`

/**
 * Activity
 * @param options
 * @returns {{*}}
 */
export function useActivityQuery (options) {
  return useQuery(GET_ACTIVITY, options)
}
