import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { THEMES, getClassName } from '../../../utils/ui'
import './toggleList.component.css'

const ToggleList = (props) => {
  const { className, theme, items, initialOpenItems, accordion, onToggle } = props
  const [openItems, setOpenItems] = useState(initialOpenItems || [])

  if (!items || !items.length) {
    return null
  }

  const handleToggle = (toggledItemId) => (event) => {
    let newOpenItems = openItems

    if (openItems.indexOf(toggledItemId) > -1) {
      newOpenItems = openItems.filter((id) => id !== toggledItemId)
    } else {
      newOpenItems = [toggledItemId, ...openItems]
    }

    setOpenItems(accordion ? newOpenItems.slice(0, 1) : newOpenItems)
    onToggle && onToggle(toggledItemId, event)
  }

  const baseClassName = getClassName('toggle-list', [
    { condition: className, trueClassName: className },
    { condition: theme, trueClassName: `toggle-list--${theme}` }
  ])

  return (
    <div className={baseClassName}>
      {items.map((item) => {
        if (!item) {
          return null
        }

        const { className, id, title, panel } = item
        const isOpen = openItems.indexOf(id) > -1

        return (
          <Accordion
            key={`toggle-list-item--${id}`}
            classes={{
              root: className ? `${className} toggle-list_item` : 'toggle-list_item',
              expanded: className ? `${className} toggle-list_item toggle-list_item--open` : 'toggle-list_item toggle-list_item--open'
            }}
            square
            expanded={isOpen}
            onChange={handleToggle(id)}
          >
            <AccordionSummary
              aria-controls={`${id}-toggle-list-item-content`}
              id={`${id}-toggle-list-item-header`}
              classes={{
                root: 'toggle-list_header',
                focused: 'toggle-list_header toggle-list_header--focused',
                content: 'toggle-list_title',
                expandIcon: 'toggle-list_toggle'
              }}
              expandIcon={(
                <i className='q4i-caret-sm-down-4pt' />
              )}
              IconButtonProps={{
                disableRipple: true,
                disableTouchRipple: true
              }}
            >
              {title}
            </AccordionSummary>
            <AccordionDetails classes={{ root: 'toggle-list_panel' }}>
              {panel}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  )
}

ToggleList.propTypes = {
  /**
   * A custom className to pass into the component
   */
  className: PropTypes.string,

  /**
   * Used to paint the list items using a specific theme
   */
  theme: PropTypes.string,

  /**
   * Used to determine which items are open on initial render of the list
   */
  initialOpenItems: PropTypes.arrayOf(PropTypes.string),

  /**
   * Used to determine whether or not the toggle list acts like an accordion, where only one item is opened at a time
   */
  accordion: PropTypes.bool,

  /**
   * Items to pass into the component
   */
  items: PropTypes.arrayOf(PropTypes.shape({
    /**
     * A custom className to pass into the component
     */
    className: PropTypes.string,

    /**
     * A unique ID string used to identify the list item
     * Note: this value is used for managing open state and should be passed into initialOpenItems or forceOpenItems
     */
    id: PropTypes.string.isRequired,

    /**
     * Used to determine what to render inside the header of the list item
     */
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    /**
     * Used to determine what to render inside the panel of the list item
     */
    panel: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.arrayOf(PropTypes.object)])
  })).isRequired,

  /**
   * A callback for when the user attempts to toggle the item
   */
  onToggle: PropTypes.func
}

ToggleList.defaultProps = {
  theme: THEMES.LIGHT_GREY,
  accordion: false
}

export default ToggleList
