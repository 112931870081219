import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Menu, MenuItem, LinearProgress } from '@material-ui/core'
import { get } from 'lodash'

import { modalType, openModal, statusType } from '../../../actions'
import { ENTITY_TYPES, BRIEFING_BOOK_TYPE } from '../../../utils'

import './notification.component.scss'

const { BRIEFING_BOOK } = BRIEFING_BOOK_TYPE
const { ERROR, IN_PROGRESS, SUCCESS } = statusType
const BULK_EMAIL_COMPLETE = 'bulkEmailComplete'

/**
 * Notification Component
 */
class Notification extends Component {
  /**
   * Get Progress Notification
   * @param progress
   * @param id
   * @param title
   * @param entityType
   */
  getProgressMessage = ({ progress, id, entityType, title }) => {
    return (
      <div className='notification_col notification_col--message'>
        <div className='notification_progress'>
          <div className='notification_progress-message'>
            <span onClick={() => { this.redirectToBriefingBook(id, entityType) }} className='notification_progress-message--link'>{title}</span> downloading...
          </div>
          <div className='notification_progress-percent'>{`${progress}%`}</div>
        </div>
        <LinearProgress
          classes={{
            root: 'notification_progress-bar notification_progress-bar--rain'
          }}
          value={progress}/>
      </div>
    )
  }

  /**
   * Get Error Notification
   * @param title
   */
  getErrorMessage = ({ title }) => {
    return (
      <div className='notification_col notification_col--message'>
        <div className='notification_message notification_message--error'>
          Oops, something went wrong while trying to generate {title || 'Briefing Book'}. Please try again or contact us if you see this message again.
        </div>
      </div>
    )
  }

  /**
   * Get Success Notification
   * @param id
   * @param entityType
   * @param title
   * @param type
   * @param fileType
   */
  getSuccessMessage = ({ id, entityType, title, type, fileType }) => {
    const url = `/${BRIEFING_BOOK}/${id}/download?fileType=${fileType}&legacy=true`
    return type === 'briefing-book'
      ? (
          <div className='notification_col notification_col--message'>
            <div className='notification_message'>
              <span onClick={() => {
                this.redirectToBriefingBook(id)
              }} className='link'>{title}</span> is ready to download
            </div>
            <i className='notification_icon notification_icon--download q4i-download-4pt'
               onClick={() => this.downloadNotificationFile(url, title, fileType)}/>
          </div>
        )
      : (
          <div className='notification_col notification_col--message'>
            <div className='notification_message notification_message--success'>
              <span
                onClick={() => { this.redirectToBriefingBook(id, entityType) }}
                className='link'>{title}</span> download completed
            </div>
          </div>
        )
  }

  /**
   * Get notification item
   * @param source
   * @param index
   * @returns {*}
   */
  getNotificationItem = (source, index) => {
    if (!source) {
      return
    }

    switch (source.type) {
      case BULK_EMAIL_COMPLETE:
        return this.getBulkEmailCompleteNotification(source, index)
      case BRIEFING_BOOK:
      case 'briefing-book':
        return this.getBriefingBookNotification(source, index)
      default:
        break
    }
  }

  /**
   * Download notification file
   * @param url
   * @param title
   * @param fileType
   */
  downloadNotificationFile = (url, title, fileType) => {
    const { downloadFile, onClose } = this.props

    downloadFile(url, title, fileType)
    onClose()
  }

  /**
   * Handle Undelivered Recipient Click
   * @param {string[]} undeliveredRecipients
   */
  handleUndeliveredRecipients = (undeliveredRecipients) => {
    const { openModal, onClose } = this.props
    onClose()
    openModal({
      type: modalType.EMAIL_UNDELIVERED_MODAL,
      props: {
        undeliveredRecipients
      }
    })
  }

  /**
   * Get Bulk Email Notification
   * @param {object} data
   * @param {number} index
   * @returns {JSX.Element}
   */
  getBulkEmailCompleteNotification = (data, index) => {
    const payload = get(data, 'payload')
    const deliveredCount = get(payload, 'deliveredCount', 0)
    const undeliveredCount = get(payload, 'undeliveredCount', 0)
    const undeliveredRecipients = get(payload, 'undeliveredRecipients', [])

    return (
      <>
      {
        deliveredCount > 0
          ? (
              <MenuItem
                key={`${index}-successful`}
                disableRipple={true}
                classes={{ root: 'notification_item' }}
              >
                <div className='notification_col notification_col--icon'>
                  <i className='q4i-mail-4pt'></i>
                </div>
                <div className='notification_col notification_col--message'>
                  <div className='notification_message'>
                    <p>Email sent successfully to {deliveredCount} recipient(s).</p>
                  </div>
                </div>
              </MenuItem>
            )
          : null
      }
      {
        undeliveredCount > 0
          ? (
              <MenuItem
                key={`${index}-unsuccessful`}
                disableRipple={true}
                classes={{ root: 'notification_item' }}
              >
                <div className='notification_col notification_col--icon'>
                  <i className='q4i-mail-4pt'></i>
                </div>
                <div className='notification_col notification_col--message'>
                  <div className='notification_message'>
                    <p>
                      Failed to deliver email for {undeliveredCount} recipient(s).
                    </p>
                    <p className='link email-link' onClick={() => this.handleUndeliveredRecipients(undeliveredRecipients)}>
                      View unsuccessful deliveries
                    </p>
                  </div>
                </div>
              </MenuItem>
            )
          : null
      }
      </>
    )
  }

  /**
   * Get Briefing Book Notification Item
   * @param source
   * @param index
   * @returns {*}
   */
  getBriefingBookNotification = (source, index) => {
    const { entityId, entityType, type, progress } = source
    const id = entityId || get(source, 'meta.id') || source.id
    const title = get(source, 'meta.title') || get(source, 'title', 'Briefing Book')
    const fileType = get(source, 'meta.fileType') || source.fileType
    const status = source.status.toUpperCase()

    return (
      <MenuItem
        key={index}
        disableRipple={true}
        classes={{
          root: 'notification_item'
        }}>
          <div className='notification_col notification_col--icon'>
            <i className='q4i-book-4pt'></i>
          </div>

          {(status === IN_PROGRESS) ? this.getProgressMessage({ id, entityType, title, progress }) : null}
          {(status === SUCCESS) ? this.getSuccessMessage({ id, entityType, title, type, fileType }) : null}
          {(status === ERROR) ? this.getErrorMessage({ title }) : null}
      </MenuItem>
    )
  }

  /**
   * Redirect to BB page
   * @param id
   * @param entityType
   */
  redirectToBriefingBook = (id, entityType) => {
    const { history, onClose } = this.props
    const route = (ENTITY_TYPES || []).includes(entityType) ? entityType : BRIEFING_BOOK

    onClose()
    if (history && id) {
      history.push(`/${route}/${id}`)
    }
  }

  /**
   * Render Notification Modal
   * @returns {XML}
   */
  render () {
    const { anchorEl, onClose, notifications } = this.props

    if (!notifications || !notifications.data) {
      return null
    }

    return (
      <Menu
        id='notification-modal'
        className='notification-mask'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        classes={{
          paper: 'notification'
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <div className={'notification_header'}>Notifications</div>
        <div className={'notification_container'}>
            {notifications.data.length
              ? (notifications.data).slice(0, 10).map((item, i) => {
                  return this.getNotificationItem(item, i)
                })
              : <MenuItem
                    key={0}
                    classes={{
                      root: 'notification_no-data'
                    }}>
                    No notifications.
                </MenuItem>
            }
        </div>
      </Menu>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

export default connect(null, mapDispatchToProps)(Notification)
