import { combineReducers } from 'redux'
import brokerDetails from './brokerDetails.reducer'
import consensus from './consensus.reducer'
import rating from './rating.reducer'
import current from './consensusDetails.reducer'

const estimateReducer = combineReducers({
  brokerDetails,
  consensus,
  rating,
  current
})

export default estimateReducer
