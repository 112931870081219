import gql from 'graphql-tag'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'

const GET_TEARSHEET_TEMPLATE = gql`
  query TearsheetTemplate {
    tearsheetTemplate {
      items {
        id
        type
        name
        output
        items {
          tearsheetTemplateItemConnection {
            items {
              id
              type
              options {
                type
                selected
                values
              }
            }
          }
        }
      }
    }
  }`

const EXPORT_BRIEFING_BOOK = gql`
  query BriefingBook(
    $id: [String]!
    $tickerId: String!
    $securityId: String!
    $legacySecurityId: String!
    $currencyCode: String
    $output: [String]!
    $templateId: String!
    $title: String
    $note: String
    $itinerary: Boolean
    $items: [BriefingbookTemplateItems]
  ) {
    briefingBook(
      id: $id
      currencyCode: $currencyCode
    ) {
      generate(
        tickerId: $tickerId
        securityId: $securityId
        legacySecurityId: $legacySecurityId
        currencyCode: $currencyCode
        title: $title
        note: $note 
        itinerary: $itinerary
        template: {
          id: $templateId
          output: $output 
          items: $items
        }
      ) {
        requestId
      }
    }
  }`

/**
 * Tearsheet Template
 */
export function useTearsheetTemplateQuery () {
  return useQuery(GET_TEARSHEET_TEMPLATE)
}

/**
 * BriefingBook Export
 * @param options
 */
export function useBriefingBookExportQuery (options) {
  return useLazyQuery(EXPORT_BRIEFING_BOOK, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}
