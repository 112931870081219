import React, { useState, useEffect, useMemo, useRef } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

// actions
import { useHistoricalStockPromiseQuery, usePressReleasesPromiseQuery, useIndexHistoricalStockPromiseQuery } from '../hook'

// components
import SecurityStockOverviewCard from './overview.component'

// utils
import { chartStock, getLocalStorageItem, INDICES, THEMES } from '../../../utils'
import { get, omitBy, isEmpty, isNil, isFunction } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  tickerId: PropTypes.string.isRequired
}

const defaultProps = {
  theme: THEMES.DARK
}

const RANGE = 6
const COLORS = [
  { code: '#1abc9c', used: false },
  { code: '#d95608', used: false },
  { code: '#dc9e27', used: false }
]

/**
 * Get indices colors
 * @param props
 */
function getColors ({ colors, code = '#94e9f6', used }) {
  return (colors || []).map((color) => (color.code === code) ? { ...color, used } : color)
}

/**
 * Get indices variables
 * @param source
 */
function getVariables (source) {
  return omitBy({
    index: source.map((index) => !index.tickerId ? index.value : null).filter((id) => id),
    tickerId: source.map((index) => index.tickerId).filter((id) => id)
  }, isEmpty)
}

/**
 * Format News
 * @param news
 */
function getPressReleases (news) {
  return (news || [])
    .filter((record) => record.date && record.headline)
    .map((record) => record && ({
      x: moment.utc(record.date).endOf('day').valueOf(),
      title: ' ',
      text: record.headline,
      id: record.id
    }))
    .reverse()
}

/**
 * Get stock range dates
 * @param {string|number} range
 */
function getStockRange (range = RANGE) {
  const timeZoneOffset = moment.tz.zone('America/New_York').parse(Date.now()) + 1
  let start = moment().subtract(1, 'day')
  const end = moment().startOf('day').subtract(timeZoneOffset, 'minutes')

  switch (range) {
    case 'week':
      start = start.subtract(1, 'week').startOf('day')
      break
    case 'all':
      start = start.subtract(5, 'years')
      break
    default:
      start = start.subtract(range, 'months').startOf('day')
      break
  }

  return { start, end }
}

/**
 * Get indices from LocalStorage
 * @returns {*}
 */
function getIndicesFromStorage () {
  const indices = getLocalStorageItem('indices') || []
  // backward compatibility to remove obsolete indices saved in local storage
  const onlyNewIndices = indices.filter(index => INDICES.some(INDEX => INDEX.symbol === index.value))
  return onlyNewIndices
}

/**
 * Security Stock Overview
 * @param props
 */
function SecurityStockOverview (props) {
  const { dataId, theme, tickerId } = props

  const storage = useMemo(getIndicesFromStorage, [])

  const [currentIndices, updateCurrentIndices] = useState(storage)
  const previousIndices = useRef([])

  const setCurrentIndices = (updated) => {
    updateCurrentIndices((current) => {
      previousIndices.current = current
      return isFunction(updated)
        ? updated(current)
        : updated
    })
  }

  const [colors, setColors] = useState(() => {
    return COLORS.map((color) => ({ ...color, used: !!currentIndices?.some((item) => item.color === color.code) }))
  })

  const [range, setRange] = useState(RANGE)
  const [dates, setDates] = useState(getStockRange(RANGE))

  const ranges = useMemo(() => ({
    startDate: dates?.start?.toISOString(),
    endDate: dates?.end?.toISOString()
  }), [dates])

  const [chartData, setChartData] = useState({ news: [], stock: [], indices: [] })
  const { stock, news, indices } = chartData

  const [getStockData, { loading }] = useHistoricalStockPromiseQuery()
  const [getNewsData, { loading: pressLoading }] = usePressReleasesPromiseQuery()
  const [getIndex, { loading: indexLoading }] = useIndexHistoricalStockPromiseQuery()

  /**
   * Get stock data and news
   */
  useEffect(() => {
    const handleIndexStockResponse = (response, indices) => {
      if (!isEmpty(response)) {
        const stocks = get(response, 'stock.items')
        return currentIndices.map((index) => {
          const { value, tickerId } = (index || {})
          const indexStockData = chartStock((stocks || []).filter((x) =>
            (!!x?.tickerId && x.tickerId === tickerId) || (!!x?.index && x.index === value)
          ))
          return { ...index, stock: indexStockData }
        })
      } else if (!isEmpty(indices) && deletedIndex) {
        return indices.filter((x) =>
          currentIndices.some((y) => (!!y.tickerId && y.tickerId === x.tickerId) || (!!y.value && y.value === x.value))
        )
      }
      return []
    }

    if (!ranges.endDate || !ranges.startDate || !tickerId) return

    const options = { tickerId, ...ranges }
    const tasks = [getStockData(options), getNewsData(options)]

    const deletedIndex = currentIndices?.length < previousIndices.current?.length
    if (!isEmpty(currentIndices) && !deletedIndex) {
      const variables = getVariables(currentIndices)
      tasks.push(getIndex({ ...variables, ...ranges }))
    } else if (deletedIndex) {
      // reset previous Indices to current
      previousIndices.current = currentIndices
    }

    Promise
      .all(tasks)
      .catch((e) => {
        console.error(e)
        return []
      })
      .then((results) => {
        if (isEmpty(results)) return

        const [stockResponse, newsResponse, indexStockResponse] = results
        setChartData((current) => {
          const stockData = isEmpty(stockResponse) ? current.stock : chartStock(stockResponse.stock.items)
          const newsData = isEmpty(newsResponse) ? current.stock : getPressReleases(newsResponse.news.items)

          const indicesData = handleIndexStockResponse(indexStockResponse, current.indices)

          return {
            stock: stockData,
            news: newsData,
            indices: indicesData
          }
        })
      })
  }, [ranges, currentIndices, tickerId, getIndex, getStockData, getNewsData])

  /**
   * Handle range change
   * @param range
   */
  const handleRangeChange = (range) => {
    setRange(range)
    setDates(getStockRange(range))
  }

  /**
   * Handle dates change
   * @param date
   * @param type
   */
  const handleDatesChange = (date, type) => {
    setRange(null)
    let end
    if (type === 'end') {
      const timeZoneOffset = moment.tz.zone('America/New_York').parse(date) + 1
      end = moment(date).endOf('day').subtract(timeZoneOffset, 'minutes')
    }
    setDates({ ...dates, [type]: end ?? date })
  }

  /**
   * Handle new index add
   * @param index
   */
  const handleIndexAdd = (index) => {
    const color = colors?.find((x) => !x.used)
    if (isNil(index?.value) || isNil(color)) return

    const { value } = index

    setColors(getColors({ colors, code: color.code, used: true }))
    setCurrentIndices((current) => current.concat({ ...index, color: color.code }))

    const inStorage = storage.find((item) => item?.value === value)
    if (!inStorage) {
      // eslint-disable-next-line no-undef
      localStorage.setItem('indices', JSON.stringify(storage.concat({ ...index, color: color.code })))
    }
  }

  /**
   * Handle indices delete
   * @param index
   */
  const handleIndexDelete = (index) => {
    if (isNil(index?.value)) return
    const { value, color } = index

    setColors((current) => getColors({ colors: current, code: color, used: false }))
    setCurrentIndices((current) => current.filter((item) => item?.value !== value))

    // eslint-disable-next-line no-undef
    localStorage.setItem('indices', JSON.stringify(storage.filter((item) => item?.value !== value)))
  }

  return (
    <SecurityStockOverviewCard
      dataId={dataId}
      theme={theme}
      isLoading={[loading, pressLoading, indexLoading].some((loading) => !!loading)}
      data={{ stock, news, indices }}
      range={range}
      dates={dates}
      onRangeChange={handleRangeChange}
      onDatesChange={handleDatesChange}
      onIndexAdd={handleIndexAdd}
      onIndexDelete={handleIndexDelete}
    />
  )
}

SecurityStockOverview.propTypes = propTypes
SecurityStockOverview.defaultProps = defaultProps

export default SecurityStockOverview
