import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

// components
import { AgGridReact } from 'ag-grid-react'
import { Pagination } from '../index'
import BulkActionBar from './bulkActionBar/bulkActionBar.component'

// utils
import { getClassName, getDefaultRowClassRules, getDefaultExcelStyles } from '../../utils'
import { throttle } from 'lodash'

import 'ag-grid-community/dist/styles/ag-grid.css'
import './agGrid.component.css'

const propTypes = {
  className: PropTypes.string,
  /**
   * Box model  / sizing props
   */
  domLayout: PropTypes.oneOf(['normal', 'autoHeight']),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Used to determine row height
   * Note: changing rowHeight in Ag-grid requires both JS and CSS, which is why this prop was introduced
   */
  rowDensity: PropTypes.oneOf(['default', 'comfy', 'compact']),
  /**
   * Used to provide the grid with columns and data
   */
  columnDefs: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    headerName: PropTypes.string,
    headerClass: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.string]),
    cellClassRules: PropTypes.object,
    valueFormatter: PropTypes.func,
    width: PropTypes.number,
    minWidth: PropTypes.number,
    sortable: PropTypes.bool,
    lockPinned: PropTypes.bool
  })).isRequired,
  rowData: PropTypes.array.isRequired,

  bulkActions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    icon: PropTypes.string,
    label: PropTypes.string,
    onSelect: PropTypes.func.isRequired
  })),
  pagination: PropTypes.bool,
  paginationProps: PropTypes.shape({
    startFromZero: PropTypes.bool,
    total: PropTypes.number,
    initialPage: PropTypes.number,
    forcePage: PropTypes.number,
    initialPageSize: PropTypes.number,
    pageSizeOptions: PropTypes.array,
    pageSizeId: PropTypes.string,
    showPageSizeSelection: PropTypes.bool,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func
  }),

  /**
   * Used to hide the the group auto column that ag-grid will create
   */
  hideAutoColumn: PropTypes.bool,
  /**
   * Used to have the grid attempt auto-sizing after firing onGridReady callback
   * Note: convenience function
   */
  sizeToFit: PropTypes.bool,
  /**
   * Some of the important ag-Grid specific props (there are many many many many more)
   * Learn more at https://www.ag-grid.com/javascript-grid-reference-overview/
   */
  onSortChanged: PropTypes.func,
  onRowDataChanged: PropTypes.func,
  onGridReady: PropTypes.func,
  onColumnMoved: PropTypes.func,
  onDragStopped: PropTypes.func,
  onGridColumnsChanged: PropTypes.func,
  /**
   * Used to provide custom components to render cell
   * Learn more at: https://www.ag-grid.com/javascript-grid-cell-rendering-components/#react-cell-rendering
   */
  frameworkComponents: PropTypes.object
}

const defaultProps = {
  headerHeight: 48,
  rowDensity: 'default'
}

const columnTypes = {
  icon: 'icon',
  text: 'text',
  centered: 'centered',
  boolean: 'boolean',
  number: 'number',
  change: 'change',
  date: 'date'
}

/**
 * Get ag-grid icons
 */
function getIcons () {
  return {
    menu: '<i class="q4i-hamburger-q4inc-4pt"/>',
    sortAscending: '<i class="q4i-arrow-up-4pt"/>',
    sortDescending: '<i class="q4i-arrow-down-4pt"/>',
    columnMoveHide: '<i class="q4i-close-4pt"/>',
    columnMoveMove: '<i class="q4i-swap-4pt"/>',
    columnMoveLeft: '<i class="q4i-swap-4pt"/>',
    columnMoveRight: '<i class="q4i-swap-4pt"/>',
    columnGroupOpened: '<i class="q4i-caret-sm-up-4pt"/>',
    columnGroupClosed: '<i class="q4i-caret-sm-down-4pt"/>',
    groupExpanded: '<i class="q4i-caret-sm-up-4pt"/>',
    groupContracted: '<i class="q4i-caret-sm-down-4pt"/>'
  }
}

/**
 * AgGrid Component
 * @param props
 */
function AgGrid (props) {
  const {
    className, domLayout, width, height, headerHeight, rowDensity, isPivot,
    rowData, bulkActions, hideAutoColumn, pagination, paginationProps, onRowClicked, isPinned, sizeToFit, onGridReady,
    onRowSelected, onRowDataChanged, autoGroupColumnDef, columnDefs, onGridColumnsChanged
  } = props

  const agGrid = useRef(null)
  const [isMounted, setIsMounted] = useState(false)

  const noData = !rowData || !rowData.length
  const rowHeight = {
    default: 48,
    comfy: 40,
    compact: 26
  }[rowDensity] || 48

  const getIsBulkActionBarActive = (columns, group) => {
    return (columns || []).some((column) => column.headerCheckboxSelection) || (group && group.headerCheckboxSelection)
  }

  const [bulkActionBar, setBulkActionBar] = useState({
    isActive: getIsBulkActionBarActive(columnDefs, autoGroupColumnDef),
    isVisible: false,
    isBulkSelected: false
  })

  const { isActive, isVisible, isBulkSelected } = bulkActionBar

  const handleBulkActionBarUpdate = throttle((grid) => {
    if (isActive && isMounted) {
      const selectedRows = grid.api && grid.api.getSelectedRows()
      const isVisible = selectedRows && selectedRows.length > 0
      const isBulkSelected = selectedRows && rowData && (selectedRows.length === rowData.length)

      setBulkActionBar({ ...bulkActionBar, isVisible, isBulkSelected })
    }
  })

  /**
   * Ag-grid callback wrapping function to provide utility and convenient auto-size functionality
   * @param grid
   */
  const handleGridCallback = (grid) => {
    if (!grid) {
      return
    }

    switch (grid.type) {
      case 'gridReady':
        agGrid.current = grid

        if (sizeToFit) {
          grid.api && grid.api.sizeColumnsToFit()
        }

        setIsMounted(true)
        setBulkActionBar({
          ...bulkActionBar,
          isActive: getIsBulkActionBarActive(columnDefs, autoGroupColumnDef)
        })

        onGridReady && onGridReady(grid)
        break
      case 'rowSelected':
        handleBulkActionBarUpdate(grid)
        onRowSelected && onRowSelected(grid)
        break
      case 'rowDataChanged':
        handleBulkActionBarUpdate(grid)
        onRowDataChanged && onRowDataChanged(grid)
        break
      case 'gridColumnsChanged':
        handleBulkActionBarUpdate(grid)
        onGridColumnsChanged && onGridColumnsChanged(grid)
        break
      default:
        break
    }
  }

  const handleBulkCheckboxChange = (value) => {
    const { current } = (agGrid || {})

    if (!current || !current.api) {
      return
    }

    value ? current.api.selectAll() : current.api.deselectAll()
  }

  const renderBulkActionBar = () => {
    if (!agGrid || !rowData || !rowData.length || !isActive || !bulkActions || !bulkActions.length) {
      return null
    }

    return (
      <BulkActionBar
        isVisible={isVisible}
        isBulkSelected={isBulkSelected}
        actions={bulkActions}
        onChange={handleBulkCheckboxChange}
      />
    )
  }

  const baseClassName = getClassName('ag-grid-root ag-theme-bootstrap', [
    { condition: className, trueClassName: className },
    { condition: domLayout === 'autoHeight', trueClassName: 'ag-grid-root--auto-height' },
    { condition: pagination, trueClassName: 'ag-grid-root--paginated' },
    { condition: noData, trueClassName: 'ag-grid-root--no-data' },
    { condition: rowDensity, trueClassName: `ag-grid-root--${rowDensity}` },
    { condition: isPivot, trueClassName: 'ag-grid-root--pivot' },
    { condition: isPinned, trueClassName: 'ag-grid-root--pinned' }
  ])

  return (
    <div
      className={baseClassName}
      style={{ width, height: domLayout === 'autoHeight' ? null : height }}
    >
      {renderBulkActionBar()}
      <AgGridReact
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        sortingOrder={['asc', 'desc']}
        suppressMultiSort
        pivotMode={isPivot}
        rowClass={onRowClicked && 'ag-row--clickable'}
        rowSelection='multiple'
        suppressRowClickSelection
        rowClassRules={getDefaultRowClassRules()}
        excelStyles={getDefaultExcelStyles()}
        suppressCellSelection
        ensureDomOrder
        suppressRowTransform
        suppressDragLeaveHidesColumns
        getMainMenuItems={() => ['pinSubMenu']}
        getContextMenuItems={() => ['copy', 'copyWithHeaders']}
        icons={getIcons()}
        columnTypes={columnTypes}
        overlayNoRowsTemplate='No data available'
        {...props}
        autoGroupColumnDef={{
          ...autoGroupColumnDef,
          maxWidth: hideAutoColumn ? 0 : autoGroupColumnDef?.maxWidth
        }}
        // override callback for utility and convenient auto-sizing
        onGridReady={handleGridCallback}
        onRowSelected={handleGridCallback}
        onGridColumnsChanged={handleGridCallback}
        onRowDataChanged={handleGridCallback}
        // override ag-grid pagination
        pagination={false}
        suppressPaginationPanel
      />
      {pagination && !noData && (<Pagination {...paginationProps} />)}
    </div>
  )
}

AgGrid.propTypes = propTypes
AgGrid.defaultProps = defaultProps

export default AgGrid
