import React from 'react'
import { formatDate } from '../../../../../../utils'

const EMPTY_PLACEHOLDER = '-'

/**
 * Title Custom Cell Component
 * @param headline
 * @param uri
 * @returns {*}
 * @constructor
 */
const TitleCell = ({ headline, uri }) => {
  return <div className='cell'>
    <div className='cell_content'>
      {headline
        ? uri
          ? <div className='cell_content-name cell_content--link' onClick={() => window.open(uri, '_blank')}>{headline}</div>
          : <div className='cell_content-name'>{headline}</div>
        : EMPTY_PLACEHOLDER}
    </div>
  </div>
}

const CustomCell = ({ data = {}, column = {} }) => {
  const cellData = data[column.colId]

  switch (column.colId) {
    case 'date':
      return cellData ? formatDate(cellData) : EMPTY_PLACEHOLDER
    case 'headline':
      return <TitleCell {...data} />
    default:
      return cellData || EMPTY_PLACEHOLDER
  }
}

export default CustomCell
