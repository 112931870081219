export const INDICES = [
  {
    name: 'S&P500',
    group: 'IND_SPF',
    symbol: 'SP500',
    fullname: 'Standard & Poor\'s 500',
    region: 'US',
    category: 'index'
  },
  {
    name: 'NASDAQ',
    group: 'IND_GIDS',
    symbol: 'COMP',
    fullname: 'NASDAQ Composite',
    region: 'US',
    category: 'index'
  },
  {
    name: 'OMXCMCPI',
    group: 'IND_GIDS',
    symbol: 'OMXCMCPI',
    fullname: 'Stockholm Indices OMXCopenhagen Segm Mid Cap DKK PI',
    region: 'US',
    category: 'index'
  },
  {
    name: 'DOW 30',
    group: 'IND_DJI',
    symbol: 'DJI',
    fullname: 'Dow Jones',
    region: 'US',
    category: 'index'
  },
  {
    name: 'S&P/TSX Composite Index',
    group: 'INDXTSE',
    symbol: '0000',
    fullname: 'S&P Indices S&P/TSX Composite Index',
    region: 'CA',
    category: 'index'
  },
  {
    name: 'NYSE Composite Index',
    group: 'IND_GIF',
    symbol: 'NYA',
    fullname: 'NYSE Arca Indices NYSE Composite Index Revised',
    region: 'US',
    category: 'index'
  },
  {
    name: 'INSTR.IV',
    group: 'IND_GIF',
    symbol: 'INSTR.IV',
    tickerId: '57b9282ebe1c33ae235cb049',
    fullname: 'SPDR MSCI Europe Consumer Discretionary UCITS ETF',
    region: 'IE',
    category: 'index'
  },
  // Euro indices
  {
    name: 'SX7E',
    group: 'INDXSTX',
    symbol: 'SX7E',
    fullname: 'STOXX Indices EURO STOXX Banks ESTX Banks EUR (Price)',
    region: 'SW',
    category: 'index'
  },
  {
    name: 'SXXP',
    group: 'INDXSTX',
    symbol: 'SXXP',
    fullname: 'STOXX Indices STOXX Europe 600 STXE 600 EUR (Price)',
    region: 'SW',
    category: 'index'
  },
  {
    name: 'OMX20',
    group: 'INDXCSE',
    symbol: 'OMXC20',
    fullname: 'Copenhagen Indices OMX Copenhagen Index 20 most Traded Shs',
    region: 'DNK',
    category: 'index'
  },
  {
    name: 'OMXH25',
    group: 'INDXHEL',
    symbol: 'OMXH25',
    fullname: 'OMXH Benchmark Cap OMX Helsinki 25 Index',
    region: 'FIN',
    category: 'index'
  },
  {
    name: 'OMXS30',
    group: 'INDXSTO',
    symbol: 'OMXS30',
    fullname: 'OMX Stockholm 30 Index',
    region: 'SWE',
    category: 'index'
  },
  {
    name: 'BEL20',
    group: 'INDXBRU',
    symbol: 'BEL20',
    fullname: 'Euronext Bruss.Ind. BEL-20',
    region: 'BEL',
    category: 'index'
  },
  {
    name: 'CAC 40',
    group: 'INDXPAR',
    symbol: 'PX1',
    fullname: 'Paris Indices CAC 40',
    region: 'FRA',
    category: 'index'
  },
  {
    name: 'CAC Next 20',
    group: 'INDXPAR',
    symbol: 'CN20',
    fullname: 'Paris Indices CAC Next20',
    region: 'FRA',
    category: 'index'
  },
  {
    name: 'CAC Mid 60',
    group: 'INDXPAR',
    symbol: 'CACMD',
    fullname: 'Paris Indices CAC MID 60',
    region: 'FRA',
    category: 'index'
  },
  {
    name: 'CAC Small',
    group: 'INDXPAR',
    symbol: 'CACS',
    fullname: 'Paris Indices CAC SMALL',
    region: 'FRA',
    category: 'index'
  },
  {
    name: 'SBF 250',
    group: 'INDXPAR',
    symbol: 'CACT',
    fullname: 'Indices Euronext CAC ALL TRADABLE CAC ALL TRADABLE',
    region: 'FRA',
    category: 'index'
  },
  {
    name: 'AEX',
    group: 'INDXAMS',
    symbol: 'AEX',
    fullname: 'Aex-Index Amsterdam Aex-Index',
    region: 'NLD',
    category: 'index'
  },
  {
    name: 'AMX Mid',
    group: 'INDXAMS',
    symbol: 'AMX',
    fullname: 'Aex-Index Amsterdam Midkap-Index',
    region: 'NLD',
    category: 'index'
  },
  {
    name: 'AScX Small',
    group: 'INDXAMS',
    symbol: 'ASCX',
    fullname: 'Euronext Indices Amsterdam Small Cap index',
    region: 'NLD',
    category: 'index'
  },
  {
    name: 'DAX',
    group: 'IND_DBI',
    symbol: 'DAX',
    fullname: 'Deutsche Boerse Ind DAX-Index',
    region: 'DEU',
    category: 'index'
  },
  {
    name: 'TecDAX',
    group: 'IND_DBI',
    symbol: 'TDXP',
    fullname: 'Deutsche Boerse Ind TecDAX-Index Performance-Index',
    region: 'DEU',
    category: 'index'
  },
  {
    name: 'MDAX',
    group: 'IND_DBI',
    symbol: 'MDAX',
    fullname: 'Deutsche Boerse Ind Mid-Cap DAX Index',
    region: 'DEU',
    category: 'index'
  },
  {
    name: 'SDAX',
    group: 'IND_DBI',
    symbol: 'SDXP',
    fullname: 'Deutsche Boerse Ind SDAX Performance-Index',
    region: 'DEU',
    category: 'index'
  },
  {
    name: 'OBX',
    group: 'INDXOSL',
    symbol: 'OBX',
    fullname: 'Oslo Indices OBX Index',
    region: 'NOR',
    category: 'index'
  },
  {
    name: 'OSE All Share',
    group: 'INDXOSL',
    symbol: 'OSEAX',
    fullname: 'Oslo Indices Oslo Exchange All share Index GI',
    region: 'NOR',
    category: 'index'
  },
  {
    name: 'IBEX 35',
    group: 'INDXMCE',
    symbol: 'INDI',
    fullname: 'Sociedad de Bolsas IBEX-35 Index',
    region: 'ESP',
    category: 'index'
  },
  // ETFs
  {
    name: 'FTSE 100 ETF',
    group: 'XLON',
    symbol: 'MIDD',
    tickerId: 'd8769e225c9b3e53cb9833808a3924db',
    fullname: 'iShares Core FTSE 100 ETF',
    region: 'UK',
    category: 'etf'
  },
  {
    name: 'FTSE 250 ETF',
    group: 'XLON',
    symbol: 'INDI',
    tickerId: '59a2a0a067fe5c34c81f3e9a67400c52',
    fullname: 'iShares FTSE 250 ETF',
    region: 'UK',
    category: 'etf'
  },
  {
    name: 'FTSE MIB ETF',
    group: 'MTAA',
    symbol: 'IMIB',
    tickerId: 'aaddff01ecba5db7394f5a3930e772bd',
    fullname: 'iShares FTSE MIB ETF',
    region: 'Italy',
    category: 'etf'
  }
]
