import React from 'react'
import PropTypes from 'prop-types'
import {
  HOLDING_REGION_OPTIONS,
  HOLDING_SECTOR_OPTIONS,
  HOLDING_MARKET_GROUP_OPTIONS
} from '../../../../../utils/ownership/ownership.util'
import { THEMES } from '../../../../../utils/ui'
import { Select, Button, Toolbar, ToolbarRow } from '../../../../index'
import { SectionSearch } from '../../../../shared'

const InstitutionHoldingsToolbar = (props) => {
  const { toolbarTheme, toolTheme, noData, handleFilterChange, handleExport, filter, resetFilter } = props
  const { regions, sectors, capGroups, search } = filter
  const handleSearchChange = (search) => handleFilterChange({ search, page: 1 })
  const onRegionOptionChange = (region) => handleFilterChange({
    regions: region && region.map((item) => item && item.value),
    page: 1
  })
  const onSectorOptionChange = (sector) => handleFilterChange({
    sectors: sector && sector.map((item) => item && item.value),
    page: 1
  })
  const onMarketGroupOptionChange = (capGroup) => handleFilterChange({
    capGroups: capGroup && capGroup.map((item) => item && item.value),
    page: 1
  })

  return (
    <Toolbar theme={toolbarTheme}>
      <ToolbarRow justified>
        <div className='toolbar_group'>
          <Select
            className='holding-grid_toolbar-regions'
            theme={toolTheme}
            size='thin'
            value={regions ? HOLDING_REGION_OPTIONS.find((option) => option.value === regions) : ''}
            options={HOLDING_REGION_OPTIONS}
            onChange={onRegionOptionChange}
            placeholder='Regions'
            isMulti
            isDefaultList
            closeMenuOnSelect={false}
            searchable={false}
            clearable={false}
          />
          <Select
            className='holding-grid_toolbar-sectors'
            theme={toolTheme}
            size='thin'
            value={sectors ? HOLDING_SECTOR_OPTIONS.find((option) => option.value === sectors) : ''}
            options={HOLDING_SECTOR_OPTIONS}
            onChange={onSectorOptionChange}
            placeholder='Sectors'
            isMulti
            isDefaultList
            closeMenuOnSelect={false}
            searchable={false}
            clearable={false}
          />
          <Select
            className='holding-grid_toolbar-marketcaps'
            theme={toolTheme}
            size='thin'
            value={capGroups ? HOLDING_MARKET_GROUP_OPTIONS.find((option) => option.value === capGroups) : ''}
            options={HOLDING_MARKET_GROUP_OPTIONS}
            onChange={onMarketGroupOptionChange}
            placeholder='Market Caps'
            isMulti
            isDefaultList
            closeMenuOnSelect={false}
            searchable={false}
            clearable={false}
          />
          <Button
            theme='ink'
            label='RESET'
            onClick={resetFilter}
          />
        </div>
        <div className='toolbar_group'>
          <SectionSearch
            className='holding-grid_toolbar-search'
            theme='ink'
            value={search}
            onQueryChange={handleSearchChange}
            onClear={handleSearchChange}
          />
          <Button
            theme='ink'
            icon='q4i-download-4pt'
            label='Export'
            disabled={noData}
            onClick={handleExport}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

InstitutionHoldingsToolbar.propTypes = {
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  noData: PropTypes.bool,
  handleFilterChange: PropTypes.func,
  handleExport: PropTypes.func
}

export default InstitutionHoldingsToolbar
