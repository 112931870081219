import {
  GET_DASHBOARDS_REQUEST,
  GET_DASHBOARDS_SUCCESS,
  GET_DASHBOARDS_FAILURE,
  CLONE_DASHBOARD_SUCCESS,
  IDLE,
  FETCHED,
  FETCHING,
  ERROR, UPDATE_DASHBOARD_SUCCESS
} from '../../actions/dashboard'
import _ from 'lodash'
import { REMOVE_DASHBOARD_SUCCESS } from '../../actions/dashboard/dashboard.actions'

const initial = {
  data: [],
  status: IDLE
}

const dashboard = (state = initial, action) => {
  switch (action.type) {
    case GET_DASHBOARDS_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_DASHBOARDS_FAILURE:
      return {
        ...state,
        status: ERROR
      }
    case REMOVE_DASHBOARD_SUCCESS:
      const dashboards = _.filter([...state.data], (each) => {
        return each._id !== action.payload._id
      })
      return {
        ...state,
        data: dashboards,
        status: FETCHED
      }
    case CLONE_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload],
        status: FETCHED
      }
    case UPDATE_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item._id === action.payload._id) {
            return action.payload
          }
          return item
        })
      }
    case GET_DASHBOARDS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: FETCHED
      }
    default:
      return state
  }
}

export default dashboard
