import gql from 'graphql-tag'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'

const GET_CONTACT = gql`
  query Contact(
    $id: [String]!
    $securityId: [String]!
  ) {
    contact(
      id: $id
      securityId: $securityId
    ) {
      items {
        id
        salutation
        suffixProf
        fullName
        nickname
        directPhone
        phoneExtension
        mobile
        email
        bio
        source
        status
        coverage
        jobs {
          entityId
          institutionName
          institutionType
          title
          phone
          directPhone
          email
          address1
          address2
          address3
          city
          countryName
          stateProvince
          postalCode
          functions
        }
        managedFunds {
          fundId
        }
        contactHoldingCurrentConnection(securityId: $securityId) {
          items {
            current
          }
        }
        favoriteConnection {
          items {
            id
          }
        }
      }
    }
  }`

const GET_FUND = gql`
  query Fund(
    $id: [String]!
    $currencyCode: String!
  ) {
    fund(id: $id, currencyCode: $currencyCode) {
      items {
        id
        fundName
        fundTypeDesc
        style
        turnover
        institutionId
        institutionName
        portfolioValue
        equityAUM
        qualityRating
        institutionConnection {
          items {
            addressConnection {
              items {
                city
                stateProvinceCode
                countryName
              }
            }
          }        
        }
      }
    }
  }`

const GET_INSTITUTION = gql`
  query Institution(
    $id: [String]!
    $currencyCode: String!
  ) {
    institution(id: $id, currencyCode: $currencyCode) {
      items {
        id
        institutionName
        institutionType
        style
        turnover
        totalAUM
        equityAUM
        qualityRating
        profile
        investmentApproach
        activist
        addressConnection {
          items {
            city
            stateProvinceCode
            countryName
          }        
        }
      }
    }
  }`

const GET_ENTITY_CONTACT = gql`
  query Contact(
    $id: [String]!
  ) {
    contact(
      id: $id
    ) {
      items {
        id
        fullName
        status
        jobs {
          entityId
          title
          phone
          directPhone
          email
          address1
          address2
          address3
          city
          countryName
          stateProvince
          postalCode
        }
        managedFunds {
          fundId
        }
      }
    }
  }`

/**
 * Contact by Id
 * @param options
 */
export function useContactQuery (options) {
  return useQuery(GET_CONTACT, options)
}

/**
 * Fund by Id
 * @param options
 */
export function useFundQuery (options) {
  return useQuery(GET_FUND, options)
}

/**
 * Institution by Id
 * @param options
 */
export function useInstitutionQuery (options) {
  return useQuery(GET_INSTITUTION, options)
}

/**
 * Contact(s) by Id(s)
 * @param options
 */
export function useContactsQuery (options) {
  return useQuery(GET_ENTITY_CONTACT, options)
}

/**
 * Contact by Id
 * @param options
 */
export function useInstitutionContactQuery (options) {
  return useLazyQuery(GET_ENTITY_CONTACT, options)
}
