import React, { Component } from 'react'
import { connect } from 'react-redux'
import WidgetError from '../../../components/widget/error/widgetError.component'

import './iframe.container.css'

class IframeWidget extends Component {
  render () {
    const { theme, config } = this.props

    const className = 'iframe-widget-container'
    const titleAvailable = config && config.title && config.title.length

    return (
      <div className={`${className} ${className}--${theme}`}>
        {titleAvailable && (
          <header className={`${className}_header`}>{config.title}</header>
        )}
        {config && config.url && config.url.length ? (
          <section className={`${className}_frame ${titleAvailable ? '' : `${className}_frame--full`}`}>
            <iframe
              src={config.url}
              width={config.width || '100%'}
              height={config.height || '100%'}
              frameBorder='0'
              title={config.title || ''}
              allow={config.allow || 'autoplay; encrypted-media'}
              allowFullScreen
              scrolling='no'
            />
          </section>
        ) : (
          <WidgetError theme={theme} message='No URL provided. Please configure this widget.' />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.dashboard.dashboardState.theme
  }
}

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(IframeWidget)
