import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// components
import { Checkbox, Select } from '../../../../index'

// utils
import { convertStringToBoolean, ENTITY_TYPE, TEMPLATE_ITEMS } from '../../../../../utils'
import { chunk, get, merge, omitBy, isEmpty } from 'lodash'

const { FUND } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string,
  entityType: PropTypes.string,
  template: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

const defaultProps = {
  template: {},
  settings: {}
}

/**
 * Merge template data
 * @param template
 * @param entityType
 */
function normalize ({ template, entityType }) {
  const { items } = (template || {})
  const options = (items || []).reduce((acc, item) => {
    const option = get(item, 'tearsheetTemplateItemConnection.items[0]', {})

    return Object.keys(TEMPLATE_ITEMS).includes(option.type)
      ? { ...acc, [option.type]: { ...option } }
      : acc
  }, {})

  const templateItems = Object.values({ ...merge(TEMPLATE_ITEMS, options) })
    .filter((item) => item.type && Object.keys(options).includes(item.type))
    .filter((item) => entityType ? get(item, 'entityType', []).includes(entityType) : item)

  return chunk(templateItems, (entityType === FUND) ? 5 : 6)
}

/**
 * Select Option Component
 * @param props
 */
function Options ({ dataId, data, settingItem, onChange }) {
  const { type, select = [], selected } = (data || {})
  const { options } = (settingItem || {})

  const settingOptions = (options || []).find((option) => option.type === type)
  const value = get(settingOptions, 'selected') || selected

  const handleChange = (option) => option && onChange({ type, selected: option.value })

  return (
    <Select
      dataId={{
        inputId: `${dataId}Dropdown`,
        menuId: `${dataId}List`
      }}
      size='thin'
      placeholder='Select'
      value={select.find((option) => option.value === value)}
      options={select}
      searchable={false}
      clearable={false}
      onChange={handleChange}
    />
  )
}

/**
 * Template Items
 * @param props
 */
function TemplateItems (props) {
  const { dataId: dataIdPrefix, entityType, template, settings, onChange } = props
  const { items = [] } = settings
  const { type } = template

  const columns = normalize({ template, entityType })

  if (!columns || !columns.length) {
    return null
  }

  return (
    <Grid container spacing={3}>
      {(columns || []).map((column, index) => <Grid item xs={6} key={`${type}-column-${index}`}>
        {(column || []).map((row) => {
          const { dataId, id, type, label, options } = (row || {})

          const settingItem = (items || []).find((item) => item.type === type)
          const handleChange = (value) => {
            if (value) {
              const allOptions = merge((options || []).map((option) => ({ type: option.type, selected: option.selected })), get(settingItem, 'options', []))
              const item = omitBy({
                type,
                options: (typeof value === 'object') ? [...allOptions.filter((option) => option.type !== value.type), value] : allOptions
              }, isEmpty)
              onChange({ ...settings, items: [...items.filter((item) => item !== settingItem), item] })
            } else {
              onChange({ ...settings, items: items.filter((item) => item.type !== type) })
            }
          }

          return (
            <div key={id} className='tearsheet-export-modal_option-item'>
              <Checkbox
                dataId={`${dataIdPrefix}${dataId}`}
                id={id}
                size='medium'
                label={label}
                isSelected={!!settingItem}
                onChange={handleChange}
              />
              {(options || []).map((data) => {
                const { dataId, type, label } = (data || {})
                const itemOption = (type !== 'limit')
                  ? get(settingItem, 'options', []).find((option) => option.type === type)
                  : null

                return (type === 'limit')
                  ? <Options
                    dataId={`${dataIdPrefix}${dataId}`}
                    key={`${id}-${type}`}
                    data={data}
                    settingItem={settingItem}
                    onChange={handleChange}
                  />
                  : <Checkbox
                    dataId={`${dataIdPrefix}${dataId}`}
                    id={`${id}-${type}`}
                    key={`${id}-${type}`}
                    size='medium'
                    label={label}
                    isSelected={convertStringToBoolean(get(itemOption, 'selected', false))}
                    onChange={(value) => handleChange({ type, selected: value.toString() })}
                  />
              })}
            </div>
          )
        })}
      </Grid>)}
    </Grid>
  )
}

TemplateItems.propTypes = propTypes
TemplateItems.defaultProps = defaultProps

export default memo(TemplateItems)
