export const ALLOWED_CURRENCIES = [
  'DKK', // Danish Krones
  'SEK', // Swedish Krones
  'NOK', // Norwegian Krones
  'EUR', // Euros
  'GBP', // Great Britain Pounds
  'CHF' // Swiss Francs
]

export const DEFAULT_CURRENCY = 'USD' // American Dollar

export const LOCALIZATION_KEY = 'localization'
