import gql from 'graphql-tag'
import { get } from 'lodash'

import client from '../../../middleware/graphql.middleware'

import { getStockHistorical } from '../../stock'
import { getNews } from '../../news'
import { getEvents } from '../../event'
import { fetchFundHistoricalPosition } from '../fund'
import { fetchInstitutionHistoricalPosition } from '../institution'
import { ENTITY_TYPE } from '../../../utils/entity'
import { COMPANY_SPECIFIC_EVENTS, isTradingTicker } from '../../../utils'

export const GET_ENTITY_OWNERSHIP_CHART_POSITION_REQUEST = 'GET_ENTITY_OWNERSHIP_CHART_POSITION_REQUEST'
export const GET_ENTITY_OWNERSHIP_CHART_POSITION_SUCCESS = 'GET_ENTITY_OWNERSHIP_CHART_POSITION_SUCCESS'
export const GET_ENTITY_OWNERSHIP_CHART_POSITION_FAILURE = 'GET_ENTITY_OWNERSHIP_CHART_POSITION_FAILURE'

export const GET_ENTITY_OWNERSHIP_LANGUAGE_POSITION_REQUEST = 'GET_ENTITY_OWNERSHIP_LANGUAGE_POSITION_REQUEST'
export const GET_ENTITY_OWNERSHIP_LANGUAGE_POSITION_SUCCESS = 'GET_ENTITY_OWNERSHIP_LANGUAGE_POSITION_SUCCESS'
export const GET_ENTITY_OWNERSHIP_LANGUAGE_POSITION_FAILURE = 'GET_ENTITY_OWNERSHIP_LANGUAGE_POSITION_FAILURE'

export const GET_ENTITY_OWNERSHIP_CHART_STOCK_REQUEST = 'GET_ENTITY_OWNERSHIP_CHART_STOCK_REQUEST'
export const GET_ENTITY_OWNERSHIP_CHART_STOCK_SUCCESS = 'GET_ENTITY_OWNERSHIP_CHART_STOCK_SUCCESS'
export const GET_ENTITY_OWNERSHIP_CHART_STOCK_FAILURE = 'GET_ENTITY_OWNERSHIP_CHART_STOCK_FAILURE'

export const GET_ENTITY_OWNERSHIP_CHART_EVENT_REQUEST = 'GET_ENTITY_OWNERSHIP_CHART_EVENT_REQUEST'
export const GET_ENTITY_OWNERSHIP_CHART_EVENT_REQUEST_GQL = 'GET_ENTITY_OWNERSHIP_CHART_EVENT_REQUEST_GQL'
export const GET_ENTITY_OWNERSHIP_CHART_EVENT_SUCCESS = 'GET_ENTITY_OWNERSHIP_CHART_EVENT_SUCCESS'
export const GET_ENTITY_OWNERSHIP_CHART_EVENT_SUCCESS_GQL = 'GET_ENTITY_OWNERSHIP_CHART_EVENT_SUCCESS_GQL'
export const GET_ENTITY_OWNERSHIP_CHART_EVENT_FAILURE = 'GET_ENTITY_OWNERSHIP_CHART_EVENT_FAILURE'
export const GET_ENTITY_OWNERSHIP_CHART_EVENT_FAILURE_GQL = 'GET_ENTITY_OWNERSHIP_CHART_EVENT_FAILURE_GQL'

export const GET_ENTITY_OWNERSHIP_CHART_NEWS_REQUEST = 'GET_ENTITY_OWNERSHIP_CHART_NEWS_REQUEST'
export const GET_ENTITY_OWNERSHIP_CHART_NEWS_SUCCESS = 'GET_ENTITY_OWNERSHIP_CHART_NEWS_SUCCESS'
export const GET_ENTITY_OWNERSHIP_CHART_NEWS_FAILURE = 'GET_ENTITY_OWNERSHIP_CHART_NEWS_FAILURE'

export const RESET_ENTITY_OWNERSHIP_CHART = 'RESET_ENTITY_OWNERSHIP_CHART'

/**
 * Dispatch GET_ENTITY_OWNERSHIP_CHART_POSITION_REQUEST
 * @param params
 * @param options
 */
const _getPosition = (params, options = {}) => (dispatch) => {
  const positionAction = params.entityType === ENTITY_TYPE.FUND
    ? fetchFundHistoricalPosition
    : fetchInstitutionHistoricalPosition

  if (!params.entityId) {
    return Promise.resolve()
  }

  return dispatch(positionAction({
    securityId: params.securityId,
    entityId: params.entityId,
    quarters: params.quarters
  }, {
    types: options.types || [
      GET_ENTITY_OWNERSHIP_CHART_POSITION_REQUEST,
      GET_ENTITY_OWNERSHIP_CHART_POSITION_SUCCESS,
      GET_ENTITY_OWNERSHIP_CHART_POSITION_FAILURE
    ]
  }))
}

/**
 * Dispatch GET_ENTITY_OWNERSHIP_CHART_STOCK_REQUEST
 * @param params
 */
const _getStock = (params) => (dispatch) => {
  return dispatch(getStockHistorical(params, {
    types: [
      GET_ENTITY_OWNERSHIP_CHART_STOCK_REQUEST,
      GET_ENTITY_OWNERSHIP_CHART_STOCK_SUCCESS,
      GET_ENTITY_OWNERSHIP_CHART_STOCK_FAILURE
    ]
  }))
}

/**
 * Dispatch GET_ENTITY_OWNERSHIP_CHART_EVENTS_REQUEST
 * @param params
 */
const _getEvents = (params) => (dispatch) => {
  return dispatch(getEvents({
    securityId: params.securityId,
    fields: ['title', 'date', 'event_type'],
    startDate: params.startDate.toISOString(),
    endDate: params.endDate.toISOString(),
    limit: 500
  }, {
    types: [
      GET_ENTITY_OWNERSHIP_CHART_EVENT_REQUEST,
      GET_ENTITY_OWNERSHIP_CHART_EVENT_SUCCESS,
      GET_ENTITY_OWNERSHIP_CHART_EVENT_FAILURE
    ]
  }))
}
// ------GRAPHQL implementation ----- //
/**
 * Dispatch GET_EVENTS_REQUEST_GQL
 *
 */
export const _getEventsGQL = (params) => (dispatch) => {
  dispatch({ type: GET_ENTITY_OWNERSHIP_CHART_EVENT_REQUEST_GQL })
  return client
    .query({
      query: GET_EVENTS,
      variables: {
        startDate: params.startDate.toISOString(),
        endDate: params.endDate.toISOString(),
        tickerId: params.tickerId,
        sortDir: 'desc',
        eventType: COMPANY_SPECIFIC_EVENTS,
        page: 1,
        limit: 500
      },
      fetchPolicy: 'no-cache'
    })
    .then((data) => {
      const events = get(data, 'data.event.items')
      dispatch({ payload: events, type: GET_ENTITY_OWNERSHIP_CHART_EVENT_SUCCESS_GQL })
    })
    .catch((error) => {
      dispatch({ error, type: GET_ENTITY_OWNERSHIP_CHART_EVENT_FAILURE_GQL })
    })
}

// GraphQL queries
const GET_EVENTS = gql`
  query Event(
    $startDate: String!
    $endDate: String!
    $page: Int
    $limit: Int
    $sortDir: String
    $tickerId: [String]
    $eventType : [String]
  ) {
    event(
      page: $page,
      limit: $limit,
      sortDir: $sortDir,
      filter: {
        startDate: $startDate,
        endDate: $endDate,
        tickerId: $tickerId,
        eventType: $eventType,
      }
    
    ) {
      items { 
        id
        eventTitle
        eventDate
        eventTypeName
      }
    }
  }`

/**
 * Dispatch GET_ENTITY_OWNERSHIP_CHART_NEWS_REQUEST
 * @param params
 */
const _getNews = (params) => (dispatch) => {
  return dispatch(getNews({
    type: 'press',
    securityId: params.securityId,
    startDate: params.startDate.toISOString(),
    endDate: params.endDate.toISOString(),
    limit: 500
  }, {
    types: [
      GET_ENTITY_OWNERSHIP_CHART_NEWS_REQUEST,
      GET_ENTITY_OWNERSHIP_CHART_NEWS_SUCCESS,
      GET_ENTITY_OWNERSHIP_CHART_NEWS_FAILURE
    ]
  }))
}

/**
 * Get Entity Ownership Chart Historical Position
 * @param params
 * @param params.entityType
 * @param params.entityId
 * @param params.securityId
 * @param params.startDate
 * @param params.endDate
 * @param options
 * @return {function(*): Promise<[]>}
 */
export const getEntityOwnershipChartPosition = (params, options) => (dispatch) => {
  dispatch(_getPosition(params, options))
}

/**
 * Get Entity Ownership Chart Data
 * @param params
 * @param params.securityId
 * @param params.startDate
 * @param params.endDate
 * @param options
 * @param options.useGraphQL
 * @param options.eventSubscribed
 * @return {function(*): Promise<[]>}
 */
export const getEntityOwnershipChart = (params, options) => (dispatch) => {
  const { useGraphQL, eventSubscribed = true } = options
  return Promise.all([
    dispatch(_getStock(params)),
    dispatch(_getNews(params)),
    isTradingTicker(params.tickerId) && eventSubscribed && dispatch(useGraphQL ? _getEventsGQL(params) : _getEvents(params))
  ])
}

/**
 * Reset Entity Ownership Chart
 */
export const resetEntityOwnershipChart = () => (dispatch) => {
  return dispatch({ type: RESET_ENTITY_OWNERSHIP_CHART })
}
