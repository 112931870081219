import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid'
import { Message } from '../../../../components'
import { TextInput, TextArea, Checkbox } from '../../../shared';
import { ComboBox } from '../../../../components'
import SuggestedContacts from './suggestedContacts/suggestedContacts.component'
import { get } from 'lodash'
import { combine, THEMES } from '../../../../utils'

class CustomContactForm extends Component {

  onShowRequestProfileMessage() {
    this.setState({
      showRequestProfileMessage: true
    });
  }

  onCloseRequestProfileMessage() {
    this.setState({
      showRequestProfileMessage: false
    });
  }

  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Select Institution
   * Update values with institution data from search + clear search suggestions
   */
  onSelectInstitution = (item) => {
    const { setFieldValue, clearSuggestions } = this.props;
    const institution = item && item.value
    const address = get(item, 'value.address[0]', {})
    const { address1, address2, address3 } = (address || {})

    // clear search suggestions
    clearSuggestions()

    // populate institution fields
    setFieldValue('street', combine([address1, address2, address3], ' '));
    setFieldValue('city', address.city || '');
    setFieldValue('state', address.state_province || '');
    setFieldValue('zip', address.postal_code || '');
    setFieldValue('countryName', address.country_name || '');
    setFieldValue('country', address.country || '');
    setFieldValue('corporatePhone', address.telephone || '');
    setFieldValue('institutionId', institution.mongo_id || '');
    setFieldValue('institutionName', institution.institution_name || '');
    setFieldValue('institutionType', institution.type || '');
    setFieldValue('factsetEntityId', institution.factset_entity_id || '');
    setFieldValue('q4EntityId', institution.q4_entity_id || '');
  };

  /**
   * Remove Institution
   * Clears institution field values
   */
  onRemoveInstitution = () => {
    const { setFieldValue } = this.props;

    // clear populated institution field values
    [
      'street',
      'city',
      'state',
      'zip',
      'countryName',
      'country',
      'corporatePhone',
      'institutionId',
      'institutionName',
      'factsetEntityId',
      'q4EntityId'
    ].forEach((field) => {
      setFieldValue(field, '');
    });
  };

  /**
   * Custom Institution
   * @param clicked
   */
  onCustomInstitutionClick = (clicked) => {
    this.setState({ isCustomInstitutionClicked: clicked });
    this.onRemoveInstitution();
  };

  /**
   * Format values for Select
   * @param values
   */
  formatSelectValues (values) {
    return (values || []).map((item) => {
      const { _source } = item
      return _source && {
        label: get(_source, 'institution_name'),
        value: _source
      }
    })
  }

  /**
   * Handle Input Change
   * @param query
   */
  handleInputChange = (query) => {
    const { clearSuggestions, onSearch } = this.props
    if (!query) {
      clearSuggestions()
    } else {
      onSearch(query)
    }
  }

  /**
   * Render Institution Field (custom or searchable)
   */
  renderInstitutionField = () => {
    const { isCustomInstitutionClicked } = this.state;
    const { values, errors, handleChange, searchSuggestions } = this.props;
    const isCustomInstitution = (values.id && !values.factsetEntityId && values.institutionName) || isCustomInstitutionClicked;

    const items = values && values.institutionName
      ? [{ label: values.institutionName, value: values.institutionName }]
      : []

    return isCustomInstitution ?
      <TextInput
        id='institutionName'
        value={values.institutionName}
        error={errors.institutionName}
        onChange={handleChange}
        autoFocus={true}
      /> :
      <ComboBox
        selectProps={{
          placeholder: 'Keyword Search',
          theme: THEMES.LIGHT_GREY,
          options: this.formatSelectValues(searchSuggestions),
          value: null,
          disabled: Boolean(values.institutionName),
          error: {
            isError: Boolean(errors.institutionName)
          },
          onInputChange: this.handleInputChange,
          onChange: this.onSelectInstitution
        }}
        removableListProps={{
          theme: THEMES.LIGHT_GREY,
          items,
          onRemove: this.onRemoveInstitution
        }}
      />
  };

  renderRequestProfile = () => {
    const { handleChange, values } = this.props;

      return (
        <div className='field field--full field--request-profile'>
          <Checkbox
            className='checkbox-field--inline'
            id='requestProfile'
            value='requestProfile'
            label='Request Profile Validation'
            size='medium'
            onChange={(val, event) => {
              handleChange(event);
            }}
            isChecked={values.requestProfile}
          />

          <div
            className='button--info button--pale-grey'
            onClick={this.onShowRequestProfileMessage.bind(this)} />
          <Message
            title={'Request Contact Validation'}
            type='info'
            message={'Selecting this option will prompt our data team to reach out to the contact to confirm their details. Once confirmed, they will be added to the the Q4 Contacts Database. Please ensure that you are not including any information that the person wouldn\'t want to be shared with other industry professionals such as home phone numbers or non-work email addresses.'}
            visible={this.state.showRequestProfileMessage}
            onClose={this.onCloseRequestProfileMessage.bind(this)}
            renderExitButton={true}
            buttons={[{
              ui: 'citrus',
              label: 'ok',
              onClick: this.onCloseRequestProfileMessage.bind(this)
            }]}
          />
        </div>
      );
  };

  /**
   * Render
   * @return {XML}
   */
  render() {
    const {
      visible,
      values,
      handleChange,
      errors,
      contactSuggestions,
      onSelectContactSuggestion,
      openModal
    } = this.props;

    if (!visible) {
      return null;
    }
    const { isCustomInstitutionClicked } = this.state;
    const isCustomInstitution = (values.id && !values.factsetEntityId && values.institutionName) || isCustomInstitutionClicked;
    const InstitutionField = this.renderInstitutionField;
    const isEditing = !!values.id

    return (
      <Grid container spacing={3}>

        {/* Column 1 */}
        <Grid item xs={6}>

          <Grid container spacing={3}>

            {/* Inner column 1 */}
            <Grid item xs={6}>
              <div className='field field--text field--full'>
                <label className='field_label' htmlFor='firstName'>First Name <span className='required'>(required)</span></label>
                <TextInput id='firstName'
                  error={errors.firstName}
                  value={values.firstName}
                  onChange={handleChange}
                  placeholder='Jane'
                  autoFocus={true}
                  tabindex={1} />
              </div>

              <div className='field field--text field--full'>
                <label className='field_label' htmlFor='nickName'>Nickname</label>
                <TextInput id='nickName'
                  value={values.nickName}
                  placeholder='Plain Jane'
                  onChange={handleChange}
                  tabindex={3} />
              </div>

              <div className='field field--text field--full'>
                <label className='field_label' htmlFor='phone'>Direct Phone</label>
                <TextInput id='phone'
                  value={values.phone}
                  placeholder='xxx-xxx-xxxx'
                  onChange={handleChange}
                  tabindex={5} />
              </div>

              <div className='field field--text field--full'>
                <label className='field_label' htmlFor='email'>Email</label>
                <TextInput id='email'
                  error={errors.email}
                  errorMessage={errors.email}
                  value={values.email}
                  placeholder='janesmith@email.com'
                  onChange={handleChange}
                  tabindex={7} />
              </div>
            </Grid>

            {/* Inner column 2 */}
            <Grid item xs={6}>
              <div className='field field--text field--full'>
                <label className='field_label' htmlFor='lastName'>Last Name <span
                  className='required'>(required)</span></label>
                <TextInput id='lastName'
                  error={errors.lastName}
                  value={values.lastName}
                  placeholder='Smith'
                  onChange={handleChange}
                  tabindex={2} />
              </div>

              <div className='field field--text field--full'>
                <label className='field_label' htmlFor='jobTitle'>Job Title</label>
                <TextInput id='jobTitle'
                  value={values.jobTitle}
                  placeholder='CEO, Director, Analyst'
                  onChange={handleChange}
                  tabindex={4} />
              </div>

              <div className='field field--text field--full'>
                <label className='field_label' htmlFor='mobile'>Mobile Phone</label>
                <TextInput id='mobile'
                  value={values.mobile}
                  placeholder='xxx-xxx-xxxx'
                  onChange={handleChange}
                  tabindex={6} />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className='field field--text field--full'>
                <label className='field_label' htmlFor='bio'>Biography</label>
                <TextArea
                  id='bio'
                  value={values.bio}
                  onChange={handleChange}
                  maxlength={700}
                  placeholder='Type a brief biography of this contact'
                  tabindex={7} />
              </div>

              {this.renderRequestProfile()}
            </Grid>

          </Grid>

        </Grid>

        {/* Column 2 */}
        <Grid item xs={3}>
          <div className='field field--text field--full field--grouped'>
            <label className='field_label'>Institution Name <span className='required'>(required)</span></label>
            <InstitutionField />
          </div>

          <div className='field field--full'>
            <Checkbox
              id='customInstitution'
              label='Use custom institution'
              size='medium'
              onChange={this.onCustomInstitutionClick}
              isChecked={Boolean(isCustomInstitution)}
            />
          </div>

          <div className='field field--text field--full'>
            <label className='field_label'>Address</label>
            <TextInput id='street'
              className='text-input--condensed'
              placeholder='Street'
              value={values.street}
              onChange={handleChange} />

            <TextInput id='city'
              className='text-input--condensed'
              placeholder='City'
              value={values.city}
              onChange={handleChange} />

            <TextInput id='state'
              className='text-input--condensed'
              placeholder='State / Province'
              value={values.state}
              onChange={handleChange} />

            <TextInput id='zip'
              className='text-input--condensed'
              placeholder='Zip / Postal Code'
              value={values.zip}
              onChange={handleChange} />

            <TextInput id='countryName'
              placeholder='Country'
              value={values.countryName}
              onChange={handleChange} />
          </div>

          <div className='field field--text field--full'>
            <label className='field_label'>Corporate Phone</label>
            <TextInput id='corporatePhone'
              value={values.corporatePhone}
              placeholder='xxx-xxx-xxxx'
              onChange={handleChange} />
          </div>
        </Grid>

        {/* Column 3 */}
        {!isEditing && <Grid item xs={3}>
          <SuggestedContacts
            suggestions={contactSuggestions}
            openModal={openModal}
            onSelect={onSelectContactSuggestion}
          />
        </Grid>}

      </Grid>
    );
  }
}

export default CustomContactForm;
