import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Select } from '../../../../../../../components'
import { THEMES } from '../../../../../../../utils/ui'

/**
 * Turnover Filter
 */
class Turnover extends PureComponent {

  /**
   * Handle filter update for turnover
   * @param options
   */
  handleTurnoverChange = (selectedItems, event) => {
    const { onTurnoverChange, options } = this.props
    const { action, option } = event
    const { value, label } = option

    const selected = (options || []).map((item) => {
      if (item && item.value === value) {
        return action === 'select-option'
          ? { ...item, selected: true }
          : Object.assign({}, { value, label })
      }
      return item
    })

    onTurnoverChange({ turnoverOptions: selected })
  }

  /**
   * Render
   * @return {*}
   */
  render () {
    const { options } = this.props

    return (
      <div className='field field--full field--grouped'>
        <Select
          placeholder='Turnover'
          options={options}
          value={(options || []).filter((item) => item && !!item.selected)}
          isMulti
          isDefaultList
          searchable={false}
          closeMenuOnSelect={false}
          clearable={false}
          theme={THEMES.WHITE}
          onChange={this.handleTurnoverChange}
        />
      </div>
    )
  }
}

Turnover.propTypes = {
  options: PropTypes.array.isRequired,
  onTurnoverChange: PropTypes.func.isRequired
}

export default Turnover
