import React from 'react'
import PropTypes from 'prop-types'

import { Button, Toolbar, ToolbarRow } from '../../../../components'
import {  SectionSearch } from '../../../../components/shared'
import { THEMES } from '../../../../utils'


/**
 * Address Book Toolbar
 * @param props
 * @returns {*}
 * @constructor
 */
const AddressBookToolbar = (props) => {
  const { noData, pageParams, onParamsChange, onExport, exportProgress } = props
  const { search } = pageParams

  const onSearchChange = (searchQuery) => onParamsChange({ search:searchQuery, page: 1 })
  
  return (
    <Toolbar>
      <ToolbarRow justified>
        <div className='toolbar_group toolbar_group--spaced'>
      
        </div>

        <div className='toolbar_group'>
          <SectionSearch
            className='address-book-page_toolbar-search'
            value={search}
            onQueryChange={onSearchChange}
            onClear={onSearchChange}
          />
          <Button
            theme={THEMES.STEEL}
            className='address-book-page_toolbar-export'
            icon='q4i-download-4pt'
            label='Export'
            loading={exportProgress}
            disabled={noData}
            onClick={onExport}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

AddressBookToolbar.propTypes = {
  noData: PropTypes.bool,
  pageParams: PropTypes.shape({
    search: PropTypes.string
  }),
  onParamsChange: PropTypes.func,
  onExport: PropTypes.func
}

AddressBookToolbar.defaultProps = {
  filters: PropTypes.shape({
    search: ''
  })
}

export default AddressBookToolbar
