import {
  FAILED,
  FETCHED,
  FETCHING, IDLE,
  NLG_PEER_FAILURE,
  NLG_PEER_REQUEST,
  NLG_PEER_SUCCESS,
  NLG_SET_CURRENT_PEER
} from '../../../actions/widget/iris'

const initialState = {
  status: IDLE,
  items: [],
  currentPeer: null
}

const nlgPeers = (state = initialState, action) => {
  switch (action.type) {
    case NLG_PEER_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case NLG_PEER_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        items: action.payload
      }
    case NLG_PEER_FAILURE:
      return {
        error: action.error,
        status: FAILED
      }
    case NLG_SET_CURRENT_PEER:
      return {
        ...state,
        currentPeer: action.payload
      }
    default:
      return state
  }
}

export default nlgPeers
