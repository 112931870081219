export const EVENT_TYPES = {
  EARNINGS_CALLS: 'Earnings Calls',
  SHAREHOLDER_ANALYST_CALLS: 'Shareholder/Analyst Calls',
  MA_CALLS: 'M&A Calls',
  OPERATING_RESULTS_CALLS: 'Operating Results Calls',
  GUIDANCE_UPDATE_CALLS: 'Guidance/Update Calls',
  INTERIM_MANAGEMENT_STATEMENT_CALLS: 'Interim Management Statement Calls',
  SALES_TRADING_STATEMENT_CALLS: 'Sales/Trading Statement Calls',
  FIXED_INCOME_CALLS: 'Fixed Income Calls',
  SPECIAL_CALLS: 'Special Calls',
  INTERIM_MANAGEMENT_STATEMENT_RELEASE_DATE: 'Interim Management Statement Release Date',
  SALES_TRADING_STATEMENT_RELEASE_DATE: 'Sales/Trading Statement Release Date',
  ESTIMATED_EARNINGS_RELEASE_DATE_SP_GLOBAL_DERIVED: 'Estimated Earnings Release Date (S&P Global Derived)',
  EARNINGS_RELEASE_DATE: 'Earnings Release Date',
  ANALYST_INVESTOR_DAY: 'Analyst/Investor Day',
  CONFERENCES: 'Conferences',
  COMPANY_CONFERENCE_PRESENTATIONS: 'Company Conference Presentations',
  ANNUAL_GENERAL_MEETING: 'Annual General Meeting',
  INDEX_CONSTITUENT_ADDS: 'Index Constituent Adds',
  INDEX_CONSTITUENT_DROPS: 'Index Constituent Drops',
  DELAYED_EARNINGS_ANNOUNCEMENTS: 'Delayed Earnings Announcements'
}

export const EVENT_TYPE_OPTIONS = [
  {
    label: EVENT_TYPES.EARNINGS_CALLS,
    value: EVENT_TYPES.EARNINGS_CALLS
  },
  {
    label: EVENT_TYPES.SHAREHOLDER_ANALYST_CALLS,
    value: EVENT_TYPES.SHAREHOLDER_ANALYST_CALLS
  },
  {
    label: EVENT_TYPES.MA_CALLS,
    value: EVENT_TYPES.MA_CALLS
  },
  {
    label: EVENT_TYPES.OPERATING_RESULTS_CALLS,
    value: EVENT_TYPES.OPERATING_RESULTS_CALLS
  },
  {
    label: EVENT_TYPES.GUIDANCE_UPDATE_CALLS,
    value: EVENT_TYPES.GUIDANCE_UPDATE_CALLS
  },
  {
    label: EVENT_TYPES.INTERIM_MANAGEMENT_STATEMENT_CALLS,
    value: EVENT_TYPES.INTERIM_MANAGEMENT_STATEMENT_CALLS
  },
  {
    label: EVENT_TYPES.SALES_TRADING_STATEMENT_CALLS,
    value: EVENT_TYPES.SALES_TRADING_STATEMENT_CALLS
  },
  {
    label: EVENT_TYPES.FIXED_INCOME_CALLS,
    value: EVENT_TYPES.FIXED_INCOME_CALLS
  },
  {
    label: EVENT_TYPES.SPECIAL_CALLS,
    value: EVENT_TYPES.SPECIAL_CALLS
  },
  {
    label: EVENT_TYPES.INTERIM_MANAGEMENT_STATEMENT_RELEASE_DATE,
    value: EVENT_TYPES.INTERIM_MANAGEMENT_STATEMENT_RELEASE_DATE
  },
  {
    label: EVENT_TYPES.SALES_TRADING_STATEMENT_RELEASE_DATE,
    value: EVENT_TYPES.SALES_TRADING_STATEMENT_RELEASE_DATE
  },
  {
    label: EVENT_TYPES.ESTIMATED_EARNINGS_RELEASE_DATE_SP_GLOBAL_DERIVED,
    value: EVENT_TYPES.ESTIMATED_EARNINGS_RELEASE_DATE_SP_GLOBAL_DERIVED
  },
  {
    label: EVENT_TYPES.EARNINGS_RELEASE_DATE,
    value: EVENT_TYPES.EARNINGS_RELEASE_DATE
  },
  {
    label: EVENT_TYPES.ANALYST_INVESTOR_DAY,
    value: EVENT_TYPES.ANALYST_INVESTOR_DAY
  },
  {
    label: EVENT_TYPES.CONFERENCES,
    value: EVENT_TYPES.CONFERENCES
  },
  {
    label: EVENT_TYPES.COMPANY_CONFERENCE_PRESENTATIONS,
    value: EVENT_TYPES.COMPANY_CONFERENCE_PRESENTATIONS
  },
  {
    label: EVENT_TYPES.ANNUAL_GENERAL_MEETING,
    value: EVENT_TYPES.ANNUAL_GENERAL_MEETING
  },
  {
    label: EVENT_TYPES.INDEX_CONSTITUENT_ADDS,
    value: EVENT_TYPES.INDEX_CONSTITUENT_ADDS
  },
  {
    label: EVENT_TYPES.INDEX_CONSTITUENT_DROPS,
    value: EVENT_TYPES.INDEX_CONSTITUENT_DROPS
  },
  {
    label: EVENT_TYPES.DELAYED_EARNINGS_ANNOUNCEMENTS,
    value: EVENT_TYPES.DELAYED_EARNINGS_ANNOUNCEMENTS
  }
]

export const EVENT_SEARCH_FIELDS = ['eventTitle', 'symbol', 'companyName']

// exclude conferences
export const COMPANY_SPECIFIC_EVENTS = [
  EVENT_TYPES.EARNINGS_CALLS,
  EVENT_TYPES.EARNINGS_RELEASE_DATE,
  EVENT_TYPES.FIXED_INCOME_CALLS,
  EVENT_TYPES.GUIDANCE_UPDATE_CALLS,
  EVENT_TYPES.MA_CALLS,
  EVENT_TYPES.OPERATING_RESULTS_CALLS,
  EVENT_TYPES.SALES_TRADING_STATEMENT_CALLS,
  EVENT_TYPES.SHAREHOLDER_ANALYST_CALLS,
  EVENT_TYPES.SPECIAL_CALLS,
  EVENT_TYPES.ANALYST_INVESTOR_DAY,
  EVENT_TYPES.ESTIMATED_EARNINGS_RELEASE_DATE_SP_GLOBAL_DERIVED,
  EVENT_TYPES.SALES_TRADING_STATEMENT_RELEASE_DATE,
  EVENT_TYPES.INTERIM_MANAGEMENT_STATEMENT_CALLS,
  EVENT_TYPES.INTERIM_MANAGEMENT_STATEMENT_RELEASE_DATE,
  EVENT_TYPES.ANNUAL_GENERAL_MEETING,
  EVENT_TYPES.INDEX_CONSTITUENT_ADDS,
  EVENT_TYPES.INDEX_CONSTITUENT_DROPS,
  EVENT_TYPES.DELAYED_EARNINGS_ANNOUNCEMENTS
]
