import {
  GET_ACTIVITY_LIST_REQUEST,
  GET_ACTIVITY_LIST_SUCCESS,
  GET_ACTIVITY_LIST_FAILURE,
  RESET_ACTIVITY_LIST,
  GET_CONTACT_FILTER_REQUEST_GQL,
  GET_CONTACT_FILTER_SUCCESS_GQL,
  GET_CONTACT_FILTER_FAILURE_GQL,
  RESET_CONTACT_FILTER,
  GET_INSTITUTION_FILTER_REQUEST_GQL,
  GET_INSTITUTION_FILTER_SUCCESS_GQL,
  GET_INSTITUTION_FILTER_FAILURE_GQL,
  RESET_INSTITUTION_FILTER,
  statusType
} from '../../actions'
import { formatActivities } from '../../utils/activity'

const initial = {
  initialLoading: true,
  data: [],
  contactFilter: [],
  contactFilterLoading: false,
  institutionFilter: [],
  institutionFilterLoading: false,
  total: 0,
  status: statusType.IDLE,
}

const activityList = (state = initial, action) => {
  const { payload, meta, error } = action

  switch (action.type) {
    // ACTIVITIES
    case GET_ACTIVITY_LIST_REQUEST:
      return {
        ...state,
        initialLoading: true,
        status: statusType.IN_PROGRESS
      }

    case GET_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        ...meta,
        data: formatActivities(payload),
        status: statusType.SUCCESS,
        initialLoading: false
      }

    case GET_ACTIVITY_LIST_FAILURE:
      return {
        error,
        status: statusType.ERROR,
        initialLoading: false
      }

    case RESET_ACTIVITY_LIST:
      return initial

    // CONTACTS
    case GET_CONTACT_FILTER_REQUEST_GQL:
      return {
        ...state,
        contactFilterLoading: true,
      }

    case GET_CONTACT_FILTER_SUCCESS_GQL:
      return {
        ...state,
        ...meta,
        contactFilterLoading: false,
        type: action.type,
        contactFilter: (payload || []).map((contact) => {
          return {
            value: contact?.id,
            label: contact?.entityConnection?.fullName
          }
        }),
        status: statusType.SUCCESS
      }

    case GET_CONTACT_FILTER_FAILURE_GQL:
      return {
        ...state,
        contactFilterLoading: false,
        status: statusType.ERROR
      }

    case RESET_CONTACT_FILTER:
      return {
        ...state,
        contactFilter: []
      }

    // INSTITUTIONS
    case GET_INSTITUTION_FILTER_REQUEST_GQL:
      return {
        ...state,
        institutionFilterLoading: true
      }

    case GET_INSTITUTION_FILTER_SUCCESS_GQL:
      return {
        ...state,
        ...meta,
        institutionFilterLoading: false,
        institutionFilter: (payload || []).map((inst) => {
          return {
            value: inst?.entityConnection?.entityId,
            label: inst?.entityConnection?.institutionName
          }
        }),
        status: statusType.SUCCESS,
      }

    case GET_INSTITUTION_FILTER_FAILURE_GQL:
      return {
        ...state,
        institutionFilterLoading: false,
        status: statusType.ERROR
      }

    case RESET_INSTITUTION_FILTER:
      return {
        ...state,
        institutionFilter: []
      }

    default:
      return state
  }
}

export default activityList
