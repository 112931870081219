import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { AgGrid, Message, PopoverMenu } from '../../../../components'
import { getDefaultColumnDef, preventDefaultRowClick } from '../../../../utils/agGrid.util'
import { getActivityTypeOptions, isParent } from '../../../../utils'
import CustomCellRender from './cell/cell.component'

const autoGroupColumn = {
  ...getDefaultColumnDef({ columnIndex: 0, isAlternating: false }),
  headerName: '', minWidth: 60, maxWidth: 60,
  cellRendererParams: {
    suppressCount: true,
    suppressPadding: true,
  },
  headerCheckboxSelection: true, checkboxSelection: true,
  cellRenderer: 'CustomCellRender', sortable: false, pinned: 'left', lockPinned: true
}

/**
 * Delete Confirm Modal
 * @param onClose
 * @param title
 * @param message
 * @param onDeleteConfirm
 * @returns {*}
 * @constructor
 */
const ConfirmModal = ({ onClose, title, message, onDeleteConfirm }) => {
  return (
    <Message
      visible
      type='warning'
      title={title}
      message={message}
      onClose={onClose}
      buttons={[
        {
          ui: 'shaded',
          label: 'cancel',
          onClick: onClose
        },
        {
          ui: 'spice',
          label: 'delete',
          onClick: () => onDeleteConfirm()
        }
      ]}
    />
  )
}

/**
 * Activity Table
 */
class ActivityTable extends PureComponent {
  /**
   * constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      columns: [
        { ...getDefaultColumnDef({ columnIndex: 1, type: 'icon', isAlternating: false }), field: 'category', headerName: 'Type', minWidth: 80, maxWidth: 80, pinned: 'left', lockPinned: true },
        { ...getDefaultColumnDef({ columnIndex: 2, isAlternating: false }), field: 'title', minWidth: 300, pinned: 'left', lockPinned: true },
        // regular columns with alternating styles
        { ...getDefaultColumnDef({ columnIndex: 4 }), field: 'contact', minWidth: 180, maxWidth: 220 },
        { ...getDefaultColumnDef({ columnIndex: 5 }), field: 'institution', minWidth: 180, maxWidth: 220 },
        { ...getDefaultColumnDef({ columnIndex: 6 }), field: 'address', headerName: 'Location', minWidth: 180, maxWidth: 220 },
        { ...getDefaultColumnDef({ columnIndex: 7, type: 'date' }), field: 'start', headerName: 'Start Date', minWidth: 120, maxWidth: 120, sort: 'desc' },
        { ...getDefaultColumnDef({ columnIndex: 8 }), field: 'tag', headerName: 'Tags', minWidth: 220, maxWidth: 260, sortable: false }
      ],
      popoverMenuProps: null,
      selectedIds: []
    }
  }

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  handleGridReady = (grid) => {
    setTimeout(() => this.agGrid = grid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => this.agGrid && this.agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle AgGrid Column Sort
   * @param grid
   */
  handleSortChange = (grid) => {
    const { handleFilterChange } = this.props
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel && !sortModel.length) {
      return
    }

    handleFilterChange && handleFilterChange({
      sort: {
        property: (sortModel[0].colId === 'start')
          ? 'start.date_time'
          : (sortModel[0].colId === 'address')
            ? 'location'
            : sortModel[0].colId,
        direction: sortModel[0].sort
      },
      page: 1
    })
  }

  /**
   * Handle row click event
   * @param _id {String} - activity id
   */
  handleRowClicked = ({ _id }) => {
    _id && this.props.history.push(`/activity/${_id}`)
  }

  /**
   * On Expand Menu Click
   * Displays expandable menu for contact/institutions and tags
   * @param event
   * @param popoverMenuProps
   */
  onExpandMenuClick = (event, popoverMenuProps) => {
    event.stopPropagation()
    this.setState({ popoverMenuProps })
  }

  /**
   * On Expand Menu Close Click
   * @param event
   */
  onExpandMenuCloseClick = (event) => {
    event.stopPropagation()
    this.setState({ popoverMenuProps: null })
  }

  /**
   * On Expand Menu Item Click
   * Handler for activity links menu item click
   * @param event
   * @param data
   */
  onExpandMenuItemClick = (event, data) => {
    event.stopPropagation()

    const { history, handleExpandMenuItemClick } = this.props
    const type = (data && data.entity_type) || ''

    if (data && data.reference) {
      return this.onTagClick(event, data)
    }

    switch (type.toLowerCase()) {
      case 'contact':
        handleExpandMenuItemClick
          ? handleExpandMenuItemClick({ entityType: 'contact', contactId: data.item })
          : history.push(`/contact/${data.item}`)
        break
      case 'institution':
        handleExpandMenuItemClick
          ? handleExpandMenuItemClick({ entityType: 'institution', entityId: data.entity_id })
          : history.push(`/institution/${data.entity_id}`)
        break
      default:
        return
    }
  }

  /**
   * On Tag Click
   * @param event
   * @param tag
   */
  onTagClick = (event, tag) => {
    event.stopPropagation()

    const { history } = this.props
    history && history.push(`/search?query=${encodeURIComponent('#' + tag.name)}`)
  }

  /**
   * Get Delete Confirmation Message based on number of selected activities
   */
  getConfirmMessage = () => {
    const { data } = this.props
    const { selectedIds } = this.state

    const hasItinerary = selectedIds && (data || [])
      .find((record) => (selectedIds.indexOf(record && record._id) >= 0 && isParent(record.category)))

    const title = `Delete ${(selectedIds && selectedIds.length > 1) ? 'Activities' : 'Activity'}?`
    const message = `Are you sure you want to delete ${(selectedIds && selectedIds.length > 1) ? 'these activities' : 'this activity'}? 
                    ${hasItinerary ? 'Deleting them will also delete all Itinerary Activities.' : ''}`

    return { title, message }
  }

  /**
   * Handles AgGrid bulk delete
   */
  onBulkDelete = () => {
    const { openModal } = this.props
    const selectedRows = this.agGrid && this.agGrid.api && this.agGrid.api.getSelectedRows()
    if (!selectedRows || !selectedRows.length) {
      return
    }

    this.setState({
      selectedIds: (selectedRows || []).map((row) => row && row._id)
    })

    openModal({
      component: ({ onClose }) => ConfirmModal({
        onClose,
        title: this.getConfirmMessage().title,
        message: this.getConfirmMessage().message,
        onDeleteConfirm: this.onBulkDeleteConfirm
      })
    })
  }

  /**
   * Handle confirm message
   */
  onBulkDeleteConfirm = () => {
    const { handleDeleteSelection, closeModal } = this.props
    const { selectedIds } = this.state

    handleDeleteSelection(selectedIds)
    closeModal({
      component: ConfirmModal
    })
  }

  /**
   * Renders CustomCell Component
   */
  renderCustomCell = (props) => {
    return (
      <CustomCellRender
        {...props}
        onTagClick={this.onTagClick}
        onExpandMenuClick={this.onExpandMenuClick}
        onExpandMenuItemClick={this.onExpandMenuItemClick}
      />
    )
  }

  /**
   * Returns grid columns
   */
  getColumnsDefinition = () => {
    return this.state.columns.map(({ type, ...column }, idx) => ({
      ...getDefaultColumnDef({ columnIndex: idx, type }),
      ...column
    }))
  }

  render () {
    const { isGridReady, filters: { page, limit }, data, total, handleFilterChange, pageSizeId } = this.props
    return (
      <>
        {isGridReady && (<AgGrid
          className='activity-grid--old_table'
          domLayout='autoHeight'
          sizeToFit={true}

          // suppress configs
          suppressMovableColumns={true}
          suppressContextMenu={true}

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            sortable: true,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={this.getColumnsDefinition()}
          rowData={data}

          // bulk actions
          bulkActions={[{
            id: 'entity-activity-list-delete',
            icon: 'q4i-trashbin-2pt',
            onSelect: this.onBulkDelete
          }]}

          // pagination
          pagination
          paginationProps={{
            pageSizeId,
            forcePage: page,
            initialPageSize: limit,
            showPageSizeSelection: true,
            total,
            onPageChange: ({ selected }) => handleFilterChange({ page: selected }),
            onPageSizeChange: ({ selected: newPageSize }) => handleFilterChange({ limit: newPageSize, page: 1 })
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: this.renderCustomCell
          }}

          // hierarchy grouping for expandable rows
          treeData={true}
          groupDefaultExpanded={0}
          getDataPath={(data) => data.hierarchy}
          autoGroupColumnDef={autoGroupColumn}

          // event listeners
          onGridReady={this.handleGridReady}
          onGridSizeChanged={this.handleGridResize}
          onSortChanged={this.handleSortChange}
          onRowClicked={preventDefaultRowClick(
            this.handleRowClicked,
            ['cell-title_toggle', 'expandable-cell--clickable', 'tags-item']
          )}

          // css overwrites
          rowClassRules={{
            'ag-row--expanded': ({ data, node }) => data.itinerary || node.expanded || node.parent.expanded
          }}
          isPinned={data && data.length} />)}

        {/* Popover Menu for Custom Cell */}
        {this.state.popoverMenuProps && (<PopoverMenu
            arrowClass='bottom-right'
            onClose={this.onExpandMenuCloseClick}
            onClick={this.onExpandMenuItemClick}
            scrollable={true}
            {...this.state.popoverMenuProps} />
        )}
      </>
    )
  }
}

ActivityTable.propTypes = {
  isGridReady: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,

  filters: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,

  data: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.oneOf(getActivityTypeOptions().map((type) => type.value)).isRequired,
    hierarchy: PropTypes.arrayOf(PropTypes.string).isRequired
  })).isRequired,

  total: PropTypes.number.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleDeleteSelection: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  pageSizeId: PropTypes.string
}

ActivityTable.defaultProps = {
  loading: false,
  data: [],
  total: 0
}

export default ActivityTable
