import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { RESET_CONTACT } from '../contact'

/**
 * News Action Types
 */

export const GET_NEWS_DETAIL_REQUEST = 'GET_NEWS_DETAIL_REQUEST'
export const GET_NEWS_DETAIL_SUCCESS = 'GET_NEWS_DETAIL_SUCCESS'
export const GET_NEWS_DETAIL_FAILURE = 'GET_NEWS_DETAIL_FAILURE'
export const RESET_NEWS_DETAIL = 'RESET_NEWS'

export const GET_NEWS_REQUEST = 'GET_NEWS_HISTORICAL_REQUEST'
export const GET_NEWS_SUCCESS = 'GET_NEWS_HISTORICAL_SUCCESS'
export const GET_NEWS_FAILURE = 'GET_NEWS_HISTORICAL_FAILURE'
export const RESET_NEWS = 'RESET_NEWS'

/**
 * Get News
 * Returns list of news for given security
 * @param params.securityId
 * @param params.type - i.e. 'press'
 * @param params.startDate
 * @param params.endDate
 * @param params.limit
 * @param options
 * @return {{}}
 * @private
 */
const _getNews = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_NEWS_REQUEST,
      GET_NEWS_SUCCESS,
      GET_NEWS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/news/historical',
    payload: {
      ...params,
      limit: params.limit || 20
    }
  }
})

/**
 * Get News Detail
 * @param id
 * @param options
 */
const _getNewsDetail = (id, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_NEWS_DETAIL_REQUEST,
      GET_NEWS_DETAIL_SUCCESS,
      GET_NEWS_DETAIL_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/news/${id}`
  }
})

/**
 * Dispatch GET_NEWS_REQUEST
 * @param params
 * @param options
 */
export const getNews = (params, options) => (dispatch) => {
  dispatch(_getNews(params, options))
}

/**
 * Dispatch GET_NEWS_DETAIL_REQUEST
 * @param id
 * @param options
 */
export const getNewsDetail = (id, options) => (dispatch) => {
  dispatch(_getNewsDetail(id, options))
}

/**
 * Dispatch RESET_NEWS
 */
export const resetNews = () => (dispatch) => {
  return dispatch({ type: RESET_CONTACT })
}

/**
 * Dispatch RESET_NEWS_DETAIL
 */
export const resetNewsDetail = () => (dispatch) => {
  return dispatch({ type: RESET_NEWS_DETAIL })
}
