import gql from 'graphql-tag'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'

const DELETE_TARGET = gql`
  mutation Target(
    $id: [String]!
  ) {
    target {
      delete(
        id: $id
      ) {
        count
      }
    }
  }`

export const GET_TARGETING_SEARCH = gql`
  query Targeting(
    $entityType: String!
    $securityId: String!
    $page: Int
    $limit: Int
    $search: String
    $excludeActivists: Boolean
    $style: [String]
    $type: [String]
    $turnover: [String]
    $activity: Boolean
    $startDate: String
    $endDate: String
    $minPurchasingPower:String
    $maxPurchasingPower:String
  ) {
    targeting(
        entityType: $entityType
        page: $page
        limit: $limit
        search: $search
        securityId: $securityId
        filter: {
          excludeActivists: $excludeActivists, 
          style: $style, 
          type: $type, 
          turnover: $turnover,
          activity: $activity
          activityDate: {
            startDate: $startDate,
            endDate: $endDate
          }
          purchasingPower: {
            minPurchasingPower:$minPurchasingPower,
            maxPurchasingPower:$maxPurchasingPower
          }
        }
      ) {
      items {
        entityId
        institutionId
        entityName
        entityType
        style
        type
        turnover
        totalAum
        activist
        qualityRating
        equityAum
        purchasingPower
        aiScore
        activityConnection{
          items {
            id
            category
            title
            start {
              date
              timezone
            }
          }
        }
      }
    }
  }`

/**
 * Delete Target
 * @param options
 */
export function useTargetDeleteQuery (options) {
  return useMutation(DELETE_TARGET, options)
}

/**
 * Get targeting search
 * @param options
 */
export function useTargetingSearchQuery (options) {
  return useLazyQuery(GET_TARGETING_SEARCH, options)
}
