import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Message } from '../../../../../../../components'
import { TextInput } from '../../../../../../../components/shared'
import { THEMES } from '../../../../../../../utils'

/**
 * Info Modal
 * @param onClose
 * @return {*}
 * @constructor
 */
const InfoModal = ({ onClose }) => {
  return (
    <Message
      visible={true}
      type='info'
      title='AUM'
      message={
        '<p>Assets under management (AUM) is the total market value of assets that a financial institution or fund manages on behalf of investors.</p>'
      }
      useHtml={true}
      onClose={onClose}
      renderExitButton={true}
      buttons={[{
        ui: 'citrus',
        label: 'Ok',
        onClick: onClose
      }]}
    />
  )
}

/**
 * AUM Filter
 */
class AUM extends PureComponent {

  /**
   * On Min Value Change
   * @param event
   */
  onMinChange = (event) => {
    const { value } = this.props
    const minValue = event.currentTarget.value
    const maxValue = value && value[1]

    this.onChange([minValue, maxValue])
  }

  /**
   * On Max Value Change
   * @param event
   */
  onMaxChange = (event) => {
    const { value } = this.props
    const maxValue = event.currentTarget.value
    const minValue = value && value[0]

    this.onChange([minValue, maxValue])
  }

  /**
   * Wrapper for onchange with validation on fly
   * @param value
   */
  onChange = (value) => {
    const { onChange, onValidate } = this.props
    const ret = this.validate(false, value)
    onValidate && onValidate(ret)
    onChange(value)
  }

  /**
   * Restrict special characters
   * @param event
   */
  onKeyDown = (event) => {
    const restrictedKeys = [109, 189, 187]
    const keyCode = event.which || event.keyCode

    if (restrictedKeys.includes(keyCode)) {
      event.preventDefault()
    }
  }

  /**
   * Show Info Modal
   */
  onInfoClick = () => {
    const { openModal } = this.props
    openModal({
      component: InfoModal
    })
  }

  /**
   * Validation
   * @return {boolean}
   */
  validate = (throwError = true, formValue = null) => {
    const { onError } = this.props
    const value = formValue || this.props.value
    const minValue = parseInt(value[0], 0)
    const maxValue = parseInt(value[1], 0)

    if (minValue < 0) {
      throwError && onError('Min value must be 0 or above')
      return false
    }

    if (maxValue < 0) {
      throwError && onError('Max value must be 0 or above')
      return false
    }

    if (minValue > maxValue && !isNaN(maxValue)) {
      throwError && onError('Min value cannot be greater than the max')
      return false
    }

    onError(null)
    return true
  }

  /**
   * Render
   * @return {*}
   */
  render () {
    const { value, error } = this.props
    const min = (value && value[0]) || ''
    const max = (value && value[1]) || ''

    return (
      <div className='field field--text field--coupled'>
        <label className='field_label'>
          AUM ($MM)
          <span className='button--info button--soft-grey' onClick={this.onInfoClick}/>
        </label>
        <div className='field_set'>
          <TextInput
            type='number'
            min={0}
            value={min}
            error={error}
            placeholder='Min'
            autoComplete='false'
            onChange={this.onMinChange}
            onKeyDown={this.onKeyDown}
            onBlur={this.validate}
            theme={THEMES.WHITE}
          />
          <TextInput
            type='number'
            min={0}
            value={max}
            error={error}
            placeholder='Max'
            autoComplete='false'
            onChange={this.onMaxChange}
            onKeyDown={this.onKeyDown}
            onBlur={this.validate}
            theme={THEMES.WHITE}
          />
        </div>
        {error && (
          <div className='field_error'>
            {error}
          </div>
        )}
      </div>
    )
  }
}

AUM.propTypes = {
  value: PropTypes.array.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  openModal: PropTypes.func.isRequired
}

export default AUM
