import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { colorOptions, getCompleteSeries } from '../../../../utils/report'
import { Select } from '../../../../components'
import { THEMES } from '../../../../utils/ui'

import './pieSlices.component.css'

/**
 * SeriesColorSelectOption
 * @param option
 */
function SeriesColorSelectOption (option) {
  return option && (
    <span className='report-series-config_swatch' style={{ backgroundColor: option }} />
  )
}

/**
 * ReportPieSlices
 * @param chartOptions
 * @param reportSnapshot
 * @param onChange
 */
function ReportPieSlices ({ chartOptions, reportSnapshot, onChange }) {
  const [selectedPieSlice, setPieSlice] = useState(null)
  const { series } = chartOptions || {}
  const completeSeries = getCompleteSeries(series.slice(0, 1))
  const { _id: seriesId, dataLabelsColor } = completeSeries[0] || {}

  /**
   * Recalculate selectedPieSlice if dataLabels changed
   */
  useEffect(() => {
    if (selectedPieSlice) {
      setPieSlice((dataLabelsColor || []).find((dataLabel) => dataLabel.label === selectedPieSlice.label) || null)
    }
  }, [dataLabelsColor, selectedPieSlice])

  /**
   * Updates Pie Slice Color
   * @param color
   */
  const updatePieSliceColor = (color) => {
    onChange(series.map((eachSeries) => {
      if (eachSeries._id === seriesId) {
        return {
          ...eachSeries,
          dataLabelsColor: dataLabelsColor.map((dataLabel) => dataLabel.label === selectedPieSlice.label
            ? { ...dataLabel, color }
            : dataLabel
          )
        }
      }
      return eachSeries
    }))
  }

  const selectLabelOptions = [].concat(dataLabelsColor || [])
    .map((option) => option && { value: option, ...option })

  return (
    <section>
      <div className='pie-config-item'>
        <div className='pie-config-item_label'>Pie Slices</div>
        <Select
          theme={THEMES.WHITE}
          options={selectLabelOptions}
          noOptionsMessage='No Data Available'
          value={selectedPieSlice}
          onChange={setPieSlice}
          clearable={false}
          searchable={false}
        />
      </div>
      {selectedPieSlice && (
        <div className='pie-config-item'>
          <div className='pie-config-item_label'>Color</div>
          <Select
            className='pie-config-item_colorpicker'
            placeholder='Select a color'
            theme={THEMES.WHITE}
            maxHeight={220}
            options={(colorOptions || []).map((option) => option && { label: SeriesColorSelectOption(option), value: option })}
            value={selectedPieSlice.color && { label: SeriesColorSelectOption(selectedPieSlice.color), value: selectedPieSlice.color }}
            onChange={(option) => option.value && updatePieSliceColor(option.value)}
            clearable={false}
            searchable={false}
          />
        </div>
      )}
    </section>
  )
}

ReportPieSlices.propTypes = {
  /**
   * chartOptions
   */
  chartOptions: PropTypes.shape({
    chartType: PropTypes.oneOf(['pie']),
    series: PropTypes.arrayOf(PropTypes.shape({
      _id: PropTypes.string,
      dataLabelsColor: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        color: PropTypes.string
      }))
    })),
    valueFields: PropTypes.arrayOf(PropTypes.string)
  }),

  /**
   * reportSnapshot
   */
  reportSnapshot: PropTypes.array.isRequired,

  /**
   * Used as a callback function to lift the state up to the parent component
   */
  onChange: PropTypes.func.isRequired
}

ReportPieSlices.defaultProps = {}

export default memo(ReportPieSlices)
