import React from 'react'
import PropTypes from 'prop-types'

import { ENTITY_CONTACT, getEntityCategoryIcon, format } from '../../../../../../../utils'

import './cell.component.css'

const EMPTY_PLACEHOLDER = '-'

/**
 * Title Custom Cell Component
 * @param type
 * @param name
 * @param institutionName
 */
const TitleCell = ({ type, name, institutionName }) => {
  return (
    <div className='cell'>
      <i className={`cell_icon cell_icon--${type.toLowerCase()} ${getEntityCategoryIcon(type.toLowerCase())}`} />
      <div className='cell_title'>
        {name}
        {type === ENTITY_CONTACT && <div className='cell_sub-title'>{institutionName}</div>}
      </div>
    </div>
  )
}

/**
 * Email Custom Cell Component
 * @param {object} props
 * @param {object} props.contact
 * @param {function} props.handleEmailClick
 * @returns {JSX.Element}
 */
const EmailCell = (props) => {
  const { contact, handleEmailClick } = props

  if (contact?.email) {
    return (
      <div className='cell cell--email'>
        <div onClick={handleEmailClick.bind(null, contact)}>
          <i className='cell_icon cell_icon--email q4i-mail-2pt' />
        </div>
      </div>
    )
  } else {
    return <div className='cell cell--email'>{EMPTY_PLACEHOLDER}</div>
  }
}

EmailCell.propTypes = {
  contact: PropTypes.object,
  handleEmailClick: PropTypes.func
}

/**
 * AgGrid Custom Cell Render Component
 * @param props
 */
const CustomCell = (props) => {
  const { data = {}, column = {} } = props
  const cellData = data[column.colId]
  switch (column.colId) {
    case 'name':
      return <TitleCell {...data} />
    case 'location':
      return cellData || EMPTY_PLACEHOLDER
    case 'email':
      return (
        <EmailCell
          contact={data}
          handleEmailClick={props.handleEmailClick}
        />
      )
    case 'phone':
      return cellData || EMPTY_PLACEHOLDER
    case 'position':
      return Number(cellData) ? format(cellData) : EMPTY_PLACEHOLDER
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
