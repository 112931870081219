import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { formatStockPrice, getValueSign, format } from '../../../../utils'
import './quoteHeader.component.css'

class StockQuoteHeader extends Component {
  render () {
    const { tickerId, history, stockQuote } = this.props
    const security = stockQuote?.Security
    const last = formatStockPrice(stockQuote?.Last, stockQuote?.Currency, true)
    const change = parseFloat(formatStockPrice(stockQuote?.ChangeFromPreviousClose || 0, stockQuote?.Currency)).toFixed(2)
    const changePercent = parseFloat(stockQuote?.PercentChangeFromPreviousClose || 0).toFixed(2)
    const valueSign = getValueSign(stockQuote?.ChangeFromPreviousClose)

    return (
      <div
        className={`sq-header sq-header--${this.props.theme || 'dark'}`} onClick={() => {
          tickerId && history.push(`/security/${tickerId}`)
        }}
      >
        <div className='sq-header_item sq-header_security'>
          <span className='sq-header_symbol'>{security && security.Symbol}</span>
          <span className='sq-header_market'>{security && security.Market}</span>
        </div>
        <div className='sq-header_item sq-header_quote'>
          <span className='sq-header_price'>{last}</span>
          <span className={`sq-header_item sq-header_item--${valueSign}`}>
            {change} ({changePercent}%)
          </span>
        </div>
        <div className='sq-header_item sq-header_item--volume'>
          <div>
            <span className='sq-header_label'>Volume</span>
            <span className='sq-header_value'>{format(stockQuote?.Volume) || 0}</span>
          </div>
          <div>
            <span className='sq-header_label'>Average Vol</span>
            <span className='sq-header_value'>{format(this.props.averageVolume) || 0}</span>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(StockQuoteHeader)
