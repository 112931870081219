import {
  GET_CONTACT_REQUEST,
  GET_CONTACT_SUCCESS,
  GET_CONTACT_FAILURE,
  RESET_CONTACT,
  GET_TARGET_SUCCESS,
  CREATE_TARGET_SUCCESS,
  REMOVE_TARGET_SUCCESS,
  GET_SAVED_CONTACT_SUCCESS,
  ADD_CONTACT_TO_ADDRESS_BOOK_SUCCESS,
  REMOVE_CONTACT_FROM_ADDRESS_BOOK_SUCCESS,
  statusType
} from '../../../actions'

const initial = {
  _id: null,
  entityId: null,
  salutation: '',
  suffix: '',
  name: '',
  nickname: '',
  phone: '',
  extension: '',
  mobile: '',
  email: '',
  bio: '',
  jobs: [],
  funds: [],
  source: '',
  contactStatus: '',
  status: statusType.IDLE
}

const contact = (state = initial, action) => {
  const { payload = {} } = action

  switch (action.type) {
    case GET_CONTACT_REQUEST:
      return {
        ...initial,
        status: statusType.IN_PROGRESS
      }

    case GET_CONTACT_SUCCESS:
      return {
        ...state,
        _id: payload._id || null,
        entityId: payload.factset_person_id || null,
        salutation: payload.salutation || '',
        suffix: payload.suffix_prof || '',
        name: payload.full_name || '',
        firstName: payload.first_name || '',
        lastName: payload.last_name || '',
        nickname: payload.nickname || '',
        phone: payload.direct_phone || '',
        extension: payload.phone_extension || '',
        mobile: payload.mobile || '',
        email: payload.email || '',
        bio: payload.bio || '',
        jobs: payload.jobs || [],
        funds: payload.managed_funds || [],
        source: payload.source,
        contactStatus: payload.status,
        deal: payload._deal || null,
        status: statusType.SUCCESS
      }

    case GET_CONTACT_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case RESET_CONTACT:
      return {
        ...initial
      }

    case GET_TARGET_SUCCESS:
      return {
        ...state,
        _target: payload.targetId,
        targetDate: payload.targetDate
      }

    case CREATE_TARGET_SUCCESS:
      return {
        ...state,
        _target: payload.reference && payload.reference.item,
        targetDate: payload.create_date
      }

    case REMOVE_TARGET_SUCCESS:
      return {
        ...state,
        _target: null,
        targetDate: null
      }

    case GET_SAVED_CONTACT_SUCCESS:
      return {
        ...state,
        _favorite: payload._id
      }

    case ADD_CONTACT_TO_ADDRESS_BOOK_SUCCESS:
      return {
        ...state,
        _favorite: payload._id
      }

    case REMOVE_CONTACT_FROM_ADDRESS_BOOK_SUCCESS:
      return {
        ...state,
        _favorite: null
      }

    default:
      return state
  }
}

export default contact
