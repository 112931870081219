import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class DataTableCell extends PureComponent {

    /**
     * Handle onClick event for DataTableCell
     * @param event
     */
    handleCellClick = (event) => {
        const {id, rowData, onCellClick} = this.props;
        onCellClick && onCellClick(event, id, rowData);
    };

    /**
     * Get TableCell class based on column properties
     * @param className
     * @param headerCell
     * @returns {*}
     */
    getDataTableCellClass = (className, headerCell) => {
        const {id, width, textAlign, dense, sortable, highlighted, isAlternate, onCellClick} = this.props;

        const classArray = [
            className ? `data-table_cell ${className}` : 'data-table_cell',
            headerCell ? 'data-table_cell--head' : 'data-table_cell--body',
            id ? `data-table_cell--${id.toLowerCase()}` : '',
            width ? `data-table_cell--${width}` : 'data-table_cell--auto',
            textAlign ? `data-table_cell--${textAlign}` : '',
            dense ? 'data-table_cell--dense' : '',
            sortable ? 'data-table_cell--sortable' : '',
            highlighted ? 'data-table_cell--highlighted' : '',
            isAlternate ? 'data-table_cell--alternate' : '',
            onCellClick ? 'data-table_cell--clickable' : ''
        ];

        return classArray.join(' ').trim();
    };

    /**
     * Get value for aria-sort based on sortDirection prop
     */
    getSortValue = () => {
        const {sortDirection} = this.props;

        if (!sortDirection) {
            return null;
        }

        return (sortDirection === 'asc' || sortDirection === 'ASC') ? 'ascending' : 'descending';
    };

    /**
     * Render DataTableFooter Component
     * @returns {XML}
     */
    render() {
        const {className, children, headerCell, rowSpan, colSpan} = this.props;

        const baseClassName = this.getDataTableCellClass(className, headerCell);

        return headerCell ? (
            <th
                className={baseClassName}
                rowSpan={rowSpan ? rowSpan : null}
                colSpan={colSpan ? colSpan : null}
                onClick={this.handleCellClick}
                aria-sort={this.getSortValue()}
                scope='col'
            >
                {children}
            </th>
        ): (
            <td
                className={baseClassName}
                rowSpan={rowSpan ? rowSpan : null}
                colSpan={colSpan ? colSpan : null}
                onClick={this.handleCellClick}
            >
                {children}
            </td>
        );
    }
}

DataTableCell.propTypes = {
    /**
     * A custom className to add to the component
     */
    className: PropTypes.string,

    /**
     * Data used to render the DataTableRow content
     * Used to supply onCellClick callback
     */
    rowData: PropTypes.object,

    /**
     * A unique ID for the component
     * Usually derived from the column's ID value
     */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Determines whether or not the component will render a <td> or <th> element
     */
    headerCell: PropTypes.bool,

    /**
     * The width of the table cell. Widths have been assigned categories for consistency
     */
    width: PropTypes.oneOf(['auto', 'icon', 'checkbox', 'narrower', 'narrow', 'default', 'wide', 'wider', 'widest']),

    /**
     * Text alignment for the cell
     */
    textAlign: PropTypes.oneOf(['left', 'center', 'right']),

    /**
     * Used to provide extra padding at the beginning and end of each Data Table Row
     * Only needs to be applied to the first and last column if desired
     */
    dense: PropTypes.bool,

    /**
     * Used to determine whether or not the cell can be used to sort the table
     * Only applicable to header cells
     */
    sortable: PropTypes.bool,

    /**
     * Used to determine sort direction for this column
     * Only applicable to header cells
     */
    sortDirection: PropTypes.oneOf(['asc', 'desc', 'ASC', 'DESC']),

    /**
     * Used to determine whether the cell is highlighted in citrus
     * Only applicable to header cells
     */
    highlighted: PropTypes.bool,

    /**
     * Used to determine whether the cell is tinted for alternation
     */
    isAlternate: PropTypes.bool,

    /**
     * Determines the number of rows this cell will span
     */
    rowSpan: PropTypes.number,

    /**
     * Determines the number of columns this cell will span
     */
    colSpan: PropTypes.number,

    /**
     * A callback for when the user clicks on a cell
     */
    onCellClick: PropTypes.func
};

DataTableCell.defaultProps = {
    width: 'auto',
    textAlign: 'left'
};

export default DataTableCell;