import React, {Component} from 'react';
import {MAIL_SUCCESS, MAIL_ERROR} from '../../actions';
import { Modal } from '../../components';

class TrialReminderModal extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            visible: true
        };
    }

    /**
     * onClose
     */
    onClose = () => {
        const {onClose} = this.props;
        this.setState({visible: false});
        onClose();
    };

    /**
     * onContactUs
     */
    onContactUs = () => {
        const {onClose, sendMail, createToast} = this.props;

        sendMail({
            message: 'Please have sales or CSL rep follow up:',
            template: 'iris-trial-inquiry',
            subject: 'Trial Extension Request',
            to: 'scsm@q4inc.com'
        }).then((response) => {
            if (response.type === MAIL_SUCCESS) {
                createToast({text: 'Inquiry has been successfully sent.'});
            }
            else if (response.type === MAIL_ERROR) {
                createToast({text: 'Oops, something went wrong while trying to send this inquiry.'});
            }

            this.setState({visible: false});
            onClose();
        });
    };

    /**
     * Constructs reminder message with dynamic number of days
     * @param daysUntilExpiry
     * @return {string}
     */
    getReminderMessage = (daysUntilExpiry) => {
        if (daysUntilExpiry === 0) {
            return 'Your trial will expire today.';
        }
        return `Your trial will expire in ${daysUntilExpiry} ${daysUntilExpiry > 1 ? ' days' : ' day'}.`;
    };

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const {daysUntilExpiry} = this.props;
        const {visible} = this.state;
        const title = this.getReminderMessage(daysUntilExpiry);

        return (
            <Modal
                className='modal-root--centered modal-root--trial'
                visible={visible}
                title={title}
                containerWidth={500}
                contentHeight={80}
                footerButtons={[
                    {
                        label: 'Ok',
                        ui: 'shaded',
                        onClick: this.onClose
                    },
                    {
                        label: 'Request',
                        ui: 'citrus',
                        onClick: this.onContactUs
                    }
                ]}
            >
                <p>To request an extension please contact us.</p>
            </Modal>
        );
    }
}

export default TrialReminderModal;
