import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { getStages } from '../stage/stage.actions'

/**
 * Deal Action Type Constants
 * @type {string}
 */
export const GET_DEAL_REQUEST = 'GET_DEAL_REQUEST'
export const GET_DEAL_SUCCESS = 'GET_DEAL_SUCCESS'
export const GET_DEAL_FAILURE = 'GET_DEAL_FAILURE'
export const GET_DEALS_REQUEST = 'GET_DEALS_REQUEST'
export const GET_DEALS_SUCCESS = 'GET_DEALS_SUCCESS'
export const GET_DEALS_FAILURE = 'GET_DEALS_FAILURE'
export const CREATE_DEAL_REQUEST = 'CREATE_DEAL_REQUEST'
export const CREATE_DEAL_SUCCESS = 'CREATE_DEAL_SUCCESS'
export const CREATE_DEAL_FAILURE = 'CREATE_DEAL_FAILURE'
export const UPDATE_DEAL_REQUEST = 'UPDATE_DEAL_REQUEST'
export const UPDATE_DEAL_SUCCESS = 'UPDATE_DEAL_SUCCESS'
export const UPDATE_DEAL_FAILURE = 'UPDATE_DEAL_FAILURE'
export const DELETE_DEAL_REQUEST = 'DELETE_DEAL_REQUEST'
export const DELETE_DEAL_SUCCESS = 'DELETE_DEAL_SUCCESS'
export const DELETE_DEAL_FAILURE = 'DELETE_DEAL_FAILURE'
export const FILTER_DEAL_ACTIVITY = 'FILTER_DEAL_ACTIVITY'

export const GET_DEALS_BY_ENTITY_ID_REQUEST = 'GET_DEALS_BY_ENTITY_ID_REQUEST'
export const GET_DEALS_BY_ENTITY_ID_SUCCESS = 'GET_DEALS_BY_ENTITY_ID_SUCCESS'
export const GET_DEALS_BY_ENTITY_ID_FAILURE = 'GET_DEALS_BY_ENTITY_ID_FAILURE'
export const CLEAR_ENTITY_DEALS = 'CLEAR_ENTITY_DEALS'

/**
 * Get Deal
 * Returns single deal by id
 * @param id
 * @param params
 */
const _getDeal = (id, params) => ({
  [CALL_API]: {
    types: [
      GET_DEAL_REQUEST,
      GET_DEAL_SUCCESS,
      GET_DEAL_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/pipeline/deal/${id}`,
    payload: params
  }
})

/**
 * Get Deals
 * Returns all deals within user's organization
 * @param params.limit
 * @param params
 */
const _getDeals = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_DEALS_REQUEST,
      GET_DEALS_SUCCESS,
      GET_DEALS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/pipeline/deal',
    payload: params
  }
})

/**
 * Create Deal
 * @param data
 */
const _createDeal = (data) => ({
  [CALL_API]: {
    types: [
      CREATE_DEAL_REQUEST,
      CREATE_DEAL_SUCCESS,
      CREATE_DEAL_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/pipeline/deal',
    payload: data
  }
})

/**
 * Update Deal
 * @param id
 * @param data
 */
const _updateDeal = (id, data) => ({
  [CALL_API]: {
    types: [
      UPDATE_DEAL_REQUEST,
      UPDATE_DEAL_SUCCESS,
      UPDATE_DEAL_FAILURE
    ],
    method: METHOD_TYPE.PUT,
    endpoint: `/pipeline/deal/${id}`,
    payload: data
  }
})

/**
 * Delete Deal
 * @param id
 */
const _deleteDeal = (id) => ({
  [CALL_API]: {
    types: [
      DELETE_DEAL_REQUEST,
      DELETE_DEAL_SUCCESS,
      DELETE_DEAL_FAILURE
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: `/pipeline/deal/${id}`
  }
})


const _getDealsByEntityId = (id) => ({
  [CALL_API]: {
    types: [
      GET_DEALS_BY_ENTITY_ID_REQUEST,
      GET_DEALS_BY_ENTITY_ID_SUCCESS,
      GET_DEALS_BY_ENTITY_ID_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/pipeline/deal/entity/${id}`,
  }
})

/**
 * Filter Deal Activity
 * @param filter
 * @private
 */
const _filterDealActivity = (filter) => ({
  type: FILTER_DEAL_ACTIVITY,
  payload: filter
})

/**
 * Dispatch GET_DEAL_REQUEST
 * @param id
 */
export const getDeal = (id, params) => (dispatch) => {
  dispatch(_getDeal(id, params))
  dispatch(getStages())
}

/**
 * Dispatch GET_DEALS_REQUEST
 * @param params
 */
export const getDeals = (params, options) => (dispatch) => {
  return dispatch(_getDeals(params, options))
}

/**
 * Dispatch CREATE_DEAL_REQUEST
 * @param data
 */
export const createDeal = (data) => (dispatch) => {
  return dispatch(_createDeal(data))
}

/**
 * Dispatch UPDATE_DEAL_REQUEST
 * @param id
 * @param data
 * @returns {function(*)}
 */
export const updateDeal = (id, data) => (dispatch) => {
  return dispatch(_updateDeal(id, data))
}

/**
 * Dispatch DELETE_DEAL_REQUEST
 * @param id
 */
export const deleteDeal = (id) => (dispatch) => {
  return dispatch(_deleteDeal(id))
}

/**
 * Dispatch DELETE_DEAL_REQUEST
 * @param filter
 */
export const filterDealActivity = (filter) => (dispatch) => {
  return dispatch(_filterDealActivity(filter))
}


export const getDealsByEntityId = (id) => (dispatch) => {
  return dispatch(_getDealsByEntityId(id))
}

export const clearEntityDeals = () => (dispatch) => {
  return dispatch({ type: CLEAR_ENTITY_DEALS })
}

