import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {getDefaultLimitValue, getMaxLimitValue} from '../../../../utils/report';
import { Slider } from '../../../index';
import './reportLimit.component.css';

/**
 * Report Builder Limit Component
 */
class ReportLimit extends PureComponent {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        const initialValue = props.initialValue && parseInt(props.initialValue, 10);

        this.state = {
            inputValue: initialValue || 1,
            sliderValue: initialValue || 1
        };
    }

    /**
     * ComponentWillReceiveProps
     * @param nextProps
     * @param nextContext
     */
    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if ((nextProps.initialValue !== this.state.sliderValue) || (nextProps.initialValue !== this.state.inputValue)) {
            this.setState({
                sliderValue: nextProps.initialValue,
                inputValue: nextProps.initialValue
            });
        }
    }

    /**
     * Handle input keyDown event
     * @param event
     */
    handleInputKeyDown = (event) => {
        const invalidKeys = ['-', '+', 'e'];
        const key = event.key;

        if (invalidKeys.includes(key)) {
            event.preventDefault();
        }
    };

    /**
     * Handle input change event
     * @param event
     */
    handleInputChange = (event) => {
        const {onChange} = this.props;
        const maxLimitValue = getMaxLimitValue();

        const target = event.target;
        const inputValue = target.value && target.value > maxLimitValue ? maxLimitValue : target.value;

        const newState = {
            inputValue
        };

        if (inputValue && inputValue > 0) {
            newState.sliderValue = parseInt(inputValue, 10);
        }

        this.setState(newState);

        onChange && onChange(parseInt(inputValue, 10));
    };

    /**
     * Handle input blur event
     */
    handleInputBlur = () => {
        const {onChange} = this.props;
        const {inputValue} = this.state;

        if (!inputValue || !parseInt(inputValue, 10)) {
            this.setState({
                inputValue: 1,
                sliderValue: 1
            });

            onChange && onChange(1);
        }
    };

    /**
     * Handle slider change event
     * @param value
     */
    handleSliderChange = (value) => {
        const {onChange} = this.props;

        this.setState({
            sliderValue: value,
            inputValue: value
        });

        onChange && onChange(value);
    };

    /**
     * Render Report Field Component
     * @return {XML}
     */
    render() {
        const {sliderTheme, fieldTheme, entityTypeLabel, entityTypeId, isLocked} = this.props;
        const {inputValue, sliderValue} = this.state;

        return (
            <article className={'report-limit' + (isLocked ? '--disabled' : '')}>
                <label className='report-limit_label' htmlFor={`report-limit--${entityTypeId}`}>
                    {entityTypeLabel} Display Amount
                </label>
                <div className='report-limit_inner'>
                    <div className='report-limit_slider'>
                        <Slider
                            theme={sliderTheme}
                            min={1}
                            max={getMaxLimitValue()}
                            value={sliderValue}
                            onChange={this.handleSliderChange}
                        />
                    </div>
                    <div className='report-limit_input'>
                        <div className={`field field--text ${fieldTheme ? `field--${fieldTheme}` : ''}`}>
                            <input
                                className='field_input field_input--shortest'
                                type='string'
                                step='1'
                                id={`report-limit--${entityTypeId}`}
                                value={(inputValue === getMaxLimitValue() && isLocked) ? `${inputValue}+` : inputValue}
                                onKeyDown={this.handleInputKeyDown}
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputBlur}
                            />
                        </div>
                    </div>
                </div>
            </article>
        );
    }
}

ReportLimit.propTypes = {
    sliderTheme: PropTypes.string,
    fieldTheme: PropTypes.string,
    entityTypeLabel: PropTypes.string.isRequired,
    entityTypeId: PropTypes.string.isRequired,
    initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired
};

ReportLimit.defaultProps = {
    sliderTheme: 'rain',
    initialValue: getDefaultLimitValue()
};

export default ReportLimit;
