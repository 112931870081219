import React from 'react'

import { SectionTab } from '../../../../../../components/shared'
import { THEMES } from '../../../../../../utils/ui'
import { ENTITY_INSTITUTION, ENTITY_TYPE, FLYOUT_ENTITY_TYPES } from '../../../../../../utils'
import { formatEntity } from '../../../../../../utils/entity'
import Grid from '@material-ui/core/Grid'

import { AttributesContainer, FlyoutBadge, HighLightsContainer } from '../components'
import { ContactGridOld } from '.././../../../../../components/contact'
import FundGridOld from '../../../../../../components/ownership/institution/fund/grid/grid.container'
import HoldingGrid from '../../../../../../components/ownership/holding/grid/grid.container'
import FlyoutTitle from '../components/flyoutTitle/flyoutTitle.container'
import TopManagersContainer from '../components/topManagers/topManagers.container'
import FlyoutBanner from '../components/banner/banner.component'
import PeerAnalysisGridOld from '../../../../../../components/ownership/peerAnalysis/grid/grid.container'
import { ActivityGridOld, TagInput } from '../../../../../../components'

const Institution = ({ classes, institution, targetId, hasDeal, isLoading, handleRedirect, ...props }) => {
  const entity = formatEntity(institution, ENTITY_INSTITUTION)
  return (
    isLoading
      ? null
      : (
        <div className='q4-fade-in target-detail--institution'>
          <FlyoutBanner
            title={(<FlyoutTitle title={entity.name} />)}
            icon='q4i-institution-2pt'
            theme={THEMES.WHITE}
            badgeType='institution'
            data={entity}
            entityType={ENTITY_INSTITUTION}
            targetId={targetId}
            hasDeal={hasDeal}
            details={`${entity.type} | Style: ${entity.style} | Turnover: ${entity.turnover}`}
            handleClose={props.handleClose}
            handleTargetUtilAction={props.handleTargetUtilAction}
          />

          <div className='target-detail_overview'>
            <Grid container spacing={3}>
              <FlyoutBadge targetId={targetId} />
              <Grid item xs={6}>
                <Grid item xs={12} className='grid-section'>
                  <AttributesContainer
                    entityId={entity.entityId}
                    entity={entity}
                    className='card-attributes'
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <HighLightsContainer
                    entity={{
                      ...entity,
                      entityType: FLYOUT_ENTITY_TYPES.INSTITUTION
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} className='grid-section'>
                  <TopManagersContainer
                    entity={entity}
                    handleRowClick={({ contactId }) => {
                      handleRedirect({ contactId, entityType: FLYOUT_ENTITY_TYPES.CONTACT })
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TagInput
                  theme={THEMES.LIGHT}
                  entityType={ENTITY_TYPE.INSTITUTION}
                  entityId={entity.q4_entity_id}
                />
              </Grid>
            </Grid>
          </div>
          <div className='target-detail_tab-section'>
            <SectionTab
              rememberMounted
              tabs={[
                {
                  id: 'contact',
                  label: 'Contact',
                  view: (
                    <ContactGridOld
                      entityId={entity && entity.entityId}
                      handleRowClick={({ contactId }) => {
                        handleRedirect({ contactId, entityType: FLYOUT_ENTITY_TYPES.CONTACT })
                      }}
                    />
                  )
                },
                {
                  id: 'activity',
                  label: 'Activity',
                  view: (
                    <ActivityGridOld
                      showChildren
                      isEntity
                      entityId={entity && entity._id}
                      entity={entity}
                      entityType={ENTITY_INSTITUTION}
                      handleExpandMenuItemClick={handleRedirect}
                    />
                  )
                },
                {
                  id: 'funds',
                  label: 'Funds',
                  view: (
                    <FundGridOld
                      entityId={entity && entity.entityId}
                      handleRowClick={({ entityId }) => {
                        handleRedirect({ entityId, entityType: FLYOUT_ENTITY_TYPES.FUND })
                      }}
                      handlePopoverItemClick={({ contactId }) => {
                        handleRedirect({ contactId, entityType: FLYOUT_ENTITY_TYPES.CONTACT })
                      }}
                    />
                  )
                },
                {
                  id: 'holdings',
                  label: 'Holdings',
                  view: (<HoldingGrid entityId={entity && entity.entityId} />)
                },
                {
                  id: 'peer-analysis',
                  label: 'peer analysis',
                  view: (
                    <PeerAnalysisGridOld
                      entityId={entity && entity.entityId}
                    />
                  )
                },
              ]}
            />
          </div>
        </div>
      )
  )
}

export default Institution
