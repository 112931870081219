import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab as MUITab } from '@material-ui/core'
import { getClassName, THEMES } from '../../utils/ui'

import './sectionTab.component.css'

/**
 * Section Tab Component
 * @param props
 */
class SectionTab extends Component {
  render () {
    const { className, theme, compact, activeTab, tabs, onChange } = this.props

    const baseClassName = getClassName('section-tabs', [
      { condition: theme, trueClassName: `section-tabs--${theme}` },
      { condition: compact, trueClassName: 'section-tabs--compact' },
      { condition: className, trueClassName: className }
    ])

    return (
      <Tabs
        className={baseClassName}
        classes={{
          indicator: 'section-tabs_indicator'
        }}
        tabs={tabs}
        value={activeTab}
        onChange={onChange}
      >
        {(tabs || []).map((tab, index) => {
          const { icon, label, value, hidden } = tab
          if (!!hidden) return null

          const tabClassName = getClassName('section-tabs_item', [
            { condition: value === activeTab, trueClassName: 'section-tabs_item--active' }
          ])

          return (
            <MUITab
              key={`tab-${index}`}
              className={tabClassName}
              theme={theme}
              icon={icon ? <i className={`icon ${icon}`} /> : null}
              label={<span className='label'>{label}</span>}
              value={value}
              hidden={hidden}
              disableRipple
            />
          )
        })}
      </Tabs>
    )
  }
}

SectionTab.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.WHITE, THEMES.RAIN, THEMES.STEEL, THEMES.SLATE, THEMES.DARK, THEMES.LIGHT]),
  compact: PropTypes.bool,
  activeTab: PropTypes.string,
  tabs: PropTypes.array.isRequired,
  onChange: PropTypes.func
}

SectionTab.defaultProps = {
  theme: THEMES.RAIN
}

export default SectionTab
