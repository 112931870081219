import { combineReducers } from 'redux'
import position from './position.reducer'
import purchasingPower from './purchasingPower.reducer'
import highlights from './highlights.reducer'
import topInstitutionManagers from './topInstitutionManagers.reducer'

export default combineReducers({
  position,
  purchasingPower,
  highlights,
  topInstitutionManagers
})
