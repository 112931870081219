import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Message } from '../../../components'
import { THEMES } from '../../../utils'

const propTypes = {
  onClose: PropTypes.func.isRequired
}

const defaultProps = {
  title: 'Error',
  message: 'Oops, something went wrong while trying to send this request. Please try again or contact us if you see this message again.'
}

/**
 * General Error Modal
 * @param onClose
 * @param title
 * @param message
 */
const ErrorModal = ({ onClose, title, message }) => {
  return (
    <Message
      visible
      type='error'
      title={title}
      message={message}
      buttons={[{
        ui: THEMES.SPICE,
        label: 'close',
        onClick: onClose
      }]}
    />
  )
}

ErrorModal.propTypes = propTypes
ErrorModal.defaultProps = defaultProps

export default memo(ErrorModal)
