import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Checkbox} from '../../../../../../../components/shared';
import {convertStringToBoolean, THEMES} from '../../../../../../../utils';

/**
 * Peer Ownership Filter
 */
class PeerOwnership extends PureComponent {

    /**
     * Handle Change
     * @param value
     */
    handleChange = (value) => {
        const {getPeers, resetPeers, onChange} = this.props;
        (!!value) ? getPeers({ page: 1, limit: 25 }) : resetPeers();
        onChange(value);
    };

    /**
     * Render
     * @return {*}
     */
    render() {
        const {value} = this.props;

        return (
            <div className='field field--full field--grouped'>
                <Checkbox
                    id='peerOwnership'
                    theme={THEMES.WHITE_RAIN}
                    isChecked={convertStringToBoolean(value)}
                    label='Peers'
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

PeerOwnership.propTypes = {
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    onChange: PropTypes.func.isRequired,
    getPeers: PropTypes.func.isRequired,
    resetPeers: PropTypes.func.isRequired
};

export default PeerOwnership;