import {
  statusType,
  SENCHA_INITIALIZED,
  POST_MESSAGE_REQUEST,
  POST_MESSAGE_SUCCESS
} from '../../actions'

const initial = {
  status: statusType.IDLE
}

const sencha = (state = initial, action) => {
  switch (action.type) {
    case SENCHA_INITIALIZED:
      return {
        ...state,
        status: statusType.SUCCESS
      }
    case POST_MESSAGE_REQUEST:
      return {
        ...state,
        postMessage: action.payload
      }
    case POST_MESSAGE_SUCCESS:
      return {
        ...state,
        postMessage: null
      }
    default:
      return state
  }
}

export default sencha
