import React from 'react'
import moment from 'moment-timezone'
import { format } from '../../../../../../utils'

const EMPTY_PLACEHOLDER = '-'

/**
 * Estimate EPS/Sales Custom Cell Component
 * @param values
 * @returns {string}
 * @constructor
 */
const Estimate = ({ value }) => {
  const { item, estimate, actual_value } = value

  switch (item) {
    case 'SALES':
      return estimate ? format(estimate, 2) : EMPTY_PLACEHOLDER
    case 'EPS':
      return (estimate || actual_value)
        ? <div className='cell'>
          <div className='cell_content'>
            <div>{estimate ? `${format(estimate, 2)} E` : ''}</div>
            <div>{actual_value ? `${format(actual_value, 2)} A` : '-'}</div>
          </div>
        </div>
        : EMPTY_PLACEHOLDER
    default:
      return EMPTY_PLACEHOLDER
  }
}

/**
 * Get grid fields
 * @param values
 * @returns {{label: string, value: T}[]}
 * @private
 */
const _getFields = (values) => {
  return (values || []).map((value) => {
    const { date, frequency } = value
    const period = moment.isMoment(date) ? date : moment.utc(date)

    return {
      label: `${period.format('MM/DD/YY')}-${frequency}`,
      value
    }
  })
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 * @param onExpandMenuClick
 */
const CustomCell = ({ data = {}, column = {} }) => {
  const cellData = data[column.colId]
  const fields = _getFields(data && data.data)
  const field = (fields || []).find((field) => field && field.label === column.colId)

  switch (column.colId) {
    case 'category':
      return cellData || EMPTY_PLACEHOLDER
    case (field && field.label):
      return <Estimate value={field && field.value} />
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
