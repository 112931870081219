import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { Button, Select, Toolbar, ToolbarRow } from '../../../../index'
import { getLocalizedCurrency, isMetricCurrencyEnabled, PEER_METRIC, THEMES } from '../../../../../utils'

const propTypes = {
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  noData: PropTypes.bool,
  metric: PropTypes.string,
  onQueryChange: PropTypes.func.isRequired,
  onPeerUpdate: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired
}

const defaultProps = {
  toolbarTheme: THEMES.Q4_BLUE,
  toolTheme: THEMES.INK,
  metric: 'current'
}

/**
 * Peer Analysis Toolbar Component
 * @param props
 */
function PeerAnalysisToolbar (props) {
  const { dataId, toolbarTheme, toolTheme, noData, metric, onQueryChange, onPeerUpdate, onExport, exporting } = props

  const localizedOptions = PEER_METRIC.map((option) => {
    return {
      ...option,
      label: isMetricCurrencyEnabled(option.value) ? `${option.label} (MM ${getLocalizedCurrency()})` : option.label
    }
  })

  const selected = localizedOptions.find((option) => option.value === metric)
  const handleChange = (option) => option && onQueryChange({ metric: option.value })

  return (
    <Toolbar theme={toolbarTheme} className='peer-analysis-grid_toolbar'>
      <ToolbarRow justified>
        <div className='toolbar_group toolbar_group'>
          <Select
            dataId={{
              inputId: `${dataId}MetricFilterInput`,
              menuId: `${dataId}MetricFilterMenu`
            }}
            theme={toolTheme}
            size='wide'
            value={selected}
            options={localizedOptions}
            searchable={false}
            clearable={false}
            onChange={handleChange}
          />
          <Button
            dataId={`${dataId}ModifyPeers`}
            theme={toolTheme}
            label='Peers'
            onClick={onPeerUpdate}
          />
        </div>
        <div className='toolbar_group'>
          <Button
            dataId={`${dataId}Export`}
            theme={toolTheme}
            loading={exporting}
            icon='q4i-download-4pt'
            label='Export'
            disabled={noData}
            onClick={onExport}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

PeerAnalysisToolbar.propTypes = propTypes
PeerAnalysisToolbar.defaultProps = defaultProps

export default memo(PeerAnalysisToolbar)
