import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { getClassName, THEMES } from '../../utils/ui'

import { Spinner } from '../../components'

import './card.component.css'

/**
 * Card Component
 */
function Card (props) {
  const {
    dataId, isLoading, style, className, theme, title, headerActions, children, isChin, footNote, footerActions
  } = props
  const hideHeader = !title && (!headerActions || !headerActions.length)
  const hideFooter = !footNote && (!footerActions || !footerActions.length)
  const baseClassName = getClassName('card', [
    { condition: className, trueClassName: className },
    { condition: theme, trueClassName: `card--${theme}` }
  ])
  const footerClassName = getClassName('card_footer', [
    { condition: footNote && footerActions && footerActions.length, trueClassName: 'card_footer--justified' }
  ])
  const spinnerProps = getSpinnerProps(theme)

  /**
   * Returns spinner props based on theme
   * @param themes
   */
  function getSpinnerProps (theme) {
    const spinnerProps = props.spinnerProps
    return theme === THEMES.LIGHT
      ? { maskOpacity: 1, maskColor: theme, ...spinnerProps }
      : {}
  }

  return (
    <article className={baseClassName} style={style} data-id={dataId}>
      {!hideHeader && (
        <header data-id='CardHeader' className='card_header'>
          {(typeof title === 'object') ? title : <div className='card_title'>{title}</div>}
          {(headerActions.length > 0) && (
            <div className='card_actions'>{headerActions.map((Action, idx) => <Action key={idx} />)}</div>
          )}
        </header>
      )}

      <section className={`card_children ${isChin ? 'card_children-chin' : ''}`}>
        <CSSTransition classNames='card_spinner-' in={isLoading} timeout={300} mountOnEnter unmountOnExit appear>
          <Spinner className='card_spinner' mask theme={theme} {...spinnerProps} />
        </CSSTransition>
        {children}
      </section>

      {!hideFooter && (
        <footer className={footerClassName}>
          {footNote && <div>{footNote}</div>}
          {(footerActions.length > 0) && (
            <div className='card_actions'>{footerActions.map((Action, idx) => <Action key={idx} />)}</div>
          )}
        </footer>
      )}
    </article>
  )
}

Card.propsTypes = {
  dataId: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  headerActions: PropTypes.array,
  footNote: PropTypes.string,
  footerActions: PropTypes.array,
  isLoading: PropTypes.bool,
  spinnerProps: PropTypes.shape({
    maskOpacity: PropTypes.number,
    maskColor: PropTypes.string
  })
}

Card.defaultProps = {
  style: {},
  theme: THEMES.DARK,
  headerActions: [],
  footerActions: [],
  spinnerProps: {}
}

export default Card
