import React, { Component } from 'react'
import { Modal } from '../../../../components'
import { FormikTextInput } from '../../../shared'
import { withFormik } from 'formik'
import * as Yup from 'yup'

import './changePassword.component.css'

/**
 * Change Password Component
 */
class _ChangePassword extends Component {
  /**
     * Constructor
     * @param props
     */
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false
    }
  };

  /**
     * Render Widget Library
     * @returns {*}
     */
  render () {
    const { visible, isSubmitting, handleSubmit, errors, onClose } = this.props

    return (
      <Modal
        visible={visible}
        title='Change Password'
        containerWidth={500}
        contentHeight={188}
        onClose={onClose}
        footerButtons={[{
          label: 'Cancel',
          ui: 'shaded',
          onClick: onClose
        }, {
          label: 'Submit',
          ui: 'citrus',
          disabled: isSubmitting,
          onClick: handleSubmit
        }]}
      >
        <form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
          <FormikTextInput
            id='oldPass'
            ui='not-labeled'
            placeholder='Current Password'
            type='password'
            serverError={errors.serverError && errors.serverError === 'Old password is incorrect.' && errors.serverError}
            {...this.props}
          />

          <FormikTextInput
            id='newPass'
            ui='not-labeled'
            placeholder='New Password'
            type='password'
            {...this.props}
          />

          <FormikTextInput
            id='confirmPass'
            ui='not-labeled'
            placeholder='Confirm New Password'
            type='password'
            {...this.props}
          />
        </form>
        <div className='modal_note'>Password must contain minimum 10 characters, including 1 uppercase, 1 digit, 1 special character.
        </div>
      </Modal>
    )
  }
}

const ChangePassword = withFormik({
  validateOnChange: true,
  validateOnBlur: true,
  mapPropsToValues: () => ({
    oldPass: '',
    newPass: '',
    confirmPass: ''
  }),
  validationSchema: Yup.object().shape({
    oldPass: Yup.string()
      .required('required'),
    newPass: Yup.string()
      .required('required')
      .matches(/^(?=.*[A-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s])\S{10,}$/, 'Password must contain minimum 10 characters, including 1 uppercase, 1 digit, 1 special character!!'),
    // .oneOf([Yup.ref('oldPass'), null], 'New password cannot be same as old password!'),
    confirmPass: Yup.string()
      .required('required')
      .oneOf([Yup.ref('newPass'), null], 'New password and confirmation password do not match!')
  }),
  handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    const { oldPass, newPass } = values
    const { auth, createToast, onClose } = props

    auth.changePassword(oldPass, newPass)
      .then((data) => {
        createToast({ text: (data && data.message) })
        onClose()
      })
      .catch((data) => {
        setSubmitting(false)
        setErrors({ serverError: data && data.message })
      })
  },
  displayName: 'ChangePasswordForm' // helps with debugging (React DevTools)
})(_ChangePassword)

export default ChangePassword
