import moment from 'moment-timezone'
import { getDateFromMoment, getDateTime, getTimeFromDate, guessTimeZone } from '../../../utils'

export default {
  _id: null,
  _deal: null,
  tag: [],
  category: 'meeting',
  title: '',
  links: [],
  addressBookLinks:[],
  participants: [],
  address: [],
  start: {
    date_time: getDateTime(getDateFromMoment(moment()), getTimeFromDate(moment(), { round: 30 })),
    timezone: guessTimeZone()
  },
  end: {
    date_time: getDateTime(getDateFromMoment(moment()), getTimeFromDate(moment().add(1, 'hour'), { round: 30 })),
    timezone: guessTimeZone()
  },
  startTime: getTimeFromDate(moment(), { round: 30 }),
  endTime: getTimeFromDate(moment().add(1, 'hour'), { round: 30 }),
  all_day: false,
  virtual: false,
  body: ''
}
