import { config } from '../../config'
import { isLocalhost } from '../http'
import { EVENT_TYPES } from './event.const'

const {
  EARNINGS_CALLS, SHAREHOLDER_ANALYST_CALLS, MA_CALLS, OPERATING_RESULTS_CALLS, GUIDANCE_UPDATE_CALLS,
  INTERIM_MANAGEMENT_STATEMENT_CALLS, SALES_TRADING_STATEMENT_CALLS, FIXED_INCOME_CALLS, SPECIAL_CALLS,
  INTERIM_MANAGEMENT_STATEMENT_RELEASE_DATE, SALES_TRADING_STATEMENT_RELEASE_DATE,
  ESTIMATED_EARNINGS_RELEASE_DATE_SP_GLOBAL_DERIVED, EARNINGS_RELEASE_DATE
} = EVENT_TYPES

/**
 * Returns Icon for an Event Type
 * @param {string} eventTypeName
 * @return {string}
 */
export const getEventIcon = (eventTypeName, size = '4pt') => {
  switch (eventTypeName) {
    case EARNINGS_CALLS:
    case SHAREHOLDER_ANALYST_CALLS:
    case MA_CALLS:
    case OPERATING_RESULTS_CALLS:
    case GUIDANCE_UPDATE_CALLS:
    case INTERIM_MANAGEMENT_STATEMENT_CALLS:
    case SALES_TRADING_STATEMENT_CALLS:
    case FIXED_INCOME_CALLS:
    case SPECIAL_CALLS:
      return `q4i-phone-${size}`
    case INTERIM_MANAGEMENT_STATEMENT_RELEASE_DATE:
    case SALES_TRADING_STATEMENT_RELEASE_DATE:
    case ESTIMATED_EARNINGS_RELEASE_DATE_SP_GLOBAL_DERIVED:
    case EARNINGS_RELEASE_DATE:
      return `q4i-releases-${size}`
    default:
      return `q4i-map-marker-${size}`
  }
}

/**
 * Get PDF Viewer pluging URL
 * @param {string} path
 * @param {boolean} useS3Alias Apply /transcript/download Desktop alias to file path
 * @param {string} query
 */
export const getTranscriptLink = (path, useS3Alias, query) => {
  const pdfViewerURL = 'pdf-preview/web/viewer.html?file='
  const baseUrl = config.baseUrl
  let transcriptHost = baseUrl

  // fallback to develop CloudFront on localhost
  if (isLocalhost()) {
    transcriptHost = 'https://develop.q4desktop.com'
  }

  const s3DownloadPath = `${transcriptHost}/transcript/download${path}`
  const url = useS3Alias 
    ? encodeURIComponent(s3DownloadPath.replace('//g', '+'))
    : encodeURIComponent(path.replace('//g', '+'))

  const basePath = `${baseUrl}/${pdfViewerURL}${url}`

  if (query) {
    return `${basePath}#search=${query}`
  } else {
    return `${basePath}`
  }
}
