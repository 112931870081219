import React from 'react'
import ComboBox from '../../../comboBox/comboBox.component'
import ContactFormContainer from '../../../../components/contact/form/contactForm.container'
import { CORPORATE_PARTICIPANT, THEMES } from '../../../../utils'
import { statusType } from '../../../../actions'
import { uniqBy } from 'lodash'

function ActivityParticipants (props) {
  const { openCloseParticipantModal, data, loading, findParticipants, participants, isContactModalVisible, modalType, setFieldValue, clearSearchData } = props

  const actions = loading === statusType.SUCCESS ? [{
    type: 'action',
    theme: THEMES.CITRUS,
    label: 'Create Corporate Participant',
    onClick: () => openCloseParticipantModal(CORPORATE_PARTICIPANT)
  }] : []

  /**
   * Format values for Select and Removable list
   * @param values
   */
  function getFormattedValues (values) {
    return (values || [])
      .map((participant) => {
        return {
          value: participant._id ? participant._id : participant._corporateparticipant,
          label: participant.name
        }
      })
  }

  /**
   * Removes participant object from array of selected participants
   * @param participant {}
   */
  function removeParticipant (participant) {
    setFieldValue('participants', (participants || []).filter((item) => {
      const { _id, _corporateparticipant } = item
      return _id
        ? _id !== participant
        : _corporateparticipant !== participant
    }))
  }

  /**
   * Handle Participant Select
   * @param item
   */
  function handleChange (item) {
    if (!item || (item && item.type === 'action')) {
      return
    }
    const participant = { name: item.label, _corporateparticipant: item.value }
    setFieldValue('participants', uniqBy([].concat((participants || []), participant), '_corporateparticipant'))
  }

  /**
   * Handle Input Change
   * @param query
   */
  function handleInputChange (query) {
    query ? findParticipants(query) : clearSearchData()
  }

  return (
    <div className='activity-form__activity-participants activity-form--section'>
      <div className='field--text field field--full'>
        <label htmlFor='activity-participants' className='field_label'>Corporate Participants</label>
        <ComboBox
          theme={THEMES.LIGHT_GREY}
          selectProps={{
            placeholder: 'Full Name',
            value: null,
            options: [].concat(getFormattedValues(data), actions),
            searchable: true,
            onInputChange: handleInputChange,
            onChange: handleChange
          }}
          removableListProps={{
            items: getFormattedValues(participants),
            onRemove: removeParticipant
          }}
        />
      </div>
      {isContactModalVisible && modalType === CORPORATE_PARTICIPANT &&
      <ContactFormContainer
        type={CORPORATE_PARTICIPANT}
        isTypeSelectable={false}
        onClose={() => openCloseParticipantModal(null)}
        onSaveSuccess={(contact) => handleChange({ label: contact.full_name, value: contact._id })}
      />}
    </div>
  )
}

export default ActivityParticipants
