import React from 'react'
import PropTypes from 'prop-types'

// components
import { Button, Select } from '../../../../../components'
import RemovableList from '../../../../list/removeable/removableList.component'

// utils
import { THEMES } from '../../../../../utils/ui'
import { chartTypeOptions, getDefaultSeries, getPivotLabel, getIdentifierLabel } from '../../../../../utils/report'

import './seriesList.component.css'

// internal constants
const comboChartTypeOptions = chartTypeOptions.filter((option) => option.availableInCombo).map((option) => option.chartType)

// Prop Types
const peerPropType = PropTypes.shape({
  _security: PropTypes.string.isRequired,
  exchange: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired
})

const indexPropType = PropTypes.shape({
  group: PropTypes.string,
  symbol: PropTypes.string.isRequired
})

const seriesFieldPropType = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
})

const pivotPropType = PropTypes.string

/**
 * SeriesListItem Component
 * @param series
 * @param options
 * @param updateSeriesItem
 */
function SeriesListItem ({ item, options, updateSeriesItem }) {
  const { availablePeers, availablePivotValues, availableSeriesFields, availableIndices } = options
  const { _id, index, idx, _field, peer, pivotValue } = item
  const showSelectPeerDropdown = (availablePeers && availablePeers.length > 0)
  const showSelectPivotDropdown = (availablePivotValues && availablePivotValues.length > 0)

  /**
   * Handles Dropdown Select
   * @param key
   * @param option
   */
  const handleSelect = (key) => (option) => {
    const { value } = option
    const newKey = key === 'peerIndex' ? (value && value.exchange ? 'peer' : 'index') : key
    const newItem = { ...item }

    if (newKey === 'peer') {
      delete newItem.index
    } else if (newKey === 'index') {
      delete newItem.peer
    }

    return value && updateSeriesItem(_id, { ...newItem, [newKey]: value })
  }

  /**
   * Returns Formatted Series Field Label
   * @param seriesField
   */
  const getSeriesFieldLabel = (seriesField) => seriesField && seriesField.label
  const selectPeerOptions = [].concat((availablePeers || []), availableIndices || [])
    .map((option) => option && { label: getIdentifierLabel(option), value: option })
  const selectSeriesOptions = [].concat(availableSeriesFields || []).map((field) => field && { label: field.label, value: field })
  const selectPivotValues = [].concat(availablePivotValues || []).map((value) => value && { label: getPivotLabel(value), value: value })

  return (
    <section className='series-list-item'>
      <header className='series-list-item_subtitle'> Series {idx + 1}</header>
      <article className='series-list-item_group'>
        {showSelectPeerDropdown && (
          <Select
            size='thin'
            placeholder='Select Peer or Index'
            options={selectPeerOptions}
            noOptionsMessage='No Data Available'
            value={(peer || index) && { label: getIdentifierLabel(peer || index), value: (peer || index) }}
            onChange={handleSelect('peerIndex')}
            searchable={false}
            clearable={false}
          />
        )}
        {showSelectPivotDropdown && (
          <Select
            size='thin'
            placeholder='Select Pivot'
            options={selectPivotValues}
            noOptionsMessage='No Data Available'
            maxHeight={177}
            value={pivotValue && { label: getPivotLabel(pivotValue), value: pivotValue }}
            onChange={handleSelect('pivotValue')}
            clearable={false}
            searchable={false}
          />
        )}
        <Select
          size='thin'
          placeholder='Select Field'
          options={selectSeriesOptions}
          noOptionsMessage='No Data Available'
          maxHeight={177}
          value={_field && { label: getSeriesFieldLabel(_field), value: _field }}
          onChange={handleSelect('_field')}
          clearable={false}
          searchable={false}
        />
      </article>
    </section>
  )
}

/**
 * SeriesList Component
 * @param series
 * @param availablePeers
 * @param availableIndices
 * @param availablePivotValues
 * @param availableSeriesFields
 * @param onChange
 * @param props
 * @constructor
 */
function SeriesList ({ series, availablePeers, availableIndices, availablePivotValues, availableSeriesFields, onChange, ...props }) {
  /**
   * Adds a Series
   */
  const addSeriesItem = () => {
    const defaultSeries = getDefaultSeries()
    onChange([
      ...series,
      defaultSeries
    ])
  }

  /**
   * Updates a Series
   * @param seriesId
   * @param newSeriesItem
   */
  const updateSeriesItem = (seriesId, newSeriesItem) => {
    const chartType = newSeriesItem.chartType || (newSeriesItem._field && newSeriesItem._field.chartType)

    onChange(series.map((item) => {
      if (item._id === seriesId) {
        return {
          ...newSeriesItem,
          chartType: chartType && (comboChartTypeOptions.includes(chartType) ? chartType : comboChartTypeOptions[0])
        }
      }

      return item
    }))
  }

  /**
   * Removes a Series by id
   * @param id
   */
  const removeSeriesItem = (id) => onChange(series.filter((item) => item._id !== id))

  /**
   * Get series in a format that RemovableList understands
   * @param series
   */
  const getFormattedItems = (series) => {
    return (series || []).map((seriesItem, idx) => {
      const { _id } = seriesItem

      return {
        value: _id,
        item: {
          ...seriesItem,
          idx
        }
      }
    })
  }

  return (
    <section className='series-list'>
      <Button
        theme={THEMES.WHITE}
        label='Add a series'
        onClick={addSeriesItem}
      />
      <RemovableList
        size='auto'
        theme={THEMES.WHITE}
        items={getFormattedItems(series)}
        customItemRender={(item) => {
          return (
            <SeriesListItem
              item={item}
              options={{ availablePeers, availablePivotValues, availableSeriesFields, availableIndices }}
              updateSeriesItem={updateSeriesItem}
            />
          )
        }}
        onRemove={removeSeriesItem}
      />
    </section>
  )
}

SeriesList.propTypes = {

  /**
   * List of available peers to display as options in a series dropdown
   */
  availablePeers: PropTypes.arrayOf(peerPropType),

  /**
   * List of available pivot fields to display as options in a series dropdown
   */
  availablePivotValues: PropTypes.arrayOf(pivotPropType),

  /**
   * List of available data fields to display as options in a series dropdown
   */
  availableSeriesFields: PropTypes.arrayOf(seriesFieldPropType),

  /**
   * Series items array
   */
  series: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    yAxis: PropTypes.string.isRequired,
    chartType: PropTypes.string,
    color: PropTypes.string,
    peer: peerPropType,
    index: indexPropType,
    pivotValue: pivotPropType,
    _field: seriesFieldPropType
  })),

  /**
   * Callback function to lift the state up to the parent component
   */
  onChange: PropTypes.func.isRequired
}

SeriesList.defaultProps = {
  series: [],
  availablePeers: [],
  availableIndices: [],
  availablePivotValues: [],
  availableSeriesFields: []
}

export default SeriesList
