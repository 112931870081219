import moment from 'moment-timezone'
import { linearRegression, linearRegressionLine } from 'simple-statistics'

export function convertToChartArray (historicals, dateField = 'ISODate', valueField = 'Last') {
  const stockItems = []

  historicals.forEach(function (item) {
    stockItems.push([formatChartDateValues(new Date(item[dateField]).getTime()), item[valueField]])
  })

  return stockItems.reverse()
}

export function formatChartDateValues (date) {
  // convert to utc
  return moment.utc(date).valueOf()
}

/**
 * Returns an array of trend lines.
 * This function calculates a trend line for each unique field for left and right yAxis.
 * @param series
 * @param yAxisConfig
 * @param xAxisCategories
 */
export function getMultiSeriesTrendLine (series = [], yAxisConfig = [], xAxisCategories = []) {
  if (!series || !series.length) {
    return []
  }

  // sort series by data length to always calculate the longest trendline possible
  series = series.sort((a, b) => ((b && b.data) || []).length - ((a && a.data) || []).length)

  // adjust ownership data to make sure that we always have data in the following format: [Number(x), Number(y)]
  // linearRegressionLine func expects both x and y to be a number
  if (xAxisCategories && xAxisCategories.length) {
    series = series.map((eachSeries) => ({
      ...eachSeries,
      data: (eachSeries.data || []).map((value, idx) => [idx, value])
    }))
  }

  // split series by yAxis and remove series based on shouldRenderTrendLine flag
  const [leftAxisSeries, rightAxisSeries] = yAxisConfig.map(({ id, shouldRenderTrendLine }) => shouldRenderTrendLine
    ? series.filter(({ yAxis }) => yAxis === id)
    : []
  )

  // merge multi-series by field for each yAxis
  const yAxis = [leftAxisSeries, rightAxisSeries]
    .map((axis) => {
      return axis.reduce((acc, curr) => {
        const fieldId = curr._field._id
        acc[fieldId] = acc[fieldId]
          ? { ...acc[fieldId], data: [...acc[fieldId].data, ...curr.data] }
          : { name: curr._field.label, data: curr.data, color: curr.color }
        return acc
      }, {})
    })
    // convert object with fields to array
    .map((axis) => Object.values(axis))

  // calculate trend line for each unique field and format series to render in HighCharts
  return yAxis
    .map((axis, idx) => axis.map(({ name, color, data }) => ({
      className: 'series series--trendline',
      type: 'line',
      name: `Trendline for ${name}`,
      yAxis: idx === 0 ? 'left' : 'right',
      data: calculateLinearTrendLine(data),
      marker: { enabled: false },
      color,
      animation: false
    })))
    .reduce((acc, curr) => [...acc, ...curr], [])
}

/**
 * Calculates trend line start and end data points using linear regression algorithm
 * @param data
 */
export function calculateLinearTrendLine (data) {
  // filter and sort data in ascending order
  data = (data || [])
    .filter((value) => Array.isArray(value))
    .filter(([_, value]) => value !== null)
    .sort((a, b) => (a && a[0]) - (b && b[0]))

  if (!data || !data.length) {
    return []
  }

  const dataLength = data.length
  const regressionFunc = linearRegressionLine(linearRegression(data))

  const startX = data[0][0]
  const startY = regressionFunc(data[0][0])
  const endX = data[dataLength - 1][0]
  const endY = regressionFunc(data[dataLength - 1][0])

  return [
    [startX, startY],
    [endX, endY]
  ]
}

/**
 * Calculates relative values
 * @param seriesData
 */
export function getRelativeValues (seriesData = []) {
  if (!seriesData || !seriesData.length) {
    return []
  }

  let initial

  return seriesData.reduce((acc, [category, value]) => {
    if (!value) {
      acc.push([category, null])
    } else if (!initial && value) {
      initial = (value || 0)
      acc.push([category, 0])
    } else if (initial) {
      acc.push([category, (((value || 0) * 100) / initial) - 100])
    }
    return acc
  }, [])
}
