import {
  GET_TARGET_REQUEST,
  GET_TARGET_SUCCESS,
  GET_TARGET_FAILURE,
  CREATE_TARGET_REQUEST,
  CREATE_TARGET_SUCCESS,
  CREATE_TARGET_FAILURE,
  REMOVE_TARGET_REQUEST,
  REMOVE_TARGET_SUCCESS,
  REMOVE_TARGET_FAILURE,
  CLEAR_TARGET,
  statusType
} from '../../actions'
import { TARGETING_TABS } from '../../utils'

const initial = {
  data: {},
  status: statusType.IDLE,
  activeTab: TARGETING_TABS.ALL
}

const target = (state = initial, action) => {
  const { type, payload = {} } = action

  switch (type) {
    case GET_TARGET_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_TARGET_SUCCESS:
      return {
        ...state,
        data: payload,
        status: statusType.SUCCESS
      }

    case GET_TARGET_FAILURE:
      return {
        error: action.error,
        status: statusType.ERROR
      }

    case CREATE_TARGET_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case CREATE_TARGET_SUCCESS:
      return {
        ...state,
        data: payload,
        status: statusType.SUCCESS
      }

    case CREATE_TARGET_FAILURE:
      return {
        error: action.error,
        status: statusType.ERROR
      }

    case REMOVE_TARGET_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case REMOVE_TARGET_SUCCESS:
      return {
        ...state,
        data: {},
        status: statusType.SUCCESS
      }

    case REMOVE_TARGET_FAILURE:
      return {
        error: action.error,
        status: statusType.ERROR
      }
    case CLEAR_TARGET:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default target
