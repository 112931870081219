import React, {Component} from 'react';
import {connect} from 'react-redux';
import Clock from 'react-clock';
import DigitalClock from './digital/digital.container';

import './clock.container.css';


class ClockWidget extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: new Date()
        };
    }

    componentDidMount() {
        this.clockTimer = setInterval(
            () => this.setState({date: new Date()}),
            1000
        );
    }

    componentWillUnmount = () => {
        if (this.clockTimer) {
            clearInterval(this.clockTimer);
        }
    };

    render() {
        const {theme, size, layout} = this.props;
        const className = `clock-widget clock-widget--${theme}`;
        const isSmall = layout.h === 1;
        const clockSize = size || 220;

        return (
            <div className={className}>
                {isSmall ?
                    <DigitalClock layout={layout} /> :
                    <Clock size={clockSize} value={this.state.date}/>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        theme: state.dashboard.dashboardState.theme
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClockWidget);