import {
  GET_DEALS_BY_ENTITY_ID_REQUEST,
  GET_DEALS_BY_ENTITY_ID_SUCCESS,
  GET_DEALS_BY_ENTITY_ID_FAILURE,
  CLEAR_ENTITY_DEALS,
  statusType
} from '../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const entityDeals = (state = initial, action) => {
  switch (action.type) {
    case GET_DEALS_BY_ENTITY_ID_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }
    case GET_DEALS_BY_ENTITY_ID_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: statusType.SUCCESS
      }
    case GET_DEALS_BY_ENTITY_ID_FAILURE:
      return {
        error: action.error,
        status: statusType.ERROR
      }
    case CLEAR_ENTITY_DEALS:
      return {
        ...initial
      }
    default:
      return state
  }
}

export default entityDeals
