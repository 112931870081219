import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'
import { getClassName, THEMES } from '../../utils'

import './tag.component.scss'

const propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf([
    THEMES.DARK,
    THEMES.LIGHT,
    THEMES.SOFT_GREY
  ]),
  isHeader: PropTypes.bool,
  items: PropTypes.array,
  limit: PropTypes.number,
  deletable: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onMoreClick: PropTypes.func
}

const defaultProps = {
  theme: THEMES.LIGHT,
  items: [],
  deletable: false
}

/**
 * Tag Component
 * @param props
 */
function Tag (props) {
  const { theme, className, isHeader, id, items, limit, deletable, onClick, onDelete, onMoreClick } = props

  const baseClassName = getClassName('tags', [
    { condition: className, trueClassName: className },
    { condition: theme, trueClassName: `tags--${theme}` }
  ])

  return (
    <>
      <div className={baseClassName}>
        {isHeader && <header className='tags-header'>Tags</header>}
        {(items || []).slice(0, limit).map((item, index) => {
          const deleteProps = deletable ? {
            deleteIcon: <i className='tags-item_delete q4i-close-4pt' />,
            onDelete: (event) => onDelete(event, item)
          } : {}

          return (
            <Chip
              key={`tag-${index}`}
              className={getClassName('tags-item', [
                { condition: deletable, trueClassName: 'tags-item--deletable' }
              ])}
              label={<div className='tags-item_label'>#{item.name}</div>}
              onClick={(event) => onClick(event, item)}
              {...deleteProps}
            />
          )
        })}

        {(items.length > limit) && <Chip
          key='tag-more'
          className='tags-item tags-item--more'
          label={<i className='tags-item_icon q4i-more-4pt' />}
          onClick={(event) => onMoreClick(event, `${id}`)}
        />}
      </div>
    </>
  )
}

Tag.propTypes = propTypes
Tag.defaultProps = defaultProps

export default memo(Tag)
