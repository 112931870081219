
/**
 * Encodes a string in base-64
 * @param str
 * @return {string}
 */
export const base64Encode = (str) => {
  return window.btoa(encodeURIComponent(str))
}

/**
 * Decodes a base-64 encoded string
 * @param base64String
 * @return {string}
 */
export const base64Decode = (base64String) => {
  return decodeURIComponent(window.atob(base64String))
}

/**
 * Converts blob object to base64 encoded string
 * @param blob
 */
export const convertBlobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })
}

/**
 * Converts base64 encoded string to blob
 * @param url
 */
export const convertBase64ToBlob = (url) => {
  const parsedUrl = url.split(',')
  if (!parsedUrl || parsedUrl.length !== 2) {
    return
  }
  const base64 = parsedUrl[1]
  const byteString = window.atob(base64)
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const intArray = new Uint8Array(arrayBuffer)

  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i)
  }
  return new window.Blob([intArray])
}

/**
 * Converts blob to objectUrl
 * @param blob
 */
export const convertBlobToObjectURL = (blob) => {
  let objectUrl = ''

  try {
    objectUrl = URL.createObjectURL(blob)
  } catch (err) {
    objectUrl = ''
  }

  return objectUrl
}

/**
 * Returns css links that are included on the page.
 */
export const getCssLinks = () => {
  // fallback to develop css on localhost
  if (['localhost', '127.0.0.1', ''].includes(window.location.hostname)) {
    return [
      'https://develop.q4desktop.com/static/css/2.94672220.chunk.css',
      'https://develop.q4desktop.com/static/css/main.37082810.chunk.css'
    ]
  }

  return Array.from(document.getElementsByTagName('link'))
    .filter((link) => link.rel === 'stylesheet')
    .map((link) => link.href)
}
