import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Radio } from '../index'
import { getClassName, THEMES } from '../../../utils/ui'

import './radioGroup.component.scss'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT, THEMES.WHITE]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  controls: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  inline: PropTypes.bool
}

const defaultProps = {
  theme: THEMES.LIGHT,
  controls: [],
  inline: true
}

/**
 * Radio Group Component
 * @param props
 */
function RadioGroup (props) {
  const { dataId, theme, inline, label, name, controls, selected, onChange } = props

  const baseClassName = getClassName('radio-group', [
    { condition: theme, trueClassName: `radio-group--${theme}` },
    { condition: inline, trueClassName: 'radio-group--inline' }
  ])

  return (
    <div data-id={dataId} className={baseClassName}>
      {label ? <label className='radio-group_label'>{label}</label> : null}
      {(controls || []).map((control) => {
        const { dataId, label, value, color, disabled } = (control || {})
        return (
          <Radio
            dataId={dataId}
            theme={theme}
            color={color}
            key={value}
            name={name}
            value={value}
            label={label}
            isSelected={selected === value}
            onChange={onChange}
            disabled={disabled}
          />
        )
      })}
    </div>
  )
}

RadioGroup.propTypes = propTypes
RadioGroup.defaultProps = defaultProps

export default memo(RadioGroup)
