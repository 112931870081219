import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// components
import { RenderHTML } from '../../../index'

// utils
import { defaultIfEmpty, getAddress, getPhones, ENTITY_TYPE } from '../../../../utils'
import { get } from 'lodash'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  entityContact: PropTypes.object,
  institutionId: PropTypes.string,
  job: PropTypes.object
}

const defaultProps = {
  job: {}
}

/**
 * Get correct contact job
 * @param entityId
 * @param entityType
 * @param entityContact
 * @param institutionId
 * @param job
 */
function getContactJob ({ entityId, entityType, entityContact, institutionId, job }) {
  switch (entityType) {
    case INSTITUTION:
      return entityContact
        ? (entityContact.jobs || []).find((job) => job.entityId === entityId)
        : null
    case FUND:
      return entityContact
        ? (entityContact.jobs || []).find((job) => job.entityId === institutionId) || get(entityContact, 'jobs[0]')
        : null
    default:
      return job
  }
}

/**
 * Address Component
 * @param props
 */
function Address ({ entityId, entityType, entityContact, institutionId, job }) {
  const contactJob = getContactJob({ entityId, entityType, entityContact, institutionId, job })

  if (!contactJob || !contactJob.entityId) {
    return null
  }

  const { fullName } = (entityContact || {})
  const { title, phone, directPhone, email } = (contactJob || {})

  const address = getAddress(contactJob)
  const phones = getPhones(phone, directPhone)

  return (
    <Grid container spacing={2} className='tearsheet_section'>
      <Grid item xs={12} className='tearsheet_section-title'>
        {entityType === CONTACT ? 'Details' : 'Contact'}
      </Grid>
      <Grid item xs={12} className='tearsheet_section-content'>
        {(entityType !== CONTACT) && fullName
          ? <div className='tearsheet_section-subtitle'>
            <div className='tearsheet_section-subtitle-detail'>{defaultIfEmpty(fullName)}</div>
            {title
              ? <div className='tearsheet_section-subtitle-detail--light'>{defaultIfEmpty(title)}</div>
              : null}
          </div>
          : null}
        <span className='tearsheet_section-content tearsheet_section-content--inline'>
          <i className='icon q4i-phone-4pt' />
          <RenderHTML html={defaultIfEmpty(phones)} />
        </span>
        <span className='tearsheet_section-content tearsheet_section-content--inline'>
          <i className='icon q4i-mail-4pt' />
          {defaultIfEmpty(email)}
        </span>
        <span className='tearsheet_section-content tearsheet_section-content--inline'>
          <i className='icon q4i-map-marker-4pt' />
          <RenderHTML html={defaultIfEmpty(address)} />
        </span>
      </Grid>
    </Grid>
  )
}

Address.propTypes = propTypes
Address.defaultProps = defaultProps

export default memo(Address)
