import {
  SET_ACTIVE_SECURITY,
  SET_DASHBOARD_THEME,
  SET_ACTIVE_DASHBOARD,
  GET_DASHBOARD_SUCCESS
} from '../../actions/dashboard/index'
import {
  CLONE_DASHBOARD_SUCCESS,
  REMOVE_DASHBOARD_SUCCESS,
  CANCEL_DASHBOARD_EDIT
} from '../../actions/dashboard/dashboard.actions'

const initial = {
  theme: 'dark',
  tickerId: null,
  securityId: null,
  activeDashboard: null
}

const dashboardState = (state = initial, action) => {
  switch (action.type) {
    case SET_DASHBOARD_THEME:
      return {
        ...state,
        theme: action.theme
      }
    case SET_ACTIVE_DASHBOARD:
      return {
        ...state,
        activeDashboard: action.dashboardId,
        prevActiveDashboard: state.activeDashboard
      }
    case REMOVE_DASHBOARD_SUCCESS:
      return {
        ...state,
        activeDashboard: (action.payload._id === state.activeDashboard) ? null : state.activeDashboard
      }
    case GET_DASHBOARD_SUCCESS:
    case CLONE_DASHBOARD_SUCCESS:
      return {
        ...state,
        activeDashboard: action.payload._id,
        prevActiveDashboard: action.payload._id
      }
    case CANCEL_DASHBOARD_EDIT :
      return {
        ...state,
        activeDashboard: action.payload._id
      }
    case SET_ACTIVE_SECURITY:
      return {
        ...state,
        tickerId: action.tickerId,
        securityId: action.security,
        entityId: action && action.entityId
      }
    default:
      return state
  }
}

export default dashboardState
