import {
  SEARCH_ACTIVITY_DEALS_REQUEST,
  SEARCH_ACTIVITY_DEALS_SUCCESS,
  SEARCH_ACTIVITY_DEALS_FAILURE,
  statusType,
  CLEAR_SEARCH_DATA
} from '../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const activityParticipants = (state = initial, action) => {
  switch (action.type) {
    case SEARCH_ACTIVITY_DEALS_REQUEST:
      return {
        ...initial,
        status: statusType.IN_PROGRESS
      }

    case SEARCH_ACTIVITY_DEALS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: statusType.SUCCESS
      }

    case SEARCH_ACTIVITY_DEALS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case CLEAR_SEARCH_DATA:
      return { ...initial }

    default:
      return state
  }
}

export default activityParticipants
