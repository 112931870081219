import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { getClassName } from '../../utils/ui'
import { renderDarkThumb, renderTrackVertical } from '../../resources/theme/q4.custom-scrollbar'

// Components
import Portal from '../portal/portal.component'
import { Scrollbars } from 'react-custom-scrollbars'

import './flyout.component.css'

/**
 * Flyout Component
 */
const Flyout = (props) => {
  const { collapsed, minWidth, leftOffset, children, handleClose } = props

  const baseClassName = getClassName('flyout', [
    { condition: collapsed, trueClassName: 'flyout--collapsed' }
  ])

  const baseStyle = {
    width: collapsed ? '0' : `calc(100% - ${leftOffset}px)`,
    minWidth: collapsed ? '0' : minWidth
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  })

  /**
   * Handle key down
   * @param event
   */
  function handleKeyDown ({ key, isTrusted }) {
    if (isTrusted && ['Escape', 'Esc'].includes(key)) {
      handleClose()
    }
  }

  return (
    <Portal>
      <div className={baseClassName} style={baseStyle}>
        <Scrollbars
          className='react-scrollbar flyout_inner'
          autoHide
          hideTracksWhenNotNeeded
          renderThumbVertical={renderDarkThumb}
          renderTrackVertical={renderTrackVertical} >
          {children}
        </Scrollbars>
      </div>
    </Portal>
  )
}

Flyout.propTypes = {
  /**
   * Used to determine open/close state of a component
   */
  collapsed: PropTypes.bool.isRequired,

  /**
   * Used to calculate the available width
   */
  leftOffset: PropTypes.number.isRequired,

  /**
   * Used to set a minimum width of a component
   */
  minWidth: PropTypes.number.isRequired,

  /**
   * Component Content
   */
  children: PropTypes.object,

  /**
   * Used as a callback function, which is being called on flyout close event
   */
  handleClose: PropTypes.func
}

Flyout.defaultProps = {
  collapsed: true,
  leftOffset: 0,
  minWidth: 900
}

export default Flyout
