import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'

import { Button, Card, RenderHTML } from '../../index'
import {
  renderLightThumb,
  renderTrackVertical
} from '../../../resources/theme/q4.custom-scrollbar'
import { getClassName, THEMES } from '../../../utils/ui'

import './card.component.css'

/**
 * Comment
 * @param params.comment
 * @param params.placeholder
 * @deprecated -> use entity/comment/cart
 */
function Comment ({ comment, placeholder }) {
  return (
    <Scrollbars
      className='react-scrollbar'
      autoHide
      hideTracksWhenNotNeeded
      renderThumbVertical={renderLightThumb}
      renderTrackVertical={renderTrackVertical}
    >
      <div className='comment-body'>
        {
          comment
            ? <RenderHTML html={comment} />
            : <span className='placeholder'>{placeholder}</span>
        }
      </div>
    </Scrollbars>
  )
}

/**
 * Entity Summary Card
 * @param props.comment
 * @param props.placeholder
 * @param props.isLoading
 * @param props.onEditClick
 */
function EntitySummaryCard (props) {
  const { comment, placeholder, onEditClick, isLoading, theme, className, dataId } = props

  const baseClassName = getClassName('card--entity card--entity-summary', [
    { condition: className, trueClassName: className }
  ])


  /**
   * Handle Edit Click
   */
  const handleEditClick = () => {
    onEditClick()
  }

  /**
   * Render
   */
  return (
    <Card
      dataId={dataId}
      className={baseClassName}
      theme={theme}
      title='Comment'
      isChin
      headerActions={[() => (
        <Button
          theme={theme === THEMES.DARK ? THEMES.DARK : THEMES.LIGHT_GREY}
          icon='q4i-edit-4pt'
          label='Edit'
          disabled={isLoading}
          onClick={handleEditClick}
        />
      )]}
    >
      {
        !isLoading && (
          <Comment
            placeholder={placeholder}
            comment={comment}
          />
        )
      }
    </Card>
  )
}

export default EntitySummaryCard
