import React from 'react'
import PropTypes from 'prop-types'

import { Select, ComboBox } from '../../../../../components'
import { Checkbox } from '../../../../../components/shared'
import { THEMES } from '../../../../../utils'
import { uniqBy, toUpper } from 'lodash'

const CONTACT_TYPES = [
  { label: 'Contact', value: 'Contact' },
  { label: 'Custom Contact', value: 'Custom Contact' },
  { label: 'Corporate Participant', value: 'Corporate Participant' }
]
function Filter (props) {
  const { onFilterChange, filters, institutionSelector, locationSelector } = props
  const { targets, deals, type, institutions: selectedInstitutions, locations: selectedLocations } = filters

  const onTypeChange = (option) => onFilterChange({
    type: option && option.map((item) => item && item.value)
  })

  const onCheckboxChange = (value, event) => onFilterChange({
    [event.target.id]: value
  })

  const getInstitutionLabel = selectedInstitutions && selectedInstitutions.length ? `Institutions (${selectedInstitutions.length})` : 'Institutions'
  const getLocationLabel = selectedLocations && selectedLocations.length ? `Locations (${selectedLocations.length})` : 'Locations'
  const MAX_LIST_SIZE = 10
  const isInstitutionSearchDisabled = selectedInstitutions.length >= MAX_LIST_SIZE
  const isInstitutionError = isInstitutionSearchDisabled
    ? { isError: true, message: 'You have reached the maximum search limit. Please remove an institution to continue.' }
    : null

  const isLocationSearchDisabled = selectedLocations.length >= MAX_LIST_SIZE
  const isLocationError = isLocationSearchDisabled
    ? { isError: true, message: 'You have reached the maximum search limit. Please remove a location to continue.' }
    : null

  const handleInstitutionInputChange = (query, event) => {
    if (event.action === 'input-change') {
      institutionSelector.getInstitutionSuggestions(query)
    } else {
      institutionSelector.resetInstitutionSuggestions()
    }
  }

  const onInstitutionAdd = (item) => {
    onFilterChange({ institutions: item && [].concat(selectedInstitutions, item) })
    institutionSelector.resetInstitutionSuggestions()
  }
  const onInstitutionRemove = (value) => onFilterChange({ institutions: selectedInstitutions.filter((item) => item && item.value !== value) })
  const institutionOptions = () => {
    return institutionSelector.institutionSuggestions.filter(suggestedInst =>
      !selectedInstitutions.some(selectedInst => suggestedInst.value === selectedInst.value)
    )
  }

  const handleLocationInputChange = (query, event) => {
    if (event.action === 'input-change') {
      locationSelector.getLocationSuggestions(query)
    } else {
      locationSelector.resetLocationSuggestions()
    }
  }

  const onLocationAdd = (item) => {
    onFilterChange({ locations: item && [].concat(selectedLocations, item) })
    locationSelector.resetLocationSuggestions()
  }
  const onLocationRemove = (value) => onFilterChange({ locations: selectedLocations.filter((item) => item && item.value !== value) })
  const locationOptions = () => {
    const formatCity = (city) => city.charAt(0).toUpperCase() + city.slice(1)
    let locationText
    const concatLocations = locationSelector.locationSuggestions.map(loc => {
      if (!loc.city && loc.countryCode) {
        locationText = loc.countryCode ? `${toUpper(loc.countryCode)}` : ''
      } else {
        locationText = formatCity((loc.city)) + (loc.countryCode ? `, ${toUpper(loc.countryCode)}` : '')
      }
      return {
        value: locationText,
        label: locationText,
        locationData: { city: loc.city, countryCode: loc.countryCode }
      }
    })
    const filteredResults = concatLocations.filter(suggestedLoc =>
      !selectedLocations.some(selectedLoc => suggestedLoc.value === selectedLoc.value)
    )
    return uniqBy(filteredResults, 'value');
  }

  return (
    <section className='contact-page_sidebar_filter'>
      <div className='field field--full field--text'>
        <ComboBox
          theme={THEMES.WHITE}
          selectProps={{
            inputId: 'contact-filter-institution-input',
            dataId: { inputId: 'contact-filter-institution-wrapper', menuId: 'contact-filter-institution-menu' },
            placeholder: 'Search',
            label: getInstitutionLabel,
            options: institutionOptions(),
            disabled: isInstitutionSearchDisabled,
            value: null,
            loading: institutionSelector.institutionLoading,
            closeMenuOnSelect: true,
            error: isInstitutionError,
            onInputChange: handleInstitutionInputChange,
            onChange: onInstitutionAdd
          }}
          removableListProps={{
            items: selectedInstitutions,
            onRemove: onInstitutionRemove
          }}
        />
      </div>

      <div className='field field--full field--text'>
        <label className='field_label'>Contact Type</label>
        <Select
          inputId='contact-filter-type-input'
          dataId={{ inputId: 'contact-filter-type-wrapper', menuId: 'contact-filter-type-menu' }}
          theme={THEMES.WHITE}
          placeholder='Select'
          options={CONTACT_TYPES}
          value={CONTACT_TYPES.filter(cType => type.includes(cType.value))}
          isMulti
          isDefaultList
          searchable={false}
          clearable={false}
          closeMenuOnSelect={false}
          onChange={onTypeChange}
        />
      </div>

      <div className='field field--full field--text'>
        <ComboBox
          theme={THEMES.WHITE}
          selectProps={{
            inputId: 'contact-filter-location-input',
            dataId: { inputId: 'contact-filter-location-wrapper', menuId: 'contact-filter-location-menu' },
            placeholder: 'Search',
            label: getLocationLabel,
            options: locationOptions(),
            disabled: isLocationSearchDisabled,
            value: null,
            loading: locationSelector.locationLoading,
            closeMenuOnSelect: true,
            error: isLocationError,
            onInputChange: handleLocationInputChange,
            onChange: onLocationAdd
          }}
          removableListProps={{
            items: selectedLocations,
            onRemove: onLocationRemove
          }}
        />
      </div>

      <div className='field_label--standalone'>Include:</div>

      <div className='field field--full'>
        <Checkbox
          id='targets'
          label='Targets'
          theme={THEMES.SOFT_GREY}
          className='address-book-page_toolbar-target'
          isChecked={targets}
          onChange={onCheckboxChange}
        />
      </div>

      <div className='field field--full'>
        <Checkbox
          id='deals'
          label='Deals'
          theme={THEMES.SOFT_GREY}
          className='address-book-page_toolbar-deal'
          isChecked={deals}
          onChange={onCheckboxChange}
        />
      </div>
    </section>
  )
}

Filter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    type: PropTypes.array,
    targets: PropTypes.bool,
    deals: PropTypes.bool
  })
}
export default Filter
