import { combineReducers } from 'redux'
import analytics from './analytics.reducer'
import detail from './detail.reducer'
import institution from './institution.reducer'

export default combineReducers({
  analytics,
  detail,
  institutionDetail: institution
})
