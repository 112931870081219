import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/react-hooks'

export const GET_ADVANCED_SEARCH_RESULTS = gql`
  query AdvancedSearch(
    $query: String,
    $entity: [String],
    $page: Int,
    $limit: Int
    $isInstitutionSearch: Boolean!
    $isContactSearch: Boolean!
    $isFundSearch: Boolean!
    $tickerId: [String],
    $securityId: [String],
    $filter: SearchFilter,
    $useElasticSearch: Boolean
  ) {
    advancedSearch(
      query: $query,
      entity: $entity,
      filter: $filter,
      page: $page,
      limit: $limit,
      useElasticSearch: $useElasticSearch
    ) {
      items {
        id
        score
        entityConnection(securityId: $securityId, tickerId: $tickerId) {
          ... on InstitutionSearchDTO @include(if: $isInstitutionSearch) {
            entityId
            institutionName
            type
            entity {
              id
              addressConnection {
                items {
                  addressLine1
                  city
                  countryCode
                  hq
                  postalCode
                  stateProvinceCode
                  stateProvinceName
                }
              }
            }
          }
          ... on ContactSearchDTO @include(if: $isContactSearch) {
            fullName
            type
            entity {
              id
              source
              jobs {
                title
                entityId
                institutionName
              }
            }
          }
          ... on FundSearchDTO @include(if: $isFundSearch) {
            entityId
            institutionId
            fundName
            type
            entity {
              id
              institutionConnection {
                items {
                   addressConnection {
                    items {
                      addressLine1
                      city
                      hq
                      postalCode
                      stateProvinceCode
                      stateProvinceName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export function useAdvancedSearchQuery () {
  return useLazyQuery(GET_ADVANCED_SEARCH_RESULTS, {
    fetchPolicy: 'no-cache'
  })
}
