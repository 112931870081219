import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { get, isNil, omitBy, pick, uniq } from 'lodash'
import { OWNERSHIP_TYPE } from '../../../../../utils'

// actions
import { useShareholderIdHolderQuery, useShareholderIdFilterQuery, GET_SHAREHOLDERID_FUND_HOLDERS, GET_SHAREHOLDERID_HOLDERS_EXPORT } from '../../../hook'
import { useExport } from '../../../../../services/csvExport/csvExport.service'
import { openModal, modalType } from '../../../../../actions'

// components
import ShareholderIdToolbar from './toolbar/toolbar.component'
import ShareholderIdSidebar from './sidebar/sidebar.component'
import ShareholderIdTable from './table/table.component'

// utils
import { getPageSizeFromStorage, getTreeHierarchy, formatDate, format, ENTITY_TYPE, EXPORT_DATE_FORMAT, getLocalizedCurrency } from '../../../../../utils'

const propTypes = {
  dataIdPrefix: PropTypes.string,
  toolbarTheme: PropTypes.string,
  toolTheme: PropTypes.string,
  tickerId: PropTypes.string.isRequired,
  history: PropTypes.object,
  persistence: PropTypes.object,
}

const defaultProps = {}

const QUARTERS = 20 // 5 years
const startDate = moment.utc().subtract(QUARTERS, 'quarters').toISOString()
const PAGE_SIZE_ID = 'shareholder-id-grid'
const ZERO = '0.00'

/**
 * Get filter (style, type) options
 * @param data
 * @param type
 */
function getOptions (data, type) {
  const source = `${ENTITY_TYPE.INSTITUTION}${type}`
  const items = get(data, `${source}.items`, [])
  return (items || []).map((item) => ({ label: item[source], value: item[source] }))
}

/**
 * ShareholderId Grid Component
 * @param props
 */
function ShareholderIdGrid (props) {
  const { dataIdPrefix, toolbarTheme, toolTheme, tickerId, history, openModal, persistence } = props

  const [holdingData, setHoldingData] = useState()
  const [fundLoading, setFundLoading] = useState(false)
  const [isSidebar, setIsSidebar] = useState(!!persistence.getGridFiltersDecoded() || false)
  const [state, setState] = useState(persistence.getGridFiltersDecoded({
    search: null,
    filter: {
      isActivist: false,
      position: null,
      style: null,
      turnover: null,
      type: null
    },
    listOptions: {
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
  }));
  const { search, filter, listOptions } = state
  const { isActivist, position } = (filter || {})
  const variables = omitBy({
    tickerId,
    startDate,
    search,
    position,
    isActivist,
    ...pick(filter, ['style', 'turnover', 'type']),
    ...listOptions
  }, isNil)

  const { data: shareHolderData, error, loading, client, refetch } = useShareholderIdHolderQuery({ variables })
  const { data: filterData, loading: filterLoading } = useShareholderIdFilterQuery({ variables: { tickerId } })

  const fetchDataForItem = async (options) => (await client.query({
    query: GET_SHAREHOLDERID_FUND_HOLDERS,
    ...options,
    fetchPolicy: 'no-cache'
  })).data.fundHolding;
  
  useEffect(() => {
    if (shareHolderData && !loading) {
      const fetchAndSetData = async () => {
        const updatedItems = await Promise.all(shareHolderData.instHolding.items.map(async (item) => {
          if (item.institutionId) {
            const fundVariables = omitBy({
              institutionId: item.institutionId,
              tickerId,
              startDate,
              source: OWNERSHIP_TYPE.SHAREHOLDER
            }, isNil);
            const fundHoldingConnection = await fetchDataForItem({ variables: fundVariables });
            return { ...item, fundHoldingConnection };
          }
          return item
        }));
        
        setHoldingData({ ...shareHolderData, instHolding: { ...shareHolderData.instHolding, items: updatedItems } });
        setFundLoading(false);
      };
      
      setHoldingData(shareHolderData);
      setFundLoading(true);
      fetchAndSetData();
    }
  }, [shareHolderData, loading]);

  const { generateExport, exporting } = useExport({
    onError: () => {
      openModal({
        type: modalType.ERROR_MODAL
      })
    }
  })

  const holders = getTreeHierarchy(
    get(holdingData, 'instHolding.items', []),
    'fundHoldingConnection.items'
  )
  const total = get(holdingData, 'instHolding.count', 0)

  /**
   * Handle query change
   * @param query
   */
  const handleQueryChange = (query = {}) => {
    const options = query.listOptions ? { ...query } : { ...query, listOptions: { ...listOptions, page: 1 } }
    setState({ ...state, ...options })
    persistence.setGridFilters({ ...state, ...options })
  }

  /**
   * Get quarter CSV definition
   * @param holdings
   */
  function getQuarterCSVDefinition (holdings) {
    if (!holdings || !holdings.length) {
      return []
    }

    let csvColumns = {}
    const quarters = uniq((holdings)
      .filter((holding) => get(holding, 'reportDate'))
      .map(({ reportDate }) => reportDate));

    (quarters || []).forEach((date, index) => {
      csvColumns = {
        ...csvColumns,
        [formatDate(moment.utc(date), EXPORT_DATE_FORMAT, undefined, true)]: get(holdings[index], 'current', '-')
      }
    })

    return csvColumns
  }

  /**
   * Format mapper for CSV export
   * @param holder
   * @param index
   */
  const holdersCSVMapper = (holder, index) => {
    const {
      institutionName, institutionStyle, institutionTurnover,
      institutionQualityRating, holdings, currentOrd, currentAdr
    } = holder
    const { current: position, change, marketValue, percentTSO } = get(holdings, '0', {})
    const rank = index + 1

    const quarters = getQuarterCSVDefinition(holdings)

    return {
      '#': rank,
      'Shareholder Name': institutionName,
      'Position': position,
      'Change': change,
      'ORD': currentOrd,
      'ADR': currentAdr,
      [`Market Value (${getLocalizedCurrency()})`]: marketValue,
      '%OS': percentTSO ? format(percentTSO, 2) : ZERO,
      'Style': institutionStyle,
      'Turnover': institutionTurnover,
      'QR': institutionQualityRating,
      ...quarters
    }
  }

  /**
   * Handle export
   */
  const handleExport = () => {
    const params = {
      client,
      variables: { ...variables, limit: 0 },
      query: GET_SHAREHOLDERID_HOLDERS_EXPORT,
      dataPath: 'data.instHolding.items',
      fileName: 'shareholderId_holders.csv',
      formatter: holdersCSVMapper
    }

    generateExport(params)
  }

  const pageLoading = fundLoading || loading

  return (
    <div className='shareholder-id-grid grid--sidebar'>
      <ShareholderIdSidebar
        dataId={`${dataIdPrefix}ShareholderId`}
        isSidebar={isSidebar}
        filter={{ ...filter }}
        filterLoading={filterLoading}
        typeOptions={getOptions(filterData, 'Type')}
        styleOptions={getOptions(filterData, 'Style')}
        onQueryChange={handleQueryChange}
      />
      <div className='grid_body'>
        <ShareholderIdToolbar
          collapsed={isSidebar}
          dataId={`${dataIdPrefix}ShareholderId`}
          toolbarTheme={toolbarTheme}
          toolTheme={toolTheme}
          search={search}
          noData={!(holders || []).length}
          onSidebarToggle={() => setIsSidebar(!isSidebar)}
          onQueryChange={handleQueryChange}
          onExport={handleExport}
          exporting={exporting}
        />
        <ShareholderIdTable
          pageSizeId={PAGE_SIZE_ID}
          loading={pageLoading}
          data={pageLoading ? [] : holders}
          total={total}
          listOptions={listOptions}
          onQueryChange={handleQueryChange}
          history={history}
          error={error}
          onRefresh={refetch}
        />
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

ShareholderIdGrid.propTypes = propTypes
ShareholderIdGrid.defaultProps = defaultProps

export default withRouter(connect(mapDispatchToProps)(ShareholderIdGrid))
