import {
  FETCH_WIDGET_BROKER_DETAILS_REQUEST,
  FETCH_WIDGET_BROKER_DETAILS_SUCCESS,
  FETCH_WIDGET_BROKER_DETAILS_ERROR,
  RESET_WIDGET_BROKER_DETAILS,
  FETCHED,
  FETCHING,
  IDLE,
  FAILED
} from '../../../actions/widget/estimates'

const initialState = {
  eps: {},
  sales: {},
  ebitda: {},
  sga: {},
  ffo: {},
  status: IDLE
}

const brokerDetails = (state = initialState, action) => {
  switch (action.type) {
    case RESET_WIDGET_BROKER_DETAILS:
      return {
        ...initialState
      }
    case FETCH_WIDGET_BROKER_DETAILS_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_WIDGET_BROKER_DETAILS_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        [action.payload.item]: {
          ...action.payload
        }
      }
    case FETCH_WIDGET_BROKER_DETAILS_ERROR:
      return {
        ...state,
        status: FAILED,
        error: action.error
      }
    default:
      return state
  }
}

export default brokerDetails
