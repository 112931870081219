import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/react-hooks'
import { usePromiseQuery } from '../../../hook'

const GET_HISTORICAL_STOCK = gql`
query Stock(
  $index: [String]
  $tickerId: [String]
  $startDate: String!
  $endDate: String!
) {
  stock(
    index: $index
    tickerId: $tickerId
    startDate: $startDate
    endDate: $endDate
  ) {
    items {
      date
      last
      tickerId
      volume
    }
  }
}`

const GET_STOCK = gql`
query Stock(
  $tickerId: [String]
) {
  stock(
    tickerId: $tickerId
  ) {
    items {
      date
      currency
      last
      tickerId
      volume
      lastCloseChange
      lastCloseChangePercent
    }
  }
}`

/**
 * Historical Stock
 * @param options
 */
export function useHistoricalStockQuery (options) {
  return useLazyQuery(GET_HISTORICAL_STOCK, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Current Stock
 * @param options
 */
export function useStockQuery (options) {
  return useLazyQuery(GET_STOCK, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Historical Stock
 * @param options
 */
export function useHistoricalStockPromiseQuery () {
  return usePromiseQuery(GET_HISTORICAL_STOCK, {
    fetchPolicy: 'no-cache'
  })
}

/**
 * Current Stock
 * @param options
 */
export function useStockPromiseQuery (options) {
  return usePromiseQuery(GET_STOCK, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}
