import React from 'react'
import PropTypes from 'prop-types'
import './noSubscriptionMessage.component.css'

/**
 * Subscription Message Component
 */
const NoSubscriptionMessage = (props) => {
  const { image } = props

  const imageStyle = {
    backgroundImage: `url(${image})`
  }

  return (
    <div className='no-subscription-message'>
      <div className='no-subscription-message_details'>
        <h2 className='no-subscription-message_title'>Looks like you haven't subscribed to this feature.<br />Interested?
        </h2>
        <a className='button button--tall button--citrus' href='mailto:desktop@q4inc.com'>Let's Chat</a>
      </div>
      {image && (
        <div className='no-subscription-message_snapshot'>
          <div className='no-subscription-message_image' style={imageStyle} />
          <div className='no-subscription-message_macbook' />
        </div>
      )}
    </div>
  )
}

NoSubscriptionMessage.propTypes = {
  image: PropTypes.string
}

export default NoSubscriptionMessage
