import React from 'react'
import { Button } from '../../../../../components'
import { combine, ENTITY_TYPE, THEMES } from '../../../../../utils'
import { get } from 'lodash'

const { CONTACT, FUND } = ENTITY_TYPE
const EMPTY_PLACEHOLDER = '-'

/**
 * Title Custom Cell Component
 */
const TitleCell = ({ data }) => {
  const { entityType, institutionId, entityConnection } = data
  const { fullName, fundName, institutionName, jobs } = entityConnection || {}
  const isCustomJob = !institutionId && get(jobs, '[0].entityId') === ''
  const name = fullName || fundName || institutionName || EMPTY_PLACEHOLDER
  const job = institutionId
    ? (jobs || []).find((job) => job.entityId === institutionId)
    : (isCustomJob && jobs[0]) || null;
  const title = get(job, 'title', null)
  const institution = (entityType === CONTACT)
    ? get(job, 'institutionName', null)
    : (entityType === FUND) ? institutionName : null

  return <div className='cell'>
    <i className={`cell_icon cell_icon--${entityType} q4i-${entityType}-2pt`} />
    <div className='cell_content'>
      <div className='cell_content-name'>{combine([name, title], ' - ')}</div>
      {institution && <div className='cell_content-detail'>{institution}</div>}
    </div>
  </div>
}

/**
 * AgGrid Custom Cell Render Component
 * @param dataId
 * @param data
 * @param column
 * @param onComment
 */
const CustomCell = ({ dataId, data = {}, column = {}, onComment }) => {
  switch (column.colId) {
    case 'name':
      return <TitleCell data={data} />
    case 'comment':
      return <Button
        dataId={`${dataId}AddComment`}
        theme={THEMES.LIGHT}
        icon='q4i-edit-4pt'
        label='Comment'
        onClick={() => onComment(data)}
      />
    case 'draggable':
      return null
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
