import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { getClassName, THEMES } from '../../utils'

import './toolbar.component.css'

/**
 * Toolbar Component
 */
class Toolbar extends PureComponent {
  /**
   * Render Toolbar component
   * @returns {*}
   */
  render () {
    const { theme, children, className } = this.props

    const baseClassName = getClassName('toolbar', [
      { condition: className, trueClassName: className },
      { condition: theme, trueClassName: `toolbar--${theme}` }
    ])

    return (
      <div className={baseClassName}>
        <div className='toolbar_inner'>
          {children}
        </div>
      </div>
    )
  }
}

Toolbar.propTypes = {
  /**
   * A custom className to pass into the component
   */
  className: PropTypes.string,

  /**
   * Used to paint the Toolbar using a specific theme
   */
  theme: PropTypes.oneOf([THEMES.RAIN, THEMES.STEEL, THEMES.Q4_BLUE, THEMES.INK]),

  /**
   * Children to render within the Toolbar
   * Note: Use ToolBarRow for additional customization
   */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

Toolbar.defaultProps = {
  theme: THEMES.RAIN
}

export default Toolbar
