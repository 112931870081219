import {
  GET_PIPELINE_WIDGET_SUCCESS,
  GET_PIPELINE_WIDGET_FAILURE,
  GET_PIPELINE_WIDGET_REQUEST
} from '../../../actions/widget/pipeline'
import { statusType } from '../../../actions'

const initial = {
  open: [],
  lanes: [],
  status: {
    pipeline: statusType.IDLE
  }
}

const pipelineReducer = (state = initial, action) => {
  switch (action.type) {
    case GET_PIPELINE_WIDGET_REQUEST:
      return {
        ...state,
        pipeline: {
          ...state.pipeline,
          status: statusType.IN_PROGRESS
        }
      }
    case GET_PIPELINE_WIDGET_SUCCESS:
      const pipeline = action.payload
      state.open = (pipeline || []).map((stage) => {
        return {
          id: stage._id,
          type: 'stage',
          lost: stage.lost || false,
          label: stage.label,
          probability: stage.probability,
          cardStyle: { marginBottom: 5, marginLeft: 4, marginRight: 4, width: 222, backgroundColor: '#ffffff' },
          cards: (stage._deal || []).map((deal) => Object.assign(deal, {
            type: 'deal',
            id: deal._id
          }))
        }
      })

      state.lanes = [].concat(state.targets, state.open)
      state.status.pipeline = statusType.SUCCESS
      return { ...state }

    case GET_PIPELINE_WIDGET_FAILURE:
      state.status.pipeline = statusType.ERROR
      return { ...state }
    default:
      return state
  }
}

export default pipelineReducer
