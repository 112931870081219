import './ghostable.component.css'
import './ghostable.doc'
import React, { memo } from 'react'
import { CSSTransition } from 'react-transition-group'
import { getClassName, GhostableClassName, TransitionSpeed, TransitionSpeedModifier } from '../../utils'

const defaultProps = {
  transitionSpeed: TransitionSpeedModifier.Default,
  exitDelay: 0,
  enterDelay: 0,
  appear: true,
  mountOnEnter: true,
  unmountOnExit: true,
}

/**
 * Ghostable
 * @param {GhostableProps} props 
 * @returns JSX.Element
 */
const Ghostable = (props) => {
  const { children, ghosted, transitionSpeed: transitionSpeedModifier, enterDelay, exitDelay, ...transitionProps } = props;

  const transitionSpeed = new TransitionSpeed(transitionSpeedModifier);
  const enterTimeout = transitionSpeed.time * (enterDelay + 1);
  const exitTimeout = transitionSpeed.time * (exitDelay + 1);

  function getStateClassName(stateClassName, conditions = []) {
    return getClassName(stateClassName, [...(conditions || [])]);
  }

  return (
    <CSSTransition
      in={!ghosted}
      classNames={{
        appear: getStateClassName(GhostableClassName.GhostWithDoneModifier),
        appearActive: getStateClassName(GhostableClassName.SolidWithTransitionModifier, [
          { condition: enterDelay > 0, trueClassName: `${GhostableClassName.SolidWithTransitionModifier}-${enterDelay}x-delay` },
          {
            condition: !!transitionSpeedModifier,
            trueClassName: `${GhostableClassName.SolidWithTransitionModifier}-${transitionSpeed.modifier}-speed`,
          },
        ]),
        appearDone: getStateClassName(GhostableClassName.SolidWithDoneModifier),
        enter: getStateClassName(GhostableClassName.GhostWithDoneModifier),
        enterActive: getStateClassName(GhostableClassName.SolidWithTransitionModifier, [
          { condition: enterDelay > 0, trueClassName: `${GhostableClassName.SolidWithTransitionModifier}-${enterDelay}x-delay` },
          {
            condition: !!transitionSpeedModifier,
            trueClassName: `${GhostableClassName.SolidWithTransitionModifier}--${transitionSpeed.modifier}-speed`,
          },
        ]),
        enterDone: getStateClassName(GhostableClassName.SolidWithDoneModifier),
        exit: getStateClassName(GhostableClassName.SolidWithDoneModifier),
        exitActive: getStateClassName(GhostableClassName.GhostWithTransitionModifier, [
          { condition: exitDelay > 0, trueClassName: `${GhostableClassName.GhostWithTransitionModifier}-${exitDelay}x-delay` },
          {
            condition: !!transitionSpeedModifier,
            trueClassName: `${GhostableClassName.GhostWithTransitionModifier}--${transitionSpeed.modifier}-speed`,
          },
        ]),
        exitDone: getStateClassName(GhostableClassName.GhostWithDoneModifier),
      }}
      timeout={{
        appear: enterTimeout,
        enter: enterTimeout,
        exit: exitTimeout,
      }}
      {...transitionProps}
    >
      {children}
    </CSSTransition>
  );
}

Ghostable.defaultProps = defaultProps

export default memo(Ghostable)
