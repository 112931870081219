import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get } from 'lodash'

import EntitySummaryCard from './card/card.component'
import EntitySummaryEdit from './edit/edit.component'

import {
  getEntitySummary,
  saveEntitySummary,
  resetEntitySummary,
  deleteEntitySummary,
  openModal,
  statusType
} from '../../actions'
import { THEMES } from '../../utils'

/**
 * Entity Summary (Comment) Component
 * @deprecated -> use entity/comment/edit
 */
class EntitySummary extends Component {
  /**
   * ComponentDidMount
   */
  componentDidMount () {
    const { getEntitySummary, entityId } = this.props
    getEntitySummary(entityId)
  }

  /**
   * ComponentWillUnmount
   */
  componentWillUnmount () {
    const { resetEntitySummary, entityId } = this.props
    resetEntitySummary(entityId)
  }

  /**
   * Handle Edit Click
   */
  handleEditClick = () => {
    const { openModal, comment, placeholder } = this.props

    openModal({
      component: ({ onClose }) => (
        <EntitySummaryEdit
          comment={comment}
          onClose={onClose}
          onSave={this.handleSave}
          onDelete={this.handleDelete}
          placeholder={placeholder}
        />
      )
    })
  }

  /**
   * Handle Save
   * @param comment
   */
  handleSave = (comment) => {
    const { saveEntitySummary, entityId, entityType } = this.props
    return saveEntitySummary(entityId, entityType, comment)
  }

  /**
   * Handle Delete
   */
  handleDelete = () => {
    const { deleteEntitySummary, entityId } = this.props
    return deleteEntitySummary(entityId)
  }

  /**
   * Render
   */
  render () {
    const { dataId, comment, isLoading, entityType, theme, className } = this.props

    return (
      <EntitySummaryCard
        dataId={`${dataId}CommentsCard`}
        className={className}
        comment={comment}
        placeholder={`No ${entityType} comments`}
        isLoading={isLoading}
        onEditClick={this.handleEditClick}
        theme={theme}
      />
    )
  }
}

EntitySummary.defaultProps = {
  placeholder: 'Enter some background information, investment rationale, or any over-arching note for the profile...',
  theme: THEMES.DARK
}

EntitySummary.propTypes = {
  entityId: PropTypes.string.isRequired,
  dataId: PropTypes.string,
  entityType: PropTypes.string.isRequired,
  theme: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    comment: get(state, 'entitySummary.data'),
    isError: get(state, 'entitySummary.status') === statusType.ERROR,
    isLoading: get(state, 'entitySummary.status') === statusType.IN_PROGRESS
  }
}

const mapDispatchToProps = (dispatch) => ({
  getEntitySummary: bindActionCreators(getEntitySummary, dispatch),
  saveEntitySummary: bindActionCreators(saveEntitySummary, dispatch),
  deleteEntitySummary: bindActionCreators(deleteEntitySummary, dispatch),
  resetEntitySummary: bindActionCreators(resetEntitySummary, dispatch),
  openModal: bindActionCreators(openModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(EntitySummary)
