import { detect } from "detect-browser"

export const getIsBrowser = (userAgent) => {
  let isChrome = false;
  let isEdge = false;
  let isEdgeChromium = false;

  const browser = detect(userAgent);
  switch (browser && browser.name) {
    case 'chrome':
      isChrome = true;
      break;
    case 'edge':
      isEdge = true;
      break;
    case 'edge-chromium':
      isEdgeChromium = true;
      break;
    default:
      break;
  }
  return {
    isChrome,
    isEdge,
    isEdgeChromium,
  };
}