import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import './toast.component.css';

/**
 * Toast Component
 * @param props
 */
class Toast extends Component {

    constructor(props) {
        super(props);

        this.state = {
            closed: {}
        };
    }

    onClose = (id) => {
        this.setState({
            closed: Object.assign({}, this.state.closed, {
                [id]: true
            })
        });

        this.props.deleteToast(id);
    };

    /**
     * TODO: remove once activity and deal forms are moved to React
     * Returns predefined toast message template.
     * @param params
     */
    getToastMessage = (params) => {
        if (!params || !params.title) {
            return;
        }

        return ( (params.src) ? <div className='toast-item_message'>
                <Link to={params.src}>{params.title}</Link> has been successfully saved.
            </div> : <div className='toast-item_message'> {params.title} has been successfully saved.</div>
        );
    };

    /**
     * Renders a single toast notification.
     * @param message
     */
    renderToastMessage = (message) => {
        const {closed} = this.state;

        const duration = message.timeout || 5000;
        const anchor = {
            vertical: message.anchor && message.anchor.vertical ? message.anchor.vertical : 'top',
            horizontal: message.anchor && message.anchor.horizontal ? message.anchor.horizontal : 'right'
        };
        const transition = {
            direction: message.transition && message.transition.direction ? message.transition.direction : 'left',
            timeout: message.transition && message.transition.duration ? message.transition.duration : 250
        };
        const {params} = message;

        const toastMessage = (params && params.title) ?
            this.getToastMessage(params) :
            <div className='toast-item_message'>{message.text}</div>;

        return (
            <Snackbar
                key={message.id}
                className='toast-item'
                anchorOrigin={anchor}
                autoHideDuration={duration}
                resumeHideDuration={duration}
                disableWindowBlurListener={true}
                TransitionProps={transition}
                open={!closed[message.id]}
                onClose={() => this.onClose(message.id)}
            >
                <div data-id='ToastMessage' className='toast-item_content'>
                    <i className={`toast-item_icon ${message.type ? `toast-item_icon--${message.type}` : 'toast-item_icon--success'}`}></i>
                    {toastMessage}
                    <i className='toast-item_close q4i-close-4pt' onClick={() => this.onClose(message.id)}></i>
                </div>
            </Snackbar>
        );

    };

    render() {
        const {message} = this.props;
        return (
            <div className='toast'>
                {message.map((message) => this.renderToastMessage(message))}
            </div>
        );
    }
}

Toast.propTypes = {
    message: PropTypes.array.isRequired,
    deleteToast: PropTypes.func.isRequired
};

export default Toast;
