import React from 'react'
import { format, getAUM, defaultIfEmpty, getLocalizedCurrency, hideCurrencyIfEmpty } from '../../../../utils'

const UNKNOWN = 'Unknown'

/**
 * Institution
 * @param props
 * @returns {*}
 */
export const Institution = (props) => {
  const { institutionName, institutionType, style, turnover, position, totalAum, equityAum, purchasingPower, ownership } = props

  return (
    <>
      <div className='item_content-title'>{institutionName}</div>
      <div className='item_content-subtitle item_content-subtitle--small'>
        {defaultIfEmpty(institutionType, UNKNOWN)}
        <span className='pipe'>Style: {defaultIfEmpty(style, UNKNOWN)}</span>
        <span className='pipe'>Turnover: {defaultIfEmpty(turnover, UNKNOWN)}</span>
      </div>
      <div className='item_content-body'>
        <div className='item_content-detail'>
          <div className='item_content-label'>Purchasing Power</div>
          <div className='item_content-value'>{format(purchasingPower, 0, 'dash')}</div>
        </div>
        <div className='item_content-detail'>
          <div className='item_content-label'>Current Position <span className='item_content-label--ownership'>({ownership})</span></div>
          <div className='item_content-value'>{format(position, 0, 'dash')}</div>
        </div>
        <div className='item_content-detail'>
          <div className='item_content-label'>AUM</div>
          <div className='item_content-value'>{defaultIfEmpty(getAUM(totalAum))} {hideCurrencyIfEmpty(totalAum, getLocalizedCurrency())} </div>
        </div>
        <div className='item_content-detail'>
          <div className='item_content-label'>E AUM</div>
          <div className='item_content-value'>{defaultIfEmpty(getAUM(equityAum))} {hideCurrencyIfEmpty(equityAum, getLocalizedCurrency())}</div>
        </div>
      </div>
    </>
  )
}
