import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { addQueryParams } from '../../utils/http/http.util'

export const FETCH_ESTIMATES_CONSENSUS_REQUEST = 'FETCH_ESTIMATES_CONSENSUS_REQUEST'
export const FETCH_ESTIMATES_CONSENSUS_SUCCESS = 'FETCH_ESTIMATES_CONSENSUS_SUCCESS'
export const FETCH_ESTIMATES_CONSENSUS_ERROR = 'FETCH_ESTIMATES_CONSENSUS_ERROR'

export const SET_ESTIMATES_CONSENSUS_METRIC = 'SET_ESTIMATES_CONSENSUS_METRIC'

const resources = {
  consensusTable: '/estimates/v2/consensus'
}

const _fetchConsensusTable = (url, params) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_ESTIMATES_CONSENSUS_REQUEST,
      FETCH_ESTIMATES_CONSENSUS_SUCCESS,
      FETCH_ESTIMATES_CONSENSUS_ERROR
    ],
    method: METHOD_TYPE.GET,
    payload: params
  }
})

export const fetchConsensusTable = (params) => (dispatch, getState) => {
  const url = addQueryParams(resources.consensusTable, params)
  const metric = params.metric
  const state = getState()

  if (state.estimates.consensus.selected.metric !== metric) {
    dispatch({ type: SET_ESTIMATES_CONSENSUS_METRIC, metric })
  }
  return dispatch(_fetchConsensusTable(url))
}

export const setConsensusMetric = (metric) => (dispatch) => {
  dispatch({ type: SET_ESTIMATES_CONSENSUS_METRIC, metric })
}
