import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Card } from '../../../../../../../components'

import { format, inMillions } from '../../../../../../../utils/number.util'
import { isPassive } from '../../../../../../../utils/targeting/targeting.util'
import { THEMES } from '../../../../../../../utils/ui'

import './attributes.component.css'

const propTypes = {
  entityType: PropTypes.oneOf(['institution', 'fund']).isRequired,
  label: PropTypes.string.isRequired,
  currentPosition: PropTypes.number,
  purchasingPower: PropTypes.number,
  ai_score: PropTypes.number,
  total_aum: PropTypes.number,
  equity_aum: PropTypes.number,
  quality_rating: PropTypes.number,
  isLoading: PropTypes.bool.isRequired
}

const defaultProps = {
  label: 'Attributes',
  isLoading: false
}

/**
 * AttributeValue Component
 */
function AttributeValue ({ label, value, isLoading }) {
  return (
    <div className='attributes_item'>
      <div className='attributes_item--label'>{label}</div>
      <div className='attributes_item--value'>{(!isLoading && value) ? value : '-'}</div>
    </div>
  )
}

/**
 * Attributes Component
 */
function AttributesComponent ({ entityType, entity, isLoading, className }) {
  const {
    purchasingPower,
    currentPosition,
    aiScore,
    aum,
    equity_aum,
    quality_rating,
    style
  } = entity

  const attributes = [
    { label: 'Purchasing Power', value: format(purchasingPower) },
    { label: 'Current Pos', value: format(currentPosition) },
    { label: 'AI Score', value: isPassive({ style: style, aiScore: aiScore }) },
    { label: 'AUM ($MM)', value: entityType === 'institution' ? format(aum) : inMillions(aum, 0) },
    { label: 'E AUM ($MM)', value: entityType === 'institution' ? format(equity_aum) : inMillions(equity_aum, 0) },
    { label: 'QR Score', value: quality_rating }
  ]

  return (
    <Card
      title='attributes'
      isLoading={isLoading}
      theme={THEMES.LIGHT}
      className={className}
    >
      <div className='attributes'>
        {attributes.map((attribute) => {
          return <AttributeValue key={attribute.label} {...attribute} isLoading={isLoading} />
        })}
      </div>
    </Card>
  )
}

AttributesComponent.propTypes = propTypes
AttributesComponent.defaultProps = defaultProps

export default memo(AttributesComponent)
