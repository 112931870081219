import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// actions
import { useHistoricalQuery, usePeerHistoricalQuery } from '../../hook'

// components
import { Card, EntityOwnershipChart, Select } from '../../../index'
import EntityOwnershipList from './list/list.component'
import Peer from './peer/peer.container'

// utils
import { getActiveTicker, getOwnershipType, ENTITY_TYPE, THEMES, DEFAULT_TICKER, isTradingTicker } from '../../../../utils'
import { get } from 'lodash'

import './entity.container.scss'

const { FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf([INSTITUTION, FUND]).isRequired,
  quarters: PropTypes.number,
  isHeader: PropTypes.bool,
  isPeer: PropTypes.bool
}

const defaultProps = {
  theme: THEMES.DARK,
  entityType: INSTITUTION,
  quarters: 5,
  isHeader: true,
  isPeerComponent: true
}

const VIEW = {
  CHART: 'chart',
  LIST: 'list'
}

const OPTIONS = [
  { label: 'Chart View', value: VIEW.CHART },
  { label: 'List View', value: VIEW.LIST }
]

/**
 * Entity Ownership Component
 * @param props
 */
function EntityOwnership (props) {
  const {
    dataId, theme, isEntityLoading, isHeader, isPeerComponent, tickerId, entityId, entityType,
    ticker: { symbol, exchange }, quarters, ownershipType
  } = props
  const { value = '13f' } = (ownershipType || {})

  const [view, setView] = useState(VIEW.CHART)
  const [peers, setPeers] = useState([])
  const [positions, setPositions] = useState([])
  const source = (entityType === FUND) ? FUND : 'inst'

  /**
   * Reset state on entityId change
   */
  useEffect(() => {
    if (entityId) {
      setView(VIEW.CHART)
      setPeers([])
      setPositions([])
    }
  }, [entityId, setView, setPeers, setPositions])

  const { loading, data } = useHistoricalQuery(entityType, {
    skip: !entityId || !isTradingTicker(tickerId),
    variables: { tickerId, entityId, quarters, source: value }
  })
  const [getPeer, { loading: isPeerLoading, variables: peerVariables, data: peer }] = usePeerHistoricalQuery(entityType, {
    skip: !entityId,
    variables: { tickerId, entityId, quarters }
  })

  /**
   * Handle update peers
   * @param peer
   */
  const handlePeerAdd = (peer) => {
    const { tickerId } = peer

    if (!tickerId) {
      return
    }

    setPeers([].concat(peers, peer))
    getPeer({ variables: { tickerId, entityId, quarters } })
  }

  /**
   * Update peers
   */
  useEffect(() => {
    const { tickerId } = (peerVariables || {})
    const isPeer = (peers || []).find((item) => item.tickerId === tickerId)
    const position = get(peer, `${source}HoldingHistorical.items[0]`)

    if (isPeer && position && !(positions || []).find((item) => item.tickerId === tickerId)) {
      setPositions([].concat(positions, { ...position, ...peers.find((item) => get(item, 'tickerId') === tickerId) }))
    }
  }, [source, peerVariables, peer, peers, positions])

  /**
   * Handle delete peers
   * @param peer
   */
  const handlePeerDelete = (peer) => {
    const { tickerId } = peer

    if (tickerId) {
      setPeers(peers.filter((item) => item && (item.tickerId !== tickerId)))
      setPositions(positions.filter((item) => item && (item.tickerId !== tickerId)))
    }
  }

  const position = get(data, `${source}HoldingHistorical.items[0]`)
  const isLoading = [isEntityLoading, loading, isPeerLoading].some((item) => !!item)

  return (
    <Card
      dataId={`${dataId}OwnershipCard`}
      theme={theme}
      className={`entity-ownership ${isHeader ? 'card--large' : 'entity-ownership--headless'}`}
      isLoading={isLoading}
      title={!!isHeader && 'Ownership'}
      headerActions={!!isHeader && [() => (
        <Select
          dataId={{
            inputId: `${dataId}OwnershipChartFilterInput`,
            menuId: `${dataId}OwnershipChartFilterMenu`
          }}
          theme={theme}
          size='thin'
          value={OPTIONS.find((option) => option && (option.value === view))}
          options={OPTIONS}
          onChange={(option) => option && setView(option.value)}
          searchable={false}
          clearable={false}
          disabled={isLoading}
        />
      )]}
    >
      <div className='entity-ownership_view'>
        <EntityOwnershipChart
          theme={theme}
          active={view === VIEW.CHART}
          entityId={entityId}
          data={{
            loading,
            position
          }}
          peers={positions}
          height={276}
        />
        <EntityOwnershipList
          active={view === VIEW.LIST}
          position={{ ...position, symbol, exchange }}
          quarters={quarters}
          peers={positions}
        />
      </div>
      {!!isPeerComponent && (
        <Peer
          theme={theme}
          dataId={`${dataId}OwnershipChart`}
          peers={peers}
          onPeerAdd={handlePeerAdd}
          onPeerDelete={handlePeerDelete}
        />
      )}
    </Card>
  )
}

EntityOwnership.propTypes = propTypes
EntityOwnership.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)

  return {
    ticker,
    tickerId: get(ticker, 'q4_ticker_id', DEFAULT_TICKER), // TODO: tmp default value for pre-IPO
    ownershipType: getOwnershipType(profile)
  }
}

export default connect(mapStateToProps)(EntityOwnership)
