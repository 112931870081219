import _ from 'lodash'
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getPipeline } from '../../../actions/widget/pipeline'
import { setClassNames } from '../../../utils/widget.util'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderDarkThumb, renderLightThumb, renderTrackVertical } from '../../../resources/theme/q4.custom-scrollbar'
import { Spinner } from '../../../components'
import WidgetError from '../../../components/widget/error/widgetError.component'
import PipelineChart from '../../../components/widget/pipeline/pipeline.component'
import './pipeline.container.css'
import { statusType } from '../../../actions/index'

class PipelineWidget extends Component {

  _fetchData = () => {
    this.props.getPipeline()
  }

  componentDidMount = () => {
    const { isEdit } = this.props
    const isFetched = (this.props.pipeline.status.pipeline === statusType.SUCCESS ||
      this.props.stock.status === statusType.SUCCESS)

    if (isEdit && isFetched) {
      return
    }

    const refreshInterval = this.props.options.refreshInterval
    if (refreshInterval) {
      this.timer = setInterval(this._fetchData.bind(this), refreshInterval)
    }

    this._fetchData()
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  checkForDeals = (pipeline) => {
    let hasDeals = false

    pipeline.lanes.forEach((lane) => {
      if (lane && lane.cards) {
        hasDeals = true
      }
    })

    return hasDeals
  }

  render () {
    const { stock, pipeline, layout, theme, history } = this.props
    const security = (stock && stock.data && stock.data.Security) || {}
    const classes = setClassNames('pipeline-widget', layout, theme)
    const currency = stock && stock.data && stock.data.Currency
    const hasDeals = this.checkForDeals(pipeline)
    const renderThumb = theme === 'dark' ? renderLightThumb : renderDarkThumb
    const is_loading = (this.props.pipeline.status.pipeline === statusType.IN_PROGRESS ||
      this.props.stock.status === statusType.IN_PROGRESS)

    if (!is_loading && !hasDeals) {
      return (
        <WidgetError
          theme={theme}
          header={'Pipeline'}
          linkText={'Add a deal'}
          linkUrl={`/pipeline`}
          message={`Looks like you don't have any active deals in your pipeline.`}
        />
      )
    }

    return (
      <div className={classes.base}>
        <header className='pipeline-widget_header'>
          <h2 className='pipeline-widget_heading' onClick={() => history.push('/pipeline')}>
            Pipeline
            <span className='pipeline-widget_security'>
                            {`${(security && security.Symbol) || ''} ${(security && security.Market) || ''}`}
                        </span>
          </h2>
        </header>
        <section className='pipeline-widget_items'>
          {is_loading && _.isEmpty(pipeline.open) ? <Spinner/> : (
            <Scrollbars
              className='react-scrollbar'
              autoHide
              hideTracksWhenNotNeeded
              renderThumbVertical={renderThumb}
              renderTrackVertical={renderTrackVertical}>
              <div className='pipeline-widget_chart-container'>
                <PipelineChart {...this.props}> </PipelineChart>
              </div>
            </Scrollbars>
          )}
        </section>
        <footer className='pipeline-widget_footer'>
          <p>{currency ? (currency === 'USD') ? 'In US dollars' : `In ${currency}` : ''}</p>
          <Link to={`/pipeline`}>View all</Link>
        </footer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pipeline: state.widget.pipeline.pipeline,
    stock: state.widget.pipeline.stock,
    theme: state.dashboard.dashboardState.theme
  }
}

const mapDispatchToProps = (dispatch) => ({
  getPipeline: bindActionCreators(getPipeline, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PipelineWidget))
