import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Banner } from '../../../components'
import ActivityGrid from './grid/grid.container'
import { ACTIVITY_TYPE_OPTIONS, ALL, ENTITY_TYPE } from '../../../utils'
import { GET_ACTIVITIES } from '../hook/activityQuery.hook'

// Export related
import { useExport } from '../../../services/csvExport/csvExport.service'
import client from '../../../middleware/graphql.middleware'
import { get, sortedUniq } from 'lodash'
import moment from 'moment-timezone'
import { modalType, openModal } from '../../../actions/ui'

const { INSTITUTION } = ENTITY_TYPE
const types = (ACTIVITY_TYPE_OPTIONS || [])
  .map((option) => option.value && option.value.toLowerCase())
  .filter((option) => option !== ALL)
const format = 'YYYY-MM'

/**
 * Get total number of months from YTD and previous year. Maximum of 2 years.
 * @return {Array}
 */
function getTotalMonths () {
  const months = []
  const today = moment()

  let startDate = moment().startOf('year').subtract(1, 'year')
  while (startDate.isBefore(today)) {
    months.push(startDate.format(format))
    startDate = startDate.add(1, 'month')
  }

  return months
}

/**
 * Create Empty Row and Institution Engagement columns
 * @param totalMonths
 * @return {*[]}
 */
function createRowBreak (totalMonths) {
  const months = {}
  totalMonths.forEach((month) => {
    months[month] = month
  })

  return [
    {},
    { 'Meeting Type': 'Institution Name', ...months }
  ]
}

/**
 * Get counts of institution activities and activity types, grouped by month
 * @param activity
 * @param index
 * @param activities
 * @return {*[]}
 */
function getActivityCounts (activity, index, activities) {
  if (!activities || !activities.length) {
    return
  }

  // Export middleware automatically begins mapping data on response
  // We only want to return one iteration
  if (index === 0) {
    const totalMonths = getTotalMonths()
    const rowBreak = createRowBreak(totalMonths)
    const activityCategories = []
    const activityInstitutions = []
    let uniqueInstitutions = []
    const categoryCounts = []
    const institutionCounts = []
    let monthCounts = {}

    activities.forEach((activity) => {
      activityCategories.push({
        category: activity.category,
        month: moment(new Date(Number(get(activity, 'start.date')))).format(format)
      })

      // Remove duplicate institution attendee links
      const institutions = (activity.links || []).filter((link, index, self) => {
        const { entityType } = link
        return (entityType && entityType.toLowerCase() === INSTITUTION) && (self.findIndex((item) => item.entityId === link.entityId) === index)
      })

      institutions.forEach((link) => {
        uniqueInstitutions.push(link.entityName)
        activityInstitutions.push({
          entityName: link.entityName,
          month: moment(new Date(Number(get(activity, 'start.date')))).format(format)
        })
      })
    })

    uniqueInstitutions = sortedUniq(uniqueInstitutions.sort())

    // Iterate through activity types and map monthly engagements
    types.forEach((category) => {
      totalMonths.forEach((month) => {
        monthCounts[month] = activityCategories.filter(activityCategory => (activityCategory.category === category && activityCategory.month === month)).length
      })
      categoryCounts.push({ 'Meeting Type': category, ...monthCounts })
      monthCounts = {}
    })

    // Iterate through institutions and map monthly engagements
    uniqueInstitutions.forEach((institution) => {
      totalMonths.forEach((month) => {
        monthCounts[month] = activityInstitutions.filter(activityInstitution => (activityInstitution.entityName === institution && activityInstitution.month === month)).length
      })
      institutionCounts.push({ 'Meeting Type': institution, ...monthCounts })
      monthCounts = {}
    })

    return [].concat(categoryCounts, rowBreak, institutionCounts)
  } else {
    return []
  }
}

/**
 * Handle Export
 * @param generate
 */
function handleExport (generate) {
  const params = {
    client,
    variables: { limit: 0 },
    query: GET_ACTIVITIES,
    dataPath: 'data.activity.items',
    fileName: 'activity_engagement.csv',
    formatter: getActivityCounts
  }

  generate(params)
}

/**
 * Activity Landing Page
 * @param props
 * @constructor
 */
function ActivityLandingPage (props) {
  const { openModal } = props
  const [isExportEnabled, enableExport] = useState(false)

  const { generateExport } = useExport({
    onError: () => {
      openModal({
        type: modalType.ERROR_MODAL
      })
    }
  })

  const controls = [
    {
      type: 'button',
      label: 'Export Engagement',
      disabled: !isExportEnabled,
      onClick: () => handleExport(generateExport)
    },
    {
      type: 'button',
      theme: 'square',
      icon: 'q4i-add-4pt',
      onClick: () => openModal({
        type: modalType.ACTIVITY_MODAL
      })
    }
  ]

  return (
    <div className='activity-page'>
      <Banner
        title='Activity'
        icon='q4i-activity-2pt'
        controls={controls}
      />

      <ActivityGrid
        setExportState={enableExport}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

export default connect(null, mapDispatchToProps)(ActivityLandingPage)
