import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import {
  getDefaultEntityTypeFields,
  getFilteredAvailableFields,
  getIsFieldWithFilter,
  getIconClass,
  getIsReportType
} from '../../../../utils/report'
import { THEMES } from '../../../../utils/ui'
import { ComboBox, RemovableList } from '../../../../components'
import './reportFields.component.css'

/**
 * ReportFieldLabel Component
 * @param field
 * @param isFilter
 * @returns {*}
 */
function ReportFieldLabel ({ field, isFilter }) {
  return (
    <span className='report-fields_label'>
      {field.label}
      {isFilter && (
        <span className='report-fields_indicator'>
          <i className='q4i-funnel-filter-4pt' />
        </span>
      )}
    </span>
  )
}

/**
 * ReportFieldGroup Component
 * @param isPrimary
 * @param entityType
 * @param fields
 * @param availableFields
 * @param filters
 * @param onFieldAdd
 * @param onFieldRemove
 * @returns {*}
 */
function ReportFieldGroup ({ isPrimary, entityType, fields, availableFields, filters, onFieldAdd, onFieldRemove }) {
  const [_availableFields, setAvailableFields] = useState([])
  const [query, setQuery] = useState()

  const fieldGroup = getFieldGroup(entityType, fields, filters)
  const removeMessageProps = {
    title: 'Discard Column?',
    message: 'This column has a filter assigned to it. Discarding this column will also discard the filter. Are you sure?'
  }

  useEffect(() => {
    setAvailableFields(getFilteredAvailableFields(availableFields, entityType, query))
  }, [availableFields, entityType, query])

  /**
   * Reduction function to group holdings and non-holdings fields
   * @param entityType
   * @param fields
   * @param filters
   * @returns {({quarterlyFields, fields}|*)|{quarterlyFields: Array, fields: Array}}
   */
  function getFieldGroup (entityType, fields, filters) {
    const fieldGroup = { fields: [], quarterlyFields: [] }

    if (!fields || !fields.length || !entityType) {
      return fieldGroup
    }

    return (fields || []).reduce((groups, field) => {
      if (field._entityType._id !== entityType._id) {
        return groups
      }

      const isQuarterly = field.name.indexOf('holdings.') >= 0
      const isFilter = getIsFieldWithFilter(field, filters)

      groups[isQuarterly ? 'quarterlyFields' : 'fields'].push({
        value: field._id,
        label: (
          <ReportFieldLabel
            field={field}
            isFilter={isFilter}
          />
        ),
        isLocked: !isQuarterly && getDefaultEntityTypeFields(entityType).includes(field.name),
        shouldWarnOnRemove: isFilter
      })

      return groups
    }, fieldGroup)
  }

  /**
   * Get label values for Combobox
   * @param fields
   * @return {(*|{label: *, value: *})[]}
   */
  function getFieldLabels (fields) {
    return [].concat(fields || [])
      .map((field) => field && { label: field.label, value: field })
  }

  /**
   * Handle selection change
   * @param options
   */
  function handleChange (options) {
    const values = [].concat(options || []).map((option) => option && option.value)
    onFieldAdd(values)
  }

  return (
    <div className='report-fields_group'>
      <header className='report-fields_header'>
        <h3 className='report-fields_group-title'>
          <span className={`report-fields_group-icon report-fields_group-icon--${entityType.name}`}>
            <i className={getIconClass(entityType.name)} />
          </span>
          {entityType.label}
        </h3>
      </header>
      <section className='report-fields_search'>
        <ComboBox
          selectProps={{
            placeholder: 'Add a field',
            theme: THEMES.WHITE,
            size: 'thin',
            maxHeight: 150,
            value: query,
            options: getFieldLabels(_availableFields),
            onInputChange: (query) => setQuery(query),
            onChange: handleChange.bind(this),
            isMulti: true,
            closeMenuOnSelect: false,
            showDropdownIndicator: false
          }}
          removableListProps={{
            theme: THEMES.WHITE,
            size: 'small',
            items: fieldGroup.fields,
            removeMessageProps,
            onRemove: onFieldRemove
          }}
        />

        {fieldGroup.quarterlyFields.length > 0 && (
          <div className='report-fields_sub-group'>
            <h4 className='report-fields_sub-group-title'>Quarterly Fields</h4>
            <RemovableList
              theme={THEMES.LIGHT_GREY}
              size='small'
              items={fieldGroup.quarterlyFields}
              removeMessageProps={removeMessageProps}
              onRemove={onFieldRemove}
            />
          </div>
        )}
      </section>
    </div>
  )
}

/**
 * ReportFields Component
 * @param entityType
 * @param fields
 * @param availableFields
 * @param filters
 * @param onFieldAdd
 * @param onFieldRemove
 * @returns {*}
 */
function ReportFields ({ entityType, fields, availableFields, filters, onFieldAdd, onFieldRemove }) {
  return (
    <section className='report-fields'>
      {(entityType || []).map((type) => {
        return (
          <ReportFieldGroup
            key={`report-fields_group--${type._id}`}
            isPrimary={getIsReportType(entityType, type.name)}
            entityType={type}
            fields={fields}
            filters={filters}
            availableFields={availableFields}
            onFieldAdd={onFieldAdd}
            onFieldRemove={onFieldRemove}
          />
        )
      })}
    </section>
  )
}

ReportFields.propTypes = {
  entityType: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  availableFields: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  onFieldAdd: PropTypes.func.isRequired,
  onFieldRemove: PropTypes.func.isRequired
}

ReportFields.defaultProps = {
  entityType: [],
  fields: [],
  availableFields: [],
  filters: []
}

export default memo(ReportFields)
