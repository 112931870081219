import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Card, ReadMore } from '../../../index'
import { THEMES } from '../../../../utils'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  isLoading: PropTypes.bool.isRequired,
  bio: PropTypes.string

}

const defaultProps = {
  isLoading: false,
  bio: ''
}

/**
 * Biography Component
 * @param props
 */
function Biography ({ dataId, theme, isLoading, bio }) {
  return (
    <Card
      dataId={`${dataId}BiographyCard`}
      theme={theme}
      isLoading={isLoading}
      title='Biography'
    >
      <div className='card_section-detail-text'>
        {(bio && bio.length)
          ? <ReadMore
            lines={5}
            theme={theme}
            text={bio.replace(/\\r|\\n/g, '<br>')}
            renderHTML />
          : <span className='card_section-detail-placeholder'>No data available</span>}
      </div>
    </Card>
  )
}

Biography.propTypes = propTypes
Biography.defaultProps = defaultProps

export default memo(Biography)
