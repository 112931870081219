import {
  FETCH_CONSENSUS_DETAILS_REQUEST,
  FETCH_CONSENSUS_DETAILS_SUCCESS,
  FETCH_CONSENSUS_DETAILS_ERROR,
  RESET_ESTIMATES,
  FETCHED,
  FETCHING,
  IDLE,
  FAILED
} from '../../actions/estimates'

const initialState = {
  status: IDLE
}

const consensusDetails = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ESTIMATES:
      const _initial = { ...state }

      const exclude = [...Object.keys(initialState), ...(action.exclude || [])]
      Object.keys(state).forEach((key) => {
        if (exclude.indexOf(key) < 0) {
          delete _initial[key]
        }
      })

      return _initial

    case FETCH_CONSENSUS_DETAILS_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_CONSENSUS_DETAILS_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        [action.payload._security]: {
          ...state[action.payload._security],
          [action.payload.metric]: action.payload
        }
      }

    case FETCH_CONSENSUS_DETAILS_ERROR:
      return {
        ...initialState,
        status: FAILED,
        error: action.error
      }
    default:
      return state
  }
}

export default consensusDetails
