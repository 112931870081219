import React, { PureComponent } from 'react'
import Grid from '@material-ui/core/Grid'
import { TextInput } from '../../../shared'

class CorporateParticipantForm extends PureComponent {
  /**
     * Render
     * @return {XML}
     */
  render () {
    const {
      visible,
      values,
      handleChange,
      errors
    } = this.props

    if (!visible) {
      return null
    }

    return (
      <Grid container spacing={3}>

        {/* Column 1 */}
        <Grid item xs={3}>
          <div className='field field--text field--full'>
            <label className='field_label' htmlFor='firstName'>First Name <span className='required'>(required)</span></label>
            <TextInput
              id='firstName'
              error={errors.firstName}
              value={values.firstName}
              onChange={handleChange}
              placeholder='Jane'
              autoFocus
            />
          </div>

          <div className='field field--text field--full'>
            <label className='field_label' htmlFor='nickName'>Nickname</label>
            <TextInput
              id='nickName'
              value={values.nickName}
              placeholder='Plain Jane'
              onChange={handleChange}
            />
          </div>

          <div className='field field--text field--full'>
            <label className='field_label' htmlFor='phone'>Direct Phone</label>
            <TextInput
              id='phone'
              value={values.phone}
              placeholder='xxx-xxx-xxxx'
              onChange={handleChange}
            />
          </div>

          <div className='field field--text field--full'>
            <label className='field_label' htmlFor='email'>Email</label>
            <TextInput
              id='email'
              error={errors.email}
              errorMessage={errors.email}
              value={values.email}
              placeholder='janesmith@email.com'
              onChange={handleChange}
            />
          </div>
        </Grid>

        {/* Column 2 */}
        <Grid item xs={3}>
          <div className='field field--text field--full'>
            <label className='field_label' htmlFor='lastName'>Last Name <span className='required'>(required)</span></label>
            <TextInput
              id='lastName'
              error={errors.lastName}
              value={values.lastName}
              placeholder='Smith'
              onChange={handleChange}
            />
          </div>

          <div className='field field--text field--full'>
            <label className='field_label' htmlFor='jobTitle'>Job Title</label>
            <TextInput
              id='jobTitle'
              value={values.jobTitle}
              placeholder='CEO, Director, Analyst'
              onChange={handleChange}
            />
          </div>

          <div className='field field--text field--full'>
            <label className='field_label' htmlFor='mobile'>Mobile Phone</label>
            <TextInput
              id='mobile'
              value={values.mobile}
              placeholder='xxx-xxx-xxxx'
              onChange={handleChange}
            />
          </div>

        </Grid>

      </Grid>

    )
  }
}

export default CorporateParticipantForm
