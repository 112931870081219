import _ from 'lodash';
import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {FETCHED, FETCHING, fetchUpcomingActivities} from '../../../../actions/widget/activity/upcomingActivity.actions';
import {setClassNames} from '../../../../utils/widget.util';
import moment from 'moment-timezone';
import { Spinner } from '../../../../components';
import WidgetError from '../../../../components/widget/error/widgetError.component';
import UpcomingActivitiesList from '../../../../components/widget/activity/upcomingActivitiesList/upcomingActivitiesList.component';
import {statusType} from '../../../../actions';
import { guessTimeZone } from '../../../../utils'

class UpcomingActivities extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showCalendar: this.props.options.showCalendar
        };
    }


    _fetchData = () => {
        const startDate = moment.utc().format('MM-DD-YYYY hh:mm:ss a');
        this.loadActivities(startDate);
    };

    componentDidMount = () => {
        const {isEdit, upcomingActivitiesStatus, currentActivitiesStatus} = this.props;
        const isFetched = upcomingActivitiesStatus === FETCHED && currentActivitiesStatus === FETCHED;

        if (isEdit && isFetched) {
            return;
        }

        const refreshInterval = this.props.options.refreshInterval;
        if (!this.state.showCalendar && refreshInterval) {
            this.timer = setInterval(this._fetchData.bind(this), refreshInterval);
            this._fetchData();
        }
    };

    componentWillUnmount = () => {
        if (this.timer) {
            clearInterval(this.timer);
        }
    };

    componentDidUpdate = (prevProps) => {
        const {activityForm} = this.props;

        if (prevProps.activityForm !== activityForm && activityForm.status === statusType.SUCCESS) {
            this._fetchData();
        }
    }

    loadActivities(startDate) {
        if (!startDate) {
            return;
        }

        const params = {
            category: [
                'comment', 'phone', 'email', 'meeting', 'earnings', 'roadshow', 'conference'
            ],
            startDate,
            limit: 50,
            currentAndUpcomingActivity: true,
            sort: JSON.stringify([
                {
                    property: 'start.date_time',
                    direction: 'ASC'
                }
            ])
        };

        this.props.fetchUpcomingActivities(params);
    }

    render() {
        let activities;
        let {upcomingActivitiesStatus, currentActivitiesStatus, theme, layout, classes, options, currentActivityDate} = this.props;
        const is_loading = upcomingActivitiesStatus === FETCHING || currentActivitiesStatus === FETCHING;

        if (options.showCalendar) {
            activities = this.props.currentActivities;
            activities = _.filter(activities, (activity) => {

                const timezone = activity.start.timezone || activity.end.timezone || guessTimeZone()
                const startDate = activity && activity.start && moment.utc(activity.start.date_time).tz(timezone);
                const endDate = activity && activity.end && moment.utc(activity.end.date_time).tz(timezone);
                const currentActivityDateTz = moment(currentActivityDate).tz(timezone);

                return currentActivityDateTz.isSame(startDate, 'day')
                    || currentActivityDateTz.isSame(endDate, 'day')
                    || (startDate.isSameOrBefore(currentActivityDateTz) && endDate.isSameOrAfter(currentActivityDateTz));
            });
        } else {
            activities = this.props.upcomingActivities;
        }

        if (!classes) {
            classes = setClassNames('upcoming-activities', layout, theme);
        }

        if (!is_loading && _.isEmpty(activities)) {
            return (
                <WidgetError
                    theme={theme}
                    header={'Activity List'}
                    message={'There is no activity scheduled.'}
                />
            );
        }

        return (
            <div className={classes.base}>
                {_.isEmpty(activities) ?
                    <Spinner/> :
                    <UpcomingActivitiesList {...this.props} activities={activities}/>}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const activity = state.widget.activity;
    return {
        upcomingActivities: ((activity && activity.upcomingActivities && activity.upcomingActivities.activities) || []),
        upcomingActivitiesStatus: activity.upcomingActivities.status,
        currentActivities: ((activity && activity.currentActivities && activity.currentActivities.activities) || []),
        currentActivityDate: ((activity && activity.currentActivities.currentActivityDate)),
        currentActivitiesStatus: activity.currentActivities.status,
        theme: state.dashboard.dashboardState.theme,
        activityForm: state.activity.activityForm,
        ...ownProps
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchUpcomingActivities: bindActionCreators(fetchUpcomingActivities, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingActivities);
