import {
  DOWNLOAD_EXPORT_REQUEST,
  DOWNLOAD_EXPORT_SUCCESS,
  DOWNLOAD_EXPORT_ERROR,
  statusType
} from '../../actions'

const initial = {
  status: statusType.IDLE
}

const exportContacts = (state = initial, action) => {
  switch (action.type) {
    case DOWNLOAD_EXPORT_REQUEST:
      return {
        status: statusType.IN_PROGRESS
      }

    case DOWNLOAD_EXPORT_SUCCESS:
      return {
        status: statusType.SUCCESS
      }

    case DOWNLOAD_EXPORT_ERROR:
      return {
        status: statusType.ERROR
      }

    default:
      return state
  }
}

export default exportContacts
