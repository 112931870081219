import React, { useState, useRef, memo } from 'react'
import PropTypes from 'prop-types'

// components
import { Button, Select } from '../index'

// utils
import { getClassName, getFromXigniteToStandard } from '../../utils'
import { get } from 'lodash'

import './peerInput.component.scss'

const propTypes = {
  theme: PropTypes.string,
  isSearching: PropTypes.bool.isRequired,
  suggestions: PropTypes.array.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
}

const defaultProps = {
  isSearching: false,
  suggestions: []
}

/**
 * Normalize suggestions
 * @param suggestions
 */
function getOptions (suggestions) {
  return (suggestions || []).map(
    ({ q4_ticker_id: tickerId, q4_entity_id: entityId, symbol, company_name: companyName, exchange, isSaved, mongo_id: legacySecurityId }) =>
      ({
        value: tickerId,
        entityId,
        symbol,
        exchange,
        companyName,
        isSaved,
        legacySecurityId
      })
  )
}

/**
 * Options UI template
 * @param symbol
 * @param companyName
 * @param exchange
 * @param isSaved
 */
const formatOptionLabel = ({ symbol, companyName, exchange, isSaved }) => (
  <div className='peer-option__container'>
    <div>
      <div className='peer-option__group'>
        <span className='peer-option__symbol'>{symbol}</span>
        <span className='peer-option__exchange'>{getFromXigniteToStandard(exchange)}</span>
      </div>
      <div className='peer-option__group'>
        <span className='peer-option__companyName'>{companyName}</span>
      </div>
    </div>
    <div>
      <i className={`peer-option__indicator ${isSaved ? 'q4i-checkmark-2pt' : 'q4i-add-4pt'}`} />
    </div>
  </div>
)

/**
 * Peer Input Component
 * @param props
 */
function PeerInputComponent (props) {
  const { theme, isSearching, suggestions, onSearch, onSelect } = props
  const [state, setState] = useState({ isInput: false, inputValue: '', searchValue: null })
  const [cleared, setCleared] = useState(false)
  const { isInput, inputValue, searchValue } = state

  const options = !!inputValue ? getOptions(suggestions) : []
  const selectReference = useRef()
  const select = get(selectReference, 'current.select')

  /**
   * Handle suggestion select
   * Called every time suggestion is selected via mouse or keyboard
   * @param option
   * @param action
   */
  const handleSelectionChange = (option, { action }) => {
    const { symbol } = (option || {})

    symbol && onSelect(option)
    
    // modify the clear action to also remove the inputValue
    if (action === 'clear') {
      setCleared(true)
    }
    if (action === 'select-option' || action === 'clear') {
      setState({ ...state, inputValue: '', searchValue: null })
    }
  }

  /**
   * Handle add new peer button click
   */
  const handleAddClick = () => {
    const input = select && select.inputRef

    setState({ ...state, isInput: true })
    input && input.focus()
  }

  /**
   * Handle key down event
   * @param event
   */
  const handleKeyDown = (event) => {
    const { key } = (event || {})

    if (key === 'Enter') {
      event.preventDefault()
    }
  }

  /**
   * Handle input change
   * Called every time input value is changed
   * @param query
   * @param action
   */
  const handleInputChange = (query, { action }) => {
    if (action === 'input-change') {
      setState({
        ...state,
        inputValue: query,
        searchValue: query ? { value: query, label: query } : null // setup a fake value to enable the clear input feature
      })
    }
    onSearch(query)
  }

  /**
   * Handle blur event
   */
  const handleBlur = () => {
    if (!cleared && !inputValue) {
      setState({ ...state, isInput: false })
    }
    setCleared(false)
  }

  return (
    <>
      <div
        className={getClassName('peer-input', [
          { condition: theme, trueClassName: `peer-input--${theme}` }
        ])}
      >
        <Button
          className={getClassName('peer-input_add', [
            { condition: isInput, trueClassName: 'peer-input_add--hidden' },
            { condition: theme, trueClassName: `peer-input_add--${theme}` }
          ])}
          icon='q4i-add-4pt'
          onClick={handleAddClick}
          tooltip='ADD'
        />

        <div className={getClassName('peer-input_search', [
          { condition: isInput, trueClassName: 'peer-input_search--expanded' }])}
        >
          <Select
            size='thin'
            theme={theme}
            selectReference={selectReference}
            placeholder='Add a security or symbol'
            loading={isSearching}
            value={searchValue}
            inputValue={inputValue}
            options={options}
            isOptionDisabled={(option) => option.isSaved}
            formatOptionLabel={formatOptionLabel}
            filterOption={() => true} // disable the default search filter
            onInputChange={handleInputChange}
            isClearable={!!inputValue}
            onChange={handleSelectionChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            showDropdownIndicator={false}
            maxMenuHeight={342}
          />
        </div>
      </div>
    </>
  )
}

PeerInputComponent.propTypes = propTypes
PeerInputComponent.defaultProps = defaultProps

export default memo(PeerInputComponent)
