import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Message } from '../../index'
import { getClassName, MESSAGE_TYPE, THEMES } from '../../../utils'

import './score.component.scss'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.shape({
    title: PropTypes.string,
    score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    message: PropTypes.string,
    list: PropTypes.object
  }).isRequired,
  openModal: PropTypes.func.isRequired
}

const defaultProps = {
  theme: THEMES.DARK,
  loading: false
}

/**
 * Score Message Modal
 * @param props
 * @returns {*}
 * @constructor
 */
const MessageModal = ({ onClose, title, message, list }) => {
  return (
    <Message
      visible
      type={MESSAGE_TYPE.INFO}
      title={title}
      message={`<p>${message}</p>`}
      list={list}
      onClose={onClose}
      useHtml
      renderExitButton
      buttons={[{
        ui: 'citrus',
        label: 'ok',
        onClick: onClose
      }]}
    />
  )
}

/**
 * Open Message Message
 * @param title
 * @param message
 * @param list
 * @param openModal
 */
function handleClick ({ title, message, list, openModal }) {
  openModal({
    component: ({ onClose }) => MessageModal({ onClose, title, message, list })
  })
}

/**
 * Entity Score Component
 * @param props
 */
function EntityScore (props) {
  const { dataId, theme, loading, data, openModal } = props
  const { title, score, message, list } = data

  return (
    <div
      data-id={dataId}
      className={getClassName('entity-score', [
        { condition: theme, trueClassName: `entity-score--${theme}` }
      ])}
      onClick={() => !loading && handleClick({ title, message, list, openModal })}
    >
      <div className='entity-score_title'>{title}</div>
      <i className='entity-score_message q4i-info-fill' />
      <div className='entity-score_value'>{score || '-'}</div>
    </div>
  )
}

EntityScore.propTypes = propTypes
EntityScore.defaultProps = defaultProps

export default memo(EntityScore)
