import React, {Component} from 'react';
import ReactHighcharts from 'react-highcharts';
import {darkSlate, citrus, teal, spice, silver, softGrey, white, paleGrey} from '../../../../resources/materialui-overrides/colors';
import {format} from '../../../../utils/number.util'
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

class BrokerDetailsChart extends Component {

    getChart = () => {
        const {theme} = this.props;
        const borderColor = theme === 'dark' ? darkSlate : softGrey;

        return {
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                spacing: [20, 0, 20, 0]
            },
            title: {
                text: null
            },
            tooltip: {
                enabled: true,
                useHtml: true,
                borderWidth: 0,
                formatter: function() {
                    const analyst = (this.point && this.point && this.point.analyst) || 'Restricted';
                    const value = (this.point.y > 0) ? '+' + format(this.point.y, 2) : format(this.point.y, 2);
                    const color = (this.point.y >= 0) ? teal : spice;

                    if (this.series.name === 'Mean') {
                        return false;
                    }

                    return [
                        '<div class="broker-detail-tooltip">',
                        '<span class="name">' + analyst + '</span><br>',
                        '<span class="value"><span style="color: ' + color + ';">' + value + '</span> from mean</span>',
                        '</div>'
                    ].join('');
                }
            },
            plotOptions: {
                column : {
                    minPointLength: 1,
                    maxPointWidth: 50,
                    dataLabels : {
                        enabled : true,
                        crop: false,
                        overflow: 'none',
                        inside: false,
                        style: {
                            color: theme === 'dark' ? white : darkSlate,
                            fontSize: '13px',
                            fontWeight: '300',
                            fontFamily: 'Open Sans, Sans-serif'
                        },
                        formatter: function() {
                            const first = this.series.data[0];
                            const last  = this.series.data[this.series.data.length - 1];

                            if ((this.point.category === first.category && this.point.y === first.y) ||
                                (this.point.category === last.category  && this.point.y === last.y)) {
                                return format(this.point.y,2);
                            }
                            return "";
                        }
                    },
                },
            },
            legend: {
                enabled: false
            },
            xAxis: {
                visible: false
            },
            yAxis: {
                title: {
                    enabled: false
                },
                labels: {
                    enabled: false,
                    style: {
                        color: silver,
                        fontSize: '13px',
                        fontFamily: 'Open Sans, Sans-serif'
                    }
                },
                gridLineColor: borderColor,
                minorGridLineWidth: 1,
                minorGridLineColor: borderColor,
                minorTicks: true,
                opposite: true

            },
            credits: {
                enabled: false
            },
            series: this.getSeries()
        };
    };


    getBrokerMean = (metric) => {
        const {consensus, selectedData} = this.props;
        const {date, frequency} = selectedData;

        return ((consensus.find((each) => {
            return (each.item || '').toLowerCase() === metric;
        }) || {}).data || []).find((each) => {
            return (
                (each._item || '').toLowerCase() === metric) &&
                (moment.utc(each.date).format('YYYY-MM-DD') === date) &&
                (each.frequency === frequency);
        });

    };

    getSeriesData = () => {
        const {broker, selectedData} = this.props;
        const currentMetric = selectedData.metric || 'eps';
        const mean = (this.getBrokerMean(currentMetric) || {}).value || 0;
        const brokerEstimates = (broker && broker[currentMetric] && broker[currentMetric].estimates) || [];

        const estimatesData = brokerEstimates.map((item) => {
            return {
                type: 'est',
                analyst: item.analyst,
                analyst_id: item.analyst_id,
                y: item.quarter_est - mean
            };
        });

        estimatesData.push({
            type: 'mean',
            mean,
            y: 0
        });

        const sortedEstimates = estimatesData.sort((a, b) => {
            return a.y - b.y;
        });

        return {
            est: sortedEstimates.map((item) => item.type === 'est' ? item : null),
            mean: sortedEstimates.map((item) => item.type === 'mean' ? item : null),
        };
    };

    getSeries = () => {
        const {theme} = this.props;
        const seriesData = this.getSeriesData();

        return [{
            animation: false,
            name: 'Estimates',
            color: teal,
            negativeColor: spice,
            borderWidth: 0,
            data: seriesData.est
        }, {
            animation: false,
            name: 'Mean',
            type: 'scatter',
            states: {
                hover: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: true,
                padding: 10,
                inside: false,
                style: {
                    color: theme === 'dark' ? white : darkSlate,
                    fontSize: '13px',
                    fontWeight: '300',
                    fontFamily: 'Open Sans, Sans-serif'
                },
                formatter: function() {
                    return `<span style="color:${theme === 'dark' ? citrus : paleGrey};">MEAN</span><br>${format(this.point.mean, 2)}`;
                },
                y: 0
            },
            color: citrus,
            data: seriesData.mean
        }];
    };

    render() {
        return (
            <ReactHighcharts
                className='broker-details-widget_chart' ref='broker-details'
                config={this.getChart()}>
            </ReactHighcharts>
        );
    }
}

BrokerDetailsChart.propTypes = {
    broker: PropTypes.object.isRequired,
    consensus: PropTypes.array.isRequired,
    theme: PropTypes.string.isRequired
};

export default BrokerDetailsChart;