import {
  SET_DEFAULT_ESTIMATE_METRIC,
  SET_DEFAULT_ESTIMATE_TAB,
  ESTIMATE_PEER_CHANGED,
  RESET_ESTIMATES
} from '../../actions/estimates'

const initialState = {
  defaultTab: 'estimates-consensus'
}

const state = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ESTIMATES:
      const _state = { ...initialState }
      delete _state.metric
      return _state
    case ESTIMATE_PEER_CHANGED:
      const peerChangedState = {
        ...initialState
      }

      if (action.payload) {
        peerChangedState.metric = 'eps'
      }
      return peerChangedState
    case SET_DEFAULT_ESTIMATE_METRIC:
      return {
        ...state,
        metric: action.defaultMetric
      }
    case SET_DEFAULT_ESTIMATE_TAB:
      return {
        ...state,
        defaultTab: action.defaultTab
      }
    default:
      return state
  }
}

export default state
