import {
  SEND_MAIL_REQUEST,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_ERROR,
  MAIL_REQUEST,
  MAIL_SUCCESS,
  MAIL_ERROR,
  IDLE,
  SENDING,
  SENT,
  FAILED
} from '../../actions/shared/index'
import { statusType } from '../../actions'

const mailReducer = (state = { status: IDLE }, { type, payload, error }) => {
  switch (type) {
    case SEND_MAIL_REQUEST:
      return {
        ...state,
        status: SENDING
      }
    case SEND_MAIL_SUCCESS:
      return {
        ...state,
        ...payload,
        status: SENT
      }
    case SEND_MAIL_ERROR:
      return {
        ...state,
        error: error,
        status: FAILED
      }

    case MAIL_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }
    case MAIL_SUCCESS:
      return {
        ...state,
        ...payload,
        status: statusType.SUCCESS
      }
    case MAIL_ERROR:
      return {
        ...state,
        error: error,
        status: statusType.ERROR
      }
    default:
      return state
  }
}

export default mailReducer
