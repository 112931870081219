export const PROFILE_REGIONS = {
  'NORTH_AMERICA': 'north_america',
  'SOUTH_AMERICA': 'south_america',
  'EUROPE': 'europe',
  'ASIA': 'asia',
  'MIDDLE_EAST': 'middle_east',
}

export const TRIAL_DAYS_UNTIL_EXPIRY = 'trial_days_until_expiry'

export const DEFAULT_TICKER = 'NonTrading'

export const DEFAULT_Q4_ENTITY_ID = '1234567890'
