import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { RangeTab, SectionSearch } from '../../../../../shared'
import { Button, Toolbar, ToolbarRow } from '../../../../../index'

// utils
import { HOLDER, THEMES } from '../../../../../../utils'

const propTypes = {
  dataId: PropTypes.string.isRequired,
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  noData: PropTypes.bool,
  search: PropTypes.string,
  holderType: PropTypes.string.isRequired,
  onSidebarToggle: PropTypes.func.isRequired,
  onHolderChange: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired
}

const defaultProps = {
  toolbarTheme: THEMES.Q4_BLUE,
  toolTheme: THEMES.INK
}

/**
 * Current Holder Toolbar Component
 * @param props
 */
function CurrentHolderToolbar (props) {
  const {
    dataId, toolbarTheme, toolTheme, noData, holderType, search,
    onSidebarToggle, onHolderChange, onQueryChange, onExport, exporting, collapsed
  } = props
  const handleSearchChange = (search) => onQueryChange({ search })

  return (
    <Toolbar theme={toolbarTheme}>
      <ToolbarRow justified>
        <div className='toolbar_group toolbar_group--spaced'>
          <Button
            active={collapsed}
            theme={toolTheme}
            label='Filter'
            icon='q4i-funnel-filter-4pt'
            onClick={onSidebarToggle}
          />
          <RangeTab
            theme={toolTheme}
            buttons={HOLDER.map((option) => ({
              ...option,
              dataId: `${dataId}${option.dataId}`,
              active: holderType === option.value,
              onClick: (event, value) => onHolderChange(value)
            }))}
          />
        </div>
        <div className='toolbar_group'>
          <SectionSearch
            dataId={`${dataId}Search`}
            theme={toolTheme}
            value={search}
            onQueryChange={handleSearchChange}
            onClear={handleSearchChange}
          />
          <Button
            dataId={`${dataId}Export`}
            theme={toolTheme}
            loading={exporting}
            icon='q4i-download-4pt'
            label='Export'
            disabled={noData}
            onClick={onExport}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

CurrentHolderToolbar.propTypes = propTypes
CurrentHolderToolbar.defaultProps = defaultProps

export default memo(CurrentHolderToolbar)
