import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

export const GET_FUND_CURRENT_FOR_INSTITUTION_REQUEST = 'GET_FUND_CURRENT_FOR_INSTITUTION_REQUEST'
export const GET_FUND_CURRENT_FOR_INSTITUTION_SUCCESS = 'GET_FUND_CURRENT_FOR_INSTITUTION_SUCCESS'
export const GET_FUND_CURRENT_FOR_INSTITUTION_FAILURE = 'GET_FUND_CURRENT_FOR_INSTITUTION_FAILURE'

/**
 * Get Fund Current for Institution
 * @param factsetEntityId
 * @param params
 * @private
 */
const _fetchFundsForInstitution = (factsetEntityId, params) => ({
  [CALL_API]: {
    types: [
      GET_FUND_CURRENT_FOR_INSTITUTION_REQUEST,
      GET_FUND_CURRENT_FOR_INSTITUTION_SUCCESS,
      GET_FUND_CURRENT_FOR_INSTITUTION_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/ownership/v2/fund/${factsetEntityId}/current`,
    payload: params
  }
})

/**
 * Get Fund Current for Institution
 * @param factsetEntityId
 * @param params
 */
export const fetchFundsForInstitution = (factsetEntityId, params) => (dispatch) => {
  return dispatch(_fetchFundsForInstitution(factsetEntityId, params))
}
