import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { Toolbar, ToolbarRow } from '../../../../index'
import { THEMES } from '../../../../../utils'
import { RangeTab } from '../../../../shared'

const propTypes = {
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  tabs: PropTypes.object.isRequired,
  onTabChange: PropTypes.func.isRequired
}

const defaultProps = {
  toolbarTheme: THEMES.Q4_BLUE,
  toolTheme: THEMES.INK
}

const TABS = [
  { label: 'Coverage List', value: 'coverage' },
  { label: 'Historical Estimate', value: 'estimate' }
]

/**
 * Contact Coverage Toolbar Component
 * @param props
 */
function ContactCoverageToolbar (props) {
  const { toolbarTheme, toolTheme, tabs, onTabChange } = props

  const handleChange = (event, value) => {
    Object.keys(tabs).forEach((option) => { tabs[option] = (option === value) })
    return onTabChange(tabs)
  }

  return (
    <Toolbar theme={toolbarTheme}>
      <ToolbarRow justified>
        <div className='toolbar_group'>
          <RangeTab
            theme={toolTheme}
            buttons={TABS.map((option) => ({
              ...option,
              active: tabs[option.value],
              onClick: handleChange
            }))}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

ContactCoverageToolbar.propTypes = propTypes
ContactCoverageToolbar.defaultProps = defaultProps

export default memo(ContactCoverageToolbar)
