import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

export const GET_INSTITUTION_PURCHASING_POWER_REQUEST = 'GET_INSTITUTION_PURCHASING_POWER_REQUEST'
export const GET_INSTITUTION_PURCHASING_POWER_SUCCESS = 'GET_INSTITUTION_PURCHASING_POWER_SUCCESS'
export const GET_INSTITUTION_PURCHASING_POWER_FAILURE = 'GET_INSTITUTION_PURCHASING_POWER_FAILURE'
export const RESET_INSTITUTION_PURCHASING_POWER = 'RESET_INSTITUTION_PURCHASING_POWER'

/**
 * Get Purchasing Power
 * @param factsetEntityId
 * @param securityId
 * @param options
 * @private
 */
const _fetchInstitutionPurchasingPower = ({ factsetEntityId, securityId }, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_INSTITUTION_PURCHASING_POWER_REQUEST,
      GET_INSTITUTION_PURCHASING_POWER_SUCCESS,
      GET_INSTITUTION_PURCHASING_POWER_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/purchasingpower/institution/${factsetEntityId}`,
    payload: {
      securityId
    }
  }
})

/**
 * Get Purchasing Power
 * @param params
 * @param options
 */
export const fetchInstitutionPurchasingPower = (params, options) => (dispatch) => {
  return dispatch(_fetchInstitutionPurchasingPower(params, options))
}

/**
 * Clean Purchasing Power
 * @return {function(*): *}
 */
export const cleanInstitutionPurchasingPower = () => (dispatch) => {
  return dispatch({ type: RESET_INSTITUTION_PURCHASING_POWER })
}
