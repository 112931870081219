import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const GET_REPORT_DATA_LIST_REQUEST = 'GET_REPORT_DATA_LIST_REQUEST'
export const GET_REPORT_DATA_LIST_SUCCESS = 'GET_REPORT_DATA_LIST_SUCCESS'
export const GET_REPORT_DATA_LIST_ERROR = 'GET_REPORT_DATA_LIST_ERROR'

export const GET_REPORT_CUSTOM_TEMPLATES_REQUEST = 'GET_REPORT_CUSTOM_TEMPLATES_REQUEST'
export const GET_REPORT_CUSTOM_TEMPLATES_SUCCESS = 'GET_REPORT_CUSTOM_TEMPLATES_SUCCESS'
export const GET_REPORT_CUSTOM_TEMPLATES_ERROR = 'GET_REPORT_CUSTOM_TEMPLATES_ERROR'
export const RESET_REPORT_CUSTOM_TEMPLATES = 'RESET_REPORT_CUSTOM_TEMPLATES'

export const GET_REPORT_DATA_ITEM_REQUEST = 'GET_REPORT_DATA_ITEM_REQUEST'
export const GET_REPORT_DATA_ITEM_SUCCESS = 'GET_REPORT_DATA_ITEM_SUCCESS'
export const GET_REPORT_DATA_ITEM_ERROR = 'GET_REPORT_DATA_ITEM_ERROR'

export const SAVE_REPORT_DATA_ITEM_REQUEST = 'SAVE_REPORT_DATA_ITEM_REQUEST'
export const SAVE_REPORT_DATA_ITEM_SUCCESS = 'SAVE_REPORT_DATA_ITEM_SUCCESS'
export const SAVE_REPORT_DATA_ITEM_ERROR = 'SAVE_REPORT_DATA_ITEM_ERROR'

export const DELETE_REPORT_DATA_ITEM_REQUEST = 'DELETE_REPORT_DATA_ITEM_REQUEST'
export const DELETE_REPORT_DATA_ITEM_SUCCESS = 'DELETE_REPORT_DATA_ITEM_SUCCESS'
export const DELETE_REPORT_DATA_ITEM_ERROR = 'DELETE_REPORT_DATA_ITEM_ERROR'

export const GET_REPORT_DATA_TEMPLATES_REQUEST = 'GET_REPORT_TEMPLATES_REQUEST'
export const GET_REPORT_DATA_TEMPLATES_SUCCESS = 'GET_REPORT_TEMPLATES_SUCCESS'
export const GET_REPORT_DATA_TEMPLATES_ERROR = 'GET_REPORT_TEMPLATES_ERROR'

export const STORE_REPORT_DATA_ITEM = 'STORE_REPORT_DATA_ITEM'
export const SET_REPORT_DATA_ITEM_FETCHED = 'SET_REPORT_DATA_ITEM_FETCHED'
export const RESET_REPORT_DATA_ITEM_STATUS = 'RESET_REPORT_DATA_ITEM_STATUS'
export const RESET_REPORT_DATA_ITEM = 'RESET_REPORT_DATA_ITEM'

/**
 * Get Report List
 * @private
 */
const _getReportDataList = (payload) => ({
  [CALL_API]: {
    types: [
      GET_REPORT_DATA_LIST_REQUEST,
      GET_REPORT_DATA_LIST_SUCCESS,
      GET_REPORT_DATA_LIST_ERROR
    ],
    payload,
    method: METHOD_TYPE.GET,
    endpoint: '/report'
  }
})

/**
 * Get Custom Template List
 * @private
 */
const _getCustomTemplates = (payload) => ({
  [CALL_API]: {
    types: [
      GET_REPORT_CUSTOM_TEMPLATES_REQUEST,
      GET_REPORT_CUSTOM_TEMPLATES_SUCCESS,
      GET_REPORT_CUSTOM_TEMPLATES_ERROR
    ],
    payload,
    method: METHOD_TYPE.GET,
    endpoint: '/report/customTemplates'
  }
})

/**
 * Get Report Fields
 * @private
 */
const _getReportDataItem = (_id) => ({
  [CALL_API]: {
    types: [
      GET_REPORT_DATA_ITEM_REQUEST,
      GET_REPORT_DATA_ITEM_SUCCESS,
      GET_REPORT_DATA_ITEM_ERROR
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/report/dataItem/${_id}`
  }
})

/**
 * Create new Report
 * @private
 */
const _saveReportDataItem = (payload, method, endpoint) => ({
  [CALL_API]: {
    types: [
      SAVE_REPORT_DATA_ITEM_REQUEST,
      SAVE_REPORT_DATA_ITEM_SUCCESS,
      SAVE_REPORT_DATA_ITEM_ERROR
    ],
    method,
    payload,
    endpoint
  }
})

/**
 * Delete Report
 * @param _id
 * @private
 */
const _deleteReportDataItem = (_id) => ({
  [CALL_API]: {
    types: [
      DELETE_REPORT_DATA_ITEM_REQUEST,
      DELETE_REPORT_DATA_ITEM_SUCCESS,
      DELETE_REPORT_DATA_ITEM_ERROR
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: `/report/dataItem/${_id}`
  }
})

/**
 * Get Report Templates
 * @private
 */
const _getReportDataTemplates = () => ({
  [CALL_API]: {
    types: [
      GET_REPORT_DATA_TEMPLATES_REQUEST,
      GET_REPORT_DATA_TEMPLATES_SUCCESS,
      GET_REPORT_DATA_TEMPLATES_ERROR
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/report/templates'
  }
})

/**
 * Get Report List
 * @param params
 */
export const getReportDataList = (params) => (dispatch) => {
  return dispatch(_getReportDataList(params))
}

/**
 * Get Custom Templates
 * @param params
 * @returns {function(*): *}
 */
export const getCustomTemplates = (params) => (dispatch) => {
  return dispatch(_getCustomTemplates(params))
}

export const resetCustomTemplates = () => (dispatch) => {
  return dispatch({ type: RESET_REPORT_CUSTOM_TEMPLATES })
}
/**
 * Get Report Data Item by ID
 * @param _id
 * @returns {function(*): *}
 */
export const getReportDataItem = (_id) => (dispatch) => {
  return dispatch(_getReportDataItem(_id))
}

/**
 * Save Report
 * @param reportDataItem
 * @param _id
 * @returns {function(*): *}
 */
export const saveReportDataItem = (reportDataItem, _id) => (dispatch) => {
  const method = _id ? METHOD_TYPE.PUT : METHOD_TYPE.POST
  const endpoint = _id ? `/report/dataItem/${_id}` : '/report/dataItem'

  return dispatch(_saveReportDataItem(reportDataItem, method, endpoint))
}

/**
 * Delete Report
 * @param _id
 */
export const deleteReportDataItem = (_id) => (dispatch) => {
  return dispatch(_deleteReportDataItem(_id))
}

/**
 * Get Report Templates
 */
export const getReportDataTemplates = () => (dispatch) => {
  return dispatch(_getReportDataTemplates())
}

export const storeReportDataItem = (report) => (dispatch) => {
  return dispatch({ type: STORE_REPORT_DATA_ITEM, payload: report })
}

export const setReportDataItemFetched = () => (dispatch) => {
  return dispatch({ type: SET_REPORT_DATA_ITEM_FETCHED })
}

export const resetReportDataItemStatus = () => (dispatch) => {
  return dispatch({ type: RESET_REPORT_DATA_ITEM_STATUS })
}

export const resetReportDataItem = () => (dispatch) => {
  return dispatch({ type: RESET_REPORT_DATA_ITEM })
}
