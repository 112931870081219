import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

export const GET_PEER_ANALYSIS = gql`
  query PeerAnalysis(
    $tickerId: String!
    $entityId: String!
    $entityType: String!
    $holder: Boolean
    $limit: Int!
    $sortBy: String
    $sortDir: String
    $currencyCode: String
  ) {
    securityCurrent: peerAnalysis(
      entityId: $entityId
      entityType: $entityType
      peerTickerId: [$tickerId]
      metric: "current"
      currencyCode: $currencyCode
    ) {
      items {
        metric
        entityId
        entityType
        securityName
        tickerId
        reportDate
        q0
        q1
        q2
        q3
        q4
      }
    }
    securityQtrChange: peerAnalysis(
      entityId: $entityId
      entityType: $entityType
      peerTickerId: [$tickerId]
      metric: "qtrChange"
      limit: 0
      currencyCode: $currencyCode
    ) {
      items {
        metric
        entityId
        entityType
        securityName
        tickerId
        reportDate
        q0
        q1
        q2
        q3
        q4
      }
    }
    peerCurrent: peerAnalysis(
      entityId: $entityId
      entityType: $entityType
      tickerId: $tickerId
      metric: "current"
      limit: $limit
      sortBy: $sortBy
      sortDir: $sortDir
      currencyCode: $currencyCode
    ) {
      items {
        metric
        entityId
        entityType
        securityName
        tickerId
        reportDate
        q0
        q1
        q2
        q3
        q4
      }
    }
    peerQtrChange: peerAnalysis(
      entityId: $entityId
      entityType: $entityType
      tickerId: $tickerId
      metric: "qtrChange"
      limit: 0
      currencyCode: $currencyCode
    ) {
      items {
        metric
        entityId
        entityType
        securityName
        tickerId
        reportDate
        q0
        q1
        q2
        q3
        q4
      }
    }
    marketValue: peerAnalysis(
      entityId: $entityId
      entityType: $entityType
      metric: "marketValue"
      filter: {
        holders: $holder
      }
      currencyCode: $currencyCode
    ) {
      count
      sum
    }
  }`

/**
 * Peer Analysis
 * @param options
 */
export function usePeerQuery (options) {
  return useQuery(GET_PEER_ANALYSIS, options)
}
