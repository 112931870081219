import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Button, Card, RenderHTML } from '../../../index'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderLightThumb, renderTrackVertical } from '../../../../resources/theme/q4.custom-scrollbar'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  comment: PropTypes.string,
  onEdit: PropTypes.func.isRequired
}

const defaultProps = {
  isLoading: false,
  placeholder: ''
}

/**
 * Comment Component
 * @param props
 */
function Comment ({ theme, placeholder, comment }) {
  return (comment && comment.length)
    ? <Scrollbars
      className='react-scrollbar'
      autoHide
      autoHeight
      autoHeightMax={100}
      hideTracksWhenNotNeeded
      renderThumbVertical={renderLightThumb}
      renderTrackVertical={renderTrackVertical}
    >
      <RenderHTML theme={theme} html={comment} />
    </Scrollbars>
    : placeholder
}

/**
 * Entity Comment Card Component
 * @param props
 */
function CommentCard (props) {
  const { dataId, theme, isLoading, placeholder, comment, onEdit } = props

  return <Card
    dataId={`${dataId}CommentCard`}
    theme={theme}
    isLoading={isLoading}
    title='Comment'
    isChin
    headerActions={[() => (
      <Button
        dataId={`${dataId}CommentEdit`}
        theme={theme}
        icon='q4i-edit-4pt'
        label='Edit'
        disabled={isLoading}
        onClick={onEdit}
      />
    )]}
  >
    {!isLoading && (
      <Comment
        theme={theme}
        placeholder={placeholder}
        comment={comment}
      />
    )}
  </Card>
}

CommentCard.propTypes = propTypes
CommentCard.defaultProps = defaultProps

export default memo(CommentCard)
