import {
  SET_INCLUDE_MY_SECURITY,
  FETCH_TOP_PEER_MOVERS_REQUEST,
  FETCH_TOP_PEER_MOVERS_SUCCESS,
  FETCH_TOP_PEER_MOVERS_ERROR,
  RESET_TOP_PEER_MOVERS_STATUS,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../../actions/widget/ownership'
import { get } from 'lodash'

const initial = {
  status: IDLE,
  list: [],
  isSecurity: false
}

const topmovers = (state = initial, action) => {
  switch (action.type) {
    case SET_INCLUDE_MY_SECURITY:
      return {
        ...state,
        isSecurity: action.value
      }
    case FETCH_TOP_PEER_MOVERS_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_TOP_PEER_MOVERS_SUCCESS:
      return {
        ...state,
        list: get(action, 'payload.data.peerMover.items', []),
        status: FETCHED
      }
    case RESET_TOP_PEER_MOVERS_STATUS:
      return {
        ...state,
        list: [],
        status: IDLE
      }
    case FETCH_TOP_PEER_MOVERS_ERROR:
      return {
        ...state,
        error: action.error,
        status: FAILED
      }
    default:
      return state
  }
}

export default topmovers
