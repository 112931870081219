import React, {Component} from 'react';
import ReactHighcharts from "react-highcharts";
import {inMillions} from "../../../utils/number.util";
import {white, lightSlate, citrus, tangerine, spice, teal, rain} from "../../../resources/materialui-overrides/colors";
import './pipeline.component.css';
import _ from 'lodash';
require('highcharts-funnel')(ReactHighcharts.Highcharts);

export default class PipelineChart extends Component {

    getDeals = (lanes, stockPrice) => {
        const funnelData = [];

        if (!lanes) {
            return [];
        }
        _.filter(lanes, lane => lane)
        .forEach((lane) => {
            const cards = lane.cards;
            const probability = (lane && lane.probability) || 0;
            const positionSum = (cards || []).reduce((acc, curr) => acc + curr.amount, 0);
            const marketValue = positionSum * (probability / 100) * stockPrice;

            if (lane.label !== "Targets" && lane.label !== "Objective Not Met") {
                funnelData.push({
                    name: `${lane.label} (${cards.length})`,
                    y: !cards.length ? 1 : cards.length,
                    value: inMillions(marketValue, 2) + 'M',
                    empty: !!cards.length
                });
            }
        });

        return funnelData;
    };

    getFontStyle = (layout, theme) => {
        return {
            fontFamily: 'Open Sans, sans-serif',
            fontWeight: 400,
            fontSize: layout.w >= 2 ? '13px' : '11px',
            color: theme === 'dark' ? white : lightSlate
        };
    };

    getChartConfig = (layout) => {
        const chartHeight = layout.h > 2 ? 400 : 240;
        let chartConfig;

        if (layout.w >= 2) {
            chartConfig = {
                width: 530,
                margin: [25, 190, 25, 25],
                spacingRight: 300,
                marginLeft: 50
            }
        } else {
            chartConfig = {
                width: 300,
                margin: [25, 40, 80, 40],
                y: 0
            }
        }

        return {
            type: 'funnel',
            backgroundColor: 'transparent',
            height: chartHeight,
            ...chartConfig
        };
    };

    getLegendConfig = (layout, theme) => {
        let legendConfig;

        if (layout.w >= 2) {
            legendConfig = {
                layout: "vertical",
                width: 180,
                itemWidth: 175,
                verticalAlign: "top",
                x: 310,
                y: layout.h >= 3 ? 150 : 60
            }
        } else {
            legendConfig = {
                width: 320,
                itemWidth: 150,
                verticalAlign: 'bottom',
                x: 35,
                y: 0
            }
        }

        return {
            enabled: true,
            align: 'right',
            itemStyle: this.getFontStyle(layout, theme),
            itemHoverStyle: this.getFontStyle(layout, theme),
            itemDistance: 5,
            margin: 0,
            padding: 0,
            layout: 'horizontal',
            ...legendConfig
        };
    };

    getChart = (data, layout, theme) => {
        return {
            chart: this.getChartConfig(layout),
            legend: this.getLegendConfig(layout, theme),
            colors: [
                citrus,
                tangerine,
                spice,
                teal,
                rain
            ],
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            tooltip: {
                useHTML: true,
                formatter: function () {
                    return (`
                        <div class="highcharts-tooltip_wrapper">
                            <h2>${this.point.name}</h2>
                            <h3>Valued at <span>${this.point.value}</span></h3>
                        </div>
                    `);
                }
            },
            plotOptions: {
                series: {
                    states: {
                        hover: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    neckWidth: '40%',
                    neckHeight: '0%'
                }
            },
            series: [{
                name: 'Deals',
                data: data,
                borderWidth: 0,
                showInLegend: true
            }],
        };
    };

    
    render() {
        const {pipeline, stock, layout, theme} = this.props;
        const stockPrice = (stock && stock.data && stock.data.last) || 0;
        const chartData = this.getDeals(pipeline.lanes, stockPrice);

        return (
            <ReactHighcharts className='pipeline-widget_chart' ref='stockChart'
                             config={this.getChart(chartData, layout, theme)}>
            </ReactHighcharts>
        );
    }
}