import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getClassName} from '../../utils/ui/ui.util';
import Table from '@material-ui/core/Table';
import DataTableHead from './head/head.component';
import DataTableBody from './body/body.component';
import DataTableFooter from './footer/footer.component';
import './dataTable.component.css';

/**
 * DataTable Component
 */
class DataTable extends Component {

    /**
     * Constructor
     * @param props
     * @param {array} props.data - data to render in table
     * @param {array} props.columns - column definition
     * @param {function} props.customCellRender - optional custom render function for table cells
     */
    constructor(props) {
        super(props);

        if (props.localPagination) {
            this.state = {
                page: props.initialPage || 1,
                pageSize: props.initialPageSize || 10
            };
        }
    }

    /**
     * ComponentDidUpdate
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        if (this.props.localPagination && (this.props.dataTotal !== prevProps.dataTotal)) {
            this.setState({
                page: 1
            });
        }
    }

    /**
     * Handle Page Change Event
     * If localPagination is true, this will locally slice the data based on event params
     * @param event
     */
    handlePageChange = (event) => {
        const {localPagination, onPageChange} = this.props;

        if (localPagination) {
            this.setState({
                page: event.selected || 1
            });

            return;
        }

        onPageChange && onPageChange(event);
    };

    /**
     * Handle Page Size Change Event
     * If localPagination is true, this will locally slice the data based on event params
     * @param event
     */
    handlePageSizeChange = (event) => {
        const {localPagination, onPageSizeChange} = this.props;

        if (localPagination) {
            this.setState({
                page: 1,
                pageSize: event.selected || 10
            });

            return;
        }

        onPageSizeChange && onPageSizeChange(event);
    };


    /**
     * Get locally sliced data for local pagination use
     * @param data
     * @param page
     * @param pageSize
     * @returns {Array.<T>}
     */
    getLocalData = (data, page, pageSize) => {
        const start = (page - 1) * pageSize;
        const end = (page - 1) * pageSize + pageSize;

        return (data || []).slice(start, end);
    };

    /**
     * Render Action Toolbar
     * @param actionItems
     * @returns {XML}
     */
    renderActionToolbar = (actionItems) => {
        const baseClassName = getClassName('data-table_action-toolbar', [
            {condition: actionItems && actionItems.length, trueClassName: 'data-table_action-toolbar--visible'}
        ]);

        return (
            <div className={baseClassName}>
                {(actionItems || []).map((actionItem) => {
                    return (
                        <div
                            key={`data-table-action-toolbar-item--${actionItem.id}`}
                            className='data-table_action-toolbar-item'
                            onClick={actionItem.onSelect}
                        >
                            {actionItem.icon && (<i className={actionItem.icon}/>)}
                            {actionItem.view}
                        </div>
                    );
                })}
            </div>
        );
    };

    /**
     * Render
     * @return {XML}
     */
    render() {
        const {
            data, dataTotal, columns, groupBy, sort,
            showPagination, showPageSizeSelection, localPagination,
            initialPage, initialPageSize, pageSizeOptions, pageSizeId,
            alternating, fixed, size, noResultsText,
            customColumnRender, customCellRender, onRowClick, onCellClick, onSortChange, actionItems
        } = this.props;

        const baseClass = [
            'data-table',
            alternating ? 'data-table--alternating' : '',
            groupBy ? 'data-table--grouped' : '',
            fixed ? 'data-table--fixed' : '',
            size ? `data-table--${size}` : ''
        ].join(' ').trim();

        return (
            <div className='data-table-root'>
                {this.renderActionToolbar(actionItems)}
                <Table className={baseClass}>
                    <DataTableHead
                        columns={columns}
                        sort={sort}
                        alternating={alternating}
                        onSortChange={onSortChange}
                        customColumnRender={customColumnRender}
                    />
                    <DataTableBody
                        data={localPagination ? this.getLocalData(data, this.state.page, this.state.pageSize) : data || []}
                        columns={columns}
                        groupBy={groupBy}
                        alternating={alternating}
                        noResultsText={noResultsText}
                        customCellRender={customCellRender}
                        onCellClick={onCellClick}
                        onRowClick={onRowClick}
                    />
                    <DataTableFooter
                        data={data}
                        dataTotal={dataTotal}
                        columns={columns}
                        showPagination={showPagination}
                        initialPage={initialPage}
                        forcePage={localPagination ? this.state.page : this.props.forcePage}
                        initialPageSize={initialPageSize}
                        pageSizeOptions={pageSizeOptions}
                        pageSizeId={pageSizeId}
                        showPageSizeSelection={showPageSizeSelection}
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                    />
                </Table>
            </div>
        );
    }
}

DataTable.propTypes = {
    /**
     * Array of data to display in the table
     */
    data: PropTypes.array,

    /**
     * The total amount of data records
     * Used for pagination to determine page count
     */
    dataTotal: PropTypes.number,

    /**
     * Array of columns used to display the data
     * Columns require an id property
     */
    columns: PropTypes.array,

    /**
     * UI Elements to be used for action toolbar
     * Action toolbar will become visible if non-null array is passed
     */
    actionItems: PropTypes.array,

    /**
     * A sort array, supports multi-sorting
     * property : Used to determine the sorted column
     * direction : Used to determine the direction of the arrow within the header cell
     */
    sort: PropTypes.arrayOf(PropTypes.shape({
        property: PropTypes.string.isRequired,
        direction: PropTypes.oneOf(['asc', 'ASC', 'desc', 'DESC']),
    })),

    /**
     * Used to determine the direction of the arrow within the header cell
     */
    sortDirection: PropTypes.oneOf(['asc', 'ASC', 'desc', 'DESC']),

    /**
     * Used to render an excel style grouped table
     * props.data should include a _grouping object with key and rowspan properties (key to match with groupBy)
     * props.columns should include a _groupKey property
     */
    groupBy: PropTypes.string,

    /**
     * Text used when displaying no available data
     * Default: No data available
     */
    noResultsText: PropTypes.string,

    /**
     * Used to determine whether the cells are tinted for alternation
     */
    alternating: PropTypes.bool,

    /**
     * A property used to set the table layout
     */
    fixed: PropTypes.bool,

    /**
     * Used to determine table size
     */
    size: PropTypes.oneOf(['default', 'small', 'xsmall']),

    /**
     * Used to determine whether or not to display showPagination for this table
     */
    showPagination: PropTypes.bool,

    /**
     * Used to determine whether or not pagination is local as opposed to server-side
     * Should be used if all data is passed at once
     */
    localPagination: PropTypes.bool,

    /**
     * Used to set an initial page on the component's first render
     */
    initialPage: PropTypes.number,

    /**
     * Used to set an overwrite page control with prop
     */
    forcePage: PropTypes.number,

    /**
     * Used to set an initial page size on the component's first render
     */
    initialPageSize: PropTypes.number,

    /**
     * Used to overwrite the default page size options
     * Default: 10, 25, 50, 100
     */
    pageSizeOptions: PropTypes.array,

    /**
     * A unique ID string used to store the user's last page size selection
     * Stored in localStorage under 'pagination' object
     */
    pageSizeId: PropTypes.string,

    /**
     * Override whether or not to show the page size options
     */
    showPageSizeSelection: PropTypes.bool,

    /**
     * A function used to overwrite what is rendered within the table cells
     */
    customCellRender: PropTypes.func,

    /**
     * A function used to overwrite what is rendered within the table column
     */
    customColumnRender: PropTypes.func,

    /**
     * A callback for when the user clicks on a row
     * Adds a visual indicator for the row (cursor: pointer)
     */
    onRowClick: PropTypes.func,

    /**
     * A callback for when the user clicks on a cell
     */
    onCellClick: PropTypes.func,

    /**
     * A callback for when sortBy or sortDirection is changed
     */
    onSortChange: PropTypes.func,

    /**
     * A callback for when the user changes pages
     */
    onPageChange: PropTypes.func,

    /**
     * A callback for when the user changes page size
     * if pageSizeId is provided, this also backs up the user's choice to localStorage under that ID
     */
    onPageSizeChange: PropTypes.func
};

DataTable.defaultProps = {
    data: [],
    columns: [],
    noResultsText: 'No data available',
    fixed: false,
    size: 'default',
    showPagination: false,
    localPagination: false,
    showPageSizeSelection: false,
    initialPageSize: 10,
    pageSizeOptions: [10, 25, 50, 100]
};

export default DataTable;