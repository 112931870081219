import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import { Card } from '../../../index'
import { defaultIfEmpty, format, formatDate, inMillions, THEMES, DEFAULT_DATE_FORMAT, formatLocalizedDate } from '../../../../utils'
import { capitalize, get } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    type: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    usePurchasingPower: PropTypes.bool,
    start: PropTypes.object,
    end: PropTypes.object,
    stage: PropTypes.object,
    revisions: PropTypes.array
  }).isRequired,
  stock: PropTypes.number.isRequired
}

const defaultProps = {
  isLoading: false,
  data: {}
}

const EMPTY_PLACEHOLDER = '-'
const ZERO = '0'

/**
 * Get stage information
 * @param label
 * @param revisions
 * @returns {string|null}
 */
function getStage ({ label, revisions }) {
  const update = (revisions || []).filter((revision) => revision.stage)
  const revision = update.length && update[update.length - 1]
  const { date } = (revision || {})

  return (
    !date ? null : <>
      {label}<span className='card_section-detail--neutral'>since {formatLocalizedDate(formatDate(date, 'MMMM Do, YYYY'), 'MMMM Do, YYYY')}</span>
    </>
  )
}

/**
 * Attribute Component
 * @param props
 */
function Attribute ({ label, value, width }) {
  return (
    <Grid item xs={width || 4} className='card_section'>
      <h2 className='card_section-subtitle'>{label}</h2>
      <div className='card_section-detail'>{defaultIfEmpty(value)}</div>
    </Grid>
  )
}

/**
 * Deal Attributes Card Component
 * @param props
 */
function DealAttribute (props) {
  const { dataId, theme, isLoading, data, stock } = props
  const { type, amount, usePurchasingPower, start, end, stage, revisions } = data
  const { label, probability } = (stage || {})

  const attributes = [
    { label: 'Objective', value: capitalize(type) },
    { label: 'Stage', value: getStage({ label, revisions }), width: 8 },
    {
      label: 'Potential POS',
      value: <>
        {(amount || amount === 0) ? format(amount) : EMPTY_PLACEHOLDER}
        {usePurchasingPower ? <span className='card_section-detail--neutral'>Purchasing Power</span> : ''}
      </>
    },
    {
      label: 'Potential Mkt Value ($MM)',
      value: amount ? inMillions((amount * stock), 2) : (amount === 0) ? ZERO : EMPTY_PLACEHOLDER
    },
    {
      label: 'Weighted Mkt Value ($MM)',
      value: amount ? inMillions((amount * (probability / 100) * stock), 2) : (amount === 0) ? ZERO : EMPTY_PLACEHOLDER
    },
    { label: 'Created', value: formatLocalizedDate(formatDate(get(start, 'date')), DEFAULT_DATE_FORMAT) },
    { label: 'Estimated Close', value: formatLocalizedDate(formatDate(get(end, 'date')), DEFAULT_DATE_FORMAT) }
  ]

  return (
    <Card
      dataId={`${dataId}AttributeCard`}
      theme={theme}
      isLoading={isLoading}
      title='Information'
    >
      <Grid container spacing={3}>
        {attributes.map((attribute) => (
          <Attribute key={attribute.label} {...attribute} />
        ))}
      </Grid>
    </Card>
  )
}

DealAttribute.propTypes = propTypes
DealAttribute.defaultProps = defaultProps

export default memo(DealAttribute)
