import {
  GET_ADDRESS_BOOK_CONTACTS_REQUEST,
  GET_ADDRESS_BOOK_CONTACTS_SUCCESS,
  GET_ADDRESS_BOOK_CONTACTS_FAILURE,
  UPDATE_USER_EMAIL_PREFERENCE,
  statusType,
  emailPreference
} from '../../actions'

const initial = {
  data: [],
  total: 0,
  status: statusType.IDLE,
  initialLoading: true,
  userEmailPreference: emailPreference.INITIAL
}

const contacts = (state = initial, action) => {
  const { payload, meta } = action

  switch (action.type) {
    case GET_ADDRESS_BOOK_CONTACTS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_ADDRESS_BOOK_CONTACTS_SUCCESS:
      return {
        ...state,
        data: payload,
        total: meta && meta.total,
        status: statusType.SUCCESS,
        initialLoading: false
      }

    case GET_ADDRESS_BOOK_CONTACTS_FAILURE:
      return {
        ...state,
        data: [],
        status: statusType.ERROR,
        initialLoading: false
      }
    
    case UPDATE_USER_EMAIL_PREFERENCE: {
      return {
        ...state,
        userEmailPreference: payload
      }
    }

    default:
      return state
  }
}

export default contacts
