import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getClassName, THEMES } from '../../utils/ui'

import './badge.component.css'

const propTypes = {
  badges: PropTypes.arrayOf(PropTypes.shape({
    dataId: PropTypes.string.isRequired,
    theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    hidden: PropTypes.bool
  }))
}

const defaultProps = {
  theme: THEMES.DARK
}

/**
 * Badge Item Component
 * @param props
 * @returns {*}
 * @constructor
 */
function BadgeComponent ({ dataId, icon, label, hidden }) {
  return (
    !hidden
      ? <div data-id={dataId} className='badge_item'>
        {icon && <i className={`badge_icon ${icon}`} />}
        {label && <div className='badge_label'>{label}</div>}
      </div>
      : null
  )
}

/**
 * Badge Component
 * @param app
 */
function Badge ({ theme, items }) {
  return (
    (items && items.length)
      ? <div className={getClassName('badge', [
        { condition: theme, trueClassName: `badge--${theme}` }
      ])}>
        {(items || []).map((item) => <BadgeComponent key={item.dataId} {...item} />)}
      </div>
      : null
  )
}

Badge.propTypes = propTypes
Badge.defaultProps = defaultProps

export default memo(Badge)
