import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DataTableRow from '../row/row.component';
import DataTableCell from '../cell/cell.component';

class DataTableHead extends Component {

    /**
     * Handle cell click event
     * @param event
     * @param columnId
     */
    handleCellClick = (event, columnId) => {
        if (!event || !columnId) {
            return;
        }

        const {onSortChange, sort} = this.props;

        const sortItem = (sort || []).find((item) => item.property === columnId);
        const direction = sortItem ? sortItem.direction === 'asc' ? 'desc' : 'asc' : 'asc';

        onSortChange && onSortChange(columnId, direction);
    };

    /**
     * Get TableCell content based on props
     * @param column
     * @param columnIndex
     * @param columns
     */
    getDataTableCellContent = (column, columnIndex, columns) => {
        const {customColumnRender} = this.props;
        let cellContent;

        if (customColumnRender) {
            cellContent = customColumnRender(column.id, column, columnIndex, columns);
        } else {
            cellContent = column.label;
        }

        return cellContent;
    };

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const {columns, sort, alternating} = this.props;

        return (
            <thead className='data-table_head'>
                <DataTableRow>
                    {(columns && columns.length > 0) ? columns.map((column, columnIndex) => {
                        const sortItem = column.sortable && (sort || []).find((item) => item.property === column.id);

                        return (
                            <DataTableCell
                                key={`data-table-body-cell--${column.id}`}
                                headerCell={true}
                                sortDirection={sortItem ? sortItem.direction : null}
                                id={column.id}
                                width={column.width}
                                textAlign={column.textAlign}
                                dense={column.dense}
                                highlighted={column.highlighted}
                                isAlternate={alternating && (column.isAlternate || !!(columnIndex % 2))}
                                onCellClick={column.sortable ? this.handleCellClick : null}
                            >
                                {this.getDataTableCellContent(column, columnIndex, columns)}
                            </DataTableCell>
                        );
                    }) : (
                        <DataTableCell headerCell={true}/>
                    )}
                </DataTableRow>
            </thead>
        );
    }
}

DataTableHead.propTypes = {
    /**
     * Array of columns used to display the data
     * Columns require an id property
     */
    columns: PropTypes.array,

    /**
     * A sort array, supports multi-sorting
     * property : Used to determine the sorted column
     * direction : Used to determine the direction of the arrow within the header cell
     */
    sort: PropTypes.arrayOf(PropTypes.shape({
        property: PropTypes.string.isRequired,
        direction: PropTypes.oneOf(['asc', 'ASC', 'desc', 'DESC']),
    })),

    /**
     * Used to determine whether the cells are tinted for alternation
     */
    alternating: PropTypes.bool,

    /**
     * A callback for when sortBy or sortDirection is changed
     */
    onSortChange: PropTypes.func
};

export default DataTableHead;