import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { FETCH_STOCKQUOTE_ERROR } from './stockQuote.actions'
import { push } from 'connected-react-router'

export const FETCH_AVGVOLUME_REQUEST = 'FETCH_AVGVOLUME_REQUEST'
export const FETCH_AVGVOLUME_SUCCESS = 'FETCH_AVGVOLUME_SUCCESS'
export const FETCH_AVGVOLUME_ERROR = 'FETCH_AVGVOLUME_ERROR'

const fetchAvgVolume = (url) => ({
  [CALL_API]: {
    types: [FETCH_AVGVOLUME_REQUEST, FETCH_AVGVOLUME_SUCCESS, FETCH_AVGVOLUME_ERROR],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
})

const stockError = (error) => ({
  type: FETCH_STOCKQUOTE_ERROR,
  error: error.error,
  status: error.status
})

export const loadAvgVolume = (params) => (dispatch, getState) => {
  const state = getState()
  const securityId = params.securityId || state.dashboard.dashboardState.securityId
  if (!securityId) {
    return Promise.all([
      dispatch(stockError({
        error: 'You must provide a valid securityId',
        status: 404
      })),
      dispatch(push('/error'))
    ])
  }

  return dispatch(fetchAvgVolume(`/stock/average-volume/${securityId}`))
}
