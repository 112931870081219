import React, { useState } from 'react'
import PropTypes from 'prop-types'

// actions
import { useOwnershipQuery } from '../../hook'

// components
import { Card, SectionTab } from '../../../index'
import SecurityOwnershipChart from '../../chart/security/security.component'

// utils
import { THEMES, getOffsetQuarterDate } from '../../../../utils'
import { capitalize, get } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  tickerId: PropTypes.string.isRequired,
  quarters: PropTypes.number,
  height: PropTypes.number
}

const defaultProps = {
  theme: THEMES.DARK,
  quarters: 4,
  height: 308
}

const TABS = {
  STYLE: 'style',
  TYPE: 'type',
  TURNOVER: 'turnover',
  REGION: 'region'
}

/**
 * Security Ownership Component
 * @param props
 */
function SecurityOwnership (props) {
  const { dataId, theme, tickerId, quarters, height } = props
  const [active, setActive] = useState(TABS.STYLE)
  const quarterDate = getOffsetQuarterDate()
  const { loading, data } = useOwnershipQuery({
    variables: { tickerId, metric: 'percentTSO', limit: 5, quarterDate }
  })

  return (
    <Card
      dataId={`${dataId}OwnershipCard`}
      theme={theme}
      isLoading={loading}
      title='Ownership'
      headerActions={[() => <SectionTab
        compact
        theme={theme}
        activeTab={active}
        onChange={(event, value) => setActive(value)}
        tabs={[
          {
            label: capitalize(TABS.STYLE),
            value: TABS.STYLE
          },
          {
            label: capitalize(TABS.TYPE),
            value: TABS.TYPE
          },
          {
            label: capitalize(TABS.TURNOVER),
            value: TABS.TURNOVER
          },
          {
            label: capitalize(TABS.REGION),
            value: TABS.REGION
          }
        ]} /> ]}
    >
      <SecurityOwnershipChart
        active={active}
        ownership={get(data, `${active}.items`, [])}
        quarters={quarters}
        quarterDate={quarterDate.toString()}
        height={height}
      />
    </Card>
  )
}

SecurityOwnership.propTypes = propTypes
SecurityOwnership.defaultProps = defaultProps

export default SecurityOwnership
