import React from 'react'
import PropTypes from 'prop-types'
import { format } from '../../../utils'

import './toolbar.component.css'

/**
 * Search Result Toolbar
 * @param props
 * @returns {*}
 * @constructor
 */
const SearchResultToolbar = (props) => {
  const { loading, search, total, hasMore } = props

  return (
    <section className='search-page_toolbar'>
      <div className='search-page_toolbar-counter'>
        {loading
          ? 'Searching'
          : total
            ? `${hasMore ? 'More than ' : ''}${format(total)}`
            : 'No'} {!loading && `${total === 1 ? 'result' : 'results'} ${search.length ? 'for ' : ''}`}
        {!loading && <span className='search-page_toolbar-counter--term'>{search}</span>}
      </div>
    </section>
  )
}

SearchResultToolbar.propTypes = {
  loading: PropTypes.bool,
  search: PropTypes.string,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasMore: PropTypes.bool
}

SearchResultToolbar.defaultProps = {
  loading: false,
  search: '',
  total: 0,
  hasMore: false
}

export default SearchResultToolbar
