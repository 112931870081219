import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Prompt } from 'react-router-dom'

const propTypes = {

  /**
   * Navigate and redirect function
   */
  navigate: PropTypes.func.isRequired,
  /**
   * Use as "message" prop of Prompt of React-Router
   */
  shouldBlockNavigation: PropTypes.func.isRequired,
  /**
   * Customized Prompt Modal
   */
  handleOpenPromptModal: PropTypes.func.isRequired,
  /**
   * Navigation Btn click
   */
  handleNavigationModalClick: PropTypes.func.isRequired,
  /**
   * When should shouldBlockNavigation be invoked
   */
  when: PropTypes.bool
}

const defaultProps = {
  when: true
}

class RouteLeavingPrompt extends Component {

  constructor (props) {
    super(props)
    this.state = { confirmedNavigation: false }
  }

  /**
   * Customized navigation modal CONFIRM_NAVIGATION_MODAL will be used
   * and block navigation when user do not want to redirect
   * @param nextLocation
   * @returns {boolean}
   */
  handleBlockedNavigation = (nextLocation) => {
    const { shouldBlockNavigation, handleOpenPromptModal } = this.props
    const { confirmedNavigation } = this.state

    if (shouldBlockNavigation(nextLocation) && !confirmedNavigation) {
      handleOpenPromptModal(nextLocation, this.handleConfirmNavigationClick)
      return false
    }
    return true
  }

  /**
   * Redirect btn click action
   * @param nextLocation
   */
  handleConfirmNavigationClick = (nextLocation) => {
    const { page, navigate, handleNavigationModalClick } = this.props
    handleNavigationModalClick(nextLocation, () => this.setState({
      confirmedNavigation: true
    }, () => {
      if (nextLocation.pathname !== page) {
        navigate(nextLocation)
      }
    }))
  }

  render () {
    const { when } = this.props
    return (
      <Prompt
        when={when}
        message={this.handleBlockedNavigation}/>
    )
  }
}

RouteLeavingPrompt.propTypes = propTypes
RouteLeavingPrompt.defaultProps = defaultProps

export default RouteLeavingPrompt
