import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

// actions
import { useDealCreateQuery, useDealUpdateQuery, usePipelineQuery, usePurchasingPowerQuery } from '../hook'
import { openModal, createToast, modalType, messageSencha } from '../../../actions'

// components
import EditModal from './edit.component'

// utils
import { getActiveTicker } from '../../../utils'
import { get, isNumber } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  deal: PropTypes.object,
  entities: PropTypes.array,
  isEurope: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func
}

const defaultProps = {
  dataId: 'DealEdit',
  deal: {}
}

/**
 * Deal Create/Edit Modal
 * @param props
 */
function DealEdit (props) {
  const { dataId, securityId, deal, entities, isEurope, onClose, onSaveSuccess, openModal, createToast, messageSencha } = props
  const { id } = deal

  const { data: pipeline } = usePipelineQuery()

  /**
   * Handle purchasing power
   */
  const [handlePurchasingPower, { loading: purchasingPowerProgress, data: purchasingPower }] = usePurchasingPowerQuery()
  const handleGetPurchasingPower = (entity = []) => {
    handlePurchasingPower({ skip: isEurope, variables: { securityId, entity, isRelative: true } })
  }

  /**
   * Handle create/edit deal
   */
  const [handleCreate, { loading: createProgress }] = useDealCreateQuery()
  const [handleUpdate, { loading: updateProgress }] = useDealUpdateQuery()
  const handleDealSave = (values) => {
    const { amount } = (values || {})
    const variables = { ...values, amount: isNumber(amount) ? amount : null }
    const request = id
      ? handleUpdate({ variables: { id, ...variables } })
      : handleCreate({ variables })

    request.then((response) => get(response, 'errors')
      ? handleFailure()
      : handleSuccess(get(response, 'data.deal', {}))
    )
  }

  /**
   * On Action Success
   * @param response
   */
  function handleSuccess (response) {
    const type = response.update ? 'update' : 'create'

    // @deprecate refresh sencha ownership grid on deal create
    response.create && messageSencha({ type: 'dealCreate', params: { id: get(response, 'create.items[0].id'), entities } })

    createToast({ text: `Deal ${type}d successfully.` })
    onSaveSuccess && onSaveSuccess()
    onClose()
  }

  /**
   * On Action Completion Failure display an error
   */
  function handleFailure () {
    openModal({ type: modalType.ERROR_MODAL })
  }

  return (
    <EditModal
      dataId={dataId}
      loading={[createProgress, updateProgress].some((loading) => !!loading)}
      deal={deal}
      entities={entities}
      pipeline={get(pipeline, 'pipeline.items', [])}
      isEurope={isEurope}
      purchasingPowerProgress={purchasingPowerProgress}
      purchasingPower={get(purchasingPower, 'purchasingPower.sum')}
      onGetPurchasingPower={handleGetPurchasingPower}
      onSave={handleDealSave}
      onClose={onClose}
      openModal={openModal}
    />
  )
}

DealEdit.propTypes = propTypes
DealEdit.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)

  return {
    isEurope: get(profile, 'region') === 'europe',
    securityId: get(ticker, 'q4_entity_id', '1234567890') // TODO: tmp default value for pre-IPO
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch),
  createToast: bindActionCreators(createToast, dispatch),
  messageSencha: bindActionCreators(messageSencha, dispatch) // @deprecate
})

export default connect(mapStateToProps, mapDispatchToProps)(DealEdit)
