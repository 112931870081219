import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './progressDots.component.css'

/**
 * ProgressDots Component
 */
class ProgressDots extends PureComponent {
  /**
     * Render ProgressDots Component
     * @return {XML}
     */
  render () {
    const { className, theme, verticalPadding, dotCount, dotPosition, dotWidth, onDotClick } = this.props

    const baseClassName = [
      className ? `${className} progress-dots` : 'progress-dots',
      theme ? `progress-dots--${theme}` : '',
      onDotClick ? 'progress-dots--interactive' : ''
    ].join(' ')

    const defaultDotWidth = 8
    const styles = {
      base: {
        paddingTop: verticalPadding,
        paddingBottom: verticalPadding,
        lineHeight: dotWidth
      },
      wrap: {
        padding: dotWidth ? dotWidth / 2 : null,
        borderRadius: dotWidth ? dotWidth * 2 : null
      },
      node: {
        width: dotWidth,
        height: dotWidth
      },
      bar: {
        width: dotPosition ? `${(dotWidth || defaultDotWidth) + (((dotWidth || defaultDotWidth) * 2) * (dotPosition - 1))}px` : null,
        borderRadius: dotWidth
      }
    }

    return (
      <section className={baseClassName} style={styles.base}>
        <div className='progress-dots_wrap' style={styles.wrap}>
          <div className='progress-dots_inner'>
            {[...Array(dotCount || 0)].map((node, index) => {
              return (index > 0) ? (
                <React.Fragment key={`progress-node--${index}`}>
                  <span className='progress-dots_space' style={styles.node} />
                  <span className='progress-dots_node' style={styles.node} />
                </React.Fragment>
              ) : (
                <span key={`progress-node--${index}`} className='progress-dots_node' style={styles.node} />
              )
            })}
            <span className='progress-dots_bar' style={styles.bar} />
          </div>
        </div>
      </section>
    )
  }
}

ProgressDots.propTypes = {
  /**
     * A custom className to pass into the component
     */
  className: PropTypes.string,

  /**
     * A theme used to paint the component in a specific colour
     */
  theme: PropTypes.string,

  /**
     * Used to determine the amount of dots/stages of progress
     */
  dotCount: PropTypes.number.isRequired,

  /**
     * Used to determine the current dot/stage of progress
     */
  dotPosition: PropTypes.number.isRequired,

  /**
     * Used to determine the width of the dots and their space between
     */
  dotWidth: PropTypes.number,

  /**
     * Used to determine padding space above and below the component
     */
  verticalPadding: PropTypes.number,

  /**
     * If provided, used to provide a callback for clicking on a dot
     * This should be used if you desire the dots to be used as a pagination
     */
  onDotClick: PropTypes.func
}

ProgressDots.defaultProps = {
  theme: 'rain'
}

export default ProgressDots
