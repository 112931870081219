import React, { memo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// components
import { Modal } from '../../../index'

import './detail.component.scss'
import renderHTML from 'react-render-html'

const propTypes = {
  news: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

const defaultProps = {
  news: {}
}

/**
 * Get news subtitle
 * @param source
 * @param date
 */
function getSubtitle (source, date) {
  return (
    <span className='security-recent-update_modal-subtitle'>
      {source ? <span>{source}</span> : ''}
      {date ? <span><i className='q4i-time-4pt' />{moment(date).fromNow()}</span> : ''}
    </span>
  )
}

/**
 * News Detail Modal
 * @param props
 */
function NewsDetail ({ news, onClose }) {
  const { headline, date, source, sanitized } = (news || {})

  return (
    <Modal
      visible
      scrollable
      containerMaxWidth='md'
      contentPadding='20px'
      contentBackground
      className='security-recent-update_modal'
      title={headline || 'News Details'}
      subtitle={getSubtitle(source, date)}
      onClose={onClose}
    >
      <div className='security-recent-update_modal-body'>
        {sanitized
          ? renderHTML(sanitized.replace(/<a href/g, '<a target="_blank" href'))
          : 'No data available'}
      </div>
    </Modal>
  )
}

NewsDetail.propTypes = propTypes
NewsDetail.defaultProps = defaultProps

export default memo(NewsDetail)
