import _ from 'lodash'
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import {
  renderDarkThumb,
  renderLightThumb,
  renderTrackVertical
} from '../../../../resources/theme/q4.custom-scrollbar'
import { bindActionCreators } from 'redux'
import { fetchDownloadsAnalytics } from '../../../../actions/widget/analytics'
import { setClassNames } from '../../../../utils/widget.util'
import { getFromXigniteToStandard } from '../../../../utils/stock/stock.util'
import DownloadsWebAnalytics
  from '../../../../components/widget/analytics/downloadsWebAnalytics/downloadsWebAnalytics.component'
import { Spinner } from '../../../../components'
import { FAILED, FETCHED, FETCHING } from '../../../../actions/widget/analytics/'
import WidgetError from '../../../../components/widget/error/widgetError.component'
import './downloadsWebAnalytics.container.css'

class DownloadsWebAnalyticsContainer extends Component {

  constructor (props) {
    super(props)
    this.state = {
      timer: null
    }
  }

  /**
   * Triggered when component mounted
   * Enable auto-reloading if enabled
   */
  componentDidMount = () => {
    const { isEdit, status } = this.props
    if (isEdit && status === FETCHED) {
      return
    }

    const refreshInterval = this.props.options.refreshInterval
    if (refreshInterval) {
      this.setState({
        timer: setInterval(this.fetchData.bind(this), refreshInterval)
      })
    }

    this.fetchData()
  }

  /**
   * ComponentDidUpdate
   * Re-fetch data when security changed
   * @param prevProps
   */
  componentDidUpdate = (prevProps) => {
    if (prevProps.securityId !== this.props.securityId) {
      this.fetchData()
    }
  }

  /**
   * clear fetch interval on unmount
   */
  componentWillUnmount = () => {
    if (this.state.timer) {
      clearInterval(this.state.timer)
    }
  }

  /**
   * Render footer tag
   * @returns {XML}
   */
  renderFooter = () => {
    return (
      <Link to={`/analytics`}>VIEW DETAIL</Link>
    )
  }

  /**
   * Fetch web analytics data
   */
  fetchData = () => {
    const params = {
      'start-date': '30daysAgo',
      'end-date': 'today',
      'filters': 'ga:eventAction==download',
      'dimensions': 'ga:eventCategory, ga:eventLabel',
      'metrics': 'ga:sessions',
      'sort': '-ga:sessions',
      'max-results': 10,
      'page': 1,
      'start': 0,
      'limit': 25
    }

    this.props.fetchDownloadsAnalytics(params)
  }

  getNormalizedDownloads (items) {

    return (items || []).map((item) => {

      if (!item.length || item.length < 3) {
        return null
      }

      const fileName = item[1]

      return {
        fileName: fileName ? fileName.substr(item[1].lastIndexOf('/') + 1) : null,
        count: parseInt(item[2], 10) || 0
      }
    })
  }

  render () {
    const { theme, layout, colors, downloads, securityId, profile, status, history } = this.props

    const classes = setClassNames('downloads-web-analytics-container', layout, theme)
    const tickers = (profile && profile._organization && (profile._organization.tickers || []))
    const is_loading = status === FETCHING
    const failed = status === FAILED
    const renderThumb = theme === 'dark' ? renderLightThumb : renderDarkThumb

    // get active company from organization's tickers, fallback to primary ticker
    const company = _.find((tickers), (ticker) => ticker._security === securityId)
      || _.find((tickers), (ticker) => ticker.primary)

    if (!is_loading && _.isEmpty(downloads)) {
      return (
        <WidgetError
          theme={theme}
          header={'Downloads - Web Analytics'}
          message={'Looks like you don\'t have any analytics available.'}
        />
      )
    }

    if (failed) {
      return (
        <WidgetError
          theme={theme}
          header={'Downloads - Web Analytics'}
          message={'Looks like you don\'t have any analytics available.'}
        />
      )
    }

    return (
      <div className={classes.base}>
        <header className={`${classes.name}_header`}>
                    <span className='downloads-web-analytics-container_title'
                          onClick={() => history.push('/analytics')}>DOWNLOADS - WEB ANALYTICS</span>
          <span
            className='downloads-web-analytics-container_company'>{company ? `${company.symbol} ${getFromXigniteToStandard(company.exchange)}` : null}</span>
        </header>
        <section className={`${classes.name}_body`}>
          {is_loading && _.isEmpty(downloads) ? <Spinner/> : (
            <Scrollbars
              className='react-scrollbar'
              autoHide
              hideTracksWhenNotNeeded
              renderThumbVertical={renderThumb}
              renderTrackVertical={renderTrackVertical}>
              <div className='downloads-web-analytics-container_chart'>
                <DownloadsWebAnalytics
                  items={this.getNormalizedDownloads(downloads)}
                  colors={colors}
                  theme={theme}
                  layout={layout}
                />
              </div>
            </Scrollbars>
          )}
        </section>
        <footer className={`${classes.name}_footer`}>
          {this.renderFooter()}
        </footer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.shared.profile,
    theme: state.dashboard.dashboardState.theme,
    securityId: state.dashboard.dashboardState.securityId,
    status: state.widget.analytics.downloadsWebAnalytics.status,
    downloads: (state.widget.analytics.downloadsWebAnalytics.items || []),
    colors: state.widget.analytics.downloadsWebAnalytics.colors || []
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDownloadsAnalytics: bindActionCreators(fetchDownloadsAnalytics, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DownloadsWebAnalyticsContainer))
