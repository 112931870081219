/**
 * Get cookie
 * @param key
 * @returns {*}
 */
export function getCookie(key) {
  if (!document || !document.cookie) {
    return null;
  }

  const name = key + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  const cookie = ca.find(cookie => cookie.trim().startsWith(name));
  if (cookie) {
    const cookieValue = cookie.trim().substring(name.length, cookie.length);
    if (cookieValue) {
      return cookieValue;
    }
  }
  return null;
}

/**
 * Set cookie
 * @param key
 * @param value
 * @param expiryInDays defaults to 400 days which is the max allowed by the Chrome browser
 */
export function setCookie(key, value, expiryInDays = 400) {
  const date = new Date();
  date.setDate(date.getDate() + expiryInDays);
  let expires = "expires="+ date.toUTCString();
  document.cookie = key + "=" + value + ";" + expires + ";path=/";
}
