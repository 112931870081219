import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { getClassName, THEMES, allowOnlyIntergersOnKeyDown, allowOnlyIntergersOnChange } from '../../../../utils/ui'
import './textInput.component.css'

/**
 * TextInput Component
 */
class TextInput extends PureComponent {
  /**
   * Render Component
   * @return {XML}
   */
  render () {
    const {
      dataId, id, name, theme, className, placeholder, type, value, error, errorMessage, onChange, onKeyDown, onBlur,
      autoComplete, autoFocus, readOnly, maxLength, min, max, tabindex, onlyInteger
    } = this.props

    const baseClassName = getClassName('text-input', [
      { condition: theme, trueClassName: `text-input--${theme}` },
      { condition: error, trueClassName: 'text-input--error' },
      { condition: readOnly, trueClassName: 'text-input--readonly' },
      { condition: className, trueClassName: className }
    ])

    return (
      <div className={baseClassName} data-id={dataId}>
        <input
          id={id}
          name={name}
          className='text-input_field'
          placeholder={placeholder}
          type={type}
          value={value}
          maxLength={maxLength}
          onChange={onlyInteger ? e => allowOnlyIntergersOnChange(e, onChange) : onChange}
          onKeyDown={onlyInteger ? e => allowOnlyIntergersOnKeyDown(e, onKeyDown) : onKeyDown}
          onBlur={onBlur}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          readOnly={readOnly}
          min={min}
          max={max}
          tabIndex={tabindex}
        />

        {error && errorMessage && errorMessage.length
          ? <div className='text-input_error-container'>
            <i className='text-input_error-container-icon q4i-warning-4pt' />
            <div className='text-input_error-container-message'>{errorMessage}</div>
          </div>
          : null}
      </div>
    )
  }
}

TextInput.propTypes = {
  dataId: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  autoComplete: PropTypes.string,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  readOnly: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  tabindex: PropTypes.number,
  theme: PropTypes.oneOf([
    THEMES.LIGHT_GREY,
    THEMES.PALE_GREY,
    THEMES.DARK_SLATE,
    THEMES.WHITE
  ]),
  onlyInteger: PropTypes.bool
}

TextInput.defaultProps = {
  type: 'text',
  placeholder: '',
  autoComplete: 'off',
  theme: THEMES.LIGHT_GREY,
  onlyInteger: false
}

export default TextInput
