import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

export const FETCH_TRADING_ANALYTICS_REQUEST = 'FETCH_TRADING_ANALYTICS_REQUEST'
export const FETCH_TRADING_ANALYTICS_SUCCESS = 'FETCH_TRADING_ANALYTICS_SUCCESS'
export const FETCH_TRADING_ANALYTICS_ERROR = 'FETCH_TRADING_ANALYTICS_ERROR'

export const FETCH_EXPECTED_RANGES_REQUEST = 'FETCH_EXPECTED_RANGES_REQUEST'
export const FETCH_EXPECTED_RANGES_SUCCESS = 'FETCH_EXPECTED_RANGES_REQUEST'
export const FETCH_EXPECTED_RANGES_ERROR = 'FETCH_EXPECTED_RANGES_REQUEST'

export const FETCH_STOCK_REQUEST = 'FETCH_STOCK_REQUEST'
export const FETCH_STOCK_SUCCESS = 'FETCH_STOCK_SUCCESS'
export const FETCH_STOCK_ERROR = 'FETCH_STOCK_ERROR'

export const FETCH_ALL_TRADING_ANALYTICS_SUCCESS = 'FETCH_ALL_TRADING_ANALYTICS_SUCCESS'

const resources = {
  indicators: '/oxford/indicators',
  range: '/oxford/expected-ranges',
  stock: '/stock'
}

const _fetchTradingAnalytics = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_TRADING_ANALYTICS_REQUEST,
      FETCH_TRADING_ANALYTICS_SUCCESS,
      FETCH_TRADING_ANALYTICS_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

const _fetchStock = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_STOCK_REQUEST,
      FETCH_STOCK_SUCCESS,
      FETCH_STOCK_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

const _fetchExpectedRanges = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_EXPECTED_RANGES_REQUEST,
      FETCH_EXPECTED_RANGES_SUCCESS,
      FETCH_EXPECTED_RANGES_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

export const fetchTradingAnalytics = (id) => {
  return (dispatch, getState) => {
    const state = getState()

    if (!id) {
      id = state.dashboard.dashboardState.securityId
    }
    return Promise.all([
      dispatch(_fetchTradingAnalytics(`${resources.indicators}/${id}`)),
      dispatch(_fetchExpectedRanges(`${resources.range}/${id}`)),
      dispatch(_fetchStock(`${resources.stock}/${id}`))
    ]).then((data) => {
      dispatch({
        type: FETCH_ALL_TRADING_ANALYTICS_SUCCESS,
        payload: data
      })
    })
  }
}
