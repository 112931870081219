import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

export const GET_FUND_CONTACTS_REQUEST = 'GET_FUND_CONTACTS_REQUEST'
export const GET_FUND_CONTACTS_SUCCESS = 'GET_FUND_CONTACTS_SUCCESS'
export const GET_FUND_CONTACTS_FAILURE = 'GET_FUND_CONTACTS_FAILURE'
export const RESET_FUND_CONTACT_GRID = 'RESET_FUND_CONTACT_GRID'

const _fetchFundContacts = (payload, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_FUND_CONTACTS_REQUEST,
      GET_FUND_CONTACTS_SUCCESS,
      GET_FUND_CONTACTS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/fund/contact/v2',
    payload
  }
})

export const resetFundContactGrid = () => (dispatch) => {
  return dispatch({ type: RESET_FUND_CONTACT_GRID })
}

export const fetchFundContacts = (params, options) => (dispatch) => {
  return dispatch(_fetchFundContacts(params, options))
}
