import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import {config} from '../../../config';
import {sendMailV1, SENT, FAILED} from '../../../actions/shared';
import { Message, ReadMore } from '../../../components';
import './widgetCard.container.css';

class WidgetCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            isMailSent: false
        };
    }

    /**
     * Send Mail Request
     */
    sendMail = () => {
        const {widget, profile, sendMail} = this.props;
        const {isMailSent} = this.state;

        if (!profile || !profile.client || isMailSent) {
            return;
        }

        sendMail({
            to: config.widget.subscriptionInquiryList || [],
            subject: 'Feature Inquiry',
            body: [
                `User Name: ${profile.firstName} ${profile.lastName}`,
                `User Company: ${profile.client.Name}`,
                `User Email: ${profile.user}`,
                `Subscription Inquiry: ${widget.subscription.join(', ')}`
            ].join('<br>')
        });

        this.setState({
            isModalOpen: true,
            isMailSent: true
        });
    };

    /**
     * Close Confirmation Modal
     */
    closeModal = () => {
        this.setState({
            isModalOpen: false
        });
    };

    render() {
        const {widget, isUnlocked, onWidgetSelect, status} = this.props;
        const {isModalOpen, isMailSent} = this.state;

        const cardClass = [
            'widget-card',
            isUnlocked ? '' : 'widget-card--locked',
            widget.isTooLarge ? 'widget-card--too-large' : ''
        ].join(' ').trim();

        return (
            <div className={cardClass}>
                <h3 className='widget-card_title'>{widget.title}</h3>
                {!isUnlocked ? (
                    <div className='widget-card_indicator'>
                        <i className='q4i-lock-4pt'/>
                    </div>
                ) : null}
                <div className='widget-card_description'>
                    <ReadMore text={widget.description} lines={2}/>
                </div>
                <div className='widget-card_actions'>
                    {isUnlocked ? (
                        <button className='button button--citrus button--tall'
                                onClick={onWidgetSelect}>Add to Dashboard</button>
                    ) : (
                        <button className={`button button--slate button--tall ${isMailSent ? 'button--disabled' : ''}`}
                                onClick={this.sendMail}>Contact Us</button>
                    )}
                </div>
                <Message
                    visible={isModalOpen && (status === SENT || status === FAILED)}
                    renderExitButton={true}
                    type={status === SENT ? null : 'error'}
                    title={status === SENT ? 'Message sent' : 'Failed to send'}
                    message={status === SENT ?
                        'Your request has been successfully submitted. A member of our team will be in touch shortly to discuss your inquiry.' :
                        'Oops, something went wrong while trying to send this request. Please try again or contact us if you see this message again.'
                    }
                    onClose={this.closeModal}
                    buttons={[{
                        ui: 'citrus',
                        label: 'close',
                        onClick: this.closeModal
                    }]}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.shared.profile,
        status: state.shared.mail.status
    };
};

const mapDispatchToProps = (dispatch) => ({
    sendMail: bindActionCreators(sendMailV1, dispatch)
});

WidgetCard.propTypes = {
    widget: PropTypes.object.isRequired,
    isUnlocked: PropTypes.bool.isRequired,
    onWidgetSelect: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetCard);
