import React from 'react'
import { config } from '../../../../config'
import moment from 'moment-timezone'
import { format } from '../../../../utils/number.util'
import { RouteLink } from '../../../../components'
import './peerAlertsItem.component.css'

const getTranscriptLink = (key) => {
  const pdfViewerURL = 'resources/pdfPreview/web/viewer.html?file='
  return config.desktopUrl + '/' + pdfViewerURL + encodeURIComponent(key.replace('//g', '+'))
}

const getEventDate = (event) => {
  if (!event) {
    return
  }

  if (event.assets.event_type === 'Conference') {
    return <span>{moment.utc(event.date).format('MM/DD/YYYY')}</span>
  } else {
    const day = moment.utc(event.date).format('MM/DD/YYYY')

    switch (event.assets.market_time) {
      case 'Specific Time':
        return moment(event.date).calendar()
      case 'Before Market':
      case 'After Market':
        return <span>{day}</span>
      default:
        return <span>{day}</span>
    }
  }
}

const renderNewsItem = (ticker, item) => {
  return (
    <div className='alerts-list-item alerts-list-item--news'>
      <div className='alerts-list-item_content'>
        <div className='alerts-list-item_type'>
          <i className='icon q4i-releases-2pt' />
        </div>
        <div className='alerts-list-item_body'>
          <div className='alerts-list-item_title'>
            <h2>{item.title}</h2>
          </div>
          {item.assets.url ? <RouteLink className='alerts-list-item_link' target='_blank' to={item.assets.url}>Read
                        Article
          </RouteLink> : ''}
        </div>
      </div>
      <div className='alerts-list-item_footer'>
        <span className='alerts-list-item_security'>
          <span>{ticker}</span>
        </span>
        <span className='alerts-list-item_date'>
          <i className='icon q4i-calendar-4pt' />
          {moment(item.date).calendar()}
        </span>
      </div>
    </div>
  )
}

const renderTranscriptsItem = (ticker, item) => {
  let transcriptUrl = null

  if (item.assets.reports && item.assets.reports.length) {
    transcriptUrl = item.assets.reports[0].transcript_url
  }

  return (
    <div className='alerts-list-item alerts-list-item--transcripts'>
      <div className='alerts-list-item_content'>
        <div className='alerts-list-item_type'>
          <i className='icon q4i-transcript-4pt' />
        </div>
        <div className='alerts-list-item_body'>
          <div className='alerts-list-item_title'>
            <h2>{item.title}</h2>
          </div>
          {item.assets.press_release_url
            ? <RouteLink className='alerts-list-item_link' target='_blank' to={item.assets.press_release_url}>Read
                            Press Release
            </RouteLink> : ''}

          {transcriptUrl ? <RouteLink
            className='alerts-list-item_link' target='_blank'
            to={`${getTranscriptLink(transcriptUrl)}`}
          >View
                            transcript
          </RouteLink>
            : ''}
        </div>
      </div>
      <div className='alerts-list-item_footer'>
        <span className='alerts-list-item_security'>
          <i className='icon q4i-security-4pt' />
          <span>{ticker}</span>
        </span>
        <span className='alerts-list-item_date'>
          <i className='icon q4i-calendar-4pt' />
          {getEventDate(item)}
        </span>
      </div>
    </div>
  )
}

const renderEventsItem = (ticker, item) => {
  let webcastUrl; let transcriptUrl = null

  if (item.assets.reports && item.assets.reports.length) {
    webcastUrl = item.assets.reports[0].webcast_replay
    transcriptUrl = item.assets.reports[0].transcript_url
  }

  return (
    <div className='alerts-list-item alerts-list-item--events'>
      <div className='alerts-list-item_content'>
        <div className='alerts-list-item_type'>
          <i className='icon q4i-transcript-4pt' />
        </div>
        <div className='alerts-list-item_body'>
          <div className='alerts-list-item_title'>
            <h2>{item.title}</h2>
          </div>
          {webcastUrl
            ? <RouteLink className='alerts-list-item_link' target='_blank' to={webcastUrl}>View webcast</RouteLink> : ''}

          {transcriptUrl ? <RouteLink
            className='alerts-list-item_link' target='_blank'
            to={`${getTranscriptLink(transcriptUrl)}`}
          >View
                            transcript
          </RouteLink>
            : ''}
        </div>
      </div>
      <div className='alerts-list-item_footer'>
        <span className='alerts-list-item_security'>
          <i className='icon q4i-security-4pt' />
          <span>{ticker}</span>
        </span>
        <span className='alerts-list-item_date'>
          <i className='icon q4i-calendar-4pt' />
          {getEventDate(item)}
        </span>
      </div>
    </div>
  )
}

const renderResearchItem = (ticker, item, downloadItem) => {
  const params = {
    key: item.assets.key,
    file: {
      name: item.title,
      type: 'pdf'
    }
  }

  return (
    <div className='alerts-list-item alerts-list-item--events'>
      <div className='alerts-list-item_content'>
        <div className='alerts-list-item_type'>
          <i className='icon q4i-estimates-research-2pt' />
        </div>
        <div className='alerts-list-item_body'>
          <div className='alerts-list-item_title'>
            <h2>{item.title}</h2>
          </div>
          <span className='alerts-list-item_link' onClick={() => downloadItem(params)}>Download report</span>
        </div>
      </div>
      <div className='alerts-list-item_footer'>
        <span className='alerts-list-item_security'>
          <i className='icon q4i-security-4pt' />
          <span>{ticker}</span>
        </span>
        <span className='alerts-list-item_date'>
          <i className='icon q4i-calendar-4pt' />
          {moment(item.date).calendar()}
        </span>
      </div>
    </div>
  )
}

const renderEstimatesItem = (ticker, item) => {
  if (!item || !item.type || !item.assets) {
    return
  }

  const { securityId } = this.props
  const title = `${getNormalizedEstimatesDate(item)} ${item.assets.estimateType} Estimate changed ${item.assets.prevValue ? `from ${format(item.assets.prevValue, 2)}` : ''} to ${format(item.assets.value, 2)} by ${item.title}`

  return (
    <div className='alerts-list-item alerts-list-item--events'>
      <div className='alerts-list-item_content'>
        <div className='alerts-list-item_type'>
          <i className='icon q4i-estimates-research-2pt' />
        </div>
        <div className='alerts-list-item_body'>
          <div className='alerts-list-item_title'>
            <h2>{title}</h2>
          </div>

          <RouteLink
            className='alerts-list-item_link' target='_blank'
            to={`company/${securityId}/estimates`}
          >View
                        Estimates
          </RouteLink>
        </div>
      </div>
      <div className='alerts-list-item_footer'>
        <span className='alerts-list-item_security'>
          <i className='icon q4i-security-4pt' />
          <span>{ticker}</span>
        </span>
        <span className='alerts-list-item_date'>
          <i className='icon q4i-calendar-4pt' />
          {moment(item.date).calendar()}
        </span>
      </div>
    </div>
  )
}

const getNormalizedEstimatesDate = (item) => {
  switch (item.assets.frequency) {
    case 'quarterly':
      return `${moment(item.date).format('MMM YY')} Q${moment(item.date).get('quarter')}`
    case 'annual':
      return `FY ${moment(item.date).get('year')}`
    default:
      return `${moment(item.date).format('MMM YY')}`
  }
}

const PeerAlertsItem = (props) => {
  let alertItem = null

  switch (props.item.type) {
    case 'news':
      alertItem = renderNewsItem(props.ticker, props.item)
      break
    case 'transcripts':
      alertItem = renderTranscriptsItem(props.ticker, props.item)
      break
    case 'events':
      alertItem = renderEventsItem(props.ticker, props.item)
      break
    case 'research':
      alertItem = renderResearchItem(props.ticker, props.item, props.downloadItem)
      break
    case 'estimates' :
      alertItem = renderEstimatesItem(props.ticker, props.item)
      break
    default:
      break
  }
  return <li>{alertItem}</li>
}

export default PeerAlertsItem
