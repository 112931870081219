import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './chips.component.css'

/**
 * Chips Component
 */
class Chips extends Component {
  /**
   * Render Chips
   * @returns {*}
   */
  render () {
    const { className, theme, direction, size, items, itemKey, onClick, onRemove } = this.props

    const componentClass = [
      className ? `${className} chips` : 'chips',
      theme ? `chips--${theme}` : '',
      direction ? `chips--${direction}` : 'chips--horizontal',
      size ? `chips--${size}` : '',
      onClick ? 'chips--clickable' : ''
    ].join(' ')

    return (
      <div className={componentClass}>
        {(items || []).map((chip, index) => {
          const label = itemKey ? chip[itemKey] : chip
          return (
            <article key={`chip-${index}`} className='chips_chip'>
              <label className='chips_label' onClick={(event) => onClick && onClick(event, chip, index)}>{label}</label>
              <span className='chips_close' onClick={() => onRemove && onRemove(chip, index)}>
                <i className='q4i-close-4pt' />
              </span>
            </article>
          )
        })}
      </div>
    )
  }
}

Chips.propTypes = {
  /**
   * A custom className to add to the component
   */
  className: PropTypes.string,

  /**
   * Used to paint the chips using a specific theme
   */
  theme: PropTypes.string,

  /**
   * Used to determine item render direction
   */
  direction: PropTypes.oneOf(['horizontal', 'vertical']),

  /**
   * Used to determine chip size
   */
  size: PropTypes.oneOf(['default', 'tall', 'input']),

  /**
   * An array of items to render
   * If supplying an array of objects, provide an itemKey to determine what is used as a label
   */
  items: PropTypes.array.isRequired,

  /**
   * When providing an array of objects, used to specify the object's target key to use as the chip item label
   */
  itemKey: PropTypes.string,

  /**
   * A callback for when the user clicks on the chip's label
   */
  onClick: PropTypes.func,

  /**
   * A callback for when the user clicks on the chip's close icon
   */
  onRemove: PropTypes.func.isRequired
}

Chips.defaultProps = {
  direction: 'horizontal',
  size: 'default',
  items: []
}

export default Chips
