import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { intersection } from 'lodash'
import { getClassName } from '../../utils/ui/ui.util'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderDarkThumb, renderTrackVertical } from '../../resources/theme/q4.custom-scrollbar'
import DrawerLibraryCategoryList from './categoryList/drawerLibraryCategoryList.component'
import DrawerLibraryCard from './card/drawerLibraryCard.component'
import { Button } from '../../components'
import './drawerLibrary.component.css'

/**
 * Drawer Library Component
 */
class DrawerLibrary extends PureComponent {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      categorySelection: null,
      subcategorySelection: null
    }

    this.appScrollableContainer = document.getElementById('app-scrollable')

    if (props.isOpen) {
      this.appScrollableContainer.classList.add('overflow-hidden')
    }
  }

  /**
   * ComponentDidUpdate
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    const { isOpen } = this.props

    if (!prevProps.isOpen && isOpen) {
      this.appScrollableContainer.classList.add('overflow-hidden')
    }

    if (prevProps.isOpen && !isOpen) {
      this.appScrollableContainer.classList.remove('overflow-hidden')

      this.setState({
        categorySelection: null,
        subcategorySelection: null
      })
    }
  }

  /**
   * On Category select, open the widget selection drawer
   * @param event
   * @param category
   * @param isPrimaryGroup
   */
  handleCategorySelect = (event, category, isPrimaryGroup) => {
    const { onCategorySelect } = this.props

    this.setState(isPrimaryGroup ? {
      categorySelection: category,
      subcategorySelection: null
    } : {
      subcategorySelection: category
    })

    onCategorySelect && onCategorySelect(category, event)
  }

  /**
   * Handle clicking of the mask
   */
  handleClose = () => {
    const { onClose } = this.props

    this.setState({
      categorySelection: null,
      subcategorySelection: null
    })

    onClose && onClose()
  }

  /**
   * Get an array of selected category values
   * @returns {Array}
   */
  getSelectedCategories = () => {
    const { categorySelection, subcategorySelection } = this.state
    const selectedCategories = []

    if (categorySelection) {
      selectedCategories.push(categorySelection.value)
    }

    if (subcategorySelection) {
      selectedCategories.push(subcategorySelection.value)
    }

    return selectedCategories
  }

  /**
   * Render DrawerLibrary Categories Pane
   * @param isPrimaryGroup
   * @param categories
   * @param categoryAction
   * @returns {*}
   */
  renderCategoryPane = (isPrimaryGroup, categories, categoryAction) => {
    const { categorySelection, subcategorySelection } = this.state

    const paneClassName = getClassName('drawer-library_pane drawer-library_pane--categories', [
      {
        condition: isPrimaryGroup,
        trueClassName: 'drawer-library_pane--primary',
        falseClassName: 'drawer-library_pane--secondary'
      },
      { condition: categoryAction, trueClassName: 'drawer-library_pane--with-action' }
    ])

    return (
      <section className={paneClassName}>
        {categories && categories.length > 0 && (
          <div className='drawer-library_pane-body'>
            <Scrollbars className='react-scrollbar' autoHide hideTracksWhenNotNeeded
                        renderThumbVertical={renderDarkThumb} renderTrackVertical={renderTrackVertical}>
              <DrawerLibraryCategoryList
                isPrimaryGroup={isPrimaryGroup}
                categories={categories}
                selectedCategory={isPrimaryGroup ? categorySelection : subcategorySelection}
                onSelect={this.handleCategorySelect}
              />
            </Scrollbars>
          </div>
        )}
        {categoryAction && (
          <footer className='drawer-library_pane-footer'>
            <Button theme='charcoal' tall {...categoryAction} />
          </footer>
        )}
      </section>
    )
  }

  /**
   * Render DrawerLibrary Items Pane
   */
  renderItemPane = () => {
    const { items, unavailableSelectionMessage, noSelectionMessage, onItemSelect } = this.props

    const selectedCategories = this.getSelectedCategories()
    const filteredItems = items.filter((item) => intersection(selectedCategories, item.categories || []).length === selectedCategories.length)

    const emptySelection = (filteredItems && filteredItems.length > 0)
    const availableSelection = (filteredItems || []).filter((item) => !item.isDisabled)
    const unavailableSelection = (filteredItems || []).filter((item) => item.isDisabled)

    const handleItemSelect = (item) => (event) => {
      onItemSelect && onItemSelect(item, event)
    }

    return (
      <section className='drawer-library_pane drawer-library_pane--items'>
        {emptySelection ? (
          <Scrollbars
            className='react-scrollbar'
            autoHide
            hideTracksWhenNotNeeded
            renderThumbVertical={renderDarkThumb}
            renderTrackVertical={renderTrackVertical}
          >
            <div className='drawer-library_pane-body'>
              {availableSelection.length > 0 && availableSelection.map((item) => {
                const { id, title, description, isLockedTemplate } = item

                return (
                  <DrawerLibraryCard
                    key={`drawer-library-card--${id}`}
                    title={title}
                    description={description}
                    isLockedTemplate={isLockedTemplate}
                    onSelect={handleItemSelect(item)}
                  />
                )
              })}
            </div>
            {unavailableSelection.length > 0 && (
              <div className='drawer-library_pane-body'>
                <h2 className='drawer-library_title'>Unavailable</h2>
                <p
                  className='drawer-library_message'>{unavailableSelectionMessage || 'The following items are unavailable.'}</p>
                {unavailableSelection.map((item) => {
                  const { id, title, description, isLockedTemplate } = item

                  return (
                    <DrawerLibraryCard
                      key={`drawer-library-card--${id}`}
                      title={title}
                      description={description}
                      isDisabled={true}
                      isLockedTemplate={isLockedTemplate}
                      onSelect={handleItemSelect(item)}
                    />
                  )
                })}
              </div>
            )}
          </Scrollbars>
        ) : (
          <div className='drawer-library_pane-body'>
            <h2 className='drawer-library_title'>Unavailable</h2>
            <p className='drawer-library_message'>{noSelectionMessage || 'Nothing to choose from.'}</p>
          </div>
        )}
      </section>
    )
  }

  /**
   * Render Widget Library
   * @returns {*}
   */
  render () {
    const { className, libraryTitle, isOpen, categories, categoryAction } = this.props
    const { categorySelection, subcategorySelection } = this.state
    const subcategories = (categorySelection && categorySelection.children)
    const hasSubcategories = subcategories && subcategories.length > 0

    const baseClassName = getClassName('drawer-library', [
      { condition: className, trueClassName: className },
      { condition: isOpen, trueClassName: 'drawer-library--open', falseClassName: 'drawer-library--closed' },
      { condition: hasSubcategories, trueClassName: 'drawer-library--with-subcategories' },
      { condition: categorySelection, trueClassName: 'drawer-library--with-category-selection' },
      { condition: subcategorySelection, trueClassName: 'drawer-library--with-subcategory-selection' }
    ])

    return (
      <div className={baseClassName}>
        <div className='drawer-library_inner'>
          <header className='drawer-library_header'>
            <h2 className='drawer-library_title'>Select {libraryTitle}</h2>
            <div className='drawer-library_close' onClick={this.handleClose}>
              <i className='q4i-close-4pt' />
            </div>
          </header>
          <div className='drawer-library_body'>
            {this.renderCategoryPane(true, categories, categoryAction)}
            {this.renderCategoryPane(false, hasSubcategories && subcategories)}
            {(hasSubcategories ? subcategorySelection : categorySelection) && this.renderItemPane()}
          </div>
        </div>
        <div className='drawer-library_mask' onClick={this.handleClose} />
      </div>
    )
  }
}

DrawerLibrary.propTypes = {
  /**
   * A custom className to pass into the radio button component
   */
  className: PropTypes.string,

  /**
   * Used to determine whether or not the DrawerLibrary is open / visisble
   */
  isOpen: PropTypes.bool.isRequired,

  /**
   * Used to determine what the user is choosing from the library
   */
  libraryTitle: PropTypes.string,

  /**
   * An array of categories to supply to the component
   */
  categories: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  })).isRequired,

  /**
   * Array of actions to render in the category footer section
   * Note: Standard button props
   */
  categoryAction: PropTypes.shape({
    label: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired
  }),

  /**
   * An array of items to supply to the component
   */
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    isLockedTemplate: PropTypes.bool,
    isDisabled: PropTypes.bool,
    categories: PropTypes.arrayOf(PropTypes.string)
  })),

  /**
   * The message to display for items that are not available for selection
   */
  unavailableSelectionMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * The message to display if no item selection is available
   */
  noSelectionMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * A callback for when a category is selected
   * Note: Whole category is sent as argument
   */
  onCategorySelect: PropTypes.func,

  /**
   * A callback for when an item is selected
   * Note: Whole item is sent as argument
   */
  onItemSelect: PropTypes.func,

  /**
   * A Callback for when the drawer library requests to be closed
   */
  onClose: PropTypes.func.isRequired
}

DrawerLibrary.defaultProps = {
  libraryTitle: 'Category',
  categories: [],
  items: []
}

export default DrawerLibrary
