import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Select } from '../../../../components'
import { getClassName, THEMES } from '../../../../utils'
import { getIconClass } from '../../../../utils/report'
import './reportGroups.component.css'

/**
 * Custom Option Render for Dropdown Component
 * @param option
 */
function ReportGroupDropdownOption ({ name, label }) {
  return (
    <>
      <span className={`report-groups_item-icon report-groups_item-icon--${name}`}>
        <i className={getIconClass(name)} />
      </span>
      <span className='report-groups_item-label'>{label}</span>
    </>
  )
}

/**
 * ReportGroup Component
 * @param isPrimary
 * @param group
 * @param onGroupRemove
 * @returns {*}
 * @constructor
 */
function ReportGroup ({ isPrimary, group, onGroupRemove }) {
  const handleGroupRemove = () => onGroupRemove(isPrimary, group)

  return (
    <div className='report-groups_item-wrap'>
      <span className={`report-groups_item-icon report-groups_item-icon--${group.name}`}>
        <i className={getIconClass(group.name)} />
      </span>
      <span className='report-groups_item-label'>{group.label}</span>
      <span className='report-groups_item-action report-groups_item-action--close' onClick={handleGroupRemove}>
        <i className='q4i-close-4pt' />
      </span>
    </div>
  )
}

/**
 * ReportGroups Component
 * @param props
 * @returns {*}
 * @constructor
 */
function ReportGroups (props) {
  const { groups, availableGroups, onGroupAdd, onGroupRemove } = props

  const isPrimarySelected = !!(groups && groups.length && groups[0])

  const shouldShowPrimaryGroups = !groups || !groups.length
  const shouldShowSecondaryGroups = (groups || []).length === 1 && (availableGroups || []).length > 0

  const baseClassName = getClassName('report-groups', [
    { condition: shouldShowPrimaryGroups, trueClassName: 'report-groups--empty' },
    { condition: isPrimarySelected && shouldShowSecondaryGroups, trueClassName: 'report-groups--half-full' },
    { condition: (groups || []).length === 2, trueClassName: 'report-groups--twice-full' },
    { condition: (groups || []).length === 1 && !shouldShowSecondaryGroups, trueClassName: 'report-groups--once-full' }
  ])

  const selectGroupOptions = [].concat(availableGroups || [])
    .map((group) => group && { label: ReportGroupDropdownOption(group), value: group })

  return (
    <section className={baseClassName}>
      {isPrimarySelected && (
        <article className={`report-groups_item ${groups.length > 1 ? 'report-groups_item--expanded' : ''}`}>
          <ReportGroup
            isPrimary
            group={groups[0]}
            onGroupRemove={onGroupRemove}
          />
          {(groups.length > 1) && (
            <ul className='report-groups_children'>
              {groups.map((group, index) => {
                return !!index && (
                  <li key={`report-group--${group._id}`} className='report-groups_child q4-fade-in'>
                    <article className='report-groups_item'>
                      <ReportGroup
                        group={group}
                        onGroupRemove={onGroupRemove}
                      />
                    </article>
                  </li>
                )
              })}
            </ul>
          )}
        </article>
      )}

      {shouldShowPrimaryGroups && (
        <Select
          theme={THEMES.WHITE}
          placeholder='Add Data'
          size='thin'
          options={selectGroupOptions}
          onChange={(option) => option.value && onGroupAdd(option.value)}
          portal
          clearable={false}
          searchable={false}
          setAsAbsolutePosition
        />
      )}
      {shouldShowSecondaryGroups && (
        <Select
          className='report-groups_dropdown report-groups_dropdown--secondary'
          theme={THEMES.WHITE}
          placeholder='Add Data'
          size='thin'
          options={selectGroupOptions}
          onChange={(option) => option.value && onGroupAdd(option.value)}
          portal
          clearable={false}
          searchable={false}
          setAsAbsolutePosition
        />
      )}
    </section>
  )
}

ReportGroups.propTypes = {
  groups: PropTypes.array.isRequired,
  availableGroups: PropTypes.array.isRequired,
  onGroupAdd: PropTypes.func.isRequired,
  onGroupRemove: PropTypes.func.isRequired
}

ReportGroups.defaultProps = {
  groups: [],
  availableGroups: []
}

export default memo(ReportGroups)
