import { combineReducers } from 'redux'

import reportDataList from './reportDataList.reducer'
import reportDataItem from './reportDataItem.reducer'
import reportDataTemplates from './reportDataTemplates.reducer'

import reportBuilderList from './reportBuilderList.reducer'
import reportBuilderItem from './reportBuilderItem.reducer'

import reportEntityTypes from './reportEntityTypes.reducer'
import reportFields from './reportFields.reducer'
import reportBuilderFields from './reportBuilderFields.reducer'
import reportSnapshot from './reportSnapshot.reducer'
import reportDataConfig from './reportDataConfig.reducer'
import reportDataVisualization from './reportDataVisualization.reducer'
import reportPeerConfig from './reportPeerConfig.reducer'
import reportCustomTemplates from './reportCustomTemplates.reducer'

export default combineReducers({
  reportDataList,
  reportDataItem,
  reportDataTemplates,
  reportBuilderList,
  reportBuilderItem,
  reportEntityTypes,
  reportFields,
  reportBuilderFields,
  reportSnapshot,
  reportDataConfig,
  reportDataVisualization,
  reportPeerConfig,
  reportCustomTemplates
})
