import {
  FETCH_SELLERS_QR_REQUEST,
  FETCH_SELLERS_QR_SUCCESS,
  FETCH_SELLERS_QR_ERROR,
  FETCHING,
  FETCHED,
  FAILED,
  IDLE
} from '../../../actions/widget/ownership'

const initial = {
  holders: [],
  status: IDLE
}

const sellersQr = (state = initial, action) => {
  switch (action.type) {
    case FETCH_SELLERS_QR_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_SELLERS_QR_SUCCESS:
      let qr = {}

      if (Array.isArray(action.payload) && action.payload.length) {
        qr = (action.payload[0] && action.payload[0].sell) || {}
      } else {
        qr = action.payload
      }

      return {
        ...state,
        qr,
        status: FETCHED
      }
    case FETCH_SELLERS_QR_ERROR:
      return {
        ...state,
        error: action.error,
        status: FAILED
      }
    default:
      return state
  }
}

export default sellersQr
