import { get } from 'lodash'
import PropTypes from 'prop-types'

import { getClassName, THEMES } from '../../../../../utils'
import { Button } from '../../../../../components'
import AddressBookListEdit from '../listEdit/listEdit.component'


function ContactLists (props) {

  const {
    lists,
    onListEdit,
    openModal,
    onListDelete,
    onParamsChange,
    onListSelect,
    listCount,
    selectedListId,
    setSelectedListId
  } = props

  /**
   * Handle list create
   */
  const handleListCreate = () => {
    _onListEdit()
  }

  /**
   * Handle list edit
   * @param event
   * @param list
   */
  const handleListEdit = (event, list) => {
    event.stopPropagation()
    _onListEdit(list)
  }

  /**
   * Open list create/update modal
   * @param list
   */
  const _onListEdit = (list = {}) => {
    openModal({
      component: ({ onClose }) => (
        <AddressBookListEdit
          list={list}
          lists={lists}
          onSave={onListEdit}
          onClose={onClose}
        />
      )
    })
  }

  /**
   * Handle list select
   * @param list
   */
  const handleListSelect = (list) => {
    const listId = get(list, '_id', 'all')

    setSelectedListId(listId)
    onParamsChange({ list: listId, page: 1 })
    onListSelect({ selectedList: list })
  }



  /**
   * Handle list delete
   * @param event
   * @param list
   */
  const handleListDelete = (event, list) => {
    event.stopPropagation()
    onListDelete(list)
  }


  return (
    <>
      <div className='list-header'>
        <label className='label'>New List</label>
        <Button
          className='create-new-list'
          theme={THEMES.CITRUS}
          icon='q4i-add-4pt'
          tooltip='ADD'
          onClick={handleListCreate}
        />
      </div>

      <div className='lists'>
        {[].concat({ _id: 'all', name: 'All Saved Contacts' }, lists || []).map((list) => {
          const { _id, name } = list

          if (!_id || !name) {
            return null
          }

          return (
            <div
              key={_id}
              className={getClassName('lists_item', [{
                condition: (selectedListId === _id), trueClassName: 'lists_item--selected'
              }])}
              onClick={() => handleListSelect(list)}
            >
              <div className='lists_item-detail'>
                <div className='lists_label'>{name}</div>
                <div className='lists_count'>({(listCount && listCount[_id]) || 0})</div>
              </div>
              {(_id !== 'all') && (
                <div className='lists_item-actions q4-fade-in'>
                  <i className='lists_icon q4i-edit-4pt' onClick={(event) => handleListEdit(event, list)} />
                  <i className='lists_icon q4i-trashbin-4pt' onClick={(event) => handleListDelete(event, list)} />
                </div>
              )}
            </div>
          )

        })}
      </div>
    </>
  )
}

ContactLists.propTypes = {
  onListEdit: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  onListDelete: PropTypes.func.isRequired,
  onParamsChange: PropTypes.func.isRequired,
  onListSelect: PropTypes.func.isRequired,
  listCount: PropTypes.object,
  selectedListId: PropTypes.string,
  setSelectedListId: PropTypes.func.isRequired
}

export default ContactLists