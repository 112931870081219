import { PureComponent } from 'react'
import ReactDOM from 'react-dom'

const portalId = 'app-portal'

class Portal extends PureComponent {

  constructor (props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount () {
    this.portalRoot = document.getElementById(portalId)
    this.portalRoot && this.portalRoot.appendChild(this.el)
  }

  componentWillUnmount () {
    this.portalRoot && this.portalRoot.removeChild(this.el)
  }

  render () {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

/**
 * Used to render children components into a DOM node that exists outside the app-root DOM hierarchy
 */
export default Portal