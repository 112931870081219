import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// actions
import { useCommentQuery } from '../../hook'
import { modalType, openModal } from '../../../../actions/ui'

// components
import CommentCard from './card.component'

// utils
import { ENTITY_TYPE, THEMES } from '../../../../utils'
import { get } from 'lodash'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  entity: PropTypes.shape({
    entityId: PropTypes.string.isRequired,
    entityType: PropTypes.oneOf([CONTACT, FUND, INSTITUTION])
  })
}

const defaultProps = {
  theme: THEMES.DARK,
  entity: {}
}

/**
 * Entity Comment
 * @param props
 */
function EntityComment (props) {
  const { dataId, theme, entity, openModal } = props
  const { entityId, entityType } = entity

  const { loading, data, refetch } = useCommentQuery({ variables: { entityId } })

  /**
   * Handle comment edit
   */
  const handleEdit = () => {
    entityId && openModal({
      type: modalType.ENTITY_COMMENT_EDIT_MODAL,
      props: {
        entity,
        isCard: true,
        comment: get(data, 'comment.items[0]', {}),
        onSaveSuccess: refetch
      }
    })
  }

  return <CommentCard
    dataId={dataId}
    theme={theme}
    isLoading={loading}
    placeholder={`No ${entityType} comments`}
    comment={get(data, 'comment.items[0].comment', '')}
    onEdit={handleEdit}
  />
}

EntityComment.propTypes = propTypes
EntityComment.defaultProps = defaultProps

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

export default connect(null, mapDispatchToProps)(EntityComment)
