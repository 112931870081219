export const SET_REPORT_DATA_CONFIG = 'SET_REPORT_DATA_CONFIG'
export const RESET_REPORT_DATA_CONFIG = 'RESET_REPORT_DATA_CONFIG'

export const setReportDataConfig = (config) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({ type: SET_REPORT_DATA_CONFIG, payload: config })
    resolve()
  })
}

export const resetReportDataConfig = () => (dispatch) => {
  dispatch({ type: RESET_REPORT_DATA_CONFIG })
}
