import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './formikTextInput.component.css'

/**
 * @deprecated - use TextInput instead
 */
class FormikTextInput extends Component {
  render () {
    const {
      id,
      ui,
      theme,
      className,
      placeholder,
      type,
      values,
      errors,
      serverError,
      touched,
      handleChange,
      handleBlur,
      errorTooltip
    } = this.props

    return (
      <div
        className={`formik-text-input${(errors[id] && (errors[id].indexOf('required') !== -1) && touched[id] && ' formik-text-input--error') || ''} ${(ui && `formik-text-input--${ui}`) || ''}`}>
        {errorTooltip && (errors[id] && (errors[id].indexOf('required') === -1) && touched[id]) ?
          <div className='tooltip tooltip--error tooltip--error--right'>{errors[id]}</div>
          : serverError ? <div className='tooltip tooltip--error tooltip--error--right'>{serverError}</div> : null}
        <input
          id={id}
          className={`${className} ${theme ? `${className}--${theme}` : ''}`}
          placeholder={placeholder}
          type={type}
          value={values[id]}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    )
  }
}

FormikTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  ui: PropTypes.string,
  theme: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  errorTooltip: PropTypes.bool
}

FormikTextInput.defaultProps = {
  className: 'formik-text-input_field',
  placeholder: '',
  type: 'text',
  errorTooltip: true
}

export default FormikTextInput
