import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

/**
 * Target Action Type Constants
 * @type {string}
 */
export const GET_ALL_SAVED_TARGETS_REQUEST = 'GET_ALL_SAVED_TARGETS_REQUEST'
export const GET_ALL_SAVED_TARGETS_SUCCESS = 'GET_ALL_SAVED_TARGETS_SUCCESS'
export const GET_ALL_SAVED_TARGETS_FAILURE = 'GET_ALL_SAVED_TARGETS_FAILURE'

export const GET_PAGINATED_SAVED_TARGETS_REQUEST = 'GET_PAGINATED_SAVED_TARGETS_REQUEST'
export const GET_PAGINATED_SAVED_TARGETS_SUCCESS = 'GET_PAGINATED_SAVED_TARGETS_SUCCESS'
export const GET_PAGINATED_SAVED_TARGETS_FAILURE = 'GET_PAGINATED_SAVED_TARGETS_FAILURE'

export const DELETE_SAVED_TARGETS_REQUEST = 'DELETE_SAVED_TARGETS_REQUEST'
export const DELETE_SAVED_TARGETS_SUCCESS = 'DELETE_SAVED_TARGETS_SUCCESS'
export const DELETE_SAVED_TARGETS_FAILURE = 'DELETE_SAVED_TARGETS_FAILURE'

export const RESET_PAGINATED_SAVED_TARGETS_REQUEST = 'RESET_PAGINATED_SAVED_TARGETS_REQUEST'

export const FILTER_ALL_TARGETS = 'FILTER_ALL_TARGETS'

/**
 * Get Pipeline for user's organization
 * @private
 */
const _getAllSavedTargets = () => ({
  [CALL_API]: {
    types: [
      GET_ALL_SAVED_TARGETS_REQUEST,
      GET_ALL_SAVED_TARGETS_SUCCESS,
      GET_ALL_SAVED_TARGETS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/targeting/target/all'
  }
})

/**
 * Get paginated saved targets
 * @private
 */
const _getPaginatedSavedTargets = (query) => ({
  [CALL_API]: {
    types: [
      GET_PAGINATED_SAVED_TARGETS_REQUEST,
      GET_PAGINATED_SAVED_TARGETS_SUCCESS,
      GET_PAGINATED_SAVED_TARGETS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/targeting/target',
    payload: query
  }
})

/**
 * Delete saved targets
 * @private
 */
const _deleteSavedTargets = (query) => ({
  [CALL_API]: {
    types: [
      DELETE_SAVED_TARGETS_REQUEST,
      DELETE_SAVED_TARGETS_SUCCESS,
      DELETE_SAVED_TARGETS_FAILURE
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: '/targeting/target',
    payload: query
  }
})

const _filterSavedTargets = (filter) => {
  return {
    type: FILTER_ALL_TARGETS,
    filter
  }
}

/**
 * Dispatch GET_ALL_TARGETS_REQUEST
 */
export const getAllSavedTargets = () => (dispatch) => {
  return dispatch(_getAllSavedTargets())
}

/**
 * Dispatch GET_PAGINATED_TARGETS_REQUEST
 */
export const getPaginatedSavedTargets = (params) => (dispatch) => {
  return dispatch(_getPaginatedSavedTargets(params))
}

/**
 * Filter existing targets by search value
 * @param filter
 * @returns {function(*): *}
 */
export const filterAllSavedTargets = (filter) => (dispatch) => {
  return dispatch(_filterSavedTargets(filter))
}

/**
 * Delete saved targets
 */
export const deleteSavedTargets = (ids) => (dispatch) => {
  return dispatch(_deleteSavedTargets({ ids }))
}

/**
 * Resets paginates saved targets state
 */
export const resetPaginatedSavedTargets = () => (dispatch) => {
  return dispatch({ type: RESET_PAGINATED_SAVED_TARGETS_REQUEST })
}
