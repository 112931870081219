import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'

import CustomCell from './cell/cell.component'
import { AgGrid, NoContentMessage, Spinner } from '../../../../../components'
import { setColumnDefinition, THEMES } from '../../../../../utils'

const propTypes = {
  pageSizeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number,
  listOptions: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  onQueryChange: PropTypes.func.isRequired
}

const defaultProps = {
  loading: false,
  data: [],
  total: 0,
  listOptions: {}
}

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'headline',
    headerName: 'Headline',
    minWidth: 560
  }]

/**
 * Contact Research Table Component
 * @param props
 */
function ContactResearchTable (props) {
  const { pageSizeId, loading, data, total, listOptions, onQueryChange } = props
  const [agGrid, setAgGrid] = useState({})

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle page change
   * @param selected
   */
  const handlePageChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, page: selected }
    })
  }

  /**
   * Handle page size change
   * @param selected
   */
  const handlePageSizeChange = ({ selected }) => {
    onQueryChange({
      listOptions: { limit: selected, page: 1 }
    })
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell {...props} />
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage />
        : <AgGrid
          domLayout='autoHeight'
          sizeToFit

          // suppress configs
          suppressMovableColumns
          suppressContextMenu

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={setColumnDefinition({ columns, isLocked: false })}
          rowData={data}

          // pagination
          pagination
          paginationProps={{
            pageSizeId,
            forcePage: listOptions.page,
            initialPageSize: listOptions.limit,
            showPageSizeSelection: true,
            total,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: renderCustomCell
          }}

          // event listeners
          onGridReady={handleGridReady}
          onGridSizeChanged={handleGridResize}
        />}
    </div>
  )
}

ContactResearchTable.propTypes = propTypes
ContactResearchTable.defaultProps = defaultProps

export default memo(ContactResearchTable)
