import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const GET_REPORT_ENTITY_TYPES_REQUEST = 'GET_REPORT_ENTITY_TYPES_REQUEST'
export const GET_REPORT_ENTITY_TYPES_SUCCESS = 'GET_REPORT_ENTITY_TYPES_SUCCESS'
export const GET_REPORT_ENTITY_TYPES_ERROR = 'GET_REPORT_ENTITY_TYPES_ERROR'

/**
 * Get Report Fields
 * @private
 */
const _getReportEntityTypes = () => ({
  [CALL_API]: {
    types: [
      GET_REPORT_ENTITY_TYPES_REQUEST,
      GET_REPORT_ENTITY_TYPES_SUCCESS,
      GET_REPORT_ENTITY_TYPES_ERROR
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/report/dataItem/entityType'
  }
})

export const getReportEntityTypes = () => (dispatch) => {
  return dispatch(_getReportEntityTypes())
}
