import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

export const GET_INSTITUTION_CURRENT_POSITION_REQUEST = 'GET_INSTITUTION_CURRENT_POSITION_REQUEST'
export const GET_INSTITUTION_CURRENT_POSITION_SUCCESS = 'GET_INSTITUTION_CURRENT_POSITION_SUCCESS'
export const GET_INSTITUTION_CURRENT_POSITION_FAILURE = 'GET_INSTITUTION_CURRENT_POSITION_FAILURE'

export const GET_INSTITUTION_HISTORICAL_POSITION_REQUEST = 'GET_INSTITUTION_HISTORICAL_POSITION_REQUEST'
export const GET_INSTITUTION_HISTORICAL_POSITION_SUCCESS = 'GET_INSTITUTION_HISTORICAL_POSITION_SUCCESS'
export const GET_INSTITUTION_HISTORICAL_POSITION_FAILURE = 'GET_INSTITUTION_HISTORICAL_POSITION_FAILURE'

export const RESET_INSTITUTION_CURRENT_POSITION = 'RESET_INSTITUTION_CURRENT_POSITION'

/**
 * Get Institution Current Position
 * @param params.factsetEntityId
 * @param params.securityId
 * @param options
 * @private
 */
const _fetchInstitutionCurrentPosition = (params = {}, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_INSTITUTION_CURRENT_POSITION_REQUEST,
      GET_INSTITUTION_CURRENT_POSITION_SUCCESS,
      GET_INSTITUTION_CURRENT_POSITION_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/ownership/v2/institution/${params.factsetEntityId}/position`,
    payload: params
  }
})

/**
 * Get Institution Historical Position
 * @param params.entityId - mongo id
 * @param params.securityId
 * @param options
 * @private
 */
const _fetchInstitutionHistoricalPosition = (params = {}, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_INSTITUTION_HISTORICAL_POSITION_REQUEST,
      GET_INSTITUTION_HISTORICAL_POSITION_SUCCESS,
      GET_INSTITUTION_HISTORICAL_POSITION_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/ownership/v2/institution/${params.entityId}/related`,
    payload: params
  }
})

/**
 * Get Institution Current Position
 * @param params
 * @param options
 */
export const fetchInstitutionCurrentPosition = (params, options) => (dispatch) => {
  if (!params || !params.factsetEntityId || (!params.securityId && !params.tickerId)) {
    return
  }
  return dispatch(_fetchInstitutionCurrentPosition(params, options))
}

/**
 * Get Institution Historical Position
 * @param params
 * @param options
 */
export const fetchInstitutionHistoricalPosition = (params, options) => (dispatch) => {
  if (!params || !params.entityId || !params.securityId) {
    return
  }
  return dispatch(_fetchInstitutionHistoricalPosition(params, options))
}

/**
 * Clean Institution Current POSITION
 */
export const cleanInstitutionCurrentPosition = () => (dispatch) => {
  return dispatch({ type: RESET_INSTITUTION_CURRENT_POSITION })
}
