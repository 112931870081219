import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { ENTITY_TYPE, OWNERSHIP_TYPE } from '../../../utils'

export const GET_PEER_ANALYSIS = gql`
  query PeerAnalysis(
    $tickerId: String
    $entityId: String! 
    $entityType: String!
    $holder: Boolean
    $currencyCode: String
  ) {
    marketValue: peerAnalysis(
      tickerId: $tickerId
      entityId: $entityId
      entityType: $entityType
      metric: "marketValue"
      currencyCode: $currencyCode
      filter: {
        holders: $holder
      }
    ) {
      average
      sum
      count
    }
    current: peerAnalysis(
      tickerId: $tickerId
      entityId: $entityId
      entityType: $entityType
      metric: "current"
      currencyCode: $currencyCode
      filter: {
        holders: $holder
      }
    ) {
      sum
    }
    qtrChange: peerAnalysis(
      tickerId: $tickerId
      entityId: $entityId
      entityType: $entityType
      metric: "qtrChange"
      currencyCode: $currencyCode
      filter: {
        holders: $holder
      }
    ) {
      sum
    }
  }`

const GET_INST_CURRENT_HOLDINGS = gql`
  query InstitutionHoldingCurrent(
    $entityId: [String]!
    $sector: [String]
    $capGroup: [String]
    $currencyCode: String
    $source: String = "${OWNERSHIP_TYPE['13F']}"
  ) {
    marketValue: instHoldingCurrent(
      currencyCode: $currencyCode
      institutionId: $entityId
      filter: {
        securitySector: $sector
        securityCapGroup: $capGroup
      }
      source: $source
    ) {
      sum(field: "marketValue")
    }
    marketValueQtrChange: instHoldingCurrent(
      currencyCode: $currencyCode
      institutionId: $entityId
      filter: {
        securitySector: $sector
        securityCapGroup: $capGroup
      }
      source: $source
    ) {
      sum(field: "marketValueQtrChange")
    }
  }`

const GET_FUND_CURRENT_HOLDINGS = gql`
  query FundHoldingCurrent(
    $entityId: [String]!
    $sector: [String]
    $capGroup: [String]
    $currencyCode: String
    $source: String = "${OWNERSHIP_TYPE['13F']}"
  ) {
    marketValue: fundHoldingCurrent(
      currencyCode: $currencyCode
      fundId: $entityId
      filter: {
        securitySector: $sector
        securityCapGroup: $capGroup
      }
      source: $source
    ) {
      sum(field: "marketValue")
    }
    marketValueQtrChange: fundHoldingCurrent(
      currencyCode: $currencyCode
      fundId: $entityId
      filter: {
        securitySector: $sector
        securityCapGroup: $capGroup
      }
      source: $source
    ) {
      sum(field: "marketValueQtrChange")
    }
  }`

/**
 * Peer Analysis
 * @param options
 */
export function usePeerAnalysisQuery (options) {
  return useQuery(GET_PEER_ANALYSIS, options)
}

/**
 * Current Holdings
 * @param type
 * @param options
 */
export function useCurrentHoldingQuery (type, options) {
  const query = [ENTITY_TYPE.FUND, ENTITY_TYPE.CONTACT].includes(type)
    ? GET_FUND_CURRENT_HOLDINGS
    : GET_INST_CURRENT_HOLDINGS

  return useQuery(query, options)
}
