import {
  GET_CONTACT_ESTIMATE_REQUEST,
  GET_CONTACT_ESTIMATE_SUCCESS,
  GET_CONTACT_ESTIMATE_FAILURE,
  statusType
} from '../../actions'

const initial = {
  status: statusType.IDLE
}

const estimate = (state = initial, action) => {
  const { payload, request } = action

  switch (action.type) {
    case GET_CONTACT_ESTIMATE_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_CONTACT_ESTIMATE_SUCCESS:
      return {
        ...state,
        [request.analyst_id]: (payload || []).sort((a, b) => new Date(b.date) - new Date(a.date)),
        status: statusType.SUCCESS
      }

    case GET_CONTACT_ESTIMATE_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    default:
      return state
  }
}

export default estimate
