import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { config } from '../../../config'
import ReactGridLayout from 'react-grid-layout-modified'
import { debounce } from 'lodash'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderDarkThumb, renderTrackVertical } from '../../../resources/theme/q4.custom-scrollbar'
import { NoContentMessage, Toolbar, ToolbarRow, Button } from '../../../components'
import PageWidget from '../widget/pageWidget.component'
import './pageEditor.component.css'

/**
 * Report Builder Page Editor Component
 */
class PageEditor extends PureComponent {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      isExpanded: false,

      sizeConfig: this.getSizeConfig()
    }

    this.isDragging = false
    this.isResizing = false

    this.paperContainer = React.createRef()
    this.updatePaperScale = debounce(this.updatePaperScale, 300)
  }

  /**
   * ComponentDidMount
   */
  componentDidMount () {
    this.props.setUpdatePaperScale && this.props.setUpdatePaperScale(this.updatePaperScale)

    window.addEventListener('resize', this.updatePaperScale)
    this.updatePaperScale()
  }

  componentDidUpdate (prevProps, prevState) {
    this.props.onPageUpdate()
  }

  /**
   * ComponentWillUnmount
   */
  componentWillUnmount () {
    window.removeEventListener('resize', this.updatePaperScale)
    this.updatePaperScale.cancel()
  }

  /**
   * Handle collapsing and expanding of the Pages Manager
   */
  handleSizeToggle = () => {
    const { onSizeToggle } = this.props
    const { isExpanded } = this.state

    this.setState({
      isExpanded: !isExpanded
    }, () => {
      onSizeToggle()
    })
  }

  /**
   * Handle clicking on the PageWidget container
   * Functionally, this is done to select a widget
   * @param widgetIndex
   * @param event
   */
  handlePageWidgetSelect = (widgetIndex, event) => {
    event && event.stopPropagation()

    if (!this.isDragging && !this.isResizing) {
      const { onWidgetSelect } = this.props

      onWidgetSelect && onWidgetSelect(widgetIndex, event)
    }
  }

  /**
   * Handle ReactGridLayout onDrag event
   */
  handleDrag = () => {
    this.isDragging = true
  }

  /**
   * Handle ReactGridLayout onDragStop event
   */
  handleDragStop = (layout, oldItem, newItem) => {
    const { onWidgetUpdate } = this.props

    // HACK: add some delay otherwise a click event is sent
    setTimeout(() => {
      this.isDragging = false
      onWidgetUpdate && onWidgetUpdate(layout, oldItem, newItem)
    }, 100)
  }

  /**
   * Handle ReactGridLayout onResizeStart event
   */
  handleResizeStart = () => {
    this.isResizing = true
  }

  /**
   * Handle ReactGridLayout onResizeStop event
   */
  handleResizeStop = (layout, oldItem, newItem) => {
    const { onWidgetUpdate } = this.props

    // HACK: add some delay otherwise a click event is sent
    setTimeout(() => {
      this.isResizing = false
      onWidgetUpdate && onWidgetUpdate(layout, oldItem, newItem)
    }, 100)
  }

  /**
   * Get Selected Page
   * @returns {*}
   */
  getSelectedPage = () => {
    const { pages, selectedPageIndex } = this.props
    return pages[selectedPageIndex]
  }

  /**
   * Get size configuration for the page
   * @returns {*}
   */
  getSizeConfig = () => {
    const { layout } = this.props
    const { pageWidth, pageHeight, columnCount, rowCount, marginCount } = config.pageBuilder

    const rowHeight = parseInt(pageHeight / rowCount[layout], 10)
    const verticalMargin = rowHeight * marginCount
    const horizontalMargin = parseInt(pageWidth / columnCount[layout], 10) * marginCount

    return {
      grid: {
        width: parseInt(pageWidth - (horizontalMargin * 2), 10),
        cols: columnCount[layout] - (marginCount * 2), // subtract the space being used by the page margins
        rows: rowCount[layout] - (marginCount * 2), // subtract the space being used by the page margins
        rowHeight
      },
      paperStyle: {
        transform: `translate(-50%, -50%) scale(${this.getPaperScale()})`,
        width: parseInt(pageWidth, 10),
        height: parseInt(pageHeight, 10),
        paddingTop: verticalMargin,
        paddingRight: horizontalMargin,
        paddingBottom: verticalMargin,
        paddingLeft: horizontalMargin
      }
    }
  }

  /**
   * Get calculated scaling value for the paper container
   * @returns {*}
   */
  getPaperScale = () => {
    const { pageWidth, pageHeight } = config.pageBuilder
    const { layout } = this.props
    const PaperContainer = this.paperContainer && this.paperContainer.current
    let availableWidth
    let availableHeight
    let scale

    if (PaperContainer) {
      availableWidth = PaperContainer.clientWidth
      availableHeight = PaperContainer.clientHeight
    } else {
      availableWidth = window.innerWidth - (layout === 'landscape' ? 250 : 210)
      availableHeight = window.innerHeight - 150 // minus banner and toolbar
    }

    const pagePadding = 30
    const pageWidthLimit = availableWidth - (pagePadding * 2)
    const pageHeightLimit = availableHeight - (pagePadding * 2)

    scale = pageWidthLimit / pageWidth

    if (pageHeight * scale > pageHeightLimit) {
      scale = pageHeightLimit / pageHeight
    }

    return scale > 1 ? 1 : scale
  }

  /**
   * Set new scaling state values for the paper container
   */
  updatePaperScale = () => {
    const { sizeConfig } = this.state
    const { paperStyle } = sizeConfig

    this.setState({
      sizeConfig: {
        ...sizeConfig,
        paperStyle: {
          ...paperStyle,
          transform: `translate(-50%, -50%) scale(${this.getPaperScale()})`
        }
      }
    })
  }

  /**
   * Render Toolbar Buttons
   * @param selectedPage
   */
  renderToolbarActions = (selectedPage) => {
    const { toolbarActions } = this.props
    const { isExpanded } = this.state

    return (
      <React.Fragment>
        <div className='page-editor_actions page-editor_actions--left'>
          {selectedPage && (toolbarActions || []).map((action, index) => {
            return (
              <Button
                key={`page-editor-action--${index}`}
                theme='steel'
                {...action}
              />
            )
          })}
        </div>
        <div className='page-editor_actions page-editor_actions--right'>
          <Button
            className='page-editor_expansion-toggle'
            theme='steel'
            square={true}
            tall={false}
            icon='q4i-caret-sm-up-4pt'
            styles={{
              icon: isExpanded ? { transform: 'rotate(180deg)' } : null
            }}
            onClick={this.handleSizeToggle}
          />
        </div>
      </React.Fragment>
    )
  }

  /**
   * Render Grid Layout
   * @returns {Array}
   */
  renderGridLayout = () => {
    const { renderWidget, selectedWidgetIndex, onWidgetEdit, onWidgetRemove, onWidgetDuplicate } = this.props

    const page = this.getSelectedPage()
    const layout = (page.layout && page.layout.template) || []
    const widgets = page.widget || []

    return layout.map((layoutItem, index) => {
      const widgetItem = widgets.find((widget) => parseInt(widget.layout_id, 10) === parseInt(layoutItem.i, 10))
      const isSelected = index === selectedWidgetIndex

      return (
        <div
          className={isSelected && 'react-grid-item--selected'}
          key={layoutItem.i}
          onClick={(event) => this.handlePageWidgetSelect(index, event)}
          onDoubleClick={(event) => onWidgetEdit(index, event)}
        >
          <PageWidget
            index={index}
            isSelected={isSelected}
            onEdit={onWidgetEdit}
            onRemove={onWidgetRemove}
            onDuplicate={onWidgetDuplicate}
          >
            {renderWidget ? renderWidget(layoutItem, widgetItem) : null}
          </PageWidget>
        </div>
      )
    })
  }

  /**
   * Render page
   */
  render () {
    const { pages, paperRef, selectedPageIndex, noContentMessageProps } = this.props
    const { sizeConfig } = this.state

    const selectedPage = pages[selectedPageIndex]
    const { grid, paperStyle } = sizeConfig

    return (
      <section className='page-editor'>
        <Toolbar>
          <ToolbarRow justified={true}>
            {this.renderToolbarActions(selectedPage)}
          </ToolbarRow>
        </Toolbar>
        <section className='page-editor_body' onClick={(event) => this.handlePageWidgetSelect(null, event)}>
          {selectedPage && (
            <Scrollbars
              className='react-scrollbar'
              autoHide
              hideTracksWhenNotNeeded
              renderThumbVertical={renderDarkThumb}
              renderTrackVertical={renderTrackVertical}
            >
              <div className='page-editor_paper-wrap' ref={this.paperContainer}>
                <div className='page-editor_paper-inner' style={paperStyle} ref={paperRef}>
                  {sizeConfig.grid && (selectedPage.widget && selectedPage.widget.length > 0 ? (
                    <ReactGridLayout
                      key={(selectedPage._id || selectedPage.tempId) || selectedPageIndex}
                      className='react-grid'
                      layout={selectedPage.layout.template}
                      width={grid.width}
                      cols={grid.cols}
                      maxRows={grid.rows}
                      rowHeight={grid.rowHeight}
                      margin={[0, 0]}
                      isDraggable={true}
                      isResizable={true}
                      preventCollision={true}
                      compactType={null}
                      onDrag={this.handleDrag}
                      onDragStop={this.handleDragStop}
                      onResizeStart={this.handleResizeStart}
                      onResizeStop={this.handleResizeStop}
                    >
                      {this.renderGridLayout()}
                    </ReactGridLayout>
                  ) : noContentMessageProps && (
                    <NoContentMessage {...noContentMessageProps} />
                  ))}
                </div>
              </div>
            </Scrollbars>
          )}
        </section>
      </section>
    )
  }
}

PageEditor.propTypes = {
  layout: PropTypes.oneOf(['landscape', 'portrait']),
  isFullscreen: PropTypes.bool,
  isExpanded: PropTypes.bool,
  toolbarActions: PropTypes.array,
  pages: PropTypes.array,
  selectedPageIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
  selectedWidgetIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]),
  paperRef: PropTypes.object,
  renderWidget: PropTypes.func,
  onSizeToggle: PropTypes.func.isRequired,
  onWidgetUpdate: PropTypes.func.isRequired,
  onWidgetEdit: PropTypes.func,
  onWidgetSelect: PropTypes.func,
  onWidgetRemove: PropTypes.func,
  onWidgetDuplicate: PropTypes.func,
  setUpdatePaperScale: PropTypes.func,
  onPageUpdate: PropTypes.func.isRequired
}

PageEditor.defaultProps = {
  pages: [],
  layout: 'landscape'
}

export default PageEditor
