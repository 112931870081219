import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const CREATE_PEER = gql`
  mutation Peer(
    $tickerId: String!
    $entityId: String!
    $legacySecurityId: String!
    $name: String!
    $symbol: String!
    $exchange: String!
  ) {
    peer {
      create(
        tickerId: $tickerId
        entityId: $entityId
        legacySecurityId: $legacySecurityId
        name: $name
        symbol: $symbol
        exchange: $exchange
      ) {
        items {
          id
        }
      }
    }
  }`

const DELETE_PEER = gql`
  mutation Peer(
    $id: [String]!
  ) {
    peer {
      delete(
        id: $id
      ) {
        count
      }
    }
  }`

/**
 * Peer create
 * @param options
 */
export function usePeerCreateQuery (options) {
  return useMutation(CREATE_PEER, options)
}

/**
 * Peer delete
 * @param options
 */
export function usePeerDeleteQuery (options) {
  return useMutation(DELETE_PEER, options)
}
