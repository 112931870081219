import { intersection, differenceWith, isEmpty } from 'lodash'
import { stockEntityType } from './report.const'
import { formatDate } from '../date.util'

/**
 * Get Report Field entity type icon
 * @param entityType
 * @returns {*}
 */
export const getIconClass = (entityType) => {
  switch (entityType) {
    case 'institution':
    case 'shareholderid':
    case 'institutionSurveillance':
      return 'q4i-institution-4pt'
    case 'fundShareholderid':
    case 'fundSurveillance':
    case 'fund':
      return 'q4i-fund-4pt'
    case 'contact':
      return 'q4i-contact-4pt'
    case 'activity':
      return 'q4i-activity-4pt'
    case 'stock':
      return 'q4i-stock-4pt'
    default:
      return ''
  }
}

/**
 * Helper function for flattening data into an array of _ids
 * @param array
 * @param key
 */
export const getFlattenedData = (array, key = '_id') => {
  return (array || []).map((item) => item && item[key])
}

/**
 * Helper function to determine if aggregation option is available for selected xAxis
 * @param xAxisFieldType
 * @return {boolean}
 */
export const getIsAggregationAvailable = (xAxisFieldType) => {
  return ['boolean', 'choice'].includes(xAxisFieldType)
}

/**
 * Check for main entity report type
 * @param reportEntityTypes
 * @param reportType
 */
export const getIsReportType = (reportEntityTypes, reportType) => {
  return Array.isArray(reportEntityTypes) && reportEntityTypes.length > 0 && reportEntityTypes[0].name === reportType
}

/**
 * Check to see if Pivot should be enabled
 * @param reportEntityTypes
 * @param fields
 * @returns {boolean}
 */
export const getIsPivotAvailable = (reportEntityTypes, fields) => {
  if (isEmpty(reportEntityTypes) || isEmpty(fields)) {
    return false
  }

  return reportEntityTypes.map((eachEntityType) => {
    return !isEmpty(intersection(eachEntityType.pivotFields || [], fields.map((each) => each._id)))
  }).filter((each) => each).length === reportEntityTypes.length
}

/**
 * Check to see if Row Grouping should be enabled
 * @param reportEntityTypes
 * @param fields
 * @returns {boolean}
 */
export const getIsGroupedMode = (reportEntityTypes, fields) => {
  if (isEmpty(reportEntityTypes) || isEmpty(fields)) {
    return false
  }

  if (reportEntityTypes.length < 2 && !getIsReportType(reportEntityTypes, stockEntityType)) {
    return false
  }

  return reportEntityTypes.map((eachEntityType) => {
    return !isEmpty(intersection(eachEntityType.groupedFields || [], fields.map((each) => each._id)))
  }).filter((each) => each).length === reportEntityTypes.length
}

/**
 * Get Available Peers (exclude already selected and your own ticker)
 * @param foundItems
 * @param current
 * @param ticker
 * @returns {*}
 */
export const getAvailablePeers = (foundItems, current, ticker) => {
  const peersDifference = differenceWith(foundItems, current, (a, b) => {
    return a.mongo_id === (b._security || b.mongo_id)
  })

  if (!ticker) {
    return peersDifference
  }

  return (peersDifference || []).filter((peer) => {
    const peerId = peer._security || peer.mongo_id
    return peerId !== ticker._security
  })
}

/**
 * Get latest revision data
 * @param revisions
 */
export const getLastRevisionData = (revisions) => {
  const revision = revisions && revisions.length && revisions[0]

  if (!revision) {
    return null
  }

  const isUTC = true
  const date = formatDate(revision.date, 'MMMM DD, YYYY', isUTC)
  const name = revision._profile && `${revision._profile.firstName} ${revision._profile.lastName}`

  return {
    date,
    name
  }
}
