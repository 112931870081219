import { combineReducers } from 'redux'
import pipeline from './pipeline.reducer'
import stock from './stock.reducer'

const pipelineReducer = combineReducers({
  pipeline,
  stock
})

export default pipelineReducer
