import React, { Component } from 'react';
import _ from 'lodash';
import './targetFilter.component.css';

class TargetFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterValue: ''
        };
    }

    handleFilterChange = (event) => {
        const value = (event && event.target && event.target.value) || '';
        this.setState({ filterValue: value }, () => {
            this.filterTarget();
        });
    };

    filterTarget = _.debounce(() => {
        this.props.filterTarget(this.state.filterValue);
    }, 500);

    render = () => {
        return (
            <div className='target-filter'>
                <input className='target-filter__input' value={this.state.filterValue}
                       placeholder='Search'
                       onChange={this.handleFilterChange}/>
            </div>
        );
    };
}

export default TargetFilter;
