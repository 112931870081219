import {
  FETCH_CALENDAR_REQUEST,
  FETCH_CALENDAR_SUCCESS,
  FETCH_CALENDAR_ERROR,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../../actions/widget/activity/currentActivity.actions'

const initial = {
  list: [],
  status: IDLE
}

const calendar = (state = initial, action) => {
  switch (action.type) {
    case FETCH_CALENDAR_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_CALENDAR_SUCCESS:
      return {
        ...state,
        list: action.payload,
        status: FETCHED
      }
    case FETCH_CALENDAR_ERROR:
      return {
        ...state,
        error: action.error,
        status: FAILED
      }
    default:
      return state
  }
}

export default calendar
