import React, { PureComponent } from 'react'
import Autosuggest from 'react-autosuggest'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import Button from '../button/button.component'
import { renderDarkThumb, renderTrackVertical } from '../../resources/theme/q4.custom-scrollbar'
import { getClassName, THEMES } from '../../utils/ui'

import './autosuggest.component.css'

/**
 * Autosuggest Component
 */
class AutoSuggest extends PureComponent {
  /**
   * Render search input field
   * @param props
   * @returns {*}
   */
  renderInputField = (props) => {
    const { value } = props
    const { className, theme, error, loading, clearable, onClear } = this.props

    return (
      <div className={getClassName('autosuggest_input', [
        { condition: className, trueClassName: className },
        { condition: theme, trueClassName: `autosuggest_input--${theme}` },
        { condition: (loading || error), trueClassName: 'autosuggest_input--state' },
      ])}>
        <input {...props} />
        {(loading || error) ?
          <div className={`autosuggest_input-indicator ${error ? 'autosuggest_input-indicator--error' : ''}`}>
            {loading ? <i className='autosuggest_input-indicator--loading' /> : null}
          </div> : null
        }
        <Button
          theme={THEMES.TRANSPARENT}
          icon='q4i-close-4pt'
          hidden={!clearable || (!value || !value.length)}
          onClick={onClear}
        />
      </div>
    )
  }

  /**
   * Get section suggestions (e.g. security, institution, fund, contact)
   * @param section
   * @returns {*}
   */
  getSectionSuggestions = (section) => {
    return section.suggestions
  }

  /**
   * Handle search tag query change
   * @param value
   */
  getSuggestions = ({ value }) => {
    const { getSuggestions } = this.props
    getSuggestions && getSuggestions(value)
  }

  /**
   * Clear suggestions
   */
  clearSuggestions = () => {
    const { clearSuggestions } = this.props
    clearSuggestions && clearSuggestions()
  }

  /**
   * Render suggestion container
   * Customize the content or behaviour of the suggestions container beyond rendering the suggestions themselves
   * @param containerProps
   * @param children
   * @returns {*}
   */
  renderSuggestionsContainer = ({ containerProps, children }) => {
    const items = (children && children.props && children.props.items) || []
    const {
      suggestionHeight,
      suggestionItemHeight,
      suggestionLimit,
      actions,
      actionItemHeight
    } = this.props

    const { ref, ...restContainerProps } = containerProps
    const getReference = (scrollbars) => {
      if (scrollbars !== null) {
        ref(scrollbars.view)
      }
    }

    return (
      <div {...containerProps}
           className={'autosuggest autosuggest_results'}
           style={{
             height:
               (items.length < suggestionLimit) ? (actions && actions.length && items.length) ?
                 (suggestionItemHeight * items.length) + (actions.length * actionItemHeight) :
                 suggestionItemHeight * items.length : suggestionHeight
           }}>
        <Scrollbars
          ref={getReference}
          {...restContainerProps}
          className='react-scrollbar'
          autoHide
          hideTracksWhenNotNeeded
          renderThumbVertical={renderDarkThumb}
          renderTrackVertical={renderTrackVertical}>
          {children}
          {(actions || []).map((action) => {
            return (
              <div key={action.key} className='autosuggest_results-action'>
                <Button {...action} />
              </div>
            )
          })}
        </Scrollbars>
      </div>
    )
  }

  /**
   * Render AutoSuggest Component
   * @returns {XML}
   */
  render () {
    const {
      inputReference, className, theme, value, placeholder, onChange, onKeyUp, onBlur,
      suggestions, renderSuggestion, getSuggestionValue, onSuggestionSelected, multiSection, renderSectionTitle
    } = this.props

    const inputProps = { value, placeholder, onChange, onKeyUp, onBlur }

    return (
      <Autosuggest
        ref={inputReference}
        multiSection={multiSection}
        inputProps={inputProps} // required
        renderInputComponent={this.renderInputField}
        suggestions={suggestions} // required
        onSuggestionsFetchRequested={this.getSuggestions} // required
        renderSuggestion={renderSuggestion} // required
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue} // required
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsClearRequested={this.clearSuggestions} // required
        getSectionSuggestions={this.getSectionSuggestions} // required with multiSection
        renderSectionTitle={renderSectionTitle} // required with multiSection
        theme={{
          container: `autosuggest ${className} autosuggest--${theme}`,
          containerOpen: 'autosuggest--open',
          suggestion: 'autosuggest_results-item',
          suggestionHighlighted: 'autosuggest_results-item--selected'
        }} />
    )
  }
}

AutoSuggest.propTypes = {
  /**
   * Docs: https://github.com/moroshko/react-autosuggest
   */
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
  suggestions: PropTypes.array.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func,
  clearSuggestions: PropTypes.func.isRequired,
  multiSection: PropTypes.bool,
  renderSectionTitle: PropTypes.any,

  /**
   * Input reference
   */
  inputReference: PropTypes.func,

  /**
   * Custom className
   */
  className: PropTypes.string,

  /**
   * Paints the component in a specific theme
   */
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT, THEMES.INK]),

  /**
   * Error flag to display error state
   */
  error: PropTypes.bool,

  /**
   * Loading flag to render loading state
   */
  loading: PropTypes.bool,

  /**
   * Clear text input field
   */
  clearable: PropTypes.bool,

  /**
   * Height of the result container (for custom scroll)
   */
  suggestionHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Height of the item within the result container (for custom scroll)
   */
  suggestionItemHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Limit of the items visible in a result container (for custom scroll)
   */
  suggestionLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Add any fixed button actions to the results list
   */
  actions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    theme: PropTypes.oneOf([THEMES.SOFT_GREY, THEMES.CITRUS, THEMES.RAIN]),
    icon: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func
  })),

  actionItemHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

AutoSuggest.defaultProps = {
  value: '',
  placeholder: 'Search',
  suggestions: [],
  multiSection: false,
  theme: THEMES.DARK,
  loading: false,
  error: false,
  clearable: false,
  suggestionHeight: 40 * 4,
  suggestionItemHeight: 40,
  suggestionLimit: 4
}

export default AutoSuggest
