import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import CustomCell from './cell/cell.component'
import { AgGrid, NoContentMessage, Spinner } from '../../../../../components'
import { preventDefaultRowClick, setColumnDefinition, THEMES } from '../../../../../utils'

const propTypes = {
  pageSizeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number,
  listOptions: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  onQueryChange: PropTypes.func.isRequired,
  researchSubscription: PropTypes.object,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  loading: false,
  data: [],
  total: 0,
  listOptions: {}
}

const gridColumns = [
  {
    field: 'company',
    headerName: 'Name',
    minWidth: 360
  },
  {
    field: 'EPS',
    headerName: 'Recent',
    type: 'number',
    minWidth: 130,
    maxWidth: 130
  },
  {
    field: 'target',
    headerName: 'Target Price',
    type: 'number',
    minWidth: 130,
    maxWidth: 130
  },
  {
    field: 'rating',
    headerName: 'Rating',
    type: 'centered',
    minWidth: 130,
    maxWidth: 130
  },
  {
    field: 'date',
    headerName: 'Research',
    type: 'date',
    minWidth: 130,
    maxWidth: 130
  }]

/**
 * Contact Coverage Table
 * @param props
 */
function ContactCoverageTable (props) {
  const { pageSizeId, loading, data, total, listOptions, onQueryChange, researchSubscription, history } = props
  const [agGrid, setAgGrid] = useState({})
  const [columns] = useState(
    researchSubscription
      ? gridColumns
      : gridColumns.filter((column) => column.field !== 'date'))

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click event
   * @param security {Object}
   */
  const handleRowClick = (security) => {
    const id = get(security, 'security_id', null)

    if (id && history) {
      history.push(`/security/${id}`)
    }
  }

  /**
   * Handle page change
   * @param selected
   */
  const handlePageChange = ({ selected }) => onQueryChange({
    listOptions: { ...listOptions, page: selected }
  })

  /**
   * Handle page size change
   * @param selected
   */
  const handlePageSizeChange = ({ selected }) => onQueryChange({
    listOptions: { ...listOptions, limit: selected, page: 1 }
  })

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell {...props} />
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage />
        : <AgGrid
          domLayout='autoHeight'
          sizeToFit

          // suppress configs
          suppressMovableColumns
          suppressContextMenu

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            sortable: false,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={setColumnDefinition({ columns, isLocked: false })}
          rowData={data}

          // pagination
          pagination
          paginationProps={{
            pageSizeId,
            forcePage: listOptions.page,
            initialPageSize: listOptions.limit,
            showPageSizeSelection: true,
            total,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: renderCustomCell
          }}

          // event listeners
          onGridReady={handleGridReady}
          onGridSizeChanged={handleGridResize}
          onRowClicked={preventDefaultRowClick(
            handleRowClick, ['']
          )}
        />}
    </div>
  )
}

ContactCoverageTable.propTypes = propTypes
ContactCoverageTable.defaultProps = defaultProps

export default memo(ContactCoverageTable)
