import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  loadStockQuote,
  loadAvgVolume,
  FETCHING,
  FETCHED
} from '../../../../actions/widget/stock'
import { Spinner } from '../../../../components'
import StockQuoteItem from '../../../../components/widget/stock/quote/quote.component'
import WidgetError from '../../../../components/widget/error/widgetError.component'
import { withRouter } from 'react-router-dom'
import './quote.container.css'

class StockQuote extends Component {

  _fetchData = () => {
    const { loadStockQuote, loadAvgVolume, securityId } = this.props

    const params = {
      securityId
    }

    loadStockQuote(params)
    loadAvgVolume(params)
  }

  /**
   * Triggered when component mounted
   * Enable auto-reloading if enabled
   */
  componentDidMount = () => {
    const { isEdit, status } = this.props
    const isFetched = status[0] === FETCHED

    if (isEdit && isFetched) {
      return
    }

    const refreshInterval = this.props.options.refreshInterval
    if (refreshInterval) {
      this.timer = setInterval(this._fetchData.bind(this), refreshInterval)
    }

    this._fetchData()
  }

  /**
   * ComponentDidUpdate
   * Re-fetch data when security changed
   * @param prevProps
   */
  componentDidUpdate = (prevProps) => {
    if (prevProps.securityId !== this.props.securityId) {
      this._fetchData()
    }
  }

  /**
   * clear fetch interval on unmount
   */
  componentWillUnmount = () => {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  render () {
    const { status, theme, stockQuote, tickerId, history } = this.props
    const is_loading = status[0] === FETCHING

    if (!is_loading && _.isEmpty(stockQuote)) {
      return (
        <WidgetError
          theme={theme}
          header={'Stock Quote'}
          message={'No stock data available.'}
        />
      )
    }

    return (
      <div className={`stock-quote-container stock-quote-container--${theme}`}
           onClick={() => history.push(`/security/${tickerId}`)}>
        {_.isEmpty(stockQuote) ?
          <Spinner/> :
          <StockQuoteItem {...this.props} />
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const stock = state.widget.stock
  return {
    stockQuote: (stock.quote && stock.quote.items) || [],
    averageVolume: (stock.volume && stock.volume.volume && stock.volume.volume.volumeAverage) || 0,
    status: [stock.quote.status, stock.volume.status],
    theme: state.dashboard.dashboardState.theme,
    securityId: state.dashboard.dashboardState.securityId,
    tickerId: state.dashboard.dashboardState.tickerId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadStockQuote: bindActionCreators(loadStockQuote, dispatch),
    loadAvgVolume: bindActionCreators(loadAvgVolume, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StockQuote))
