import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { getClassName, getFromXigniteToStandard } from '../../../../utils'
import { ComboBox } from '../../../../components'
import './reportPeers.component.css'

/**
 * ReportPeerLabel Component
 * @param type
 * @param peer
 * @returns {*}
 */
function ListLabel (peer) {
  const { symbol, exchange, security_name } = peer

  return (
    <span className='report-peers_item report-peers_item--list'>
      <span className='report-peers_item-symbol'>{symbol}</span>
      <span className='report-peers_item-exchange'>{getFromXigniteToStandard(exchange)}</span>
      <span className='report-peers_item-separator'>|</span>
      <span className='report-peers_item-name'>{security_name}</span>
    </span>
  )
}

/**
 * ReportPeers Component
 * @param props
 * @returns {*}
 */
function ReportPeers (props) {
  const {
    isDetailed, peers, availablePeers,
    onSearchQueryChange, onSearchQueryClear, onSearchPeerSelect, onRemovePeer, onResetPeers, onAddDefaultPeers, onClearPeers
  } = props
  const [query, setQuery] = useState('')

  const selectedPeers = [].concat(peers || [])
    .map((peer) => peer && { value: peer._security, item: peer })

  const options = [].concat(availablePeers || [])
    .map((peer) => {
      const { symbol = '', exchange = '', company_name = '' } = peer
      return peer && {
        value: peer,
        label: `${symbol} ${getFromXigniteToStandard(exchange)} | ${company_name}`
      }
    })

  const baseClassName = getClassName('report-peers', [
    { condition: isDetailed, trueClassName: 'report-peers--detailed' }
  ])
  /**
   * Handle selection change
   * @param options
   */
  function handleChange (options) {
    const values = [].concat(options || []).map((option) => option && option.value)
    onSearchPeerSelect(values)
  }

  /**
   * Handle Input Change
   * Set query value only if user types input changes. Retains input value onBlur, onSelect.
   * Clear query results if user manually clears input, otherwise sends new query onChange.
   * @param query
   * @param action
   */
  function handleInputChange (query, { action }) {
    const isInputChanged = action === 'input-change'

    if (isInputChanged) {
      setQuery(query)
    }

    !query.length && isInputChanged ? onSearchQueryClear() : onSearchQueryChange(query)
  }

  return (
    <div className={baseClassName}>
      <div className='report-peers_header'>
        <div className='report-peers_title-container'>
          <h3 className='report-peers_title'>Peers {isDetailed && `(${peers ? peers.length : 0})`}</h3>
          {isDetailed && (
            <h4 className='report-peers_subtitle'>Select up to 20 peers</h4>
          )}
        </div>
        <div className='report-peers_action-container'>
          {(peers && peers.length > 0) ? (
            <span className='report-peers_action' onClick={onResetPeers}>Reset</span>
          ) : (
            <span className='report-peers_action' onClick={onAddDefaultPeers}>Add Default Peers</span>
          )}
          <span className='report-peers_action' onClick={onClearPeers}>Clear</span>
        </div>
      </div>
      <div className='report-peers_search'>
        <ComboBox
          selectProps={{
            placeholder: query && query.length ? '' : 'Add a security or symbol',
            disabled: peers.length >= 20,
            size: isDetailed ? null : 'thin',
            options,
            closeMenuOnSelect: false,
            inputValue: query,
            isMulti: true,
            onInputChange: handleInputChange.bind(this),
            onChange: handleChange.bind(this)
          }}
          removableListProps={{
            size: 'small',
            items: selectedPeers,
            customItemRender: (item) => ListLabel(item),
            onRemove: onRemovePeer
          }}
        />
      </div>
    </div>
  )
}

ReportPeers.propTypes = {
  isDetailed: PropTypes.bool,
  peers: PropTypes.array.isRequired,
  availablePeers: PropTypes.array.isRequired,
  onSearchQueryChange: PropTypes.func.isRequired,
  onSearchQueryClear: PropTypes.func.isRequired,
  onSearchPeerSelect: PropTypes.func.isRequired,
  onRemovePeer: PropTypes.func.isRequired,
  onResetPeers: PropTypes.func.isRequired,
  onAddDefaultPeers: PropTypes.func.isRequired,
  onClearPeers: PropTypes.func.isRequired
}

ReportPeers.defaultProps = {
  isDetailed: false,
  peers: [],
  availablePeers: []
}

export default memo(ReportPeers)
