import {
  POP_FLYOUT_DETAILS_ITEM,
  PUSH_FLYOUT_DETAILS_ITEM,
  RESET_FLYOUT_DETAILS_ITEM,
  SET_FLYOUT_DETAILS_ITEM,
} from '../../actions'

const initial = {
  data: []
}

const targets = (state = initial, action) => {
  switch (action.type) {
    case PUSH_FLYOUT_DETAILS_ITEM:
      return {
        ...state,
        data: [...state.data, action.payload]
      }
    case POP_FLYOUT_DETAILS_ITEM:
      return {
        ...state,
        data: [...state.data.slice(0, state.data.length - 1)]
      }
    case SET_FLYOUT_DETAILS_ITEM:
      return {
        ...state,
        data: [action.payload]
      }
    case RESET_FLYOUT_DETAILS_ITEM:
      return {
        ...state,
        data: []
      }
    default:
      return state
  }
}

export default targets
