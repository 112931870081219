import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import moment from 'moment-timezone';
import { fetchHistoricalTable, setHistoricalMetric, FETCHING } from '../../../actions/estimates';
import { downloadExportData } from '../../../actions/shared/index';
import { formatEstimatesSalesValue, format } from '../../../utils/number.util';
import { getTickers } from '../../../utils';
import { getFromXigniteToStandard } from '../../../utils/stock/stock.util';
import { DataTable, Select, Spinner, Toolbar, ToolbarRow } from '../../../components'
import './estimatesHistorical.container.css';
import { getUserDefaultMetric } from '../../../utils/user.util';
import { THEMES } from '../../../utils/ui'

const columns = [
    {id: 'period', label: 'Period', width: 'auto', dense: true},
    {id: 'actual', label: 'Actual', width: 'default', textAlign: 'right'},
    {id: 'mean', label: 'Mean', width: 'default', textAlign: 'right'},
    {id: 'high',label: 'High', width: 'default', textAlign: 'right'},
    {id: 'low', label: 'Low', width: 'default', textAlign: 'right'},
    {id: 'count', label: '#', width: 'default', textAlign: 'center'}
];

class EstimatesHistorical extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        const defaultMetric = getUserDefaultMetric(props.profile, props.securityId);
        const metric = ((props.defaultState.metric || defaultMetric) === 'ffo') ?
            {label: 'Funds From Operations', value: 'ffo'} :
            {label: 'Earnings Per Share', value: 'eps'};

        const types = [
            metric,
            {label: 'Sales', value: 'sales'}
        ];

        this.state = {
            exporting: false,
            types: types,
            activeType: types.find((each) => {
                return each.value === props.historicalMetric;
            }) || metric
        };
    }

    /**
     * ComponentDidMount
     */
    componentDidMount() {
        this._fetchData();
    }

    /**
     * ComponentDidUpdate
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {currentPeer, securityId} = this.props;
        const _securityId = (currentPeer && currentPeer._security) || securityId;

        if (prevProps.currentPeer && prevProps.currentPeer._security !== _securityId) {
            this._fetchData();
        }
    }

    /**
     * Fetch Historical Estimates data
     * @private
     */
    _fetchData = () => {
        const {currentPeer, securityId} = this.props;
        const {types} = this.state;
        const _securityId = (currentPeer && currentPeer._security) || securityId;

        this.props.fetchHistoricalTable({
            securityId: _securityId,
            metric: types && types.map((each) => each.value)
        });
    };

    /**
     * Handle Dropdown change
     * @param option
     */
    handleDropdownChange = (option) => {
        this.setState({
            activeType: option
        });
        this.props.setHistoricalMetric(option.value);

    };

    /**
     * On Export Click
     */
    onExport = () => {
        const {activeType} = this.state;
        const {currentPeer, myOrganization, securityId, peers, profile} = this.props;
        const _security = (currentPeer && currentPeer._security) || securityId;
        const peerObj = [...getTickers(profile), ...peers].find((each) => {
            return each._security === _security;
        });

        const activeSymbol = peerObj ? peerObj.symbol : myOrganization.symbol;
        const activeExchange = peerObj ? getFromXigniteToStandard(peerObj.exchange) : getFromXigniteToStandard(myOrganization.exchange);

        this.setState({ exporting: true });

        this.props.downloadExportData({
            url: '/estimates/v2/export/consensus/historical',
            contentType: 'text/csv',
            params: {
                metric: activeType && activeType.value,
                securityId: _security
            },
            file: {
                name: [
                    activeSymbol,
                    activeExchange,
                    activeType.value,
                    'historical_estimates'
                ].join('_'),
                type: 'csv'
            }
        }).then(() => { this.setState({ exporting: false }); });
    };

    getPeriod = (date, frequency, quarter) => {
        let formattedDate = date && moment.utc(date).format('MMM YY');

        if (frequency === 'annual') {
            return formattedDate + ' FY';
        }
        else {
            return formattedDate + ' Q' + quarter;
        }
    };

    /**
     * Format data for the table
     * @param items
     * @param type
     * @returns {Array}
     */
    formatData = (items, type) => {
        const me = this;

        return (items || []).sort((a, b) => new Date(b.date) - new Date(a.date)).map((item) => {
            let relevantData = {};

            if (type !== 'sales') {
                relevantData = {
                    actual: format(item[`${type}_actual`], 2, 'dash'),
                    mean: format(item[`${type}_mean`], 2, 'dash'),
                    high: format(item[`${type}_high`], 2, 'dash'),
                    low: format(item[`${type}_low`], 2, 'dash'),
                    count: item[`${type}_count`]
                };
            } else {
                relevantData = {
                    actual: formatEstimatesSalesValue(item.sales_actual),
                    mean: formatEstimatesSalesValue(item.sales_mean),
                    high: formatEstimatesSalesValue(item.sales_high),
                    low: formatEstimatesSalesValue(item.sales_low),
                    count: item.sales_count
                };
            }

            return {
                period: me.getPeriod(item.date, item.frequency, item.quarter),
                ...relevantData
            };
        });
    };

    /**
     * Render
     * @returns {XML}
     */
    render() {
        const {types, activeType, exporting} = this.state;
        const {historical, loading} = this.props;
        const data = this.formatData(historical, (activeType && activeType.value));
        const hasData = (data.filter(item => item.count)).length;

        return (
            <div className='estimates-historical'>
                <div className='estimates-historical_list'>
                    {!data.length && loading && (
                        <Spinner
                            theme={'rain'}
                            mask={true}
                        />
                    )}
                  <Toolbar theme={THEMES.Q4_BLUE}>
                    <ToolbarRow >
                      <div className='estimates_toolbar'>
                        <div className='toolbar_group'>
                          <Select
                            theme={THEMES.INK}
                            size='thin'
                            value={activeType}
                            options={types}
                            isDefaultList
                            closeMenuOnSelect
                            onChange={this.handleDropdownChange}
                            searchable={false}
                            clearable={false}
                          />
                        </div>
                        <button
                          className={`button button--ink ${!hasData ? 'button--disabled' : ''} ${exporting ? 'button--loading' : ''}`}
                          onClick={this.onExport}>
                          <i className='button_icon q4i-download-4pt'/>
                          <span className='button_label'>Export</span>
                        </button>
                      </div>
                    </ToolbarRow>
                  </Toolbar>
                    <DataTable
                        columns={columns}
                        data={data}
                        noResultsText={loading ? ' ' : 'No data available'}
                        alternating={true}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const profile = state.shared.profile;
    const defaultState = state.estimates.defaultState;
    const historical = state.estimates.historical.items || [];
    const status = state.estimates.historical.status;
    const historicalMetric = state.estimates.historical.activeMetric;
    const currentPeer = state.estimates.peers.currentPeer;
    const peers = state.estimates.peers.items;
    const myOrganization = peers && peers[0];

    return {
        myOrganization,
        historical,
        defaultState,
        currentPeer,
        profile,
        peers,
        loading: status === FETCHING,
        historicalMetric,
        securityId: props.match.params.securityId
    };
};

const mapDispatchToProps = (dispatch) => ({
    setHistoricalMetric: bindActionCreators(setHistoricalMetric, dispatch),
    fetchHistoricalTable: bindActionCreators(fetchHistoricalTable, dispatch),
    downloadExportData: bindActionCreators(downloadExportData, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EstimatesHistorical));
