import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Message, Modal } from '../../../components'
import { HtmlEditor } from '../../shared'
import { toolbarOptions, normalizeBody } from '../../../utils/htmlEditor.util'
import { THEMES } from '../../../utils'

import './edit.component.css'

/**
 * @deprecated
 */
class EntitySummaryEdit extends PureComponent {
  /**
   * Constructor
   */
  constructor (props) {
    super(props)
    this.state = {
      comment: props.comment,
      changed: false,
      hasError: false,
      hasUnsavedChanges: false
    }
  }

  /**
   * Handle Change
   */
  handleChange = ({ html }) => {
    const newComment = normalizeBody(html) || null
    const oldComment = normalizeBody(this.props.comment) || null
    const changed = newComment !== oldComment

    this.setState({
      comment: newComment,
      changed
    })
  }

  /**
   * Handle Save
   * Save/update comment (or deletes if comment empty)
   */
  handleSave = () => {
    const { comment, changed } = this.state
    const { onSave, onDelete, onClose } = this.props

    if (changed) {
      const action = comment ? onSave(comment) : onDelete()
      action.then(({ error }) => {
        error ? this.handleError(error) : onClose()
      })
    }
  }

  /**
   * Handle Error
   * @param error
   */
  handleError = (error) => {
    this.setState({ hasError: !!error })
  }

  /**
   * Handle dismiss error
   */
  handleDismissError = () => {
    this.setState({ hasError: false })
  }

  /**
   * Handle Cancel
   */
  handleCancel = () => {
    const { onClose } = this.props
    const { changed } = this.state

    if (changed) {
      return this.setState({ hasUnsavedChanges: true })
    }

    onClose()
  }

  /**
   * Handle dismiss unsaved changes
   * @param proceed - true to dismiss
   */
  handleDismissUnsavedChanges = (dismiss) => {
    const { onClose } = this.props
    this.setState({ hasUnsavedChanges: dismiss })
    dismiss && onClose()
  }

  render () {
    const { comment, onClose, placeholder } = this.props
    const { changed, hasError, hasUnsavedChanges } = this.state

    return (
      <Modal
        className='modal--edit-comment'
        visible
        containerWidth={800}
        containerMaxWidth='md'
        title='Comment'
        contentHeight={400}
        onClose={onClose}
        footerButtons={[{
          label: 'Cancel',
          ui: 'shaded',
          onClick: this.handleCancel
        }, {
          label: 'Save',
          ui: THEMES.CITRUS,
          disabled: !changed,
          onClick: this.handleSave
        }]}
      >
        <HtmlEditor
          html={comment || ''}
          toolbar={toolbarOptions}
          onChange={this.handleChange}
          placeholder={placeholder}
        />
        <Message
          visible={hasError}
          type='error'
          title='Error'
          message='Oops, something went wrong while trying to send this request. Please try again or contact us if you see this message again.'
          onClose={this.handleDismissError}
          buttons={[{
            ui: THEMES.SPICE,
            label: 'close',
            onClick: this.handleDismissError
          }]}
        />
        <Message
          visible={hasUnsavedChanges}
          type='error'
          title='Discard changes?'
          message='Any unsaved changes will be lost.'
          onClose={() => this.handleDismissUnsavedChanges(false)}
          buttons={[
            {
              ui: 'shaded',
              label: 'cancel',
              onClick: () => this.handleDismissUnsavedChanges(false)
            },
            {
              ui: THEMES.SPICE,
              label: 'proceed',
              onClick: () => this.handleDismissUnsavedChanges(true)
            }
          ]}
        />
      </Modal>
    )
  }
}

EntitySummaryEdit.defaultProps = {
  comment: ''
}

EntitySummaryEdit.propTypes = {
  comment: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}

export default EntitySummaryEdit
