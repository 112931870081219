import { ENTITY_TYPE } from '../entity'
const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

export const EXPORT = {
  CSV: 'csv',
  PDF: 'pdf'
}

export const GENERATE_STATUS = {
  PENDING: 'pending',
  COMPLETE: 'complete'
}

export const BRIEFING_BOOK_TYPE = {
  BRIEFING_BOOK: 'briefingbook',
  TEARSHEET: 'tearsheet'
}

// template static data
export const TEMPLATES = {
  essential: {
    description: 'Understand the basics with a snapshot of each investor in a table view.',
    content: [
      'Current Position',
      'Prev Quarter Position',
      'Purchasing Power',
      'AUM',
      'E AUM',
      'Style',
      'Type',
      'Turnover',
      'Quality Rating',
      'AI Score',
      'Target'
    ]
  },
  insight: {
    description: 'Configure this template to prepare for conversations with investors.',
    content: [
      'Last Activity',
      'Total Activity',
      'Current Position',
      'Purchasing Power',
      'AUM',
      'E AUM',
      'Style',
      'Turnover',
      'Quality Rating',
      'AI Score',
      'Contact Details'
      // 'Badges'
    ]
  }
}

// template items static data
export const TEMPLATE_ITEMS = {
  activity: {
    dataId: 'Activities',
    label: 'Activities',
    options: [
      {
        dataId: 'ActivitiesOptions',
        type: 'limit',
        select: [
          { label: 'Last Activity', value: 'lastActivity' },
          { label: '5 Most Recent', value: 'fiveMostRecent' },
          { label: '3 Months', value: 'threeMonths' },
          { label: '6 Months', value: 'sixMonths' },
          { label: '1 Year', value: 'oneYear' },
          { label: 'All Activities', value: 'allLastActivities' }
        ]
      },
      {
        dataId: 'ActivityNotes',
        type: 'notes',
        label: 'Activity Notes'
      }],
    entityType: [CONTACT, FUND, INSTITUTION]
  },
  latestActivity: {
    dataId: 'LastActivity',
    label: 'Last Activity',
    options: [{
      dataId: 'ActivityNotes',
      type: 'notes',
      label: 'Activity Notes'
    }],
    entityType: [CONTACT, FUND, INSTITUTION]
  },
  topHoldings: {
    dataId: 'TopHoldings',
    label: 'Top Holdings',
    options: [{
      dataId: 'TopHoldingsOptions',
      type: 'limit',
      select: [
        { label: '5 Holdings', value: '5' },
        { label: '10 Holdings', value: '10' }
      ]
    }],
    entityType: [FUND, INSTITUTION]
  },
  peerHoldings: {
    dataId: 'PeerHoldings',
    label: 'Peer Holdings',
    options: [{
      dataId: 'PeerHoldingsOptions',
      type: 'limit',
      select: [
        { label: '5 Holdings', value: '5' },
        { label: '10 Holdings', value: '10' }
      ]
    }],
    entityType: [FUND, INSTITUTION]
  },
  managedFunds: {
    dataId: 'ManagedFunds',
    label: 'Managed Funds',
    options: [{
      dataId: 'ManagedFundsOptions',
      type: 'limit',
      select: [
        { label: '5 Funds', value: '5' },
        { label: '10 Funds', value: '10' }
      ]
    }],
    entityType: [CONTACT, INSTITUTION]
  },
  industryAnalysis: {
    dataId: 'IndustryAnalysis',
    label: 'Sector, Market Cap, Region',
    entityType: [FUND, INSTITUTION]
  },
  topIndustryBuySell: {
    dataId: 'TopIndustryBuySell',
    label: 'Top Industry Buys, Sells, Holdings',
    entityType: [FUND, INSTITUTION]
  },
  ownershipChart: {
    dataId: 'OwnershipChart',
    label: 'Ownership',
    options: [{
      dataId: 'OwnershipChartOptions',
      type: 'limit',
      select: [
        { label: '1 Year (in quarters)', value: '4' },
        { label: '2 Years', value: '8' },
        { label: '3 Years', value: '12' }
      ]
    }],
    entityType: [CONTACT, FUND, INSTITUTION]
  },
  investmentApproach: {
    dataId: 'InvestmentApproach',
    label: 'Investment Approach',
    entityType: [INSTITUTION]
  },
  entityComment: {
    dataId: 'Comments',
    label: 'Comments',
    entityType: [CONTACT, FUND, INSTITUTION]
  },
  tags: {
    dataId: 'Tags',
    label: 'Tags',
    entityType: [CONTACT, FUND, INSTITUTION]
  }
}
