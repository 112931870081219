import { fetchInstitutionCurrentPosition, fetchFundCurrentPosition } from '../ownership'

/**
 *  Tearsheet Action Type Constants
 * @type {string}
 */
export const GET_TEARSHEET_REQUEST = 'GET_TEARSHEET_REQUEST'
export const GET_TEARSHEET_SUCCESS = 'GET_TEARSHEET_SUCCESS'
export const GET_TEARSHEET_FAILURE = 'GET_TEARSHEET_FAILURE'

export const GENERATE_TEARSHEET_REQUEST = 'GENERATE_TEARSHEET_REQUEST'
export const GENERATE_TEARSHEET_SUCCESS = 'GENERATE_TEARSHEET_SUCCESS'
export const GENERATE_TEARSHEET_FAILURE = 'GENERATE_TEARSHEET_FAILURE'

export const GET_TEARSHEET_ENTITY_CURRENT_POSITION_REQUEST = 'GET_TEARSHEET_ENTITY_CURRENT_POSITION_REQUEST'
export const GET_TEARSHEET_ENTITY_CURRENT_POSITION_SUCCESS = 'GET_TEARSHEET_ENTITY_CURRENT_POSITION_SUCCESS'
export const GET_TEARSHEET_ENTITY_CURRENT_POSITION_FAILURE = 'GET_TEARSHEET_ENTITY_CURRENT_POSITION_FAILURE'

/**
 * Dispatch GET_TEARSHEET_ENTITY_CURRENT_POSITION_REQUEST
 * @param params
 */
export const getEntityPosition = (params) => (dispatch) => {
  const _fetchCurrentPosition = params && (params.entityType === 'fund')
    ? fetchFundCurrentPosition
    : fetchInstitutionCurrentPosition

  return dispatch(_fetchCurrentPosition(params, {
    types: [
      GET_TEARSHEET_ENTITY_CURRENT_POSITION_REQUEST,
      GET_TEARSHEET_ENTITY_CURRENT_POSITION_SUCCESS,
      GET_TEARSHEET_ENTITY_CURRENT_POSITION_FAILURE
    ]
  }))
}
