import {
  FAILED,
  FETCH_ESTIMATES_BROKER_ERROR,
  FETCH_ESTIMATES_BROKER_REQUEST,
  FETCH_ESTIMATES_BROKER_SUCCESS,
  UPDATE_RESTRICTED_REQUEST,
  UPDATE_RESTRICTED_SUCCESS,
  RESET_ESTIMATES,
  SET_BROKER_DATES,
  FETCHED,
  FETCHING,
  IDLE,
  SET_ESTIMATES_BROKER_METRIC,
  ESTIMATE_PEER_CHANGED,
  SET_BROKER_QUARTER_DATES
} from '../../actions/estimates'

const initialState = {
  status: IDLE,
  items: {
    estimates: []
  },
  dates: [],
  selected: {}
}

const estimatesBroker = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ESTIMATES:
      return {
        ...initialState
      }
    case ESTIMATE_PEER_CHANGED:
      return {
        ...state,
        items: {
          estimates: []
        },
        selected: {
          metric: (action.payload ? 'eps' : state.selected.metric)
        }
      }
    case SET_BROKER_QUARTER_DATES:
      return {
        ...state,
        dates: action.dates
      }
    case SET_BROKER_DATES:
      return {
        ...state,
        items: {
          estimates: []
        },
        selected: {
          ...state.selected,
          ...action.dates
        }
      }
    case SET_ESTIMATES_BROKER_METRIC:
      return {
        ...state,
        items: {
          estimates: []
        },
        selected: {
          ...state.selected,
          metric: action.metric
        }
      }
    case UPDATE_RESTRICTED_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_ESTIMATES_BROKER_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_ESTIMATES_BROKER_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        items: action.payload
      }
    case UPDATE_RESTRICTED_SUCCESS:
      const _updated = action.payload
      const estimates = [...state.items.estimates]
      let found = false
      estimates.map((each) => {
        if ((each._id && each._id === _updated._id) ||
                    (each.broker_id && _updated.broker_id === each.broker_id &&
                        each.quarter_est === _updated.quarter_est &&
                        each.year_est === _updated.year_est)) {
          found = true
          return Object.assign(each, _updated)
        }
        return each
      })
      if (!found) {
        estimates.push(_updated)
      }
      return {
        ...state,
        status: FETCHED,
        items: { ...state.items, estimates: estimates }
      }

    case FETCH_ESTIMATES_BROKER_ERROR:
      return {
        ...state,
        status: FAILED,
        error: action.error
      }
    default:
      return state
  }
}

export default estimatesBroker
