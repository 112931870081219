import _ from 'lodash'
import differenceBy from 'lodash/differenceBy'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  loadPeerAlerts,
  loadPeerList,
  FETCHING,
  FETCHED
} from '../../../actions/widget/watchlist'
import { downloadResearch } from '../../../actions/widget/watchlist/peer.actions'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderLightThumb, renderDarkThumb, renderTrackVertical } from '../../../resources/theme/q4.custom-scrollbar'
import { format, getValueSign, intToMillions } from '../../../utils/number.util'
import { getFromXigniteToStandard, formatStockPrice, formatCurrency } from '../../../utils/stock/stock.util'
import { getLocalStorageItem } from '../../../utils/localStorage.util'
import { setClassNames } from '../../../utils/widget.util'
import { Modal, Spinner } from '../../../components'
import PeerAlertsItem from '../../../components/widget/peerAlerts/peerAlertsItem/peerAlertsItem.component'
import WidgetError from '../../../components/widget/error/widgetError.component'
import { getTickers } from '../../../utils'
import './watchlist.container.css'
import { withRouter } from 'react-router-dom'

const ALERT_LS_PREFIX = 'ALERT_'

class WatchList extends Component {

  constructor (props) {
    super(props)
    this.state = {
      isAlertModalOpen: false
    }
  }

  _loadPeerAlerts = () => {
    const { profile } = this.props
    const securities = ((profile && profile._organization && profile._organization.tickers) || [])
      .sort((a) => {
        if (a.primary) {
          return -1
        }
        return 1
      }).map((ticker) => ticker._security).slice(0, 5)

    const peerListParams = {
      service: ['stock', 'average_volume'],
      security: securities,
      select: ['symbol', 'exchange'],
      limit: 20
    }

    this.props.loadPeerList(peerListParams).then(() => {

      const alertParams = {
        alerts: this.props.options.alerts || [],
        alertHours: this.props.options.alertHours || 24,
        security: this.props.peers.map((peer) => peer._security),
        limit: 20
      }

      this.props.loadPeerAlerts(alertParams)
    })
  }

  /**
   * Triggered when component mounted
   * Enable auto-reloading if enabled
   */
  componentDidMount = () => {
    const { isEdit, status } = this.props
    if (isEdit && status === FETCHED) {
      return
    }

    const refreshInterval = this.props.options.refreshInterval
    if (refreshInterval) {
      this.timer = setInterval(this._loadPeerAlerts.bind(this), refreshInterval)
    }

    this._loadPeerAlerts()
  }

  /**
   * ComponentDidUpdate
   * Re-fetch data when security changed
   * @param prevProps
   */
  componentDidUpdate = (prevProps) => {
    if (prevProps.securityId !== this.props.securityId) {
      this._loadPeerAlerts()
    }
  }

  /**
   * clear fetch interval on unmount
   */
  componentWillUnmount = () => {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  /**
   * Open Alert Modal
   * @param alertItem
   */
  openAlertModal = (alertItem) => {
    this.setState({
      activeAlertItem: alertItem,
      isAlertModalOpen: true
    })
  }

  /**
   * Close Alert Modal
   */
  closeAlertModal = () => {
    this.setState({
      isAlertModalOpen: false
    })
  }

  /**
   * Redirect to the security page
   * @param tickerId
   * @returns {*}
   */
  redirect = (tickerId) => {
    const { history } = this.props
    return tickerId && history.push(`/security/${tickerId}`)
  }

  getMyCompany = () => {
    const { profile, securityId } = this.props
    const tickers = getTickers(profile)
    return _.find((tickers), (ticker) => ticker._security === securityId) || _.find((tickers), (ticker) => ticker.primary)
  }

  /**
   * Find distinct alerts between api response and local storage
   * @param newItems
   * @param lsItems
   * @return {*}
   */
  getDistinctAlertItems = (newItems, lsItems) => {
    // if no items came from API - return empty array
    if (!newItems || !newItems.length) {
      return []
    }

    // if no items in local storage - treat all items as new
    if (!lsItems || !lsItems.length) {
      return newItems
    }

    return differenceBy(newItems, lsItems, 'item')
  }

  /**
   * Mark items as read by saving them into local storage
   * @param item
   */
  setAlertsRead = (item) => {
    localStorage.setItem(`${ALERT_LS_PREFIX}${item._security}`, JSON.stringify(item))
  }

  /**
   * get column widths
   * @param layout
   * @returns {{dayRange: number, week: number, vol: number, avgVol: number, notification: number}}
   */
  getWidths = (layout) => {
    if (layout.w > 1) {
      return {
        dayRange: 90,
        week: 90,
        vol: 55,
        avgVol: 75,
        notification: 65
      }
    } else {
      return {
        dayRange: 90,
        week: 90,
        vol: 60,
        avgVol: 50,
        notification: 45
      }
    }
  }

  getServiceData (item, serviceType) {
    const service = ((item.services || []).find((service) => service.type === serviceType))

    if (service) {
      return service.data
    }
  }

  getFormattedStockValue (value, currency) {
    const _value = formatStockPrice(value, currency)
    return format(Math.abs(_value), 2)
  }

  /**
   * Render Watchlist items
   * @param items
   * @param widths
   * @returns {XML}
   */
  renderItems = (items, widths) => {
    const { peerAlerts } = this.props
    const dropNegative = true
    const company = this.getMyCompany()

    if (company) {
      const myCompanyItem = (items || []).find((item) => item._security === company._security)

      // put my company in the first position
      if (myCompanyItem) {
        items = items.filter((item) => item._security !== myCompanyItem._security)
        items.unshift(myCompanyItem)
      }
    }

    return items.map((item) => {

      const peerAlertsItem = _.find(peerAlerts, (alertItem) => alertItem._security === item._security)
      const stock = this.getServiceData(item, 'stock') || {}
      const averageVolume = this.getServiceData(item, 'average_volume')
      const exchange = (item.exchange && getFromXigniteToStandard(item.exchange)) || ''
      const currency = (stock && stock.Currency) || 'USD'

      let active = (company && company._security && company._security === item._security)
      let newAlerts = []

      if (peerAlertsItem) {
        const alertLsItem = getLocalStorageItem(`${ALERT_LS_PREFIX}${peerAlertsItem._security}`)
        newAlerts = this.getDistinctAlertItems(peerAlertsItem.alerts, (alertLsItem && alertLsItem.alerts) || [])
      }

      let alertsClass = [
        'watchlist-widget_notifications',
        newAlerts.length ? 'watchlist-widget_notifications--new' : ''
      ].join(' ')

      // TODO: once the ticketId comes in, replace _security by tickerId
      const handleColClick = () => this.redirect(item._security)

      return (
        <tr className={`watchlist-widget_item ${active ? 'watchlist-widget_item--mine' : ''}`} key={item._security}>
          <td className='watchlist-widget_cell' onClick={handleColClick}>
            <span>{item.symbol}</span>
            <span>{exchange}</span>
          </td>
          <td className='watchlist-widget_cell' onClick={handleColClick}>
            {stock && stock.Last ? (
              <span>
                                {this.getFormattedStockValue(stock.Last, currency)} <span
                className='watchlist-widget_silver-text'>{formatCurrency(currency)}</span>
                            </span>
            ) : <span className='watchlist-widget_silver-text'>-</span>}
            <span
              className={`watchlist-widget_change watchlist-widget_change--${getValueSign(stock && stock.ChangeFromPreviousClose)}`}>
                            {this.getFormattedStockValue(stock && stock.ChangeFromPreviousClose, currency)}
              {stock && stock.PercentChangeFromPreviousClose &&
              ` (${format(stock && stock.PercentChangeFromPreviousClose, 2, null, dropNegative)}%)`}
                        </span>
          </td>
          <td className='watchlist-widget_cell watchlist-widget_cell--day-range' width={widths.dayRange}
              onClick={handleColClick}>
            {stock && stock.High ? (
              <span>{this.getFormattedStockValue(stock.High, currency)} <i
                className='q4i-arrow-up-4pt'/></span>
            ) : <span className='watchlist-widget_silver-text'>-</span>}
            {stock && stock.Low ? (
              <span>{this.getFormattedStockValue(stock.Low, currency)} <i
                className='q4i-arrow-down-4pt'/></span>
            ) : <span className='watchlist-widget_silver-text'>-</span>}
          </td>
          <td className='watchlist-widget_cell watchlist-widget_cell--52-week' width={widths.week}
              onClick={handleColClick}>
            {stock && stock.High52Weeks ? (
              <span>{this.getFormattedStockValue(stock.High52Weeks, currency)} <i
                className='q4i-arrow-up-4pt'/></span>
            ) : <span className='watchlist-widget_silver-text'>-</span>}
            {stock && stock.Low52Weeks ? (
              <span>{this.getFormattedStockValue(stock.Low52Weeks, currency)} <i
                className='q4i-arrow-down-4pt'/></span>
            ) : <span className='watchlist-widget_silver-text'>-</span>}
          </td>
          <td className='watchlist-widget_cell' width={widths.vol}
              onClick={handleColClick}>
            {stock && stock.Volume ? (
              <span>
                                {intToMillions((stock && stock.Volume) || 0)}<span
                className='watchlist-widget_silver-text'>M</span>
                            </span>
            ) : <span className='watchlist-widget_silver-text'>-</span>}
          </td>
          <td className='watchlist-widget_cell watchlist-widget_cell--average-volume' width={widths.avgVol}
              onClick={handleColClick}>
            {averageVolume ? (
              <span>
                                {intToMillions(averageVolume || 0)}<span
                className='watchlist-widget_silver-text'>M</span>
                            </span>
            ) : <span className='watchlist-widget_silver-text'>-</span>}
          </td>
          <td className='watchlist-widget_cell' width={widths.notification} onClick={() => {
            this.setAlertsRead(peerAlertsItem)
            this.openAlertModal(peerAlertsItem)
          }}>
            <div className={alertsClass}>
              {newAlerts.length}
            </div>
          </td>
        </tr>
      )
    })
  }

  /**
   * Render Peer Alert Modal items
   * @param alertItem
   * @returns {*}
   */
  renderAlertItem = (alertItem) => {
    if (!alertItem || !alertItem.alerts || !alertItem.alerts.length) {
      return <p>No new alerts.</p>
    }

    const { downloadResearch } = this.props

    const security = this.props.peers.find((peer) => peer._security === alertItem._security)

    const exchange = (security && security.exchange && getFromXigniteToStandard(security.exchange)) || ''

    return (alertItem.alerts).sort((a, b) => new Date(b.date) - new Date(a.date)).map((alert) => {
      return (
        <PeerAlertsItem key={alert.item}
                        ticker={`${security && security.symbol} ${exchange}`}
                        item={alert}
                        downloadItem={(params) => downloadResearch(params)}/>
      )
    })
  }

  /**
   * Render Watchlist Widget Component
   * @returns {XML}
   */
  render () {
    const { peers, theme, layout, status, history } = this.props
    const { isAlertModalOpen, activeAlertItem } = this.state
    const is_loading = status === FETCHING
    const widths = this.getWidths(layout)
    const classes = setClassNames('watchlist-widget', layout, theme)
    const renderThumb = theme === 'dark' ? renderLightThumb : renderDarkThumb

    if (!is_loading && _.isEmpty(peers)) {
      return (
        <WidgetError
          theme={theme}
          header={'Peer Alerts'}
          linkText={'Add Peers'}
          linkUrl={`/peerlist`}
          message={'Looks like you don\'t have any Peers added to your Peerlist.'}
        />
      )
    }

    return (
      <div className={classes.base}>
        <header className='watchlist-widget_header'>
          <table>
            <tbody>
            <tr>
              <td className='watchlist-widget_cell' colSpan={2}
                  onClick={() => history.push('/peerlist')}>Peer Alerts
              </td>
              <td className='watchlist-widget_cell watchlist-widget_cell--day-range'
                  width={widths.dayRange}>INTRADAY
              </td>
              <td className='watchlist-widget_cell watchlist-widget_cell--52-week'
                  width={widths.week}>52 WEEK
              </td>
              <td className='watchlist-widget_cell' width={widths.vol}>VOL</td>
              <td className='watchlist-widget_cell watchlist-widget_cell--average-volume'
                  width={widths.avgVol}>AVG VOL
              </td>
              <td className='watchlist-widget_cell' width={widths.notification}>
                <i className='q4i-notifications-4pt'/>
              </td>
            </tr>
            </tbody>
          </table>
        </header>
        <section className='watchlist-widget_items'>
          {_.isEmpty(peers) ? <Spinner/> :
            <Scrollbars
              className="react-scrollbar"
              autoHide
              hideTracksWhenNotNeeded
              renderThumbVertical={renderThumb}
              renderTrackVertical={renderTrackVertical}>
              <table>
                <tbody>
                {
                  this.renderItems(this.props.peers, widths)}
                </tbody>
              </table>
            </Scrollbars>
          }
        </section>
        <footer className='watchlist-widget_footer'>
          <span>Stock based on 15 minute delay. Volume change based on 1 day. Average volume based on 1 month.</span>
        </footer>
        <Modal
          visible={isAlertModalOpen}
          title='Peer Alerts'
          contentPadding='20px 20px 10px'
          scrollable={true}
          contentBackground={true}
          onClose={this.closeAlertModal}
          spinnerProps={{
            maskOpacity: 1
          }}
        >
          <ul className='watchlist-widget_alerts-list'>
            {this.renderAlertItem(activeAlertItem)}
          </ul>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const watchlist = state.widget.watchlist
  return {
    peers: watchlist.list.items || [],
    peerAlerts: watchlist.peerAlerts.items || [],
    watchlistActiveItem: watchlist.list.watchlistActiveItem,
    status: watchlist.list.status,
    theme: state.dashboard.dashboardState.theme,
    securityId: state.dashboard.dashboardState.securityId
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadPeerList: bindActionCreators(loadPeerList, dispatch),
  loadPeerAlerts: bindActionCreators(loadPeerAlerts, dispatch),
  downloadResearch: bindActionCreators(downloadResearch, dispatch)
})

WatchList.propTypes = {
  peers: PropTypes.array.isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WatchList))
