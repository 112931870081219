import {
  GET_SECURITY_REQUEST,
  GET_SECURITY_SUCCESS,
  GET_SECURITY_FAILURE,
  statusType
} from '../../actions'

const initialState = {}

const security = (state = initialState, action) => {
  switch (action.type) {
    case GET_SECURITY_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }
    case GET_SECURITY_SUCCESS:
      return {
        data: action.payload,
        status: statusType.SUCCESS
      }
    case GET_SECURITY_FAILURE:
      return {
        status: statusType.ERROR
      }
    default:
      return state
  }
}

export default security
