import React, {Component} from 'react';
import { ComboBox } from '../../../index'
import {getItineraryAddresses, THEMES} from '../../../../utils';
import { Message } from '../../../../components';
import { Checkbox } from '../../../../components/shared'
import './activityMultiLocation.component.css';
import {statusType} from '../../../../actions';

const enterKey = 13
const arrowKeys = [38, 40]

class ActivityMultiLocation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            query: '',
            Confirmation: null,
            address: props.address
        };
        this.selectReference = React.createRef()
        this.customValueCreation = false
        this.prevKey = null
    }

    componentDidMount() {
        this.setInitialParentLocations();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {activityFormState} = this.props;
        if (activityFormState.status !== prevProps.activityFormState.status && activityFormState.status === statusType.SUCCESS) {
            this.setInitialParentLocations();
        }
    }

    /**
     * If you are editing an activity that holds itineraries,
     * set the initial location values, which makes up of all the locations of each itinerary.
     */
    setInitialParentLocations = () => {
        const {address, isEdit, itinerary, setFieldValue} = this.props;
        let addressValues = address || [];

        if (isEdit && Array.isArray(itinerary) && itinerary.length) {
            let itineraryAddresses = (getItineraryAddresses(itinerary) || []).map((item) => {
                item.isChild = true; // API does some magic here, so the address does not get saved in the parent activity.
                return item;
            });
            const addressLocations = (addressValues || []).map((item) => item.location);
            itineraryAddresses = itineraryAddresses.filter((item) => !addressLocations.includes(item.location));
            setFieldValue('address', addressValues.concat(itineraryAddresses));
        }
    };

    /**
     * Handle Input Change
     * @param query String
     */
    handleInputChange = (query, b, c) => {
        const {clearSearchData, searchLocation} = this.props;
        this.disableResultFocus()
        this.setState({ query }, query ? searchLocation(query) : clearSearchData())
    };

    /**
     * Disable Result Focus
     * Reference passed to Select to prevent focus on first option
     */
    disableResultFocus () {
        this.selectReference.current.select.getNextFocusedOption = () => null
    }

    /**
     * Handle Change
     * Compares selected location against current locations and add if unique
     * @param location
     */
    handleChange = (location) => {
      if (this.customValueCreation) {
        return
      }
        const {address, setFieldValue, clearSearchData} = this.props;
        const locationExists = (address || []).some((item) => item.location === location.label);

        if (!locationExists) {
          setFieldValue('address', [].concat(address, { location: location.label }))
        }
        this.setState({query: ''});
        clearSearchData();
    };

  /**
   * Handle Key Down
   * Allow users to submit custom locations via the enter key
   * @param event
   */
  handleKeyDown = (event) => {
      const { address, setFieldValue, clearSearchData } = this.props
      if (!event || !event.target) {
        return
      }
      const { keyCode, target } = event
      const { value } = target

      // If user has pressed enter and hasn't used arrow key navigation to highlight result, enter custom value
      if (keyCode === enterKey && !arrowKeys.some((key) => key === this.prevKey)) {
        this.customValueCreation = true
        this.selectReference.current.select.onClearIndicatorTouchEnd = () => null

        this.setState({ query: '' }, () => {
          setFieldValue('address', [].concat(address, { location: value }))
          this.customValueCreation = false
          clearSearchData()
        })
      }

      this.prevKey = keyCode
    }

    /**
     * Format values for Select and Removable List
     * @param location
     * @returns {*}
     */
    getFormattedValues (data) {
        return (data || [])
          .map((address) => {
            return {
              label: address.location ? address.location : this.formatLocation(address),
              value: address.location ? address.location : this.formatLocation(address)
            }
          })
    };

    /**
     * Format location to user friendly view
     * @param location
     * @returns {*}
     */
    formatLocation = (location) => {
        if (!location) {
            return null;
        }

        const province = location.state_province_name ? location.state_province_code : null;

        return [location.location_city, province, location.country_name]
            .filter((item) => {
                return !!item;
            })
            .join(', ');
    };

    /**
     * Filters and removes selected location object from array of selected locations
     * @param location {}
     * @param confirmed {Boolean}
     */
    removeLocation = (location, confirmed = false) => {
        const {address, isEdit, setFieldValue} = this.props;

        if (isEdit && !confirmed) {
            return this.showDialogConfirmation(
                'Delete location?',
                'It will be removed from all Itinerary Activities as well.',
                this.removeLocation.bind(this, location, true),
                'Yes'
            );
        }

        setFieldValue('address', (address || []).filter((item) => item.location !== location));
    };

    showDialogConfirmation(title, text, fn, btnLabel = 'Proceed') {
        this.setState({
            Confirmation: this.renderConfirmation(title, text, fn, btnLabel)
        });
    }

    hideDialogConfirmation() {
        this.setState({
            Confirmation: null
        });
    }

    renderConfirmation = (title, text, fn, btnLabel) => {
        return () => {
            return (
                <Message
                    visible={true}
                    type='warning'
                    title={title}
                    message={text}
                    buttons={[
                        {
                            ui: 'shaded',
                            label: 'Cancel',
                            onClick: () => {
                                this.hideDialogConfirmation();
                            }
                        },
                        {
                            ui: 'spice',
                            label: btnLabel,
                            onClick: () => {
                                fn.call(this);
                                this.hideDialogConfirmation();
                            }
                        }
                    ]}/>
            );
        };
    };
    render() {
        const {search, address, virtual, setFieldValue} = this.props;
        const {query, Confirmation} = this.state;

        return (
            <React.Fragment>
                <div className='activity-form__activity-multi-location activity-form--section'>
                    <div className='field field--text field--full'>
                        <label className='field_label'>
                            Location
                        </label>
                        <ComboBox
                          theme={THEMES.LIGHT_GREY}
                          selectProps={{
                            selectReference: this.selectReference,
                            placeholder: 'City, State, Country',
                            options: this.getFormattedValues((search && search.data) || []),
                            value: null,
                            inputValue: query,
                            onInputChange: this.handleInputChange,
                            onChange: this.handleChange,
                            onKeyDown: this.handleKeyDown,
                            closeMenuOnSelect: true,
                            disabled: !!virtual
                          }}
                          removableListProps={{
                            items: !!virtual
                              ? []
                              : this.getFormattedValues((address || []).filter((item) => item.location && item.location.length)),
                            onRemove: (location) => this.removeLocation(location)
                          }}
                        />
                    </div>
                    <Checkbox
                      theme={THEMES.LIGHT}
                      className='activity-multi-location_checkbox'
                      id='activity-location_virtual'
                      isChecked={!!virtual}
                      label='Virtual'
                      onChange={(virtual) => setFieldValue('virtual', virtual)}
                    />
                </div>
                {!!Confirmation && <Confirmation/>}
            </React.Fragment>
        );
    }
}

export default ActivityMultiLocation;
