
/**
 * Reducer Decorator that helps to prevent race conditions by
 * returning the most latest state based on timestamps in
 * action and state.
 * @param {function} reducer
 * @param {object} initialState
 * @param {array} actions
 */
export function takeLatest (reducer, initialState, actions) {
  return function (state, action) {
    if (!action || !state) {
      return state || initialState
    }

    if (!actions.includes(action.type)) {
      return state
    }

    if (action.timestamp < state.timestamp) {
      return state
    }

    state.timestamp = action.timestamp

    return reducer(state, action)
  }
}