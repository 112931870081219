import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'

const GET_COMMENT = gql`
  query Comment($entityId: [String]!) {
    comment(entityId: $entityId) {
      items {
        id
        comment
      }
    }
  }`

const CREATE_COMMENT = gql`
  mutation Comment(
    $entity: CommentaryEntity!
    $comment: String!
  ) {
    comment {
      create(
        entity: $entity
        comment: $comment
      ) {
        items {
          id
        }
      }
    }
  }`

export const UPDATE_COMMENT = gql`
  mutation Comment(
    $id: [String]!
    $comment: String!
  ) {
    comment {
      update(
        id: $id
        comment: $comment
      ) {
        count
      }
    }
  }`

const DELETE_COMMENT = gql`
  mutation Comment($id: [String]!) {
    comment {
      delete(id: $id) {
        count
      }
    }
  }`

/**
 * Comment get
 * @param options
 */
export function useCommentQuery (options) {
  return useQuery(GET_COMMENT, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Comment create
 * @param options
 */
export function useCommentCreateQuery (options) {
  return useMutation(CREATE_COMMENT, options)
}

/**
 * Comment update
 * @param options
 */
export function useCommentUpdateQuery (options) {
  return useMutation(UPDATE_COMMENT, options)
}

/**
 * Comment delete
 * @param options
 */
export function useCommentDeleteQuery (options) {
  return useMutation(DELETE_COMMENT, options)
}
