import moment from 'moment-timezone'
import { capitalize, isEmpty, last, uniqBy, first, get } from 'lodash'
import emojiRegex from 'emoji-regex'
import {
  getDefaultHeaderClasses,
  getDefaultCellClassRules,
  getDefaultColumnWidth,
  getDefaultValueFormatter
} from '../agGrid.util'
import {
  getFilterSummaryLabel,
  getFieldById,
  getIsGroupField,
  getIsReportType,
  stockEntityType
} from '../report'

/**
 * Strip emoji from the string
 * @param string
 * @returns {*}
 */
export const emojiStrip = (string) => {
  const emRegex = emojiRegex();
  return string.replace(emRegex, '');
}

/**
 * Get Excel Export Entity rows
 * @param mergeAcross
 * @param entityType
 */
const _getEntityRows = (mergeAcross, entityType) => {
  if (!entityType || !entityType.length) {
    return []
  }

  return [
    [{
      styleId: 'subtitle-row',
      data: { type: 'String', value: 'Data Type(s):' },
      mergeAcross
    }],
    [{
      styleId: 'clear-row',
      data: {
        type: 'String',
        value: (entityType || []).map((type, index) => capitalize(type.label)).join(', ')
      },
      mergeAcross
    }]
  ]
}

/**
 * Get Excel Export Sort rows
 * @param mergeAcross
 * @param reportFields
 * @param sort
 */
const _getSortRows = (mergeAcross, reportFields, sort) => {
  if (!sort || !sort.length) {
    return []
  }

  return [
    [{
      styleId: 'subtitle-row',
      data: { type: 'String', value: 'Sorted By:' },
      mergeAcross
    }],
    ...(sort || []).map((sortItem) => {
      const field = getFieldById(reportFields, sortItem._field)
      const fieldString = field && field.columnName
      const directionString = ` (${sortItem.direction})`
      const quarterDate = sortItem.quarter && moment(sortItem.quarter, 'YYYYMMDD')
      const quarterString = quarterDate ? ` (Q${quarterDate.quarter()} ${quarterDate.format('YYYY')})` : ''

      return [{
        styleId: 'clear-row',
        data: {
          type: 'String',
          value: `${fieldString}${quarterString}${directionString}`
        },
        mergeAcross
      }]
    })
  ]
}

/**
 * Get Excel Export Filter rows
 * @param mergeAcross
 * @param filters
 */
const _getFilterRows = (mergeAcross, filters) => {
  if (!filters || !filters.length) {
    return []
  }

  return [
    [{
      styleId: 'subtitle-row',
      data: { type: 'String', value: 'Filtered By:' },
      mergeAcross
    }],
    ...(filters || []).map((filter) => {
      const { _field, state } = filter
      const fieldString = _field && _field.label
      const quarterDate = state.quarter && moment(state.quarter, 'YYYYMMDD')
      const quarterString = quarterDate ? ` (Q${quarterDate.quarter()} ${quarterDate.format('YYYY')})` : ''
      const summaryString = getFilterSummaryLabel(filter)

      return [{
        styleId: 'clear-row',
        data: {
          type: 'String',
          value: `${fieldString}${quarterString} - ${summaryString}`
        },
        mergeAcross
      }]
    })
  ]
}

// TODO: Reintroduce these functions to complete grid border hierarchy design

// /**
//  * Get Ag-grid headerClass for column definitions
//  * @param columnIndex
//  * @param name
//  * @param typeOfValue
//  * @returns {string}
//  */
// export const getHeaderClass = (columnIndex, name, typeOfValue) => {
//   const isPivotColumn = columnIndex === 'pivot'
//   let className = getDefaultHeaderClasses(columnIndex, typeOfValue, false)
//
//   if (isPivotColumn) {
//     className += ' ag-header-cell--bordered'
//   }
//
//   return className
// }

// /**
//  * Determine odd quarter depending on pivotKey date string
//  * @param pivotKey
//  * @returns {boolean|number}
//  */
// const getIsOddQuarter = (pivotKey) => {
//   const dateObject = moment.utc(pivotKey, moment.ISO_8601)
//
//   if (!dateObject.isValid()) {
//     return true
//   }
//
//   return dateObject.quarter() % 2
// }

/**
 * Get Ag-grid cellClassRules for column definitions
 * @param columnIndex
 * @param name
 * @param type
 * @returns {*}
 */
export const getCellClassRules = (columnIndex, name, type) => {
  const rules = getDefaultCellClassRules(columnIndex, type, name, false)

  if (type === 'number') {
    rules['ag-cell--decimal'] = (params) => !isNaN(params.value) && !Number.isInteger(params.value)
  }

  return rules
}

/**
 * Format exported Excel/CSV cell
 * @param cell
 * @return {*}
 */
export const processExportedCell = (cell) => {
  const processedValue = cell.value

  switch (typeof processedValue) {
    case 'string':
      // Get rid of -> added by agGrid
      return emojiStrip(processedValue.replace(/^ -> /, ''))
    case 'boolean':
      return processedValue ? 'Yes' : 'No'
    default:
      return processedValue
  }
}

/**
 * Get header title rows for Excel export
 * @param columnCount
 * @param reportTitle
 * @param lastRevision
 */
export const getHeaderRows = (columnCount, reportTitle, lastRevision) => {
  // subtract one column from the counter to calculate merged column span
  const mergeAcross = columnCount - 1

  const headerRows = [
    [{ styleId: 'blue-row', data: { type: 'String', value: '' }, mergeAcross }],
    [{
      styleId: 'title-row',
      data: {
        type: 'String',
        value: reportTitle
      },
      mergeAcross
    }],
    [{ styleId: 'blue-row', data: { type: 'String', value: '' }, mergeAcross }]
  ]

  if (lastRevision) {
    const asOfString = `Updated on ${lastRevision.date}`
    const generatedByString = `Generated by ${lastRevision.name}`

    headerRows.splice(2, 0,
      [{
        styleId: 'blue-row',
        data: {
          type: 'String',
          value: asOfString
        },
        mergeAcross
      }],
      [{
        styleId: 'blue-row',
        data: {
          type: 'String',
          value: generatedByString
        },
        mergeAcross
      }]
    )
  }

  return headerRows
}

/**
 * Get header data configuration rows for Excel export
 * @param columnCount
 * @param reportFields
 * @param entityType
 * @param filters
 * @param sort
 */
export const getConfigRows = (columnCount, reportFields, entityType, filters, sort) => {
  // subtract one column from the counter to calculate merged column span
  const mergeAcross = columnCount - 1

  const entityRows = _getEntityRows(mergeAcross, entityType)
  const configRows = [
    [{ styleId: 'clear-row', data: { type: 'String', value: '' }, mergeAcross }],
    ...entityRows,
    [{ styleId: 'clear-row', data: { type: 'String', value: '' }, mergeAcross }]
  ]

  if (sort && sort.length) {
    const sortRows = _getSortRows(mergeAcross, reportFields, sort)

    configRows.push(
      ...sortRows,
      [{ styleId: 'clear-row', data: { type: 'String', value: '' }, mergeAcross }]
    )
  }

  if (filters && filters.length) {
    const filterRows = _getFilterRows(mergeAcross, filters)

    configRows.push(
      ...filterRows,
      [{ styleId: 'clear-row', data: { type: 'String', value: '' }, mergeAcross }]
    )
  }

  return configRows
}

/**
 * Get header string for CSV export
 * @param columnCount
 * @param reportTitle
 * @param lastRevision
 * @param reportFields
 * @param entityType
 * @param filters
 * @param sort
 * @returns {string}
 */
export const getHeaderString = (columnCount, reportTitle, lastRevision, reportFields, entityType, filters, sort) => {
  const mergedRows = [
    ...getHeaderRows(columnCount, reportTitle, lastRevision),
    ...getConfigRows(columnCount, reportFields, entityType, filters, sort)
  ]

  return mergedRows.reduce((headerString, row) => {
    if (row[0] && row[0].data) {
      headerString += ((row[0].data.value || '').replace(/,/g, ' ') + '\n')
      return headerString
    }

    return headerString
  }, '')
}

/**
 * Format individual column
 * @param reportDataConfig
 * @param field
 * @param columnIndex
 * @param extraColOptions
 * @return {*}
 */
const getFormattedColumn = (reportDataConfig, field, columnIndex, extraColOptions = {}) => {
  const { sort, isPivot } = reportDataConfig
  const { _id, name, width, columnName, sortable, type } = field

  let sortEnabled = sortable

  // sorting of holding fields in non-pivot mode is currently not supported
  if (name.indexOf('holdings.') > -1 && !isPivot) {
    sortEnabled = false
  }

  const columnDef = {
    field: name,
    headerName: columnName,
    headerClass: getDefaultHeaderClasses(columnIndex, type, false),
    cellClassRules: getCellClassRules(columnIndex, name, type),
    valueFormatter: getDefaultValueFormatter(type),
    width: getDefaultColumnWidth(type),
    minWidth: width ? (width * 6) : getDefaultColumnWidth(type),
    lockPinned: true,
    suppressMenu: true,
    sortable: sortEnabled,
    comparator: () => {} // empty sorting comparator to ignore client sort
  }

  if (type === 'number') {
    columnDef.type = 'numericColumn'
  }

  if (sortEnabled) {
    const sortItem = (sort || []).find((sortItem) => sortItem._field === _id)

    if (sortItem) {
      Object.assign(columnDef, {
        sortQuarter: sortItem.quarter ? sortItem.quarter : 'all',
        sort: sortItem.direction.toLowerCase()
      })
    }
  }

  if (isEmpty(extraColOptions)) {
    return columnDef
  }

  return { ...columnDef, ...extraColOptions }
}

/**
 * Get formatted pivot column
 * @param reportDataConfig
 * @param field
 * @param columnIndex
 * @returns {*}
 */
const getFormattedPivotColumn = (reportDataConfig, field, columnIndex) => {
  return getFormattedColumn(reportDataConfig, field, columnIndex, {
    pivot: true,
    pivotComparator: field.type === 'date' ? (a, b) => {
      let result

      if (!a || !b) {
        result = 1
      }

      const dateA = moment(a).endOf('quarter')
      const dateB = moment(b).endOf('quarter')

      if (dateA.isBefore(dateB)) {
        result = 1
      } else if (dateA.isSame(dateB)) {
        result = 0
      } else {
        result = -1
      }

      return result
    } : null
  })
}

/**
 * Get formatted group column
 * @param field
 * @returns {*}
 */
const getFormattedGroupColumn = (field) => {
  const { columnName, width, type, name } = field

  return {
    rowGroup: true,
    hide: true,
    headerName: columnName,
    field: name,
    width: getDefaultColumnWidth(type),
    minWidth: width ? (width * 6) : getDefaultColumnWidth(type)
  }
}

/**
 * Get Formatted Pivot Columns
 * @param reportDataConfig
 * @returns {any[]}
 */
const getFormattedPivotColumns = (reportDataConfig) => {
  const { fields, pivotFields } = reportDataConfig
  const uniqueFields = uniqBy(fields || [], (column) => column.name)
  const secondaryPivotField = pivotFields && pivotFields.length && pivotFields[0]

  return uniqueFields.map((field, index) => {
    if (field.name.indexOf('current_qtr_date') > -1 || field.name === (secondaryPivotField && secondaryPivotField.name)) {
      return getFormattedPivotColumn(reportDataConfig, field, index)
    } else if ((field._entityType.groupedFields || []).indexOf(field._id) > -1) {
      return getFormattedGroupColumn(field)
    } else {
      return getFormattedColumn(reportDataConfig, field, 'pivot', { aggFunc: 'first' })
    }
  })
}

/**
 * Get formatted stock columns
 * @param reportDataConfig
 */
const getFormattedGroupColumns = (reportDataConfig) => {
  const { entityType, fields, isPivot } = reportDataConfig
  const uniqueFields = uniqBy(fields || [], (column) => column.name)
  const primaryEntityType = (entityType.length > 0 && entityType[0]) || {}
  let columnIndex = 0

  return uniqueFields.map((field) => {
    const isGroupField = getIsGroupField(field)

    if (isGroupField && (get(field, '_entityType._id') === primaryEntityType._id || isPivot)) {
      return getFormattedGroupColumn(field)
    }

    const formattedColumn = getFormattedColumn(reportDataConfig, field, columnIndex, { aggFunc: 'first' })

    columnIndex = columnIndex + 1
    return formattedColumn
  })
}

/**
 * Get Formatted Columns
 * @param reportDataConfig
 * @returns {*}
 */
export const getFormattedColumns = (reportDataConfig) => {
  const { entityType, fields, isPivot } = reportDataConfig

  if (isPivot) {
    return getFormattedPivotColumns(reportDataConfig)
  } else if ((entityType.length > 1) || getIsReportType(entityType, stockEntityType)) {
    return getFormattedGroupColumns(reportDataConfig)
  } else {
    return (fields || []).map((field, index) => {
      return getFormattedColumn(reportDataConfig, field, index)
    })
  }
}

/**
 * Get auto group column definition
 * @param fields
 * @param entityTypes
 * @return {*}
 */
export const getAutoGroupColumnDef = (fields, entityTypes) => {
  if (!fields || !fields.length || !entityTypes || !entityTypes.length) {
    return
  }

  const primaryEntityType = first(entityTypes)
  const secondaryEntityType = last(entityTypes)

  const primaryGroupField = fields.find((field) => {
    const { _id, groupedFields } = field._entityType
    return _id === primaryEntityType._id && (groupedFields || []).indexOf(field._id) > -1
  }) || {}

  const secondaryGroupField = fields.find((field) => {
    const { _id, groupedFields } = field._entityType
    return _id === secondaryEntityType._id && (groupedFields || []).indexOf(field._id) > -1
  })

  const groupField = (secondaryGroupField || primaryGroupField)
  const { width, type } = groupField

  return {
    field: primaryGroupField.name,
    width: getDefaultColumnWidth(type),
    minWidth: width ? (width * 6) : getDefaultColumnWidth(type),
    suppressMenu: true,
    sortable: false,
    cellRendererParams: {
      suppressCount: true
    },
    pinned: 'left',
    suppressMovable: true,
    lockPosition: true
  }
}

/**
 * Format column header label for pivot columns
 * @param colDef
 * @returns {string|*}
 * @private
 */
const _formatPivotHeaderName = (colDef) => {
  let headerName = colDef.headerName

  if (!headerName) {
    return 'Unknown'
  }

  if (headerName === 'false') {
    return 'No'
  } else if (headerName === 'true') {
    return 'Yes'
  }

  const headerAsDate = moment.utc(headerName, moment.ISO_8601)

  if (headerAsDate.isValid()) {
    const quarter = headerAsDate.quarter()
    const year = headerAsDate.format('YYYY')
    const currentYear = moment().format('YYYY')
    const currentQuarter = moment().quarter()

    headerName = `Q${quarter} ${year}` === `Q${currentQuarter} ${currentYear}` ? 'Current' : `Q${quarter} ${year}`
  }

  return headerName
}

/**
 * Process Pivot Group Headers
 * @param colDef
 */
export const processPivotGroupHeaders = (colDef) => {
  // disable re-ordering of columns outside of their respective pivot group
  colDef.marryChildren = true
  colDef.headerName = _formatPivotHeaderName(colDef)
}

/**
 * Process Pivot Headers
 * @param colDef
 */
export const processPivotHeaders = (colDef) => {
  if (colDef && colDef.sortQuarter && colDef.sortQuarter !== 'all') {
    const pivotKey = colDef.pivotKeys && colDef.pivotKeys[0]
    const pivotMomentDate = moment.utc(pivotKey, moment.ISO_8601)

    if (!pivotMomentDate.isValid()) {
      return colDef
    }

    // only keep sort for columns that match the quarter
    const sortQuarterMomentDate = moment.utc(colDef.sortQuarter, 'YYYYMMDD')
    if (!pivotMomentDate.isSame(sortQuarterMomentDate, 'quarter')) {
      colDef.sort = null
    }
  }

  return colDef
}
