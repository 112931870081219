import { config } from '../../../config'
import { DATA_IDS as ACTIVITY_DATA_IDS } from '../../../components/activity/form/activityForm.definition'
import { DATA_IDS as PEER_CONFIG_MODAL_DATA_IDS } from '../../../components/peerConfigModal/peerConfigModal.definition'

const dataIdPrefix = 'InstitutionPage'
const dealCreate = 'DealCreate'
const entitySearch = 'EntitySearch'
const contactForm = 'ContactForm'

export const DATA_IDS = {
  // BANNER
  AI_SCORE: `${dataIdPrefix}AiScore`,
  QR_SCORE: `${dataIdPrefix}QrScore`,
  REQUEST_UPDATE_BUTTON: `${dataIdPrefix}BannerEntityUpdate`,
  UTILITY: `${dataIdPrefix}BannerUtility`,
  UTILITY_LOG_ACTIVITY: `${dataIdPrefix}BannerUtilityActivityCreate`,
  UTILITY_TARGET: `${dataIdPrefix}BannerUtilityTarget`,
  UTILITY_CREATE_DEAL: `${dataIdPrefix}BannerUtilityDealCreate`,
  UTILITY_DOWNLOAD_TEARSHEET: `${dataIdPrefix}BannerUtilityTearsheetGenerate`,
  UTILITY_ADD_TO_BRIEFING_BOOK: `${dataIdPrefix}BannerUtilityBriefingBookSave`,
  // BADGES
  TARGET_BADGE: `${dataIdPrefix}BannerTargetBadge`,
  DEAL_BADGE: `${dataIdPrefix}BannerDealBadge`,
  // OVERVIEW
  ATTRIBUTE: `${dataIdPrefix}AttributesCard`,
  HIGHLIGHT: `${dataIdPrefix}HighlightsCard`,
  OWNERSHIP: `${dataIdPrefix}OwnershipCard`,
  OWNERSHIP_CHART_FILTER_INPUT: `${dataIdPrefix}OwnershipChartFilterInput`,
  OWNERSHIP_CHART_FILTER_MENU: `${dataIdPrefix}OwnershipChartFilterMenu`,
  OWNERSHIP_PEERS_INPUT: `${dataIdPrefix}OwnershipChartPeersInput`,
  OWNERSHIP_PEERS_MENU: `${dataIdPrefix}OwnershipChartPeersMenu`,
  OWNERSHIP_PEERS_ITEM: `${dataIdPrefix}OwnershipChartPeersItem`,
  TOP_BUY: `${dataIdPrefix}TopBuysCard`,
  TOP_SELL: `${dataIdPrefix}TopSellsCard`,
  DEAL: `${dataIdPrefix}DealsCard`,
  PROFILE: `${dataIdPrefix}ProfileCard`,
  COMMENT: `${dataIdPrefix}CommentCard`,
  COMMENTS_EDIT: {
    MODAL: 'EntityCommentEditModal',
    EDITOR: 'EntityCommentEditEditor',
    SAVE: 'EntityCommentEditSave',
    CANCEL: 'EntityCommentEditCancel'
  },
  COMMENT_EDIT_MODAL: `${dataIdPrefix}CommentsEditModal`,
  COMMENT_EDIT_EDITOR: `${dataIdPrefix}CommentsEditModalEditor`,
  COMMENT_EDIT_SAVE: `${dataIdPrefix}CommentsEditModalSave`,
  COMMENT_EDIT_CANCEL: `${dataIdPrefix}CommentsEditModalClose`,
  TAG: `${dataIdPrefix}Tags`,
  TAG_SEARCH_INPUT: `${dataIdPrefix}TagsTagSearchInput`,
  // ACTIVITY
  ACTIVITY_TAB: `${dataIdPrefix}ActivityTab`,
  ACTIVITY_GRID: `${dataIdPrefix}ActivityGrid`,
  ACTIVITY_CATEGORY_FILTER_INPUT: `${dataIdPrefix}ActivityCategoryFilterInput`,
  ACTIVITY_CATEGORY_FILTER_MENU: `${dataIdPrefix}ActivityCategoryFilterMenu`,
  ACTIVITY_SEARCH: `${dataIdPrefix}ActivitySearch`,
  ACTIVITY_EXPORT: `${dataIdPrefix}ActivityExport`,
  ACTIVITY_CREATE: `${dataIdPrefix}ActivityCreate`,
  // FUND
  FUND_TAB: `${dataIdPrefix}FundTab`,
  FUND_GRID: `${dataIdPrefix}FundGrid`,
  FUND_SEARCH: `${dataIdPrefix}FundSearch`,
  FUND_FILTER_ALL: `${dataIdPrefix}FundFilterAll`,
  FUND_FILTER_HOLDERS: `${dataIdPrefix}FundFilterHolders`,
  FUND_FILTER_PEER_HOLDERS: `${dataIdPrefix}FundFilterPeerHolders`,
  FUND_EXPORT: `${dataIdPrefix}FundExport`,
  // CONTACT
  CONTACT_TAB: `${dataIdPrefix}ContactTab`,
  CONTACT_FILTER_ALL: `${dataIdPrefix}ContactFilterAll`,
  CONTACT_FILTER_MY_CONTACTS: `${dataIdPrefix}ContactFilterMyContacts`,
  CONTACT_FILTER_HOLDERS: `${dataIdPrefix}ContactFilterHolders`,
  CONTACT_FILTER_PEER_HOLDERS: `${dataIdPrefix}ContactFilterPeerHolders`,
  CONTACT_FILTER_JOB_FUNCTION_INPUT: `${dataIdPrefix}ContactFilterJobFunctionInput`,
  CONTACT_FILTER_JOB_FUNCTION_MENU: `${dataIdPrefix}ContactFilterJobFunctionMenu`,
  CONTACT_FILTER_RESET: `${dataIdPrefix}ContactFilterReset`,
  CONTACT_SEARCH: `${dataIdPrefix}ContactSearch`,
  CONTACT_EXPORT: `${dataIdPrefix}ContactExport`,
  CONTACT_GRID: `${dataIdPrefix}ContactGrid`,
  CONTACT_GRID_BULK_SELECT: `${dataIdPrefix}ContactGridBulkSelect`,
  CONTACT_GRID_ADD_BRIEFING_BOOK: `${dataIdPrefix}ContactGridAddBriefingBook`,
  CONTACT_GRID_CONTACT_NAME: `${dataIdPrefix}ContactGridContactName`,
  CONTACT_GRID_CONTACT_EMAIL: `${dataIdPrefix}ContactGridContactEmail`,
  // CURRENT HOLDINGS
  CURRENT_HOLDING_TAB: `${dataIdPrefix}CurrentHoldingsTab`,
  CURRENT_HOLDING_GRID: `${dataIdPrefix}CurrentHoldingsGrid`,
  CURRENT_REGION_FILTER_INPUT: `${dataIdPrefix}CurrentHoldingsRegionFilterInput`,
  CURRENT_REGION_FILTER_MENU: `${dataIdPrefix}CurrentHoldingsRegionFilterMenu`,
  CURRENT_SECTOR_FILTER_INPUT: `${dataIdPrefix}CurrentHoldingsSectorFilterInput`,
  CURRENT_SECTOR_FILTER_MENU: `${dataIdPrefix}CurrentHoldingsSectorFilterMenu`,
  CURRENT_MARKET_CAP_FILTER_INPUT: `${dataIdPrefix}CurrentHoldingsMarketCapFilterInput`,
  CURRENT_MARKET_CAP_FILTER_MENU: `${dataIdPrefix}CurrentHoldingsMarketCapFilterMenu`,
  CURRENT_FILTER_RESET: `${dataIdPrefix}CurrentHoldingsResetFilter`,
  CURRENT_SEARCH: `${dataIdPrefix}CurrentHoldingsSearch`,
  CURRENT_EXPORT: `${dataIdPrefix}CurrentHoldingsExport`,
  // HISTORICAL HOLDINGS
  HISTORICAL_HOLDING_TAB: `${dataIdPrefix}HistoricalHoldingsTab`,
  HISTORICAL_HOLDING_GRID: `${dataIdPrefix}HistoricalHoldingsGrid`,
  HISTORICAL_QUARTER_FILTER_INPUT: `${dataIdPrefix}HistoricalHoldingsQuarterFilterInput`,
  HISTORICAL_QUARTER_FILTER_MENU: `${dataIdPrefix}HistoricalHoldingsQuarterFilterMenu`,
  HISTORICAL_SEARCH: `${dataIdPrefix}HistoricalHoldingsSearch`,
  HISTORICAL_EXPORT: `${dataIdPrefix}HistoricalHoldingsExport`,
  // PEER ANALYSIS
  PEER_ANALYSIS_TAB: `${dataIdPrefix}PeerAnalysisTab`,
  PEER_ANALYSIS_GRID: `${dataIdPrefix}PeerAnalysisGrid`,
  PEER_ANALYSIS_MODIFY_PEERS: `${dataIdPrefix}PeerAnalysisModifyPeers`,
  PEER_ANALYSIS_METRIC_FILTER_INPUT: `${dataIdPrefix}PeerAnalysisMetricFilterInput`,
  PEER_ANALYSIS_METRIC_FILTER_MENU: `${dataIdPrefix}PeerAnalysisMetricFilterMenu`,
  PEER_ANALYSIS_EXPORT: `${dataIdPrefix}PeerAnalysisExport`,

  // DEAL CREATE/EDIT MODAL
  DEAL_MODAL: {
    MODAL: `${dealCreate}`,
    SAVE: `${dealCreate}Save`,
    CANCEL: `${dealCreate}Cancel`,
    OBJECTIVE: `${dealCreate}Objective`,
    OBJECTIVE_OPTIONS: {
      NEW: 'ObjectiveNew',
      INCREASE: 'ObjectiveIncrease',
      MAINTAIN: 'ObjectiveMaintain',
      PREVIOUS: 'ObjectivePrevious'
    },
    TITLE: `${dealCreate}Title`,
    STAGE: {
      MENU: `${dealCreate}StageMenu`,
      INPUT: `${dealCreate}StageInput`
    },
    CREATE_DATE: `${dealCreate}Date`,
    CLOSE_DATE: `${dealCreate}CloseDate`,
    NOTES: `${dealCreate}Notes`,
    POTENTIAL_INVESTORS: {
      CONTAINER: `${entitySearch}`,
      CONTROLS: {
        INSTITUTION: `${entitySearch}ControlPanelInstitution`,
        FUND: `${entitySearch}ControlPanelFund`,
        CONTACT: `${entitySearch}ControlPanelContact`
      },
      SEARCH: {
        INPUT: `${entitySearch}ComboBoxInput`,
        MENU: `${entitySearch}ComboBoxMenu`,
        PROFILE: `${entitySearch}ComboBoxRequestProfile`,
        CREATE: `${entitySearch}ComboBoxCreateContact`
      },
      REQUEST_PROFILE: {
        MODAL: `${entitySearch}RequestProfile`,
        SAVE: `${entitySearch}RequestProfileSave`,
        CANCEL: `${entitySearch}RequestProfileCancel`,
        MESSAGE: `${entitySearch}RequestProfileMessage`
      },
      CREATE_CONTACT: {
        MODAL: `${contactForm}Modal`,
        SAVE: `${contactForm}Save`,
        CANCEL: `${contactForm}Cancel`
      },
      LIST: `${entitySearch}ComboBoxList`
    },
    PURCHASING_POWER: {
      INPUT: `${dealCreate}PurchasingPower`,
      CHECKBOX: `${dealCreate}UsePurchasingPower`
    }
  },
  // REQUEST UPDATE MODAL
  REQUEST_UPDATE: {
    MODAL: 'EntityUpdateModal',
    CANCEL: 'EntityUpdateCancel',
    SAVE: 'EntityUpdateSave',
    NOTE: 'EntityUpdateNote'
  }
}

const page = '.institution-page'
const spinner = '.spinner_mask'
const tag = `div[data-id='${DATA_IDS.TAG}']`
const contactTab = `div[data-id='${DATA_IDS.CONTACT_TAB}']`
const contactGrid = `div[data-id='${DATA_IDS.CONTACT_GRID}']`
const activityTab = `div[data-id='${DATA_IDS.ACTIVITY_TAB}']`
const activityGrid = `div[data-id='${DATA_IDS.ACTIVITY_GRID}']`
const fundTab = `div[data-id='${DATA_IDS.FUND_TAB}']`
const fundGrid = `div[data-id='${DATA_IDS.FUND_GRID}']`
const currentHoldingTab = `div[data-id='${DATA_IDS.CURRENT_HOLDING_TAB}']`
const currentHoldingGrid = `div[data-id='${DATA_IDS.CURRENT_HOLDING_GRID}']`
const historicalHoldingTab = `div[data-id='${DATA_IDS.HISTORICAL_HOLDING_TAB}']`
const historicalHoldingGrid = `div[data-id='${DATA_IDS.HISTORICAL_HOLDING_GRID}']`
const peerAnalysisTab = `div[data-id='${DATA_IDS.PEER_ANALYSIS_TAB}']`
const peerAnalysisGrid = `div[data-id='${DATA_IDS.PEER_ANALYSIS_GRID}']`
const commentsModal = `div[data-id='${DATA_IDS.COMMENTS_EDIT.MODAL}']`
const dealEditModal = `div[data-id='${DATA_IDS.DEAL_MODAL.MODAL}']`
const peerConfigModal = '.peer-config-modal'
const requestUpdateModal = '.modal'
const toastMessage = '.toast-item_message'
const messageModal = '.message'
const dialogModal = '.MuiDialog-container' // TODO: MUI classes should not be used

export const Url = config.baseUrl

export const Locator = {
  Page: `${page}`,
  Splash: {
    Button: '.splash-screen_loading'
  },
  Spinner: `${page} ${spinner}`,
  Banner: {
    AiScore: `${page} div[data-id='${DATA_IDS.AI_SCORE}']`,
    QrScore: `${page} div[data-id='${DATA_IDS.QR_SCORE}']`,
    RequestUpdateButton: `${page} button[data-id='${DATA_IDS.REQUEST_UPDATE_BUTTON}']`,
    Utility: {
      Button: `${page} button[data-id='${DATA_IDS.UTILITY}']`,
      Menu: {
        LogActivity: `div[data-id='${DATA_IDS.UTILITY_LOG_ACTIVITY}']`,
        CreateDeal: `div[data-id='${DATA_IDS.UTILITY_CREATE_DEAL}']`,
        Target: `div[data-id='${DATA_IDS.UTILITY_TARGET}']`
      }
    }
  },
  Overview: {
    Badge: {
      Deal: `${page} div[data-id='${DATA_IDS.DEAL_BADGE}']`,
      Target: `${page} div[data-id='${DATA_IDS.TARGET_BADGE}']`
    },
    Attribute: {
      Card: `${page} article[data-id='${DATA_IDS.ATTRIBUTE}']`,
      Title: `${page} article[data-id='${DATA_IDS.ATTRIBUTE}'] .card_header`
    },
    Highlight: {
      Card: `${page} article[data-id='${DATA_IDS.HIGHLIGHT}']`,
      Title: `${page} article[data-id='${DATA_IDS.HIGHLIGHT}'] .card_header`
    },
    Ownership: {
      Card: `${page} article[data-id='${DATA_IDS.OWNERSHIP}']`,
      Title: `${page} article[data-id='${DATA_IDS.OWNERSHIP}'] .card_header`,
      Chart: `${page} article[data-id='${DATA_IDS.OWNERSHIP}'] .entity-ownership_chart`,
      Input: `${page} div[data-id='${DATA_IDS.OWNERSHIP_CHART_FILTER_INPUT}']`,
      Menu: `${page} div[data-id='${DATA_IDS.OWNERSHIP_CHART_FILTER_MENU}']`,
      Peer: {
        Input: `${page} div[data-id='${DATA_IDS.OWNERSHIP_PEERS_INPUT}']`,
        InputValue: `${page} div[data-id='${DATA_IDS.OWNERSHIP_PEERS_INPUT}'] input`,
        Menu: `${page} div[data-id='${DATA_IDS.OWNERSHIP_PEERS_MENU}'] .entity-ownership_peer-select_item`,
        Item: `${page} div[data-id='${DATA_IDS.OWNERSHIP_PEERS_ITEM}'] .entity-ownership_peer-item_label`,
        Delete: `${page} div[data-id='${DATA_IDS.OWNERSHIP_PEERS_ITEM}'] .entity-ownership_peer-item_delete`
      }
    },
    TopBuy: {
      Card: `${page} article[data-id='${DATA_IDS.TOP_BUY}']`,
      Title: `${page} article[data-id='${DATA_IDS.TOP_BUY}'] .card_header`,
      Value: `${page} article[data-id='${DATA_IDS.TOP_BUY}'] .card_list`
    },
    TopSell: {
      Card: `${page} article[data-id='${DATA_IDS.TOP_SELL}']`,
      Title: `${page} article[data-id='${DATA_IDS.TOP_SELL}'] .card_header`,
      Value: `${page} article[data-id='${DATA_IDS.TOP_SELL}'] .card_list`
    },
    Deal: {
      Card: `${page} article[data-id='${DATA_IDS.DEAL}']`,
      Title: `${page} article[data-id='${DATA_IDS.DEAL}'] .card_header`,
      Name: `${page} article[data-id='${DATA_IDS.DEAL}'] .card_children-link`,
      Input: `${page} article[data-id='${DATA_IDS.DEAL}'] .card_actions .select-component`,
      Menu: `${page} article[data-id='${DATA_IDS.DEAL}'] .card_actions .select_menu`
    },
    Comment: {
      Card: `${page} article[data-id='${DATA_IDS.COMMENT}']`,
      Title: `${page} article[data-id='${DATA_IDS.COMMENT}'] .card_header`,
      Edit: `${page} article[data-id='${DATA_IDS.COMMENT}'] .card_actions .button`
    },
    Profile: {
      Card: `${page} article[data-id='${DATA_IDS.PROFILE}']`,
      Title: `${page} article[data-id='${DATA_IDS.PROFILE}'] .card_header .label`
    },
    Tag: {
      Body: `${page} ${tag}`,
      Input: `${page} ${tag} div[data-id='${DATA_IDS.TAG_SEARCH_INPUT}']`,
      InputValue: `${page} ${tag} div[data-id='${DATA_IDS.TAG_SEARCH_INPUT}'] input`,
      Title: `${page} ${tag} .tag-input_header`,
      Item: `${page} ${tag} .tags-item_label`,
      ItemDelete: `${page} ${tag} .tags-item_delete`,
      Add: `${page} ${tag} .tag-input_add`
    }
  },
  Contact: {
    Tab: `${page} ${contactTab}`,
    Title: `${page} ${contactTab} .section-tab_nav-item`,
    Grid: {
      Body: `${page} ${contactGrid}`,
      Spinner: `${page} ${contactGrid} ${spinner}`,
      Toolbar: {
        Filter: {
          All: `${page} ${contactGrid} button[data-id='${DATA_IDS.CONTACT_FILTER_ALL}']`,
          MyContacts: `${page} ${contactGrid} button[data-id='${DATA_IDS.CONTACT_FILTER_MY_CONTACTS}']`,
          Holders: `${page} ${contactGrid} button[data-id='${DATA_IDS.CONTACT_FILTER_HOLDERS}']`,
          PeerHolders: `${page} ${contactGrid} button[data-id='${DATA_IDS.CONTACT_FILTER_PEER_HOLDERS}']`
        },
        JobFunction: {
          Input: `${page} ${contactGrid} div[data-id='${DATA_IDS.CONTACT_FILTER_JOB_FUNCTION_INPUT}']`,
          Menu: `${page} ${contactGrid} div[data-id='${DATA_IDS.CONTACT_FILTER_JOB_FUNCTION_MENU}']`
        },
        FilterReset: `${page} ${contactGrid} button[data-id='${DATA_IDS.CONTACT_FILTER_RESET}']`,
        Search: {
          Input: `${page} ${contactGrid} div[data-id='${DATA_IDS.CONTACT_SEARCH}'] .section-search_input`,
          Cancel: `${page} ${contactGrid} div[data-id='${DATA_IDS.CONTACT_SEARCH}'] .section-search_clear`
        },
        Export: `${page} ${contactGrid} button[data-id='${DATA_IDS.CONTACT_EXPORT}']`
      },
      Header: {
        Sort: {
          FullName: {
            SortAsc: `${page} ${contactGrid} .ag-header-row div[col-id='fullName'] .ag-header-cell-sorted-asc`,
            SortDesc: `${page} ${contactGrid} .ag-header-row div[col-id='fullName'] .ag-header-cell-sorted-desc`,
            SortNone: `${page} ${contactGrid} .ag-header-row div[col-id='fullName'] .ag-header-cell-sorted-none`
          }
        }
      },
      Pagination: `${page} ${contactGrid} .pagination`
    }
  },
  Activity: {
    Tab: `${page} ${activityTab}`,
    Title: `${page} ${activityTab} .section-tab_nav-item`,
    Grid: {
      Body: `${page} ${activityGrid}`,
      Spinner: `${page} ${activityGrid} ${spinner}`,
      Toolbar: {
        Category: {
          Input: `${page} ${activityGrid} div[data-id='${DATA_IDS.ACTIVITY_CATEGORY_FILTER_INPUT}']`,
          Menu: `${page} ${activityGrid} div[data-id='${DATA_IDS.ACTIVITY_CATEGORY_FILTER_MENU}']`
        },
        Search: {
          Input: `${page} ${activityGrid} div[data-id='${DATA_IDS.ACTIVITY_SEARCH}'] .section-search_input`,
          Cancel: `${page} ${activityGrid} div[data-id='${DATA_IDS.ACTIVITY_SEARCH}'] .section-search_clear`
        },
        Export: `${page} ${activityGrid} button[data-id='${DATA_IDS.ACTIVITY_EXPORT}']`,
        Create: `${page} ${activityGrid} button[data-id='${DATA_IDS.ACTIVITY_CREATE}']`
      },
      Header: {
        StartDate: `${page} ${activityGrid} .ag-header-row div[col-id='start']`,
        Sort: {
          StartDate: {
            SortAsc: `${page} ${activityGrid} .ag-header-row div[col-id='start'] .ag-header-cell-sorted-asc`,
            SortDesc: `${page} ${activityGrid} .ag-header-row div[col-id='start'] .ag-header-cell-sorted-desc`
          }
        },
        Tags: `${page} ${activityGrid} .ag-header-row div[col-id='tag']`
      },
      Pagination: `${page} ${activityGrid} .pagination`
    }
  },
  Fund: {
    Tab: `${page} ${fundTab}`,
    Title: `${page} ${fundTab} .section-tab_nav-item`,
    Grid: {
      Body: `${page} ${fundGrid}`,
      Spinner: `${page} ${fundGrid} ${spinner}`,
      NoContentMessage: `${page} ${fundGrid} .no-content-message`,
      Toolbar: {
        Search: {
          Input: `${page} ${fundGrid} div[data-id='${DATA_IDS.FUND_SEARCH}'] .section-search_input`,
          Cancel: `${page} ${fundGrid} div[data-id='${DATA_IDS.FUND_SEARCH}'] .section-search_clear`
        },
        Filter: {
          All: `${page} ${fundGrid} button[data-id='${DATA_IDS.FUND_FILTER_ALL}']`,
          Holder: `${page} ${fundGrid} button[data-id='${DATA_IDS.FUND_FILTER_HOLDERS}']`,
          PeerHolder: `${page} ${fundGrid} button[data-id='${DATA_IDS.FUND_FILTER_PEER_HOLDERS}']`
        },
        Export: `${page} ${fundGrid} button[data-id='${DATA_IDS.FUND_EXPORT}']`
      },
      Header: {
        Pos: `${page} ${fundGrid} .ag-header-row div[col-id='current']`,
        Sort: {
          Pos: {
            SortAsc: `${page} ${fundGrid} .ag-header-row div[col-id='current'] .ag-header-cell-sorted-asc`,
            SortDesc: `${page} ${fundGrid} .ag-header-row div[col-id='current'] .ag-header-cell-sorted-desc`
          }
        }
      },
      Pagination: `${page} ${fundGrid} .pagination`
    }
  },
  CurrentHolding: {
    Tab: `${page} ${currentHoldingTab}`,
    Title: `${page} ${currentHoldingTab} .section-tab_nav-item`,
    Grid: {
      Body: `${page} ${currentHoldingGrid}`,
      Spinner: `${page} ${currentHoldingGrid} ${spinner}`,
      Toolbar: {
        Region: {
          Input: `${page} ${currentHoldingGrid} div[data-id='${DATA_IDS.CURRENT_REGION_FILTER_INPUT}']`,
          Menu: `${page} ${currentHoldingGrid} div[data-id='${DATA_IDS.CURRENT_REGION_FILTER_MENU}']`
        },
        Sector: {
          Input: `${page} ${currentHoldingGrid} div[data-id='${DATA_IDS.CURRENT_SECTOR_FILTER_INPUT}']`,
          Menu: `${page} ${currentHoldingGrid} div[data-id='${DATA_IDS.CURRENT_SECTOR_FILTER_MENU}']`
        },
        MarketCap: {
          Input: `${page} ${currentHoldingGrid} div[data-id='${DATA_IDS.CURRENT_MARKET_CAP_FILTER_INPUT}']`,
          Menu: `${page} ${currentHoldingGrid} div[data-id='${DATA_IDS.CURRENT_MARKET_CAP_FILTER_MENU}']`
        },
        Search: {
          Input: `${page} ${currentHoldingGrid} div[data-id='${DATA_IDS.CURRENT_SEARCH}'] .section-search_input`,
          Cancel: `${page} ${currentHoldingGrid} div[data-id='${DATA_IDS.CURRENT_SEARCH}'] .section-search_clear`
        },
        FilterReset: `${page} ${currentHoldingGrid} button[data-id='${DATA_IDS.CURRENT_FILTER_RESET}']`,
        Export: `${page} ${currentHoldingGrid} button[data-id='${DATA_IDS.CURRENT_EXPORT}']`
      },
      Header: {
        Pos: `${page} ${currentHoldingGrid} .ag-header-row div[col-id='current']`,
        Sort: {
          Pos: {
            SortAsc: `${page} ${currentHoldingGrid} .ag-header-row div[col-id='current'] .ag-header-cell-sorted-asc`,
            SortDesc: `${page} ${currentHoldingGrid} .ag-header-row div[col-id='current'] .ag-header-cell-sorted-desc`
          }
        }
      },
      Pagination: `${page} ${currentHoldingGrid} .pagination`
    }
  },
  HistoricalHolding: {
    Tab: `${page} ${historicalHoldingTab}`,
    Title: `${page} ${historicalHoldingTab} .section-tab_nav-item`,
    Grid: {
      Body: `${page} ${historicalHoldingGrid}`,
      Spinner: `${page} ${historicalHoldingGrid} ${spinner}`,
      Toolbar: {
        Quarter: {
          Input: `${page} ${historicalHoldingGrid} div[data-id='${DATA_IDS.HISTORICAL_QUARTER_FILTER_INPUT}']`,
          Menu: `${page} ${historicalHoldingGrid} div[data-id='${DATA_IDS.HISTORICAL_QUARTER_FILTER_MENU}']`
        },
        Search: {
          Input: `${page} ${historicalHoldingGrid} div[data-id='${DATA_IDS.HISTORICAL_SEARCH}'] .section-search_input`,
          Cancel: `${page} ${historicalHoldingGrid} div[data-id='${DATA_IDS.HISTORICAL_SEARCH}'] .section-search_clear`
        },
        Export: `${page} ${historicalHoldingGrid} button[data-id='${DATA_IDS.HISTORICAL_EXPORT}']`
      },
      Header: {
        Sort: {
          Name: {
            SortNone: `${page} ${historicalHoldingGrid} .ag-header-row div[col-id='securityName'] .ag-header-cell-sorted-none`,
            SortAsc: `${page} ${historicalHoldingGrid} .ag-header-row div[col-id='securityName'] .ag-header-cell-sorted-asc`,
            SortDesc: `${page} ${historicalHoldingGrid} .ag-header-row div[col-id='securityName'] .ag-header-cell-sorted-desc`
          }
        }
      },
      Pagination: `${page} ${historicalHoldingGrid} .pagination`
    }
  },
  PeerAnalysis: {
    Tab: `${page} ${peerAnalysisTab}`,
    Title: `${page} ${peerAnalysisTab} .section-tab_nav-item`,
    Grid: {
      Body: `${page} ${peerAnalysisGrid}`,
      Spinner: `${page} ${peerAnalysisGrid} ${spinner}`,
      Toolbar: {
        Metric: {
          Input: `${page} ${peerAnalysisGrid} div[data-id='${DATA_IDS.PEER_ANALYSIS_METRIC_FILTER_INPUT}']`,
          Menu: `${page} ${peerAnalysisGrid} div[data-id='${DATA_IDS.PEER_ANALYSIS_METRIC_FILTER_MENU}']`,
          Selected: `${page} ${peerAnalysisGrid} div[data-id='${DATA_IDS.PEER_ANALYSIS_METRIC_FILTER_INPUT}'] .select__single-value`,
          MarketValue: `${page} ${peerAnalysisGrid} div[data-id='${DATA_IDS.PEER_ANALYSIS_METRIC_FILTER_MENU}'] .select__option:nth-child(3)`
        },
        Peers: `${page} ${peerAnalysisGrid} button[data-id='${DATA_IDS.PEER_ANALYSIS_MODIFY_PEERS}']`,
        Export: `${page} ${peerAnalysisGrid} button[data-id='${DATA_IDS.PEER_ANALYSIS_EXPORT}']`
      },
      Pagination: `${page} ${peerAnalysisGrid} .pagination`
    }
  },
  ActivityModal: {
    Modal: `.MuiDialog-root`,
    SaveAndCloseButton: `button[data-id='${ACTIVITY_DATA_IDS.ACTIVITY_MODAL_SAVE_AND_CLOSE_BUTTON}']`,
    Cancel: `button[data-id='${ACTIVITY_DATA_IDS.ACTIVITY_MODAL_CANCEL_BUTTON}']`,
    Title: `.modal_title`,
    ToastMessage: `${toastMessage}`
  },
  CommentModal: {
    Modal: `${commentsModal}`,
    Title: `${commentsModal} .modal_title`,
    Editor: `${commentsModal} div[data-id='${DATA_IDS.COMMENTS_EDIT.EDITOR}']`,
    EditorTextArea: `${commentsModal} div[data-id='${DATA_IDS.COMMENTS_EDIT.EDITOR}'] .public-DraftEditor-content`,
    Cancel: `${commentsModal} button[data-id='${DATA_IDS.COMMENTS_EDIT.CANCEL}']`,
    Save: `${commentsModal} button[data-id='${DATA_IDS.COMMENTS_EDIT.SAVE}']`
  },
  RequestUpdateModal: {
    Modal: `${requestUpdateModal}`,
    Cancel: `${requestUpdateModal} button[data-id='${DATA_IDS.REQUEST_UPDATE.CANCEL}`,
    Save: `${requestUpdateModal} button[data-id='${DATA_IDS.REQUEST_UPDATE.SAVE}`,
    Note: `${requestUpdateModal} div[data-id='${DATA_IDS.REQUEST_UPDATE.NOTE}`,
    NoteTextArea: `${requestUpdateModal} div[data-id='${DATA_IDS.REQUEST_UPDATE.NOTE}'] textarea`
  },
  DialogModal: {
    Modal: `${dialogModal}`
  },
  MessageModal: {
    Modal: `${messageModal}`,
    Title: `${messageModal} .message_title`,
    Text: `${messageModal} .message_text`,
    Button: `${messageModal} .message_actions .button`,
    List: `${messageModal} .message_list`
  },
  PeerConfigModal: {
    Modal: `${peerConfigModal}`,
    Title: `${peerConfigModal} .modal_title`,
    Close: `${peerConfigModal} .modal_exit`,
    Search: {
      Input: `${peerConfigModal} div[data-id='${PEER_CONFIG_MODAL_DATA_IDS.PEER_CONFIG_ADD_PEERS_INPUT}']`,
      InputValue: `${peerConfigModal} div[data-id='${PEER_CONFIG_MODAL_DATA_IDS.PEER_CONFIG_ADD_PEERS_INPUT}'] input`,
      Menu: `${peerConfigModal} div[data-id='${PEER_CONFIG_MODAL_DATA_IDS.PEER_CONFIG_ADD_PEERS_MENU}']`
    },
    Cancel: `${peerConfigModal} button[data-id='${PEER_CONFIG_MODAL_DATA_IDS.PEER_CONFIG_CANCEL_BUTTON}']`,
    Update: `${peerConfigModal} button[data-id='${PEER_CONFIG_MODAL_DATA_IDS.PEER_CONFIG_UPDATE_BUTTON}']`
  },
  DealModal: {
    Modal: `${dealEditModal}`,
    Save: `${dealEditModal} button[data-id='${DATA_IDS.DEAL_MODAL.SAVE}']`,
    Cancel: `${dealEditModal} button[data-id='${DATA_IDS.DEAL_MODAL.CANCEL}']`,
    Objective: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.OBJECTIVE}']`,
    Objectives: {
      New: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.OBJECTIVE_OPTIONS.NEW}']`,
      Increase: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.OBJECTIVE_OPTIONS.INCREASE}']`,
      Maintain: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.OBJECTIVE_OPTIONS.MAINTAIN}']`,
      Previous: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.OBJECTIVE_OPTIONS.PREVIOUS}']`
    },
    Title: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.TITLE}']`,
    Stage: {
      Input: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.STAGE.INPUT}']`,
      Menu: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.STAGE.MENU}']`
    },
    Date: {
      Label: '.date-picker_label',
      Create: {
        Input: `${dealEditModal} input[data-id='${DATA_IDS.DEAL_MODAL.CREATE_DATE}']`,
        Modal: '.date-picker-modal'
      },
      Close: {
        Input: `${dealEditModal} input[data-id='${DATA_IDS.DEAL_MODAL.CLOSE_DATE}']`,
        Modal: '.date-picker-modal'
      }
    },
    Notes: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.NOTES}']`,
    PurchasingPower: {
      Input: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.PURCHASING_POWER.INPUT}'] input`,
      Checkbox: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.PURCHASING_POWER.CHECKBOX}']`
    },
    EntitySearch: {
      Container: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.CONTAINER}']`,
      Controls: {
        Institution: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.CONTAINER}'] button[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.CONTROLS.INSTITUTION}']`,
        Fund: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.CONTAINER}'] button[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.CONTROLS.FUND}']`,
        Contact: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.CONTAINER}'] button[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.CONTROLS.CONTACT}']`
      },
      Search: {
        Input: `${dealEditModal} div[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.SEARCH.INPUT}']`,
        Menu: `div[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.SEARCH.MENU}']`,
        Profile: `button[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.SEARCH.PROFILE}']`,
        Create: `button[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.SEARCH.CREATE}']`
      },
      Investors: {
        List: `${dealEditModal} ul[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.LIST}']`,
        Remove: `${dealEditModal} ul[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.LIST}'] .q4i-close-4pt`
      },
      Profile: {
        Modal: `div[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.REQUEST_PROFILE.MODAL}']`,
        Save: `div[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.REQUEST_PROFILE.MODAL}'] button[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.REQUEST_PROFILE.SAVE}']`,
        Cancel: `div[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.REQUEST_PROFILE.MODAL}'] button[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.REQUEST_PROFILE.CANCEL}']`,
        Message: `div[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.REQUEST_PROFILE.MODAL}'] div[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.REQUEST_PROFILE.MESSAGE}']`,
        MessageTextArea: `textarea[id="message"]`
      },
      Create: {
        Modal: `div[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.CREATE_CONTACT.MODAL}']`,
        Save: `div[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.CREATE_CONTACT.MODAL}'] button[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.CREATE_CONTACT.SAVE}']`,
        Cancel: `div[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.CREATE_CONTACT.MODAL}'] button[data-id='${DATA_IDS.DEAL_MODAL.POTENTIAL_INVESTORS.CREATE_CONTACT.CANCEL}']`
      }
    }
  },
  Footer: {
    ScrollUp: '.footer .scroll-up-button'
  }
}
