import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { preventDefaultRowClick, setColumnDefinition } from '../../../../utils'

import CustomCell from './cell/cell.component'
import { AgGrid, PopoverMenu } from '../../../index'

const columns = [
  {
    field: 'full_name',
    headerName: 'Name',
    minWidth: 320,
    maxWidth: 360,
    pinned: 'left',
    lockPinned: true,
    headerCheckboxSelection: true,
    checkboxSelection: true
  },
  { field: 'title', headerName: 'Title', minWidth: 220, maxWidth: 260 },
  { field: 'location', headerName: 'Location', minWidth: 180, maxWidth: 220 },
  { field: 'investmentFocus', headerName: 'Investment Focus', minWidth: 180, maxWidth: 220, sortable: false },
  {
    type: 'number',
    field: 'currentHolding',
    headerName: 'Position',
    minWidth: 180,
    maxWidth: 220
  },
  { field: 'phone', headerName: 'Phone', minWidth: 180, maxWidth: 180, sortable: false },
  { field: 'email', headerName: 'Email', minWidth: 220, maxWidth: 220, sortable: false },
  {
    type: 'date',
    field: 'lastActivity',
    headerName: 'Last Activity',
    minWidth: 120,
    maxWidth: 120,
    sortable: false
  }]

class ContactTable extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      popoverMenuProps: null
    }
  }

  /**
   * Handle onSortChanged event (multi-sort included)
   * @param grid
   */
  handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel || !sortModel[0]) {
      return
    }

    const sortParams = sortModel[0]
    this.props.handleSortChange({ property: sortParams.colId, direction: sortParams.sort })
  }

  /**
   * Handles redirect to entity page
   */
  handleOnRowClick = (row) => {
    if (!row) {
      return
    }    
    const { handleRowClick } = this.props
    handleRowClick && handleRowClick(row)
  }

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  handleGridReady = (grid) => {
    setTimeout(() => this.agGrid = grid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => this.agGrid && this.agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * On Expand Menu Click
   * Displays expandable menu for contact/institutions/participants
   * @param event
   * @param popoverMenuProps
   */
  onExpandMenuClick = (event, popoverMenuProps) => {
    event.stopPropagation()
    this.setState({ popoverMenuProps })
  }

  /**
   * On Expand Menu Close Click
   * @param event
   */
  onExpandMenuCloseClick = (event) => {
    event.stopPropagation()
    this.setState({ popoverMenuProps: null })
  }

  /**
   * Renders CustomCell Component
   */
  renderCustomCell = (props) => {
    return (
      <CustomCell
        {...props}
        onExpandMenuClick={this.onExpandMenuClick}
      />
    )
  }

  /**
   * Handle bulk actions
   **/
  handleBulkAction = (actionId) => {
    const { handleBulkAction } = this.props

    if (!this.agGrid || !actionId) {
      return
    }

    const selectedRows = this.agGrid.api.getSelectedRows()
    if (!selectedRows || !selectedRows.length) {
      return
    }

    handleBulkAction(actionId, selectedRows)
  }

  render () {
    const { data, dataTotal, query, handleQueryChange, pageSizeId } = this.props

    return (
      <>
        <AgGrid
          className='contact-grid_table'
          domLayout='autoHeight'
          sizeToFit

          // suppress configs
          suppressMovableColumns
          suppressContextMenu
          enableSorting={false}

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            sortable: true,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={setColumnDefinition({ columns })}
          rowData={data}

          // custom components
          frameworkComponents={{ CustomCellRender: this.renderCustomCell }}

          // bulk actions
          bulkActions={[{
            id: 'contacts-add-to-bb',
            icon: 'q4i-book-4pt',
            onSelect: () => this.handleBulkAction('contacts-add-to-bb')
          }]}

          // pagination
          pagination
          paginationProps={{
            pageSizeId,
            forcePage: query.page,
            initialPageSize: query.limit,
            showPageSizeSelection: true,
            total: dataTotal,
            onPageChange: ({ selected: newPage }) => handleQueryChange({ page: newPage }),
            onPageSizeChange: ({ selected: newPageSize }) => handleQueryChange({ limit: newPageSize, page: 1 })
          }}

          // event listeners
          onSortChanged={this.handleSortChange}
          onGridReady={this.handleGridReady}
          onGridSizeChanged={this.handleGridResize}
          onRowClicked={preventDefaultRowClick(
            this.handleOnRowClick,
            ['cell--email', 'expandable-cell']
          )}

          isPinned={data && data.length}
        />

        {/* Popover Menu for Custom Cell */}
        {this.state.popoverMenuProps && (
          <PopoverMenu
            onClick={() => console.log('onPopoverMenuItem Click')}
            onClose={this.onExpandMenuCloseClick}
            scrollable={true}
            {...this.state.popoverMenuProps}
          />
        )}
      </>
    )
  }
}

ContactTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    investmentFocus: PropTypes.array,
    position: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    lastActivity: PropTypes.string
  })),
  dataTotal: PropTypes.number,
  query: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  handleQueryChange: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  handleBulkAction: PropTypes.func.isRequired,
  pageSizeId: PropTypes.string
}

ContactTable.defaultProps = {
  data: [],
  dataTotal: 0,
  query: {}
}

export default ContactTable
