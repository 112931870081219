import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash'

import {
  fetchFund,
  getTarget,
  getDealsByEntityId,
  clearEntityDeals,
  clearTarget,
  statusType, resetTags
} from '../../../../../../actions'

import Fund from './fund.component'
import PropTypes from 'prop-types'

const propTypes = {
  /**
   * Used to fetch fund data
   */
  entityId: PropTypes.string.isRequired,

  /**
   * Used to show fund data
   */
  fund: PropTypes.shape({
    // data object serves as a map to store { key: value } fund data in the following format:
    // [factset_fund_id]: {
    //    ...entityData,
    // }
    data: PropTypes.object
  }),

  /**
   * Used to show target icon and provide data for utility menu
   */
  targetId: PropTypes.string,

  /**
   * Used to provide data for utility menu
   */
  hasDeal: PropTypes.bool,
}


/**
 * FundContainer
 */
function FundContainer (props) {
  const {
    entityId,
    entityType,
    targetId,
    hasDeal,
    fund,
    getFund,
    getTarget,
    getDealsByEntityId,
    clearEntityDeals,
    resetTags,
    clearTarget
  } = props
  const { data, status } = fund.data[entityId] || {}

  useEffect(() => {
    if (!data) {
      entityId && getFund(entityId)
    }
  }, [entityId, data, getFund])

  useEffect(() => {
    if (!data || !data._id || !entityType) {
      return
    }

    getTarget(data._id)
    getDealsByEntityId(data._id)
    return () => {
      clearEntityDeals()
      clearTarget()
      resetTags()
    }
  }, [data, entityType, getTarget, getDealsByEntityId, clearEntityDeals, clearTarget, resetTags])

  return (
    <Fund
      {...props}
      fund={data}
      isLoading={!data || [statusType.IDLE, statusType.IN_PROGRESS].includes(status)}
      targetId={targetId}
      hasDeal={hasDeal}
    />
  )
}

FundContainer.propTypes = propTypes

const mapDispatchToProps = (dispatch) => ({
  getFund: bindActionCreators(fetchFund, dispatch),
  getTarget: bindActionCreators(getTarget, dispatch),
  resetTags: bindActionCreators(resetTags, dispatch),
  clearEntityDeals: bindActionCreators(clearEntityDeals, dispatch),
  clearTarget: bindActionCreators(clearTarget, dispatch),
  getDealsByEntityId: bindActionCreators(getDealsByEntityId, dispatch)
})

const mapStateToProps = (state) => {
  const { targeting, entityDeals } = state

  return {
    fund: state.fund.fundDetail,
    targetId: get(targeting, 'target.data._id') || get(targeting, 'target.data.targetId'),
    hasDeal: get(entityDeals, 'data', []).length > 0
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FundContainer)
