import React, { Component } from 'react'

/**
 * Widget Library Component
 */
class DashboardEditHeader extends Component {

    constructor (props) {
        super(props)

        this.state = {
            currentDashboard: props.dashboard,
            title: props.isNewDashboard ? 'Untitled' : (props.dashboard && props.dashboard.title) || 'N/A'
        }
    }

    componentDidMount () {
        this.focusTitleEdit()
    }

    /**
     * Handle text or checkbox input change
     * @param event
     */
    handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    focusTitleEdit = () => {
        this.refs.dashboardTitle && this.refs.dashboardTitle.focus()
    }

    _isTitleValid = () => {
        return this.state.title.trim().length
    }

    /**
     * Render Dashboard Edit Header
     * @returns {*}
     */
    render () {
        const { theme, onCancel, onSave, deleteAvailable, onDelete, isModifyingState } = this.props
        const { title } = this.state

        const isTitleValid = this._isTitleValid()
        const isDarkTheme = theme === 'dark'
        const buttonClass = [
            `button button--tall`,
            isModifyingState ? 'button--disabled' : ''
        ].join(' ')

        return (
            <div className='dashboard_edit'>
                <div className='dashboard_contain'>
                    <div className='dashboard_actions dashboard_actions--left'>
                        <div className='dashboard_title-edit'>
                            <div className={`field ${isDarkTheme ? 'field--slate' : ''} field--text field--full`}>
                                <input
                                    ref='dashboardTitle'
                                    className='field_input'
                                    name='title'
                                    placeholder='Add Title'
                                    maxLength={32}
                                    value={title}
                                    onFocus={(event) => {
                                        const value = event.target.value
                                        event.target.value = ''
                                        event.target.value = value
                                    }}
                                    onChange={this.handleInputChange}
                                />
                            </div>
                        </div>
                        {deleteAvailable && (
                            <div className='dashboard_action'>
                                <span className='dashboard_divider'/>
                                <button
                                    className={`${buttonClass} button--square button--${isDarkTheme ? 'slate' : 'pale-grey'}`}
                                    onClick={onDelete}>
                                    <i className='q4i-trashbin-4pt'/>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className='dashboard_actions dashboard_actions--right'>
                        <span className='dashboard_action'>
                            <button className={`${buttonClass} button--cancel`} onClick={onCancel}>Cancel</button>
                        </span>
                        <span className='dashboard_action'>
                            <button
                                className={`${buttonClass} button--${isDarkTheme ? 'citrus' : 'rain'} ${isTitleValid ? '' : 'button--disabled'}`}
                                onClick={() => onSave({ title })}>Save</button>
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default DashboardEditHeader