import {
  GET_TAG_REQUEST,
  GET_TAG_SUCCESS,
  GET_TAG_FAILURE,
  CREATE_TAG_REQUEST,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  RESET_TAGS,
  statusType
} from '../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const tags = (state = initial, action) => {
  switch (action.type) {
    case GET_TAG_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_TAG_SUCCESS:
      return {
        ...state,
        type: action.type,
        data: action.payload,
        status: statusType.SUCCESS
      }

    case GET_TAG_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case CREATE_TAG_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case CREATE_TAG_SUCCESS:
      return {
        ...state,
        type: action.type,
        data: [].concat(action.payload, state.data),
        status: statusType.SUCCESS
      }

    case CREATE_TAG_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case DELETE_TAG_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case DELETE_TAG_SUCCESS:
      const data = [].concat(state.data).filter((tag) => tag._id !== (action.payload && action.payload._id))

      return {
        ...state,
        type: action.type,
        data,
        status: statusType.SUCCESS
      }

    case DELETE_TAG_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case RESET_TAGS:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default tags
