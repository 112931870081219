import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { fetchBrokerTable, fetchConsensusTable, setBrokerQuarterDates, FETCHING } from '../../../actions/estimates'
import { downloadExportData } from '../../../actions/shared/index'
import { getPeriodKey, getPeriodLabel, getUniquelySortedDataPoints, formatValue } from '../../../utils/estimates.util'
import { getFromXigniteToStandard } from '../../../utils/stock/stock.util'
import { getTickers } from '../../../utils'
import { DataTable, Select, Spinner, Toolbar, ToolbarRow } from '../../../components'
import EstimatesBrokerDetailModal
  from '../../../components/estimates/brokerDetail/modal/estimatesBrokerDetailModal.component'
import { getUserDefaultMetric } from '../../../utils/user.util'
import { THEMES } from '../../../utils/ui'
import './estimatesConsensus.container.css'

class EstimatesConsensus extends Component {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      metrics: [
        { label: 'Earnings Per Share', value: 'eps' },
        { label: 'Income Statement', value: 'income' },
        { label: 'Balance Sheet', value: 'balance' },
        { label: 'Cash Flow', value: 'cash' },
        { label: 'Per Share Items', value: 'pershare' },
        { label: 'Funds From Operations', value: 'ffo' }
      ],
      supportedMetricsMap: {
        'eps': 'eps',
        'earnings per share': 'eps',
        'ebitda': 'ebitda',
        'sg&a expense': 'sga',
        'funds from operations': 'ffo',
        'sales': 'sales'
      },
      defaultMetric: getUserDefaultMetric(props.profile, props.securityId),
      exporting: false,
      isBrokerDetailsModalOpen: false,
      selectedBrokerDetailsItem: null,
      selectedBrokerMetric: null,
      consensusMap: []
    }
  }

  /**
   * ComponentDidMount
   */
  componentDidMount () {
    this._fetchData(null, true)
  }

  /**
   * ComponentDidUpdate
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    const { currentPeer, securityId, profile } = this.props
    if (((currentPeer && currentPeer._security) !== (prevProps.currentPeer && prevProps.currentPeer._security)) ||
      prevProps.securityId !== securityId) {
      const defaultMetric = getUserDefaultMetric(profile, securityId)
      this.setState({ defaultMetric }, () => {
        this._fetchData(null, true)
      })
    }
  }

  /**
   * Fetch data
   * @param metric
   * @param generateDates
   * @private
   */
  _fetchData = (metric, generateDates) => {
    const { defaultMetric } = this.state
    let _metric = metric || this.props.metric || defaultMetric

    if (!this.state.metrics.find((each) => {
      return each.value === _metric
    })) {
      _metric = this.state.metrics[0].value
    }

    this.props.fetchConsensusTable(Object.assign({ metric: _metric }, this.getConsensusParams())).then((resp) => {
      this.mapConsensusItems(_metric)

      const hasData = (resp.payload || []).find((item) => {
        return item.data && item.data.length > 0
      })

      if (generateDates && hasData) {
        this.props.setBrokerQuarterDates(getUniquelySortedDataPoints(resp.payload))
      }
    })
  }

  /**
   * Map consensus items to cells in the table
   */
  mapConsensusItems (_metric) {
    const consensusMap = (this.props.consensus[_metric]).map((consensusItem) => {
      if (!consensusItem || !this.state.supportedMetricsMap[consensusItem.title.toLowerCase()]) {
        return null
      }

      const mappedItems = (consensusItem.data || []).map((item) => {
        return {
          key: getPeriodKey(item.date, item.type, item.frequency, item.quarter),
          type: this.state.supportedMetricsMap[consensusItem.title.toLowerCase()],
          item,
        }
      })

      return mappedItems
    }).filter((item) => item)

    this.setState({
      consensusMap: [].concat.apply([], consensusMap)
    })
  }

  /**
   * Get params for consensus call
   * @returns {{securityId: *}}
   */
  getConsensusParams = () => {
    const { currentPeer, securityId } = this.props
    return { securityId: (currentPeer && currentPeer._security) || securityId }
  }

  /**
   * Get params for broker call
   * @param consensusItem
   * @param metric
   * @returns {{id: *, metric: *, frequency: (*|string|null), date, quarter, limit: number}}
   */
  getBrokerParams = (consensusItem, metric) => {
    const { currentPeer, securityId } = this.props

    return {
      id: (currentPeer && currentPeer._security) || securityId,
      metric: metric,
      frequency: consensusItem.frequency,
      date: consensusItem.date,
      quarter: consensusItem.quarter,
      limit: 25
    }
  }

  /**
   * Handle Dropdown change
   * @param option
   */
  handleDropdownChange = (option) => {
    this._fetchData(option.value)
  }

  /**
   * On Export Click
   */
  onExport = () => {
    const { myOrganization, currentPeer, metric, securityId, peers, profile } = this.props

    const _security = (currentPeer && currentPeer._security) || securityId
    const peerObj = [...getTickers(profile), ...peers].find((each) => {
      return each._security === _security
    })

    const activeSymbol = peerObj ? peerObj.symbol : myOrganization.symbol
    const activeExchange = peerObj ? getFromXigniteToStandard(peerObj.exchange) : getFromXigniteToStandard(myOrganization.exchange)

    this.setState({
      exporting: true
    })

    this.props.downloadExportData({
      url: '/estimates/v2/export/consensus',
      contentType: 'text/csv',
      params: {
        metric,
        securityId: _security
      },
      file: {
        name: [
          activeSymbol,
          activeExchange,
          metric,
          'consensus_estimates'
        ].join('_'),
        type: 'csv'
      }
    }).then(() => {
      this.setState({
        exporting: false
      })
    })
  }

  /**
   * Format Consensus columns
   * @param items
   */
  formatColumns = (items) => {
    if (!items || !items.length) {
      return []
    }

    const data = getUniquelySortedDataPoints(items)
    let estimateFound = false

    const columns = (data || []).map((item) => {
      let highlighted = false

      if (!estimateFound && (item.type && item.type === 'estimate')) {
        estimateFound = true
        highlighted = true
      }

      return {
        id: getPeriodKey(item.date, item.type, item.frequency, item.quarter),
        label: getPeriodLabel(item.date, item.type, item.frequency, item.quarter),
        width: 'narrower',
        textAlign: 'right',
        highlighted
      }
    })
    columns.unshift({ id: 'category', label: 'Category', width: 'auto', dense: true })

    return columns
  }

  /**
   * Format Consensus data
   * @param items
   * @param metric
   */
  formatData = (items, metric) => {
    return (items || []).map((item) => {
      const data = {
        category: item.title
      };

      (item.data || []).forEach((dataItem) => {
        const key = getPeriodKey(dataItem.date, dataItem.type, dataItem.frequency, dataItem.quarter)
        data[key] = formatValue(dataItem.value, metric)
      })

      return data
    })
  }

  /**
   * Custom table cell render function
   * Used to customize cell rendering for specific cells
   * @param columnId
   * @param data
   * @param rowIndex
   * @param rows
   */
  customCellRender = (columnId, data, rowIndex, rows) => {
    if (!data) {
      return
    }

    switch (columnId) {
      case 'category':
        if (data === 'Guidance (high)' || data === 'Guidance (low)') {
          return <span className='data-table_value data-table_value--indented'>{data}</span>
        }
        return data.toString()
      default:
        const row = rows[rowIndex]
        const category = row.category && row.category.toLowerCase()

        if (this.state.supportedMetricsMap[category]) {
          return <span className='data-table_cell--broker-details-available'>{data}</span>
        }

        return data.toString()
    }
  }

  /**
   * Open broker details modal
   * @param event
   * @param columnId
   * @param row
   */
  onCellItemClick = (event, columnId, row) => {
    if (!columnId || !row) {
      return
    }

    const rowMetric = this.state.supportedMetricsMap[row['category'].toLowerCase()]

    if (!rowMetric) {
      return
    }

    const selectedCell = this.state.consensusMap.find((item) => item.key === columnId && item.type === rowMetric)

    if (!selectedCell || !selectedCell.item) {
      return
    }

    this.setState({
      isBrokerDetailsModalOpen: true,
      selectedBrokerDetailsItem: selectedCell.item,
      selectedBrokerMetric: rowMetric,
    }, () => {
      this.props.fetchBrokerTable(this.getBrokerParams(selectedCell.item, rowMetric))
    })
  }

  /**
   * Close Broker Details Modal
   */
  closeBrokerDetailsModal = () => {
    this.setState({
      isBrokerDetailsModalOpen: false
    })
  }

  render () {
    const { metrics, exporting, defaultMetric } = this.state
    const { consensus, loading, metric, profile } = this.props

    const _metric = metric || defaultMetric
    const consensusData = consensus[_metric] || []

    let columns = this.formatColumns(consensusData)
    let formattedData = this.formatData(consensusData, _metric)

    const metricObj = metrics.find((each) => {
      return each.value === _metric
    }) || this.state.metrics[0]

    // todo: refactor to easily provide better UX with table load
    // Adjust for unavailable data
    if (columns && columns.length && columns.length === 1) {
      columns = [{ id: 'empty' }]
      formattedData = []
    } else if (!formattedData || !formattedData.length) {
      columns = [{ id: 'empty' }]
    }

    return (
      <div className='estimates-consensus'>
        <div className='estimates-consensus_list'>
          {!formattedData.length && loading && (
            <Spinner
              theme={'rain'}
              mask={true}
            />
          )}
          <Toolbar theme={THEMES.Q4_BLUE}>
            <ToolbarRow>
              <div className='estimates_toolbar'>
                <div className='toolbar_group'>
                  <Select
                    className='estimates-broker-details_dropdown estimates-broker-details_dropdown--metric'
                    theme={THEMES.INK}
                    size='thin'
                    value={metricObj}
                    options={metrics}
                    isDefaultList
                    closeMenuOnSelect
                    onChange={this.handleDropdownChange}
                    searchable={false}
                    clearable={false}
                  />
                </div>
                <button
                  className={`button button--ink ${!formattedData.length ? 'button--disabled' : ''} ${exporting ? 'button--loading' : ''}`}
                  onClick={this.onExport}>
                  <i className='button_icon q4i-download-4pt'/>
                  <span className='button_label'>Export</span>
                </button>
              </div>
            </ToolbarRow>
          </Toolbar>
          <DataTable
            data={formattedData}
            columns={columns}
            noResultsText={loading ? ' ' : 'No data available'}
            alternating={true}
            customCellRender={this.customCellRender}
            onCellClick={this.onCellItemClick}
          />
        </div>

        <EstimatesBrokerDetailModal
          visible={this.state.isBrokerDetailsModalOpen}
          onClose={this.closeBrokerDetailsModal}
          profile={profile}
          broker={this.props.broker}
          brokerItem={this.state.selectedBrokerDetailsItem}
          metric={this.state.selectedBrokerMetric}
          loading={this.props.brokerLoading}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const profile = state.shared.profile
  const _selected = state.estimates.consensus.selected
  const metric = (_selected && _selected.metric)
  const consensus = state.estimates.consensus
  const broker = state.estimates.broker.items
  const status = state.estimates.consensus.status
  const currentPeer = state.estimates.peers.currentPeer
  const brokerStatus = state.estimates.broker.status
  const peers = state.estimates.peers.items
  const myOrganization = peers && peers[0]

  return {
    myOrganization,
    profile,
    consensus,
    currentPeer,
    broker,
    metric,
    peers,
    loading: status === FETCHING,
    brokerLoading: brokerStatus === FETCHING,
    securityId: props.match.params.securityId
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchConsensusTable: bindActionCreators(fetchConsensusTable, dispatch),
  fetchBrokerTable: bindActionCreators(fetchBrokerTable, dispatch),
  downloadExportData: bindActionCreators(downloadExportData, dispatch),
  setBrokerQuarterDates: bindActionCreators(setBrokerQuarterDates, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EstimatesConsensus))
