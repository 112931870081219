import React, {PureComponent} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getItinerary} from '../../../../../actions/activity/itinerary.actions';

import ItineraryToolbar from './toolbar/toolbar.component';
import ItineraryGrid from './grid/grid.component';

class ItineraryGridContainer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            filters: {
                limit: 25,
                page: 1,
                category: 'all'
            }
        };
    }

    componentDidMount() {
        this.getItinerary();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if modal is closed then itinerary list needs to refresh maintaining filters
        if(prevProps.formModalOpen && !this.props.formModalOpen) {
            this.handleFilterChange({...this.state.filters});
        }
    }

    /**
     * Fetch Itinerary
     */
    getItinerary = () => {
        const {activity} = this.props;
        this.props.fetchItinerary(activity._id, this.state.filters);
    };

    /**
     * Handle filter change
     * @param filters
     */
    handleFilterChange = (filters) => {
        this.setState({
            filters: {
                ...this.state.filters,
                ...{page: 1},
                ...filters
            }
        }, () => this.getItinerary());
    };

    render() {
        const {itinerary, history, handleItineraryItemCreate} = this.props;
        const {filters} = this.state;
        return (
            <div className='itinerary-tab'>
                <ItineraryToolbar
                    counts={itinerary.counts}
                    handleActivitiesTypeChange={this.handleFilterChange}
                    selectedType={(filters && filters.category) || 'all'}
                    handleItineraryItemCreate={handleItineraryItemCreate}
                />
                <ItineraryGrid
                    items={itinerary.items || []}
                    history={history}
                    filters={filters}
                    handleFilterChange={this.handleFilterChange}
                    total={itinerary.total}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    itinerary: state.activity.itinerary.grid || {}
});

const mapDispatchToProps = (dispatch) => ({
    fetchItinerary: bindActionCreators(getItinerary, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ItineraryGridContainer));