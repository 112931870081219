import './promiseQuery.doc'
import { useApolloClient } from '@apollo/react-hooks'
import { isEmpty } from 'lodash'
import { useState, useCallback, useRef } from 'react'

/**
 * @typedef { import('apollo-client').QueryOptions['query'] } DocumentNode
 * @typedef { import('apollo-client').QueryOptions['variables'] } Variables
 *
 * usePromiseQuery Hook
 * @param {DocumentNode} query
 * @returns {[function(Variables): PromiseLike, PromiseQueryHookResult]}
 */
export const usePromiseQuery = (query, options) => {
  const optionsRef = useRef(options)

  /** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */
  const [loading, setLoading] = useState()

  /** @type {[PromiseQueryHookResult, React.Dispatch<React.SetStateAction<PromiseQueryHookResult>>]} */
  const [result, setResult] = useState({ loading })

  const client = useApolloClient()

  const fetch = useCallback((variables) => {
    setLoading(true)
    return client.query({
      query,
      variables,
      ...optionsRef.current
    }).then((response) => {
      if (isEmpty(response)) return

      const { data, ...rest } = response
      setResult(rest)

      return data
    })
      .finally(() => {
        setLoading(false)
      })
  },
  [client, query]
  )

  return [fetch, { ...result, loading: !!loading }]
}
