import React, { Component } from 'react'
import { uniq } from 'lodash'
import { Message, Sidebar, SectionTab } from '../../../../../components'
import { statusType } from '../../../../../actions'
import Filter from './filter/filter.component'
import SaveSearch from './filter/save/save.component'
import SavedSearch from './saved/saved.component'
import { THEMES } from '../../../../../utils'
import './sidebar.component.css'

import {
  CREATE_TARGETING_SAVED_SEARCH_FAILURE,
  CREATE_TARGETING_SAVED_SEARCH_SUCCESS,
  UPDATE_TARGETING_SAVED_SEARCH_FAILURE,
  UPDATE_TARGETING_SAVED_SEARCH_SUCCESS
} from '../../../../../actions/targeting'

import './sidebar.component.css'

const TABS = {
  FILTERS: 'filters',
  SAVED: 'saved'
}

/**
 * Error Message Modal
 * @param onClose
 * @returns {*}
 * @constructor
 */
const ErrorModal = ({ onClose }) => {
  return (
    <Message
      visible={true}
      type='error'
      title='Error'
      message='Oops, something went wrong while trying to send this request. Please try again or contact us if you see this message again.'
      onClose={onClose}
      buttons={[{
        ui: 'spice',
        label: 'close',
        onClick: onClose
      }]}
    />
  )
}

/**
 * Clear button
 * @returns {*}
 * @constructor
 */
const ClearButton = ({ onResetClick }) => {
  const classes = 'clear-button'
  return (
    <div className={classes} onClick={onResetClick}>Clear Filters</div>
  )
}

/**
 * Sidebar Header
 * @return {*}
 * @constructor
 */
const Header = ({ activeTab, onTabChange, onResetClick }) => {
  return (
    <>
      <SectionTab
        theme={THEMES.RAIN}
        onChange={onTabChange}
        activeTab={activeTab}
        tabs={[
          {
            label: 'Filters',
            value: TABS.FILTERS
          },
          {
            label: 'Saved Filters',
            value: TABS.SAVED
          }
        ]}/>
      <ClearButton
        onResetClick={onResetClick}
      />
    </>
  )
}

/**
 * Targeting Sidebar
 */
class TargetingSidebar extends Component {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      activeTab: TABS.FILTERS,
      search: null,
      isSaveSearchOpen: false,
      isValid: true
    }
  }

  /**
   * On Tab Change
   * @param event
   * @param value
   */
  onTabChange = (event, value) => {
    const { fetchSavedSearch, savedSearch } = this.props

    if (value === 'saved' && savedSearch.status !== statusType.SUCCESS) {
      fetchSavedSearch()
    }

    this.setState({
      activeTab: value || 'filters'
    })
  }

  /**
   * On Apply Click (Search)
   */
  onApplyClick = () => {
    const { handleFilterChange, fetchTargets, filter, handelBlockNavigation } = this.props
    const { isValid } = this.state

    if (!isValid) {
      return
    }

    if (filter && filter.page > 1) {
      // reset pagination on apply click, it will automatically trigger re-fetch
      const pageFilter = {
        page: 1
      }

      handleFilterChange(pageFilter)
    } else {
      fetchTargets()
      handelBlockNavigation()
    }
  }

  /**
   * On Reset Click - clear filters
   */
  onResetClick = () => {
    const { resetFilter, resetPeers } = this.props
    resetFilter()
    resetPeers()
    this.setState({ isValid: true, clear: true })
  }

  /**
   * On save search click (sidebar footer) open 'Save My Search' modal
   */
  onSaveClick = () => {
    this.setState({
      isSaveSearchOpen: true
    })
  }

  /**
   * On search save close 'Save My Search' modal
   */
  onSaveClose = () => {
    this.setState({
      isSaveSearchOpen: false
    })
  }

  /**
   * On save search click (save search modal) create/update search
   * @param search
   */
  createSavedSearch = (search) => {
    const { filter, createSavedSearch, updateSavedSearch } = this.props
    const request = (search.id) ? updateSavedSearch : createSavedSearch

    request({ ...search, filter }).then((data) => {
      const success = [
        CREATE_TARGETING_SAVED_SEARCH_SUCCESS, UPDATE_TARGETING_SAVED_SEARCH_SUCCESS
      ].includes(data.type)
      const failure = [
        CREATE_TARGETING_SAVED_SEARCH_FAILURE, UPDATE_TARGETING_SAVED_SEARCH_FAILURE
      ].includes(data.type)

      success && this.onSaveSuccess(search.id)
      failure && this.onSaveFailure()
    })

    this.setState({ search })
  }

  /**
   * On Save Success create toast and reset saved search
   */
  onSaveSuccess = (id) => {
    const { createToast, resetSavedSearch } = this.props
    createToast({ text: `Your search ${id ? 'updated' : 'saved'} successfully.` })
    resetSavedSearch()

    this.setState({
      isSaveSearchOpen: false
    })
  }

  /**
   * On Save Failure display error
   */
  onSaveFailure = () => {
    const { openModal } = this.props
    openModal({
      component: ErrorModal
    })
  }

  /**
   * Get dropdown options
   * @param options
   * @param selected
   * @returns {(T&{selected: *})[]}
   */
  getOptions = (options, selected) => {
    return (options || []).map((option) => ({ ...option, selected: [].concat(selected).includes(option.value) }))
  }

  /**
   * Fetched saved search targets
   * @param event
   * @param search
   */
  loadSavedSearch = async (event, search) => {
    const { fetchTargets, getPeers, handleFilterChange } = this.props
    const { id, name, filter } = search
    const { owns_security, peers } = filter
    const query = { ...filter }

    this.setState({ search: { id, name } })
    handleFilterChange(filter)

    if (peers) {
      const peerList = await getPeers({ page: 1, limit: 25 })
      const security = [].concat(owns_security || [], (peerList.payload || []).filter((peer) => peer && peer._security).map((peer) => peer._security))
      query.owns_security = uniq(security.filter((securityItem) => securityItem && securityItem._id).map((security) => security._id))
    }

    fetchTargets(query)
    this.onTabChange(event, TABS.FILTERS)
  }

  /**
   * Validate all available filters
   * @param isValid
   */
  onValidate = (isValid) => {
    this.setState({ isValid })
  }

  /**
   * On Error
   * @param id
   * @param error
   */
  onError = (id, error) => {
    const { setErrors, filter } = this.props

    setErrors && setErrors({
      errors: {
        ...(filter.errors || {}),
        [id]: error
      }
    })
  }

  /**
   * Render
   * @returns {*}
   */
  render () {
    const {
      collapsed, filter,
      searchLocations, resetLocationSuggestions, isSearchLocationsLoading,
      searchSecurities, resetSecuritySuggestions, isSearchSecuritiesLoading, peers, getPeers, resetPeers,
      handleFilterChange, savedSearch, createSavedSearch, deleteSavedSearch,
      aiTargetingSubscription, openModal, closeModal, region
    } = this.props
    const { activeTab, search, isSaveSearchOpen } = this.state
    const footerButtons = (activeTab === TABS.FILTERS) ? [
      { label: 'Save', onClick: this.onSaveClick, theme: THEMES.SOFT_GREY },
      { label: 'Apply', onClick: this.onApplyClick, theme: THEMES.CITRUS }
    ] : null

    return (
      <>
        <Sidebar
          className='targeting-page_sidebar sidebar--tabs'
          collapsed={collapsed}
          headerTemplate={
            <Header
              activeTab={activeTab}
              onTabChange={this.onTabChange}
              onResetClick={this.onResetClick}
            />}
          footerButtons={footerButtons}>
          {activeTab === TABS.FILTERS && <Filter
            region={region}
            filter={filter}
            searchLocations={searchLocations}
            resetLocationSuggestions={resetLocationSuggestions}
            isSearchLocationsLoading={isSearchLocationsLoading}
            searchSecurities={searchSecurities}
            resetSecuritySuggestions={resetSecuritySuggestions}
            isSearchSecuritiesLoading={isSearchSecuritiesLoading}
            peers={peers}
            getPeers={getPeers}
            resetPeers={resetPeers}
            handleFilterChange={handleFilterChange}
            aiTargetingSubscription={aiTargetingSubscription}
            openModal={openModal}
            onValidate={this.onValidate}
            onError={this.onError}
          />}
          {activeTab === TABS.SAVED && <SavedSearch
            savedSearch={savedSearch}
            loadSavedSearch={this.loadSavedSearch}
            deleteSavedSearch={deleteSavedSearch}
            openModal={openModal}
            closeModal={closeModal}
          />}
        </Sidebar>

        {isSaveSearchOpen ? <SaveSearch
          visible={isSaveSearchOpen}
          onSave={this.createSavedSearch}
          onClose={this.onSaveClose}
          search={search}
          createSavedSearch={createSavedSearch}/> : null}
      </>
    )
  }
}

export default TargetingSidebar
