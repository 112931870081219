import { get } from 'lodash'

import {
  GET_ENTITY_SUMMARY_REQUEST,
  GET_ENTITY_SUMMARY_SUCCESS,
  GET_ENTITY_SUMMARY_FAILURE,
  SAVE_ENTITY_SUMMARY_REQUEST,
  SAVE_ENTITY_SUMMARY_SUCCESS,
  SAVE_ENTITY_SUMMARY_FAILURE,
  DELETE_ENTITY_SUMMARY_REQUEST,
  DELETE_ENTITY_SUMMARY_SUCCESS,
  DELETE_ENTITY_SUMMARY_FAILURE,
  RESET_ENTITY_SUMMARY,
  statusType
} from '../../actions'

const initial = {
  data: null,
  status: statusType.IDLE
}

const entitySummary = (state = initial, action) => {
  switch (action.type) {
    case GET_ENTITY_SUMMARY_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_ENTITY_SUMMARY_SUCCESS:
      return {
        ...state,
        data: get(action, 'payload.body'),
        status: statusType.SUCCESS
      }

    case GET_ENTITY_SUMMARY_FAILURE:
      return {
        error: action.error,
        status: statusType.ERROR
      }

    case SAVE_ENTITY_SUMMARY_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case SAVE_ENTITY_SUMMARY_SUCCESS:
      return {
        ...state,
        data: get(action, 'payload.body'),
        status: statusType.SUCCESS
      }

    case SAVE_ENTITY_SUMMARY_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case DELETE_ENTITY_SUMMARY_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case DELETE_ENTITY_SUMMARY_SUCCESS:
      return {
        ...initial,
        status: statusType.SUCCESS
      }

    case DELETE_ENTITY_SUMMARY_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case RESET_ENTITY_SUMMARY:
      return initial

    default:
      return state
  }
}

export default entitySummary
