import { get, isNumber, set } from 'lodash'
import moment from 'moment-timezone'
import { format, getValueSign, ENTITY_TYPE, formatDate, getLocalizedCurrency } from '../../utils'
import {PEER_METRIC_VALUES} from './ownership.const'

/**
 * Regions for targeting institution holdings flyout
 * @type {*[]}
 */
export const HOLDING_REGION_OPTIONS = [
  { label: 'Asia', value: 'Asia' },
  { label: 'Africa', value: 'Africa' },
  { label: 'Europe', value: 'Europe' },
  { label: 'Latin America', value: 'Latin America' },
  { label: 'Middle East', value: 'Middle East' },
  { label: 'North America', value: 'North America' },
  { label: 'Pacific', value: 'Pacific' },
  { label: 'Other', value: 'Other' }
]

/**
 * Sectors for targeting institution holdings flyout
 * @type {*[]}
 */
export const HOLDING_SECTOR_OPTIONS = [
  { label: 'Commercial Services', value: 'Commercial Services' },
  { label: 'Communications', value: 'Communications' },
  { label: 'Consumer Durables', value: 'Consumer Durables' },
  { label: 'Consumer Non-Durables', value: 'Consumer Non-Durables' },
  { label: 'Consumer Services', value: 'Consumer Services' },
  { label: 'Distribution Services', value: 'Distribution Services' },
  { label: 'Electronic Technology', value: 'Electronic Technology' },
  { label: 'Energy Minerals', value: 'Energy Minerals' },
  { label: 'Finance', value: 'Finance' },
  { label: 'Government', value: 'Government' },
  { label: 'Health Services', value: 'Health Services' },
  { label: 'Health Technology', value: 'Health Technology' },
  { label: 'Industrial Services', value: 'Industrial Services' },
  { label: 'Miscellaneous', value: 'Miscellaneous' },
  { label: 'Non-Energy Minerals', value: 'Non-Energy Minerals' },
  { label: 'Process Industries', value: 'Process Industries' },
  { label: 'Producer Manufacturing', value: 'Producer Manufacturing' },
  { label: 'Retail Trade', value: 'Retail Trade' },
  { label: 'Technology Services', value: 'Technology Services' },
  { label: 'Transportation', value: 'Transportation' },
  { label: 'Utilities', value: 'Utilities' },
  { label: 'Other', value: 'Other' }
]

/**
 * Market Groups for targeting institution holdings flyout
 * @type {*[]}
 */
export const HOLDING_MARKET_GROUP_OPTIONS = [
  { label: 'Mega', value: 'MEGA' },
  { label: 'Large', value: 'LARGE' },
  { label: 'Mid', value: 'MID' },
  { label: 'Small', value: 'SMALL' },
  { label: 'Micro', value: 'MICRO' },
  { label: 'Other', value: 'Other' }
]

/**
 * Get formatted AUM
 * @param value
 * @returns {*}
 */
export const getAUM = (value) => {
  if (!isNumber(value)) {
    return value
  }

  if (value >= 1000) {
    return format(Number(value) / 1000, 2) + 'B'
  }

  return format(Number(value), 2) + 'M'
}

/**
 * Get formatted Fund AUM
 * @param value
 * @returns {*}
 */
export const getFundAUM = (value) => {
  if (!isNumber(value)) {
    return value
  }

  if (value >= 1000000000) {
    return format(Number(value) / 1000000000, 2) + 'B'
  } else if (value >= 1000000) {
    return format(Number(value) / 1000000, 2) + 'M'
  } else if (value >= 1000) {
    return format(Number(value) / 1000, 2) + 'K'
  }
}

/**
 * Format AI Score based on Institution Style
 * @param entity
 * @param score
 * @returns {string}
 */
export const getAiScore = (entity = {}, score) => {
  const oxford = (entity._oxford && entity._oxford.style) || null
  const style = oxford || (entity.style ? entity.style : '')
  const isPassive = ['index', 'quant'].indexOf(style.toLowerCase()) > -1

  return (score && isNumber(score)) ? score : isPassive ? 'Passive' : '-'
}

/**
 * Get change value based on ownership type
 * @param dataItem
 * @param isShareholderId
 * @returns {{sign: string, value: string}}
 */
export const getChange = (dataItem, isShareholderId = false) => {
  const dropNegative = true
  let value

  if (isShareholderId) {
    value = dataItem.change

    return {
      value: (value || value !== 0) ? format(value, null, null, dropNegative) : '0',
      sign: getValueSign(value)
    }
  }

  value = dataItem.qtr_change || dataItem.change

  const isDelayed = moment.utc().isBetween(moment.utc().startOf('quarter'), moment.utc().startOf('quarter').add(45, 'day'))
  const notYetReported = isDelayed && Number(value) === 0

  return {
    value: notYetReported ? 'Yet to file' : (value || value !== 0) ? format(value, null, null, dropNegative) : '0',
    sign: getValueSign(value)
  }
}

/**
 * Return historical quarter fields
 * @param {*} quarters
 */
export const getHistQtrFields = (quarters) => {
  let fields = ''

  for (let quarter = 1; quarter <= quarters; quarter++) {
    fields = `${fields} q${quarter}Value q${quarter}Change`
  }

  return fields
}

/**
 * Check if within 45 day reporting window
 */
export const isReportingWindow = () => {
  const currentQtr = moment.utc().startOf('quarter')
  const reportingWindow = moment.utc().startOf('quarter').add(45, 'day')
  return moment.utc().isBetween(currentQtr, reportingWindow)
}

/**
 * Check if entity is yet to file based on reporting window
 * and latest report date
 * @param {*} reportDate
 * @param qtrChange
 */
export const isYetToFile = (reportDate, qtrChange) => {
  const date = moment.utc(new Date(Number(reportDate)))
  const currentQtr = moment.utc().startOf('quarter')
  const isDelayed = isReportingWindow()
  return isDelayed && date.isBefore(currentQtr) && !qtrChange
}

/**
 * Format holders to display parent/child relationship {@link https://www.ag-grid.com/javascript-grid-tree-data/}
 * @param data {object}
 * @param data {string}
 */
export const getTreeHierarchy = (data, fundsKey) => {
  return (data || []).reduce((acc, holder, index) => {
    const { fundId, institutionId, holderType, entityId } = (holder || {})
    holder = { ...holder, rank: (index + 1), hierarchy: [(holderType === ENTITY_TYPE.FUND) ? (fundId || entityId) : (institutionId || entityId)] }
    acc.push(holder)

    const funds = get(holder, fundsKey, [])

    if (Array.isArray(funds) && funds.length > 0) {
      set(holder, fundsKey, funds.map((fund) => {
        const { fundId, entityId } = (fund || {})
        return { ...fund, isNested: true, hierarchy: [holder.institutionId, fundId || entityId] }
      }))

      acc.push(...get(holder, fundsKey))
    }

    return acc
  }, [])
}

/**
 * Get current ownership date
 * @returns {string}
 */
export const getCurrentOwnershipDate = (useLocalization = false) => {
  const day = moment().tz('America/New_York').isoWeekday()
  const week = (day === 7) ? 0 : 1
  const date = moment.utc().subtract(week, 'week').startOf('isoweek').add(4, 'days').startOf('day')

  return useLocalization ? formatDate(date, undefined, undefined, true) : formatDate(date)
}

export const isMetricCurrencyEnabled = (metric) => {
  if (metric === PEER_METRIC_VALUES.marketValueChange || metric === PEER_METRIC_VALUES.marketValue){
    return `(${getLocalizedCurrency()})`;
  }
  return "";
}
