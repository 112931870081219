import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import moment from 'moment-timezone'
import { useGridPersistence, LEVEL } from '../../hook'
import { HOLDER } from '../../utils'
import { usePeerQuery, useSecurityQuery, useStockQuery } from './hook'
import { openModal } from '../../actions'

// components
import { SectionTab } from '../../components/shared'
import {
  Spinner,
  CurrentHolderGrid,
  HistoricalHolderGrid,
  HolderPeerAnalysisGrid,
  ShareholderIdGrid,
  SurveillanceGrid,
  PageMessage
} from '../../components'
import SecurityBanner from './banner/banner.component'
import SecurityOverview from './overview/overview.component'

// utils
import { getOwnershipType, OWNERSHIP_TYPE, THEMES, PAGE_MESSAGE, ROUTE_PATH } from '../../utils'
import { get } from 'lodash'

const dataIdPrefix = 'SecurityPage'

const propTypes = {}
const defaultProps = {}

const { SHAREHOLDER, SURVEILLANCE } = OWNERSHIP_TYPE

export const TABS = {
  SHAREHOLDER: 'shareholderId',
  SURVEILLANCE: 'surveillance',
  CURRENT: 'current',
  HISTORICAL: 'historical',
  PEER: 'peer'
}

const stockAverageRange = {
  startDate: moment.utc().subtract(1, 'month').startOf('day').toISOString(),
  endDate: moment.utc().toISOString()
}

/**
 * Security Profile Page
 * @param props
 */
function SecurityPage (props) {
  const { tickerId, legacySecurityId, ownershipType, openModal } = props
  const { value = '13f' } = ownershipType

  const { loading, error, data } = useSecurityQuery({ variables: { tickerId } })
  const { loading: isStock, data: stock } = useStockQuery({ variables: { tickerId, ...stockAverageRange } })
  const { data: peer, refetch } = usePeerQuery({ variables: { tickerId } })

  const security = get(data, 'security.items.0')
  const { id, active } = (security || {})

  const persistence = useGridPersistence({
    route: ROUTE_PATH.SECURITY_WITH_ID,
    defaultRootTab: TABS.CURRENT,
  })

  const { getGridWithDefault, setGridTab } = persistence;

  /**
   * Render Security Page
   */
  return (
    <>
      {(error || (data && !id)) && <Redirect to='/error/404' />}
      {loading && <Spinner mask theme={THEMES.DARK} />}
      {!!id &&
        (active  
          ? <div className='security-page'>
              <SecurityBanner
                dataIdPrefix={dataIdPrefix}
                security={security}
                legacySecurityId={legacySecurityId}
                peerId={get(peer, 'peer.items.0.id')}
                onPeerUpdate={() => refetch()}
                openModal={openModal}
              />

              <SecurityOverview
                dataIdPrefix={dataIdPrefix}
                security={security}
                legacySecurityId={legacySecurityId}
                isStockLoading={[isStock].some((item) => !!item)}
                stock={{ ...get(stock, 'stock.items.0'), averageVolume: get(stock, 'averageVolume.average') }}
              />

              <SectionTab
                theme={THEMES.RAIN}
                initialActiveTab={getGridWithDefault(LEVEL.GRID_TAB, TABS.CURRENT)}
                setGridTab={setGridTab}
                tabs={[
                  {
                    id: TABS.SHAREHOLDER,
                    label: 'Shareholder ID',
                    view: (
                      <ShareholderIdGrid
                        dataIdPrefix={dataIdPrefix}
                        tickerId={tickerId}
                        persistence={persistence}
                      />
                    ),
                    hidden: (value !== SHAREHOLDER)
                  },
                  {
                    id: TABS.SURVEILLANCE,
                    label: 'Surveillance',
                    view: (
                      <SurveillanceGrid
                        dataIdPrefix={dataIdPrefix}
                        tickerId={tickerId}
                        persistence={persistence}
                      />
                    ),
                    hidden: (value !== SURVEILLANCE)
                  },
                  {
                    id: TABS.CURRENT,
                    label: 'Current Holders',
                    view: (
                      <CurrentHolderGrid
                        dataIdPrefix={dataIdPrefix}
                        tickerId={tickerId}
                        persistence={persistence}
                      />
                    )
                  },
                  {
                    id: TABS.HISTORICAL,
                    label: 'Historical Holders',
                    view: (
                      <HistoricalHolderGrid
                        dataIdPrefix={dataIdPrefix}
                        tickerId={tickerId}
                        persistence={persistence}
                      />
                    )
                  },
                  {
                    id: TABS.PEER,
                    label: 'Peer Analysis',
                    view: (
                      <HolderPeerAnalysisGrid
                        dataIdPrefix={dataIdPrefix}
                        security={security}
                        tickerId={tickerId}
                        persistence={persistence}
                      />
                    )
                  }
                ]}
              />
            </div>
          : <PageMessage
              message = {PAGE_MESSAGE.SEC_PAGE_INACTIVE}
            />
        )
      }
    </>
  )
}

SecurityPage.propTypes = propTypes
SecurityPage.defaultProps = defaultProps

const mapStateToProps = (state, ownProps) => {
  const profile = get(state, 'profile.data')
  return {
    ownershipType: getOwnershipType(profile, ownProps.tickerId)
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SecurityPage)
