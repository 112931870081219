import {
  GET_PEERS_REQUEST,
  GET_PEERS_SUCCESS,
  GET_PEERS_FAILURE,
  CREATE_PEER_SUCCESS,
  CREATE_PEER_FAILURE,
  REMOVE_PEER_SUCCESS,
  REMOVE_PEER_FAILURE,
  RESET,
  statusType
} from '../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const peers = (state = initial, action) => {
  const { payload, error } = action

  switch (action.type) {
    case GET_PEERS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_PEERS_SUCCESS:
      return {
        ...state,
        data: (payload || []).filter((peer) => peer && peer._security),
        status: statusType.SUCCESS
      }

    case GET_PEERS_FAILURE:
      return {
        error,
        status: statusType.ERROR
      }

    case CREATE_PEER_SUCCESS:
      return {
        ...state,
        data: [].concat(payload[0], state.data),
        status: statusType.SUCCESS
      }

    case CREATE_PEER_FAILURE:
      return {
        status: statusType.ERROR
      }

    case REMOVE_PEER_SUCCESS:
      return {
        ...state,
        data: [].concat(state.data).filter((peer) => peer._id !== (payload && payload._id)),
        status: statusType.SUCCESS
      }

    case REMOVE_PEER_FAILURE:
      return {
        status: statusType.ERROR
      }

    case RESET:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default peers
