import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { getClassName, THEMES } from '../../utils'

import './listEditable.component.scss'

/**
 * Editable List Component
 */
class ListEditable extends PureComponent {
  /**
   * Render
   * @return {XML}
   */
  render () {
    const { listKey, iconTheme, icon, items, onDelete } = this.props
    return (
      <div className='removable-list'>
        {(items || []).map((item, index) => {
          if (!item) {
            return null
          }

          const { label } = item

          return (
            <div key={item[listKey] || index} className='removable-list_item'>
              <div className='removable-list_item-content'>
                {icon ? <i className={getClassName('removable-list_item-icon', [
                  { condition: icon, trueClassName: icon },
                  { condition: iconTheme, trueClassName: `removable-list_item-icon--${iconTheme}` }
                ])}
                /> : null}
                <div className='removable-list_item-label'>{label}</div>
              </div>
              <i className='removable-list_item-delete q4i-close-4pt' onClick={() => onDelete(item)} />
            </div>
          )
        })}
      </div>
    )
  }
}

ListEditable.propTypes = {
  theme: PropTypes.oneOf([THEMES.LIGHT_GREY, THEMES.WHITES]),
  icon: PropTypes.string,
  iconTheme: PropTypes.string,
  listKey: PropTypes.string,
  items: PropTypes.array,
  onDelete: PropTypes.func
}

ListEditable.defaultProps = {
  items: [],
  theme: THEMES.LIGHT_GREY
}

export default ListEditable
