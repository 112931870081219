import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

/**
 * Quick Search Action Type Constants
 * @type {string}
 */
export const GET_QUICK_SEARCH_REQUEST = 'GET_QUICK_SEARCH_REQUEST'
export const GET_QUICK_SEARCH_SUCCESS = 'GET_QUICK_SEARCH_SUCCESS'
export const GET_QUICK_SEARCH_FAILURE = 'GET_QUICK_SEARCH_FAILURE'
export const CLEAR_QUICK_SEARCH = 'CLEAR_QUICK_SEARCH'

/**
 * Get quick search
 * @param params
 * @returns {{[p: string]: *}}
 * @private
 */
const _getQuickSearch = (params, options = {}, apiVersion = 'v2') => ({
  [CALL_API]: {
    types: options.types || [
      GET_QUICK_SEARCH_REQUEST,
      GET_QUICK_SEARCH_SUCCESS,
      GET_QUICK_SEARCH_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/search/${apiVersion}/quick`,
    payload: Object.assign({
      pageSize: 10,
      type: [options.type],
      page: options.page
    }, params)
  }
})

/**
 * Dispatch GET_QUICK_SEARCH_REQUEST
 */
export const getQuickSearch = (query, options, apiVersion) => (dispatch) => {
  return dispatch(_getQuickSearch(query, options, apiVersion))
}

/**
 * Clear Quicksearch
 */
const _clearQuickSearch = () => ({
  type: CLEAR_QUICK_SEARCH
})

/**
 * Dispatch CLEAR_NEW_QUICK_SEARCH
 */
export const clearQuickSearch = () => (dispatch) => {
  return dispatch(_clearQuickSearch())
}
