import { combineReducers } from 'redux'
import peers from './estimatesPeer.reducer'
import consensus from './estimatesConsensus.reducer'
import broker from './estimatesBroker.reducer'
import historical from './estimatesHistorical.reducer'
import consensusDetails from './consensusDetails.reducer'
import rating from './currentRating.reducer'
import defaultState from './defaultState.reducer'
import currentSecurity from './currentSecurity.reducer'

export default combineReducers({
  peers,
  consensus,
  broker,
  historical,
  consensusDetails,
  rating,
  defaultState,
  currentSecurity
})
