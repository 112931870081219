import gql from 'graphql-tag'
import { get } from 'lodash'
import { emailPreference } from '../index'
import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import client from '../../middleware/graphql.middleware'
import { SWIFTYPE_ENTITY_TYPES } from '../../utils'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../services/launchDarkly.service'

export const CREATE_ADDRESS_BOOK_LIST_REQUEST = 'CREATE_ADDRESS_BOOK_LIST_REQUEST'
export const CREATE_ADDRESS_BOOK_LIST_SUCCESS = 'CREATE_ADDRESS_BOOK_LIST_SUCCESS'
export const CREATE_ADDRESS_BOOK_LIST_FAILURE = 'CREATE_ADDRESS_BOOK_LIST_FAILURE'

export const UPDATE_ADDRESS_BOOK_LIST_REQUEST = 'UPDATE_ADDRESS_BOOK_LIST_REQUEST'
export const UPDATE_ADDRESS_BOOK_LIST_SUCCESS = 'UPDATE_ADDRESS_BOOK_LIST_SUCCESS'
export const UPDATE_ADDRESS_BOOK_LIST_FAILURE = 'UPDATE_ADDRESS_BOOK_LIST_FAILURE'

export const DELETE_ADDRESS_BOOK_LIST_REQUEST = 'DELETE_ADDRESS_BOOK_LIST_REQUEST'
export const DELETE_ADDRESS_BOOK_LIST_SUCCESS = 'DELETE_ADDRESS_BOOK_LIST_SUCCESS'
export const DELETE_ADDRESS_BOOK_LIST_FAILURE = 'DELETE_ADDRESS_BOOK_LIST_FAILURE'

export const GET_ADDRESS_BOOK_LISTS_REQUEST = 'GET_ADDRESS_BOOK_LIST_REQUEST'
export const GET_ADDRESS_BOOK_LISTS_SUCCESS = 'GET_ADDRESS_BOOK_LIST_SUCCESS'
export const GET_ADDRESS_BOOK_LISTS_FAILURE = 'GET_ADDRESS_BOOK_LIST_FAILURE'

export const GET_ADDRESS_BOOK_LIST_COUNT_REQUEST = 'GET_ADDRESS_BOOK_LIST_COUNT_REQUEST'
export const GET_ADDRESS_BOOK_LIST_COUNT_SUCCESS = 'GET_ADDRESS_BOOK_LIST_COUNT_SUCCESS'
export const GET_ADDRESS_BOOK_LIST_COUNT_FAILURE = 'GET_ADDRESS_BOOK_LIST_COUNT_FAILURE'

export const GET_ADDRESS_BOOK_CONTACTS_REQUEST = 'GET_ADDRESS_BOOK_CONTACTS_REQUEST'
export const GET_ADDRESS_BOOK_CONTACTS_SUCCESS = 'GET_ADDRESS_BOOK_CONTACTS_SUCCESS'
export const GET_ADDRESS_BOOK_CONTACTS_FAILURE = 'GET_ADDRESS_BOOK_CONTACTS_FAILURE'

export const UPDATE_SAVED_CONTACT_LIST_REQUEST = 'UPDATE_SAVED_CONTACT_LIST_REQUEST'
export const UPDATE_SAVED_CONTACT_LIST_SUCCESS = 'UPDATE_SAVED_CONTACT_LIST_SUCCESS'
export const UPDATE_SAVED_CONTACT_LIST_FAILURE = 'UPDATE_SAVED_CONTACT_LIST_FAILURE'

export const ADD_CONTACT_TO_ADDRESS_BOOK_REQUEST = 'ADD_CONTACT_TO_ADDRESS_BOOK_REQUEST'
export const ADD_CONTACT_TO_ADDRESS_BOOK_SUCCESS = 'ADD_CONTACT_TO_ADDRESS_BOOK_SUCCESS'
export const ADD_CONTACT_TO_ADDRESS_BOOK_FAILURE = 'ADD_CONTACT_TO_ADDRESS_BOOK_FAILURE'

export const REMOVE_CONTACT_FROM_ADDRESS_BOOK_REQUEST = 'REMOVE_CONTACT_FROM_ADDRESS_BOOK_REQUEST'
export const REMOVE_CONTACT_FROM_ADDRESS_BOOK_SUCCESS = 'REMOVE_CONTACT_FROM_ADDRESS_BOOK_SUCCESS'
export const REMOVE_CONTACT_FROM_ADDRESS_BOOK_FAILURE = 'REMOVE_CONTACT_FROM_ADDRESS_BOOK_FAILURE'

export const GET_SAVED_CONTACT_REQUEST = 'GET_SAVED_CONTACT_REQUEST'
export const GET_SAVED_CONTACT_SUCCESS = 'GET_SAVED_CONTACT_REQUEST_SUCCESS'
export const GET_SAVED_CONTACT_FAILURE = 'GET_SAVED_CONTACT_REQUEST_FAILURE'

export const RESET_SAVED_CONTACT = 'RESET_SAVED_CONTACT'

export const GET_ADDRESS_BOOK_INSTITUTION_REQUEST = 'GET_ADDRESS_BOOK_INSTITUTION_REQUEST'
export const GET_ADDRESS_BOOK_INSTITUTION_SUCCESS = 'GET_ADDRESS_BOOK_INSTITUTION_SUCCESS'
export const GET_ADDRESS_BOOK_INSTITUTION_FAILURE = 'GET_ADDRESS_BOOK_INSTITUTION_FAILURE'
export const RESET_ADDRESS_BOOK_INSTITUTION = 'RESET_ADDRESS_BOOK_INSTITUTION'

export const GET_ADDRESS_BOOK_CONTACT_LOCATION_REQUEST = 'GET_ADDRESS_BOOK_CONTACT_LOCATION_REQUEST'
export const GET_ADDRESS_BOOK_CONTACT_LOCATION_SUCCESS = 'GET_ADDRESS_BOOK_CONTACT_LOCATION_SUCCESS'
export const GET_ADDRESS_BOOK_CONTACT_LOCATION_FAILURE = 'GET_ADDRESS_BOOK_CONTACT_LOCATION_FAILURE'
export const RESET_ADDRESS_BOOK_CONTACT_LOCATION = 'RESET_ADDRESS_BOOK_CONTACT_LOCATION'

export const UPDATE_USER_EMAIL_PREFERENCE = 'UPDATE_USER_EMAIL_PREFERENCE'

/**
 * Create Contact List
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _createContactList = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      CREATE_ADDRESS_BOOK_LIST_REQUEST,
      CREATE_ADDRESS_BOOK_LIST_SUCCESS,
      CREATE_ADDRESS_BOOK_LIST_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/addressbook/list',
    payload: params
  }
})

/**
 * Update Contact List
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _updateContactList = (params = {}, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      UPDATE_ADDRESS_BOOK_LIST_REQUEST,
      UPDATE_ADDRESS_BOOK_LIST_SUCCESS,
      UPDATE_ADDRESS_BOOK_LIST_FAILURE
    ],
    method: METHOD_TYPE.PUT,
    endpoint: `/addressbook/list/${params.id}`,
    payload: params
  }
})

/**
 * Delete Contact List
 * @param id
 * @param options
 * @returns {{}}
 * @private
 */
const _deleteContactList = (id, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      DELETE_ADDRESS_BOOK_LIST_REQUEST,
      DELETE_ADDRESS_BOOK_LIST_SUCCESS,
      DELETE_ADDRESS_BOOK_LIST_FAILURE
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: `/addressbook/list/${id}`
  }
})

/**
 * Get Contact Lists
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _getContactLists = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_ADDRESS_BOOK_LISTS_REQUEST,
      GET_ADDRESS_BOOK_LISTS_SUCCESS,
      GET_ADDRESS_BOOK_LISTS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/addressbook/list',
    payload: params
  }
})

/**
 * Get Contact List Count
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _getContactListCount = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_ADDRESS_BOOK_LIST_COUNT_REQUEST,
      GET_ADDRESS_BOOK_LIST_COUNT_SUCCESS,
      GET_ADDRESS_BOOK_LIST_COUNT_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/addressbook/list/count',
    payload: params
  }
})

/**
 * Get Address Book Contacts
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _getContacts = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_ADDRESS_BOOK_CONTACTS_REQUEST,
      GET_ADDRESS_BOOK_CONTACTS_SUCCESS,
      GET_ADDRESS_BOOK_CONTACTS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/addressbook',
    payload: params
  }
})

/**
 * Add Contact to Address Book
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _addToAddressBook = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      ADD_CONTACT_TO_ADDRESS_BOOK_REQUEST,
      ADD_CONTACT_TO_ADDRESS_BOOK_SUCCESS,
      ADD_CONTACT_TO_ADDRESS_BOOK_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/addressbook',
    payload: params
  }
})

/**
 * Remove Contact from Address Book
 * @param params
 * @param options
 * @returns {{}}
 * @private
 */
const _removeFromAddressBook = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      REMOVE_CONTACT_FROM_ADDRESS_BOOK_REQUEST,
      REMOVE_CONTACT_FROM_ADDRESS_BOOK_SUCCESS,
      REMOVE_CONTACT_FROM_ADDRESS_BOOK_FAILURE
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: '/addressbook',
    payload: params
  }
})

const _fetchInstitutionSuggestions = (query) => (dispatch) => {
  dispatch({ type: GET_ADDRESS_BOOK_INSTITUTION_REQUEST })
  return client
    .query({
      query: ADVANCED_SEARCH_INST_QUERY,
      variables: {
        query: query,
        entity: [SWIFTYPE_ENTITY_TYPES.INSTITUTIONS],
        page: 1,
        limit: 10,
        useElasticSearch: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)
      },
      fetchPolicy: 'no-cache'
    })
    .then((data) => {
      const searchResults = get(data, 'data.advancedSearch.items')
      dispatch({
        payload: searchResults,
        type: GET_ADDRESS_BOOK_INSTITUTION_SUCCESS
      })
    })
    .catch((error) => {
      dispatch({ error, type: GET_ADDRESS_BOOK_INSTITUTION_FAILURE })
    })
}

const _fetchLocationSuggestions = (query) => (dispatch) => {
  dispatch({ type: GET_ADDRESS_BOOK_CONTACT_LOCATION_REQUEST })
  return client
    .query({
      query: ADVANCED_SEARCH_CONTACT_LOCATIONS_QUERY,
      variables: {
        query: query,
        entity: [SWIFTYPE_ENTITY_TYPES.CONTACT_LOCATION],
        page: 1,
        limit: 10,
        useElasticSearch: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)
      },
      fetchPolicy: 'no-cache'
    })
    .then((data) => {
      const searchResults = get(data, 'data.advancedSearch.items')
      dispatch({
        payload: searchResults,
        type: GET_ADDRESS_BOOK_CONTACT_LOCATION_SUCCESS
      })
    })
    .catch((error) => {
      dispatch({ error, type: GET_ADDRESS_BOOK_CONTACT_LOCATION_FAILURE })
    })
}

/**
 * Update Saved Contact's lists
 * @param params.copy - true to copy to selected lists
 * @param options
 * @returns {{}}
 * @private
 */
const _updateSavedContactLists = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      UPDATE_SAVED_CONTACT_LIST_REQUEST,
      UPDATE_SAVED_CONTACT_LIST_SUCCESS,
      UPDATE_SAVED_CONTACT_LIST_FAILURE
    ],
    method: METHOD_TYPE.PUT,
    endpoint: '/addressbook',
    payload: params
  }
})

/**
 * Get Saved Contact by contact id
 * @param id
 * @param options
 * @returns {{}}
 * @private
 */
const _getSavedContact = (id, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_SAVED_CONTACT_REQUEST,
      GET_SAVED_CONTACT_SUCCESS,
      GET_SAVED_CONTACT_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/addressbook/contact/${id}`
  }
})

/**
 * Dispatch CREATE_ADDRESS_BOOK_LIST_REQUEST
 * @param params
 * @param options
 */
export const createContactList = (params, options) => (dispatch) => {
  return dispatch(_createContactList(params, options))
}

/**
 * Dispatch UPDATE_ADDRESS_BOOK_LIST_REQUEST
 * @param params
 * @param options
 */
export const updateContactList = (params, options) => (dispatch) => {
  return dispatch(_updateContactList(params, options))
}

/**
 * Dispatch DELETE_ADDRESS_BOOK_LIST_REQUEST
 * @param id
 * @param options
 */
export const deleteContactList = (id, options) => (dispatch) => {
  return dispatch(_deleteContactList(id, options))
}

/**
 * Dispatch GET_ADDRESS_BOOK_LISTS_REQUEST
 * @param params
 * @param options
 */
export const getContactLists = (params, options) => (dispatch) => {
  return dispatch(_getContactLists(params, options))
}

/**
 * Dispatch GET_ADDRESS_BOOK_LIST_COUNT_REQUEST
 * @param params
 * @param options
 */
export const getContactListCount = (params, options) => (dispatch) => {
  return dispatch(_getContactListCount(params, options))
}

/**
 * Dispatch GET_ADDRESS_BOOK_CONTACTS_REQUEST
 * @param params
 * @param options
 */
export const getContacts = (params, options) => (dispatch) => {
  return dispatch(_getContacts(params, options))
}

/**
 * Dispatch ADD_CONTACT_TO_ADDRESS_BOOK_REQUEST
 * @param params
 * @param options
 */
export const addToAddressBook = (params, options) => (dispatch) => {
  return dispatch(_addToAddressBook(params, options))
}

/**
 * Dispatch REMOVE_CONTACT_FROM_ADDRESS_BOOK_REQUEST
 * @param params
 * @param options
 */
export const removeFromAddressBook = (params, options) => (dispatch) => {
  return dispatch(_removeFromAddressBook(params, options))
}

/**
 * Dispatch UPDATE_CONTACT_LISTS_REQUEST
 * @param params
 * @param options
 */
export const updateSavedContactLists = (params, options) => (dispatch) => {
  return dispatch(_updateSavedContactLists(params, options))
}

/**
 * Dispatch GET_SAVED_CONTACT_REQUEST
 * @param id
 * @param options
 */
export const getSavedContact = (id, options) => (dispatch) => {
  return dispatch(_getSavedContact(id, options))
}

/**
 * Dispatch RESET_SAVED_CONTACT
 */
export const clearSavedContact = () => (dispatch) => {
  return dispatch({ type: RESET_SAVED_CONTACT })
}

/**
 * Dispatch GET_ADDRESS_BOOK_INSTITUTION_REQUEST
 */
export const fetchInstitutionSuggestions = (query) => (dispatch) => {
  if (!query) {
    return dispatch({
      payload: [],
      type: GET_ADDRESS_BOOK_INSTITUTION_SUCCESS
    })
  }

  return dispatch(_fetchInstitutionSuggestions(query))
}

/**
 * Dispatch RESET_ADDRESS_BOOK_INSTITUTION
 */
export const resetInstitutionSuggestions = () => (dispatch) => {
  return dispatch({ type: RESET_ADDRESS_BOOK_INSTITUTION })
}

/**
 * Dispatch GET_ADDRESS_BOOK_CONTACT_LOCATION_REQUEST
 */
export const fetchLocationSuggestions = (query) => (dispatch) => {
  if (!query) {
    return dispatch({
      payload: [],
      type: GET_ADDRESS_BOOK_CONTACT_LOCATION_SUCCESS
    })
  }

  return dispatch(_fetchLocationSuggestions(query))
}

/**
 * Dispatch RESET_ADDRESS_BOOK_CONTACT_LOCATION
 */
export const resetLocationSuggestions = () => (dispatch) => {
  return dispatch({ type: RESET_ADDRESS_BOOK_CONTACT_LOCATION })
}

/**
 * Dispatch UPDATE_USER_EMAIL_PREFERENCE
 */
 export const updateUserEmailPreference = (preference = emailPreference.INITIAL) => (dispatch) => {
  return dispatch({ type: UPDATE_USER_EMAIL_PREFERENCE, payload: preference })
}

/**
 * ADVANCED_SEARCH GraphQL query for institution search
 */
const ADVANCED_SEARCH_INST_QUERY = gql`
  query AdvancedSearch(
    $entity: [String]!, 
    $filter: SearchFilter, 
    $query: String, 
    $page: Int, 
    $limit: Int,
    $useElasticSearch: Boolean,
  ) {
    advancedSearch(
      entity: $entity, 
      filter: $filter, 
      query: $query, 
      page: $page, 
      limit: $limit, 
      useElasticSearch: $useElasticSearch,
    ) {
      items {
        id
        entityConnection {
          ...institutionsFragmentAddressBook
        }
      }
      count
    }
  }
  fragment institutionsFragmentAddressBook on InstitutionSearchDTO {
    type
    sourceId
    institutionName
    entityId
  }
`

/**
 * Dispatch ADVANCED_SEARCH_CONTACT_LOCATIONS_QUERY
 */
const ADVANCED_SEARCH_CONTACT_LOCATIONS_QUERY = gql`
  query AdvancedSearch(
    $entity: [String]!, 
    $filter: SearchFilter, 
    $query: String, 
    $page: Int, 
    $limit: Int,
    $useElasticSearch: Boolean
  ) {
    advancedSearch(
      entity: $entity, 
      query: $query, 
      filter: $filter, 
      page: $page, 
      limit: $limit,
      useElasticSearch: $useElasticSearch
    ) {
      items {
        entityConnection {
          ...contactLocationsFragment
        }
      }
    }
  }
  fragment contactLocationsFragment on ContactLocationSearchDTO {
    city
    state
    countryCode
    country
  }
`
