import {
  STORE_REPORT_DATA_VISUALIZATION,
  RESET_REPORT_DATA_VISUALIZATION
} from '../../actions'

const initial = {
  widgetType: 'table',
  showTitle: true,
  chartOptions: {
    chartType: null,
    aggregationModel: null,
    xAxis: null,
    yAxis: [],
    series: []
  }
}

const reportDataVisualization = (state = initial, action) => {
  switch (action.type) {
    case STORE_REPORT_DATA_VISUALIZATION:
      return {
        ...state,
        ...action.payload
      }
    case RESET_REPORT_DATA_VISUALIZATION:
      return initial
    default:
      return state
  }
}

export default reportDataVisualization
