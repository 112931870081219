import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Card } from '../../../../../../../components'
import { config } from '../../../../../../../config'
import { THEMES } from '../../../../../../../utils/ui'

import './information.component.css'

const propTypes = {
  entity: PropTypes.shape({
    direct_phone: PropTypes.string,
    phone_extension: PropTypes.string,
    mobile: PropTypes.string,
    direct_email: PropTypes.string
  }),
  className: PropTypes.string
}

const defaultProps = {
  label: 'Contact Information',
  isLoading: false
}

/**
 * InformationValue Component
 */
function InformationValue ({ label, value, type }) {
  return (
    <div className='information_item'>
      <div className='information_item--label'>{label}</div>
      <div className='information_item--value'>{value ? (type === 'email' ?
        <a
          className='card_section-detail'
          href={`mailto:${value}?Subject=Contact%20from%20Q4%20Desktop&bcc=${config.loggerEmail}`}>{value}</a>
        : value) : '-'}
      </div>
    </div>
  )
}

/**
 * Information Component
 */
function InformationComponent ({ entity, isLoading, className }) {
  const {
    direct_phone,
    phone_extension,
    mobile,
    direct_email
  } = entity
  const attributes = [
    { label: 'Primary Phone', value: direct_phone },
    { label: 'Extension', value: phone_extension },
    { label: 'Mobile Phone', value: mobile },
    { label: 'Email', type: 'email', value: direct_email }
  ]

  return (
    <Card
      title='Contact Information'
      isLoading={isLoading}
      className={className}
      theme={THEMES.LIGHT}
    >
      <div className='information'>
        {attributes.map((attribute) => {
          return <InformationValue key={attribute.label} {...attribute} isLoading={isLoading}/>
        })}
      </div>
    </Card>
  )
}

InformationComponent.propTypes = propTypes
InformationComponent.defaultProps = defaultProps

export default memo(InformationComponent)
