import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

export const GET_CONTACTS = gql`
  query Contact(
    $securityId: [String]!
    $entityId: [String]!
    $entityType: String!
    $favorite: Boolean
    $holder: Boolean
    $peerHolder: Boolean
    $functions: [String]
    $search: String
    $sortBy: String
    $sortDir: String
    $page: Int!
    $limit: Int!
  ) {
    contact(
      entityId: $entityId
      entityType: $entityType
      securityId: $securityId
      filter: {
        favorite: $favorite
        holder: $holder
        peerHolder: $peerHolder
        functions: $functions
      }
      search: $search
      sortBy: $sortBy
      sortDir: $sortDir
      page: $page
      limit: $limit
    ) {
      items {
        id
        fullName
        jobs {
          entityId
          title
          phone
          email
          city
          stateProvince
          investmentFocus
          functions
        }
        activityConnection {
          items {
            start {
              date
            }
          }
        }
        contactHoldingCurrentConnection(securityId: $securityId) {
          items {
            current
          }
        }
      }
      count
    }
  }`

export function useContactQuery (options) {
  return useQuery(GET_CONTACTS, options)
}
