import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import {
  getManagedFunds
} from '../../../actions/contact'
import { getActiveTicker } from '../../../utils'
import ManagedFunds from './table/table.component'

import './grid.container.css'

/**
 * ManagedFundContainer
 * @param props
 * @returns {*}
 * @constructor
 * @deprecated
 */
function ManagedFundGrid (props) {
  const { contactId, getManagedFunds, securityId, history, funds, handleRowClick } = props

  useEffect(() => {
    getManagedFunds({ id: contactId, securityId })
  }, [contactId, securityId, getManagedFunds])

  return (
    <ManagedFunds
      history={history}
      params={{
        id: contactId,
        securityId: securityId
      }}
      funds={funds}
      getManagedFunds={getManagedFunds}
      handleRowClick={handleRowClick}
    />
  )
}

const mapDispatchToProps = (dispatch) => ({
  getManagedFunds: bindActionCreators(getManagedFunds, dispatch)
})

const mapStateToProps = (state) => {
  const { contact, profile } = state
  const ticker = profile && getActiveTicker(profile.data)
  return {
    contact: contact.detail,
    securityId: ticker && ticker._security,
    funds: contact.fund
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManagedFundGrid))
