import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'

// actions
import { useBriefingBookQuery, useBriefingBookUpdateQuery, useBriefingBookDeleteQuery } from '../hook'
import { closeModal, createToast, openModal, modalType } from '../../../actions'

// components
import { Spinner } from '../../../components'
import BriefingBookBanner from './banner/banner.component'
import BriefingBookTable from './table/table.component'

// utils
import { ENTITY_TYPE, ACTIVITY_TYPE, THEMES } from '../../../utils'
import { get } from 'lodash'

const dataIdPrefix = 'BriefingBookDetailPage'
const { ROADSHOW, CONFERENCE } = ACTIVITY_TYPE

const propTypes = {}
const defaultProps = {}

/**
 * Get entities payload
 * @param entities
 * @returns {*}
 */
function getEntityPayload (entities) {
  return (entities || []).filter((entity) => entity.entityId).map((entity) => ({
    entityId: entity.entityId,
    entityType: entity.entityType,
    ...entity.entityType === ENTITY_TYPE.CONTACT && { institutionId: entity.institutionId }
  }))
}

/**
 * Briefing Book Detail Page
 * @param props
 */
function BriefingBookDetailPage (props) {
  const { match, openModal, closeModal, createToast, history } = props
  const bookId = get(match, 'params.id')
  const [entities, setEntities] = useState([])

  const { loading, error, data, refetch } = useBriefingBookQuery(
    { variables: { id: bookId } }
  )
  const book = get(data, 'briefingBook.items[0]', {})
  const { id, title, entity, activityConnection } = book
  const isItinerary = [ROADSHOW, CONFERENCE].includes(get(activityConnection, 'items[0].category'))

  /**
   * Update entities state (used as data not refetch on rearrange)
   * can be eliminated when apollo cache will work with fragments
   */
  useEffect(() => {
    setEntities(entity)
  }, [entity, setEntities])

  const [handleDelete, { loading: deleteProgress }] = useBriefingBookDeleteQuery()
  const [handleUpdate, { loading: updateProgress }] = useBriefingBookUpdateQuery()

  /**
   * Handle briefing book delete
   */
  const handleBriefingBookDelete = () => {
    closeModal({
      type: modalType.CONFIRM_MODAL
    })

    handleDelete({ variables: { id } })
      .then((response) => get(response, 'errors')
        ? handleFailure() : history.push('/briefingBook')
      )
  }

  /**
   * Handle briefing book update (title, entities)
   */
  const handleBriefingBookEdit = () => {
    openModal({
      type: modalType.BRIEFING_BOOK_EDIT_MODAL,
      props: {
        book: { id, title, entities },
        onSaveSuccess: handleSuccess
      }
    })
  }

  /**
   * Handle briefing book update (delete/rearrange entities)
   * @param entities
   * @param type
   */
  const handleBriefingBookUpdate = (entities, type) => {
    const entity = getEntityPayload(entities)

    closeModal({
      type: modalType.CONFIRM_MODAL
    })

    handleUpdate({ variables: { id, entity } })
      .then((response) => get(response, 'errors')
        ? handleFailure()
        : type ? handleSuccess(type) : setEntities(entities)
      )
  }

  /**
   * Handle briefing book export modal
   */
  const handleBriefingBookExport = () => {
    openModal({
      type: modalType.TEARSHEET_EXPORT_MODAL,
      props: {
        dataId: dataIdPrefix,
        book: { id, title },
        isItinerary
      }
    })
  }

  /**
   * On success refetch book and show toast message
   */
  function handleSuccess (type) {
    refetch({ id }).then(() => createToast({ text: `Briefing Book ${type}d successfully.` }))
  }

  /**
   * On Action Completion Failure display an error
   */
  function handleFailure () {
    openModal({
      type: modalType.ERROR_MODAL
    })
  }

  return (
    <>
      {(error || (data && !id)) && <Redirect to='/error/404' />}
      {!id && loading && <Spinner mask theme={THEMES.DARK} />}
      {!!id && (
        <div className='briefing-book-detail-page'>
          <BriefingBookBanner
            dataIdPrefix={dataIdPrefix}
            book={book}
            onEdit={handleBriefingBookEdit}
            onDelete={handleBriefingBookDelete}
            onExport={handleBriefingBookExport}
            openModal={openModal}
            history={history}
          />
          <BriefingBookTable
            dataId={dataIdPrefix}
            loading={[loading, updateProgress, deleteProgress].some((item) => !!item)}
            data={entities}
            onEdit={handleBriefingBookEdit}
            onUpdate={handleBriefingBookUpdate}
            openModal={openModal}
            history={history}
          />
        </div>
      )}
    </>
  )
}

BriefingBookDetailPage.propTypes = propTypes
BriefingBookDetailPage.defaultProps = defaultProps

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
  createToast: bindActionCreators(createToast, dispatch)
})

export default connect(null, mapDispatchToProps)(BriefingBookDetailPage)
