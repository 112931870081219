const AnimateTime = 300;

export const GhostableDelay = {
  One: 1,
  Two: 2,
  Three: 3,
  Four: 4,
}

export const GhostableClassName = {
  GhostWithTransitionModifier: 'nui-ghost--transition',
  GhostWithDoneModifier: 'nui-ghost--done',
  SolidWithTransitionModifier: 'nui-solid--transition',
  SolidWithDoneModifier: 'nui-solid--done',
}

export const TransitionSpeedModifier = {
  Fast: 'fast',
  Default: 'default',
  Slow: 'slow',
}

/**
 * @class TransitionSpeed
 * @private @prop {string} _modifier
 */
export class TransitionSpeed {
  _modifier

  /**
   * @constructor
   * @param {string} modifier 
   */
  constructor(modifier) {
    this._modifier = modifier;
  }

  /**
   * modifier
   * @returns {string}
   */
  get modifier() {
    return this._modifier;
  }

  /**
   * time
   * @returns {number}
   */
  get time() {
    switch (this._modifier) {
      case TransitionSpeedModifier.Fast:
        return AnimateTime / 2;
      case TransitionSpeedModifier.Slow:
        return AnimateTime * 2;
      case TransitionSpeedModifier.Default:
      default:
        return AnimateTime;
    }
  }
}
