import React, {PureComponent} from 'react';
import { Select } from '../../../../../components'
import PropTypes from 'prop-types';

/**
 * Report Builder String Filter Component
 */
class StringFilter extends PureComponent {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || '',
            selectedOperator: this.getSelectedOperator(props.operator, props.operators)
        };

        this.inputReference = React.createRef();
    }

    /**
     * ComponentDidUpdate
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {value, operator, operators} = this.props;
        const selectedOperator = this.state.selectedOperator;

        const newOperator = operator && (operator.value !== (selectedOperator && selectedOperator.value));
        const newValue = value !== prevProps.value;

        const newState = {};

        if (newOperator) {
            newState.selectedOperator = this.getSelectedOperator(operator, operators);
        }

        if (newValue) {
            newState.value = value;
        }

        this.setState(newState);
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
        const input = this.inputReference && this.inputReference.current;
        input && input.focus();
    }

    /**
     * Handle input change
     * @param event
     */
    handleInputChange = (event) => {
        const {isBasic, filterId, onChange} = this.props;
        const {selectedOperator} = this.state;

        const value = event.target && event.target.value;
        const valueState = {
            operator: selectedOperator,
            value
        };

        this.setState({
            value
        }, () => {
            onChange && onChange(filterId, valueState, isBasic);
        });
    };

    /**
     * Handle operator change from Dropdown component
     * @param operator
     */
    handleOperatorChange = (operator) => {
        if (!operator) {
            return;
        }

        const {filterId, onChange} = this.props;
        const {value} = this.state;

        onChange && onChange(filterId, {
            operator,
            value
        });
    };

    /**
     * Get selected operator
     * @param operator
     * @param operators
     * @returns {*|null}
     */
    getSelectedOperator = (operator, operators) => {
        let selectedOperator = operator || null;

        if (!selectedOperator && operators && operators.length) {
            selectedOperator = operators[0] || null;
        }

        return selectedOperator;
    };

    /**
     * Render Report Filter Component
     * @return {XML}
     */
    render() {
        const {isBasic, filterId, fieldLabel, operators} = this.props;
        const {selectedOperator, value} = this.state;

        return (
            <div className='string-filter'>
                {!isBasic && (
                    <div className='field field--full field--dropdown'>
                        <label className='field_label'>Operator</label>
                        {operators && (
                            <Select
                                options={operators}
                                value={selectedOperator}
                                onSelect={this.handleOperatorChange}
                                clearable={false}
                                searchable={false}
                            />
                        )}
                    </div>
                )}
                <div className='field field--full field--text'>
                    <label className='field_label' htmlFor={`string-filter--${filterId}`}>
                        {isBasic ? `${fieldLabel} ${selectedOperator.label}` : 'Value'}
                    </label>
                    <input
                        ref={this.inputReference}
                        className='field_input'
                        type='text'
                        id={`string-filter--${filterId}`}
                        name={`stringFilterValue--${filterId}`}
                        placeholder='Enter keyword'
                        maxLength={32}
                        value={value}
                        onChange={this.handleInputChange}
                    />
                </div>
            </div>
        );
    }
}

StringFilter.propTypes = {
    isBasic: PropTypes.bool,
    filterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    fieldLabel: PropTypes.string,
    value: PropTypes.string,
    operators: PropTypes.array.isRequired,
    operator: PropTypes.object,
    onChange: PropTypes.func.isRequired
};

export default StringFilter;
