import React, {Component} from 'react';
import { Modal } from '../../../../../../../components'
import {TextInput} from '../../../../../../../components/shared';

/**
 * Save/Update Search Modal Component
 */
class SaveSearch extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            value: (props.search && props.search.name) || '',
            error: false
        };
    }

    /**
     * On change field value
     * @param event
     */
    onChange = (event) => {
        const value = event.currentTarget && event.currentTarget.value;

        this.setState({value});
        this.validate(value);
    };

    /**
     * Validation
     * @return {boolean}
     */
    validate = (value) => {
        this.setState({
            error: !(value.trim() && value.trim().length)
        });
    };

    /**
     * On close modal
     */
    onClose = () => {
        const {search, onClose} = this.props;

        this.setState({
            value: (search && search.name) || '',
            error: false
        });

        onClose();
    };

    /**
     * On save button click
     */
    onSaveClick = () => {
        const {search, onSave} = this.props;
        const params = search ? search : {};
        const {value} = this.state;

        this.validate(value);

        if (value && value.length) {
            onSave({...params, name: value});
        }
    };

    /**
     * Render Save Search Modal
     * @returns {*}
     */
    render() {
        const {visible} = this.props;
        const {value, error} = this.state;

        return (
            <Modal
                title='Save Your Search'
                visible={visible}
                onClose={this.onClose}
                footerButtons={[
                    {
                        ui: 'shaded',
                        label: 'Cancel',
                        onClick: this.onClose
                    },
                    {
                        ui: 'citrus',
                        label: 'Save',
                        onClick: this.onSaveClick
                    }
                ]}>
                <div className='field field--text field--full'>
                    <label className='field_label'>Search Title <span className='required'>(required)</span></label>
                    <TextInput
                        id='searchTitle'
                        value={value}
                        error={error}
                        placeholder='New York Targets'
                        onChange={this.onChange}
                        autoFocus={true}/>
                </div>
            </Modal>
        );
    }
}

export default SaveSearch;
