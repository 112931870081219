import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_MOVERS = gql`
  query Mover(
    $entityId: String!
    $entityType: String!
    $moverType: String!
    $currencyCode: String
  ) {
    mover(
      entityId: $entityId
      entityType: $entityType
      moverType: $moverType
      currencyCode: $currencyCode
    ) {
      items {
        tickerId
        securityId
        securityName
        marketValueChange
      }
      sum(field: "marketValueChange")
    }
  }`

const GET_SECURITY_MOVERS = gql`
  query Mover(
    $entityId: String!
    $entityType: String!
    $moverType: String!
    $quarterDate: String!
    $source: String!
  ) {
    mover(
      entityId: $entityId
      entityType: $entityType
      moverType: $moverType
      quarterDate: $quarterDate
      source: $source
    ) {
      items {
        entityId
        entityName
        change
      }
    }
  }`

/**
 * Movers
 * @param options
 */
export function useMoverQuery (options) {
  return useQuery(GET_MOVERS, options)
}

/**
 * Security movers
 * @param options
 */
export function useSecurityMoverQuery (options) {
  return useQuery(GET_SECURITY_MOVERS, options)
}
