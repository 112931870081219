import {
  REPORT_BUILDER_PEER_REQUEST,
  REPORT_BUILDER_PEER_FAILURE,
  REPORT_BUILDER_PEER_SUCCESS,
  REPORT_BUILDER_CLEAR_PEERS,
  REPORT_BUILDER_REMOVE_PEER,
  REPORT_BUILDER_PEER_SEARCH_REQUEST,
  REPORT_BUILDER_PEER_SEARCH_SUCCESS,
  GET_REPORT_DATA_ITEM_SUCCESS,
  REPORT_BUILDER_PEER_SEARCH_FAILURE,
  REPORT_BUILDER_FOUND_PEERS_RESET,
  REPORT_BUILDER_SET_PEERS,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED,
  REPORT_BUILDER_ADD_PEER
} from '../../actions'

const initial = {
  peers: [],
  foundPeers: [],
  status: IDLE
}

const reportPeerConfig = (state = initial, action) => {
  switch (action.type) {
    case REPORT_BUILDER_PEER_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_REPORT_DATA_ITEM_SUCCESS:
      return {
        ...state,
        peers: action.payload.peers || [],
        status: FETCHED
      }
    case REPORT_BUILDER_PEER_SUCCESS:
      return {
        ...state,
        peers: action.payload,
        status: FETCHED
      }
    case REPORT_BUILDER_PEER_FAILURE:
      return {
        ...state,
        status: FAILED
      }

    case REPORT_BUILDER_PEER_SEARCH_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case REPORT_BUILDER_PEER_SEARCH_SUCCESS:
      return {
        ...state,
        foundPeers: (action.payload || []).map((item) => item._source),
        status: FETCHED
      }
    case REPORT_BUILDER_PEER_SEARCH_FAILURE:
      return {
        ...state,
        status: FAILED
      }
    case REPORT_BUILDER_CLEAR_PEERS:
      return {
        ...state,
        peers: []
      }
    case REPORT_BUILDER_REMOVE_PEER:
      return {
        ...state,
        peers: state.peers.filter((peer) => {
          return peer._security !== action.payload
        })
      }
    case REPORT_BUILDER_FOUND_PEERS_RESET:
      return {
        ...state,
        foundPeers: []
      }
    case REPORT_BUILDER_ADD_PEER:
      return {
        ...state,
        peers: state.peers.concat(action.payload)
      }
    case REPORT_BUILDER_SET_PEERS:
      return {
        ...state,
        peers: action.payload
      }
    default:
      return state
  }
}

export default reportPeerConfig
