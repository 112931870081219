import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import { popFlyoutDetailsItem } from '../../../../../../../actions/targeting'
import { Button } from '../../../../../../../components'
import { THEMES } from '../../../../../../../utils/ui'

import './flyoutTitle.container.css'

const propTypes = {
  title: PropTypes.string.isRequired
}

const defaultProps = {
  title: ''
}

/**
 * FlyoutTitle Container
 */
function FlyoutTitle (props) {
  const { title, flyoutDetailsHistory, popFlyoutDetailsItem } = props
  const showBackButton = flyoutDetailsHistory.length > 1

  return (
    <div className='targeting-banner-title'>
      {showBackButton && (
        <Button className='button--targeting-title'
                theme={THEMES.Q4_BLUE_WHITE}
                icon='q4i-arrow-left-4pt'
                label='Back'
                onClick={popFlyoutDetailsItem}
        />
      )}
      <span className='targeting-banner-title_text'>{title}</span>
    </div>
  )
}

FlyoutTitle.propTypes = propTypes
FlyoutTitle.defaultProps = defaultProps

const mapStateToProps = (state) => {
  return {
    flyoutDetailsHistory: state.targeting.flyout.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  popFlyoutDetailsItem: bindActionCreators(popFlyoutDetailsItem, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FlyoutTitle))
