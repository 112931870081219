export const EMAIL_APP_NAME = 'CRM_EMAIL_APP'

export const IFRAME_MESSAGE_FROM_CHILD = {
  INIT: 'init',
  MOUNTED: 'mounted',
  UNMOUNTED: 'unmounted',
  REDIRECT: 'redirect',
  REFRESH: 'refresh',
  GO_TO_PREVIOUS_PAGE: 'goToPreviousPage',
  SHOW_SUCCESS_TOAST: 'showSuccessToast',
  FINISHED_CONNECT: 'finishedConnect',
  UPDATE_CHILD_PATH_ON_PARENT: 'updateChildPathOnParent',
  FINISHED_DISCONNECT: 'finishedDisconnect',
}

export const IFRAME_MESSAGE_TO_CHILD = {
  UPDATE_AUTH0_USER: 'updateAuth0User',
  UPDATE_RECIPIENTS: 'updateRecipients',
  UPDATE_DESKTOP_USER_ID: 'updateDesktopUserId',
  UPDATE_PARENT_PATH_ON_CHILD: 'updateParentPathOnChild',
  UPDATE_DESKTOP_FF: 'updateDesktopFF',
}
