import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { AgGrid, Spinner } from '../../../../components'
import {
  addressBookBulkActions,
  CORPORATE_PARTICIPANT,
  ENTITY_CONTACT,
  getDefaultColumnDef,
  preventDefaultRowClick, THEMES
} from '../../../../utils'

import CustomCellRender from './cell/cell.component'

/**
 * Address Book Grid
 */
class AddressBookGrid extends PureComponent {
  /**
   * constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      columns: [
        {
          ...getDefaultColumnDef({ columnIndex: 0, type: 'flexed', isAlternating: false }),
          field: 'full_name',
          headerName: 'Name',
          minWidth: 350,
          pinned: 'left',
          lockPinned: true,
          headerCheckboxSelection: true,
          checkboxSelection: true
        },
        // regular columns with alternating styles
        {
          ...getDefaultColumnDef({ columnIndex: 2, type: 'text' }),
          field: 'jobs.institution_name',
          headerName: 'Institution',
          minWidth: 240,
          maxWidth: 280
        },
        {
          ...getDefaultColumnDef({ columnIndex: 3 }),
          field: 'type',
          headerName: 'Contact Type',
          minWidth: 170,
          maxWidth: 180
        },
        {
          ...getDefaultColumnDef({ columnIndex: 4, type: 'text' }),
          field: 'jobs.city',
          headerName: 'Location',
          minWidth: 180,
          maxWidth: 200
        },
        {
          ...getDefaultColumnDef({ columnIndex: 5, type: 'text' }),
          field: 'jobs.functions',
          headerName: 'Functions',
          minWidth: 220,
          maxWidth: 260,
          sortable: false
        },
        {
          ...getDefaultColumnDef({ columnIndex: 6, type: 'text' }),
          field: 'phone',
          headerName: 'Phone',
          minWidth: 150,
          maxWidth: 160,
          sortable: false
        },
        {
          ...getDefaultColumnDef({ columnIndex: 7, type: 'icon' }),
          field: 'email',
          headerName: 'Email',
          minWidth: 80,
          maxWidth: 80,
          sortable: false
        },
        {
          ...getDefaultColumnDef({ columnIndex: 8, type: 'date' }),
          field: 'activity_date',
          headerName: 'Last Activity',
          minWidth: 140,
          maxWidth: 140,
          sortable: false
        },
      ]
    }
  }

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  handleGridReady = (grid) => {
    setTimeout(() => this.agGrid = grid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => this.agGrid && this.agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle AgGrid Column Sort
   * @param grid
   */
  handleSortChange = (grid) => {
    const { onParamsChange } = this.props
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel && !sortModel.length) {
      return
    }

    onParamsChange && onParamsChange({
      sort: {
        property: sortModel[0].colId,
        direction: sortModel[0].sort
      },
      page: 1
    })
  }

  /**
   * Handle row click event
   * @param _id {String} - contact id
   */
  handleRowClicked = ({ _id, reference }) => {
    const { history } = this.props
    const type = reference && reference.type
    const link = (type === CORPORATE_PARTICIPANT) ? 'corporate-participant' : (type === ENTITY_CONTACT) ? 'contact' : null

    if (_id && link && history) {
      history.push(`/${link}/${_id}`)
    }
  }

  /**
   * Handle bulk actions based on action id
   * @param actionId
   */
  handleBulkAction (actionId) {
    if (!this.agGrid || !actionId) {
      return
    }

    const { onBulkAction } = this.props
    const selectedRows = this.agGrid.api.getSelectedRows()

    if (!selectedRows || !selectedRows.length) {
      return
    }

    onBulkAction(actionId, selectedRows)
    this.agGrid.api.redrawRows()
  }

  /**
   * Renders CustomCell Component
   * @param props
   * @returns {*}
   */
  renderCustomCell = (props) => {
    return (
      <CustomCellRender
        {...props}
        handleEmailClick={this.props.handleEmailClick}
        // onUtilityItemClick={this.onUtilityItemClick}
      />
    )
  }

  /**
   * Returns grid columns
   */
  getColumnsDefinition = () => {
    return this.state.columns.map(({ type, ...column }, idx) => ({
      ...getDefaultColumnDef({ columnIndex: idx, type }),
      ...column
    }))
  }

  render () {
    const { loading, isGridReady, pageParams: { page, limit }, data, total, onParamsChange } = this.props

    return (
      <div className='address-book-page_grid-table'>
        {loading && <Spinner mask theme={THEMES.RAIN} />}
        {isGridReady && (<AgGrid
          className='address-book-grid'
          domLayout='autoHeight'
          sizeToFit={true}

          // suppress configs
          suppressMovableColumns={true}
          suppressContextMenu={true}

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            sortable: true,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={this.getColumnsDefinition()}
          rowData={data}

          // bulk actions
          bulkActions={[{
            id: addressBookBulkActions.DELETE_CONTACTS,
            className: 'delete',
            icon: 'q4i-trashbin-4pt',
            onSelect: () => this.handleBulkAction(addressBookBulkActions.DELETE_CONTACTS)
          }, {
            id: addressBookBulkActions.EMAIL,
            className: 'email',
            icon: 'q4i-mail-4pt',
            onSelect: () => this.handleBulkAction(addressBookBulkActions.EMAIL)
          }, {
            id: addressBookBulkActions.ADD_TO_LIST,
            className: 'add-to-list',
            icon: 'q4i-add-to-list-4pt',
            onSelect: () => this.handleBulkAction(addressBookBulkActions.ADD_TO_LIST)
          }, {
            id: addressBookBulkActions.CREATE_ACTIVITY,
            className: 'create-activity',
            icon: 'q4i-activity-4pt',
            onSelect: () => this.handleBulkAction(addressBookBulkActions.CREATE_ACTIVITY)
          }, {
            id: addressBookBulkActions.ADD_TO_BRIEFING_BOOK,
            className: 'add-to-briefing-book',
            icon: 'q4i-book-4pt',
            onSelect: () => this.handleBulkAction(addressBookBulkActions.ADD_TO_BRIEFING_BOOK)
          }]}

          // pagination
          pagination
          paginationProps={{
            pageSizeId: 'address-book-grid',
            forcePage: page,
            initialPageSize: limit,
            showPageSizeSelection: true,
            total,
            onPageChange: ({ selected }) => onParamsChange({ page: selected }),
            onPageSizeChange: ({ selected }) => onParamsChange({ page: 1, limit: selected })
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: this.renderCustomCell
          }}

          // event listeners
          onGridReady={this.handleGridReady}
          onGridSizeChanged={this.handleGridResize}
          onSortChanged={this.handleSortChange}
          onRowClicked={preventDefaultRowClick(
            this.handleRowClicked,
            ['cell_icon--utility', 'cell--email', 'cell_icon--email']
          )}

          isPinned={data && data.length} />)}
      </div>
    )
  }
}

AddressBookGrid.propTypes = {
  loading: PropTypes.bool,
  isGridReady: PropTypes.bool.isRequired,
  pageParams: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  onParamsChange: PropTypes.func.isRequired,
  onBulkAction: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

AddressBookGrid.defaultProps = {
  data: [],
  total: 0
}

export default AddressBookGrid
