import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { ENTITY_TYPE, OWNERSHIP_TYPE } from '../../../utils'

const GET_INST_INDUSTRY_ANALYSIS = gql`
  query InstitutionIndustryAnalysis(
    $entityId: [String]!
    $source: String = "${OWNERSHIP_TYPE['13F']}"
    $sortBy: String
    $sortDir: String
    $currencyCode: String
    $limit: Int!
  ) {
    sector: instHoldingCurrent(
      institutionId: $entityId
      group: {
        by: "securitySector"
        operator: sum
      }
      source: $source
      sortBy: $sortBy
      sortDir: $sortDir
      currencyCode: $currencyCode
      limit: $limit
    ) {
      items {
        securitySector
        percentPortfolio
        marketValue  
        marketValueChange
      }
    }
    capGroup: instHoldingCurrent(
      institutionId: $entityId
      group: {
        by: "securityCapGroup"
        operator: sum
      }
      source: $source
      sortBy: $sortBy
      sortDir: $sortDir
      currencyCode: $currencyCode
      limit: $limit
    ) {
      items {
        securityCapGroup
        percentPortfolio
        marketValue  
        marketValueChange
      }
    }
    region: instHoldingCurrent(
      institutionId: $entityId
      group: {
        by: "securityRegion"
        operator: sum
      }
      source: $source
      sortBy: $sortBy
      sortDir: $sortDir
      currencyCode: $currencyCode
      limit: $limit
    ) {
      items {
        securityRegion
        percentPortfolio
        marketValue  
        marketValueChange
      }
    }
  }`

const GET_FUND_INDUSTRY_ANALYSIS = gql`
  query FundIndustryAnalysis(
    $entityId: [String]!
    $source: String = "${OWNERSHIP_TYPE['13F']}"
    $sortBy: String
    $sortDir: String
    $currencyCode: String
    $limit: Int!
  ) {
    sector: fundHoldingCurrent(
      fundId: $entityId
      group: {
        by: "securitySector"
        operator: sum
      }
      source: $source
      sortBy: $sortBy
      sortDir: $sortDir
      currencyCode: $currencyCode
      limit: $limit
    ) {
      items {
        securitySector
        percentPortfolio
        marketValue  
        marketValueChange
      }
    }
    capGroup: fundHoldingCurrent(
      fundId: $entityId
      group: {
        by: "securityCapGroup"
        operator: sum
      }
      source: $source
      sortBy: $sortBy
      sortDir: $sortDir
      currencyCode: $currencyCode
      limit: $limit
    ) {
      items {
        securityCapGroup
        percentPortfolio
        marketValue  
        marketValueChange
      }
    }
    region: fundHoldingCurrent(
      fundId: $entityId
      group: {
        by: "securityRegion"
        operator: sum
      }
      source: $source
      sortBy: $sortBy
      sortDir: $sortDir
      currencyCode: $currencyCode
      limit: $limit
    ) {
      items {
        securityRegion
        percentPortfolio
        marketValue  
        marketValueChange
      }
    }
  }`

/**
 * Industry Analysis
 * @param type
 * @param options
 */
export function useIndustryAnalysisQuery (type, options) {
  const query = (type === ENTITY_TYPE.FUND) ? GET_FUND_INDUSTRY_ANALYSIS : GET_INST_INDUSTRY_ANALYSIS
  return useQuery(query, options)
}
