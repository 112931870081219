import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// actions
import { useHoldingQuery } from '../../../hook'

// component
import TopHoldingGrid from './topHolding.component'

// utils
import { get } from 'lodash'
import { getLocalizedCurrency, isTradingTicker } from '../../../../../utils'

const propTypes = {
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  tickerId: PropTypes.string.isRequired,
  securityName: PropTypes.string.isRequired,
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

const defaultProps = {
  limit: 5
}

/**
 * Top Holdings
 * @param props
 */
function TopHolding (props) {
  const { entityId, entityType, tickerId, securityName, limit } = props

  const { data } = useHoldingQuery(entityType, {
    variables: {
      entityId,
      tickerId,
      currencyCode: getLocalizedCurrency(),
      limit: Number(limit) + 1 // add 1 to consider my security filter from top holdings
    }
  })

  const security = isTradingTicker(tickerId) ? get(data, 'security.items[0]') : {"securityName": securityName}

  return (
    <Grid container spacing={2} className='tearsheet_section'>
      <Grid item xs={12} className='tearsheet_section-title'>{`Top ${limit} Holdings`}</Grid>
      <Grid item xs={12} className='tearsheet_section-content'>
        <TopHoldingGrid
          securityName={securityName}
          security={security}
          holdings={get(data, 'holding.items')}
          limit={Number(limit)}
        />
      </Grid>
    </Grid>
  )
}

TopHolding.propTypes = propTypes
TopHolding.defaultProps = defaultProps

export default TopHolding
