import { mapInstitutionsResult } from '../../utils'
import {
  GET_CONTACT_EDIT_REQUEST,
  GET_CONTACT_EDIT_SUCCESS,
  GET_CONTACT_EDIT_FAILURE,
  GET_CONTACT_SUGGESTIONS_SUCCESS,
  GET_CORPORATE_PARTICIPANT_EDIT_REQUEST,
  GET_CORPORATE_PARTICIPANT_EDIT_SUCCESS,
  GET_CORPORATE_PARTICIPANT_EDIT_FAILURE,
  CREATE_CORPORATE_PARTICIPANT_REQUEST,
  CREATE_CORPORATE_PARTICIPANT_SUCCESS,
  CREATE_CORPORATE_PARTICIPANT_FAILURE,
  UPDATE_CORPORATE_PARTICIPANT_REQUEST,
  UPDATE_CORPORATE_PARTICIPANT_SUCCESS,
  UPDATE_CORPORATE_PARTICIPANT_FAILURE,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILURE,
  REMOVE_CONTACT_REQUEST,
  REMOVE_CONTACT_SUCCESS,
  REMOVE_CONTACT_FAILURE,
  SEARCH_INSTITUTION_SUCCESS,
  SEARCH_INSTITUTION_SUCCESS_GQL,
  DISMISS_ERROR,
  RESET_FORM,
  RESET_SUGGESTIONS,
  RESET_CONTACT_SUGGESTIONS,
  statusType
} from '../../actions'


const initial = {
  data: {},
  error: '',
  status: statusType.IDLE,
  searchSuggestions: []
}

const form = (state = initial, action) => {
  switch (action.type) {
    case CREATE_CONTACT_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        type: action.type,
        data: action.payload,
        status: statusType.SUCCESS
      }

    case CREATE_CONTACT_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case UPDATE_CONTACT_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        type: action.type,
        data: action.payload,
        status: statusType.SUCCESS
      }

    case UPDATE_CONTACT_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case CREATE_CORPORATE_PARTICIPANT_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case CREATE_CORPORATE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        type: action.type,
        data: action.payload,
        status: statusType.SUCCESS
      }

    case CREATE_CORPORATE_PARTICIPANT_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case UPDATE_CORPORATE_PARTICIPANT_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case UPDATE_CORPORATE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        type: action.type,
        data: action.payload,
        status: statusType.SUCCESS
      }

    case UPDATE_CORPORATE_PARTICIPANT_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case GET_CONTACT_EDIT_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_CONTACT_EDIT_SUCCESS:
      return {
        ...state,
        type: action.type,
        data: action.payload,
        status: statusType.SUCCESS
      }

    case GET_CONTACT_EDIT_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case GET_CORPORATE_PARTICIPANT_EDIT_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_CORPORATE_PARTICIPANT_EDIT_SUCCESS:
      return {
        ...state,
        type: action.type,
        data: action.payload,
        status: statusType.SUCCESS
      }

    case GET_CORPORATE_PARTICIPANT_EDIT_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case REMOVE_CONTACT_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case REMOVE_CONTACT_SUCCESS:
      return {
        ...state,
        status: statusType.SUCCESS
      }

    case REMOVE_CONTACT_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case SEARCH_INSTITUTION_SUCCESS:
      return {
        ...state,
        searchSuggestions: action.payload || []
      }
    case SEARCH_INSTITUTION_SUCCESS_GQL:
      return {
        ...state,
        searchSuggestions: mapInstitutionsResult(action.payload || [])
      }  

    case GET_CONTACT_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        contactSuggestions: action.payload || []
      }

    case RESET_CONTACT_SUGGESTIONS:
      return {
        ...state,
        contactSuggestions: []
      }

    case RESET_SUGGESTIONS:
      return {
        ...state,
        searchSuggestions: []
      }

    case DISMISS_ERROR:
      return {
        ...state,
        error: '',
        status: statusType.IDLE
      }

    case RESET_FORM:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default form
