import React from 'react'
import PropTypes from 'prop-types'
import { modalType } from '../../../actions/ui'

// component
import { ComboBox } from '../../index'
import { RadioButton } from '../../shared/form'
import EntitySearchRequestProfile from '../requestProfile/requestProfile.component'

// utils
import { ENTITY_TYPE, THEMES, getEntityLocationEDW, SWIFTYPE_TO_ENTITY_TYPE_MAP } from '../../../utils'
import { get, omitBy, isEmpty } from 'lodash'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

/**
 * Get suggestions for EDW Search
 * @param suggestions
 */
const getSuggestionsFromEdwSearch = (suggestions) => {
  return (suggestions || []).map((suggestion) => {
    const entityData = get(suggestion, 'entityConnection', {})
    let suggestionData = {}

    if (!entityData) return {}

    switch (SWIFTYPE_TO_ENTITY_TYPE_MAP[entityData.type]) {
      case INSTITUTION: {
        const entityId = get(entityData, 'entity.id', null)

        suggestionData = {
          entityId,
          entityType: INSTITUTION,
          entityConnection: omitBy({
            id: entityId,
            institutionName: entityData.institutionName || null
          }, isEmpty),
          labelHelper: {
            title: entityData.institutionName,
            detail: getEntityLocationEDW(get(entityData, 'entity.addressConnection.items'))
          }
        }
        break
      }
      case FUND: {
        const entityId = get(entityData, 'entity.id', null)

        suggestionData = {
          entityId,
          entityType: FUND,
          entityConnection: omitBy({
            id: entityId,
            fundName: entityData.fundName || null
          }, isEmpty),
          labelHelper: {
            title: entityData.fundName,
            detail: getEntityLocationEDW(get(entityData, 'entity.institutionConnection.items[0].addressConnection.items'))
          }
        }
        break
      }
      case CONTACT: {
        const institution = get(entityData, 'entity.jobs[0].title', null)
        const institutionId = get(entityData, 'entity.jobs[0].entityId', null)
        const entityId = get(entityData, 'entity.id', null)

        suggestionData = {
          entityId,
          entityType: CONTACT,
          entityConnection: omitBy({
            id: entityId,
            fullName: entityData.fullName || null,
            jobs: (entityData?.entity?.jobs || []).map((job) => ({
              title: job.title,
              institutionName: job.institutionName,
              entityId: job.entityId
            })),
            selectedInstitution: institutionId ? `${institutionId}:${entityId}` : null
          }, isEmpty),
          labelHelper: {
            isCustomContact: entityData?.entity?.source === 'q4desktop',
            title: `${entityData.fullName} ${institution || ''}`,
            detail: get(entityData, 'entity.jobs[0].institutionName', '')
          }
        }
        break
      }
      default:
        return null
    }

    return {
      label: (
        <span className='entity-search_suggestion'>
          <span className='entity-search_suggestion-content'>
            <div className='entity-search_suggestion-title'>{suggestionData.labelHelper.title || '-'}</div>
            <div className='entity-search_suggestion-detail'>{suggestionData.labelHelper.detail}</div>
          </span>
          {!!suggestionData.labelHelper.isCustomContact && (<i className='entity-search_suggestion-icon q4i-custom-4pt' />)}
        </span>
      ),
      value: suggestionData.entityId,
      data: suggestionData
    }
  })
}

/**
 * Selected Item Component
 */
const SelectedItem = ({ item, onContactInstitutionSelect, isRelativeInstitution }) => {
  const { entityId, entityType, entityConnection } = item || {}
  const { fullName, fundName, institutionName, jobs } = (entityConnection || {})
  const isCustomJob = !entityConnection.selectedInstitution && get(jobs, '[0].entityId') === ''

  const buttons = !isRelativeInstitution
    ? (jobs || []).map((job) => {
        const id = `${job.entityId}:${entityId}`
        return (
          <RadioButton
            name='entity-search-contact'
            key={id}
            id={id}
            value={id}
            label={`${job.title ? job.title + ', ' : ''}${job.institutionName}`}
            theme={isCustomJob ? THEMES.TRANSPARENT : null}
            isSelected={entityConnection.selectedInstitution === id || isCustomJob}
            onChange={() => onContactInstitutionSelect(item, job.entityId)}
          />
        )
      })
    : []

  if (buttons.length && !isCustomJob) {
    buttons.push(
      <RadioButton
        name='entity-search-contact'
        key={entityId}
        id={entityId}
        label='Not Applicable'
        value=''
        isSelected={!entityConnection.selectedInstitution}
        onChange={() => onContactInstitutionSelect(item, null)}
      />
    )
  }

  return (
    <>
      <div className='entity-search_entity'>
        <i className={`entity-search_entity-icon entity-search_entity-icon--${entityType} q4i-${entityType}-4pt`} />
        <div className='entity-search_entity-title'>
          {fullName || fundName || institutionName}
        </div>
      </div>
      {!!buttons.length && (
        <div className='entity-search_entity-content'>{buttons}</div>
      )}
    </>
  )
}

/**
 * Handle Suggestion Select
 * @param props
 */
const handleChange = (props) => {
  const { option, onSuggestedItemSelect } = props
  const { data, type } = option || {}

  if (!data || (type && type === 'action')) {
    return
  }

  onSuggestedItemSelect(data)
}

/**
 * EntitySearchComboBox Component
 */
const EntitySearchComboBox = (props) => {
  const {
    dataId, controls, loading, error, isRelativeInstitution, suggestedValues, selectedValues,
    onControlsChange, onSuggestedItemSelect, onContactInstitutionSelect, onContactCreate, onRemove, openModal
  } = props

  const options = getSuggestionsFromEdwSearch(suggestedValues)

  const actions = [
    {
      dataId: `${dataId}RequestProfile`,
      type: 'action',
      theme: THEMES.CITRUS,
      icon: 'q4i-request-4pt',
      label: 'Request Profile',
      onClick: () => openModal({
        component: EntitySearchRequestProfile
      }),
      hide: controls.selectedTab === CONTACT
    },
    {
      dataId: `${dataId}CreateContact`,
      type: 'action',
      theme: THEMES.CITRUS,
      icon: 'q4i-custom-4pt',
      label: 'Create Contact',
      onClick: () => openModal({
        type: modalType.CONTACT_MODAL,
        props: {
          type: CONTACT,
          isTypeSelectable: false,
          onSaveSuccess: onContactCreate
        }
      }),
      hide: controls.selectedTab !== CONTACT
    }
  ].filter((action) => !action.hide)

  return (
    <ComboBox
      theme={THEMES.LIGHT}
      selectProps={{
        dataId: {
          inputId: `${dataId}Input`,
          menuId: `${dataId}Menu`
        },
        loading,
        error,
        size: 'thick',
        value: null,
        options: [].concat(options, actions),
        onInputChange: (search) => onControlsChange({ search }),
        onChange: (option) => handleChange({ option, onSuggestedItemSelect }),
        portal: true
      }}
      removableListProps={{
        dataId: `${dataId}List`,
        size: 'auto',
        items: (selectedValues || []).map((item) => ({ value: item.entityId, item })),
        customItemRender: (item) => SelectedItem({ item, onContactInstitutionSelect, isRelativeInstitution }),
        onRemove
      }}
    />
  )
}

const EntityPropTypes = PropTypes.shape({
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf([CONTACT, FUND, INSTITUTION]).isRequired,
  entityConnection: PropTypes.shape({
    fullName: PropTypes.string,
    fundName: PropTypes.string,
    institutionName: PropTypes.string,
    jobs: PropTypes.array,
    /**
     * Contact's Selected Institution (Optional).
     * Has the following format: `${institutionId}:${contactId}`
     */
    selectedInstitution: PropTypes.string
  })
})

EntitySearchComboBox.propTypes = {
  dataId: PropTypes.string,
  loading: PropTypes.bool,
  controls: PropTypes.object,
  isRelativeInstitution: PropTypes.bool,
  isEdwSearch: PropTypes.bool,
  suggestedValues: PropTypes.array,
  selectedValues: PropTypes.arrayOf(EntityPropTypes),
  onControlsChange: PropTypes.func.isRequired,
  onSuggestedItemSelect: PropTypes.func.isRequired,
  onContactInstitutionSelect: PropTypes.func.isRequired,
  onContactCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
}

EntitySearchComboBox.defaultProps = {
  loading: false,
  suggestedValues: [],
  selectedValues: []
}

export default EntitySearchComboBox
