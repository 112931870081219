import { useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

export function useDispatchAction (action, dispatch) {
  return useCallback((...arg) => !action ? null : dispatch(action(...arg)), [dispatch, action])
}

/**
 * @typedef { import('../store/store.doc').RootState } RootState
 *
 * @param {function(RootState):*} selector
 * @returns {*}
 */
export function useTypedSelector (selector, equal = shallowEqual) {
  return useSelector(selector, equal)
}
