import {
  FETCH_WIDGET_ESTIMATES_REQUEST,
  FETCH_WIDGET_ESTIMATES_SUCCESS,
  FETCH_WIDGET_ESTIMATES_ERROR,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../../actions/widget/estimates'

const initialState = {
  eps: {},
  sales: {},
  ebitda: {},
  sga: {},
  ffo: {},
  status: IDLE
}

const current = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WIDGET_ESTIMATES_REQUEST:
      return {
        ...state,
        [action.payload.metric]: {
          ...state[action.payload.metric],
          status: FETCHING
        },
        status: FETCHING
      }
    case FETCH_WIDGET_ESTIMATES_SUCCESS:
      return {
        ...state,
        [action.payload.metric]: {
          ...action.payload,
          status: FETCHED
        }
      }
    case FETCH_WIDGET_ESTIMATES_ERROR:
      return {
        ...state,
        status: FAILED
      }
    default:
      return state
  }
}

export default current
