import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import './dropdownOptionItem.component.css';
import SelectListItem from '../../selectList/selectListItem/selectListItem.component';

class DropdownOptionItem extends PureComponent {

    handleOptionClick = (option) => {
        this.props.onClick && this.props.onClick(option);
    };

    render() {
        const {
            children,
            isHighlighted,
            height,
            value,
            className,
            isAction
        } = this.props;

        return (
            <SelectListItem
                height={height}
                isHighlighted={isHighlighted}
                value={value}
                isAction={isAction}
                onClick={this.handleOptionClick}
                className={className}>
                {children}
            </SelectListItem>
        );
    }
}

DropdownOptionItem.propTypes = {
    value: PropTypes.any,
    isAction: PropTypes.bool,
    onClick: PropTypes.func
};

DropdownOptionItem.defaultProps = {
    isHighlighted: false,
    height: 40,
    isAction: false
};

export default DropdownOptionItem;