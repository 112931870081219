import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

/**
 * Entity Top Buys Action Types
 */
export const GET_ENTITY_TOP_BUYS_REQUEST = 'GET_ENTITY_TOP_BUYS_REQUEST'
export const GET_ENTITY_TOP_BUYS_SUCCESS = 'GET_ENTITY_TOP_BUYS_SUCCESS'
export const GET_ENTITY_TOP_BUYS_FAILURE = 'GET_ENTITY_TOP_BUYS_FAILURE'

export const RESET_ENTITY_TOP_BUYS = 'RESET_ENTITY_TOP_BUYS'

/**
 * Get Entity Top Buys
 * @param params
 * @param options.types - action types
 * @private
 */
const _getTopBuys = (params, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_ENTITY_TOP_BUYS_REQUEST,
      GET_ENTITY_TOP_BUYS_SUCCESS,
      GET_ENTITY_TOP_BUYS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/ownership/v2/${params.entityType}/${params.entityId}/buys`
  }
})

/**
 * Dispatch GET_ENTITY_TOP_BUYS_REQUEST
 */
export const getTopBuys = (params, options) => (dispatch) => {
  return dispatch(_getTopBuys(params, options))
}

/**
 * Dispatch RESET_ENTITY_TOP_BUYS
 */
export const resetTopBuys = () => (dispatch) => {
  return dispatch({ type: RESET_ENTITY_TOP_BUYS })
}
