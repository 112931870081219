import gql from 'graphql-tag'
import { get } from 'lodash'

import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { getActiveTicker } from '../../utils'
import client from '../../middleware/graphql.middleware'

/**
 * Stock Action Type Constants
 * @type {string}
 */
export const GET_STOCK_REQUEST = 'GET_STOCK_REQUEST'
export const GET_STOCK_SUCCESS = 'GET_STOCK_SUCCESS'
export const GET_STOCK_FAILURE = 'GET_STOCK_FAILURE'

export const GET_STOCK_HISTORICAL_REQUEST = 'GET_STOCK_HISTORICAL_REQUEST'
export const GET_STOCK_HISTORICAL_SUCCESS = 'GET_STOCK_HISTORICAL_SUCCESS'
export const GET_STOCK_HISTORICAL_FAILURE = 'GET_STOCK_HISTORICAL_FAILURE'

export const GET_STOCK_AVERAGE_VOLUME_REQUEST = 'GET_STOCK_AVERAGE_VOLUME_REQUEST'
export const GET_STOCK_AVERAGE_VOLUME_SUCCESS = 'GET_STOCK_AVERAGE_VOLUME_SUCCESS'
export const GET_STOCK_AVERAGE_VOLUME_FAILURE = 'GET_STOCK_AVERAGE_VOLUME_FAILURE'

export const RESET_STOCK = 'RESET_STOCK'

// GraphQL queries
const GET_STOCK = gql`
  query Stock( $tickerId: [String]! ) {
    stock: stock(tickerId: $tickerId) {
      items {
        tickerId
        index
        currency
        date
        high
        last
        lastClose
        lastCloseChange
        lastCloseChangePercent
        low
        open
        volume
      }
    }
  }`


/**
 * Get Stock Historical
 * @param params.securityId
 * @param params.startDate
 * @param params.endDate
 * @param options
 * @returns {{}}
 * @private
 */
const _getStockHistorical = (params = {}, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_STOCK_HISTORICAL_REQUEST,
      GET_STOCK_HISTORICAL_SUCCESS,
      GET_STOCK_HISTORICAL_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/stock/historical',
    payload: {
      securityId: params.securityId,
      startDate: params.startDate.toISOString(),
      endDate: params.endDate.toISOString(),
      page: 1,
      limit: 25
    }
  }
})

/**
 * Get Stock Average Volume
 */
const _getStockAverageVolume = (securityId) => ({
  [CALL_API]: {
    types: [
      GET_STOCK_AVERAGE_VOLUME_REQUEST,
      GET_STOCK_AVERAGE_VOLUME_SUCCESS,
      GET_STOCK_AVERAGE_VOLUME_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/stock/average-volume/${securityId}`
  }
})

/**
 * Dispatch GET_STOCK_HISTORICAL_REQUEST
 */
export const getStockHistorical = (params, options) => (dispatch) => {
  return dispatch(_getStockHistorical(params, options))
}

/**
 * Dispatch GET_STOCK_REQUEST
 */
export const getStockAverageVolume = (securityId) => (dispatch, getState) => {
  if (!securityId) {
    const { profile } = getState()
    const activeTicker = profile && getActiveTicker(profile.data)
    securityId = activeTicker && activeTicker._security
  }
  return securityId && dispatch(_getStockAverageVolume(securityId))
}

export const resetStock = () => (dispatch) => {
  return dispatch({ type: RESET_STOCK })
}

/**
 * Dispatch GET_STOCK_REQUEST
 * @param {*} tickerId
 */
export const getStock =  (tickerId) => (dispatch, getState) => {
  dispatch({ type: GET_STOCK_REQUEST })
  return client
    .query({
      query: GET_STOCK,
      variables: { tickerId },
      fetchPolicy: 'no-cache'
    })
    .then((data) => {
      const stock = get(data, 'data.stock.items[0]')
      dispatch({ payload: stock, type: GET_STOCK_SUCCESS })
    })
    .catch((error) => {
      dispatch({ error, type: GET_STOCK_FAILURE })
    })
}
