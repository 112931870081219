import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { capitalize, get } from 'lodash'

// utils
import { THEMES } from '../../../../utils/ui'
import { AGGREGATION_ENUM } from '../../../../utils/report/report.const'
import { getIsAggregationAvailable, getChartAxisLabel, getXAxisFieldLabel } from '../../../../utils/report'

// components
import Checkbox from '../../../shared/form/checkbox/checkbox.component'
import { Select } from '../../../../components'
import SeriesList from './seriesList/seriesList.component'

import './chartConfig.component.css'

/**
 * ReportChartDataConfig
 * @param chartType
 * @param titleConfig
 * @param xAxisConfig
 * @param aggregationConfig
 * @param seriesConfig
 * @constructor
 */
function ReportChartConfig ({ chartType, titleConfig, xAxisConfig, aggregationConfig, seriesConfig }) {
  const showSeriesConfig = get(xAxisConfig, 'xAxis._field')
  const showAggregationConfig = getIsAggregationAvailable(get(xAxisConfig, 'xAxis._field.type'))
  const axisOptions = (xAxisConfig.availableXAxisFields || []).map((field) => field && { label: getXAxisFieldLabel(field), value: field })

  return (
    <section className='report-chart-data-config'>
      <div className='report-chart-data-config_field'>
        <Checkbox
          key='report-chart-data-config--title'
          theme={THEMES.WHITE_STEEL}
          label='Show Title'
          name='showTitle'
          id='showTitle'
          isChecked={titleConfig.showTitle}
          onChange={titleConfig.onChange}
        />
      </div>
      <div className='report-chart-data-config_field'>
        <div className='report-chart-data-config_label'> {getChartAxisLabel(chartType)} </div>
        <Select
          theme={THEMES.WHITE}
          placeholder='Select'
          value={xAxisConfig.xAxis && xAxisConfig.xAxis._field && { label: getXAxisFieldLabel(xAxisConfig.xAxis._field), value: xAxisConfig.xAxis._field }}
          options={axisOptions}
          noOptionsMessage='No Data Available'
          onChange={(option) => option && option.value && xAxisConfig.onChange(option.value)}
          searchable={false}
          clearable={false}
        />
      </div>
      {showAggregationConfig && (
        <div className='report-chart-data-config_field'>
          <div className='report-chart-data-config_label'>Aggregate</div>
          <Select
            theme={THEMES.WHITE}
            value={aggregationConfig && aggregationConfig.aggregationModel && { label: capitalize(aggregationConfig.aggregationModel), value: aggregationConfig.aggregationModel }}
            options={(AGGREGATION_ENUM || []).map((option) => option && { label: capitalize(option), value: option })}
            noOptionsMessage='No Data Available'
            onChange={(option) => option && option.value && aggregationConfig.onChange(option.value)}
            searchable={false}
            clearable={false}
          />
        </div>
      )}
      {showSeriesConfig && (
        <div className='report-chart-data-config_field report-chart-data-config_field--series'>
          <div className='report-chart-data-config_label'> Series</div>
          <SeriesList {...seriesConfig} />
        </div>
      )}
    </section>
  )
}

ReportChartConfig.propTypes = {
  chartType: PropTypes.string,
  titleConfig: PropTypes.shape({
    showTitle: PropTypes.bool,
    onChange: PropTypes.func.isRequired
  }),
  xAxisConfig: PropTypes.shape({
    xAxis: PropTypes.shape({
      _field: PropTypes.object
    }),
    availableXAxisFields: PropTypes.array
  }),
  seriesConfig: PropTypes.shape({
    series: PropTypes.array,
    availablePeers: PropTypes.array,
    availableIndices: PropTypes.array,
    availableSeriesFields: PropTypes.array,
    onChange: PropTypes.func.isRequired
  }),
  aggregationConfig: PropTypes.shape({
    aggregationModel: PropTypes.oneOf(AGGREGATION_ENUM),
    onChange: PropTypes.func.isRequired
  })
}

ReportChartConfig.defaultProps = {
  titleConfig: {},
  xAxisConfig: {},
  seriesConfig: {},
  aggregationConfig: {}
}

export default memo(ReportChartConfig)
