/**
 * Determines if ai score is passive
 * @param style
 * @param aiScore
 * @param emptyPlaceholder
 * @return {string}
 */
export const isPassive = ({ style, aiScore, emptyPlaceholder = '-' }) => {
  const isPassive = ['index', 'quant'].indexOf(style && style.toLowerCase()) > -1
  return (aiScore || aiScore === 0) ? aiScore : isPassive ? 'Passive' : emptyPlaceholder
}
