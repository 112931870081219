import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { getFromXigniteToStandard } from '../../../utils/stock/stock.util'
import { getTranscriptLink, formatDate, getEventIcon, getTimeWithZone, getTimeZoneOffsetMs, isMidnight, isServiceEnabled, EVENT_TYPES, getLocalizedFormat } from '../../../utils'
import moment from 'moment-timezone'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderDarkThumb, renderLightThumb, renderTrackVertical } from '../../../resources/theme/q4.custom-scrollbar'
import { FETCHED, FETCHING, loadEvents, loadEventsGQL } from '../../../actions/widget/events/events.actions'
import { getEventModal } from '../../../actions/event/modal/eventModal.actions'
import { openModal, closeModal, modalType } from '../../../actions'
import { DetailList, Modal, Spinner, RouteLink } from '../../../components'
import WidgetError from '../../../components/widget/error/widgetError.component'
import './events.container.css'
import { EVENT_DOCUMENT_LABEL } from '../../../utils/calendar/calendar.const'

// This component using beta flag which switches between GraphQL and REST API  

const eventProps = {
  sortDirection: 'ASC',
  hasTranscript: false,
  type: [
    'earningsCall',
    'guidanceCall',
    'salesCall',
    'earningsRelease',
    'salesRelease',
    'conference',
    'presentation',
    'meeting',
    'other'
  ],
  page: 1,
  start: 0,
  limit: 100
}
const eventPropsGQL = {
  startDate: moment().utc().startOf('day'),
  endDate: moment().utc().startOf('month').add(3, 'month').endOf('day'),
  timezoneOffset: getTimeZoneOffsetMs(),
  page: 1,
  limit: 30,
  securityId: [],
  transcript: false,
  peers: true,
  eventType: []
}


class Events extends Component {

  constructor(props) {
    super(props)
    this.state = {
      inWatchlist: true,
      isModalOpen: false
    }
  }

  _fetchData = () => {
    const profile = this.props.profile

    const services = profile && profile.services
    const isSubscribed = isServiceEnabled('events', services || [])

    if (isSubscribed) {
      this.props.loadEventsGQL(eventPropsGQL)
    }
  }

  componentDidMount () {
    const { isEdit, eventsStatus } = this.props
    if (isEdit && eventsStatus === FETCHED) {
      return
    }

    const refreshInterval = this.props.options.refreshInterval
    if (refreshInterval) {
      this.timer = setInterval(this._fetchData.bind(this), refreshInterval)
    }

    this._fetchData()
  }

  componentWillUnmount () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  /**
   * When clicking on an event, ask sencha to open a modal
   * @param id
   */
  handleItemClick = (event) => {
    this.props.openModal({
      type: modalType.EVENT_DETAIL_MODAL,
      props: {
        event,
        onClose: this.props.closeModal
      }
    })
  }

  /**
   * Close news details Modal
   */
  handleCloseModal = () => {
    this.setState({
      isModalOpen: false
    })
  }

  /**
   * Get icon class based on event type
   * @param type
   * @param pt
   * @returns {*|string}
   */
  getEventTypeClass = (type, pt) => {
    const classMap = {
      'Earnings Call': `q4i-phone-${pt}`,
      'Guidance Call': `q4i-phone-${pt}`,
      'Sales and Revenue Call': `q4i-phone-${pt}`,
      'Earnings Release': `q4i-releases-${pt}`,
      'Sales and Revenue Release': `q4i-releases-${pt}`,
      'Conference': `q4i-map-marker-${pt}`,
      'Presentation': `q4i-map-marker-${pt}`,
      'Analyst, Investor and Shareholder Meeting': `q4i-map-marker-${pt}`,
      'Special Situation, M&A and Other': `q4i-map-marker-${pt}`
    }

    return classMap[type] || 'q4i-map-marker-4pt'
  }

  /**
   * Get Event's primary ticker
   * @param event
   * @returns {string}
   */
  getPrimaryTicker = (event) => {
    return (event && event.tickers && event.tickers.find((ticker) => ticker.primary)) || '-'
  }

  /**
   * Get detailed date of the event
   * @param event
   * @returns {*}
   */
  getEventDate = (event) => {
    let date
    const localFormat = getLocalizedFormat('dddd, MMMM DD, YYYY')

    if (event.event_type === 'Conference') {
      date = moment.utc(event.date).format(localFormat)
    } else {
      const day = moment.utc(event.date).format(localFormat)
      const time = moment(event.date).format('LT')
      date = (event.market_time === 'Specific Time' ? `${day} ${time}` : day)
    }

    return `${date}${(event.projected ? ' (projected)' : '')}`
  }
  /**
   * Render event modal phone number value
   * @param phone
   * @param passcode
   * @returns {XML}
   */
  renderEventModalPhoneValue = (phone, passcode) => {
    let value = phone

    if (passcode) {
      value = [
        <span key={`phone-number--${phone}`}>{phone}</span>,
        <span key={`phone-pass--${passcode}`}><i className='q4i-lock-4pt' />{passcode}</span>
      ]
    }

    return value
  }

  /**
   * Render event modal routeLink
   * @param href
   * @param text
   * @returns {XML}
   */
  renderEventModalLink = (href, text) => {
    return <RouteLink to={href} target='_blank'>{text}</RouteLink>
  }


  //------ Following functions dynamically chosen based on  GraphQL beta events flag --//
  /**
   * (GraphQL specific) Get Event Details
   * @param event
   * @returns {*}
   */
  getEventDetailsGQL = (event) => {
    if (!event) {
      return {}
    }
    const { eventDate,
      passcodeLiveInternational,
      passcodeLiveUS,
      phoneLiveInternational,
      phoneLiveUS,
      phoneReplayInternational,
      phoneReplayUS,
      transcriptUrl,
      webcastLiveUrl,
      webcastReplayUrl,
      webcastSlidesUrl } = event

    const information = [{
      icon: 'q4i-calendar-4pt',
      label: 'Date',
      value: formatDate(eventDate, 'dddd, MMMM DD, YYYY LT', undefined, true)
    }]

    return {
      information,
      audio: [
        phoneLiveUS ? {
          icon: 'q4i-phone-4pt',
          label: 'Live (United States)',
          value: this.renderEventModalPhoneValue(phoneLiveUS, passcodeLiveUS)
        } : null,
        phoneLiveInternational ? {
          icon: 'q4i-phone-4pt',
          label: 'Live (International)',
          value: this.renderEventModalPhoneValue(phoneLiveInternational, passcodeLiveInternational)
        } : null,
        phoneReplayUS ? {
          icon: 'q4i-phone-4pt',
          label: 'Replay (United States)',
          value: this.renderEventModalPhoneValue(phoneReplayUS)
        } : null,
        phoneReplayInternational ? {
          icon: 'q4i-phone-4pt',
          label: 'Replay (International)',
          value: this.renderEventModalPhoneValue(phoneReplayInternational)
        } : null
      ].filter((item) => item),
      documents: [
        webcastLiveUrl ? {
          icon: 'q4i-webcast-4pt',
          value: this.renderEventModalLink(webcastLiveUrl, EVENT_DOCUMENT_LABEL.WEBCAST_LIVE)
        } : null,
        webcastReplayUrl ? {
          icon: 'q4i-webcast-4pt',
          value: this.renderEventModalLink(webcastReplayUrl, EVENT_DOCUMENT_LABEL.WEBCAST_REPLAY)
        } : null,
        webcastSlidesUrl ? {
          icon: 'q4i-presentation-4pt',
          value: this.renderEventModalLink(webcastSlidesUrl, EVENT_DOCUMENT_LABEL.PRESENTATION)
        } : null,
        transcriptUrl ? {
          icon: 'q4i-transcript-4pt',
          value: this.renderEventModalLink(getTranscriptLink(transcriptUrl), EVENT_DOCUMENT_LABEL.TRANSCRIPT)
        } : null,
      ].filter((item) => item)
    }
  }

  /**
   * (REST API) Get Event Details
   * @param event
   * @returns {*}
   */
  getEventDetails = (event) => {
    if (!event.date) {
      return {}
    }

    const report = event.reports && event.reports[0]
    const information = [{
      icon: 'q4i-calendar-4pt',
      label: 'Date',
      value: this.getEventDate(event)
    }]

    if (!report) {
      return {
        information
      }
    }

    return {
      information,
      audio: [
        report.phone_live_us ? {
          icon: 'q4i-phone-4pt',
          label: 'Live (United States)',
          value: this.renderEventModalPhoneValue(report.phone_live_us, report.passcode_live_us)
        } : null,
        report.phone_live_int ? {
          icon: 'q4i-phone-4pt',
          label: 'Live (International)',
          value: this.renderEventModalPhoneValue(report.phone_live_int, report.passcode_live_int)
        } : null,
        report.phone_replay_us ? {
          icon: 'q4i-phone-4pt',
          label: 'Replay (United States)',
          value: this.renderEventModalPhoneValue(report.phone_replay_us, report.passcode_replay_us)
        } : null,
        report.phone_replay_int ? {
          icon: 'q4i-phone-4pt',
          label: 'Replay (International)',
          value: this.renderEventModalPhoneValue(report.phone_replay_int, report.passcode_replay_int)
        } : null
      ].filter((item) => item),
      documents: [
        report.webcast_live ? {
          icon: 'q4i-webcast-4pt',
          value: this.renderEventModalLink(report.webcast_live, EVENT_DOCUMENT_LABEL.WEBCAST_LIVE)
        } : null,
        report.webcast_replay ? {
          icon: 'q4i-webcast-4pt',
          value: this.renderEventModalLink(report.webcast_replay, EVENT_DOCUMENT_LABEL.WEBCAST_REPLAY)
        } : null,
        report.webcast_slides ? {
          icon: 'q4i-presentation-4pt',
          value: this.renderEventModalLink(report.webcast_slides, EVENT_DOCUMENT_LABEL.PRESENTATION)
        } : null,
        report.transcript_url ? {
          icon: 'q4i-transcript-4pt',
          value: this.renderEventModalLink(getTranscriptLink(report.transcript_url), EVENT_DOCUMENT_LABEL.TRANSCRIPT)
        } : null,
        report.report_url ? {
          icon: 'q4i-press-releases-4pt',
          value: this.renderEventModalLink(report.report_url, EVENT_DOCUMENT_LABEL.PRESS_RELEASE)
        } : null,
      ].filter((item) => item)
    }
  }

  /**
   * (REST API) Render Event Modal Subtitle
   * @param event
   * @returns {string}
   */
  renderEventModalSubtitle = (event) => {
    const primaryTicker = this.getPrimaryTicker(event)

    return (
      <span>
        {event.company_name ? `${event.company_name} ` : ''}
        {primaryTicker ? `${primaryTicker.symbol} ${getFromXigniteToStandard(primaryTicker.exchange)}` : ''}
      </span>
    )
  }

  /**
   * (GraphQL) Render Event Modal Subtitle
   * @param event
   * @returns {string}
   */
  renderEventModalSubtitleGQL = (event) => {
    if (!event) {
      return {}
    }
    const primaryTicker = { symbol: event.symbol, exchange: event.exchange }
    return (
      <span>
        {event.companyName ? `${event.companyName} ` : ''}
        {primaryTicker ? `${primaryTicker.symbol} ${getFromXigniteToStandard(primaryTicker.exchange)}` : ''}
      </span>
    )
  }

  /**
   * LEGACY - (RestAPI)Render Event Item 
   * @param event
   * @param myTicker
   * @returns {XML}
   */
  _renderEvent = (event, myTicker, index) => {
    if (!event) {
      return null
    }

    const primaryTicker = this.getPrimaryTicker(event)
    let isMyTicker = false

    if (myTicker) {
      isMyTicker = (myTicker.symbol === primaryTicker.symbol && myTicker.exchange === primaryTicker.exchange)
    }

    return (
      <div key={event._id + '-' + `${index}`}
        onClick={() => this.handleItemClick(event)}
        className={`events-widget_item ${isMyTicker ? 'events-widget_item--my-ticker' : ''} q4-fade-in`}>
        <div className='events-widget_type'>
          <i className={`events-widget_icon ${this.getEventTypeClass(event.event_type, '4pt')}`} />
        </div>
        <div className='events-widget_details'>
          <div
            className='events-widget_ticker'>{primaryTicker.symbol} {getFromXigniteToStandard(primaryTicker.exchange)}</div>
          <h3 className='events-widget_title'>{event.title}</h3>
          <span className='events-widget_time'>{this.getEventDate(event)}</span>
        </div>
      </div>
    )
  }

  /**
   * (GraphQL) Render Event Item
   * @param event
   * @param myTicker
   * @returns {XML}
   */
  renderEventGQL = (event, myTicker, index) => {
    if (!event) {
      return null
    }

    const primaryTicker = { symbol: event.symbol, exchange: event.exchange }
    const isZeroTime = isMidnight(event.eventDate, true)
    const eventTime = getTimeWithZone(event.eventDate)
    const isConference = ([EVENT_TYPES.CONFERENCES, EVENT_TYPES.COMPANY_CONFERENCE_PRESENTATIONS]).includes(event.eventTypeName)
    const hideTime = isZeroTime || isConference
    let isMyTicker = false

    if (myTicker) {
      isMyTicker = (myTicker.symbol === primaryTicker.symbol)
    }
    return (
      <div key={event.id + '-' + event.tickerId + '-' + `${index}`}
        onClick={() => this.handleItemClick(event)}
        className={`events-widget_item ${isMyTicker ? 'events-widget_item--my-ticker' : ''} q4-fade-in`}>
        <div className='events-widget_type'>
          <i className={`events-widget_icon ${getEventIcon(event.eventTypeName)}`} />
        </div>
        <div className='events-widget_details'>
          <div
            className='events-widget_ticker'>{primaryTicker.symbol} {getFromXigniteToStandard(primaryTicker.exchange)}</div>
          <h3 className='events-widget_title'>{event.eventTitle}</h3>
          <span className='events-widget_time'>
            {`${formatDate(event.eventDate, 'dddd, MMMM DD, YYYY', hideTime, true)}  ${hideTime ? '' : eventTime}`}
          </span>
        </div>
      </div>
    )
  }
  //------------//

  render () {
    const { profile, theme, fetched, events, eventsStatus, eventModal, eventModalStatus, history } = this.props

    const { isModalOpen } = this.state

    const isLoading = eventsStatus === FETCHING
    const myTicker = (fetched) ? profile._organization.tickers.find((t) => t.primary) : null
    const eventModalDetails = this.getEventDetails(eventModal)

    const renderThumb = theme === 'dark' ? renderLightThumb : renderDarkThumb

    let labelWidth = 50
    if (!isEmpty(eventModalDetails.audio)) {
      labelWidth = 135
    }

    if (!isLoading && (isEmpty(events))) {
      return (
        <WidgetError
          theme={theme}
          header={'Peer Events'}
          message={'There are no events scheduled for you, or your peers within the next 3 months.'}
        />
      )
    }

    const eventsPagePath = '/event'

    return (
      <div className={`events-widget events-widget--${theme}`}>
        <header className='events-widget_header'>
          <h2 className='events-widget_heading'
            onClick={() => history.push(eventsPagePath)}>Peer Events</h2>
        </header>
        <section className='events-widget_items'>
          {(isEmpty(events)) ? <Spinner /> : (
            <Scrollbars
              className='react-scrollbar'
              autoHide
              hideTracksWhenNotNeeded
              renderThumbVertical={renderThumb}
              renderTrackVertical={renderTrackVertical}>
              {(events || []).map((event, index) => this.renderEventGQL(event, myTicker, index))}
            </Scrollbars>
          )}
        </section>
        <footer className='events-widget_footer'>
          <RouteLink className='events-widget_all-link' to={eventsPagePath}>View All</RouteLink>
        </footer>

        <Modal
          className='modal-root--events-widget'
          visible={isModalOpen}
          loading={eventModalStatus === FETCHING}
          scrollable={true}
          containerMaxWidth={'md'}
          contentPadding='20px'
          contentBackground={true}
          badge={this.getEventTypeClass(eventModal.event_type, '2pt')}
          title={eventModal.title || 'Event Details'}
          subtitle={this.renderEventModalSubtitle(eventModal)}
          onClose={this.handleCloseModal}
          spinnerProps={{
            maskOpacity: 1
          }}
        >
          {eventModalDetails.information && eventModalDetails.information.length ? (
            <DetailList title={'Information'} items={eventModalDetails.information}
              labelWidth={labelWidth} />
          ) : null}
          {!isEmpty(eventModalDetails.audio) ? (
            <DetailList title={'Audio'} items={eventModalDetails.audio} labelWidth={labelWidth} />
          ) : null}
          {!isEmpty(eventModalDetails.documents) ? (
            <DetailList title={'Documents'} items={eventModalDetails.documents} labelWidth={labelWidth} />
          ) : null}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const events = state.widget.events
  const eventModal = state.event.modal
  const profile = state.shared.profile
  const fetched = (profile.status === FETCHED && events.status === FETCHED)

  return {
    fetched,
    profile,
    eventModal: eventModal.data,
    eventModalStatus: eventModal.status,
    events: events.events,
    eventsStatus: events.status,
    theme: state.dashboard.dashboardState.theme
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadEventsGQL: bindActionCreators(loadEventsGQL, dispatch),
  loadEvents: bindActionCreators(loadEvents, dispatch),
  getEventModal: bindActionCreators(getEventModal, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Events))
