import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { get, omitBy, isNull } from 'lodash'

// actions
import { searchEntityClear, openModal, statusType } from '../../actions'

// components
import { Pagination } from '../../components'
import EntitySearchControlPanel from './controlPanel/controlPanel.component'
import EntitySearchComboBox from './comboBox/comboBox.component'

// hooks
import { useDispatchAction, usePrevious, useTypedSelector } from '../../hook'
import { useAdvancedSearchQuery } from './hook/advancedSearchQuery.hook'

// utils
import { debounce, ENTITY_TYPE, getActiveTicker } from '../../utils'

import './entitySearch.container.scss'
import { launchDarklyHelper, LD_FEATURE_FLAGS } from '../../services/launchDarkly.service'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

/**
 * Entity Search Container
 * @param {EntitySearchProps} props
 * @returns {JSX.Element}
 */
function EntitySearch (props) {
  const { dataId, error, label, required, isRelativeSearch, isRelativeInstitution, onChange } = props

  const searchEntityState = useTypedSelector((rootState) => rootState.searchEntity || [])
  const profile = useTypedSelector((rootState) => rootState.profile?.data)

  const dispatch = useDispatch()
  const handleSearchEntityClear = useDispatchAction(searchEntityClear, dispatch)
  const handleOpenModal = useDispatchAction(openModal, dispatch)

  const [getSearchResults, { loading: gqlSearchLoading, data: gqlSearchData }] = useAdvancedSearchQuery()

  const initialState = {
    controls: {
      search: ''
    },
    entities: props.entities || [],
    page: 1,
    limit: 10
  }

  const [state, setState] = useState(initialState)

  const [searchData, setSearchData] = useState(null)

  const savedState = usePrevious(state)

  const ticker = getActiveTicker(profile)

  useEffect(() => {
    const { entities } = state
    const institution = (entities || []).some(({ entityType }) => entityType === INSTITUTION)

    setState((prevState) => ({
      ...prevState,
      controls: {
        ...prevState.controls,
        isRelativeSearch,
        selectedTab: (isRelativeInstitution && institution) ? FUND : INSTITUTION
      },
      isInstitution: institution
    }))
  }, [])

  useEffect(() => {
    const { entities } = state

    if (savedState?.entities !== entities) {
      const institution = (entities || []).some(({ entityType }) => entityType === INSTITUTION)

      if (isRelativeInstitution) {
        setState((prevState) => ({
          ...prevState,
          controls: {
            ...prevState.controls,
            selectedTab: institution && (prevState.controls.selectedTab === INSTITUTION) ? FUND : prevState.controls.selectedTab
          },
          isInstitution: institution
        }))
      }
      onChange(entities)
    }
  }, [state.entities, state.controls, isRelativeInstitution])

  useEffect(() => {
    if (gqlSearchData) {
      setSearchData(gqlSearchData)
    }
  }, [gqlSearchData])

  useEffect(() => {
    if (!state.controls.search) return
    fetchEntities()
  }, [state.controls.search])

  /**
   * Dispatches an action to fetch suggested entities
   */
  const fetchEntities = (query = getQueryParams()) => {
    if (!query || !query.query) {
      setSearchData(null)
      return
    }
    debouncedHandleGqlSearch(query)
  }

  /**
   * Returns queryParams for entity search action
   */
  const getQueryParams = () => {
    const queryParams = {}
    const { selectedTab, search, isRelativeSearch } = state.controls
    const { entities, controls } = state

    if (selectedTab) {
      queryParams.type = selectedTab
    }

    if (search) {
      queryParams.query = search
    }

    if (isRelativeSearch && controls.selectedTab !== INSTITUTION && entities.length) {
      queryParams.entity = JSON.stringify(
        (entities || []).map((entity) => ({
          entityType: entity.entityType,
          entityId: entity.entityId,
          institutionId: (entity.entityType === FUND)
            ? get(entity, 'entityConnection.institutionId', null)
            : null // todo: send contact institutionId?
        }))
      )
    }

    return queryParams
  }

  /**
   * Query the Advanced Search GraphQL Node
   * @param {*} query
   */
  const handleGqlSearch = (query) => {
    // TODO: To figure out a better way to map swifttype entity type to entity type in this component
    let entity
    switch (query.type) {
      case INSTITUTION:
        entity = 'institutions'
        break
      case FUND:
        entity = 'funds'
        break
      case CONTACT:
        entity = 'contacts'
        break
      default:
        break
    }

    const filter = omitBy({
      relevantReferences: query.entity ? JSON.parse(query.entity).map(_entity => _entity.entityId) : null
    }, isNull)

    getSearchResults({
      variables: {
        query: query.query,
        entity: [entity],
        page: 1,
        limit: 10,
        filter,
        isContactSearch: query.type === CONTACT,
        isFundSearch: query.type === FUND,
        isInstitutionSearch: query.type === INSTITUTION,
        tickerId: ticker.q4_ticker_id,
        securityId: ticker.q4_entity_id,
        useElasticSearch: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)
      }
    })
  }

  /**
   * Returns the debounced handleGqlSearch
   */
  const debouncedHandleGqlSearch = useCallback(debounce(handleGqlSearch), [])

  /**
   * Returns search results from apollo gql
   * @return {any[]}
   */
  const getSuggestedValues = () => {
    return get(searchData, 'advancedSearch.items', [])
  }

  /**
   * Filters suggested entities based on changes in controls state params
   * @param value
   */
  const onControlsChange = (value) => {
    // reset suggested items
    handleSearchEntityClear()
    setSearchData(null)

    setState((prevState) => ({
      ...prevState,
      controls: { ...prevState.controls, ...value }
    }))
  }

  /**
   * Adds new entity to the list of selected entities
   * @param option
   * @param insertPosition
   */
  const onSuggestedItemSelect = (option, insertPosition = 0) => {
    const { entities = [] } = state
    const { entityId, entityType, entityConnection } = option || {}
    const isInstitution = isRelativeInstitution ? entities.some(({ entityType }) => entityType === INSTITUTION) : false
    if (entities.find((entity) => entity.entityId === entityId)) {
      return
    }

    // reset suggested items
    handleSearchEntityClear()
    setSearchData(null)

    setState((prevState) => ({
      ...prevState,
      entities: insertPosition ? [...prevState.entities.slice(0, insertPosition), option, ...prevState.entities.slice(insertPosition)] : [option, ...prevState.entities],
      controls: { ...prevState.controls, search: '' }
    }))

    if (entityType === CONTACT && entityConnection.selectedInstitution && !isInstitution) {
      const institution = buildInstitutionEntityFromContact(option, entityConnection.selectedInstitution)
      // insert institution record next to contact
      institution && onSuggestedItemSelect(institution, 1)
    }
  }

  /**
   * Handle updates to contact's associated institution. If contact's institution is not in the
   * list of selected entities, this function will automatically add institution right after the contact to which
   * this institution belongs.
   * @param contact
   * @param jobId
   */
  const onContactInstitutionSelect = (contact, jobId = null) => {
    const { entityId, entityConnection } = contact
    // modify contact entity to save selected institution
    const { entities } = state
    const entityIdx = (entities || []).findIndex((entity) => entity.entityId === entityId)
    entityConnection.selectedInstitution = jobId ? `${jobId}:${entityId}` : null
    entities[entityIdx] = contact
    // update array reference to trigger re-rendering
    setState((prevState) => ({ ...prevState, entities: [...entities] }))

    // add institution to selected items
    if (jobId) {
      const institution = buildInstitutionEntityFromContact(contact, entityConnection.selectedInstitution)
      institution && onSuggestedItemSelect(institution, entityIdx + 1)
    }
  }

  /**
   * Removes entities from the list of selected entities.
   * @param id
   */
  const onSelectedItemRemove = (id) => {
    const page = state.page
    const entities = state.entities.filter((entity) => entity.entityId !== id)

    setState((prevState) => ({
      ...prevState,
      entities,
      // reset pagination to previous page if user removed all items on current page
      page: (!paginateSelectedValues(entities).length && page > 1) ? page - 1 : page
    }))
  }

  /**
   * Handles pagination
   * @param selected
   */
  const onPageChange = ({ selected }) => {
    setState((prevState) => ({ ...prevState, page: selected }))
  }

  /**
   * Paginates the list of selected entities
   * @param entities
   */
  const paginateSelectedValues = (entities) => {
    const { page, limit } = state
    const start = (page - 1) * limit
    const end = start + limit
    return (entities || []).slice(start, end)
  }

  /**
   * Constructs institution entity structure based on contact's job
   * @param contact
   * @param jobId
   */
  const buildInstitutionEntityFromContact = (contact, jobId) => {
    const { entityConnection = {} } = contact
    const job = (entityConnection.jobs || []).find((job) => job.entityId === jobId.split(':')[0]) || {}
    return job.entityId
      ? {
        entityId: job.entityId,
        entityType: INSTITUTION,
        entityConnection: {
          institutionName: job.institutionName
        }
      }
      : null
  }

  /**
   * Constructs contact entity structure based on custom contact
   * @param customContact
   */
  const buildContactEntityFromCustomContact = (customContact) => {
    const institutionId = get(customContact, 'jobs[0].q4_entity_id', null)
    return {
      entityId: customContact._id,
      entityType: CONTACT,
      entityConnection: {
        fullName: customContact.full_name,
        jobs: get(customContact, 'jobs', []).map((job) => ({
          entityId: job.q4_entity_id,
          title: job.title,
          institutionName: job.institution_name
        })),
        selectedInstitution: institutionId ? `${institutionId}:${customContact._id}` : null
      }
    }
  }

  /**
   * Handles contact form successful save
   * Automatically adds saved contact to the list of selected entities.
   * @param customContact
   */
  const handleContactFormSave = (customContact) => {
    const contact = buildContactEntityFromCustomContact(customContact)
    onSuggestedItemSelect(contact)
  }

  const { controls, entities, isInstitution, page, limit } = state

  const entitySearchStatus = searchEntityState.status
  const selectedValues = paginateSelectedValues(entities)

  return (
    <div className='entity-search' data-id={dataId}>
      <div className='field field--text field--full'>
        {label
          ? (
            <label className='field_label'>
              {label}
              {!!required && <span className='required'>(required)</span>}
            </label>
          )
          : null}
        <EntitySearchControlPanel
          dataId={`${dataId}ControlPanel`}
          controls={controls}
          onControlsChange={onControlsChange}
          isRelativeInstitution={isRelativeInstitution}
          isInstitution={isInstitution}
        />
        <EntitySearchComboBox
          dataId={`${dataId}ComboBox`}
          error={error}
          controls={controls}
          onControlsChange={onControlsChange}
          isRelativeInstitution={isRelativeInstitution}
          loading={entitySearchStatus === statusType.IN_PROGRESS || gqlSearchLoading}
          // values
          value={controls.search}
          suggestedValues={getSuggestedValues()}
          selectedValues={selectedValues}
          // actions
          onSuggestedItemSelect={onSuggestedItemSelect}
          onRemove={onSelectedItemRemove}
          onContactInstitutionSelect={onContactInstitutionSelect}
          onContactCreate={handleContactFormSave}
          openModal={handleOpenModal}
        />
        {(entities?.length > limit) && (
          <div className='entity-search_pagination'>
            <Pagination
              dataId={`${dataId}Pagination`}
              total={entities.length}
              forcePage={page}
              onPageChange={onPageChange}
            />
          </div>
        )}
      </div>
    </div>
  )
}

EntitySearch.propTypes = {
  dataId: PropTypes.string,
  entities: PropTypes.array,
  isRelativeInstitution: PropTypes.bool, // limit to one relative institution
  isRelativeSearch: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  })
}

EntitySearch.defaultProps = {
  dataId: 'EntitySearch',
  entities: [],
  isRelativeInstitution: false,
  isRelativeSearch: false,
  required: false
}

export default memo(EntitySearch)
