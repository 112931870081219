import React, { memo } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'

import { format, formatDate, getClassName, getFromXigniteToStandard, getQuarterEndDates } from '../../../../../utils'
import { get } from 'lodash'

import './list.component.scss'

const propTypes = {
  active: PropTypes.bool,
  position: PropTypes.object.isRequired,
  quarters: PropTypes.number,
  peers: PropTypes.array
}

const defaultProps = {
  position: {},
  quarters: 5
}

/**
 * Get latest reported quarter from holdings data
 * @param position
 * @returns {Date}
 * @private
 */
const _getLatestReportedQtr = ({ position }) => {
  const records = get(position, 'position', [])
  const latestReportedQtr = records && records.length && records[records.length - 1]
  const latestReportedQtrDate = latestReportedQtr
    ? moment.utc(latestReportedQtr.x).add(1, 'day').endOf('quarter').startOf('day')
    : moment.utc().subtract(1, 'quarter').endOf('quarter').startOf('day')

  return latestReportedQtrDate.toDate()
}

/**
 * Position Column Component
 * @param props
 */
const Position = ({ dates, position }) => {
  return (
    <div className='entity-ownership_list-column'>
      <header className='entity-ownership_list-row entity-ownership_list-row--header'>
        {position.symbol}
        <span className='entity-ownership_list-row-divider'>
          {getFromXigniteToStandard(position.exchange)}
        </span>
      </header>
      {(dates || []).map((date, idx) => {
        const value = position[`q${idx + 1}Value`]
        return (
          <div key={`q${idx + 1}-${position.securityId}`} className='entity-ownership_list-row'>
            {value ? format(value) : '-'}
          </div>
        )
      })}
    </div>
  )
}

/**
 * Entity Ownership List Component
 * @param props
 */
function EntityOwnershipList (props) {
  const { active, position, quarters, peers } = props
  const dates = (peers && peers.length)
    ? getQuarterEndDates(quarters, moment.utc())
    : getQuarterEndDates(quarters, _getLatestReportedQtr({ position }))

  return (
    <div className={getClassName('entity-ownership_list', [
      { condition: active, trueClassName: 'entity-ownership_list--active' }
    ])}>
      <div className='entity-ownership_list-column'>
        <header className='entity-ownership_list-row entity-ownership_list-row--header'>Date</header>
        {(dates || []).map((date) => date &&
          <div key={date} className='entity-ownership_list-row'>{formatDate(date, undefined, undefined, true)}</div>)}
      </div>
      <Position
        dates={dates}
        position={position}
      />
      {(peers || []).map((peer) => (
        (peer && peer.tickerId)
          ? <Position
              key={peer.tickerId}
              dates={dates}
              position={peer}
            />
          : null
      ))}
    </div>
  )
}

EntityOwnershipList.propTypes = propTypes
EntityOwnershipList.defaultProps = defaultProps

export default memo(EntityOwnershipList)
