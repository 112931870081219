import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { debounce, getFromXigniteToStandard, THEMES } from '../../../../../../../utils'
import { ComboBox } from '../../../../../../../components'

/**
 * Ownership Filter
 */
class Ownership extends PureComponent {

  /**
   * ComponentWillUnmount
   */
  componentWillUnmount = () => {
    const { resetSecuritySuggestions } = this.props
    resetSecuritySuggestions()
  }

  /**
   * OnInputChange - search securities
   * @param query
   */
  handleInputChange = debounce((query) => {
    const { searchSecurities, resetSecuritySuggestions } = this.props

    if (!query) {
      resetSecuritySuggestions()
      return
    }

    searchSecurities(query)
  })

  /**
   * Handle AutoComplete onChange event
   * @param selectedValue
   * @param type
   */
  handleChange = (selectedValue, type) => {
    if (!type || !type.action || type.action !== 'select-option') {
      return
    }

    const { value, onChange, resetSecuritySuggestions } = this.props
    const selected = [...value, selectedValue.data]

    onChange({ owns_security: selected })
    resetSecuritySuggestions()
  }

  /**
   * Handle RemovableList onRemove event
   * @param securityId
   */
  handleRemove = (securityId) => {
    const { value, onChange, resetSecuritySuggestions } = this.props
    const selected = value.filter((item) => item._id !== securityId)

    onChange({ owns_security: selected })
    resetSecuritySuggestions()
  }

  /**
   * Get suggestions in a format that AutoComplete consumes
   * Remove selected securities and peers if necessary
   */
  getFormattedSuggestions = (suggestions, value, peers) => {
    if (!suggestions || !suggestions.length) {
      return []
    }

    const selectedIds = [].concat(value, peers.map((peer) => peer._security)).map((security) => security._id)
    return suggestions.filter((security) => !selectedIds.includes(security._id)).map((suggestion) => ({
      value: suggestion._id,
      label: this.renderSecurity(suggestion),
      data: suggestion
    }))
  }

  /**
   * Get items in a format that RemovableList consumes
   * @param value
   * @returns {[]}
   */
  getFormattedListItems = (value) => {
    if (!value || !value.length) {
      return []
    }

    return value.filter((suggestion) => suggestion && suggestion._id).map((suggestion) => ({
      value: suggestion._id,
      label: this.renderSecurity(suggestion)
    }))
  }

  /**
   * Render Suggested/Selected Security
   * @param security
   * @return {*}
   */
  renderSecurity = (security) => {
    const { symbol, exchange, name } = security

    return (
      <span className='ownership-filter_item'>
        <span className='ownership-filter_item-symbol'>{symbol}</span>
        <span className='ownership-filter_item-exchange'>{getFromXigniteToStandard(exchange)}</span>
        <span className='ownership-filter_item-separator'>|</span>
        <span className='ownership-filter_item-name'>{name}</span>
      </span>
    )
  }

  /**
   * Render
   * @return {*}
   */
  render = () => {
    const { suggestions, value, peers, loading } = this.props

    return (
      <div className='field field--text field--full'>
        <label className='field_label'>Ownership</label>
        <div className='ownership-filter'>
          <ComboBox
            theme={THEMES.WHITE}
            selectProps={{
              placeholder: 'Search',
              value: null,
              options: this.getFormattedSuggestions(suggestions, value, peers),
              loading,
              onInputChange: this.handleInputChange,
              onChange: this.handleChange
            }}
            removableListProps={{
              items: this.getFormattedListItems(value),
              onRemove: this.handleRemove
            }}
          />
        </div>
      </div>
    )
  }
}

Ownership.propTypes = {
  value: PropTypes.array,
  suggestions: PropTypes.array,
  peers: PropTypes.array,
  searchSecurities: PropTypes.func.isRequired,
  resetSecuritySuggestions: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

Ownership.defaultProps = {
  value: [],
  suggestions: [],
  peers: []
}

export default Ownership
