import {
  GET_AI_SCORE_REQUEST,
  GET_AI_SCORE_SUCCESS,
  GET_AI_SCORE_FAILURE,
  statusType
} from '../../actions'
import { takeLatest } from '../../utils/redux.util'

const initial = {
  aiScore: null,
  status: statusType.IDLE
}

const aiScore = (state = initial, action) => {
  const { payload } = action

  switch (action.type) {
    case GET_AI_SCORE_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_AI_SCORE_SUCCESS:
      return {
        ...state,
        aiScore: !isNaN(payload) ? payload : null,
        status: statusType.SUCCESS
      }

    case GET_AI_SCORE_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }
    default:
      return state
  }
}

export default takeLatest(
  aiScore,
  initial,
  [GET_AI_SCORE_REQUEST, GET_AI_SCORE_SUCCESS, GET_AI_SCORE_FAILURE]
)
