import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import shortid from 'shortid'
import { get } from 'lodash'
import { getClassName, getActiveTicker, THEMES } from '../../../utils'
import {
  defaultEntityTypeFilter,
  getAvailableEntityTypes,
  getAvailableFields,
  getAvailablePivotFields,
  getAvailableFilters,
  getFilteredSort,
  getDefaultEntityTypeFields,
  getDefaultLimitValue,
  getEntityQuartersMap,
  getInitialFilterState,
  getIsPivotAvailable,
  getNewReportConfig,
  getShouldFetchSnapshot,
  getAvailablePeers,
  getIsReportType,
  stockEntityType,
  getIsFieldWithFilter,
  getAvailableIndices
} from '../../../utils/report'
import {
  FETCHING,
  getReportFields,
  resetReportSnapshot,
  setReportDataConfig,
  clearPeers,
  clearFoundPeers,
  loadPeers,
  searchPeers,
  removePeer,
  addPeer,
  resetAutoCompletePeers
} from '../../../actions/report'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderDarkThumb, renderTrackVertical } from '../../../resources/theme/q4.custom-scrollbar'
import { Button, Message, Spinner } from '../../../components'
import {
  ReportFields,
  ReportFilters,
  ReportGroups,
  ReportIndices,
  ReportLimits,
  ReportPeers,
  ReportPivot
} from '../../../components/report/config'

import './reportConfig.container.css'

/**
 * Report Builder Configuration Container
 */
class ReportConfig extends Component {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    const { reportDataConfig, initialIsCollapsed } = props
    const { entityType, fields, isLocked } = reportDataConfig

    this.state = {
      isCollapsed: initialIsCollapsed || false,
      toggleCount: initialIsCollapsed ? 1 : 0,
      isLocked,
      isMessageOpen: false,

      views: ['data', 'filters', 'settings'],
      selectedView: 'data',

      // backup of report configuration used when overwriting / discarding changes
      _report: {
        _entityType: [],
        _fields: [],
        _pivotFields: [],
        _filters: [],
        _sort: [],
        _limit: []
      },

      isPivotAvailable: getIsPivotAvailable(entityType, fields)
    }
  }

  componentDidMount () {
    this.props.resetAutoCompletePeers()
  }

  /**
   * ComponentDidUpdate
   * @param prevProps
   * @param prevState
   */
  componentDidUpdate (prevProps, prevState) {
    const { entityType, fields, isPivot } = this.props.reportDataConfig
    const { entityType: prevEntityType, fields: prevFields } = prevProps.reportDataConfig
    const { isPivotAvailable } = this.state

    if (entityType.length !== prevEntityType.length || fields.length !== prevFields.length) {
      this.setState({
        isPivotAvailable: getIsPivotAvailable(entityType, fields)
      })
    }

    if (!isPivotAvailable && isPivot) {
      this.handlePivotToggle(false)
    }
  }

  /**
   * Search Peers
   * @param searchValue
   */
  searchPeers = (searchValue) => {
    const { searchPeers } = this.props
    const params = {
      limit: 5,
      type: 'security',
      query: searchValue
    }

    searchValue && searchPeers(params)
  }

  /**
   * Handle adding a new Report Builder data group
   * @param newEntityType
   */
  handleEntityTypeAdd = (newEntityType) => {
    const { reportEntityTypes, reportFields, reportDataConfig, subscriptionServices, setReportDataConfig, onChange } = this.props
    const { entityType, filters, limit } = reportDataConfig

    const newEntityTypes = [...entityType, newEntityType]
    const shouldFetchSnapshot = true

    const newFields = this.getDefaultFields(newEntityType)
    const availableFilters = getAvailableFilters(reportFields, newFields)
    const defaultFilter = availableFilters.find((each) => {
      return get(each, '_field.name') === defaultEntityTypeFilter &&
        get(each, '_field._entityType._id') === newEntityType._id
    })

    const limitItem = (limit || []).find((item) => item._entityType === newEntityType._id)
    const newLimit = limitItem ? limit : [...limit, { _entityType: newEntityType._id, value: getDefaultLimitValue() }]
    const isPivotAvailable = getIsPivotAvailable(newEntityTypes, newFields)
    const _pivotQuarters = getEntityQuartersMap(filters, newEntityTypes)

    const _reportData = {
      entityType: newEntityTypes,
      availableEntityTypes: getAvailableEntityTypes(reportEntityTypes, newEntityTypes, subscriptionServices),
      fields: newFields,
      availableFields: getAvailableFields(reportFields, newEntityTypes, newFields),
      availableFilters,
      limit: newLimit,
      isPivot: isPivotAvailable,
      _pivotQuarters
    }

    if (defaultFilter) {
      const newFilters = [...filters, {
        ...defaultFilter,
        _id: shortid.generate(),
        state: getInitialFilterState(defaultFilter, _pivotQuarters)
      }]

      Object.assign(_reportData, {
        filters: newFilters,
        _pivotQuarters: getEntityQuartersMap(newFilters, newEntityTypes)
      })
    }

    this.setState({ isPivotAvailable }, () => setReportDataConfig(_reportData))

    onChange('entityType-add', { entityType: newEntityTypes, fields: newFields }, shouldFetchSnapshot)
  }

  /**
   * Handle removing a new Report Builder data group
   * @param isPrimary
   * @param removedEntityType
   */
  handleEntityTypeRemove = (isPrimary, removedEntityType) => {
    const {
      reportEntityTypes, reportFields, reportDataConfig, subscriptionServices,
      setReportDataConfig, onChange
    } = this.props
    const { entityType, fields, pivotFields, filters, sort, limit } = reportDataConfig

    const _entityType = isPrimary ? [] : (entityType || []).filter((type) => type._id !== removedEntityType._id)
    const { _fields, _pivotFields, _filters, _sort, _limit } = getNewReportConfig(_entityType, fields, pivotFields, filters, sort, limit)
    const shouldFetchSnapshot = true

    if (fields.length !== _fields.length || filters.length !== _filters.length) {
      return this.setState({
        isMessageOpen: true,
        _report: {
          _entityType,
          _fields,
          _pivotFields,
          _filters,
          _sort,
          _limit
        }
      })
    }

    setReportDataConfig({
      entityType: _entityType,
      availableEntityTypes: getAvailableEntityTypes(reportEntityTypes, _entityType, subscriptionServices),
      availableFields: getAvailableFields(reportFields, _entityType, fields),
      availablePivotFields: getAvailablePivotFields(fields),
      _pivotQuarters: getEntityQuartersMap(filters, _entityType)
    })

    onChange('entityType-remove', { entityType: _entityType }, shouldFetchSnapshot)
  }

  /**
   * Handle adding a new Report Builder Field
   * @param options
   */
  handleFieldAdd = (options) => {
    const { reportFields, reportDataConfig, setReportDataConfig, onChange } = this.props
    const { entityType, fields } = reportDataConfig

    const newFields = [...fields, ...options]
    const shouldFetchSnapshot = true

    setReportDataConfig({
      fields: newFields,
      availableFields: getAvailableFields(reportFields, entityType, newFields),
      availablePivotFields: getAvailablePivotFields(newFields),
      availableFilters: getAvailableFilters(reportFields, newFields),
      latestField: options && options.length && options[options.length - 1]
    })

    onChange('field-add', { fields: newFields }, shouldFetchSnapshot)
  }

  /**
   * On Report Builder Field remove
   * @param id
   */
  handleFieldRemove = (id) => {
    const { reportFields, reportDataConfig, setReportDataConfig, onChange } = this.props
    const { entityType, fields, pivotFields, filters, sort } = reportDataConfig

    const newFields = (fields || []).filter((field) => field._id !== id)
    const isFilter = getIsFieldWithFilter((fields || []).find((field) => field._id === id), filters)

    const newConfig = {
      fields: newFields,
      pivotFields: pivotFields.filter((field) => field._id !== id),
      availableFields: getAvailableFields(reportFields, entityType, newFields),
      availablePivotFields: getAvailablePivotFields(newFields),
      availableFilters: getAvailableFilters(reportFields, newFields)
    }

    if (isFilter) {
      newConfig.filters = filters.filter((filter) => filter && filter._field && filter._field._id !== id)
    }

    if (sort && sort.length > 0) {
      const newSort = (sort || []).filter((each) => each._field !== id)

      if (sort.length !== newSort.length) {
        newConfig.sort = newSort
      }
    }

    setReportDataConfig(newConfig)

    onChange('field-remove', newConfig, true)
  }

  /**
   * Handle Autocomplete result select for filters
   * @param newFilter
   */
  handleFilterSelect = (newFilter) => {
    const { _pivotQuarters } = this.props.reportDataConfig

    this.handleFilterAdd({
      ...newFilter,
      _id: shortid.generate(),
      state: getInitialFilterState(newFilter, _pivotQuarters)
    })
  }

  /**
   * Handle adding a new Report Builder Filter
   * @param newFilter
   */
  handleFilterAdd = (newFilter) => {
    const { reportDataConfig, setReportDataConfig, onChange } = this.props
    const { fields, filters, sort, entityType } = reportDataConfig

    const newFilters = [...filters, newFilter]
    const filterType = newFilter.filterType

    const _pivotQuarters = getEntityQuartersMap(newFilters, entityType)
    const shouldFetchSnapshot = getShouldFetchSnapshot('filter-add', { filterType })

    setReportDataConfig({
      filters: newFilters,
      _pivotQuarters,
      sort: filterType === 'date' ? getFilteredSort(sort, fields) : sort
    })

    onChange('filters', { filters: newFilters }, shouldFetchSnapshot)
  }

  /**
   * On Report Builder Filter remove
   * @param filterId
   */
  handleFilterRemove = (filterId) => {
    const { reportDataConfig, setReportDataConfig, onChange } = this.props
    const { fields, filters, entityType, sort } = reportDataConfig

    const removedFilter = filters.find((filter) => filter._id === filterId)
    const newFilters = (filters || []).filter((filter) => filter._id !== filterId)
    const _pivotQuarters = getEntityQuartersMap(newFilters, entityType)
    const shouldFetchSnapshot = true

    setReportDataConfig({
      filters: newFilters,
      _pivotQuarters,
      sort: removedFilter.filterType === 'date' ? getFilteredSort(sort, fields) : sort
    })

    onChange('filters', { filters: newFilters }, shouldFetchSnapshot)
  }

  /**
   * On Report Builder Filter value change
   * @param filterId
   * @param newState
   */
  handleFilterChange = (filterId, newState) => {
    const { reportDataConfig, setReportDataConfig, onChange } = this.props
    const { fields, filters, sort, entityType } = reportDataConfig
    const _prevState = {}, _newState = {}

    const newFilters = filters.map((filter) => {
      if (filter._id !== filterId) {
        return filter
      }

      Object.assign(_prevState, filter.state)
      Object.assign(_newState, {
        ...filter.state,
        ...newState
      })

      return {
        ...filter,
        state: _newState
      }
    })

    const updatedFilter = newFilters.find((filter) => filter._id === filterId)
    const _pivotQuarters = getEntityQuartersMap(newFilters, entityType)
    const shouldFetchSnapshot = getShouldFetchSnapshot('filter-change', { _prevState, _newState })

    setReportDataConfig({
      filters: newFilters,
      _pivotQuarters,
      sort: updatedFilter.filterType === 'date' ? getFilteredSort(sort, fields) : sort
    })

    onChange('filters', { filters: newFilters }, shouldFetchSnapshot)
  }

  /**
   * Handle limit change for a specified entity type
   * @param entityTypeId
   * @param newValue
   */
  handleLimitChange = (entityTypeId, newValue) => {
    if (!newValue) {
      return
    }

    const { reportDataConfig, setReportDataConfig, onChange } = this.props
    const { limit } = reportDataConfig

    let newLimit = [...limit]
    const limitItem = limit.find((each) => each._entityType === entityTypeId)
    const shouldFetchSnapshot = true

    if (!limit.length || !limitItem) {
      newLimit.push({
        _entityType: entityTypeId,
        value: newValue
      })
    } else {
      newLimit = (limit || []).map((each) => {
        if (each._entityType === entityTypeId) {
          return {
            ...each,
            value: newValue
          }
        }

        return each
      })
    }

    setReportDataConfig({
      limit: newLimit
    })

    onChange('limit', { limit: newLimit }, shouldFetchSnapshot)
  }

  /**
   * Handle discarding of fields and filters impacted by new entity types
   */
  handleReportConfigDiscard = () => {
    const {
      reportEntityTypes, reportFields, subscriptionServices,
      resetReportSnapshot, setReportDataConfig, onChange
    } = this.props
    const { _report } = this.state
    const { _entityType, _fields, _pivotFields, _filters, _sort, _limit } = _report

    const shouldFetchSnapshot = _entityType && _entityType.length

    setReportDataConfig({
      entityType: _entityType,
      availableEntityTypes: getAvailableEntityTypes(reportEntityTypes, _entityType, subscriptionServices),
      fields: _fields,
      availableFields: getAvailableFields(reportFields, _entityType, _fields),
      pivotFields: _pivotFields,
      availablePivotFields: getAvailablePivotFields(_fields),
      filters: _filters,
      availableFilters: getAvailableFilters(reportFields, _fields),
      sort: _sort,
      limit: _limit,
      _pivotQuarters: getEntityQuartersMap(_filters, _entityType)
    })

    this.setState({
      isMessageOpen: false
    }, () => {
      onChange('discard', {
        entityType: _entityType,
        fields: _fields,
        pivotFields: _pivotFields,
        filters: _filters
      }, shouldFetchSnapshot)

      if (!_fields.length) {
        resetReportSnapshot()
      }
    })
  }

  /**
   * Handle toggling of isPivot within the Report Data Configuration
   * @param isPivot
   */
  handlePivotToggle = (isPivot) => {
    this.props.setReportDataConfig({
      isPivot
    })
  }

  /**
   * Handle secondary pivot change
   * @param option
   */
  handlePivotChange = (option) => {
    const { reportDataConfig, setReportDataConfig } = this.props
    const { pivotFields } = reportDataConfig
    const secondaryPivotField = pivotFields && pivotFields.length && pivotFields[0]

    if ((option && option._id) !== (secondaryPivotField && secondaryPivotField._id)) {
      setReportDataConfig({
        pivotFields: option ? [option] : []
      })
    }
  }

  /**
   * Handle selectedView change event
   * @param newView
   */
  handleViewChange = (newView) => {
    const { selectedView } = this.state

    if (selectedView !== newView) {
      this.setState({
        selectedView: newView
      })
    }
  }

  /**
   * Handle toggling of isCollapsed state
   */
  handleCollapseToggle = () => {
    const { isCollapsed, toggleCount } = this.state

    this.setState({
      isCollapsed: !isCollapsed,
      toggleCount: toggleCount + 1
    })
  }

  /**
   * Handle closing of the Message modal
   */
  handleMessageClose = () => {
    this.setState({
      isMessageOpen: false
    })
  }

  /**
   * Get default fields for newly selected entity type
   * @param entityType
   * @return {*[]}
   */
  getDefaultFields = (entityType) => {
    const { reportFields, reportDataConfig } = this.props
    const { fields } = reportDataConfig

    const entityTypeId = entityType && entityType._id
    const defaultEntityTypeFields = getDefaultEntityTypeFields(entityType)

    const defaultFields = defaultEntityTypeFields.reduce((acc, defaultFieldName) => {
      const _found = reportFields.find((reportField) => {
        const { name, _entityType } = reportField

        return (
          (_entityType && _entityType._id) === entityTypeId &&
          defaultFieldName === name
        )
      })
      if (_found) {
        acc.push(_found)
      }
      return acc
    }, [])

    if (defaultFields && defaultFields.length) {
      return [...fields, ...defaultFields]
    } else {
      return fields
    }
  }

  /**
   * Handle reset peers
   */
  handleResetPeers = () => {
    this.handleAddDefaultPeers()
  }

  /**
   * Handle clear peers
   */
  handleClearPeers = () => {
    const { clearPeers, onChange } = this.props
    clearPeers()
    onChange('peer-add', {}, true)
  }

  /**
   * Handle add default peers
   */
  handleAddDefaultPeers = () => {
    const { loadPeers, onChange } = this.props
    const peerParams = {
      select: ['symbol', 'exchange', 'security_name'],
      sortField: 'Symbol',
      sortOrder: 1,
      limit: 20,
      excludeMyCompany: true
    }

    loadPeers(peerParams)
    onChange('peer-add', {}, true)
  }

  /**
   * Handle Search Peer Select
   * @param selectedPeers
   */
  handleSearchPeerSelect = (selectedPeers) => {
    const { addPeer, onChange } = this.props
    const peers = [].concat(selectedPeers || []).map((peer) => {
      const { mongo_id, symbol, exchange, security_name } = peer
      return {
        _security: mongo_id,
        symbol,
        exchange,
        security_name
      }
    })

    addPeer(peers)
    onChange('peer-add', {}, true)
  }

  /**
   * Handle Peer Remove
   * @param id
   */
  handlePeerRemove = (id) => {
    const { removePeer, onChange } = this.props

    removePeer(id)
    onChange('peer-remove', {}, true)
  }

  /**
   * Handle Search  Peer QueryChange
   * @param searchValue
   */
  handleSearchPeerQueryChange = (searchValue) => {
    this.searchPeers(searchValue)
  }

  /**
   * Handle adding a new Index
   * @param index
   */
  handleIndexAdd = (index) => {
    const { reportDataConfig, setReportDataConfig, onChange } = this.props
    const { indices } = reportDataConfig

    const newIndices = [...(indices || []), index]
    const shouldFetchSnapshot = true

    setReportDataConfig({
      indices: newIndices,
      availableIndices: getAvailableIndices(newIndices)
    })

    onChange('index-add', { indices: newIndices }, shouldFetchSnapshot)
  }

  /**
   * Handle removing an existing Index
   * @param id
   */
  handleIndexRemove = (id) => {
    const { reportDataConfig, setReportDataConfig, onChange } = this.props
    const { indices } = reportDataConfig

    const newIndices = (indices || []).filter((index) => `${index.symbol}.${index.group}` !== id)
    const shouldFetchSnapshot = true

    setReportDataConfig({
      indices: newIndices,
      availableIndices: getAvailableIndices(newIndices)
    })

    onChange('index-remove', { indices: newIndices }, shouldFetchSnapshot)
  }

  /**
   * Render Report Config View Tabs (Basic / Advanced)
   */
  renderPaneTabs = () => {
    const { views, selectedView } = this.state
    const { entityType, fields } = this.props.reportDataConfig

    return (
      <div className='report-config_pane-tabs'>
        {(views || []).map((view) => {
          const isSelected = view === selectedView
          const isDisabled = (view === 'settings' && !entityType.length) || (view === 'filters' && !fields.length)
          const className = getClassName('report-config_pane-tab', [
            { condition: isSelected, trueClassName: 'report-config_pane-tab--selected' },
            { condition: isDisabled, trueClassName: 'report-config_pane-tab--disabled' }
          ])

          const handleTabClick = () => this.handleViewChange(view)

          return (
            <div key={`report-config_pane-tab--${view}`} className={className} onClick={handleTabClick}>{view}</div>
          )
        })}
      </div>
    )
  }

  /**
   * Render all the available panes
   * Note: rendered together for required ui transitions
   */
  renderPanes = () => {
    const { views, selectedView, isLocked } = this.state

    return (
      <Scrollbars
        className='react-scrollbar'
        autoHide
        hideTracksWhenNotNeeded
        renderThumbVertical={renderDarkThumb}
        renderTrackVertical={renderTrackVertical}
      >
        {(views || []).map((view) => {
          const isActive = view === selectedView
          const className = getClassName('report-config_pane-item', [
            { condition: isActive, trueClassName: 'report-config_pane-item--active' },
            { condition: isLocked && isActive, trueClassName: 'report-config_pane-item--locked' }
          ])

          return (
            <div key={`report-config_pane-item--${view}`} className={className}>{this.renderPane(view)}</div>
          )
        })}
      </Scrollbars>
    )
  }

  /**
   * Unlock template
   */
  handleEditTemplate = () => {

    const { setReportDataConfig, onChange } = this.props
    this.setState({
      isLocked: false
    }, () => {
      setReportDataConfig({ isLocked: false }).then(() => {
        onChange('limit', {}, true)
      })
    })
  }

  /**
   * Render Locked PopUp
   * @returns {*}
   */
  renderLockedPopup = () => {
    return (
      <div className="locked-template-popup">
        <div className="locked-template-popup_title">
          This template is locked.
        </div>
        <div className="locked-template-popup_description">
          Any changes you make will only be reflected on the top 250 results of your shareholder base.
        </div>
        <Button
          label='edit template'
          icon='q4i-edit-2pt'
          wide
          theme={THEMES.LIGHT_GREY}
          onClick={this.handleEditTemplate}
        />
      </div>
    )
  }

  /**
   * Render individual pane based on view
   * @param view
   */
  renderPane = (view) => {
    const viewMap = {
      data: this.renderFieldsPane,
      filters: this.renderFiltersPane,
      settings: this.renderSettingsPane
    }

    return (viewMap[view] && viewMap[view]()) || null
  }

  /**
   * Render Fields Pane
   * @returns {XML}
   */
  renderFieldsPane = () => {
    const { peers, foundPeers, clearFoundPeers, ticker, reportDataConfig } = this.props
    const { entityType, availableEntityTypes, fields, availableFields, filters, indices, availableIndices } = reportDataConfig

    const isStockReport = getIsReportType(entityType, stockEntityType)

    return (
      <>
        <header className='report-config_pane-header'>
          <h2 className='report-config_title'>Holders</h2>
        </header>
        <section className='report-config_pane-body'>
          <ReportGroups
            groups={entityType}
            availableGroups={availableEntityTypes}
            onGroupAdd={this.handleEntityTypeAdd}
            onGroupRemove={this.handleEntityTypeRemove}
          />
        </section>
        {isStockReport && (
          <>
            <div className='report-config_pane-keyline' />
            <ReportPeers
              peers={peers}
              availablePeers={getAvailablePeers(foundPeers, peers, ticker)}
              onSearchQueryChange={this.handleSearchPeerQueryChange}
              onSearchQueryClear={clearFoundPeers}
              onSearchPeerSelect={this.handleSearchPeerSelect}
              onRemovePeer={this.handlePeerRemove}
              onResetPeers={this.handleResetPeers}
              onAddDefaultPeers={this.handleAddDefaultPeers}
              onClearPeers={this.handleClearPeers}
            />
            <div className='report-config_pane-keyline' />
            <header className='report-config_pane-header'>
              <h2 className='report-config_title'>Indices</h2>
            </header>
            <section className='report-config_pane-body'>
              <ReportIndices
                indices={indices}
                availableIndices={availableIndices}
                onIndexAdd={this.handleIndexAdd}
                onIndexRemove={this.handleIndexRemove}
              />
            </section>
          </>
        )}
        {(entityType || []).length > 0 && (
          <>
            <div className='report-config_pane-keyline' />
            <header className='report-config_pane-header'>
              <h2 className='report-config_title'>Report Columns</h2>
            </header>
            <section className='report-config_pane-body'>
              <ReportFields
                entityType={entityType}
                fields={fields}
                availableFields={availableFields}
                filters={filters}
                onFieldAdd={this.handleFieldAdd}
                onFieldRemove={this.handleFieldRemove}
              />
            </section>
          </>
        )}
      </>
    )
  }

  /**
   * Render Filters Pane
   * @param selectedView
   * @returns {XML}
   */
  renderFiltersPane = (selectedView) => {
    const { filters, availableFilters, _pivotQuarters } = this.props.reportDataConfig

    return (
      <>
        <header className='report-config_pane-header'>
          <h2 className='report-config_title'>Filters</h2>
        </header>
        <section className='report-config_pane-body'>
          <ReportFilters
            isConfigFilterOpen={selectedView === 'filters'}
            filters={filters}
            pivotQuarters={_pivotQuarters}
            availableFilters={availableFilters}
            onFilterAdd={this.handleFilterSelect}
            onFilterRemove={this.handleFilterRemove}
            onFilterChange={this.handleFilterChange}
          />
        </section>
      </>
    )
  }

  /**
   * Render Settings Pane
   * @returns {XML}
   */
  renderSettingsPane = () => {
    const { entityType, pivotFields, limit, isPivot, availablePivotFields } = this.props.reportDataConfig
    const { isPivotAvailable, isLocked } = this.state

    return (
      <>
        <header className='report-config_pane-header'>
          <h2 className='report-config_title'>Configuration Settings</h2>
        </header>
        <section className='report-config_pane-body'>
          <ReportLimits
            entityType={entityType}
            limit={limit}
            onLimitChange={this.handleLimitChange}
            isLocked={isLocked}
          />
        </section>
        <div className='report-config_pane-keyline' />
        <header className='report-config_pane-header'>
          <h2 className='report-config_title'>Pivot Settings</h2>
        </header>
        <section className='report-config_pane-body'>
          <ReportPivot
            isPivot={isPivot}
            isPivotAvailable={isPivotAvailable}
            pivotFields={pivotFields}
            availablePivotFields={availablePivotFields}
            onPivotToggle={this.handlePivotToggle}
            onPivotChange={this.handlePivotChange}
          />
        </section>
      </>
    )
  }

  /**
   * Render Report Config Component
   * @return {XML}
   */
  render () {
    const { isLoading } = this.props
    const { isCollapsed, toggleCount, isMessageOpen, isLocked } = this.state

    let baseClassName = getClassName('report-config', [
      { condition: isCollapsed, trueClassName: 'report-config--collapsed' },
      { condition: isLocked, trueClassName: 'report-config--disabled' }
    ])

    return (
      <section className={baseClassName}>
        <div className='report-config_inner'>
          <header className='report-config_header'>
            {this.renderPaneTabs()}
            <div className='report-config_collapse-toggle' onClick={this.handleCollapseToggle}>
              <i style={{ transform: `rotate(${toggleCount * 180}deg)` }} className='q4i-arrow-left-4pt' />
            </div>
          </header>
          <div className='report-config_body'>
            {isLoading && (
              <Spinner
                theme={'rain'}
                mask={true}
              />
            )}
            {this.renderPanes()}
            {isLocked && this.renderLockedPopup()}
          </div>
        </div>

        <Message
          visible={isMessageOpen}
          type='warning'
          title='Discard Column(s)?'
          message='Are you sure you want to discard this data type? Removing data types will also remove all columns, filters, and pivot selections associated with them.'
          buttons={[{
            ui: 'shaded',
            label: 'cancel',
            onClick: this.handleMessageClose
          }, {
            ui: 'spice',
            label: 'confirm',
            onClick: this.handleReportConfigDiscard
          }]}
          onClose={this.handleMessageClose}
        />
      </section>
    )
  }
}

ReportConfig.propTypes = {
  initialIsCollapsed: PropTypes.bool,
  isLocked: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  const reportState = state.report
  const { reportDataConfig, reportEntityTypes, reportFields, reportPeerConfig } = reportState
  const isLoading = reportEntityTypes.status === FETCHING || reportFields.status === FETCHING
  const profileData = state.profile && state.profile.data
  const ticker = getActiveTicker(profileData)
  const services = (profileData.services || []).reduce((acc, each) => {
    acc[each.type] = each.enabled
    return acc
  }, {})

  return {
    reportDataConfig,
    templateSource: (services.surveillance && 'surveillance') || (services.shareholder_id && 'shareholder_id') || '13F',
    reportEntityTypes: reportEntityTypes.data || [],
    reportFields: reportFields.data || [],
    isLoading,
    subscriptionServices: services,
    peers: reportPeerConfig.peers || [],
    foundPeers: reportPeerConfig.foundPeers,
    ticker
  }
}

const mapDispatchToProps = (dispatch) => ({
  getReportFields: bindActionCreators(getReportFields, dispatch),
  setReportDataConfig: bindActionCreators(setReportDataConfig, dispatch),
  resetReportSnapshot: bindActionCreators(resetReportSnapshot, dispatch),
  clearPeers: bindActionCreators(clearPeers, dispatch),
  clearFoundPeers: bindActionCreators(clearFoundPeers, dispatch),
  loadPeers: bindActionCreators(loadPeers, dispatch),
  searchPeers: bindActionCreators(searchPeers, dispatch),
  removePeer: bindActionCreators(removePeer, dispatch),
  addPeer: bindActionCreators(addPeer, dispatch),
  resetAutoCompletePeers: bindActionCreators(resetAutoCompletePeers, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportConfig)
