import { get } from 'lodash'
import {
  FETCH_TOP_SHAREHOLDERS_REQUEST,
  FETCH_TOP_SHAREHOLDERS_SUCCESS,
  FETCH_TOP_SHAREHOLDERS_ERROR,
  FETCH_TOP_SHAREHOLDERS_SNOWFLAKE_SUCCESS,
  FETCHING,
  FETCHED,
  FAILED,
  IDLE
} from '../../../actions/widget/ownership'

const initial = {
  holders: [],
  status: IDLE
}

const current = (state = initial, action) => {
  switch (action.type) {
    case FETCH_TOP_SHAREHOLDERS_SNOWFLAKE_SUCCESS:
      return {
        ...state,
        holders: get(action, 'payload.data.instHoldingCurrent.items', []),
        status: FETCHED
      }
    case FETCH_TOP_SHAREHOLDERS_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_TOP_SHAREHOLDERS_SUCCESS:
      const holders = Array.isArray(action.payload) ? action.payload : ((action.payload && action.payload.data) || [])

      return {
        ...state,
        holders,
        status: FETCHED
      }
    case FETCH_TOP_SHAREHOLDERS_ERROR:
      return {
        ...state,
        error: action.error,
        status: FAILED
      }
    default:
      return state
  }
}

export default current
