import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

export const RESET_CONTACT_GRID = 'RESET_CONTACT_GRID'
export const GET_INSTITUTION_CONTACTS_REQUEST = 'GET_INSTITUTION_CONTACTS_REQUEST'
export const GET_INSTITUTION_CONTACTS_SUCCESS = 'GET_INSTITUTION_CONTACTS_SUCCESS'
export const GET_INSTITUTION_CONTACTS_FAILURE = 'GET_INSTITUTION_CONTACTS_FAILURE'

/**
 * Reset data in Contact Grid
 */
export const resetContactGrid = () => (dispatch) => {
  return dispatch({ type: RESET_CONTACT_GRID })
}

const _fetchInstitutionContacts = (payload, options = {}) => ({
  [CALL_API]: {
    types: options.types || [
      GET_INSTITUTION_CONTACTS_REQUEST,
      GET_INSTITUTION_CONTACTS_SUCCESS,
      GET_INSTITUTION_CONTACTS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/institution/contact/v2',
    payload
  }
})

export const fetchInstitutionContacts = (params, options) => (dispatch) => {
  return dispatch(_fetchInstitutionContacts(params, options))
}
