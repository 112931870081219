import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {getClassName} from '../../../utils/ui';
import {Scrollbars} from 'react-custom-scrollbars';
import {renderLightThumb, renderDarkThumb, renderTrackVertical} from '../../../resources/theme/q4.custom-scrollbar';
import './dropdown.component.css';

/**
 * Dropdown Component
 */
class Dropdown extends PureComponent {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            toggleCount: 0,
            noData: !props.options || !props.options.length,
            isDarkTheme: this.getIsDarkerTheme(props.theme)
        };

        this.componentReference = React.createRef();
        this.appBodyContainer = document.getElementsByTagName('body')[0];
        this.optionContainer = document.createElement('div');
        this.optionContainer.className = 'dropdown-portal';
    }

    /**
     * ComponentDidUpdate
     * Recalculate noData state if a new set of options is provided
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {options, theme} = this.props;

        const newOptions = (options && options.length) !== (prevProps.options && prevProps.options.length);
        const newTheme = theme !== prevProps.theme;

        if (newOptions || newTheme) {
            this.setState({
                noData: !options || !options.length,
                isDarkTheme: this.getIsDarkerTheme(theme)
            });
        }
    }

    /**
     * ComponentWillUnmount
     * Unsubscribe from 'outside click' listener
     */
    componentWillUnmount() {
        if (this.state.expanded) {
            this.appBodyContainer.removeChild(this.optionContainer);
        }
    }

    /**
     * Handle Dropdown Toggling
     */
    handleToggle = () => {
        const {disabled} = this.props;

        if (!disabled) {
            const {expanded} = this.state;
            expanded ? this.handleCollapse() : this.handleExpand();
        }
    };

    /**
     * Handle Dropdown collapse
     */
    handleExpand = () => {
        this.setState({
            expanded: true,
            toggleCount: this.state.toggleCount + 1
        }, () => {
            this.appBodyContainer.classList.add('overflow-hidden');
            this.appBodyContainer.appendChild(this.optionContainer);
        });
    };

    /**
     * Handle Dropdown collapse
     */
    handleCollapse = () => {
        this.setState({
            expanded: false,
            toggleCount: this.state.toggleCount + 1
        }, () => {
            this.appBodyContainer.classList.remove('overflow-hidden');
            this.appBodyContainer.removeChild(this.optionContainer);
        });
    };

    /**
     * Handle Dropdown selection
     * @param option
     */
    handleSelect = (option) => {
        const {onSelect} = this.props;

        this.handleCollapse();
        onSelect && onSelect(option);
    };

    /**
     * Calculate Dropdown Options height value
     * @returns {number}
     */
    getDropdownHeight = () => {
        const {options, dropdownHeight, forceDropdownHeight} = this.props;
        let itemHeight = this.props.itemHeight;
        const {noData} = this.state;

        const defaultItemHeight = 40;

        itemHeight = (!(isNaN(itemHeight)) && itemHeight) || defaultItemHeight;

        if (noData) {
            return (itemHeight);
        }

        if (dropdownHeight) {
            const totalItemHeight = options.length * itemHeight;
            const calculatedHeight = dropdownHeight > totalItemHeight ? totalItemHeight : dropdownHeight;

            return forceDropdownHeight ? dropdownHeight : calculatedHeight;
        } else {
            return options.length * itemHeight;
        }
    };

    /**
     * Get Dropdown styles
     * @returns {Object}
     */
    getStyles = () => {
        const {width, height, itemHeight, maskOpacity, maskColor, zIndex} = this.props;
        const {toggleCount} = this.state;
        const Component = this.componentReference && this.componentReference.current;
        const ComponentRect = Component && Component.getBoundingClientRect();

        const defaultWidth = 160;
        const defaultHeight = 40;
        const dropdownHeight = this.getDropdownHeight();

        return {
            base: {
                width: width ? width : defaultWidth,
                height: height ? height : defaultHeight,
                zIndex
            },
            inner: {
                height: height ? height : defaultHeight,
                lineHeight: height ? `${height}px` : `${defaultHeight}px`
            },
            mask: {
                backgroundColor: maskColor,
                opacity: maskOpacity,
            },
            filler: {
                height
            },
            options: {
                top: (ComponentRect && ComponentRect.top) + (height ? height : defaultHeight),
                left: ComponentRect && ComponentRect.left,
                width: (ComponentRect && ComponentRect.width) ? ComponentRect.width : width ? width : defaultWidth,
                height: height + dropdownHeight,
                marginTop: height * -1
            },
            scroller: {
                height: dropdownHeight
            },
            option: {
                height: itemHeight ? itemHeight : defaultHeight,
                lineHeight: itemHeight ? `${itemHeight}px` : `${defaultHeight}px`
            },
            toggle: {
                transform: `rotate(${toggleCount * 180}deg)`
            }
        };
    };

    /**
     * Get Option label
     * @param option
     * @param index
     * @returns {*}
     */
    getLabel = (option, index) => {
        const {options, optionKey, customOptionRender} = this.props;

        if (optionKey) {
            return option[optionKey];
        }

        if (customOptionRender) {
            return customOptionRender(option, index, options);
        }

        return option;
    };

    /**
     * Determine if theme is one of the darker variants
     * @param theme
     * @returns {boolean}
     */
    getIsDarkerTheme = (theme) => {
        return (
            theme === 'dark' ||
            theme === 'slate' ||
            theme === 'dark-slate' ||
            theme === 'charcoal' ||
            theme === 'steel' ||
            theme === 'q4-blue' ||
            theme === 'ink'
        );
    };

    /**
     * Render Dropdown Options
     * @param options
     * @param styles
     * @returns {XML}
     */
    renderOptions = (options, styles) => {
        const {isDarkTheme} = this.state;

        const defaultItemHeight = 40;
        const itemHeight = (!(isNaN(this.props.itemHeight)) && this.props.itemHeight) || defaultItemHeight;
        const totalItemHeight = options.length * itemHeight;
        const isScrollable = totalItemHeight > styles.scroller.height;

        return isScrollable ? (
            <Scrollbars
                style={styles.scroller}
                className='dropdown_scrollbar react-scrollbar'
                hideTracksWhenNotNeeded
                renderThumbVertical={isDarkTheme ? renderLightThumb : renderDarkThumb}
                renderTrackVertical={renderTrackVertical}
            >
                <ul className='dropdown_list'>
                    {(options || []).map((option, index) => {
                        return (
                            <li
                                key={index}
                                className='dropdown_option'
                                style={styles.option}
                                onClick={() => this.handleSelect(option)}
                            >
                                {this.getLabel(option, index)}
                            </li>
                        );
                    })}
                </ul>
            </Scrollbars>
        ) : (
            <ul className='dropdown_list'>
                {(options || []).map((option, index) => {
                    return (
                        <li
                            key={index}
                            className='dropdown_option'
                            style={styles.option}
                            onClick={() => this.handleSelect(option)}
                        >
                            {this.getLabel(option, index)}
                        </li>
                    );
                })}
            </ul>
        );
    };

    /**
     * Render Read More
     * @returns {*}
     */
    render() {
        const {className, theme, value, options, optionKey, placeholder, disabled} = this.props;
        const {expanded, noData} = this.state;

        const styles = this.getStyles();
        const label = value ? (optionKey ? (value[optionKey] || placeholder || 'Select') : (value || placeholder || 'Select')) : (placeholder || 'Select');

        const baseClassName = getClassName('dropdown', [
            {condition: className, trueClassName: className},
            {condition: theme, trueClassName: `dropdown--${theme}`},
            {condition: expanded, trueClassName: 'dropdown--expanded'},
            {condition: disabled, trueClassName: 'dropdown--disabled'},
            {condition: value, falseClassName: 'dropdown--empty'}
        ]);

        return (
            <div className={baseClassName} style={styles.base} ref={this.componentReference}>
                <div className='dropdown_inner' style={styles.inner} onClick={this.handleToggle}>
                    <span className='dropdown_value'>{label}</span>
                    <div className='dropdown_toggle' style={styles.toggle}>
                        <i className='q4i-arrow-down-4pt'/>
                    </div>
                </div>
                {ReactDOM.createPortal((
                    <div className={baseClassName}>
                        <div className='dropdown_mask' style={styles.mask} onClick={this.handleCollapse}/>
                        <div className='dropdown_options' style={styles.options}>
                            <div className='dropdown_filler' style={styles.filler} onClick={this.handleCollapse}/>
                            {noData ? (
                                <span className='dropdown_no-data-text' style={styles.option}>No Data Available</span>
                            ) : this.renderOptions(options, styles)}
                        </div>
                    </div>
                ), this.optionContainer)}
            </div>
        );
    }
}

Dropdown.propTypes = {
    /**
     * A custom className to add to the component
     */
    className: PropTypes.string,

    /**
     * Used to paint the dropdown using a specific theme
     */
    theme: PropTypes.string,

    /**
     * The width of the dropdown component
     */
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * The height of the dropdown component
     */
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * The height of the items within the dropdown component
     */
    itemHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * The height of the dropdown component's results container
     */
    dropdownHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * Used to overwrite default logic of calculating height based on item length if dropdownHeight exceeds the calculated total
     */
    forceDropdownHeight: PropTypes.bool,

    /**
     * The color used to pain the dropdown component's mask
     * The mask is visible when the dropdown is expanded
     */
    maskColor: PropTypes.string,

    /**
     * Opacity for the dropdown component's mask
     * The mask is visible when the dropdown is expanded
     */
    maskOpacity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * The z-index of the dropdown component
     */
    zIndex: PropTypes.number,

    /**
     * The active value of the dropdown component
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

    /**
     * An array of options available to select using the dropdown component
     * If supplying an array of objects, provide an optionKey to determine what is used as a label
     */
    options: PropTypes.array.isRequired,

    /**
     * When providing an array of objects, used to specify the object's target key to use as the dropdown option label
     */
    optionKey: PropTypes.string,

    /**
     * A method used to render options within the dropdown component
     */
    customOptionRender: PropTypes.func,

    /**
     * Used to specify what label is shown when no selected value is present
     */
    placeholder: PropTypes.string,

    /**
     * Used to disable interaction with the dropdown component
     */
    disabled: PropTypes.bool,

    /**
     * A callback for when the user selects an option using the dropdown component
     * Passes selected option as an argument
     */
    onSelect: PropTypes.func.isRequired
};

Dropdown.defaultProps = {
    theme: 'pale-grey',
    width: 140,
    height: 40,
    itemHeight: 40,
    forceDropdownHeight: false,
    zIndex: 5,
    placeholder: 'Select'
};

export default Dropdown;
