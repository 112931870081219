export const STORE_REPORT_DATA_VISUALIZATION = 'STORE_REPORT_DATA_VISUALIZATION'
export const RESET_REPORT_DATA_VISUALIZATION = 'RESET_REPORT_DATA_VISUALIZATION'

export const storeReportDataVisualization = (config) => (dispatch) => {
  dispatch({ type: STORE_REPORT_DATA_VISUALIZATION, payload: config })
}

export const resetReportDataVisualization = () => (dispatch) => {
  dispatch({ type: RESET_REPORT_DATA_VISUALIZATION })
}
