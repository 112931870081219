import { combineReducers } from 'redux'
import detail from './detail'
import filter from './filter.reducer'
import savedSearch from './savedSearch.reducer'
import allSavedTargets from './allSavedTargets.reducer'
import paginatedSavedTargets from './paginatedSavedTargets.reducer'
import search from './search.reducer'
import target from './target.reducer'
import flyout from './flyout.reducer'

export default combineReducers({
  detail,
  filter,
  savedSearch,
  allSavedTargets,
  paginatedSavedTargets,
  flyout,
  search,
  target

})
