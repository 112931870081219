import { combineReducers } from 'redux'
import activityList from './activityList.reducer'
import attendee from './attendee.reducer'
import itinerary from './itinerary'
import activity from './activity.reducer'
import activityForm from './form.reducer'
import activityParticipants from './activityParticipants.reducer'
import activityDeal from './activityDeal.reducer'
import activityLocation from './activityLocation.reducer'

export default combineReducers({
  activity,
  attendee,
  itinerary,
  activityForm,
  activityList,
  activityParticipants,
  activityDeal,
  activityLocation
})
