import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { getClassName } from '../../../utils/ui/ui.util'
import './pageWidget.component.css'

/**
 * Page Widget Component
 */
class PageWidget extends PureComponent {

  /**
   * Handle PageWidget actions onMouseDown event
   * @param event
   */
  handleActionsMouseDown = (event) => {
    event.stopPropagation()
  }

  /**
   * Handle onEdit event
   * @param event
   */
  handleEdit = (event) => {
    event && event.stopPropagation()

    const { index, onEdit } = this.props
    onEdit && onEdit(index)
  }

  /**
   * Handle duplicate event
   * @param event
   */
  handleDuplicate = (event) => {
    event && event.stopPropagation()

    const { index, onDuplicate } = this.props
    onDuplicate && onDuplicate(index)
  }

  /**
   * Handle onRemove event
   * @param event
   */
  handleRemove = (event) => {
    event && event.stopPropagation()

    const { index, onRemove } = this.props
    onRemove && onRemove(index)
  }

  /**
   * Render PageWidget Component
   * @returns {*}
   */
  render () {
    const { children, isSelected } = this.props

    const baseClassName = getClassName('page-widget', [
      { condition: isSelected, trueClassName: 'page-widget--selected' },
      { condition: children, falseClassName: 'page-widget--empty' }
    ])

    return (
      <article className={baseClassName}>
        {children && (
          <ul className='page-widget_actions' onMouseDown={this.handleActionsMouseDown}>
            <li className='page-widget_action' onClick={this.handleEdit}>
              <i className='q4i-edit-4pt' />
            </li>
            <li className='page-widget_action' onClick={this.handleDuplicate}>
              <i className='q4i-clone-duplicate-4pt' />
            </li>
            <li className='page-widget_action' onClick={this.handleRemove}>
              <i className='q4i-trashbin-4pt' />
            </li>
          </ul>
        )}
        <div className='page-widget_inner'>
          {children || (
            <h4 className='page-widget_error'>Woops. Something went wrong.</h4>
          )}
        </div>
      </article>
    )
  }
}

PageWidget.propTypes = {
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isSelected: PropTypes.bool,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onDuplicate: PropTypes.func
}

export default PageWidget