import {
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  RESET,
  statusType
} from '../../actions'

const initial = {
  initialLoading: true,
  data: [],
  status: statusType.IDLE
}

const events = (state = initial, action) => {
  const { payload, error } = action

  switch (action.type) {
    case GET_EVENTS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        data: payload,
        status: statusType.SUCCESS,
        initialLoading: false
      }

    case GET_EVENTS_FAILURE:
      return {
        error,
        status: statusType.ERROR,
        initialLoading: false
      }

    case RESET:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default events
