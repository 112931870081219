import React, { memo, useMemo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

// components
import { Banner, AIScore, EntityScore } from '../../../../../../../components'
import { ENTITY_TYPE, QR, THEMES } from '../../../../../../../utils'
import { capitalize } from 'lodash'

const { FUND } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string.isRequired,
  fund: PropTypes.object.isRequired,
  targetId: PropTypes.string,
  hasActiveDeal: PropTypes.bool,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  openModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDealSaveSuccess: PropTypes.func,
}

const defaultProps = {
  dataIdPrefix: 'FundPage',
  fund: {},
  hasActiveDeal: false,
  theme: THEMES.DARK,
}

/**
 * Banner Detail Component
 * @param institution
 * @param institutionId
 * @param type
 * @param style
 * @param turnover
 */
const Details = ({ institution, institutionId, type, style, turnover }) => {
  return (
    <>
      <Link className='banner_details--link' to={`/institution/${institutionId}`}>{institution}</Link>
      {institution && (type || style || turnover) && <br />}
      {type ? <span>{type}</span> : null}
      <span className={`${type ? 'divider' : ''}`}>Style: {style || '-'}</span>
      <span className='divider'>Turnover: {turnover ? capitalize(turnover) : '-'}</span>
    </>
  )
}

/**
 * Fund Banner Component
 * @param props
 */
function FundBanner (props) {
  const { dataIdPrefix, fund, openModal, targetId, hasActiveDeal, theme, onClose, onDealSaveSuccess } = props
  const { id, institutionId, fundName, institutionName, fundTypeDesc, style, turnover, qualityRating } = fund

  const controlTheme = useMemo(() => theme !== defaultProps.theme ? theme : undefined, [theme])

  const controls = [
    {
      type: 'score',
      ScoreComponent: <AIScore
        dataId={dataIdPrefix}
        entityId={id}
        entityType={FUND}
        style={style}
        theme={controlTheme}
      />
    },
    {
      type: 'score',
      ScoreComponent: <EntityScore
        dataId={`${dataIdPrefix}QrScore`}
        data={{
          title: 'QR Score',
          score: qualityRating,
          message: QR
        }}
        theme={controlTheme}
        openModal={openModal}
      />
    },
    {
      dataId: `${dataIdPrefix}BannerUtility`,
      type: 'utility',
      entity: { ...fund, _target: targetId },
      entityType: FUND,
      theme: controlTheme,
      items: [
        { action: 'CREATE_ACTIVITY' },
        {
          action: 'TARGET',
          hide: hasActiveDeal
        },
        {
          action: 'CREATE_DEAL',
          onSaveSuccess: onDealSaveSuccess,
          hide: !onDealSaveSuccess,
        },
        { action: 'DOWNLOAD_TEARSHEET' },
        { action: 'BRIEFING_BOOK' }
      ]
    },
    {
      type: 'button',
      theme,
      square: true,
      icon: 'q4i-close-4pt',
      onClick: onClose
    }
  ]

  return (
    <Banner
      size='medium'
      icon='q4i-fund-2pt'
      title={fundName}
      theme={theme}
      details={
        <Details
          institution={institutionName}
          institutionId={institutionId}
          type={fundTypeDesc}
          style={style}
          turnover={turnover}
        />
      }
      controls={controls}
    />
  )
}

FundBanner.propTypes = propTypes
FundBanner.defaultProps = defaultProps

export default memo(FundBanner)
