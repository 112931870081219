import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'

// actions
import { useDealQuery, useDealDeleteQuery, usePipelineQuery } from '../hook'
import { openModal, closeModal, modalType } from '../../../actions'

// components
import { ActivityGrid, Spinner } from '../../../components'
import DealBanner from './banner/banner.component'
import DealOverview from './overview/overview.component'

// utils
import { DEFAULT_TICKER, getActiveTicker, getOwnershipType, THEMES } from '../../../utils'
import { get } from 'lodash'

const dataIdPrefix = 'DealDetailPage'

const propTypes = {
  history: PropTypes.object.isRequired
}

const defaultProps = {}

/**
 * Deal Detail Page
 * @param props
 */
function DealDetailPage (props) {
  const { match, stock, tickerId, securityId, ownershipType, openModal, closeModal, history } = props
  const dealId = get(match, 'params.id')

  const { data: pipeline } = usePipelineQuery()
  const { loading, error, data, refetch } = useDealQuery({
    variables: {
      id: dealId,
      tickerId,
      securityId,
      source: (ownershipType.value === 'surveillance') ? '13f' : ownershipType.value, // TODO: remove condition when surveillance migrated
      quarters: 1
    }
  })
  const [handleDelete, { loading: deleteProgress }] = useDealDeleteQuery()

  const deal = get(data, 'deal.items[0]')
  const { id, title, entity, activity } = (deal || {})

  /**
   * Handle deal update
   */
  const handleDealEdit = () => {
    openModal({
      type: modalType.DEAL_EDIT_MODAL,
      props: {
        deal,
        onSaveSuccess: refetch
      }
    })
  }

  /**
   * Handle deal delete
   */
  const handleDealDelete = () => {
    closeModal({ type: modalType.CONFIRM_MODAL })

    handleDelete({ variables: { id } }).then((response) =>
      get(response, 'errors') ? handleFailure() : history.push('/pipeline'))
  }

  /**
   * On Action Completion Failure display an error
   */
  function handleFailure () {
    openModal({ type: modalType.ERROR_MODAL })
  }

  return (
    <>
      {(error || (data && !id)) && <Redirect to='/error/404' />}
      {([loading, deleteProgress].some((item) => !!item)) && <Spinner mask theme={THEMES.DARK} />}
      {!!id && (
        <div className='deal-detail-page'>
          <DealBanner
            dataIdPrefix={dataIdPrefix}
            title={title}
            onEdit={handleDealEdit}
            onDelete={handleDealDelete}
            openModal={openModal}
          />
          <DealOverview
            dataIdPrefix={dataIdPrefix}
            deal={deal}
            pipeline={get(pipeline, 'pipeline.items', [])}
            stock={stock}
            subscription={(ownershipType.value === 'surveillance') ? '13F' : ownershipType.label} // TODO: remove condition when surveillance migrated
            tickerId={tickerId}
          />
          <ActivityGrid
            dataIdPrefix={dataIdPrefix}
            id={activity}
            deal={id}
            entity={entity}
            showChildren
          />
        </div>
      )}
    </>
  )
}

DealDetailPage.propTypes = propTypes
DealDetailPage.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)

  return {
    stock: get(state, 'stock.quote.data.last', 0),
    tickerId: get(ticker, 'q4_ticker_id', DEFAULT_TICKER), // TODO: tmp default value for pre-IPO
    securityId: get(ticker, 'q4_entity_id', '1234567890'), // TODO: tmp default value for pre-IPO
    ownershipType: getOwnershipType(profile)
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(DealDetailPage)
