import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// components
import {
  SecurityAttribute,
  SecurityStockOverview,
  SecurityOwnership,
  SecurityRecentUpdate,
  TagInput,
  TopMovers
} from '../../../components'

// utils
import { ENTITY_TYPE } from '../../../utils'

const { SECURITY } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string.isRequired,
  security: PropTypes.object.isRequired,
  legacySecurityId: PropTypes.string,
  stock: PropTypes.object.isRequired,
  isStockLoading: PropTypes.bool
}

const defaultProps = {
  security: {},
  stock: {},
  isStockLoading: false
}

/**
 * Security Overview Component
 * @param props
 */
function SecurityOverview (props) {
  const { dataIdPrefix, security, legacySecurityId, stock, isStockLoading } = props
  const { tickerId } = security

  return (
    <div className='overview-layout'>
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <SecurityAttribute
                dataId={dataIdPrefix}
                isLoading={isStockLoading}
                data={stock}
              />
            </Grid>

            <Grid item xs={8}>
              <SecurityRecentUpdate
                dataId={dataIdPrefix}
                tickerId={tickerId}
                legacySecurityId={legacySecurityId}
              />
            </Grid>

            <Grid item xs={12}>
              <SecurityStockOverview
                dataId={dataIdPrefix}
                tickerId={tickerId}
              />
            </Grid>

            <Grid item xs={8}>
              <SecurityOwnership
                dataId={dataIdPrefix}
                tickerId={tickerId}
              />
            </Grid>

            <Grid item xs={4}>
              <TopMovers
                dataId={dataIdPrefix}
                entityId={tickerId}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TagInput
            dataId={dataIdPrefix}
            entityId={tickerId}
            entityType={SECURITY}
          />
        </Grid>

      </Grid>
    </div>
  )
}

SecurityOverview.propTypes = propTypes
SecurityOverview.defaultProps = defaultProps

export default memo(SecurityOverview)
