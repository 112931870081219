import moment from 'moment-timezone'
import {
  GET_QUICK_SEARCH_REQUEST,
  GET_QUICK_SEARCH_SUCCESS,
  GET_QUICK_SEARCH_FAILURE,
  statusType,
  CLEAR_QUICK_SEARCH
} from '../../actions'

const initial = {
  data: [],
  status: statusType.IDLE,
  timestamp: null
}

function actionIsValid (state, data) {
  return data.timestamp >= state.timestamp
}

const quickSearch = (state = initial, action) => {
  const { error, payload, timestamp } = action
  switch (action.type) {
    case GET_QUICK_SEARCH_REQUEST:
      state.timestamp = timestamp
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_QUICK_SEARCH_SUCCESS:
      if (actionIsValid(state, action)) {
        return {
          ...state,
          data: payload,
          status: statusType.SUCCESS,
          timestamp
        }
      }
      return state

    case GET_QUICK_SEARCH_FAILURE:
      return {
        error,
        status: statusType.ERROR
      }

    case CLEAR_QUICK_SEARCH:
      return {
        ...initial,
        timestamp: moment.utc().valueOf()
      }

    default:
      return state
  }
}

export default quickSearch
