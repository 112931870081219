import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// components
import { Checkbox, TextArea, TextField } from '../../../../form'
import { get } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  cover: PropTypes.shape({
    title: PropTypes.string,
    notes: PropTypes.string
  }).isRequired,
  settings: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onCoverChange: PropTypes.func.isRequired
}

const defaultProps = {
  cover: {},
  settings: {}
}

/**
 * Template Cover
 * @param props
 */
function TemplateCover (props) {
  const { dataId, cover, settings, onChange, onCoverChange } = props
  const { isCover } = settings
  const { title, note } = cover

  const handleCoverChange = (event, type) => onCoverChange({ ...cover, [type]: get(event, 'target.value') })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Checkbox
          dataId={`${dataId}Cover`}
          id='cover'
          label='Cover Page'
          isSelected={isCover}
          onChange={(value) => onChange({ ...settings, isCover: value })}
        />
      </Grid>
      {!isCover ? <hr className='field-separator field-separator--short' /> : null}
      {!!isCover && (
        <>
          <Grid item xs={12}>
            <div className='field field--text field--full'>
              <TextField
                dataId={`${dataId}Title`}
                label='Title'
                placeholder='Type cover title'
                value={title || ''}
                onChange={(event) => handleCoverChange(event, 'title')}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className='field field--text field--full'>
              <TextArea
                dataId={`${dataId}Notes`}
                label='Note'
                placeholder='Type notes (max 700 characters)'
                value={note || ''}
                maxLength={700}
                onChange={(event) => handleCoverChange(event, 'note')} />
            </div>
          </Grid>
        </>
      )}
    </Grid>
  )
}

TemplateCover.propTypes = propTypes
TemplateCover.defaultProps = defaultProps

export default memo(TemplateCover)
