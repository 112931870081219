import gql from 'graphql-tag'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'

const GET_FUND = gql`
  query Fund($id: [String]!, $currencyCode: String) {
    fund(id: $id, currencyCode: $currencyCode) {
      items {
        id
        fundName
        fundTypeDesc
        style
        turnover
        institutionId
        institutionName
        portfolioValue
        equityAUM
        qualityRating
        active
      }
    }
  }`

const GET_TARGET = gql`
  query Target($entityId: [String]!) {
    target(entityId: $entityId) {
      items {
        id
      }
    }
  }`

const GET_PIPELINE = gql`
  query Pipeline {
    pipeline {
      items {
        id
        index
        label
        lost
      }
    }
  }`

export const GET_DEALS = gql`
  query Deal(
    $entityId: String!
    $limit: Int! = ${10}
  ) {
    deal(
      entityId: $entityId
      limit: $limit
    ) {
      items {
        id
        title
        stage
      }
    }
  }`

/**
 * Fund by Id
 * @param options
 */
export function useFundQuery (options) {
  return useQuery(GET_FUND, options)
}

/**
 * Target
 * @param options
 */
export function useTargetQuery (options) {
  return useLazyQuery(GET_TARGET, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Pipeline
 * @param options
 */
export function usePipelineQuery (options) {
  return useQuery(GET_PIPELINE, options)
}

/**
 * Deal
 * @param options
 */
export function useDealQuery (options) {
  return useLazyQuery(GET_DEALS, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}
