import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

const CREATE_BRIEFING_BOOK = gql`
  mutation BriefingBook(
    $type: BriefingBookTypeDTO!
    $title: String!
    $entity: [BriefingBookEntity]!
  ) {
    briefingBook {
      create(
        type: $type
        title: $title
        entity: $entity
      ) {
        items {
          id
        }
        count
      }
    }
  }`

export const UPDATE_BRIEFING_BOOK = gql`
  mutation BriefingBook(
    $id: String!
    $title: String
    $entity: [BriefingBookEntity]
  ) {
    briefingBook {
      update(
        id: $id
        title: $title
        entity: $entity
      ) {
        count
      }
    }
  }`

/**
 * Briefing Book Create
 * @param options
 */
export function useBriefingBookCreateQuery (options) {
  return useMutation(CREATE_BRIEFING_BOOK, options)
}

/**
 * Briefing Book Update
 * @param options
 */
export function useBriefingBookUpdateQuery (options) {
  return useMutation(UPDATE_BRIEFING_BOOK, options)
}
