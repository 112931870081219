import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Dragula from 'react-dragula';
import {getClassName} from '../../../utils/ui/ui.util';
import {Scrollbars} from 'react-custom-scrollbars';
import {renderDarkThumb, renderTrackVertical} from '../../../resources/theme/q4.custom-scrollbar';
import {Button} from '../../../components';
import PageManagerItem from './item/pageManagerItem.component';
import './pageManager.component.css';

/**
 * Pages Manager Component
 * Add, Remove, Edit, and Re-order Pages
 */
class PageManager extends PureComponent {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            isCollapsed: false,
            isPageLimitReached: false
        };

        this.pageItemContainer = React.createRef();
    }

    /**
     * ComponentDidUpdate
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {pages, pageLimit, selectedPageIndex} = this.props;

        if (!isNaN(pageLimit)) {
          const pageCount = pages ? pages.length : 0;

          this.setState({
            isPageLimitReached: pageCount >= pageLimit
          })
        }

        // new page added
        if (pages.length > prevProps.pages.length && selectedPageIndex > prevProps.selectedPageIndex) {
            this.handleScrollToSelectedPage();
        }
    }

    /**
     * Decorator for Dragula framework
     * @param componentBackingInstance
     */
    dragulaDecorator = (componentBackingInstance) => {
        const me = this;
        const {onPageMove} = this.props;

        if (componentBackingInstance) {
            const options = {
                direction: 'vertical',
                moves: function (element) {
                    const {pages} = me.props;
                    const classList = (element && element.classList) || [];
                    const isSelectedItem = classList.contains('page-manager-item--selected');

                    return (pages && pages.length && pages.length > 1) && isSelectedItem;
                },
                invalid: (element, handle) => {
                    return handle.tagName === 'BUTTON';
                }
            };

            Dragula([componentBackingInstance], options)
                .on('drag', (element, source) => {
                    this.dragIndex = Array.prototype.indexOf.call(source.childNodes, element);
                })
                .on('drop', (element, target, source) => {
                    this.dropIndex = Array.prototype.indexOf.call(source.childNodes, element);
                    onPageMove && onPageMove(this.dragIndex, this.dropIndex);
                });
        }
    };

    /**
     * Handle 'Add Page' button click event
     */
    handleAddButtonClick = () => {
        const {onPageAdd} = this.props;
        const {isPageLimitReached} = this.state;

        if (isPageLimitReached) {
          return;
        }

        onPageAdd && onPageAdd();
    };

    /**
     * Handle 'Duplicate Page' popovermenu item click event
     * @param pageId
     */
    handlePageDuplicateClick = (pageId) => {
        const {onPageDuplicate} = this.props;
        const {isPageLimitReached} = this.state;

        if (isPageLimitReached) {
          return;
        }

        onPageDuplicate && onPageDuplicate(pageId);
    }

    /**
     * Handle collapsing and expanding of the Pages Manager
     */
    handleCollapseToggle = () => {
        const {onSizeToggle} = this.props;
        const {isCollapsed} = this.state;

        this.setState({
            isCollapsed: !isCollapsed
        }, () => {
            onSizeToggle();
        });
    };

    /**
     * Trigger Scrollbars scrollTo method to scroll to the selected page
     */
    handleScrollToSelectedPage = () => {
        const Scrollbars = this.pageItemContainer && this.pageItemContainer.current;

        if (!Scrollbars) {
            return;
        }

        const {pageLayout, selectedPageIndex} = this.props;
        const {isCollapsed} = this.state;

        const scrollHeight = Scrollbars.getClientHeight();
        const scrollTop = Scrollbars.getScrollTop();
        const pageItemMargin = 14;
        const pageItemHeight = (isCollapsed ? 30 : pageLayout === 'landscape' ? 100 : 240) + pageItemMargin;
        const selectedPageTop = pageItemHeight * ((selectedPageIndex || 0));

        if (selectedPageTop >= (scrollTop + scrollHeight - pageItemMargin)) {
            return Scrollbars.scrollTop(selectedPageTop + pageItemHeight - scrollHeight + pageItemMargin);
        }

        if (selectedPageTop <= scrollTop) {
            return Scrollbars.scrollTop(selectedPageTop);
        }
    };

    /**
     * Render Widget Library
     * @returns {*}
     */
    render() {
        const {pageLayout, pages, selectedPageIndex, onPageSelect, onPageRemove} = this.props;
        const {isCollapsed, isPageLimitReached} = this.state;

        const baseClassName = getClassName('page-manager', [
            {condition: pageLayout, trueClassName: `page-manager--${pageLayout}`},
            {condition: isCollapsed, trueClassName: 'page-manager--collapsed'}
        ]);

        return (
            <section className={baseClassName}>
                <header className='page-manager_header'>
                    <div className='page-manager_collapse-toggle'>
                        <Button
                            theme='soft-grey'
                            square={true}
                            tall={false}
                            icon='q4i-caret-sm-left-4pt'
                            styles={{
                                icon: isCollapsed ? {transform: 'rotate(180deg)'} : null
                            }}
                            onClick={this.handleCollapseToggle}
                        />
                    </div>
                    <div className='page-manager_add-button'>
                        <Button
                            theme='soft-grey'
                            square={isCollapsed}
                            tall={false}
                            icon={isCollapsed ? 'q4i-plus-4pt' : ''}
                            label={isCollapsed ? '' : 'Add Page'}
                            disabled={isPageLimitReached}
                            onClick={this.handleAddButtonClick}
                        />
                    </div>
                    </header>
                <section className='page-manager_body'>
                    {(pages && pages.length > 0) && (
                        <Scrollbars
                            ref={this.pageItemContainer}
                            className='react-scrollbar'
                            autoHide
                            hideTracksWhenNotNeeded
                            renderThumbVertical={renderDarkThumb}
                            renderTrackVertical={renderTrackVertical}
                        >
                            <div className='page-manager_list' ref={this.dragulaDecorator}>
                                {pages.map((page, index) => {
                                    const {_id, tempId, thumbnail} = page;
                                    const isSelected = selectedPageIndex === index;
                                    const pageId = _id || tempId;

                                    return (
                                        <PageManagerItem
                                            key={`page-manager-item--${pageId}`}
                                            index={index}
                                            id={pageId}
                                            pageLayout={pageLayout}
                                            gridLayout={page.layout.template}
                                            thumbnail={thumbnail}
                                            isCollapsed={isCollapsed}
                                            isSelected={isSelected}
                                            isPageLimitReached={isPageLimitReached}
                                            onPageSelect={onPageSelect}
                                            onPageDuplicate={this.handlePageDuplicateClick}
                                            onPageRemove={onPageRemove}
                                        />
                                    );
                                })}
                            </div>
                        </Scrollbars>
                    )}
                </section>
            </section>
        );
    }
}

PageManager.propTypes = {
    pages: PropTypes.array.isRequired,
    selectedPageIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pageLayout: PropTypes.oneOf(['landscape', 'portrait']),
    pageLimit: PropTypes.number,
    onSizeToggle: PropTypes.func.isRequired,
    onPageSelect: PropTypes.func.isRequired,
    onPageAdd: PropTypes.func.isRequired,
    onPageDuplicate: PropTypes.func.isRequired,
    onPageRemove: PropTypes.func.isRequired,
    onPageMove: PropTypes.func.isRequired
};

PageManager.defaultProps = {
    pages: [],
    pageLayout: 'landscape'
};

export default PageManager;
