
import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const GET_ENTITY_SUMMARY_REQUEST = 'GET_ENTITY_SUMMARY_REQUEST'
export const GET_ENTITY_SUMMARY_SUCCESS = 'GET_ENTITY_SUMMARY_SUCCESS'
export const GET_ENTITY_SUMMARY_FAILURE = 'GET_ENTITY_SUMMARY_FAILURE'

export const SAVE_ENTITY_SUMMARY_REQUEST = 'SAVE_ENTITY_SUMMARY_REQUEST'
export const SAVE_ENTITY_SUMMARY_SUCCESS = 'SAVE_ENTITY_SUMMARY_SUCCESS'
export const SAVE_ENTITY_SUMMARY_FAILURE = 'SAVE_ENTITY_SUMMARY_FAILURE'

export const DELETE_ENTITY_SUMMARY_REQUEST = 'DELETE_ENTITY_SUMMARY_REQUEST'
export const DELETE_ENTITY_SUMMARY_SUCCESS = 'DELETE_ENTITY_SUMMARY_SUCCESS'
export const DELETE_ENTITY_SUMMARY_FAILURE = 'DELETE_ENTITY_SUMMARY_FAILURE'

export const RESET_ENTITY_SUMMARY = 'RESET_ENTITY_SUMMARY'

/**
 * Get Entity Summary (Comment)
 * @param id - entity's mongo id
 * @private
 */
const _getEntitySummary = (id) => ({
  [CALL_API]: {
    types: [
      GET_ENTITY_SUMMARY_REQUEST,
      GET_ENTITY_SUMMARY_SUCCESS,
      GET_ENTITY_SUMMARY_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/entitysummary/${id}`
  }
})

/**
 * Save Entity Summary (Comment)
 * @param id - entity's mongo id
 * @param entity
 * @param comment
 * @private
 */
const _saveEntitySummary = (id, entity, comment) => ({
  [CALL_API]: {
    types: [
      SAVE_ENTITY_SUMMARY_REQUEST,
      SAVE_ENTITY_SUMMARY_SUCCESS,
      SAVE_ENTITY_SUMMARY_FAILURE
    ],
    method: METHOD_TYPE.PUT,
    endpoint: `/entitysummary/${id}`,
    payload: {
      entity,
      body: comment
    }
  }
})

/**
 * Delete Entity Summary (Comment)
 * @param id - entity's mongo id
 * @private
 */
const _deleteEntitySummary = (id) => ({
  [CALL_API]: {
    types: [
      DELETE_ENTITY_SUMMARY_REQUEST,
      DELETE_ENTITY_SUMMARY_SUCCESS,
      DELETE_ENTITY_SUMMARY_FAILURE
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: `/entitysummary/${id}`
  }
})

/**
 * Dispatch GET_ENTITY_SUMMARY_REQUEST
 * @param id
 */
export const getEntitySummary = (id) => (dispatch) => {
  return dispatch(_getEntitySummary(id))
}

/**
 * Get entity object based on id length
 * @param entityId
 * @param type
 * @return {{}|*}
 * @private
 */
const _getEntity = (entityId, type) => {
  const isQ4Id = entityId && entityId.length === 32
  return isQ4Id
    ? { entityId: entityId, type }
    : { item: entityId, type }
}

/**
 * Dispatch SAVE_ENTITY_SUMMARY_REQUEST
 * @param id - contact's mongo id
 * @param type - entity type
 * @param comment
 */
export const saveEntitySummary = (id, type, comment) => (dispatch) => {
  return dispatch(_saveEntitySummary(id, _getEntity(id, type), comment))
}

/**
 * Dispatch DELETE_ENTITY_SUMMARY_REQUEST
 * @param id - contact's mongo id
 */
export const deleteEntitySummary = (id) => (dispatch) => {
  return dispatch(_deleteEntitySummary(id))
}

/**
 * Dispatch RESET_ENTITY_SUMMARY
 */
export const resetEntitySummary = () => (dispatch) => {
  return dispatch({ type: RESET_ENTITY_SUMMARY })
}
