import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// components
import {
  EntityAttribute,
  EntityBadge,
  EntityHighlight,
  TagInput
} from '../../../../../../../components'
import { TopManagers } from '../../components'
import { ENTITY_TYPE } from '../../../../../../../utils'

const { INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string.isRequired,
  theme: PropTypes.string,
  institution: PropTypes.object.isRequired,
  targetId: PropTypes.string,
  hasActiveDeal: PropTypes.bool,
  deals: PropTypes.shape({
    isLoading: PropTypes.bool,
    deals: PropTypes.array,
    pipeline: PropTypes.array
  }).isRequired,
  onDealSaveSuccess: PropTypes.func.isRequired,
  isAnalytics: PropTypes.bool,
  openModal: PropTypes.func.isRequired
}

const defaultProps = {
  dataIdPrefix: 'InstitutionPage',
  institution: {},
  hasActiveDeal: false,
  deals: {}
}

/**
 * Institution Overview Component
 * @param props
 */
function InstitutionOverview (props) {
  const { dataIdPrefix, theme, institution, targetId, hasActiveDeal, isAnalytics, handleRowClick } = props
  const { id, totalAUM, equityAUM, activist } = institution

  return (
    <div className='overview-layout overview-layout--flyout'>
      <Grid container spacing={3}>
        <EntityBadge
          dataId={dataIdPrefix}
          theme={theme}
          data={{
            targetId,
            hasActiveDeal,
            activist,
            isAnalytics
          }}
        />
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <EntityAttribute
                dataId={dataIdPrefix}
                theme={theme}
                entityId={id}
                entityType={INSTITUTION}
                data={{
                  aum: totalAUM,
                  equityAUM
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <EntityHighlight
                dataId={dataIdPrefix}
                theme={theme}
                entityId={id}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TopManagers
            entity={institution}
            handleRowClick={handleRowClick}
          />
        </Grid>

        <Grid item xs={12}>
          <TagInput
            dataId={dataIdPrefix}
            theme={theme}
            entityId={id}
            entityType={INSTITUTION}
          />
        </Grid>

      </Grid>
    </div>
  )
}

InstitutionOverview.propTypes = propTypes
InstitutionOverview.defaultProps = defaultProps

export default memo(InstitutionOverview)
