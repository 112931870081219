import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Message } from '../../components'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string
}

/**
 * General gdpr Modal
 * @param onClose
 * @param title
 * @param message
 */
const ConfirmNavigationModal = ({ onClose, handleCancel , handleConfirm , title, message }) => {
  return (
    <Message
      visible
      type='error'
      title={title}
      message={message}
      useHtml
      onClose={onClose}
      renderExitButton
      buttons={[{
        ui: 'dark-slate',
        label: 'CANCEL',
        onClick: handleCancel
      },{
        ui: 'spice',
        label: 'CONFIRM',
        onClick: handleConfirm
      }
      ]}
    />
  )
}

ConfirmNavigationModal.propTypes = propTypes

export default memo(ConfirmNavigationModal)
