import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

/**
 * Single News Action Type Constants
 * @type {string}
 */
export const GET_NEWS_MODAL_REQUEST = 'GET_NEWS_REQUEST'
export const GET_NEWS_MODAL_SUCCESS = 'GET_NEWS_SUCCESS'
export const GET_NEWS_MODAL_FAILURE = 'GET_NEWS_FAILURE'

/**
 * Get News
 * Returns single news item by id
 * @param id
 */
const _getNewsModal = (id) => ({
  [CALL_API]: {
    types: [
      GET_NEWS_MODAL_REQUEST,
      GET_NEWS_MODAL_SUCCESS,
      GET_NEWS_MODAL_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/news/${id}`
  }
})

/**
 * Dispatch GET_NEWS_MODAL_REQUEST
 * @param id
 */
export const getNewsModal = (id) => (dispatch) => {
  dispatch(_getNewsModal(id))
}
