import {
  GET_ADDRESS_BOOK_LISTS_REQUEST,
  GET_ADDRESS_BOOK_LISTS_SUCCESS,
  GET_ADDRESS_BOOK_LISTS_FAILURE,
  GET_ADDRESS_BOOK_LIST_COUNT_SUCCESS,
  statusType
} from '../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const lists = (state = initial, action) => {
  const { payload = {} } = action

  switch (action.type) {
    case GET_ADDRESS_BOOK_LISTS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_ADDRESS_BOOK_LISTS_SUCCESS:
      return {
        ...state,
        data: [
          ...initial.data,
          ...payload
        ],
        status: statusType.SUCCESS
      }

    case GET_ADDRESS_BOOK_LISTS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case GET_ADDRESS_BOOK_LIST_COUNT_SUCCESS:
      return {
        ...state,
        count: (payload || []).reduce((acc, record) => {
          const listId = record._id || 'all'
          acc[listId] = record.count || 0
          return acc
        }, {}),
        status: statusType.SUCCESS
      }

    default:
      return state
  }
}

export default lists
