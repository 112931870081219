import React, {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './dropdown.component.css';
import DropdownToggle from './dropdownToggle/dropdownToggle.component';

class Dropdown extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            dropdownInput: props.input
        };

        this.dropdownToggleRef = React.createRef();
        this.componentRef = React.createRef();
        this.appBodyContainer = document.getElementsByTagName('body')[0];
        this.optionContainer = document.createElement('div');
        this.optionContainer.className = 'dropdown-portal';
        this.toggleCount = 1;
    }

    componentWillUnmount() {
        if (this.state.expanded) {
            this.appBodyContainer.removeChild(this.optionContainer);
        }
    }

    resetValue() {
        if (this.dropdownToggleRef) {
            this.dropdownToggleRef.current.resetValue();
        }
    }

    /**
     * Expand/collapse the select list.
     */
    handleToggle = () => {
        const {disabled} = this.props;

        if (!disabled) {
            const {expanded} = this.state;
            expanded ? this.handleCollapse() : this.handleExpand();
        }
    };

    /**
     * Expand the select list
     */
    handleExpand = () => {
        this.toggleCount++;
        this.setState({
            expanded: true
        }, () => {
            this.appBodyContainer.classList.add('overflow-hidden');
            this.appBodyContainer.appendChild(this.optionContainer);
        });
    };

    /**
     * Collapse the select list
     */
    handleCollapse = () => {
        const {onCollapse} = this.props;
        const {expanded} = this.state;
        const isOdd = this.toggleCount % 2;

        if (!isOdd) {
            this.toggleCount++;
        }

        if (expanded === true) {
            this.setState({
                expanded: false
            }, () => {
                this.appBodyContainer.classList.remove('overflow-hidden');
                this.appBodyContainer.removeChild(this.optionContainer);
            });
            onCollapse && onCollapse();
        }
    };

    /**
     * On select item callback
     */
    handleSelect = (value) => {
        const {onSelect, closeOnSelect} = this.props;
        onSelect && onSelect(value);
        if (closeOnSelect) {
            this.handleCollapse();
        }
    };

    /**
     * Renders the dropdown toggle (button or input)
     * @returns {*}
     */
    renderDropdownToggle() {
        const {
            theme,
            toggleTheme,
            label,
            value,
            toggleHeight,
            onInputBlur,
            onInputChange,
            onInputEnter,
            prepend,
            arrow,
            loading
        } = this.props;
        const {dropdownInput} = this.state;
        const child = (dropdownInput ? dropdownInput : label || 'Select');

        return (
            <DropdownToggle
                ref={this.dropdownToggleRef}
                value={value}
                theme={theme || toggleTheme}
                height={toggleHeight}
                onInputBlur={onInputBlur}
                onInputChange={onInputChange}
                onInputEnter={onInputEnter}
                toggleCount={this.toggleCount}
                handleToggle={this.handleToggle}
                handleCollapse={this.handleCollapse}
                arrow={arrow}
                loading={loading}
                prepend={prepend}>
                {child}
            </DropdownToggle>
        );
    }

    /**
     * Renders the dropdown list.
     * @returns {*}
     */
    renderDropdownList() {
        const {theme, listTheme, itemHeight, height, children, toggleHeight} = this.props;
        const {expanded} = this.state;

        return expanded && ReactDOM.createPortal(React.cloneElement(children, {
            handleCollapse: this.handleCollapse,
            handleSelect: this.handleSelect,
            dropdownRef: this.componentRef,
            theme: theme || listTheme,
            optionHeight: itemHeight,
            toggleHeight,
            height
        }), this.optionContainer);
    }

    render() {
        const {
            className,
            disabled,
            width,
            minWidth,
            itemHeight,
            toggleHeight,
            zIndex,
        } = this.props;

        const {
            expanded
        } = this.state;

        const baseStyles = {
            width,
            zIndex,
            minWidth,
            height: toggleHeight || itemHeight
        };

        const dropdownClass = [
            `${className ? `${className} dropdown` : 'dropdown'}`,
            `${expanded ? 'dropdown--expanded' : ''}`,
            `${disabled ? 'dropdown--disabled' : ''}`
        ].join(' ');

        return (
            <div className={dropdownClass} style={baseStyles} ref={this.componentRef}>
                {this.renderDropdownToggle()}
                {this.renderDropdownList()}
            </div>
        );
    }
}

Dropdown.propTypes = {
    className: PropTypes.string,
    theme: PropTypes.string,
    toggleTheme: PropTypes.string,
    listTheme: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.number,
    itemHeight: PropTypes.number,
    toggleHeight: PropTypes.number,
    zIndex: PropTypes.number,
    disabled: PropTypes.bool,
    closeOnSelect: PropTypes.bool,
    input: PropTypes.any,
    value: PropTypes.string,
    label: PropTypes.any,
    onInputEnter: PropTypes.func,
    onInputChange: PropTypes.func,
    onInputBlur: PropTypes.func,
    prepend: PropTypes.string,
    arrow: PropTypes.bool,
    loading: PropTypes.bool,
    onCollapse: PropTypes.func
};

Dropdown.defaultProps = {
    itemHeight: 40,
    toggleHeight: 40,
    toggleTheme: 'pale-grey',
    listTheme: 'silver',
    width: '100%',
    minWidth: 140,
    zIndex: 5,
    closeOnSelect: true,
    arrow: true,
    loading: false
};

export default Dropdown;