export const EVENT_TYPES = {
  PUBLIC_EVENT: 'public',
  ACTIVITY: 'activity'
}

export const PUBLIC_EVENT_TYPES = {
  EARNINGS_CALL: 'earningsCall',
  EARNINGS_RELEASE: 'earningsRelease',
  GUIDANCE_CALL: 'guidanceCall',
  SALES_CALL: 'salesCall',
  SALES_RELEASE: 'salesRelease',
  CONFERENCE: 'conference',
  PRESENTATION: 'presentation',
  MEETING: 'meeting',
  OTHER: 'other'
}

export const FACTSET_PUBLIC_EVENT_TYPES = {
  [PUBLIC_EVENT_TYPES.EARNINGS_CALL]: 'Earnings Call',
  [PUBLIC_EVENT_TYPES.EARNINGS_RELEASE]: 'Earnings Release',
  [PUBLIC_EVENT_TYPES.GUIDANCE_CALL]: 'Guidance Cal',
  [PUBLIC_EVENT_TYPES.SALES_CALL]: 'Sales and Revenue Call',
  [PUBLIC_EVENT_TYPES.SALES_RELEASE]: 'Sales and Revenue Release',
  [PUBLIC_EVENT_TYPES.CONFERENCE]: 'Conference',
  [PUBLIC_EVENT_TYPES.PRESENTATION]: 'Presentation',
  [PUBLIC_EVENT_TYPES.MEETING]: 'Analyst, Investor and Shareholder Meeting',
  [PUBLIC_EVENT_TYPES.OTHER]: 'Special Situation, M&A and Other'
}

export const CALENDAR_VIEWS = {
  MONTH: 'Month',
  WEEK: 'Week',
  DAY: 'Day',
  SCHEDULE: 'Agenda'
}

export const EVENT_DOCUMENT_LABEL = {
  WEBCAST_LIVE: 'Webcast',
  WEBCAST_REPLAY: 'Webcast (replay)',
  PRESENTATION: 'Presentation',
  TRANSCRIPT: 'Transcript',
  PRESS_RELEASE: 'Press Release'
}