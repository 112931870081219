import {
  GET_INSTITUTION_CURRENT_HOLDINGS_REQUEST,
  GET_INSTITUTION_CURRENT_HOLDINGS_SUCCESS,
  GET_INSTITUTION_CURRENT_HOLDINGS_FAILURE,
  statusType
} from '../../../actions'

const initial = {
  data: [],
  total: 0,
  status: statusType.IDLE
}

const institutionCurrentHoldingsReducer = (state = initial, action) => {
  switch (action.type) {
    case GET_INSTITUTION_CURRENT_HOLDINGS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }
    case GET_INSTITUTION_CURRENT_HOLDINGS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        total: action.meta.total,
        status: statusType.SUCCESS
      }
    case GET_INSTITUTION_CURRENT_HOLDINGS_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }
    default:
      return state
  }
}

export default institutionCurrentHoldingsReducer
