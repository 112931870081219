import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './dropdownToggle.component.css';

class DropdownToggle extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.value || ''
        };
    }

    resetValue() {
        this.setState({
            inputValue: this.props.value
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextState) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                inputValue: nextProps.value
            });
        }
    }

    handleToggleClick = () => {
        const {handleToggle, onClick} = this.props;
        onClick && onClick();
        handleToggle && handleToggle();
    };

    handleCollapse = () => {
        const {handleCollapse} = this.props;
        handleCollapse && handleCollapse();
    };

    onInputBlur = (event) => {
        const {onInputBlur} = this.props;
        const value = event.target.value;
        onInputBlur && onInputBlur(value, event);
    };

    onInputChange = (event) => {
        const {onInputChange, closeOnInputChange} = this.props;
        const value = event.target.value;

        this.setState({
            inputValue: event.target.value
        });

        if (closeOnInputChange === true) {
            this.handleCollapse();
        }

        onInputChange && onInputChange(value);
    };

    onInputKeyPress = (event) => {
        const {onInputEnter, closeOnInputEnter} = this.props;
        const value = event.target.value;

        if (event.key === 'Enter') {
            onInputEnter && onInputEnter(value);
            if (closeOnInputEnter) {
                this.handleCollapse();
            }
        }
    };

    render() {
        const {
            children,
            height,
            toggleCount,
            theme,
            arrow,
            loading
        } = this.props;
        const {inputValue} = this.state;

        const toggleStyles = {
            transform: `rotate(${toggleCount * 180}deg)`
        };

        const isChildStr = typeof children === 'string';
        const toggleValue = (isChildStr ? <div className='dropdown-toggle_value-label'>{children}</div> : React.cloneElement(children, {
            onChange: this.onInputChange,
            onBlur: this.onInputBlur,
            onKeyPress: this.onInputKeyPress,
            value: inputValue || ''
        }));

        const prepend = (this.props.prepend ? <div className='prepend'><span>{this.props.prepend}</span></div> : null);

        const dropdownToggleClass = [
            'dropdown-toggle',
            `${theme ? 'dropdown-toggle--' + theme : ''}`
        ].join(' ');

        return (
            <div className={dropdownToggleClass} style={{height}} onClick={this.handleToggleClick}>
                <div className='dropdown-toggle_value'>
                    {prepend}
                    {toggleValue}
                </div>

                {loading ?
                    <div className='dropdown-loading'>
                        <i className='dropdown-loading_spinner'/>
                    </div>
                    :
                    <div className='dropdown-toggle_toggler' style={toggleStyles}>
                        {arrow && <i className='q4i-arrow-up-4pt'/>}
                    </div>
                }
            </div>
        );
    }
}

DropdownToggle.propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    theme: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onInputBlur: PropTypes.func,
    onInputChange: PropTypes.func,
    onInputEnter: PropTypes.func,
    closeOnInputChange: PropTypes.bool,
    closeOnInputEnter: PropTypes.bool,
    toggleCount: PropTypes.number,
    prepend: PropTypes.string,
    arrow: PropTypes.bool
};

DropdownToggle.defaultProps = {
    height: 40,
    closeOnInputChange: false,
    closeOnInputEnter: true,
    toggleCount: 1,
    theme: 'pale-grey',
    arrow: true
};

export default DropdownToggle;
