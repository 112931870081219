import React, { useRef, useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import moment from 'moment-timezone'

// utils
import { DEFAULT_DATE_FORMAT, format, formatDate, getLocalizedFormat } from '../../../../utils'
import { get, isEqual } from 'lodash'

const propTypes = {
  height: PropTypes.number,
  stock: PropTypes.array,
  news: PropTypes.array,
  indices: PropTypes.array,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  config: PropTypes.object
}

const defaultProps = {
  stock: [],
  news: [],
  indices: [],
  config: {
    spacing: [16, 20, 0, 4],
    pointWidth: 60,
    animationDuration: 750,
    axis: {
      title: { x: 2, y: 2 },
      labels: { x: 34, y: -10 },
      color: '#939ba0',
      titleColor: '#939ba0',
      labelColor: '#939ba0',
      lineColor: '#373B41',
      labelFontSize: '11px',
      gridLineColor: '#373B41'
    },
    stockColor: '#3498DB',
    crosshairColor: '#939ba0',
    legend: {
      position: { x: 0, y: 16 },
      symbolHeight: 10,
      symbolWidth: 10,
      symbolRadius: 16,
      style: {
        color: '#ffffff',
        fontWeight: 'normal',
        fontSize: '12px',
        cursor: 'default'
      }
    }
  }
}

const SERIES_ID = {
  STOCK: 'stock',
  NEWS: 'news'
}

const SERIES_NAME = {
  STOCK: 'Stock',
  NEWS: 'News'
}

/**
 * Historical Stock Chart
 * @param props
 */
function HistoricalStockChart (props) {
  const highcharts = useRef()
  const [config, setConfig] = useState(null)
  
  useEffect(() => {
    props && setConfig(_getChartConfig(props))
  }, [props])
  

  return config
    ? <HighchartsReact
        ref={highcharts}
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={config}
        immutable={true}
      />
    : null
}

/**
 * Get series plot options
 * @param indices
 */
const _getSeriesPlotOptions = (indices) => {
  const options = {
    allowPointSelect: true,
    enableMouseTracking: true,
    states: {
      hover: { lineWidth: 2 },
      inactive: { opacity: 1 }
    },
    marker: {
      enabled: false
    }
  }

  return (indices && indices.length)
    ? {
        ...options,
        compare: 'percent',
        showInNavigator: true,
        compareStart: true
      }
    : { ...options, compare: null }
}

/**
 * Highstock chart config for series
 * @param props
 * @returns {*}
 * @private
 */
const _getSeriesConfig = (props) => {
  const { config, stock, news, indices } = props

  return [{
    id: SERIES_ID.STOCK,
    dataId: SERIES_ID.STOCK,
    name: SERIES_NAME.STOCK,
    type: 'spline',
    yAxis: 'global-yAxis',
    color: config.stockColor,
    lineWidth: 2,
    zIndex: 2,
    marker: {
      symbol: 'circle',
      lineColor: null,
      radius: 2
    },
    data: stock || []
  },
  {
    id: SERIES_ID.NEWS,
    name: SERIES_NAME.NEWS,
    type: 'flags',
    color: '#e67f22',
    fillColor: '#e67f22',
    clip: false,
    shape: 'circle',
    onSeries: SERIES_ID.STOCK,
    linkedTo: SERIES_ID.STOCK,
    stackDistance: 0,
    visible: true,
    zIndex: 4,
    allowOverlapX: true,
    y: -4,
    width: 2,
    height: 2,
    states: {
      hover: { enabled: false }
    },
    data: stock && (news || [])
  }].concat(indices.map((index) => {
    const { label, color, stock } = (index || {})

    return {
      id: label,
      dataId: label,
      name: label,
      type: 'spline',
      yAxis: 'global-yAxis',
      color,
      lineWidth: 2,
      zIndex: 2,
      marker: {
        symbol: 'circle',
        lineColor: null,
        radius: 2
      },
      data: stock || []
    }
  }))
}

/**
 * Base highstock chart config
 * @param props
 * @returns {*}
 * @private
 */
const _getChartConfig = (props) => {
  const { config, width, height, indices, stock } = props
  let count = 0

  const getCurrency = () => {
    return stock?.[0]?.currency || ''
  }

  return {
    chart: {
      spacing: config.spacing,
      backgroundColor: null,
      className: 'security-stock-overview_chart chart-general q4-fade-in',
      type: 'spline',
      reflow: false,
      panning: false,
      pinchType: false,
      style: {
        fontFamily: 'Open Sans'
      },
      width,
      height
    },
    rangeSelector: { enabled: false },
    enableMouseTracking: { enabled: false },
    exporting: { enabled: false },
    credits: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    legend: { enabled: false },
    title: { text: null },
    xAxis: {
      id: 'datetime-axis-stock',
      lineWidth: 0,
      tickLength: 0,
      crosshair: {
        color: config.crosshairColor
      },
      labels: {
        autoRotation: [0],
        rotation: 0,
        style: {
          color: get(config, 'axis.labelColor'),
          fontSize: get(config, 'axis.labelFontSize'),
          textOverflow: 'none'
        },
        formatter: function () {
          const isMoreThanTwoWeeks = stock?.length > 10
          const formattedDate = moment.tz(this.value, 'America/New_York').format(getLocalizedFormat(DEFAULT_DATE_FORMAT))

          if (isMoreThanTwoWeeks) {
            if (count % 3 === 0) { // To show only every 3'rd label on the axis
              count++
              return formattedDate
            }
            count++
          } else {
            return formattedDate
          }
        }
      },
      minTickInterval: 24 * 3600 * 1000,
      overscroll: 1, // include the last label
      // Solution sample by highcharts: https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/stock/demo/intraday-breaks/
      breaks: [{ // hide weekends, month is 0 based
        from: Date.UTC(2011, 9, 9), // any weekend that exclude the Sunday and Saturday on the chart
        to: Date.UTC(2011, 9, 10, 23, 59),
        repeat: 7 * 24 * 36e5 // repeat every week/7 days for the past and future
      }]
    },
    yAxis: [{
      id: 'global-yAxis',
      lineWidth: 0,
      tickAmount: 4,
      tickWidth: 0,
      opposite: true,
      plotLines: false,
      showLastLabel: false,
      allowDecimals: false,
      gridLineWidth: 1,
      gridLineColor: get(config, 'axis.gridLineColor'),
      lineColor: config.lineColor,
      title: {
        text: (indices && indices.length) ? '%' : getCurrency(),
        align: 'high',
        rotation: 0,
        offset: 10,
        x: get(config, 'axis.title.x'),
        y: get(config, 'axis.title.y'),
        style: {
          color: get(config, 'axis.titleColor')
        }
      },
      labels: {
        useHTML: true,
        align: 'right',
        style: {
          color: get(config, 'axis.labelColor'),
          fontSize: get(config, 'axis.labelFontSize')
        },
        x: get(config, 'axis.labels.x'),
        y: get(config, 'axis.labels.y')
      }
    }],
    tooltip: {
      useHTML: true,
      shared: true,
      split: false,
      crosshairs: true,
      hideDelay: config.animationDuration,
      formatter: function () {
        const points = get(this, 'points')
        const name = get(this, 'series.name')
        const date = formatDate(Number(this.x), 'dddd, MMM D, YYYY', true, true)

        if (name === SERIES_NAME.NEWS) {
          return (
            `<div class='chart-tip chart-tip--sized'>
              <div class='chart-tip_header'>${date}</div>
              <div class='chart-tip_body'>
                <span class='chart-tip_value'>${get(this, 'point.text') || '-'}</span>
              </div>
            </div>`
          )
        }

        return (
          `<div class='chart-tip'>
            <div class='chart-tip_header'>${date}</div>
            ${(points || []).map((item, index) => {
              const { color, series, y } = (item || {})
              const name = get(series, 'name')
              const percentChanged = (indices && indices.length)
                ? ` (${parseFloat(item.point.change).toFixed(2)}%)`
                : ''

              return (
                `<div key={index} class='chart-tip_body'>
                  <span class='chart-tip_circle' style='color: ${color}'>\u25CF</span>
                  <span class='chart-tip_name'>${name}:</span>
                  <span class='chart-tip_value'>${format(y, 2)}${percentChanged}</span>
                </div>`
              )
            }).join(' ')}
          </div>`
        )
      }
    },
    plotOptions: {
      flags: {
        allowPointSelect: true,
        enableMouseTracking: true,
        animation: false
      },
      spline: {
        turboThreshold: 2000,
        states: {
          hover: {
            halo: {
              opacity: 0,
              size: 0
            }
          }
        }
      },
      series: _getSeriesPlotOptions(indices)
    },
    series: _getSeriesConfig(props)
  }
}

HistoricalStockChart.propTypes = propTypes
HistoricalStockChart.defaultProps = defaultProps

export default memo(HistoricalStockChart, (prevProps, nextProps) => isEqual(prevProps, nextProps))
