import {
  GET_ENTITY_OWNERSHIP_CHART_POSITION_REQUEST,
  GET_ENTITY_OWNERSHIP_CHART_POSITION_SUCCESS,
  GET_ENTITY_OWNERSHIP_CHART_POSITION_FAILURE,
  statusType
} from '../../../../actions'

const moment = require('moment-timezone')

/**
 * Entity Ownership Chart - Position Reducer
 */
const position = (state = {}, action) => {
  const { payload, request } = action
  switch (action.type) {
    case GET_ENTITY_OWNERSHIP_CHART_POSITION_REQUEST:
      return {
        ...state,
        [payload.entityId]: {
          status: statusType.IN_PROGRESS
        }
      }

    case GET_ENTITY_OWNERSHIP_CHART_POSITION_SUCCESS:
      return {
        ...state,
        [request.entityId]: {
          data: (payload || [])
            .filter((record) => record.current_qtr_date)
            .map((record) => {
              return {
                x: moment.utc(record.current_qtr_date).valueOf(),
                y: isNaN(record.current) ? null : record.current
              }
            })
            .reverse(),
          status: statusType.SUCCESS
        }
      }

    case GET_ENTITY_OWNERSHIP_CHART_POSITION_FAILURE:
      return {
        ...state,
        [request.entityId]: {
          status: statusType.ERROR
        }
      }

    default:
      return state
  }
}

export default position
