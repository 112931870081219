import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// actions
import { useTopIndustryQuery } from '../../../hook'

// component
import TopIndustryGrid from './topIndustry.component'

// utils
import { get } from 'lodash'
import { getLocalizedCurrency, MOVER_TYPE } from '../../../../../utils'

const { BUY, SELL } = MOVER_TYPE

const propTypes = {
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  industry: PropTypes.string
}

const defaultProps = {}

/**
 * Top Industry Buys/Sells/Holdings
 * @param props
 */
function TopIndustry (props) {
  const { entityId, entityType, industry } = props

  const { data } = useTopIndustryQuery(entityType, {
    skip: !industry,
    variables: {
      entityId,
      entityType,
      industry,
      currencyCode: getLocalizedCurrency(),
      limit: 5
    }
  })

  return (
    <Grid container spacing={2} className='tearsheet_section'>
      <Grid item xs={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} className='tearsheet_section-title'>Top Industry Buys (MM)</Grid>
          <Grid item xs={12} className='tearsheet_section-content'>
            <TopIndustryGrid
              data={get(data, 'buy.items', [])}
              type={BUY}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} className='tearsheet_section-title'>Top Industry Sells (MM)</Grid>
          <Grid item xs={12} className='tearsheet_section-content'>
            <TopIndustryGrid
              data={get(data, 'sell.items', [])}
              type={SELL}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} className='tearsheet_section-title'>Top Industry Holdings (MM)</Grid>
          <Grid item xs={12} className='tearsheet_section-content'>
            <TopIndustryGrid data={get(data, 'holding.items', [])} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

TopIndustry.propTypes = propTypes
TopIndustry.defaultProps = defaultProps

export default TopIndustry
