export const navy = '#001a49'
export const deepSapphire = '#0a3f6f'
export const dodgerBlue = '#0e4579'
export const denim = '#124d84'
export const ink = '#10528f'
export const endeavour = '#1b5e9a'
export const q4Blue = '#0f5ca3'
export const steel = '#1f6bb0'
export const rain = '#297ac5'
export const olympic = '#428BCF'
export const surf = '#4696E0'
export const sky = '#3498db'
export const teal = '#1abc9c'
export const darkTeal = '#0da084'
export const lime = '#2ecc71'
export const apple = '#aa2d40'
export const ice = '#94e9f6'
export const avocado = '#3a925f'
export const sunshine = '#f1c40f'
export const citrus = '#f1af0f'
export const mustard = '#dc9e27'
export const tangerine = '#fc7e26'
export const ginger = '#e67f22'
export const amber = '#d95608'
export const spice = '#ec6a4c'
export const cherry = '#e74c3c'
export const eggplant = '#6a3476'
export const plum = '#804a8c'
export const raspberry = '#a4305e'
export const walnut = '#935f39'
export const blackPearl = '#14171a'
export const blackSmoke = '#121517'
export const charcoal = '#1a1d21'
export const raisinBlack = '#1f2327'
export const darkSlate = '#22272b'
export const slate = '#2a3035'
export const lightSlate = '#545b62'
export const grey = '#646d75'
export const coldGrey = '#858e93'
export const silver = '#939ba0'
export const softGrey = '#e0e0e0'
export const lightGrey = '#eeeeee'
export const paleGrey = '#f1f1f1'
export const mutedGrey = '#f7f7f7'
export const white = '#ffffff'
export const black = '#000000'
