import { formatDate } from '../../utils/date.util'
import {
  GET_REPORT_DATA_LIST_REQUEST,
  GET_REPORT_DATA_LIST_SUCCESS,
  GET_REPORT_DATA_LIST_ERROR,
  SAVE_REPORT_DATA_ITEM_SUCCESS,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../actions'

const initial = {
  data: [],
  meta: {
    page: 0,
    total: 0
  },
  status: IDLE
}

const reportDataList = (state = initial, action) => {
  switch (action.type) {
    case GET_REPORT_DATA_LIST_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case GET_REPORT_DATA_LIST_SUCCESS:
      const data = (action.payload || []).map((report) => {
        const { _id, title, create_date, updated_date, _profile } = report
        const isUTC = true

        return {
          _id,
          title,
          _profile,
          create_date: formatDate(create_date, 'MM/DD/YY', isUTC),
          updated_date: formatDate(updated_date, 'MM/DD/YY', isUTC),
          author: (_profile && _profile.firstName) ? `${_profile.firstName} ${_profile.lastName}` : '-'
        }
      })

      return {
        ...state,
        data,
        meta: action.meta,
        status: FETCHED
      }
    case SAVE_REPORT_DATA_ITEM_SUCCESS:

      if (!action.payload || !action.payload._id) {
        return {
          ...state
        }
      }

      // normalize payload for report data list
      const isUTC = true
      const savedReportDataItem = {
        _id: action.payload._id,
        title: action.payload.title,
        _profile: action.payload._profile,
        create_date: formatDate(action.payload.create_date, 'MM/DD/YY', isUTC),
        updated_date: formatDate(action.payload.updated_date, 'MM/DD/YY', isUTC),
        author: (action.payload._profile && action.payload._profile.firstName) ? `${action.payload._profile.firstName} ${action.payload._profile.lastName}` : '-'
      }

      let reportDataList = [...state.data]

      const found = reportDataList.find((each, idx) => {
        if (each._id === savedReportDataItem._id) {
          reportDataList[idx] = savedReportDataItem
          return true
        }
        return false
      })

      if (!found) {
        reportDataList = [savedReportDataItem, ...reportDataList]
      }

      return {
        ...state,
        data: reportDataList,
        meta: action.meta,
        status: FETCHED
      }
    case GET_REPORT_DATA_LIST_ERROR:
      return {
        ...state,
        status: FAILED
      }
    default:
      return state
  }
}

export default reportDataList
