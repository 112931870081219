import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'

// actions
import { useBriefingBookCreateQuery, useBriefingBookExportQuery, useTearsheetTemplateQuery } from '../hook'
import { closeModal, openModal, createToast, modalType } from '../../../actions'

// components
import ExportModal from './export.component'
import { getLocalStorageItem, BRIEFING_BOOK_TYPE, ENTITY_TYPE, GENERATE_STATUS, getQ4SecurityId, getLegacySecurityId, getQ4TickerId, getLocalizedCurrency } from '../../../utils'
import { get, omitBy, isNil } from 'lodash'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string,
  isItinerary: PropTypes.bool,
  book: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string
  }),
  entity: PropTypes.shape({
    entityName: PropTypes.string,
    entityId: PropTypes.string.isRequired,
    entityType: PropTypes.oneOf([CONTACT, FUND, INSTITUTION]).isRequired,
    institutionId: PropTypes.string
  }),
  onSaveSuccess: PropTypes.func
}

const defaultProps = {
  dataId: 'BriefingBookExportModal'
}

const { PENDING } = GENERATE_STATUS
const { TEARSHEET } = BRIEFING_BOOK_TYPE

/**
 * Tearsheet Export
 * @param props
 */
function TearsheetExport (props) {
  const { dataId, isItinerary, entity, book, tickerId, securityId, legacySecurityId, openModal, closeModal, createToast } = props
  const { id } = (book || {})
  const { entityId, entityType, entityName } = (entity || {})

  const [handleCreate, { loading: createProgress, data: tearsheet }] = useBriefingBookCreateQuery()
  const isTearsheet = !!(entityId && entityType)
  const bookId = isTearsheet ? get(tearsheet, 'briefingBook.create.items[0].id') : id

  const [rememberSettings, setRememberSettings] = useState(true)
  const [exportSettings, setExportSettings] = useState(getLocalStorageItem('tearsheet_export_settings') || [])
  const settings = (Array.isArray(exportSettings) && exportSettings.find((item) => item.isSelected)) || {}

  const [cover, setCover] = useState({ title: '', note: '' })
  const exportTitle = get(cover, 'title', '').trim()
  const exportNote = get(cover, 'note', '').trim()

  const { loading, data } = useTearsheetTemplateQuery()
  const [handleExport, { loading: exporting }] = useBriefingBookExportQuery({
    variables: omitBy({
      id: bookId,
      tickerId,
      securityId, // @deprecated
      legacySecurityId, // @deprecated
      currencyCode: getLocalizedCurrency(),
      title: exportTitle.length ? exportTitle : null,
      note: exportNote.length ? exportNote : null,
      ...settings
    }, isNil),
    onError: () => openModal({
      type: modalType.ERROR_MODAL
    }),
    onCompleted: () => {
      closeModal()
      window.sessionStorage.setItem(`download-${bookId}`, PENDING)
      rememberSettings
        ? localStorage.setItem('tearsheet_export_settings', JSON.stringify(exportSettings))
        : localStorage.removeItem('tearsheet_export_settings')

      createToast({
        text: <>
          {isTearsheet
            ? <Link to={`/${entityType}/${entityId}`}>{entityName} </Link>
            : <Link to={`/briefingBook/${id}`}>{get(book, 'title')} </Link>}
            is downloading. Check the notifications to view its progress.
          </>
      })
    }
  })

  const templates = get(data, 'tearsheetTemplate.items', [])

  /**
   * Set initial export settings
   */
  useEffect(() => {
    if (!exportSettings.length) {
      const settings = templates.map((template) => {
        const { id, output = [] } = (template || {})
        return { templateId: id, output: output[0], isSelected: get(templates, '[0].id') === id }
      })
      setExportSettings(settings)
    }
  }, [templates, exportSettings, setExportSettings])

  /**
   * Handle export setting change
   * @param settings
   */
  const handleSettingChange = (settings) => {
    Array.isArray(settings)
      ? setExportSettings(settings)
      : setExportSettings([...exportSettings.filter((item) => item.templateId !== settings.templateId), settings])
  }

  /**
   * Handle briefing book export
   * tearsheet: save and export
   * briefingbook: export
   */
  const handleBriefingBookExport = () => {
    const { entityName, ...tearsheetEntity } = (entity || {})

    isTearsheet
      ? handleCreate({ variables: { title: entityName, type: TEARSHEET, entity: tearsheetEntity } })
        .then((response) => (response && response.errors) ? handleFailure() : handleExport())
      : handleExport()
  }

  /**
   * On Action Completion Failure display an error
   */
  function handleFailure () {
    openModal({
      type: modalType.ERROR_MODAL
    })
  }

  return (
    <ExportModal
      dataId={`${dataId}ExportModal`}
      loading={[loading, createProgress, exporting].some((item) => !!item)}
      entityType={get(entity, 'entityType')}
      isItinerary={isItinerary}
      cover={cover}
      templates={templates}
      settings={exportSettings}
      onChange={handleSettingChange}
      onExport={handleBriefingBookExport}
      rememberSettings={rememberSettings}
      onRememberSettingsChange={(value) => setRememberSettings(value)}
      onCoverChange={(cover) => setCover(cover)}
      onClose={closeModal}
    />
  )
}

TearsheetExport.propTypes = propTypes
TearsheetExport.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')

  return {
    tickerId: getQ4TickerId(profile),
    legacySecurityId: getLegacySecurityId(profile),
    securityId: getQ4SecurityId(profile)
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
  createToast: bindActionCreators(createToast, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(TearsheetExport)
