import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// actions
import { searchEntity, searchEntityClear, statusType } from '../../../../../actions'

// components
import { Select } from '../../../../index'
import { debounce, getFromXigniteToStandard, ENTITY_TYPE, THEMES } from '../../../../../utils'
import { get } from 'lodash'

import './peer.container.scss'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../../../../services/launchDarkly.service'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  onPeerAdd: PropTypes.func.isRequired,
  onPeerDelete: PropTypes.func.isRequired
}

const defaultProps = {
  theme: THEMES.DARK
}

/**
 * Peers Component
 * @param props
 */
const Peers = ({ dataId, peers, onDelete }) => {
  return (
    (peers || []).map((peer, index) => {
      const { tickerId, symbol, exchange } = peer
      return (
        <div
          key={tickerId}
          data-id={(index === 0) ? dataId : null}
          className='entity-ownership_peer-item'
        >
          <div className='entity-ownership_peer-item_label'>{symbol}
            <span className='entity-ownership_peer-item_label-divider'>
              {getFromXigniteToStandard(exchange)}
            </span>
          </div>
          <i className='entity-ownership_peer-item_delete q4i-close-4pt' onClick={() => onDelete(peer)} />
        </div>
      )
    })
  )
}

/**
 * Get formatted suggestions
 * Remove selected securities if necessary
 */
function getSuggestions (suggestions, peers) {
  const selectedIds = (peers || []).map((peer) => peer && peer.tickerId)
  return (suggestions || [])
    .filter((suggestion) => !selectedIds.includes(get(suggestion, '_source.q4_ticker_id')))
    .map((suggestion) => {
      const source = get(suggestion, '_source', {})
      const { symbol, exchange } = source
      const tickerId = source.q4_ticker_id
      return {
        label: <span className='entity-ownership_peer-select_item'>
          <span className='entity-ownership_peer-select_item-symbol'>{symbol}</span>
          <span className='entity-ownership_peer-select_item-exchange'>{getFromXigniteToStandard(exchange)}</span>
          <span className='entity-ownership_peer-select_item-name'>{source.security_name}</span>
        </span>,
        value: tickerId,
        data: { tickerId, symbol, exchange }
      }
    })
}

/**
 * Add Peer Component
 * @param props
 */
function Peer (props) {
  const { dataId, theme, peers, onPeerAdd, onPeerDelete, loading, suggestions, searchEntity, searchEntityClear } = props

  const options = getSuggestions(suggestions, peers)
  const handleInputChange = debounce((query) => {
    (query && query.length)
      ? searchEntity({ query, type: ENTITY_TYPE.SECURITY, limit: 20, useElasticSearch: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)})
      : searchEntityClear()
  })

  return (
    <div className='entity-ownership_peer'>
      <Select
        dataId={{
          inputId: `${dataId}PeersInput`,
          menuId: `${dataId}PeersMenu`
        }}
        loading={loading}
        theme={theme}
        className='entity-ownership_peer-select'
        size='thin'
        placeholder='Add Peer (Max 3)'
        value={null}
        options={options}
        clearable={false}
        disabled={peers.length >= 3}
        showDropdownIndicator={false}
        onInputChange={handleInputChange}
        onChange={(security) => security && onPeerAdd(security.data)}
      />
      <Peers
        dataId={`${dataId}PeersItem`}
        peers={peers}
        onDelete={(security) => onPeerDelete(security)}
      />
    </div>
  )
}

Peer.propTypes = propTypes
Peer.defaultProps = defaultProps

const mapStateToProps = (state) => {
  return {
    loading: get(state, 'search.entity.status') === statusType.IN_PROGRESS,
    suggestions: get(state, 'search.entity.data', [])
  }
}

const mapDispatchToProps = (dispatch) => ({
  searchEntity: bindActionCreators(searchEntity, dispatch),
  searchEntityClear: bindActionCreators(searchEntityClear, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Peer)
