import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { addQueryParams } from '../../utils/http/http.util'

export const FETCH_ESTIMATES_HISTORICAL_REQUEST = 'FETCH_ESTIMATES_HISTORICAL_REQUEST'
export const FETCH_ESTIMATES_HISTORICAL_SUCCESS = 'FETCH_ESTIMATES_HISTORICAL_SUCCESS'
export const FETCH_ESTIMATES_HISTORICAL_ERROR = 'FETCH_ESTIMATES_HISTORICAL_ERROR'

export const SET_ESTIMATES_HISTORICAL_METRIC = 'SET_ESTIMATES_HISTORICAL_METRIC'

const resources = {
  historicalTable: '/estimates/v2/consensus/historical'
}

const _fetchHistoricalTable = (url, params) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_ESTIMATES_HISTORICAL_REQUEST,
      FETCH_ESTIMATES_HISTORICAL_SUCCESS,
      FETCH_ESTIMATES_HISTORICAL_ERROR
    ],
    method: METHOD_TYPE.GET,
    payload: params
  }
})

export const fetchHistoricalTable = (params) => (dispatch) => {
  const url = addQueryParams(resources.historicalTable, params)
  return dispatch(_fetchHistoricalTable(url))
}

export const setHistoricalMetric = (metric) => (dispatch, getState) => {
  const state = getState()
  if (state.estimates.historical.activeMetric !== metric) {
    dispatch({ type: SET_ESTIMATES_HISTORICAL_METRIC, metric })
  }
}
