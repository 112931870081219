import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// actions
import { useBriefingBookDeleteQuery, useBriefingBookQuery } from '../hook'
import { closeModal, createToast, openModal, modalType } from '../../../actions'

// components
import { Banner } from '../../../components'
import BriefingBookToolbar from './toolbar/toolbar.component'
import BriefingBookTable from './table/table.component'

// utils
import { getPageSizeFromStorage, THEMES } from '../../../utils'
import { get, isNull, omitBy } from 'lodash'

const dataIdPrefix = 'BriefingBookPage'
const PAGE_SIZE_ID = 'briefing-book-grid'

const propTypes = {}
const defaultProps = {}

/**
 * Briefing Book Page
 * @param props
 */
function BriefingBookPage (props) {
  const { openModal, closeModal, createToast, history } = props
  const [state, setState] = useState({
    search: null,
    searchField: ['title', 'profileConnection.fullName'],
    filter: {
      activity: false,
      myBook: false
    },
    listOptions: {
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
  })
  const { search, searchField, filter, listOptions } = state
  const variables = omitBy({
    search,
    searchField,
    ...filter,
    ...listOptions
  }, isNull)

  const [handleBriefingBookDelete, { loading: deleteProgress }] = useBriefingBookDeleteQuery()
  const { loading, data, refetch } = useBriefingBookQuery({ variables })

  const books = get(data, 'briefingBook.items', [])
  const total = get(data, 'briefingBook.count', 0)

  /**
   * Handle briefing book create
   */
  const handleBriefingBookCreate = () => {
    openModal({
      type: modalType.BRIEFING_BOOK_EDIT_MODAL,
      props: {
        onSaveSuccess: handleCreateSuccess
      }
    })
  }

  /**
   * Handle query change
   * @param query
   */
  const handleQueryChange = (query = {}) => {
    const options = query.listOptions ? { ...query } : { ...query, listOptions: { ...listOptions, page: 1 } }
    setState({ ...state, ...options })
  }

  /**
   * Handle bulk action
   * @param selectedIds - array of briefing book id(s)
   */
  const handleBulkAction = (selectedIds) => {
    if (!selectedIds || !selectedIds.length) {
      return
    }

    openModal({
      type: modalType.CONFIRM_MODAL,
      props: {
        content: {
          title: `Delete Briefing ${(selectedIds && selectedIds.length > 1) ? 'Books' : 'Book'}?`,
          message: `Are you sure you want to delete ${(selectedIds && selectedIds.length > 1) ? 'these Briefing Books' : 'this Briefing Book'}?`
        },
        onConfirm: () => handleBulkDeleteConfirm(selectedIds)
      }
    })
  }

  /**
   * Handle confirm message
   * @param selectedIds
   */
  function handleBulkDeleteConfirm (selectedIds) {
    closeModal({
      type: modalType.CONFIRM_MODAL
    })

    handleBriefingBookDelete({
      variables: { id: selectedIds }
    })
      .then((response) => {
        get(response, 'errors')
          ? handleFailure() : handleDeleteSuccess(get(response, 'data.briefingBook.delete.count', null))
      })
  }

  /**
   * On Delete Success show toast message and refetch books
   * @param deletedCount
   */
  function handleDeleteSuccess (deletedCount) {
    if (!deletedCount) {
      return
    }

    refetch({ ...variables, page: 1 }).then(() => {
      handleQueryChange({ listOptions: { ...listOptions, page: 1 } })
      createToast({ text: `Briefing ${(deletedCount > 1) ? 'Books' : 'Book'} deleted successfully.` })
    })
  }

  /**
   * On create success refetch books and show toast message
   */
  function handleCreateSuccess () {
    refetch().then(() => createToast({ text: 'Briefing Book created successfully.' }))
  }

  /**
   * On Action Completion Failure display an error
   */
  function handleFailure () {
    openModal({
      type: modalType.ERROR_MODAL
    })
  }

  return (
    <div className='briefing-book-page'>
      <Banner
        icon='q4i-book-2pt'
        title='Briefing Books'
        controls={[{
          dataId: `${dataIdPrefix}Create`,
          type: 'button',
          theme: THEMES.CITRUS,
          icon: 'q4i-add-4pt',
          onClick: handleBriefingBookCreate
        }]}
      />
      <BriefingBookToolbar
        dataId={dataIdPrefix}
        filter={filter}
        search={search}
        onQueryChange={handleQueryChange}
      />
      <BriefingBookTable
        dataId={dataIdPrefix}
        pageSizeId={PAGE_SIZE_ID}
        loading={[loading, deleteProgress].some((item) => !!item)}
        data={books}
        total={total}
        listOptions={listOptions}
        noFilter={[].concat(search, Object.values(filter)).every((value) => !value)}
        onQueryChange={handleQueryChange}
        onBulkAction={handleBulkAction}
        onBriefingBookCreate={handleBriefingBookCreate}
        history={history}
      />
    </div>
  )
}

BriefingBookPage.propTypes = propTypes
BriefingBookPage.defaultProps = defaultProps

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
  createToast: bindActionCreators(createToast, dispatch)
})

export default connect(null, mapDispatchToProps)(BriefingBookPage)
