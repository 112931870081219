import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { addQueryParams } from '../../../utils/http/http.util'

export const FETCH_WIDGET_BROKER_DETAILS_REQUEST = 'FETCH_WIDGET_BROKER_DETAILS_REQUEST'
export const FETCH_WIDGET_BROKER_DETAILS_SUCCESS = 'FETCH_WIDGET_BROKER_DETAILS_SUCCESS'
export const FETCH_WIDGET_BROKER_DETAILS_ERROR = 'FETCH_WIDGET_BROKER_DETAILS_ERROR'
export const RESET_WIDGET_BROKER_DETAILS = 'RESET_WIDGET_BROKER_DETAILS'

const resources = {
  currentMetric: '/estimates/v2/broker'
}

const _fetchBrokerDetails = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_WIDGET_BROKER_DETAILS_REQUEST,
      FETCH_WIDGET_BROKER_DETAILS_SUCCESS,
      FETCH_WIDGET_BROKER_DETAILS_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

const _resetBrokerData = () => ({
  type: RESET_WIDGET_BROKER_DETAILS,
  payload: {}
})

export const fetchBrokerDetails = (params) => (dispatch) => {
  const url = `${resources.currentMetric}`
  return dispatch(_fetchBrokerDetails(
    addQueryParams(url, params)
  ))
}

export const resetBrokerData = () => (dispatch) => {
  return dispatch(_resetBrokerData())
}
