import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

// actions
import { peerConfig, openModal, modalType } from '../../actions'
import { usePeerCreateQuery } from './hook/peerInputQuery.hook'
import { useDispatchAction, useTypedSelector } from '../../hook'

// components
import PeerInputComponent from './peerInput.component'

// utils
import { THEMES } from '../../utils'
import { debounce, get } from 'lodash'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../services/launchDarkly.service'

const propTypes = {
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.WHITE]),
  onAddSuccess: PropTypes.func
}

const defaultProps = {
  theme: THEMES.WHITE
}

/**
 * Peer Input Component
 * @param props
 * @returns {JSX.Element}
 */
function PeerInput (props) {
  // #region MapStateToProps
  const foundPeers = useTypedSelector((state) => state.peerConfig.foundPeers)
  const isSearching = useTypedSelector((state) => state.peerConfig.status === 'IN_PROGRESS')
  // #endregion

  // #region MapDispatchToProps
  const dispatch = useDispatch()
  const handleModalOpen = useDispatchAction(openModal, dispatch)
  const handleSearchPeers = useDispatchAction(peerConfig.searchPeersV2, dispatch)
  const handleResetAutoCompletePeers = useDispatchAction(peerConfig.resetAutoCompletePeers, dispatch)
  // #endregion

  const { theme, onAddSuccess } = props

  const [handlePeerCreate] = usePeerCreateQuery()

  /**
   * Handle peer search
   * @param query
   */
  const handlePeerSearch = (query) => {
    if (!query) {
      handleResetAutoCompletePeers()
    } else {
      const params = {
        limit: 12,
        type: 'security',
        query,
        useElasticSearch: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)
      }
      handleSearchPeers(params)
    }
  }

  const handleSearchDebounce = debounce(handlePeerSearch, 300)

  /**
   * Handle add peer
   * @param option
   */
  const handleAddPeer = (option) => {
    const { value: tickerId, entityId, symbol, exchange, companyName, legacySecurityId } = (option || {})

    handlePeerCreate({
      variables: {
        tickerId,
        entityId,
        legacySecurityId,
        symbol,
        exchange,
        name: companyName
      }
    }).then((response) => {
      get(response, 'errors') ? handleModalOpen({ type: modalType.ERROR_MODAL }) : onAddSuccess()
    }).catch(() => {
      handleModalOpen({ type: modalType.ERROR_MODAL })
    })
  }

  return (
    <PeerInputComponent
      theme={theme}
      isSearching={isSearching}
      suggestions={foundPeers}
      onSearch={handleSearchDebounce}
      onSelect={handleAddPeer}
    />
  )
}

PeerInput.propTypes = propTypes
PeerInput.defaultProps = defaultProps

export default memo(PeerInput)
