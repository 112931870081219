import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import _ from 'lodash'
import Chart from './chart.component'
import { getActiveTicker } from '../../../utils'

import {
  ENTITY_INSTITUTION,
  ENTITY_FUND
} from '../../../utils/entity'

import {
  getEntityOwnershipChartData,
  resetEntityOwnershipChartData,
  statusType
} from '../../../actions'

/**
 * Entity Ownership Chart
 * Displays position with related stock price, news, events and activity
 */
class OwnershipEntityChart extends Component {
  /**
   * Constructor
   * @param props.entityType - fund or institution
   * @param props.entityId - mongo institution or fund id
   * @param props.contactId - mongo contact id
   * @param props.securityId - mongo security id
   * @param props.ticker - current ticker symbol/exchange
   * @param props.quarters - number of quarters
   * @param props.width - chart width (optional)
   * @param props.height - chart height (optional)
   */
  constructor (props) {
    super(props)
    const { quarters } = this.props

    this.state = {
      startDate: moment.utc().endOf('quarter').startOf('day').subtract(quarters + .8, 'quarter').toDate(),
      endDate: moment.utc().startOf('day').toDate()
    }
  }

  /**
   * ComponentDidMount
   */
  componentDidMount () {
    this.getData()
  }

  /**
   * ComponentWillUnmount
   */
  componentWillUnmount () {
    resetEntityOwnershipChartData()
  }

  /**
   * ComponentDidUpdate
   * Reload data if security/ticker changes
   */
  componentDidUpdate (prevProps) {
    const securityChanged = !_.isEqual(prevProps.securityId, this.props.securityId)

    if (securityChanged) {
      this.getData()
    }
  }

  /**
   * Get Chart Data
   * @return Promise
   */
  getData () {
    const { getEntityOwnershipChartData, entityType, entityId, contactId, securityId, subscription } = this.props
    const { startDate, endDate } = this.state

    return getEntityOwnershipChartData({
      entityType,
      entityId,
      contactId,
      securityId,
      startDate,
      endDate,
      subscription
    })
  }

  /**
   * Filter data by id
   * Reducer can contain data for multiple charts, this returns
   * data for given entityId
   * @param state
   * @param id
   */
  filterById (state, id) {
    return state && state[id]
  }

  /**
   * Is chart loading
   * @param position
   * @param stock
   * @param events
   * @param activity
   * @param news
   * @returns {boolean}
   */
  isChartLoading ({ position, stock, events, activity, news }) {
    const { entityId } = this.props
    const series = [stock, events, activity, news]

    if (entityId) {
      series.push(position)
    }

    return !series.every((state) => {
      return _.get(state, 'status') === statusType.SUCCESS
    })
  }

  /**
   * Render
   */
  render () {
    const { securityId, entityId, contactId, quarters } = this.props
    const { startDate, endDate } = this.state

    const position = this.filterById(this.props.position, entityId)
    const stock = this.filterById(this.props.stock, securityId)
    const events = this.filterById(this.props.events, securityId)
    const activity = this.filterById(this.props.activity, contactId || entityId)
    const news = this.filterById(this.props.news, securityId)
    const isLoading = this.isChartLoading({ position, stock, events, activity, news })

    const options = {
      ...this.props,
      position: _.get(position, 'data'),
      stock: _.get(stock, 'data'),
      events: _.get(events, 'data'),
      activity: _.get(activity, 'data'),
      news: _.get(news, 'data'),
      startDate,
      endDate,
      quarters,
      isLoading
    }

    return (
      <Chart {...options} />
    )
  }
}

OwnershipEntityChart.propTypes = {
  entityType: PropTypes.oneOf([ENTITY_INSTITUTION, ENTITY_FUND]).isRequired,
  entityId: PropTypes.string.isRequired,
  securityId: PropTypes.string.isRequired,
  contactId: PropTypes.string,
  quarters: PropTypes.number,
  subscription: PropTypes.array,
  ticker: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  position: PropTypes.object,
  stock: PropTypes.object,
  events: PropTypes.object,
  activity: PropTypes.object,
  news: PropTypes.object
}

OwnershipEntityChart.defaultProps = {
  quarters: 5,
  width: null,
  height: null,
  subscription: [],
  ticker: {},
  position: [],
  stock: [],
  events: [],
  activity: [],
  news: []
}

const mapStateToProps = (state) => {
  const ticker = getActiveTicker(state.profile.data)

  return {
    position: state.chart.entityOwnership.position,
    stock: state.chart.entityOwnership.stock,
    events: state.chart.entityOwnership.events,
    activity: state.chart.entityOwnership.activity,
    news: state.chart.entityOwnership.news,
    subscription: state.profile.data.services,
    ticker: {
      symbol: ticker && ticker.symbol,
      exchange: ticker && ticker.exchange
    },
    securityId: ticker && ticker._security
  }
}

const mapDispatchToProps = (dispatch) => ({
  getEntityOwnershipChartData: bindActionCreators(getEntityOwnershipChartData, dispatch),
  resetEntityOwnershipChartData: bindActionCreators(resetEntityOwnershipChartData, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(OwnershipEntityChart)
