import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'

// actions
import { useSearchBriefingBooksQuery, useBriefingBookCreateQuery, useBriefingBookUpdateQuery } from '../hook'
import { openModal, closeModal, createToast, modalType } from '../../../actions'

// components
import AddToModal from './addTo.component'

// utils
import { BRIEFING_BOOK_TYPE, ENTITY_TYPE } from '../../../utils'
import { get } from 'lodash'

const { BRIEFING_BOOK } = BRIEFING_BOOK_TYPE
const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string,
  entities: PropTypes.arrayOf(PropTypes.shape({
    entityId: PropTypes.string.isRequired,
    entityType: PropTypes.oneOf([CONTACT, FUND, INSTITUTION]).isRequired,
    institutionId: PropTypes.string
  })).isRequired
}

const defaultProps = {
  dataId: 'AddToBriefingBook'
}

/**
 * Add Entity To Briefing Book
 * @param props
 */
function AddBriefingBook (props) {
  const { dataId, entities, openModal, closeModal, createToast } = props
  const [search, setSearch] = useState(null)

  const [handleSearch, { loading, data }] = useSearchBriefingBooksQuery()
  const [handleCreate, { loading: createProgress }] = useBriefingBookCreateQuery()
  const [handleUpdate, { loading: updateProgress }] = useBriefingBookUpdateQuery()

  /**
   * Handle briefing book search
   * @param value
   */
  const handleBriefingBookSearch = (value) => {
    setSearch((value && value.length) ? value : null)
    search && handleSearch({
      variables: { search, type: BRIEFING_BOOK }
    })
  }

  /**
   * Handle briefing book update/create
   * @param id
   * @param title
   * @param entity
   */
  const handleAddToBriefingBook = ({ id, title, entity }) => {
    const variables = { title, entity }
    const request = id
      ? handleUpdate({ variables: { id, ...variables } })
      : handleCreate({ variables: { ...variables, type: BRIEFING_BOOK } })

    request.then((response) => get(response, 'errors')
      ? handleFailure()
      : handleSuccess({ response: get(response, 'data.briefingBook', {}), id, title })
    )
  }

  /**
   * On Action Success
   * @param response
   * @param id
   * @param title
   */
  function handleSuccess ({ response, id, title }) {
    const bookId = get(response, 'create.items[0].id') || id

    bookId && createToast({
      text: <><Link to={`/briefingbook/${bookId}`}>{title || 'Briefing Book'}</Link> {`${id ? 'updated' : 'created'} successfully.`}</>
    })

    closeModal()
  }

  /**
   * On Action Completion Failure display an error
   */
  function handleFailure () {
    openModal({
      type: modalType.ERROR_MODAL
    })
  }

  return (
    <AddToModal
      dataId={dataId}
      entities={entities}
      isSearching={loading}
      suggestions={search ? get(data, 'briefingBook.items', []) : []}
      onSearch={handleBriefingBookSearch}
      isAddToProgress={[createProgress, updateProgress].some((item) => !!item)}
      onAddToBriefingBook={handleAddToBriefingBook}
      onClose={closeModal}
    />
  )
}

AddBriefingBook.propTypes = propTypes
AddBriefingBook.defaultProps = defaultProps

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
  createToast: bindActionCreators(createToast, dispatch)
})

export default connect(null, mapDispatchToProps)(AddBriefingBook)
