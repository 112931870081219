import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '../../../../components/shared'
import { isEntitySearch, isAllEntitySearch, SEARCH_TYPE, SWIFTTYPE_FLAGS } from '../../../../utils/search'
import { isEqual } from 'lodash'

/**
 * Refine Search with Sub-filters
 * @param props
 * @returns {*}
 * @constructor
 */
const SubFilters = (props) => {
  const { type, activity, fund, peer, onChange } = props

  /**
   * Handle sub-filter change
   * @param field
   * @param value
   * @returns {*}
   */
  const handleOnChange = (field, value) => onChange(field, value)

  return (
    <section className='search-page_filter-section'>
      <div className='search-page_filter-content'>
        {isEntitySearch(type) ? <div className='field field--full'>
          <Checkbox
            id='searchByLoggedActivity'
            label='Activity Logged'
            isChecked={Boolean(activity)}
            onChange={(value) => handleOnChange('activity', value)}
          />
        </div> : null}
        {isEqual(type, [SEARCH_TYPE.CONTACT]) || isAllEntitySearch(type) ? <div className='field field--full'>
          <Checkbox
            id='searchByManagedFunds'
            label='Manages Funds'
            isChecked={Boolean(fund)}
            onChange={(value) => handleOnChange('flags', value && [SWIFTTYPE_FLAGS.MANAGED_FUNDS])}
          />
        </div> : null}
        {isEqual(type, [SEARCH_TYPE.TRANSCRIPT])
          ? <div className='field field--full'>
            <Checkbox
              id='searchByPeer'
              label='Filter by Peers'
              isChecked={Boolean(peer)}
              onChange={(value) => handleOnChange('watchlist', value)}
            />
          </div> : null}
      </div>
    </section>
  )
}

SubFilters.propTypes = {
  type: PropTypes.array.isRequired,
  activity: PropTypes.bool,
  fund: PropTypes.bool,
  peer: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

SubFilters.defaultProps = {
  type: []
}

export default SubFilters
