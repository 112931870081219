import { getUrlSearchParams } from './http/http.util'
import { getLocalStorageItem } from './localStorage.util'

export const SECURITY_ID = 'security_id'

/**
 * Returns security id from JWT token.
 * @returns {String|null}
 * @deprecated - use getActiveTicker from profile.util
 */
export const getSecurityId = () => {
  return getLocalStorageItem(SECURITY_ID) || null
}

/**
 * Saves security id into localStorage.
 * @param securityId
 * @deprecated - use setActiveTicker action instead
 */
export const setSecurityId = (securityId) => {
  securityId && localStorage.setItem(SECURITY_ID, securityId)
}

export const securityHasChanged = () => {
  const securityId = getUrlSearchParams().get('securityId')
  return securityId && securityId !== localStorage.getItem('securityId')
}
