import { combineReducers } from 'redux'
import coverage from './detail/coverage/coverage.reducer'
import detail from './detail/detail.reducer'
import estimate from './detail/coverage/estimate.reducer'
import fundGrid from './grid/fundGrid.reducer'
import favorite from './detail/favorite.reducer'
import form from './form.reducer'
import fund from './detail/fund.reducer'
import grid from './grid/grid.reducer'
import position from './detail/position.reducer'
import research from './detail/research.reducer'
import strategy from './detail/strategy.reducer'
import webcast from './detail/webcast.reducer'

export default combineReducers({
  coverage,
  detail,
  estimate,
  favorite,
  form,
  fund,
  grid,
  position,
  research,
  strategy,
  webcast,
  fundGrid
})
