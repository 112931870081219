import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  ACKNOWLEDGE_TERMS_REQUEST,
  ACKNOWLEDGE_TERMS_SUCCESS,
  ACKNOWLEDGE_TERMS_FAILURE,
  SET_ACTIVE_TICKER,
  TICKER_ID,
  statusType
} from '../../actions'

const initial = {
  data: {},
  status: statusType.IDLE
}

const profile = (state = initial, action) => {
  switch (action.type) {
    case GET_PROFILE_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_PROFILE_SUCCESS:
      return {
        type: action.type,
        data: mapActiveTicker(action.payload),
        status: statusType.SUCCESS
      }

    case GET_PROFILE_FAILURE:
      return {
        type: action.type,
        error: action.error,
        status: statusType.ERROR
      }

    case SET_ACTIVE_TICKER:
      const tickerId = action.payload
      const { data } = state

      return {
        type: action.type,
        data: mapActiveTicker(data, tickerId),
        status: statusType.SUCCESS
      }

    case ACKNOWLEDGE_TERMS_REQUEST:
      return {
        ...state,
        type: action.type,
        status: statusType.IN_PROGRESS
      }
    case ACKNOWLEDGE_TERMS_SUCCESS:
      return {
        type: action.type,
        status: statusType.SUCCESS,
        data: {
          ...state.data,
          terms: {
            ...state.data.terms,
            acknowledged: true
          }
        }
      }
    case ACKNOWLEDGE_TERMS_FAILURE:
      return {
        ...state,
        type: action.type,
        status: statusType.ERROR
      }

    default:
      return state
  }
}

/**
 * Set Active Ticker flag (if any)
 * @param profile
 * @param tickerId
 * @returns {*}
 */
const mapActiveTicker = (profile, tickerId) => {
  tickerId = tickerId || localStorage.getItem(TICKER_ID)

  if (profile && profile._organization) {
    profile._organization.tickers = (profile._organization.tickers || []).map((ticker) => {
      ticker.active = ticker.q4_ticker_id === tickerId
      return ticker
    })
  }

  return profile
}

export default profile
