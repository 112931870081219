export const getMenuItems = ({ legacySecurityId, tickerId }) => [
  {
    key: 'dashboard',
    title: 'Dashboard',
    path: '/dashboard',
    icon: 'q4i-dashboard-2pt',
    section: 'root'
  },
  {
    key: 'peerlist',
    title: 'Peer List',
    path: '/peerlist',
    icon: 'q4i-watchlist-2pt',
    section: 'root'
  },
  {
    key: 'pipeline',
    title: 'Pipeline',
    path: '/pipeline',
    pattern: [
      '/pipeline',
      '/deal/:id'
    ],
    icon: 'q4i-deal-2pt',
    section: 'root'
  },
  {
    key: 'activity',
    title: 'Activity',
    path: '/activity',
    pattern: [
      '/activity',
      '/activity/:id'
    ],
    icon: 'q4i-activity-2pt',
    section: 'root'
  },
  {
    key: 'calendar',
    title: 'Calendar',
    path: '/calendar',
    icon: 'q4i-events-transcripts-2pt',
    section: 'root'
  },
  {
    key: 'contacts',
    title: 'Contact Address Book',
    path: '/contact',
    pattern: [
      '/contact',
      '/contact/:id'
    ],
    icon: 'q4i-contact-2pt',
    section: 'root'
  },
  {
    key: 'events',
    title: 'Events & Transcripts',
    path: '/event',
    pattern: [
      '/event',
      '/event/:id'
    ],
    icon: 'q4i-transcript-2pt',
    section: 'root'
  },
  {
    key: 'briefingbook',
    title: 'Briefing Books',
    path: '/briefingbook',
    pattern: [
      '/briefingbook',
      '/briefingbook/:id'
    ],
    icon: 'q4i-book-2pt',
    section: 'root'
  },
  {
    key: 'ai_targeting',
    title: 'Targeting',
    path: '/targeting',
    icon: 'q4i-targeting-2pt',
    section: 'root',
    subscription: 'ai_targeting'
  },
  {
    key: 'report',
    title: 'Reports',
    path: '/report',
    pattern: [
      '/report',
      '/report/:id'
    ],
    icon: 'q4i-reports-2pt',
    section: 'root'
  },
  {
    key: 'search',
    title: 'Advanced Search',
    path: '/search',
    icon: 'q4i-search-2pt',
    section: 'root'
  },
  {
    key: 'security',
    title: 'Security',
    path: `/security/${tickerId || legacySecurityId}`,
    pattern: [
      '/security/:id'
    ],
    icon: 'q4i-security-2pt',
    section: 'intelligence',
    tickerId: true
  },
  {
    key: 'estimates',
    title: 'Estimates',
    path: `/estimates/${legacySecurityId}`,
    pattern: '/estimates/:id',
    icon: 'q4i-estimates-research-2pt',
    section: 'intelligence',
    tickerId: true
  },
  {
    key: 'research',
    title: 'Research',
    path: '/research',
    pattern: [
      '/research',
      '/research/:id'
    ],
    icon: 'q4i-research-2pt',
    section: 'intelligence'
  },
  {
    key: 'engagement_analytics',
    title: 'Engagement Analytics',
    path: '/engagement',
    icon: 'q4i-star-2pt',
    section: 'intelligence'
  },
  {
    key: 'web_analytics',
    title: 'Web Analytics',
    path: '/analytics',
    pattern: [
      '/analytics',
      '/analytics/:days',
      '/analytics/:days/:id'
    ],
    icon: 'q4i-website-analytics-2pt',
    section: 'intelligence'
  },
  {
    key: 'webcast_analytics',
    title: 'Webcast Analytics',
    path: '/webcast',
    pattern: [
      '/webcast',
      '/webcast/:id'
    ],
    icon: 'q4i-webcast-2pt',
    section: 'intelligence'
  }
]
