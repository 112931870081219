import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'

export const GET_SECURITY_REQUEST = 'GET_SECURITY_REQUEST'
export const GET_SECURITY_SUCCESS = 'GET_SECURITY_SUCCESS'
export const GET_SECURITY_FAILURE = 'GET_SECURITY_FAILURE'

/**
 * Fetch Security
 * @param entityId
 * @param tickerId
 * @private
 */
const _fetchSecurity = ({ entityId, tickerId }) => ({
  [CALL_API]: {
    types: [
      GET_SECURITY_REQUEST,
      GET_SECURITY_SUCCESS,
      GET_SECURITY_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/security/${entityId || tickerId}`,
    payload: { entityId, tickerId }
  }
})

/**
 * Get Security by entity id
 */
export const getSecurity = ({ entityId, tickerId }) => (dispatch) => {
  return dispatch(_fetchSecurity({ entityId, tickerId }))
}
