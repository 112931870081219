import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { capitalize } from 'lodash'
import { getFilteredAvailableFilters } from '../../../../utils/report'
import { THEMES } from '../../../../utils/ui'
import { Select } from '../../../../components'
import { ReportFilter } from '../../config'
import './reportFilters.component.css'

/**
 * ReportFilters Component
 * @param isBasic
 * @param filters
 * @param availableFilters
 * @param pivotQuarters
 * @param isConfigFilterOpen
 * @param onFieldAdd
 * @param onFilterChange
 * @param onFilterRemove
 * @returns {*}
 */
function ReportFilters ({ isBasic, filters, availableFilters, pivotQuarters, isConfigFilterOpen, onFilterAdd, onFilterChange, onFilterRemove, isLocked }) {
  const [_availableFilters, setAvailableFilters] = useState([])
  const [query, setQuery] = useState('')

  useEffect(() => {
    if (!isBasic) {
      setAvailableFilters(getFilteredAvailableFilters(availableFilters, query))
    }
  }, [availableFilters, isBasic, query])

  const options = (_availableFilters || []).map((filter) => {
    const { _field } = filter
    return _field && {
      label: `${_field.label} (${_field._entityType && capitalize(_field._entityType.label)})`,
      value: filter
    }
  })
  return (
    <section className={'report-filters' + (isLocked ? '--disabled' : '')}>
      {!isBasic && (
        <section className='report-filters_search'>
          <Select
            placeholder='Filter by keyword...'
            theme={THEMES.WHITE}
            size='thin'
            showDropdownIndicator={false}
            itemHeight={30}
            options={options}
            value={query}
            onInputChange={(query) => setQuery(query)}
            onChange={(filter) => filter.value && onFilterAdd(filter.value)}
          />
        </section>
      )}
      <div className={'report-filters_body' + (isLocked ? '--disabled' : '')}>
        {(filters || []).map((filter, index) => {
          return (
            <ReportFilter
              key={filter._id}
              index={index}
              isBasic={isBasic}
              isConfigFilterOpen={isConfigFilterOpen}
              filter={filter}
              pivotQuarters={pivotQuarters}
              onChange={onFilterChange}
              onRemove={onFilterRemove}
            />
          )
        })}
      </div>
    </section>
  )
}

ReportFilters.propTypes = {
  /**
   * Used to determine whether or not the filters include a search, as well as an operator on every filter
   */
  isBasic: PropTypes.bool,

  filters: PropTypes.array.isRequired,
  availableFilters: PropTypes.array.isRequired,
  pivotQuarters: PropTypes.object,
  isConfigFilterOpen: PropTypes.bool,
  onFilterAdd: PropTypes.func,
  onFilterRemove: PropTypes.func,
  onFilterChange: PropTypes.func.isRequired
}

ReportFilters.defaultProps = {
  filters: [],
  availableFilters: [],
  pivotQuarters: {},
  isBasic: false
}

export default memo(ReportFilters)
