import React from 'react'
import moment from 'moment-timezone'
import { get, sortBy } from 'lodash'

import { format, getUniqEntityLocations, inMillions, formatLocalizedDate, DEFAULT_DATE_FORMAT } from '../../../../../../utils'

import { getEntityCategoryIcon, formatEntity } from '../../../../../../utils/entity'
import { ExpandableCell } from '../../../../../../components/agGrid/cell'

import './cell.component.css'

const EMPTY_PLACEHOLDER = '-'

/**
 * Title Custom Cell Component
 */
const TitleCell = ({ onTargetNameClick, onUtilityItemClick, ...data }) => {
  const entityType = data.entityType
  const isTarget = !!data._target
  const isDeal = !!data._deal

  data = formatEntity(data, entityType)
  return (
    <div className='cell cell--title' onClick={() => onTargetNameClick({ ...data, entityType })}>
      <i className={`cell_icon cell_icon--${entityType} ${getEntityCategoryIcon(entityType)}`} />
      <div className='cell_title'>
        {data.name}
      </div>
      {isTarget && <i className='cell_icon cell_icon--target q4i-targeting-4pt' />}
      {isDeal && <i className='cell_icon cell_icon--deal q4i-deal-4pt' />}
      <i
        className='cell_icon cell_icon--utility q4i-utility-4pt'
        onClick={(event) => {
          onUtilityItemClick(event, data)
        }}
      />
    </div>
  )
}

/**
 * Location Custom Cell Component
 */
const LocationCell = ({ address, onExpandMenuClick }) => {
  const locations = (getUniqEntityLocations(address) || []).map((addressItem) => {
    return {
      label: addressItem
    }
  })

  return (
    <ExpandableCell
      items={locations}
      onClick={onExpandMenuClick}
    />
  )
}

/**
 * AUM Custom Cell Component
 */
const AUMCell = ({ entityType, ...data }) => {
  const getAUM = ({ totalAum }) => totalAum && format(totalAum, 0)
  const getFundAUM = ({ portfolio_value }) => portfolio_value && inMillions(portfolio_value, 0)
  const aum = entityType === 'fund' ? getFundAUM(data) : getAUM(data)

  return aum || EMPTY_PLACEHOLDER
}

/**
 * EAUM Custom Cell Component
 */
const EAUMCell = ({ entityType, ...data }) => {
  const getAUM = ({ equityAum }) => equityAum && format(equityAum, 0)
  const getFundAUM = ({ equityAum }) => equityAum && inMillions(equityAum, 0)
  const aum = entityType === 'fund' ? getFundAUM(data) : getAUM(data)

  return aum ? aum + ' C' : EMPTY_PLACEHOLDER
}

/**
 * Purchasing Power Custom Cell Component
 */
const PurchasingPowerCell = ({ purchasingPower }) => {
  const value = parseInt(purchasingPower)
  return isNaN(value) ? EMPTY_PLACEHOLDER : format(value, 0)
}

/**
 * AI Score Custom Cell Component
 */
const AIScoreCell = ({ style, aiScore }) => {
  const isPassive = ['index', 'quant'].includes(style ? style.toLowerCase() : '')
  return (aiScore || aiScore === 0) ? aiScore : isPassive ? 'Passive' : EMPTY_PLACEHOLDER
}

/**
 * Quality Rating Custom Cell Component
 */
const QualityRatingCell = ({ rating }) => {
  if (!rating) {
    return EMPTY_PLACEHOLDER
  }

  return (
    <div className='cell_rating'>
      {rating}
    </div>
  )
}

/**
 * Last Activity Custom Cell Component
 */
const LastActivityCell = ({ dateTime }) => {
  if (!(dateTime?.items?.length)) {
    return EMPTY_PLACEHOLDER
  }
  const activity = sortBy(dateTime.items, 'start.date', 'desc')[0]
  const date = parseInt(get(activity, 'start.date', ''))
  return date ? formatLocalizedDate(moment(date).format('MM/DD/YYYY'),'MM/DD/YYYY')  : EMPTY_PLACEHOLDER
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 * @param onExpandMenuClick
 */
const CustomCell = ({ data = {}, column = {}, onExpandMenuClick, onTargetNameClick, onUtilityItemClick }) => {
  const cellData = data[column.colId]
  switch (column.colId) {
    case 'name':
      return (
        <TitleCell
          {...data}
          onTargetNameClick={onTargetNameClick}
          onUtilityItemClick={onUtilityItemClick}
        />
      )
    case 'address':
      return <LocationCell address={cellData} onExpandMenuClick={onExpandMenuClick} />
    case 'style':
      return cellData || EMPTY_PLACEHOLDER
    case 'turnover':
      return cellData || EMPTY_PLACEHOLDER
    case 'totalAum':
      return <AUMCell {...data} />
    case 'equityAum':
      return <EAUMCell {...data} />
    case 'purchasingPower':
      return <PurchasingPowerCell {...data} />
    case 'aiScore':
      return <AIScoreCell {...data} />
    case 'qualityRating':
      return <QualityRatingCell rating={cellData} />
    case 'activityConnection':
      return <LastActivityCell dateTime={cellData} />
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
