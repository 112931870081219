import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { addQueryParams } from '../../utils/http/http.util'

export const GET_REPORT_SNAPSHOT_REQUEST = 'GET_REPORT_SNAPSHOT_REQUEST'
export const GET_REPORT_SNAPSHOT_SUCCESS = 'GET_REPORT_SNAPSHOT_SUCCESS'
export const GET_REPORT_SNAPSHOT_ERROR = 'GET_REPORT_SNAPSHOT_ERROR'

export const RESET_SNAPSHOT = 'RESET_SNAPSHOT'
export const STORE_REPORT_SNAPSHOT = 'STORE_REPORT_SNAPSHOT'

const resources = {
  snapshot: '/report/dataItem/snapshot'
}

/**
 * Get Report Fields
 * @private
 */
const _getReportSnapshot = (url, body) => ({
  [CALL_API]: {
    types: [
      GET_REPORT_SNAPSHOT_REQUEST,
      GET_REPORT_SNAPSHOT_SUCCESS,
      GET_REPORT_SNAPSHOT_ERROR
    ],
    method: METHOD_TYPE.POST,
    endpoint: url,
    payload: body
  }
})

export const getReportSnapshot = (body, params) => (dispatch) => {
  const url = addQueryParams(resources.snapshot, params)
  return dispatch(_getReportSnapshot(url, body))
}

export const resetReportSnapshot = () => (dispatch) => {
  return dispatch({ type: RESET_SNAPSHOT })
}

export const storeReportSnapshot = (snapshotData) => (dispatch) => {
  return dispatch({ type: STORE_REPORT_SNAPSHOT, payload: snapshotData })
}
