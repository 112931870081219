import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import { closeModal, modalType } from '../../actions'
import { ActivityForm } from '../../components/activity'
import { AddToBriefingBook, BriefingBookEdit, TearsheetExport } from '../briefingBook'
import { AddToAddressBook, ContactForm } from '../../components/contact'
import { EntityCommentEdit, EntityUpdateRequest } from '../../components/entity'
import { DealEdit } from '../../components/deal'
import { ConfirmModal, ErrorModal, InfoModal } from '../../components/message'
import EmailPreferenceModal from '../emailPreferenceModal/emailPreferenceModal.component'
import EmailUndeliveredModal from '../emailUndeliveredModal/emailUndeliveredModal.component'
import PeerConfig from '../../components/peerConfigModal/peerConfigModal.container'
import GdprModal from '../../components/gdprModal/gdprModal.component'
import ConfirmNavigationModal from '../../components/confirmNavigationModal/confirmNavigationModal.component'
import EventDetailModal from '../../components/eventDetailModal/eventDetailModal.component'

const globalModals = {
  [modalType.ERROR_MODAL]: ErrorModal,
  [modalType.CONFIRM_MODAL]: ConfirmModal,
  [modalType.INFO_MODAL]: InfoModal,
  [modalType.ACTIVITY_MODAL]: ActivityForm,
  [modalType.EMAIL_PREFERENCE_MODAL]: EmailPreferenceModal,
  [modalType.EMAIL_UNDELIVERED_MODAL]: EmailUndeliveredModal,
  [modalType.ADD_TO_ADDRESS_BOOK_MODAL]: AddToAddressBook,
  [modalType.ADD_TO_BRIEFING_BOOK_MODAL]: AddToBriefingBook,
  [modalType.BRIEFING_BOOK_EDIT_MODAL]: BriefingBookEdit,
  [modalType.TEARSHEET_EXPORT_MODAL]: TearsheetExport,
  [modalType.CONTACT_MODAL]: ContactForm,
  [modalType.DEAL_EDIT_MODAL]: DealEdit,
  [modalType.ENTITY_COMMENT_EDIT_MODAL]: EntityCommentEdit,
  [modalType.ENTITY_UPDATE_REQUEST_MODAL]: EntityUpdateRequest,
  [modalType.PEER_CONFIG_MODAL]: PeerConfig,
  [modalType.GDPR_MODAL]: GdprModal,
  [modalType.CONFIRM_NAVIGATION_MODAL]: ConfirmNavigationModal,
  [modalType.EVENT_DETAIL_MODAL]: EventDetailModal
}

/**
 * Modal Provider Component
 */
const ModalProvider = ({ modals, closeModal }) => {
  return (modals || [])
    .filter((modal) => modal && (modal.type || modal.component))
    .map((modal, index) => {
      const ModalComponent = (!modal.component && modal.type) ? globalModals[modal.type] : modal.component
      const props = modal.props || {}
      return (
        <ModalComponent
          {...props}
          key={`modal--${index}`}
          onClose={(params) => {
            props.onClose && props.onClose(params)
            closeModal()
          }}
        />
      )
    })
}

ModalProvider.propTypes = {
  /**
   * Array of modals to render
   */
  modals: PropTypes.arrayOf(PropTypes.shape({
    /**
     * Modal Type
     */
    type: PropTypes.oneOf(Object.keys(globalModals)),

    /**
     * Modal Inner Component
     */
    component: PropTypes.func,

    /**
     * Modal Inner Component Props
     */
    props: PropTypes.shape({

      /**
       * Used as a callback function to lift the state up to parent component
       */
      onClose: PropTypes.func
    })
  }))
}

const mapStateToProps = (state) => ({
  modals: state.ui.modal.modals || []
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: bindActionCreators(closeModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalProvider))
