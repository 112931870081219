import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { Message } from '../../../../../../../../components'
import { MESSAGE_TYPE, GDPR } from '../../../../../../../../utils'

const propTypes = {
  onClose: PropTypes.func.isRequired
}

const defaultProps = {
  title: 'GDPR Forgotten'
}

/**
 * GDPR Message Component
 * @param props
 */
function GdprMessage ({ onClose, title }) {
  return (
    <Message
      visible
      type={MESSAGE_TYPE.INFO}
      title={title}
      message={GDPR}
      useHtml
      onClose={onClose}
      renderExitButton
      buttons={[{
        ui: 'citrus',
        label: 'Ok',
        onClick: onClose
      }]}
    />
  )
}

GdprMessage.propTypes = propTypes
GdprMessage.defaultProps = defaultProps

export default memo(GdprMessage)
