import moment from 'moment-timezone'
import { cloneDeep, get, set, intersectionWith, isPlainObject, mergeWith, isObject, isArray } from 'lodash'
import { getQuartersEndDates } from '../date.util'
import {
  dataOverwriteMap,
  emptyStateTitle,
  emptyStateMessages,
  nonPivotHoldingsFields,
  pivotEntityTypes
} from './report.const'
import { THEMES } from '../ui'
import { FAILED, IDLE } from '../../actions'

/**
 * Apply data overwrites for any applicable field within a set of data
 * @param reportSnapshot
 * @param fields
 * @returns {*}
 */
export const getOverwrittenData = (reportSnapshot, fields) => {
  // check to see if overrides are required
  const overrideFields = (fields || []).filter((field) => {
    const entityType = field && field._entityType && field._entityType.name
    return !!(dataOverwriteMap[entityType] && dataOverwriteMap[entityType][field.name])
  })

  if (!overrideFields.length) {
    return reportSnapshot
  }

  return (reportSnapshot || []).map((rowData) => {
    const newRowData = cloneDeep(rowData)

    overrideFields.forEach((field) => {
      if (!field._id || !field._entityType || !field._entityType.name) {
        return
      }

      const rowValue = get(newRowData, field.name)
      const textToOverride = dataOverwriteMap[field._entityType.name] && dataOverwriteMap[field._entityType.name][field.name]

      // if dynamic override
      if (textToOverride.func && typeof textToOverride.func === 'function') {
        set(newRowData, field.name, textToOverride.func(rowValue))
      }
      // custom override
      else if (rowValue === textToOverride.current) {
        set(newRowData, field.name, textToOverride.replace)
      }
    })

    return newRowData
  })
}

/**
 * Get pivot quarters from date filters
 * @param filters
 * @param entityTypes
 * @param format
 */
export const getEntityQuartersMap = (filters, entityTypes, format = 'YYYYMMDD') => {
  const result = {};

  (entityTypes || []).map((entityType) => entityType.name).forEach((entityType) => {
    // check if entity type requires pivot quarters
    if (pivotEntityTypes.indexOf(entityType) === -1) {
      return
    }

    const today = moment.utc().endOf('quarter')

    // initialize default quarters
    result[entityType] = getQuartersEndDates(today.clone().subtract(5, 'years'), today, format)

    // find all filters for current entity type
    const entityTypeFilters = filters
      .filter((filterItem) => filterItem._field && (filterItem._field._entityType.name === entityType))
      .filter((filterItem) => filterItem.filterType === 'date')
      .reduce((acc, filterItem) => {
        const filterKey = `${filterItem.filterType}.${filterItem.state.operator.value}.${filterItem._field.name}`

        if (!acc[filterKey]) {
          acc[filterKey] = filterItem
        } else {
          switch (filterItem.state.operator.value) {
            case 'gte':
            case 'gt':
              if (moment(acc[filterKey].state.value).utc().isBefore(moment(filterItem.state.value).utc())) {
                acc[filterKey] = filterItem
              }
              break
            case 'lte':
            case 'lt':
              if (moment(acc[filterKey].state.value).utc().isAfter(moment(filterItem.state.value).utc())) {
                acc[filterKey] = filterItem
              }
              break
            default:
              break
          }
        }
        return acc
      }, {})

    const uniqueEntityTypeFilters = Object.keys(entityTypeFilters).map((entityTypeFilterKey) => {
      return entityTypeFilters[entityTypeFilterKey]
    })

    uniqueEntityTypeFilters.forEach((entityTypeFilter) => {
      const operator = entityTypeFilter.state.operator.value
      const field = entityTypeFilter._field.name
      const value = entityTypeFilter.state.value

      // do not recalculate quarters if value is null
      if (!value) {
        return
      }

      if (nonPivotHoldingsFields.includes(field)) {
        switch (operator) {
          case 'gte':
          case 'gt':
            result[entityType] = intersectionWith(result[entityType], getQuartersEndDates(moment.utc(value).clone().startOf('quarter'), today, format))
            break
          case 'lte':
          case 'lt':
            result[entityType] = intersectionWith(result[entityType], getQuartersEndDates(today.clone().subtract(5, 'years'), moment.utc(value).clone().endOf('quarter'), format))
            break
          default:
            break
        }
      }
    })
  })

  return result
}

/**
 * Get Flattened Data
 * @param snapshot
 */
export const getUnwindData = (snapshot) => {
  return (snapshot || []).reduce((_data, rootItem) => {
    const total = []
    let groupedTotal = []
    const reportTotal = []
    let holdings
    let groupedRows
    const rootProps = {}

    for (const key in rootItem) {
      if (!isArray(rootItem[key])) {
        rootProps[key] = rootItem[key]
      } else if (key === 'holdings') {
        holdings = rootItem[key]
      } else if (key === 'groupedRows') {
        groupedRows = rootItem[key]
      } else {
        // convert list of objects to object of lists
        // [{"name":"Karim"}, {"name":"Yehia"}] => {"name":[Karim, Yehia]}
        rootProps[key] = rootItem[key] && rootItem[key].reduce((acc, eachVal) => {
          if (isPlainObject(eachVal)) {
            for (const objKey in eachVal) {
              if (eachVal.hasOwnProperty(objKey)) {
                if (!acc[objKey]) {
                  acc[objKey] = []
                }
                acc[objKey].push(eachVal[objKey])
              }
            }
          }
          return acc
        }, {})
      }
    }

    if (holdings) {
      holdings.forEach((eachHolding) => {
        total.push({ ...rootProps, ...{ holdings: eachHolding } })
      })
    } else {
      total.push(rootProps)
    }

    if (groupedRows) {
      holdings && total.forEach((eachRootElement) => {
        // add total row for first entity type, exclude holdings from secondary entity type
        groupedTotal.push({ ...eachRootElement })
      })

      groupedRows.length > 0 ? groupedRows.forEach((eachGroupedRow) => {
        groupedTotal.push(mergeWith(eachGroupedRow, rootProps, (objValue, srcValue) => {
          if (isObject(objValue) && isObject(srcValue) && !isArray(objValue) && !isArray(srcValue)) {
            return { ...objValue, ...srcValue }
          }
          return objValue || srcValue
        }))

      }) : (!holdings && groupedTotal.push({ ...rootProps }))
    } else {
      groupedTotal = total
    }

    groupedTotal.forEach((eachGroupTotal) => {
      const groupedTotalWithoutHoldings = {}
      let groupTotalHoldings

      for (const key in eachGroupTotal) {
        if (!isArray(eachGroupTotal[key])) {
          groupedTotalWithoutHoldings[key] = eachGroupTotal[key]
        } else if (key === 'holdings') {
          groupTotalHoldings = eachGroupTotal[key]
        }
      }

      if (groupTotalHoldings) {
        groupTotalHoldings.forEach((eachGroupedHolding) => {
          reportTotal.push({ ...groupedTotalWithoutHoldings, ...{ holdings: eachGroupedHolding } })
        })
      } else {
        reportTotal.push(eachGroupTotal)
      }
    })

    reportTotal.forEach((each) => {
      _data.push(each)
    })

    return _data
  }, [])
}

/**
 * Get props for the NoContentMessage component used in ag-grid
 */
export const getNoRowsOverlayParams = (fields, snapshotStatus, fetchSnapshot) => {
  if (fields && fields.length && snapshotStatus === IDLE) {
    return {}
  }

  const isFailed = snapshotStatus === FAILED
  const message = isFailed
    ? emptyStateMessages.snapshotFailed
    : (fields && fields.length) ? emptyStateMessages.noDataAvailable : emptyStateMessages.missingReportConfig

  return {
    image: require('../../resources/images/noContent/report_no_color.png').default,
    theme: THEMES.LIGHT,
    title: emptyStateTitle,
    message,
    actions: (fetchSnapshot && isFailed) ? [{
      theme: 'rain',
      icon: 'q4i-sync-4pt',
      label: 'Refresh',
      onClick: () => fetchSnapshot()
    }] : null
  }
}
