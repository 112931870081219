import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { getClassName } from '../../../utils/ui'
import './drawerLibraryCategoryList.component.css'

function DrawerLibraryCategoryList (props) {
  const { isPrimaryGroup, categories, selectedCategory, onSelect } = props

  return (
    <ul className='drawer-library-category-list'>
      {(categories || []).map((category) => {
        const { value, label, icon } = category
        const isSelected = value === (selectedCategory && selectedCategory.value)

        const className = getClassName('drawer-library-category-list_item', [
          { condition: isSelected, trueClassName: 'drawer-library-category-list_item--selected' }
        ])

        const handleCategorySelect = (event) => onSelect(event, category, isPrimaryGroup)

        return (
          <li key={`drawer-library-category--${value}`} className={className} onClick={handleCategorySelect}>
            <span className='drawer-library-category-list_icon'><i className={icon} /></span>
            <span className='drawer-library-category-list_label'>{label}</span>
            <span className='drawer-library-category-list_arrow'><i className='q4i-caret-sm-right-4pt' /></span>
          </li>
        )
      })}
    </ul>
  )
}

DrawerLibraryCategoryList.propTypes = {
  /**
   * Used to determine whether or not the list of categories are first-level
   */
  isPrimaryGroup: PropTypes.bool.isRequired,

  /**
   * An array of categories to supply to the component
   */
  categories: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string
    }))
  })).isRequired,

  /**
   * A category object of the current user-selected category
   */
  selectedCategory: PropTypes.shape({
    value: PropTypes.string.isRequired
  }),

  /**
   * A callback fired when the user selects a category
   */
  onSelect: PropTypes.func.isRequired
}

export default memo(DrawerLibraryCategoryList)
