import shortid from 'shortid'
import moment from 'moment-timezone'
import { get, uniqBy, isUndefined } from 'lodash'
import { formatDate, getCurrentDate, getPreviousQuarter } from '../date.util'
import { getIndices } from '../stock/stock.util'
import {
  getFlattenedData,
  pivotFieldDefs,
  nonPivotHoldingsFields,
  ownershipEntityTypes,
  ownershipShareholderIdEntityTypes,
  ownershipSurveillanceEntityTypes,
  surveillanceEntityTypes
} from '../report'

/**
 * Get report templates based on user subscriptions
 * @returns {*[]}
 */
export const getFilteredTemplates = (subscriptionsService, reportDataTemplates) => {
  return subscriptionsService ? reportDataTemplates.filter((eachTemplate) => {
    const subscriptions = (eachTemplate._entityType || []).reduce((acc, eachEntityType) => {
      (eachEntityType.subscriptions || []).map((each) => {
        return acc.push(each)
      })
      return acc
    }, [])

    return (subscriptions.includes(subscriptionsService) || eachTemplate.reportCategories.includes('stock'))
  }) : reportDataTemplates
}

/**
 * Parse entity types using Report Entity Types response
 * @param reportEntityTypes
 * @param entityTypeIds
 * @returns {any[]}
 */
export const parsePopulatedEntityTypes = (reportEntityTypes, entityTypeIds) => {
  if (!entityTypeIds || !entityTypeIds.length) {
    return []
  }

  return entityTypeIds.map((id) => {
    return reportEntityTypes.find((type) => type._id === id)
  }).filter((type) => type)
}

/**
 * Parse fields using Report Field response
 * @param reportFields
 * @param fieldIds
 * @returns {any[]}
 */
export const parsePopulatedFields = (reportFields, fieldIds) => {
  if (!fieldIds || !fieldIds.length) {
    return []
  }

  return fieldIds.map((id) => {
    return reportFields.find((reportField) => reportField._id === id)
  }).filter((field) => field)
}

/**
 * Parse filters using report fields
 * @param fields
 * @param filters
 * @returns {Array}
 */
export const parsePopulatedFilters = (fields, filters) => {
  if (!fields || !fields.length || !filters || !filters.length) {
    return []
  }

  return filters.map((filter) => {
    const _field = fields.find((field) => field._id === filter._field)

    if (!_field) {
      return null
    }

    const _filter = _field._filter
    const operator = (_filter.filterOptions || []).find((option) => option.value === filter.operator)

    return {
      ..._filter,
      _field,
      _id: shortid.generate(),
      state: {
        value: filter.value,
        operator,
        isOpen: false,
        quarter: filter.quarter
      }
    }
  }).filter((filter) => filter)
}

/**
 * Parse indices using all known indices
 * @param indices
 * @returns {*[]}
 */
export const parsePopulatedIndices = (indices) => {
  const allIndices = getIndices()

  return (indices || []).map((index) => {
    return allIndices.find((i) => i.symbol === index.symbol && i.group === index.group)
  }).filter((index) => index)
}

/**
 * Get current quarter end date based on entity type
 * @param entityType
 * @return {string}
 */
export const getReportQuarterEndDate = (entityType) => {
  // for surveillance data - use current quarter
  if (surveillanceEntityTypes.indexOf(entityType) > -1) {
    return getCurrentDate().endOf('quarter').format('YYYYMMDD')
  } else {
    return getPreviousQuarter().endOf('quarter').format('YYYYMMDD')
  }
}

/**
 * Get mapped secondaries entity types
 * @param reportEntityTypes
 * @param secondaries
 */
const getMappedSecondaryEntityTypes = (reportEntityTypes, secondaries) => {
  return (secondaries || []).reduce((res, eachSecondary) => {
    const _found = reportEntityTypes.find((eachEntity) => {
      return eachEntity._id === eachSecondary._id
    })
    if (_found) {
      res.push(_found)
    }
    return res
  }, [])
}

/**
 * Calculates available data types for the report
 * @param reportEntityTypes
 * @param entityTypes
 * @param subscriptionServices
 * @returns {*}
 */
export const getAvailableEntityTypes = (reportEntityTypes, entityTypes, subscriptionServices) => {
  const subscriptionService = (subscriptionServices && ((subscriptionServices.surveillance && 'surveillance') || (subscriptionServices.shareholder_id && 'shareholder_id')))
  const currentEntityTypes = (!entityTypes || !entityTypes.length)
    ? reportEntityTypes.filter((type) => type && type.isPrimary)
    : entityTypes[0] && getMappedSecondaryEntityTypes(reportEntityTypes, entityTypes[0]._secondaries)

  // if no additional ownership service available - return primary entity types with 13F ownership
  if (!subscriptionService) {
    return currentEntityTypes.filter((entityType) => ownershipSurveillanceEntityTypes.concat(ownershipShareholderIdEntityTypes).indexOf(entityType.name) === -1)
  }

  // if surveillance available filter out 13F and shareholder id
  if (subscriptionService === 'surveillance') {
    return currentEntityTypes.filter((entityType) => ownershipEntityTypes.concat(ownershipShareholderIdEntityTypes).indexOf(entityType.name) === -1)
  }

  // if shareholder id available filter out 13F and surveillance
  if (subscriptionService === 'shareholder_id') {
    return currentEntityTypes.filter((entityType) => ownershipEntityTypes.concat(ownershipSurveillanceEntityTypes).indexOf(entityType.name) === -1)
  }
}

/**
 * Calculates fields available for search
 * available_fields = all_fields(reportFields) / selected_entities(entityTypes) - selected_fields(fields)
 * @param reportFields
 * @param entityTypes
 * @param fields
 * @returns {*}
 */
export const getAvailableFields = (reportFields, entityTypes, fields) => {
  const availableFields = []

  if (!entityTypes || !entityTypes.length) {
    return availableFields
  }

  entityTypes.forEach((entityType) => {
    const matchedFields = (reportFields || []).filter((reportField) => {
      const { _id, _entityType, hidden } = reportField

      return (
        !hidden &&
        (entityType._id === (_entityType && _entityType._id)) &&
        !(fields || []).find((field) => field._id === _id)
      )
    })

    availableFields.push(...matchedFields)
  })

  return availableFields
}

/**
 * Get a filtered list of availableFields based on entity type
 * @param availableFields
 * @param entityType
 * @param query
 * @returns {Array|*}
 */
export const getFilteredAvailableFields = (availableFields, entityType, query) => {
  if (!availableFields || !availableFields.length || !entityType) {
    return []
  }

  return availableFields.filter((field) => {
    if ((field._entityType && field._entityType._id) !== entityType._id) {
      return false
    }

    if (query) {
      return field.label.toLowerCase().indexOf(query.toLowerCase()) >= 0
    }

    return true
  })
}

/**
 * Calculates fields available for search
 * available_fields = all_fields(reportFields) / selected_entities(entityTypes) - selected_fields(fields)
 * @param reportFields
 * @returns {*}
 */
export const getAvailablePivotFields = (reportFields) => {
  if (!reportFields || !reportFields.length) {
    return []
  }

  return uniqBy(reportFields, (field) => field.name).filter((field) => {
    if (!field || !field.type) {
      return false
    }

    return field.type === 'choice' || field.type === 'boolean'
  })
}

/**
 * Calculates indices available for search
 * @param reportIndices
 */
export const getAvailableIndices = (reportIndices) => {
  const indices = getIndices()
  const flattenedReportIndices = getFlattenedData(reportIndices, 'name')

  return indices.filter((index) => !flattenedReportIndices.includes(index.name))
}

/**
 * Get Field Data by ID
 * @param reportFields
 * @param _id
 */
export const getFieldById = (reportFields, _id) => {
  return (reportFields || []).find((field) => (field && field._id) === _id)
}

/**
 * Get isPrimaryField value
 * @param entityType
 * @param fields
 * @param fieldId
 * @returns {boolean}
 */
export const getIsPrimaryField = (entityType, fields, fieldId) => {
  if (!fieldId) {
    return false
  }

  const primaryEntityTypeId = entityType && entityType.length && entityType[0]._id
  const field = getFieldById(fields, fieldId)

  if (!primaryEntityTypeId || !field || !field._entityType) {
    return false
  }

  return field._entityType._id === primaryEntityTypeId
}

/**
 * Determine whether or not the field has a filter associated with it
 * @param field
 * @param filters
 * @returns {boolean}
 */
export const getIsFieldWithFilter = (field, filters) => {
  return !!(filters || []).find((filter) => (filter._field && filter._field._id) === (field && field._id))
}

/**
 * Calculates filters available for search
 * @param reportFields
 * @param fields
 * @returns {*}
 */
export const getAvailableFilters = (reportFields, fields) => {
  const _fields = [...(fields || [])]

  _fields.filter((each) => {
    return each.name.indexOf('holdings.') > -1
  }).map((eachPivotField) => {
    if (!_fields.find((eachField) => {
      return eachField.name === 'holdings.current_qtr_date' &&
        eachPivotField._entityType._id === eachField._entityType._id
    })) {
      const _found = reportFields.find((eachReportField) => {
        return eachReportField.name === 'holdings.current_qtr_date' &&
          eachReportField._entityType._id === eachPivotField._entityType._id
      })
      _found && _fields.push(_found)
    }
    return eachPivotField
  })

  return _fields.filter((fieldItem) => fieldItem && fieldItem._filter).map((field) => {
    return {
      ...field._filter,
      _field: field
    }
  })
}

/**
 * Get a filtered list of availableFilters based on query
 * @param availableFilters
 * @param query
 * @returns {Array|*}
 */
export const getFilteredAvailableFilters = (availableFilters, query) => {
  if (!availableFilters || !availableFilters.length) {
    return []
  }

  if (!query) {
    return availableFilters
  }

  return availableFilters.filter((filter) => {
    return filter && filter._field && filter._field.label.toLowerCase().indexOf(query.toLowerCase()) >= 0
  })
}

/**
 * Get parsed filters with initial values
 * @param fields
 * @param filters
 */
export const getParsedFilters = (fields, filters) => {
  if (!fields || !fields.length || !filters || !filters.length) {
    return []
  }

  const parsedFilters = parsePopulatedFilters(fields, filters)

  return (parsedFilters || []).map((filter) => {
    switch (filter.filterType) {
      case 'date':
        const { value, quarter } = filter.state
        let initialValue = value || quarter

        // surveillance top buyers and sellers
        const entityType = filter._field && filter._field._entityType && filter._field._entityType.name
        const fieldName = filter._field && filter._field.name
        const operator = filter.state && filter.state.operator && filter.state.operator.value

        if (entityType === 'institutionSurveillance' && fieldName === 'holdings.report_date') {
          switch (operator) {
            case 'lte':
            case 'lt':
              initialValue = getCurrentDate().endOf('day')
              break
            case 'gte':
            case 'gt':
              // need to subtract 6 days since we currently don't have gt operator for date
              initialValue = getCurrentDate().subtract(6, 'days')
              break
            default:
              break
          }
        } else if (/^-?\d{1,2}$/.test(initialValue)) {

          // within 45 days - subtract 1 extra quarter
          if ((entityType === 'institution' || entityType === 'fund')
            && fieldName === 'holdings.current_qtr_date'
            && (moment.utc().subtract(45, 'days').isSameOrBefore(moment.utc().startOf('quarter')))) {
            initialValue--
          }

          switch (operator) {
            case 'lte':
            case 'lt':
              initialValue = moment.utc().add(initialValue, 'quarter').endOf('quarter').endOf('day')
              break
            case 'gte':
            case 'gt':
              initialValue = moment.utc().add(initialValue, 'quarter').startOf('quarter').startOf('day')
              break
            default:
              break
          }
        }

        if (initialValue) {
          filter.state.value = initialValue
        }

        break
      default:
        break
    }

    return filter
  })
}

/**
 * Get initial report filter state
 * @param filter
 * @param _pivotQuarters
 * @returns {*}
 */
export const getInitialFilterState = (filter, _pivotQuarters) => {
  const { filterType, filterOptions } = filter

  let initialState = {
    isOpen: true,
    value: null
  }

  switch (filterType) {
    case 'number':
      const isPivotFilter = getIsPivotFilter(filter)

      if (isPivotFilter) {
        const entityType = filter._field && filter._field._entityType && filter._field._entityType.name
        const currentQuarter = getCurrentDate().endOf('quarter').format('YYYYMMDD')
        initialState.quarter = _pivotQuarters && _pivotQuarters[entityType] && (_pivotQuarters[entityType].find((quarter) => quarter === currentQuarter) || _pivotQuarters[entityType][0])
      }

      initialState.operator = filterOptions && filterOptions.length && filterOptions[0]
      break
    case 'string':
      initialState.operator = filterOptions && filterOptions.length && filterOptions[0]
      break
    case 'date':
      initialState.operator = filterOptions && filterOptions.length && filterOptions[0]
      initialState.value = getPreviousQuarter(new Date()).endOf('quarter').startOf('day')
      break
    case 'boolean':
      initialState.value = true
      break
    case 'choice':
      initialState.value = (filterOptions || []).map((option) => option.value)
      break
    default:
      initialState = {}
  }

  return initialState
}

/**
 * Get a filter's summary label
 * @param filter
 */
export const getFilterSummaryLabel = (filter) => {
  const { filterType, filterOptions, state } = filter
  const operator = (state && state.operator) || (filterOptions && filterOptions.length && filterOptions[0])

  switch (filterType) {
    case 'number':
    case 'string':
      return operator ? state.value ? `${operator.label} ${state.value}` : `${operator.label} ...` : '-'
    case 'date':
      return operator ? state.value ? `${operator.label} ${formatDate(state.value, 'MM/DD/YY', true)}` : `${operator.label} ...` : '-'
    case 'boolean':
      return state.value ? 'Include' : 'Exclude'
    case 'choice':
      const filteredOptions = (state.value || []).map((valueItem) => {
        const option = filterOptions.find((option) => option.value === valueItem)
        return option && option.label
      }).filter((option) => option)

      return filteredOptions.length ? filteredOptions.join(', ') : 'None selected'
    default:
      return '-'
  }
}

/**
 * Get formatted filters for saving / fetching report
 * @param filters
 * @param availablePivotQuarters
 * @returns {Array}
 */
export const getFormattedFilters = (filters, availablePivotQuarters) => {
  const filteredFilters = (filters || []).filter((filter) => {
    return filter && filter.state && !isUndefined(filter.state.value)
  })

  return filteredFilters.map((filter) => {
    const { _field, state } = filter
    const { operator, value } = state
    let { quarter } = state

    if (_field && _field.name.indexOf('holdings.') > -1 && !quarter) {
      const entityType = _field._entityType && _field._entityType.name
      const availablePivotQuarter = availablePivotQuarters &&
        availablePivotQuarters[entityType] &&
        availablePivotQuarters[entityType].length &&
        availablePivotQuarters[entityType][0]

      quarter = availablePivotQuarter || getReportQuarterEndDate(entityType)
    }

    const _filter = {
      _field: _field && _field._id,
      operator: operator && operator.value,
      value
    }

    if (nonPivotHoldingsFields.indexOf(_field.name) < 0) {
      _filter.quarter = quarter
    }

    return _filter
  })
}

/**
 * Get formatted indices for saving / fetching report
 * @param indices
 * @returns {[]}
 */
export const getFormattedIndices = (indices) => {
  return (indices || []).map((index) => ({
    symbol: index.symbol,
    group: index.group
  }))
}

/**
 * Get a filtered list of availableIndices based on query
 * @param availableIndices
 * @param query
 * @returns {*}
 */
export const getFilteredAvailableIndices = (availableIndices, query) => {
  return availableIndices.filter((index) => {
    const { symbol, group, fullname } = index || {}

    function containsQuery (string, query) {
      return string && string.toLowerCase().indexOf(query.toLowerCase()) >= 0
    }

    return containsQuery(symbol, query) || containsQuery(group, query) || containsQuery(fullname, query)
  })
}

/**
 * Get filtered array of report config prop based on an inclusive array
 * @param propArray
 * @param valuePath
 * @param filterArray
 * @returns {Array|*}
 */
const getFilteredConfigProps = (propArray, valuePath, filterArray) => {
  if (!propArray || !propArray.length) {
    return []
  }

  if (!filterArray) {
    return propArray
  }

  return propArray.filter((prop) => filterArray.includes(get(prop, valuePath)))
}

/**
 * Get New Fields and Filters based on Entity Types
 * @param entityTypes
 * @param fields
 * @param pivotFields
 * @param filters
 * @param sort
 * @param limit
 * @returns {*}
 */
export const getNewReportConfig = (entityTypes, fields, pivotFields, filters, sort, limit) => {
  if (!entityTypes || !entityTypes.length) {
    return {
      _fields: [],
      _pivotFields: [],
      _filters: [],
      _sort: [],
      _limit: []
    }
  }

  const flattenedEntityTypes = getFlattenedData(entityTypes)
  const _fields = getFilteredConfigProps(fields, '_entityType._id', flattenedEntityTypes)
  const _pivotFields = getFilteredConfigProps(pivotFields, '_entityType._id', flattenedEntityTypes)
  const _filters = getFilteredConfigProps(filters, '_field._entityType._id', flattenedEntityTypes)
  const _limit = getFilteredConfigProps(limit, '_entityType', flattenedEntityTypes)
  const _sort = (sort || []).filter((sortItem) => {
    const sortedField = _fields.find((field) => field._id === sortItem._field)
    const entityTypeId = sortedField && sortedField._entityType && sortedField._entityType._id
    return flattenedEntityTypes.indexOf(entityTypeId) >= 0
  })

  return {
    _fields,
    _pivotFields,
    _filters,
    _sort,
    _limit
  }
}

/**
 * Get default field for entity type
 * @param entityType
 * @return {string[]}
 */
export const getDefaultEntityTypeFields = (entityType) => {
  switch (entityType && entityType.name) {
    case 'institution':
    case 'shareholderid':
      return ['holder_name', 'holdings.current', 'holdings.current_qtr_date']
    case 'institutionSurveillance':
      return ['institution_name', 'holdings.current', 'holdings.current_qtr_date']
    case 'fund':
    case 'fundSurveillance':
    case 'fundShareholderid':
      return ['fund_name', 'holdings.current', 'holdings.current_qtr_date']
    case 'activity':
      return ['title']
    case 'contact':
      return ['full_name']
    case 'stock':
      return ['ID', 'Last', 'Date']
    default:
      break
  }
}

/**
 * Check if field is use for row grouping
 * @param field
 * @returns {boolean}
 */
export const getIsGroupField = (field) => {
  if (!field || !field._id || !field._entityType || !field._entityType.groupedFields) {
    return false
  }

  return (field._entityType.groupedFields || []).indexOf(field._id) !== -1
}

/**
 * Check if filter governs a pivot field
 * @param filter
 * @return {boolean}
 */
export const getIsPivotFilter = (filter) => {
  if (!filter || !filter._field || nonPivotHoldingsFields.indexOf(filter._field.name) >= 0) {
    return false
  }

  return !!(pivotFieldDefs.find((pivotField) => filter._field.name.startsWith(pivotField)))
}

/**
 * Get determinant for whether or not to refetch the snapshot from a report config change
 * @param changeType
 * @param data
 * @return {boolean}
 */
export const getShouldFetchSnapshot = (changeType, data) => {
  let shouldFetchSnapshot = false

  switch (changeType) {
    case 'filter-add':
      const { filterType } = data

      if (filterType === 'boolean' || filterType === 'date') {
        shouldFetchSnapshot = true
      }

      break
    case 'filter-change':
      const { _prevState, _newState } = data

      const _operator = _newState.operator && _newState.operator.value
      const _prevOperator = _prevState.operator && _prevState.operator.value

      const newOperator = _operator !== _prevOperator
      const newQuarter = _newState.quarter !== _prevState.quarter
      const newValue = _newState.value !== _prevState.value
      const validValue = (_newState.value || _newState.value === false || (!!_prevState.value && _newState.value === ''))

      if (
        validValue &&
        ((newOperator || newQuarter || newValue) || (!newValue && validValue && (newOperator || newQuarter)))
      ) {
        shouldFetchSnapshot = true
      }

      break
    default:
      break
  }

  return shouldFetchSnapshot
}

/**
 * Get available pivot quarters based on entityType
 * @param _pivotQuarters
 * @param entityType
 * @returns {*|Array|Array}
 */
export const getAvailablePivotQuarters = (_pivotQuarters, entityType) => {
  if (!_pivotQuarters || !entityType || !entityType.name) {
    return []
  }

  return _pivotQuarters[entityType.name] || []
}

/**
 * Get formatted sort items for saving / fetching report
 * @param sort
 * @param fields
 * @param _pivotQuarters
 * @return {Array}
 */
export const getFormattedSort = (sort, fields, _pivotQuarters) => {
  return (sort || []).map((sortItem) => {
    const { _field, quarter } = sortItem
    const sortField = fields.find((field) => field._id === _field)

    if (sortField && sortField.name.indexOf('holdings.') > -1 && !quarter) {
      const entityType = sortField._entityType
      const availablePivotQuarters = getAvailablePivotQuarters(_pivotQuarters, entityType)

      return {
        ...sortItem,
        quarter: availablePivotQuarters[0] || getReportQuarterEndDate(entityType.name)
      }
    }

    return sortItem
  })
}

/**
 * Get non-holding related sort items
 * @param sort
 * @param fields
 */
export const getFilteredSort = (sort, fields) => {
  if (!sort || !sort.length || !fields || !fields.length) {
    return []
  }

  return sort.filter((sortItem) => {
    const sortField = fields.find((field) => field._id === sortItem._field)
    return sortField && sortField.name.indexOf('holdings.') === -1
  })
}

/**
 * Default limit value
 */
export const getDefaultLimitValue = () => {
  return 10
}

/**
 * Max limit value
 */
export const getMaxLimitValue = () => {
  return 250
}
