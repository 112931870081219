import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// components
import { RenderHTML } from '../../../index'

const propTypes = {
  bio: PropTypes.string
}

const defaultProps = {
  bio: ''
}

/**
 * Biography Component
 * @param props
 */
function Biography ({ bio }) {
  if (!bio || !bio.length) {
    return null
  }

  return (
    <Grid container spacing={2} className='tearsheet_section'>
      <Grid item xs={12} className='tearsheet_section-title'>Background</Grid>
      <Grid item xs={12} className='tearsheet_section-content tearsheet_section-content-editor'>
        <RenderHTML html={(bio || '').replace(/\\r|\\n/g, '<br>')} />
      </Grid>
    </Grid>
  )
}

Biography.propTypes = propTypes
Biography.defaultProps = defaultProps

export default memo(Biography)
