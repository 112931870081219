import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './pageMessage.component.scss'

/**
 * Message Component
 */
class PageMessage extends PureComponent {
  /**
   * Render Message Component
   * @returns {*}
   */
  render () {
    const { adjacentComponent, message } = this.props

    return (
      <div className='page-message'>
        <div className='page-message-inner'>
          {message}
        </div>
        { adjacentComponent }
      </div>
    )
  }
}

PageMessage.propTypes = {
  message: PropTypes.string.isRequired,
}

export default PageMessage