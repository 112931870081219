import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

// actions
import { useCurrentQuery, GET_FUND_CURRENT_HOLDINGS, GET_INST_CURRENT_HOLDINGS } from '../../hook'
import { openModal, modalType } from '../../../../actions/ui'
import { useExport } from '../../../../services/csvExport/csvExport.service'

// components
import CurrentHoldingToolbar from './toolbar/toolbar.component'
import CurrentHoldingTable from './table/table.component'
import {
  formatDate,
  formatTitleCase,
  getPageSizeFromStorage,
  ENTITY_TYPE,
  HOLDING_CAP_GROUP,
  EXPORT_DATE_FORMAT
} from '../../../../utils'
import { get, flatten, isNull, omitBy } from 'lodash'
import { getLocalizedCurrency } from '../../../../utils/currencyLocalization'

const { FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string,
  toolbarTheme: PropTypes.string,
  toolTheme: PropTypes.string,
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.oneOf([FUND, INSTITUTION]).isRequired
}

const defaultProps = {
  entityType: INSTITUTION
}

const PAGE_SIZE_ID = 'current-holding-grid'

/**
 * Current Holding Grid Component
 * @param props
 */
function CurrentHoldingGrid (props) {
  const { dataIdPrefix, toolbarTheme, toolTheme, entityId, entityType, history, openModal } = props
  const [state, setState] = useState({
    search: null,
    filter: {
      sector: null,
      capGroup: null,
      region: null
    },
    listOptions: {
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
  })
  const { search, filter, listOptions } = state
  const variables = omitBy({
    entityId,
    search,
    region: flatten(filter.region),
    sector: flatten(filter.sector),
    capGroup: flatten(filter.capGroup),
    currencyCode: getLocalizedCurrency(),
    ...listOptions
  }, isNull)

  const { data, loading, client } = useCurrentQuery(entityType, { variables })
  const { generateExport, exporting } = useExport({
    onError: () => {
      openModal({
        type: modalType.ERROR_MODAL
      })
    }
  })

  const source = (entityType === FUND) ? FUND : 'inst'
  const holdings = get(data, `${source}HoldingCurrent.items`)
  const total = get(data, `${source}HoldingCurrent.count`, 0)

  /**
   * Handle query change
   * @param query
   */
  const handleQueryChange = (query = {}) => {
    const options = query.listOptions ? { ...query } : { ...query, listOptions: { ...listOptions, page: 1 } }
    setState({ ...state, ...options })
  }

  /**
   * Format mapper for CSV export
   */
  const holdingsCSVMapper = (holding) => {
    const {
      qtrChange, current, marketValue, marketValueChange, percentPortfolio, percentTSO,
      reportDate, securityCapGroup, securityName, filingType, filingSource, holderType
    } = holding
    const marketCap = HOLDING_CAP_GROUP
      .find((option) => option.value === securityCapGroup)

    const sourceData = holderType === FUND ? filingType : filingSource
    return {
      'Company Name': securityName,
      'Current Position': current,
      'Change': qtrChange,
      [`Market Value (${getLocalizedCurrency()})`]: marketValue,
      [`Market Value Change (${getLocalizedCurrency()})`]: marketValueChange,
      'Market Cap': (marketCap && marketCap.label) || 'Other',
      'Percent TSO': percentTSO,
      'Percent of Portfolio': percentPortfolio,
      'Report Date': reportDate ? formatDate(new Date(Number(reportDate)), EXPORT_DATE_FORMAT, true, true) : '-',
      'Source': sourceData ? formatTitleCase(sourceData) : null
    }
  }

  /**
   * Handle export
   */
  const handleExport = () => {
    const params = {
      client,
      variables: { ...variables, limit: 0 },
      query: (entityType === FUND) ? GET_FUND_CURRENT_HOLDINGS : GET_INST_CURRENT_HOLDINGS,
      dataPath: `data.${source}HoldingCurrent.items`,
      fileName: `${source}_current_holdings.csv`,
      formatter: holdingsCSVMapper
    }

    generateExport(params)
  }

  return (
    <>
      <CurrentHoldingToolbar
        dataId={`${dataIdPrefix}CurrentHoldings`}
        toolbarTheme={toolbarTheme}
        toolTheme={toolTheme}
        search={search}
        filter={filter}
        noData={!holdings || !holdings.length}
        onQueryChange={handleQueryChange}
        onExport={handleExport}
        exporting={exporting}
      />
      <CurrentHoldingTable
        pageSizeId={PAGE_SIZE_ID}
        loading={loading}
        data={holdings}
        total={total}
        listOptions={listOptions}
        onQueryChange={handleQueryChange}
        history={history}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

CurrentHoldingGrid.propTypes = propTypes
CurrentHoldingGrid.defaultProps = defaultProps

export default withRouter(connect(mapDispatchToProps)(CurrentHoldingGrid))
