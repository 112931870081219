import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'

// components
import { Select } from '../../../index'
import { INDICES, THEMES } from '../../../../utils'
import { get } from 'lodash'

import './index.component.scss'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  indices: PropTypes.array,
  onIndexAdd: PropTypes.func.isRequired,
  onIndexDelete: PropTypes.func.isRequired
}

const defaultProps = {
  theme: THEMES.DARK,
  indices: []
}

/**
 * Indices Component
 * @param props
 */
const Indices = ({ dataId, indices, onDelete }) => {
  return (
    (indices || []).map((index, idx) => (
      <div
        key={get(index, 'value')}
        data-id={(idx === 0) ? dataId : null}
        style={{ color: `${index.color}` }}
        className='security-stock-overview_index-item'>
        <div className='security-stock-overview_index-item_label'>{get(index, 'label')}</div>
        <i className='security-stock-overview_index-item_delete q4i-close-4pt' onClick={() => onDelete(index)} />
      </div>
    ))
  )
}

/**
 * Get formatted options
 * Remove selected index if necessary
 */
function getOptions (suggestions, indices) {
  // ToDo: Remove Temp Fix
  const hideSuggetion = [
    'OMXCMCPI',
    'INSTR.IV',
    'SX7E',
    'SXXP'
  ]

  const filteredSuggestions = (suggestions || []).filter((suggestion) => {
    return !hideSuggetion.includes(get(suggestion, 'name'))
  })

  const selected = (indices || []).map((index) => index.value)
  return (filteredSuggestions || [])
    .filter((suggestion) => !selected.includes(get(suggestion, 'symbol')))
    .map((suggestion) => ({
      label: get(suggestion, 'name'),
      value: get(suggestion, 'symbol'),
      tickerId: get(suggestion, 'tickerId', null)
    }))
}

/**
 * Add Index Component
 * @param props
 */
function Index (props) {
  const { dataId, theme, indices, onIndexAdd, onIndexDelete, loading } = props
  const [suggestions, setSuggestions] = useState([])

  const options = getOptions(suggestions, indices)
  const handleInputChange = (query) => {
    const search = (query && query.length)
      ? INDICES.filter((suggestion) => {
          const indexString = suggestion && `${suggestion.name} ${suggestion.fullname}`.toLowerCase()
          return indexString.includes(query.toLowerCase())
        })
      : []

    setSuggestions(search)
  }

  return (
    <div className='security-stock-overview_index'>
      <Select
        dataId={{
          inputId: `${dataId}IndicesInput`,
          menuId: `${dataId}IndicesMenu`
        }}
        loading={loading}
        theme={theme}
        className='security-stock-overview_index-select'
        size='thin'
        placeholder='Add Index (Max 3)'
        value={null}
        options={options}
        clearable={false}
        disabled={indices.length >= 3}
        showDropdownIndicator={false}
        onInputChange={handleInputChange}
        onChange={onIndexAdd}
      />
      <Indices
        dataId={`${dataId}IndicesItem`}
        indices={indices}
        onDelete={onIndexDelete}
      />
    </div>
  )
}

Index.propTypes = propTypes
Index.defaultProps = defaultProps

export default memo(Index)
