import React, {Component} from 'react';
import Board from 'react-trello';
import PropTypes from 'prop-types';
import { Banner, Message, SectionTab } from '../../components';
import LaneHeader from './laneHeader/laneHeader.component';
import Card from './card/card.component';
import { getEntity, getLocalStorageItem, isAcknowledgedTrial, ENTITY_TYPE, THEMES } from '../../utils';
import { modalType } from '../../actions/ui'
import PipelineMarketingModal from './marketing/marketing.component';

import './pipeline.component.css';

/**
 * Pipeline Component
 * Refer to https://github.com/rcdexta/react-trello for documentation
 */
class Pipeline extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            eventBus: null,
            isTargetsExpanded: false,
            currentMovedCard: null,
            disclosure: false,
            isMarketingModalOpen: !(getLocalStorageItem('seen_marketing_pipeline'))
        };
    };

    /**
     * Close marketing modal
     */
    closeMarketingModal = () => {
        this.setState({
            isMarketingModalOpen: false
        }, () => localStorage.setItem('seen_marketing_pipeline', new Date().toString()));
    };

    setEventBus = (handle) => {
        this.setState({eventBus: handle});
    };

    /**
     * Activate Objective Not Met drop area via DOM to speed up card dragging animation.
     */
    showObjectiveNotMetDropArea = () => {
        const pipeline = document.getElementsByClassName('pipeline_board').length && document.getElementsByClassName('pipeline_board')[0];
        const classList = pipeline && pipeline.classList;

        if (!classList) {
            return;
        }

        const isDropAreaActive = classList.contains('pipeline_board--drag-target');
        const isOpenTab = classList.contains('pipeline_board--open');

        if (!isDropAreaActive && isOpenTab) {
            classList.add('pipeline_board--drag-target');
        }
    };

    /**
     * Deactivate Objective Not Met drop area via DOM to speed up card dragging animation.
     */
    hideObjectiveNotMetDropArea = () => {
        const pipeline = document.getElementsByClassName('pipeline_board').length && document.getElementsByClassName('pipeline_board')[0];
        const classList = pipeline && pipeline.classList;

        if (classList && classList.contains('pipeline_board--drag-target')) {
            classList.remove('pipeline_board--drag-target');
        }
    };

    /**
     * Handle onMouseUp
     * Called when card is dropped outside of pipeline board
     */
    onMouseUp = () => {
        this.hideObjectiveNotMetDropArea();
    };

    /**
     * Handle Card Drag End
     * Called when card dragged to new lane, or position changed
     * @param cardId - deal id
     * @param sourceLaneId - stage id
     * @param targetLaneId - stage id
     */
    onCardDragComplete = (cardId, sourceLaneId, targetLaneId) => {
        this.hideObjectiveNotMetDropArea();

        if (sourceLaneId === targetLaneId) {
            return;
        }

        const {pipeline,  openModal, onDealCreate, onDealCancel} = this.props;
        const targetsLane = pipeline.lanes.find((lane) => lane.type === 'target');
        const lostStage = pipeline.lost[0];

        // Create a deal from Target card
        if (targetsLane && targetsLane.id === sourceLaneId) {
            const card = targetsLane.cards.find((card) => card.id === cardId);

            if (card) {
                const { type, q4_entity_id, item } = ((card && card.reference) || {})

                const entityType = (type || '').toLowerCase()
                const entity = {
                    id: entityType === ENTITY_TYPE.CONTACT ? item._id : q4_entity_id,
                    fullName: item.full_name,
                    fundName: item.fund_name,
                    institutionName: entityType === ENTITY_TYPE.INSTITUTION ? item.institution_name : null,
                    institutionId: entityType === ENTITY_TYPE.FUND ? item.q4_inst_id : null,
                    jobs: entityType === ENTITY_TYPE.CONTACT
                        ? (item.jobs || []).map((job) => job && {
                            q4_entity_id: job.q4_entity_id,
                            institutionName: job.institution_name
                        }) : null
                }

                openModal({
                    type: modalType.DEAL_EDIT_MODAL,
                    props: {
                        deal: {
                            stage: targetLaneId,
                            entity: getEntity(entityType, entity)
                        },
                        onSaveSuccess: onDealCreate
                    }
                })

                onDealCancel && onDealCancel()
            }

            // remove after moving, since cancel will retain the deal card in lane
            return this.removeCard(targetLaneId, cardId);
        }


        if (lostStage.id === targetLaneId) {
            return this.setState({
                objectiveNotMetWarning: true,
                currentMovedCard: {cardId, sourceLaneId, targetLaneId}
            });
        }

        this.props.updateDeal(cardId, {
            _stage: targetLaneId,
        });
    };

    removeCard(laneId, cardId) {
        this.state.eventBus.publish({
            type: 'REMOVE_CARD',
            laneId,
            cardId
        });
    }

    serializeCard = (card) => {
        return {
            ...card,
            address: (card.address || []).find((location) => location.hq),
            fromReact: true,
            reference: JSON.stringify({
              ...card.reference,
              item: {
                ...card.reference.item,
                entityId: card.reference.q4_entity_id
              }
            })
        };
    };

    /**
     * Handle adding a new deal
     */
    onDealAddClick = () => {
        const { openModal, onDealCreate } = this.props;

        openModal({
            type: modalType.DEAL_EDIT_MODAL,
            props: {
                onSaveSuccess: onDealCreate
            }
        })
    };

    /**
     * Show/Hide Targets Lane
     * @param event
     * @param show - true to expand targets lane
     */
    toggleTargets = (event, show) => {
        this.setState({
            isTargetsExpanded: (show !== null) ? show : !this.state.isTargetsExpanded
        });
    };

    /**
     * Handle Header Click Events
     * @param event
     */
    onHeaderClick = (event) => {
        const target = event.currentTarget;
        const targetId = target && target.id;

        switch (targetId) {
            case 'targetsCollapse':
                return this.toggleTargets(event, false);
            case 'targetsExpand':
                return this.toggleTargets(event, true);
            default:
                return;
        }
    };

    /**
     * Applies search filter to target lane
     * @param targetLane - target lane on pipeline
     * @param targetFilter - value from filter input filed
     * @param allTargets - array of all targets
     * @returns {Object} - filtered target lane
     */
    applyTargetFilter = (targetLane, targetFilter, allTargets) => {
        targetFilter = (targetFilter || '').toLowerCase();
        allTargets = (allTargets && allTargets.data) || [];
        targetLane.cards = this._filterTarget(allTargets, targetFilter);
        return targetLane;
    };

    _filterTarget = (targetsData, targetsFilter) => {
        return (targetsData || []).filter((target) => {

            const item = target.reference.item;

            if (item.fund_name && item.fund_name.length) {
                return item.fund_name.toLowerCase().indexOf(targetsFilter) > -1;
            }

            if (item.institution_name && item.institution_name.length) {
                return item.institution_name.toLowerCase().indexOf(targetsFilter) > -1;
            }

            if (item.full_name && item.full_name.length) {
                return item.full_name.toLowerCase().indexOf(targetsFilter) > -1;
            }

            return false;
        });
    };

    /**
     * Set active tab
     * @param event
     * @param value
     */
    onTabChange = (event, value) => {
        const {pipeline} = this.props;
        const lost = pipeline.lost && pipeline.lost.length && pipeline.lost[0];
        this.props.setActiveTab({value, stage: (lost && lost.id)});
        this.props.filterTarget('');
        this.toggleTargets();
    };

    /**
     * On move to lost
     * @param isConfirmed
     */
    onMoveToLost = (isConfirmed = false) => {
        if (!isConfirmed) {
            this.state.eventBus.publish({
                type: 'MOVE_CARD',
                fromLaneId: this.state.currentMovedCard.targetLaneId,
                toLaneId: this.state.currentMovedCard.sourceLaneId,
                cardId: this.state.currentMovedCard.cardId,
                index: 0
            });
        }
        else {
            this.props.updateDeal(this.state.currentMovedCard.cardId, {
                _stage: this.state.currentMovedCard.targetLaneId,
            });
        }

        this.setState({
            currentMovedCard: null,
            objectiveNotMetWarning: false
        });
    };

    /**
     * Deal drag start
     */
    handleDragStart = (cardId, laneId) => {
        if (laneId === 'targets') {
            return;
        }

        this.showObjectiveNotMetDropArea();
    };

    /**
     * On Download CSV
     */
    onDownloadCsvClick = () => {
        const {securityId, exportPipelineCsv} = this.props;
        exportPipelineCsv({securityId});
    };

    /**
     * On disclosure tap
     */
    onDisclosure = () => {
        this.setState({
            disclosure: true
        });
    };

    /**
     * On disclosure close
     */
    onDisclosureClose = () => {
        this.setState({
            disclosure: false
        });
    };

    /**
     * Render Disclosure
     * @returns {function(): *}
     */
    renderDisclosure = () => {
        return () => {
            const {disclosure} = this.state;
            const message = (
                '<p>Pipeline allows you to manage your investor relations efforts like a sales pipeline. Create a new Deal with any fund or firm and then update the stage through to investment.' +
                ' <a href="https://vimeo.com/272363628" target="_blank">Watch Video</a></p>' +
                '<p><strong>Potential POS</strong> is the number of shares a Potential Investor could buy' +
                ' in your stock. You have the option to "Use Purchasing Power" when editing or creating a Deal to' +
                ' populate this field with the firm or fund\'s calculated buying power in your stock.</p>' +
                '<p><strong>Probability %</strong> is the chance a Deal has of making it through the Pipeline based on its current stage.</p>' +
                '<p><strong>Weighted Market Value</strong> is calculated by multiplying a Deal\'s Potential POS by the stage' +
                ' Probability % and your current stock price. Each stage shows a combined Weighted Market Value of' +
                ' all Deals within that stage.</p>'
            );

            return (
                <Message
                    visible={disclosure}
                    type='info'
                    title='Pipeline'
                    message={message}
                    useHtml={true}
                    onClose={this.onDisclosureClose}
                    renderExitButton={true}
                    buttons={[{
                        ui: 'citrus',
                        label: 'ok',
                        onClick: this.onDisclosureClose
                    }]}/>
            )
        };
    };

    /**
     * Render 'objective not met' warning message
     * @returns {null}
     */
    renderMessage = () => {
        const {objectiveNotMetWarning, currentMovedCard} = this.state;

        return (
            (objectiveNotMetWarning && currentMovedCard) ?
                <Message
                    visible={true}
                    type='warning'
                    title='Objective Not Met?'
                    message='Moving a Deal to Objective Not Met will hide the Deal from the Investor Pipeline and all Potential Investor profiles.'
                    onClose={() => {
                        this.onMoveToLost(false)
                    }}
                    buttons={[
                        {
                            ui: 'shaded',
                            label: 'cancel',
                            onClick: () => {
                                this.onMoveToLost(false)
                            }
                        },
                        {
                            ui: 'white',
                            label: 'confirm',
                            onClick: () => {
                                this.onMoveToLost(true)
                            }
                        }
                    ]}/> : null
        );
    };

    /**
     * Render
     * @return {XML}
     */
    render() {
        const {pipeline, stock, targets, history, profile, openModal, onDealCreate} = this.props;
        const { isTargetsExpanded, isMarketingModalOpen } = this.state;

        const activeTab = pipeline.activeTab || 'open';
        const targetLane = (pipeline.lanes || []).find((lane) => lane && lane.id === 'targets');
        const pipelineStateCls = `${activeTab === 'open' ? 'pipeline_board--open' : ''}`;
        const stockPrice = (stock && stock.data && stock.data.last) || 0;
        const Disclosure = this.renderDisclosure();

        // apply targets filter
        if (targetLane) {
            pipeline.lanes[0] = this.applyTargetFilter(targetLane, pipeline.targetFilter, targets);
        }

        return (
            <div className='pipeline' onMouseUp={this.onMouseUp}>
                <Disclosure/>
                {isAcknowledgedTrial(profile.data) ?
                    <PipelineMarketingModal visible={isMarketingModalOpen} onClose={this.closeMarketingModal}/> :
                    null
                }
                <Banner
                    title='Pipeline'
                    icon='q4i-deal-2pt'
                    disclosure={{
                      type: 'info',
                      onClick: this.onDisclosure
                    }}
                    controls={[{
                        type: 'button',
                        icon: 'q4i-download-4pt',
                        label: 'Export',
                        disabled: pipeline.status && pipeline.status.csvExport === 'IN_PROGRESS',
                        onClick: this.onDownloadCsvClick
                    },
                    {
                        type: 'button',
                        icon: 'q4i-plus-4pt',
                        tooltip: 'Add',
                        onClick: this.onDealAddClick
                    }]}
                    alignChildrenWithBadge={true}
                >
                    <SectionTab
                        aligned={true}
                        className='pipeline_section-tab'
                        theme={THEMES.SLATE}
                        compact={true}
                        onChange={this.onTabChange}
                        activeTab={activeTab}
                        tabs={[
                            {
                                label: 'Open',
                                value: 'open'
                            },
                            {
                                label: 'Lost',
                                value: 'lost'
                            }
                        ]}/>
                </Banner>
                <Board
                    className={`pipeline_board ${isTargetsExpanded ? '' : 'pipeline_board--target-collapsed'} ${pipelineStateCls}`}
                    customCardLayout
                    customLaneHeader={
                        <LaneHeader
                            onClick={this.onHeaderClick}
                            filterTarget={this.props.filterTarget}
                            profile={this.props.profile}
                            stockPrice={stockPrice}
                            openModal={openModal}
                            onDealCreate={onDealCreate}
                        />
                    }
                    data={{
                        lanes: pipeline.lanes
                    }}
                    laneDraggable={false}
                    draggable={activeTab === 'open'}
                    eventBusHandle={this.setEventBus}
                    handleDragStart={this.handleDragStart}
                    handleDragEnd={this.onCardDragComplete}>
                    <Card
                        stockPrice={stockPrice}
                        history={history}
                    />
                </Board>
                {this.renderMessage()}

            </div>
        )
    }
}

Pipeline.propTypes = {
    pipeline: PropTypes.object.isRequired
};

export default Pipeline;
