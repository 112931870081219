export const OWNERSHIP_TYPE = {
  '13F': '13f',
  SHAREHOLDER: 'shareholderId',
  SURVEILLANCE: 'surveillance'
}

export const HOLDING_TYPE = {
  STAKES: 'stakes',
  INSTITUTIONS: 'institutions'
}

export const MOVER_TYPE = {
  BUY: 'buy',
  SELL: 'sell'
}

export const MOVER_RANGE = [
  { label: 'Most Recent', value: 'mostRecent' },
  { label: '3 Months', value: 3 },
  { label: '6 Months', value: 6 },
  { label: '1 Year', value: 12 }
]

/**
 * Historical Holdings Quarters
 */
export const HISTORICAL_QUARTER = [
  { label: '1 Year', value: 4 },
  { label: '2 Years', value: 8 },
  { label: '3 Years', value: 12 },
  { label: '4 Years', value: 16 },
  { label: '5 Years', value: 20 }
]

/**
 * Security Holder Type
 */
export const HOLDER = [
  { label: 'All', value: 'all', dataId: 'all' },
  { label: 'Institutions', value: 'institution', dataId: 'institution' },
  { label: 'Insiders', value: 'insider', dataId: 'insider' },
  { label: 'Funds & ETFs', value: 'fund', dataId: 'fund' }
]

/**
 * Security Holder Position
 */
export const HOLDER_POSITION = [
  { label: 'New', value: 'new' },
  { label: 'Sold Out', value: 'sold' },
  { label: 'Increased', value: 'increased' },
  { label: 'Decreased', value: 'decreased' },
  { label: 'Maintained', value: 'maintained' }
]

export const HOLDER_INVESTMENT_STYLE = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Passive', value: 'passive' }
]

/**
 * Security Holder Style
 */
export const HOLDER_STYLE = [
  { label: 'Aggressive Growth', value: 'Aggressive Growth', isActive: true },
  { label: 'Alternative', value: 'Alternative', isActive: true },
  { label: 'Broker/Dealer', value: 'Broker/Dealer', isActive: true },
  { label: 'Deep Value', value: 'Deep Value', isActive: true },
  { label: 'GARP', value: 'GARP', isActive: true },
  { label: 'Growth', value: 'Growth', isActive: true },
  { label: 'Hedge Fund', value: 'Hedge Fund', isActive: true },
  { label: 'Income', value: 'Income', isActive: true },
  { label: 'Index', value: 'Index', isActive: false },
  { label: 'Private Equity', value: 'Private Equity', isActive: true },
  { label: 'Specialty', value: 'Specialty', isActive: true },
  { label: 'Value', value: 'Value', isActive: true },
  { label: 'Yield', value: 'Yield', isActive: true },
  { label: 'Quant', value: 'Quant', isActive: false }
]

/**
 * Security Holder Turnover
 */
export const HOLDER_TURNOVER = [
  { label: 'Very Low', value: 'Very Low' },
  { label: 'Low', value: 'Low' },
  { label: 'Medium', value: 'Medium' },
  { label: 'High', value: 'High' },
  { label: 'Very High', value: 'Very High' }
]

/**
 * Security Holder Type
 */
export const HOLDER_TYPE = [
  { label: 'Arbitrage', value: 'Arbitrage', filter: 'institution' },
  { label: 'Bank Investment Division', value: 'Bank Investment Division', filter: 'institution' },
  { label: 'Broker', value: 'Broker', filter: 'institution' },
  { label: 'Corporate', value: 'Corporate', filter: 'institution' },
  { label: 'Custodial', value: 'Custodial', filter: 'institution' },
  { label: 'Fund of Funds Manager', value: 'Fund of Funds Manager', filter: 'institution' },
  { label: 'Fund of Hedge Funds Manager', value: 'Fund of Hedge Funds Manager', filter: 'institution' },
  { label: 'Foundation/Endowment Manager', value: 'Foundation/Endowment Manager', filter: 'institution' },
  { label: 'Fund Distributor', value: 'Fund Distributor', filter: 'institution' },
  { label: 'Fund', value: 'Fund', filter: 'institution' },
  { label: 'Family Office', value: 'Family Office', filter: 'institution' },
  { label: 'Govt (Fed/Local/Agency)', value: 'Govt (Fed/Local/Agency)', filter: 'institution' },
  { label: 'Hedge Fund Manager', value: 'Hedge Fund Manager', filter: 'institution' },
  { label: 'Investment Adviser', value: 'Investment Adviser', filter: 'institution' },
  { label: 'Investment Banking', value: 'Investment Banking', filter: 'institution' },
  { label: 'Investment Company', value: 'Investment Company', filter: 'institution' },
  { label: 'Insurance Company', value: 'Insurance Company', filter: 'institution' },
  { label: 'Mutual Fund Manager', value: 'Mutual Fund Manager', filter: 'institution' },
  { label: 'Master Ltd Part', value: 'Master Ltd Part', filter: 'institution' },
  { label: 'Market Maker', value: 'Market Maker', filter: 'institution' },
  { label: 'Private Banking/Wealth Mgmt', value: 'Private Banking/Wealth Mgmt', filter: 'institution' },
  { label: 'Pension Fund Manager', value: 'Pension Fund Manager', filter: 'institution' },
  { label: 'Real Estate Manager', value: 'Real Estate Manager', filter: 'institution' },
  { label: 'Research Firm', value: 'Research Firm', filter: 'institution' },
  { label: 'Subsidiary Branch', value: 'Subsidiary Branch', filter: 'institution' },
  { label: 'Stock Borrowing/Lending', value: 'Stock Borrowing/Lending', filter: 'institution' },
  { label: 'Sovereign Wealth Manager', value: 'Sovereign Wealth Manager', filter: 'institution' },
  { label: 'Venture Capital/Pvt Equity', value: 'Venture Capital/Pvt Equity', filter: 'institution' },

  // fund
  { label: 'Bank Portfolio', value: 'BKP', filter: 'fund' },
  { label: 'Closed-End Fund', value: 'CEF', filter: 'fund' },
  { label: 'Exchange Traded Fund', value: 'ETF', filter: 'fund' },
  { label: 'Exchange Traded Notes', value: 'ETN', filter: 'fund' },
  { label: '13F Parent Portfolio', value: 'FPA', filter: 'fund' },
  { label: 'Hedge Fund', value: 'HED', filter: 'fund' },
  { label: 'Insurance - Diversified', value: 'IND', filter: 'fund' },
  { label: 'Insurance - Life/Health', value: 'INL', filter: 'fund' },
  { label: 'Investment Management Co', value: 'INM', filter: 'fund' },
  { label: 'Insurance - P & C', value: 'INP', filter: 'fund' },
  { label: 'Non-Public Fund', value: 'MFC', filter: 'fund' },
  { label: 'Open-End Fund', value: 'OEF', filter: 'fund' },
  { label: 'Pension Fund', value: 'PEF', filter: 'fund' }, 
  { label: 'Pension & Life Product', value: 'PLP', filter: 'fund' },
  { label: 'Private Equity Fund', value: 'PVF', filter: 'fund' }, 
  { label: 'Reinsurance', value: 'REI', filter: 'fund' },
  { label: 'Unit Investment Trust', value: 'UIT', filter: 'fund' },
  { label: 'Variable Annuity Fund', value: 'VAR', filter: 'fund' }
]

/**
 * Current Holding Region Options
 */
export const HOLDING_REGION = [
  { label: 'Asia', value: 'Asia' },
  { label: 'Africa', value: 'Africa' },
  { label: 'Europe', value: 'Europe' },
  { label: 'Latin America', value: 'Latin America' },
  { label: 'Middle East', value: 'Middle East' },
  { label: 'North America', value: 'North America' },
  { label: 'Pacific', value: 'Pacific' },
  { label: 'Other', value: [null, ''] }
]

/**
 * Current Holding Sector Options
 */
export const HOLDING_SECTOR = [
  { label: 'Commercial Services', value: 'Commercial Services' },
  { label: 'Communications', value: 'Communications' },
  { label: 'Consumer Durables', value: 'Consumer Durables' },
  { label: 'Consumer Non-Durables', value: 'Consumer Non-Durables' },
  { label: 'Consumer Services', value: 'Consumer Services' },
  { label: 'Distribution Services', value: 'Distribution Services' },
  { label: 'Electronic Technology', value: 'Electronic Technology' },
  { label: 'Energy Minerals', value: 'Energy Minerals' },
  { label: 'Finance', value: 'Finance' },
  { label: 'Government', value: 'Government' },
  { label: 'Health Services', value: 'Health Services' },
  { label: 'Health Technology', value: 'Health Technology' },
  { label: 'Industrial Services', value: 'Industrial Services' },
  { label: 'Miscellaneous', value: 'Miscellaneous' },
  { label: 'Non-Energy Minerals', value: 'Non-Energy Minerals' },
  { label: 'Process Industries', value: 'Process Industries' },
  { label: 'Producer Manufacturing', value: 'Producer Manufacturing' },
  { label: 'Retail Trade', value: 'Retail Trade' },
  { label: 'Technology Services', value: 'Technology Services' },
  { label: 'Transportation', value: 'Transportation' },
  { label: 'Utilities', value: 'Utilities' },
  { label: 'Other', value: [null, ''] }
]

/**
 * Current Holding Cap Group Options
 */
export const HOLDING_CAP_GROUP = [
  { label: 'Mega', value: 'MEGA' },
  { label: 'Large', value: 'LARGE' },
  { label: 'Mid', value: 'MID' },
  { label: 'Small', value: 'SMALL' },
  { label: 'Micro', value: 'MICRO' },
  { label: 'Other', value: [null, ''] }
]

/**
 * Peer Analysis Metric values
 */
 export const PEER_METRIC_VALUES = {
  current: 'current',
  qtrChange: 'qtrChange',
  marketValue: 'marketValue',
  marketValueChange: 'marketValueChange',
  percentTSO: 'percentTSO',
  percentTSOChange: 'percentTSOChange',
  percentPortfolio: 'percentPortfolio',
  percentPortfolioChange: 'percentPortfolioChange'
 }

/**
 * Peer Analysis Metric Options
 */
export const PEER_METRIC = [
  { label: 'Position', value: PEER_METRIC_VALUES.current },
  { label: 'Position Change', value: PEER_METRIC_VALUES.qtrChange },
  { label: 'Market Value', value: PEER_METRIC_VALUES.marketValue },
  { label: 'Market Value Change', value: PEER_METRIC_VALUES.marketValueChange },
  { label: '%OS (Owned Shares)', value: PEER_METRIC_VALUES.percentTSO },
  // { label: '%OS Change', value: PEER_METRIC_VALUES.percentTSOChange },
  { label: '%PORT (Portfolio)', value: PEER_METRIC_VALUES.percentPortfolio }
  // { label: '%PORT Change', value: PEER_METRIC_VALUES.percentPortfolioChange }
]

export const LOGGED_ACTIVITY = [
  { label: 'All', value: 'all' },
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
]

export const YET_TO_FILE = 'Yet to File'
