import React from 'react'
import ReactHighstock from 'react-highcharts/ReactHighstock'
import { lightSlate, white } from '../../../../resources/materialui-overrides/colors'

const DownloadsWebAnalytics = (props) => {
  const getLabelStyle = (isNarrowLayout) => {
    return {
      'font-family': '"Open Sans", Arial !important',
      color: props.theme === 'dark' ? white : lightSlate,
      fontWeight: 'normal',
      fontSize: '13px',
      width: isNarrowLayout ? '235px' : '265px'
    }
  }

  const createPlotOptions = (isNarrowLayout) => {
    return {
      series: {
        borderWidth: 0,
        minPointLength: 1,
        colorByPoint: true
      },
      pie: {
        dataLabels: {
          enabled: false
        },
        states: {
          hover: {
            enabled: false
          }
        },
        center: [isNarrowLayout ? 120 : 110, 85],
        showInLegend: true
      }
    }
  }

  const createSeries = (data, isNarrowLayout) => {
    return [{
      data: (data || []).map((item) => {
        if (!item || !item.fileName) {
          return null
        }

        return {
          name: item.fileName,
          y: item.count
        }
      }),
      size: isNarrowLayout ? 185 : 205,
      innerSize: '35%'
    }]
  }

  const getNarrowLabelFormatter = () => {
    return function () {
      return "<div style='width: 245px'>" +
                "<div style='width: 190px; max-width: 190px; display: inline-block'>" +
                "<span style='font-family: Open Sans, Arial !important; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%; display: inline-block'>" + this.name + '</span>' +
                '</div>' +
                "<span style='font-family: Open Sans, Arial !important; display: inline-block; margin-left: 0px; vertical-align: top; text-align: right; width: 45px'>" + this.y + '</span>' +
                '</div>'
    }
  }

  const getLabelFormatter = () => {
    return function () {
      return '<div>' +
                "<div style='width: 210px; max-width: 210px; display: inline-block'>" +
                "<span style='font-family: Open Sans, Arial !important; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%; display: inline-block'>" + this.name + '</span>' +
                '</div>' +
                "<span style='font-family: Open Sans, Arial !important; display: inline-block; margin-left: 5px; vertical-align: top; text-align: right; width: 45px'>" + this.y + '</span>' +
                '</div>'
    }
  }

  const getLegendConfig = (isNarrowLayout) => {
    if (isNarrowLayout) {
      return {
        enabled: true,
        useHTML: true,
        navigation: {
          enabled: false
        },
        y: 180,
        verticalAlign: 'top',
        itemHoverStyle: getLabelStyle(isNarrowLayout),
        itemStyle: getLabelStyle(isNarrowLayout),
        labelFormatter: getNarrowLabelFormatter()
      }
    } else {
      return {
        enabled: true,
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        navigation: {
          enabled: false
        },
        x: -10,
        y: 0,
        itemHoverStyle: getLabelStyle(isNarrowLayout),
        itemStyle: getLabelStyle(isNarrowLayout),
        useHTML: true,
        labelFormatter: getLabelFormatter()
      }
    }
  }

  const getChartConfig = () => {
    const isNarrowLayout = props.layout && props.layout.w === 1
    const pieOffset = isNarrowLayout ? 220 : 40 // offset for chart & spacing between legend

    return {
      chart: {
        type: 'pie',
        backgroundColor: 'transparent',
        height: isNarrowLayout ? (pieOffset + (props.items.length * 21)) : (pieOffset + (props.items.length * 21)),
        marginTop: isNarrowLayout ? 0 : 20,
        marginLeft: isNarrowLayout ? -5 : 0,
        animation: false
      },
      credits: { enabled: false },
      colors: props.colors.map((item) => item.value),
      title: {
        text: ''
      },
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      rangeSelector: {
        enabled: false
      },
      legend: getLegendConfig(isNarrowLayout),
      plotOptions: createPlotOptions(isNarrowLayout),
      tooltip: {
        enabled: false,
        crosshairs: false
      },
      series: createSeries(props.items, isNarrowLayout)
    }
  }
  return <ReactHighstock config={getChartConfig()} />
}

export default DownloadsWebAnalytics
