import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { get, isEqual, isEmpty, omitBy, isNull } from 'lodash'
import qs from 'query-string'

// actions
import {
  clearSearchResults,
  getAdvancedSearchV3,
  getAdvancedSearchFacets,
  getElasticSearch,
  getTagSearch,
  getPeers,
  statusType,
  getAdvancedSearchGql
} from '../../actions'

// components
import { Pagination } from '../../components'
import SearchFilter from './filter/filter.component'
import SearchResultList from './list/list.component'
import SearchResultToolbar from './toolbar/toolbar.component'
import { SearchQueryComponent } from './searchQueryComponent'

import {
  DEFAULT_TICKER,
  ENTITY_SEARCH_TYPES,
  SEARCH_FACETS,
  SEARCH_SOURCE,
  SEARCH_TYPE,
  SWIFTTYPE_FILTER_FIELDS,
  convertStringToBoolean,
  getActiveTicker,
  getOwnershipType,
  getPageSizeFromStorage,
  getSearchSource,
  getTranscriptLink,
  isEntitySearch,
  isTagSearch,
  getLocalizedCurrency
} from '../../utils'

import './search.page.scss'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from '../../services/launchDarkly.service'

const PEER_RESULTS_LIMIT = 200
const initialState = {
  query: '',
  type: ENTITY_SEARCH_TYPES,
  limit: getCurrentPagelimit(),
  page: 1,
  filter: {}, // swiftype specific filters
  activity: null,
  watchlist: null
}

const propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  // Redux Actions
  clearResults: PropTypes.func.isRequired,
  getAdvancedSearch: PropTypes.func,
  getAdvancedSearchGql: PropTypes.func.isRequired,
  getAdvancedSearchV3: PropTypes.func.isRequired,
  getElasticSearch: PropTypes.func.isRequired,
  getFacets: PropTypes.func.isRequired,
  getPeers: PropTypes.func.isRequired,
  getTagSearch: PropTypes.func.isRequired,
  // Redux State
  entityId: PropTypes.string,
  facet: PropTypes.object.isRequired,
  isFacetLoading: PropTypes.bool,
  peers: PropTypes.array,
  profile: PropTypes.object.isRequired,
  results: PropTypes.object,
  securityId: PropTypes.string,
  tickerId: PropTypes.string,
  ownershipType: PropTypes.shape({
    label: PropTypes.string
  }),
  // Data, Actions from HOC
  getGQLSearchResults: PropTypes.func.isRequired,
  gqlLoading: PropTypes.bool,
  gqlData: PropTypes.shape({
    advancedSearch: PropTypes.shape({
      items: PropTypes.array
    })
  })
}

const defaultProps = {}

/**
 * Custom hook to reference to a previous state
 * @param {*} value
 */
function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

/**
 * Get the current page limit from cache or default 10
 * Used for initial state and called once again to calculate the reset page limit size
 */
function getCurrentPagelimit () {
  return getPageSizeFromStorage('search-result-list') || 10
}

/**
 * Search Page
 * @param props
 */
function SearchPage (props) {
  const [searchState, setsearchState] = useState(initialState)
  const prevSearchState = usePrevious(searchState)
  const abortControllerRef = useRef()

  const {
    ownershipType,
    peers, facet,
    isFacetLoading,
    history,
    profile,
    location,
    entityId,
    securityId,
    tickerId
  } = props

  // componentDidMount
  useEffect(() => {
    const queryString = location && location.search

    if (queryString) {
      setStateFromQueryString(queryString)
    }

    props.getPeers && props.getPeers({ limit: PEER_RESULTS_LIMIT })
    getFacets()
  }, [])

  // componentDidUpdate
  useEffect(() => {
    const { type, query } = searchState
    const queryString = location && location.search
    const queryParams = qs.parse(queryString)
    const isTypeChanged = type !== prevSearchState?.type
    const isStateChanged = ['query', 'type', 'page', 'limit', 'filter', 'watchlist', 'activity']
      .some((prop) => !isEqual(searchState[prop], get(prevSearchState, prop)))

    if (queryParams.query && queryParams.query !== query) {
      setStateFromQueryString(queryString)
    }

    const isValid = isValidQuery(searchState)
    const isPrevValid = prevSearchState?.type && isValidQuery(prevSearchState)

    isTypeChanged && abortLastRequest()
    isTypeChanged && getFacets()
    isStateChanged && setQueryString()

    if (!isValid && isPrevValid) {
      return props.clearResults()
    }

    isStateChanged && isValid && getResults()
  })

  const abortLastRequest = () => abortControllerRef.current && abortControllerRef.current.abort()

  // componentWillUnmount
  useEffect(() => {
    return () => {
      props.clearResults()
    }
  }, [props.clearResults])

  /**
   * Get Search Facets
   * @param queryParams
   */
  const getFacets = (queryParams = getQueryParams()) => {
    const isEntity = isEntitySearch(queryParams.type)
    isEntity && props.getFacets({
      entityType: queryParams.type,
      facet: Object.values(SEARCH_FACETS)
    })
  }

  /**
   * Set state from provided querystring params
   * @param {*} queryString
   */
  const setStateFromQueryString = (queryString) => {
    const queryParams = qs.parse(queryString)
    let state = { ...searchState }

    Object.keys(queryParams).forEach((key) => {
      const isSwiftTypeFilter = SWIFTTYPE_FILTER_FIELDS.includes(key)

      if (isSwiftTypeFilter) {
        state = {
          ...state,
          filter: {
            ...state.filter,
            [key]: (key === 'flags') ? [].concat(Number(queryParams[key])) : queryParams[key]
          }
        }
      } else if (key === 'type') {
        state[key] = [].concat(queryParams[key])
      } else {
        state[key] = queryParams[key]
      }
    })

    setsearchState({
      ...state,
      page: 1
    })
  }

  /**
   * Set query string on filters update
   * @param queryParams
   */
  const setQueryString = (queryParams = getQueryParams()) => {
    const { query, type, activity, watchlist, filter = {} } = queryParams
    const filters = (filter.all || []).map((item) => qs.stringify({ ...item })).join('&')

    history.replace({
      pathname: '/search',
      search: `?${qs.stringify({ query, type, activity, watchlist })}${filters.length ? `&${filters}` : ''}`
    })
  }

  /**
   * Get query params
   * @param params
   * @returns {*}
   */
  const getQueryParams = () => {
    const { type, query, limit, page, filter, activity, watchlist } = searchState
    const filterQuery = getFilterQuery(type, filter)
    const entitySearch = isEntitySearch(type)
    const isEntityType = [].concat(type).some((entity) => [SEARCH_TYPE.CONTACT, SEARCH_TYPE.INSTITUTION, SEARCH_TYPE.FUND].includes(entity))
    const isTranscript = isEqual(type, [SEARCH_TYPE.TRANSCRIPT])
    const currencyCode = getLocalizedCurrency()

    return omitBy({
      query: (query || null),
      type,
      limit,
      page,
      securityId,
      tickerId,
      entityId,
      currencyCode,
      filter: entitySearch ? filterQuery : null,
      activity: isEntityType ? (activity || null) : null,
      watchlist: isTranscript ? (watchlist || null) : null
    }, isNull)
  }

  /**
   * Check if user has fulfilled minimum query criteria
   * @param props
   */
  const isValidQuery = (searchOptions) => {
    const { type, filter, query, activity, watchlist } = searchOptions
    const filters = omitFiltersByType(type, filter)
    const externalFilters = omitFiltersByType(type, omitBy({
      activity,
      watchlist
    }, isNull))

    return !(isEmpty(filters) && isEmpty(externalFilters) && isEmpty(query))
  }

  /**
   * Get Filter Query
   * @param type
   * @param filter
   */
  const getFilterQuery = (type, filter = {}) => {
    const filterQuery = { all: [] }
    const filtersByType = omitFiltersByType(type, filter)

    Object.keys(filtersByType).forEach((key) => {
      const values = filter[key]
      if (values && values.length) {
        filterQuery.all.push({ [key]: values })
      }
    })

    return isEmpty(filterQuery.all) ? {} : filterQuery
  }

  /**
   * Exclude passing filters unrelated to selected entity type
   * @param {*} type
   * @param {*} filter
   */
  const omitFiltersByType = (type, filter) => {
    const isContact = [].concat(type).includes(SEARCH_TYPE.CONTACT)
    const isEntityType = [].concat(type).some((entityType) => [SEARCH_TYPE.CONTACT, SEARCH_TYPE.INSTITUTION, SEARCH_TYPE.FUND].includes(entityType))
    const filterByType = { ...filter }

    if (!isContact) {
      delete filterByType.functions
      delete filterByType.fund
      delete filterByType.flags
    }

    if (!isEntityType) {
      delete filterByType.activity
      delete filterByType.metro
    }

    return filterByType
  }

  /**
   * Update filters used by swifttype
   * @param field
   * @param selectedValues
   */
  const updateSwiftTypeFilters = (field, selectedValues) => {
    const { filter } = searchState
    const newFilters = {
      ...filter,
      [field]: selectedValues
    }

    if (isEmpty(selectedValues) || selectedValues === false) {
      delete newFilters[field]
    }

    setsearchState({
      ...searchState,
      filter: newFilters,
      page: 1
    })
  }

  /**
   * Get Search Results according to search source
   * @param queryParams
   */
  const getResults = (queryParams = getQueryParams()) => {
    const controller = new AbortController()
    abortControllerRef.current = controller

    const { query, type } = searchState

    const searchSource = getSearchSource(query, type, profile)
    const { type: queryParamType } = queryParams

    const isTagEntity = []
      .concat(ENTITY_SEARCH_TYPES, SEARCH_TYPE.ACTIVITY)
      .some((entityType) => [].concat(queryParamType).includes(entityType))

    const tagSearchOptions = isTagEntity ? queryParams : {}

    switch (searchSource) {
      case SEARCH_SOURCE.TAG_SEARCH:
        isEntitySearch(type)
          ? props.getAdvancedSearchGql({ ...queryParams, byTag: true }, controller)
          : props.getTagSearch(tagSearchOptions)
        break
      case SEARCH_SOURCE.GQL_ADVANCED_SEARCH:
        props.getAdvancedSearchGql(queryParams, controller)
        break
      case SEARCH_SOURCE.ADVANCED_SEARCH_V3:
        props.getAdvancedSearchV3(queryParams)
        break
      case SEARCH_SOURCE.GRAPHQL_SEARCH:
        getGQLSearch(queryParams)
        break
      default:
        props.getElasticSearch(queryParams)
        break
    }
  }

  /**
   * Get formatted data according to search source
   */
  const getFormattedData = () => {
    const { query, type } = searchState
    const searchSource = getSearchSource(query, type, profile)
    switch (searchSource) {
      case SEARCH_SOURCE.TAG_SEARCH:
      case SEARCH_SOURCE.ADVANCED_SEARCH:
      case SEARCH_SOURCE.ADVANCED_SEARCH_V3:
      case SEARCH_SOURCE.ELASTIC_SEARCH:
      case SEARCH_SOURCE.GQL_ADVANCED_SEARCH:
        return props.results
      case SEARCH_SOURCE.GRAPHQL_SEARCH:
        return formatGqlResults(props.gqlData, props.gqlLoading, query)
      default:
        return props.results
    }
  }

  /**
   * Events V2
   * Perform search using GraphQL node
   * @param {Object} queryParam see getQueryParams for format
   */
  const getGQLSearch = (queryParams) => {
    const { query, type, limit, page, watchlist, byTag } = queryParams
    props.getGQLSearchResults({
      variables: { 
        query, 
        entity: type, 
        limit, 
        page, 
        watchlist: !!watchlist, 
        byTag: !!byTag,
        useElasticSearch: !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_ELASTIC_CLOUD_SEARCH_ENGINE)
      }
    })
  }

  /**
   * Events V2
   * Format search results to match legacy search results
   * @param {Object} queryData gql advancedSearch result
   * @param {boolean} loading gql loading state
   * @param {string} query text to search on PDF file
   * @returns {Object} search results formatted
   */
  const formatGqlResults = (queryData, isLoading, query) => {
    const rawData = queryData?.advancedSearch?.items || []
    const { type } = getQueryParams()

    const resultData = rawData.map((item) => {
      // Assumes that only one event should be returned per search result.
      const event = item?.entityConnection
      const entity = event?.entity
      return item && event && entity
        ? {
            date: entity.eventDate,
            highlight: event.transcriptHighlight,
            security: entity.companyName,
            title: entity.eventTitle,
            type: type[0],
            url: getTranscriptLink(entity.transcriptUrl, true, query),
            _id: item.id + '-' + event.eventId
          }
        : {}
    })
    const resultStatus = isLoading ? statusType.IN_PROGRESS : statusType.IDLE
    const resultTotal = queryData?.advancedSearch.count

    return { data: resultData, status: resultStatus, total: resultTotal }
  }

  /**
   * Filter results based on applied filters
   * @param field - filed to apply filters to
   * @param selectedValues - array of selected values
   */
  const handleFilterChange = (field, selectedValues) => {
    // update swiftype filter
    if (SWIFTTYPE_FILTER_FIELDS.includes(field)) {
      return updateSwiftTypeFilters(field, selectedValues)
    }

    const newState = {
      ...searchState,
      [field]: selectedValues || null,
      page: 1
    }

    // update external filters
    setsearchState(newState)
  }

  /**
   * Handle Type Change
   * @param type
   */
  const handleTypeChange = (typeChanged) => {
    setsearchState({ ...searchState, type: typeChanged, page: 1 })
  }

  /**
   * Handle Filter and Type Options Reset
   */
  const handleReset = () => {
    const { query } = searchState
    setsearchState({ ...initialState, query, limit: getCurrentPagelimit() })
  }

  /**
   * Handle Pagination Change
   * @param param.page
   * @param param.limit
   */
  const handlePaginationChange = ({ page, limit }) => {
    const { page: prevPage, limit: prevLimit } = searchState

    setsearchState({
      ...searchState,
      page: page || prevPage,
      limit: limit || prevLimit
    })
  }

  const { data, status, total } = getFormattedData()
  const loading = status === statusType.IN_PROGRESS
  const subscriptions = (profile.services || []).reduce((acc, item) => {
    acc[item.type] = item.enabled
    return acc
  }, {})

  const maxPages = 100
  const maxTotal = maxPages * Number(searchState.limit)
  const hasMore = total > maxTotal
  const showSearchResultToolbar = !(searchState.type.includes('research') && get(subscriptions, 'estimates_research'))
  const showPagination = (!!data.length && !(searchState.type.includes('research') && get(subscriptions, 'estimates_research')))

  return (
    <div className='search-page'>
      <SearchFilter
        type={searchState.type}
        filter={searchState.filter}
        subscriptions={subscriptions}
        peer={convertStringToBoolean(searchState.watchlist)}
        activity={convertStringToBoolean(searchState.activity)}
        facet={facet}
        isFacetLoading={isFacetLoading}
        isTagSearch={isTagSearch(searchState.query)}
        onTypeChange={handleTypeChange}
        onFilterChange={handleFilterChange}
        onReset={handleReset}
      />
      <div className='search-page_result'>
        {showSearchResultToolbar &&
          <SearchResultToolbar
            loading={loading}
            search={searchState.query}
            total={hasMore ? maxTotal : total}
            hasMore={hasMore}
          />}
        <SearchResultList
          loading={loading}
          data={data}
          type={searchState.type}
          peers={peers}
          profile={profile}
          ownership={ownershipType && ownershipType.label}
          history={history}
          subscriptions={subscriptions}
        />
        {showPagination &&
          <div className='search-page_result-pagination'>
            <Pagination
              pageSizeId='search-result-list'
              forcePage={Number(searchState.page)}
              initialPageSize={searchState.limit}
              showPageSizeSelection
              total={hasMore ? maxTotal : total}
              onPageChange={({ selected }) => handlePaginationChange({ page: selected })}
              onPageSizeChange={({ selected }) => handlePaginationChange({ page: 1, limit: selected })}
            />
          </div>}
      </div>
    </div>
  )
}

SearchPage.propTypes = propTypes
SearchPage.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)

  return {
    securityId: ticker && ticker._security,
    entityId: (ticker && ticker.q4_entity_id) || DEFAULT_TICKER,
    tickerId: (ticker && ticker.q4_ticker_id) || DEFAULT_TICKER,
    ownershipType: getOwnershipType(profile),
    results: get(state, 'search.advanced'),
    filter: get(state, 'search.advanced.filter'),
    facet: get(state, 'search.advanced.facet.data'),
    isFacetLoading: get(state, 'search.advanced.facet.status') === statusType.IN_PROGRESS,
    peers: get(state, 'peer.data'),
    profile
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAdvancedSearchGql: bindActionCreators(getAdvancedSearchGql, dispatch),
  getAdvancedSearchV3: bindActionCreators(getAdvancedSearchV3, dispatch),
  getElasticSearch: bindActionCreators(getElasticSearch, dispatch),
  getTagSearch: bindActionCreators(getTagSearch, dispatch),
  getFacets: bindActionCreators(getAdvancedSearchFacets, dispatch),
  getPeers: bindActionCreators(getPeers, dispatch),
  clearResults: bindActionCreators(clearSearchResults, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchQueryComponent(SearchPage)))
