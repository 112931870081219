import React, { memo } from 'react'
import PropTypes from 'prop-types'

// utils
import { defaultIfEmpty, format, getChangeClassName, getLocalizedCurrency, inMillions } from '../../../../../utils'
import { capitalize, get } from 'lodash'

const EMPTY_PLACEHOLDER = '-'

const propTypes = {
  funds: PropTypes.array.isRequired
}

const defaultProps = {
  funds: []
}

/**
 * Managed Fund Grid
 * @param props
 */
function ManagedFundGrid ({ funds }) {
  return (
    <div className='tearsheet_grid tearsheet_grid--seven-columns'>
      <div className='tearsheet_grid-row tearsheet_grid-row--header'>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--text'>Funds</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>POS</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>VAL (MM {getLocalizedCurrency()})</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>CHG (MM {getLocalizedCurrency()})</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>AUM (MM {getLocalizedCurrency()})</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--center'>Style</div>
        <div className='tearsheet_grid-cell tearsheet_grid-cell--center'>Turnover</div>
      </div>
      {(funds.length ? funds : [{}]).map((fund, index) => {
        const { id, fundName, style, turnover, portfolioValue } = (fund || {})
        const holding = get(fund, 'fundHoldingCurrentConnection.items[0]', [])

        const current = get(holding, 'current', null)
        const marketValue = get(holding, 'marketValue', null)
        const marketValueChange = get(holding, 'marketValueChange')

        return (
          <div className='tearsheet_grid-row' key={id || `managedFund-${index}`}>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--text'>
              {defaultIfEmpty(fundName)}
            </div>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>
              {format(current) || EMPTY_PLACEHOLDER}
            </div>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>
              {inMillions(marketValue, 2) || EMPTY_PLACEHOLDER}
            </div>
            <div className={`tearsheet_grid-cell tearsheet_grid-cell--number ${getChangeClassName(marketValueChange)}`}>
              {(marketValueChange === 0) ? marketValueChange : inMillions(marketValueChange, 2) || EMPTY_PLACEHOLDER}
            </div>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--number'>
              {inMillions(portfolioValue, 0) || EMPTY_PLACEHOLDER}
            </div>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--center'>
              {defaultIfEmpty(style)}
            </div>
            <div className='tearsheet_grid-cell tearsheet_grid-cell--center'>
              {turnover ? capitalize(turnover) : '-'}
            </div>
          </div>
        )
      })}
    </div>
  )
}

ManagedFundGrid.propTypes = propTypes
ManagedFundGrid.defaultProps = defaultProps

export default memo(ManagedFundGrid)
