import { config } from '../../config'

export const POST_PASSWORD_RESET_REQUEST = 'POST_PASSWORD_RESET_REQUEST'
export const POST_PASSWORD_RESET_SUCCESS = 'POST_PASSWORD_RESET_SUCCESS'
export const POST_PASSWORD_RESET_FAILURE = 'POST_PASSWORD_RESET_FAILURE'
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET'

const _postResetPasswordEmail = ({ email }) => (dispatch) => {
  dispatch({ type: POST_PASSWORD_RESET_REQUEST })
  return fetch(
    `https://${config.auth0Domain}/dbconnections/change_password`,
    {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        client_id: config.auth0ClientId,
        email,
        connection: config.auth0ResetEmailDb
      }),
      json: true
    })
    .then((response) => {
      if (response.ok) {
        dispatch({
          payload: { data: response.json() },
          type: POST_PASSWORD_RESET_SUCCESS
        })
      } else {
        dispatch({
          payload: { error: response.json() },
          type: POST_PASSWORD_RESET_FAILURE
        })
      }
    })
    .catch((error) => {
      dispatch({
        payload: { error: error },
        type: POST_PASSWORD_RESET_FAILURE
      })
    })
}
const _resetPasswordEmailState = () => dispatch => {
  dispatch({ type: RESET_PASSWORD_RESET })
}

export const sendResetPasswordEmail = (params) => (dispatch) => {
  dispatch(_postResetPasswordEmail(params))
}
export const resetPasswordEmailState = () => (dispatch) => {
  dispatch(_resetPasswordEmailState())
}
