import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// actions
import { getContactEstimate } from '../../../../actions'

// components
import EstimateGrid from './estimate.component'

// utils
import { get } from 'lodash'

const propTypes = {
  entityId: PropTypes.string.isRequired,
  legacySecurityId: PropTypes.string.isRequired
}

const defaultProps = {}

/**
 * Estimates
 * @param props
 */
function Estimate (props) {
  const { entityId, legacySecurityId, estimate, getContactEstimate } = props
  const data = get(estimate, entityId)

  useEffect(() => {
    if (!entityId || !legacySecurityId) {
      return
    }

    const variables = {
      securityId: legacySecurityId,
      analyst_id: entityId,
      item: ['EPS', 'SALES']
    }

    getContactEstimate({ ...variables })
  }, [entityId, legacySecurityId, getContactEstimate])

  return (data && data.length
    ? <Grid container spacing={2} className='tearsheet_section'>
      <Grid item xs={12} className='tearsheet_section-title'>Historical Estimate</Grid>
      <Grid item xs={12} className='tearsheet_section-content'>
        <EstimateGrid
          entityId={entityId}
          estimate={data}
        />
      </Grid>
    </Grid> : null
  )
}

Estimate.propTypes = propTypes
Estimate.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  estimate: get(state, 'tearsheet.estimate')
})

const mapDispatchToProps = (dispatch) => ({
  getContactEstimate: bindActionCreators(getContactEstimate, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Estimate)
