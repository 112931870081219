import { createTheme } from '@material-ui/core/styles'

/**
 * Material UI Theme - override theme font and size
 * Note: Overrides for components should be done through scss and not globally here
 * @type {Theme}
 */
const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: '"Open Sans",sans-serif',
    htmlFontSize: 13
  },
  disabled: {}
})

export default theme
