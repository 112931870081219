import {
  fetchInstitutionPurchasingPower,
  fetchFundPurchasingPower
} from '../..'

export const TARGETING_GET_PURCHASING_POWER_REQUEST = 'TARGETING_GET_PURCHASING_POWER_REQUEST'
export const TARGETING_GET_PURCHASING_POWER_SUCCESS = 'TARGETING_GET_PURCHASING_POWER_SUCCESS'
export const TARGETING_GET_PURCHASING_POWER_FAILURE = 'TARGETING_GET_PURCHASING_POWER_FAILURE'

/**
 * Get Purchasing Power
 * @param entityType
 * @param params
 */
export const fetchPurchasingPower = (entityType, params) => (dispatch) => {
  const _fetchCurrentPosition = entityType === 'institution'
    ? fetchInstitutionPurchasingPower
    : fetchFundPurchasingPower

  return dispatch(_fetchCurrentPosition(params, {
    types: [
      TARGETING_GET_PURCHASING_POWER_REQUEST,
      TARGETING_GET_PURCHASING_POWER_SUCCESS,
      TARGETING_GET_PURCHASING_POWER_FAILURE
    ]
  }))
}
