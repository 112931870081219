import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {isEqual} from 'lodash';
import {getFilterSummaryLabel, getIsPivotFilter} from '../../../../utils/report';
import {getClassName} from '../../../../utils/ui/ui.util';
import FilterSummary from './summary/filterSummary.component';
import NumberFilter from './number/numberFilter.component';
import BooleanFilter from './boolean/booleanFilter.component';
import ChoiceFilter from './choice/choiceFilter.component';
import StringFilter from './string/stringFilter.component';
import DateFilter from './date/dateFilter.component';
import PivotFilter from './pivot/pivotFilter.component';
import './reportFilter.component.css';

/**
 * Report Builder Filter Component
 */
class ReportFilter extends Component {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        const {isOpen, quarter} = props.filter.state;

        this.state = {
            selectedPivotQuarter: quarter || null,

            isOpen,
            isBeingOpen: false,
            isBeingRemoved: false
        };
    }

    /**
     * ShouldComponentUpdate
     * @param nextProps
     * @param nextState
     */
    shouldComponentUpdate(nextProps, nextState) {
        const {isBasic, isConfigFilterOpen, filter, pivotQuarters} = this.props;

        const newState = !isEqual(this.state, nextState);
        const newPivotQuarters = !isEqual(pivotQuarters, nextProps.pivotQuarters);
        const newFilterState = !isEqual((filter && filter.state), (nextProps.filter && nextProps.filter.state));
        const shouldCollapse = !isBasic && isConfigFilterOpen && !nextProps.isConfigFilterOpen;

        return newState || newPivotQuarters || newFilterState || shouldCollapse;
    }

    /**
     * ComponentDidUpdate
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {isBasic} = this.props;

        if (!isBasic) {
            const {isOpen} = this.state;

            if (isOpen && prevProps.isConfigFilterOpen && !this.props.isConfigFilterOpen) {
                this.setState({
                    isOpen: false
                });
            }
        }
    }

    /**
     * Handle filter collapse toggling
     */
    handleToggle = () => {
        const {isBeingOpen, isOpen} = this.state;

        if (isBeingOpen) {
            return;
        }

        this.setState({
            isOpen: !isOpen,
            isBeingOpen: true
        });

        setTimeout(() => {
            this.setState({
                isBeingOpen: false
            });
        }, 300);
    };

    /**
     * Handle Removal of Report Filter
     */
    handleRemove = () => {
        const {filter, onRemove} = this.props;
        const {isBeingRemoved} = this.state;

        if (isBeingRemoved) {
            return;
        }

        this.setState({
            isOpen: false,
            isBeingRemoved: true
        }, () => {
            setTimeout(() => {
                onRemove && onRemove(filter._id);
            }, 600);
        });
    };

    /**
     * Get component styles
     * @param type
     * @param options
     * @param isOpen
     * @param isPivotFilter
     * @param index
     * @returns {Object}
     */
    getStyles = (type, options, isOpen, isPivotFilter, index = 0) => {
        const defaultHeight = 55;
        let openedHeight = 86;
        const zIndex = 55 - index

        if (isPivotFilter) {
            openedHeight += 86;
        }

        switch(type) {
            case 'number':
            case 'string':
            case 'date':
                return {
                    height: isOpen ? 142 + openedHeight : defaultHeight,
                    zIndex
                };
            case 'boolean':
                return {
                    height: isOpen ? 22 + openedHeight : defaultHeight,
                    zIndex
                };
            case 'choice':
                return {
                    height: isOpen ? options.length * 22 + openedHeight : defaultHeight,
                    zIndex
                };
            default:
                return {
                    height: defaultHeight,
                    zIndex
                };
        }
    };

    /**
     * Render Filter Configuration based on type
     * @param filter
     * @param isPivotFilter
     * @returns {XML}
     */
    renderFilterConfig = (filter, isPivotFilter) => {
        const {isBasic, index, pivotQuarters, onChange} = this.props;
        const {selectedPivotQuarter} = this.state;
        
        const {_id, _field, filterType, filterOptions, state} = filter;
        const value = state && state.value;
        const operator = state && state.operator;

        switch (filterType) {
            case 'boolean':
                return (
                    <BooleanFilter
                        filterId={_id}
                        value={value}
                        onChange={onChange}
                    />
                );
            case 'choice':
                return (
                    <ChoiceFilter
                        filterId={_id}
                        options={filterOptions}
                        value={value}
                        onChange={onChange}
                    />
                );
            case 'number':
                return (
                    <NumberFilter
                        isBasic={isBasic}
                        filterId={_id}
                        fieldLabel={_field && _field.label}
                        operators={filterOptions}
                        value={value}
                        operator={operator}
                        disabled={!isBasic && (isPivotFilter && !selectedPivotQuarter)}
                        onChange={onChange}
                    >
                        {isPivotFilter && (
                            <PivotFilter
                                filter={filter}
                                pivotQuarters={pivotQuarters}
                                selectedPivotQuarter={selectedPivotQuarter}
                                onChange={this.handlePivotFilterChange}
                            />
                        )}
                    </NumberFilter>
                );
            case 'date':
                return (
                    <DateFilter
                        isBasic={isBasic}
                        index={index}
                        filterId={_id}
                        operators={filterOptions}
                        value={value}
                        operator={operator}
                        onChange={onChange}
                    />
                );
            case 'string':
                return (
                    <StringFilter
                        isBasic={isBasic}
                        filterId={_id}
                        fieldLabel={_field && _field.label}
                        operators={filterOptions}
                        value={value}
                        operator={operator}
                        onChange={onChange}
                    />
                );
            default:
                return null;
        }
    };

    /**
     * Store selected pivot quarter in state
     * @param value
     */
    handlePivotFilterChange = (value) => {
        const {filter, onChange} = this.props;
        
        const {_id, state} = filter;
        const newValueState = {
            ...state,
            quarter: value
        };

        this.setState({
            selectedPivotQuarter: value
        }, () => {
            onChange && onChange(_id, newValueState);
        });
    };

    /**
     * Render basic filter
     * @param filter
     */
    renderBasicView = (filter) => {
        return (
            <article className='report-builder-filter report-builder-filter--basic'>
                {this.renderFilterConfig(filter)}
            </article>
        );
    };

    /**
     * Render advanced (default) filter
     * @param filter
     * @returns {XML}
     */
    renderAdvancedView = (filter, index) => {
        const {isOpen, isBeingOpen, isBeingRemoved} = this.state;

        const filterType = filter.filterType;
        const entityType = filter._field && filter._field._entityType && filter._field._entityType.name;
        const fieldLabel = filter._field && filter._field.label;
        const isPivotFilter = getIsPivotFilter(filter);

        const styles = this.getStyles(filterType, (filter.filterOptions || []), isOpen, isPivotFilter, index);
        const baseClassName = getClassName(`report-builder-filter report-builder-filter--${filterType}`, [
            {condition: isOpen, trueClassName: 'report-builder-filter--open'},
            {condition: isBeingOpen, trueClassName: 'report-builder-filter--opening'},
            {condition: isBeingRemoved, trueClassName: 'report-builder-filter--removing'}
        ]);

        return (
            <article className={baseClassName} style={styles}>
                <FilterSummary
                    entityType={entityType}
                    fieldLabel={fieldLabel}
                    summaryLabel={getFilterSummaryLabel(filter)}
                    isBeingRemoved={isBeingRemoved}
                    onToggle={this.handleToggle}
                    onRemove={this.handleRemove}
                />
                <div className='report-builder-filter_config'>
                    {this.renderFilterConfig(filter, isPivotFilter)}
                </div>
            </article>
        );
    };

    /**
     * Render Report Filter Component
     * @return {XML}
     */
    render() {
        const {isBasic, filter, index} = this.props;

        if (!filter) {
            return null;
        }

        return isBasic ? this.renderBasicView(filter) : this.renderAdvancedView(filter, index);
    }
}

ReportFilter.propTypes = {
    isBasic: PropTypes.bool,
    isConfigFilterOpen: PropTypes.bool,
    pivotQuarters: PropTypes.object,
    filter: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func
};

export default ReportFilter;
