import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

const GET_INSTITUTION_POSITION = gql`
  query InstitutionHoldingCurrent(
      $tickerId: [String]! 
      $entityId: [String]!
      $source: String!
    ) {
    instHoldingCurrent(
      tickerId: $tickerId 
      institutionId: $entityId
      source: $source
      ) {
      items {
        current
      }
    }
  }`

/**
 * Current Position
 * @param options
 */
export function usePositionQuery (options) {
  return useQuery(GET_INSTITUTION_POSITION, options)
}
