import React, { memo } from 'react'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { Card, Select } from '../../../index'
import { CARD_CLASS_NAME, defaultIfEmpty, format, getClassName, MOVER_RANGE } from '../../../../utils'

import './mover.component.scss'

const propTypes = {
  dataId: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  subscription: PropTypes.string.isRequired,
  range: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onRangeChange: PropTypes.func.isRequired,
  data: PropTypes.shape({
    buyers: PropTypes.array.isRequired,
    hasBuyers: PropTypes.bool,
    sellers: PropTypes.array.isRequired,
    hasSellers: PropTypes.bool
  }).isRequired
}

const defaultProps = {
  isLoading: false,
  data: {}
}

/**
 * Top Mover Card
 * @param props
 */
function TopMoverCard (props) {
  const { dataId, theme, isLoading, data, subscription, range, onRangeChange } = props
  const { buyers, hasBuyers, sellers, hasSellers } = data

  function renderMover(mover) {
    if (isEmpty(mover)) return null;

    const { entityId, entityName, change } = mover;

    const isBuyer = change >= 0
    const changeClassName = getClassName(CARD_CLASS_NAME.LIST_VALUE, [{ 
      condition: isBuyer, 
      trueClassName: CARD_CLASS_NAME.LIST_VALUE_WITH_POSITIVE_MODIFIER,
      falseClassName: CARD_CLASS_NAME.LIST_VALUE_WITH_NEGATIVE_MODIFIER, 
    }]);
    const baseClassName = getClassName(CARD_CLASS_NAME.LIST, [{ condition: !entityId, trueClassName: CARD_CLASS_NAME.LIST_WITH_DISABLED_MODIFIER }]);

    return (
      <Link key={entityId} className={baseClassName} to={`/institution/${entityId}`}>
        <div className={CARD_CLASS_NAME.LIST_LABEL}>{defaultIfEmpty(entityName)}</div>
        <div className={changeClassName}>{format(change)}</div>
      </Link>
    )
  }

  return (
    <Card
      dataId={`${dataId}TopBuyerSellerCard`}
      theme={theme}
      className='security-movers'
      isLoading={isLoading}
      title={
        <div className={CARD_CLASS_NAME.LIST_LABEL}>
          Top Movers <span className={CARD_CLASS_NAME.HEADER_NOTE}>{subscription}</span>
        </div>
      }
      headerActions={[() => (
        <Select
          theme={theme}
          size='thin'
          value={MOVER_RANGE.find((option) => option && (option.value === range))}
          options={MOVER_RANGE}
          onChange={onRangeChange}
          searchable={false}
          clearable={false}
          disabled={isLoading}
        />
      )]}
    >
      <Grid container spacing={3}>
        {(!isLoading && !hasBuyers && !hasSellers)
          ? <Grid item xs={12}>No data available</Grid>
          : (!isLoading &&
            <>
              <Grid item xs={12} className='security-movers_section'>
                <div className='security-movers_title'>
                  <div className='security-movers_title-type'>Buyers</div>
                  {hasBuyers && <div className='security-movers_title-metric'>Pos Chg</div>}
                </div>
                {!hasBuyers
                  ? 'No data available'
                  : (buyers || []).map(renderMover)}
              </Grid>
              <Grid item xs={12} className='security-movers_section'>
                <div className='security-movers_title'>
                  <div className='security-movers_title-type'>Sellers</div>
                  {hasSellers && <div className='security-movers_title-metric'>Pos Chg</div>}
                </div>
                {!hasSellers
                  ? 'No data available'
                  : (sellers || []).map(renderMover)}
              </Grid>
            </>)}
      </Grid>
    </Card>
  )
}

TopMoverCard.propTypes = propTypes
TopMoverCard.defaultProps = defaultProps

export default memo(TopMoverCard)
