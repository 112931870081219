import React, {PureComponent} from 'react';
import RouteLink from '../routeLink/routeLink.component';
import PropTypes from 'prop-types';
import {getClassName} from '../../utils/ui';

/**
 * Button Component
 */
class Button extends PureComponent {

    /**
     * Handle start of Button hiding process
     * This is done to provide a fadeout animation
     */
    handleClick = (event) => {
        const {onClick} = this.props;

        event.target && event.target.blur();
        onClick && onClick(event);
    };

    /**
     * Render Button Component
     * @returns {*}
     */
    render() {
        const {
            dataId, className, reference, linkTo, styles, type, label, icon, target,
            theme, tall, wide, circle, square,
            loading, invisible, hidden, disabled, tooltip, active
        } = this.props;

        const baseClassName = getClassName('button', [
            {condition: className, trueClassName: className},
            {condition: theme, trueClassName: `button--${theme}`},
            {condition: tall, trueClassName: 'button--tall'},
            {condition: wide, trueClassName: 'button--wide'},
            {condition: circle, trueClassName: 'button--circle'},
            {condition: square || (!label && icon), trueClassName: 'button--square'},
            {condition: type && type === 'utility', trueClassName: 'button--utility'},
            {condition: loading, trueClassName: 'button--loading'},
            {condition: invisible, trueClassName: 'button--invisible'},
            {condition: hidden, trueClassName: 'button--hidden'},
            {condition: disabled, trueClassName: 'button--disabled'},
            {condition: active, trueClassName: 'button--active'}
        ]);

        return linkTo ? (
            <RouteLink data-id={dataId} className={baseClassName} to={linkTo} ref={reference} style={styles && styles.base} target={target || '_self'}>
                {icon && <i className={`button_icon ${icon}`} style={styles && styles.icon}/>}
                {label && <span className='button_label' style={styles && styles.label}>{label}</span>}
                {tooltip && <div className='tooltip tooltip--bottom'>{tooltip}</div>}
            </RouteLink>
        ) : (
            <button data-id={dataId} className={baseClassName} onClick={this.handleClick} ref={reference} style={styles && styles.base}>
                {icon && <i className={`button_icon ${icon}`} style={styles && styles.icon}/>}
                {label && <span className='button_label' style={styles && styles.label}>{label}</span>}
                {tooltip && <div className='tooltip tooltip--bottom'>{tooltip}</div>}
            </button>
        );
    }
}

Button.propTypes = {
    /**
     * Used for tracking purposes
     */
    dataId: PropTypes.string,

    /**
     * A custom className to add to the component
     */
    className: PropTypes.string,

    /**
     * Used to determine the ref attribute of the returned element
     */
    reference: PropTypes.object,

    /**
     * Used to transform the button into a Link component
     * Note: prop provides link url
     */
    linkTo: PropTypes.string,

    /**
     * Custom styles passed into the component
     */
    styles: PropTypes.shape({
        base: PropTypes.object,
        icon: PropTypes.object,
        label: PropTypes.object
    }),

    /**
     * Used to provide the button with an icon
     */
    icon: PropTypes.string,

    /**
     * Used to provide the button with a label
     */
    label: PropTypes.string,

    /**
     * Used to paint the Button using a specific theme
     */
    theme: PropTypes.string,

    /**
     * Used to modify the Button component with various modifiers
     */
    tall: PropTypes.bool,
    square: PropTypes.bool,
    circle: PropTypes.bool,
    wide: PropTypes.bool,

    /**
     * Used to paint the Button component with a pulsing loader
     */
    loading: PropTypes.bool,

    /**
     * Used to hide the Button component with opacity
     */
    invisible: PropTypes.bool,

    /**
     * Used to hide the Button component with display: none
     */
    hidden: PropTypes.bool,

    /**
     * Used to disable interaction with the Button component
     */
    disabled: PropTypes.bool,

    /**
     * A callback for when the user clicks the Button component
     */
    onClick: PropTypes.func,

    /**
     * Used to force a active button style on the Button Component
    */
    active: PropTypes.bool
};

Button.defaultProps = {
    linkTo: null,
    tall: false
};

export default Button;
