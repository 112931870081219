import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import renderHTML from 'react-render-html'
import { getClassName } from '../../utils/ui/ui.util'
import Dialog from '@material-ui/core/Dialog'
import './message.component.css'

const styles = {}

/**
 * Message Component
 * @param {string} props.type
 * @param {string} props.message
 * @param {array} props.buttons
 */
class Message extends Component {
  /**
   * Render Action Button
   * @param button
   * @param index
   */
  renderActionButton = (button, index) => {
    const {
      ui,
      icon,
      label,
      hidden,
      invisible,
      loading,
      disabled,
      ref,
      onClick
    } = button

    const buttonClass = getClassName('button button--wide button--tall', [
      { condition: ui, trueClassName: `button--${ui}` },
      {
        condition: icon && !(label && label.length),
        trueClassName: 'button--square'
      },
      { condition: invisible, trueClassName: 'button--invisible' },
      { condition: hidden, trueClassName: 'button--hidden' },
      { condition: loading, trueClassName: 'button--loading' },
      { condition: disabled, trueClassName: 'button--disabled' }
    ])

    return (
      <button
        key={`message-button--${index}`}
        ref={ref}
        className={buttonClass}
        onClick={onClick}
      >
        {icon && <i className={`button_icon ${icon}`} />}
        {label}
      </button>
    )
  }

  /**
   * Render
   * @return {XML}
   */
  render () {
    const {
      className,
      visible,
      type,
      title,
      message,
      list,
      useHtml,
      buttons,
      onClose,
      renderExitButton,
      disableEscapeKeyDown,
      disableAutoFocus,
      disableRestoreFocus
    } = this.props

    return (
      <Dialog
        disableEscapeKeyDown={disableEscapeKeyDown}
        disableAutoFocus={disableAutoFocus}
        disableRestoreFocus={disableRestoreFocus}
        className={className ? `message-root ${className}` : 'message-root'}
        classes={{
          paper: `message message--${type}`
        }}
        open={!!visible}
        onClose={onClose}
      >
        <div className='message_content'>
          <h1 className='message_title'>
            {useHtml ? renderHTML(title) : title}
          </h1>
          <div className='message_text'>
            {useHtml ? renderHTML(message) : message}
          </div>
          {list ? <div className='message_list'>{list}</div> : null}
        </div>
        {buttons && buttons.length > 0 && (
          <footer className='message_footer'>
            <div className='message_actions'>
              {buttons.map(this.renderActionButton)}
            </div>
          </footer>
        )}
        {renderExitButton && (
          <div className='message_exit' onClick={onClose}>
            <i className='q4i-close-4pt' />
          </div>
        )}
      </Dialog>
    )
  }
}

export default withStyles(styles)(Message)
