import React from 'react'

import NoSubscriptionMessage from '../noSubscriptionMessage/noSubscriptionMessage.component'
import { config } from '../../../config'
import { useTypedSelector } from '../../../hook'
import { isSubscribed } from '../../../utils'

import backgroundImage from '../../../resources/images/tryEngagementAnalytics/generic-chart.svg'
import EaScreenshot from '../../../resources/images/subscription/engagement.png'
import './tryEngagementAnalytics.component.css'
import Banner from '../../banner/banner.component'


/**
 * Temporary page to transition users from WebAnalytics(old) to EngagementAnalytics(new)
 */
function TryEngagementAnalytics (props) {
  const serviceToReplace = {
    title: '',
    serviceName: '',
    icon: ''
  }
  const path = props.location?.pathname
  switch (path) {
    case '/analytics':
      serviceToReplace.title = 'Web Analytics'
      serviceToReplace.serviceName = 'website'
      serviceToReplace.icon = 'q4i-website-analytics-2pt'
      break
    case '/webcast':
      serviceToReplace.title = 'Webcast Analytics'
      serviceToReplace.serviceName = 'webcast'
      serviceToReplace.icon = 'q4i-webcast-2pt'
      break
    default:
      break
  }
  const profile = useTypedSelector((state) => state.profile)
  return (
    <>
      <Banner
        title={serviceToReplace.title}
        icon={serviceToReplace.icon}
      />
      {isSubscribed(profile?.data, serviceToReplace.serviceName)
        ? (
          <div className='try-engagement-analytics_container'>
            <img src={backgroundImage} alt='generic graph' />
            <h3>Engagement Analytics</h3>
            <p>{serviceToReplace.title} has been replaced by Engagement Analytics. It is an enhanced tool that is built to help you view and understand how institutions are engaging with your IR.</p>
            <a className='button button--tall button--rain' href={`${config.capitalConnectUrl}/app/engagement`} target="_blank">Go to engagement analytics</a>
          </div>)
        : <NoSubscriptionMessage image={EaScreenshot} />}
    </>
  )

}
export default TryEngagementAnalytics
