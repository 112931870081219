import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Select } from '../../../../components'
import { Checkbox } from '../../../shared'
import './reportPivot.component.css'
import { THEMES } from '../../../../utils/ui'

/**
 * ReportPivotLabel Component
 * @param pivotField
 * @returns {*}
 */
function ReportPivotLabel (pivotField) {
  return (
    <span className='report-pivot_label'>{pivotField && pivotField.columnName ? `Pivot by ${pivotField.columnName}` : 'No secondary pivot'}</span>
  )
}

/**
 * ReportPivotComponent
 * @param props
 * @returns {*}
 */
function ReportPivot (props) {
  const { isPivot, pivotFields, isPivotAvailable, availablePivotFields, onPivotToggle, onPivotChange } = props
  const selectedPivot = pivotFields && pivotFields.length > 0 ? { label: ReportPivotLabel(pivotFields[0]), value: pivotFields[0] } : null
  const selectPivotOptions = [].concat(availablePivotFields || [])
    .map((option) => option && { label: ReportPivotLabel(option), value: option })

  return (
    <div className='report-pivot'>
      <div className='field field--custom'>
        <Checkbox
          theme='soft-grey'
          id='report-config-pivot-toggle'
          label='Enable Pivot'
          isChecked={isPivot}
          isDisabled={!isPivotAvailable}
          onChange={onPivotToggle}
        />
      </div>
      <div className='field field--custom'>
        <Select
          size='thin'
          placeholder='Select secondary pivot'
          theme={THEMES.WHITE}
          options={selectPivotOptions}
          value={(isPivot && selectedPivot) || null}
          onChange={(option) => option.value && onPivotChange(option.value)}
          disabled={!isPivot || !isPivotAvailable}
          clearable={false}
          searchable={false}
        />
      </div>
    </div>
  )
}

ReportPivot.propTypes = {
  isPivot: PropTypes.bool.isRequired,
  pivotFields: PropTypes.array.isRequired,
  isPivotAvailable: PropTypes.bool.isRequired,
  availablePivotFields: PropTypes.array.isRequired,
  onPivotToggle: PropTypes.func.isRequired,
  onPivotChange: PropTypes.func.isRequired
}

ReportPivot.defaultProps = {
  pivotFields: [],
  availablePivotFields: []
}

export default memo(ReportPivot)
