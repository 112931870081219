import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { Button, Card, Message } from '../../../../../components'
import './suggestedContacts.component.css'
import { THEMES } from '../../../../../utils'

/**
 * Contact List Component
 * @param param.suggestions
 */
const ContactList = ({ suggestions, onSelect, onPreview }) => {
  return (
    <div className='contact-list q4-fade-in'>
      {suggestions.map((contact) => {
        const institution = contact.institution && contact.institution[0]
        return (
          <div className='contact-list_item' key={contact._id}>
            <div className='contact-list_item_detail'>
              <div className='contact-list_item_detail_title'>{contact.name}</div>
              <div className='contact-list_item_detail_subtitle'>{institution}</div>
            </div>

            <div className='contact-list_item_actions'>
              <Button
                label='Preview'
                theme={THEMES.SOFT_GREY}
                onClick={() => onPreview(contact)}
              />
              <Button
                label='Select'
                theme={THEMES.CITRUS}
                onClick={() => onSelect(contact)}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

/**
 * Preview Component
 * @param param.contact
 */
const Preview = ({ contact, onBack, onSelect }) => {
  const institution = contact.institution && contact.institution[0]
  const title = contact.title && contact.title[0]
  const jobFunction = contact.functions && contact.functions[0]

  return (
    <div className='preview q4-fade-in'>
      <div className='preview_detail'>
        <div className='preview_detail_title'>{contact.name}</div>
        <div className='preview_detail_subtitle'>{institution}</div>

        <Grid container spacing={1} className='preview_detail_grid'>
          <Grid item xs={5} className='preview_detail_label'>Job Title</Grid>
          <Grid item xs={7} className='preview_detail_value'>{title || '-'}</Grid>
          <Grid item xs={5} className='preview_detail_label'>Corporate Phone</Grid>
          <Grid item xs={7}>{(contact.phone || []).map((phone) => (
            <div>{phone}</div>
          )) || '-'}
          </Grid>
          <Grid item xs={5} className='preview_detail_label'>Email</Grid>
          <Grid item xs={7}>{(contact.email || []).map((email) => (
            <div className='preview_detail_value'>{email}</div>
          )) || '-'}
          </Grid>
          <Grid item xs={5} className='preview_detail_label'>Functions</Grid>
          <Grid item xs={7} className='preview_detail_value'>{jobFunction}</Grid>
        </Grid>
      </div>

      <div className='preview_actions'>
        <Button label='Back to Results'
          theme={THEMES.SOFT_GREY}
          onClick={onBack}
        />
        <Button label='Select'
          theme={THEMES.CITRUS}
          onClick={() => onSelect(contact)}
        />
      </div>
    </div>
  )
}

/**
 * Placeholder
 */
const Placeholder = () => {
  return (
    <div className='placeholder q4-fade-in'>
      <span className='placeholder_text'>Suggested contacts will appear if we find any matches.</span>
    </div>
  )
}

class SuggestedContacts extends Component {
  /**
   * Constructor
   * @param props
   */
  constructor(props) {
    super(props)
    this.state = {
      contact: null
    }
  }

  /**
   * Show Info Modal
   */
  handleInfoClick = () => {
    const { openModal } = this.props;
    openModal({
      component: ({ onClose }) => <Message
        visible={true}
        type='info'
        title='What is a suggested contact?'
        message={
          '<p>Suggested contacts will appear as you enter details for your new contact to ensure there are no duplicate records in your Address Book.</p>'
        }
        useHtml={true}
        onClose={onClose}
        renderExitButton={true}
        buttons={[{
          ui: 'citrus',
          label: 'Got It',
          onClick: onClose
        }]}
      />
    });
  };

  /**
   * Handle Preview
   * @param contact
   */
  handlePreview = (contact) => {
    this.setState({ contact })
  }

  /**
   * Handle Back
   */
  handleBack = () => {
    this.setState({ contact: null })
  }

  /**
   * Render
   */
  render() {
    const { suggestions, onSelect } = this.props
    const { contact } = this.state
    const hasSuggestions = suggestions && suggestions.length > 0

    return (
      <Card
        theme={THEMES.LIGHT}
        className='suggested-contacts'
        title='Suggested Contacts'
        headerActions={[
          () => <Button
          className='button--info'
          theme={THEMES.PALE_GREY}
          onClick={this.handleInfoClick}
          circle
        />
        ]}
      >
        {!contact && hasSuggestions && <ContactList
          suggestions={suggestions}
          onPreview={this.handlePreview}
          onSelect={onSelect}
        />}
        {contact && <Preview
          contact={contact}
          onSelect={onSelect}
          onBack={this.handleBack}
        />}
        {!contact && !hasSuggestions && <Placeholder />}

      </Card>

    )
  }
}

SuggestedContacts.propTypes = {
  onSelect: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired
}

SuggestedContacts.defaultProps = {
  suggestions: []
}

export default SuggestedContacts
