import React, { Component } from 'react'
import _ from 'lodash'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderLightThumb, renderDarkThumb, renderTrackVertical } from '../../../resources/theme/q4.custom-scrollbar'
import { Categories } from '../../../data/widget'
import WidgetCard from '../../../containers/widget/card/widgetCard.container'
import './widgetLibrary.component.css'
import {WEB_ANALYTICS_INSTITUTION_WIDGET, WEB_ANALYTICS_DOWNLOAD_WIDGET} from '../../../utils/widget.util'
import {getEaIntegrationEnabledFlag} from '../../../utils'

/**
 * Widget Library Component
 */
class WidgetLibrary extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor (props) {
        super(props)

        this.state = {
            categories: Categories,
            activeCategory: null,
            isSelectionOpen: false
        }
    }

    /**
     * On Category select, open the widget selection drawer
     * @param category
     */
    openSelection = (category) => {
        this.setState({
            activeCategory: category || null,
            isSelectionOpen: true
        })
    }

    /**
     * Close the widget selection drawer
     */
    closeSelection = () => {
        this.setState({
            activeCategory: null,
            isSelectionOpen: false
        })
    }

    /**
     * Handle clicking of the mask
     */
    handleMaskClick = () => {
        const { isSelectionOpen } = this.state
        const { onClose } = this.props

        if (isSelectionOpen) {
            return this.closeSelection()
        }

        onClose && onClose()
    }

    /**
     * On Widget select, close the drawer and run callback
     * @param widget
     * @param callback
     */
    onWidgetSelect = (widget, callback) => {
        this.closeSelection()
        callback(widget)
    }

    /**
     * Render widget categories
     * @param categories
     * @param activeCategory
     */
    renderCategories = (categories, activeCategory) => {
        const { onClose, theme, region } = this.props
        const renderThumb = theme === 'dark' ? renderLightThumb : renderDarkThumb

        const availableCategories = _.filter((categories || []), (category) => {
            return category.region && category.region.indexOf(region) >= 0
        })

        return (
            <Scrollbars
                className="react-scrollbar"
                autoHide
                hideTracksWhenNotNeeded
                renderThumbVertical={renderThumb}
                renderTrackVertical={renderTrackVertical}>
                <h2 className='widget-library_title'>Select widget</h2>
                <ul className='widget-library_list'>
                    {availableCategories.map((category) => {
                        return (
                            <li
                                className={`widget-library_list-item ${category.type === activeCategory ? 'widget-library_list-item--active' : ''}`}
                                key={category.type}
                                onClick={() => {
                                    this.openSelection(category.type)
                                }}
                            >
                                <span className='widget-library_list-icon'>
                                    <i className={`q4i-${category.icon}-2pt`}/>
                                </span>
                                <span className='widget-library_list-label'>{category.label}</span>
                                <span className='widget-library_list-arrow'>
                                    <i className='q4i-caret-sm-right-4pt'/>
                                </span>
                            </li>
                        )
                    })}
                </ul>
                <div className='widget-library_back' onClick={onClose}>
                    <i className='q4i-arrow-left-2pt'/>
                </div>
            </Scrollbars>
        )
    }

    /**
     * Determine widget subscription requirement
     * @param widget
     * @param activeSubscription
     * @returns {boolean|*}
     */
    isWidgetUnLocked = (widget, activeSubscription) => {
        const _options = (widget.options || {})

        const availableSubscriptions = _.intersection(activeSubscription, widget.subscription)
        return (availableSubscriptions.length === widget.subscription.length) ||
            ((_options.isPartialSubscription || false) && availableSubscriptions.length > 0)
    }

    /**
     * Render widget selection drawer
     */
    renderSelection = () => {
        const { layout, widgets, region, subscription, theme } = this.props
        const { activeCategory } = this.state

        if (!activeCategory) {
            return null
        }

        const activeSubscription = _.reduce((subscription || []), (accumulator, item) => {
            if (item.enabled) {
                accumulator.push(item.type)
            }
            return accumulator
        }, [])

        const filteredWidgets = _.filter((widgets || []), (widget) => {
            const isCategory = widget.category === activeCategory
            const isRegion = widget.region && widget.region.indexOf(region) >= 0

            return isCategory && isRegion
        })

        let availableWidgets = []
        let unavailableWidgets = []
        let subscriptionConflict = false

        filteredWidgets.forEach((widget) => {
            if (layout.h >= widget.minHeight && layout.w >= widget.minWidth) {
                availableWidgets.push(widget)
            } else {
                unavailableWidgets.push({
                    isTooLarge: true,
                    ...widget
                })
            }

            if (!subscriptionConflict) {
                subscriptionConflict = !this.isWidgetUnLocked(widget, activeSubscription)
            }
        })

        return (
            <Scrollbars
                className="react-scrollbar"
                autoHide
                hideTracksWhenNotNeeded
                renderThumbVertical={theme === 'dark' ? renderLightThumb : renderDarkThumb}
                renderTrackVertical={renderTrackVertical}
            >
                {(availableWidgets.length && unavailableWidgets.length) ? (
                    <div>
                        <h2 className='widget-library_title'>Select type</h2>
                        {this.renderWidgets(availableWidgets, activeSubscription)}
                        <h2 className='widget-library_title widget-library_title--unavailable'>Unavailable</h2>
                        <p className='widget-library_message'>This widget is incompatible with a container of this size,
                            please resize and try again.</p>
                        {this.renderWidgets(unavailableWidgets, activeSubscription)}
                    </div>
                ) : (availableWidgets.length && !unavailableWidgets.length) ? (
                    <div>
                        <h2 className='widget-library_title'>Select type</h2>
                        {this.renderWidgets(availableWidgets, activeSubscription)}
                    </div>
                ) : (!availableWidgets.length && unavailableWidgets.length) ? (
                    <div>
                        <h2 className='widget-library_title widget-library_title--unavailable'>Unavailable</h2>
                        <p className='widget-library_message'>This widget is incompatible with a container of this size,
                            please resize and try again.</p>
                        {this.renderWidgets(unavailableWidgets, activeSubscription)}
                    </div>
                ) : (
                    <div>
                        <h2 className='widget-library_title widget-library_title--unavailable'>Unavailable</h2>
                        <p className='widget-library_message'>No widgets available.</p>
                    </div>
                )}
                {subscriptionConflict && (
                    <span className='widget-library_disclaimer'>
                        <i className='q4i-lock-4pt'/> Subscription Required
                    </span>
                )}
                <div className='widget-library_back' onClick={this.closeSelection}>
                    <i className='q4i-arrow-left-2pt'/>
                </div>
            </Scrollbars>
        )
    }

    /**
     * Render appropriate widgets based on layout and active category
     * @param widgets
     * @param subscription
     */
    renderWidgets = (widgets, subscription) => {
        const { layout, onAdd } = this.props

        if (!layout || !(typeof layout === 'object' && Object.keys(layout).length)) {
            return null
        }
        
        const filteredWidgets =  (widgets || []).filter((widget) => {
            if (getEaIntegrationEnabledFlag()) {
                if (widget.id === WEB_ANALYTICS_DOWNLOAD_WIDGET.id || widget.id === WEB_ANALYTICS_INSTITUTION_WIDGET.id) {
                    return false;
                } else return true;
            } else return true;
        }).map((filteredWidget) => {return (
            <WidgetCard
            key={filteredWidget.id}
            widget={filteredWidget}
            isUnlocked={this.isWidgetUnLocked(filteredWidget, subscription)}
            onWidgetSelect={() => this.onWidgetSelect(filteredWidget, onAdd)}
            />
        ) })

        return filteredWidgets
    }

    /**
     * Render Widget Library
     * @returns {*}
     */
    render () {
        const { categories, activeCategory, isSelectionOpen } = this.state
        const { widgets, onSuggestion, hidden } = this.props

        return (
            <div className={hidden ? 'widget-library widget-library--hidden' : 'widget-library'}>
                <div
                    className={`widget-library_categories ${isSelectionOpen ? 'widget-library_categories--selected' : ''}`}>
                    {this.renderCategories(categories, activeCategory)}
                    <div className='widget-library_suggestion'>
                        <button className='button button--black-smoke' onClick={onSuggestion}>Suggest a Widget</button>
                    </div>
                </div>
                <div className={`widget-library_selection ${isSelectionOpen ? 'widget-library_selection--open' : ''}`}>
                    {this.renderSelection(widgets, activeCategory)}
                </div>
                <div className='widget-library_mask' onClick={this.handleMaskClick}/>
            </div>
        )
    }
}

export default WidgetLibrary