// This file contains functions that are used for development purposes only. They are not used in production.
const warn = console.warn
/**
 * This code overrides the console warn function to suppress warnings that are not relevant.
 * The warnings that are suppressed are from node modules that are not longer maintained
 * @param  {...any} warnings 
 */
export const overrideLogWarn = (...warnings) => {
  let showWarning = true
  const warning = warnings[0] || ''
  const warning2 = warnings[1] || ''
  //suppress warnings from node modules that are not longer maintained
  if (warning.includes("UNSAFE_") &&
    (warning2.includes("ReactGridLayout") || warning2.includes("PaginationBoxView") || warning2.includes("Styled(styled.article)")
      || warning2.includes("BoardContainer"))) {
    showWarning = false
  }

  if (showWarning) {
    warn(...warnings)
  }
  else warn("Legacy warning suppressed")
}