import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { push } from 'connected-react-router'
import { fetchProfile } from '../shared/index'

export const GET_DASHBOARDS_REQUEST = 'GET_DASHBOARDS_REQUEST'
export const GET_DASHBOARDS_SUCCESS = 'GET_DASHBOARDS_SUCCESS'
export const GET_DASHBOARDS_FAILURE = 'GET_DASHBOARDS_FAILURE'

export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST'
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS'
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE'

export const SET_ACTIVE_SECURITY = 'SET_ACTIVE_SECURITY'
export const SET_ACTIVE_DASHBOARD = 'SET_ACTIVE_DASHBOARD'
export const SET_DASHBOARD_THEME = 'SET_DASHBOARD_THEME'

export const CLONE_DASHBOARD_REQUEST = 'CLONE_DASHBOARD_REQUEST'
export const CLONE_DASHBOARD_SUCCESS = 'CLONE_DASHBOARD_SUCCESS'
export const CLONE_DASHBOARD_FAILURE = 'CLONE_DASHBOARD_FAILURE'

export const REMOVE_DASHBOARD_REQUEST = 'REMOVE_DASHBOARD_REQUEST'
export const REMOVE_DASHBOARD_SUCCESS = 'REMOVE_DASHBOARD_SUCCESS'
export const REMOVE_DASHBOARD_FAILURE = 'REMOVE_DASHBOARD_FAILURE'

export const UPDATE_DASHBOARD_REQUEST = 'UPDATE_DASHBOARD_REQUEST'
export const UPDATE_DASHBOARD_SUCCESS = 'UPDATE_DASHBOARD_SUCCESS'
export const UPDATE_DASHBOARD_FAILURE = 'UPDATE_DASHBOARD_FAILURE'

export const CANCEL_DASHBOARD_EDIT = 'CANCEL_DASHBOARD_EDIT'

export const FETCHING = 'FETCHING'
export const FETCHED = 'FETCHED'
export const IDLE = 'IDLE'
export const ERROR = 'ERROR'

/**
 * Get Dashboard list
 * Returns all deals within user's organization
 * @param params.limit
 * @param params
 */
const fetchDashboard = (params) => ({
  [CALL_API]: {
    types: [
      GET_DASHBOARDS_REQUEST,
      GET_DASHBOARDS_SUCCESS,
      GET_DASHBOARDS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    payload: params,
    endpoint: '/dashboard'
  }
})

/**
 * Load Dashboard list
 * @returns {function(*)}
 */
export const loadDashboard = (params) => (dispatch, getState) => {
  const state = getState()
  const tickerId = params.tickerId || state.dashboard.dashboardState.tickerId
  const securityId = params.securityId || state.dashboard.dashboardState.securityId

  if (!securityId) {
    return
  }

  dispatch({
    type: SET_ACTIVE_SECURITY,
    tickerId,
    security: securityId,
    entityId: params && params.entityId
  })

  return dispatch(fetchProfile()).then(() => {
    dispatch(fetchDashboard({
      ...params,
      securityId
    }))
  })
}

/**
 * Get Dashboard list
 * Returns all deals within user's organization
 * @param id
 */
const _updateDashboardById = (id, data) => ({
  [CALL_API]: {
    types: [
      UPDATE_DASHBOARD_REQUEST,
      UPDATE_DASHBOARD_SUCCESS,
      UPDATE_DASHBOARD_FAILURE
    ],
    method: METHOD_TYPE.PUT,
    endpoint: `/dashboard/${id}`,
    payload: data || {}
  }
})

const _cloneDashboard = (id, data) => ({
  [CALL_API]: {
    types: [
      CLONE_DASHBOARD_REQUEST,
      CLONE_DASHBOARD_SUCCESS,
      CLONE_DASHBOARD_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: `/dashboard/${id}`,
    payload: data || {}
  }
})

const _removeDashboard = (id) => ({
  [CALL_API]: {
    types: [
      REMOVE_DASHBOARD_REQUEST,
      REMOVE_DASHBOARD_SUCCESS,
      REMOVE_DASHBOARD_FAILURE
    ],
    method: METHOD_TYPE.DELETE,
    endpoint: `/dashboard/${id}`
  }
})

export const updateDashboardById = (id, data, doRedirect) => (dispatch) => {
  return dispatch(_updateDashboardById(id, data)).then(() => {
    if (doRedirect) {
      dispatch(push('/dashboard'))
    }
  })
}

export const cloneDashboard = (id, data, doRedirect) => (dispatch) => {
  return dispatch(_cloneDashboard(id, data)).then((resp) => {
    if (doRedirect) {
      dispatch(push('/dashboard'))
    } else {
      dispatch(fetchDashboard())
      return resp.payload
    }
  })
}

export const cancelDashboardEdit = (id) => (dispatch) => {
  if (id) {
    dispatch({ type: CANCEL_DASHBOARD_EDIT, payload: { _id: id } })
  }
  return dispatch(push('/dashboard'))
}

export const removeDashboard = (id) => (dispatch) => {
  return dispatch(_removeDashboard(id)).then(() => {
    dispatch(push('/dashboard'))
  })
}

export const editActiveDashboard = (dashboardId) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_DASHBOARD, dashboardId })
  return dispatch(push('/dashboard/edit'))
}

export const setActiveDashboard = (dashboardId) => (dispatch) => {
  return dispatch({ type: SET_ACTIVE_DASHBOARD, dashboardId })
}

export const changeTheme = (theme) => (dispatch) => {
  return dispatch({ type: SET_DASHBOARD_THEME, theme })
}
