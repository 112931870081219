import {
  FAILED,
  FETCH_ESTIMATES_CURRENT_RATING_REQUEST,
  FETCH_ESTIMATES_CURRENT_RATING_SUCCESS,
  FETCH_ESTIMATES_CURRENT_RATING_ERROR,
  FETCHED,
  FETCHING,
  IDLE
} from '../../actions/estimates'

const initialState = {
  status: IDLE,
  rating: {}
}

const estimatesCurrentRating = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ESTIMATES_CURRENT_RATING_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_ESTIMATES_CURRENT_RATING_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        rating: action.payload
      }
    case FETCH_ESTIMATES_CURRENT_RATING_ERROR:
      return {
        ...state,
        status: FAILED,
        error: action.error
      }
    default:
      return state
  }
}

export default estimatesCurrentRating
