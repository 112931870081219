import React, {PureComponent} from 'react';
import {Checkbox} from '../../../../shared';
import PropTypes from 'prop-types';

/**
 * Report Builder Boolean Filter Component
 */
class BooleanFilter extends PureComponent {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this.state = {
            value: !!props.value
        };
    }

    /**
     * Handle 'boolean' type Filter select
     * @param newValue
     */
    handleBoolean = (newValue) => {
        const {filterId, onChange} = this.props;

        this.setState({
            value: newValue
        }, () => {
            onChange && onChange(filterId, {
                value: this.state.value
            });
        });
    };

    /**
     * Render Report Filter Component
     * @return {XML}
     */
    render() {
        const {filterId} = this.props;
        const {value} = this.state;

        return (
            <div className='boolean-filter'>
                <Checkbox
                    theme='rain'
                    label='Include'
                    id={`boolean-filter--${filterId}`}
                    isChecked={value}
                    onChange={this.handleBoolean}
                />
            </div>
        );
    }
}

BooleanFilter.propTypes = {
    filterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

export default BooleanFilter;