import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '../../index'
import { RangeTab } from '../../shared'
import { ENTITY_TYPE, THEMES } from '../../../utils'
import { capitalize } from 'lodash'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

/**
 * EntitySearchRangeTab Component
 */
const EntitySearchRangeTab = ({ dataId, selectedTab, onTabChange, isRelativeInstitution, isInstitution }) => {
  const buttons = [INSTITUTION, FUND, CONTACT].map((label) => ({
    dataId: `${dataId}${capitalize(label)}`,
    active: selectedTab === label,
    value: label,
    label: capitalize(`${label}s`),
    disabled: isRelativeInstitution && isInstitution && label === INSTITUTION,
    onClick: (event, value) => onTabChange({ selectedTab: value })
  }))

  return (
    <RangeTab theme={THEMES.LIGHT} className='entity-search_tabs' buttons={buttons} />
  )
}

/**
 * EntitySearchControlPanel Component
 */
const EntitySearchControlPanel = ({ dataId, controls, onControlsChange, isRelativeInstitution, isInstitution }) => {
  const showCheckbox = !isRelativeInstitution && (controls.selectedTab !== INSTITUTION)

  return (
    <div className='entity-search_controls'>
      <EntitySearchRangeTab
        dataId={dataId}
        selectedTab={controls.selectedTab}
        onTabChange={onControlsChange}
        isRelativeInstitution={isRelativeInstitution}
        isInstitution={isInstitution}
      />
      {showCheckbox && (
        <Checkbox
          dataId={`${dataId}RelativeSearch`}
          name='relative'
          label='Relevant profiles'
          isSelected={controls.isRelativeSearch}
          onChange={(value) => onControlsChange({ isRelativeSearch: value })}
        />
      )}
    </div>
  )
}

EntitySearchControlPanel.propTypes = {
  dataId: PropTypes.string,
  controls: PropTypes.shape({
    /**
     * Used to determine which tab is active
     */
    selectedTab: PropTypes.oneOf([CONTACT, FUND, INSTITUTION]),

    /**
     * Used to determine if relative search checkbox is active
     */
    isRelativeSearch: PropTypes.bool
  }).isRequired,

  /**
   * Callback function to update controls state
   */
  onControlsChange: PropTypes.func.isRequired,
  isRelativeInstitution: PropTypes.bool
}

export default EntitySearchControlPanel
