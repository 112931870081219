import React, {Component} from 'react';
import './mask.component.css';

/**
 * Mask Component
 * @param {bool} props.visible
 * @param {bool} props.spinner - show loading spinner mask
 * @param {bool} props.fullScreen (default true)
 */
class Mask extends Component {

    /**
     * Get Classes
     * @return {Array|*}
     */
    getClasses = () => {
        const {fullScreen, spinner} = this.props;
        const isFullScreen = fullScreen === null ? true : fullScreen;
        const classes = ['mask'];

        if (isFullScreen) {
            classes.push('mask--fullscreen');
        }
        if (spinner) {
            classes.push('mask--spinner');
        }
        return classes.join(' ');
    };

    /**
     * Render Spinner
     * @param {bool} spinner
     * @return {function(): XML}
     */
    renderSpinner = (spinner) => {
        if (!spinner) {
            return null;
        }
        return () => (<div className={'mask_spinner'}></div>);
    };

    /**
     * Render
     * @return {XML}
     */
    render() {
        const {visible, spinner} = this.props;
        const Spinner = this.renderSpinner(spinner);

        if (!visible) {
            return null;
        } else {
            return (
                <div className={this.getClasses()}>
                    <Spinner/>
                </div>
            );
        }
    }
}

export default Mask;