import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// actions
import { getContactCoverage } from '../../../../actions'

// components
import CoverageGrid from './coverage.component'

// utils
import { get } from 'lodash'

const propTypes = {
  entityId: PropTypes.string.isRequired,
  legacySecurityId: PropTypes.string.isRequired
}

const defaultProps = {}

/**
 * Contact Coverage
 * @param props
 */
function Coverage (props) {
  const { entityId, legacySecurityId, coverage, getContactCoverage } = props

  useEffect(() => {
    if (!entityId || !legacySecurityId) {
      return
    }

    const variables = {
      securityId: legacySecurityId,
      analyst_id: entityId,
      item: ['EPS'],
      limit: 5,
      sort: JSON.stringify([{
        property: 'EPS',
        direction: 'desc'
      }])
    }

    getContactCoverage({ ...variables })
  }, [entityId, legacySecurityId, getContactCoverage])

  return (
    <Grid container spacing={2} className='tearsheet_section'>
      <Grid item xs={12} className='tearsheet_section-title'>Top 5 Coverage</Grid>
      <Grid item xs={12} className='tearsheet_section-content'>
        <CoverageGrid coverage={get(coverage, entityId)} />
      </Grid>
    </Grid>
  )
}

Coverage.propTypes = propTypes
Coverage.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  coverage: get(state, 'tearsheet.coverage')
})

const mapDispatchToProps = (dispatch) => ({
  getContactCoverage: bindActionCreators(getContactCoverage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Coverage)
