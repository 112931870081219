import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { ENTITY_TYPE } from '../../../utils'

const GET_INSTITUTION_POSITION = gql`
  query InstitutionHoldingCurrent(
    $tickerId: [String]! 
    $entityId: [String]!
    $source: String!
  ) {
    instHoldingCurrent(
      tickerId: $tickerId 
      institutionId: $entityId
      source: $source
    ) {
      items {
        current
      }
    }
  }`

const GET_FUND_POSITION = gql`
  query FundHoldingCurrent(
    $tickerId: [String]!
    $entityId: [String]!
    $source: String!
   ) {
    fundHoldingCurrent(
      tickerId: $tickerId
      fundId: $entityId
      source: $source
      ) {
      items {
        current
      }
    }
  }`

const GET_PURCHASING_POWER = gql`
  query PurchasingPower($securityId: [String]!, $entityId: [String]!, $entityType: String!) {
    purchasingPower(securityId: $securityId, entityId: $entityId, entityType: $entityType) {
      items {
        purchasingPower
      }
    }
  }`

/**
 * Current Position
 * @param type
 * @param options
 * @returns {{*}}
 */
export function usePositionQuery (type, options) {
  const query = (type === ENTITY_TYPE.FUND) ? GET_FUND_POSITION : GET_INSTITUTION_POSITION
  return useQuery(query, options)
}

/**
 * Purchasing Power
 * @param options
 * @returns {{*}}
 */
export function usePurchasingPowerQuery (options) {
  return useQuery(GET_PURCHASING_POWER, options)
}

/**
 * Max Purchasing Power
 * @param options
 * @returns {{*}}
 */
// export function useMaxPurchasingPowerQuery (options) {
//   return useQuery(GET_MAX_PURCHASING_POWER, options)
// }
