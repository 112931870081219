import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'

import CustomCell from './cell/cell.component'
import { AgGrid, NoContentMessage, Spinner } from '../../../../components'
import { preventDefaultRowClick, setColumnDefinition, THEMES } from '../../../../utils'

const propTypes = {
  dataId: PropTypes.string,
  pageSizeId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  listOptions: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  noFilter: PropTypes.bool.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onBulkAction: PropTypes.func.isRequired,
  onBriefingBookCreate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  loading: false,
  data: [],
  total: 0,
  listOptions: {},
  noFilter: true
}

const columns = [
  {
    field: 'title',
    headerName: 'Title',
    type: 'text',
    minWidth: 520,
    headerCheckboxSelection: true,
    checkboxSelection: true
  },
  {
    field: 'author',
    headerName: 'Author',
    type: 'text',
    minWidth: 220,
    maxWidth: 220
  },
  {
    field: 'created',
    headerName: 'Created',
    type: 'date',
    minWidth: 120,
    maxWidth: 120
  },
  {
    field: 'updated',
    headerName: 'Updated',
    type: 'date',
    minWidth: 120,
    maxWidth: 120
  }]

/**
 * Briefing Book Table Component
 * @param props
 */
function BriefingBookTable (props) {
  const {
    dataId, pageSizeId, loading, data, total, listOptions, noFilter,
    onQueryChange, onBulkAction, onBriefingBookCreate, history
  } = props
  const [agGrid, setAgGrid] = useState({})

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click event
   * @param id {String}
   */
  const handleRowClick = ({ id }) => {
    history && history.push(`/briefingbook/${id}`)
  }

  /**
   * Handle page change
   * @param selected
   */
  const handlePageChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, page: selected }
    })
  }

  /**
   * Handle page size change
   * @param selected
   */
  const handlePageSizeChange = ({ selected }) => {
    onQueryChange({
      listOptions: { ...listOptions, limit: selected, page: 1 }
    })
  }

  /**
   * Handle Column Sort
   * @param grid
   */
  const handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel && !sortModel.length) {
      return
    }

    onQueryChange({
      listOptions: {
        ...listOptions,
        sortBy: sortModel[0].colId,
        sortDir: sortModel[0].sort,
        page: 1
      }
    })
  }

  /**
   * Handle AgGrid bulk delete
   */
  const handleBulkDelete = () => {
    const selectedRows = agGrid.api && agGrid.api.getSelectedRows()
    const selectedIds = (selectedRows || []).map((row) => row && row.id)

    if (!selectedIds || !selectedIds.length) {
      return
    }

    onBulkAction(selectedIds)
  }

  /**
   * Get NoContentMessage Props
   * @returns {{*}}
   */
  const getNoContentMessageProps = () => {
    return noFilter ? {
      title: 'No Briefing Books',
      message: 'Start adding books',
      actions: [{
        label: 'Add Briefing Book',
        dataId: `${dataId}Create`,
        onClick: onBriefingBookCreate
      }]
    } : null
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell dataId={dataId} history={history} {...props} />
  }

  return (
    <div className={`grid_table ${pageSizeId}_table`}>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage {...getNoContentMessageProps()} />
        : <AgGrid
          domLayout='autoHeight'
          sizeToFit

          // suppress configs
          suppressMovableColumns
          suppressContextMenu

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            sortable: true,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={setColumnDefinition({ columns, isLocked: false })}
          rowData={data}

          // bulk actions
          bulkActions={[{
            id: 'briefing-book-delete',
            icon: 'q4i-trashbin-2pt',
            onSelect: handleBulkDelete
          }]}

          // pagination
          pagination
          paginationProps={{
            pageSizeId,
            forcePage: listOptions.page,
            initialPageSize: listOptions.limit,
            showPageSizeSelection: true,
            total,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: renderCustomCell
          }}

          // event listeners
          onGridReady={handleGridReady}
          onGridSizeChanged={handleGridResize}
          onSortChanged={handleSortChange}
          onRowClicked={preventDefaultRowClick(
            handleRowClick, ['button']
          )}
        />}
    </div>
  )
}

BriefingBookTable.propTypes = propTypes
BriefingBookTable.defaultProps = defaultProps

export default memo(BriefingBookTable)
