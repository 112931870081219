import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Message } from '../../../../../../../components'
import { Select } from '../../../../../../../components'
import { RadioButton } from '../../../../../../../components/shared/form'
import { THEMES } from '../../../../../../../utils/ui'

/**
 * Info Modal
 * @param onClose
 * @return {*}
 * @constructor
 */
const InfoModal = ({ onClose }) => {
  return (
    <Message
      visible={true}
      type='info'
      title='Investment Style'
      message={
        '<p>Active investors actively make investment decisions for a fund to outperform a particular index. By contrast, passive portfolio managers aim to closely imitate a specific market index\'s investment holdings or benchmarks.</p>'
      }
      useHtml={true}
      onClose={onClose}
      renderExitButton={true}
      buttons={[{
        ui: 'citrus',
        label: 'Ok',
        onClick: onClose
      }]}
    />
  )
}

/**
 * Style Filter
 */
class Style extends PureComponent {

  /**
   * Handle Change
   * @param event
   */
  handleChange = (event) => {
    const { value } = event.currentTarget
    const { onChange } = this.props

    onChange(value)
  }

  /**
   * Handle filter update for style multi-select dropdown
   * @param options
   */
  handleStyleChange = (selectedItems, event) => {
    const { onStyleChange, options } = this.props
    const { action, option } = event
    const { value, label, filter } = option

    const selected = (options || []).map((item) => {
      if (item && item.value === value) {
        return action === 'select-option'
          ? { ...item, selected: true }
          : Object.assign({}, { label, value, filter })
      }
      return item
    })

    onStyleChange({ styleOptions: selected })
  }

  /**
   * Show Info Modal
   */
  onInfoClick = () => {
    const { openModal } = this.props
    openModal({
      component: InfoModal
    })
  }

  /**
   * Render
   * @return {*}
   */
  render () {
    const { value, options } = this.props

    return (
      <React.Fragment>
        <div className='field field--full field--radio field--grouped'>
          <label className='field_label'>
            Investment Style
            <span className='button--info button--soft-grey' onClick={this.onInfoClick} />
          </label>
          <RadioButton
            className='radio-button--inline'
            id='investmentStyleAll'
            value='all'
            label='All'
            name='investment_style'
            isSelected={value === 'all'}
            onChange={this.handleChange}
          />
          <RadioButton
            className='radio-button--inline'
            id='investmentStyleActive'
            value='active'
            label='Active'
            name='investment_style'
            isSelected={value === 'active'}
            onChange={this.handleChange}
          />
          <RadioButton
            className='radio-button--inline'
            id='investmentStylePassive'
            value='passive'
            label='Passive'
            name='investment_style'
            isSelected={value === 'passive'}
            onChange={this.handleChange}
          />
        </div>

        <div className='field field--full field--grouped'>
          <Select
            placeholder='Style'
            options={options}
            value={(options || []).filter((item) => item && !!item.selected)}
            isMulti
            isDefaultList
            searchable={false}
            closeMenuOnSelect={false}
            clearable={false}
            theme={THEMES.WHITE}
            onChange={this.handleStyleChange}
          />
        </div>
      </React.Fragment>
    )
  }
}

Style.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  onStyleChange: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
}

export default Style
