export const CONTACT_FILTER_ALL = ''
export const CONTACT_FILTER_FAVORITE = 'favorite'
export const CONTACT_FILTER_HOLDING = 'holding'
export const CONTACT_FILTER_PEER_HOLDING = 'peer_holding'

export const addressBookBulkActions = {
  ADD_TO_BRIEFING_BOOK: 'ADD_TO_BRIEFING_BOOK',
  ADD_TO_LIST: 'ADD_TO_LIST',
  CREATE_ACTIVITY: 'CREATE_ACTIVITY',
  DELETE_CONTACTS: 'DELETE_CONTACTS',
  EMAIL: 'EMAIL'
}

export const contactsGridBulkActions = {
  ADD_TO_BRIEFING_BOOK: 'ADD_TO_BRIEFING_BOOK'
}

export const CONTACT_INSTITUTION_CARD_LAYOUT = {
  COMPACT: 'compact',
  DEFAULT: 'default'
}

export const FUNCTIONS = [
  { label: 'Portfolio Manager-Equities', value: 'Portfolio Manager-Equities' },
  { label: 'Founder', value: 'Founder' },
  { label: 'Analyst-Equity', value: 'Analyst-Equity' },
  { label: 'Chief Executive Officer', value: 'Chief Executive Officer' },
  { label: 'Private Equity Analyst', value: 'Private Equity Analyst' },
  { label: 'Corporate Officer/Principal', value: 'Corporate Officer/Principal' },
  { label: 'Private Equity Investor', value: 'Private Equity Investor' },
  { label: 'Chief Investment Officer', value: 'Chief Investment Officer' },
  { label: 'Portfolio Manager-Fixed Income', value: 'Portfolio Manager-Fixed Income' },
  { label: 'Director/Board Member', value: 'Director/Board Member' },
  { label: 'Analyst-Fixed Income', value: 'Analyst-Fixed Income' },
  { label: 'President', value: 'President' },
  { label: 'Investment Committee Member', value: 'Investment Committee Member' },
  { label: 'Economist', value: 'Economist' },
  { label: 'Chairman', value: 'Chairman' },
  { label: 'Market Strategist', value: 'Market Strategist' },
  { label: 'Director of Research - Equity', value: 'Director of Research - Equity' },
  { label: 'Director of Finance/CFO', value: 'Director of Finance/CFO' },
  { label: 'Trading-Equity', value: 'Trading-Equity' },
  { label: 'Head-Equity Investments', value: 'Head-Equity Investments' },
  { label: 'Sales & Marketing', value: 'Sales & Marketing' },
  { label: 'Compliance Officer', value: 'Compliance Officer' },
  { label: 'Director of Investments', value: 'Director of Investments' },
  { label: 'Chief Operating Officer', value: 'Chief Operating Officer' },
  { label: 'Head-Fixed Income Invts', value: 'Head-Fixed Income Invts' },
  { label: 'Trading-Fixed Income', value: 'Trading-Fixed Income' },
  { label: 'Head of Private Banking', value: 'Head of Private Banking' },
  { label: 'Institutional Sales', value: 'Institutional Sales' },
  { label: 'Independent Dir/Board Member', value: 'Independent Dir/Board Member' },
  { label: 'Consultant / Advisor', value: 'Consultant / Advisor' },
  { label: 'Treasurer', value: 'Treasurer' },
  { label: 'Director of Research - Fxd Inc', value: 'Director of Research - Fxd Inc' },
  { label: 'Comptroller/Controller/Auditor', value: 'Comptroller/Controller/Auditor' },
  { label: 'Corporate Secretary', value: 'Corporate Secretary' },
  { label: 'General Counsel', value: 'General Counsel' },
  { label: 'Investor Relations Contact', value: 'Investor Relations Contact' },
  { label: 'Chief Tech/Sci/R&D Officer', value: 'Chief Tech/Sci/R&D Officer' },
  { label: 'Public Communications Contact', value: 'Public Communications Contact' },
  { label: 'Human Resources Officer', value: 'Human Resources Officer' },
  { label: 'Chief Administrative Officer', value: 'Chief Administrative Officer' },
  { label: 'Director-Back Office Operation', value: 'Director-Back Office Operation' },
  { label: 'Shariah Board Member', value: 'Shariah Board Member' },
  { label: 'Chief Invt Officer-Spezialfond', value: 'Chief Invt Officer-Spezialfond' },
  { label: 'Admin', value: 'Admin' },
  { label: 'Associate Portfolio Manager-Equity', value: 'Associate Portfolio Manager-Equity' },
  { label: 'Director of Research - Fixed Income', value: 'Director of Research - Fixed Income' },
  { label: 'Portfolio Manager - Fixed Income', value: 'Portfolio Manager - Fixed Income' },
  { label: 'Research Associate - Equity', value: 'Research Associate - Equity' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Asset Allocator', value: 'Asset Allocator' },
  { label: 'Buy-Side Corporate Access', value: 'Buy-Side Corporate Access' },
  { label: 'CEO', value: 'CEO' },
  { label: 'CIO - Fixed Income', value: 'CIO - Fixed Income' },
  { label: 'COO', value: 'COO' },
  { label: 'Executive', value: 'Executive' },
  { label: 'Head of Buy-Side Corporate Access', value: 'Head of Buy-Side Corporate Access' },
  { label: 'Head of Government', value: 'Head of Government' },
  { label: 'Head of Trading', value: 'Head of Trading' },
  { label: 'Investment Banking', value: 'Investment Banking' },
  { label: 'Portfolio Manager - Equity', value: 'Portfolio Manager - Equity' },
  { label: 'Portfolio Manager-Equity', value: 'Portfolio Manager-Equity' },
  { label: 'Research Associate - Fixed Income', value: 'Research Associate - Fixed Income' },
  { label: 'Strategist', value: 'Strategist' },
  { label: 'Trader', value: 'Trader' }
]

export const GDPR = `GDPR Forgotten (aka the right to erasure) mean that individuals can request that their
personally identifiable information be deleted from an applicable record.<br>
Any company that is subject to the GDPR has an obligation to delete personal data based on such request.`

export const NEW_CONTACT_INSTITUTION_SEARCH_OPTIONS = {
  entityType: 'institutions',
  page: 1,
  limit: 20
}

export const CONTACT_SUGGESTIONS_SEARCH_OPTIONS = {
  entityType: 'contacts',
  page: 1,
  limit: 5
}
