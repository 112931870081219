import { get, uniq } from 'lodash'
import { ENTITY_TYPE, ENTITY_TYPES } from './entity.const'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

/**
 * Check if requested type is entity type
 */
export const isEntityType = (type) => {
  return ENTITY_TYPES.some((entity) => [].concat(type).includes(entity))
}

/**
 * Returns Entity Category Icon
 * @param category
 * @param size
 * @return {string}
 */
export const getEntityCategoryIcon = (category, size = 2) => {
  const icon = {
    [INSTITUTION]: 'q4i-institution',
    [FUND]: 'q4i-fund',
    [CONTACT]: 'q4i-contact'
  }[category]

  return (icon && size) ? `${icon}-${size}pt` : ''
}

/**
 * Returns Entity Location String
 * @param address
 * @return {string}
 */
export const getEntityLocation = (address) => {
  const hq = (address || []).filter((location) => location).find((location) => location.hq) || {}
  return [hq.city, hq.state_province, hq.country].filter((field) => field).join(', ')
}

/**
 * Returns Entity Location String for EDW Search
 * @param {any[]} addresses
 * @return {string}
 */
export const getEntityLocationEDW = (addresses) => {
  const hq = (addresses || []).filter((location) => location).find((location) => location.hq === 'true' || location.hq === true) || {}
  return [hq.city, hq.stateProvinceCode, hq.countryCode].filter((field) => field).join(', ')
}

/**
 * Get unique addresses
 * @param address
 * @return {Array}
 */
export const getUniqEntityLocations = (address) => {
  return uniq((address || []).filter((location) => location && location.city).map((location) => {
    return [location.city, location.state_province, location.country_name]
      .filter((field) => field)
      .join(', ')
  }))
}

/**
 * Returns Institution Factset Entity Id
 * @param entity
 * @return {string}
 */
export const getInstitutionFactsetEntityId = (entity) => {
  if (entity.entityType.toLowerCase() === CONTACT) {
    return get(entity, 'jobs[0].factset_entity_id', '')
  }
  return entity.factset_entity_id || ''
}

/**
 * Returns a generic entity object for institution, fund or contact
 * @param {Object} entity
 * @param {String} entityType
 */
export const formatEntity = (entity, entityType) => {
  if (!entity || !entityType) {
    return
  }

  entityType = entityType.toLowerCase()

  switch (entityType) {
    case INSTITUTION:
      return {
        ...entity,
        name: entity.institutionName || entity.institution_name || entity.entityName,
        entityId: entity.factset_entity_id || entity.entityId,
        q4EntityId: entity.entityId
      }
    case FUND:
      return {
        ...entity,
        name: entity.fundName || entity.fund_name || entity.entityName,
        entityId: entity.factset_fund_id || entity.entityId,
        q4EntityId: entity.entityId
      }
    case CONTACT:
      return {
        ...entity,
        name: entity.name || entity.full_name,
        entityId: entity._id,
        q4EntityId: entity.entityId
      }
    default:
      return null
  }
}

/**
 * Returns an initial formatted entity(ies) (institution, fund or contact)
 * example of use: Entity Search component props
 * @param {string} entityType
 * @param {Object} entity
 */
export const getEntity = (entityType, entity) => {
  const { id, fullName, fundName, institutionName } = (entity || {})

  switch (entityType) {
    case CONTACT:
      const job = get(entity, 'jobs.0')
      const institutionId = get(entity, 'jobs[0].entityId') || get(entity, 'jobs[0].q4_entity_id')
      const contact = [{
        entityType,
        entityId: id,
        institutionId,
        entityConnection: { fullName }
      }]

      return (institutionId)
        ? [].concat(contact, {
            entityType: ENTITY_TYPE.INSTITUTION,
            entityId: institutionId,
            entityConnection: {
              institutionName: job.institutionName
            }
          })
        : contact

    case FUND:
      return [{
        entityType,
        entityId: id,
        entityConnection: {
          fundName,
          institutionId: entity.institutionId || null
        }
      }]

    case INSTITUTION:
      return [{
        entityType,
        entityId: id,
        entityConnection: { institutionName }
      }]

    default:
      return []
  }
}
