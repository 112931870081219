import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchProfile} from '../../actions/shared/profile.actions';
import {
    fetchPeer,
    peerChanged,
    setDefaultTab,
    fetchCurrentSecurity,
    resetEstimates,
    fetchConsensusDetails,
    setConsensusMetric,
    FETCHED
} from '../../actions/estimates';
import {getFromXigniteToStandard} from '../../utils/stock/stock.util';
import {isServiceEnabled} from '../../utils/user.util';
import { Banner, NoSubscriptionMessage, SectionHeader } from '../../components';
import { SectionTab, Dropdown } from '../../components/shared';
import EstimatesOverview from './overview/estimatesOverview.container';
import EstimatesConsensus from './consensus/estimatesConsensus.container';
import EstimatesBrokerDetail from './brokerDetail/estimatesBrokerDetail.container';
import EstimatesHistorical from './historical/estimatesHistorical.container';
import './estimates.container.css';

class EstimatesContainer extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this._securityHasChanged = false;
    }

    /**
     * ComponentDidMount
     */
    componentDidMount() {
        this._fetchData();
    }

    /**
     * ComponentDidUpdate
     * Check for security and fetch data appropriately
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        if (this.props.securityId !== prevProps.securityId) {
            this._securityHasChanged = true;
            this._fetchData();
        }
    }

    /**
     * Fetch Estimates data
     * @private
     */
    _fetchData = () => {
        const {securityId} = this.props;
        const peerParams = {
            select: ['symbol', 'exchange', 'company_name']
        };


        if (this._securityHasChanged) {
            this.props.peerChanged();
        }

        this.props.fetchCurrentSecurity(securityId);

        this.props.fetchProfile().then(() => {
            const profile = this.props.profile;
            const securities = ((profile && profile._organization && profile._organization.tickers) || [])
                .sort((a) => {
                    if (a.primary) {
                        return -1;
                    }
                    return 1;
                }).map((ticker) => ticker._security).slice(0, 5);

            if (securities.length) {
                peerParams.security = securities;
            }

            this.props.fetchPeer(peerParams).then(() => {
                const currentPeer = this.props.peers.find((eachPeer) => {
                    return eachPeer._security === (this.props.currentPeer && this.props.currentPeer._security);
                });

                if (currentPeer){
                    this._fetchPeerConsensusDetails(currentPeer);
                }

            });

        });
    };

    /**
     *
     * Get Analytics cont to match with the restricted records
     */
    _fetchPeerConsensusDetails = (peer) => {
        return this.props.fetchConsensusDetails({
            metric: 'eps',
            securityId: peer._security
        });
    };

    /**
     * Handle Section Tab change
     * @param tab
     */
    handleTabChange = (tab) => {
        this.props.setDefaultTab(tab.id);
    };

    /**
     * Handle peer dropdown change
     * @param option
     */
    handleDropdownChange = (option) => {
        const peer = {
            _security:  option._security,
            _id: option._id,
            exchange: option.exchange,
            symbol: option.symbol,
            company_name: option.company_name
        };

        this.props.peerChanged(peer);
        this._fetchPeerConsensusDetails(peer);
    };

    /**
     * Generate Dropdown Item Template
     * @param peer
     * @returns {*}
     */
    generatePeerDropdownItem = (peer) => {
        if (!peer) {
            return null;
        }

        return (
            <label className='estimates-page_peer-item'>
                <span className='estimates-page_peer-label estimates-page_peer-label--symbol'>
                    {peer.symbol && peer.symbol.slice(0, 5)}
                </span>
                <span className='estimates-page_peer-label estimates-page_peer-label--exchange'>
                    {getFromXigniteToStandard(peer.exchange)}
                </span>
            </label>
        );
    };

    /**
     * Format dropdown options
     * @param peers
     * @returns {Array}
     */
    getDropdownOptions = (peers) => {
        return (peers || []).map((peer) => {
            return {
                ...peer,
                label: this.generatePeerDropdownItem(peer)
            };
        });
    };

    /**
     * Reset Current Active Peer
     */
    resetPeer = () => {
        const {securityId} = this.props;

        this.props.resetEstimates([securityId]);
        this.props.peerChanged(null);
    };

    /**
     *  redirect to security page
     * @param {string} tickerId
     */
    redirect = (tickerId) => {
        const {history} = this.props;
        tickerId && history.push(`/security/${tickerId}`)
    }

    /**
     * Render
     * Render Estimates container
     * @returns {XML}
     */
    render() {
        const {peers, consensusDetails, currentPeer, defaultTab, profile, currentSecurity} = this.props;
        const security = currentPeer && currentPeer._security;
        const data = consensusDetails[security];
        const currency = (data && data.currency) || 'USD';

        const services = profile && profile.services;
        const isSubscribed = isServiceEnabled('estimates', services || []);

        if (services && services.length && !isSubscribed) {
            return (
                <div className='estimates-page q4-fade-in'>
                    <Banner
                        title='Estimates'
                        icon='q4i-estimates-research-2pt'
                    />
                    <NoSubscriptionMessage image={require('../../resources/images/subscription/estimates.png').default}/>
                </div>
            );
        }

        return (
            <div className='estimates-page q4-fade-in'>
                <Banner
                    title='Estimates'
                    details={(
                        <p className='estimates-page_company-details estimates-page_company-details--banner'
                           onClick={() => this.redirect(currentSecurity.q4_ticker_id)}>
                            <span>{currentSecurity.company_name}</span>
                            <span>{currentSecurity.symbol}</span>
                            <span>{currentSecurity.exchange && getFromXigniteToStandard(currentSecurity.exchange)}</span>
                        </p>
                    )}
                    icon='q4i-estimates-research-2pt'
                />
                <EstimatesOverview/>
                <SectionHeader
                    title='Estimates Detail'
                    annotationText={currentPeer ? (
                        <p className='estimates-page_company-details estimates-page_company-details--section-header'
                           onClick={() => this.redirect(currentPeer.tickerId)}>
                            <span>{currentPeer.company_name}</span>
                            <span>{currentPeer.symbol}</span>
                            <span>{currentPeer.exchange && getFromXigniteToStandard(currentPeer.exchange)}</span>
                        </p>
                    ) : (
                        <p className='estimates-page_company-details estimates-page_company-details--section-header'
                        onClick={() => this.redirect(currentSecurity.q4_ticker_id)}>
                            <span>{currentSecurity.company_name}</span>
                            <span>{currentSecurity.symbol}</span>
                            <span>{currentSecurity.exchange && getFromXigniteToStandard(currentSecurity.exchange)}</span>
                        </p>
                    )}
                >
                    {peers.length && (
                        <React.Fragment>
                            <Dropdown
                                placeholder='Select Peer'
                                theme='pale-grey'
                                width={140}
                                height={30}
                                itemHeight={30}
                                dropdownHeight={155}
                                value={{
                                    ...currentPeer,
                                    label: this.generatePeerDropdownItem(currentPeer)
                                }}
                                options={this.getDropdownOptions(peers)}
                                optionKey={'label'}
                                onSelect={this.handleDropdownChange}
                            />
                            <button
                                className={`button button--pale-grey ${currentPeer ? '' : 'button--disabled'}`}
                                onClick={this.resetPeer}>Reset</button>
                        </React.Fragment>
                    )}
                </SectionHeader>
                    <SectionTab
                        tabs={[{
                            id: 'estimates-consensus',
                            label: 'Consensus',
                            view: <EstimatesConsensus/>
                        }, {
                            id: 'estimates-broker-detail',
                            label: 'Broker Detail',
                            view: <EstimatesBrokerDetail/>
                        }, {
                            id: 'estimates-historical',
                            label: 'Historical',
                            view: <EstimatesHistorical/>
                        }]}
                        forceActiveTab={defaultTab}
                        rememberMounted={false}
                        onTabChange={this.handleTabChange}
                    />
                <h4 className='estimates-page_annotation'>Amount in {currency}</h4>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const profile = state.shared.profile;
    const peers = state.estimates.peers;
    const consensusDetails = state.estimates.consensusDetails;
    const currentSecurity = state.estimates.currentSecurity || {};
    const {defaultTab} = state.estimates.defaultState;

    return {
        profile,
        defaultTab,
        peers: peers.items || [],
        currentPeer: peers.currentPeer,
        consensusDetails,
        currentSecurity,
        loading: profile.status !== FETCHED && peers.status !== FETCHED,
        securityId: props.match.params.securityId
    };
};

const mapDispatchToProps = (dispatch) => ({
    fetchPeer: bindActionCreators(fetchPeer, dispatch),
    fetchProfile: bindActionCreators(fetchProfile, dispatch),
    peerChanged: bindActionCreators(peerChanged, dispatch),
    fetchCurrentSecurity: bindActionCreators(fetchCurrentSecurity, dispatch),
    fetchConsensusDetails: bindActionCreators(fetchConsensusDetails, dispatch),
    resetEstimates: bindActionCreators(resetEstimates, dispatch),
    setDefaultTab: bindActionCreators(setDefaultTab, dispatch),
    setConsensusMetric: bindActionCreators(setConsensusMetric, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EstimatesContainer));
