import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { modalType } from '../../../actions'

// components
import { Banner } from '../../../components'
import { getFromXigniteToStandard, ENTITY_TYPE, HOLDING_CAP_GROUP } from '../../../utils'

import './banner.component.scss'

const { SECURITY } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string.isRequired,
  security: PropTypes.object.isRequired,
  peerId: PropTypes.string,
  openModal: PropTypes.func.isRequired
}

const defaultProps = {
  security: {}
}

/**
 * Banner Title Component
 * @param props
 */
const Title = ({ securityName, symbol, exchange }) => {
  return (
    <div className='security-banner-title'>
      <div className='security-banner-title_name'>{securityName}</div>
      {symbol &&
        <div className='security-banner-title_ticker'>
          {symbol}
          <span className='security-banner-title_ticker--exchange'>
            {getFromXigniteToStandard(exchange)}
          </span>
        </div>}
    </div>
  )
}

/**
 * Banner Detail Component
 * @param props
 */
const Details = ({ industry, capGroup }) => {
  const group = HOLDING_CAP_GROUP.find((option) => option.value === capGroup)

  return (
    <>
      {industry ? <span>{industry.replace(/_/g, '').split(/(?=[A-Z])/).join(' ')}</span> : null}
      <span className={`${industry ? 'divider' : ''}`}>Market Cap: {(group && group.label) || 'Other'}</span>
    </>
  )
}

/**
 * Security Banner Component
 * @param props
 */
function SecurityBanner (props) {
  const { dataIdPrefix, security, legacySecurityId, peerId, onPeerUpdate, openModal } = props
  const { id, securityName, symbol, exchangeCode, industry, capGroup } = security

  const controls = [
    {
      type: 'button',
      dataId: `${dataIdPrefix}EntityUpdate`,
      label: 'Request Update',
      onClick: () => openModal({
        type: modalType.ENTITY_UPDATE_REQUEST_MODAL,
        props: {
          values: {
            entityId: id,
            entityType: SECURITY,
            name: securityName
          }
        }
      })
    },
    {
      dataId: `${dataIdPrefix}BannerUtility`,
      type: 'utility',
      entity: { ...security, legacySecurityId },
      entityType: SECURITY,
      items: [{
        action: 'PEER',
        peerId,
        onSuccess: onPeerUpdate
      }]
    }
  ]

  return (
    <Banner
      dataId={`${dataIdPrefix}Banner`}
      size='medium'
      icon='q4i-security-2pt'
      title={
        <Title
          securityName={securityName}
          symbol={symbol}
          exchange={exchangeCode}
        />
      }
      details={
        <Details
          industry={industry}
          capGroup={capGroup}
        />
      }
      controls={controls}
    />
  )
}

SecurityBanner.propTypes = propTypes
SecurityBanner.defaultProps = defaultProps

export default memo(SecurityBanner)
