import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import {
  getContactPosition,
  getCurrentPosition,
  getContactStrategy
} from '../../../../actions'

import {
  getActiveTicker,
  getClassName,
  getOwnershipType,
  THEMES,
  CONTACT_INSTITUTION_CARD_LAYOUT,
  FLYOUT_ENTITY_TYPES
} from '../../../../utils'

import InstitutionComponent from './institution.component'

import './institution.component.css'

const propTypes = {
  contactId: PropTypes.string.isRequired,
  contactJob: PropTypes.shape({
    factset_entity_id: PropTypes.string
  }).isRequired,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]).isRequired,
  layout: PropTypes.oneOf([
    CONTACT_INSTITUTION_CARD_LAYOUT.COMPACT,
    CONTACT_INSTITUTION_CARD_LAYOUT.DEFAULT
  ]).isRequired,

  onTitleClick: PropTypes.func
}

const defaultProps = {
  theme: THEMES.DARK,
  layout: CONTACT_INSTITUTION_CARD_LAYOUT.DEFAULT
}

/**
 * ContactInstitutionContainer
 * @deprecated
 */
function ContactInstitutionContainer (props) {
  const {
    contactId,
    contactJob,
    theme,
    layout,
    securityId,
    position,
    strategy,
    profile,
    getContactPosition,
    getCurrentPosition,
    getContactStrategy,
    onTitleClick,
    history
  } = props

  const baseClassName = getClassName('contact-institution_overview', [
    { condition: theme, trueClassName: `contact-institution_overview--${theme}` },
    { condition: layout, trueClassName: `contact-institution_overview--${layout}` }
  ])

  useEffect(() => {
    getContactPosition({ contactId, securityId })
  }, [contactId, securityId, getContactPosition])

  useEffect(() => {
    contactJob.factset_entity_id && getCurrentPosition({ factsetEntityId: contactJob.factset_entity_id, securityId })
  }, [contactId, securityId, contactJob, getCurrentPosition])

  useEffect(() => {
    getContactStrategy(contactId)
  }, [contactId, getContactStrategy])

  /**
   * Handles title click event
   * Defaults to history redirect if onTitleClick function is not provided
   * @param entityId
   */
  function handleTitleClick ({ entityId }) {
    onTitleClick
      ? onTitleClick({ entityId, entityType: FLYOUT_ENTITY_TYPES.INSTITUTION })
      : history.push(`/institution/${entityId}`)
  }

  return (
    <div className={baseClassName}>
      <InstitutionComponent
        details={{
          _id: contactId,
          job: {
            entityId: contactJob.factset_entity_id,
            institutionName: contactJob.institution_name,
            institutionType: contactJob.institution_type,
            directPhone: contactJob.direct_telephone,
            ...contactJob
          },
          strategy: (strategy || []).find((strategy) => (strategy.factset_entity_id === contactJob.factset_entity_id))
        }}
        ownershipLabel={getOwnershipType(profile.data).label}
        securityId={securityId}
        position={position}
        theme={theme}
        layout={layout}
        handleTitleClick={handleTitleClick}
      />
    </div>
  )
}

ContactInstitutionContainer.propTypes = propTypes
ContactInstitutionContainer.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const { contact, profile } = state
  const ticker = getActiveTicker(profile.data)

  return {
    profile,
    securityId: ticker && ticker._security,
    contact,
    position: contact.position,
    strategy: contact.strategy.data
  }
}

const mapDispatchToProps = (dispatch) => ({
  getContactPosition: bindActionCreators(getContactPosition, dispatch),
  getCurrentPosition: bindActionCreators(getCurrentPosition, dispatch),
  getContactStrategy: bindActionCreators(getContactStrategy, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactInstitutionContainer))
