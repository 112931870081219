import React from 'react'
import moment from 'moment-timezone'
import { format, getChange, HOLDING_MARKET_GROUP_OPTIONS, intToMillions, formatLocalizedDate } from '../../../../../../utils'
import './cell.component.css'

const EMPTY_PLACEHOLDER = '-'

/**
 * Title Custom Cell Component
 */
const TitleCell = ({ title }) => {
  return (
    <div className='cell_title'>
      {title}
    </div>
  )
}

/**
 * Date Added Custom Cell Component
 */
const AsOfCell = ({ date_time }) => {
  return date_time ? formatLocalizedDate(moment(date_time).utc().format('MM/DD/YY'),'MM/DD/YY') : '-'
}

/**
 * Render cell color depend on number's value.
 * @param item
 * @returns span
 */
const RenderCellDataColor = ({item}) => {
  const roundData = intToMillions(item)
  const rowData = item === undefined  ? EMPTY_PLACEHOLDER : format(roundData)
  const itemClassName = item > 0 ? '--increase' : item < 0 ? '--decrease' : ''

  return (
    <span className={`peer-analysis_table${itemClassName}`}>{rowData}</span>
  )
}

/**
 * @param capMarket
 * @returns string
 */
const FilterMarketCap = (capMarket) => {
  const capMarketData = HOLDING_MARKET_GROUP_OPTIONS.find((option) => option.value === capMarket)
  if (!capMarket || !capMarketData || !capMarketData.label) {
    return 'Other'
  }
  return capMarketData.label
}

/**
 * AgGrid Custom Cell Render Component
 * @param data
 * @param column
 */
const CustomCell = ({ data = {}, column = {} }) => {
  const cellData = data[column.colId]
  switch (column.colId) {
    case 'security_name':
      return <TitleCell title={cellData} />
    case 'security_country_name':
      return cellData
    case 'current':
      return !isNaN(cellData) ? format(cellData, 0) : EMPTY_PLACEHOLDER
    case 'qtr_change':
      return getChange(data).value
    case 'market_value':
      return intToMillions(cellData)
    case 'market_value_change':
      return <RenderCellDataColor item={cellData} />
    case 'cap_group':
      return FilterMarketCap(cellData)
    case 'percent_tso':
    case 'percent_portfolio':
      const value = format(cellData, 2)
      return (value === '0' || !(value)) ? '0.00' : value
    case 'report_date':
      return <AsOfCell date_time={cellData} />
    case 'source':
      return cellData
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
