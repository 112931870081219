import {
  GET_ENTITY_SEARCH_REQUEST,
  GET_ENTITY_SEARCH_SUCCESS,
  GET_ENTITY_SEARCH_FAILURE,
  statusType, ENTITY_SEARCH_CLEAR
} from '../../actions'

const initial = {
  data: [],
  status: statusType.IDLE
}

const entitySearch = (state = initial, action) => {
  switch (action.type) {
    case GET_ENTITY_SEARCH_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_ENTITY_SEARCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: statusType.SUCCESS
      }

    case GET_ENTITY_SEARCH_FAILURE:
      return {
        error: action.error,
        status: statusType.ERROR
      }

    case ENTITY_SEARCH_CLEAR:
      return initial

    default:
      return state
  }
}

export default entitySearch
