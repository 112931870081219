import './all.container.css'
import './all.doc'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useExport } from '../../../../services/csvExport/csvExport.service'
import { useDispatchAction, useTypedSelector } from '../../../../hook'
import { GET_TARGETING_SEARCH, useTargetDeleteQuery, useTargetingSearchQuery } from '../../hook'
import moment from 'moment-timezone'

// actions
import {
  closeModal,
  createTargetingSavedSearch,
  createTargets,
  createToast,
  deleteTargetingSavedSearch,
  getPeers,
  getTargetingSavedSearch,
  modalType,
  openModal,
  setFlyoutDetailsItem,
  resetFlyoutDetailsItem,
  resetPeers,
  resetTargetingFilter,
  resetTargetingSavedSearch,
  resetTargetLocationSuggestions,
  resetTargetSecuritySuggestions,
  searchTargetLocationFactset,
  searchTargetSecurity,
  setTargetingFilterErrors,
  statusType,
  updateSearchTargets,
  updateTargetingFilter,
  updateTargetingSavedSearch,
  createTarget,
  CREATE_TARGET_FAILURE,
  CREATE_TARGETS_FAILURE,
  CREATE_TARGET_SUCCESS,
  REMOVE_TARGET_SUCCESS
} from '../../../../actions'
// components
import TargetingGrid from './grid/grid.component'
import TargetingSidebar from './sidebar/sidebar.component'
import TargetingToolbar from './toolbar/toolbar.component'
import TargetDetail from './detail/detail.component'
import { Flyout } from '../../../../components'
import RouteLeavingPrompt from '../../../../components/routeLeavingPrompt/routeLeavingPrompt.component'
// utils
import { allGridBulkActions, getClassName, isSubscribed, ROUTE_PATH, getActiveTicker } from '../../../../utils'
import { debounce, get, throttle, isEmpty } from 'lodash'

const TOTAL_COUNT_DEFAULT = 100

// #region Global
/**
 * Get selected dropdown options
 * @param options
 * @returns {*}
 */
function getOptions (options) {
  return (options || []).filter((option) => option.selected).map((option) => option.value)
}

/**
 * Get query params from filter
 * @return {*}
 */
function getQueryParams (filter, securityId, _peers) {
  // TODO implement the remaining supported filters for targeting page, intended filters are commented for now
  const {
    target_type: entityType,
    styleOptions,
    turnoverOptions,
    typeOptions,
    exclude_activists: excludeActivists,
    activity_start: activityStart,
    activity_end: activityEnd,
    page,
    limit,
    logged_activity: loggedActivity,
    // peers: filterPeers,
    // ai_only,
    // peer_activity,
    // quality_rating,
    // aum,
    // equity_aum,
    purchasing_power: purchasingPower
    // investment_style,
    // location,
    // owns_security: ownsSecurity
  } = filter

  const style = getOptions(styleOptions)
  const turnover = getOptions(turnoverOptions)
  const type = getOptions(typeOptions)

  // TODO ownership filter to determine if own security for
  // const security = [...(ownsSecurity || []), ...(peers || []).map((peer) => peer._security)]
  // const owns_security = uniq(security.map((x) => x._id))

  const activityDateStart = activityStart ? moment(activityStart).startOf('day').toISOString() : undefined
  const activityDateEnd = activityEnd ? moment(activityEnd).endOf('day').toISOString() : undefined
  const activity = loggedActivity === 'all' ? undefined : loggedActivity === 'true'
  const minPurchasingPower = purchasingPower?.[0] || undefined
  const maxPurchasingPower = purchasingPower?.[1] || undefined

  return {
    entityType,
    securityId,
    page,
    limit,
    style,
    type,
    turnover,
    excludeActivists,
    activity,
    startDate: activityDateStart,
    endDate: activityDateEnd,
    minPurchasingPower,
    maxPurchasingPower
    // location,
    // quality_rating,
    // aum,
    // equity_aum,
    // purchasing_power,
    // investment_style,
    // owns_security,
    // peers: filterPeers,
    // peer_activity,
    // ai_only,
  }
}

const { TARGETING: TARGETING_ROUTE_PATH } = ROUTE_PATH
// #endregion

/**
 * All Targets Tab Container
 * @param {AllTargetsProps} props
 * @returns {JSX.Element}
 */
function AllContainer (props) {
  // #region MapStateToProps
  const filter = useTypedSelector((x) => x.targeting.filter)
  const peers = useTypedSelector((x) => x.peer.data)
  const savedSearch = useTypedSelector((x) => x.targeting.savedSearch)
  const isSearchLocationsLoading = useTypedSelector((x) => x.targeting.filter.locationSuggestionStatus === statusType.IN_PROGRESS)
  const isSearchSecuritiesLoading = useTypedSelector((x) => x.targeting.filter.securitySuggestionStatus === statusType.IN_PROGRESS)
  const aiTargetingSubscription = useTypedSelector((x) => get(isSubscribed(x.profile.data, 'ai_targeting'), 'enabled') === true)
  const region = useTypedSelector((x) => x.profile.data?.region)
  const flyoutDetailsHistory = useTypedSelector((x) => x.targeting.flyout.data)
  // #endregion

  // #region MapDispatchToProps
  const dispatch = useDispatch()
  const handleTargetingFilterUpdate = useDispatchAction(updateTargetingFilter, dispatch)
  const handleTargetLocationSearch = useDispatchAction(searchTargetLocationFactset, dispatch)
  const handleTargetSecuritySearch = useDispatchAction(searchTargetSecurity, dispatch)
  const handleTargetLocationSuggestionsReset = useDispatchAction(resetTargetLocationSuggestions, dispatch)
  const handleTargetSecuritySuggestionsReset = useDispatchAction(resetTargetSecuritySuggestions, dispatch)
  const handlePeersFetch = useDispatchAction(getPeers, dispatch)
  const handlePeersReset = useDispatchAction(resetPeers, dispatch)
  const handleTargetingFilterReset = useDispatchAction(resetTargetingFilter, dispatch)
  const handleTargetingFilterErrorsChange = useDispatchAction(setTargetingFilterErrors, dispatch)
  const handleSavedSearchFetch = useDispatchAction(getTargetingSavedSearch, dispatch)
  const handleSavedSearchCreate = useDispatchAction(createTargetingSavedSearch, dispatch)
  const handleSavedSearchChange = useDispatchAction(updateTargetingSavedSearch, dispatch)
  const handleSavedSearchDelete = useDispatchAction(deleteTargetingSavedSearch, dispatch)
  const handleSavedSearchReset = useDispatchAction(resetTargetingSavedSearch, dispatch)
  const handleModalOpen = useDispatchAction(openModal, dispatch)
  const handleModalClose = useDispatchAction(closeModal, dispatch)
  const handleNotification = useDispatchAction(createToast, dispatch)
  const handleFlyoutDetailsChange = useDispatchAction(setFlyoutDetailsItem, dispatch)
  const handleFlyoutDetailsReset = useDispatchAction(resetFlyoutDetailsItem, dispatch)
  const handleTargetCreate = useDispatchAction(createTarget, dispatch)
  const handleBulkTargetCreate = useDispatchAction(createTargets, dispatch)
  const handleTargetStoreUpdate = useDispatchAction(updateSearchTargets, dispatch)
  // #endregion

  const history = useHistory()

  const [handleTargetDelete] = useTargetDeleteQuery()
  const { onClose } = props

  const [state, setState] = useState({
    sidebarCollapsed: false,
    flyoutCollapsed: true,
    flyoutLeftOffset: 500,
    layoutConfig: {
      flyoutMinWidth: 900,
      flyoutShadowWidth: 10,
      navigationWidth: 50
    },
    blockNavigation: false
  })
  const { sidebarCollapsed, flyoutCollapsed, flyoutLeftOffset, layoutConfig, blockNavigation } = state

  const fetchCount = useRef(0)
  const onHandleDebouncedFlyoutOpen = useRef(debounce(handleFlyoutOpen, 1000, { leading: true, trailing: true }))
  const onHandleThrottledGridResize = useRef(throttle(handleGridResize, 100))

  const baseClass = useMemo(() => getClassName('targeting-page_tabs_tab targeting-page_tabs_tab--all', [
    { condition: !flyoutCollapsed, trueClassName: 'targeting-page_tabs_tab--flyout-open' }
  ]), [flyoutCollapsed])

  const [searchTargets, { loading, error, data, client, refetch }] = useTargetingSearchQuery()
  const targets = get(data, 'targeting.items')

  // Will currently be set to TOTAL_COUNT_DEFAULT as count node is not yet implemented
  const total = get(data, 'targeting.count', TOTAL_COUNT_DEFAULT)

  /**
   * Dispatches an action to load targets
   * @param query
   */
  const fetchTargets = useCallback((query) => {
    refetch(getQueryParams(query || filter, props.securityId))
    fetchCount.current++
  }, [filter, refetch, props.securityId])

  /**
   * componentDidMount
   * Fetch initial targets
   */
  useEffect(() => {
    if (!handlePeersReset || !!fetchCount.current) {
      return
    }
    handlePeersReset().then(() => {
      searchTargets({ variables: getQueryParams(filter, props.securityId) })
      fetchCount.current++
    })
  }, [filter, handlePeersReset, searchTargets, props.securityId])

  useEffect(() => {
    if (!handleFlyoutDetailsReset || !flyoutCollapsed) return
    handleFlyoutDetailsReset()
  }, [flyoutCollapsed, handleFlyoutDetailsReset])

  /**
   * componentWillUnmount
   * Reset aiTargeting filter state
   */
  useEffect(() => {
    if (!handlePeersReset) return
    handlePeersReset()

    if (!handleTargetingFilterReset) return
    handleTargetingFilterReset()
  }, [handlePeersReset, handleTargetingFilterReset])

  /**
   * Search Metro Locations
   * @param query
   */
  function searchLocations (query) {
    handleTargetLocationSearch(query)
  }

  /**
   * Search Ownership Securities
   * @param query
   */
  function searchSecurities (query) {
    handleTargetSecuritySearch(query)
  }

  /**
   * Dispatches an action to update filer state
   * @param updated
   */
  function handleFilterChange (updated) {
    const { page } = updated
    const { page: currentPage } = filter
    if (currentPage === page) {
      handleTargetingFilterUpdate(updated)
    } else {
      handleTargetingFilterUpdate({ ...filter, page })
      fetchTargets({ ...filter, page })
    }
  }

  /**
   * Dispatches an action to load targeting saved search
   */
  function fetchSavedSearch () {
    if (!handleSavedSearchFetch) return

    handleSavedSearchFetch()
  }

  const targetingSearchCSVMapper = (searchData) => {
    // TODO format the CSV
    return searchData
  }

  const { generateExport, exporting } = useExport({
    onError: () => {
      openModal({
        type: modalType.ERROR_MODAL
      })
    }
  })

  /**
   * Sends request to retrieve search data according to filters with no limit
   */
  const handleExport = () => {
    const params = {
      client,
      variables: getQueryParams({ ...filter, limit: 0 }, props.securityId),
      query: GET_TARGETING_SEARCH,
      dataPath: 'data.targeting.items',
      fileName: 'targeting-search-results.csv',
      formatter: targetingSearchCSVMapper
    }
    generateExport(params)
  }

  /**
   * Toggle Sidebar
   */
  function toggleSidebar () {
    setState((current) => ({
      ...current,
      sidebarCollapsed: !sidebarCollapsed,
      flyoutCollapsed: true
    }))
  }

  /**
   * set blockNavigation flag
   */
  function handelBlockNavigation () {
    setState((current) => ({
      ...current,
      blockNavigation: true
    }))
  }
  /**
   * Handles grid resize and updates flyout width
   * @param width
   */
  function handleGridResize (width) {
    setState((current) => ({
      ...current,
      flyoutLeftOffset: width
    }))
  }

  /**
   * Handles AgGrid onRowClick
   * @param data
   */
  function handleRowClick (data) {
    if (!data) {
      return
    }
    const { entityType, entityId } = data
    history.push(`/${entityType}/${entityId}`)
  }

  /**
   * Handle bulk actions based on action id
   * @param actionId
   * @param selectedRows
   */
  async function handleBulkAction (actionId, selectedRows) {
    if (!actionId || !selectedRows || !selectedRows.length) {
      return
    }

    switch (actionId) {
      case allGridBulkActions.ADD_TO_SAVED_TARGETS:
        await handleAddToSavedTargets(selectedRows)
        break
      case allGridBulkActions.ADD_TO_BRIEFING_BOOK:
        handleAddToBriefingBook(selectedRows)
        break
      default:
        break
    }
  }

  /**
   * Add targets to save targets
   * @param selectedRows
   */
  async function handleAddToSavedTargets (selectedRows) {
    if (!selectedRows || !selectedRows.length) {
      return
    }

    const result = await handleBulkTargetCreate({
      targets: selectedRows
    })

    if (result.type === CREATE_TARGETS_FAILURE) {
      handleModalOpen({
        type: modalType.ERROR_MODAL
      })
    }
  }

  /**
   * open Prompt Modal
   * @param nextLocation
   * @param handleConfirmNavigationClick
   */
  function handleOpenPromptModal (nextLocation, handleConfirmNavigationClick) {
    handleModalOpen({
      type: modalType.CONFIRM_NAVIGATION_MODAL,
      props: {
        onClose: handleModalClose,
        handleCancel: handleModalClose,
        title: 'Discard Filtered Targets?',
        message: 'You are about to navigate away from your targeting search, are you sure you want to proceed?',
        handleConfirm: () => handleConfirmNavigationClick(nextLocation)
      }
    })
  }

  /**
   * Redirect btn click action
   * @param nextLocation
   */
  function handleNavigationModalClick (nextLocation, callback) {
    if (!nextLocation) {
      return
    }

    callback()
    handleModalClose()
  }

  /**
   * Add targets to briefing book
   * @param selectedRows
   */
  function handleAddToBriefingBook (selectedRows) {
    if (!selectedRows || !selectedRows.length) {
      return
    }

    handleModalOpen({
      type: modalType.ADD_TO_BRIEFING_BOOK_MODAL,
      props: {
        entities: selectedRows.map(({ entityType, entityId }) => ({
          entityType,
          entityId
        }))
      }
    })
  }

  /**
   * Handles flyout preview
   */
  function handleFlyoutOpen (data) {
    handleFlyoutDetailsChange({
      entityType: data.entityType,
      q4_entity_id: data.entityId
    })

    setState((current) => ({
      ...current,
      flyoutCollapsed: false,
      sidebarCollapsed: true
    }))
  }

  /**
   * Closes flyout preview
   */
  function handleFlyoutClose () {
    setState((current) => ({
      ...current,
      flyoutCollapsed: true
    }))
  }

  /**
   * On Action Completion Failure display an error
   */
  function handleFailure () {
    handleModalOpen({
      type: modalType.ERROR_MODAL
    })
  }

  /**
   * Click event for utility menu which will add/remove _target from flyout and grid
   * @param targetId
   * @param reference
   */
  function handleTargetUtilAction (targetId, reference) {
    const action = targetId ? handleTargetDelete({ variables: { id: targetId } }) : handleTargetCreate({ reference })

    action.then((response) => {
      const { errors, type } = response
      if ([CREATE_TARGET_FAILURE].includes(type) || !isEmpty(errors)) {
        handleFailure()
        return
      }
      onClose && onClose()
      if (type === CREATE_TARGET_SUCCESS) return
      handleTargetStoreUpdate(REMOVE_TARGET_SUCCESS, { ...reference, _id: targetId })
    })
  }

  const noData = !targets || !targets.length
  const flyoutDetailsItem = (flyoutDetailsHistory.length && flyoutDetailsHistory[flyoutDetailsHistory.length - 1]) || null

  return (
    <div className={baseClass}>
      <RouteLeavingPrompt
        navigate={path => history.push(path)}
        page={TARGETING_ROUTE_PATH}
        handleOpenPromptModal={handleOpenPromptModal}
        handleNavigationModalClick={handleNavigationModalClick}
        shouldBlockNavigation={(location) => blockNavigation && location.pathname !== TARGETING_ROUTE_PATH}
      />
      <TargetingSidebar
        collapsed={sidebarCollapsed}
        filter={filter}
        handelBlockNavigation={handelBlockNavigation}
        searchLocations={searchLocations}
        resetLocationSuggestions={handleTargetLocationSuggestionsReset}
        isSearchLocationsLoading={isSearchLocationsLoading}
        searchSecurities={searchSecurities}
        resetSecuritySuggestions={handleTargetSecuritySuggestionsReset}
        isSearchSecuritiesLoading={isSearchSecuritiesLoading}
        peers={peers}
        getPeers={handlePeersFetch}
        resetPeers={handlePeersReset}
        handleFilterChange={handleFilterChange}
        resetFilter={handleTargetingFilterReset}
        setErrors={handleTargetingFilterErrorsChange}
        fetchTargets={fetchTargets}
        savedSearch={savedSearch}
        fetchSavedSearch={fetchSavedSearch}
        createSavedSearch={handleSavedSearchCreate}
        updateSavedSearch={handleSavedSearchChange}
        deleteSavedSearch={handleSavedSearchDelete}
        resetSavedSearch={handleSavedSearchReset}
        aiTargetingSubscription={aiTargetingSubscription}
        region={region}
        openModal={handleModalOpen}
        closeModal={handleModalClose}
        createToast={handleNotification}
      />
      <div className='targeting-page-tab_body'>
        <TargetingToolbar
          collapsed={sidebarCollapsed}
          toggleSidebar={toggleSidebar}
          handleExport={handleExport}
          resultsTotalCount={total}
          noData={noData}
          exporting={exporting}
        />
        <TargetingGrid
          isLoading={loading}
          error={error}
          aiTargetingSubscription={aiTargetingSubscription}
          region={region}
          data={targets}
          total={total}
          filter={filter}
          flyoutCollapsed={flyoutCollapsed}
          flyoutLeftOffset={flyoutLeftOffset}
          layoutConfig={layoutConfig}
          handleFilterChange={handleFilterChange}
          handleGridResize={onHandleThrottledGridResize.current}
          handleFlyoutOpen={onHandleDebouncedFlyoutOpen.current}
          handleRowClick={handleRowClick}
          handleBulkAction={handleBulkAction}
          handleTargetUtilAction={handleTargetUtilAction}
        />
        <Flyout
          collapsed={flyoutCollapsed}
          minWidth={layoutConfig.flyoutMinWidth}
          leftOffset={flyoutLeftOffset}
          handleClose={handleFlyoutClose}
        >
          {flyoutDetailsItem &&
            <TargetDetail
              data={flyoutDetailsItem}
              onClose={handleFlyoutClose}
              handleTargetUtilAction={handleTargetUtilAction}
            />}
        </Flyout>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)

  return {
    tickerId: get(ticker, 'q4_ticker_id', '1234567890'),
    securityId: get(ticker, 'q4_entity_id', '1234567890') // TODO: tmp default value for pre-IPO
  }
}

export default connect(mapStateToProps)(memo(AllContainer))
