import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// actions
import { openModal } from '../../../../actions'

// components

import './saved.container.scss'

const propTypes = {
  variable: PropTypes.string.isRequired
}

const defaultProps = {
  variable: ''
}

/**
 * Saved Container
 * @param props
 */
function SavedContainer (props) {
  return (
    <div>
      <p>Some Text</p>
    </div>
  )
}

SavedContainer.propTypes = propTypes
SavedContainer.defaultProps = defaultProps

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SavedContainer)
