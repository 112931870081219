import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { fetchProfile } from '../../shared/index'
import {
  addQueryParams,
  getOwnershipType,
  MOVER_TYPE,
  ENTITY_TYPE
} from '../../../utils'
import client from '../../../middleware/graphql.middleware'
import gql from 'graphql-tag'
import moment from 'moment-timezone'

export const FETCH_TOP_BUYERS_REQUEST = 'FETCH_TOP_BUYERS_REQUEST'
export const FETCH_TOP_BUYERS_SUCCESS = 'FETCH_TOP_BUYERS_SUCCESS'
export const FETCH_TOP_BUYERS_ERROR = 'FETCH_TOP_BUYERS_ERROR'

export const FETCH_TOP_BUYERS_SNOWFLAKE_SUCCESS = 'FETCH_TOP_BUYERS_SNOWFLAKE_SUCCESS'
export const FETCH_TOP_SELLERS_SNOWFLAKE_SUCCESS = 'FETCH_TOP_SELLERS_SNOWFLAKE_SUCCESS'

export const FETCH_TOP_SELLERS_REQUEST = 'FETCH_TOP_SELLERS_REQUEST'
export const FETCH_TOP_SELLERS_SUCCESS = 'FETCH_TOP_SELLERS_SUCCESS'
export const FETCH_TOP_SELLERS_ERROR = 'FETCH_TOP_SELLERS_ERROR'

export const FETCH_BUYERS_QR_REQUEST = 'FETCH_BUYERS_QR_REQUEST'
export const FETCH_BUYERS_QR_SUCCESS = 'FETCH_BUYERS_QR_SUCCESS'
export const FETCH_BUYERS_QR_ERROR = 'FETCH_BUYERS_QR_ERROR'

export const FETCH_SELLERS_QR_REQUEST = 'FETCH_SELLERS_QR_REQUEST'
export const FETCH_SELLERS_QR_SUCCESS = 'FETCH_SELLERS_QR_SUCCESS'
export const FETCH_SELLERS_QR_ERROR = 'FETCH_SELLERS_QR_ERROR'

const { BUY, SELL } = MOVER_TYPE

const type = {
  [BUY]: {
    request: FETCH_TOP_BUYERS_REQUEST,
    success: FETCH_TOP_BUYERS_SNOWFLAKE_SUCCESS,
    error: FETCH_TOP_BUYERS_ERROR
  },
  [SELL]: {
    request: FETCH_TOP_SELLERS_REQUEST,
    success: FETCH_TOP_SELLERS_SNOWFLAKE_SUCCESS,
    error: FETCH_TOP_SELLERS_ERROR
  }
}

/**
 * Movers query for top buys/sells
 */
const TOP_BUYS_SELLS = gql`
  query Movers(
    $entityId: String!
    $entityType: String!
    $moverType: String!
    $quarterDate: String!
    $source: String!
  ) {
    mover(
      entityId: $entityId
      entityType: $entityType
      moverType: $moverType
      quarterDate: $quarterDate
      source: $source
    ) {
      items {
        securityId
        securityName
        entityName
        entityId
        change
        source
      }
    }
  }`

const fetchBuyersQr = (url) => ({
  [CALL_API]: {
    types: [
      FETCH_BUYERS_QR_REQUEST,
      FETCH_BUYERS_QR_SUCCESS,
      FETCH_BUYERS_QR_ERROR
    ],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
})

const fetchSellersQr = (url) => ({
  [CALL_API]: {
    types: [
      FETCH_SELLERS_QR_REQUEST,
      FETCH_SELLERS_QR_SUCCESS,
      FETCH_SELLERS_QR_ERROR
    ],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
})

/**
 * Get URL by ownership type
 * @param ownershipType - 13f, surveillance, shareholder_id
 * @param type - buyers-sellers, buyers-sellers-qr
 * @param params
 * @return string
 * @private
 */
const _getUrlByOwnershipType = (ownershipType, type, params) => {
  const { securityId } = params
  return {
    '13f': {
      'buyers-sellers': `/ownership/v2/security/${securityId}/movers`,
      'buyers-sellers-qr': `/ownership/v2/security/${securityId}/movers`
    },
    surveillance: {
      'buyers-sellers': '/oxford/report',
      'buyers-sellers-qr': '/oxford/analysis/stats'
    },
    shareholder_id: {
      'buyers-sellers': '/shareholderid',
      'buyers-sellers-qr': '/ownership/company'
    }
  }[ownershipType][type]
}

/**
 * Fetch Top Buys/Sells from Snowflake
 * @param moverType
 * @param entityId
 * @param source
 * @param quarterDate
 * @param dispatch
 */
const fetchTopBuysSellsSnowflake = ({ moverType, entityId, source, quarterDate }, dispatch) => {
  const actions = type[moverType]

  dispatch({ type: actions.request })
  return client
    .query({
      query: TOP_BUYS_SELLS,
      variables: {
        entityId,
        entityType: ENTITY_TYPE.SECURITY,
        moverType,
        quarterDate,
        source
      }
    })
    .then((data) => {
      dispatch({ payload: data, type: actions.success })
    })
    .catch((error) => {
      dispatch({ error, type: actions.error })
    })
}

export const loadTopBuyersSellers = (params) => (dispatch, getState) => {
  return dispatch(fetchProfile()).then(() => {
    const state = getState()
    const source = getOwnershipType(state.shared.profile).value
    const entityId = state.dashboard.dashboardState.tickerId
    const quarterDate = params.startDate || moment().subtract(3, 'month').toISOString()

    return Promise.all([
      fetchTopBuysSellsSnowflake({ moverType: BUY, entityId, source, quarterDate }, dispatch),
      fetchTopBuysSellsSnowflake({ moverType: SELL, entityId, source, quarterDate }, dispatch)
    ])
  })
}

export const loadTopBuyersSellersQr = (params) => (dispatch, getState) => {
  return dispatch(fetchProfile()).then(() => {
    const state = getState()
    const ownershipType = getOwnershipType(state.shared.profile).name
    const securityId = params.securityId || state.dashboard.dashboardState.securityId
    const baseUrl = _getUrlByOwnershipType(ownershipType, 'buyers-sellers-qr', params)
    params = { ...params, securityId }

    if (ownershipType === 'surveillance') {
      const url = addQueryParams(baseUrl, {
        ...params,
        report_type: 'weekly_buyers_sellers_report'
      })
      dispatch(fetchBuyersQr(url))
      dispatch(fetchSellersQr(url))
    } else {
      const buyerUrl = addQueryParams(`${baseUrl}/buyers/stats`, params)
      const sellerUrl = addQueryParams(`${baseUrl}/sellers/stats`, params)

      dispatch(fetchBuyersQr(buyerUrl))
      dispatch(fetchSellersQr(sellerUrl))
    }
  })
}
