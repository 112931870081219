import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { config } from '../../../config'
import queryString from 'query-string'
import PropTypes from 'prop-types'

// actions
import {
  getActivityList,
  removeActivities,
  resetActivity,
  resetActivityList,
  openModal,
  closeModal,
  statusType
} from '../../../actions'

import ActivityToolbar from './toolbar/toolbar.component'
import ActivityTable from './table/table.component'
import { Spinner } from '../../../components'

import {
  getActivityAttendee,
  getClassName,
  getPageSizeFromStorage,
  CORPORATE_PARTICIPANT,
  ENTITY_INSTITUTION,
  ENTITY_FUND,
  ENTITY_CONTACT,
  THEMES
} from '../../../utils'

import './grid.container.css'
import { getActiveToken } from '../../../utils/auth/auth.util'

const PAGE_SIZE_ID = 'activity-grid'

/**
 * Activity Grid
 */
class ActivityGridOld extends PureComponent {
  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.initialFilters = {
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10,
      page: 1,
      search: '',
      sort: {
        property: 'start',
        direction: 'desc'
      },
      category: 'all'
    }

    this.state = {
      filters: this.initialFilters
    }
  }

  /**
   * Component Did Mount
   */
  componentDidMount () {
    this.getActivities()
  }

  /**
   * Component Did Update
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    const { form, entityId } = this.props

    if (prevProps.form.status !== form.status && form.status === statusType.SUCCESS) {
      this.getActivities()
    } else if (entityId !== prevProps.entityId) {
      this.resetFilters()
    }
  }

  /**
   * Component Will Unmount
   */
  componentWillUnmount () {
    this.props.resetActivityList()
  }

  /**
   * Get Activities
   * @param query
   */
  async getActivities () {
    const query = await this.getQueryParams()
    const { entityId, fetchActivities } = this.props
    entityId && fetchActivities(query)
  }

  /**
   * Get query params
   * @param params
   * @returns {Promise<{*}>}
   */
  async getQueryParams (params = {}) {
    const { entityType, entityId, showChildren, token } = this.props
    const filters = { ...this.state.filters, showChildren };

    (entityType === CORPORATE_PARTICIPANT) ? filters.participants = [entityId] : filters.entityId = [entityId]
    filters.sort = filters.sort ? JSON.stringify([filters.sort]) : delete filters.sort

    const exportToken = await getActiveToken(token)

    if (params.export && exportToken) {
      delete filters.page
      delete filters.limit
      filters.token = exportToken
    }
    return filters
  }

  /**
   * Reset filters and re-fetch activities
   */
  resetFilters () {
    const filters = {
      ...this.initialFilters,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10
    }
    this.setState({
      filters
    }, () => {
      this.getActivities()
    })
  }

  /**
   * Filter activities based on changes in filter state params
   * @param filters
   */
  handleFilterChange (filters) {
    this.setState(
      { filters: { ...this.state.filters, ...filters } },
      () => this.getActivities()
    )
  }

  /**
   * Open create new activity modal
   * @param filters
   */
  handleActivityCreate (filters) {
    const { entityType, entity, openModal } = this.props

    openModal({
      type: 'ACTIVITY_MODAL',
      props: {
        isParentOptionHidden: (entityType !== CORPORATE_PARTICIPANT),
        values: (entityType !== CORPORATE_PARTICIPANT)
          ? getActivityAttendee(entityType, entity)
          : { participants: [entity] }
      }
    })
  }

  /**
   * Handle activity bulk delete
   * @param ids
   */
  async handleActivityRemove (ids) {
    const { resetActivity, removeActivities } = this.props

    await removeActivities(ids)
    resetActivity()

    this.handleFilterChange({ page: 1 })
  }

  /**
   * Handle grid csv export
   */
  async handleExport () {
    const query = await this.getQueryParams({ export: true })
    window.open(`${config.apiUrl}/crm/activity/export?${queryString.stringify(query)}`, '_self')
  }

  /**
   * Render Activity Grid
   */
  render () {
    const {
      toolbarTheme, toolTheme, className, isEntity, history, list: { data, total, status, counts, initialLoading }, openModal, closeModal,
      handleExpandMenuItemClick
    } = this.props
    const { filters } = this.state
    return (
      <div className={getClassName('activity-grid--old', [
        { condition: className, trueClassName: className }
      ])}
      >
        {(status === statusType.IN_PROGRESS) && <Spinner mask theme={THEMES.RAIN} />}
        <ActivityToolbar
          toolbarTheme={toolbarTheme}
          toolTheme={toolTheme}
          noData={!data || !data.length}
          isEntity={isEntity}
          counts={counts}
          searchValue={filters.search}
          category={filters.category}
          handleActivityCreate={this.handleActivityCreate.bind(this)}
          handleFilterChange={this.handleFilterChange.bind(this)}
          handleExport={this.handleExport.bind(this)} />
        <ActivityTable
          pageSizeId={PAGE_SIZE_ID}
          isGridReady={!initialLoading}
          filters={filters}
          data={data}
          total={total}
          handleFilterChange={this.handleFilterChange.bind(this)}
          handleDeleteSelection={this.handleActivityRemove.bind(this)}
          openModal={openModal}
          closeModal={closeModal}
          history={history}
          handleExpandMenuItemClick={handleExpandMenuItemClick}
        />
      </div>
    )
  }
}

ActivityGridOld.propTypes = {
  className: PropTypes.string,
  toolbarTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toolTheme: PropTypes.oneOf([THEMES.Q4_BLUE, THEMES.INK]),
  entityType: PropTypes.oneOf([CORPORATE_PARTICIPANT, ENTITY_CONTACT, ENTITY_FUND, ENTITY_INSTITUTION]).isRequired,
  entityId: PropTypes.string.isRequired,
  entity: PropTypes.object,
  isEntity: PropTypes.bool,
  showChildren: PropTypes.bool
}

ActivityGridOld.defaultProps = {
  toolTheme: THEMES.INK,
  toolbarTheme: THEMES.Q4_BLUE,
  isEntity: false,
  showChildren: false
}

const mapStateToProps = (state) => ({
  form: state.activity.activityForm,
  list: state.activity.activityList,
  token: state.token
})

const mapDispatchToProps = (dispatch) => ({
  fetchActivities: bindActionCreators(getActivityList, dispatch),
  removeActivities: bindActionCreators(removeActivities, dispatch),
  resetActivity: bindActionCreators(resetActivity, dispatch),
  resetActivityList: bindActionCreators(resetActivityList, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActivityGridOld))
