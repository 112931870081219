import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import { Card } from '../../index'
import { defaultIfEmpty, format, inMillions, ENTITY_TYPE } from '../../../utils'
import { getLocalizedCurrency } from '../../../utils/currencyLocalization'

const { FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string,
    position: PropTypes.number,
    purchasingPower: PropTypes.number,
    aum: PropTypes.number,
    equityAUM: PropTypes.number
  }).isRequired,
  entityType: PropTypes.oneOf([FUND, INSTITUTION]).isRequired,
  ownershipType: PropTypes.string.isRequired
}

const defaultProps = {
  isLoading: false,
  entity: {},
  entityType: INSTITUTION,
  ownershipType: '13F'
}

/**
 * Attribute Component
 */
function Attribute ({ label, value }) {
  return (
    <Grid item xs={5} className='card_section'>
      <h2 className='card_section-subtitle'>{label}</h2>
      <div className='card_section-detail'>{defaultIfEmpty(value)}</div>
    </Grid>
  )
}

/**
 * Entity Attributes Card Component
 * @param props
 */
function AttributeCard (props) {
  const { dataId, theme, isLoading, entity, entityType, ownershipType } = props
  const { position, purchasingPower, aum, equityAUM } = entity

  const attributes = [
    { label: 'Purchasing Power', value: format(purchasingPower) },
    { label: `Current Position (${ownershipType})`, value: format(position) },
    {
      label: `AUM (MM ${getLocalizedCurrency()})`,
      value: entityType === INSTITUTION ? format(aum, 0) : inMillions(aum, 0)
    },
    {
      label: `E AUM (MM ${getLocalizedCurrency()})`,
      value: entityType === INSTITUTION ? format(equityAUM, 0) : inMillions(equityAUM, 0)
    }
  ]

  return (
    <Card
      dataId={dataId}
      theme={theme}
      isLoading={isLoading}
      title='Attributes'
    >
      <Grid container spacing={3}>
        {attributes.map((attribute) => {
          return <Attribute key={attribute.label} {...attribute} />
        })}
      </Grid>
    </Card>
  )
}

AttributeCard.propTypes = propTypes
AttributeCard.defaultProps = defaultProps

export default memo(AttributeCard)
