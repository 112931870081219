import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// actions
import { useBriefingBookCreateQuery, useBriefingBookUpdateQuery } from '../hook'
import { openModal, closeModal, modalType } from '../../../actions'

// components
import EditModal from './edit.component'
import { BRIEFING_BOOK_TYPE } from '../../../utils'
import { get } from 'lodash'

const { BRIEFING_BOOK, TEARSHEET } = BRIEFING_BOOK_TYPE

const propTypes = {
  dataId: PropTypes.string,
  type: PropTypes.oneOf([BRIEFING_BOOK, TEARSHEET]).isRequired,
  book: PropTypes.object,
  onSaveSuccess: PropTypes.func
}

const defaultProps = {
  dataId: 'BriefingBookEdit',
  type: BRIEFING_BOOK,
  book: {}
}

/**
 * Briefing Book Create/Edit Entity
 * @param props
 */
function BriefingBookEdit (props) {
  const { dataId, type, book, openModal, closeModal, onSaveSuccess } = props
  const { id } = book

  const [handleCreate, { loading: createProgress }] = useBriefingBookCreateQuery()
  const [handleUpdate, { loading: updateProgress }] = useBriefingBookUpdateQuery()
  const handleBriefingBookSave = ({ title, entity }) => {
    const variables = { title, entity }
    const request = id
      ? handleUpdate({ variables: { id, ...variables } })
      : handleCreate({ variables: { ...variables, type } })

    request.then((response) => get(response, 'errors')
      ? handleFailure()
      : handleSuccess(get(response, 'data.briefingBook', {}))
    )
  }

  /**
   * On Action Success
   * @param response
   */
  function handleSuccess (response) {
    const type = response.update ? 'update' : 'create'

    onSaveSuccess && onSaveSuccess(type)
    closeModal()
  }

  /**
   * On Action Completion Failure display an error
   */
  function handleFailure () {
    openModal({
      type: modalType.ERROR_MODAL
    })
  }

  return (
    <EditModal
      dataId={dataId}
      book={book}
      loading={[createProgress, updateProgress].some((item) => !!item)}
      onSave={handleBriefingBookSave}
      onClose={closeModal}
    />
  )
}

BriefingBookEdit.propTypes = propTypes
BriefingBookEdit.defaultProps = defaultProps

const mapDispatchToProps = (dispatch) => ({
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch)
})

export default connect(null, mapDispatchToProps)(BriefingBookEdit)
