import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {getClassName} from '../../../utils/ui/ui.util';
import './editableText.component.css';

class EditableText extends PureComponent {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            value: props.value ? props.value : '',
            storedValue: props.value ? props.value : '',
            isEditing: false
        };

        this.componentReference = React.createRef();
        this.valueReference = React.createRef();
        this.inputReference = React.createRef();
    }

    /**
     * ComponentDidMount
     */
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.handleKeyDown);
    }

    /**
     * ComponentDidUpdate
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        const {value} = this.props;
        const {isEditing} = this.state;

        const newValue = value !== prevProps.value;

        if (!isEditing && newValue) {
            this.setState({
                value
            });
        }
    }

    /**
     * ComponentWillUnmount
     */
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    /**
     * Handle input change
     * @param event
     */
    handleInputChange = (event) => {
        const {onQueryChange} = this.props;
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (this.isValid(value) && onQueryChange) {
            onQueryChange(value);
        }
    };

    /**
     * Handle edit click
     */
    handleEdit = () => {
        const {shouldDisposeValue} = this.props;
        const {value} = this.state;

        this.setState({
            isEditing: true,
            value: shouldDisposeValue ? '' : value,
            storedValue: value
        }, () => {
            this.handleRefocusInput();
        });
    };

    /**
     * Handle text or checkbox input change
     * @param event
     */
    handleInputFocus = (event) => {
        const {onInputFocus} = this.props;

        const value = event.target.value;
        event.target.value = '';
        event.target.value = value;

        onInputFocus && onInputFocus();
    };

    /**
     * Handle text input blur
     */
    handleInputBlur = () => {
        const {onInputBlur} = this.props;
        onInputBlur && onInputBlur();
    };

    /**
     * Handle refocusing of text input
     */
    handleRefocusInput = () => {
        const Input = this.inputReference && this.inputReference.current;
        Input && Input.focus();
    };

    /**
     * Handle EditableText Cancel
     * @param value
     */
    handleCancel = (value) => {
        const {storedValue} = this.state;
        const {onInputBlur, onCancel} = this.props;

        this.setState({
            isEditing: false,
            value: storedValue
        });

        onInputBlur && onInputBlur();
        onCancel && onCancel(value);
    };

    /**
     * Handle EditableText Submit
     */
    handleSubmit = () => {
        const {value} = this.state;

        if (!this.isValid(value)) {
            return;
        }

        const {onInputBlur, onSubmit} = this.props;

        this.setState({
            isEditing: false,
            storedValue: value
        });

        onInputBlur && onInputBlur();
        onSubmit && onSubmit(value);
    };

    /**
     * If clicked on outside of element, exit edit mode
     */
    handleClickOutside = (event) => {
        const Component = this.componentReference && this.componentReference.current;

        if (Component && !Component.contains(event.target)) {
            this.state.isEditing && this.handleSubmit();
        }
    };

    /**
     * Handle keydown event
     * @param event
     */
    handleKeyDown = (event) => {
        const {isEditing} = this.state;

        if (!isEditing) {
            return;
        }

        event = event || window.event;

        switch(event.key) {
            case 'Escape':
            case 'Esc':
                return this.componentReference && this.handleCancel();
            case 'Enter':
                event.preventDefault();
                return this.componentReference && this.handleSubmit();
            default:
                return;
        }
    };

    /**
     * Test to see that the value is valid for submission
     * @param value
     */
    isValid = (value) => {
        const {minLength} = this.props;
        return value.trim().length >= minLength;
    };

    /**
     * Get component styles
     * @returns {Object}
     */
    getStyles = () => {
        const {invisible, inputWidth, inputHeight, iconSize, zIndex} = this.props;

        return {
            base: {
                zIndex
            },
            input: {
                width: !invisible && inputWidth,
                height: inputHeight,
                lineHeight: invisible && inputHeight ? `${inputHeight}px` : null
            },
            button: {
                height: inputHeight,
                width: inputHeight,
                lineHeight: inputHeight ? `${inputHeight}px` : null
            },
            icon: {
                fontSize: iconSize,
                lineHeight: invisible && inputHeight ?`${inputHeight}px` : null
            }
        };
    };

    /**
     * Render the editable portion of the EditableText component
     * @param value
     * @param invisible
     * @returns {XML}
     */
    renderEditableView = (value, invisible) => {
        const {fieldTheme, buttonTheme, placeholder, maxLength} = this.props;

        const renderValue = value.replace(/ /g, '\u00a0');
        const styles = this.getStyles();
        const buttonClassName = getClassName('button button--square', [
            {condition: buttonTheme, trueClassName: `button--${buttonTheme || 'rain'}`, falseClassName: 'button--rain'},
            {condition: this.isValid(value), falseClassName: 'button--disabled'}
        ]);

        return (
            <div className='editable-text_edit-container'>
                {invisible ? (
                    <React.Fragment>
                        <span className='editable-text_value editable-text_value--hidden'>{renderValue}</span>
                        <input
                            style={styles.input}
                            ref={this.inputReference}
                            className='editable-text_input'
                            type='text'
                            name='value'
                            value={value}
                            placeholder={placeholder}
                            maxLength={maxLength}
                            onFocus={this.handleInputFocus}
                            onBlur={this.handleInputBlur}
                            onChange={this.handleInputChange}
                        />
                    </React.Fragment>
                ) : (
                    <div className={`field field--text ${fieldTheme ? `field--${fieldTheme}` : ''}`}>
                        <input
                            style={styles.input}
                            ref={this.inputReference}
                            className='field_input'
                            type='text'
                            name='value'
                            value={value}
                            placeholder={placeholder}
                            maxLength={maxLength}
                            onFocus={this.handleInputFocus}
                            onBlur={this.handleInputBlur}
                            onChange={this.handleInputChange}
                        />
                        <button style={styles.button} className={buttonClassName} onClick={this.handleSubmit}>
                            <i className='button_icon q4i-checkmark-4pt'/>
                        </button>
                    </div>
                )}
            </div>
        );
    };

    /**
     * Render Read More
     * @returns {*}
     */
    render() {
        const {className, theme, invisible, customValueRender} = this.props;
        const {value, isEditing} = this.state;
        const styles = this.getStyles();

        const baseClassName = getClassName('editable-text', [
            {condition: className, trueClassName: className},
            {condition: isEditing, trueClassName: 'editable-text--editing'},
            {condition: theme, trueClassName: `editable-text--${theme}`},
            {condition: invisible, trueClassName: 'editable-text--invisible'},
            {condition: value, falseClassName: 'editable-text--empty'}
        ]);

        return (
            <div className={baseClassName} style={styles.base} ref={this.componentReference}>
                {isEditing ? this.renderEditableView(value, invisible) : customValueRender ? (
                    <div className='editable-text_value' onDoubleClick={this.handleEdit}>
                        {customValueRender(value)}
                    </div>
                ) : (
                    <span className='editable-text_value' ref={this.valueReference} onClick={this.handleEdit}>
                        {value}
                    </span>
                )}
                {!isEditing && (
                    <div style={styles.icon} className='editable-text_edit-toggle'>
                        <i className='q4i-edit-4pt' onClick={this.handleEdit}/>
                    </div>
                )}
            </div>
        );
    }
}

EditableText.propTypes = {
    /**
     * A custom className to add to the component
     */
    className: PropTypes.string,

    /**
     * Used to paint the component using a specific theme
     */
    theme: PropTypes.string,

    /**
     * Used to paint the component in such a way that the input is not noticeable
     */
    invisible: PropTypes.bool,

    /**
     * Used to paint the component's input field (edit mode) using a specific theme
     */
    fieldTheme: PropTypes.string,

    /**
     * Used to paint the component's button (edit mode) using a specific theme
     */
    buttonTheme: PropTypes.string,

    /**
     * The width of the component's input
     */
    inputWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * The height of the component's input
     */
    inputHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * The font-size of the component's toggle icons
     */
    iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    /**
     * The z-index of the component
     */
    zIndex: PropTypes.number,

    /**
     * The active value of the component
     */
    value: PropTypes.string.isRequired,

    /**
     * Used to determine whether or not the value should be cleared upon input focus
     */
    shouldDisposeValue: PropTypes.bool,

    /**
     * A method used to provide custom rendering for the component's active value
     */
    customValueRender: PropTypes.func,

    /**
     * Used to determine the minimum required character length for the active value
     */
    minLength: PropTypes.number,

    /**
     * Used to determine the maxLength of the component's input
     */
    maxLength: PropTypes.number,

    /**
     * Placeholder text for the component's input
     */
    placeholder: PropTypes.string,

    /**
     * A callback for when the user triggers onFocus within the component's input
     */
    onInputFocus: PropTypes.func,

    /**
     * A callback for when the user triggers onBlur within the component's input
     */
    onInputBlur: PropTypes.func,

    /**
     * A callback for when the user triggers onChange within the component's input
     * Note: this is affected by minLength
     */
    onQueryChange: PropTypes.func,

    /**
     * A callback for when the user submits the active value
     */
    onSubmit: PropTypes.func
};

EditableText.defaultProps = {
    value: '',
    placeholder: 'Enter title',
    minLength: 1,
    maxLength: 60
};

export default EditableText;