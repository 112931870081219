import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { DOWNLOAD_API } from '../../middleware/download.middleware'
import { NOTIFICATION, GENERATE_STATUS } from '../../utils'

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE'

export const GET_DOWNLOAD_FILE_REQUEST = 'GET_DOWNLOAD_FILE_REQUEST'
export const GET_DOWNLOAD_FILE_SUCCESS = 'GET_DOWNLOAD_FILE_SUCCESS'
export const GET_DOWNLOAD_FILE_FAILURE = 'GET_DOWNLOAD_FILE_FAILURE'

export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST'
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS'
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE'

export const APPEND_NEW_NOTIFICATION = 'APPEND_NEW_NOTIFICATION'
export const UPDATE_PROGRESS_NOTIFICATION = 'UPDATE_PROGRESS_NOTIFICATION'

export const DOWNLOAD_PROGRESS = 'DOWNLOAD_PROGRESS'
export const DOWNLOAD_COMPLETE = 'DOWNLOAD_COMPLETE'
export const DOWNLOAD_ERROR = 'DOWNLOAD_ERROR'

/**
 * Get notifications
 * @returns {{[p: string]: *}}
 * @private
 */
const _getNotifications = () => ({
  [CALL_API]: {
    types: [
      GET_NOTIFICATIONS_REQUEST,
      GET_NOTIFICATIONS_SUCCESS,
      GET_NOTIFICATIONS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: '/notification'
  }
})

/**
 * Download notification file
 * @param url
 * @param contentType
 * @param file
 * @returns {{[p: string]: *}}
 * @private
 */
const _downloadFile = ({ url, contentType, file }) => ({
  [DOWNLOAD_API]: {
    types: [
      GET_DOWNLOAD_FILE_REQUEST,
      GET_DOWNLOAD_FILE_SUCCESS,
      GET_DOWNLOAD_FILE_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: url,
    options: {
      headers: {
        'Content-Type': contentType
      }
    },
    payload: { file }
  }
})

/**
 * Update notification
 * @param payload
 * @returns {{[p: string]: *}}
 * @private
 */
const _updateNotification = (payload) => ({
  [CALL_API]: {
    types: [
      UPDATE_NOTIFICATION_REQUEST,
      UPDATE_NOTIFICATION_SUCCESS,
      UPDATE_NOTIFICATION_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/notification',
    payload
  }
})

/**
 * Get Notifications
 * @returns {function(*): *}
 */
export const getNotifications = () => (dispatch) => {
  return dispatch(_getNotifications())
}

/**
 * Append Notification
 * @param notification
 * @returns {function(*): *}
 */
export const appendNewNotification = (notification) => (dispatch) => {
  const { notificationType, payload, timestamp } = notification

  switch (notificationType) {
    case NOTIFICATION.GENERATE_COMPLETE:
      const status = window.sessionStorage.getItem(`download-${payload.id}`)

      if (status === GENERATE_STATUS.PENDING) {
        window.sessionStorage.setItem(`download-${payload.id}`, GENERATE_STATUS.COMPLETE)
        _getNotificationAction(payload, dispatch)
      }

      return dispatch({
        type: DOWNLOAD_COMPLETE,
        payload: { ...payload, timestamp }
      })

    case NOTIFICATION.GENERATE_PROGRESS:
      return payload && payload.progress === 100
        ? dispatch({
          type: DOWNLOAD_COMPLETE,
          payload: { ...payload, timestamp }
        })
        : dispatch({
          type: DOWNLOAD_PROGRESS,
          payload: { ...payload, timestamp }
        })

    case NOTIFICATION.GENERATE_ERROR:
      return dispatch({
        type: DOWNLOAD_ERROR,
        payload: { ...payload, timestamp }
      })

    default:
      return dispatch({
        type: APPEND_NEW_NOTIFICATION,
        payload: notification
      })
  }
}

/**
 * Download notification file
 * @param url
 * @param name
 * @param type
 * @returns {Function}
 */
export const downloadFile = (url, name, type) => (dispatch) => {
  const contentTypes = {
    csv: 'text/csv',
    pdf: 'application/pdf'
  }

  dispatch(_downloadFile({
    url,
    contentType: contentTypes[type],
    file: {
      name,
      type
    }
  }))
}

/**
 * Mark read Notifications
 * @param ids
 * @params isLegacy
 * @returns {Function}
 */
export const markReadNotifications = (ids, isLegacy) => (dispatch) => {
  if (isLegacy) {
    dispatch(_updateNotification({ ids }))
  } else {
    dispatch({ type: UPDATE_PROGRESS_NOTIFICATION })
  }
}

/**
 * Get notification action based on type
 * @param payload
 * @param dispatch
 * @private
 */
const _getNotificationAction = (payload, dispatch) => {
  const { requestId, type, title, fileType } = (payload || {})

  switch (type) {
    case 'briefingbook':
      const contentTypes = {
        csv: 'text/csv',
        pdf: 'application/pdf'
      }

      dispatch(_downloadFile({
        url: `/tearsheet/download?requestId=${requestId}`,
        contentType: contentTypes[type],
        file: { name: title, type: fileType }
      }))
      break
    default:
      break
  }
}
