import {
  OPEN_MODAL,
  CLOSE_MODAL,
  CLOSE_ALL_MODALS
} from '../../actions'

const initial = {
  modals: []
}

/**
 * Redux state for modal visibility
 *
 * Note: this is required as modals need to be created in root.container until sencha
 * migration is fully completed, after which modals can be created from any react container or component
 *
 * @param state
 * @param action
 */
const modal = (state = initial, action) => {
  const { payload } = action

  switch (action.type) {
    case OPEN_MODAL:
      return {
        modals: [...state.modals, payload]
      }

    case CLOSE_MODAL:
      return {
        modals: state.modals.slice(0, -1)
      }

    case CLOSE_ALL_MODALS:
      return initial

    default:
      return state
  }
}

export default modal
