import React, { memo } from 'react'
import PropTypes from 'prop-types'

import './toolbar.component.scss'

// components
import { Toolbar, ToolbarRow, Button } from '../../../../components'
import { THEMES } from '../../../../utils'
import { SectionSearch } from '../../../../components/shared'

const propTypes = {
  collapsed: PropTypes.bool.isRequired,
  dataId: PropTypes.string,
  toolbarTheme: PropTypes.oneOf([THEMES.RAIN, THEMES.STEEL]),
  toolTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  toggleFilterSidebar: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func
}

const defaultProps = {
  toolbarTheme: THEMES.RAIN,
  toolTheme: THEMES.STEEL
}

/**
 * Events & Transcripts Toolbar Component
 * @param props
 */
function EventTranscriptToolbar (props) {
  const { toolTheme, toolbarTheme, collapsed, toggleFilterSidebar, searchText, setSearchText } = props

  return (
    <Toolbar theme={toolbarTheme}>
      <ToolbarRow left>
        <div className='toolbar_group events_toolbar'>
          <Button
            active={!collapsed}
            className='eventTranscripts-toolbar_filter-button'
            theme={toolTheme}
            label='Filter'
            icon='q4i-funnel-filter-4pt'
            onClick={toggleFilterSidebar}
          />
          <SectionSearch
            dataId='eventSearch'
            theme={toolTheme}
            value={searchText}
            onQueryChange={setSearchText}
            onClear={() => setSearchText('')}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

EventTranscriptToolbar.propTypes = propTypes
EventTranscriptToolbar.defaultProps = defaultProps

export default memo(EventTranscriptToolbar)
