import React, { memo } from 'react'
import PropTypes from 'prop-types'

import './error.component.scss'

const propTypes = {
  message: PropTypes.string
}

const defaultProps = {}

/**
 * Field Error Component
 * @param message
 */
function Error ({ message }) {
  return ((message && message.length)
    ? <div className='field-error'>
      <i className='field-error_icon q4i-warning-4pt' />
      <div className='field-error_message'>{message}</div>
    </div>
    : null)
}

Error.propTypes = propTypes
Error.defaultProps = defaultProps

export default memo(Error)
