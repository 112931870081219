import React, { PureComponent } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// actions
import {
  fetchCorporateParticipant,
  removeCorporateParticipant,
  openModal,
  closeModal,
  statusType
} from '../../../actions'

// components
import { ActivityGridOld, Spinner } from '../../../components'
import ParticipantBanner from './banner/banner.component'
import ParticipantOverview from './overview/overview.component'

import { CORPORATE_PARTICIPANT, THEMES } from '../../../utils'

import './participant.page.css'

/**
 * Corporate Participant Page
 */
class CorporateParticipantPage extends PureComponent {
  /**
   * ComponentDidMount
   */
  componentDidMount () {
    this.getParticipant()
  }

  /**
   * Get Participant
   */
  getParticipant () {
    const { match, getParticipant } = this.props
    const id = match && match.params && match.params.id

    id && getParticipant(id)
  }

  /**
   * Get Full Name
   * @param props
   * @returns {string}
   */
  getFullName (props) {
    const { fullName, firstName, lastName, suspended } = props
    const name = (fullName && fullName.length) ? fullName : [firstName, lastName].filter((name) => name && name.trim()).join(' ')

    return `${name} ${suspended ? '(deleted)' : ''}`
  }

  /**
   * Render Participant Page
   * @returns {*}
   */
  render () {
    const { status, participant, removeParticipant, openModal, closeModal, history } = this.props
    const { _id, fullName, firstName, lastName, suspended } = participant

    const name = this.getFullName({ fullName, firstName, lastName, suspended })

    if ((status === statusType.ERROR) || (status === statusType.SUCCESS && !_id)) {
      return <Redirect to='/error/404' />
    }

    return (
      <div className='corporate-participant-page'>
        {(status === statusType.IN_PROGRESS) && <Spinner mask theme={THEMES.RAIN} />}
        {!!_id && (<>
          <ParticipantBanner
            name={name}
            participant={participant}
            handleParticipantUpdate={this.getParticipant.bind(this)}
            removeParticipant={removeParticipant}
            openModal={openModal}
            closeModal={closeModal}
            history={history} />

          <ParticipantOverview
            participant={participant} />

          <ActivityGridOld
            className='corporate-participant-page_activity-grid'
            entityType={CORPORATE_PARTICIPANT}
            entityId={_id}
            entity={{
              _corporateparticipant: _id, name
            }} />
        </>)}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  status: state.corporateParticipant.detail.status,
  participant: state.corporateParticipant.detail
})

const mapDispatchToProps = (dispatch) => ({
  getParticipant: bindActionCreators(fetchCorporateParticipant, dispatch),
  removeParticipant: bindActionCreators(removeCorporateParticipant, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CorporateParticipantPage))
