import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { SectionSearch } from '../../../../components/shared'
import { Toolbar, ToolbarRow } from '../../../../components'
import { THEMES } from '../../../../utils'

const propTypes = {
  dataId: PropTypes.string,
  toolbarTheme: PropTypes.oneOf([THEMES.RAIN, THEMES.STEEL]),
  toolTheme: PropTypes.oneOf([THEMES.STEEL, THEMES.Q4_BLUE]),
  search: PropTypes.string,
  filter: PropTypes.object,
  onQueryChange: PropTypes.func.isRequired
}

const defaultProps = {
  toolbarTheme: THEMES.RAIN,
  toolTheme: THEMES.STEEL,
  filter: {}
}

/**
 * Peerlist Toolbar Component
 * @param props
 */
function PeerListToolbar (props) {
  const {
    dataId, toolbarTheme, toolTheme, search, onQueryChange
  } = props

  const handleSearchChange = (search) => onQueryChange({ search })

  return (
    <Toolbar theme={toolbarTheme}>
      <ToolbarRow right>
        <div className='toolbar_group'>
          <SectionSearch
            dataId={`${dataId}Search`}
            theme={toolTheme}
            value={search}
            onQueryChange={handleSearchChange}
            onClear={handleSearchChange}
          />
        </div>
      </ToolbarRow>
    </Toolbar>
  )
}

PeerListToolbar.propTypes = propTypes
PeerListToolbar.defaultProps = defaultProps

export default memo(PeerListToolbar)
