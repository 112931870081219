import {
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_ERROR,
  IDLE,
  FETCHING,
  FETCHED,
  FAILED
} from '../../actions/shared/index'

const profileReducer = (state = { status: IDLE }, { type, payload, error }) => {
  switch (type) {
    case FETCH_PROFILE_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        ...payload,
        status: FETCHED
      }
    case FETCH_PROFILE_ERROR:
      return {
        ...state,
        error,
        status: FAILED
      }
    default:
      return state
  }
}

export default profileReducer
