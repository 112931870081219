import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { addQueryParams } from '../../../utils/http/http.util'
import { fetchProfile } from '../../shared'
import _ from 'lodash'

export const FETCH_AI_TARGETING_REQUEST = 'FETCH_AI_TARGETING_REQUEST'
export const FETCH_AI_TARGETING_SUCCESS = 'FETCH_AI_TARGETING_SUCCESS'
export const FETCH_AI_TARGETING_ERROR = 'FETCH_AI_TARGETING_ERROR'
export const RESET_AI_TARGETING_STATUS = 'RESET_AI_TARGETING_STATUS'
export const SET_REQUEST_PARAMETERS_IN_AI_TARGETING = 'SET_REQUEST_PARAMETERS_IN_AI_TARGETING'
export const SET_COUNTRY_IN_AI_TARGETING = 'SET_COUNTRY_IN_AI_TARGETING'
export const SET_REGION_IN_AI_TARGETING = 'SET_REGION_IN_AI_TARGETING'

export const FETCHING = 'FETCHING'
export const IDLE = 'IDLE'
export const FETCHED = 'FETCHED'
export const FAILED = 'FAILED'

const resources = {
  aiTargeting: '/targeting/ai/location'
}

const _fetchData = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_AI_TARGETING_REQUEST,
      FETCH_AI_TARGETING_SUCCESS,
      FETCH_AI_TARGETING_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

const _setCountry = (payload) => ({
  type: SET_COUNTRY_IN_AI_TARGETING,
  payload
})

const _setRegion = (payload) => ({
  type: SET_REGION_IN_AI_TARGETING,
  payload
})

const setParameters = (payload) => ({
  type: SET_REQUEST_PARAMETERS_IN_AI_TARGETING,
  payload
})

export const getAiTargetingByLocation = (params) => (dispatch) => {
  const path = resources.aiTargeting

  dispatch(fetchProfile()).then(() => {
    const url = addQueryParams(path, _.pickBy(params))
    dispatch(setParameters(params))
    return dispatch(_fetchData(url))
  })
}

export const resetStatus = () => ({
  type: RESET_AI_TARGETING_STATUS
})

export const setCountry = (country) => (dispatch) => {
  return dispatch(_setCountry(country))
}

export const setRegion = (region) => (dispatch) => {
  return dispatch(_setRegion(region))
}
