import React, {Component} from 'react';
import {Link} from "react-router-dom";
import { modalType } from '../../../actions/ui'
import {inMillions, format} from '../../../utils/number.util';
import TargetFilter from './targetFilter/targetFilter.component';
import './laneHeader.component.css';

/**
 * Lane Header Component for Pipeline Board
 * Refer to https://github.com/rcdexta/react-trello for documentation
 */
class LaneHeader extends Component {

    constructor(props) {
        super(props);
        this.header = null;
    }

    /**
     * Handle adding a new deal
     */
    onDealAddClick = (stage) => {
        const { openModal, onDealCreate } = this.props;

        openModal({
            type: modalType.DEAL_EDIT_MODAL,
            props: {
                deal: { stage },
                onSaveSuccess: onDealCreate
            }
        })
    };

    setHeaderRef = (el) => {
        this.header = el;
    };

    /**
     * Saved Targets Lane Header
     * @return {function()}
     */
    renderTargetHeader = () => {
        const {cards, loaded, onClick} = this.props;
        const count = cards && cards.length;

        return (
            <div className='lane-header lane-header--target'>
                <div className='lane-header_row lane-header_row--controls'>
                    <div className='lane-header_expand-button' id='targetsExpand' onClick={onClick}>
                        <i className='lane-header_icon lane-header_icon--target q4i-targeting-4pt'/>
                        <div className={`lane-header_target-count ${loaded ? 'q4-fade-in' : 'q4-fade-in--transparent'}`}>
                            {count}
                        </div>
                    </div>
                    <div className='lane-header_target-title lane-header_target-title--collapse'>
                        <Link className='lane-header_link' to={'/target'}>Saved Targets</Link>
                    </div>
                    <i onClick={onClick} id='targetsCollapse' className='lane-header_icon lane-header_icon--collapse q4i-arrow-left-4pt'/>
                </div>
                <div className='lane-header_row lane-header_row--target-filter'>
                    <TargetFilter filterTarget={this.props.filterTarget}/>
                </div>
            </div>
        );
    };

    /**
     * Adds footer element at the end of each line.
     * @param id - stage id
     * @param label - lane label
     * @returns
     */
    renderAddNewDeal = (id, label) => {
        if (!id || !label ) {
            return null;
        }

        return (
            <div className='lane-header_row lane-header_row--add-deal' onClick={() => this.onDealAddClick(id)}>
                <div className='lane-header_title lane-header_title--add-deal'>Add New Deal</div>
            </div>
        );
    };

    /**
     * Pipeline Stage Lane Header
     * @return {function()}
     */
    renderStageHeader = () => {
        const {id, label, cards, probability, stockPrice, lost} = this.props;
        const positionSum = (cards || []).reduce((acc, curr) => acc + curr.amount, 0);
        const marketValue = positionSum * (probability / 100) * stockPrice;

        return (
            <div className='lane-header' column={label} ref={this.setHeaderRef}>
                <div className='lane-header_row'>
                    {lost && <i className='lane-header_icon lane-header_icon--lost q4i-nodata-4pt'/>}
                    <div className='lane-header_title'>{label}</div>
                    <div className='lane-header_count'>{cards && cards.length}</div>
                </div>
                <div className='lane-header_row lane-header_row--market-info'>
                    <div className='lane-header_value'>
                        {marketValue < 10000 ? '$' + format(marketValue, 2) : '$' + inMillions(marketValue, 2) + 'M'}
                        <div className='tooltip tooltip--top-left'>Weighted Market Value</div>
                    </div>
                    <div className='lane-header_percentage'>
                        {probability}%
                        <div className='tooltip tooltip--top-left'>Probability</div>
                    </div>
                </div>
                {this.renderAddNewDeal(id, label)}
            </div>
        );
    };

    /**
     * Renders
     */
    render() {
        const {type} = this.props;

        switch (type) {
            case 'target':
                return this.renderTargetHeader();
            case 'stage':
                return this.renderStageHeader();
            default:
                return null;
        }
    }
}


export default LaneHeader;
