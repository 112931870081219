import compactTimezones from '../data/timezones/compact-timezones.data'
import { LD_FEATURE_FLAGS, launchDarklyHelper } from './../services/launchDarkly.service'
import { getLocalizationLocalStorage } from './currencyLocalization'

import {
  findTimeZone,
  getZonedTime,
  getUTCOffset
} from 'timezone-support'

const { isNumber, padStart } = require('lodash')
const moment = require('moment')

/**
 * Global ISO 8601 date format for export
 */
export const EXPORT_DATE_FORMAT = 'YYYY-MM-DD'

/**
 * Global date format
 */
export const DEFAULT_DATE_FORMAT = 'MM/DD/YY'

/**
 * Returns native Date object from Moment date object.
 * @param momentDate
 * @returns {Date}
 */
export function getDateFromMoment (momentDate) {
  return (momentDate && moment.isMoment(momentDate) ? momentDate.toDate() : momentDate)
}

/**
 * Returns Moment date object from native Date object.
 * @param date
 * @param timezone
 * @returns {moment.Moment}
 */
export function getMomentFromDate (date, timezone) {
  const momentDate = (date && !moment.isMoment(date) ? moment(date) : date)
  return (timezone ? momentDate.tz(timezone) : momentDate)
}

/**
 * Global default display format for time
 * @returns {string}
 */
export function getTimeFormat () {
  return 'h:mma'
}

export function isValidTimeFormat (time, format = getTimeFormat()) {
  const momentDate = moment(time, format, true)
  return momentDate.isValid()
}

/**
 * Passing a date and a time, returns the combined date-time object.
 * @param date {Date} A date object
 * @param time {String} The time to use
 * @param timeFormat {String} Optional. The time format to use for the time parameter. Default: getTimeFormat()
 * @param timeZone {String} Optional. The timezone to convert date
 * @returns {Date|Moment} Returns Date if Date object eas passed, otherwise Moment object.
 */
export function getDateTime (date, time, timeFormat = getTimeFormat(), timeZone = guessTimeZone()) {
  if (!date || !time) {
    return null
  }

  const isMoment = moment.isMoment(date)
  const dateObj = (isMoment ? date : moment(date))
  // Use moment.parseZone to parse and format dateObj without applying timezone conversion which might trigger date switch
  const day = moment.parseZone(dateObj).format('MM-DD-YYYY')
  const dayTimeStr = `${day} ${time || dateObj.format(timeFormat)}`
  const dayTime = moment.tz(dayTimeStr, `MM-DD-YYYY ${timeFormat}`, timeZone)
  return (isMoment ? dayTime : dayTime.format())
}

/**
 * Get current surveillance ownership date
 * @return {moment.Moment}
 * @private
 */
export function getCurrentDate () {
  let weekAgo = 1
  const weekDay = moment().utc().isoWeekday()

  if (weekDay === 7) {
    weekAgo = 0
  }

  return moment.utc().subtract(weekAgo, 'week').startOf('isoweek').add(4, 'days').startOf('day')
}

export function formatDate (dateString, format = DEFAULT_DATE_FORMAT, isUTC, useLocalFormat = false) {
  const localFormat = useLocalFormat ? getLocalizedFormat(format) : format
  if (!dateString) {
    return '-'
  }
  const fromFormat = ['MM/DD/YY', 'YYYY-MM-DD hh:mm:ss ZZ', 'x']
  const date = isUTC ? moment.utc(dateString, fromFormat) : moment(dateString, fromFormat)
  if (!date.isValid()) {
    return '-'
  }

  return format ? date.format(localFormat) : date
}

/**
 * Get previous quarter for a given date
 * @return {moment.Moment}
 */
export function getPreviousQuarter (currentDate) {
  const currentMomentDate = moment(currentDate).utc()
  return currentMomentDate.clone().subtract(1, 'quarter')
}

/**
 * Get relative (prev or future) quarter by integer
 * @param integer
 * @param format
 */
export const getRelativeQuarter = (integer, format = 'MM/DD/YYYY') => {
  const today = moment.utc().endOf('day')
  const quarter = moment.utc().endOf('quarter')
  const endDate = today.isSame(quarter) ? quarter : quarter.subtract(1, 'quarter').endOf('quarter')

  return moment.utc(endDate).add(integer, 'quarter').endOf('quarter').format(format)
}

/**
 * Get an array of dates representing the end date of a number of historical quarters
 * @param quarters
 * @param endDate
 * @param format
 * @returns {[]}
 */
export function getQuarterEndDates (quarters, endDate, format = 'MM/DD/YY') {
  const quarterDate = moment.utc(endDate || new Date())
    .subtract(1, 'quarter').endOf('quarter')

  const quarterDates = []
  for (let i = 0; i < quarters; i++) {
    quarterDates.push(quarterDate.clone().format(format))
    quarterDate.subtract(1, 'quarter').endOf('quarter')
  }

  return quarterDates
}

/**
 * Get the last quarter date where 45 days had passed for the most recent quarter
 * * @returns {date}
 */
export function getOffsetQuarterDate () {
  return moment.utc().subtract(45, 'days').endOf('quarter').startOf('day').toDate()
}

/***
 * Get an array dates representing the end date of a number of historical quarters.
 * @param startDate
 * @param endDate
 * @param _format
 * @return {Array}
 */
export function getQuartersEndDates (startDate, endDate, _format = 'MM/DD/YY') {
  const quarterDate = moment.utc(endDate || new Date(), moment.ISO_8601)
    .add(1, 'day').subtract(1, 'quarter').endOf('quarter')

  const quarterDates = []
  for (; quarterDate.isSameOrAfter(moment(startDate));) {
    quarterDates.push(_format ? quarterDate.clone().format(_format) : quarterDate.clone().toDate())
    quarterDate.subtract(1, 'quarter').endOf('quarter')
  }

  return quarterDates
}

export const isDateBeforeDate = (dateOne, dateTwo) => {
  let momentOne = getMomentFromDate(dateOne)
  let momentTwo = getMomentFromDate(dateTwo)
  const areDatesCorrect = !!(momentOne && dateTwo && momentOne.isValid() && momentTwo.isValid())

  if (areDatesCorrect) {
    momentOne = moment(moment.utc(momentOne).format())
    momentTwo = moment(moment.utc(momentTwo).format())
  }

  return (areDatesCorrect ? momentOne.isBefore(momentTwo) : null)
}

/**
 * Given a number of minutes, rounds it to the closest minute, based on the increment in props.
 * @param minutes {Number|String} The minutes.
 * @param increment {Number|String} The minutes.
 * @param roundMethod {String} Math round method (ceil, floor).
 * @returns {Number} The minutes rounded.
 */
export const getMinutesRounded = (minutes, increment, roundMethod = 'ceil') => {
  return (isNumber(Number(minutes)) && isNumber(Number(increment)) ? increment * Math[roundMethod](Number(minutes) / Number(increment)) : null)
}

/**
 * Given a date, returns the time.
 * @param date {Moment|Date}
 * @param options {Object} Optional.
 * @param options.timezone {String}
 * @param options.format {String} The time format
 * @param options.round {Number} The minutes increment to round to. E.g 30, for half an hour.
 * @returns {String} The time.
 */
export const getTimeFromDate = (date, options = {}) => {
  if (!date) {
    return null
  }

  let momentDate = getMomentFromDate(date, options.timezone)

  if (options.round) {
    const minutes = momentDate.minute()
    const roundedMinutes = getMinutesRounded(minutes, options.round)
    momentDate = momentDate.clone().minutes(roundedMinutes)
  }
  return momentDate.format(options.format || getTimeFormat())
}

/**
 * Returns minutes in a 2 digit format.
 * @param minutes {Number|String}
 * @returns {string}
 */
export const getMinutesTwoDigits = (minutes) => {
  return (isNumber(Number(minutes)) && Number(minutes).toString().length < 2 ? `0${minutes}` : minutes || null)
}

/**
 * Convert Minutes To Hours:Minutes
 * @param minutes
 * @returns {string}
 */
export const getHoursMinutesFromOffset = (minutes) => {
  return (isNumber(Number(minutes)) &&
    `${(minutes > 0) ? '-' : '+'}${padStart(Math.floor(Math.abs(minutes) / 60).toString(), 2, '0')}:${padStart((Math.abs(minutes) % 60).toString(), 2, '0')}`) ||
    null
}

/**
 * Returns true if two dates are the same
 * @param start
 * @param end
 * @param format
 */
export const isSameDate = (start, end, format = 'MM-DD-YYYY') => {
  const startDate = start && formatDate(start, format)
  const endDate = end && formatDate(end, format)
  return (startDate && endDate && startDate === endDate)
}

/**
 * Get list of timezones objects
 * @param date
 * @returns {{offset: number, label: string, value: string}[]}
 */
export const getTimeZones = (date) => {
  return _getCompactTimeZones()
    .map((zone) => {
      const timezone = findTimeZone(zone.tzCode)
      const zonedTime = getZonedTime(date, timezone)
      const offSet = getUTCOffset(date, timezone)
      const hoursMinutes = getHoursMinutesFromOffset(offSet.offset)

      return {
        value: zone.tzCode,
        label: `(GMT${hoursMinutes}) - ${zone.label}`,
        offset: zonedTime.zone.offset
      }
    })
    .sort((a, b) => {
      const offsetDelta = b.offset - a.offset
      if (offsetDelta !== 0) {
        return offsetDelta
      }

      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    })
}

/**
 * Find alternative timezone from a compact timezone list
 * @param date
 * @returns {string}
 */
export const getCompatibleTimeZone = (data) => {
  const date = moment.utc(new Date(data.date_time)).toDate()
  const tzCode = data && data.timezone

  if (!date || !tzCode) {
    return
  }

  // try to find exact timezone match
  let defaultCompactTimezone = getTimeZones(date)
    .find((compactTzItem) => compactTzItem.value === tzCode)

  // if unable to find exact match - find by offset
  if (!defaultCompactTimezone) {
    const timezone = findTimeZone(tzCode)
    const zonedTime = getZonedTime(date, timezone)
    defaultCompactTimezone = getTimeZones(date)
      .find((compactTzItem) => compactTzItem.offset === zonedTime.zone.offset)
  }

  return defaultCompactTimezone && defaultCompactTimezone.value
}

/**
 * Guess client's timezone respecting custom timezone list
 * @return {string}
 */
export const guessTimeZone = () => {
  const date = moment.utc().toDate()
  const defaultCompactTimezone = getTimeZones(date)
    .find((compactTzItem) => compactTzItem.value === moment.tz.guess())

  return (defaultCompactTimezone && defaultCompactTimezone.value) || moment.tz.guess()
}

/**
 * Get compact timezone list
 * @return {{offset: number, tzCode: string, label: string}[]}
 * @private
 */
const _getCompactTimeZones = () => {
  const date = moment.utc().toDate()

  return compactTimezones
    // only return items for which we were able to find corresponding
    // timezone from `timezone-support` library and offset
    .filter((tzItem) => {
      const timezone = findTimeZone(tzItem.timezone)
      const offset = getUTCOffset(date, timezone)
      return timezone && offset
    })
    .map((tzItem) => {
      const timezone = findTimeZone(tzItem.timezone)
      const offset = getUTCOffset(date, timezone)

      return {
        tzCode: tzItem.timezone,
        offset: offset.offset,
        label: tzItem.label
      }
    })
}

/**
 * get Timezone offset such as GMT-11
 * @param date
 * @returns {string}
 */
export const getCompatibleTimeZoneOffSet = (date) => {
  if (!date) {
    return
  }
  const timezone = getTimezone(date)
  const offSet = getUTCOffset(new Date(date.date_time), findTimeZone(timezone))
  const hoursMinutesOffset = getHoursMinutesFromOffset(offSet.offset)

  return hoursMinutesOffset
}

/**
 * timezone doesn't exist, get current timezone else get compatible timezone
 * @param date
 * @returns {string}
 */
export const getTimezone = (date) => {
  return date && date.timezone ? getCompatibleTimeZone(date) : guessTimeZone()
}

/**
 * Returns the user's Time with Timezone abbr. from timestamp
 * @param timestamp
 * @returns {string}
 */
export const getTimeWithZone = (timestamp) => {
  const rawTimeArr = formatDate(timestamp, 'LT').split(' ')
  let timeZoneAbb = moment.tz(moment.tz.guess()).zoneAbbr()
  if (isNumber(parseInt(timeZoneAbb)) && (timeZoneAbb[0] === '-' || timeZoneAbb[0] === '+')) {
    // handle cases where moment fails to find an appropriate abbr for a timezone
    timeZoneAbb = 'GMT' + timeZoneAbb
  }
  return `${rawTimeArr[0]} ${rawTimeArr[1]} ${timeZoneAbb}`
}

/** Returns true if the given date is midnight or 'zero time' e.g. YYYY-MM-DD 00:00:00.000
 * @param {string} dateString
 * @param {boolean} isUTC
 * @returns {boolean}
 */
export const isMidnight = (dateString, isUTC = false) => {
  if (!dateString) {
    return
  }

  const fromFormat = ['MM/DD/YY', 'YYYY-MM-DD hh:mm:ss ZZ', 'x']
  const momentDate = isUTC ? moment.utc(dateString, fromFormat) : moment(dateString)

  return momentDate.isValid() && (momentDate.hours() === 0 && momentDate.minutes() === 0 && momentDate.seconds() === 0)
}

/**
 * Returns signed(+,-) timezone offset in ms
 */
export const getTimeZoneOffsetMs = () => {
  return moment().utcOffset() * 60 * 1000
}

/**
 * Returns a date formatted according to the region of the logged-in user
 * @param {string} date 
 * @param {string} format 
 * @returns {string}
 */
export const formatLocalizedDate = (date, format) => {
  const formattedUSDate = moment(date, format)
  return formattedUSDate.format(getLocalizedFormat(format));
}

/**
 * Returns the value of USE_LOCALIZED_DATE_FORMAT Launch Darkly Feature Flag
 * @returns {boolean}
 */
const isLocalizedDateFormatFlagEnabled = () => {
  return !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.USE_LOCALIZED_DATE_FORMAT)
}

/**
 * Returns a boolean to indicate if the logged-in user region is 'europe'
 * @returns {boolean}
 */
const isEurope = () => {
  return getLocalizationLocalStorage()?.region === 'europe'
}

/**
 * Return a date format string according to the region of the logged-in user
 * @param {string} usFormat 
 * @returns {string}
 */
export const getLocalizedFormat = (usFormat) => {
  if (!isLocalizedDateFormatFlagEnabled() || !isEurope()) {
    return usFormat
  }

  switch (usFormat) {
    case 'MM/DD':
      return 'DD/MM'
    case 'MM-DD':
      return 'DD-MM'
    case 'MM/DD/YY':
      return 'DD/MM/YY'
    case 'MM/DD/YYYY':
      return 'DD/MM/YYYY'
    case 'MM-DD-YY':
      return 'DD-MM-YY'
    case 'MM-DD-YYYY':
      return 'DD-MM-YYYY'
    case 'MM-DD-YYYY hh:mm:ss a':
      return 'DD-MM-YYYY hh:mm:ss a'
    case 'YYYY-MM-DD':
      return 'DD-MM-YYYY'
      case 'YYYY/MM/DD':
        return 'DD/MM/YYYY'
    case 'YYYY-MM-DD hh:mm:ss ZZ':
      return 'DD-MM-YYYY hh:mm:ss ZZ'
    case 'MMM DD, YYYY':
      return 'DD MMM, YYYY'
    case 'MMMM DD, YYYY':
      return 'DD MMMM, YYYY'
    case 'MMMM DD, YYYY h:mm A':
      return 'DD MMMM, YYYY h:mm A'
    case 'dddd, MMMM D, YYYY':
      return 'dddd, D MMMM, YYYY'
    case 'dddd, MMMM DD, YYYY':
      return 'dddd, DD MMMM, YYYY'
    case 'dddd, MMMM D, YYYY LT':
      return 'dddd, D MMMM, YYYY LT'
    case 'dddd, MMMM DD, YYYY LT':
      return 'dddd, DD MMMM, YYYY LT'
    case 'dddd, MMM D, YYYY h:mm a':
      return 'dddd, D MMM, YYYY h:mm a'
    case 'dddd, MMM D YYYY':
      return 'dddd, D MMM YYYY'
    case 'dddd, MMM D, YYYY':
      return 'dddd, D MMM, YYYY'
    case 'dddd, MMM DD, YYYY':
      return 'dddd, DD MMM, YYYY'
    case 'dddd, MMM Do, YYYY':
      return 'dddd, Do MMM, YYYY'
    case 'LLLL':
      return 'dddd, DD MMMM, YYYY h:mm A'
    case 'MMMM Do, YYYY':
      return 'Do MMMM, YYYY'
    case 'MMMM D, YYYY':
      return 'D MMMM, YYYY'
    case 'dddd, LL':
      return 'dddd, DD MMMM YYYY'
    case '%m/%d':
      return '%d/%m'
    default:
      return usFormat
  }
}
