import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// components
import { Button, Checkbox, DateRangePicker, RadioGroup, Sidebar, Select } from '../../../../../index'
import {
  ENTITY_TYPE,
  HOLDER_POSITION,
  HOLDER_STYLE,
  HOLDER_TURNOVER,
  HOLDER_TYPE,
  LOGGED_ACTIVITY,
  THEMES
} from '../../../../../../utils'
import { get, isEmpty } from 'lodash'

const propTypes = {
  dataId: PropTypes.string.isRequired,
  theme: PropTypes.string,
  filter: PropTypes.shape({
    isActivist: PropTypes.bool,
    position: PropTypes.string,
    style: PropTypes.array,
    turnover: PropTypes.array,
    type: PropTypes.array,
    activity: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }).isRequired,
  holderType: PropTypes.string,
  isSidebar: PropTypes.bool.isRequired,
  onQueryChange: PropTypes.func.isRequired
}

const defaultProps = {
  filter: {},
  isSidebar: false,
  theme: THEMES.WHITE,
}

const { INSTITUTION } = ENTITY_TYPE

/**
 * Sidebar Header Component
 * @param props
 */
const Header = ({ onReset }) => (
  <>
    <div className='sidebar_header-title'>Filters</div>
    <Button
      theme={THEMES.SOFT_GREY}
      label='Reset'
      onClick={onReset}
    />
  </>
)

/**
 * Current Holder Sidebar
 * @param props
 */
function CurrentHolderSidebar (props) {
  const { dataId, theme, holderType, filter, isSidebar, onQueryChange } = props
  const { isActivist, position, style, turnover, type, activity, startDate, endDate } = filter

  const typeSelected = HOLDER_TYPE.filter((option) => (type || []).includes(option.value))
  const styleSelected = HOLDER_STYLE.filter((option) => (style || []).includes(option.value))
  const turnoverSelected = HOLDER_TURNOVER.filter((option) => (turnover || []).includes(option.value))

  /**
   * Handle filter reset
   */
  const handleFilterReset = () => onQueryChange({
    filter: {
      isActivist: false,
      position: null,
      style: null,
      turnover: null,
      type: null,
      activity: 'all',
      startDate: null,
      endDate: null
    }
  })

  /**
   * Handle filter change
   * @param value
   * @param type
   */
  const handleFilterChange = (value, type) => {
    if (Array.isArray(value)) {
      const types = (value || []).map((item) => item && item.value)
      return onQueryChange({
        filter: { ...filter, [type]: !isEmpty(types) ? types : null }
      })
    }

    return onQueryChange({ filter: { ...filter, [type]: value } })
  }

  /**
   * Handle date range change
   * @param value
   */
  const handleDateChange = (value) => {
    onQueryChange({
      filter: {
        ...filter,
        startDate: Array.isArray(value) ? value[0] : null,
        endDate: Array.isArray(value) ? value[1] : null
      }
    })
  }

  /**
   * Handle date range reset and sets filter to provided activity 
   * @param activityValue
   */
  const handleDateRangeReset = (activityValue) => {
    onQueryChange({
      filter: {
        ...filter,
        activity: activityValue,
        startDate: null,
        endDate: null
      }
    })
  }

  return (
    <Sidebar
      collapsed={!isSidebar}
      headerTemplate={<Header onReset={handleFilterReset} />}
      scrollbars={false}
    >
      <Grid container spacing={6}>
        <Grid item xs={12} className='sidebar_inner-section'>
          <Grid container spacing={1}>
            {['all', INSTITUTION].includes(holderType) &&
              <Grid item xs={12}>
                <Checkbox
                  dataId={`${dataId}ActivistFilter`}
                  theme={theme}
                  id='isActivist'
                  label='Activist'
                  isSelected={isActivist}
                  onChange={(value) => handleFilterChange(value, 'isActivist')}
                />
              </Grid>}
            <Grid item xs={12}>
              <Select
                dataId={{
                  inputId: `${dataId}PositionFilterInput`,
                  menuId: `${dataId}PositionFilterMenu`
                }}
                theme={theme}
                placeholder='Position'
                value={HOLDER_POSITION.find(({ value }) => value === position) || null}
                options={HOLDER_POSITION}
                searchable={false}
                clearable={false}
                onChange={(option) => option && handleFilterChange(option.value, 'position')}
              />
            </Grid>
          </Grid>
        </Grid>

        {!['insider'].includes(holderType) &&
          <Grid item xs={12} className='sidebar_inner-section'>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Select
                  dataId={{
                    inputId: `${dataId}StyleFilterInput`,
                    menuId: `${dataId}StyleFilterMenu`
                  }}
                  theme={theme}
                  placeholder='Style'
                  value={styleSelected}
                  options={HOLDER_STYLE}
                  isMulti
                  isDefaultList
                  searchable={false}
                  clearable={false}
                  closeMenuOnSelect={false}
                  onChange={(options) => handleFilterChange(options, 'style')}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  dataId={{
                    inputId: `${dataId}TurnoverFilterInput`,
                    menuId: `${dataId}TurnoverFilterMenu`
                  }}
                  theme={theme}
                  placeholder='Turnover'
                  value={turnoverSelected}
                  options={HOLDER_TURNOVER}
                  isMulti
                  isDefaultList
                  searchable={false}
                  clearable={false}
                  closeMenuOnSelect={false}
                  onChange={(options) => handleFilterChange(options, 'turnover')}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  dataId={{
                    inputId: `${dataId}TypeFilterInput`,
                    menuId: `${dataId}TypeFilterMenu`
                  }}
                  theme={theme}
                  placeholder='Type'
                  portal
                  value={typeSelected}
                  options={HOLDER_TYPE}
                  isMulti
                  isDefaultList
                  searchable={false}
                  clearable={false}
                  closeMenuOnSelect={false}
                  onChange={(options) => handleFilterChange(options, 'type')}
                />
              </Grid>
            </Grid>
          </Grid>}

        {!['insider'].includes(holderType) &&
          <Grid item xs={12} className='sidebar_inner-section'>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <RadioGroup
                  dataId={`${dataId}ActivityFilter`}
                  name='activity'
                  label='Logged Activity'
                  selected={activity}
                  controls={LOGGED_ACTIVITY}
                  onChange={(event) =>
                    event.target.value === 'all' ?
                      handleDateRangeReset('all') :
                      handleFilterChange(get(event, 'currentTarget.value'), 'activity')
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DateRangePicker
                  theme={theme}
                  disabled={activity === 'all'}
                  value={[startDate, endDate]}
                  onChange={handleDateChange}
                  clearable
                />
              </Grid>
            </Grid>
          </Grid>}
      </Grid>
    </Sidebar>
  )
}

CurrentHolderSidebar.propTypes = propTypes
CurrentHolderSidebar.defaultProps = defaultProps

export default memo(CurrentHolderSidebar)
