import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Message } from '../../../components';
import './widgetConfig.component.css';

class WidgetConfiguration extends Component {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            isDetailsModalOpen: false
        };
    }

    /**
     * Open the widget details message modal
     */
    openDetailsModal = () => {
        this.setState({
            isDetailsModalOpen: true
        });
    };

    /**
     * Close the widget details message modal
     */
    closeDetailsModal = () => {
        this.setState({
            isDetailsModalOpen: false
        });
    };

    render() {
        const {actions, widget, theme} = this.props;
        const {isDetailsModalOpen} = this.state;

        return (
            <div className={`widget-config widget-config--${theme} q4-fade-in`}>
                <div className="widget-config_actions">
                    {(actions || []).map((action, index) => {
                        return (
                            <span className={`widget-config_action ${action.icon}`} key={`button-${index}`}
                                  onClick={(e) => action.onClick(e)}/>
                        );
                    })}
                </div>
                {widget && (
                    <div className='widget-config_details' onClick={this.openDetailsModal}>
                        <i className='q4i-info-fill'/>
                    </div>
                )}
                {widget && (
                    <Message
                        visible={isDetailsModalOpen}
                        type='info'
                        title={widget.title}
                        message={widget.description}
                        onClose={this.closeDetailsModal}
                        renderExitButton={true}
                        buttons={[{
                            ui: 'citrus',
                            label: 'ok',
                            onClick: this.closeDetailsModal
                        }]}
                    />
                )}
            </div>
        );
    }
}

WidgetConfiguration.propTypes = {
    theme: PropTypes.string.isRequired,
    actions: PropTypes.array.isRequired
};

export default WidgetConfiguration;
