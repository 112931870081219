import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
// redux actions
import {
  fetchContact,
  clearContact,
  getManagedFunds,
  getTarget,
  getDealsByEntityId,
  clearEntityDeals,
  clearTarget,
  statusType, openModal, closeModal, modalType, resetTags
} from '../../../../../../actions'
import { getActiveTicker } from '../../../../../../utils'
import { getSavedContact } from '../../../../../../actions/addressBook'
import ContactComponent from './contact.component'
import { get } from 'lodash'

const propTypes = {
  contactId: PropTypes.string.isRequired,
  getContact: PropTypes.func.isRequired
}

/**
 * ContactContainer
 */
function ContactContainer (props) {
  const {
    contact,
    entityType,
    contactId,
    getContact,
    getTarget,
    clearContact,
    targetId,
    hasDeal,
    getSavedContact,
    getDealsByEntityId,
    clearEntityDeals,
    clearTarget,
    resetTags,
    openModal
  } = props
  const institutionName = contact.jobs && contact.jobs[0] && contact.jobs[0].institution_name
  const contactDetailStatus = contact.status

  useEffect(() => {
    getContact(contactId)
    getSavedContact(contactId)
    return () => {
      clearContact()
    }
  }, [contactId, getContact, getSavedContact, clearContact])

  useEffect(() => {
    if (!contactId || !entityType) {
      return
    }
    getTarget(contactId)
    getDealsByEntityId(contactId)

    return () => {
      clearEntityDeals()
      clearTarget()
      resetTags()
    }
  }, [contactId, getTarget, entityType, getDealsByEntityId, clearEntityDeals, clearTarget, resetTags])

  /**
   * Show Info Modal
   */
  const onGDPRInformation = () => {
    openModal({
      type: modalType.GDPR_MODAL
    })
  }

  return (
    <ContactComponent
      {...props}
      isLoading={!contact || [statusType.IDLE, statusType.IN_PROGRESS].includes(contactDetailStatus)}
      contact={contact}
      institutionName={institutionName}
      targetId={targetId}
      hasDeal={hasDeal}
      handleGDPRmodal={onGDPRInformation}
    />
  )
}

ContactContainer.propTypes = propTypes

const mapDispatchToProps = (dispatch) => ({
  getContact: bindActionCreators(fetchContact, dispatch),
  getManagedFunds: bindActionCreators(getManagedFunds, dispatch),
  // getSavedContact is used to fetch saved contact, function needed and contact.favorite.data used in kebob from flyout banner
  getSavedContact: bindActionCreators(getSavedContact, dispatch),
  clearContact: bindActionCreators(clearContact, dispatch),
  resetTags: bindActionCreators(resetTags, dispatch),
  getTarget: bindActionCreators(getTarget, dispatch),
  clearEntityDeals: bindActionCreators(clearEntityDeals, dispatch),
  clearTarget: bindActionCreators(clearTarget, dispatch),
  getDealsByEntityId: bindActionCreators(getDealsByEntityId, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch)
})

const mapStateToProps = (state) => {
  const { contact, targeting, profile, entityDeals } = state
  const ticker = profile && getActiveTicker(profile.data)

  return {
    contact: contact.detail,
    targetStatus: get(targeting, 'target.status'),
    securityId: ticker && ticker._security,
    funds: contact.fund,
    targetId: get(targeting, 'target.data._id') || get(targeting, 'target.data.targetId'),
    hasDeal: get(entityDeals, 'data', []).length > 0
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactContainer))
