import {
  GET_CORPORATE_PARTICIPANT_REQUEST,
  GET_CORPORATE_PARTICIPANT_SUCCESS,
  GET_CORPORATE_PARTICIPANT_FAILURE,
  REMOVE_CORPORATE_PARTICIPANT_REQUEST,
  REMOVE_CORPORATE_PARTICIPANT_SUCCESS,
  REMOVE_CORPORATE_PARTICIPANT_FAILURE,
  statusType
} from '../../actions'

const initial = {
  _id: null,
  suspended: false,
  fullName: '',
  firstName: '',
  lastName: '',
  nickname: '',
  title: '',
  phone: '',
  mobile: '',
  email: '',
  status: statusType.IDLE
}

const corporateParticipant = (state = initial, action) => {
  const { payload = {} } = action

  switch (action.type) {
    case GET_CORPORATE_PARTICIPANT_REQUEST:
      return {
        ...initial,
        status: statusType.IN_PROGRESS
      }

    case GET_CORPORATE_PARTICIPANT_SUCCESS:
      const job = (payload.jobs && Array.isArray(payload.jobs) && payload.jobs.length) ? payload.jobs[0] : {}

      const useFullName = !payload.first_name && !payload.last_name && payload.full_name
      const name = payload.full_name && payload.full_name.split(' ')

      return {
        ...state,
        _id: payload._id || null,
        suspended: payload.suspended || false,
        fullName: payload.full_name || '',
        firstName: useFullName ? name[0] : payload.first_name || '',
        lastName: useFullName ? (name.length > 1 ? name[name.length - 1] : '') : payload.last_name || '',
        nickname: payload.nickname || '',
        mobile: payload.mobile || '',
        title: job.title || '',
        functions: job.functions || [],
        phone: job.direct_telephone || '',
        email: job.email || '',
        status: statusType.SUCCESS
      }

    case GET_CORPORATE_PARTICIPANT_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case REMOVE_CORPORATE_PARTICIPANT_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case REMOVE_CORPORATE_PARTICIPANT_SUCCESS:
      return {
        ...state,
        status: statusType.SUCCESS
      }

    case REMOVE_CORPORATE_PARTICIPANT_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    default:
      return state
  }
}

export default corporateParticipant
