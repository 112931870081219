import React, {PureComponent} from 'react';
import {throttle} from 'lodash';
import {getClassName} from '../../../utils/ui';
import './scrollUpButton.component.css';

/**
 * ScrollUpButton Component
 */
class ScrollUpButton extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            attachedToFooter: false,
            headerHeight: 80,
            footerHeight: 150
        };

        this.handleScroll = throttle(this.handleScroll, 15);
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
        const scrollableContainer = document.getElementById('app-scrollable');
        const header = document.getElementById('app-header');
        const footer = document.getElementById('app-footer');

        if (header && footer) {
            this.setState({
                headerHeight: header.offsetHeight,
                footerHeight: footer.offsetHeight
            });
        }

        scrollableContainer && scrollableContainer.addEventListener('scroll', this.handleScroll);
    }

    /**
     * componentWillUnmount
     */
    componentWillUnmount() {
        const scrollableContainer = document.getElementById('app-scrollable');

        scrollableContainer && scrollableContainer.removeEventListener('scroll', this.handleScroll);
    }

    /**
     * Scroll to the top of the page
     */
    scrollToTop = () => {
        const body = document.getElementById('app-scrollable');

        this.setState({
            visible: false
        }, () => {
            body && body.scrollTo ? body.scrollTo({
                top: 0,
                behavior: 'smooth'
            }) : body.scrollTop = 0;
        });
    };

    /**
     * Returns element position on the page. Determines element's distance to top and bottom
     * @param element
     * @return {{distanceToTop: number, distanceToBottom: number} | undefined}
     */
    getElementPosition = (element) => {
        if (!element) {
            return;
        }

        const pageSize = element.scrollHeight;

        return {
            distanceToTop: element.scrollTop,
            distanceToBottom: pageSize - (element.scrollTop + element.offsetHeight)
        };
    };

    /**
     * Handle scroll event
     */
    handleScroll = (event) =>  {
        const {headerHeight, footerHeight} = this.state;
        const {distanceToTop, distanceToBottom} = this.getElementPosition(event.target);

        if ([distanceToTop, distanceToBottom, headerHeight, footerHeight].some((num) => isNaN(num))) {
            return;
        }

        this.setState({
            visible: distanceToTop > headerHeight,
            attachedToFooter: distanceToBottom < (footerHeight / 2)
        });
    };

    /**
     * Render Scroll Up Button
     */
    render() {
        const {visible, attachedToFooter} = this.state;

        const baseClassName = getClassName('scroll-up-button', [
            {condition: visible, trueClassName: 'scroll-up-button--visible'},
            {condition: attachedToFooter, trueClassName: 'scroll-up-button--sticky'}
        ]);

        return (
            <button id='scroll-up-button' className={baseClassName} onClick={this.scrollToTop}>
                <i className='q4i-arrow-up-2pt'/>
            </button>
        );
    }
}

export default ScrollUpButton;