import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

// actions
import { getEvents, resetEvents, openModal, statusType } from '../../../actions'
import { useNewsLazyQuery } from '../hook'
import { useEventTranscriptQuery } from '../../../pages/eventsTranscripts/hook'

// components
import SecurityRecentUpdateCard from './recentUpdate.component'

// utils
import { getTimeZoneOffsetMs, isServiceEnabled, PROFILE_REGIONS, THEMES } from '../../../utils'
import { get, isNil } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  tickerId: PropTypes.string.isRequired,
  legacySecurityId: PropTypes.string
}

const defaultProps = {
  theme: THEMES.DARK
}

/**
 * Security Recent Updates
 * @param props
 */
function SecurityRecentUpdate (props) {
  const {
    dataId,
    theme,
    tickerId,
    legacySecurityId,
    profile,
    getEvents,
    resetEvents,
    openModal
  } = props
  const { region } = profile || {}

  const services = profile && profile.services
  const isSubscribed = isServiceEnabled('events', services || [])

  const [transcript, setTranscript] = useState(false)

  const hideNews = useMemo(() => [PROFILE_REGIONS.EUROPE].some((x) => region === x), [region])

  const [getNews, { data: news }] = useNewsLazyQuery()

  const [getEventsGQL, { loading: eventsLoadingGQL, data }] = useEventTranscriptQuery()
  const eventsGQL = data?.event?.items || []

  useEffect(() => {
    if (isNil(tickerId) || hideNews) return
    getNews({ variables: { tickerId, sortDir: 'desc', sortBy: 'date' }, })
  }, [hideNews, tickerId, getNews])

  /**
   * Get events and transcripts
   */
  useEffect(() => {
    if (!tickerId) return

    if (isSubscribed) {
      const endDate = moment().utc().toISOString()
      getEventsGQL({
        variables: {
          endDate: endDate,
          timezoneOffset: getTimeZoneOffsetMs(),
          tickerId: [tickerId],
          transcript,
          limit: 10,
          sortDir: 'desc'
        }
      })
    }

    return () => resetEvents()
  }, [transcript, legacySecurityId, getEvents, resetEvents, getEventsGQL])

  return (
    <SecurityRecentUpdateCard
      dataId={dataId}
      theme={theme}
      isLoading={eventsLoadingGQL}
      useGraphQL={true}
      data={{
        events: eventsGQL,
        news: get(news, 'news.items')
      }}
      transcript={transcript}
      hideNews={hideNews}
      hasTranscript={(value) => setTranscript(value)}
      openModal={openModal}
    />
  )
}

SecurityRecentUpdate.propTypes = propTypes
SecurityRecentUpdate.defaultProps = defaultProps

const mapStateToProps = (state) => ({
  profile: state.profile.data,
  eventsLoading: get(state, 'security.event.status') === statusType.IN_PROGRESS,
  events: get(state, 'security.event.data')
})

const mapDispatchToProps = (dispatch) => ({
  getEvents: bindActionCreators(getEvents, dispatch),
  resetEvents: bindActionCreators(resetEvents, dispatch),
  openModal: bindActionCreators(openModal, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SecurityRecentUpdate)
