import {
  PEER_LIST_FETCH_FAILURE,
  PEER_LIST_FETCH_REQUEST,
  PEER_LIST_FETCH_SUCCESS,
  IDLE,
  FETCHED,
  FETCHING,
  ERROR
} from '../../../actions/widget/watchlist/peer.actions'

const initial = {
  items: [],
  status: IDLE
}

const peers = (state = initial, action) => {
  switch (action.type) {
    case PEER_LIST_FETCH_REQUEST:
      return {
        ...state,
        status: FETCHING
      }
    case PEER_LIST_FETCH_SUCCESS:
      return {
        ...state,
        status: FETCHED,
        items: action.payload
      }
    case PEER_LIST_FETCH_FAILURE:
      return {
        ...state,
        error: action.error,
        status: ERROR
      }
    default:
      return state
  }
}

export default peers
