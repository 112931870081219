import {
  GET_ITINERARY_PDF_REQUEST,
  GET_ITINERARY_PDF_SUCCESS,
  GET_ITINERARY_PDF_FAILURE,
  statusType
} from '../../../actions'

const initial = {
  data: {},
  status: statusType.IDLE
}

const itinerary = (state = initial, action) => {
  const { payload = {} } = action

  switch (action.type) {
    case GET_ITINERARY_PDF_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_ITINERARY_PDF_SUCCESS:
      return {
        ...state,
        data: payload || {},
        status: statusType.SUCCESS
      }

    case GET_ITINERARY_PDF_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    default:
      return state
  }
}

export default itinerary
