import moment from 'moment-timezone'
import { PUBLIC_EVENT_TYPES } from '../../utils/calendar/calendar.const'

import {
  UPDATE_CALENDAR_FILTER,
  RESET_CALENDAR_FILTER,
  GET_SECURITY_FILTER_SUGGESTIONS_SUCCESS,
  RESET_SECURITY_FILTER_SUGGESTIONS
} from '../../actions'

const typeOptions = [
  { value: PUBLIC_EVENT_TYPES.EARNINGS_CALL, label: 'Earnings Call' },
  { value: PUBLIC_EVENT_TYPES.EARNINGS_RELEASE, label: 'Earnings Release' },
  { value: PUBLIC_EVENT_TYPES.GUIDANCE_CALL, label: 'Guidance' },
  { value: PUBLIC_EVENT_TYPES.SALES_CALL, label: 'Sales & Revenue Call' },
  { value: PUBLIC_EVENT_TYPES.SALES_RELEASE, label: 'Sales & Revenue Release' },
  { value: PUBLIC_EVENT_TYPES.CONFERENCE, label: 'Conference' },
  { value: PUBLIC_EVENT_TYPES.PRESENTATION, label: 'Presentation' },
  { value: PUBLIC_EVENT_TYPES.MEETING, label: 'Meetings' },
  { value: PUBLIC_EVENT_TYPES.OTHER, label: 'Other' }
]

const initial = {
  startDate: moment().startOf('month').startOf('day').subtract(1, 'week').toDate(),
  endDate: moment().endOf('month').endOf('day').add(1, 'week').toDate(),
  typeOptions, // type filter options
  type: [], // type filter selections
  securitySuggestions: [], // security filter search results
  security: [], // security filter selections
  modified: 0
}

const filter = (state = initial, action) => {
  const { payload } = action

  switch (action.type) {
    case UPDATE_CALENDAR_FILTER:
      return {
        ...state,
        ...payload,
        modified: state.modified++
      }

    case RESET_CALENDAR_FILTER:
      return {
        ...initial
      }

    case GET_SECURITY_FILTER_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        securitySuggestions: (payload || []).map((result) => ({
          _id: result._source.mongo_id,
          name: result._source.company_name,
          symbol: result._source.symbol,
          exchange: result._source.exchange
        })
        )
      }

    case RESET_SECURITY_FILTER_SUGGESTIONS:
      return {
        ...state,
        securitySuggestions: initial.securitySuggestions
      }

    default:
      return state
  }
}

export default filter
