export const USER_LOGOUT = 'USER_LOGOUT'

/**
 * User logged out
 * @private
 */
const _userLogout = () => ({
  type: USER_LOGOUT
})

/**
 * Dispatch USER_LOGOUT
 */
export const userLogout = () => (dispatch) => {
  return dispatch(_userLogout())
}
