import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

export const FETCH_ESTIMATES_RATING_REQUEST = 'FETCH_ESTIMATES_RATING_REQUEST'
export const FETCH_ESTIMATES_RATING_SUCCESS = 'FETCH_ESTIMATES_RATING_SUCCESS'
export const FETCH_ESTIMATES_RATING_ERROR = 'FETCH_ESTIMATES_RATING_ERROR'

export const FETCH_WIDGET_ESTIMATES_REQUEST = 'FETCH_WIDGET_ESTIMATES_REQUEST'
export const FETCH_WIDGET_ESTIMATES_SUCCESS = 'FETCH_WIDGET_ESTIMATES_SUCCESS'
export const FETCH_WIDGET_ESTIMATES_ERROR = 'FETCH_WIDGET_ESTIMATES_ERROR'

export const FETCHING = 'FETCHING'
export const IDLE = 'IDLE'
export const FETCHED = 'FETCHED'
export const FAILED = 'FAILED'
export const ERROR = 'ERROR'

const resources = {
  consensusDetails: '/estimates/v2/consensusdetails',
  rating: '/estimates/v2/rating'
}

const _fetchEstimatesRating = (url, payload) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_ESTIMATES_RATING_REQUEST,
      FETCH_ESTIMATES_RATING_SUCCESS,
      FETCH_ESTIMATES_RATING_ERROR
    ],
    payload,
    method: METHOD_TYPE.GET
  }
})

const _fetchConsensusDetails = (url, payload) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_WIDGET_ESTIMATES_REQUEST,
      FETCH_WIDGET_ESTIMATES_SUCCESS,
      FETCH_WIDGET_ESTIMATES_ERROR
    ],
    payload,
    method: METHOD_TYPE.GET
  }
})

export const fetchEstimates = (securityId) => {
  return (dispatch, getState) => {
    const state = getState()
    if (!securityId) {
      securityId = state.dashboard.dashboardState.securityId
    }

    const merticUrl = `${resources.consensusDetails}`

    dispatch(_fetchEstimatesRating(resources.rating, { securityId, metric: 'rating' }));
    ['eps', 'sales', 'ebitda', 'ffo'].forEach((metric) => {
      dispatch(_fetchConsensusDetails(merticUrl, { securityId, metric }))
    })
  }
}
