import './swapable.component.css'
import './swapable.doc.js'
import { isNil } from 'lodash'
import React, { memo, useMemo, useRef, useState } from 'react'
import { getClassName } from '../../utils'
import Ghostable from '../ghostable/ghostable.component'

const SwapableClassName = {
  Base: 'nui-swapable',
  BaseWithTransitionModifier: 'nui-swapable--transition',
  Layer: 'nui-swapable_layer',
}

/**
 * Swapable
 * @param {SwapableProps} props 
 * @returns {JSX.Element}
 */
const Swapable = (props) => {
  const { className, id, dataId, layers, selected, onSwapEnd, onSwapStart } = props;

  /** @type {[number, React.Dispatch<number>]} */ 
  const [height, setHeight] = useState(null);
  /** @type {React.MutableRefObject<HTMLDivElement>} */ 
  const swapableRef = useRef();

  const baseClassName = getClassName(SwapableClassName.Base, [
    { condition: !className, trueClassName: className },
    { condition: !!height, trueClassName: SwapableClassName.BaseWithTransitionModifier },
  ]);

  // #region Lifecycle Methods
  function handleSelectedChange() {
    setHeight(swapableRef.current?.clientHeight);
  }

  useMemo(handleSelectedChange, [selected]);
  // #endregion

  // #region Handle Methods
  function handleEnter(node) {
    setHeight(node.offsetHeight);
  }

  function handleEntered() {
    setHeight(null);

    !isNil(onSwapEnd) && onSwapEnd();
  }

  function handleExiting() {
    !isNil(onSwapStart) && onSwapStart();
  }
  // #endregion

  const heightStyle = {
    height,
  };

  return (
    <div className={baseClassName} id={id} data-id={dataId} style={heightStyle} ref={swapableRef}>
      {layers.map(
        (layer, index) => {
          return (
            <Ghostable
              key={`swapable-component-${index}`}
              ghosted={selected !== index}
              onEnter={handleEnter}
              onEntered={handleEntered}
              onExiting={handleExiting}
            >
              <div className={SwapableClassName.Layer}>{layer}</div>
            </Ghostable>
          );
        }
      )}
    </div>
  );
};

export default memo(Swapable);
