import {
  GET_ENTITY_OWNERSHIP_CHART_EVENT_REQUEST,
  GET_ENTITY_OWNERSHIP_CHART_EVENT_SUCCESS,
  GET_ENTITY_OWNERSHIP_CHART_EVENT_FAILURE,
  GET_ENTITY_OWNERSHIP_CHART_EVENT_REQUEST_GQL,
  GET_ENTITY_OWNERSHIP_CHART_EVENT_SUCCESS_GQL,
  GET_ENTITY_OWNERSHIP_CHART_EVENT_FAILURE_GQL,
  RESET_ENTITY_OWNERSHIP_CHART,
  statusType
} from '../../../../actions'

const moment = require('moment-timezone')
const initial = {
  data: [],
  status: statusType.IDLE
}

/**
 * Entity Ownership Chart - Events Reducer
 */
const events = (state = initial, action) => {
  const { payload } = action

  switch (action.type) {
    case GET_ENTITY_OWNERSHIP_CHART_EVENT_REQUEST:
    case GET_ENTITY_OWNERSHIP_CHART_EVENT_REQUEST_GQL:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }
    case GET_ENTITY_OWNERSHIP_CHART_EVENT_SUCCESS:
      return {
        data: (payload || [])
          .filter((record) => record.date && record.title)
          .map((record) => ({
            x: moment.utc(record.date).valueOf(),
            title: ' ',
            text: record.title,
            id: record._id
          }))
          .reverse(),
        status: statusType.SUCCESS
      }
    case GET_ENTITY_OWNERSHIP_CHART_EVENT_SUCCESS_GQL:
      return {
        data: (payload || [])
          .filter((record) => record.eventDate && record.eventTitle)
          .map((record) => ({
            x: parseInt(record.eventDate),
            title: ' ',
            text: record.eventTitle,
            id: record.id
          }))
          .reverse(),
        status: statusType.SUCCESS
      }

    case GET_ENTITY_OWNERSHIP_CHART_EVENT_FAILURE:
    case GET_ENTITY_OWNERSHIP_CHART_EVENT_FAILURE_GQL:
      return {
        status: statusType.ERROR
      }

    case RESET_ENTITY_OWNERSHIP_CHART:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default events
