import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { addQueryParams } from '../../../utils/http/http.util'

export const NLG_PEER_REQUEST = 'NLG_PEER_REQUEST'
export const NLG_PEER_SUCCESS = 'NLG_PEER_SUCCESS'
export const NLG_PEER_FAILURE = 'NLG_PEER_FAILURE'
export const NLG_SET_CURRENT_PEER = 'NLG_SET_CURRENT_PEER'

const resources = {
  peer: '/peer'
}

const _fetchPeers = (url) => ({
  [CALL_API]: {
    types: [NLG_PEER_REQUEST, NLG_PEER_SUCCESS, NLG_PEER_FAILURE],
    endpoint: url,
    method: METHOD_TYPE.GET
  }
})

const _setCurrentPeer = (currentPeer) => ({
  type: NLG_SET_CURRENT_PEER,
  payload: currentPeer
})

export const fetchPeers = (params) => (dispatch) => {
  const url = addQueryParams(resources.peer, params)
  return dispatch(_fetchPeers(url))
}

export const setCurrentPeer = (params) => (dispatch) => {
  return dispatch(_setCurrentPeer(params))
}
