import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Error } from '../index'
import { getClassName, THEMES } from '../../../utils/ui'

import './textField.component.scss'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT, THEMES.WHITE]),
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool
}

const defaultProps = {
  theme: THEMES.LIGHT,
  type: 'text',
  placeholder: '',
  autoComplete: 'off',
  loading: false,
  disabled: false,
  required: false,
  error: {}
}

/**
 * Get field value
 * min/max/maxValue does not restrict user's manual input when input type is 'number'
 * @param type
 * @param value
 * @param max
 */
function getValue ({ type, value, max }) {
  if (type === 'number' && max) {
    return (value > max) ? max : value
  }

  return value
}

/**
 * Text Field Component
 * @param props
 */
function TextField (props) {
  const {
    dataId, theme, label, id, type, name, placeholder, value, error: { isError, message }, min, max, maxLength,
    onChange, onBlur, onKeyDown, autoComplete, autoFocus, readOnly, loading, disabled, required
  } = props

  const baseClassName = getClassName('text-field', [
    { condition: theme, trueClassName: `text-field--${theme}` },
    { condition: isError, trueClassName: 'text-field--error' },
    { condition: readOnly, trueClassName: 'text-field--readonly' },
    { condition: disabled, trueClassName: 'text-field--disabled' }
  ])

  return (
    <div data-id={dataId} className={baseClassName}>
      {label && <label className='text-field_label'>
        {label} {!!required && <span className='text-field_label--required'>(required)</span>}
      </label>}
      <div className='text-field-input'>
        <input
          className='text-field_input'
          id={id}
          type={type}
          name={name}
          placeholder={placeholder}
          value={getValue({ type, value, max })}
          min={min}
          max={max}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          readOnly={readOnly}
          disabled={disabled}
        />
        {loading && <i className='text-field_loading-spinner' />}
      </div>

      {isError && <Error message={message} />}
    </div>
  )
}

TextField.propTypes = propTypes
TextField.defaultProps = defaultProps

export default memo(TextField)
