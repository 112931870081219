import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'

// actions
import { modalType } from '../../../../actions'

// components
import CustomCell from './cell/cell.component'
import { AgGrid, NoContentMessage, Spinner } from '../../../../components'
import { preventDefaultRowClick, setColumnDefinition, THEMES } from '../../../../utils'

import './table.component.scss'

const propTypes = {
  dataId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const defaultProps = {
  loading: false,
  data: []
}

const gridColumns = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'text',
    minWidth: 520,
    headerCheckboxSelection: true,
    checkboxSelection: true
  },
  {
    field: 'comment',
    headerName: '',
    type: 'centered',
    minWidth: 144,
    maxWidth: 144
  },
  {
    field: 'draggable',
    headerName: '',
    type: 'icon',
    minWidth: 100,
    maxWidth: 100,
    rowDrag: true
  }
]

/**
 * Briefing Book Table Component
 * @param props
 */
function BriefingBookTable (props) {
  const { dataId, loading, data, onEdit, onUpdate, openModal, history } = props
  const [agGrid, setAgGrid] = useState({})
  const [columns] = useState(gridColumns)

  /**
   * Disable drag and drop for book with single entity
   */
  useEffect(() => {
    if (!agGrid || !agGrid.api) {
      return
    }

    agGrid.api.setSuppressRowDrag(data.length === 1)
  }, [data, agGrid])

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  const handleGridReady = (grid) => {
    setAgGrid(grid)
    setTimeout(() => agGrid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  const handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      setTimeout(() => agGrid.api && agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row drag end event
   * @param event
   */
  const handleRowDragEnd = ({ type, node }) => {
    const { rowIndex } = node || {}
    const entity = node.data || {}
    const entityId = entity.entityId || null

    if (!entityId || type !== 'rowDragEnd') {
      return
    }

    const update = (data || []).filter((entity) => entity.entityId !== entityId)
    update.splice(rowIndex, 0, entity)

    onUpdate(update)
  }

  /**
   * Handle row click event
   * @param id {String}
   */
  const handleRowClick = ({ entityId, entityType }) => {
    history && history.push(`/${entityType}/${entityId}`)
  }

  /**
   * Handle AgGrid bulk delete
   */
  const handleBulkDelete = () => {
    const selectedRows = agGrid.api && agGrid.api.getSelectedRows()
    const selectedIds = (selectedRows || []).map((row) => row && row.entityId)

    if (!selectedIds.length) {
      return
    }

    const update = (data || []).filter((entity) => !selectedIds.includes(entity.entityId))

    openModal({
      type: modalType.CONFIRM_MODAL,
      props: {
        content: {
          title: `Delete ${(selectedIds.length > 1) ? 'Entities' : 'Entity'}?`,
          message: `Are you sure you want to delete ${(selectedIds.length > 1) ? 'these Entities' : 'this Entity'}?`
        },
        onConfirm: () => onUpdate(update, 'update')
      }
    })
  }

  /**
   * Handle entity comment
   * @param entity
   */
  const handleComment = (entity) => {
    const { entityId } = (entity || {})

    entityId && openModal({
      type: modalType.ENTITY_COMMENT_EDIT_MODAL,
      props: { entity }
    })
  }

  /**
   * Get NoContentMessage Props
   * @returns {{*}}
   */
  const getNoContentMessageProps = () => {
    return {
      title: 'No Entities',
      message: 'Start adding entities to this briefing book',
      actions: [{
        label: 'Add Entities',
        dataId: `${dataId}Edit`,
        onClick: onEdit
      }]
    }
  }

  /**
   * Renders CustomCell Component
   */
  const renderCustomCell = (props) => {
    return <CustomCell
      {...props}
      dataId={dataId}
      onComment={handleComment}
    />
  }

  return (
    <div className='grid_table briefing-book-detail-page_table'>
      {loading && <Spinner mask theme={THEMES.RAIN} />}
      {!data.length
        ? <NoContentMessage {...getNoContentMessageProps()} />
        : <AgGrid
          sizeToFit

          // suppress configs
          suppressMovableColumns
          suppressContextMenu

          // columns and data
          defaultColDef={{
            suppressMenu: true,
            cellRenderer: 'CustomCellRender'
          }}
          columnDefs={setColumnDefinition({ columns, isLocked: false, isAlternating: false })}
          rowData={data}

          // draggable rows
          rowDragManaged
          animateRows

          // bulk actions
          bulkActions={[{
            id: 'briefing-book-entity-delete',
            icon: 'q4i-trashbin-2pt',
            onSelect: handleBulkDelete
          }]}

          // custom components
          frameworkComponents={{
            CustomCellRender: renderCustomCell
          }}

          // event listeners
          onGridReady={handleGridReady}
          onGridSizeChanged={handleGridResize}
          onRowDragEnd={handleRowDragEnd}
          onRowClicked={preventDefaultRowClick(
            handleRowClick, ['ag-column--centered', 'ag-row-drag']
          )}
        />}
    </div>
  )
}

BriefingBookTable.propTypes = propTypes
BriefingBookTable.defaultProps = defaultProps

export default memo(BriefingBookTable)
