import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, isEqual } from 'lodash'
import moment from 'moment'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { config } from '../../../config'

// actions
import {
  getContacts,
  getContactLists,
  getContactListCount,
  createContactList,
  updateContactList,
  deleteContactList,
  addToAddressBook,
  removeFromAddressBook,
  downloadExportData,
  fetchInstitutionSuggestions,
  resetInstitutionSuggestions,
  fetchLocationSuggestions,
  resetLocationSuggestions,
  createToast,
  openModal,
  closeModal,
  modalType,
  statusType,
  CREATE_ADDRESS_BOOK_LIST_SUCCESS,
  CREATE_ADDRESS_BOOK_LIST_FAILURE,
  UPDATE_ADDRESS_BOOK_LIST_SUCCESS,
  UPDATE_ADDRESS_BOOK_LIST_FAILURE,
  DELETE_ADDRESS_BOOK_LIST_SUCCESS,
  DELETE_ADDRESS_BOOK_LIST_FAILURE,
  ADD_CONTACT_TO_ADDRESS_BOOK_SUCCESS,
  ADD_CONTACT_TO_ADDRESS_BOOK_FAILURE,
  REMOVE_CONTACT_FROM_ADDRESS_BOOK_SUCCESS,
  REMOVE_CONTACT_FROM_ADDRESS_BOOK_FAILURE,
  DOWNLOAD_EXPORT_ERROR,
  updateUserEmailPreference,
  setEmailRecipients,
  emailPreference
} from '../../../actions'

// components
import { Banner, Message, RenderHTML } from '../../../components'
import AddressBookSidebar from './sidebar/sidebar.component'
import AddressBookToolbar from './toolbar/toolbar.component'
import AddressBookGrid from './grid/grid.component'

import { launchDarklyHelper, LD_FEATURE_FLAGS } from '../../../services/launchDarkly.service'

import {
  combine,
  getActivityAttendee,
  getPageSizeFromStorage,
  addressBookBulkActions,
  ENTITY_CONTACT,
  CORPORATE_PARTICIPANT,
  MESSAGE,
  THEMES,
  ENTITY_TYPE,
  debounce,
  CRM_EMAIL_ROUTE,
  getEmailsForDefaultEmailClient,
  getRecipientsForCrmEmailClient,
  getCookie,
  getIsBrowser
} from '../../../utils'

import './landing.page.css'

/**
 * Confirmation Message Modal
 * @param onClose
 * @param title
 * @param message
 * @param label
 * @param onDeleteConfirm
 * @returns {*}
 * @constructor
 */
const ConfirmModal = ({ onClose, title, message, label, onDeleteConfirm }) => {
  return (
    <Message
      visible
      type='warning'
      title={title}
      message={message}
      onClose={onClose}
      buttons={[
        {
          ui: 'shaded',
          label: 'cancel',
          onClick: onClose
        },
        {
          ui: 'spice',
          label,
          onClick: () => onDeleteConfirm()
        }
      ]}
    />
  )
}

/**
 * Error Message Modal
 * @param onClose
 * @returns {*}
 * @constructor
 */
const ErrorModal = ({ onClose }) => {
  return (
    <Message
      visible
      type='error'
      title='Error'
      message={MESSAGE.ERROR}
      onClose={onClose}
      buttons={[
        {
          ui: 'spice',
          label: 'close',
          onClick: onClose
        }
      ]}
    />
  )
}

/**
 * Contact Landing Page
 */
class ContactLandingPage extends Component {
  initialFilterState = {
    institutions: [],
    locations: [],
    type: [],
    targets: false,
    deals: false
  }

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      tempFilters: this.initialFilterState,
      appliedFilters: this.initialFilterState,
      pageParams: {
        list: 'all',
        search: '',
        limit: getPageSizeFromStorage('address-book-grid') || 10,
        page: 1,
        sort: {
          property: 'full_name',
          direction: 'asc'
        }
      },
      selectedList: null
    }
  }

  /**
   * Component Did Mount
   */
  componentDidMount () {
    this.getData()
    this.initUserEmailPreference()
  }

  /**
   * Get Data
   */
  getData () {
    const { getContactLists, getContactListCount } = this.props
    return Promise.all([
      getContactLists(),
      getContactListCount(),
      this.getContacts()
    ])
  }

  /**
   * Init User Email Preference
   */
  initUserEmailPreference () {
    const isCrmEmailFeatureEnabled = !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.CRM_EMAIL)
    if (isCrmEmailFeatureEnabled) {
      const userEmailPreference = getCookie('user-email-preference')
      this.props.updateUserEmailPreference(userEmailPreference ?? emailPreference.INITIAL)
    }
  }

  /**
   * Get query params
   * @param params
   * @returns {*}
   */
  getQueryParams (params = {}) {
    const filters = {
      ...this.state.appliedFilters,
      ...this.state.pageParams,
      timezoneOffset: moment().utcOffset()
    }

    if (filters.institutions.length) {
      filters.institutions = filters.institutions.map(inst => inst.value)
    }

    if (filters.locations.length) {
      filters.locations = JSON.stringify(
        filters.locations.map(loc => loc.locationData)
      )
    }

    filters.sort = filters.sort
      ? JSON.stringify([filters.sort])
      : delete filters.sort
    return filters
  }

  /**
   * Get Contacts
   * @param query
   */
  getContacts (query = this.getQueryParams()) {
    const { getContacts } = this.props
    getContacts(query)
  }

  /**
   * Set tempFilters to new values
   * @param filters
   */
  handleFilterChange (filters) {
    this.setState({
      tempFilters: { ...this.state.tempFilters, ...filters }
    })
  }

  /**
   * Apply tempFilters and refetch the data
   */
  handleFilterApply () {
    this.setState(
      {
        pageParams: { ...this.state.pageParams, page: 1 },
        appliedFilters: this.state.tempFilters
      },
      () => this.getContacts()
    )
  }

  /**
   * Reset tempFilters  to initial state
   */
  handleFilterReset () {
    this.setState({ tempFilters: this.initialFilterState })
  }

  /**
   * Re-fetch contacts data based on the provided page parameters
   * @param params
   */
  handlePageParamsChange (params) {
    this.setState({ pageParams: { ...this.state.pageParams, ...params } }, () =>
      this.getContacts()
    )
  }

  /**
   * Handle grid csv export
   */
  handleExport () {
    const { selectedList, tempFilters } = this.state
    const { institutions, locations, type, targets, deals } = tempFilters
    const { downloadExportData } = this.props
    const fileName = get(selectedList, 'name', institutions || locations || type || targets || deals ? 'Filtered Contacts' : 'All Saved Contacts')

    downloadExportData({
      url: '/addressbook/export',
      contentType: 'text/csv',
      params: this.getQueryParams(),
      file: {
        name: fileName,
        type: 'csv'
      }
    }).then(data => {
      const { type } = data

      if (type === DOWNLOAD_EXPORT_ERROR) {
        this._handleFailure()
      }
    })
  }

  /**
   * Handle list create/edit
   * @param params.id
   * @param params.name
   */
  handleListEdit (params = {}) {
    const {
      createContactList,
      updateContactList,
      getContactLists,
      createToast
    } = this.props
    const id = params.id || null

    const request = id
      ? updateContactList(params)
      : createContactList({ name: params.name })

    request.then(data => {
      const { type } = data
      const success = [
        CREATE_ADDRESS_BOOK_LIST_SUCCESS,
        UPDATE_ADDRESS_BOOK_LIST_SUCCESS
      ].includes(type)
      const failure = [
        CREATE_ADDRESS_BOOK_LIST_FAILURE,
        UPDATE_ADDRESS_BOOK_LIST_FAILURE
      ].includes(type)

      if (success) {
        createToast({
          text: `List ${id ? 'updated' : 'created'} successfully.`
        })
        getContactLists()
      }

      failure && this._handleFailure()
    })
  }

  /**
   * Handle list delete
   * @param list
   */
  handleListDelete (list) {
    const { openModal } = this.props

    openModal({
      component: ({ onClose }) =>
        ConfirmModal({
          onClose,
          title: 'Delete Contact List?',
          message: 'Are you sure you want to delete this list?',
          label: 'Delete',
          onDeleteConfirm: this.onListDelete.bind(this, list && list._id)
        })
    })
  }

  /**
   * On contact list delete
   * @param id
   */
  onListDelete (id) {
    const { pageParams } = this.state
    const { deleteContactList, getContactLists, createToast } = this.props

    this._handleMessageClose()

    id &&
      deleteContactList(id).then(data => {
        const { type } = data
        if (type === DELETE_ADDRESS_BOOK_LIST_SUCCESS) {
          createToast({ text: 'List deleted successfully.' })
          if (id === pageParams.list) {
            this.setState({ pageParams: { ...pageParams, list: 'all' } })
            this.getContacts()
          }
          getContactLists()
        } else if (type === DELETE_ADDRESS_BOOK_LIST_FAILURE) {
          this._handleFailure()
        }
      })
  }

  /**
   * Add contact to address book
   * @param contact
   */
  addToAddressBook (contact) {
    const { addToAddressBook, createToast } = this.props
    const id = contact && contact._id

    id &&
      addToAddressBook({ contact: [].concat(id) }).then(data => {
        const { type } = data
        if (type === ADD_CONTACT_TO_ADDRESS_BOOK_SUCCESS) {
          createToast({ text: 'Contact saved successfully to Address Book.' })
          this.getData()
        } else if (type === ADD_CONTACT_TO_ADDRESS_BOOK_FAILURE) {
          this._handleFailure()
        }
      })
  }

  /**
   * Handle bulk actions based on action id
   * @param actionId
   * @param contacts
   */
  handleBulkAction (actionId, contacts) {
    const entities = (contacts || []).map(
      contact =>
        contact && {
          id: contact._id,
          entityId: contact._id,
          entityType: contact.reference && contact.reference.type,
          institutionId: get(contact, 'jobs[0].q4_entity_id')
        }
    )

    if (!actionId || !entities || !entities.length) {
      return
    }

    switch (actionId) {
      case addressBookBulkActions.DELETE_CONTACTS:
        return this.handleBulkDelete(entities)
      case addressBookBulkActions.EMAIL:
        return this.handleBulkEmail(contacts)
      case addressBookBulkActions.ADD_TO_LIST:
        return this.handleAddToAddressBook(entities)
      case addressBookBulkActions.CREATE_ACTIVITY:
        return this.handleActivityCreate(contacts)
      case addressBookBulkActions.ADD_TO_BRIEFING_BOOK:
        return this.handleAddToBriefingBook(entities)
      default:
        break
    }
  }

  /**
   * Handles bulk delete - remove contacts from address book
   * @param entities - contacts to remove
   */
  handleBulkDelete (entities) {
    const { openModal } = this.props

    openModal({
      component: ({ onClose }) =>
        ConfirmModal({
          onClose,
          title: `Remove ${entities.length > 1 ? 'Contacts' : 'Contact'}?`,
          message: <RenderHTML html={this.getConfirmMessage(entities)} />,
          label: 'Remove',
          onDeleteConfirm: this.onBulkDeleteConfirm.bind(this, entities)
        })
    })
  }

  /**
   * Get remove/delete from address book confirmation message
   * @param entities
   * @returns {string}
   */
  getConfirmMessage (entities) {
    const { pageParams } = this.state

    const contacts = (entities || []).filter(
      entity => entity.entityType === ENTITY_CONTACT
    ).length
    const participants = (entities || []).filter(
      entity => entity.entityType === CORPORATE_PARTICIPANT
    ).length

    const contact = contacts > 1 ? 'contacts' : 'contact'
    const participant = participants > 1 ? 'participants' : 'participant'

    if (contacts && participants) {
      return pageParams.list === 'all'
        ? `Are you sure you want to remove selected ${contact} from Address Book and permanently delete selected corporate ${participant}?`
        : `Are you sure you want to remove selected ${contact} and corporate ${participant} from this list?`
    } else if (contacts && !participants) {
      return pageParams.list === 'all'
        ? `Are you sure you want to remove selected ${contact} from Address Book?`
        : `Are you sure you want to remove selected ${contact} from this list?`
    } else if (!contacts && participants) {
      return pageParams.list === 'all'
        ? `Are you sure you want to permanently delete selected ${participant}?`
        : `Are you sure you want to remove selected ${participant} from this list?`
    }
  }

  /**
   * Handle bulk delete confirm message
   * @param entities
   */
  onBulkDeleteConfirm (entities) {
    const { pageParams } = this.state
    const {
      removeFromAddressBook,
      getContactListCount,
      createToast
    } = this.props
    const ids = [].concat(entities || []).map(entity => entity && entity.id)

    this._handleMessageClose()

    ids &&
      removeFromAddressBook({
        list: pageParams.list === 'all' ? null : pageParams.list,
        contact: ids
      }).then(data => {
        const { type } = data
        if (type === REMOVE_CONTACT_FROM_ADDRESS_BOOK_SUCCESS) {
          createToast({
            text: `${
              ids.length > 1 ? 'Contacts' : 'Contact'
            } removed successfully from ${
              pageParams.list === 'all' ? 'Address Book' : 'the list'
            }.`
          })
          this.getContacts()
          getContactListCount()
        } else if (type === REMOVE_CONTACT_FROM_ADDRESS_BOOK_FAILURE) {
          this._handleFailure()
        }
      })
  }

  /**
   * Handle bulk email
   * @param {object[]} contacts
   */
  handleBulkEmail (contacts) {
    const isCrmEmailFeatureEnabled = !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.CRM_EMAIL)
    const browser = getIsBrowser();
    const isCompatibleBrowser = browser.isChrome || browser.isEdge || browser.isEdgeChromium;
    const { userEmailPreference } = this.props

    if (!isCrmEmailFeatureEnabled || !isCompatibleBrowser || userEmailPreference === emailPreference.DEFAULT_EMAIL) {
      const emails = getEmailsForDefaultEmailClient(contacts)

      if (emails && emails.length) {
        window.location.href = `mailto:${combine(
        emails,
        ';'
        )}?Subject=Contact%20from%20Q4%20Desktop&bcc=${config.loggerEmail}`
      }
    } else if (isCrmEmailFeatureEnabled && userEmailPreference === emailPreference.CRM_EMAIL) {
      const recipients = getRecipientsForCrmEmailClient(contacts)
      this.props.setEmailRecipients(recipients)
      this.props.history.push(CRM_EMAIL_ROUTE.EMAIL)
    } else if (isCrmEmailFeatureEnabled && userEmailPreference === emailPreference.INITIAL) {
      this.props.openModal({
        type: modalType.EMAIL_PREFERENCE_MODAL,
        props: {
          contacts
        }
      })
    }
  }

  /**
   * Handle single email click
   * @param {object} contact
   */
  handleEmailClick = (contact) => {
    const isCrmEmailFeatureEnabled = !!launchDarklyHelper.getLocalFlag(LD_FEATURE_FLAGS.CRM_EMAIL)
    const browser = getIsBrowser();
    const isCompatibleBrowser = browser.isChrome || browser.isEdge || browser.isEdgeChromium;
    const { userEmailPreference } = this.props

    if (!isCrmEmailFeatureEnabled || !isCompatibleBrowser || userEmailPreference === emailPreference.DEFAULT_EMAIL) {
      const emails = getEmailsForDefaultEmailClient([contact])
      const email = emails[0]
      if (email) {
        const href = `mailto:${email}?Subject=Contact%20from%20Q4%20Desktop&bcc=${config.loggerEmail}`
        window.location.href = href
      }
    } else if (isCrmEmailFeatureEnabled && userEmailPreference === emailPreference.CRM_EMAIL) {
      const recipients = getRecipientsForCrmEmailClient([contact])
      if (recipients?.length) {
        this.props.setEmailRecipients(recipients)
        this.props.history.push(CRM_EMAIL_ROUTE.EMAIL)
      }
    } else if (isCrmEmailFeatureEnabled && userEmailPreference === emailPreference.INITIAL) {
      this.props.openModal({
        type: modalType.EMAIL_PREFERENCE_MODAL,
        props: {
          contacts: [contact]
        }
      })
    }
  }

  /**
   * Add contacts to address book lists
   * @param entities - of contacts to add to Address Book lists
   */
  handleAddToAddressBook (entities) {
    const { openModal } = this.props

    openModal({
      type: modalType.ADD_TO_ADDRESS_BOOK_MODAL,
      props: {
        contactIds:
          entities && Array.isArray(entities)
            ? entities.map(entity => entity.id)
            : null,
        onSaveSuccess: () => this.getData()
      }
    })
  }

  /**
   * Map attendees and participants for Activity
   * @param contacts
   * @returns {*}
   */
  getActivityItems (contacts) {
    const links = []
    const participants = []

    contacts.forEach(contact => {
      const type = (contact.reference && contact.reference.type) || ''

      switch (type) {
        case ENTITY_CONTACT:
          return links.push({
            entityType: ENTITY_CONTACT,
            entityId: contact.factset_person_id,
            name: contact.full_name,
            jobs: [].concat(contact.jobs || []),
            _id: contact._id
          })
        case CORPORATE_PARTICIPANT:
          return participants.push({
            entityType: CORPORATE_PARTICIPANT,
            name: contact.full_name,
            _corporateparticipant: contact._id,
            _id: contact._id
          })
        default:
          return null
      }
    })

    return { links, participants }
  }

  /**
   * Create an Activity with selected contacts
   * @param contacts
   */
  handleActivityCreate (contacts) {
    const { openModal } = this.props
    const entities = this.getActivityItems(contacts) || {}

    openModal({
      type: modalType.ACTIVITY_MODAL,
      props: {
        isParentOptionHidden: true,
        values: {
          links: (entities.links || [])
            .map(contact => getActivityAttendee(contact.entityType, contact))
            .reduce((accumulator, value) => {
              ;(value.links || []).forEach(
                link => link && accumulator.push(link)
              )
              return accumulator
            }, []),
          participants: entities.participants
        }
      }
    })
  }

  /**
   * Add contacts to Briefing Book
   * @param entities - of contacts to add to BB
   */
  handleAddToBriefingBook (entities) {
    const { openModal } = this.props

    openModal({
      type: modalType.ADD_TO_BRIEFING_BOOK_MODAL,
      props: {
        entities: (entities || [])
          .filter(entity => entity.entityType === ENTITY_CONTACT)
          .map(entity => ({
            entityId: entity.entityId,
            entityType: ENTITY_TYPE.CONTACT,
            institutionId:
              entity.institutionId && entity.institutionId.length
                ? entity.institutionId
                : null
          }))
      }
    })
  }

  /**
   * Handle close message modal
   * @private
   */
  _handleMessageClose () {
    const { closeModal } = this.props
    closeModal({
      component: ConfirmModal
    })
  }

  /**
   * On Request Failure display error
   */
  _handleFailure () {
    const { openModal } = this.props
    openModal({
      component: ErrorModal
    })
  }

  /**
   * Get Institution Suggestions
   * @param query
   */
  getInstitutionSuggestions = debounce(query => {
    const { fetchInstitutionSuggestions } = this.props
    fetchInstitutionSuggestions && fetchInstitutionSuggestions(query)
  }, 600)

  /**
   * Get Location Suggestions
   * @param query
   */
  getLocationSuggestions = debounce(query => {
    const { fetchLocationSuggestions } = this.props
    fetchLocationSuggestions && fetchLocationSuggestions(query)
  }, 600)

  /**
   * Render Contact Landing Component
   * @returns {*}
   */
  render () {
    const { appliedFilters, tempFilters, pageParams } = this.state
    const {
      contacts: { data, total, initialLoading },
      addressBookFilters: {
        institutionData,
        institutionLoading,
        locationData,
        locationLoading
      },
      resetInstitutionSuggestions,
      resetLocationSuggestions,
      contactsLoading,
      lists,
      listCount,
      listsLoading,
      exportContacts,
      history,
      openModal
    } = this.props

    const filterControls = {
      handleFilterApply: this.handleFilterApply.bind(this),
      handleFilterReset: this.handleFilterReset.bind(this),
      applyIsDisabled: isEqual(appliedFilters, tempFilters),
      resetIsDisabled: isEqual(tempFilters, this.initialFilterState)
    }

    const institutionSelector = {
      getInstitutionSuggestions: this.getInstitutionSuggestions,
      institutionSuggestions: institutionData || [],
      institutionLoading,
      resetInstitutionSuggestions
    }

    const locationSelector = {
      getLocationSuggestions: this.getLocationSuggestions,
      locationSuggestions: locationData || [],
      locationLoading,
      resetLocationSuggestions
    }
    return (
      <div className='address-book-page'>
        <Banner
          title='Contact Address Book'
          icon='q4i-contact-2pt'
          controls={[
            {
              dataId: 'AddressBookPageBannerUtility',
              type: 'utility',
              theme: THEMES.CITRUS,
              icon: 'q4i-add-4pt',
              items: [
                {
                  dataId: 'AddressBookPageBannerUtilityContactSave',
                  icon: 'q4i-contact-list-2pt',
                  label: 'Save to Address Book',
                  onClick: this.handleAddToAddressBook.bind(this, null)
                },
                {
                  dataId: 'AddressBookPageBannerUtilityContactCreate',
                  icon: 'q4i-custom-2pt',
                  label: 'Create Custom Contact',
                  onClick: () =>
                    openModal({
                      type: modalType.CONTACT_MODAL,
                      props: {
                        onSelectSuccess: this.addToAddressBook.bind(this)
                      }
                    })
                }
              ]
            }
          ]}
        />
        <div className='address-book-page_body'>
          <AddressBookSidebar
            institutionSelector={institutionSelector}
            locationSelector={locationSelector}
            lists={lists}
            listCount={listCount}
            loading={listsLoading}
            contactsLoading={contactsLoading}
            onListSelect={list => this.setState({ selectedList: list })}
            onListEdit={this.handleListEdit.bind(this)}
            onListDelete={this.handleListDelete.bind(this)}
            filters={tempFilters}
            filterControls={filterControls}
            pageParams={pageParams}
            onFilterChange={this.handleFilterChange.bind(this)}
            onParamsChange={this.handlePageParamsChange.bind(this)}
            openModal={openModal}
          />

          <div className='address-book-page_grid'>
            <AddressBookToolbar
              noData={!data || !data.length}
              pageParams={pageParams}
              onParamsChange={this.handlePageParamsChange.bind(this)}
              onExport={this.handleExport.bind(this)}
              exportProgress={exportContacts.status === statusType.IN_PROGRESS}
            />
            <AddressBookGrid
              loading={contactsLoading}
              isGridReady={!initialLoading}
              pageParams={pageParams}
              data={data}
              total={total}
              onParamsChange={this.handlePageParamsChange.bind(this)}
              onBulkAction={this.handleBulkAction.bind(this)}
              history={history}
              handleEmailClick={this.handleEmailClick}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    contacts: get(state, 'addressBook.contacts'),
    contactsLoading: get(state, 'addressBook.contacts.status') === statusType.IN_PROGRESS,
    lists: get(state, 'addressBook.lists.data'),
    listCount: get(state, 'addressBook.lists.count'),
    listsLoading: get(state, 'addressBook.lists.status') === statusType.IN_PROGRESS,
    exportContacts: get(state, 'addressBook.exportContacts'),
    addressBookFilters: get(state, 'addressBook.addressBookFilters'),
    userEmailPreference: get(state, 'addressBook.contacts.userEmailPreference')
  }
}

const mapDispatchToProps = dispatch => ({
  getContacts: bindActionCreators(getContacts, dispatch),
  getContactLists: bindActionCreators(getContactLists, dispatch),
  getContactListCount: bindActionCreators(getContactListCount, dispatch),
  createContactList: bindActionCreators(createContactList, dispatch),
  updateContactList: bindActionCreators(updateContactList, dispatch),
  deleteContactList: bindActionCreators(deleteContactList, dispatch),
  addToAddressBook: bindActionCreators(addToAddressBook, dispatch),
  removeFromAddressBook: bindActionCreators(removeFromAddressBook, dispatch),
  downloadExportData: bindActionCreators(downloadExportData, dispatch),
  fetchInstitutionSuggestions: bindActionCreators(fetchInstitutionSuggestions, dispatch),
  resetInstitutionSuggestions: bindActionCreators(resetInstitutionSuggestions, dispatch),
  fetchLocationSuggestions: bindActionCreators(fetchLocationSuggestions, dispatch),
  resetLocationSuggestions: bindActionCreators(resetLocationSuggestions, dispatch),
  createToast: bindActionCreators(createToast, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch),
  updateUserEmailPreference: bindActionCreators(updateUserEmailPreference, dispatch),
  setEmailRecipients: bindActionCreators(setEmailRecipients, dispatch)
})

export default withRouter(withLDConsumer()(connect(mapStateToProps, mapDispatchToProps)(ContactLandingPage)))
