import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { ENTITY_TYPE, OWNERSHIP_TYPE } from '../../../utils'

export const GET_INST_CURRENT_HOLDINGS = gql`
  query InstitutionHoldingCurrent(
    $entityId: [String]!
    $search: String
    $sector: [String]
    $capGroup: [String]
    $region: [String]
    $source: String = "${OWNERSHIP_TYPE['13F']}"
    $sortBy: String
    $sortDir: String
    $page: Int!
    $limit: Int!
    $currencyCode: String
  ) {
    instHoldingCurrent(
      institutionId: $entityId
      search: $search
      filter: {
        securitySector: $sector
        securityCapGroup: $capGroup
        securityRegion: $region
      }
      source: $source
      sortBy: $sortBy
      sortDir: $sortDir
      page: $page
      limit: $limit
      currencyCode: $currencyCode
    ) {
      items {
        tickerId
        securityName
        securityIndustry
        securityCapGroup
        securitySector
        securityRegion
        securityCountryName
        securityCountryCode
        securityActive
        filingSource
        filingOrigin
        filingType
        current
        change
        qtrChange
        marketValue
        marketValueChange
        marketValueQtrChange
        percentTSO
        percentPortfolio
        reportDate
        currentQtrDate
        prevReportDate
      }
      count
    }
  }`

export const GET_FUND_CURRENT_HOLDINGS = gql`
  query FundHoldingCurrent(
    $entityId: [String]!
    $search: String
    $sector: [String]
    $capGroup: [String]
    $region: [String]
    $source: String = "${OWNERSHIP_TYPE['13F']}"
    $currencyCode: String
    $sortBy: String
    $sortDir: String
    $page: Int!
    $limit: Int!
  ) {
    fundHoldingCurrent(
      fundId: $entityId
      search: $search
      source: $source
      currencyCode: $currencyCode
      filter: {
        securitySector: $sector
        securityCapGroup: $capGroup
        securityRegion: $region
      }
      sortBy: $sortBy
      sortDir: $sortDir
      page: $page
      limit: $limit
    ) {
      items {
        tickerId
        securityName
        securityIndustry
        securityCapGroup
        securitySector
        securityRegion
        securityCountryName
        securityCountryCode
        filingType
        current
        change
        qtrChange
        marketValue
        marketValueChange
        marketValueQtrChange
        percentTSO
        percentPortfolio
        reportDate
        currentQtrDate
        prevReportDate
        holderType
      }
      count
    }
  }`

/**
 * Current Holdings
 * @param type
 * @param options
 */
export function useCurrentQuery (type, options) {
  const query = (type === ENTITY_TYPE.FUND) ? GET_FUND_CURRENT_HOLDINGS : GET_INST_CURRENT_HOLDINGS
  return useQuery(query, options)
}
