import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'

/**
 * PeerAnalysis Action Types
 * @type {string}
 */
export const GET_PEERS_ANALYSIS_REQUEST = 'peerAnalysis/GET_PEERS_REQUEST'
export const GET_PEERS_ANALYSIS_SUCCESS = 'peerAnalysis/GET_PEERS_SUCCESS'
export const GET_PEERS_ANALYSIS_FAILURE = 'peerAnalysis/GET_PEERS_FAILURE'

/**
 * Get Peers Analysis
 * @return {{}}
 * @private
 */
const _getPeerAnalysis = (entityId, params) => ({
  [CALL_API]: {
    types: [
      GET_PEERS_ANALYSIS_REQUEST,
      GET_PEERS_ANALYSIS_SUCCESS,
      GET_PEERS_ANALYSIS_FAILURE
    ],
    method: METHOD_TYPE.GET,
    endpoint: `/ownership/v2/institution/${entityId}/peers`,
    payload: params
  }
})

/**
 * Dispatch GET_PEERS_ANALYSIS_REQUEST
 */
export const fetchPeerAnalysis = (entityId, params) => (dispatch) => {
  if (!params || !entityId || !params.securityId) {
    return
  }
  return dispatch(_getPeerAnalysis(entityId, params))
}
