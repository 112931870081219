import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect, withRouter } from 'react-router-dom'

// actions
import { useContactQuery, useTargetQuery, useDealQuery, usePipelineQuery } from '../hook'
import { getContactWebcast, removeCustomContact, openModal, closeModal, statusType } from '../../../actions'

// components
import ContactDetailBanner from '../detail/banner/banner.component'
import ContactDetailOverview from '../detail/overview/overview.component'
import { SectionTab } from '../../../components/shared'
import {
  ActivityGrid,
  ContactCoverageGrid,
  ContactResearchGrid,
  ContactWebcastGrid,
  FundGrid,
  Spinner
} from '../../../components'

import { ENTITY_TYPE, getActiveTicker, getEaIntegrationEnabledFlag, isSubscribed, THEMES } from '../../../utils'
import { get } from 'lodash'

// Data Ids
import { DATA_IDS } from '../../../tests-e2e/pages/contact/contact.definition'

const {
  ACTIVITY_TAB,
  ACTIVITY_GRID,
  FUND_TAB,
  FUND_GRID,
  WEBCAST_TAB,
  WEBCAST_GRID,
  COVERAGE_TAB,
  COVERAGE_GRID,
  RESEARCH_TAB,
  RESEARCH_GRID
} = DATA_IDS

const dataIdPrefix = 'ContactPage'

const propTypes = {}
const defaultProps = {}

const { CONTACT } = ENTITY_TYPE
const TABS = {
  ACTIVITY: 'activity',
  WEBCAST: 'webcast',
  COVERAGE: 'coverage',
  RESEARCH: 'research'
}

/**
 * Contact Profile Page
 * @param props
 */
function ContactDetailPage (props) {
  const {
    match, securityId, isTargetSuccess, removeCustomContact, webcast, getContactWebcast,
    estimateSubscription, researchSubscription, spSubscription, webcastSubscription, openModal, closeModal, history
  } = props
  const contactId = get(match, 'params.id')

  const { loading, error, data, refetch } = useContactQuery({
    variables: { id: contactId, securityId }
  })

  const contact = get(data, 'contact.items[0]', {})
  const { id, source, status, managedFunds, coverage } = contact

  const [getTarget, { data: target }] = useTargetQuery({
    variables: { entityId: id }
  })

  const { loading: isPipeline, data: stages } = usePipelineQuery()
  const [getDeals, { loading: isDeals, data: deal }] = useDealQuery({
    variables: { entityId: id }
  })

  const targetId = get(target, 'target.items[0].id', null)
  const deals = get(deal, 'deal.items', [])
  const pipeline = get(stages, 'pipeline.items', [])
  const lost = pipeline.find((item) => item.lost)
  const activeDeal = deals.find((deal) => deal.stage !== (lost && lost.id))
  const gdpr = status === 'gdpr'

  useEffect(() => {
    if (!id) {
      return
    }

    getTarget()
    getDeals()
    getContactWebcast({ id })
  }, [id, getTarget, getDeals, getContactWebcast])

  useEffect(() => {
    isTargetSuccess && getTarget()
  }, [isTargetSuccess, getTarget])

  const handleDealUpdate = () => {
    getTarget()
    getDeals()
  }

  return (
    <>
      {(error || (data && !id)) && <Redirect to='/error/404' />}
      {loading && <Spinner mask theme={THEMES.DARK} />}
      {!!id && (
        <div className='contact-page'>
          <ContactDetailBanner
            dataIdPrefix={dataIdPrefix}
            contact={contact}
            targetId={targetId}
            hasActiveDeal={!!activeDeal}
            onDealSaveSuccess={handleDealUpdate}
            onContactUpdate={refetch}
            removeCustomContact={removeCustomContact}
            openModal={openModal}
            closeModal={closeModal}
            history={history}
          />
          <ContactDetailOverview
            dataIdPrefix={dataIdPrefix}
            contact={contact}
            targetId={targetId}
            hasActiveDeal={!!activeDeal}
            deals={{
              isLoading: [isDeals, isPipeline].some((item) => !!item),
              deals,
              pipeline
            }}
            onDealSaveSuccess={handleDealUpdate}
            isWebcast={webcastSubscription && !!(webcast && webcast.length)}
            gdpr={gdpr}
          />
          <SectionTab
            theme={THEMES.RAIN}
            tabs={[
              {
                id: TABS.ACTIVITY,
                label: 'Activity',
                dataId: {
                  label: ACTIVITY_TAB,
                  view: ACTIVITY_GRID
                },
                view: (
                  <ActivityGrid
                    dataIdPrefix={dataIdPrefix}
                    entityId={id}
                    entityType={CONTACT}
                    entity={contact}
                    showChildren
                  />
                )
              },
              {
                id: TABS.FUND,
                label: 'Managed Funds',
                dataId: {
                  label: FUND_TAB,
                  view: FUND_GRID
                },
                view: (
                  <FundGrid
                    dataIdPrefix={dataIdPrefix}
                    id={(managedFunds || []).map((fund) => fund.fundId).filter((id) => id)}
                  />
                ),
                hidden: gdpr || coverage
              },
              {
                id: TABS.WEBCAST,
                label: 'Webcast',
                dataId: {
                  label: WEBCAST_TAB,
                  view: WEBCAST_GRID
                },
                view: (
                  <ContactWebcastGrid
                    id={id}
                  />
                ),
                hidden: !webcastSubscription || gdpr || getEaIntegrationEnabledFlag()
              },
              {
                id: TABS.COVERAGE,
                label: 'Coverage',
                dataId: {
                  label: COVERAGE_TAB,
                  view: COVERAGE_GRID
                },
                view: (
                  <ContactCoverageGrid
                    id={id}
                  />
                ),
                hidden: !estimateSubscription || spSubscription || gdpr || !(source === 'factset' && coverage)
              },
              {
                id: TABS.RESEARCH,
                label: 'Research',
                dataId: {
                  label: RESEARCH_TAB,
                  view: RESEARCH_GRID
                },
                view: (
                  <ContactResearchGrid
                    id={id}
                  />
                ),
                hidden: !researchSubscription || spSubscription || gdpr
              }
            ]}
          />
        </div>
      )}
    </>
  )
}

ContactDetailPage.propTypes = propTypes
ContactDetailPage.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)

  return {
    securityId: get(ticker, 'q4_entity_id', '1234567890'), // TODO: tmp default value for pre-IPO
    webcast: get(state, 'contact.webcast.data'),
    isTargetSuccess: get(state, 'targeting.target.status') === statusType.SUCCESS,
    estimateSubscription: isSubscribed(profile, 'estimates'),
    researchSubscription: isSubscribed(profile, 'research'),
    webcastSubscription: isSubscribed(profile, 'webcast'),
    spSubscription: isSubscribed(profile, 'estimates_research')
  }
}

const mapDispatchToProps = (dispatch) => ({
  getContactWebcast: bindActionCreators(getContactWebcast, dispatch),
  removeCustomContact: bindActionCreators(removeCustomContact, dispatch),
  openModal: bindActionCreators(openModal, dispatch),
  closeModal: bindActionCreators(closeModal, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactDetailPage))
