import { CALL_API, METHOD_TYPE } from '../../middleware/api.middleware'
import { isParent, BRIEFING_BOOK_TYPE, ENTITY_TYPE } from '../../utils'
import _ from 'lodash'

export const CREATE_BRIEFINGBOOK_REQUEST = 'CREATE_BRIEFINGBOOK_REQUEST'
export const CREATE_BRIEFINGBOOK_SUCCESS = 'CREATE_BRIEFINGBOOK_SUCCESS'
export const CREATE_BRIEFINGBOOK_FAILURE = 'CREATE_BRIEFINGBOOK_FAILURE'

const _create = (params, options) => ({
  [CALL_API]: {
    types: options.types || [
      CREATE_BRIEFINGBOOK_REQUEST,
      CREATE_BRIEFINGBOOK_SUCCESS,
      CREATE_BRIEFINGBOOK_FAILURE
    ],
    method: METHOD_TYPE.POST,
    endpoint: '/briefingbook',
    payload: { ...params, limit: options.limit || 25 }
  }
})

/**
 * Redux Action to Create Briefing Book From Activity Record
 * @param activity
 * @param options
 */
export const createBriefingBookFromActivity = (activity, options = {}) => (dispatch) => {
  /**
   * Briefing Book reference object
   */
  const reference = (attendee) => {
    const type = attendee && (attendee.entity_type || '').toLowerCase()
    const isContact = (type === ENTITY_TYPE.CONTACT)

    return _.omitBy({
      type: attendee.entity_type,
      item: isContact ? attendee._id : null,
      q4_entity_id: !isContact ? attendee.q4_entity_id : null,
      ...(isContact && { q4_institution_id: attendee.q4_institution_id || null })
    }, _.isNull)
  }

  /**
   * Returns Briefing Book references
   * @param activity
   */
  const getReferences = (activity) => {
    return (activity.links || []).map(reference)
  }

  /**
   * Returns Briefing Book itinerary references
   * @param activity
   */
  const getItineraryReferences = (activity) => {
    return _.uniqBy(
      (activity._itinerary || []).reduce((acc, curr) => acc.concat(curr.links), []).map(reference),
      (attendee) => attendee.q4_entity_id || attendee.item
    )
  }

  return dispatch(_create({
    _note: activity._id,
    title: activity.title,
    hasOrderedReferences: true,
    reference: isParent(activity.category) ? getItineraryReferences(activity) : getReferences(activity),
    type: BRIEFING_BOOK_TYPE.BRIEFING_BOOK
  }, options))
}
