import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { modalType } from '../../../actions'
import { Card, Select } from '../../index'
import { format, formatDate, defaultIfEmpty, ENTITY_TYPE } from '../../../utils'
import { get } from 'lodash'

import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import './deal.component.scss'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool,
  entityType: PropTypes.oneOf([CONTACT, FUND, INSTITUTION]).isRequired,
  deals: PropTypes.array,
  deal: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    amount: PropTypes.number,
    start: PropTypes.object,
    end: PropTypes.object
  }).isRequired,
  pipeline: PropTypes.array,
  onDealSelect: PropTypes.func.isRequired,
  onDealUpdate: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
}

const defaultProps = {
  isLoading: false,
  isUpdate: false,
  deals: [],
  deal: {},
  pipeline: []
}

/**
 * Header Action Component
 * @param props
 */
function Action ({ isLoading, theme, id, deals, onDealSelect }) {
  const options = (deals || []).map((deal) => deal && {
    value: deal.id,
    label: deal.title
  })
  const value = (options || []).find((option) => option.value === id)

  return (deals.length > 1)
    ? <Select
      dataId={{
        inputId: 'DealCardSelectDealInput',
        menuId: 'DealCardSelectDealMenu'
      }}
      theme={theme}
      size='thin'
      placeholder=''
      value={value}
      options={options}
      onChange={(option) => option && onDealSelect(option.value)}
      searchable={false}
      clearable={false}
      disabled={isLoading || deals.length < 2}
    /> : null
}

/**
 * Pipeline Dropdown Component
 * @param props
 */
function Pipeline ({ isUpdate, theme, stage, pipeline, onDealUpdate, openModal, closeModal }) {
  const options = (pipeline || []).map((item) => item && {
    value: item.id,
    label: item.label
  })
  const value = (options || []).find((option) => stage && (option.value === stage))

  /**
   * Handle deal update
   * @param value
   */
  function handleDealUpdate (value) {
    if (!value || value === stage) {
      return
    }

    const newStage = (pipeline || []).find((item) => item.id === value)
    newStage.lost
      ? openModal({
        type: modalType.CONFIRM_MODAL,
        props: {
          content: {
            title: 'Remove from Pipeline?',
            message: `Changing the stage to 'Objective Not Met' will hide the Deal 
            from the Investor Pipeline and all Potential Investor profiles.`,
            label: 'Remove'
          },
          onConfirm: () => {
            closeModal({
              type: modalType.CONFIRM_MODAL
            })

            onDealUpdate(value)
          }
        }
      })
      : onDealUpdate(value)
  }

  return <Select
    dataId={{
      inputId: 'DealCardSelectStageInput',
      menuId: 'DealCardSelectStageMenu'
    }}
    theme={theme}
    size='thin'
    value={value}
    options={options}
    onChange={(option) => option && handleDealUpdate(option.value)}
    searchable={false}
    clearable={false}
    disabled={isUpdate}
  />
}

/**
 * Deal Progress Component
 * @param props
 */
function Progress ({ stage }) {
  const progress = (stage && (stage.index + 1) * 20) || 20

  return (<div className='deal-card_progress' data-id='DealCardProgress'>
    <div className='deal-card_progress-stage' style={{ width: `${progress}%` }} />
  </div>)
}

/**
 * Deal Detail Component
 * @param props
 */
function Detail ({ label, value, width }) {
  return (
    <Grid item xs={width} className='card_section'>
      <h2 className='card_section-subtitle'>{label}</h2>
      <div className='card_section-detail'>{defaultIfEmpty(value)}</div>
    </Grid>
  )
}

/**
 * Entity Deal Card Component
 * @param props
 */
function DealCard (props) {
  const {
    dataId, theme, isLoading, isUpdate, entityType, deals, deal, pipeline,
    onDealSelect, onDealUpdate, openModal, closeModal
  } = props
  const { id, title, amount, start = {}, end = {}, stage, pipelineConnection } = deal

  const lost = pipeline.find((item) => item.lost)
  const active = deals.filter((deal) => deal.stage !== (lost && lost.id))
  const details = [
    { label: 'Potential POS', value: format(amount), width: 4 },
    { label: 'Created', value: formatDate(start.date, undefined, undefined, true), width: 3 },
    { label: 'Est. Close', value: formatDate(end.date, undefined, undefined, true), width: 3 }
  ]

  return (
    <Card
      dataId={dataId}
      theme={theme}
      className='deal-card'
      isLoading={isLoading}
      title='Deals'
      headerActions={[() => (
        <Action
          isLoading={isLoading}
          theme={theme}
          id={id}
          deals={active}
          onDealSelect={onDealSelect}
        />
      )]}
    >
      {(!isLoading && !active.length) ? `No active deals for this ${entityType}` : null}
      {(!isLoading && !!active.length) && <Grid container spacing={3}>
        <Grid item xs={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Link className='card_children-link' to={`/deal/${id}`}>{title}</Link>
              <Progress
                stage={get(pipelineConnection, '[0]')}
              />
            </Grid>
            {details.map((detail) => {
              return <Detail key={detail.label} {...detail} />
            })}
          </Grid>
        </Grid>

        <Grid item xs={4} className='deal-card_pipeline'>
          <Pipeline
            isUpdate={isUpdate}
            theme={theme}
            stage={stage}
            pipeline={pipeline}
            onDealUpdate={onDealUpdate}
            openModal={openModal}
            closeModal={closeModal}
          />
        </Grid>
      </Grid>}
    </Card>
  )
}

DealCard.propTypes = propTypes
DealCard.defaultProps = defaultProps

export default memo(DealCard)
