import React, {Component} from 'react';
import {Menu, MenuItem, Grow} from '@material-ui/core';
import pathToRegexp from 'path-to-regexp';
import {getActiveTicker, getTickers, getFromXigniteToStandard} from '../../../utils';
import {getMenuItems} from '../../navigation/data/menu.data';

import './securityMenu.component.css';

/**
 * Security Menu Component
 */
class SecurityMenu extends Component {

    /**
     * On Menu Item Click
     * @typedef ticker
     * @prop {string} q4_ticker_id
     * @prop {string} _security
     * 
     * @param {ticker} ticker
     */
    onClick = (ticker) => {
        const {setActiveTicker, getStock, onClose} = this.props;

        if (!ticker || !ticker.q4_ticker_id) {
            return;
        }

        setActiveTicker(ticker.q4_ticker_id);
        getStock(ticker.q4_ticker_id);
        this.updateSecurityRoute(ticker);

        onClose();
    };

    /**
     * Update Route Path
     * If current route is has ticker id in path, update path
     * with new security id (i.e. /security/:id)
     * @param {ticker} ticker
     */
    updateSecurityRoute = (ticker) => {
        const {q4_ticker_id: tickerId, _security: legacySecurityId} = ticker || {};
        const {history} = this.props;
        const pathname = history.location.pathname;
        const queryParams = history.location.search;

        const menuItem = getMenuItems({legacySecurityId, tickerId}).find((menuItem) => {
            const pattern = menuItem.pattern || menuItem.path;
            const re = pathToRegexp(pattern);
            return re.exec(pathname);
        });

        menuItem && history.push((menuItem.tickerId ? menuItem.path : pathname) + queryParams);
    };

    /**
     * Render Security Menu
     * @returns {XML}
     */
    render() {
        const {anchorEl, onClose, profile} = this.props;

        if (!profile || !profile.data) {
            return null;
        }

        const tickers = getTickers(profile.data);
        const activeTicker = getActiveTicker(profile.data);
        const selectedIndex = tickers.findIndex(ticker => ticker.q4_ticker_id === activeTicker.q4_ticker_id);

        return (
            <div className='security-menu-component'>
                <Menu
                    id='security-menu'
                    className='security-menu-mask'
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={onClose}
                    classes={{paper: 'security-menu'}}
                    elevation={1}
                    marginThreshold={0}
                    TransitionComponent={Grow}
                    style={{transformOrigin: '0 0 0'}}>
                    {tickers.map((ticker, index) => {
                        const {symbol, exchange} = ticker;
                        return <MenuItem
                            key={`ticker-${index}`}
                            classes={{
                                root: 'security-menu_item',
                                selected: 'security-menu_item--selected'
                            }}
                            onClick={() => this.onClick(ticker)}
                            selected={index === selectedIndex}
                            disableRipple={true}>
                            {`${symbol} | ${getFromXigniteToStandard(exchange)}`}
                        </MenuItem>;
                    })}
                </Menu>
            </div>
        );
    }
}

export default SecurityMenu;
