import moment from 'moment-timezone'
import { CALENDAR_VIEWS } from '../../utils/calendar/calendar.const'
import {
  SET_CURRENT_DATE,
  SET_CURRENT_VIEW
} from '../../actions'

const initial = {
  currentDate: moment().toDate(),
  currentView: CALENDAR_VIEWS.MONTH
}

const calendar = (state = initial, action) => {
  const { payload } = action

  switch (action.type) {
    case SET_CURRENT_DATE:
      return {
        ...state,
        currentDate: payload
      }

    case SET_CURRENT_VIEW:
      return {
        ...state,
        currentView: payload
      }

    default:
      return state
  }
}

export default calendar
