import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {RadioButton} from '../../../../../../../components/shared/form';

/**
 * Type Filter
 */
class TargetType extends PureComponent {

    /**
     * Handle Change
     * @param event
     */
    handleChange = (event) => {
        const {onChange} = this.props;
        onChange(event.currentTarget.value);
    };

    /**
     * Render
     * @return {*}
     */
    render() {
        const {value} = this.props;

        return (
            <div className='field field--full field--radio'>
                <label className='field_label'>Type</label>
                <RadioButton
                    className='radio-button--inline'
                    id='entityTypeInstitution'
                    value='institution'
                    label='Institution'
                    name='type'
                    isSelected={value === 'institution'}
                    onChange={this.handleChange}
                />
                <RadioButton
                    className='radio-button--inline'
                    id='entityTypeFund'
                    value='fund'
                    label='Fund'
                    name='type'
                    isSelected={value === 'fund'}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

TargetType.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default TargetType;
