import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Popover from '@material-ui/core/Popover'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderDarkThumb, renderTrackVertical } from '../../resources/theme/q4.custom-scrollbar'
import ExpandableItem from './expandableItem/expandableItem.component'
import { getClassName, THEMES } from '../../utils'

import './popoverMenu.component.css'

/**
 * Render popover menu items
 * @param theme
 * @param items
 * @param scrollable
 * @param deletable
 * @param onClick
 * @param onDelete
 * @param renderLabel
 * @return {*}
 * @constructor
 */
const MenuItems = ({ theme, items, scrollable, deletable, onClick, onDelete, renderLabel }) => {
  return items.map((item, index) => {
    const { dataId, type, icon, label } = item

    const className = getClassName('popover_item', [
      { condition: theme, trueClassName: `popover_item--${theme}` },
      { condition: scrollable, trueClassName: 'popover_item--thin' }
    ])

    if (type === 'expandable') {
      return (
        <ExpandableItem key={`item-${index}`} theme={theme} icon={icon} label={label}>
          <item.content />
        </ExpandableItem>
      )
    }

    return (
      <div key={`item-${index}`} className={className}>
        <div data-id={dataId}
             onClick={(e) => onClick(e, item)}
             className={`popover_item-detail ${deletable ? 'popover_item-detail--deletable' : ''}`}>
          {icon ? <i className={`popover_item-icon ${icon}`} /> : null}
          <div
            className={`popover_item-label ${icon ? 'popover_item-label--has-icon' : ''}`}>{renderLabel(item)}</div>
        </div>
        {deletable ?
          <i className='popover_item-delete q4i-close-4pt' onClick={(e) => onDelete(e, item)} /> : null}
      </div>
    )
  })
}

/**
 * Popover Component
 * @see {@link: https://material-ui.com/api/popover/} for further details
 */
class PopoverMenu extends PureComponent {

  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)

    this.state = {
      isHiding: false
    }
  }

  /**
   * ComponentDidUpdate
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    if (this.props.open !== prevProps.open) {
      this.setState({
        isHiding: !this.props.open && prevProps.open
      })
    }
  }

  /**
   * Get item's label
   * @param item
   * @returns {*|string}
   */
  renderLabel = (item) => {
    const { renderLabel } = this.props

    if (renderLabel) {
      return renderLabel(item)
    }

    return item.label
  }

  /**
   * Render Popover Menu Component
   * @return {XML}
   */
  render () {
    const {
      dataId, items, theme, anchorEl, anchor, transform, open, onClose, height, itemHeight, limit,
      onClick, onDelete, deletable, scrollable, isMargin, type
    } = this.props
    const { isHiding } = this.state

    const baseClassName = getClassName('popover', [
      {
        condition: theme,
        trueClassName: `popover--${theme} popover--${theme}--${anchor.vertical}-${anchor.horizontal}`
      },
      { condition: isMargin, trueClassName: `popover--margin--${anchor.vertical}-${anchor.horizontal}` },
      { condition: !items.length, trueClassName: 'popover--empty' },
      { condition: isHiding, trueClassName: 'popover--hiding' }
    ])

    return (
      <Popover
        data-id={dataId}
        className='popover-root'
        classes={{
          paper: baseClassName
        }}
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={anchor}
        transformOrigin={transform}
      >
        {!scrollable ? (
          <MenuItems
            items={items}
            type={type}
            deletable={deletable}
            onClick={onClick}
            onDelete={onDelete}
            renderLabel={this.renderLabel}
          />
        ) : (
          <Scrollbars
            style={{ height: (items.length < limit) ? itemHeight * items.length : height }}
            className='react-scrollbar'
            autoHide
            hideTracksWhenNotNeeded
            renderThumbVertical={renderDarkThumb}
            renderTrackVertical={renderTrackVertical}
          >
            <MenuItems
              items={items}
              type={type}
              deletable={deletable}
              scrollable={scrollable}
              onClick={onClick}
              onDelete={onDelete}
              renderLabel={this.renderLabel}
            />
          </Scrollbars>
        )}
      </Popover>
    )
  }
}

PopoverMenu.propTypes = {
  open: PropTypes.bool,
  theme: PropTypes.oneOf([THEMES.WHITE, THEMES.LIGHT, THEMES.LIGHT_GREY, THEMES.RAIN, THEMES.CITRUS, THEMES.DARK]),
  anchorEl: PropTypes.object,
  anchor: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string
  }),
  transform: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string
  }),
  items: PropTypes.array,
  deletable: PropTypes.bool,
  scrollable: PropTypes.bool,
  isMargin: PropTypes.bool,
  height: PropTypes.number,
  itemHeight: PropTypes.number,
  limit: PropTypes.number,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  renderLabel: PropTypes.func
}

PopoverMenu.defaultProps = {
  theme: THEMES.LIGHT,
  anchor: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transform: {
    vertical: 'top',
    horizontal: 'right'
  },
  items: [],
  deletable: false,
  scrollable: false,
  isMargin: false,
  itemHeight: 40,
  limit: 6,
  height: 40 * 6
}

export default PopoverMenu
