import { combineReducers } from 'redux'
import activityReducer from './activity'
import nlgReducer from './iris'
import ownershipReducer from './ownership'
import watchListReducer from './watchlist'
import estimatesReducer from './estimates'
import stockReducer from './stock'
import eventsReducer from './events/events.reducer'
import newsReducer from './news/news.reducer'
import pipelineReducer from './pipeline/index'
import aiTargetingReducer from './aiTargeting'
import analyticsReducer from './analytics/index'

const widgetReducer = combineReducers({
  nlg: nlgReducer,
  activity: activityReducer,
  ownership: ownershipReducer,
  watchlist: watchListReducer,
  estimates: estimatesReducer,
  stock: stockReducer,
  events: eventsReducer,
  news: newsReducer,
  pipeline: pipelineReducer,
  aiTargeting: aiTargetingReducer,
  analytics: analyticsReducer
})

export default widgetReducer
