import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment-timezone';
import { inMillions, format } from '../../../utils/number.util';
import { formatLocalizedDate } from '../../../utils/date.util';
import './card.component.css';

/**
 * Card Component for Pipeline Board
 * Refer to https://github.com/rcdexta/react-trello for documentation
 */
class Card extends Component {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Get Entity Icon based on type
     * @param entity
     * @return {*}
     */
    getIconCls = (entity) => {
        const type = (entity.type && typeof entity.type === 'string') ? entity.type.toLowerCase() : '';
        const source = entity.item && entity.item.source;

        return {
            institution: 'q4i-institution-4pt',
            fund: 'q4i-fund-4pt',
            contact: (source && source === 'q4desktop') ? 'q4i-custom-4pt' : 'q4i-contact-4pt'
        }[type] || '';
    };

    /**
     * When user clicks on target, user will be taken to that targets' detail page
     *
     * @param event
     * @returns {boolean}
     */
    onTargetClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const {reference, type, history} = this.props;

        if (!reference || !type || !reference.item) {
            return false;
        }

        switch (reference.type.toLowerCase()) {
            case 'institution':
                history.push(`institution/${reference.item.factset_entity_id}`);
                break;

            case 'fund':
                history.push(`fund/${reference.item.factset_fund_id}`);
                break;

            case 'contact':
                history.push(`contact/${reference.item._id}`);
                break;

            default:
                break;
        }
    };

    /**
     * Render primary entity and total entity count
     * @param entity
     * @returns {*}
     */
    renderEntity = (entity) => {
        return () => {
            const primary = entity && entity[0];

            if (!primary) {
                return null;
            }

            const { type, item = {} } = (primary || {})
            const count = entity.length;

            return ((item && item._id && (item.fund_name || item.institution_name || item.full_name))
                ? <div className='pipeline-card_entity' onClick={this.onTargetClick}>
                    <i className={`pipeline-card_icon pipeline-card_icon--${(type || '').toLowerCase()} ${this.getIconCls(primary)}`}/>
                    <div className='pipeline-card_entity-name'>
                        {item && (item.fund_name || item.institution_name || item.full_name)}
                    </div>
                </div>
                : <div className='pipeline-card_entity' onClick={this.onTargetClick}>
                  {count ? <div className='pipeline-card_count'>{count}</div> : ''}
                  <div className='pipeline-card_entity-name'>{`Potential ${(count > 1) ? 'Investors' : 'Investor'}`}</div>
                </div>
            );
        };
    };

    /**
     * Render Saved Target Card
     * @return {function()}
     */
    renderTargetCard = () => {
        return () => {
            const {reference} = this.props;
            const Entity = this.renderEntity([reference]);

            return (
                <div className='pipeline-card pipeline-card--target'>
                    <Entity/>
                </div>
            );
        };
    };

    /**
     * Render Deal Card
     * @return {function()}
     */
    renderDealCard = () => {
        return () => {
            const {id, title, amount, start, end, entity, _stage, stockPrice} = this.props;
            const Entity = this.renderEntity(entity);
            const probability = (_stage && _stage.probability) || 0;
            const marketValue = (amount * (probability / 100) * stockPrice) || 0;

            return (
                <Link to={`/deal/${id}`} onClick={this.onDealClick}>
                    <div className='pipeline-card pipeline-card--deal' title=''> {/*title='' disables plugin's tooltip*/}
                        <div className='pipeline-card_header'>
                            <div className='pipeline-card_title'>{title}</div>
                        </div>
                        <div className='pipeline-card_details'>
                            <div className='pipeline-card_detail'>
                                <div className='pipeline-card_value'>
                                    <i className='pipeline-card_icon q4i-position-4pt'/>
                                    {amount < 10000 ? amount : inMillions(amount, 2) + 'M'}
                                </div>
                                <div className='pipeline-card_value'>
                                    <i className='pipeline-card_icon q4i-price-4pt'/>
                                   {marketValue < 10000 ? '$' + format(marketValue, 2) : '$' + inMillions(marketValue, 2) + 'M'}
                                </div>
                            </div>
                            <div className='pipeline-card_detail'>
                                <div className='pipeline-card_value'>
                                    <i className='pipeline-card_icon q4i-time-4pt'/>
                                    {start && start.date_time && formatLocalizedDate(moment(start.date_time).format('MM/DD/YY'), 'MM/DD/YY')}
                                </div>
                                <div className='pipeline-card_value'>
                                    <i className='pipeline-card_icon pipeline-card_icon--close-date q4i-time-4pt'/>
                                    {end && end.date_time ? formatLocalizedDate(moment(end.date_time).format('MM/DD/YY'), 'MM/DD/YY') : '-'}
                                </div>
                            </div>
                        </div>
                        <Entity/>
                    </div>
                </Link>
            )
        }
    };

    /**
     * Renders
     * @return {XML}
     */
    render = () => {
        const {type} = this.props;
        const DealCard = this.renderDealCard();
        const TargetCard = this.renderTargetCard();

        switch (type) {
            case 'deal':
                return <DealCard/>;
            case 'target':
                return <TargetCard/>;
            default:
                return null;
        }
    };
}

export default Card;
