import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Card } from '../../../index'
import { format, defaultIfEmpty, ENTITY_TYPE, THEMES, isTradingTicker } from '../../../../utils'
import { get, isNumber } from 'lodash'

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  isLoading: PropTypes.bool.isRequired,
  entity: PropTypes.arrayOf(
    PropTypes.shape({
      entityId: PropTypes.string,
      entityType: PropTypes.oneOf([CONTACT, FUND, INSTITUTION]),
      entityConnection: PropTypes.object
    })),
  subscription: PropTypes.string.isRequired
}

const defaultProps = {
  isLoading: false,
  entity: []
}

const EMPTY_PLACEHOLDER = '-'

/**
 * Get entity icon and name
 * @param entity
 */
function getEntity (entity) {
  const { entityType, entityConnection } = (entity || {})
  const { fullName, fundName, institutionName } = (entityConnection || {})

  return entityType
    ? <>
      <i className={`card_list-icon q4i-${entityType}-4pt`} />
      <span className='card_list-title'>{defaultIfEmpty(fullName || fundName || institutionName)}</span>
    </>
    : '-'
}

/**
 * Deal Entity Component
 * @param props
 */
function DealEntity (props) {
  const { dataId, theme, isLoading, entity, subscription, tickerId } = props

  return (
    <Card
      dataId={`${dataId}EntityCard`}
      theme={theme}
      isLoading={isLoading}
      title={
        <>
          <div className='card_list-label'>
            Potential Investors <span className='card_header-note'>{subscription}</span>
          </div>
          <div className='card_list-value card_list-value--neutral'>Created POS</div>
          <div className='card_list-value card_list-value--neutral'>Current POS</div>
        </>
      }
    >
      {(!entity.length)
        ? 'No data available'
        : (entity || []).map((item) => {
          const { entityId, entityType, entityConnection, entityHoldingHistoricalConnection } = (item || {})
          const created = (entityType === CONTACT)
            ? (isTradingTicker(tickerId) ? get(entityHoldingHistoricalConnection, 'current') : EMPTY_PLACEHOLDER)
            : (isTradingTicker(tickerId) ? get(entityHoldingHistoricalConnection, 'q1Value') : EMPTY_PLACEHOLDER)
          const current = (isTradingTicker(tickerId) ? get(entityConnection, `${entityType}HoldingCurrentConnection.items[0].current`) : EMPTY_PLACEHOLDER)

          return (
            <Link key={entityId} className='card_list' to={`/${entityType}/${entityId}`}>
              <div className='card_list-label'>{getEntity(item)}</div>
              <div className='card_list-value'>{isNumber(created) ? format(created) : EMPTY_PLACEHOLDER}</div>
              <div className='card_list-value'>{isNumber(current) ? format(current) : EMPTY_PLACEHOLDER}</div>
            </Link>
          )
        })}
    </Card>
  )
}

DealEntity.propTypes = propTypes
DealEntity.defaultProps = defaultProps

export default memo(DealEntity)
