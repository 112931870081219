import {
  GET_CONTACT_WEBCAST_REQUEST,
  GET_CONTACT_WEBCAST_SUCCESS,
  GET_CONTACT_WEBCAST_FAILURE,
  RESET_CONTACT,
  statusType
} from '../../../actions'

const initial = {
  data: [],
  total: 0,
  status: statusType.IDLE
}

const webcast = (state = initial, action) => {
  const { type, payload = {}, meta } = action

  switch (type) {
    case GET_CONTACT_WEBCAST_REQUEST:
      return {
        ...initial,
        status: statusType.IN_PROGRESS
      }

    case GET_CONTACT_WEBCAST_SUCCESS:
      return {
        ...state,
        data: payload,
        total: meta && meta.total,
        status: statusType.SUCCESS
      }

    case GET_CONTACT_WEBCAST_FAILURE:
      return {
        ...state,
        status: statusType.ERROR
      }

    case RESET_CONTACT:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default webcast
