import './detail.component.css'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { pushFlyoutDetailsItem } from '../../../../../actions'

import ContactFlyout from './contact/contact.container'
import FundFlyout from './fund/fund.container'
import InstitutionFlyout from './institution/institution.container'

import { ENTITY_TYPE } from '../../../../../utils'

const propTypes = {
  data: PropTypes.object.isRequired,
  handleTargetUtilAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

const { CONTACT, FUND, INSTITUTION } = ENTITY_TYPE

function TargetDetailContainer ({ data, onClose, handleTargetUtilAction, ...props }) {
  const { entityType } = data
  const entityProps = { ...data, onClose, handleTargetUtilAction }
  const Entity = getEntityFlyout(entityType)

  /**
   * Returns flyout container based on entityType
   * @param entityType
   * @returns {*}
   */
  function getEntityFlyout (entityType) {
    switch (entityType) {
      case CONTACT:
        return ContactFlyout
      case FUND:
        return FundFlyout
      case INSTITUTION:
        return InstitutionFlyout
      default:
        return null
    }
  }

  /**
   * Handles redirect inside of the flyout
   * @param params
   */
  function handleRedirect (params) {
    if (!params || !params.entityType) {
      return
    }

    const { entityType } = params
    const { pushFlyoutDetailsItem, flyoutDetailsHistory } = props
    const lastItem = flyoutDetailsHistory[flyoutDetailsHistory.length - 1]

    switch (entityType) {
      case INSTITUTION:
      case FUND:
        if (!lastItem || lastItem.entityId !== params.entityId) {
          pushFlyoutDetailsItem({ entityType, entityId: params.entityId })
        }
        break
      case CONTACT:
        if (!lastItem || lastItem.contactId !== params.contactId) {
          pushFlyoutDetailsItem({ entityType, contactId: params.contactId })
        }
        break
      default:
        return
    }
  }

  return (
    <div className='target-detail'>
      {Entity && (
        <Entity
          {...entityProps}
          handleRedirect={handleRedirect}
        />
      )}
    </div>
  )
}

TargetDetailContainer.propTypes = propTypes

const mapDispatchToProps = (dispatch) => ({
  pushFlyoutDetailsItem: bindActionCreators(pushFlyoutDetailsItem, dispatch)
})

const mapStateToProps = (state) => {
  return {
    flyoutDetailsHistory: state.targeting.flyout.data
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TargetDetailContainer)
