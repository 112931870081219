import gql from 'graphql-tag'
import { useLazyQuery } from '@apollo/react-hooks'

const GET_QUICK_SEARCH_RESULTS = gql`
  query QuickSearch(
    $query: String,
    $entity: [String],
    $page: Int,
    $limit: Int,
    $useElasticSearch: Boolean
  ) {
    quickSearch(
      query: $query,
      entity: $entity,
      page: $page,
      limit: $limit,
      useElasticSearch: $useElasticSearch
    ) {
      items {
        id
        _id
        score
        source_id
        type
        exchange
        symbol
        name
        institution
        entityId
        q4_entity_id
        title
        phone
        email
        address
        source
        country
        country_name
        country_code
        location_city
        region_name
        state_province_code
        state_province_name
      }
      count
    }
  }
`

export function useQuickSearchQuery () {
  return useLazyQuery(GET_QUICK_SEARCH_RESULTS, {
    fetchPolicy: 'no-cache'
  })
}
