import React, {Component} from 'react';
import {MAIL_SUCCESS, MAIL_ERROR} from '../../actions';
import { Modal } from '../../components';

class TrialExpiredModal extends Component {

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            visible: true
        };
    }

    logout = () => {
        const {history} = this.props;
        this.setState({visible: false});
        history.push('/logout');
    };

    /**
     * onContactUs
     */
    onContactUs = () => {
        const {sendMail, createToast} = this.props;

        sendMail({
            message: 'Please have sales or CSL rep follow up:',
            template: 'iris-trial-inquiry',
            subject: 'Trial Extension Request',
            to: 'scsm@q4inc.com'
        }).then((response) => {
            if (response.type === MAIL_SUCCESS) {
                createToast({text: 'Inquiry has been successfully sent. You will now be logged out.'});
            }
            else if (response.type === MAIL_ERROR) {
                createToast({
                    text: 'Oops, something went wrong while trying to send this inquiry. You will now be logged out.'
                });
            }

            setTimeout(() => {
                this.logout();
            }, 3000);
        });
    };

    /**
     * Render
     * @returns {XML}
     */
    render() {
        return (
            <Modal
                className='modal-root--centered modal-root--trial'
                visible={this.state.visible}
                title={'Your trial has expired.'}
                containerWidth={500}
                contentHeight={80}
                footerButtons={[
                    {
                        label: 'Cancel',
                        ui: 'shaded',
                        onClick: this.logout
                    },
                    {
                        label: 'Contact Us',
                        ui: 'citrus',
                        onClick: this.onContactUs
                    }
                ]}
            >
                <p>To access your account please contact us.</p>
            </Modal>
        );
    }
}

export default TrialExpiredModal;
