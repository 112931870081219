import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Button } from '../../../components'
import { SubFilters, Function, Location, Type } from './components'
import { SEARCH_TYPE, ENTITY_SEARCH_TYPES, SWIFTTYPE_FLAGS, THEMES } from '../../../utils'
import './filter.component.css'

const { CONTACT, INSTITUTION, FUND, TRANSCRIPT } = SEARCH_TYPE

/**
 * Search Filter Component
 * @param props
 * @returns {*}
 * @constructor
 */
const SearchFilter = (props) => {
  const { type, filter, facet, isFacetLoading, onFilterChange, onTypeChange, onReset, activity, peer, isTagSearch, subscriptions } = props

  const functions = get(facet, 'functions', [])
  const region = get(facet, 'region', [])
  const country = get(facet, 'country_name', [])
  const metro = get(facet, 'metro', [])

  const showLocationFilter = type.some((item) => ENTITY_SEARCH_TYPES.includes(item))
  const showFunctionFilter = type.includes(CONTACT)
  const showSubfilter = type.some((item) => [CONTACT, INSTITUTION, FUND, TRANSCRIPT].includes(item))

  return (
    <aside className='search-page_filter'>
      <Type
        type={type}
        subscriptions={subscriptions}
        onChange={onTypeChange}
      />

      {!isTagSearch &&
        <>
          {showSubfilter && (
            <SubFilters
              type={type}
              activity={activity}
              fund={(filter.flags || []).includes(SWIFTTYPE_FLAGS.MANAGED_FUNDS)}
              peer={peer}
              onChange={onFilterChange}
            />
          )}

          {showFunctionFilter && (
            <Function
              isLoading={isFacetLoading}
              options={functions}
              selectedOptions={filter.functions}
              onFilterChange={onFilterChange}
            />
          )}

          {showLocationFilter && (
            <Location
              isLoading={isFacetLoading}
              type={type}
              regionOptions={region}
              regionSelectedOptions={filter.region}
              countryOptions={country}
              countrySelectedOptions={filter.country_name}
              metroOptions={metro}
              metroSelectedOptions={filter.metro}
              onFilterChange={onFilterChange}
            />
          )}
        </>}

      <Button
        className='search-page_filter-reset'
        label='Reset'
        theme={THEMES.LIGHT_GREY}
        onClick={onReset}
      />
    </aside>
  )
}

SearchFilter.propTypes = {
  type: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  filter: PropTypes.object,
  facet: PropTypes.object,
  activity: PropTypes.bool,
  peer: PropTypes.bool,
  isTagSearch: PropTypes.bool
}

SearchFilter.defaultProps = {
  filters: {},
  isTagSearch: false
}

export default SearchFilter
