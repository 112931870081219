import _ from 'lodash'
import update from 'immutability-helper'

import {
  statusType,
  GET_PIPELINE_SUCCESS,
  GET_PIPELINE_FAILURE,
  GET_PIPELINE_LOST_SUCCESS,
  GET_PIPELINE_LOST_FAILURE,
  GET_ALL_SAVED_TARGETS_SUCCESS,
  GET_ALL_SAVED_TARGETS_FAILURE,
  EXPORT_PIPELINE_REQUEST,
  EXPORT_PIPELINE_SUCCESS,
  EXPORT_PIPELINE_ERROR,
  FILTER_ALL_TARGETS,
  SET_ACTIVE_TAB,
  UPDATE_DEAL_SUCCESS
} from '../../actions'

const initial = {
  targetFilter: '',
  activeTab: 'open',
  targets: [{
    id: 'targets',
    type: 'target',
    label: 'Targets',
    droppable: false,
    cardStyle: { marginBottom: 5, marginLeft: 4, marginRight: 4, width: 222, backgroundColor: '#f1f1f1' },
    loaded: statusType.IDLE,
    cards: []
  }],
  open: [],
  lost: [],
  lanes: [{
    cardStyle: { marginBottom: 5, marginLeft: 4, marginRight: 4, width: 222, backgroundColor: '#f1f1f1' },
    loaded: statusType.IDLE,
    cards: []
  }],
  status: {
    pipeline: statusType.IDLE,
    targets: statusType.IDLE,
    csvExport: statusType.IDLE
  }
}

const pipeline = (state = initial, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      const tab = action.payload
      return {
        ...state,
        activeTab: tab.value,
        lanes: (tab.value === 'lost') ? state.lost : [].concat(state.targets, state.open)
      }

    case GET_ALL_SAVED_TARGETS_SUCCESS:
      const targets = action.payload
      return {
        ...state,
        targets: [].concat({
          id: 'targets',
          type: 'target',
          label: 'Targets',
          droppable: false,
          cardStyle: { marginBottom: 5, marginLeft: 4, marginRight: 4, width: 222, backgroundColor: '#f1f1f1' },
          loaded: statusType.SUCCESS,
          cards: (targets || []).map((target) => Object.assign(target, {
            type: 'target',
            id: target._id,
            laneId: target.laneId || 'targets'
          }))
        }),
        status: {
          ...state.status,
          targets: statusType.SUCCESS
        }
      }

    case GET_ALL_SAVED_TARGETS_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          targets: statusType.ERROR
        }
      }

    case FILTER_ALL_TARGETS:
      return {
        ...state,
        targetFilter: action.filter
      }

    case GET_PIPELINE_SUCCESS:
      const pipeline = action.payload
      const allLanes = (pipeline || []).map((stage) => ({
        id: stage._id,
        type: 'stage',
        lost: stage.lost || false,
        label: stage.label,
        probability: stage.probability,
        cardStyle: { marginBottom: 5, marginLeft: 4, marginRight: 4, width: 222, backgroundColor: '#ffffff' },
        cards: (stage._deal || []).map((deal) => Object.assign(deal, {
          type: 'deal',
          id: deal._id
        }))
      }))

      return {
        ...state,
        open: allLanes,
        lost: allLanes.filter((lane) => lane.lost),
        lanes: [].concat(state.targets, allLanes),
        status: {
          ...state.status,
          pipeline: statusType.SUCCESS
        }
      }

    case GET_PIPELINE_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          pipeline: statusType.ERROR
        }
      }

    case GET_PIPELINE_LOST_SUCCESS:
      const data = action.payload
      const stage = (data || []).pop()
      const lost = [{
        id: stage._id,
        type: 'stage',
        label: stage.label,
        probability: stage.probability,
        cardStyle: { marginBottom: 5, marginLeft: 4, marginRight: 4, width: 222, backgroundColor: '#ffffff' },
        cards: (stage._deal || []).map((deal) => Object.assign(deal, {
          type: 'deal',
          id: deal._id
        }))
      }]

      return {
        ...state,
        lanes: lost,
        lost
      }

    case GET_PIPELINE_LOST_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          pipeline: statusType.ERROR
        }
      }

    case UPDATE_DEAL_SUCCESS:
      const lanes = _findAndUpdateCard(action.payload, state.lanes)
      return {
        ...state,
        lanes,
        open: (lanes || []).filter((lane) => lane.type === 'stage')
      }

    case EXPORT_PIPELINE_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
          csvExport: statusType.IN_PROGRESS
        }
      }

    case EXPORT_PIPELINE_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          csvExport: statusType.IDLE
        }
      }

    case EXPORT_PIPELINE_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          csvExport: statusType.ERROR
        }
      }

    default:
      return state
  }
}

/**
 * Finds and updates card in pipeline
 * @param newCard - deal with updated information
 * @param lanes   - pipeline lanes
 * @private
 */
const _findAndUpdateCard = (newCard, lanes) => {
  if (!newCard || !lanes) {
    return
  }
  try {
    const cards = _flattenCards(lanes)
    const cardIndex = cards.findIndex((card) => card.id === newCard._id)
    cards[cardIndex] = _buildCard(newCard, 'deal')
    return _mapCardsToLanes(_groupCardsByType(cards), lanes)
  } catch (error) {
    console.warn('Error occurred during card update', error)
    return lanes
  }
}

/**
 * Returns an array of all cards in pipeline
 * @param lanes
 * @private
 */
const _flattenCards = (lanes) => {
  return (lanes || []).reduce((acc, lane) => acc.concat(lane.cards), []).filter((card) => card)
}

/**
 * Combines an array of cards into lanes
 * @param cards
 * @private
 */
const _groupCardsByType = (cards) => {
  cards = cards.sort((a, b) => new Date(a.create_date) - new Date(b.create_date))
  return _.groupBy(cards, (card) => card.laneId)
}

/**
 * Maps updated lanes to existing lanes.
 * @param cards
 * @param lanes
 * @private
 */
const _mapCardsToLanes = (cards, lanes) => {
  return (lanes || []).map((lane) => {
    const laneCards = (cards[lane.id] || []).map((card) => _buildCard(card, card.type))
    return update(lane, { $merge: { cards: laneCards } })
  })
}

/**
 * Creates or Updates card in pipeline.
 * @param deal
 * @param type
 * @private
 */
const _buildCard = (deal, type) => {
  return Object.assign({}, deal, {
    type,
    id: deal._id,
    laneId: deal.laneId || (deal._stage && deal._stage._id) || 'targets'
  })
}

export default pipeline
