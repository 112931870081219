import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

// components
import { Card, Modal, ReadMore, RenderHTML, SectionTab } from '../../../components'
import { combine, convertStringToBoolean, THEMES } from '../../../utils'
import { get } from 'lodash'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  isLoading: PropTypes.bool,
  address: PropTypes.array,
  profile: PropTypes.string,
  approach: PropTypes.string,
  openModal: PropTypes.func.isRequired
}

const defaultProps = {
  theme: THEMES.DARK,
  isLoading: false,
  address: []
}

const TABS = {
  PROFILE: 'profile',
  BACKGROUND: 'background',
  APPROACH: 'approach'
}

/**
 * Address Modal
 * @param props
 */
const AddressModal = ({ onClose, title, address }) => {
  return (
    <Modal
      visible
      scrollable
      onClose={onClose}
      title={title}
      footerButtons={[{
        ui: 'citrus',
        label: 'ok',
        onClick: onClose
      }]}
    >
      <Grid container spacing={3}>{address}</Grid>
    </Modal>
  )
}

/**
 * Get formatted address
 * @param record
 */
function getAddress (record = {}) {
  const { addressLine1, addressLine2, addressLine3, city, stateProvinceCode, countryName, postalCode, hq } = record

  const address = combine([addressLine1, addressLine2, addressLine3])
  const location = combine([city, stateProvinceCode])
  const country = combine([countryName, postalCode], ' ')
  const phone = get(record, 'phone', null)
  const fax = get(record, 'fax', null)

  return (
    <Grid item xs={6} className='card_section' key={postalCode}>
      {convertStringToBoolean(hq) ? <h2 className='card_section-subtitle'>Headquarter</h2> : null}
      <div className='card_section-detail'>
        <RenderHTML html={combine([address, location, country], '<br>')} />
        <RenderHTML html={combine([(phone && `P: ${phone}`), (fax && `F: ${fax}`)], '<br>')} />
      </div>
    </Grid>
  )
}

/**
 * Get other locations
 * @param address
 * @param openModal
 */
function Locations ({ address = [], openModal }) {
  const handleClick = () => openModal({
    component: ({ onClose }) => AddressModal({
      onClose,
      title: 'Other Offices',
      address: address.map((item) => getAddress(item))
    })
  })

  return (
    address.length
      ? (
        <Grid item xs={6} className='card_section'>
          <div className='card_section-link' onClick={handleClick}>View All Locations</div>
        </Grid>
        )
      : null
  )
}

/**
 * Address Tab Component
 * @param props
 */
function Address ({ address, openModal }) {
  const hq = (address || []).find((item) => convertStringToBoolean(item.hq))
  const metro = get(hq, 'metro', null)

  return (
    <Grid container spacing={3}>
      {getAddress(hq)}
      {
      metro
        ? (
          <Grid item xs={6} className='card_section'>
            <h2 className='card_section-subtitle'>Metro Area</h2>
            <div className='card_section-detail'>{metro}</div>
          </Grid>
          )
        : null
      }
      <Locations
        address={(address || []).filter((item) => !convertStringToBoolean(item.hq))}
        openModal={openModal}
      />
    </Grid>
  )
}

/**
 * Institution Profile Card Component
 * @param props
 */
function ProfileCard (props) {
  const { dataId, theme, isLoading, address, profile, approach, openModal } = props
  const [active, setActive] = useState(TABS.PROFILE)

  return (
    <Card
      dataId={`${dataId}ProfileCard`}
      theme={theme}
      isLoading={isLoading}
      title={
        <SectionTab
          compact
          theme={theme}
          activeTab={active}
          onChange={(event, value) => setActive(value)}
          tabs={[
            {
              label: 'Profile',
              value: TABS.PROFILE
            },
            {
              label: 'Background',
              value: TABS.BACKGROUND
            },
            {
              label: 'Investment Approach',
              value: TABS.APPROACH
            }
          ]}
        />
      }
    >
      {(active === TABS.PROFILE) ? (address && address.length)
        ? <Address address={address} openModal={openModal} />
        : <div className='card_section-detail-placeholder'>No data available</div>
        : null}
      {(active === TABS.BACKGROUND) ? (profile && profile.length)
        ? <div className='card_section-detail-text'>
          <ReadMore
            lines={5}
            theme={theme}
            text={profile.replace(/\\r|\\n/g, '<br>')}
            renderHTML
          />
          </div>
        : <div className='card_section-detail-placeholder'>No data available</div>
        : null}
      {(active === TABS.APPROACH) ? (approach && approach.length)
        ? <div className='card_section-detail-text'>
          <ReadMore
            lines={5}
            theme={theme}
            text={approach.replace(/\\r|\\n/g, '<br>')}
            renderHTML
          />
          </div>
        : <div className='card_section-detail-placeholder'>No data available</div>
        : null}
    </Card>
  )
}

ProfileCard.propTypes = propTypes
ProfileCard.defaultProps = defaultProps

export default memo(ProfileCard)
