import React from 'react'
import PropTypes from 'prop-types'

// utils
import { getClassName } from '../../../../utils'
import { orderBy } from 'lodash'

import './expandableCell.component.scss'

const propTypes = {
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onItemClick: PropTypes.func
}

const defaultProps = {
  items: [],
  label: 'label'
}

const EMPTY_PLACEHOLDER = '-'

/**
 * Expandable Cell Component for AgGrid
 * @param {Object} props
 */
function ExpandableCell ({ items, label, onClick, onItemClick }) {
  const sortedItems = orderBy(
    (items || []).map((item) => ({ ...item, label: item[label] })),
    [label],
    ['asc']
  )
  const cellLabel = (sortedItems[0] && sortedItems[0].label) || EMPTY_PLACEHOLDER
  const baseClassName = getClassName('expandable-cell', [
    { condition: (sortedItems.length > 1 || onItemClick), trueClassName: 'expandable-cell--clickable' }
  ])

  /**
   * Handles PopOverMenu click event
   * @param event
   */
  function handleMenuOpen (event) {
    if (sortedItems.length <= 1 || !onClick) {
      return
    }

    onClick(event, {
      items: sortedItems,
      open: true,
      anchorEl: event.currentTarget
    })
  }

  /**
   * Handles cell label click event
   * @param event
   */
  function handleCellLabelClick (event) {
    if (sortedItems.length !== 1 || !onItemClick) {
      return
    }

    onItemClick(event, sortedItems[0])
  }

  // for cells with no data or cells with one non-clickable item,
  // render value as a regular cell label
  if (!sortedItems || !sortedItems.length || (sortedItems.length === 1 && !onItemClick)) {
    return <div className='cell_label'>{cellLabel}</div>
  }

  return (
    <div className={baseClassName} onClick={handleMenuOpen}>
      <div className='expandable-cell_label' onClick={handleCellLabelClick}>{cellLabel}</div>
      {sortedItems && sortedItems.length > 1 && (
        <div className='expandable-cell_count'>{`+${sortedItems.length - 1}`}</div>
      )}
    </div>
  )
}

ExpandableCell.propTypes = propTypes
ExpandableCell.defaultProps = defaultProps

export default ExpandableCell
