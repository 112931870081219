import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { get } from 'lodash'

// components
import {
  Biography,
  ContactAttribute,
  EntityComment,
  ContactInstitution,
  EntityBadge,
  EntityDeal,
  EntityHighlight,
  TagInput
} from '../../../../components'
import { ENTITY_TYPE } from '../../../../utils'

const { CONTACT } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string.isRequired,
  contact: PropTypes.object.isRequired,
  targetId: PropTypes.string,
  hasActiveDeal: PropTypes.bool,
  deals: PropTypes.shape({
    isLoading: PropTypes.bool,
    deals: PropTypes.array,
    pipeline: PropTypes.array
  }).isRequired,
  onDealSaveSuccess: PropTypes.func.isRequired,
  isWebcast: PropTypes.bool
}

const defaultProps = {
  dataIdPrefix: 'ContactPage',
  contact: {},
  hasActiveDeal: false,
  deals: {}
}

/**
 * Contact Detail Overview Component
 * @param props
 */
function ContactDetailOverview (props) {
  const { dataIdPrefix, contact, targetId, hasActiveDeal, deals, onDealSaveSuccess, isWebcast } = props
  const { id, directPhone, phoneExtension, mobile, email, bio, source, status, jobs } = contact

  const custom = source === 'q4desktop'
  const gdpr = status === 'gdpr'
  const isInformation = [directPhone, mobile, email].some((item) => item)

  return (
    <div className='overview-layout contact-detail_overview'>
      <Grid container spacing={3}>
        <EntityBadge
          dataId={dataIdPrefix}
          data={{
            custom,
            favorite: get(contact, 'favoriteConnection.items[0].id', null),
            targetId,
            hasActiveDeal,
            isWebcast,
            gdpr
          }}
        />

        {(jobs || []).map((job, index) => job.institutionName
          ? <Grid item xs={12} key={job.entityId || index}>
            <ContactInstitution
              dataId={dataIdPrefix}
              job={job}
              contactPosition={get(contact, 'contactHoldingCurrentConnection.items[0].current')}
              strategy={get(contact, 'strategyConnection.items', []).find((strategy) => strategy.entityId === job.entityId)}
            />
          </Grid>
          : null)}

        <Grid item xs={12}>
          <Grid container spacing={3}>
            {!gdpr && <Grid item xs={6}>
              <EntityHighlight
                dataId={dataIdPrefix}
                entity={contact}
                entityId={id}
                entityType={CONTACT}
              />
            </Grid>}

            {!gdpr && isInformation && <Grid item xs={6}>
              <ContactAttribute
                dataId={dataIdPrefix}
                data={{
                  phone: directPhone,
                  extension: phoneExtension,
                  mobile,
                  email
                }}
              />
            </Grid>}

            <Grid item xs={6}>
              <Biography
                dataId={dataIdPrefix}
                bio={bio}
              />
            </Grid>

            <Grid item xs={6}>
              <EntityDeal
                dataId={dataIdPrefix}
                entityType={CONTACT}
                data={deals}
                onSaveSuccess={onDealSaveSuccess}
              />
            </Grid>

            <Grid item xs={6}>
              <EntityComment
                dataId={dataIdPrefix}
                entity={{
                  entityId: id,
                  entityType: CONTACT
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TagInput
            dataId={dataIdPrefix}
            entityId={id}
            entityType={CONTACT}
          />
        </Grid>

      </Grid>
    </div>
  )
}

ContactDetailOverview.propTypes = propTypes
ContactDetailOverview.defaultProps = defaultProps

export default memo(ContactDetailOverview)
