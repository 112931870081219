import React from 'react'
import { ExpandableCell } from '../../../../../agGrid/cell'
import { format, formatDate, inMillions, isPassive } from '../../../../../../utils'
import { capitalize, get } from 'lodash'

const EMPTY_PLACEHOLDER = '-'

/**
 * Title Cell Component
 * @param data
 * @param position
 */
const TitleCell = ({ data }) => {
  const { fundName, countryName } = data
  return <div className='cell'>
    <i className='cell_icon cell_icon--fund q4i-fund-2pt' />
    <div className='cell_content'>
      <div className='cell_content-name'>{fundName}</div>
      {countryName ? <div className='cell_content-detail'>{countryName}</div> : null}
    </div>
  </div>
}

/**
 * AgGrid Custom Cell Render Component
 * @param props
 */
const CustomCell = ({ data = {}, column = {}, onPopoverClick, onPopoverItemClick }) => {
  const cellData = data[column.colId]
  switch (column.colId) {
    case 'fundName':
      return <TitleCell data={data} />
    case 'contact':
      return <ExpandableCell
        label='fullName'
        items={get(data, 'contactConnection.items', [])}
        onClick={onPopoverClick}
        onItemClick={onPopoverItemClick}
      />
    case 'current':
      const current = get(data, 'fundHoldingCurrentConnection.items[0].current', null)
      return (current || current === 0) ? format(current) : EMPTY_PLACEHOLDER
    case 'equityAUM':
    case 'portfolioValue':
      return (cellData || cellData === 0) ? inMillions(cellData, 0) : EMPTY_PLACEHOLDER
    case 'aiScore':
      const { style } = data
      const aiScore = get(data, 'aiScoreConnection.items[0].score', null)
      return isPassive({ style, aiScore })
    case 'purchasingPower':
      const purchasingPower = get(data, 'purchasingPowerConnection.items[0].purchasingPower', null)
      return (purchasingPower || purchasingPower === 0) ? format(purchasingPower) : EMPTY_PLACEHOLDER
    case 'turnover':
      return cellData ? capitalize(cellData) : EMPTY_PLACEHOLDER
    case 'reportDate':
      const reportDate = get(data, 'fundHoldingCurrentConnection.items[0].reportDate', null)
      return reportDate ? formatDate(reportDate, undefined, true, true) : EMPTY_PLACEHOLDER
    case 'activity':
      const activity = get(data, 'activityConnection.items[0].start.date', null)
      return activity ? formatDate(activity, undefined, undefined, true) : EMPTY_PLACEHOLDER
    default:
      return cellData || EMPTY_PLACEHOLDER
  }
}

export default CustomCell
