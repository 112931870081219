export const SET_CURRENT_DATE = 'SET_CURRENT_DATE'
export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW'

/**
 * Set Current Date
 * @param date - new calendar date
 * @return {function(*): *}
 */
export const setCurrentDate = (date) => (dispatch) => {
  return dispatch({
    type: SET_CURRENT_DATE,
    payload: date
  })
}

/**
 * Set Current View
 * @param view - new calendar view
 * @return {function(*): *}
 */
export const setCurrentView = (view) => (dispatch) => {
  return dispatch({
    type: SET_CURRENT_VIEW,
    payload: view
  })
}
