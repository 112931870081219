import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { preventDefaultRowClick, setColumnDefinition } from '../../../../../utils'
import { AgGrid, PopoverMenu } from '../../../../index'
import CustomCell from './cell/cell.component'

const columns = [
  {
    field: 'security_name',
    headerName: 'Name',
    minWidth: 400,
    pinned: 'left',
    lockPinned: true,
    headerCheckboxSelection: false,
    checkboxSelection: false
  },
  {
    field: 'security_country_name',
    headerName: 'Location',
    minWidth: 180,
    maxWidth: 250
  },
  {
    type: 'number',
    field: 'current',
    headerName: 'Pos',
    minWidth: 150,
    maxWidth: 220
  },
  {
    type: 'centered',
    field: 'qtr_change',
    headerName: 'Chg',
    minWidth: 150,
    maxWidth: 220
  },
  {
    type: 'number',
    field: 'market_value',
    headerName: 'Mkt val*',
    minWidth: 100,
    maxWidth: 150
  },
  {
    type: 'number',
    field: 'market_value_change',
    headerName: 'Chg*',
    minWidth: 100,
    maxWidth: 150
  },
  {
    type: 'centered',
    field: 'cap_group',
    headerName: 'Market Cap',
    minWidth: 120,
    maxWidth: 150,
    sortable: false
  },
  {
    type: 'number',
    field: 'percent_tso',
    headerName: '%OS',
    minWidth: 100,
    maxWidth: 150
  },
  {
    type: 'number',
    field: 'percent_portfolio',
    headerName: '%PORT',
    minWidth: 100,
    maxWidth: 150
  },
  {
    type: 'date',
    field: 'report_date',
    headerName: 'As Of',
    minWidth: 120,
    maxWidth: 140
  },
  {
    field: 'source',
    headerName: 'Source',
    minWidth: 120,
    maxWidth: 140,
    sortable: false
  }
]

class HoldingTable extends PureComponent {

  constructor (props) {
    super(props)

    this.state = {
      popoverMenuProps: null
    }
  }

  componentWillUnmount () {
    clearTimeout(this.interval)
  }

  /**
   * Handle AgGrid onGridReady event
   * @param grid
   * @see: {@link: https://github.com/ag-grid/ag-grid/issues/997}
   */
  handleGridReady = (grid) => {
    this.interval = setTimeout(() => this.agGrid = grid, 0)
  }

  /**
   * Handle AgGrid gridSizeChanged event
   * @param type - event type
   */
  handleGridResize = ({ type }) => {
    if (type === 'gridSizeChanged') {
      this.interval = setTimeout(() => this.agGrid && this.agGrid.api.sizeColumnsToFit(), 0)
    }
  }

  /**
   * Handle row click
   * @param row
   */
  handleRowClick = (row) => {
    if (!row) {
      return
    }

    const { handleRowClick } = this.props
    handleRowClick(row)
  }

  /**
   * Renders CustomCell Component
   */
  renderCustomCell = (props) => {
    return (
      <CustomCell
        {...props}
      />
    )
  }

  /**
   * On Expand Menu Close Click
   * @param event
   */
  onExpandMenuCloseClick = (event) => {
    event.stopPropagation()
    this.setState({ popoverMenuProps: null })
  }

  /**
   * Handle onSortChanged event (multi-sort included)
   * @param grid
   */
  handleSortChange = (grid) => {
    const api = grid && grid.api
    const sortModel = api && api.getSortModel()

    if (!sortModel || !sortModel[0]) {
      return
    }

    const sortParams = sortModel[0]
    this.props.handleSortChange({ property: sortParams.colId, direction: sortParams.sort })
  }

  render () {
    const { data, dataTotal, query, handleQueryChange, pageSizeId } = this.props

    return (
      <div className='holding-grid_table'>
        <AgGrid

          className='holdings-table'
          domLayout='autoHeight'
          sizeToFit={true}

          // suppress configs
          suppressMovableColumns
          suppressContextMenu
          enableSorting={false}

          // columns and data
          columnDefs={setColumnDefinition({ columns })}
          rowData={data}

          defaultColDef={{
            suppressMenu: true,
            sortable: true,
            cellRenderer: 'CustomCellRender'
          }}

          // custom components
          frameworkComponents={{
            CustomCellRender: this.renderCustomCell
          }}

          pagination
          paginationProps={{
            pageSizeId,
            forcePage: query.page,
            initialPageSize: query.limit,
            showPageSizeSelection: true,
            total: dataTotal,
            onPageChange: ({ selected: newPage }) => handleQueryChange({ page: newPage }),
            onPageSizeChange: ({ selected: newPageSize }) => handleQueryChange({ limit: newPageSize, page: 1 })
          }}

          // event listeners
          onSortChanged={this.handleSortChange}
          onGridReady={this.handleGridReady}
          onGridSizeChanged={this.handleGridResize}
          onRowClicked={preventDefaultRowClick(
            this.handleRowClick,
            ['expandable-cell']
          )}

          isPinned={data && data.length}
        />

        {/* Popover Menu for Custom Cell */}
        {this.state.popoverMenuProps && (
          <PopoverMenu
            onClick={() => () => {}}
            onClose={this.onExpandMenuCloseClick}
            scrollable={true}
            {...this.state.popoverMenuProps}
          />
        )}
      </div>)
  }
}

HoldingTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    security_name: PropTypes.string,
    security_country_name: PropTypes.string,
    current: PropTypes.number,
    change: PropTypes.number,
    market_value: PropTypes.number,
    market_value_change: PropTypes.number,
    cap_group: PropTypes.string,
    percent_tso: PropTypes.number,
    percent_portfolio: PropTypes.number,
    report_date: PropTypes.string,
    source: PropTypes.string
  })),
  dataTotal: PropTypes.number,
  query: PropTypes.shape({
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired
  }).isRequired,
  handleQueryChange: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  pageSizeId: PropTypes.string
}

HoldingTable.defaultProps = {
  data: [],
  dataTotal: 0,
  query: {}
}

export default HoldingTable
