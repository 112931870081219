import gql from 'graphql-tag'
import { useMutation, useQuery } from '@apollo/react-hooks'

export const GET_BRIEFING_BOOK = gql`
  query BriefingBook(
    $id: [String]
    $activity: Boolean
    $myBook: Boolean
    $search: String
    $searchField: [String]
    $sortBy: String
    $sortDir: String
    $page: Int
    $limit: Int
  ) {
    briefingBook(
      id: $id
      filter: {
        activity: $activity
        myBook: $myBook
      }
      search: $search
      searchField: $searchField
      sortBy: $sortBy
      sortDir: $sortDir
      page: $page
      limit: $limit
    ) {
      items {
        id
        title
        file {
          id
          key
          type
        }
        entity {
          entityId
          entityType
          institutionId
          entityConnection {
            ... on ContactDTO {
              id
              fullName
              jobs {
                id
                title
                institutionName
                entityId
              }
            }
            ... on FundDTO {
              id
              fundName
              institutionName
              institutionId
            }
            ... on InstitutionDTO {
              id
              institutionName
            }
          }
        }
        created
        updated
        activityConnection {
          items {
            id
            title
            category
          }
        }
        profileConnection {
          items {
            firstName
            lastName
          }
        }
      }
      count
    }
  }`

export const UPDATE_BRIEFING_BOOK = gql`
  mutation BriefingBook(
    $id: String!
    $title: String
    $entity: [BriefingBookEntity]
  ) {
    briefingBook {
      update(
        id: $id
        title: $title
        entity: $entity
      ) {
        count
      }
    }
  }`

const DELETE_BRIEFING_BOOK = gql`
  mutation BriefingBook($id: [String]!) {
    briefingBook {
      delete(id: $id) {
        count
      }
    }
  }`

/**
 * Briefing Book(s)
 * @param options
 */
export function useBriefingBookQuery (options) {
  return useQuery(GET_BRIEFING_BOOK, {
    ...options,
    fetchPolicy: 'no-cache'
  })
}

/**
 * Briefing Book Update
 * @param options
 */
export function useBriefingBookUpdateQuery (options) {
  return useMutation(UPDATE_BRIEFING_BOOK, options)
}

/**
 * Briefing Book(s) Delete
 * @param options
 */
export function useBriefingBookDeleteQuery (options) {
  return useMutation(DELETE_BRIEFING_BOOK, options)
}
