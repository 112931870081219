export const SEARCH_TYPE = {
  CONTACT: 'contacts',
  INSTITUTION: 'institutions',
  FUND: 'funds',
  SECURITY: 'securities',
  ACTIVITY: 'activity',
  TRANSCRIPT: 'transcript',
  RESEARCH: 'research'
}

export const ENTITY_SEARCH_TYPES = [
  SEARCH_TYPE.CONTACT,
  SEARCH_TYPE.INSTITUTION,
  SEARCH_TYPE.FUND,
  SEARCH_TYPE.SECURITY
]

export const SWIFTTYPE_FLAGS = {
  MANAGED_FUNDS: 8
}

// All swiftype filterable fields
export const SWIFTTYPE_FILTER_FIELDS = [
  'functions',
  'region',
  'country_name',
  'metro',
  'flags'
]

export const SEARCH_FACETS = {
  FUNCTIONS: 'functions',
  REGION: 'region',
  COUNTRY_NAME: 'country_name',
  METRO: 'metro'
}

export const SEARCH_SOURCE = {
  TAG_SEARCH: 'tag_search',
  ADVANCED_SEARCH: 'advanced_search',
  ADVANCED_SEARCH_V3: 'advanced_search_v3',
  GQL_ADVANCED_SEARCH: 'graphql_advanced',
  GRAPHQL_SEARCH: 'graphql_search',
  ELASTIC_SEARCH: 'elastic_search'
}
