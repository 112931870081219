import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

// actions
import { getContactCoverage, getContactEstimate, clearContact, statusType } from '../../../../actions'

// components
import ContactCoverageToolbar from './toolbar/toolbar.component'
import ContactCoverageTable from './coverage/table.component'
import ContactEstimateTable from './estimate/table.component'

// utils
import { getActiveTicker, getPageSizeFromStorage, isSubscribed } from '../../../../utils'
import { get } from 'lodash'

const propTypes = {
  toolbarTheme: PropTypes.string,
  toolTheme: PropTypes.string,
  id: PropTypes.string.isRequired
}

const defaultProps = {}

const PAGE_SIZE_ID = 'contact-coverage-grid'

/**
 * Contact Coverage Grid Component
 * @param props
 */
function ContactCoverageGrid (props) {
  const {
    toolbarTheme, toolTheme, id, securityId, coverage, estimate,
    getContactCoverage, getContactEstimate, clearContact, researchSubscription, history
  } = props
  const [tabs, setTabs] = useState({
    coverage: true,
    estimate: false
  })
  const [state, setState] = useState({
    listOptions: {
      page: 1,
      limit: getPageSizeFromStorage(PAGE_SIZE_ID) || 10,
      sort: JSON.stringify([{
        property: 'EPS',
        direction: 'desc'
      }])
    }
  })

  const { listOptions } = state

  useEffect(() => {
    if (!id || !securityId) {
      return
    }

    const variables = {
      securityId,
      analyst_id: id,
      item: ['EPS', 'SALES']
    }

    getContactCoverage({ ...variables, ...listOptions })
    getContactEstimate({ ...variables })

    return () => {
      clearContact()
    }
  }, [id, securityId, listOptions, getContactCoverage, getContactEstimate, clearContact])

  /**
   * Handle query change
   * @param query
   */
  const handleQueryChange = (query = {}) => {
    const options = query.listOptions ? { ...query } : { ...query, listOptions: { ...listOptions, page: 1 } }
    setState({ ...state, ...options })
  }

  return (
    <>
      <ContactCoverageToolbar
        toolbarTheme={toolbarTheme}
        toolTheme={toolTheme}
        tabs={tabs}
        onTabChange={(tabs) => setTabs({ ...tabs })}
      />
      {!tabs.estimate
        ? <ContactCoverageTable
          pageSizeId={PAGE_SIZE_ID}
          loading={get(coverage, 'status') === statusType.IN_PROGRESS}
          data={get(coverage, 'data', [])}
          total={get(coverage, 'total', 0)}
          listOptions={listOptions}
          onQueryChange={handleQueryChange}
          researchSubscription={researchSubscription}
          history={history}
        />
        : <ContactEstimateTable
          pageSizeId={PAGE_SIZE_ID}
          loading={get(estimate, 'status') === statusType.IN_PROGRESS}
          data={get(estimate, 'data', [])}
        />}
    </>
  )
}

ContactCoverageGrid.propTypes = propTypes
ContactCoverageGrid.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const profile = get(state, 'profile.data')
  const ticker = getActiveTicker(profile)
  return {
    securityId: get(ticker, '_security'),
    coverage: get(state, 'contact.coverage'),
    estimate: get(state, 'contact.estimate'),
    researchSubscription: isSubscribed(profile, 'research')
  }
}

const mapDispatchToProps = (dispatch) => ({
  getContactCoverage: bindActionCreators(getContactCoverage, dispatch),
  getContactEstimate: bindActionCreators(getContactEstimate, dispatch),
  clearContact: bindActionCreators(clearContact, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactCoverageGrid))
