import { CALL_API, METHOD_TYPE } from '../../../middleware/api.middleware'
import { addQueryParams } from '../../../utils/http/http.util'

export const FETCH_CURRENT_ACTIVITIES_REQUEST = 'FETCH_CURRENT_ACTIVITIES_REQUEST'
export const FETCH_CURRENT_ACTIVITIES_SUCCESS = 'FETCH_CURRENT_ACTIVITIES_SUCCESS'
export const FETCH_CURRENT_ACTIVITIES_ERROR = 'FETCH_CURRENT_ACTIVITIES_ERROR'
export const SET_CURRENT_ACTIVITY_DATE = 'SET_CURRENT_ACTIVITY_DATE'

export const FETCH_CALENDAR_REQUEST = 'FETCH_CALENDAR_REQUEST'
export const FETCH_CALENDAR_SUCCESS = 'FETCH_CALENDAR_SUCCESS'
export const FETCH_CALENDAR_ERROR = 'FETCH_CALENDAR_ERROR'

export const FETCHING = 'FETCHING'
export const IDLE = 'IDLE'
export const FETCHED = 'FETCHED'
export const FAILED = 'FAILED'

const resources = {
  activities: '/crm/activity',
  calendar: '/crm/activity/calendar'
}

const _fetchCurrentActivities = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_CURRENT_ACTIVITIES_REQUEST,
      FETCH_CURRENT_ACTIVITIES_SUCCESS,
      FETCH_CURRENT_ACTIVITIES_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

const _fetchCalendar = (url) => ({
  [CALL_API]: {
    endpoint: url,
    types: [
      FETCH_CALENDAR_REQUEST,
      FETCH_CALENDAR_SUCCESS,
      FETCH_CALENDAR_ERROR
    ],
    method: METHOD_TYPE.GET
  }
})

const _setCurrentActivityDate = (payload) => ({
  type: SET_CURRENT_ACTIVITY_DATE,
  payload
})

export const fetchCurrentActivities = (params) => {
  const url = addQueryParams(resources.activities, params)

  return (dispatch) => {
    return dispatch(_fetchCurrentActivities(url))
  }
}

export const setCurrentActivityDate = (params) => (dispatch) => {
  return dispatch(_setCurrentActivityDate(params))
}

export const fetchCalendar = (params) => (dispatch) => {
  const url = addQueryParams(resources.calendar, params)
  return dispatch(_fetchCalendar(url))
}
