import React, { Component } from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Button, Spinner } from '../../../components'
import { THEMES, isSubscribed } from '../../../utils'

import './chart.component.css'

const SERIES_ID = {
  POSITION: 'position',
  STOCK: 'stock',
  EVENTS: 'events',
  ACTIVITY: 'activity',
  NEWS: 'news'
}

const SERIES_NAME = {
  POSITION: 'My Position',
  STOCK: 'Stock',
  EVENTS: 'Events',
  ACTIVITY: 'Activity',
  NEWS: 'News'
}

/**
 * Chart Component
 */
class Chart extends Component {
  /**
   * Constructor
   * @param props
   */
  constructor (props) {
    super(props)
    this.state = {
      config: _getChartConfig(props),
      isStock: true
    }

    this.highcharts = React.createRef()
  }

  /**
   * componentDidUpdate
   * @param prevProps
   */
  componentDidUpdate (prevProps) {
    if (!this.props.isLoading && prevProps.isLoading) {
      this.setState({
        config: _getChartConfig(this.props)
      })
    }
  }

  /**
   * Render Chart Legend
   * @returns {*}
   */
  getLegend () {
    const { config, isStock } = this.state
    const { isLoading, subscription } = this.props
    const isCRMSubscribed = isSubscribed({ services: subscription }, 'crm')

    return (
      <div className={`ownership-entity-chart_legend ${!isLoading ? 'q4-fade-in' : ''}`}>
        {!isLoading && ((config && config.series) || []).map((item) => {
          const { id, name } = item
          return ((id === 'position')
            ? <div key={id} className='ownership-entity-chart_legend--position'>My Position</div>
            : <Button
              key={id}
              className={`button--${id}`}
              label={name}
              hidden={(id === 'activity') && !isCRMSubscribed}
              disabled={(id !== 'stock') && !isStock}
              onClick={(event) => this.onLegendItemClick(event, id)}
            />
          )
        })}
      </div>
    )
  }

  /**
   * On Legend Item Click
   * @param event
   * @param id
   */
  onLegendItemClick (event, id) {
    const { isStock } = this.state
    const chart = this.highcharts && this.highcharts.current && this.highcharts.current.chart
    const current = chart && chart.get(id)

    if (!current) {
      return
    }

    const series = chart.series
    const classes = event && event.target && event.target.classList

    current.setVisible(!current.visible)

    if (id === 'stock') {
      this.setState({ isStock: !isStock });

      (series || []).filter((series) => {
        return [SERIES_NAME.ACTIVITY, SERIES_NAME.EVENTS, SERIES_NAME.NEWS].includes(series.name)
      }).forEach((series) => series && series.setVisible(!isStock))
    }

    classes && classes.toggle('button--off')
  }

  /**
   * Render banner
   * @returns {*}
   */
  render () {
    const { config } = this.state
    const { isLoading } = this.props

    return (
      <div className='ownership-entity-chart q4-fade-in'>
        {isLoading ? <Spinner theme={THEMES.WHITE} />
          : (
            <>
              <HighchartsReact
                ref={this.highcharts}
                highcharts={Highcharts}
                options={config}
              />
              {(this.props.config && this.props.config.isLegend) ? this.getLegend() : null}
            </>
          )}
      </div>
    )
  }
}

Chart.propTypes = {
  position: PropTypes.array,
  stock: PropTypes.array,
  events: PropTypes.array,
  activity: PropTypes.array,
  news: PropTypes.array,
  height: PropTypes.number,
  subscription: PropTypes.array,
  ticker: PropTypes.object,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  quarters: PropTypes.number,
  config: PropTypes.object
}

Chart.defaultProps = {
  quarters: 5,
  config: {
    isLegend: true,
    isTearSheet: false,
    spacing: [24, 10, 20, 10],
    pointWidth: 60,
    animationDuration: 750,
    stockAxis: {
      labels: { x: 20, y: 12 },
      title: { x: 0, y: -12 }
    },
    positionAxis: {
      labels: { x: 0, y: 12 },
      title: { x: 0, y: -12 }
    },
    axisColor: '#939ba0',
    axisTitleColor: '#939ba0',
    axisLabelColor: '#939ba0',
    gridLineColor: '#373B41',
    positionColor: '#0f5ca3',
    stockColor: '#3498DB',
    yetStockColor: 'rgba(0, 0, 0, 0.2)',
    yetStockLabel: {
      y: 65,
      align: 'center'
    },
    labelFontSize: '11px',
    labelColor: '#FFF'
  }
}

export default Chart

/**
 * Filters series by subscription
 * @param series
 * @param subscription
 */
const _filterSeriesBySubscription = (series, subscription) => {
  const isCRMSubscribed = isSubscribed({ services: subscription }, 'crm')
  return (series || []).filter((item) => {
    if (item.id === 'activity' && !isCRMSubscribed) {
      return false
    }
    return true
  })
}

/**
 * Inject null values into series when no data for given day and add 'Yet to File' position
 * This helps maintain position of columns, when stock series is toggled (prevents jumping)
 * @param series
 * @param props
 */
const _injectNulls = (series, props) => {
  const { startDate, config } = props
  const days = []
  const currentQtr = moment.utc().endOf('quarter').startOf('day')
  const currentQtrStart = moment.utc().startOf('quarter').startOf('day')
  const isDelayed = moment.utc().isBetween(currentQtrStart, currentQtrStart.clone().add(45, 'day'))
  const hasNotFiled = moment.utc(_getLatestReportedQtr(series)).isBefore(currentQtr)

  if (isDelayed && hasNotFiled) {
    const sorted = series.map((item) => item.y).sort((a, b) => a - b)
    const prevQuarter = moment.utc().subtract(1, 'quarter').endOf('quarter').startOf('day')

    series.push({
      x: prevQuarter.valueOf(),
      y: series.every((item) => (item && item.y === 0)) ? 1 : sorted[series.length - 1], // align 'Yet To File' with largest position
      z: 'Yet To File',
      color: config.yetStockColor,
      dataLabels: config.yetStockLabel
    })
  }

  // generate array of days between given start/end date
  for (let day = moment.utc(startDate); day.isBefore(currentQtr); day.add(1, 'day')) {
    days.push(day.toDate())
  }

  return (days).map((day) => {
    const dataForDay = (series || []).find((point) => moment.utc(point.x).isSame(moment.utc(day), 'day')) || {}
    return {
      x: day,
      y: isNaN(dataForDay.y) ? null : dataForDay.y,
      z: dataForDay.z,
      color: dataForDay.color,
      dataLabels: dataForDay.dataLabels
    }
  })
}

/**
 * Highstock chart config for series
 * @param props
 */
const _getSeriesConfig = (props) => {
  const { position, stock, news, events, activity, subscription, startDate, config } = props

  const series = [
    {
      id: SERIES_ID.POSITION,
      name: SERIES_NAME.POSITION,
      type: 'column',
      yAxis: 'position-axis',
      xAxis: 0,
      clip: false,
      color: config.positionColor,
      zIndex: 1,
      data: _injectNulls(position || [], { startDate, config })
    },
    {
      id: SERIES_ID.STOCK,
      name: SERIES_NAME.STOCK,
      type: 'spline',
      yAxis: 'stock-axis',
      xAxis: 0,
      color: config.stockColor,
      lineWidth: 2,
      zIndex: 2,
      data: stock || []
    },
    {
      id: SERIES_ID.ACTIVITY,
      name: SERIES_NAME.ACTIVITY,
      type: 'flags',
      color: '#f1af0f',
      fillColor: '#f1af0f',
      shape: 'circle',
      onSeries: SERIES_ID.STOCK,
      stackDistance: 0,
      showInLegend: true,
      style: {
        width: '8px',
        height: '8px'
      },
      y: -4,
      visible: true,
      states: {
        hover: {
          fillColor: '#f1af0f'
        }
      },
      zIndex: 3,
      data: stock && (activity || [])
    },
    {
      id: SERIES_ID.EVENTS,
      name: SERIES_NAME.EVENTS,
      type: 'flags',
      color: '#1abc9c',
      fillColor: '#1abc9c',
      shape: 'circle',
      onSeries: SERIES_ID.STOCK,
      stackDistance: 0,
      showInLegend: true,
      style: {
        width: '8px',
        height: '8px'
      },
      y: -4,
      visible: true,
      states: {
        hover: {
          fillColor: '#1abc9c'
        }
      },
      zIndex: 4,
      data: stock && (events || [])
    },
    {
      id: SERIES_ID.NEWS,
      name: SERIES_NAME.NEWS,
      type: 'flags',
      color: '#fc7e26',
      fillColor: '#fc7e26',
      shape: 'circle',
      onSeries: SERIES_ID.STOCK,
      stackDistance: 0,
      style: {
        width: '8px',
        height: '8px'
      },
      y: -4,
      showInLegend: true,
      visible: true,
      enableMouseTracking: true,
      states: {
        hover: {
          fillColor: '#fc7e26'
        }
      },
      zIndex: 4,
      data: stock && (news || [])
    }
  ]

  return subscription ? _filterSeriesBySubscription(series, subscription) : series
}

/**
 * Get latest reported quarter from holdings data
 * @param position
 */
const _getLatestReportedQtr = ({ position }) => {
  const latestReportedQtr = position && position.length && position[position.length - 1]
  const latestReportedQtrDate = latestReportedQtr
    ? moment.utc(latestReportedQtr.x).endOf('quarter').startOf('day')
    : moment.utc().subtract(1, 'quarter').endOf('quarter').startOf('day')

  return latestReportedQtrDate.toDate()
}

/**
 * Get min start date for chart to render
 * Offset chart start date using latest available reported qtr (differs between 13f/Surveillance)
 * @param position
 * @param startDate
 */
const _getOffsetStartDate = ({ position, quarters }) => {
  const latestReportedQtrDate = _getLatestReportedQtr({ position })
  const offsetStartDate = moment.utc(latestReportedQtrDate).subtract(quarters - 1, 'quarter').startOf('day')
  const minStartDate = moment.utc().endOf('quarter').subtract(quarters, 'quarter').startOf('day')

  return minStartDate.isAfter(offsetStartDate) ? minStartDate.toDate() : offsetStartDate.toDate()
}

/**
 * Base highstock chart config
 * @param props
 */
const _getChartConfig = (props) => {
  const { width, height, position, quarters, config } = props
  const startQtr = _getOffsetStartDate({ position, quarters })

  const label = {
    stock: config.isLegend ? 'Share Price ($)' : 'Share Price ($) <div class="ownership-entity-chart_circle ownership-entity-chart_circle--stock"></div>',
    position: config.isLegend ? 'Position' : '<div class="ownership-entity-chart_circle ownership-entity-chart_circle--position"></div> Position'
  }

  return {
    chart: {
      animation: false,
      spacing: config.spacing,
      backgroundColor: null,
      style: {
        fontFamily: 'Open Sans'
      },
      width,
      height
    },
    noData: {
      style: { display: 'none' }
    },
    credits: { enabled: false },
    rangeSelector: { enabled: false },
    navigator: { enabled: false },
    scrollbar: { enabled: false },
    legend: { enabled: false },
    navigation: {
      buttonOptions: {
        enabled: false
      }
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        color: '#4696E0',
        enableMouseTracking: false,
        allowPointSelect: false,
        pointWidth: config.pointWidth,
        dataLabels: {
          inside: false,
          enabled: config.isLegend,
          align: 'left',
          shadow: false,
          rotation: -90,
          y: -10,
          x: -5,
          style: {
            color: config.labelColor,
            textOutline: 'none'
          },
          zIndex: 3,
          formatter: function () {
            let label = ''

            if (this.point && this.point.z) {
              return config.isTearSheet ? `${this.point.z}` : `${this.point.z}<br />${props.ticker.symbol}`
            }

            if (this.y || this.y === 0) {
              if (this.y > 1000000) {
                label = (this.y / 1000000).toFixed(1) + 'M'
              } else if (this.y > 1000) {
                label = (this.y / 1000).toFixed(1) + 'K'
              } else {
                label = this.y.toFixed(0)
              }
            }
            return `${label}<br />${props.ticker.symbol}`
          }
        }
      },
      flags: {
        allowPointSelect: true,
        enableMouseTracking: true,
        animation: false
      },
      spline: {
        allowPointSelect: false,
        enableMouseTracking: false,
        states: {
          hover: {
            halo: {
              opacity: 0,
              size: 0
            }
          }
        }
      },
      series: {
        minPointLength: 2,
        animation: {
          duration: Number(config.animationDuration)
        },
        marker: {
          enabled: false
        },
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    title: {
      text: null
    },
    tooltip: {
      borderRadius: 5,
      backgroundColor: '#FFF',
      crosshairs: false,
      useHTML: true,
      animation: true,
      hideDelay: 800,
      formatter: function () {
        const date = moment.utc(this.x).format('dddd, MMM D YYYY')
        const text = this.point && this.point.options && this.point.options.text
        return `<div class='chart_tip'>
          <div class='chart_tip_header'>${date}</div>
          <div class='chart_tip_body'>${text}</div>
        </div>`
      },
      shared: true
    },
    xAxis: [
      {
        id: 'datetime-axis',
        type: 'datetime',
        lineWidth: 0,
        tickWidth: 0,
        floor: moment.utc(startQtr).subtract(1, 'quarter').add(1, 'week').valueOf(),
        ceiling: moment.utc().endOf('quarter').subtract(1, 'week').valueOf(),
        title: {
          text: null
        },
        labels: {
          rotation: 0,
          formatter: function () {
            return moment.tz(this.value, 'America/New_York').format('MM/DD/YY')
          },
          style: {
            color: config.axisLabelColor,
            fontSize: config.labelFontSize
          }
        },
        units: [['month', [3]]]
      }
    ],
    yAxis: [
      { // Stock Axis
        id: 'stock-axis',
        labels: {
          format: '{value}',
          useHTML: true,
          style: {
            color: config.axisColor,
            fontSize: config.labelFontSize
          },
          x: (config.stockAxis && config.stockAxis.labels && config.stockAxis.labels.x),
          y: (config.stockAxis && config.stockAxis.labels && config.stockAxis.labels.y)
        },
        plotLines: false,
        gridLineWidth: 1,
        lineColor: config.lineColor,
        lineWidth: 0,
        tickWidth: 0,
        gridLineColor: config.gridLineColor,
        opposite: false,
        maxPadding: 0.05,
        title: {
          useHTML: true,
          text: label.stock,
          align: 'high',
          rotation: 0,
          offset: -80,
          x: (config.stockAxis && config.stockAxis.title && config.stockAxis.title.x),
          y: (config.stockAxis && config.stockAxis.title && config.stockAxis.title.y),
          style: {
            color: config.axisTitleColor
          }
        }
      },
      { // position axis
        id: 'position-axis',
        labels: {
          formatter: function () {
            if (this.value > 1000000) {
              return (this.value / 1000000).toFixed(0) + 'M'
            } else if (this.value > 1000) {
              return (this.value / 1000).toFixed(0) + 'K'
            }
            return this.value
          },
          useHTML: true,
          align: 'right',
          style: {
            color: config.axisColor,
            fontSize: config.labelFontSize
          },
          x: (config.positionAxis && config.positionAxis.labels && config.positionAxis.labels.x),
          y: (config.positionAxis && config.positionAxis.labels && config.positionAxis.labels.y)
        },
        gridLineWidth: 1,
        plotLines: false,
        lineWidth: 0,
        tickWidth: 0,
        gridLineColor: config.gridLineColor,
        opposite: true,
        maxPadding: 0.4,
        title: {
          useHTML: true,
          text: label.position,
          align: 'high',
          rotation: 0,
          offset: -45,
          x: (config.positionAxis && config.positionAxis.title && config.positionAxis.title.x),
          y: (config.positionAxis && config.positionAxis.title && config.positionAxis.title.y),
          style: {
            color: config.axisTitleColor
          }
        }
      }
    ],
    series: _getSeriesConfig(props)
  }
}
