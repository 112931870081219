import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { debounce, THEMES } from '../../../../../../../utils'
import { ComboBox } from '../../../../../../../components'

/**
 * Location Filter
 */
class Location extends PureComponent {

  /**
   * handle AutoComplete onInputChange event
   * @param query
   */
  handleInputChange = debounce((query) => {
    const { searchLocations, resetLocationSuggestions } = this.props

    if (!query) {
      resetLocationSuggestions()
      return
    }

    searchLocations(query)
  })

  /**
   * Handle AutoComplete onChange event
   * @param selectedValue
   * @param type
   */
  handleChange = (selectedValue, type) => {
    if (!type || !type.action || type.action !== 'select-option') {
      return
    }

    const { onChange, value, resetLocationSuggestions } = this.props
    const selected = [].concat(value || [], selectedValue.label)

    onChange(selected)
    resetLocationSuggestions()
  }

  /**
   * Handle RemovableList onRemove event
   * @param encodedLocation
   */
  handleRemove = (encodedLocation) => {
    const { value, onChange, resetLocationSuggestions } = this.props
    const selected = [].concat(value).filter((loc) => loc !== decodeURIComponent(encodedLocation))

    onChange(selected)
    resetLocationSuggestions()
  }

  /**
   * Get formatted options to be properly consumed by the combo box
   * @param values
   * @returns {Array|*}
   */
  getFormattedValues = (values) => {
    if (!values || !values.length) {
      return []
    }

    return values.map((location) => {
      return {
        value: encodeURIComponent(location),
        label: location
      }
    })
  }

  /**
   * Render
   * @return {*}
   */
  render = () => {
    const { suggestions, value, loading } = this.props

    return (
      <div className='field field--text field--full'>
        <label className='field_label'>Location</label>
        <ComboBox
          theme={THEMES.WHITE}
          selectProps={{
            placeholder: 'Search',
            value: null,
            options: this.getFormattedValues(suggestions),
            loading,
            onInputChange: this.handleInputChange,
            onChange: this.handleChange
          }}
          removableListProps={{
            items: this.getFormattedValues(value),
            onRemove: this.handleRemove
          }}
        />
      </div>
    )
  }
}

Location.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  suggestions: PropTypes.array,
  searchLocations: PropTypes.func.isRequired,
  resetLocationSuggestions: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Location
