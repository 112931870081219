import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'

// utils
import { THEMES } from '../../../../utils/ui'
import { getCompleteSeries, yAxisOptions } from '../../../../utils/report'

// components
import { Select } from '../../../../components'
import { Checkbox } from '../../../shared'

// css
import './chartAxis.component.css'

/**
 * ReportChartAxisConfig
 * @param chartType
 * @param isStockReport
 * @param yAxis
 * @param series
 * @param onChange
 * @constructor
 */
function ReportChartAxisConfig ({ chartType, isStockReport, yAxis, series, onChange }) {
  const [selectedAxisId, setSelectedAxisId] = useState(null)

  const availableSeries = getCompleteSeries(series)

  if (!availableSeries || !availableSeries.length) {
    return (
      <p>Series configuration is required.</p>
    )
  }

  const showTrendLineCheckbox = !['pie', 'bar'].includes(chartType)
  const selectedAxis = (yAxis || []).find((axisItem) => axisItem.id === selectedAxisId)
  const { floor, ceiling, shouldRenderAbsoluteValues, shouldRenderRelativeValues, shouldRenderTrendLine } = selectedAxis || {}

  /**
   * Handles Axis Item Update
   * @param key
   * @param value
   */
  const updateAxisItem = (key, value) => {
    const newAxis = (yAxis || []).map((axisItem) => {
      if (axisItem.id === selectedAxisId) {
        return {
          ...axisItem,
          [key]: value
        }
      }

      return axisItem
    })

    onChange && onChange(newAxis)
  }
  /**
   * Handles Axis Select
   * @param option
   */
  const handleAxisSelect = (option) => setSelectedAxisId(option.value)

  /**
   * Handles Input Change
   * @param key
   * @return {function(*): void}
   */
  const handleInputChange = (key) => (event) => updateAxisItem(key, parseFloat(event.target.value) || null)

  /**
   * Handles Checkbox Change
   * @param key
   * @return {function(*=): void}
   */
  const handleCheckboxChange = (key) => (isChecked) => updateAxisItem(key, isChecked)

  /**
   * Handles Input Blur
   * @param key
   * @return {Function}
   */
  const handleInputBlur = (key) => (event) => {
    const value = parseFloat(event.target.value)
    key === 'floor' && ceiling !== null && !isNaN(ceiling) && value > ceiling && updateAxisItem('ceiling', value)
    key === 'ceiling' && floor !== null && !isNaN(floor) && value < floor && updateAxisItem('floor', value)
  }

  return (
    <section className='report-axis-config'>
      <div className='field field--dropdown field--full'>
        <label className='field_label'>Select axis</label>
        <Select
          placeholder='Select Axis'
          className='report-axis-config_dropdown'
          theme={THEMES.WHITE}
          options={yAxisOptions}
          value={yAxisOptions.find((option) => (option && option.value) === selectedAxisId)}
          onChange={handleAxisSelect}
          clearable={false}
          searchable={false}
        />
      </div>
      {selectedAxis && (
        <div className='report-axis-config_details'>
          <div className='field field--text field--white field--half'>
            <label className='field_label' htmlFor='report-axis-config_floor'>Min</label>
            <input
              id='report-axis-config_floor'
              className='field_input'
              type='number'
              value={floor || ''}
              max={ceiling || ''}
              placeholder='Auto'
              onChange={handleInputChange('floor')}
              onBlur={handleInputBlur('floor')}
            />
          </div>
          <div className='field field--text field--white field--half'>
            <label className='field_label' htmlFor='report-axis-config_ceiling'>Max</label>
            <input
              id='report-axis-config_ceiling'
              className='field_input'
              type='number'
              value={ceiling || ''}
              min={floor || ''}
              placeholder='Auto'
              onChange={handleInputChange('ceiling')}
              onBlur={handleInputBlur('ceiling')}
            />
          </div>
          <div className='field field--full field--grouped'>
            <Checkbox
              theme='white-rain'
              id='report-axis-config_absolute-values'
              label='Display values as absolute'
              isChecked={shouldRenderAbsoluteValues}
              onChange={handleCheckboxChange('shouldRenderAbsoluteValues')}
            />
          </div>
          {isStockReport && (
            <div className='field field--full field--grouped'>
              <Checkbox
                theme='white-rain'
                id='report-axis-config_relative-values'
                label='Compare (%)'
                isChecked={shouldRenderRelativeValues}
                onChange={handleCheckboxChange('shouldRenderRelativeValues')}
              />
            </div>
          )}
          {showTrendLineCheckbox && (
            <div className='field field--full field--grouped'>
              <Checkbox
                theme='white-rain'
                id='report-axis-config_trend-line'
                label='Trendline'
                isChecked={shouldRenderTrendLine}
                onChange={handleCheckboxChange('shouldRenderTrendLine')}
              />
            </div>
          )}
        </div>
      )}
    </section>
  )
}

ReportChartAxisConfig.propTypes = {
  chartType: PropTypes.string.isRequired,
  yAxis: PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

ReportChartAxisConfig.defaultProps = {
  yAxis: []
}

export default memo(ReportChartAxisConfig)
