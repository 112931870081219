import React from 'react'
import ReactHighstock from 'react-highcharts/ReactHighstock'
import { format, aboveOrBelow, getScaledNumber } from '../../../../utils/number.util'
import { spice, teal, white, lightSlate } from '../../../../resources/materialui-overrides/colors'
import _ from 'lodash'
import './relativePerformance.component.css'

const PerformanceChart = (props) => {
  const { theme, title, active, symbol, formatNumber } = props
  const categories = props.categories || []
  const change = props.change.toFixed(2) || 0
  const type = title === 'volume'
  const data = (props.data && props.data.length) ? props.data : []

  const plotOptions = {
    series: {
      pointPadding: 0,
      groupPadding: 0,
      stacking: 'normal',
      borderWidth: 0,
      borderRadius: 2,
      pointWidth: 12,
      maxPointWidth: 12,
      minPointLength: 2
    }
  }

  const chartSettings = {
    credits: { enabled: false },
    title: {
      text: ''
    },
    navigator: {
      enabled: false
    },
    scrollbar: {
      enabled: false
    },
    rangeSelector: {
      enabled: false
    }
  }

  const xAxisSettings = {
    minorGridLineWidth: 0,
    lineColor: 'transparent',
    tickLength: 0,
    tickInterval: 0.4
  }

  const yAxis = {
    gridLineWidth: 0,
    title: {
      text: null
    },
    labels: {
      enabled: false
    }
  }

  const labelStyle = {
    'font-family': '"Open Sans", Arial !important',
    color: theme === 'dark' ? white : lightSlate,
    fontWeight: 'normal',
    fontSize: '13px'
  }

  const createSeries = (data) => {
    const series = [{
      color: spice,
      data: []
    }, {
      color: teal,
      data: []
    }]

    _.each(data, (each) => {
      const _each = Number((each || 0).toFixed(2))

      if (_each <= 0) {
        series[0].data.push({
          y: _each,
          color: _each !== 0 ? spice : 'transparent'
        })
        series[1].data.push(null)
      } else {
        series[1].data.push({
          y: _each,
          color: _each !== 0 ? teal : 'transparent'
        })
        series[0].data.push(null)
      }
    })

    return series
  }

  const getChartConfig = (categories, data, formatNumber) => {
    return {
      chart: {
        type: 'bar',
        backgroundColor: 'transparent',
        spacing: [0, 0, 0, 0],
        marginRight: 70,
        height: data.length * 18
      },
      ...chartSettings,
      xAxis: [{
        ...xAxisSettings,
        categories,
        labels: {
          style: labelStyle,
          enabled: true,
          align: 'left',
          reserveSpace: true,
          formatter: function () {
            return categories[this.value]
          }
        }
      }, {
        ...xAxisSettings,
        categories: data,
        opposite: true,
        reversed: false,
        linkedTo: 0,
        labels: {
          style: labelStyle,
          enabled: true,
          align: 'right',
          x: 70,
          formatter: function () {
            const dataItem = data[this.value]

            if (!dataItem) {
              return
            }

            if (formatNumber) {
              if (Math.abs(Number(dataItem)) > 1000000) {
                // TODO: inThousands or imMillions should be used here instead
                return `${getScaledNumber(dataItem, 2, { scale: 1000000 })}M`
              } else {
                return `${getScaledNumber(dataItem, 2, { scale: 1000 })}K`
              }
            } else {
              return dataItem.toFixed(2)
            }
          }
        }
      }],
      yAxis,
      plotOptions,
      tooltip: {
        enabled: false,
        crosshairs: false
      },
      series: createSeries(data)
    }
  }

  return (
    <div className={`nlg-item nlg-item--${theme} nlg-item--${title} nlg-item--${active}`} onClick={(e) => props.onRelativePerformanceClick && props.onRelativePerformanceClick(e)}>
      <h2 className='nlg-item_title'>{title}</h2>
      <div className='nlg-item_wrapper'>
        {type
          ? <div className={`nlg-item_change nlg-item_change--${title}`}>
            <p>{symbol} traded {format(parseInt(change, 10), 0)} shares {aboveOrBelow(change)} expectations. Breakdown
                            as follows.
            </p>
          </div>
          : <h3 className={`nlg-item_change nlg-item_change--${title}`}>
            {change}
          </h3>}
        <ReactHighstock config={getChartConfig(categories, data, formatNumber)} />
      </div>
    </div>
  )
}

export default PerformanceChart
