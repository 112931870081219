import React from 'react'
import { Button } from '../../../../../components'
import { combine, formatDate, THEMES, formatLocalizedDate } from '../../../../../utils'
import { get } from 'lodash'

const EMPTY_PLACEHOLDER = '-'

/**
 * Title Custom Cell Component
 */
const TitleCell = ({ dataId, data, history }) => {
  const { title, activityConnection } = data
  const activityId = get(activityConnection, 'items[0].id', null)

  return <div className='cell'>
    <div className='cell_content'>
      <div className='cell_content-name'>{title || EMPTY_PLACEHOLDER}</div>
    </div>
    {activityId && <Button
      dataId={`${dataId}Activity`}
      theme={THEMES.LIGHT_GREY}
      icon='q4i-activity-4pt'
      label='Activity'
      onClick={() => history.push(`/activity/${activityId}`)}
    />}
  </div>
}

/**
 * AgGrid Custom Cell Render Component
 * @param props
 */
const CustomCell = ({ dataId, data = {}, column = {}, history }) => {
  const cellData = data[column.colId]
  switch (column.colId) {
    case 'title':
      return <TitleCell dataId={dataId} data={data} history={history} />
    case 'author':
      const author = combine([get(data, 'profileConnection.items[0].firstName', null), get(data, 'profileConnection.items[0].lastName', null)], ' ')
      return (author && author.length) ? author : EMPTY_PLACEHOLDER
    case 'created':
    case 'updated':
      return cellData ? formatLocalizedDate(formatDate(cellData),'MM/DD/YY') : EMPTY_PLACEHOLDER
    default:
      return EMPTY_PLACEHOLDER
  }
}

export default CustomCell
