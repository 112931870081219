import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

// redux actions
import { statusType, fetchCurrentPosition, fetchPurchasingPower, fetchAiScore } from '../../../../../../../actions'

// utils
import { getActiveTicker } from '../../../../../../../utils'

// components
import AttributesComponent from './attributes.component'

const propTypes = {
  // entity
  entityType: PropTypes.oneOf(['institution', 'fund']).isRequired,
  entityId: PropTypes.string.isRequired,
  entity: PropTypes.shape({
    ai_score: PropTypes.number,
    portfolio_value: PropTypes.number,
    total_aum: PropTypes.number,
    equity_aum: PropTypes.number,
    quality_rating: PropTypes.number,
    style: PropTypes.string
  }).isRequired,
  // current position
  currentPosition: PropTypes.number,
  fetchCurrentPosition: PropTypes.func.isRequired,
  currentPositionStatus: PropTypes.string.isRequired,
  // purchasing power
  purchasingPower: PropTypes.number,
  fetchPurchasingPower: PropTypes.func.isRequired,
  purchasingPowerStatus: PropTypes.string.isRequired,
  className: PropTypes.string
}

const defaultProps = {
  entityType: 'institution'
}

/**
 * Attributes Container
 */
function Attributes (props) {
  const {
    entityType,
    entityId,
    securityId,
    fetchCurrentPosition,
    currentPositionStatus,
    currentPosition,
    fetchPurchasingPower,
    purchasingPower,
    purchasingPowerStatus,
    fetchAiScore,
    aiScore,
    aiScoreStatus,
    className,
    tickerId
  } = props
  const entity = getEntityProps(props, entityType)

  useEffect(() => {
    fetchPurchasingPower(entityType, { factsetEntityId: entityId, securityId })
    fetchCurrentPosition(entityType, { factsetEntityId: entityId, tickerId, securityId })
    fetchAiScore({ entityType, entityId, tickerId, securityId })
  }, [entityId, entityType, securityId, fetchPurchasingPower, fetchCurrentPosition, fetchAiScore])

  /**
   * Returns entity props for institution or fund
   * @param {Object} props
   * @param {String} entityType
   */
  function getEntityProps (props, entityType) {
    const entityMap = {
      institution: {
        ...props.entity,
        aum: get(props, 'entity.total_aum')
      },
      fund: {
        ...props.entity,
        aum: get(props, 'entity.portfolio_value')
      }
    }

    return entityMap[entityType]
  }

  return (
    <AttributesComponent
      entityType={entityType}
      entity={{ ...entity, currentPosition, purchasingPower, aiScore }}
      isLoading={
        [purchasingPowerStatus, currentPositionStatus, aiScoreStatus].some((status) => status === statusType.IN_PROGRESS)
      }
      className={className}
    />
  )
}

Attributes.propTypes = propTypes
Attributes.defaultProps = defaultProps

const mapStateToProps = (state) => {
  const ticker = getActiveTicker(get(state, 'profile.data'))
  return {
    securityId: get(ticker, '_security'),
    tickerId: get(ticker, 'q4_ticker_id'),
    currentPosition: get(state, 'targeting.detail.position.data.current'),
    currentPositionStatus: get(state, 'targeting.detail.position.status'),
    purchasingPower: get(state, 'targeting.detail.purchasingPower.data.purchasing_power'),
    purchasingPowerStatus: get(state, 'targeting.detail.purchasingPower.status'),
    aiScore: get(state, 'aiScore.aiScore'),
    aiScoreStatus: get(state, 'aiScore.status')
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentPosition: bindActionCreators(fetchCurrentPosition, dispatch),
  fetchPurchasingPower: bindActionCreators(fetchPurchasingPower, dispatch),
  fetchAiScore: bindActionCreators(fetchAiScore, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Attributes))
