import React from 'react'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom'
import { statusType } from '../../../../../../actions'
import {
  CONTACT_INSTITUTION_CARD_LAYOUT,
  ENTITY_CONTACT,
  ENTITY_TYPE,
  FLYOUT_ENTITY_TYPES,
  formatEntity,
  THEMES
} from '../../../../../../utils'
import FlyoutTitle from '../components/flyoutTitle/flyoutTitle.container'
import FlyoutBanner from '../components/banner/banner.component'
import ManagedFundGrid from '../../../../../../components/contact/managedFundGrid/grid.container'
import { FlyoutBadge, ContactInformation, HighLightsContainer } from '../components'
import { ContactBiography, Institution } from '../../../../../../components/contact'
import { SectionTab } from '../../../../../../components/shared'
import { ActivityGridOld, EntitySummary, TagInput } from '../../../../../../components'

const ContactComponent = ({ classes, isLoading, contact, targetId, hasDeal, handleRedirect, ...props }) => {
  const entity = formatEntity(contact, ENTITY_CONTACT)
  const { direct_phone, mobile, direct_email, phone_extension } = entity
  const { profile, institutionName, handleClose, handleTargetUtilAction, handleGDPRmodal } = props
  const { jobs } = entity
  const isInformation = [direct_phone, mobile, direct_email, phone_extension].some((item) => item)
  const { bio, status, contactStatus } = contact
  const gdpr = contactStatus === 'gdpr'

  return (
    isLoading
      ? null
      : (
        <div className='q4-fade-in target-detail--contact'>
          <FlyoutBanner
            title={(<FlyoutTitle title={entity.name}/>)}
            icon='q4i-contact-2pt'
            theme={THEMES.WHITE}
            badgeType='contact'
            data={entity}
            entityType={ENTITY_CONTACT}
            profile={profile}
            targetId={targetId}
            hasDeal={hasDeal}
            details={institutionName}
            handleClose={handleClose}
            handleGDPRmodal={handleGDPRmodal}
            gdpr={gdpr}
            handleTargetUtilAction={handleTargetUtilAction}
          />
          <div className='target-detail_overview'>
            <Grid container spacing={3}>
              <FlyoutBadge targetId={targetId} />
              {(jobs || []).map((job, idx) => {
                return (
                  <Grid key={idx} item xs={12}>
                    {!gdpr &&
                    <Institution
                      contactId={contact._id}
                      contactJob={job}
                      theme={THEMES.LIGHT}
                      layout={CONTACT_INSTITUTION_CARD_LAYOUT.COMPACT}
                      onTitleClick={handleRedirect}
                    />
                    }
                  </Grid>
                )
              })}
              {!gdpr && isInformation && (
                <Grid item xs={6}>
                  <ContactInformation
                    entity={entity}
                    className='contact-information'
                  />
                </Grid>
              )}
              {!gdpr && (
                <Grid item xs={6}>
                  <HighLightsContainer
                    entity={{
                      ...entity,
                      entityType: FLYOUT_ENTITY_TYPES.CONTACT
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <EntitySummary
                  className='target-detail_comment'
                  entityId={contact._id}
                  entityType={FLYOUT_ENTITY_TYPES.CONTACT}
                  theme={THEMES.LIGHT}
                />
              </Grid>
              {!gdpr && (
                <Grid item xs={6}>
                  <ContactBiography
                    bio={bio}
                    theme={THEMES.LIGHT}
                    isLoading={status === statusType.IN_PROGRESS}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TagInput
                  theme={THEMES.LIGHT}
                  entityType={ENTITY_TYPE.CONTACT}
                  entityId={contact._id}
                />
              </Grid>
            </Grid>
          </div>
          <div className='target-detail_tab-section'>
            <SectionTab
              rememberMounted
              tabs={[
                {
                  id: 'managed-funds',
                  label: 'Managed Funds',
                  view: (
                    <ManagedFundGrid
                      contactId={contact._id}
                      handleRowClick={({ entityId }) => {
                        handleRedirect({ entityId, entityType: FLYOUT_ENTITY_TYPES.FUND })
                      }}
                    />
                  ),
                  hidden: gdpr
                },
                {
                  id: 'activity',
                  label: 'Activity',
                  view: (
                    <ActivityGridOld
                      showChildren
                      isEntity
                      entityId={entity && entity._id}
                      entity={contact}
                      entityType={ENTITY_CONTACT}
                      handleExpandMenuItemClick={handleRedirect}
                    />
                  )
                }
              ].filter((tab) => !tab.hidden)}
            />
          </div>

        </div>
      )
  )
}

export default withRouter(ContactComponent)
