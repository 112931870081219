import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { Badge } from '../../index'
import { THEMES } from '../../../utils'

const propTypes = {
  dataId: PropTypes.string,
  theme: PropTypes.oneOf([THEMES.DARK, THEMES.LIGHT]),
  className: PropTypes.string,
  data: PropTypes.shape({
    custom: PropTypes.bool,
    favorite: PropTypes.string,
    targetId: PropTypes.string,
    hasActiveDeal: PropTypes.bool,
    isWebcast: PropTypes.bool,
    gdpr: PropTypes.bool
  }).isRequired
}

const defaultProps = {
  theme: THEMES.DARK,
  data: {}
}

/**
 * Entity Badge Component
 * @param props
 */
function EntityBadge ({ dataId, theme, className, data }) {
  const { activist, custom, favorite, targetId, hasActiveDeal, isAnalytics, isWebcast, gdpr } = data

  const badges = [
    {
      dataId: `${dataId}BannerTargetBadge`,
      icon: 'q4i-targeting-4pt',
      label: 'Saved Target',
      hidden: !targetId
    },
    {
      dataId: `${dataId}BannerDealBadge`,
      icon: 'q4i-deal-4pt',
      label: 'Active Deal',
      hidden: !hasActiveDeal
    },
    {
      dataId: `${dataId}BannerActivistBadge`,
      icon: 'q4i-activist-4pt',
      label: 'Activist',
      hidden: !activist
    },
    {
      dataId: `${dataId}BannerAnalyticsBadge`,
      icon: 'q4i-website-analytics-4pt',
      label: 'Visited Website',
      hidden: !isAnalytics
    },
    {
      dataId: `${dataId}BannerCustomContactBadge`,
      icon: 'q4i-custom-4pt',
      label: 'Custom Contact',
      hidden: !custom
    },
    {
      dataId: `${dataId}BannerAddressBookBadge`,
      icon: 'q4i-contact-list-4pt',
      label: 'Saved Contact',
      hidden: !favorite
    },
    {
      dataId: `${dataId}BannerWebcastBadge`,
      icon: 'q4i-webcast-4pt',
      label: 'Webcast Attendee',
      hidden: !isWebcast || gdpr
    }
  ].filter((item) => !item.hidden)

  return !!badges.length && <Grid item xs={12} className={className}>
    <Badge
      theme={theme}
      items={badges}
    />
  </Grid>
}

EntityBadge.propTypes = propTypes
EntityBadge.defaultProps = defaultProps

export default memo(EntityBadge)
