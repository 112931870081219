import React, { memo } from 'react'
import PropTypes from 'prop-types'

// components
import { Banner } from '../../../../../../../components'
import { FlyoutTitle } from '../../components'
import GdprMessage from './gdpr/gdpr.component'

// utils
import { combine, ENTITY_TYPE, THEMES } from '../../../../../../../utils'

const { CONTACT } = ENTITY_TYPE

const propTypes = {
  dataIdPrefix: PropTypes.string.isRequired,
  theme: PropTypes.string,
  contact: PropTypes.object.isRequired,
  targetId: PropTypes.string,
  hasActiveDeal: PropTypes.bool,
  onDealSaveSuccess: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

const defaultProps = {
  dataIdPrefix: 'ContactFlyout',
  contact: {},
  hasActiveDeal: false
}

/**
 * Contact Detail Page Banner Component
 * @param props
 */
function ContactDetailBanner (props) {
  const { dataIdPrefix, theme, contact, targetId, hasActiveDeal, onDealSaveSuccess, openModal, onClose } = props
  const { salutation, fullName, nickname, suffixProf, status } = contact

  const gdpr = status === 'gdpr'
  const title = combine([salutation, fullName], ' ')

  const controls = [
    {
      dataId: `${dataIdPrefix}BannerUtility`,
      type: 'utility',
      theme,
      entity: { ...contact, _target: targetId },
      entityType: CONTACT,
      items: [
        { action: 'CREATE_ACTIVITY' },
        {
          action: 'TARGET',
          hide: hasActiveDeal
        },
        {
          action: 'CREATE_DEAL',
          onSaveSuccess: onDealSaveSuccess
        },
        { action: 'DOWNLOAD_TEARSHEET' },
        { action: 'BRIEFING_BOOK' },
        { action: 'ADDRESS_BOOK' }
      ]
    },
    {
      type: 'button',
      theme,
      icon: 'q4i-close-4pt',
      square: true,
      onClick: onClose
    }
  ]

  return (
    <Banner
      theme={theme}
      size='medium'
      icon='q4i-contact-2pt'
      title={(<FlyoutTitle title={combine([title, suffixProf])} />)}
      details={nickname && <span>Nickname - {nickname}</span>}
      disclosure={gdpr
        ? {
            type: 'button',
            theme: THEMES.CHERRY,
            label: 'GDPR Forgotten',
            onClick: () => openModal({
              component: GdprMessage
            })
          }
        : null}
      controls={controls}
    />
  )
}

ContactDetailBanner.propTypes = propTypes
ContactDetailBanner.defaultProps = defaultProps

export default memo(ContactDetailBanner)
