import React, { Component } from 'react'
import { format } from '../../../../../utils/number.util'
import { getChange } from '../../../../../utils/ownership/ownership.util'
import { withRouter } from 'react-router-dom'
import './currentTopShareholders.component.css'

const getHolderTypeIcon = (shareholder) => {
  // Insider Icon
  if ((shareholder.holder_type && shareholder.holder_type === 'person') || (shareholder.holderType && shareholder.holderType === 'person')) {
    return 'q4i-insider-4pt'
  }

  return 'q4i-institution-4pt'
}

const getKey = (shareholder) => {
  const { _id, factset_entity_id, institutionId } = shareholder || {}

  return _id || factset_entity_id || institutionId
}

const redirect = (shareholder, history) => {
  if ((shareholder.holder_type && shareholder.holder_type === 'person') || (shareholder.holderType && shareholder.holderType === 'person')) {
    return
  }

  if (shareholder.factset_entity_id) {
    return history.push(`/institution/${shareholder.factset_entity_id}`)
  }

  if (shareholder.institutionId) {
    return history.push(`/institution/${shareholder.institutionId}`)
  }
}

const renderRows = (shareholders, history, isShareholderId) => {
  return shareholders.map((shareholder, i) => {
    const current = (shareholder.current || shareholder.current !== 0) ? format(shareholder.current) : 0
    const change = getChange(shareholder, isShareholderId)

    return (
      <div
        className='top-shareholders_row q4-fade-in'
        key={getKey(shareholder) || i}
        onClick={() => redirect(shareholder, history)}
      >
        <div className='top-shareholders_holder'>
          <i className={`top-shareholders_icon ${getHolderTypeIcon(shareholder)}`} />
          <span className='top-shareholders_name'>
            {shareholder.holder_name || shareholder.institution_name || shareholder.institutionName || '-'}
          </span>
        </div>
        <div className='top-shareholders_current'>
          <p>{current}</p>
          <p className={`top-shareholders_current-change top-shareholders_${change.sign}`}>{change.value}</p>
        </div>
        <div className={`top-shareholders_change top-shareholders_${change.sign}`}>{change.value}</div>
      </div>
    )
  })
}

class CurrentTopShareholdersTable extends Component {
  render () {
    const { shareholders, history, subscriptionServices } = this.props
    const isShareholderId = subscriptionServices.shareholder_id

    return (
      <div className='top-shareholders_table'>
        {renderRows(shareholders, history, isShareholderId)}
      </div>
    )
  }
}

export default withRouter(CurrentTopShareholdersTable)
