import {
  DELETE_SAVED_TARGETS_FAILURE,
  DELETE_SAVED_TARGETS_REQUEST,
  DELETE_SAVED_TARGETS_SUCCESS,
  GET_PAGINATED_SAVED_TARGETS_FAILURE,
  GET_PAGINATED_SAVED_TARGETS_REQUEST,
  GET_PAGINATED_SAVED_TARGETS_SUCCESS,
  RESET_PAGINATED_SAVED_TARGETS_REQUEST,
  statusType
} from '../../actions'

const initial = {
  data: [],
  counts: {
    all: 0,
    contact: 0,
    fund: 0,
    institution: 0
  },
  status: statusType.IDLE,
  deleteStatus: statusType.IDLE
}

const savedTargets = (state = initial, action) => {
  switch (action.type) {
    case GET_PAGINATED_SAVED_TARGETS_REQUEST:
      return {
        ...state,
        status: statusType.IN_PROGRESS
      }

    case GET_PAGINATED_SAVED_TARGETS_SUCCESS:
      const payload = action.payload
      return {
        ...payload,
        data: payload.data.map((savedTarget) => {
          return {
            _id: savedTarget._id,
            create_date: savedTarget.create_date,
            entity_name: savedTarget.entity_name,
            entityType: savedTarget.reference.type,
            referenceItem: savedTarget.reference && savedTarget.reference.item
          }
        }),
        status: statusType.SUCCESS
      }

    case GET_PAGINATED_SAVED_TARGETS_FAILURE:
      return {
        ...state,
        error: action.error,
        status: statusType.ERROR
      }

    case DELETE_SAVED_TARGETS_REQUEST: {
      return {
        ...state,
        deleteStatus: statusType.IN_PROGRESS
      }
    }

    case DELETE_SAVED_TARGETS_SUCCESS: {
      return {
        ...state,
        deleteStatus: statusType.SUCCESS
      }
    }

    case DELETE_SAVED_TARGETS_FAILURE: {
      return {
        ...state,
        deleteStatus: statusType.ERROR
      }
    }

    case RESET_PAGINATED_SAVED_TARGETS_REQUEST:
      return {
        ...initial
      }

    default:
      return state
  }
}

export default savedTargets
