import React, { createRef, PureComponent } from 'react'
import { default as ReactSelect, components } from 'react-select'
import PropTypes from 'prop-types'
import { Button, Error } from '../../components'
import { Scrollbars } from 'react-custom-scrollbars'
import { renderDarkThumb, renderTrackVertical } from '../../resources/theme/q4.custom-scrollbar'
import { getClassName, THEMES } from '../../utils'

import './select.component.css'

const ControlComponent = (props) => {
  const { innerProps, selectProps } = props
  const { dataId, error } = selectProps

  return (
    <components.Control
      {...props}
      innerProps={{ ...innerProps, 'data-id': dataId && dataId.inputId }}
      className={`${(error && error.isError) ? 'select__control--error' : ''}`}
    />
  )
}

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <i className='select__indicator-clear q4i-close-4pt' />
    </components.ClearIndicator>
  )
}

const DropdownIndicator = (props) => {
  const { showDropdownIndicator, menuIsOpen } = props.selectProps
  const toggleStyles = { transform: `rotate(${menuIsOpen * 180}deg)` }

  return (
    showDropdownIndicator
      ? <components.DropdownIndicator {...props}>
        <div className='dropdown-toggle_arrow' style={toggleStyles}>
          <i className='select__indicator-dropdown q4i-arrow-down-4pt' />
        </div>
      </components.DropdownIndicator> : null
  )
}

const LoadingIndicator = (props) => {
  const { isLoading } = props.selectProps

  return (
    isLoading
      ? <div className='select__indicator-loading'>
        <i className='select__indicator-loading-spinner' />
      </div> : null
  )
}

const Menu = (props) => {
  const { innerProps, selectProps } = props
  const { dataId, menuPlacement, theme, size } = selectProps

  const baseClassName = getClassName('select_menu', [
    { condition: theme, trueClassName: `select_menu--${theme}` },
    { condition: size, trueClassName: `select_menu--${size}` },
    { condition: menuPlacement, trueClassName: `select_menu--${menuPlacement}` }
  ])

  return (
    <components.Menu
      {...props}
      innerProps={{ ...innerProps, 'data-id': dataId && dataId.menuId }}
      className={baseClassName}
    >
      {props.children}
    </components.Menu>
  )
}

const MenuList = (props) => {
  const { minMenuHeight, maxMenuHeight, menuPlacement } = props.selectProps
  const baseClassName = getClassName('select__menu-list', [
    { condition: menuPlacement, trueClassName: `select__menu-list--${menuPlacement}` }
  ])
  return (
    <components.MenuList className={baseClassName} {...props}>
      <Scrollbars
        className='react-scrollbar'
        autoHide
        autoHeight
        autoHeightMin={minMenuHeight}
        autoHeightMax={maxMenuHeight}
        hideTracksWhenNotNeeded
        renderThumbVertical={renderDarkThumb}
        renderTrackVertical={renderTrackVertical}>
        {props.children}
      </Scrollbars>
    </components.MenuList>
  )
}

const Option = (props) => {
  const { data, isMulti, selectProps } = props
  const { isDefaultList, onMenuClose } = selectProps

  return (
    (data && data.type === 'action') ? <components.Option className='select__option--action' {...props}>
      <Button
        {...data}
        theme={data.theme || THEMES.CITRUS}
        onClick={() => {
          const { onClick } = data
          // Close menu when an action item is selected
          // Otherwise, menu will remain open when multi-select is enabled
          onClick && onClick()
          onMenuClose && onMenuClose()
        }}
      />
    </components.Option> : isMulti && isDefaultList
      ? <div className='select__option--multi'>
        <components.Option {...props} />
      </div>
      : <components.Option {...props} />
  )
}

const ValueContainer = ({ children, ...props }) => {
  const { isMulti, selectProps } = props
  const { menuIsOpen, isSearchable, placeholder, inputValue } = selectProps

  return (
    <components.ValueContainer {...props}>
      {isMulti
        ? <>
          {isSearchable ? (!menuIsOpen && !inputValue &&
            <div className='select__placeholder'>{placeholder}</div>) : placeholder}
          {React.cloneElement(children[1])}
        </>
        : children
      }
    </components.ValueContainer>
  )
}

const MultiValueContainer = () => {
  return null
}

/**
 * Select Component
 * Usage: Autocomplete, Dropdown, Multiselect
 */
class Select extends PureComponent {
  menuPortalRef = createRef()
  portalTarget = null

  componentDidMount = () => {
    if (!this.props.portal) return
    this.portalTarget = document.body
  }

  componentDidUpdate = () => {
    this.portalTarget = this.props.portal ? document.body : null
  }

  /**
   * Filter option (one at a time) from options list using query from search
   * @param option
   * @param query
   * @returns {*}
   */
  filterOption = (option, query) => {
    if (!option) {
      return false
    }

    const label = option.label || ''
    const type = option.data && option.data.type

    if (query && query.length && type === 'action') {
      return true
    }

    return (query && query.length && typeof label === 'string')
      ? label.toLowerCase().includes(query.toLowerCase())
      : option
  }

  renderMenuPortal = (menuPortalComponentProps) => {
    return (
      <components.MenuPortal {...menuPortalComponentProps}>
        <div ref={this.menuPortalRef}>
          {menuPortalComponentProps.children}
        </div>
      </components.MenuPortal>
    );
  }

  isPortalScrolling = ({ target }) => {
    if (!this.props.portal || !this.menuPortalRef.current || !target || !this.portalTarget.contains(target)) return false
    return !this.menuPortalRef.current.contains(target)
  }
  
  /**
   * Render Select Component
   * @returns {JSX.Element}
   */
  render () {
    const {
      inputId, dataId, className, theme, size, name, label, placeholder, value, inputValue, defaultValue, selectReference, options,
      onInputChange, onChange, onKeyDown, onFocus, onBlur, error, loading, clearable, disabled, required, searchable, showDropdownIndicator,
      itemHeight, maxHeight, minHeight, limit, isMulti, isDefaultList, hideSelectedOptions, closeMenuOnSelect, setAsAbsolutePosition,
      portal, menuPlacement, noOptionsMessage, filterOption, captureMenuScroll, showInFieldValues, ...selectProps
    } = this.props

    const { isError, message } = (error || {})
    const regular = 40
    const thin = 32
    const thick = 50

    const isMultiSelected = isMulti && value && value.length > 0
    const placeholderLabel = isMultiSelected > 0 && showInFieldValues ? value.map(v => v.label).join(', ') : placeholder
    return (
      <>
        {label && <label className={getClassName('select-component_label', [
          { condition: theme, trueClassName: `select-component--${theme}` }
        ])}>
          {label} {!!required && <span className='select-component_label--required'>(required)</span>}
        </label>}
        <ReactSelect
          ref={selectReference}
          dataId={dataId}
          inputId={inputId}
          name={name}
          theme={theme}
          size={size}
          captureMenuScroll={captureMenuScroll}
          className={getClassName('select-component', [
            { condition: className, trueClassName: className },
            { condition: theme, trueClassName: `select-component--${theme}` },
            { condition: size, trueClassName: `select-component--${size}` }
          ])}
          classNamePrefix='select'
          styles={{
            menu: base => ({ ...base, marginTop: 0 }),
            menuPortal: (base) => ({ ...base, zIndex: 55 }),
            container: (base)=>(setAsAbsolutePosition?{ ...base,  position:'absolute'} : base)
          }}
          // menuIsOpen // used for testing only
          menuPortalTarget={this.portalTarget}
          menuPlacement={menuPlacement}
          placeholder={placeholderLabel}
          value={value}
          inputValue={inputValue}
          defaultValue={defaultValue}
          onInputChange={onInputChange}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          options={options}
          filterOption={filterOption || this.filterOption}
          noOptionsMessage={() => (noOptionsMessage || null)}
          onChange={onChange}
          error={error}
          isLoading={loading}
          isClearable={clearable}
          isDisabled={disabled}
          isSearchable={searchable}
          showDropdownIndicator={showDropdownIndicator}
          minMenuHeight={minHeight}
          maxMenuHeight={maxHeight
            ? maxHeight
            : (size === 'thin') ? (thin * limit) : (size === 'thick') ? (thick * limit) : (regular * limit)}
          itemHeight={itemHeight
            ? itemHeight
            : (size === 'thin') ? thin : (size === 'thick') ? thick : regular}
          limit={limit}
          isMulti={isMulti}
          isDefaultList={isDefaultList}
          closeMenuOnSelect={closeMenuOnSelect}
          hideSelectedOptions={hideSelectedOptions}
          components={{
            Control: ControlComponent,
            ClearIndicator,
            DropdownIndicator,
            LoadingIndicator,
            MenuPortal: this.renderMenuPortal,
            Menu,
            MenuList,
            Option,
            ValueContainer,
            MultiValueContainer
          }}
          closeMenuOnScroll={this.isPortalScrolling}
          {...selectProps}
        />

        {isError && <Error message={message} />}
      </>
    )
  }
}

/**
 * Docs: https://github.com/JedWatson/react-select
 */
Select.propTypes = {
  /**
   * Used for Nightwatch UI automation testing
   * Input and Menu require separate data-ids since one is not a child of the other
   */
  dataId: PropTypes.shape({
    inputId: PropTypes.string,
    menuId: PropTypes.string
  }),
  className: PropTypes.string,
  theme: PropTypes.oneOf([
    THEMES.RAIN,
    THEMES.STEEL,
    THEMES.Q4_BLUE,
    THEMES.INK,
    THEMES.LIGHT_GREY,
    THEMES.WHITE,
    THEMES.SLATE,
    THEMES.DARK,
    THEMES.LIGHT
  ]),
  size: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  // Setting select value to null or undefined does not change to placeholder-like option:
  // https://github.com/facebook/react/issues/4085
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
  inputValue: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectReference: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  options: PropTypes.array.isRequired,
  filterOption: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.shape({
    isError: PropTypes.bool,
    message: PropTypes.string
  }),
  loading: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  required: PropTypes.bool,
  showDropdownIndicator: PropTypes.bool,
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itemHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuPlacement: PropTypes.string,
  isMulti: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  setAsAbsolutePosition: PropTypes.bool,
  /**
   * Used to show checkboxes in multiselect options (predefined list)
   */
  isDefaultList: PropTypes.bool,
  captureMenuScroll: PropTypes.bool,
  portal: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  showInFieldValues: PropTypes.bool
}

Select.defaultProps = {
  theme: THEMES.LIGHT_GREY,
  error: {},
  placeholder: 'Search',
  options: [],
  minHeight: 0,
  limit: 6,
  loading: false,
  clearable: true,
  disabled: false,
  required: false,
  showDropdownIndicator: true,
  isMulti: false,
  hideSelectedOptions: false,
  isDefaultList: false,
  captureMenuScroll: false,
  portal: false,
  setAsAbsolutePosition:false,
  showInFieldValues: false
}

export default Select
